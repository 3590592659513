import { SupportFileType } from '@/pages/datastores/components/DatastoreMediaUpload'

export function splitArray(arr: string | any[], index: number) {
  // 检查索引是否在数组范围内
  if (index < 0 || index > arr.length) {
    return [[], []]
  }

  const leftPart = arr.slice(0, index)
  const rightPart = arr.slice(index)

  return [leftPart, rightPart] as string[][]
}

// 定义匹配类型的常量数组 默认包含SupportFileType中的类型
const IMAGE_EXTENSIONS = SupportFileType.image.concat([
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.webp',
  '.svg',
])
const VIDEO_EXTENSIONS = SupportFileType.video.concat([
  '.mp4',
  '.avi',
  '.mov',
  '.webm',
  '.mkv',
  '.ogg',
])

// 辅助函数：检查 URL 是否匹配指定的扩展名或域名
function _matchesType(url: string, extensions: string[]): boolean {
  const lowercaseUrl = url.toLowerCase()
  return extensions.some(ext => lowercaseUrl.endsWith(ext))
}

// 修改此逻辑 请跑下util.test.ts 的testParseString方法
export function parseString(input: string) {
  const regex =
    /(!?\[.*?\]\(.*?\))|(!?\[.*?$)|(\[.*?\]\(.*?\))|([^!\[]+|!(?!\[)|\[(?!\w))/g
  const matches = input.match(regex)

  if (!matches) {
    return [{ content: input, type: 'text' }]
  }

  return matches.map(match => {
    if (match.startsWith('![')) {
      if (match.endsWith(')')) {
        const urlMatch = match.match(/\((.*?)\)/)
        if (urlMatch && urlMatch[1]) {
          const url = urlMatch[1]
          if (_matchesType(url, VIDEO_EXTENSIONS)) {
            return { content: match, type: 'video' }
          } else if (_matchesType(url, IMAGE_EXTENSIONS)) {
            return { content: match, type: 'image' }
          }
        }
        return { content: match, type: 'image' }
      } else {
        return { content: match, type: 'text' }
      }
    } else if (
      match.startsWith('[') &&
      match.includes('](') &&
      match.endsWith(')')
    ) {
      return { content: match, type: 'link' }
    }
    return { content: match, type: 'text' }
  })
}
