import { memo } from 'react'
import { Checkbox, Form, Radio } from 'antd'
import { FormItem, Segmented } from '@/components'
import {
  TaskType,
  TaskWriteMethods,
  UncertaintyStrategy,
} from '@/features/prompt/constant/generatePrompt.ts'
import { WithQuickFillTextArea } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'

const taskTypeOptions = [
  {
    label: '查询知识库',
    value: TaskType.QUERY,
  },
  {
    label: '标签打标',
    value: TaskType.TAGGING,
  },
  {
    label: '意图分类',
    value: TaskType.DETECT_INTENT,
  },
  {
    label: '文案撰写',
    value: TaskType.WRITE,
  },
]

export const TaskForm = memo<{
  placeholderMap: Record<string, string>
}>(({ placeholderMap }) => {
  return (
    <>
      <Form.Item noStyle name='taskType'>
        <Segmented
          size='small'
          className='mb-24 h-36px [&_.ant-segmented-item]:py-8! [&_.ant-segmented-item-label]:font-500 [&_.ant-segmented-item-label]:text-14px'
          options={taskTypeOptions}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.taskType !== next.taskType}
      >
        {form => {
          const taskType = form.getFieldValue('taskType')
          switch (taskType) {
            case TaskType.QUERY:
              return (
                <>
                  <div className='font-500 mb-24'>查询说明</div>
                  <FormItem
                    className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
                    label='没查到内容时：'
                    name='handleNoContent'
                  >
                    <Radio.Group className='[&_.ant-radio-wrapper-in-form-item]:mr-24px!'>
                      <Radio value={UncertaintyStrategy.UNKNOWN}>
                        输出不知道
                      </Radio>
                      <Radio value={UncertaintyStrategy.RECONFIRM}>
                        再次确认问题
                      </Radio>
                      <Radio value={UncertaintyStrategy.CUSTOM}>
                        自定义动作
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.handleNoContent !== next.handleNoContent
                    }
                  >
                    {form => {
                      const value = form.getFieldValue('handleNoContent')
                      return (
                        <Form.Item
                          className='mt--20px'
                          name='customHandleNoContent'
                          hidden={value !== UncertaintyStrategy.CUSTOM}
                        >
                          <WithQuickFillTextArea
                            rows={3}
                            className='resize-none!'
                            placeholder={placeholderMap.customHandleNoContent}
                          />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                  <FormItem
                    className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
                    label='无法判断内容是否正确时：'
                    name='resolveUncertainInput'
                  >
                    <Radio.Group className='[&_.ant-radio-wrapper-in-form-item]:mr-24px!'>
                      <Radio value={UncertaintyStrategy.UNKNOWN}>
                        输出不知道
                      </Radio>
                      <Radio value={UncertaintyStrategy.RECONFIRM}>
                        再次确认问题
                      </Radio>
                      <Radio value={UncertaintyStrategy.CUSTOM}>
                        自定义动作
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.resolveUncertainInput !== next.resolveUncertainInput
                    }
                  >
                    {form => {
                      const value = form.getFieldValue('resolveUncertainInput')
                      return (
                        <Form.Item
                          className='mt--20px'
                          name='customResolveUncertainInput'
                          hidden={value !== UncertaintyStrategy.CUSTOM}
                        >
                          <WithQuickFillTextArea
                            rows={3}
                            className='resize-none!'
                            placeholder={
                              placeholderMap.customResolveUncertainInput
                            }
                          />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </>
              )
            case TaskType.TAGGING:
              return (
                <>
                  <FormItem
                    className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
                    label='打标说明'
                    name='taggingDesc'
                  >
                    <WithQuickFillTextArea
                      rows={3}
                      className='resize-none!'
                      placeholder={placeholderMap.taggingDesc}
                    />
                  </FormItem>
                </>
              )
            case TaskType.DETECT_INTENT:
              return (
                <>
                  <FormItem
                    className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
                    label='意图说明'
                    name='intentDesc'
                  >
                    <WithQuickFillTextArea
                      rows={3}
                      className='resize-none!'
                      placeholder={placeholderMap.intentDesc}
                    />
                  </FormItem>
                </>
              )
            case TaskType.WRITE:
              return (
                <>
                  <FormItem
                    className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
                    label='文案编写目标'
                    name='writeTarget'
                  >
                    <WithQuickFillTextArea
                      rows={3}
                      className='resize-none!'
                      placeholder={placeholderMap.writeTarget}
                    />
                  </FormItem>
                  <FormItem
                    className='mb-20 [&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
                    label='文案撰写方法'
                    name='writeMethods'
                  >
                    <Checkbox.Group className='gap-y-8 gap-x-24 [&_.ant-checkbox]:rd-4px! [&_.ant-checkbox-inner]:rd-4px!'>
                      <Checkbox value={TaskWriteMethods.NUM_EMOTION}>
                        数字情感法
                      </Checkbox>
                      <Checkbox value={TaskWriteMethods.ADJ_KILL}>
                        杀死形容词法
                      </Checkbox>
                      <Checkbox value={TaskWriteMethods.CONFLICT}>
                        矛盾冲突法
                      </Checkbox>
                      <Checkbox value={TaskWriteMethods.PROVERB}>
                        大众俗语法
                      </Checkbox>
                      <Checkbox value={TaskWriteMethods.CUSTOM}>
                        自定义方法
                      </Checkbox>
                    </Checkbox.Group>
                  </FormItem>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.writeMethods !== next.writeMethods
                    }
                  >
                    {form => {
                      const writeMethods =
                        form.getFieldValue('writeMethods') ?? []
                      return (
                        <FormItem
                          hidden={
                            !writeMethods.includes(TaskWriteMethods.CUSTOM)
                          }
                          label='自定义方法'
                          name='customMethod'
                        >
                          <WithQuickFillTextArea
                            rows={4}
                            className='resize-none!'
                            placeholder={placeholderMap.customMethod}
                          />
                        </FormItem>
                      )
                    }}
                  </Form.Item>
                </>
              )
            default:
              return null
          }
        }}
      </Form.Item>
    </>
  )
})

TaskForm.displayName = 'TaskForm'
