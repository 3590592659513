import type { FC } from 'react'
import { Form } from 'antd'
import { Input, IconFont } from '@/components'

interface OptionItem {
  label: string
  value: string
}
interface OptionsConfigProps {
  value?: OptionItem[]
  onChange?: (value: OptionItem[]) => void
}

export const OptionsConfig: FC<OptionsConfigProps> = () => {
  return (
    <Form.List name='options'>
      {(fields, { add, remove }) => {
        const nextIndex = fields.length + 1
        return (
          <>
            {fields.map(field => (
              <Form.Item
                className='mb-4'
                key={field.key}
                name={[field.name, 'label']}
                rules={[
                  {
                    required: true,
                    message: '选项名称为必填项',
                  },
                ]}
              >
                <Input
                  size='small'
                  className='pr-4'
                  placeholder='输入选项'
                  suffix={
                    <span
                      onClick={() => remove(field.name)}
                      className='cursor-pointer flex items-center text-font_1 text-op-60 justify-center w-24px h-24px inline-block rounded-4px hover:bg-error hover:bg-opacity-12 hover:text-error text-font_1'
                    >
                      <IconFont name='shanshu' className='text-16px' />
                    </span>
                  }
                />
              </Form.Item>
            ))}
            <Form.Item noStyle>
              <div
                onClick={() =>
                  add({ label: `选项${nextIndex}`, value: `选项${nextIndex}` })
                }
                className='py-8px  items-center justify-center flex border border-solid border-line rounded-6px hover:bg-bg_3 hover:bg-opacity-8'
              >
                <IconFont name='add' />
                <span className='text-12px ml-10'>添加选项</span>
              </div>
            </Form.Item>
          </>
        )
      }}
    </Form.List>
  )
}
