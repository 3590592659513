import { fileTypeMimeMap } from '@/features/datastore/constant'
import { getFileSuffix } from '@/features/datastore/utils'

export function isExcelFile(file: File) {
  const { type, name } = file

  if (type) {
    return type === fileTypeMimeMap.xls || type === fileTypeMimeMap.xlsx
  } else {
    const suffix = getFileSuffix(name)
    return suffix === 'xls' || suffix === 'xlsx'
  }
}

export function isCSVFile(file: File) {
  const { type, name } = file

  if (type) {
    return type === fileTypeMimeMap.csv
  } else {
    const suffix = getFileSuffix(name)
    return suffix === 'csv'
  }
}
