import type { ReactNode } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Avatar } from '@bty/chat-ui'
import classNames from 'classnames'
import { Badge } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import type { Bot } from '@bty/chat-types'
import { BusinessBotList } from '@bty/business-components'
import type { StoreBotItem } from '@/store'
import { useBotStore } from '@/store'
import { useChatStore } from '@/store/chat.ts'
import { isNotAuthRoute } from '@/router'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { robotStorage } from '@/utils/storage'
import { getAvatarFromBot } from '@/features/chat/utils/getAvatar'
import { Input } from '@/components'

export default function ChatMenu({
  regularChatList,
  showLogo,
  BottomChildren,
}: {
  regularChatList?: StoreBotItem[]
  showLogo?: boolean
  BottomChildren?: ReactNode
}) {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { botList, fetchBotList } = useBotStore()
  const { unreadMessageCountMap } = useChatStore()
  const botListInstances = useRef<Record<Bot['robot_id'], HTMLElement | null>>(
    {},
  )
  const isAutoScrollFlag = useRef(false)

  const activeKey = useMemo(() => {
    return location.state?.fromRoute === 'templates'
      ? 'templates'
      : params.botId || location.pathname.split('/').slice(-1)[0] || 'templates'
  }, [params, location])

  useEffect(() => {
    if (activeKey && !isAutoScrollFlag.current) {
      setTimeout(() => {
        botListInstances?.current[activeKey]?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }, 100)
      isAutoScrollFlag.current = true
    }
  }, [activeKey])

  useEffect(() => {
    !isNotAuthRoute() && fetchBotList()
  }, [activeKey])

  const { scrollRef } = useScrollBar()

  const header = (
    <div
      className={classNames(
        'h-64px relative py-26 px-19 text-16px font-600 cursor-pointer bg-white mb-12px text-20px  font-600 mt-2',
        { 'px-10px!': showLogo },
      )}
      onClick={() => {
        showLogo
          ? (window.location.href = 'https://www.betteryeah.com/')
          : navigate('/explore')
      }}
    >
      {showLogo ? (
        <div className='flex-center'>
          <img
            src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/battleyeah-ai/ai-client/logo-square.svg'
            className='w-25px h-25px ml--10px'
          />
          <span className='text-20px ml-8px'>BetterYeah AI Agent</span>
        </div>
      ) : (
        '对话'
      )}
    </div>
  )

  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      {!regularChatList?.length ? (
        <div className='h-full  b-r b-r-line b-r-op-48'>
          <BusinessBotList
            selectedId={activeKey}
            hideSearch
            filterValue={searchValue}
            prefix={
              <div className='px-16px'>
                {header}
                <Input
                  className='!h-32px'
                  prefix={<SearchOutlined />}
                  placeholder='搜索 agent'
                  onChange={e => setSearchValue(e.target.value)}
                  size='middle'
                />
              </div>
            }
            notificationMap={unreadMessageCountMap.robot}
            onChange={id => {
              navigate(`/chatbot/${id}`)
              !regularChatList && robotStorage.set(`${id}`)
            }}
          />
        </div>
      ) : (
        <div className='h-full'>
          <div className='h-full flex flex-col b-r b-r-line b-r-op-48'>
            {header}
            <div className='flex-1 flex flex-col overflow-hidden h-full'>
              {botList.length || regularChatList?.length ? (
                <div className='overflow-auto p-x-20px  h-full' ref={scrollRef}>
                  <div>
                    {(regularChatList || botList).map(bot => {
                      const { robot_id, name, description } = bot
                      return (
                        <Link
                          key={robot_id}
                          ref={el =>
                            robot_id &&
                            (botListInstances.current[robot_id] = el)
                          }
                          className={classNames(
                            'relative flex flex-items-center mb-8px b-rd-8px bg-#fff',
                            {
                              // 'bg-op-16 before:absolute before:content-[""] before:top-calc(50%-4px) before:b-rd-50% before:left--13px before:w-8px before:h-8px before:bg-#7b61ff':
                              //   robot_id === activeKey,
                              'bg-op-6': robot_id !== activeKey,
                            },
                          )}
                          to={
                            regularChatList
                              ? `/shareChannel/${robot_id}`
                              : `/chatbot/${robot_id}`
                          }
                        >
                          <div
                            className={classNames(
                              'rounded-8px w-full flex items-center p-12 hover:bg-#626999 hover:bg-op-8',
                              {
                                'bg-primary bg-op-8': robot_id === activeKey,
                              },
                            )}
                            onClick={() =>
                              !regularChatList &&
                              robotStorage.set(`${robot_id}`)
                            }
                          >
                            <Avatar
                              className='m-r-8px'
                              icon={getAvatarFromBot(bot as Bot)}
                              size={40}
                              color={bot.color}
                            />
                            <div className='flex-1 overflow-hidden'>
                              <div className='m-b-8px text-one-line c-font font-500'>
                                {name}
                              </div>
                              <div className='w-full text-one-line text-12px c-font_1'>
                                {description}
                              </div>
                            </div>
                            {!!unreadMessageCountMap.robot[robot_id!] && (
                              <Badge
                                count={
                                  unreadMessageCountMap.robot[robot_id!] ?? 0
                                }
                                showZero={false}
                                color='#ff5219'
                              />
                            )}
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            {BottomChildren}
          </div>
        </div>
      )}
    </>
  )
}
