import type { TrackConfig } from './features/track/type'

export const trackList: TrackConfig[] = [
  {
    event: 'datastore_upload',
    eventName: '上传知识库',
    trigger: ['click'],
  },
  {
    event: 'datastore_doc_cut',
    eventName: '分段剪切',
    trigger: ['click'],
  },
  {
    event: 'datastore_doc_merge',
    eventName: '分段合并',
    trigger: ['click'],
  },
  {
    event: 'datastore_doc_open',
    eventName: '打开文件',
    trigger: ['click'],
  },
  {
    event: 'datastore_doc_save',
    eventName: '知识库文件保存',
    trigger: ['click'],
  },
  {
    event: 'flow_code_node_run',
    eventName: 'Flow_Code节点运行',
    trigger: ['click'],
  },
  {
    event: 'flow_code_doc_open',
    eventName: 'Flow_Code节点打开文档',
    trigger: ['click'],
  },
  {
    event: 'flow_code_example_copy',
    eventName: 'Flow_Code节点实例代码复制',
    trigger: ['click'],
  },
  {
    event: 'agent_prompt_graph',
    eventName: 'agent_prompt示意图按钮',
    trigger: ['click'],
  },
  {
    event: 'agent_prompt_graph_regenerate',
    eventName: 'agent_prompt示意图重新生成',
    trigger: ['click'],
  },
  {
    event: 'agent_prompt_struct_transform',
    eventName: 'agent_prompt结构化非结构化切换点击',
    trigger: ['click'],
  },
  {
    event: 'agent_prompt_struct_transform_use',
    eventName: 'agent_prompt结构化非结构化切换并应用',
    trigger: ['click'],
  },
  {
    event: 'agent_prompt_generate',
    eventName: 'agent_prompt一键生成',
    trigger: ['click'],
  },
  {
    event: 'agent_prompt_generate_use',
    eventName: 'agent_prompt使用一键生成结果',
    trigger: ['click'],
  },
  {
    event: 'flow_plugin_add',
    eventName: 'flow添加插件事件',
    trigger: ['click'],
  },
  {
    event: 'intent_sample_data_add',
    eventName: '意图节点样本数据添加',
    trigger: ['click'],
  },
]
