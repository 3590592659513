import { useState, useEffect } from 'react'

export function useTypingString(inputString: string, interval = 300) {
  const [displayString, setDisplayString] = useState('')

  useEffect(() => {
    let index = 0

    const intervalId = setInterval(() => {
      if (index <= inputString.length) {
        setDisplayString(inputString.slice(0, index))
        index += 1
      } else {
        clearInterval(intervalId)
      }
    }, interval)

    return () => clearInterval(intervalId)
  }, [inputString, interval])

  return {
    displayString,
    finished: displayString.length === inputString.length,
    setDisplayString,
  }
}
