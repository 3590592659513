import { AuthRouterProvider } from '@bty/react-auth'
import { useRequest } from 'ahooks'
import { useEffect, useMemo, useState } from 'react'
import { type Params, type RouterProviderProps } from 'react-router-dom'
import type { BusinessAuth } from '@bty/business-components'
import { BusinessComponentProvider } from '@bty/business-components'
import { createGrayscale } from '@/apis/grayscale'
import auth from '@/auth'
import { API_STATUS_CODES } from '@/constants/request'
import { useWorkspace } from '@/hooks/useWorkspace'
import { useBotStore, useUserStore, useVersionStore } from '@/store'
import { tokenStorage, workspaceStorage } from '@/utils/storage'
import { BusinessError } from '@/apis/lib/error'
import { useApolloConfig } from '@/store/apolloConfig'

export function AuthProvider({
  children,
  router,
}: {
  children: React.ReactNode
  router: RouterProviderProps['router']
}) {
  const { fetchUser, user } = useUserStore()
  const [loaded, setLoaded] = useState(false)

  const { fetchWorkspaceList, currentWorkspaceItem, readyInitWorkspace } =
    useWorkspace()

  const { fetchVersionConfigList } = useVersionStore()
  const { initApolloConfigStore } = useApolloConfig()

  const fetchBotList = useBotStore(state => state.fetchBotList)

  const {
    data: grayscaleList,
    runAsync,
    loading,
  } = useRequest(createGrayscale, { manual: true })

  const isSettingsPage = location.pathname.startsWith('/workspace')

  const init = async (params: Params<string>) => {
    let initialized = false
    let url = ''
    try {
      fetchVersionConfigList()
      fetchBotList()
      initApolloConfigStore()

      const [workspaceList] = await Promise.all([
        fetchWorkspaceList(),
        fetchUser(),
      ])

      readyInitWorkspace(isSettingsPage ? params.id : params.workspaceId)
      if (!workspaceList.length) {
        console.log('该用户下没有workspace')
        url = `/login?type=workspace&redirect=${encodeURIComponent(
          window.location.pathname,
        )}`
      } else {
        initialized = true
        await runAsync()
      }
    } catch (error: any) {
      const authError =
        error?.response?.status === API_STATUS_CODES.REQUEST_NOT_AUTH ||
        (error instanceof BusinessError &&
          error.message.includes(
            `status_code=${API_STATUS_CODES.REQUEST_NOT_AUTH}`,
          ))
      if (authError) {
        const redirectParams = `redirect=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`

        const redirectURL = `/login${
          window.location.search
            ? `${window.location.search}&${redirectParams}`
            : `?${redirectParams}`
        }`
        url = redirectURL
      }
    } finally {
      setLoaded(true)
    }
    return [initialized, url]
  }

  useEffect(() => {
    !!tokenStorage.get() &&
      currentWorkspaceItem?.id &&
      !loading &&
      loaded &&
      runAsync()
  }, [currentWorkspaceItem?.id])

  const businessComponentAuth: BusinessAuth = useMemo(() => {
    return {
      workspaceId: currentWorkspaceItem?.id,
      applicationId: workspaceStorage.get() || '',
      token: tokenStorage.get() || '',
    }
  }, [currentWorkspaceItem])

  return (
    <BusinessComponentProvider
      env={__DEFINE_ENV__}
      auth={businessComponentAuth}
    >
      <AuthRouterProvider
        router={router}
        loaded={loaded}
        state={{
          user,
          token: tokenStorage.get(),
          role: currentWorkspaceItem?.roleCode,
          grayscaleList,
        }}
        initStateAction={init}
        authFn={auth}
      >
        {children}
      </AuthRouterProvider>
    </BusinessComponentProvider>
  )
}
