import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip, message } from 'antd'
import classNames from 'classnames'
import { useDebounceEffect, useRequest, useSize } from 'ahooks'
import { concat, remove } from 'lodash-es'
import styled from '@emotion/styled'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { IconFont, usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import { VariableRegex } from '@/constants/common'
import type { Variable } from '@/features/editor'
import { getFileIconByFileTypeOrMimeType } from '@/features/datastore/utils'
import type { DocumentItem } from '@/apis/datastore/types'
import type { SelectOptionsType } from './components/SelectComponent'
import { SelectComponent } from './components/SelectComponent'
import { TagVariableEditor } from './components/TagVariableEditor'

interface DocumentSelectProps {
  disabled?: boolean
  value?: string | string[]
  onChange?: (value: string | unknown[]) => void
  variables: Variable[]
  variableTipsContainer?: HTMLElement
  memoryId?: number
}

const PopoverWrapper = styled.div<{ height: number }>`
  .ant-popover {
    inset: ${props => props.height}px 0px auto auto !important;
  }
`

export const DatasetFileSelect: FC<DocumentSelectProps> = props => {
  const ref = useRef<HTMLDivElement>(null)
  const containerSize = useSize(ref)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const [_value, set_Value] = useState(() => props.value)

  const [open, setOpen] = useState(false)

  // 处理 _value -> old props.value -> new props.value -> _value 的状态闪烁的问题
  useDebounceEffect(
    () => {
      if (!open) {
        set_Value(props.value)
      }
    },
    [props.value, open],
    {
      wait: 400,
    },
  )

  const getDatasetDocuments = useNodeMetaStore(
    state => state.getDatasetDocuments,
  )
  const datasetDocumentsMap = useNodeMetaStore(
    state => state.datasetDocumentsMap,
  )
  const isFirstLoad = useRef(true)

  const documents = props.memoryId
    ? (datasetDocumentsMap[props.memoryId] ?? [])
    : []

  const fetchDocumentList = async (force = true) => {
    if (props.memoryId && !VariableRegex.test(String(props.memoryId))) {
      getDatasetDocuments(props.memoryId, force)
      isFirstLoad.current = false
    }
    return true
  }

  useRequest(fetchDocumentList, {
    manual: true,
    refreshOnWindowFocus: true,
  })

  useEffect(() => {
    fetchDocumentList(isFirstLoad.current)
  }, [props.memoryId])

  const list: Array<SelectOptionsType & DocumentItem> = useMemo(() => {
    return documents.map(item => {
      return {
        ...item,
        label: item.file_name,
        value: item.file_id,
        icon: getFileIconByFileTypeOrMimeType(item.mimetype, item.file_type),
        iconColor: '',
        type: 'document',
      }
    })
  }, [documents])

  const onVariableEditorChange = useCallback(
    (v: string) => {
      // 如果是变量，或者是已有的标签，直接添加
      if (
        VariableRegex.test(v) ||
        list.findIndex(item => String(item.value) === v) !== -1
      ) {
        set_Value(v)
        // 如果是变量，直接添加
        props.onChange?.(v)
      } else {
        message.warning('该文档不存在')
      }
      setOpen(false)
    },
    [list, props.value, props.onChange],
  )

  const pageModal = usePageModal()
  const showPageModal = (
    disabled: boolean | undefined,
    partition_id: number,
    file_id: number,
  ) => {
    if (disabled) {
      return
    }
    pageModal.show({
      url: `/datastores/${currentWorkspaceId}/${partition_id}/${file_id}/content`,
    })
  }

  const getDocumentItem = useCallback(
    (val: number | string) => {
      const item = list.find(item => item.value === val)
      return item
    },
    [list],
  )

  const onRemove = (file_value: string | number) => {
    if (Array.isArray(_value)) {
      const _new_value = remove(_value, v => v !== file_value)
      set_Value(_new_value)
      props.onChange?.(_new_value)
    }
  }

  const tagVariableEditorPlaceholder = useMemo(() => {
    const hasValue = Array.isArray(_value) ? _value.length > 0 : _value
    if (hasValue) {
      return ''
    } else if (props?.variables?.length) {
      return '请选择文件或输入表示文件ID的变量'
    } else {
      return '请选择文件'
    }
  }, [_value, props.variables])

  return (
    // 使用 popover 包裹，hack 解决 antd popover 组件在弹出框中的定位问题
    <PopoverWrapper height={containerSize?.height || 0}>
      <div ref={ref}>
        <SelectComponent
          type='document'
          getPopupContainer={() => ref.current!}
          options={list}
          open={open}
          onOpenChange={visible => {
            if (!visible && _value && props?.onChange) {
              props.onChange(_value)
            }
            setOpen(visible)
          }}
          value={Array.isArray(_value) ? _value : [_value]}
          onChange={val => {
            // 如果之前是变量，直接替换
            if (_value && VariableRegex.test(_value.toString())) {
              const _new_value = [val as string]
              set_Value(_new_value)
            }
            if (Array.isArray(_value)) {
              let _new_value = []
              if (!_value.includes(val as string)) {
                _new_value = concat(_value, val)
              } else {
                _new_value = remove(_value, v => v !== val)
              }
              set_Value(_new_value as string[])
            }
          }}
          handlePreviewClick={targetVal => {
            const knowledgeItem = getDocumentItem(targetVal!)
            if (knowledgeItem) {
              showPageModal(
                props.disabled,
                knowledgeItem.partition_id,
                knowledgeItem.file_id,
              )
            }
          }}
          extraRender={
            <div
              className={classNames({
                'b-t-1 b-t-line b-t-op-60 mt-4 pt-4': list.length,
              })}
            >
              <div
                className='w-100% h-32px cursor-pointer flex-center text-12px hover:bg-bg_3 hover:bg-op-8 rounded-6px'
                onClick={e => {
                  e.stopPropagation()
                  pageModal.show({
                    url: `/datastores/${currentWorkspaceId}/${props.memoryId}/documents`,
                  })
                }}
              >
                <IconFont name='add' className='text-16px' />
                创建数据文件
              </div>
            </div>
          }
          triggerRender={(_, ref) => {
            return (
              <>
                <div
                  className={classNames(
                    'relative px-8 py-4 b-1 rounded-6px bg-bg_3 bg-op-6 flex-center-between gap-8',
                    {
                      'b-primary': open,
                      'hover:b-primary b-bg_3 b-op-0': !open,
                    },
                  )}
                  ref={ref}
                >
                  <div className='flex gap-2 flex-wrap w-full items-center'>
                    {(Array.isArray(_value)
                      ? _value
                      : _value
                        ? [_value]
                        : []
                    ).map(value => {
                      const knowledgeItem = getDocumentItem(value)
                      const isVariable = VariableRegex.test(value)
                      return (
                        <div
                          key={value}
                          className={classNames(
                            'rounded-4px !m-0 flex items-center text-12px/16px pr-4px',
                            {
                              'bg-tips bg-op-12 c-tips b-tips': isVariable,
                            },
                          )}
                        >
                          {isVariable ? (
                            value
                          ) : (
                            <div
                              className='inline-flex  items-center my-2px rounded-4px bg-#fff px-8px py-4px'
                              title={knowledgeItem?.label || ''}
                            >
                              <img
                                className='w-14px h-16px shrink-0 mr-8px'
                                src={knowledgeItem && knowledgeItem.icon}
                                alt=''
                              />
                              <span className='max-w-250px truncate'>
                                {knowledgeItem?.label || value}
                              </span>
                              {knowledgeItem && (
                                <div
                                  className='w-16px h-16px cursor-pointer flex-center text-12px hover:bg-bg_3 hover:bg-op-8 rounded-6px ml-4px'
                                  onClick={e => {
                                    e.stopPropagation()
                                    showPageModal(
                                      props.disabled,
                                      knowledgeItem.partition_id,
                                      knowledgeItem.file_id,
                                    )
                                  }}
                                >
                                  <Tooltip placement='top' title='查看文档详情'>
                                    <IconFont
                                      name='super-link'
                                      className='c-#626999 c-op-60 text-16px'
                                    />
                                  </Tooltip>
                                </div>
                              )}
                              <IconFont
                                name='guanbi'
                                className='c-#626999 c-op-60 text-12px hover:c-#17171D ml-4px'
                                onClick={e => {
                                  e.stopPropagation()
                                  onRemove(value)
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                    <TagVariableEditor
                      variables={props.variables}
                      variableTipsContainer={props.variableTipsContainer}
                      onChange={onVariableEditorChange}
                      placeholder={tagVariableEditorPlaceholder}
                    />
                  </div>
                  <div className={classNames('w-16px h-16px flex-center')}>
                    <IconFont name='arrow' className='text-7px c-font_1' />
                  </div>
                </div>
              </>
            )
          }}
        />
      </div>
    </PopoverWrapper>
  )
}
