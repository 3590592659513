import { Checkbox as AntdCheckbox } from 'antd'
import styled from '@emotion/styled'

export const Checkbox = styled(AntdCheckbox)`
  margin-bottom: 16px;
  .ant-checkbox-inner {
    border-radius: 4px;
  }
`

export const CheckboxGroup = styled(AntdCheckbox.Group)`
  &.ant-checkbox-group {
    .ant-checkbox-wrapper {
      line-height: 16px;
      /* margin-bottom: 16px; */
      .ant-checkbox {
        & + span {
          font-weight: 500;
          color: rgba(23, 23, 29, 0.8);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .ant-checkbox-inner {
          border-radius: 4px;
        }
      }
    }
  }
` as typeof AntdCheckbox.Group
