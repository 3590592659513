import type { CSSProperties } from 'react'
import React, {
  useState,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
  memo,
  useEffect,
} from 'react'

import { message, Spin } from 'antd'
import classNames from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { insertAtCursor } from '@/features/datastore/utils'
import type {
  DatastoreMediaUploadProps,
  DatastoreMediaUploadRef,
} from '@/pages/datastores/components/DatastoreMediaUpload'
import DatastoreMediaUpload from '@/pages/datastores/components/DatastoreMediaUpload'
import { Progress } from '@/components'
import SplitMark from './SplitMark'
import { parseString, splitArray } from './utils'

// 将样式提取为常量，确保textarea和div样式一致
const editorStyle: CSSProperties = {
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  margin: 0,
  fontSize: '14px',
  lineHeight: '22px',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  wordBreak: 'break-all',
  border: 'none',
  outline: 'none',
}
export enum DatastoreBaseEditorFeatures {
  Split = 'split',
  Highlight = 'highlight',
  Upload = 'upload',
}

// 基础类型
interface BaseEditorProps {
  value: string
  onChange: (newText: string) => void
  enable: boolean
  className?: string
  placeholder?: string
}

// 拆分功能类型
interface SplitFeatureProps {
  features: DatastoreBaseEditorFeatures[]
  onSplit: (parts: [string, string]) => void
}

// 高亮类型
interface HighlightFeatureProps {
  features: DatastoreBaseEditorFeatures[]
}

interface UploadFeatureProps {
  features: DatastoreBaseEditorFeatures[]
  uploadOptions: {
    uploadDir: string
    file_id: number
    className?: string
  }
}

export function createDataBaseEditorFeatures<
  T extends DatastoreBaseEditorFeatures[],
>(features: T) {
  return features
}

// eslint-disable-next-line prettier/prettier
type FeatureProps<T extends DatastoreBaseEditorFeatures[]> = 
  // eslint-disable-next-line prettier/prettier
  (DatastoreBaseEditorFeatures.Split extends T[number] ? SplitFeatureProps : {}) &
    // eslint-disable-next-line prettier/prettier
  (DatastoreBaseEditorFeatures.Highlight extends T[number] ? HighlightFeatureProps : {}) &
    // eslint-disable-next-line prettier/prettier
  (DatastoreBaseEditorFeatures.Upload extends T[number] ? UploadFeatureProps : {})

type DatastoreBaseEditorProps<T extends DatastoreBaseEditorFeatures[]> =
  BaseEditorProps & {
    features: T
  } & FeatureProps<T>

interface BaseRef {
  focus: () => void
}
export type DatastoreBaseEditorRef<T extends DatastoreBaseEditorFeatures[]> =
  DatastoreBaseEditorFeatures.Upload extends T[number]
    ? DatastoreMediaUploadRef & BaseRef
    : BaseRef

const TextLine = memo(
  ({
    value,
    showMark,
    showHighlight,
    onSplit,
  }: {
    value: string
    showMark: boolean
    onSplit: () => void
    showHighlight: boolean
  }) => {
    const highlight = showHighlight && parseString(value)
    return (
      <>
        <div className='min-h-22px line-height-22px'>
          {highlight
            ? highlight?.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={classNames('line-height-22px', {
                      'bg-#E5F4FF': item.type === 'image',
                      'bg-#FFEDF3': item.type === 'video',
                      'bg-#E5E7FF': item.type === 'link',
                    })}
                  >
                    {item.content}
                  </span>
                )
              })
            : value}
        </div>
        {showMark && (
          <div
            onClick={() => onSplit()}
            className='absolute flex items-center h-1px w-[calc(100%+20px)]'
          >
            <SplitMark />
            <hr className='group-hover:opacity-100 opacity-0 absolute top-50% left-0 w-100% m-0px border-0px b-b-1px border-solid b-transparent' />
          </div>
        )}
      </>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value &&
      prevProps.showMark === nextProps.showMark &&
      prevProps.showHighlight === nextProps.showHighlight
    )
  },
)

function hasFeature<
  T extends DatastoreBaseEditorFeatures[],
  D extends DatastoreBaseEditorFeatures,
>(
  props: DatastoreBaseEditorProps<T>,
  feature: D,
): props is DatastoreBaseEditorProps<T> & FeatureProps<D[]> {
  return props.features.includes(feature)
}

const DatastoreBaseEditor = forwardRef(
  <T extends DatastoreBaseEditorFeatures[]>(
    props: DatastoreBaseEditorProps<T>,
    ref: React.Ref<DatastoreBaseEditorRef<T>>,
  ) => {
    const { enable, placeholder = '在这里输入文本...', className } = props

    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const datastoreMediaUploadRef = useRef<DatastoreMediaUploadRef>(null)
    const divRef = useRef<HTMLDivElement>(null)

    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    const syncScroll = () => {
      if (textareaRef.current && divRef.current) {
        const scrollTop = textareaRef.current.scrollTop
        const renderedDiv = divRef.current
        if (renderedDiv) {
          renderedDiv.scrollTop = scrollTop
        }
      }
    }

    useEffect(() => {
      const textarea = textareaRef.current
      const divBox = divRef.current
      if (textarea && divBox) {
        textarea.addEventListener('scroll', syncScroll)
      }
      return () => {
        if (textarea && divBox) {
          textarea.removeEventListener('scroll', syncScroll)
        }
      }
    }, [])

    useImperativeHandle(ref, () => {
      if (hasFeature(props, DatastoreBaseEditorFeatures.Upload)) {
        return {
          ...datastoreMediaUploadRef?.current,
          focus: () => textareaRef?.current?.focus(),
        } as DatastoreBaseEditorRef<T>
      }
      return {
        focus: () => textareaRef.current?.focus(),
      } as DatastoreBaseEditorRef<T>
    }, [ref, datastoreMediaUploadRef.current, textareaRef.current])

    // 处理输入变化
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newText = event.target.value
      props.onChange(newText)
    }

    const handleSplit = useMemoizedFn((index: number) => {
      const lines = props.value?.split('\n')
      const [left, right] = splitArray(lines, index + 1)
      if (hasFeature(props, DatastoreBaseEditorFeatures.Split)) {
        props.onSplit([left?.join('\n'), right?.join('\n')])
      }
    })

    const renderTextWithLines = useMemo(() => {
      const inputText = props?.value || ''
      const lines = inputText?.split('\n')
      return (
        <div className='whitespace-pre-wrap'>
          {lines.map((line, index) => (
            <TextLine
              key={index}
              value={line}
              showMark={
                hasFeature(props, DatastoreBaseEditorFeatures.Split)
                  ? index < lines.length - 1
                  : false
              }
              showHighlight={hasFeature(
                props,
                DatastoreBaseEditorFeatures.Highlight,
              )}
              onSplit={() => {
                handleSplit(index)
              }}
            />
          ))}
        </div>
      )
    }, [props?.value, props?.features, handleSplit])

    const uploadOpt = useMemo(() => {
      if (!hasFeature(props, DatastoreBaseEditorFeatures.Upload)) {
        return null
      }
      return {
        uploadDir: props?.uploadOptions?.uploadDir,
        onStart: () => {
          setLoading(true)
        },
        onSuccess: params => {
          const insertValue = `\n![${params?.file?.name}](${params?.url})\n`
          insertAtCursor(textareaRef?.current!, insertValue, props.onChange)
          message.success('文件上传完成')
          setLoading(false)
          setProgress(0)
        },
        onFail: () => {
          message.error('文件上传失败')
          setLoading(false)
          setProgress(0)
        },
        onProcess: params => {
          setProgress(params.percent)
        },
      } as DatastoreMediaUploadProps['uploadOptions']
    }, [
      (props as UploadFeatureProps)?.uploadOptions?.uploadDir,
      textareaRef?.current,
      props.onChange,
    ])

    const processNode = useMemo(() => {
      return (
        <div className='w-100% m-y-[-20px] flex-center '>
          <div className='w-240px flex flex-col -translate-x-1/2'>
            <p className='text-14px/22px c-font'>图片/视频上传中...</p>
            <Progress percent={progress} status='active' showInfo={false} />
          </div>
        </div>
      )
    }, [loading, progress])

    const spinNode = useMemo(() => {
      return (
        <Spin
          spinning={loading}
          className={classNames(
            'absolute flex items-center justify-center left-0 top-0 bottom-0 right-0 bg-[rgba(246,246,249,0.9)] z-400',
            (props as UploadFeatureProps)?.uploadOptions?.className,
            {
              hidden: !loading,
            },
          )}
          indicator={processNode}
        />
      )
    }, [
      processNode,
      loading,
      (props as UploadFeatureProps)?.uploadOptions?.className,
    ])

    const styles = useMemo(() => {
      return {
        position: 'relative',
        width: '100%',
        opacity: enable ? 1 : 0.4,
      } as CSSProperties
    }, [enable])

    return (
      <>
        {spinNode}
        <div
          ref={containerRef}
          className={classNames('group', className)}
          style={styles}
        >
          <div
            id='paragraph_editor__rendered-text'
            ref={divRef}
            className='c-font bg-transparent relative'
            style={editorStyle}
          >
            {renderTextWithLines}
          </div>
          <textarea
            ref={textareaRef}
            value={props.value}
            onChange={handleChange}
            className='op-70 bg-transparent caret-black overflow-hidden !left-0px resize-none absolute'
            style={editorStyle}
            placeholder={placeholder}
          />
        </div>
        {/* //暂时使用该写法 主要为了内部的上传方法 */}
        <div className='h-0! overflow-hidden'>
          {hasFeature(props, DatastoreBaseEditorFeatures.Upload) &&
            uploadOpt && (
              <DatastoreMediaUpload
                ref={datastoreMediaUploadRef}
                uploadOptions={uploadOpt}
                file_id={props.uploadOptions?.file_id}
              />
            )}
        </div>
      </>
    )
  },
)

export default DatastoreBaseEditor as <T extends DatastoreBaseEditorFeatures[]>(
  props: DatastoreBaseEditorProps<T> & {
    ref?: React.Ref<DatastoreBaseEditorRef<T>>
  },
) => React.ReactElement
