import styled from '@emotion/styled'
import { InputNumber, Slider } from 'antd'
import cn from 'classnames'

interface StepProps {
  sliderClassName?: string
  value?: number
  onChange?: (value: any) => void
  min?: number
  max?: number
  step?: number
  maxLength?: number
}

const StyledInputNumber = styled(InputNumber)`
  border-color: transparent;
  height: 24px;
  border-radius: 4px;
  transition: all 0.2s;
  width: 44px;
  font-size: 12px;
  text-align: center;

  .ant-input-number-input {
    height: 22px;
    padding: 0;
    text-align: center;
  }
  &.ant-input-number-focused {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: 'none';
  }
`

export function Steps({
  sliderClassName,
  value,
  onChange,
  min,
  max,
  step,
  maxLength,
}: StepProps) {
  return (
    <div className='flex justify-between items-center bg-bg_3 bg-opacity-8 b-rd-6px h-32px p-l-8px p-r-4px b-transparent b-1 b-solid transition-all-200 hover:b-primary'>
      <Slider
        className={cn('flex-1', sliderClassName)}
        min={min}
        max={max}
        value={typeof value === 'number' ? value : 0}
        step={step}
        onChange={onChange}
      />

      <StyledInputNumber
        min={min}
        controls={false}
        // bordered={false}
        maxLength={maxLength}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
