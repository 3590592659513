import type { FC } from 'react'
import { useRef } from 'react'
import { Button } from '@/components'
import { ShareQRCodeImage } from '@/features/agent/AgentGo/shareQRCode/ShareQRCodeImage.tsx'
import QRCodeBg from '@/assets/agent/qrcode-bg.png'
import {
  drawShareImageBg,
  drawShareImageAvatar,
  drawAgentName,
  drawQRCode,
  drawQRCodeTips,
  PixelMultiplier,
} from '@/features/agent/AgentGo/shareQRCode/utils.ts'

interface AgentGoWeChatOpenAppShareQRCodeProps {
  authAppName: string
  originId: string
  avatar: string
}

function drawTips(ctx: CanvasRenderingContext2D) {
  const borderRadius = 100 / PixelMultiplier
  const x = 45 * PixelMultiplier
  const y = 178 * PixelMultiplier
  const width = 286 * PixelMultiplier
  const height = 41 * PixelMultiplier

  ctx.beginPath()
  ctx.moveTo(x + borderRadius, y)
  ctx.lineTo(x + width - borderRadius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius)
  ctx.lineTo(x + width, y + height - borderRadius)
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - borderRadius,
    y + height,
  )
  ctx.lineTo(x + borderRadius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius)
  ctx.lineTo(x, y + borderRadius)
  ctx.quadraticCurveTo(x, y, x + borderRadius, y)
  ctx.closePath()
  ctx.fillStyle = 'rgba(98, 105, 153, 0.06)'
  ctx.fill()

  ctx.font = `400 ${14 * PixelMultiplier}px Arial`
  ctx.fillStyle = '#3F3F44'
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'

  ctx.fillText(
    '关注公众号，发消息就能和Agent对话',
    x + width / 2,
    y + height / 2 + 6,
  )
}

export const AgentGoWeChatOpenAppShareQRCode: FC<
  AgentGoWeChatOpenAppShareQRCodeProps
> = props => {
  const { authAppName, avatar, originId } = props

  const canvasEl = useRef<HTMLCanvasElement>(null)
  const qrCodeUrl = `${import.meta.env.VITE_AI_API_BASE_URL}/v1/agent/auth_app/weixin_mp/qr_code/${originId}`

  const popoverMainContent = (
    <div
      className='h-250px rounded-8px relative'
      style={{
        background:
          'radial-gradient(111% 111% at 0% 0%, #5029FF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(49% 25% at 100% 12%, rgba(123, 97, 255, 0.39) 0%, #61C2FF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(175deg, rgba(0, 43, 216, 0.08) 6%, rgba(61, 141, 255, 0.064) 49%, rgba(109, 80, 255, 0) 85%), #FAFAFC',
      }}
    >
      <img
        src={QRCodeBg}
        alt=''
        className='w-172px absolute bottom-8 left-0 right-0 m-auto'
      />
      <div className='w-full absolute z-1 h-full top-0 left-0 flex flex-col items-center'>
        <img src={avatar} alt='' className='rounded-full w-40px h-40px mt-22' />
        <span className='text-12px mt-10 font-600'>{authAppName}</span>
        <div className='font-600 w-154px h-21px flex-center  whitespace-nowrap c-#3F3F44 m-12 bg-bg_3 bg-op-6 rounded-100px'>
          <span className='text-12px scale-65 inline-block'>
            关注公众号，发消息就能和Agent对话
          </span>
        </div>
        <img
          className='w-100px h-100px mt--10px'
          src={qrCodeUrl}
          alt='qr-code'
        />
        <span className='text-12px font-400 scale-75'>
          微信扫一扫 或 长按识别二维码
        </span>
      </div>
    </div>
  )

  const drawShareImage = async () => {
    if (canvasEl.current) {
      const ctx = canvasEl.current.getContext('2d')
      if (ctx) {
        const canvasWidth = canvasEl.current.width
        const canvasHeight = canvasEl.current.height
        await drawShareImageBg(ctx, canvasWidth, canvasHeight)
        await drawShareImageAvatar(ctx, avatar, canvasWidth)
        drawAgentName(ctx, authAppName, canvasWidth, 136)
        drawTips(ctx)
        await drawQRCode(ctx, qrCodeUrl, canvasWidth, 170, 170, 242)
        drawQRCodeTips(ctx, canvasWidth, 440)
      }
    }
  }

  return (
    <ShareQRCodeImage
      canvasRef={canvasEl}
      qrCodeUrl={qrCodeUrl}
      drawShareImage={() => drawShareImage()}
      popoverMainContent={popoverMainContent}
    >
      <Button size='small' type='primary' className='vertical-top mr-8'>
        去使用
      </Button>
    </ShareQRCodeImage>
  )
}
