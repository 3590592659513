import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getUser, type User } from '@/apis/user'
import { useIdStorage } from '@/utils/storage'

interface Store {
  user?: User
  updateUser: (updateData: User | undefined) => void
  fetchUser: () => Promise<User | undefined>
}

export const useUserStore = create<Store>(set => ({
  user: undefined,
  updateUser: updateData => set(() => ({ user: updateData })),
  fetchUser: async () => {
    const res = await getUser()
    set({ user: res })
    useIdStorage.set(String(res.userId))
    return res
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('userStore', useUserStore)
}
