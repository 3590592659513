import { Global, css } from '@emotion/react'
import { Popover } from 'antd'
import { useContext, useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import { IconFont, Button } from '@/components'
import { DataStoreContext } from '@/pages/datastores/provider/DataStoreProvider'
import { fetchEmbeddingModelList } from '@/apis/datastore/api'

export function GLobalSelectStyle() {
  return (
    <Global
      styles={css`
        .AssociatedAgentPopover {
          .ant-popover-inner {
            padding: 0 !important;
          }
        }
      `}
    />
  )
}

export default () => {
  const [open, setOpen] = useState(false)
  const { dataStoreInfo } = useContext(DataStoreContext)
  const { data: embeddingModelList } = useRequest(fetchEmbeddingModelList)

  const numberOfCharacters = useMemo(() => {
    const value = dataStoreInfo?.total_text_length
    if (!value) {
      return '-'
    }
    return `${(value / 1000).toFixed(1)}k`
  }, [dataStoreInfo?.total_text_length])

  const partitionSize = useMemo(() => {
    return `${dataStoreInfo?.partition_size}MB` || '-'
  }, [dataStoreInfo?.partition_size])

  const embeddingModelInfo = useMemo(() => {
    const currentItem =
      embeddingModelList?.find(
        item => dataStoreInfo?.partition_model === item.model_name,
      ) || {}
    const icon = currentItem.icon || {}
    return {
      name: currentItem?.model_display_name,
      icon,
    }
  }, [dataStoreInfo?.partition_model, embeddingModelList])
  return (
    <>
      <GLobalSelectStyle />
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger='click'
        placement='bottomLeft'
        rootClassName='AssociatedAgentPopover'
        content={
          <div className='w-344px box-border'>
            <div
              className='px-20px py-16px flex-items-center flex justify-between'
              style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
            >
              <p>知识库详情</p>
              {/* <IconFont
                name='guanbi'
                className='cursor-pointer text-16px'
                onClick={() => setOpen(false)}
              /> */}
            </div>
            <div className='p-24px text-14px line-height-22px'>
              {/* <div className='flex items-center'>
                <p className='w-80px font-600 text-14px c-#8D8D99'>段落数</p>
                <p className='ml-24px c-#17171D'>2050</p>
              </div> */}
              <div className='flex items-center'>
                <p className='w-80px  font-600 text-14px c-#8D8D99'>字符数</p>
                <p className='ml-18px c-#17171D'>{numberOfCharacters}</p>
              </div>
              <div className='flex items-center mt-16px'>
                <p className='w-80px  font-600 text-14px c-#8D8D99'>
                  知识库大小
                </p>
                <p className='ml-18px c-#17171D'>{partitionSize}</p>
              </div>
              <div className='flex items-center mt-16px'>
                <p className='w-80px  font-600 text-14px c-#8D8D99'>
                  Embedding
                </p>
                <div className='ml-18px flex items-center'>
                  <img
                    src={embeddingModelInfo.icon}
                    className='w-20px h-20px'
                  />
                  <p className='ml-8px c-#17171D'>{embeddingModelInfo.name}</p>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <Button
          onClick={e => e.stopPropagation()}
          className='!w-36px'
          icon={<IconFont name='botxiangqing' className='c-#17171d' />}
        ></Button>
      </Popover>
    </>
  )
}
