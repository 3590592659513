import { Form } from 'antd'
import { useMemo, type ChangeEventHandler } from 'react'
import { useRequest } from 'ahooks'
import { codeRules, mobileReg, mobileRules } from '../constant'
import { Button, IconFont, Input } from '@/components'
import { updatePasswordWithVerifyCode } from '@/apis/user'
import { useSendVerifyCode } from '@/hooks/useSendVerifyCode'
import { passwordValidateRegexp } from '@/constants/common'
import { LoginTextButton } from './LoginButton'
import { StyledInputPassword } from './PasswordLoginForm'

export interface PasswordResetFormParams {
  phone: string
  verifyCode: string
  password: string
  confirmPassword: string
}

interface PasswordReserFormProps {
  onSuccess: () => void
}

export function PasswordReserForm(props: PasswordReserFormProps) {
  const { onSuccess } = props
  const [form] = Form.useForm<PasswordResetFormParams>()
  const { sending, isSendingSMS, verifyButtonText, onSendVerifyCode } =
    useSendVerifyCode()
  const phoneValue = Form.useWatch('phone', form)

  const sendDisabled = useMemo(() => {
    return isSendingSMS || !mobileReg.test(phoneValue)
  }, [isSendingSMS, phoneValue])

  const { runAsync: resetPasswordApi, loading } = useRequest(
    updatePasswordWithVerifyCode,
    { manual: true },
  )

  const handleChangeUserName: ChangeEventHandler<HTMLInputElement> = e => {
    // 禁止空格
    form.setFieldValue('phone', e.target.value.replace(/\s/g, ''))
  }

  const handleSendVerifyCode = async () => {
    const { phone } = await form.validateFields(['phone'])
    onSendVerifyCode({ phone })
  }

  const handleReset = async () => {
    const values = await form.validateFields()
    const { phone, verifyCode, password, confirmPassword } = values
    if (password === confirmPassword) {
      await resetPasswordApi({
        phone,
        verifyCode,
        password,
      })
      onSuccess()
    }
  }

  return (
    <>
      <Form form={form} layout='vertical' requiredMark={false}>
        <Form.Item name='phone' label='手机号' required rules={mobileRules}>
          <Input
            size='large'
            type='text'
            placeholder='输入手机号'
            maxLength={11}
            onChange={handleChangeUserName}
          />
        </Form.Item>
        <Form.Item name='verifyCode' label='验证码' required rules={codeRules}>
          <Input
            size='large'
            placeholder='输入验证码'
            maxLength={6}
            suffix={
              isSendingSMS ? (
                <span>{verifyButtonText}</span>
              ) : (
                <LoginTextButton
                  type='text'
                  disabled={sendDisabled}
                  className='w-102px rd-8px! mr--14px'
                  loading={sending}
                  onClick={handleSendVerifyCode}
                >
                  {verifyButtonText}
                </LoginTextButton>
              )
            }
          />
        </Form.Item>
        <Form.Item
          name='password'
          label='新密码'
          required
          rules={[
            {
              required: true,
              message: '密码输入错误，请输入6-16位密码（数字、字母）',
            },
            {
              pattern: passwordValidateRegexp,
              message: '密码输入错误，请输入6-16位密码（数字、字母）',
            },
          ]}
        >
          <StyledInputPassword
            size='large'
            type='password'
            placeholder='输入6-16位数字或字母组成的新密码'
            iconRender={visible =>
              visible ? (
                <IconFont className='text-font_1' name='eye-show' />
              ) : (
                <IconFont className='text-font_1' name='eye-hide' />
              )
            }
          />
        </Form.Item>
        <Form.Item
          name='confirmPassword'
          label='确认新密码'
          required
          rules={[
            {
              required: true,
              message: '密码输入错误，请输入6-16位密码（数字、字母）',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('两次输入密码不一致'))
              },
            }),
          ]}
        >
          <StyledInputPassword
            size='large'
            type='password'
            placeholder='再次输入新密码'
            iconRender={visible =>
              visible ? (
                <IconFont className='text-font_1' name='eye-show' />
              ) : (
                <IconFont className='text-font_1' name='eye-hide' />
              )
            }
          />
        </Form.Item>
      </Form>
      <Button
        className='mt-40px'
        type='primary'
        block
        size='large'
        loading={loading}
        onClick={handleReset}
      >
        重置密码
      </Button>
    </>
  )
}
