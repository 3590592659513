import { memo, useMemo, useState } from 'react'
import { Table, type TablePaginationConfig, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useRequest } from 'ahooks'
import { getAgentChatHistory } from '@/apis/agent'

// import { getFlowExecuteLog } from '@/apis/flow'
import { agentRunTypesMap } from '@/pages/agent/components/Logs'
import {
  LoggerTitle,
  LoggerModal,
} from '@/features/logger/components/loggerModal'

// import {
//   LoggerTitle,
//   NewLoggerModal,
// } from '@/features/logger/components/loggerModal'
// import { useGoDraftPageWithInput } from '@/pages/flowPage/hooks/useGoDraftPageWithInput'

import { Empty } from '@/features/nodes/components/Empty'
import { useTimeRangeParams } from '../../hooks/useTimeRangeParams'
import { LogModal } from '@/pages/agent/components/LogModal'
import { getFlowExecuteLog } from '@/apis/flow'
import type { LogItem } from '@/apis/run'
import { useGoDraftPageWithInput } from '@/pages/flowPage/hooks/useGoDraftPageWithInput'
import { StatisticsFlowType } from '..'
import { getLog } from '@/apis/run'
import { FlowRunTypeMap } from '@/features/analyze/constants'
import type { StabilityTimeParams } from './StabilityDetails'

interface ErrorDetailTableProps {
  record: any
  runType: string[]
  timeParams?: StabilityTimeParams | undefined
  filterParams?: any
  flowType: StatisticsFlowType
}

function InternalErrorDetailTable(props: ErrorDetailTableProps) {
  const { record, runType, timeParams, filterParams, flowType } = props
  const { flow_id } = record
  const { start_time, end_time } = useTimeRangeParams({
    timeParams,
    filterParams,
  })
  const { runAsync: fetchFlowLogDetail, data: FlowlogData } = useRequest(
    getFlowExecuteLog,
    { manual: true },
  )
  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  })
  const [recordId, setRecordID] = useState('')
  const [chatAgentModal, setChatAgentModal] = useState(false)
  const [flowLogModalOpen, setFlowLogModalOpen] = useState(false)
  const { goDraftPage } = useGoDraftPageWithInput()
  const tableTimeItem = useMemo(
    () =>
      flowType === StatisticsFlowType.AGENT ? 'create_time' : 'created_at',
    [flowType],
  )
  const { data: logsData, loading } = useRequest(
    () =>
      getAgentChatHistory({
        robot_id: flow_id,
        page_no: tableParams.current || 1,
        page_size: tableParams.pageSize || 10,
        record_status: 'FAILED',
        run_type: runType,
        start_time,
        end_time,
      }),
    {
      refreshDeps: [flow_id, tableParams],
      ready: !!flow_id && flowType === StatisticsFlowType.AGENT,
    },
  )
  const { data: flowErrorsData } = useRequest(
    () =>
      getLog({
        flowId: flow_id,
        pageNo: tableParams.current || 1,
        pageSize: tableParams.pageSize || 10,
        runTypes: runType,
        startTime: start_time,
        endTime: end_time,
        runStatus: 'FAILED',
      }),
    {
      refreshDeps: [flow_id, tableParams],
      ready: !!flow_id && flowType === StatisticsFlowType.FLOW,
    },
  )
  return (
    <>
      {(logsData?.data || flowErrorsData?.data || [])?.length > 0 || loading ? (
        <Table
          size='small'
          loading={loading}
          columns={[
            {
              title: '时间',
              dataIndex: tableTimeItem,
              ellipsis: true,
              render: value => {
                const time = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
                return <Tooltip title={time}>{time}</Tooltip>
              },
            },
            {
              title: '用户',
              dataIndex: 'user_name',
            },
            {
              title: '运行方式',
              key: 'run_type',
              ellipsis: true,
              render: (_, { run_type }) => {
                const _run_type =
                  flowType === StatisticsFlowType.AGENT
                    ? agentRunTypesMap[run_type]?.label
                    : FlowRunTypeMap[run_type]
                return <Tooltip title={_run_type}>{_run_type}</Tooltip>
              },
            },
            {
              title: '操作',
              key: 'operation',
              render: (_, record) => {
                return (
                  <div
                    className='c-#7b61ff cursor-pointer'
                    onClick={async () => {
                      if (flowType === StatisticsFlowType.AGENT) {
                        setRecordID(record.record_id)
                        setChatAgentModal(true)
                      } else {
                        fetchFlowLogDetail({
                          flowId: record.flow_id,
                          taskId: record.task_id,
                        })
                        setFlowLogModalOpen(true)
                      }
                    }}
                  >
                    查看详情
                  </div>
                )
              },
            },
          ]}
          pagination={{
            ...tableParams,
            total: logsData?.total || flowErrorsData?.total,
          }}
          dataSource={logsData?.data || flowErrorsData?.data || []}
          scroll={{ y: 300 }}
          onChange={setTableParams}
        />
      ) : (
        <Empty />
      )}
      {chatAgentModal && (
        <LogModal
          recordId={recordId}
          position='fixed'
          onClose={() => {
            setChatAgentModal(false)
          }}
        />
      )}

      {flowLogModalOpen && FlowlogData && (
        <LoggerModal
          defaultTab='output'
          title={
            <LoggerTitle
              time={FlowlogData.start_time}
              status={FlowlogData.run_status}
            />
          }
          flowId={flow_id}
          taskId={FlowlogData.task_id}
          onClose={() => {
            setFlowLogModalOpen(false)
          }}
          onReRun={item => {
            goDraftPage(item as unknown as LogItem)
          }}
        />
      )}
    </>
  )
}

export const ErrorDetailTable = memo(InternalErrorDetailTable)
