import EmptyImage from '@/assets/dataset/empty@2x.png'

export function Empty() {
  return (
    <div className='text-12px line-height-20px text-center flex flex-col items-center my-48px'>
      <img className='w-80px h-80px' src={EmptyImage} alt='' />
      <span className='text-font_2 mt-8px font-400'>无搜索结果</span>
    </div>
  )
}
