import { useMemo, type CSSProperties } from 'react'
import type { HandleProps } from 'reactflow'
import { Handle } from 'reactflow'
import { isEqual, omit } from 'lodash-es'
import { useFlowDraftStore, useFlowInteractionStore } from '@/store'
import { useSelectNode } from '@/features/nodes/base/hooks'
import { generateUniqueHandleStateId } from '../utils/handle'

export enum HandleStatus {
  NORMAL = 'NORMAL',
  TO_BE_REMOVED = 'TO_BE_REMOVED',
  EDGE_HOVERING = 'EDGE_HOVERING',
}

export const statusStyles = {
  [HandleStatus.NORMAL]: {
    borderColor: '#c5c5cc',
  },
  [HandleStatus.TO_BE_REMOVED]: {
    borderColor: '#ff5219',
    background:
      'linear-gradient(0deg, rgba(255, 82, 25, 0.4), rgba(255, 82, 25, 0.4)), #FFFFFF',
  },
  [HandleStatus.EDGE_HOVERING]: {
    borderWidth: 2,
    borderColor: '#7b61ff',
    background:
      'linear-gradient(0deg, rgba(123, 97, 255, 0.2), rgba(123, 97, 255, 0.2)), #FFFFFF',
  },
}

export const normalStyle = {
  top: 18,
  width: 10,
  height: 10,
  borderWidth: 1,
  borderStyle: 'solid',
  background: 'linear-gradient(0deg, #DADBE7, #DADBE7), #FFFFFF',
  borderRadius: '50%',
  cursor: 'pointer',
}

export interface DefaultHandleProps extends HandleProps {
  nodeId: string
  style?: Partial<CSSProperties>
}

export function DefaultHandle(props: DefaultHandleProps) {
  const { nodeId, style, type } = props
  const { dangerStateHandles, activeHandles } = useFlowInteractionStore(
    s => ({
      dangerStateHandles: s.dangerStateHandles,
      activeHandles: s.activeHandles,
    }),
    isEqual,
  )
  const pasteStatus = useFlowDraftStore(s => s.pasteStatus)
  const { targetNode, sourceNode } = useSelectNode()

  const stateId = useMemo(
    () =>
      generateUniqueHandleStateId({
        nodeId,
        sourceHandle: props.id,
        type,
      }),
    [nodeId, props.id, type],
  )

  const handleStatus = useMemo(() => {
    const isDanger = dangerStateHandles.includes(stateId)
    const isHoverActive = activeHandles.includes(stateId)
    const isFirstEdge = nodeId === sourceNode?.id && type === 'source'
    const isSecondEdge = nodeId === targetNode?.id && type === 'target'

    if (isHoverActive || ((isFirstEdge || isSecondEdge) && pasteStatus)) {
      return HandleStatus.EDGE_HOVERING
    }
    if (isDanger) {
      return HandleStatus.TO_BE_REMOVED
    }
    return HandleStatus.NORMAL
  }, [targetNode, sourceNode, stateId, dangerStateHandles, activeHandles])

  const handleStyle = useMemo(
    () => ({
      ...normalStyle,
      ...statusStyles[handleStatus],
      ...style,
    }),
    [style, handleStatus],
  )

  return <Handle {...omit(props, 'nodeId', 'status')} style={handleStyle} />
}
