import { List, Switch } from 'antd'
import React from 'react'
import type { AgentDetailTable } from '@bty/chat-types'
import type { BindingTableEvents } from '../types'
import { IconFont } from '@/components'
import TableIcon from '@/assets/agent/table.svg?url'

export interface BindingTableListItemProps {
  data: AgentDetailTable
  onClick?: (table: AgentDetailTable) => void
  onUpdate: (tool_id: string, table_id: string) => void
  onEnableChange: BindingTableEvents['onEnableChange']
}

function InternalBindingTableListItem({
  data,
  onClick,
  onUpdate,
  onEnableChange,
}: BindingTableListItemProps) {
  return (
    <List.Item
      className='h-56px !p-0 cursor-pointer hover:bg-#F2F2F7'
      onClick={event => {
        event.stopPropagation()
        onClick?.(data)
      }}
    >
      <List.Item.Meta
        className='!items-center [&_.ant-list-item-meta-avatar]:ml-20 [&_.ant-list-item-meta-avatar]:!mr-12 [&_.ant-list-item-meta-title]:flex [&_.ant-list-item-meta-title]:items-center [&_.ant-list-item-meta-title]:!m-0'
        avatar={<img src={TableIcon} />}
        title={<span>{data.title}</span>}
      />
      <div className='flex items-center pr-16'>
        <span
          className='w-28px h-28px mr-24 text-16px text-[rgba(98,105,153,0.6)] flex-center cursor-pointer hover:text-primary'
          onClick={event => {
            event.stopPropagation()
            onUpdate(data.id, data.table_id)
          }}
        >
          <IconFont name='kongjianshezhi' />
        </span>
        <Switch
          size='small'
          defaultChecked={data.is_enable}
          onChange={(checked, event) => {
            event.stopPropagation()
            onEnableChange?.(data.id, checked)
          }}
        />
      </div>
    </List.Item>
  )
}

export const BindingTableListItem = React.memo(InternalBindingTableListItem)
