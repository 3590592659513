import { Steps, message } from 'antd'
import copy from 'copy-to-clipboard'
import { IconFont } from '@/components'

interface ToBStepsProps {
  price: string
  orderId: string
}

function genCopyText(orderId: string, price: string) {
  return `户名：斑头雁（杭州）智能科技有限责任公司
账号：5719 1667 5610 902
银行：招商银行股份有限公司杭州高教路支行
金额：￥${price}
备注：${orderId}`
}

export function ToBSteps({ price, orderId }: ToBStepsProps) {
  return (
    <Steps
      direction='vertical'
      className='ml-12'
      size='small'
      items={[
        {
          title: '对公转账',
          status: 'process',
          description: (
            <div className='w-456px mt-24px mb-12px'>
              <div className='flex items-center justify-between'>
                <p>转账信息</p>
                <div
                  onClick={() => {
                    copy(genCopyText(orderId, price))
                    message.success('复制成功')
                  }}
                  className='b-1px b-solid b-line b-opacity-80 rd-8px cursor-pointer text-12px flex-center w-60px h-28px'
                >
                  <IconFont name='copy' className='mr-4px text-font_1' />
                  <span>复制</span>
                </div>
              </div>
              <div className='bg-bg_3 bg-opacity-6 rd-8px p-16px mt-12px text-14px'>
                <div className='flex'>
                  <div className='mr-32px'>户名</div>
                  <div>斑头雁（杭州）智能科技有限责任公司</div>
                </div>
                <div className='flex mt-12'>
                  <div className='mr-32px'>账号</div>
                  <div>5719 1667 5610 902</div>
                </div>
                <div className='flex mt-12'>
                  <div className='mr-32px'>银行</div>
                  <div>招商银行股份有限公司杭州高教路支行</div>
                </div>
                <div className='flex mt-12'>
                  <div className='mr-32px'>金额</div>
                  <div className='text-font_red'> ￥{price}</div>
                </div>
                <div className='flex mt-12'>
                  <div className='mr-32px'>备注</div>
                  <div>{orderId}</div>
                </div>
              </div>
            </div>
          ),
        },
        {
          title: '提交凭证',
          status: 'process',
          description: (
            <div className='w-456px text-14px mt-16px'>
              <p className='leading-relaxed'>
                汇款完成之后，点击“提交凭证”填写汇款信息。我们在收到转账和
              </p>
              <p className='leading-relaxed'>
                您提交的凭证后，将会立即为您开通服务，感谢您的耐心等待
              </p>
              <p className='leading-relaxed'>
                （服务生效日期将从审核通过日期起计算）。
              </p>
            </div>
          ),
        },
      ]}
    />
  )
}
