import { useContext, useEffect, useRef, useState } from 'react'
import { useBeforeUnload, useMatch, useNavigate } from 'react-router-dom'
import { useMemoizedFn, useRequest } from 'ahooks'
import { FilePreview } from '@bty/ui-components'
import { message } from 'antd'
import download from 'downloadjs'

import {
  DocumentContext,
  DocumentProvider,
} from '@/pages/datastores/provider/DocumentProvider'
import Header from '@/pages/datastores/document-detail/Header.tsx'
import { Subpage } from '@/pages/datastores/document-detail/Subpage.tsx'
import { useParagraph } from '@/pages/datastores/hooks/useParagraph.ts'
import { SplitConfig } from '@/pages/datastores/document-detail/SplitConfig.tsx'
import { HitTest } from '@/pages/datastores/document-detail/HitTest.tsx'
import { useHitTest } from '@/pages/datastores/hooks/useHitTest.ts'
import HitList from '@/pages/datastores/document-detail/HitList'
import { DocumentStatus, DocumentType } from '@/apis/datastore/model.ts'
import {
  fileRetry,
  getQAGroupList,
  getSplitContent,
} from '@/apis/datastore/api.ts'
import { useSplitConfig } from '@/pages/datastores/hooks/useSplitConfig.ts'
import SplitPreviewList from '@/pages/datastores/document-detail/SplitPreviewList.tsx'

// import SourceFilePreview from '@/pages/datastores/document-detail/source-file-preview'
import { useOSSClient } from '@/hooks/useOSSClient'

import { getFileSuffixByMime } from '@/features/datastore/utils'
import { KNOWLEDGE_RELOAD } from '@/constants/message'
import { fileTypeMimeMap } from '@/features/datastore/constant'
import { pubSignal } from '@/hooks/useSignal'
import { Detail } from './Detail'

export const Routers = {
  Content: 'content',
  HitTest: 'hit-test',
  Split: 'split',
  SourcePreview: 'source-preview',
}

export const RouterNameMap = {
  [Routers.Content]: '手动分段',
  [Routers.HitTest]: '命中测试',
  [Routers.Split]: '重新分段',
  [Routers.SourcePreview]: '源文件预览',
}

function Layout() {
  const match = useMatch('/datastores/:workspaceId/:id/:documentId/:route')
  const id = match?.params.id
  const workspaceId = match?.params.workspaceId
  const route = match?.params.route ?? Routers.Content
  const navigate = useNavigate()
  const paragraphBox = useRef<HTMLDivElement | null>(null)
  const routeRef = useRef<string>(route)

  const filePreviewRef = useRef<any>()

  useEffect(() => {
    routeRef.current = route
  }, [route])

  const { ossClient } = useOSSClient({ business: 'dataset' })

  const {
    documentInfo,
    reprocessDocumentLoading,
    loading: fetchDocumentLoading,
    refreshDocumentInfo,
    onReprocessDocument,
    setDocumentInfo,
  } = useContext(DocumentContext)
  const { runAsync: retryFile } = useRequest(fileRetry, { manual: true })

  const onRetry = useMemoizedFn(() => {
    if (documentInfo?.file_id) {
      onReprocessDocument(() => retryFile(documentInfo.file_id))
    }
  })

  const paragraph = useParagraph(
    documentInfo?.file_id,
    documentInfo?.file_type === DocumentType.QA
      ? getQAGroupList
      : getSplitContent,
  )
  const histTest = useHitTest(Number(id), documentInfo?.file_id)
  const splitConfig = useSplitConfig()
  const [hitTestTrigger, setHitTestTrigger] = useState(false)
  const [splitPreviewTrigger, setSplitPreviewTrigger] = useState(false)

  useBeforeUnload(
    e => {
      if (paragraph.hasEdited) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    { capture: true },
  )

  const isLoading = fetchDocumentLoading || reprocessDocumentLoading

  const highlightPdfChunk = (position: any[]) => {
    const positions = {
      position: position.map((item: any) => {
        const page = item.page + 1
        const bbox = item.bbox
        const x1 = bbox[0]
        const y1 = bbox[1]
        const x2 = bbox[2]
        const y2 = bbox[3]
        return [page, x1, x2, y1, y2]
      }),
    }
    filePreviewRef.current?.highlightItem(positions)
  }

  const toContent = useMemoizedFn(() => {
    if (documentInfo) {
      navigate(
        `/datastores/${workspaceId}/${Number(id)}/${documentInfo.file_id}/${
          Routers.Content
        }${location.search}`,
      )
      setTimeout(() => {
        paragraphBox.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 0)
    }
  })

  const handledReset = useMemoizedFn(() => {
    setSplitPreviewTrigger(false)
  })

  const handleUpdate = useMemoizedFn(() => {
    if (window.top) {
      pubSignal(KNOWLEDGE_RELOAD, window.top)
    }
    // 更新之前清空数据
    paragraph.clean()
  })

  const checkFileExist = (url: string) => {
    ossClient?.useBucket(import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET)
    return ossClient?.head(url)
  }

  const onDownload = async (url: string) => {
    try {
      await checkFileExist(url)
      const fullUrl = ossClient?.signatureUrl(url)
      const response = download(fullUrl!)
      if (response instanceof XMLHttpRequest) {
        response.onerror = function () {
          message.error('下载失败')
        }
      }
    } catch (e) {
      message.error('文件不存在')
    }
  }

  const handleSignatureUrl = (url: string) => {
    ossClient?.useBucket(import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET)

    const type = getFileSuffixByMime(documentInfo?.mimetype || '') ?? 'txt'

    if (['xlsx', 'xls', 'doc', 'docx'].includes(type)) {
      return ossClient
        ?.signatureUrl(url, {
          process: 'doc/preview,print_1,copy_1,export_1',
        })
        .replace('oss-cn-hangzhou.aliyuncs.com', 'betteryeah.com')
    }
    return ossClient?.signatureUrl(url)
  }

  const hitTestListVisible = route === Routers.HitTest && hitTestTrigger
  const splitPreviewListVisible = route === Routers.Split && splitPreviewTrigger
  return (
    <div className='h-full'>
      <div
        className='h-full bg-bg rounded-12px flex flex-col relative overflow-hidden'
        id='document-layout'
      >
        <div className='flex flex-1 flex-col overflow-hidden'>
          {documentInfo && (
            <Header
              route={route}
              documentInfo={documentInfo}
              dataLoading={isLoading}
              workspaceId={workspaceId!}
              dataset_id={Number(id)}
              onTagsChange={refreshDocumentInfo}
              onRetry={onRetry}
              hasUnSaveData={paragraph.hasEdited}
              saveLoading={paragraph.saveLoading}
              onSave={paragraph.onSave}
              isEditing={paragraph.isEditing}
              handleGenerateAISummary={async () => {
                if (documentInfo?.file_id) {
                  // setDocumentInfo({
                  //   ...documentInfo,
                  //   status: DocumentStatus.AIProcess,
                  // })
                  await paragraph.handleGenerateAISummary()
                }
              }}
              toContent={() => {
                toContent()
                setHitTestTrigger(false)
                setSplitPreviewTrigger(false)
              }}
            />
          )}
          <div className='flex-1 flex flex-col overflow-hidden relative'>
            <div className='flex-1 flex overflow-hidden'>
              {documentInfo && ossClient && (
                <Subpage
                  width='50%'
                  isOpen={route === Routers.SourcePreview}
                  title={RouterNameMap[route]}
                  className='b-r b-bg'
                  onDownload={() => {
                    onDownload(documentInfo.oss_path)
                  }}
                  onBack={() => {
                    navigate(
                      `/datastores/${workspaceId}/${Number(id)}/${
                        documentInfo.file_id
                      }/${Routers.Content}${location.search}`,
                    )
                  }}
                >
                  {ossClient &&
                    (documentInfo.file_type === DocumentType.FILE ||
                      documentInfo.file_type === DocumentType.VIDEO) &&
                    documentInfo.mimetype !== fileTypeMimeMap.msg && (
                      <FilePreview
                        url={documentInfo.oss_path}
                        ossConfig={{
                          signatureUrl: handleSignatureUrl,
                          checkFileExist,
                        }}
                        ref={filePreviewRef}
                        extra={{ pdfType: 'highlight' }}
                        withOfficeService={false}
                        mimetype={documentInfo.mimetype}
                      />
                    )}
                </Subpage>
              )}
              <div className='flex-1 overflow-hidden mt-20px'>
                <Detail
                  scrollRef={paragraphBox}
                  paragraph={paragraph}
                  hidden={hitTestListVisible || splitPreviewListVisible}
                  loading={fetchDocumentLoading}
                  isHitTest={route === Routers.HitTest}
                  reprocessLoading={reprocessDocumentLoading}
                  documentInfo={documentInfo}
                  onRetry={onRetry}
                  setDocumentInfo={setDocumentInfo}
                  onListItemClick={(type: string, params: any) => {
                    if (route !== Routers.SourcePreview) {
                      return
                    }
                    if (type === 'highlight') {
                      highlightPdfChunk(params)
                    }
                  }}
                />
                {hitTestListVisible && (
                  <HitList
                    hitList={histTest.list}
                    costTime={histTest.costTime}
                    updateHitList={histTest.updateList}
                    loading={histTest.loading}
                  />
                )}
                {splitPreviewListVisible && (
                  <SplitPreviewList
                    list={splitConfig.previewList}
                    loading={splitConfig.previewLoading}
                  />
                )}
              </div>
              {documentInfo &&
                (documentInfo.status === DocumentStatus.Done ||
                  documentInfo.status === DocumentStatus.Warning ||
                  documentInfo.status === DocumentStatus.AIProcess) && (
                  <Subpage
                    width='34%'
                    className='b-l b-bg'
                    isOpen={
                      route === Routers.Split || route === Routers.HitTest
                    }
                    title={RouterNameMap[route]}
                    onBack={() => {
                      if (route === Routers.HitTest) {
                        setHitTestTrigger(false)
                      }
                      if (route === Routers.Split) {
                        setSplitPreviewTrigger(false)
                      }
                      toContent()
                    }}
                  >
                    {route === Routers.Split && (
                      <SplitConfig
                        split_config={documentInfo.split_config}
                        file_id={documentInfo.file_id}
                        fileType={documentInfo.file_type}
                        fileMimetype={documentInfo.mimetype}
                        onBack={() => {
                          if (routeRef.current === Routers.Split) {
                            toContent()
                            setSplitPreviewTrigger(false)
                          }
                        }}
                        onReset={handledReset}
                        onPreview={req => {
                          setSplitPreviewTrigger(true)
                          splitConfig.onPreview(req)
                        }}
                        onUpdate={handleUpdate}
                      />
                    )}
                    {route === Routers.HitTest && (
                      <HitTest
                        file_id={documentInfo.file_id}
                        status={documentInfo.status}
                        onSearch={async (...restParams) => {
                          setHitTestTrigger(true)
                          await histTest.onSearch(...restParams)
                        }}
                        hitLoading={histTest.loading}
                      />
                    )}
                  </Subpage>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function DocumentDetailLayoutWithProvider() {
  const match = useMatch('/datastores/:workspaceId/:id/:documentId/:page')
  const documentId = match?.params.documentId

  return (
    <DocumentProvider documentId={documentId!}>
      <Layout />
    </DocumentProvider>
  )
}
