import classNames from 'classnames'
import type { ReactNode } from 'react'

export interface SidebarMenuItemProps {
  collapsed: boolean
  active: boolean
  path: string
  label: ReactNode
  icon: ReactNode
  extra?: ReactNode
  onClick: (key: string) => void
}

export function SidebarMenuItem(props: SidebarMenuItemProps) {
  const { collapsed, path, active, label, icon, onClick, extra } = props

  return collapsed ? (
    <div
      className={classNames(
        'relative flex flex-col flex-center w-48px py-10px b-rd-8px cursor-pointer hover:bg-#fff hover:bg-op-60',
        {
          'bg-#fff font-500': active,
        },
      )}
      onClick={() => {
        onClick(path)
      }}
    >
      <div className='mb-8px'>{icon}</div>
      <span
        className={classNames(
          'flex-1 line-height-16px text-center text-#17171d',
          {
            'text-12px': collapsed,
          },
        )}
      >
        {label}
      </span>
      <div className='absolute top-1px right-16px translate-x-50%'>{extra}</div>
    </div>
  ) : (
    <div
      className={classNames(
        'flex flex-items-center shrink-0 w-224px h-48px px-12px b-rd-8px cursor-pointer hover:bg-#fff hover:bg-op-60',
        {
          'bg-#fff font-500': active,
        },
      )}
      onClick={() => {
        onClick(path)
      }}
    >
      <div className='pl-4px mr-12px'>{icon}</div>
      <span className='flex-1 text-#17171d'>{label}</span>
      {extra}
    </div>
  )
}
