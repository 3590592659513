import { useEffect } from 'react'

function isMac() {
  return /macintosh|mac os x/i.test(navigator.userAgent.toLowerCase())
}

const S_KEY_CODE = 83
export function useDisableSaveKey(callback?: () => void) {
  // 阻止 ctrl + s 行为的函数
  const preventCtrlS = (event: KeyboardEvent) => {
    if (
      (isMac() ? event.metaKey : event.ctrlKey) &&
      event.keyCode === S_KEY_CODE
    ) {
      // Mac上面是 command+S，其他平台是 ctrl+S
      callback?.()
      event.preventDefault()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', preventCtrlS)

    // 在组件卸载时移除事件监听器
    return () => window.removeEventListener('keydown', preventCtrlS)
  }, [])
}
