import { useReactFlow } from 'reactflow'
import { NodeType, PLUGIN_STATUS } from '@/features/nodes/base'
import { PluginNode } from '@/features/nodes/plugin'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { generateNodeName } from '@/features/nodes/utils'

export function useGenInsertPluginNode() {
  const { pluginList } = useNodeMetaStore()
  const { getNodes } = useReactFlow()

  const generateToBeInsertedPlugin = (id: string) => {
    const currentPlugin = pluginList.find(p => p.function_id === id)
    if (!currentPlugin) {
      console.error('找不到当前插件')
      return
    }
    const {
      output,
      package_name,
      action_type,
      icon,
      form_config,
      display_name,
      name,
    } = currentPlugin
    const inputs = form_config.reduce<Record<string, any>>((pre, cur) => {
      if (cur.value) {
        pre[cur.variableName] = cur.value
      }
      return pre
    }, {})
    const { meta } = PluginNode

    return {
      type: NodeType.PLUGIN,
      data: {
        ...meta.initialData,
        inputs,
        plugin_id: id,
        output,
        packageName: package_name,
        actionType: action_type,
        icon,
        plugin_status: PLUGIN_STATUS.ACTIVE,
        displayName: display_name,
        name: generateNodeName(getNodes(), meta.type, name),
        pluginName: name,
      },
    }
  }

  return {
    generateToBeInsertedPlugin,
  }
}
