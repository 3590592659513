import { message } from 'antd'
import type { Node } from 'reactflow'
import { useReactFlow } from 'reactflow'
import { useFlowDraftStore } from '@/store'

const validateMessage = '请确保节点必填项完整'

export function useFlowDraftValidate() {
  const { setViewport } = useReactFlow()
  const validateFlow = useFlowDraftStore(s => s.validateFlow)

  const setViewPort = (targetViewPortNode: Node) => {
    setViewport({
      x:
        document.body.clientWidth / 2 -
        (targetViewPortNode.width || 0) / 2 -
        targetViewPortNode.position.x,
      y: (targetViewPortNode.height || 0) / 2 - targetViewPortNode.position.y,
      zoom: 1,
    })
  }

  const validate = async () => {
    const { validated, firstFailedNode } = await validateFlow()
    if (!validated) {
      if (firstFailedNode) {
        setViewPort(firstFailedNode)
      }
      const name = firstFailedNode?.data.name
      message.warning(
        name ? `请确保【${name}】节点必填项完整` : validateMessage,
      )
      return false
    }
    return true
  }

  return {
    validate,
  }
}
