import { Tooltip, message } from 'antd'
import copy from 'copy-to-clipboard'
import { useApolloConfig } from '@/store/apolloConfig'

export function ShowKnowledgeReferenceComponent(props: {
  business?: 'flow' | 'agent'
}) {
  const { business = 'agent' } = props

  const { showKnowledgeReferencePromptConfig } = useApolloConfig()

  const onCopy = async (data: string) => {
    const tips = business === 'agent' ? '快捷指令复制成功' : '指令内容复制成功'
    try {
      await copy(data)
      message.success(tips)
    } catch (e) {
      message.error('复制失败')
    }
  }

  const handleCopy = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault()
    e?.stopPropagation()
    onCopy(showKnowledgeReferencePromptConfig)
  }

  return business === 'agent' ? (
    <p className='text-12px/18px c-#8D8D99 mt-8px'>
      在Agent【角色设定】中添加 prompt 规则
      <Tooltip
        title={
          <div className='cursor-pointer' onClick={handleCopy}>
            复制prompt规则内容
          </div>
        }
      >
        <span className='mx-4px c-#7B61FF cursor-pointer' onClick={handleCopy}>
          {'"展示知识下载链接"'}
        </span>
      </Tooltip>
      后，Agent会在回答中给出 Ai 参考知识的文档名称和下载链接
    </p>
  ) : (
    <p className='text-12px/18px c-#8D8D99 mt-8px'>
      在 LLM 节点中添加prompt规则
      <Tooltip
        title={
          <div className='cursor-pointer' onClick={handleCopy}>
            复制prompt规则内容
          </div>
        }
      >
        <span className='mx-4px c-#7B61FF cursor-pointer' onClick={handleCopy}>
          {'"输出知识链接网址"'}
        </span>
      </Tooltip>
      后，LLM 的输出中 会包含 Ai 参考知识的文档名称和下载链接。
    </p>
  )
}
