import { memo } from 'react'
import { Checkbox, Form, Slider, Switch } from 'antd'
import { FormItem } from '@/components'
import { WithQuickFillTextArea } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'

export const RequirementsForm = memo<{
  placeholderMap: Record<string, string>
}>(({ placeholderMap }) => {
  return (
    <>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='要求内容/任务'
        name='contentRequirements'
      >
        <Checkbox.Group className='gap-y-8 gap-x-24 [&_.ant-checkbox]:rd-4px! [&_.ant-checkbox-inner]:rd-4px!'>
          <Checkbox value='认真思考'>认真思考</Checkbox>
          <Checkbox value='禁止撒谎'>禁止撒谎</Checkbox>
          <Checkbox value='自定义要求'>自定义要求</Checkbox>
        </Checkbox.Group>
      </FormItem>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          prev.contentRequirements !== next.contentRequirements
        }
      >
        {form => {
          const contentRequirements =
            form.getFieldValue('contentRequirements') ?? []
          return (
            <FormItem
              className='mt--10px'
              hidden={!contentRequirements.includes('自定义要求')}
              label='自定义要求'
              name='customContentRequirements'
            >
              <WithQuickFillTextArea
                rows={4}
                className='resize-none!'
                placeholder={placeholderMap.customContentRequirements}
              />
            </FormItem>
          )
        }}
      </Form.Item>
      <FormItem noStyle>
        <div className='flex mb-24 items-center'>
          <span className='font-500'>输出思考过程</span>
          <FormItem noStyle name='showWorkings' valuePropName='checked'>
            <Switch className='ml-8' size='small' />
          </FormItem>
        </div>
      </FormItem>
      <FormItem noStyle>
        <div className='flex mb-24 items-center'>
          <span className='font-500'>再次检查答案</span>
          <FormItem noStyle name='checkAgain' valuePropName='checked'>
            <Switch className='ml-8' size='small' />
          </FormItem>
        </div>
      </FormItem>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='回答长度'
        name='outputLength'
      >
        <Slider
          dots
          min={1}
          max={3}
          marks={{
            1: <div className='mt-5'>短</div>,
            2: <div className='mt-5'>适中</div>,
            3: <div className='mt-5'>长</div>,
          }}
        />
      </FormItem>
    </>
  )
})

RequirementsForm.displayName = 'RequirementsForm'
