import styled from '@emotion/styled'
import type { FC, PropsWithChildren, ReactElement } from 'react'
import { cloneElement } from 'react'
import type { PopoverProps, TreeProps } from 'antd'
import {
  Checkbox as AntdCheckbox,
  Popover,
  Radio as AntdRadio,
  Tabs,
  Tree as AntdTree,
} from 'antd'
import type { FieldControl, FormSchema, MetaState } from '@bty/ui-components'
import cn from 'classnames'
import type { CheckboxGroupProps } from 'antd/es/checkbox'
import { IconFont, Input, Segmented, Select, TextArea } from '@/components'
import { FlowFileUpload } from '@/features/nodes/components/FieldsUpload'

export const CodeNodePanelWrapper = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 48px);
  overflow: hidden;
`
export const Handler = styled.div<{
  axis?: 'x' | 'y' | 'e'
  showLine: boolean
}>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  border: 0px solid red;

  ${({ axis, showLine }) => {
    switch (axis) {
      case 'x':
        return `
        height: 100%;
        cursor: ew-resize;
        border-left-width: 1px;
        border-right-width: ${showLine ? '1px' : '0px'};
        :hover{
          cursor: ew-resize;
          border-right-width: 1px;
        }
      `
      case 'e':
        return `
      right: 0;
      left: auto;
          height: 100%;
          cursor: ew-resize;
          border-right-width: 1px;
          border-left-width: ${showLine ? '1px' : '0px'};
          :hover{
            cursor: ew-resize;
            border-left-width: 1px;
          }
        `
      case 'y':
        return `
        width: 100%;
        cursor: ns-resize;
        border-top-width: 1px;
        border-bottom-width: ${showLine ? '1px' : '0px'};
        :hover{
          cursor: ns-resize;
          border-bottom-width: 1px;
        }
      `
      default:
        return ''
    }
  }}

  border-style: solid;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.18);

  :hover {
    border-color: ${props => props.theme.colors.primary};
  }

  border-color: ${props =>
    props.showLine ? props.theme.colors.primary : 'rgba(225, 225, 229, 0.6)'};

  &:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    right: -4px;
    left: -4px;
    background: transparent;
    // pointer-events: none;
  }
`

export const Overlay = styled.div<{ isDragging: string | boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ isDragging }) => {
    return `cursor: ${
      typeof isDragging === 'string' && isDragging.includes('Height')
        ? 'ns-resize'
        : 'ew-resize'
    }`
  }};
  z-index: 1000;
`
// export const StyledCodeEditor = styled(CodeEditor)`
//   * {
//     line-height: inherit;
//   }
// `

export const FieldFormItem: FC<
  PropsWithChildren<{
    schema: FormSchema
    control: FieldControl
    meta: MetaState
  }>
> = props => {
  const { control, children, meta, schema } = props

  const hasError = meta.errors.length > 0

  const ClonedChildren = cloneElement(children as ReactElement, {
    ...control,
    placeholder: schema.placeholder,
    disabled: meta.disabled,
    status: hasError ? 'error' : '',
    className: cn((children as ReactElement).props.className, schema.className),
    'aria-invalid': hasError ? 'true' : undefined,
  })

  return (
    <div className='mb-12px w-full box-border'>
      <div className='flex items-center mb-4px w-full pb-8'>
        <IconFont name='bianliang' className='text-15px color-#7B61FF' />
        <span className='text-14px font-500 ml-4px'>{schema.variableName}</span>
        <span className='text-14px color-#8D8D99 truncate'>
          （{schema.label}）
        </span>
      </div>
      <div className='w-full'>{ClonedChildren}</div>
      <div
        className={cn(
          'mt-4 flex flex-col op-0 h-0 transition-all-200 ease-in-out',
          {
            'h-auto! op-100!': hasError,
          },
        )}
      >
        {meta.errors.map((error, index) => (
          <div className='c-error text-12px pt-8px pb-12' key={error + index}>
            {error}
          </div>
        ))}
      </div>
      {hasError && <div className='mb--12px'></div>}
    </div>
  )
}

export const StyledTree = styled(AntdTree)<TreeProps>`
  && {
    background-color: transparent;

    .ant-tree-treenode {
      position: relative;
      padding: 0;
      line-height: 36px;
    }

    .ant-tree-switcher {
      line-height: 36px;
      display: flex;
      justify-content: flex-end;
      padding-right: 4px;

      .ant-tree-switcher-icon {
        display: flex;
        align-items: center;
      }
    }

    // .ant-tree-switcher.ant-tree-switcher-noop {
    //   z-index: 0;
    // }

    .ant-tree-node-content-wrapper {
      position: unset;
      line-height: 36px;

      &:hover {
        background-color: transparent;
      }
    }

    .ant-treenode-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 6px;
    }

    .ant-tree-node-selected {
      background-color: transparent;
      // .ant-treenode-mask {
      //   background-color: rgba(123, 97, 255, 0.08);
      // }
    }
  }
`

export const StyledPopover = styled(Popover)<PopoverProps>``

export const CodeNodeCheckBox = styled(AntdCheckbox)`
  &.ant-checkbox-wrapper {
    display: flex;
    flex: 1;
    height: 32px;
    align-items: center;
    padding: 8px 12px;
    border-radius: 6px;
    margin-bottom: 4px;

    &:hover {
      background: rgba(98, 105, 153, 0.08);
    }

    .ant-checkbox.ant-checkbox + span {
      font-weight: 400;
      font-size: 12px;
      color: rgba(23, 23, 29, 0.8);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .ant-checkbox-inner {
      border-radius: 4px;
    }

    .ant-checkbox .ant-checkbox-inner:after {
      transition: none;
    }
  }
`

export const CodeNodeCheckBoxGroup = styled(AntdCheckbox.Group)`
  &.ant-checkbox-group {
    display: block;
    width: 100%;
  }
` as <T = any>(
  props: CheckboxGroupProps<T> & React.RefAttributes<HTMLDivElement>,
) => ReactElement

export const CodeNodeRadioGroup = styled(AntdRadio.Group)`
  &.ant-radio-group {
    display: block;
  }
`

export const CodeNodeRadio = styled(AntdRadio)`
  &.ant-radio-wrapper {
    display: flex;
    flex: 1;
    height: 32px;
    align-items: center;
    padding: 8px 12px;
    border-radius: 6px;
    margin: 0 0 4px 0;

    &:hover {
      background: rgba(98, 105, 153, 0.08);
    }

    .ant-radio.ant-radio + span {
      font-weight: 400;
      font-size: 12px;
    }

    .ant-radio-wrapper .ant-radio-inner::after {
      &:hover {
        transition: none;
      }
    }
  }
`
export const CodeExampleTabs = styled(Tabs)`
  &.ant-tabs {
    .ant-tabs-nav {
      margin: 0 0 8px 0;
    }

    .ant-tabs-nav-wrap {
      margin-left: 16px;

      .ant-tabs-tab {
        padding: 10px 0;
      }
    }
  }
`

export const CodeExampleSegmented = styled(Segmented)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  .ant-segmented-item {
    .ant-segmented-item-label {
      min-height: 20px;
      line-height: 20px;
    }
  }
`

export const CodeLogSelect = styled(Select)`
  && {
    .ant-select-selector {
      background-color: #fff;
      border-color: rgba(225, 225, 229, 0.6);
    }
  }
`

export const CodeDebugInput = styled(Input)`
  & {
    background-color: #fff;
    border-color: rgba(225, 225, 229, 0.6);
  }
`

export const CodeDebugTextArea = styled(TextArea)`
  & {
    background-color: #fff;
    border-color: rgba(225, 225, 229, 0.6);
  }
`

export const CodeDebugSelect = styled(Select)`
  && {
    .ant-select-selector {
      background-color: #fff;
      border-color: rgba(225, 225, 229, 0.6);
    }

    &.ant-select-multiple {
      &.ant-select-sm {
        .ant-select-selector {
          padding: 4px;
        }
      }

      .ant-select-selection-item {
        border-radius: 4px;
        background: rgba(98, 105, 153, 0.08);
        align-items: center;
        margin: 0 4px 0 0;
        padding: 0 8px;
      }
    }

    &.ant-select-lg {
      .ant-select-selector {
        border-radius: 8px;

        .ant-select-selection-item {
          height: 32px;
        }
      }
    }
  }
`

export const CodeDebugFlowFileUpload = styled(FlowFileUpload)`
  & {
    .ant-upload-drag {
      background-color: #fff;
    }
  }
`
