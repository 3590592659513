import { memo } from 'react'
import { Form, Radio } from 'antd'
import { FormItem } from '@/components'
import { WithQuickFillTextArea } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'

export const OutputForm = memo<{
  placeholderMap: Record<string, string>
}>(({ placeholderMap }) => {
  return (
    <>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12 mb-16'
        label='输出格式类型'
        name='outputType'
      >
        <Radio.Group className='[&_.ant-radio-wrapper-in-form-item]:mr-24px!'>
          <Radio value='json'>JSON</Radio>
          <Radio value='table'>表格</Radio>
          <Radio value='text'>文本</Radio>
          <Radio value='example'>自定义</Radio>
        </Radio.Group>
      </FormItem>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.outputType !== next.outputType}
      >
        {form => {
          const outputType = form.getFieldValue('outputType')
          return (
            <>
              {outputType === 'example' && (
                <div className='font-400 mb-12'>填写示例输出格式：</div>
              )}
              <Form.Item
                noStyle
                name='outputExample'
                hidden={outputType !== 'example'}
              >
                <WithQuickFillTextArea
                  rows={5}
                  className='resize-none!'
                  placeholder={placeholderMap.outputExample}
                />
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
    </>
  )
})

OutputForm.displayName = 'OutputForm'
