import { useMemo, useState, useEffect, useCallback } from 'react'
import { useRequest } from 'ahooks'
import { getFlowVersionList, recoverVersion } from '@/apis/application'
import {
  FLOW_DRAFT_LOCK_STATUS,
  useApplicationStore,
  useFlowDraftStore,
} from '@/store'
import type { FlowDataItemType } from '../draft'

export function useSelectedVersion(
  isShowVersion: boolean,
  flowDataRes: any,
  fetchDraftFlow: () => void,
) {
  const { currentApplicationId } = useApplicationStore()
  const { runAsync: handleRecoverVersion } = useRequest(recoverVersion, {
    manual: true,
  })
  const { data: FlowVersionData, runAsync: onRunFlowVersionList } = useRequest(
    () => getFlowVersionList(currentApplicationId),
    {
      manual: true,
    },
  )
  const [selectedVersion, setSelectedVersion] = useState(-1)
  const setFlowLock = useFlowDraftStore(s => s.setFlowLock)
  const setConfig = useFlowDraftStore(s => s.setConfig)

  const onSelectedVersion = useCallback((version: number) => {
    setSelectedVersion(version)
  }, [])
  const SelectedItemData = useMemo(() => {
    return FlowVersionData?.find(
      (item: FlowDataItemType) => item.applicationVersion === selectedVersion,
    )
  }, [selectedVersion, FlowVersionData, isShowVersion])
  const onBackCurrent = async () => {
    setSelectedVersion(-1)
    await fetchDraftFlow()
    setConfig(flowDataRes?.config)
    setFlowLock(FLOW_DRAFT_LOCK_STATUS.UNLOCK)
  }
  useEffect(() => {
    setSelectedVersion(-1)
    setFlowLock(FLOW_DRAFT_LOCK_STATUS.UNLOCK)
  }, [FlowVersionData])
  return {
    selectedVersion,
    FlowVersionData,
    SelectedItemData,
    onSelectedVersion,
    onBackCurrent,
    onRunFlowVersionList,
    handleRecoverVersion,
  }
}
