import { LOGO, MINI_LOGO } from '@/constants/common.ts'

interface LogoProps {
  collapsed: boolean
}

export function Logo(props: LogoProps) {
  const { collapsed } = props

  return collapsed ? (
    <img src={MINI_LOGO} alt='betteryeahLogo' className='mx-auto w-28px' />
  ) : (
    <img className='w-173px' src={LOGO} alt='betteryeahLogo' />
  )
}
