import styled from '@emotion/styled'
import { memo, useMemo } from 'react'

import { Segmented, Tooltip, message } from 'antd'
import type { SegmentedValue } from 'antd/es/segmented'
import classNames from 'classnames'
import { useMemoizedFn } from 'ahooks'
import copy from 'copy-to-clipboard'
import { IconFont, Markdown } from '@/components'
import { parseCardData } from '../utils/parse'
import { KnowledgeOutput } from './KnowledgeCard'

const StyledSegmented = styled(Segmented)`
  border-radius: 8px;

  .ant-segmented-thumb,
  &.ant-segmented.ant-segmented-sm .ant-segmented-item {
    border-radius: 6px !important;
  }
` as typeof Segmented

interface StringContentProps {
  closeCopy?: boolean
  className?: string
  showCard?: boolean
  content: string
  bucketId?: string
  openInAgent?: boolean
  extraInfo?: { partition_id: string; datastore_id: string; [key: string]: any }
}

export const StringContent = memo(
  ({
    closeCopy,
    className,
    content,
    showCard,
    bucketId,
    openInAgent,
  }: StringContentProps) => {
    const knowledgeCards = useMemo(() => {
      if (!showCard) return null
      const cardList = parseCardData(content)
      if (cardList.some(each => !each.mimetype)) return
      if (!cardList.length) return null
      return <KnowledgeOutput contents={cardList} openInAgent={openInAgent} />
    }, [showCard, bucketId, content])
    return (
      <div className={className}>
        {knowledgeCards || <Markdown text={content} />}
        {!closeCopy && !knowledgeCards && (
          <div
            className={
              'absolute right-8 top-8 z-10 flex items-center justify-center cursor-pointer w-24px h-24px rounded-4px hover:bg-#626299 hover:bg-opacity-12'
            }
          >
            <IconFont
              name='copy'
              className='text-font_1 text-16px'
              onClick={() => {
                copy(content)
                message.success('已复制到剪切板')
              }}
            />
          </div>
        )}
      </div>
    )
  },
)

interface SwitchOutputDisplayProps {
  className?: string
  value: boolean
  onChange: (value: boolean) => void
}

export const SwitchOutputDisplay = memo(
  ({ className, value, onChange }: SwitchOutputDisplayProps) => {
    const handleChange = useMemoizedFn((value: SegmentedValue) => {
      onChange(!!value)
    })

    return (
      <StyledSegmented
        className={classNames(className, '!rounded-8px')}
        size='small'
        value={value ? 1 : 0}
        options={[
          {
            icon: (
              <Tooltip
                title='卡片式展示查询结果'
                placement='bottomRight'
                align={{ offset: [14, 12] }}
              >
                <IconFont className='text-14px mt-3px' name='qiapianluoshi1x' />
              </Tooltip>
            ),
            value: 1,
          },
          {
            icon: (
              <Tooltip
                title='查询结果的原始输出格式'
                placement='bottomRight'
                align={{ offset: [14, 12] }}
              >
                <IconFont className='text-14px mt-3px' name='yuanshiluoshi1x' />
              </Tooltip>
            ),
            value: 0,
          },
        ]}
        onChange={handleChange}
      />
    )
  },
)
