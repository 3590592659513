import styled from '@emotion/styled'
import { useCallback } from 'react'
import classNames from 'classnames'
import { CodeEditor } from '@/features/editor/CodeEditor'

import { FlowFileUpload } from '../../components/FieldsUpload'
import { useNodeMetaStore } from '@/store/nodeMeta'
import type { ISupportFileConfig } from '@/apis/flow'

const DynamicFormWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const VariableInputWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 18px;
  color: #17171d;
  flex-direction: column;
`

export const EmptyWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  justify-content: start;
  align-items: center;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  line-height: 32px;
  // background: rgb(243, 243, 247);
  padding-left: 7px;
`

const CodeEditorWrapper = styled(CodeEditor)<{ readOnly: boolean }>`
  background: 'rgb(243, 243, 247)';
  color: ${({ readOnly }) =>
    readOnly ? 'rgba(0, 0, 0, 0.5) !important' : 'inherit'};
  ${({ readOnly }) => (readOnly ? 'border-color: transparent !important' : '')};
`
export function DynamicForm(props: any) {
  const {
    usedKeyList,
    dynamicValues,
    onValueChange,
    readOnly,
    fileVariableKeyList = [],
    keyFieldTypes,
  } = props
  const supportFileConfigList = useNodeMetaStore(
    state => state.supportFileConfigList,
  )
  const checkIsFile = useCallback(
    (type: string) => {
      const info = {
        isFile: false,
        supportFileTypes: [] as string[],
        supportFileConfig: [] as ISupportFileConfig[],
      }

      fileVariableKeyList?.forEach((item: any) => {
        const pattern = /(\w+)(?=\[\d+\]\.?)/
        const match = type?.match(pattern)
        const matchType = match ? match?.[1] : type
        if (item.key === matchType) {
          info.isFile = true
          info.supportFileTypes = item?.supportFileTypes
        }
      })
      const currentFileTypeConfig = supportFileConfigList.filter(i =>
        info.supportFileTypes?.includes(i.type),
      )
      info.supportFileConfig = currentFileTypeConfig
      return info
    },
    [fileVariableKeyList],
  )
  return (
    <DynamicFormWrapper>
      <div className='text-12px line-height-12px color-#17171D font-500'>
        变量
      </div>
      <div className='content'>
        {usedKeyList.length ? (
          <div className='mt-4px'>
            {usedKeyList.map((item: any) => {
              const fileInfo = checkIsFile(item)
              if (fileInfo.isFile) {
                const defaultVal = dynamicValues[item]
                let defaultFile
                if (defaultVal) {
                  defaultFile = {
                    url: defaultVal,
                  }
                }
                return (
                  <div key={item.url}>
                    <div className='flex bg-#fff justify-start items-center align-mid min-w-140px align-mid text-12px line-height-16px py-8px'>
                      <span>{item}</span>
                    </div>
                    <div className='text-12px line-height-12px color-#17171D font-500'>
                      <FlowFileUpload
                        supportFileTypes={fileInfo?.supportFileTypes}
                        supportFileConfig={fileInfo?.supportFileConfig}
                        defaultFile={defaultFile}
                        onChange={publicUrl => {
                          onValueChange(item, publicUrl)
                        }}
                      />
                    </div>
                  </div>
                )
              }
              const mode = keyFieldTypes?.[item] ?? 'text'
              return (
                <VariableInputWrapper key={item}>
                  <div
                    className={classNames(
                      'flex bg-#fff justify-start items-center align-mid min-w-140px align-mid text-12px line-height-16px py-8px',
                    )}
                  >
                    <span>{item}</span>
                  </div>
                  <CodeEditorWrapper
                    readOnly={readOnly}
                    className={
                      'ace-gray important:w-100% important:line-height-18px'
                    }
                    value={
                      typeof dynamicValues[item] !== 'string'
                        ? JSON.stringify(dynamicValues[item], null, 2)
                        : dynamicValues[item]
                    }
                    mode={mode}
                    onChange={value => {
                      let output = value
                      if (mode === 'json') {
                        try {
                          output = JSON.parse(value)
                        } catch (error) {
                          // err
                        }
                      }
                      onValueChange(item, output)
                    }}
                    aria-invalid={
                      props['aria-invalid'] && !item.content
                        ? 'true'
                        : undefined
                    }
                    setOptions={{
                      minLines: 1,
                    }}
                  />
                </VariableInputWrapper>
              )
            })}
          </div>
        ) : (
          <div className='mt-8px mb-8px'>
            <EmptyWrapper>
              {/* {'暂无变量，提示词内出现{{ var }}时，会出现变量'} */}
              {'暂无变量'}
            </EmptyWrapper>
          </div>
        )}
      </div>
    </DynamicFormWrapper>
  )
}
