import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ExamplePC from '@/assets/agent/sdk-example-pc.png'
import ExampleMobile from '@/assets/agent/sdk-example-m.png'
import { IntegratedKeys } from '@/features/agent/components/IntegratedKeys.tsx'
import { CodeBlock, DocsCodeBlock } from '@/components/CodeBlock'
import { CODE_KEY_PLACEHOLDER } from '@/features/agent/constant/base.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { Markdown, Table } from '@/components'
import { PRODUCT_BASE_NAME } from '@/constants/common.ts'

const StyledTable = styled(Table)`
  .ant-table-cell {
    line-height: 20px;
  }
`

const InstallSdkByNPM = `npm install @bty/chat-sdk # npm
yarn add @bty/chat-sdk    # yarn
pnpm add @bty/chat-sdk    # pnpm
`

async function getInstallSdkByCDN() {
  const { data } = await axios.get(
    'https://registry.npmmirror.com/@bty/chat-sdk/latest',
  )
  return `<!--将下面的CDN地址贴入网站的HTML Header中-->
  <link rel="stylesheet" href="https://web-bty-sdk.oss-cn-hangzhou.aliyuncs.com/sdk/${data?.version}/chat-sdk.css">
  <script src="https://web-bty-sdk.oss-cn-hangzhou.aliyuncs.com/sdk/${data?.version}/chat-sdk.js"></script>
`
}

const UseSDK = `// 如果你是通过包管理器方式安装的SDK，你只需要按照下面的方式导入Chat模块使用即可
import { Chat as BetterYeahChat } from "@bty/chat-sdk"

// 如果是通过CDN方式安装的，你则需要像下面这样，从windows对象获取Chat模块
const BetterYeahChat = window.BetterYeah.Chat
`

const InitSdk = `import { Chat as BetterYeahChat } from "@bty/chat-sdk"

BetterYeahChat.init({
  root: "root",
  agentId: "${CODE_KEY_PLACEHOLDER.AGENT_ID}",
  accessKey: "${CODE_KEY_PLACEHOLDER.API_KEY}",
  workspaceId: "${CODE_KEY_PLACEHOLDER.WORKSPACE_ID}",
  user: {
    id: "小Y", // 用户的标识，会在${PRODUCT_BASE_NAME} AI Agent日志中显示，也可以用来标识Agent和用户的关联关系
  },
  features: {
    conversationList: true, // 显示会话列表
    uploadFile: true // 支持上传文件
  }
})
`

const CreateConversationId = `const conversationId = await BetterYeahChat.api.Conversation.create('会话标题') // 会话标题会显示在会话窗口的顶部
`

const RenderChat = `BetterYeahChat.mount({
  conversationId: '', //  「第二步」获取到的会话ID
})
`

export default function AgentGoSDK() {
  const { scrollRef } = useScrollBar()

  const [installSdkByCDN, setInstallSdkByCDN] = useState('')

  useEffect(() => {
    getInstallSdkByCDN().then(str => {
      setInstallSdkByCDN(str)
    })
  }, [])

  return (
    <div ref={scrollRef} className='w-full h-full p-24 overflow-y-auto'>
      <div className='font-500 text-20px line-height-32px'>SDK集成</div>
      <div className='mt-24 p-24 bg-white rounded-8px'>
        <div className='font-500'>样式示例</div>
        <div className='mt-12 flex gap-24 flex-wrap'>
          <img src={ExamplePC} className='w-320px' alt='PC端示例' />
          <img src={ExampleMobile} className='w-320px' alt='移动端示例' />
        </div>
        <IntegratedKeys />
        <div className='mt-24'>
          <div className='font-500 text-16px pt-10'>第一步：安装SDK</div>
          <div className='mt-18 font-400'>方式一：通过包管理器安装</div>
          <div className='mt-8 font-400 c-font_1'>
            此方式的SDK会在打包时会自动根据你的项目Tree
            Sharking，从而包体积会更小，Vite、Webpack5等新项目推荐用
          </div>
          <CodeBlock str={InstallSdkByNPM} lang='bash' />
          <div className='mt-18 font-400'>方式二：通过CDN方式安装</div>
          <div className='mt-8 font-400 c-font_1'>
            此方式集成简单，兼容性好，一般用于老项目接入
          </div>
          <CodeBlock str={installSdkByCDN} lang='html' />
          <div className='mt-18 font-400'>使用SDK</div>
          <CodeBlock str={UseSDK} lang='javascript' />
        </div>
        <div className='mt-24'>
          <div className='font-500 text-16px pt-10'>第二步：初始化SDK</div>
          <div className='mt-18 font-400'>
            SDK在使用过程中，初始化后才能使用，一般情况下只需要初始化一次
          </div>
          <DocsCodeBlock str={InitSdk} lang='javascript' />
          <div className='mt-18 font-400'>参数说明：</div>
          <div className='mt-18'>
            <StyledTable
              columns={[
                { key: 'name', title: '字段名', dataIndex: 'name' },
                { key: 'type', title: '类型', dataIndex: 'type' },
                {
                  key: 'desc',
                  title: '说明',
                  dataIndex: 'desc',
                  render: text => {
                    return <Markdown text={text} />
                  },
                },
              ]}
              pagination={false}
              dataSource={[
                {
                  name: 'root',
                  type: 'string',
                  desc: 'DOM ID，SDK会将聊天窗口渲染到这个DOM对应的容器内，你必须确保该DOM在SDK init执行阶段已经存在，比如在Vue中，你要确保在onMounted的钩子中执行init函数',
                },
                {
                  name: 'agentId',
                  type: 'string',
                  desc: '文档上方所显示的AgentID，SDK会初始化该ID对应的Agent',
                },
                {
                  name: 'accessKey',
                  type: 'string',
                  desc: '见文档上方，该Key是SDK访问的鉴权标识，请谨慎保存',
                },
                {
                  name: 'workspaceId',
                  type: 'string',
                  desc: '见文档上方，Agent所在的工作空间的ID',
                },
                {
                  name: 'user.name',
                  type: 'string',
                  desc: `「SDK使用者」的身份标识，会在${PRODUCT_BASE_NAME} AI Agent日志中显示，也可以用来标识Agent和「SDK使用者」的关联关系`,
                },
                {
                  name: 'features',
                  type: 'object',
                  desc: '可选参数，用于配置SDK的一些特性，目前支持的特性有：「上传文件」、「会话列表」、「语音输入」',
                },
                {
                  name: 'features.conversationList',
                  type: 'boolean',
                  desc: '是否支持「会话列表」，默认值false',
                },
                {
                  name: 'features.voice',
                  type: 'boolean',
                  desc: '是否支持「语音输入」，默认值false',
                },
                {
                  name: 'features.uploadFile',
                  type: 'boolean',
                  desc: '是否支持「上传文件」，默认值false',
                },
                {
                  name: 'features.toolsLogger',
                  type: 'boolean',
                  desc: '是否支持「查看技能日志」，默认值false',
                },
              ]}
            />
          </div>
        </div>
        <div className='mt-24'>
          <div className='font-500 text-16px pt-10'>第三步：创建会话ID</div>
          <div className='line-height-20px mt-18'>
            执行下面的代码会生成一个新的会话ID，注意该方法是一个异步函数
          </div>
          <CodeBlock str={CreateConversationId} lang='javascript' />
          <div className='line-height-20px mt-12'>
            会话Id是一次会话的唯一标识，如果你想下次进入之后还保留之前的聊天窗口，你应该持久化保存生成好的会话ID
          </div>
          <div className='line-height-20px mt-12'>
            如果你想每个用户对应不同的聊天窗口，你应该给不同的用户生成不同的会话ID，并且维护好该用户和生成的会话ID的关联关系
          </div>
        </div>
        <div className='mt-24'>
          <div className='font-500 text-16px pt-10'>第四步：渲染会话</div>
          <div className='line-height-20px mt-18'>
            执行下面的函数会在「第一步」配置的dom容器内渲染出$
            {PRODUCT_BASE_NAME} AI Agent的聊天页面
          </div>
          <CodeBlock str={RenderChat} lang='javascript' />
        </div>
      </div>
    </div>
  )
}
