import { v4 as uuidv4 } from 'uuid'
import type { Node, Edge } from 'reactflow'
import { getOutgoers } from 'reactflow'
import { cloneDeep } from 'lodash-es'
import type { ReplaceNodeData } from '@/store'
import type { LoopNodeData } from '@/features/nodes/loop'
import { customLoopPackageName, LoopResultNode } from '@/features/nodes/loop'
import { NodeType } from '@/features/nodes/base'
import {
  EMPTY_NODE_HEIGHT,
  NODE_WIDTH,
  SPACE_BETWEEN_NODES,
  getNextNode,
} from '../layout'
import {
  generateEdgeFromNodes,
  generateNodeRelation,
  isConditionNode,
  isIntentNode,
  isLoopNode,
  removeOuterRelation,
} from '../utils'
import { TO_BE_COPIED_ID } from '../constants'
import { generateNodeName } from '@/features/nodes/utils'
import { BaseNodeOperation } from './BaseNodeOperation'
import type { FlowConfigData, InsertConfigData } from './BaseNodeOperation'
import { findAllChildrenInNestedNode, getInheritedNodeEnableById } from './util'
import { SingleNodeOperation } from './SingleNodeOperation'
import { getOperationByNodeType } from '.'

export class LoopNodeOperation extends BaseNodeOperation {
  insert(options: {
    type: NodeType
    data?: any
    source: string
    target: string
    sourceHandleId?: string | null | undefined
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }): InsertConfigData {
    const {
      type,
      data,
      source,
      target,
      sourceHandleId,
      nodes,
      edges,
      nodesMap,
    } = options
    const config: InsertConfigData = {
      nodes,
      edges,
      newNode: null,
    }
    const sourceNode = nodesMap[source]
    const targetNode = nodesMap[target]

    // 合法性校验
    if (!sourceNode || !targetNode) {
      throw new Error('添加节点失败')
    }

    // 节点关系
    const relation = generateNodeRelation(
      nodes,
      edges,
      sourceNode,
      sourceHandleId,
    )

    // 生成主节点
    const mainNode = {
      type,
      id: uuidv4(),
      position: {
        x: sourceNode.position.x,
        y: sourceNode.position.y,
      },
      width: NODE_WIDTH,
      height: EMPTY_NODE_HEIGHT,
      selected: true,
      data: {
        ...data,
        relation: relation || {},
      },
    }
    // 生成循环结束节点
    const loopResultMeta = LoopResultNode.meta
    const resultNode = {
      type: loopResultMeta.type,
      id: uuidv4(),
      position: {
        x: mainNode.position.x,
        y: mainNode.position.y + 13,
      },
      data: {
        ...loopResultMeta.initialData,
        name: generateNodeName(nodes, mainNode.type, customLoopPackageName),
        actionType: loopResultMeta.actionType,
        relation: {
          loopNodeId: mainNode.id,
        },
      },
    }

    mainNode.data.relation.loopResultId = resultNode.id

    const boundaryEdge: Edge = {
      type: 'help',
      source: mainNode.id,
      target: resultNode.id,
      id: `loopBoundary_${mainNode.id}-${resultNode.id}`,
      sourceHandle: `loopBoundary_${mainNode.id}`,
      // animated: true,
      data: {},
    }

    config.edges = config.edges
      .filter(
        e =>
          e.id !==
          `${
            isConditionNode(sourceNode) || isIntentNode(sourceNode)
              ? mainNode.data.relation?.branchId
              : isLoopNode(sourceNode)
                ? `loopStart_${source}`
                : source
          }-${target}`,
      )
      .concat([
        generateEdgeFromNodes({
          source: sourceNode.id,
          target: mainNode.id,
          sourceHandle: sourceHandleId,
        }),
        generateEdgeFromNodes({
          source: mainNode.id,
          target: resultNode.id,
          sourceHandle: `loopStart_${mainNode.id}`,
        }),
        boundaryEdge,
        generateEdgeFromNodes({
          source: resultNode.id,
          target,
        }),
      ])
    config.nodes = config.nodes.concat([mainNode, resultNode])
    config.newNode = mainNode

    return config
  }

  remove(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
  ): FlowConfigData {
    const removingNode = nodesMap[nodeId] as Node<LoopNodeData>
    const resultId = removingNode.data.relation.loopResultId
    const sourceIds = edges
      .filter(edge => edge.target === removingNode.id)
      .map(e => e.source)
    const targetIds = edges
      .filter(edge => edge.source === resultId)
      .map(e => e.target)

    if (!sourceIds.length || !targetIds.length) {
      throw new Error('节点数据异常')
    }

    const source = nodesMap[sourceIds[0]]
    const sourceEdge = edges.find(
      e =>
        e.source === source.id &&
        e.target === removingNode.id &&
        e.type === 'insert',
    )

    const removingNodes = findAllChildrenInNestedNode(removingNode, nodes)

    const newEdges = edges
      .filter(e => {
        if (removingNodes.find(n => n.id === e.source || n.id === e.target)) {
          return false
        }
        return true
      })
      .concat([
        generateEdgeFromNodes({
          source: sourceIds[0],
          target: targetIds[0],
          sourceHandle: sourceEdge?.sourceHandle,
        }),
      ])
    const newNodes = nodes.filter(
      n => !removingNodes.some(item => item.id === n.id),
    )

    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }

  copy(
    originNode: Node<LoopNodeData>,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
    isStart = true,
  ): FlowConfigData {
    const result: FlowConfigData = {
      nodes: [],
      edges: [],
    }

    const { relation } = originNode.data
    const resultNode = nodesMap[relation.loopResultId as string]
    if (!resultNode) {
      throw new Error('循环节点复制出错')
    }
    const targetNode = cloneDeep({
      ...originNode,
      data: {
        ...originNode.data,
        relation: removeOuterRelation(originNode.data?.relation),
      },
      id: isStart ? `${TO_BE_COPIED_ID}_${originNode.id}` : uuidv4(),
    })
    const cloneResultNode = cloneDeep({
      ...resultNode,
      id: uuidv4(),
      data: {
        ...resultNode.data,
        relation: {
          ...resultNode.data.relation,
          loopNodeId: targetNode.id,
        },
      },
    })
    targetNode.data.relation.loopResultId = cloneResultNode.id
    // 此行代码必须以次顺序推入数组，递归执行后会按序取出结果
    result.nodes.push(targetNode, cloneResultNode)

    const loopStartEdge = edges.find(
      e => e.source === originNode.id && e.target !== resultNode.id,
    )
    const firstChildNode = nodes.find(n => n.id === loopStartEdge?.target)
    // 循环体内有子节点则遍历复制
    if (firstChildNode) {
      let current: Node | null = originNode
      let next: Node | null = firstChildNode
      let currentId = targetNode.id
      while (current && next && next.id !== resultNode.id) {
        const operation = getOperationByNodeType(next.type as NodeType)
        if (operation instanceof SingleNodeOperation) {
          const cloneNext = cloneDeep({
            ...next,
            id: uuidv4(),
            data: {
              ...next.data,
              relation: {
                ...next.data.relation,
                loopNodeId: targetNode.id,
              },
            },
          })
          result.nodes.push(cloneNext)
          result.edges.push(
            generateEdgeFromNodes({
              source: currentId,
              target: cloneNext.id,
              sourceHandle:
                currentId === targetNode.id
                  ? `loopStart_${currentId}`
                  : undefined,
            }),
          )
          currentId = cloneNext.id
        } else if (operation) {
          const { nodes: cNodes, edges: cEdges } = operation.copy(
            next,
            nodes,
            edges,
            nodesMap,
            false,
          )
          cNodes[0].data.relation.loopNodeId = targetNode.id
          result.nodes = result.nodes.concat(cNodes)
          result.edges.push(
            generateEdgeFromNodes({
              source: currentId,
              target: cNodes[0].id,
              sourceHandle:
                currentId === targetNode.id
                  ? `loopStart_${currentId}`
                  : undefined,
            }),
          )
          result.edges = result.edges.concat(cEdges)
          currentId = cNodes[1].id
        }
        current = next
        next = getNextNode(nodes, edges, current)
      }
      result.edges.push(
        generateEdgeFromNodes({
          source: currentId,
          target: cloneResultNode.id,
        }),
      )
    } else {
      result.edges.push(
        generateEdgeFromNodes({
          source: targetNode.id,
          target: cloneResultNode.id,
          sourceHandle: `loopStart_${targetNode.id}`,
        }),
      )
    }
    result.edges.push(
      generateEdgeFromNodes({
        source: targetNode.id,
        target: cloneResultNode.id,
        sourceHandle: `loopBoundary_${targetNode.id}`,
        type: 'help',
      }),
    )

    return result
  }

  replaceEmpty(data: {
    id: string
    node: ReplaceNodeData<any>
    sourceHandleId?: string | undefined
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }): FlowConfigData {
    const { id, node, nodes, edges, nodesMap } = data
    const currentNode = nodesMap[id] as Node

    const outgoers = getOutgoers(currentNode, nodes, edges)
    if (!outgoers.length) {
      throw new Error('节点数据异常')
    }
    // 生成循环结束节点
    const loopResultMeta = LoopResultNode.meta
    const resultNode = {
      type: loopResultMeta.type,
      id: uuidv4(),
      position: {
        x: currentNode.position.x,
        y: currentNode.position.y + 13,
      },
      data: {
        ...loopResultMeta.initialData,
        actionType: loopResultMeta.actionType,
        relation: {
          loopNodeId: id,
        },
      },
    }
    // 循环体中默认生成一个空节点
    const emptyNode = {
      id: uuidv4(),
      type: NodeType.EMPTY,
      zIndex: 20,
      position: {
        x: currentNode.position.x + NODE_WIDTH + SPACE_BETWEEN_NODES,
        y: currentNode.position.y,
      },
      data: {
        relation: {
          loopNodeId: id,
          loopResultId: resultNode.id,
        },
      },
    }

    const boundaryEdge: Edge = {
      type: 'help',
      source: id,
      target: resultNode.id,
      id: `loopBoundary_${id}-${resultNode.id}`,
      sourceHandle: `loopBoundary_${id}`,
      // animated: true,
      data: {},
    }

    const nodeEnable = getInheritedNodeEnableById(id, nodes)
    const newEdges = edges
      .filter(e => !(e.source === id && e.target === outgoers[0].id))
      .concat([
        generateEdgeFromNodes({
          source: id,
          target: emptyNode.id,
          sourceHandle: `loopStart_${id}`,
        }),
        generateEdgeFromNodes({ source: emptyNode.id, target: resultNode.id }),
        generateEdgeFromNodes({
          source: resultNode.id,
          target: outgoers[0].id,
        }),
        boundaryEdge,
      ])

    const newNodes = nodes.concat([resultNode, emptyNode]).map(n => {
      return n.id === id
        ? {
            ...n,
            ...node,
            data: {
              ...node.data,
              isEnable: nodeEnable,
              relation: {
                ...node.data?.relation,
                loopResultId: resultNode.id,
              },
            },
          }
        : n
    })

    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }
}
