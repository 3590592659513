import { IconFont } from '@/components'
import { SUB_FLOW_STATUS } from '../../base'

export function WarningTips(props: { flowStatus: SUB_FLOW_STATUS }) {
  const { flowStatus } = props
  return (
    <div className='flex flex-items-center flex-justify-start b-rd-8px bg-#ff5219 bg-op-8 px-12px py-8px mb-16px'>
      <IconFont name='cuowu' className='text-12px m-r-8' />
      <span className='c-#99310f text-12px line-height-16px'>
        该Flow已被
        {flowStatus === SUB_FLOW_STATUS.DELETED ? '删除' : '禁用'}
        ，无法运行，请重新选择
      </span>
    </div>
  )
}
