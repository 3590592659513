import classNames from 'classnames'
import { useMemoizedFn, useRequest } from 'ahooks'
import { matchPath, useNavigate } from 'react-router-dom'
import { Markdown, IconFont, usePageModal } from '@/components'
import { getFileIconByFileTypeOrMimeType } from '@/features/datastore/utils'
import { useWorkspaceStore } from '@/store/workspace'
import { getDocumentInfo } from '@/apis/datastore/api'
import { PARAGRAPH_TRIGGER_SCROLL } from '@/constants/message'
import { usePublicStore } from '@/store'
import {
  AGENT_FROM,
  AGENT_KNOWLEDGE_ROUTER_MAP,
} from '@/features/agent/constant/base'

export interface KnowledgeContent {
  file_id?: string
  file_name?: string
  mimetype?: string
  file_type?: number
  download_url?: string
  chunk_id?: string
  content?: string
  keywords?: string[]
  datastore_id?: string
  datastore_name?: string
  relevance_score?: {
    keywords: number
    reranker: number
    vector: number
  }
}

export interface KnowledgeOutputProps {
  contents: KnowledgeContent[]
  openInAgent?: boolean
}

export function KnowledgeOutput(props: KnowledgeOutputProps) {
  const { contents, openInAgent } = props
  const navigate = useNavigate()
  const { isPublic } = usePublicStore()

  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const pageModal = usePageModal()
  const { runAsync } = useRequest(getDocumentInfo, { manual: true })

  const open = useMemoizedFn(
    async (
      datastoreId?: string,
      fileId?: string,
      chunkId?: string,
      openInPageModal = false,
    ) => {
      // 公开页禁用
      if (isPublic) return

      if (!fileId || !datastoreId) return

      try {
        // 尝试获取文档详情，有文档详情则可以跳转
        await runAsync(Number(fileId))

        const pathInfo = matchPath('/agent/:agentId/*', location.pathname)
        const isAgent = !!pathInfo

        const query = ['independent=1']
        if (chunkId) {
          query.push(`paragraphId=${chunkId}`)
        }
        const qs = query.join('&')

        const agentId = pathInfo?.params.agentId
        if (openInAgent && isAgent && agentId && !openInPageModal) {
          navigate(
            `/agent/${agentId}/design/knowledge/datastores/${datastoreId}/${fileId}?${qs}`,
            {
              replace: true,
              state: {
                [AGENT_FROM]: AGENT_KNOWLEDGE_ROUTER_MAP.DETAIL,
              },
            },
          )
          const event: any = new Event(PARAGRAPH_TRIGGER_SCROLL)
          event.paragraphId = chunkId
          event.fileId = fileId
          window.dispatchEvent(event)
          return
        }

        const url = `/datastores/${workspaceId}/${datastoreId}/${fileId}/content?${qs}`
        pageModal.show({ url })
      } catch (e) {
        console.log(e)
      }
    },
  )

  return (
    <div>
      {contents.map((each = {}, index) => {
        const rankInfo = []

        if (each.relevance_score?.reranker) {
          rankInfo.push(`重排${each.relevance_score.reranker.toFixed(2)}分`)
        }
        if (each.relevance_score?.vector) {
          rankInfo.push(`语义${each.relevance_score.vector.toFixed(2)}分`)
        }
        if (each.relevance_score?.keywords) {
          rankInfo.push(`关键词${each.relevance_score.keywords.toFixed(2)}分`)
        }

        return (
          <div
            key={index}
            className={classNames('bg-#ECEDF2 rounded-8px cursor-pointer', {
              'mb-12px': index !== contents.length - 1,
            })}
            onClick={() => {
              open(each.datastore_id, each.file_id, each.chunk_id)
            }}
          >
            <div className='flex  flex-1 justify-between align-center px-12px py-10px'>
              <p className='flex items-center'>
                <img
                  className='w-20px h-24px mr-6 shrink-0 h-16px shrink-0'
                  src={getFileIconByFileTypeOrMimeType(
                    each.mimetype!,
                    each.file_type,
                  )}
                />
                <div className='leading-16px truncate max-w-200px'>
                  {each.file_name}
                </div>
                {each.download_url && (
                  <a
                    className='shrink-0 text-16px ml-4 cursor-pointer c-font_1 hover:c-font_1'
                    href={each.download_url}
                    download
                    onClick={e => e.stopPropagation()}
                  >
                    <IconFont name='xiazairizhi' className='text-14px/14px' />
                  </a>
                )}
              </p>
              {!!each.datastore_id &&
                !!each.file_id &&
                !!each.datastore_name && (
                  <div
                    className='text-12px/14px flex items-center'
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    <p className='max-w-180px truncate'>
                      <span className='c-#8D8D99 op-60%'>所属知识库:</span>
                      <span className='  c-#17171D op-80% ml-4px'>
                        {each.datastore_name}
                      </span>
                    </p>
                    <IconFont
                      className='ml-4px text-14px/14px  cursor-pointer c-font_1'
                      name='super-link-1'
                      onClick={() => {
                        open(
                          each.datastore_id,
                          each.file_id,
                          each.chunk_id,
                          true,
                        )
                      }}
                    />
                  </div>
                )}
            </div>

            <div className='bg-#F6F6F9 p-12px rounded-8px break-all hover:bg-#E6E6ED'>
              {!!each.keywords && !!each.keywords.length && (
                <div className='color-#8D8D99 leading-20px mb-12px text-12px'>
                  命中关键词
                  {each.keywords.map((each, index) => (
                    <span
                      className='border b-#E1E1E5 px-4px py-2px rounded-4px ml-8px bg-white'
                      key={index}
                    >
                      {each}
                    </span>
                  ))}
                </div>
              )}

              <Markdown text={each.content ?? ''} />

              {!!rankInfo.length && (
                <div className='color-#8D8D99 mt-12px text-12px'>
                  命中排名{index + 1}：{rankInfo.join('、')}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
