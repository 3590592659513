import classNames from 'classnames'
import { Select } from '@/components'
import { AlarmLevelIcons } from './constants'

const options = Object.entries(AlarmLevelIcons).map(([k, v]) => ({
  label: <img className='w-44px h-20px' src={v} />,
  value: k,
}))

interface AlarmLevelsSelectProps {
  className?: string
  value?: string
  onChange?: (val: string) => void
}

export function AlarmLevelsSelect(props: AlarmLevelsSelectProps) {
  return (
    <div className={classNames('flex items-center gap-8px', props.className)}>
      <span>触发</span>
      <Select className='min-w-300px! flex-1' options={options} {...props} />
      <span>严重度告警</span>
    </div>
  )
}
