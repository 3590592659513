import { createContext } from 'react'
import type { FlowStatus } from '@/apis/flow'

export enum TaskStatus {
  start = 'start',
  complete = 'complete',
  loading = 'loading',
  abort = 'abort',
  error = 'error',
}

export interface TaskResult {
  duration?: number
  status?: FlowStatus
  result?: string
  errorMsg?: string
}

export interface LLMNodePanelContextProps {
  onUseInfo: (id: string) => void
  clearInfo: (id: string) => void
  onRun: () => void
  subscribeRun: (
    id: string,
    checkCall: () => void,
    call: () => void,
  ) => () => void
  setTaskStatus: (id: string, state: TaskStatus) => void
  getTaskStatus: (id: string) => TaskStatus | undefined
  subscribeState: (call: (map: Map<string, TaskStatus>) => void) => () => void
  setTaskResult: (id: string, result: TaskResult) => void
  getTaskResult: (id: string) => TaskResult | undefined
  subscribeResult: (call: (map: Map<string, TaskResult>) => void) => () => void
}

export const LLMNodePanelContext = createContext<LLMNodePanelContextProps>({
  onUseInfo: () => {},
  clearInfo: () => {},
  onRun: () => {},
  subscribeRun: () => () => {},
  setTaskStatus: () => {},
  getTaskStatus: () => undefined,
  subscribeState: () => () => {},
  setTaskResult: () => {},
  getTaskResult: () => undefined,
  subscribeResult: () => () => {},
})
