import type { AuthRoute } from '@/auth.ts'
import { DatabaseList } from '@/pages/database/list.tsx'
import DBDetail from '@/pages/database/detail.tsx'
import { withVersionGuard } from '@/features/pay/withVersionGuard'
import { Version } from '@/features/pay'

export const dbRoutes: AuthRoute[] = [
  {
    path: '/db',
    auth: 'auth',
    element: withVersionGuard({
      comp: <DatabaseList />,
      allowVersions: [Version.ENTERPRISE, Version.FREE],
    }),
  },
]

export const dbRoutesNoLayout: AuthRoute[] = [
  {
    path: '/db/:workspaceId/:id/:tableId?',
    auth: 'auth',
    element: withVersionGuard({
      comp: <DBDetail />,
      allowVersions: [Version.ENTERPRISE, Version.FREE],
    }),
  },
]
