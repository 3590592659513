import type { CSSProperties, FC, PropsWithChildren } from 'react'
import { useRef, useEffect, useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useViewport } from 'reactflow'
import cn from 'classnames'
import { VariableTags, type VariableTagsProps } from '../VariableTags'

export interface TagsTooltipProps {
  rootClassName?: string
  tags?: { label: string; type: string }[]
  tagsStyle?: CSSProperties
  open?: boolean
  topEl?: HTMLElement | null
  leftEl?: HTMLElement | null
  anchor?: VariableTagsProps['anchor']
  onTagClick?: (tag: { label: string; type?: string }) => void
  viewport?: {
    zoom: number
    x?: number
    y?: number
  }
  variables?: Array<{ label: string; type?: string }>
  offset?: [number, number]
  // children: ReactElement<any>
}

export const Portal: FC<PropsWithChildren> = ({ children }) => {
  // 创建一个 container 节点，作为 portal 的容器节点
  const containerRef = useRef<HTMLDivElement | null>(null)

  if (!containerRef.current) {
    containerRef.current = document.createElement('div')

    // 将 container 节点添加到 document.body
    document.body.appendChild(containerRef.current)
  }

  // 当组件销毁时，移除 container 节点
  useEffect(() => {
    return function cleanup() {
      if (containerRef.current) {
        document.body.removeChild(containerRef.current)
      }
    }
  }, [])

  return createPortal(children, containerRef.current)
}

export const TagsTooltip: FC<PropsWithChildren<TagsTooltipProps>> = props => {
  const {
    rootClassName,
    topEl,
    leftEl,
    variables = [],
    onTagClick,
    anchor = 'left',
    offset,
  } = props

  const wrapRef = useRef<HTMLDivElement | null>(null)

  const { top } = (topEl as HTMLElement).getBoundingClientRect()
  const { left, right } = (leftEl as HTMLElement).getBoundingClientRect()
  const direct = {
    left: left - 60,
    right: right + 60,
  }
  const [position, setPosition] = useState({ left: direct[anchor], top })
  const viewport = useViewport()

  useLayoutEffect(() => {
    if (topEl && leftEl) {
      const { top } = (topEl as HTMLElement).getBoundingClientRect()
      const { left, right } = (leftEl as HTMLElement).getBoundingClientRect()
      const { width = 0 } = wrapRef.current?.getBoundingClientRect() || {}
      const [offsetX = 0, offsetY = 0] = offset ?? []

      const direct = {
        left: left - width - 10 - offsetX,
        right: right + 10 + offsetX,
      }

      setPosition({
        left: direct[anchor],
        top: top + offsetY,
      })
    }
  }, [topEl, leftEl, variables, viewport, offset])

  if (!topEl) return null

  return (
    <Portal>
      <div
        ref={wrapRef}
        className={cn('fixed', rootClassName)}
        style={{ ...position }}
      >
        <VariableTags
          tags={variables}
          anchor={anchor}
          onTagClick={onTagClick}
        ></VariableTags>
      </div>
    </Portal>
  )
}
