import { Avatar } from '@bty/chat-ui'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Popover, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import type { PopoverProps } from 'antd/es/popover'
import { IconFont } from '@/components'
import rocket2 from '@/assets/app/rocket2.png'
import { FLOW_DISPLAYNAME } from '@/constants/common'

export function GLobalSelectStyle() {
  return (
    <Global
      styles={css`
        .AssociatedAgentPopover {
          .ant-popover-inner {
            padding: 0 !important;
          }
        }
      `}
    />
  )
}

const AgentItem = styled.div`
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .yunxingicon {
    display: none;
  }
  &:hover {
    background-color: rgba(98, 105, 153, 0.08);
    .yunxingicon {
      display: block;
    }
  }
`

export default ({
  data,
  onClick,
  trigger,
}: {
  data: Array<
    {
      icon: string
      color?: string
      name: string
      updateAt: string
    } & any
  >
  trigger?: PopoverProps['trigger']
  onClick: (data: any) => void
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <GLobalSelectStyle />
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger={trigger ?? 'click'}
        placement='bottomLeft'
        rootClassName='AssociatedAgentPopover'
        content={
          <div className='w-600px'>
            <div
              className='px-20px py-16px flex-items-center flex justify-between'
              style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
            >
              <div className='font-600 text-16px'>关联应用</div>
              <IconFont
                name='guanbi'
                className='cursor-pointer text-16px'
                onClick={() => setOpen(false)}
              />
            </div>
            <div className='p-8px h-400px overflow-y-scroll'>
              {data?.length > 0 ? (
                (data || [])?.map((item: any, index: number) => (
                  <AgentItem
                    key={index}
                    onClick={() => {
                      onClick(item)
                      setOpen(false)
                    }}
                  >
                    <div className='flex items-center gap-8px'>
                      <Avatar icon={item.icon} size={36} color={item.color} />
                      <div className='font-500 text-14px truncate max-w-260px'>
                        <Tooltip placement='topLeft' title={item.name}>
                          {item.name}
                        </Tooltip>
                      </div>
                      {item.application_type === 'AI' && (
                        <Tag className='h-16px c-#8D8D99 line-height-12px text-12px bg-transparent px-4px  b-[rgb(225,225,229,0.6)] rounded-4px'>
                          {FLOW_DISPLAYNAME}
                        </Tag>
                      )}
                      {item.application_type === 'AGENT' && (
                        <Tag className='h-16px c-#8D8D99 line-height-12px text-12px bg-transparent b-[rgb(225,225,229,0.6)] px-4px  rounded-4px'>
                          Agent
                        </Tag>
                      )}

                      <IconFont
                        name='super-link'
                        className='text-16px yunxingicon c-#626999 c-op-60'
                      />
                    </div>
                    <div className='text-14px c-font_1'>{`更新于 ${dayjs(
                      item.updated_at,
                    ).format('YYYY-MM-DD HH:mm')}`}</div>
                  </AgentItem>
                ))
              ) : (
                <div
                  className='h-100% w-100% flex items-center justify-center flex-col text-14px'
                  style={{ color: 'rgba(98, 105, 153, 0.48)' }}
                >
                  <img className='h-140px w-140px' src={rocket2} />
                  暂无关联应用
                </div>
              )}
            </div>
          </div>
        }
      >
        <div
          className='ml-4px cursor-pointer bg-bg_3 bg-op-12 rd-4px flex gap-3px items-center px-8px py-3px'
          onClick={() => setOpen(true)}
        >
          <IconFont name='app' />
          <span className='text-12px font-500'>{data?.length || 0}</span>
        </div>
      </Popover>
    </>
  )
}
