import { useMemoizedFn } from 'ahooks'
import { useEffect, useState } from 'react'

const cacheMap = new Map<string, any>()

export function useCache<T>(key: string, initValue: T) {
  const [value, setValue] = useState<T>(cacheMap.get(key) || initValue)

  const saveToCache = useMemoizedFn(() => {
    cacheMap.set(key, value)
  })

  useEffect(() => {
    cacheMap.delete(key)
    return saveToCache
  }, [])

  return [value, setValue] as const
}
