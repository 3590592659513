import { GradientRoundedBox } from './GradientRoundedBox'

export interface GradientBorderAvatarProps {
  image: string
  size?: number
  imageSize?: number
}

export function GradientBorderAvatar(props: GradientBorderAvatarProps) {
  const { image, size, imageSize } = props

  return (
    // <div
    //   className='flex-center p-1 b-rd-50% overflow-hidden'
    //   style={{
    //     width: size ?? 88,
    //     height: size ?? 88,
    //     background: `linear-gradient(
    //       to bottom,
    //       rgba(165, 86, 255, 0.6),
    //       rgba(68, 177, 255, 0.6)
    //     )`,
    //   }}
    // >
    //   <div className='flex-center w-100% h-100% b-rd-50% b-4 b-#fff'>
    //     <img
    //       className='object-cover b-rd-50%  overflow-hidden'
    //       src={image}
    //       alt=''
    //       style={{
    //         width: imageSize ?? 80,
    //         height: imageSize ?? 80,
    //       }}
    //     />
    //   </div>
    // </div>
    <GradientRoundedBox
      classNames='flex-center overflow-hidden'
      size={size}
      gradient='linear-gradient(139deg, rgba(165, 86, 255, 0.6) 7%, rgba(116, 89, 255, 0.6) 41%, rgba(68, 177, 255, 0.6) 82%)'
    >
      <div className='flex-center w-100% h-100% b-rd-50% b-4 b-#fff'>
        <img
          className='object-cover b-rd-50%  overflow-hidden'
          src={image}
          alt=''
          style={{
            width: imageSize ?? 80,
            height: imageSize ?? 80,
          }}
        />
      </div>
    </GradientRoundedBox>
  )
}
