import type { Edge, Node } from 'reactflow'
import type { LoopNodeData } from '@/features/nodes/loop'
import type { NestNodeBoundaryFn, NodeBoundary } from '../types'
import { getNormalNodeBoundary } from '../util'
import { isNestableNode } from '@/features/flow/utils'

export function getLoopNodeBoundary(
  node: Node<LoopNodeData>,
  nodes: Node[],
  edges: Edge[],
  findNestNodeBoundaryFn: NestNodeBoundaryFn,
): NodeBoundary {
  const boundary = {
    top: node.position.y,
    left: node.position.x,
    right: node.position.x + (node.width || 0),
    bottom: node.position.y + (node.height || 0),
  }

  const { relation } = node.data
  const resultNode = nodes.find(n => n.id === relation.loopResultId)
  // 循环体内所有子节点
  const loopChildren = nodes.filter(
    n => n.data?.relation?.loopNodeId === node.id,
  )
  // 标记边界的edge
  const loopBoundaryEdge = edges.find(
    e => e.sourceHandle === `loopBoundary_${node.id}`,
  )

  if (resultNode) {
    const loopChildrenBoundaries = loopChildren.map(n =>
      isNestableNode(n)
        ? findNestNodeBoundaryFn(n, nodes, edges)
        : getNormalNodeBoundary(n),
    )

    const defaultBottom = loopChildrenBoundaries.length
      ? Math.max.apply(
          null,
          loopChildrenBoundaries.map(item => item.bottom),
        ) + 40
      : boundary.bottom + 40

    boundary.right = getNormalNodeBoundary(resultNode).right

    boundary.bottom = loopBoundaryEdge?.data?.bottomPosY || defaultBottom
  }

  return boundary
}
