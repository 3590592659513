import { request } from './lib'

export const ApprovalStatus = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
} as const

type ApprovalStatusValue = (typeof ApprovalStatus)[keyof typeof ApprovalStatus]

export const PermissionNotificationType = {
  /**
   * 审批者收到的申请
   */
  REQUEST: 0,
  /**
   * 申请者收到的结果
   */
  APPROVAL: 1,
} as const

export interface PermissionNotification {
  id: string
  role: (typeof PermissionNotificationType)[keyof typeof PermissionNotificationType]
  /**
   * 申请人
   */
  apply_user_name: string
  /**
   * 审批状态
   */
  approved_status: ApprovalStatusValue
  /**
   * 审批人
   */
  approved_user_name: string
  workspace_name: string
  flow_id: string
  flow_name: string
  create_at: string
  type: 3 | number
  application_id: string
}

export function fetchPermissionNotifications() {
  return request.get<PermissionNotification[]>('/v1/permission_apply', {
    params: {
      page: 1,
      page_size: 999,
    },
  })
}

export enum ApplyType {
  VIEWER = 1,
  DEVELOPER = 3,
}

export function applyForPermission(
  applicationId: string,
  workspaceId: string,
  type: ApplyType,
) {
  return request.post('/v1/permission_apply', {
    applicationId,
    workspaceId,
    type,
  })
}

export function processApproval(id: string, status: ApprovalStatusValue) {
  return request.put('/v1/permission_apply', {
    id,
    status,
  })
}

export const ResultOfApply = {
  NO_ACTION: -1, // 未操作过
  APPLY: 0, // 已申请
  RESOLVE: 1, // 已经同意
  REJECTED: 2, // 拒绝
} as const

export function queryResultOfApply(applicationId: string, workspaceId: string) {
  return request.get<{
    status: (typeof ResultOfApply)[keyof typeof ResultOfApply]
    id: number
    approve_user_id: number
    approve_user_name: string
  }>('/v1/permission_apply_status', {
    params: {
      applicationId,
      workspaceId,
    },
  })
}
