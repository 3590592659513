import { request } from './lib'

// 上传文件信息至gemini

interface IUploadFileInfoRequest {
  oss_path: string
  file_type: string
  file_name: string
  file_size: number
  file_action?: 'FILE_DATA' | 'FILE_URL'
}

interface IUploadFileInfoResponse {
  file_url: string
  upload_file_id: string
  [key: string]: any
}
export function uploadFileInfo(fileInfo: IUploadFileInfoRequest) {
  return request.post<IUploadFileInfoResponse>(
    '/v1/chat/file_upload_oss',
    fileInfo,
  )
}
