import { rgba } from 'polished'
import type { CSSProperties, FC, ReactNode } from 'react'
import classNames from 'classnames'
import { IconFont } from '@/components'

export interface AppLogoProps {
  value: string
  color?: string
  size?: number
  fillSize?: number
  defaultImageUrl?: string
  type: 'icon' | 'emoji' | 'image'
  className?: string
  imageClassName?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  square?: boolean
  imgStyle?: CSSProperties
  style?: CSSProperties
}

export const AppLogo: FC<AppLogoProps> = props => {
  const {
    value,
    color = '#fff',
    size,
    type,
    fillSize = 25,
    className,
    imageClassName,
    imgStyle,
    onClick,
    square = false,
    defaultImageUrl = 'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-1.png',
    style,
  } = props

  const imageUrl =
    type === 'image' || value?.startsWith('http') ? value : defaultImageUrl

  const ComponentMap: Record<AppLogoProps['type'], ReactNode> = {
    icon: <IconFont style={{ fontSize: fillSize, color }} name={value} />,
    emoji: <span style={{ fontSize: fillSize }}>{value}</span>,
    image: (
      <img
        src={imageUrl}
        alt=''
        className={classNames(
          'w-full h-full object-cover rounded-full',
          {
            'rounded-8px': square,
            // 'rounded-full': !square,
          },
          imageClassName,
        )}
        style={imgStyle}
      />
    ),
  }

  const isImage = type === 'image' || value?.startsWith('http')

  return (
    <div
      onClick={onClick}
      className={classNames(
        'rounded-8px flex items-center justify-center shrink-0',
        className,
      )}
      style={{
        background: isImage
          ? 'transparent'
          : type === 'emoji'
            ? color
            : rgba(color, 0.12),
        width: size ?? 48,
        height: size ?? 48,
        ...style,
      }}
    >
      {isImage ? ComponentMap.image : ComponentMap[type]}
    </div>
  )
}
