import { Form } from 'antd'
import { get } from 'lodash-es'
import { memo } from 'react'
import { PromptType } from '@/apis/prompt.ts'
import { getPromptFromStructPrompt } from '@/features/prompt/utils/prompt.ts'
import { PromptMermaid } from '@/features/prompt/components/PromptMermaid.tsx'
import type { RuleFormValues } from '@/features/agent/types/rule.ts'
import { SYSTEM_PROMPT_NAME_PATH } from '@/features/agent/constant/base.ts'

interface PromptMermaidWithFormItemProps {
  getContainer?: () => HTMLElement
}

export const PromptMermaidWithFormItem = memo(
  (props: PromptMermaidWithFormItemProps) => {
    const { getContainer } = props

    return (
      <Form.Item<RuleFormValues>
        noStyle
        shouldUpdate={(prev, next) => {
          if (prev.promptType !== next.promptType) {
            return true
          } else {
            if (next.promptType === PromptType.STRUCT) {
              return (
                getPromptFromStructPrompt(prev.structPrompt ?? []) !==
                getPromptFromStructPrompt(next.structPrompt ?? [])
              )
            } else {
              return (
                get(prev, SYSTEM_PROMPT_NAME_PATH) !==
                get(next, SYSTEM_PROMPT_NAME_PATH)
              )
            }
          }
        }}
      >
        {form => {
          const promptType = form.getFieldValue('promptType')
          const prompt =
            promptType === PromptType.STRUCT
              ? getPromptFromStructPrompt(
                  form.getFieldValue('structPrompt') ?? [],
                )
              : (form.getFieldValue(SYSTEM_PROMPT_NAME_PATH) ?? '')

          const disabled = !prompt.trim()
          return (
            <PromptMermaid
              disabled={disabled}
              prompt={prompt}
              getContainer={getContainer}
            />
          )
        }}
      </Form.Item>
    )
  },
)
