import { css, Global } from '@emotion/react'
import { useEventListener } from 'ahooks'
import { Select as AntSelect } from 'antd'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import classNames from 'classnames'
import { uniq } from 'lodash-es'
import type { ReactNode } from 'react'
import { useMemo, useRef, useEffect, useState } from 'react'
import Scrollbar from 'smooth-scrollbar'
import { useLLMStore } from '@/store/llm.ts'
import { DefaultModelIcon } from '@/constants/common.ts'

// import { POINTS_CONSUME_NAME } from '@/constants/commercialization'
import { IconFont, Select } from '@/components'
import type { LLMChannels } from '@/apis/llm/model.ts'
import type { ModelFeature } from '@/apis/llm/type'
import { ModelOptionItem } from './ModelOptionItem'

const { Option, OptGroup } = AntSelect

export interface ModelSelectProps {
  disabled?: boolean
  className?: string
  value?: {
    channel: LLMChannels
    model: string
    modelSetting?: any
  }
  onChange?: (value: {
    channel: LLMChannels
    model: string
    modelSetting?: any
  }) => void
  placeholder?: string
  getPopupContainer?: () => HTMLElement
  size?: SizeType
  filterByMethodSupport?: string[]
  showModelDescription?: boolean
  popupWrapper?: HTMLDivElement | null
  popupMatchSelectWidth?: boolean
  asyncSupport?: boolean
}

export interface ModelSelectOption {
  model: string
  label: ReactNode
  value: string
  disabled: boolean
  description: string | null
  needLevelUp: boolean
  feature: ModelFeature
  type?: string
}

type ModelOptionMap = Record<
  string,
  {
    label?: string
    options: Array<ModelSelectOption>
  }
>

export function GLobalSelectStyle() {
  return (
    <Global
      styles={css`
        .limitMaxWidth {
          max-width: 350px;
        }
        .ant-select-item-group:not(:first-of-type) {
          margin-top: 4px;
          border-top: 1px solid #e1e1e599;
          border-radius: 0%;
        }
      `}
    />
  )
}

/**
 * TODO 业务需要，废弃一部分模型，暂时由前端控制，后续该逻辑需要服务端去做
 * 后续做法：
 * 服务端平滑降级后，模型会被标记为enable: false,
 * 1、业务保持现有逻辑，从getLLMModelOptions中过滤enable false 的模型
 * 2、在Select value中特殊处理，对于废弃模型，需要保持原始名称，以防止出现 GPT__cpt-3.5-turbo的显示
 */
const deprecatedModels = ['gpt-4']

function generalModalValue(channel: LLMChannels, model: string) {
  // 兼容老数据，废弃的模型，需要显示格式化之前的model名称，💩
  if (deprecatedModels.includes(model)) {
    return model
  }
  return `${channel}___${model}`
}

function parseModelValue(value: string) {
  const parseValues = value.split('___')
  return {
    channel: (parseValues?.[0] ?? '') as LLMChannels,
    model: parseValues?.[1] ?? '',
  }
}

export function ModelSelect(props: ModelSelectProps) {
  const {
    disabled,
    className,
    placeholder,
    filterByMethodSupport,
    size = 'small',
    // showModelDescription = true,
    popupWrapper,
    popupMatchSelectWidth = true,
    asyncSupport = false,
  } = props
  const wrapperRef = useRef<HTMLDivElement>(null)
  const droprRef = useRef<HTMLDivElement>(null)
  const [visiable, setVisiable] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0) // 用于保存滚动位置
  useEffect(() => {
    const element = droprRef?.current?.firstChild?.firstChild as HTMLElement
    if (!element) return
    const scrollbar = Scrollbar.init(element, {
      alwaysShowTracks: false,
    })

    // 计算选中项的位置并滚动到该位置
    const selectedOption = droprRef?.current?.querySelector(
      '.ant-select-item-option-selected',
    ) as HTMLElement
    if (selectedOption) {
      setScrollPosition(selectedOption.offsetTop)
      Scrollbar.get(element)?.scrollTo(0, selectedOption.offsetTop)
    }

    return () => {
      scrollbar.destroy()
    }
  }, [visiable, droprRef?.current])
  useEffect(() => {
    const element = droprRef?.current?.firstChild?.firstChild as HTMLElement
    if (scrollPosition !== 0) {
      Scrollbar.get(element)?.scrollTo(0, scrollPosition)
    }
  }, [visiable, scrollPosition])

  const {
    llmModelList,
    llmModelLimitedList,
    llmAsyncSupportModelLimitedList,
    getLlmModelListLoading,
    getAsyncSupportModelListLoading,
  } = useLLMStore()
  const loading = useMemo(
    () =>
      asyncSupport ? getAsyncSupportModelListLoading : getLlmModelListLoading,
    [asyncSupport, getLlmModelListLoading, getAsyncSupportModelListLoading],
  )
  const limitedList = useMemo(
    () =>
      asyncSupport ? llmAsyncSupportModelLimitedList : llmModelLimitedList,
    [asyncSupport, llmAsyncSupportModelLimitedList, llmModelLimitedList],
  )

  const moveElement = (arr: string[], element: string, newPosition: number) => {
    const currentIndex = arr.indexOf(element)
    if (currentIndex === -1) {
      return
    }
    arr.splice(currentIndex, 1)
    const adjustedNewPosition =
      newPosition > currentIndex ? newPosition - 1 : newPosition
    arr.splice(adjustedNewPosition, 0, element)
  }

  const getLLMModelOptions = () => {
    if (!llmModelList.length) {
      return []
    }

    const resultList = llmModelList.filter(item => {
      if (deprecatedModels.includes(item.model)) {
        return false
      }
      if (filterByMethodSupport) {
        return item.enable && filterByMethodSupport.includes(item.methodSupport)
      }
      return item.enable
    })

    const channels = uniq(resultList.map(item => item.code))
    // 调整模型类别顺序
    moveElement(channels, 'Amazon', 1)
    moveElement(channels, 'Google', 2)
    moveElement(channels, 'Moonshot', 3)
    moveElement(channels, 'Volcano', 4)
    moveElement(channels, 'DeepSeek', -2)

    const groupedData = channels.reduce((acc, channel) => {
      return {
        ...acc,
        [channel]: {
          label: resultList.find(v => v.code === channel)?.name,
          options: resultList
            .filter(v => v.code === channel)
            .map(item => {
              const hasLimitedList = limitedList.find(
                j => j.llm_code === item.model,
              )
              const canUsed = hasLimitedList?.llm_enabled
              return {
                // label: item.model,
                model: item.model,
                label: (
                  <div className='h-full flex flex-items-center gap-x-6px'>
                    {item?.feature?.icon ? (
                      <img
                        src={item?.feature?.icon || DefaultModelIcon}
                        alt=''
                        className='w-16px h-16px'
                      />
                    ) : (
                      <IconFont
                        name='muxing'
                        className='mr-8px text-16px text-#626999 text-op-60'
                      />
                    )}

                    <span>{item.model}</span>
                  </div>
                ),
                value: generalModalValue(item.code, item.model),
                description: item.description,
                feature: item.feature,
                disabled: !hasLimitedList || item.grayTest,
                type: item.type,
                needLevelUp: item.grayTest
                  ? false
                  : hasLimitedList
                    ? !canUsed
                    : false,
              }
            })
            .filter(item => !item.disabled),
        },
      }
    }, {} as ModelOptionMap)
    return Object.values(groupedData).filter(item => item.options.length > 0)
  }

  /**
   * 判断当前选中的模型是否是废弃模型
   * 服务端平滑降级后，模型会被标记为enable: false,
   * 1、当model不在llmModelList中或者模型的enable为false时为废弃模型
   */
  const isDeprecatedModel = useMemo(() => {
    return (
      llmModelList.findIndex(
        item =>
          item.enable &&
          item.model === props.value?.model &&
          item.code === props.value?.channel,
      ) === -1
    )
  }, [props.value?.model, props.value?.channel, llmModelList])

  const modelOptions = useMemo(
    () => getLLMModelOptions(),
    [limitedList, llmModelList],
  )
  useEventListener(
    'wheel',
    e => {
      const isPinch = !Number.isInteger(e.deltaY) && e.ctrlKey

      if (isPinch) {
        e.preventDefault()
      }
    },
    { target: wrapperRef },
  )

  const renderOptions = (options: ModelSelectOption[]) => {
    return options.map(opt => {
      const findLimitItem = limitedList?.find(
        item => item.llm_code === opt.model,
      )
      return (
        <Option
          key={opt.value}
          value={opt.value}
          label={opt.label}
          disabled={opt.disabled}
          className='important:p-0 mt-4px'
        >
          {/* {
          findLimitItem?.limited && findLimitItem.used
            ? <Tooltip arrow={false} rootClassName="limitMaxWidth" title={
              findLimitItem?.consume === findLimitItem?.limit
                ? `「${findLimitItem.model}」 本月运行次数已达上限 (${findLimitItem.limit}次/月)`
                : `「${findLimitItem.model}」 本月剩余运行次数：${findLimitItem?.limit - findLimitItem?.consume}次 (${findLimitItem?.limit}次/月)`
            }>{OptionsItem}</Tooltip>
            : OptionsItem
        } */}
          {/* {findLimitItem?.llm_enabled && !isPrivateVersion ? (
            <Tooltip
              arrow={false}
              trigger={['hover']}
              placement='right'
              rootClassName='limitMaxWidth'
              title={`运行一次消耗 ${findLimitItem.llm_consume_points} ${POINTS_CONSUME_NAME}`}
            >
              {OptionsItem}
            </Tooltip>
          ) : (
            )} */}
          <ModelOptionItem opt={opt} findLimitItem={findLimitItem} />
        </Option>
      )
    })
  }

  return (
    <div ref={wrapperRef} onWheel={e => e.stopPropagation()}>
      <GLobalSelectStyle />
      <Select
        disabled={disabled}
        // open={true}
        className={classNames('nodrag nopan w-full', className)}
        placeholder={placeholder}
        value={
          props.value
            ? isDeprecatedModel
              ? props.value.model
              : generalModalValue(props.value.channel, props.value.model)
            : undefined
        }
        onChange={value => {
          const { channel, model } = parseModelValue(value)
          props.onChange?.({ channel, model })
        }}
        loading={loading}
        popupClassName='selectLLM'
        getPopupContainer={() => wrapperRef.current as HTMLElement}
        size={size}
        onDropdownVisibleChange={(open: boolean) => setVisiable(open)}
        listHeight={456}
        virtual={false}
        dropdownRender={menu => {
          return <div ref={droprRef}>{menu}</div>
        }}
        optionLabelProp='label'
        // options={modelOptions}
        popupMatchSelectWidth={
          popupWrapper?.clientWidth || popupMatchSelectWidth
        }
      >
        {modelOptions.length > 1
          ? modelOptions.map(item => {
              return (
                <OptGroup label={item.label} key={item.label}>
                  {renderOptions(item.options)}
                </OptGroup>
              )
            })
          : renderOptions(modelOptions[0]?.options ?? [])}
      </Select>
    </div>
  )
}
