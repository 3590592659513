import { useMemo } from 'react'
import { Version } from '@/features/pay'
import { useVersionStore } from '@/store'

export function useDefaultPointsConfig() {
  const { versionInfo } = useVersionStore()

  const defaultLeftPoints = useMemo(() => {
    if (!versionInfo) return 100
    return versionInfo.product_code === Version.FREE ? 100 : 10000
  }, [])

  return {
    defaultLeftPoints,
  }
}
