import { useMemo } from 'react'
import { Form } from 'antd'
import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm } from '@/features/nodes/components'
import { DocumentSelect } from '@/features/nodes/insert-memory/DocumentSelect.tsx'
import type { NodeComponent } from '@/features/nodes/base'
import { NodeOperationTypes, NodeType } from '@/features/nodes/base/types'

export interface InsertMemoryNodeData {
  inputs: {
    partition_id?: number
    file_id?: number
    content: string
  }
  packageName: string
}

export const InsertMemoryNode: NodeComponent<InsertMemoryNodeData> = props => {
  const { nodeElement, variables } = props
  const [form] = Form.useForm()

  const selected_partition_id = form.getFieldValue(['inputs', 'partition_id'])
  const list = useMemo<JsonFormConfig[]>(
    () => [
      {
        label: '知识库',
        name: ['inputs', 'partition_id'],
        required: true,
        rules: [{ required: true, message: '请选择知识库' }],
        type: 'DatasetSelect',
      },
      {
        label: '数据文件',
        name: ['inputs', 'file_id'],
        required: true,
        hidden: !form.getFieldValue(['inputs', 'partition_id']),
        rules: [{ required: true, message: '请选择数据文件' }],
        render: () => {
          return <DocumentSelect memoryId={selected_partition_id} />
        },
      },
      {
        label: '数据内容',
        name: ['inputs', 'content'],
        required: true,
        type: 'TextEditor',
        rules: [{ required: true, message: '插入内容不能为空' }],
        tooltip: '目前仅支持纯文本数据',
        className: 'important:mb-0',
        componentProps: {
          placeholder:
            '输入上下文的变量或纯文本数据，并将变量的数据或者纯文本数据导入知识库中',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
    ],
    [nodeElement, variables, selected_partition_id],
  ) // formItem 中配置dependencies无效，因为list被memo缓存了，先这样解决
  const onBeforeChange = (values: any) => {
    // 知识库变化之后，之前选择的文档就得清空
    if (values.inputs?.partition_id !== selected_partition_id) {
      form.setFieldValue(['inputs', 'file_id'], undefined)
      delete values.inputs.file_id
    }
    return values
  }

  return (
    <div className='p-16px w-420px' onWheel={e => e.stopPropagation()}>
      <JsonForm
        list={list}
        form={form}
        beforeChange={onBeforeChange}
      ></JsonForm>
    </div>
  )
}

InsertMemoryNode.meta = {
  type: NodeType.INSERT_MEMORY,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'INTEGRATION',
  typeName: 'INSERT_DATA',
  icon: 'insert-data',
  description: '在知识库中，插入数据',
  backgroundColor: '#5683FF',
  canDelete: true,
  initialData: {
    inputs: {
      content: '',
    },
    packageName: 'integration.memory_insert.MemoryInsertAction',
  },
}
