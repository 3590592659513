import styled from '@emotion/styled'
import classNames from 'classnames'

import { Divider, message, Spin, Switch } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import type { DocumentType } from '@/apis/datastore/model'
import { useModal } from '@/hooks/useModal.tsx'

import {
  answerListToMap,
  QAParagraphItemEditorModal,
} from '../ParagraphItemEditorModal'
import KeywordsView from '@/pages/datastores/components/KeywordsView'
import { CommonParagraphItemWrap } from '../ParagraphEditorStyle'
import { deleteQAGroup, saveQAGroup } from '@/apis/datastore/api'
import type { IQAQuestionItem } from '@/apis/datastore/types'
import type { ParagraphListItemProps } from './types'
import {
  OperationAction,
  OperationActionType,
} from './components/OperationAction'
import ParagraphPreview from './components/ParagraphPreview'

const Wrap = styled(CommonParagraphItemWrap)`
  .tags {
    display: none;
  }
  &:hover {
    .info-box,
    .delete-btn {
      opacity: 1;
      width: auto;
    }
    .num-text {
      display: none;
    }
    .tags {
      display: block;
    }
  }
`

export default (props: ParagraphListItemProps<DocumentType.QA>) => {
  const {
    first,
    border,
    readonly,
    paragraph,
    numText,
    file_id,
    activeId,
    rank,
    onDelete,
    onEditorLoad,
    onEditorDestroy,
    updateParagraphsItemById,
    addParagraphsItem,
    onSelect,
  } = props

  const editorRef = useRef<HTMLDivElement | null>(null)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [qaParagraphEditorModal] = useModal(QAParagraphItemEditorModal)

  const handleSelect = useMemoizedFn(() => {
    onSelect?.((paragraph.chunk_id || (paragraph as any).originalChunkId) ?? 0)
  })

  const loadEditor = useMemoizedFn(() => {
    onEditorLoad({
      content: paragraph.content,
    })
  })

  const handleAddParagraph = useMemoizedFn(() => {
    qaParagraphEditorModal.open({
      operationType: 'add',
      file_id: file_id!,
      insert_above_chunk_id: paragraph.chunk_id,
      paragraph: {
        content: '',
        enable: true,
        qa_info: {
          questions: [{ id: Date.now(), new: true, value: '', keywords: [] }],
          answers: [{ id: Date.now(), new: true, value: '', title: '答案' }],
        },
      },
      title: '新增问答',
      callback: values => {
        addParagraphsItem?.(values, paragraph.chunk_id)
      },
    })
  })

  const handleEditParagraph = useMemoizedFn(() => {
    qaParagraphEditorModal.open({
      operationType: 'update',
      file_id,
      chunk_id: paragraph.chunk_id!,
      paragraph,
      title: `问答${rank || Number(paragraph?.key) + 1}`,
      callback: value => {
        updateParagraphsItemById?.(value, false)
      },
    })
  })

  const handleEnableParagraph = useMemoizedFn(async (newEnable: boolean) => {
    const questions = paragraph.qa_info?.questions ?? []
    const answers = paragraph.qa_info?.answers ?? []

    setLoading(true)
    try {
      await saveQAGroup({
        file_id,
        answer_group_id: paragraph.chunk_id,
        contents: questions.map(e => {
          const res = {} as IQAQuestionItem
          if (!e.new) {
            res.chunk_id = e.id
          }
          res.content = e.value
          res.keywords = e.keywords
          res.enable = newEnable
          return res as IQAQuestionItem
        }),
        answers: answerListToMap(answers),
      })
      updateParagraphsItemById?.({ ...paragraph, enable: newEnable }, false)
    } catch (err) {
      message.error('更新失败')
    }
    setLoading(false)
  })

  const handleDeleteParagraph = useMemoizedFn(async () => {
    setLoading(true)
    try {
      await deleteQAGroup({
        file_id,
        answer_group_id: paragraph.chunk_id,
      })
      onDelete(paragraph.key, false)
    } catch (err) {
      message.error('删除失败')
    }
    setLoading(false)
  })

  const handleActionClick = useMemoizedFn((type: OperationActionType) => {
    switch (type) {
      case OperationActionType.ADD:
        handleAddParagraph()
        break
      case OperationActionType.DELETE:
        handleDeleteParagraph()
        break
      case OperationActionType.EDIT:
        handleEditParagraph()
        break
    }
  })

  useEffect(() => {
    loadEditor()
    return () => {
      onEditorDestroy()
    }
  }, [])

  const actionList = useMemo(() => {
    return Object.values(OperationActionType).filter(
      item => item !== OperationActionType.AI,
    )
  }, [])

  useEffect(() => {
    if (activeId !== paragraph.chunk_id) return
    handleSelect()
  }, [activeId])

  const firstQuestion = useMemo(
    () => paragraph?.qa_info?.questions?.[0],
    [paragraph?.qa_info?.questions],
  )

  const mainQuestion = useMemo(() => {
    if (!firstQuestion) return

    return (
      <div className='mt-4px flex items-center'>
        <p className='text-14px line-height-20px font-500 c-#17171D break-all min-w-0px'>
          {firstQuestion.value}
        </p>
      </div>
    )
  }, [firstQuestion])

  const otherQuestionList = useMemo(() => {
    const { questions = [] } = paragraph?.qa_info || {}
    const otherQuestions = questions.slice(1)
    if (otherQuestions.length < 1) return null

    return otherQuestions.map((item, idx) => {
      return (
        <div key={idx} className='mt-4px flex items-top'>
          {otherQuestions.length > 1 && (
            <div className='mr-8px mt-2px bg-#626999/12 c-#626999/60 w-16px h-16px flex flex-center text-12px font-500  px-4px rounded-4px'>
              {idx + 1}
            </div>
          )}
          <p className='text-14px line-height-20px font-500 c-#17171D break-all min-w-0px'>
            {item.value}
          </p>
        </div>
      )
    })
  }, [paragraph?.qa_info])

  const answerList = useMemo(() => {
    const { answers = [] } = paragraph?.qa_info || {}
    return answers.map((item, idx) => {
      return (
        <div key={idx} className='mt-12px'>
          <p className='bg-[rgba(56,166,255,0.12)] c-#38A6FF text-12px/20px line-height-20px font-500 px-4px rounded-4px inline-flex'>
            <span className='truncate max-w-400px text-12px/20px'>
              {item.title}
            </span>
          </p>
          <p className='text-14px/20px flex-1 mt-4px'>
            <ParagraphPreview
              value={item.value || ''}
              enable={!paragraph.enable}
            />
          </p>
        </div>
      )
    })
  }, [paragraph?.qa_info, paragraph.enable])

  return (
    <Wrap
      disabled={true}
      isEdited={paragraph.isEdited}
      onDoubleClick={() => {
        if (loading) return
        handleActionClick(OperationActionType.EDIT)
      }}
      className={classNames(
        'ml-24 relative rounded-8px overflow-hidden line-height-20px cursor-pointer',
        {
          'mt-20px': !first,
        },
      )}
    >
      <Spin
        spinning={loading}
        className='absolute inset-0 flex flex-center z-1000 pointer-events-none'
      >
        <div className='edited-mark w-full h-full absolute left-0 top-0 overflow-hidden rounded-8px pointer-events-none'></div>
        <div
          className={classNames('px-20 py-16 bg-white rounded-8px', {
            'border-0': !border,
            border,
            'hover:b-primary': !readonly && border,
            'focus-within:b-primary': !readonly && border,
            '!b-error': deleteConfirmOpen,
            'paragraph-blink': activeId === paragraph.chunk_id,
          })}
        >
          <div
            className='flex-center-between gap-8'
            onClick={e => e.stopPropagation()}
          >
            <div className='flex overflow-hidden'>
              <div
                className={classNames(
                  'num-text text-12px c-font_1 font-400 line-height-20px h-26px',
                )}
              >
                {numText}
              </div>
              <div className={classNames('flex items-center tags w-full', {})}>
                <KeywordsView
                  value={paragraph.keywords ?? []}
                  className='flex flex-1'
                  empty='该问答暂无关键词'
                />
              </div>
            </div>
            <div className='flex items-center'>
              <div className={classNames('flex items-center info-box')}>
                {!paragraph.enable && (
                  <div className='ml-12 py-2 text-12px px-8 rounded-4px c-#FE9700 bg-#FE9700 bg-op-12'>
                    <span className='w-8px h-8px rounded-full mr-6 inline-block bg-#FE9700'></span>
                    <span>已禁用</span>
                  </div>
                )}
                {!readonly && (
                  <>
                    <Switch
                      size='small'
                      checked={paragraph.enable}
                      onChange={handleEnableParagraph}
                    />
                  </>
                )}
              </div>
              {!readonly && (
                <span
                  className={classNames('flex items-center delete-btn', {
                    'op-0': !deleteConfirmOpen,
                    'w-0': !deleteConfirmOpen,
                  })}
                >
                  <Divider type='vertical' className='mx-12' />
                  <OperationAction
                    actionList={actionList}
                    onActionClick={handleActionClick}
                    deleteConfirmOpen={deleteConfirmOpen}
                    setDeleteConfirmOpen={setDeleteConfirmOpen}
                  />
                </span>
              )}
            </div>
          </div>

          <div
            className={classNames('flex flex-col', {
              'op-40': !paragraph.enable,
            })}
          >
            <div className='mt-12px flex items-center'>
              <div className='text-center bg-[rgba(123,97,255,0.12)] c-#7B61FF text-12px line-height-20px font-500  px-4px rounded-4px'>
                问题
              </div>
            </div>
            {mainQuestion}

            {otherQuestionList && (
              <div className='mt-12px flex items-center'>
                <div className='bg-[rgba(123,97,255,0.12)] c-#7B61FF text-12px line-height-20px font-500  px-4px rounded-4px'>
                  相似问题
                </div>
              </div>
            )}
            {otherQuestionList}

            <div className='w-100% overflow-hidden flex items-center'>
              <div>{answerList}</div>
              <div
                className='h-0px w-0 invisible mt-12 ml-8px text-14px line-height-20px'
                ref={editorRef}
              />
            </div>
          </div>
        </div>
      </Spin>
    </Wrap>
  )
}
