import { useMemo } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { useDebounceFn } from 'ahooks'
import { Tooltip } from 'antd'
import { ParagraphExtraInfoStatus } from '@/apis/datastore/model'
import { IconFont } from '@/components'

const BtnWrapper = styled.div`
  font-weight: 500;
  font-variation-settings: 'opsz' auto;
  background: linear-gradient(100deg, #2cb5ff 0%, #6b4eff 62%, #963aff 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  z-index: 1;
  font-size: 14px;
  line-height: 14px;
`

export const defaultGifImage =
  'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/chat/generating.gif'

export function Tips(props: {
  state: ParagraphExtraInfoStatus
  text: string
  onClick?: () => void
  showBtn?: boolean
}) {
  const { state, text, onClick = () => {}, showBtn } = props

  const { run } = useDebounceFn(
    () => {
      onClick?.()
    },
    {
      wait: 100,
    },
  )

  const info = useMemo(() => {
    switch (state) {
      case ParagraphExtraInfoStatus.Error:
        return {
          icon: 'cuowu',
          classNames: 'bg-#FFF1ED text-#E54A16 min-h-46px',
        }
      case ParagraphExtraInfoStatus.Process:
      case ParagraphExtraInfoStatus.Pending:
        return {
          img: defaultGifImage,
          classNames: ' text-#8D8D99 min-h-46px',
          style: {
            background:
              'linear-gradient(91deg, rgba(54, 205, 255, 0.06) 0%, rgba(107, 78, 255, 0.06) 62%, rgba(150, 58, 255, 0.06) 99%)',
          },
        }
      case ParagraphExtraInfoStatus.Warning:
        return {
          icon: 'jingzhi',
          classNames: ' text-font min-h-46px',
          style: {
            background: 'rgba(254, 151, 0, 0.08)',
          },
        }
      default:
        return {
          icon: '',
          classNames: 'hidden',
        }
    }
  }, [state])
  return (
    <div className='bg-#fff'>
      <div
        style={info?.style}
        className={classNames(
          'flex justify-between px-[14px] py-[8px] text-14px items-center',
          info?.classNames,
        )}
      >
        <div className='flex items-center'>
          {!!info?.icon && (
            <IconFont name={info?.icon!} className='text-[12px] m-r-8' />
          )}
          {info.img && (
            <img
              className='w-[24px]! h-[24px]! !mx-0 !my-0 !m-r-8px !p-0px'
              src={info.img}
              alt='loading'
            />
          )}
          <span className='text-14px'>{text}</span>
        </div>
        {showBtn && (
          <Tooltip title='用AI解析表格内容并分段，耗时较长'>
            <div
              className='flex items-center cursor-pointer bg-#fff hover:bg-[rgba(98,105,153,0.08)] px-12px py-7px rounded-[6px]'
              onClick={run}
            >
              <IconFont
                name='AIcengjiangchuli'
                className='text-16px/16px m-r-8'
              />
              <BtnWrapper>AI增强处理</BtnWrapper>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
