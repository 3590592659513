import { memo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useMemoizedFn, useRequest } from 'ahooks'
import {
  filter,
  get,
  hasIn,
  isEmpty,
  isString,
  map,
  omit,
  padStart,
  pick,
} from 'lodash-es'
import type { DefaultOptionType } from 'antd/es/select'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Popover } from 'antd'
import { Select } from '@/components/select'
import { IconFont } from '@/components/icon'
import type { IShead } from '@/apis/batchTest'
import { getBatchTestData, getPageSize } from '@/apis/batchTest'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { tryParseToJsonObject } from '@/utils/string'

interface TestDataSelectProps {
  optionId?: string
  flowId: string
  className?: string
  value?: string
  onChange?: (value: string, data?: Record<string, any>) => void
  popWidth?: number
}

export const TestDataSelect = memo((props: TestDataSelectProps) => {
  const { optionId, flowId, className, value, onChange, popWidth } = props

  const firstInit = useRef(false)
  const [options, setOptions] = useState<DefaultOptionType[]>([])
  const [headers, setHeaders] = useState<IShead[]>([])

  const { data: pageSize, loading: pageSizeLoading } = useRequest(getPageSize)

  const { loading: testDataLoading } = useRequest(
    () => getBatchTestData(flowId, 1, pageSize ?? 50),
    {
      ready: !!pageSize && !!flowId,
      refreshDeps: [optionId],
      onSuccess(res) {
        if (!res?.table_data[0]) return
        const firstKey = res.table_header[0].variableName
        const editKeys = res.table_header
          .filter(e => e.is_edit || ['remarks'].includes(e.variableName))
          .map(e => e.variableName)
        // file 配置带 url 需要单独处理
        const fileKeys = res.table_header
          .filter(e => e.type === 'file')
          .map(e => e.variableName)

        const formatData = map(res?.table_data, (each, i) => {
          const label = `批量调试#${padStart(String(i + 1), 2, '0')}`

          let display = ''
          try {
            display = isString(each[firstKey])
              ? each[firstKey]
              : JSON.stringify(each[firstKey])
          } catch {
            display = String(each[firstKey])
          }

          const data = pick(each, editKeys)
          fileKeys.forEach(e => {
            data[e] = typeof data[e] === 'string' ? data[e] : data[e]?.url || ''
            if (isEmpty(data[e])) {
              delete data[e]
            }
          })

          return {
            label,
            display,
            value: each.test_data_id,
            data,
          }
        })

        setHeaders(res.table_header ?? [])
        setOptions(formatData ?? [])
        if (value && !formatData.find(e => e.value === value)) {
          onChange?.('')
        }

        if (firstInit.current) return
        firstInit.current = true
        const option = formatData.find(e => e.value === value)
        onChange?.((option?.value ?? '') as string, option?.data)
      },
    },
  )

  const renderOption = useMemoizedFn((option: any) => {
    const inputs = omit(option.data.data, 'remarks', 'correct_result')

    const hasValueConfigs = filter(
      headers,
      each => each.is_edit && hasIn(inputs, each.variableName),
    )

    const content = (
      <div className='w-full truncate p-8px'>
        {option.data.label}
        <span className='text-#8D8D99 ml-4px'>{option.data.display}</span>
      </div>
    )

    if (!hasValueConfigs.length) return content

    const popContent = (
      <OverlayScrollbarsComponent
        className='w-250px max-h-360px overflow-auto mr-[-8px] pr-8px'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {map(hasValueConfigs, each => {
          const sureValue = tryParseToJsonObject(
            get(inputs, each.variableName, '-'),
          )

          return (
            <div key={each.variableName} className='text-12px mb-8px'>
              <p className='leading-18px'>{each.label}</p>
              {isString(sureValue) ? (
                <p className='leading-18px text-#8D8D99 break-all'>
                  {isEmpty(sureValue) ? '-' : sureValue}
                </p>
              ) : (
                <pre className='leading-16px text-#8D8D99 break-all'>
                  {JSON.stringify(sureValue, null, 2)}
                </pre>
              )}
            </div>
          )
        })}
      </OverlayScrollbarsComponent>
    )

    return (
      <Popover
        content={popContent}
        placement='left'
        title={'数据集输入'}
        destroyTooltipOnHide
      >
        {content}
      </Popover>
    )
  })

  const handleChange = useMemoizedFn(
    (_value: string, option: DefaultOptionType) => {
      onChange?.((option?.value ?? '') as string, option?.data)
    },
  )

  return (
    <div className={classNames(className, 'flex relative')}>
      <div className='absolute h-40px w-64px flex items-center pl-12px text-#8D8D99'>
        测试集:
      </div>
      <Select
        className='w-full [&_.ant-select-selector]:pl-64px! [&_.ant-select-selection-item]:h-38px [&_.ant-select-selection-placeholder]:text-#17171D'
        popupClassName='[&_.ant-select-item]:p-0px!'
        loading={pageSizeLoading || testDataLoading}
        size='large'
        showSearch={false}
        allowClear={{
          clearIcon: (
            <IconFont
              name='qingshu'
              className='text-18px mt-[-3px] ml-[-2px] hover:opacity-60'
            />
          ),
        }}
        placeholder='未选择'
        value={!options || !options.length ? '' : value}
        options={options}
        optionRender={renderOption}
        onChange={handleChange as any}
        dropdownAlign={{ offset: [0, -4], targetOffset: [-12, 0] }}
        popupMatchSelectWidth={popWidth}
      />
    </div>
  )
})
