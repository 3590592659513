import { useDebounceFn, useKeyPress } from 'ahooks'
import { useFlowDraftStore } from '@/store'

export function useEditUndoRedo() {
  const { nodes, edges, undo, redo, updateFlow } = useFlowDraftStore()

  const { run } = useDebounceFn(
    () => {
      updateFlow({
        nodes,
        edges,
      })
    },
    {
      wait: 300,
    },
  )

  useKeyPress(
    ['meta.z'],
    () => {
      undo()
      run()
    },
    {
      exactMatch: true,
    },
  )

  useKeyPress(
    ['shift.meta.z'],
    () => {
      redo()
      run()
    },
    {
      exactMatch: true,
    },
  )
}
