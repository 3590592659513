import { request } from './lib'

export enum RoleCode {
  ADMINISTRATOR = 'Administrator',
  DEVELOPER = 'Developer',
  VIEWER = 'Viewer',
}

export const RoleName: Record<RoleCode, string> = {
  [RoleCode.ADMINISTRATOR]: '管理员',
  [RoleCode.DEVELOPER]: '开发者',
  [RoleCode.VIEWER]: '使用者',
}

export const RoleWeight: Record<RoleCode, number> = {
  [RoleCode.ADMINISTRATOR]: 3,
  [RoleCode.DEVELOPER]: 2,
  [RoleCode.VIEWER]: 1,
}

export enum AppJoined {
  JOINED = 1,
  NOT_JOINED = 0,
}

// 字符串组合枚举
type Combination<A extends string, B extends string> =
  | A
  | B
  | `${A},${B}`
  | `${B},${A}`

type AllCombinations<A extends string, B extends string = A> = A extends A
  ? Combination<A, AllCombinations<Exclude<B, A>>>
  : never
export type ApplicationType = AllCombinations<
  'AI' | 'WEB' | 'AGENT' | 'DATASET'
>

interface WorkspaceUsersRequest {
  workspaceId: string
  applicationId?: string
  applicationType?: ApplicationType
}

export interface UserItem {
  activatedStatus: AppJoined
  permissionGroupId: string
  permissionGroupName: string
  phone: string
  roleCode: RoleCode
  userId: number | string
  username: string
  avatar?: string
  applications?: any[]
  isAuthAllApp?: boolean
}

export interface RoleBodyRequest {
  workspaceId: string
  userId: string | number
  roleCode: RoleCode
  applicationType: ApplicationType
  sourceRoleCode: RoleCode
  phone: string
}

export interface RemoveUserRequest {
  permissionGroupId: string
  userId: string | number
  sourceRoleCode: RoleCode
}

export interface SearchUserItem {
  accountNonExpired: boolean
  accountNonLocked: boolean
  activatedStatus: AppJoined
  credentialsNonExpired: boolean
  enabled: boolean
  isAnonymous: boolean
  isEnabled: boolean
  name: string
  new: boolean
  phone: string
  source: string
  ucUserId: number | string
}

export interface InviteMembersRequestBody {
  phones: string[]
  role: RoleCode
  applicationIds: (number | string)[]
  applicationType: ApplicationType
  workspaceId: string
  url: string
}

export interface UpdatePermittedApplicationsRequestBody {
  workspaceId: string
  userId: string | number
  roleCode: RoleCode
  applicationIds: string[]
  applicationType?: ApplicationType
  is_auth_all_app?: boolean
}

export function getWorkspaceUsers(params: WorkspaceUsersRequest) {
  return request.get<UserItem[]>('/v1/authority/members', { params })
}

// 角色修改
export function changeUserRole(body: Omit<RoleBodyRequest, 'sourceRoleCode'>) {
  return request.put('/v1/authority/updateRole', body)
}

export function updatePermittedApplicationsForUser(
  body: UpdatePermittedApplicationsRequestBody,
) {
  return request.put('/v1/authority/updateApplication', body)
}

export function removeUserFromWorkspace(body: RemoveUserRequest) {
  return request.put('/v1/authority/removeMember', body)
}

export function getAllUsersFromWorkSpace(workspaceId: string) {
  return request.get<SearchUserItem[]>('/v1/authority/getAllMembers', {
    params: { workspaceId },
  })
}

export function inviteMembers(body: InviteMembersRequestBody) {
  return request.post('/v1/authority/inviteMembers', body)
}

export function sendInviteMsg(body: InviteMembersRequestBody) {
  return request.post('/v1/authority/sendInviteMsg', body)
}

export function getUserRoleAppInfos(workspaceId: string) {
  return request.get(
    `/v1/authority/getUserRoleAppInfos?workspaceId=${workspaceId}`,
    {
      // 在 share agent 和 share chat 使用时，不需要跳转 notAllowed，他们有自己的 403 页面
      noRedirect: true,
      ignoreError: true,
    },
  )
}

interface InviteUserQueryType {
  app_info_list: {
    application_id: string
    application_name: string
  }[]
  effective_end_time: string
  invite_user_id: number
  invite_user_name: string
  is_auth_all_app: boolean
  role_code: RoleCode
  unique_id: string
  workspace_id: string
  workspace_name: string
}

// 获取链接邀请用户基本信息
export function getInviteUserQuery(id: string) {
  return request.get<InviteUserQueryType>(`/v1/link/invite_user/query/${id}`)
}

// 邀请用户到空间
export function linkInviteMembers(
  body: { id: string; phones?: string[] },
  set?: { ignoreError: boolean },
) {
  return request.post('/v1/authority/link/inviteMembers', body, set)
}

// 无需鉴权时查看管理员列表
export function getAdminMembers(params: WorkspaceUsersRequest) {
  return request.get<UserItem[]>('/v1/authority/members_admin', { params })
}

// 不需要 agentId 查看管理员列表
export function getWorkspaceAdminMembers(workspaceId: string) {
  return request.get<UserItem[]>('/v1/authority/workspace_members_admin', {
    params: { workspaceId },
  })
}

export function getTemplateList() {
  return request.get('/v1/copilot-template/list')
}

export function createAgentByTemplate(body: {
  agent_id: string
  file_ids: string[]
  parameters: { prompt: string }
  is_skip_file_check?: boolean
}) {
  return request.post('/v1/copilot/create_agent_by_copilot_template', body, {
    headers: {
      'Application-Id': body.agent_id,
    },
  })
}

export function getAgentCopilotProgress(agentId: string) {
  return request.get(
    `/v1/copilot/get_copilot_create_agent_progress/${agentId}`,
    {
      timeout: 10 * 6 * 1000,
      headers: {
        'Application-Id': agentId,
      },
    },
  )
}
