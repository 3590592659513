import { Global, css } from '@emotion/react'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import { useEffect, useMemo, useState, type ReactNode } from 'react'
import { createPortal } from 'react-dom'
import type { ItemType } from 'antd/es/menu/interface'
import { IconFont } from '../icon'

function GlobalDropdownStyle() {
  return (
    <Global
      styles={css`
        .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
          background-color: rgba(123, 97, 255, 0.08) !important;
          font-weight: 500;
        }
      `}
    />
  )
}

interface BaseDetailTabItem {
  key: string
  content?: ReactNode
  keepAlive?: boolean
  hide?: boolean
}

export interface LabelTabItem extends BaseDetailTabItem {
  type?: 'label'
  label: ReactNode
  title?: string
  beforeChange?: () => Promise<boolean>
}

export interface DropdownTabItem extends BaseDetailTabItem {
  type: 'dropdown'
  // options: MenuItemType[]
  options: LabelTabItem[]
}
export type DetailTabItem = LabelTabItem | DropdownTabItem
export interface DetailTabsProps {
  tabs: DetailTabItem[]
  defaultKey?: string
  noContent?: boolean
  onChange: (key: string) => void
}

function isDropdownTabItem(item: DetailTabItem): item is DropdownTabItem {
  return item.type === 'dropdown'
}

export function DetailTabs(props: DetailTabsProps) {
  const { tabs, defaultKey, noContent = false, onChange } = props
  const [activeTab, setActiveTab] = useState(defaultKey || tabs?.[0]?.key)
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null)

  const flatTabs = useMemo(() => {
    return tabs.reduce<LabelTabItem[]>((pre, curr) => {
      return pre.concat(curr.type === 'dropdown' ? curr.options : curr)
    }, [])
  }, [tabs])

  const handleTabChange = (key: string) => {
    setActiveTab(key)
    onChange(key)
  }

  useEffect(() => {
    handleTabChange(defaultKey || tabs?.[0]?.key)
  }, [defaultKey])

  useEffect(() => {
    const tabsContainer = document.getElementById('detail-tabs')
    if (tabsContainer) {
      setPortalRoot(tabsContainer)
    }
  }, [])

  return (
    <>
      <GlobalDropdownStyle />
      {portalRoot &&
        createPortal(
          <div className='flex'>
            {tabs.map(item =>
              isDropdownTabItem(item) ? (
                <Dropdown
                  key={item.key}
                  trigger={['click']}
                  menu={{
                    activeKey: activeTab,
                    items: item.options as unknown as ItemType[],
                    onClick: async e => {
                      const beforeChange = item.options.find(
                        o => o.key === e.key,
                      )?.beforeChange
                      if (beforeChange) {
                        const canChange = await beforeChange()
                        if (!canChange) {
                          return
                        }
                      }
                      handleTabChange(e.key)
                    },
                  }}
                >
                  <div
                    className={classNames(
                      'flex-center h-40px px-20 b-rd-8px cursor-pointer text-16px font-500 c-#17171d c-op-80 break-keep hover:c-primary',
                      {
                        'bg-#7b61ff bg-op-8 c-#7b61ff': !!item.options.find(
                          o => o.key === activeTab,
                        ),
                      },
                    )}
                  >
                    <span>
                      {item.options.find(o => o.key === activeTab)?.title ||
                        item.options[0].title}
                    </span>
                    <IconFont name='desc' className='ml-8px text-8px' />
                  </div>
                </Dropdown>
              ) : (
                <div
                  key={item.key}
                  className={classNames(
                    'flex-center h-40px px-20px b-rd-6px text-16px font-500 c-#17171d cursor-pointer break-keep hover:c-primary',
                    {
                      'bg-#7b61ff bg-op-8 c-#7b61ff': activeTab === item.key,
                      hidden: item.hide,
                    },
                  )}
                  onClick={async () => {
                    if (item.beforeChange) {
                      const canChange = await item.beforeChange()
                      if (!canChange) {
                        return
                      }
                    }
                    handleTabChange(item.key)
                  }}
                >
                  {item.label}
                </div>
              ),
            )}
          </div>,
          portalRoot,
        )}
      {!noContent &&
        flatTabs.map(({ key, keepAlive = false, content }) => {
          return keepAlive ? (
            <div
              key={`${key}-content`}
              className={classNames('h-full flex-1 overflow-hidden', {
                hidden: activeTab !== key,
              })}
            >
              {content}
            </div>
          ) : activeTab === key ? (
            content
          ) : null
        })}
    </>
  )
}
