import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useMemoizedFn, useThrottleFn } from 'ahooks'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import classNames from 'classnames'
import { IconFont, Markdown, Modal } from '@/components'
import {
  State,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket'
import { tokenStorage } from '@/utils/storage'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import type { PromptStructItem } from '@/apis/prompt'
import { tryGetObject } from '@/features/logger/components/loggerModal/util'

interface PromptToStructModalProps {
  open: boolean
  getPrompt: () => string
  onCancel: () => void
  onConfirm: (struct: PromptStructItem[]) => void
  onOpenTip: () => void
}

export const PromptToStructModal = memo((props: PromptToStructModalProps) => {
  const { open, getPrompt, onCancel, onConfirm, onOpenTip } = props

  const [loading, setLoading] = useState(true)
  const prevPrompt = useRef<string>()
  const token = tokenStorage.get()

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const {
    state,
    content,

    handleSendMessage,
  } = useGeneratePromptSocket(socketUrl)

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const { run: scrollToBottom, cancel } = useThrottleFn(() => {
    setTimeout(() => {
      const viewport = scrollRef.current?.osInstance()?.elements().viewport
      viewport?.scroll({
        top: viewport.scrollHeight + 200,
        behavior: 'smooth',
      })
    })
  })

  const scrollToTop = useMemoizedFn(() => {
    cancel()
    const viewport = scrollRef.current?.osInstance()?.elements().viewport
    viewport?.scroll({
      top: 0,
      behavior: 'smooth',
    })
  })

  const structPrompt = useMemo(() => {
    if ([State.end, State.result].includes(state)) {
      setLoading(false)
      const struct = tryGetObject(content)
      return (struct.prompt ?? []) as PromptStructItem[]
    }
    return []
  }, [state])

  const showContent = useMemo(() => {
    if ([State.end, State.result].includes(state)) {
      scrollToTop()
      return ''
    } else {
      scrollToBottom()
      return content ?? ''
    }
  }, [content, state])

  const isError = useMemo(() => {
    if (![State.end, State.result].includes(state)) return false
    if (content && !structPrompt.length) return true
    return false
  }, [state, structPrompt, content])

  const generateStruct = useMemoizedFn(() => {
    const prompt = getPrompt()
    prevPrompt.current = prompt
    setLoading(true)
    handleSendMessage(
      JSON.stringify({
        action: 'struct_prompt_template',
        params: {
          message: prompt,
          type: '模式切换',
        },
      }),
    )
  })

  const handleConfirm = useMemoizedFn(() => {
    onConfirm(structPrompt)
  })

  const handleClose = useMemoizedFn(() => {
    onCancel()

    if ([State.pending, State.start].includes(state)) {
      onOpenTip()
    }
  })

  useEffect(() => {
    if (!open) return

    if (getPrompt() === prevPrompt.current) return
    generateStruct()
  }, [open])

  return (
    <Modal
      maskClosable={false}
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      onOk={handleConfirm}
      width={960}
      // title='转为结构化'
      title='使用文本模式的prompt'
      styles={{ body: { padding: '24px 0 12px', height: 580 } }}
      footerClassName='border-none pt-0px p-24px pt-0px'
      cancelText='重新生成'
      cancelButtonProps={{
        disabled: loading,
        onClick: generateStruct,
      }}
      okText='使用'
      okButtonProps={{
        disabled: loading || isError,
      }}
    >
      <div className='h-full flex flex-col min-h-0px'>
        {(loading || isError) && (
          <div className='h-full px-24px relative'>
            <OverlayScrollbarsComponent
              ref={scrollRef}
              className={classNames(
                'overflow-y-auto h-full flex-auto bg-bg_3/8 p-12px rounded-6px',
                {
                  'outline-error outline-1px outline-solid': isError,
                },
              )}
              element='div'
              options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
              defer
            >
              <Markdown
                text={showContent || (!isError ? '优化中，请稍后...' : '')}
              />
            </OverlayScrollbarsComponent>
            {!isError && (
              <span className='flex items-center text-#8D8D99 font-normal absolute top-100% mt-12px'>
                <IconFont className='mr-4px' name='xiaoshizhi' />
                <span className='text-12px'>
                  转换中，关闭窗口转换进程将会在后台运行。
                </span>
              </span>
            )}
            {isError && (
              <span className='text-error absolute top-100% text-12px mt-12px'>
                生成失败，请重新生成
              </span>
            )}
          </div>
        )}

        {!loading && !isError && (
          <OverlayScrollbarsComponent
            ref={scrollRef}
            className='overflow-y-auto flex-auto px-24px'
            element='div'
            options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
            defer
          >
            {structPrompt.map((each, index) => {
              return (
                <div
                  key={each.type + index}
                  className='bg-bg_3/8 rounded-6px mb-8px last:mb-0px'
                >
                  <div className='h-36px flex items-center px-12px font-500'>
                    {each.title}
                  </div>
                  <div className='px-12 pb-12px leading-22px whitespace-pre-wrap'>
                    {each.content}
                  </div>
                </div>
              )
            })}
          </OverlayScrollbarsComponent>
        )}
      </div>
    </Modal>
  )
})
