import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { sideBarCollapseStorage } from '@/utils/storage'

interface LayoutStore {
  sideBarCollapsed: boolean
  toggleSideBarCollapsed: () => void
}

export const useLayoutStore = create<LayoutStore>((set, get) => ({
  sideBarCollapsed: sideBarCollapseStorage.get() ?? false,
  toggleSideBarCollapsed: () => {
    const current = get().sideBarCollapsed
    sideBarCollapseStorage.set(!current)
    set({
      sideBarCollapsed: !current,
    })
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('layoutStore', useLayoutStore)
}
