import { CardDataLabel } from './CardDataLabel'
import { GradientBorderAvatar } from './GradientBorderAvatar'

export interface RecommendCardProps {
  id: string
  image: string
  title: string
  desc: string
  skillCount: number
  docCount: number
  heat: number
  onClick: (id: string) => void
}

export function RecommendCard(props: RecommendCardProps) {
  const { id, image, title, desc, skillCount, docCount, heat, onClick } = props

  return (
    <div
      className='flex relative z-20 p-20px b-rd-12px b-1 b-#fff cursor-pointer hover:shadow-[0_8px_24px_0px_rgba(23,23,29,0.12)]'
      style={{
        // background:
        //   'linear-gradient(159deg, rgba(0, 216, 216, 0.08) 7%, rgba(61, 141, 255, 0.064) 30%, rgba(109, 80, 255, 0) 51%), #FFFFFF',
        background:
          'linear-gradient(158deg, rgba(123, 46, 255, 0.08) 7%, rgba(61, 106, 255, 0.064) 29%, rgba(64, 182, 255, 0) 48%), #FFFFFF',
      }}
      onClick={() => onClick(id)}
    >
      <div className='mr-20px'>
        <GradientBorderAvatar image={image} />
      </div>
      <div className='flex-1 pt-8px'>
        <div className='mb-12px text-16px font-600 text-#17171d truncate'>
          {title}
        </div>
        <div className='mb-16px h-44px line-height-22px text-12px text-#8d8d99 text-op-60 multi-line-text'>
          {desc}
        </div>
        <div className='flex flex-justify-between'>
          <div className='flex gap-16px'>
            <CardDataLabel iconName='jitai' count={skillCount} title='技能' />
            <CardDataLabel iconName='jiyi' count={docCount} title='文档' />
          </div>
          <CardDataLabel className='ml-16px' iconName='remen' count={heat} />
        </div>
      </div>
    </div>
  )
}
