import { slice } from 'lodash-es'
import { useMemo, useRef } from 'react'
import type { PopoverProps } from 'antd'
import { Popover } from 'antd'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Global, css } from '@emotion/react'
import { DocumentTag } from '@/features/datastore/components/DocumentTag.tsx'

// 处理打开Modal时 悬停在popover上不关闭的问题
function GLobalPopoverStyle() {
  return (
    <Global
      styles={css`
        .__safe_popover_wrapper__batteryeah {
          z-index: 900 !important;
        }
      `}
    />
  )
}

const Wrapper = styled.div``
export function DocumentTagList(props: {
  list: string[]
  showMaxCount?: number
  onClick?: (value?: any, idx?: number) => void
  getPopupContainer?: PopoverProps['getPopupContainer']
  className?: string
}) {
  const ref = useRef<any>()
  const {
    list = [],
    showMaxCount = 2,
    onClick,
    getPopupContainer,
    className,
  } = props
  const hasMore = useMemo(() => {
    return list.length > showMaxCount
  }, [list])

  const tags = useMemo(() => {
    return {
      displayList: slice(list, 0, showMaxCount) || [],
      moreList: slice(list, showMaxCount) || [],
    }
  }, [list, showMaxCount])

  const popoverContent = useMemo(() => {
    return (
      <div className='max-w-40vw flex items-center flex-1 gap-8px flex-wrap'>
        {list.map((tag, index) => {
          return (
            <DocumentTag
              className={classNames('line-height-14px! text-14px', className)}
              maxTagWidth='fit-content'
              key={tag + index}
              closable={false}
              onClick={() => {
                onClick?.(tag, index)
              }}
            >
              {tag}
            </DocumentTag>
          )
        })}
      </div>
    )
  }, [list, hasMore, onClick])
  return (
    <Wrapper ref={ref}>
      <GLobalPopoverStyle />
      <Popover
        arrow={false}
        rootClassName='__safe_popover_wrapper__batteryeah'
        content={popoverContent}
        placement='bottomLeft'
        autoAdjustOverflow={false}
        getPopupContainer={getPopupContainer}
      >
        <div className='flex items-center flex-1 flex-wrap gap-8px'>
          <div className='flex items-center gap-8px flex-wrap'>
            {tags.displayList.map((tag, index) => {
              return (
                <DocumentTag
                  className={classNames(
                    'line-height-14px! text-14px truncate max-w-78px',
                    className,
                  )}
                  maxTagWidth='fit-content'
                  key={tag + index}
                  onClick={() => {
                    onClick?.(tag, index)
                  }}
                  closable={false}
                >
                  {tag}
                </DocumentTag>
              )
            })}
          </div>
          <div className='flex-[0_0_auto]'>
            {hasMore ? (
              <DocumentTag
                className={classNames('line-height-14px! text-14px', className)}
                maxTagWidth='fit-content'
                closable={false}
                onClick={() => {
                  onClick?.()
                }}
              >
                +{tags.moreList.length}
              </DocumentTag>
            ) : (
              <div />
            )}
          </div>
        </div>
      </Popover>
    </Wrapper>
  )
}
