import { IconFont } from '@/components'

interface ShowMoreProps {
  expanded?: boolean
  onClick?: (expanded: boolean) => void
  text?: string
  expandedText?: string
}

export function ShowMore(props: ShowMoreProps) {
  const {
    text = '更多',
    expandedText = '收起',
    expanded = false,
    onClick,
  } = props
  return (
    <div
      className='h-48px flex justify-center items-center text-12px text-#8D8D99 b-t-1 b-t-#EDEDF0 '
      onClick={() => onClick?.(expanded)}
    >
      <span>{expanded ? expandedText : text}</span>
      <IconFont
        name='arrow'
        className='text-8px ml-10'
        style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
      ></IconFont>
    </div>
  )
}
