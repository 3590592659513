import { Switch } from 'antd'
import type { PluginNodeFiledType } from '../constants'
import { PluginNodeFieldTypes } from '../constants'
import { CodeEditor } from '@/features/editor/CodeEditor'
import { FormList } from '../../components/Forms/FormList'
import type { FieldItem, FieldExtraItem } from '../../start'
import { SliderSingle, GroupSelect } from '@/components'
import { CommonSelectEditor } from '../../components/CommonSelectEditor'
import { FlowSingleSelectEditor } from '../../components/FlowSingleSelectEditor'
import { RadioGroup } from './RadioGroup'
import { MultiInput } from './MultiInput'

export interface ComponentMap
  extends Record<
    PluginNodeFiledType,
    (item: FieldItem & FieldExtraItem) => React.ReactNode
  > {}
export const componentMap: ComponentMap = {
  [PluginNodeFieldTypes.Input]: item => (
    <CodeEditor
      className='ace-gray w-100% !bg-[#f6f6f9]'
      wrapEnabled={false}
      singleLine
      height='32px'
      value={item.value}
      width='100%'
      disabled={item.disabled}
      setOptions={{ maxLines: 1 }}
      variableTipsContainer={item.variableTipsContainer}
      variables={item.variables}
      placeholder={item.placeholder}
    />
  ),

  [PluginNodeFieldTypes.Select]: item => (
    <FlowSingleSelectEditor
      placeholder={item.placeholder || ''}
      variables={[]}
      variableTipsContainer={item.variableTipsContainer}
      tags={item.options!}
    />
  ),
  [PluginNodeFieldTypes.MultiSelect]: item => (
    <CommonSelectEditor
      placeholder={item.placeholder || ''}
      multiple
      variables={[]}
      variableTipsContainer={item.variableTipsContainer}
      tags={item.options!}
    />
  ),
  [PluginNodeFieldTypes.Textarea]: item => {
    const options = item.options as unknown as {
      maxLines?: number
      minLines?: number
    }
    return (
      <CodeEditor
        className='ace-gray w-100%'
        value={item.value}
        width='100%'
        setOptions={{
          maxLines: options?.maxLines || 6,
          minLines: options?.minLines || 3,
        }}
        variableTipsContainer={item.variableTipsContainer}
        variables={item.variables}
        placeholder={item.placeholder}
      />
    )
  },
  [PluginNodeFieldTypes.Json]: item => (
    <FormList
      type='JSON'
      variableTipsContainer={item.variableTipsContainer}
      variables={item.variables}
      placeholder={item.placeholder}
    />
  ),
  [PluginNodeFieldTypes.Slider]: item => (
    <SliderSingle
      min={item?.limit?.min ?? 0}
      max={item?.limit?.max ?? 3}
      step={1}
      controls={false}
      placeholder={item.placeholder}
    />
  ),
  [PluginNodeFieldTypes.Switch]: _item => (
    <Switch size='small' value={_item.value} />
  ),
  [PluginNodeFieldTypes.Radio]: _item => {
    return (
      <RadioGroup
        options={_item.options}
        disabled={_item.disabled}
        value={_item.value}
        optionType={_item.optionType}
        size='small'
      />
    )
  },
  [PluginNodeFieldTypes.MultiInput]: _item => {
    return (
      <MultiInput
        variables={_item.variables}
        disabled={_item.disabled}
        variableTipsContainer={_item.variableTipsContainer}
        limit={_item.limit}
        showButton={_item.showAddButton}
        placeholder={_item.placeholder}
        btnText={`添加${_item.label}`}
      />
    )
  },
  [PluginNodeFieldTypes.ModelSelect]: item => (
    <GroupSelect {...item} size='small' />
  ),
}
