import { ReactFlowProvider } from 'reactflow'
import router from '@/router'
import { AntdProvider } from './antdProvider'
import { EmotionProvider } from './themeProvider'
import { AuthProvider } from './authProvider'

export function AppProvider({ children }: React.PropsWithChildren) {
  return (
    <AntdProvider>
      <EmotionProvider>
        <ReactFlowProvider>
          <AuthProvider router={router}>{children}</AuthProvider>
        </ReactFlowProvider>
      </EmotionProvider>
    </AntdProvider>
  )
}
