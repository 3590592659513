import cn from 'classnames'
import { Radio } from 'antd'
import SelectViewModeSelectImage from '@/assets/nodes/select-view-mode-1.png'
import SelectViewModeFlatImage from '@/assets/nodes/select-view-mode-2.png'
import { SelectViewMode } from '@/features/nodes/start'

export interface SelectViewModeConfigProps {
  value?: string
  onChange?: (value: string) => void
}

const options = [
  {
    label: '下拉列表',
    value: SelectViewMode.SELECT,
    image: SelectViewModeSelectImage,
  },
  {
    label: '平铺选项',
    value: SelectViewMode.Flat,
    image: SelectViewModeFlatImage,
  },
]

export function SelectViewModeConfig(props: SelectViewModeConfigProps) {
  const { value, onChange } = props

  return (
    <div className='flex items-center gap-12'>
      {options.map(option => {
        return (
          <div
            key={option.value}
            className={cn(
              'flex-center-between p-4px b-1 b-#EDEDF0 b-rd-8px flex-1 cursor-pointer',
              {
                'b-primary!': value === option.value,
              },
            )}
            onClick={() => {
              onChange?.(option.value)
            }}
          >
            <div className='flex items-center pl-8'>
              <Radio checked={option.value === value} />
              <span className='ml-8px'>{option.label}</span>
            </div>
            <div>
              <img
                className='w-64px h-57px'
                src={option.image}
                alt={option.label}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
