import { Table as AntdTable } from 'antd'
import styled from '@emotion/styled'
import { rgba } from 'polished'

export const Table = styled(AntdTable<any>)`
  .ant-table-thead th.ant-table-column-sort {
    background-color: #fff !important;
  }
  .ant-table-thead th.ant-table-column-sort:hover {
    background-color: #f0f0f0 !important;
  }
  .ant-table {
    border-radius: ${props => (props.pagination ? '8px 8px 0 0' : '8px')};
    .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          .ant-table-column-sorters {
            justify-content: flex-start !important;
            gap: 8px;
            .ant-table-column-title {
              flex: none !important;
            }
          }
        }
      }
    }
  }

  .ant-table-thead {
    border-radius: 0;
    .ant-table-cell {
      border: none;
      padding: 13px 16px;
      border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.font_1, 0.12)};
      &::before {
        display: none;
      }
      font-weight: 600;
      color: ${({ theme }) => theme.colors.font_1};
      background-color: #ffffff;
      &:first-of-type {
        border-radius: 4px 0 0 0;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .ant-table-body {
    height: ${props => props.scroll?.y ?? 'initial'};
  }
  .ant-table-tbody {
    tr td {
      border-bottom: none;
    }
    .ant-table-row {
      &:hover {
        .ant-table-cell {
          background-color: ${({ theme }) => theme.colors.bg_2};
        }
      }
      .ant-table-cell {
        border-bottom: 1px solid
          ${({ theme }) => rgba(theme.colors.font_1, 0.12)};
        color: ${({ theme }) => theme.colors.font};
        padding: 16px 16px;
        background-color: rgba(247, 247, 255, 0.04);
      }
    }
  }
  .ant-pagination {
    border-top: 1px solid ${({ theme }) => rgba(theme.colors.font_1, 0.12)};
    padding: 18px;
    margin: 0 !important;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
  }
`
