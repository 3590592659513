import { memo, useEffect, useMemo, useRef, useState } from 'react'
import type { UploadProps } from 'rc-upload'
import Upload from 'rc-upload'
import classNames from 'classnames'
import { IconFont } from '..'
import { useOSSClient } from '@/hooks/useOSSClient'
import { ImagePreviewModal } from '../modal/ImagePreviewModal'
import { useModal } from '@/hooks/useModal'

interface FileListProps {
  files: string[]
  onItemRemove: (url: string) => void
  onFileAdd: (url: string) => void
  onFocus: () => void
}

const FileItem = memo(
  (props: { file: string; onRemove: (url: string) => void }) => {
    const { file, onRemove } = props
    const [imagePreviewModal] = useModal(ImagePreviewModal)
    const [isHovering, setIsHovering] = useState(false)
    const previewClassName = useMemo(() => {
      return classNames(
        'absolute top-0 left-0 w-100% h-100% b-rd-8px bg-#000 bg-op-24 cursor-pointer',
        {
          'flex-center': isHovering,
          hidden: !isHovering,
        },
      )
    }, [isHovering])

    return (
      <div
        className='group relative mt-12px mr-12px w-52px h-52px b-rd-8px'
        onMouseEnter={() => {
          setIsHovering(true)
        }}
        onMouseLeave={() => {
          setIsHovering(false)
        }}
      >
        <img
          className='w-100% h-100% object-cover b-rd-8px overflow-hidden'
          src={file}
          alt=''
          onMouseEnter={() => {
            setIsHovering(true)
          }}
        />
        <div
          className={previewClassName}
          onClick={() => {
            imagePreviewModal.open({
              url: file,
            })
          }}
        >
          <IconFont name='fangtai-1' className='text-#fff' />
        </div>
        <IconFont
          name='tupianshanshu'
          className='absolute z-4 right--7px top--7px text-16px text-#8d8d99 cursor-pointer'
          onMouseEnter={e => {
            e.stopPropagation()
            setIsHovering(false)
          }}
          onClick={() => onRemove(file)}
        />
      </div>
    )
  },
)

export function FileList(props: FileListProps) {
  const { files, onItemRemove, onFileAdd, onFocus } = props

  const [list, setList] = useState<string[]>([])
  const fileNameRef = useRef('')
  const { ossClient } = useOSSClient()

  const beforeUpload: UploadProps['beforeUpload'] = async file => {
    fileNameRef.current = file.name
  }

  const customRequest: UploadProps['customRequest'] = async option => {
    const { file } = option
    if (ossClient && fileNameRef.current) {
      ossClient.useBucket(import.meta.env.VITE_AI_DATASTORE_RESOURCE_BUCKET)
      const { res, url } = await ossClient.put(
        `/issueReport/${Date.now()}/${fileNameRef.current}`,
        file,
      )
      if (res.status === 200) {
        // setList(l => l.concat(url))
        onFileAdd(url)
      }
    }
  }

  useEffect(() => {
    setList(files)
  }, [files])

  return (
    <div
      className='flex justify-between items-end p-12px cursor-text'
      onClick={onFocus}
    >
      <div className='flex-1 flex flex-wrap max-w-[calc(100%-24px)]'>
        {list.length ? (
          list.map(file => (
            <FileItem
              key={file}
              file={file}
              onRemove={() => onItemRemove(file)}
            />
          ))
        ) : (
          <div className='text-#8d8d99 text-op-60'>粘贴图片上传</div>
        )}
      </div>
      <Upload beforeUpload={beforeUpload} customRequest={customRequest}>
        <div className='flex-center w-24px h-24px b-rd-4px hover:bg-#626999 hover:bg-op-8 cursor-pointer'>
          <IconFont name='shangzhuantupian' className='text-#8d8d99' />
        </div>
      </Upload>
    </div>
  )
}
