import { useState } from 'react'
import styled from '@emotion/styled'
import { Radio, Form, Tooltip, Alert, Switch } from 'antd'
import classNames from 'classnames'
import {
  FormItem,
  Modal,
  IconFont,
  AppLogo,
  usePageModal,
  SliderSingle,
} from '@/components'
import type { AgentDataset } from '@/apis/agent'
import { useWorkspaceStore } from '@/store'
import { PartitionCategoryType } from '@/apis/datastore/types'

const DEFAULT_DOCUMENT_NUM = 3

interface IKnowledgeSettingModalProps {
  open?: boolean
  onCancel?: () => void
  createLoading?: boolean
  onOk: (values: Record<string, any>) => void
  currentDataset?: AgentDataset
  onChange?: (v: any) => void
}

const ItemWrap = styled.div`
  &:hover {
    .link {
      opacity: 1;
    }
    background: rgba(98, 105, 153, 0.0784);
  }
`

const AgentSearchMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const SearchResultSettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default function KnowledgeDocumentSettingModal(
  props: IKnowledgeSettingModalProps,
) {
  const pageModal = usePageModal()
  const { open, onCancel, onOk, createLoading, currentDataset } = props
  const [value, _setValue] = useState<number | undefined>(
    currentDataset?.partition_id || undefined,
  )
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const [form] = Form.useForm()
  const hit_strategy = Form.useWatch('hit_strategy', form)
  const num = Form.useWatch('num', form)
  const ranking_strategy = Form.useWatch('ranking_strategy', form)

  if (!currentDataset) {
    return null
  }

  const isQARepo =
    currentDataset.partition_category === PartitionCategoryType.QA

  return (
    <Modal
      okButtonProps={{
        loading: createLoading,
        className: '!font-400',
        disabled: !value,
      }}
      styles={{
        body: {
          padding: '16px 16px 0 16px',
        },
      }}
      width={1120}
      maskClosable={false}
      cancelButtonProps={{ className: '!font-400' }}
      onOk={async () => {
        const values = form.getFieldsValue(true)
        if (values) {
          await onOk({
            ...values,
            ranking_strategy: values.ranking_strategy ? 1 : 2,
          })
        }
      }}
      title='查询设置'
      open={open}
      onCancel={onCancel}
    >
      <div className='min-h-344px flex flex-col gap-24px p-8px'>
        <Form
          requiredMark={false}
          form={form}
          layout='vertical'
          initialValues={{
            num: currentDataset?.num ?? DEFAULT_DOCUMENT_NUM,
            threshold: currentDataset?.threshold ?? 0.8,
            hit_strategy: currentDataset?.hit_strategy || 1,
            ranking_strategy: currentDataset.ranking_strategy === 1,
            similarity: currentDataset.similarity ?? 0.4,
            ...(isQARepo && {
              random_result: currentDataset.random_result ?? false,
            }),
          }}
        >
          <AgentSearchMethodWrapper>
            <div className='flex flex-1 items-center group'>
              <div className='flex items-center'>
                <AppLogo
                  size={40}
                  fillSize={18}
                  className='!rounded-4px'
                  value={currentDataset.partition_icon}
                  color={currentDataset.partition_icon_color}
                  type='icon'
                />
                <div className='ml-12px flex flex-col justify-center max-400px'>
                  <p className=' truncate overflow-hidden font-500 flex items-center'>
                    <span className='c-#17171D text-14px/16px'>
                      {currentDataset?.partition_name}
                    </span>
                  </p>
                </div>
              </div>
              <span
                className='group-hover:op-100 text-16px ml-12 mr-24px op-0 cursor-pointer rounded-4px w-24px h-24px hover:bg-bg_3 hover:bg-op-12 c-#626999 c-op-60 flex-center'
                onClick={() =>
                  pageModal.show({
                    url: `/datastores/${currentWorkspaceId}/${currentDataset.partition_id}/documents?independent=1`,
                  })
                }
              >
                <IconFont name='super-link' />
              </span>
            </div>
          </AgentSearchMethodWrapper>
          <AgentSearchMethodWrapper>
            <p className='text-14px c-#17171D line-height-16px font-500 flex gap-4px items-center'>
              查询方式
              <Tooltip title='设置知识库查询的方式'>
                <IconFont
                  name='jieshishuimeng'
                  style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                  className='cursor-pointer'
                />
              </Tooltip>
            </p>
            <div className='mt-12px'>
              <FormItem name='hit_strategy' className='mb-12 w-full flex-1'>
                <Radio.Group className='flex flex-1 justify-between items-center gap-12px flex-nowrap'>
                  {[
                    {
                      label: '混合查询',
                      title: '使用语义和关键词进行查询，并综合排序，效果最好',
                      value: 1,
                      recommend: true,
                    },
                    {
                      label: '关键词查询',
                      title: '基于关键词查询，适合通过人名、地名等查询具体内容',
                      value: 2,
                    },
                    {
                      label: '语义查询',
                      title: '语义相似查询，适合通过描述、问题语义查询相似答案',
                      value: 3,
                    },
                  ].map(item => {
                    return (
                      <ItemWrap
                        key={item.value}
                        onClick={() => {
                          form?.setFieldsValue({
                            hit_strategy: item.value,
                          })
                        }}
                        className={classNames(
                          'flex-center-between pr-8px pl-16px py-16px b-1 rounded-8px b-line b-op-60 cursor-pointer',
                          {
                            '!b-primary': hit_strategy === item.value,
                          },
                        )}
                        style={{ width: '33%', flex: '33%', height: '100%' }}
                      >
                        <div className='flex flex-1'>
                          <div>
                            <p className='flex text-12px line-height-16px flex items-center justify-between'>
                              <span className='block w-100% text-14px truncate max-w-400px c-[rgba(23,23,29)] flex gap-4px'>
                                {item.label}
                                {item.recommend && (
                                  <span className='font-500 text-9px px-4px py-2px border-rd-100px c-#FF8056 bg-#FFECE6 line-height-9px'>
                                    推荐
                                  </span>
                                )}
                              </span>
                              <Radio value={item.value} />
                            </p>
                            <div className='mt-8px'>
                              <p className='flex justify-center'>
                                <span className='text-12px c-#8D8D99 font-400 line-height-16px'>
                                  {item.title}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </ItemWrap>
                    )
                  })}
                </Radio.Group>
              </FormItem>
            </div>
          </AgentSearchMethodWrapper>
          <SearchResultSettingWrapper>
            <p className='text-14px c-#17171D line-height-16px font-500 mb-12px'>
              查询结果设置
            </p>
            <div className='flex items-center gap-12'>
              <FormItem
                name='num'
                label='知识匹配最大结果数'
                className='mb-12 w-full flex-1'
                tooltip={
                  '从知识库中查询 X 条结果（ X 等于你设置的数字，X 的最大值为30） 建议 X 值不超过 10，避免超出大模型支持的最大 Token 数量'
                }
              >
                <SliderSingle size='small' min={0} max={30} step={1} />
              </FormItem>
            </div>
          </SearchResultSettingWrapper>
          {num && num > 10 && (
            <Alert
              className='mt-8px b-0px text-12px'
              showIcon
              message={
                <p className='font-400 text-12px/16px c-#17171D'>
                  建议最大结果数不超过10，避免超出大模型支持的最大Token数
                </p>
              }
              type='warning'
            />
          )}
          <SearchResultSettingWrapper>
            <div className='mt-12px mb-20px flex gap-8px items-center'>
              <div>结果重排算法</div>
              <Tooltip title='可将查询结果精准排序，提高匹配率'>
                <IconFont
                  name='jieshishuimeng'
                  style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                  className='cursor-pointer ml--4px'
                />
              </Tooltip>
              <Form.Item noStyle name='ranking_strategy'>
                <Switch size='small' />
              </Form.Item>
            </div>
          </SearchResultSettingWrapper>

          {ranking_strategy && (
            <SearchResultSettingWrapper>
              <FormItem
                name='similarity'
                label='最低相似度'
                className='mb-12 w-full flex-1'
                tooltip={
                  '设定的一个阈值，过滤掉相似度低于此值的匹配结果，一般0.4属于适中，0.5以上属于精确。'
                }
              >
                <SliderSingle size='small' min={0} max={1} step={0.01} />
              </FormItem>
            </SearchResultSettingWrapper>
          )}
          {isQARepo && (
            <SearchResultSettingWrapper>
              <FormItem
                name='random_result'
                label='输出答案'
                className='mb-12 w-full flex-1'
                tooltip={'输出答案说明描述'}
              >
                <Radio.Group>
                  <Radio value={false}>全部</Radio>
                  <Radio value={true}>随机一个</Radio>
                </Radio.Group>
              </FormItem>
            </SearchResultSettingWrapper>
          )}
        </Form>
      </div>
    </Modal>
  )
}
