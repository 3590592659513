/**
 * 设置 plugin 功能的弹窗内容
 */
import type { FormInstance, TableProps } from 'antd'
import _ from 'lodash-es'
import { Form as AntdForm, Tooltip, Table, Spin } from 'antd'
import { useRequest } from 'ahooks'
import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import type {
  AgentListSystemSkillItem,
  ParametersWithInput,
  ParametersWithProperty,
} from '@bty/chat-types'
import { FormItem, TextArea, IconFont, usePageModal } from '@/components'
import { componentMap } from '@/features/nodes/plugin/components/componentMap'
import { PluginNodeFieldTypes } from '@/features/nodes/plugin/constants'
import { getPluginInfo } from '@/apis/plugins'
import { PluginConfFixed } from './PluginConfFixed'
import { PluginConfOutputs } from './PluginConfOutputs'

const Form = styled(AntdForm)`
  .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-form-item-label {
    margin-bottom: 4px;
  }
  .keyu-form-item-label {
    font-size: 14px;
    font-weight: 500;
    color: #17171d;

    & > span {
      line-height: 16px;
    }
  }
`

const StyledTable = styled(Table)`
  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px !important;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-cell,
  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    padding: 10px 12px;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    font-weight: 500;
    line-height: 16px;
    color: #17171d;
    font-family: PingFang SC;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    border-bottom: 1px solid rgba(225, 225, 229, 0.6);
    border-top: 1px solid rgba(225, 225, 229, 0.6);
  }

  :where(.css-dev-only-do-not-override-rztbr1)[class^='ant-table']
    [class^='ant-table']::before {
    display: none;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 16px 12px;
  }
`

function toKeyValue<T>(origin: Record<string, any>) {
  return Object.keys(origin).reduce<Record<string, T>>((init, key) => {
    init[key] = origin[key].value
    return init
  }, {})
}

interface PluginConfProps {
  form: FormInstance
  conf: AgentListSystemSkillItem
}

interface Parameter {
  example: any
  type: string
  name: string
  key: string
  required: boolean
  defaultValue: any
  description: any
  component?: string
  extraConf: Record<string, any>
}

function getParamDefaultValue(
  key: string,
  parameters: ParametersWithInput | ParametersWithProperty,
) {
  let defaultValue
  if ((parameters as ParametersWithInput)?.inputs) {
    defaultValue = (parameters as ParametersWithInput).inputs.find(item => {
      return item.key === key
    })?.default
  } else {
    defaultValue = (parameters as ParametersWithProperty).properties?.[key]
      ?.default
  }

  defaultValue =
    _.isNil(defaultValue) || defaultValue === '' ? undefined : defaultValue

  return defaultValue
}

export function PluginConf({ form, conf }: PluginConfProps) {
  const { function_id } = conf
  const pageModal = usePageModal()

  const { data: pluginInfoData, loading } = useRequest(getPluginInfo, {
    defaultParams: [function_id],
  })

  useEffect(() => {
    const inputs = conf.metadata?.properties?.inputs
    const inputValues = inputs
      ? toKeyValue<(typeof inputs)[string]['value']>(inputs)
      : undefined

    const outputs = conf.metadata?.properties?.outputs
    const outputValues = outputs ? toKeyValue<string>(outputs) : undefined

    form.setFieldsValue({
      description: conf.description,
      ...inputValues,
      ...outputValues,
    })
  }, [conf])

  const parameters = useMemo(() => {
    if (!conf.parameters || !pluginInfoData?.parameters) return []

    try {
      if ((pluginInfoData.parameters as ParametersWithInput).inputs) {
        return (pluginInfoData.parameters as ParametersWithInput).inputs.map(
          item => {
            const defaultValue = getParamDefaultValue(item.key, conf.parameters)
            return {
              type:
                (item.field_type as { type: string })?.type ?? item.field_type,
              name: item.name ?? '未命名',
              key: item.key,
              required: item.required,
              defaultValue,
              example: item.example,
              description:
                (item.description as { description: string })?.description ??
                item.description,
              component: item.component,
              extraConf: Object.assign(
                { label: item.name },
                _.omit(item, [
                  'name',
                  'key',
                  'required',
                  'default',
                  'description',
                  'component',
                ]),
              ),
            }
          },
        )
      } else if (
        (pluginInfoData.parameters as ParametersWithProperty).properties
      ) {
        return Object.entries(
          (pluginInfoData.parameters as ParametersWithProperty).properties,
        ).map(([key, property]) => {
          const defaultValue = getParamDefaultValue(key, conf.parameters)
          return {
            key,
            name: property.name ?? '未命名',
            type: (property.type as { type: string })?.type ?? property.type,
            description:
              (property.description as { description: string })?.description ??
              property.description,
            defaultValue,
            example: property.example,
            required: (
              pluginInfoData.parameters as ParametersWithProperty
            ).required.includes(key),
            component: property.component,
            extraConf: Object.assign(
              { label: property.name },
              _.omit(property, [
                'name',
                'required',
                'default',
                'description',
                'component',
              ]),
            ),
          }
        })
      }
    } catch (e) {}

    return []
  }, [pluginInfoData, conf])

  const parameterTableConfig = useMemo(() => {
    const columns = [
      {
        title: '参数名称',
        key: 'parameter',
        width: 200,
        render: (_: any, col: Parameter) => {
          return (
            <div>
              <div className='flex gap-[6px]'>
                <span>{col.name}</span>
                {col.required ? <span className='c-[#FF5219]'>*</span> : null}
                <div
                  style={{
                    height: 'fit-content',
                    border: '1px solid #E1E1E5',
                    borderRadius: 4,
                    padding: '2px 4px',
                  }}
                  className='c-[#8D8D99] text-[10px] font-medium'
                >
                  {col.type}
                </div>
              </div>
              <p className='mt-[4px] c-[#8D8D99] text-[12px] leading-[12px]'>
                {col.key}
              </p>
            </div>
          )
        },
      },
      {
        title: '描述',
        key: 'description',
        dataIndex: 'description',
        render: (value: string) => (
          <span className='text-[14px] leading-[20px]'>{value}</span>
        ),
      },
      {
        title: '默认值',
        width: 240,
        key: 'defaultValue',
        dataIndex: 'defaultValue',
        render: (value: any, col: Parameter) => {
          const componentKey = (
            _.values(PluginNodeFieldTypes) as string[]
          ).includes(col.component as string)
            ? col.component
            : PluginNodeFieldTypes.Input
          const Comp = (
            componentMap as unknown as Record<
              string,
              (_item: any) => React.ReactNode
            >
          )[componentKey as string]

          if (
            componentKey === PluginNodeFieldTypes.Input ||
            componentKey === PluginNodeFieldTypes.Json
          ) {
            col.extraConf.placeholder =
              typeof col.example === 'object'
                ? JSON.stringify(col.example, null, 2)
                : _.isNil(col.example)
                  ? undefined
                  : String(col.example)
            value =
              typeof value === 'object'
                ? JSON.stringify(value, null, 2)
                : _.isNil(value)
                  ? undefined
                  : String(value)
          }

          return (
            <FormItem
              style={{ margin: 0 }}
              initialValue={value}
              name={['parameters', col.key]}
            >
              {Comp({ ...col.extraConf })}
            </FormItem>
          )
        },
      },
    ]
    const dataSource = parameters ?? []

    return { columns, dataSource }
  }, [parameters])

  if (loading) {
    return (
      <div className='w-100% mt-[150px] flex justify-center items-center'>
        <Spin />
      </div>
    )
  }

  const inputs = conf.metadata?.properties?.inputs

  const outputs = conf.metadata?.properties?.outputs

  const hasInputs = !!(inputs && Object.keys(inputs).length > 0)

  const hasOutputs = !!(outputs && Object.keys(outputs).length > 0)

  const icon = conf.metadata?.icon

  return (
    <div>
      <div className='flex items-center mb-20px'>
        {icon?.startsWith('https') ? (
          <img
            className='w-40px h-40px rounded-8px object-contain'
            src={icon}
          />
        ) : (
          <span
            className='w-40px h-40px rounded-8px text-20px/40px text-center'
            style={{ backgroundColor: conf.metadata?.color }}
          >
            {icon}
          </span>
        )}
        <span className='text-16px font-medium text-font ml-12px'>
          {conf.display_name}
        </span>
        <span
          className='w-24px h-24px flex items-center justify-center rounded-4px text-16px ml-8 cursor-pointer c-#626999 c-op-60 hover:bg-[rgba(98,105,153,0.12)]'
          onClick={e => {
            e.stopPropagation()
            pageModal.show({
              url: `/plugin/info/${function_id}?pageType=infoOnly`,
            })
          }}
        >
          <IconFont name='super-link' />
        </span>
      </div>
      <Form form={form} layout='vertical' requiredMark={false} preserve={false}>
        <FormItem
          name='description'
          label='插件描述'
          rules={[{ required: true, message: '请输入插件描述' }]}
          required
        >
          <TextArea
            className='!resize-none rounded-8px !h-72px'
            maxLength={300}
            showCount
            placeholder='这个插件是做什么的，在什么情况下使用'
          />
        </FormItem>
        <div className='mt-[32px] mb-[32px]'>
          <div className='flex gap-[4px] mb-[12px]'>
            <h1 className='text-[14px] font-medium'>参数配置</h1>
            <Tooltip title='参数描述'>
              <IconFont
                color='rgba(141, 141, 153, 0.4)'
                name='jieshishuimeng'
              />
            </Tooltip>
          </div>
          {parameters?.length ? (
            <StyledTable
              pagination={false}
              {...(parameterTableConfig as TableProps)}
            />
          ) : (
            <p className='text-[14px] leading-[22px] c-[#17171D]'>
              该插件无参数
            </p>
          )}
        </div>
        {hasInputs && <PluginConfFixed conf={inputs} />}
        {hasOutputs && <PluginConfOutputs conf={outputs} />}
      </Form>
    </div>
  )
}
