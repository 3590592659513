import { createContext, useContext } from 'react'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-github'

import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Modal } from '@/components'
import { Header } from './components/Header'

const FORMAT_TIME = 'YYYY年MM月DD日 HH:mm:ss'

function getColor(newValue: string, oldValue: string, highlightColor: string) {
  return newValue === oldValue ? 'inherit' : highlightColor
}

const PromptDiffModalWrapper = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 8px;
  }
`

export const PromptDiffModalContext = createContext<{
  visible: boolean
  historyDate: string
  data: Record<string, any>
  oldData: Record<string, any>
  getCurrentResult: () => string
  handleOpen: (item: Record<string, any>) => void
  handleClose: () => void
  readOnly: boolean
}>({
  data: {},
  oldData: {},
  visible: false,
  getCurrentResult: () => '',
  handleOpen: () => {},
  handleClose: () => {},
  readOnly: false,
  historyDate: '',
})

export function PromptDiffModal(props: any) {
  const { leftNode, rightNode, rightExtraNode } = props
  const {
    visible,
    data = {},
    handleClose,
    oldData,
    historyDate,
  } = useContext(PromptDiffModalContext)

  return (
    <PromptDiffModalWrapper
      open={visible}
      title='Prompt对比'
      width={'78vw'}
      footer={null}
      destroyOnClose={true}
      closeIcon={false}
      onCancel={() => {
        handleClose()
      }}
      maskClosable={false}
      bodyStyle={{
        height: '88vh',
        padding: '4px 24px 12px 24px',
        overflow: 'auto',
        boxSizing: 'border-box',
      }}
    >
      <div className='relative w-100% flex box-border gap-24px'>
        <div className='left flex-1'>
          {/* '#acf2bd' : '#ffeef0' */}
          <Header
            title={
              historyDate ? dayjs(historyDate).format(FORMAT_TIME) : '当前'
            }
            model={data?.inputs?.model}
            modelColor={getColor(
              data?.inputs?.model,
              oldData?.inputs?.model,
              '#acf2bd',
            )}
            temperature={data?.inputs?.temperature}
            temperatureColor={getColor(
              data?.inputs?.temperature,
              oldData?.inputs?.temperature,
              '#acf2bd',
            )}
            topP={data?.inputs?.top_p}
            topPColor={getColor(
              data?.inputs?.top_p,
              oldData?.inputs?.top_p,
              '#acf2bd',
            )}
            presencePenalty={data?.inputs?.presence_penalty}
            presencePenaltyColor={getColor(
              data?.inputs?.presence_penalty,
              oldData?.inputs?.presence_penalty,
              '#acf2bd',
            )}
            frequencyPenalty={data?.inputs?.frequency_penalty}
            frequencyPenaltyColor={getColor(
              data?.inputs?.frequency_penalty,
              oldData?.inputs?.frequency_penalty,
              '#acf2bd',
            )}
          />
          {leftNode}
        </div>
        <div className='right flex-1'>
          <Header
            extraNode={rightExtraNode}
            title='对比记录'
            model={oldData?.inputs?.model}
            modelColor={getColor(
              oldData?.inputs?.model,
              data?.inputs?.model,
              '#fdb8c0',
            )}
            temperature={oldData.inputs?.temperature}
            temperatureColor={getColor(
              oldData?.inputs?.temperature,
              data?.inputs?.temperature,
              '#fdb8c0',
            )}
            topP={oldData?.inputs?.top_p}
            topPColor={getColor(
              data?.inputs?.top_p,
              oldData?.inputs?.top_p,
              '#fdb8c0',
            )}
            presencePenalty={oldData?.inputs?.presence_penalty}
            presencePenaltyColor={getColor(
              data?.inputs?.presence_penalty,
              oldData?.inputs?.presence_penalty,
              '#fdb8c0',
            )}
            frequencyPenalty={oldData?.inputs?.frequency_penalty}
            frequencyPenaltyColor={getColor(
              data?.inputs?.frequency_penalty,
              oldData?.inputs?.frequency_penalty,
              '#fdb8c0',
            )}
          />
          {rightNode}
        </div>
      </div>
    </PromptDiffModalWrapper>
  )
}
