import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

interface DrawerModalStore {
  activeModals: string[]
  openModal: (modalId: string) => void
  closeModal: (modalId: string) => void
  closeAllModals: () => void
}

export const useMonitorModalStore = create<DrawerModalStore>(set => ({
  activeModals: [], // 现在是一个数组
  openModal: modalId =>
    set(state => ({
      activeModals: [...state.activeModals, modalId],
    })),
  closeModal: modalId =>
    set(state => ({
      activeModals: state.activeModals.filter(id => id !== modalId),
    })),
  closeAllModals: () => set({ activeModals: [] }),
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('drawerModalStore', useMonitorModalStore)
}
