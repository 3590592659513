import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean } from 'ahooks'
import { Tooltip } from 'antd'
import { queryWorkspaceUsageTrend, type UsageTrendType } from '@/apis/monitor'
import { ChartDashboardPane } from '@/features/agent/AgentAnalysis/ChartDashboardPane'
import { IconFont } from '@/components'
import type { ChartDashboardPaneRef } from '../../../../features/agent/AgentAnalysis/ChartDashboardPane'
import { useWorkspaceStore } from '@/store'
import { PointUsageModal } from './PointUsageModal'

function InternalWorkspacePointTrend() {
  const [
    detailModalVisible,
    { toggle: toggleDetailModal, setTrue: openDetailModal },
  ] = useBoolean(false)

  const [currentDate, setCurrentDate] = useState('')

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const trendingTableRef = useRef<ChartDashboardPaneRef>(null)

  useEffect(() => {
    trendingTableRef.current?.refresh()
  }, [currentWorkspaceId])

  const params = useMemo(
    () => ({
      statistic_time: 'weekly',
      display_time: 'daily',
    }),
    [],
  )

  return (
    <>
      <ChartDashboardPane<UsageTrendType>
        ref={trendingTableRef}
        chartClassName='max-w-none'
        type='area'
        xField='display_time'
        yField='value'
        title='平台积分用量趋势'
        tooltipTitle='消耗积分'
        addonAfterTitle={
          <Tooltip
            title={
              <div>
                <div className='leading-18px'>
                  Agent与工作流在运行过程中所消耗的积分量
                </div>
                <div className='leading-18px'>（不含插件积分用量）</div>
                <div className='leading-18px'>*今日用量数据会在次日更新</div>
              </div>
            }
            placement='bottom'
            overlayInnerStyle={{ width: '270px' }}
          >
            <IconFont
              name='jieshishuimeng'
              className='text-14px cursor-pointer mr-12px ml--6px'
              style={{ color: 'rgba(141, 141, 153, 0.4)' }}
            />
          </Tooltip>
        }
        color='rgb(123,97,255)'
        service={{
          params,
          call: queryWorkspaceUsageTrend,
          transform: data => {
            data.forEach(el => {
              el.title = ''
            })
          },
        }}
        filter
        showTooltip
        onDetailClick={xFieldValue => {
          setCurrentDate(xFieldValue)
          openDetailModal()
        }}
      />
      {detailModalVisible && currentDate && (
        <PointUsageModal
          currentDate={currentDate}
          onClose={() => {
            setCurrentDate('')
            toggleDetailModal()
          }}
        />
      )}
    </>
  )
}

export const WorkspacePointTrend = memo(InternalWorkspacePointTrend)
