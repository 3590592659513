import { message } from 'antd'
import { useFlowDraftStore, useFlowInteractionStore } from '@/store'

export function usePaneEvents() {
  const setViewPortNodeId = useFlowDraftStore(s => s.setViewPortNodeId)
  const isAddMode = useFlowInteractionStore(s => s.isAddMode)
  const clearActiveStates = useFlowInteractionStore(s => s.clearActiveStates)

  const onPaneClick = () => {
    setViewPortNodeId('')
    if (!isAddMode) {
      clearActiveStates()
    } else {
      message.warning({
        content: '请点击节点之间的连线，进行插入',
        style: {
          marginTop: 48,
        },
      })
    }
  }

  return {
    onPaneClick,
  }
}
