import classNames from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

interface LabelTabsProps {
  labels: Array<{ label: string; key: string; disable?: boolean }>
  activeKey: string
  onChange: (key: string) => void
  labelItemClassName?: string
}

export const SCROLLBAR_OPTIONS = {
  overflow: {
    x: 'scroll',
    y: 'hidden',
  },
  scrollbars: {
    autoHide: 'leave',
  },
} as const

export function LabelTabs(props: LabelTabsProps) {
  const { activeKey, labels, onChange, labelItemClassName = '' } = props

  return (
    <OverlayScrollbarsComponent
      className='overflow-auto'
      element='div'
      options={SCROLLBAR_OPTIONS}
      defer
    >
      <div className='flex'>
        {labels.map(item => (
          <div
            key={item.key}
            className={classNames(
              'flex-center h-36px px-20px b-rd-8px text-14px break-keep',
              {
                'bg-primary bg-op-8 c-primary': item.key === activeKey,
                'text-#17171d text-op-80': item.key !== activeKey,
                'text-#8d8d99! text-op-40!': item.disable,
                'hover:c-primary cursor-pointer': !item.disable,
              },
              labelItemClassName,
            )}
            onClick={() => {
              if (item.disable) {
                return
              }
              onChange(item.key)
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    </OverlayScrollbarsComponent>
  )
}
