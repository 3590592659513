import { Tooltip } from 'antd'
import { ControlButton } from 'reactflow'
import { IconFont } from '@/components'

interface FlowControlButtonProps {
  icon: string
  tooltip: string
  onClick: () => void
}

export function FlowControlButton(props: FlowControlButtonProps) {
  const { icon, tooltip, onClick } = props

  return (
    <Tooltip title={tooltip}>
      <ControlButton
        className='w-24px h-24px p-0 b-b-none b-rd-4px'
        onClick={onClick}
      >
        <IconFont
          name={icon}
          className='w-16px! max-w-unset! max-h-unset! text-16px text-#17171D'
        />
      </ControlButton>
    </Tooltip>
  )
}
