import { useEffect } from 'react'
import { useFlowInteractionStore } from '@/store'

export function useCursorChange() {
  const { isAddMode } = useFlowInteractionStore()

  const changeCursor = () => {
    const pane = document.querySelector('.react-flow__pane')
    if (pane) {
      pane.classList.add('cursor-crosshair')
    }
  }

  const resetCursor = () => {
    const pane = document.querySelector('.react-flow__pane')
    if (pane) {
      pane.classList.remove('cursor-crosshair')
    }
  }

  useEffect(() => {
    if (isAddMode) {
      changeCursor()
    } else {
      resetCursor()
    }
  }, [isAddMode])

  return {
    changeCursor,
    resetCursor,
  }
}
