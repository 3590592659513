import type { FormInstance } from 'antd'
import { Space, Form, message as antdMessage, message } from 'antd'
import classNames from 'classnames'
import { useState, useRef, useEffect } from 'react'
import type { NoticeType } from 'antd/es/message/interface'
import type { UITypes } from '@bty/smartsheet'
import type {
  Sheet,
  RequestMessage,
  WorkerMessage,
  SheetColumnType,
} from '@/features/database/types/tableImport.ts'
import { Button, Select } from '@/components'
import TableEditFormItems from '@/features/database/components/TableEditFormItems'
import {
  createTable,
  getTableDetail,
  importDataToTable,
} from '@/apis/database/api.ts'
import type { CreateTableRequest, Database } from '@/apis/database/types.ts'
import SheetCollapse from '@/features/database/components/SheetCollapse.tsx'
import {
  fixColumn,
  injectSystemColumn,
  uiTypeMap,
} from '@/features/database/utils/column.ts'
import { bindTableForAgent } from '@/apis/agent'
import { fixTableDataByColumn } from '@/features/database/utils/parseHelpers.ts'
import { useTableDescGeneral } from '../hooks/useTableDescGeneral'
import {
  isExcelFile,
  isCSVFile,
} from '@/features/database/utils/checkFileType.ts'
import { extractCSVData } from '@/features/database/utils/extractCSVData.ts'
import TableImportWorker from '@/features/database/worker/tableImportWorker.ts?worker'
import { extractExcelData } from '@/features/database/utils/extractExcelData.ts'
import LoadingIcon from '@/assets/agent/circle.png'

export interface FileParsedProps {
  form: FormInstance<{ sheets: Sheet[] }>
  useInAgent?: {
    flow_id: string
    version_id: string
  }
  database: Database | (() => Promise<Database>)
  sheets: Sheet[]
  onOk?: (firstTableId: string) => Promise<void>
  onCancel?: () => void
  onError?: (_: Error) => void
}

function createMessage(msg: string, type: NoticeType) {
  const key = `table-import${new Date().getTime()}`
  antdMessage.open({
    key,
    type,
    content: msg,
  })

  return {
    update: (msg: string, type: NoticeType) => {
      antdMessage.open({
        key,
        type,
        content: msg,
      })
    },
  }
}

export function FileParsed({
  form,
  useInAgent,
  database,
  sheets,
  onOk,
  onCancel,
  onError,
}: FileParsedProps) {
  const [loading, setLoading] = useState(false)

  const importDataAction = async (
    base_id: string,
    sourceId: string,
    params: CreateTableRequest,
    data: any[],
  ) => {
    const message = createMessage('创建数据表', 'loading')
    const table = await createTable(base_id, sourceId, params)
    message.update('创建数据表完成', 'loading')

    const flow_id = useInAgent?.flow_id || null
    const version_id = useInAgent?.version_id || null
    bindTableForAgent({
      flow_id,
      version_id,
      database_id: base_id,
      table_id: table.id,
      source_id: sourceId,
    })

    // await setFirstUserColumnToPrimary(table.columns ?? [])

    // 多少条数据为一批批量导入
    const batchSize = 200
    for (let i = 0; i < data.length; i += batchSize) {
      const batchBlockStartFlag = i
      const batchBlockEndFlag = Math.min(i + batchSize, data.length)

      message.update(
        `导入数据 ${batchBlockStartFlag} / ${data.length}`,
        'loading',
      )

      // 获取当前批次的数据子集
      const batchData = data.slice(i, i + batchSize)
      // 调用接口
      try {
        await importDataToTable(table.id, base_id, batchData)
      } catch (e) {
        antdMessage.error(
          `${batchBlockStartFlag} ~ ${batchBlockEndFlag} 行数据导入失败`,
        )
      }
    }
    message.update('导入完成', 'success')

    return table.id
  }

  const onImport = async () => {
    let _database
    setLoading(true)
    if (typeof database === 'function') {
      try {
        _database = await database()
      } catch (_) {
        antdMessage.error((_ as Error).message)
        return
      }
    } else {
      _database = database
    }

    if (!_database?.sources?.length) {
      setLoading(false)
      return
    }

    try {
      const value = await form.validateFields()
      const ids: string[] = []
      for (const sheet of value.sheets) {
        const columns = injectSystemColumn(
          sheet.columns.map(column => fixColumn(column)),
        )

        const tableData = fixTableDataByColumn(sheet.columns, sheet.data)

        const tableId = await importDataAction(
          _database.id,
          _database.sources![0]!.id,
          {
            title: sheet.title,
            table_name: sheet.title,
            description: sheet.description,
            columns,
          },
          tableData,
        )
        ids.push(tableId)
      }
      setLoading(false)
      onOk?.(ids[0])
    } catch (_) {
      setLoading(false)
      onError?.(_ as Error)
    }
  }

  const { onTableDescriptionGeneral, loading: generalDescLoading } =
    useTableDescGeneral(form)

  return (
    <>
      <div
        className={classNames(
          'body max-h-600px py-24 overflow-y-auto w-full',
          sheets.length > 1 ? 'py-20 bg-bg' : 'px-32 py-24 bg-white',
        )}
      >
        <Form
          form={form}
          requiredMark={false}
          layout='vertical'
          initialValues={{ sheets }}
        >
          <Form.List name='sheets'>
            {fields =>
              fields.length > 1 ? (
                fields.map((field, index) => (
                  <Form.Item
                    noStyle
                    key={`sheets-${field.key}`}
                    shouldUpdate={(pre, curr) =>
                      pre.sheets[field.name].title !==
                      curr.sheets[field.name].title
                    }
                  >
                    {({ getFieldValue }) => {
                      const sheetName = getFieldValue([
                        'sheets',
                        field.name,
                        'title',
                      ])
                      return (
                        <div className='px-12 mb-20 last-of-type:mb-0'>
                          <SheetCollapse
                            name={sheetName}
                            defaultOpen={index === 0}
                          >
                            <TableEditFormItems
                              formItemNamePath={[field.name]}
                              allowAutoGen={form => {
                                const title = form.getFieldValue([
                                  'sheets',
                                  field.name,
                                  'title',
                                ])
                                return !!title
                              }}
                              autoGenLoading={generalDescLoading}
                              onAutoGen={() =>
                                onTableDescriptionGeneral([
                                  'sheets',
                                  field.name,
                                ])
                              }
                            />
                          </SheetCollapse>
                        </div>
                      )
                    }}
                  </Form.Item>
                ))
              ) : (
                <TableEditFormItems<typeof form>
                  key='sheets-0'
                  formItemNamePath={[0]}
                  allowAutoGen={form => {
                    const title = form.getFieldValue(['sheets', 0, 'title'])
                    return !!title
                  }}
                  autoGenLoading={generalDescLoading}
                  onAutoGen={() => onTableDescriptionGeneral(['sheets', 0])}
                />
              )
            }
          </Form.List>
        </Form>
      </div>
      <div className='footer flex justify-end p-16 border-t border-font_1 border-opacity-8'>
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button loading={loading} type='primary' onClick={onImport}>
            保存
          </Button>
        </Space>
      </div>
    </>
  )
}

type SheetDataType = Record<string, unknown>[]

interface TargetColumnType {
  label: string
  value: string
  uidt?: string
  rqd?: boolean
}

interface DeltaFileParsedProps {
  sheet: {
    columns: SheetColumnType[]
    data: SheetDataType
  }
  tableId: string
  onOk: (tableId: string) => void
  onCancel: VoidFunction
}

interface DeltaFileParsedFormValues {
  map: { source: string; target: TargetColumnType }[]
}

export function DeltaFileParsed({
  sheet,
  tableId,
  onOk,
  onCancel,
}: DeltaFileParsedProps) {
  const [messageApi, contextHolder] = message.useMessage()

  const [form] = Form.useForm<DeltaFileParsedFormValues>()

  const [targetColumns, setTargetColumns] = useState<TargetColumnType[]>()

  const databaseIdRef = useRef<string>()

  useEffect(() => {
    ;(async () => {
      const table = await getTableDetail(tableId)
      const targetColumns = table.columns
        ?.filter(col => !col.system)
        ?.map(col => ({
          label: col.title,
          value: col.column_name,
          uidt: col.uidt ? uiTypeMap[col.uidt as UITypes] : col.uidt,
          rqd: !!col.rqd,
        }))
      setTargetColumns(targetColumns)
      form.setFieldValue('map', targetColumns?.map(target => ({ target })))
      databaseIdRef.current = table.base_id
    })()
  }, [tableId])

  const handleOk = async () => {
    if (!databaseIdRef.current) {
      return
    }

    let values
    try {
      values = await form.validateFields()
      if (
        values.map.filter(el => el.target.rqd !== true && !el.source).length ===
        values.map.length
      ) {
        antdMessage.error('至少设置一个字段映射')
        return
      }
    } catch (_) {
      return
    }

    const m = values.map.reduce((map, each) => {
      if (!each.source) {
        return map
      }
      map.set(each.source, each.target.value)
      return map
    }, new Map())

    const newData = sheet.data.map(el =>
      Object.keys(el).reduce<Record<string, unknown>>((newValue, key) => {
        const newKey = m.get(key)
        if (newKey) {
          newValue[newKey] = el[key]
        }
        return newValue
      }, {}),
    )

    const batchSize = 200
    for (let i = 0; i < newData.length; i += batchSize) {
      const batchBlockEndFlag = Math.min(i + batchSize, newData.length)

      messageApi.open({
        key: 'message__delta_import_data',
        type: 'loading',
        content: `导入数据 ${i} / ${newData.length}`,
      })

      const batchData = newData.slice(i, i + batchSize)
      try {
        await importDataToTable(tableId, databaseIdRef.current, batchData)
      } catch (e) {
        antdMessage.error(`${i} ~ ${batchBlockEndFlag} 行数据导入失败`)
      }
    }
    messageApi.open({
      key: 'message__delta_import_data',
      type: 'success',
      content: '导入完成',
      duration: 2,
    })
    onOk(tableId)
  }

  return (
    <div>
      {contextHolder}
      <Form
        className='max-h-634px py-24 px-32 overflow-x-hidden overflow-y-auto'
        form={form}
      >
        <div className='mb-6 flex items-center gap-12 px-12 h-38px bg-#f6f6f9 text-14px font-semibold text-font_1'>
          <span className='w-110px'>字段名</span>
          <span className='w-90px'>类型</span>
          <span className='w-90px'>是否必填</span>
          <span className='flex-1'>导入文件的标题行</span>
        </div>
        <Form.List name='map'>
          {fields =>
            fields.map((field, index) => (
              <div key={field.key} className='flex gap-12 px-12 py-6'>
                <div className='w-110px h-32px leading-32px truncate'>
                  <span>{targetColumns?.[index].label}</span>
                </div>
                <div className='w-90px h-32px leading-32px truncate'>
                  <span>{targetColumns?.[index].uidt}</span>
                </div>
                <div className='w-90px h-32px leading-32px truncate'>
                  <span>
                    {targetColumns?.[index].rqd === true ? '必填' : '/'}
                  </span>
                </div>
                <Form.Item<DeltaFileParsedFormValues> shouldUpdate noStyle>
                  {form => {
                    const mapValue: DeltaFileParsedFormValues['map'] =
                      form.getFieldValue('map')
                    const selectedSourceColumns = mapValue
                      .map(el => el.source)
                      .filter(Boolean)
                    const selfValue = form.getFieldValue([
                      'map',
                      field.name,
                      'source',
                    ])
                    const options = sheet.columns.filter(
                      ({ column_name }) =>
                        column_name === selfValue ||
                        !selectedSourceColumns.includes(column_name),
                    )

                    return (
                      <Form.Item
                        className='flex-1 truncate mb-0'
                        name={[field.name, 'source']}
                        rules={
                          targetColumns?.[index].rqd === true
                            ? [
                                {
                                  required: true,
                                  message: '必填字段请完成映射设置',
                                },
                              ]
                            : undefined
                        }
                      >
                        <Select
                          className='w-full [&>.ant-select-selector]:!h-32px'
                          options={options}
                          fieldNames={{ label: 'title', value: 'column_name' }}
                          allowClear
                        />
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </div>
            ))
          }
        </Form.List>
      </Form>
      <div className='footer flex justify-end p-16 border-t border-font_1 border-opacity-8'>
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button loading={false} type='primary' onClick={handleOk}>
            保存
          </Button>
        </Space>
      </div>
    </div>
  )
}

interface FileUploadProps {
  sheetLimit?: number
  onSuccess: (data: Sheet[]) => void
}

function isFileSizeOver10MB(file: File) {
  return file.size / 1024 / 1024 > 10
}

export function FileUpload({ sheetLimit, onSuccess }: FileUploadProps) {
  const workerRef = useRef<Worker | null>(null)
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)

  const isWorkerSupport = typeof Worker !== 'undefined'

  const onReset = () => {
    workerRef.current?.terminate()
    workerRef.current = null
    setFileName(null)
    setLoading(false)
  }

  const onFileChange = async (file: File) => {
    if (isFileSizeOver10MB(file)) {
      message.error('文件大小不能超过 10MB')
      return
    }

    setFileName(file.name)
    setLoading(true)
    if (isWorkerSupport) {
      workerRef.current = new TableImportWorker()
      workerRef.current.postMessage({
        type: 'start',
        file,
        sheetLimit,
      } as RequestMessage)
      workerRef.current?.addEventListener(
        'message',
        (event: MessageEvent<WorkerMessage>) => {
          switch (event.data.status) {
            case 'finish': {
              const payload = event.data.data
              onSuccess(payload)
              onReset()
              break
            }
            case 'fail':
              message.error('文件解析失败')
              onReset()
              break
            default:
              break
          }
        },
      )
    } else {
      setLoading(true)
      let data
      if (isExcelFile(file)) {
        data = await extractExcelData(file)
      } else if (isCSVFile(file)) {
        data = await extractCSVData(file)
      }
      if (data) {
        onSuccess(data)
      }
      setLoading(false)
    }
  }

  return (
    <div className='py-24 px-32'>
      <div className='h-[482px] flex justify-center items-center text-center bg-bg_3 bg-op-6 rd-8px b--1 b-solid b-line b-op-60 hover:b-primary hover:b-dashed relative'>
        {loading ? (
          <div className='flex flex-col items-center'>
            <img className='w-50px animate-spin' src={LoadingIcon} />
            <div className='font-normal mt-24'>正在解析 {fileName}</div>
            <Button className='mt-24 !px-25' onClick={onReset}>
              取消
            </Button>
          </div>
        ) : (
          <>
            <input
              type='file'
              accept='.xlsx,.csv'
              className='absolute top-0 left-0 op-0 w-full h-full'
              onChange={e => {
                if (e.target.files?.length) {
                  onFileChange(e.target.files[0])
                }
              }}
            />
            <div>
              <p className='text-[14px]/16px font-medium'>
                拖拽文件到这里，或者{' '}
                <span className='text-primary'>选择文件</span>
              </p>
              <p className='text-[12px] c-font_1 font-normal mt-8'>
                已支持 xlsx、csv，每次最多一个，每个文件不超过 10MB
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
