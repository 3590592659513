import { useMemo } from 'react'
import MarkdownIt from 'markdown-it'
import highlight from 'highlight.js'
import styled from '@emotion/styled'
import copy from 'copy-to-clipboard'
import { message } from 'antd'
import classNames from 'classnames'
import { IconFont } from '@/components'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { useWorkspaceKeys } from '@/features/agent/provider/WorkspaceKeysProvieder.tsx'
import { CODE_KEY_PLACEHOLDER } from '@/features/agent/constant/base.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

interface CodeBlockProps {
  str: string
  lang?: string
  className?: string
  title?: string
  codeFont?: string
  titleFont?: string
  hideHeader?: boolean
  height?: string
  onCopy?: () => void
}

const CodeRenderBox = styled.div<{
  codeFont?: string
  height?: string
}>`
  pre {
    width: fit-content;
    padding: 16px;
    max-height: ${props => (props?.height ? props?.height : 'none')};
    font-size: ${props => props.codeFont || '14px'};
  }
  code {
    line-height: 20px;
  }
`

export function CodeBlock(props: CodeBlockProps) {
  const { hideHeader = false } = props
  const code = useMemo(() => {
    const md = new MarkdownIt({
      html: true,
      highlight: (str: string) => {
        return highlight.highlight(props.lang ?? 'text', str, true).value
      },
    })
    return md.render(`\`\`\`${props.lang}\n${props.str}`)
  }, [props.str, props.lang])

  const onCopy = () => {
    copy(props.str)
    message.success('已复制到剪切板')
  }

  const { scrollRef } = useScrollBar()

  return (
    <div
      className={classNames(
        'rounded-8px bg-bg_3 bg-op-6 overflow-hidden mt-12 w-100% flex flex-col',
        props.className,
      )}
    >
      {!hideHeader && (
        <div className='h-32px bg-bg_3 bg-op-6 flex-center-between pl-12 pr-10 shrink-0'>
          <span className='c-font_1' style={{ fontSize: props.titleFont }}>
            {props.title || props.lang}
          </span>
          <span
            onClick={props.onCopy ?? onCopy}
            className='w-16px h-16px text-16px flex-center c-font_1 cursor-pointer'
          >
            <IconFont name='copy' className='text-#626999 text-op-60' />
          </span>
        </div>
      )}
      <CodeRenderBox
        ref={scrollRef}
        className='line-height-20px flex-1 flex w-full overflow-auto'
        dangerouslySetInnerHTML={{ __html: code }}
        codeFont={props.codeFont}
        height={props.height}
      ></CodeRenderBox>
    </div>
  )
}

/**
 * @description copy时可以替换代码中的文档占位key
 */
export function DocsCodeBlock(props: CodeBlockProps) {
  const { applicationInfo } = useAgentEdit()
  const { apiKey } = useWorkspaceKeys()

  const onCopy = () => {
    let value = props.str
    value = value.replaceAll(CODE_KEY_PLACEHOLDER.API_KEY, apiKey ?? '')
    value = value.replaceAll(
      CODE_KEY_PLACEHOLDER.WORKSPACE_ID,
      applicationInfo?.workspaceId ?? '',
    )
    value = value.replaceAll(
      CODE_KEY_PLACEHOLDER.AGENT_ID,
      applicationInfo?.flowId ?? '',
    )
    copy(value)
    message.success('已复制到剪切板')
  }

  return <CodeBlock {...props} onCopy={onCopy} />
}
