import type { FC, PropsWithChildren, RefObject, ReactNode } from 'react'
import { Popover, QRCode, message } from 'antd'
import download from 'downloadjs'
import { Button } from '@/components'

interface ShareQRCodeImageProps {
  canvasRef: RefObject<HTMLCanvasElement>
  qrCodeUrl: string
  drawShareImage: (qrCodeCanvasElement: HTMLCanvasElement) => void
  popoverMainContent: ReactNode
}

export const ShareQRCodeImage: FC<
  PropsWithChildren<ShareQRCodeImageProps>
> = props => {
  const { canvasRef, qrCodeUrl, children, popoverMainContent, drawShareImage } =
    props

  return (
    <>
      <canvas
        ref={canvasRef}
        width={750}
        height={1080}
        className='fixed top--10000% left--10000%'
      ></canvas>
      <div
        className='fixed top--10000% left--10000%'
        ref={el => {
          const canvas = el?.querySelector('canvas')
          if (canvas) {
            setTimeout(() => {
              drawShareImage(canvas)
            }, 100)
          }
        }}
      >
        <QRCode
          size={750}
          bordered={false}
          className='mt--4px'
          value={qrCodeUrl}
        />
      </div>
      <Popover
        placement='bottomLeft'
        arrow={false}
        overlayInnerStyle={{
          width: 204,
          height: 307,
          padding: 8,
        }}
        content={
          <div>
            {popoverMainContent}
            <div className='text-center font-400 text-12px flex gap-8px cursor-pointer pt-8px'>
              <Button
                className='!font-400 !px-21'
                size='small'
                onClick={() => {
                  canvasRef.current?.toBlob(blob => {
                    const item = new ClipboardItem({ 'image/png': blob! })
                    navigator.clipboard.write([item])
                    message.success('图片已复制到剪切板')
                  }, 'image/png')
                }}
              >
                复制图片
              </Button>
              <Button
                className='!font-400 !px-19'
                size='small'
                onClick={() => {
                  const url = canvasRef.current?.toDataURL()
                  if (url) {
                    download(url)
                  }
                }}
              >
                保存图片
              </Button>
            </div>
          </div>
        }
      >
        {children}
      </Popover>
    </>
  )
}
