import { memo, useMemo } from 'react'
import classNames from 'classnames'
import { get, isEmpty } from 'lodash-es'
import dayjs from 'dayjs'
import { IconFont } from '@/components'
import type { CellRenderProps } from './type'

export const OutputRender = memo((props: CellRenderProps<any>) => {
  const { className, value, cell, head } = props

  const sureValue = useMemo(() => {
    if (head.extra?.runLoading) {
      return cell.runtimeOutput
    }
    return cell.runtimeOutput || value
  }, [head.extra?.runLoading, cell.runtimeOutput, value])

  const res = useMemo(() => {
    const temp = get(sureValue, ['result', 'output'])
    if (typeof temp === 'object') {
      return JSON.stringify(temp)
    }
    return temp
  }, [sureValue])

  const date = useMemo(() => {
    if (!sureValue || !sureValue.created_at) return ''
    const temp = dayjs(sureValue.created_at)
    return temp.format('MM-DD HH:mm:ss')
  }, [sureValue])

  const duration = useMemo(() => {
    return get(sureValue, ['result', 'duration_time'])
  }, [sureValue])

  if (isEmpty(sureValue)) {
    return (
      <div className={classNames(className, 'color-[rgba(141,141,153,0.4)]')}>
        {head.placeholder ?? '运行后自动生成'}
      </div>
    )
  }

  if (sureValue.run_result_status === 'RUNNING') {
    return (
      <div className={classNames(className, 'h-full flex flex-center')}>
        <img
          width={16}
          className='animate-spin'
          src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/flow/loading.png'
        />
      </div>
    )
  }

  if (get(sureValue, ['result', 'status']) === 'FAILED') {
    return (
      <div className={classNames(className, 'flex items-center text-#E54A16')}>
        <IconFont className='text-18px mr-4px' name='cuowurizhi' />
        运行流程在节点
        <span className='mx-8px'>
          {get(sureValue, ['result', 'output', 'step_name'])}
        </span>
        出错，详情请
        <span className='ml-4px underline'>查看日志</span>
      </div>
    )
  }

  if (get(sureValue, ['result', 'status']) === 'ERROR') {
    return (
      <div className={classNames(className, 'flex items-center text-#E54A16')}>
        <IconFont className='text-18px mr-4px' name='cuowurizhi' />
        {String(get(sureValue, ['result', 'output']))}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        className,
        'h-full flex flex-col relative [&:hover_.show-icon]:opacity-100',
      )}
    >
      <div className='text-#8D8D99 mb-12px'>
        {date} <span>{duration}s</span>
      </div>
      <div className='mb-auto leading-18px overflow-auto break-all output-content'>
        {res}
      </div>
      <IconFont
        name='quanping1x'
        className='show-icon text-16px absolute top-[-4px] right-[-4px] text-#A1A5C2 opacity-0'
      />
    </div>
  )
})
