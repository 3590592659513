import { useEffect, useRef } from 'react'
import cn from 'classnames'
import type { CodeEditorInstance, CodeEditorProps } from '@/features/editor'
import { CodeEditor } from '@/features/editor'

export function JsonEditor(props: CodeEditorProps) {
  const { value, onChange, className, ...remainingProps } = props
  const isOnChangeTriggered = useRef(false)
  const editorRef = useRef<CodeEditorInstance>(null)

  // 💩覆水难收，更合理的是编辑器里面不处理JSON的格式化
  // 下面的代码是为JSON自动格式化打补丁
  useEffect(() => {
    if (isOnChangeTriggered.current) {
      isOnChangeTriggered.current = false
    } else {
      editorRef.current?.editor?.setValue(
        typeof value === 'string' ? value : JSON.stringify(value, null, 2),
      )
      editorRef.current?.editor?.clearSelection()
      editorRef.current?.editor?.blur()
    }
  }, [JSON.stringify(value)])

  return (
    <div className='w-full h-full'>
      <CodeEditor
        ref={editorRef}
        width='100%'
        theme='tomorrow'
        mode='json'
        setOptions={{
          minLines: 8,
          maxLines: 16,
          hasCssTransforms: false,
        }}
        className={cn('!py-0 overflow-hidden', className)}
        defaultValue={
          typeof value === 'string' ? value : JSON.stringify(value, null, 2)
        }
        onChange={v => {
          try {
            onChange?.(JSON.parse(v))
          } catch (err) {
            onChange?.(v)
          }
          isOnChangeTriggered.current = true
        }}
        copy
        {...remainingProps}
      />
    </div>
  )
}
