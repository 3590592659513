import { useContext, useEffect, useMemo, useRef } from 'react'
import { Switch, Form, Tooltip } from 'antd'
import { assign, get, isEmpty, isNil } from 'lodash-es'
import { InputNumber, Button, IconFont } from '@/components'
import type {
  InnerNodePanelProps,
  NodePanelComponent,
} from '@/features/nodes/base'
import type { MemoryNodeData } from '../MemoryNode'
import { DynamicForm } from '../../base/components/DynamicForm'
import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm, NodeFormItem, Steps } from '@/features/nodes/components'
import { MemoryTagSelect } from '@/features/nodes/memory/MemoryTagSelect.tsx'
import { MemoryOutputTypeSelect } from '@/features/nodes/memory/MemoryOutputTypeSelect.tsx'
import { CodeEditor } from '@/features/editor'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { useDynamicValues } from '../../hooks/useDynamicValues'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import useStartFileNodeVariableKeyList from '../../hooks/useStartFileNodeVariableKeyList'
import { VariableRegex } from '@/constants/common'

function TagSwitch(props: {
  value?: boolean
  onChange?: (value: boolean) => void
}) {
  return (
    <Switch
      {...props}
      size='small'
      checked={props.value === undefined ? true : props.value}
    />
  )
}

export const MemoryNodePanel: NodePanelComponent<MemoryNodeData> = (
  props: InnerNodePanelProps<any>,
) => {
  const { data, activatedNodeId, variables, startNodeFormItemType = [] } = props
  const [form] = Form.useForm()
  const datasetList = useNodeMetaStore(state => state.datasetList)
  const getDatasetTags = useNodeMetaStore(state => state.getDatasetTags)
  const datasetTagsMap = useNodeMetaStore(state => state.datasetTagsMap)
  const selectPopupRef = useRef<any>(null)

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    variables: variables?.map(item => item.label),
    values: form?.getFieldsValue(),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message = [
        { content: get(value, 'inputs.searchContent') },
        {
          content: (get(value, 'inputs.tags') || []).join(','),
        },
      ]
      return message
    },
  })

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType,
    variables,
  )

  const { usedKeyListByNodeId } = val

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await form.validateFields()
    run(data)
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables])

  const selected_memory = form.getFieldValue(['inputs', 'memory'])

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })

    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const onBeforeChange = (values: any) => {
    // 知识库变化或者全选(isSelectAllTags = true || undefined)知识库标签，清空之前选择的标签, 并且设置不查询所有标签下的数据
    if (
      values.isSelectAllTags !== false ||
      values.inputs?.memory !== selected_memory
    ) {
      form.setFieldValue(['inputs', 'tags'], [])
      form.setFieldValue(['inputs', 'queryFullTag'], false)
      values.inputs.tags = []
      values.inputs.queryFullTag = false
    }
    return values
  }

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: '知识库',
        name: ['inputs', 'memory'],
        required: true,
        rules: [{ required: true, message: '请选择知识库' }],
        type: 'DatasetSelect',
        componentProps: {
          onChange: (tag: number | string) => {
            if (tag && !VariableRegex.test(String(tag))) {
              getDatasetTags(Number(tag))
            }
          },
        },
      },
      {
        noStyle: true,
        hidden: !form.getFieldValue(['inputs', 'memory']),
        render: () => {
          return (
            <>
              <div className='flex items-center text-12px font-500 mb-12'>
                <div className='flex items-center'>
                  <span>数据标签</span>
                  <span className='color-error ml-5 text-18px line-height-14px position-relative top-3px'>
                    *
                  </span>
                </div>
                <div className='flex items-center ml-12'>
                  <span className='mr-8'>全选</span>
                  <Form.Item noStyle name='isSelectAllTags'>
                    <TagSwitch />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.isSelectAllTags !== curr.isSelectAllTags ||
                  prev.inputs.memory !== curr.inputs.memory
                }
              >
                {({ getFieldValue, getFieldError }) => {
                  const isSelectedAllTags = getFieldValue('isSelectAllTags')
                  const memory = getFieldValue(['inputs', 'memory'])
                  const tags = datasetTagsMap[memory] ?? []
                  if (isSelectedAllTags !== false) return null
                  return (
                    <NodeFormItem
                      noStyle
                      name={['inputs', 'tags']}
                      rules={[
                        {
                          required: true,
                          type: 'array',
                          message: '至少选择一个标签',
                        },
                      ]}
                    >
                      <MemoryTagSelect
                        tags={tags}
                        error={getFieldError(['inputs', 'tags'])}
                        variables={props.variables}
                        variableTipsContainer={props.nodeElement as HTMLElement}
                      />
                    </NodeFormItem>
                  )
                }}
              </Form.Item>
            </>
          )
        },
      },
      {
        noStyle: true,
        render: () => {
          return (
            <>
              <div className='flex items-center text-12px font-500 mb-12'>
                <div className='flex items-center'>
                  <span>查询内容</span>
                  <span className='color-error ml-5 text-18px line-height-14px position-relative top-3px'>
                    *
                  </span>
                </div>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.isSelectAllTags !== curr.isSelectAllTags ||
                    prev.inputs.tags?.length !== curr.inputs.tags?.length
                  }
                >
                  {({ getFieldValue }) => {
                    const isSelectAllTags = getFieldValue('isSelectAllTags')
                    const tags = getFieldValue(['inputs', 'tags']) ?? []

                    const canSelectAllTagData =
                      !isSelectAllTags && tags.length > 0

                    return (
                      <div className='flex items-center ml-12'>
                        <div className='mr-8 flex items-center'>
                          <span>按标签查询数据</span>
                          <Tooltip
                            placement='top'
                            title='按标签下的段落顺序查出最多前500条数据； 如果有多标签，建议在【数据标签】中通过变量来控制需要查询的标签'
                          >
                            <IconFont
                              className='keyu-form-item-tooltip-icon text-font_1 text-16px text-opacity-40 ml-5'
                              name='shuimeng'
                            />
                          </Tooltip>
                        </div>
                        <Tooltip
                          placement='top'
                          title={canSelectAllTagData ? '' : '请填写标签'}
                        >
                          <div>
                            <Form.Item
                              valuePropName='checked'
                              noStyle
                              name={['inputs', 'queryFullTag']}
                            >
                              <Switch
                                disabled={!canSelectAllTagData}
                                size='small'
                              />
                            </Form.Item>
                          </div>
                        </Tooltip>
                      </div>
                    )
                  }}
                </Form.Item>
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.inputs.queryFullTag !== curr.inputs.queryFullTag ||
                  prev.inputs.tags?.length !== curr.inputs.tags?.length
                }
              >
                {({ getFieldValue }) => {
                  const queryFullTag = getFieldValue(['inputs', 'queryFullTag'])
                  const tags = getFieldValue(['inputs', 'tags']) ?? []

                  if (queryFullTag && tags.length) {
                    return (
                      <div className='bg-warning bg-op-8 flex items-center rounded-6px py-8 pl-9 pr-22 text-12px mb-16'>
                        <IconFont name='shuimeng' className='c-warning' />
                        <span className='line-height-16px text-12px ml-4'>
                          注意！若查询数据量超出处理模型的token限制，模型会报错
                        </span>
                      </div>
                    )
                  }
                  return (
                    <NodeFormItem
                      name={['inputs', 'searchContent']}
                      rules={[{ required: true, message: '查询内容不能为空' }]}
                    >
                      <CodeEditor
                        placeholder='输入变量或者文本，根据相似度设置，从知识库匹配查询相关段落'
                        className='ace-gray min-h-120px important:w-100%'
                        variables={props.variables}
                        variableTipsContainer={props.nodeElement}
                        mode='text'
                      />
                    </NodeFormItem>
                  )
                }}
              </Form.Item>
            </>
          )
        },
      },
      {
        noStyle: true,
        render: () => {
          return (
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) =>
                prev.inputs.queryFullTag !== curr.inputs.queryFullTag
              }
            >
              {({ getFieldValue }) => {
                const queryFullTag = getFieldValue(['inputs', 'queryFullTag'])
                return (
                  <div className='flex items-center w-full gap-12'>
                    <NodeFormItem
                      required
                      hidden={queryFullTag}
                      className='flex-1'
                      label='最大结果数'
                      name={['inputs', 'maxResultNum']}
                      rules={[
                        { required: true, message: '最大结果数不能为空' },
                      ]}
                      tooltip={`知识库中可能会查询出多条匹配结果，此设置用于从匹配结果中取相关度最高的${props.data.inputs?.maxResultNum}条`}
                    >
                      <InputNumber
                        size='small'
                        className='nodrag nopan'
                        min={1}
                        max={30}
                        placeholder='最大结果数'
                      />
                    </NodeFormItem>
                    <NodeFormItem
                      required
                      hidden={queryFullTag}
                      label='最低相似度'
                      className='flex-1'
                      name={['inputs', 'minSimilar']}
                      rules={[
                        { required: true, message: '最大结果数不能为空' },
                      ]}
                      tooltip='设定的一个阈值，用于过滤掉相似度低于此值的匹配结果。确保返回的匹配结果都达到一定的相关度，减少不相关或低相关性的内容，提高检索准确性。'
                    >
                      <Steps
                        sliderClassName='nodrag nopan'
                        min={0}
                        max={1}
                        step={0.01}
                        maxLength={4}
                      />
                    </NodeFormItem>
                  </div>
                )
              }}
            </Form.Item>
          )
        },
      },
      {
        label: '输出设置',
        required: true,
        className: 'important:mb-0',
        name: ['inputs', 'outputType'],
        render: () => <MemoryOutputTypeSelect />,
      },
    ]
  }, [datasetList, props.variables, props.nodeElement, selected_memory])

  return (
    <div className='p-[16px]'>
      <div onWheel={e => e.stopPropagation()} ref={selectPopupRef}>
        <JsonForm
          form={form}
          list={list}
          beforeChange={onBeforeChange}
        ></JsonForm>
        <div className='mt-[16px]'>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
      </div>
    </div>
  )
}
