import { useReactFlow } from 'reactflow'
import { getNodeMetaByType } from '@/features/nodes'
import type { NodeType } from '@/features/nodes/base'
import { useGenInsertTemplateNode } from '@/pages/flowPage/hooks/useGenInsertTemplateNode'
import { useGenInsertPluginNode } from '@/pages/flowPage/hooks/useGenInsertPluginNode'
import { useGenInsertSubflowNode } from '@/pages/flowPage/hooks/useGenInsertSubflowNode'
import { generateNodeName } from '@/features/nodes/utils'

export function useNodeOptionSelect(
  onSelect: (data: { type: NodeType; data?: any }) => void,
) {
  const { generateToBeInsertedPlugin } = useGenInsertPluginNode()
  const { generateToBeInsertedSubflow } = useGenInsertSubflowNode()
  const { generateToBeInsertedTemplate } = useGenInsertTemplateNode()
  const { getNodes } = useReactFlow()
  const onBaseNodeSelect = (type: NodeType) => {
    const meta = getNodeMetaByType(type)
    if (!meta) {
      console.error('找不到节点元数据, 添加节点出错')
      return
    }
    onSelect({
      type,
      data: {
        ...meta.initialData,
        name: generateNodeName(getNodes(), meta.type),
      },
    })
  }

  const onPluginSelect = (id: string) => {
    const pluginData = generateToBeInsertedPlugin(id)
    if (!pluginData) return
    onSelect(pluginData)
  }

  const onTemplateSelect = async (id: string) => {
    const templateData = await generateToBeInsertedTemplate(id)
    if (!templateData) return
    onSelect(templateData)
  }

  const onSubflowSelect = (id: string) => {
    const subflowData = generateToBeInsertedSubflow(id)
    if (!subflowData) return
    onSelect(subflowData)
  }

  return {
    onBaseNodeSelect,
    onTemplateSelect,
    onPluginSelect,
    onSubflowSelect,
  }
}
