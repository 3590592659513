import { ErrorElement } from './error'

export function NotAllowed() {
  return (
    <ErrorElement
      content='暂无权限访问'
      image='https://resource.bantouyan.com/battleyeah-ai/ai-client/no-allowed.png'
    />
  )
}
