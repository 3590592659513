import type { MenuProps } from 'antd'
import { Dropdown, message } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'
import type { TableType } from '@bty/smartsheet'
import { JobStatus, JobSubscribe } from '@bty/smartsheet'
import { isEqual } from 'lodash-es'
import { IconFont, useConfirmModal } from '@/components'
import { useTable } from '@/features/database/provider/TableProvider.tsx'
import { deleteTable, copyTable } from '@/apis/database/api.ts'
import { useModal } from '@/hooks/useModal.tsx'
import CopyTableModal from '@/features/database/components/CopyTableModal.tsx'
import { deleteTableForAgent } from '@/apis/agent'

interface TableOperationProps {
  id: string
  database: {
    id: string
    source_id: string
  }
  name: string
  className?: string
}

function TableOperation({
  className,
  name,
  id,
  database,
}: TableOperationProps) {
  const [open, setOpen] = useState(false)
  const [deleteModal] = useConfirmModal()
  const [copyLoading, setCopyLoading] = useState(false)
  const [copyModal] = useModal(CopyTableModal, { loading: copyLoading })

  const {
    setRenamingTableId,
    refreshTables,
    currentTableId,
    setCurrentTableId,
  } = useTable()

  const onCopy = () => {
    setOpen(false)
    copyModal.open({
      sourceTableName: name,
      onFinish: async (table_name, excludeData) => {
        setCopyLoading(true)
        try {
          const res = await copyTable(table_name, database.id, id, excludeData)
          const jobSubscribe = JobSubscribe()
          jobSubscribe<TableType>(res.id, data => {
            if (data.status !== JobStatus.CLOSE) {
              if (data.status === JobStatus.COMPLETED) {
                setCopyLoading(false)
                refreshTables()
                if (data?.data?.result.id) {
                  setCurrentTableId(data.data.result.id)
                }
                message.success('数据表复制成功')
                copyModal.close()
              } else if (data.status === JobStatus.FAILED) {
                message.error('数据表复制失败')
                setCopyLoading(false)
                copyModal.close()
              }
            }
          })
        } catch (e) {
          setCopyLoading(false)
          message.warning('数据表复制失败')
        }
      },
    })
  }

  const onDelete = () => {
    deleteModal.open({
      title: `确认要删除数据表「${name}」？`,
      okText: '删除',
      onOk: async () => {
        await deleteTable(id)
        deleteTableForAgent({
          table_id: id,
          database_id: database.id,
        })
        deleteModal.close()
        message.success('删除成功')
        const res = await refreshTables()
        if (currentTableId === id) {
          const list = res.list ?? []
          setCurrentTableId(list[res.list.length - 1]?.id)
        }
      },
    })
    setOpen(false)
  }

  const menus: MenuProps['items'] = [
    {
      key: 'rename',
      label: (
        <div
          onClick={e => {
            e.stopPropagation()
            setRenamingTableId(id)
          }}
          className='hover:bg-primary hover:bg-op-8 px-8 py-11 cursor-pointer rd-6px'
        >
          重命名
        </div>
      ),
    },
    {
      key: 'copy',
      label: (
        <div
          onClick={e => {
            e.stopPropagation()
            onCopy()
          }}
          className='hover:bg-#7F69FF hover:bg-op-8 px-8 py-11 cursor-pointer rd-6px'
        >
          复制
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
          className='c-error hover:bg-error hover:bg-op-8 px-8 py-11 cursor-pointer rd-6px'
        >
          删除
        </div>
      ),
    },
  ]

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      overlayClassName='[&&>.ant-dropdown-menu]:p-4 [&&>.ant-dropdown-menu]:w-138px'
      menu={{ items: menus }}
    >
      <span
        className={classNames(
          className,
          'w-16px h-16px flex-center rd-4px rotate-90 c-bg_3 c-op-60 font-600 cursor-pointer hover:bg-#D8D8D8',
          {
            'block! bg-#D8D8D8': open,
          },
        )}
        onClick={e => e.stopPropagation()}
      >
        <IconFont name='gengduo' className='mt-1px ml-1px' />
      </span>
    </Dropdown>
  )
}

export default React.memo(TableOperation, isEqual)
