import { memo, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useBoolean } from 'ahooks'
import { ChartDashboardPane } from '@/features/agent/AgentAnalysis/ChartDashboardPane'
import type { ChartDashboardPaneRef } from '../../../../features/agent/AgentAnalysis/ChartDashboardPane'
import { useWorkspaceStore } from '@/store'
import { queryFlowUsageRank, StaticTimeTypes } from '@/apis/monitor'
import type { StatisticsFlowType } from '..'
import type { GeneralTimeParams } from '@/features/analyze/type'
import { UsgaeRankingModal } from './UsageRankingModal'

interface WorkspaceUsageRankPaneProps {
  flowType: StatisticsFlowType
  runType: string[]
  openLogPage: (flowId: string) => void
}

function InternalWorkspaceUsageRankPane({
  flowType,
  runType,
  openLogPage,
}: WorkspaceUsageRankPaneProps) {
  const trendingTableRef = useRef<ChartDashboardPaneRef>(null)

  const [
    rankingModalVisible,
    { setTrue: openRankingModal, setFalse: closeRankingModal },
  ] = useBoolean(false)

  const [timeParams, setTimeParams] = useState<GeneralTimeParams>({
    type: StaticTimeTypes.CUSTOM,
    startDate: dayjs().subtract(8, 'd').toISOString(),
    endDate: dayjs().subtract(1, 'd').toISOString(),
  })

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  useEffect(() => {
    trendingTableRef.current?.refresh()
  }, [currentWorkspaceId])

  const params = useMemo(
    () => ({
      flow_type: flowType,
      run_type: runType,
      statistic_time: 'weekly',
      display_time: 'daily',
    }),
    [flowType, runType],
  )

  return (
    <>
      <ChartDashboardPane
        ref={trendingTableRef}
        className='mt-24'
        type='bar'
        xField='value'
        yField='flow_name'
        title='用量排行 Top10'
        color={['#7B61FF', '#5784FD', '#2FBDF6']}
        addonAfterTitle={
          <span
            className='text-14px text-#9e9e9e mr-12 cursor-pointer'
            onClick={event => {
              event.stopPropagation()
              openRankingModal()
            }}
          >
            完整排行
          </span>
        }
        filter={{
          ignoreSubOptions: true,
          onChange: value => {
            if (value?.type === StaticTimeTypes.CUSTOM) {
              setTimeParams({
                type: value?.type,
                startDate: value?.start_date,
                endDate: value?.end_date,
              } as any)
            } else {
              setTimeParams({
                type: value?.type as StaticTimeTypes,
              })
            }
          },
        }}
        showTooltip
        onDetailClick={(_: string, flowId?: string) => {
          if (flowId) {
            openLogPage(flowId)
          }
        }}
        service={{
          call: queryFlowUsageRank,
          params,
        }}
      />
      {rankingModalVisible && (
        <UsgaeRankingModal
          onClose={closeRankingModal}
          runType={runType}
          flowType={flowType}
          timeParams={timeParams}
        />
      )}
    </>
  )
}

export const UsageWorkspaceUsageRankPane = memo(InternalWorkspaceUsageRankPane)
