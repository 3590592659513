import { useMemo } from 'react'
import { Version, VersionStatus } from '@/features/pay'
import { useVersionStore } from '@/store'

/**
 * 处理用户版本信息的封装函数
 */
export function useVersion() {
  const { versionInfo } = useVersionStore(state => ({
    versionInfo: state.versionInfo,
  }))
  const { isExpired, isPointExhausted, isTeam, isEnterprise } = useMemo(() => {
    const isExpired =
      versionInfo && versionInfo.status === VersionStatus.LoseEffect

    const isPointExhausted =
      versionInfo &&
      versionInfo.points_consume_num +
        (versionInfo.dosage_package_points_consume_num || 0) >=
        versionInfo.points_count_limit +
          (versionInfo.dosage_package_points_count_total || 0)

    const isTeam = versionInfo && versionInfo.product_code === Version.TEAM
    const isEnterprise =
      versionInfo && versionInfo.product_code === Version.ENTERPRISE

    return { isExpired, isPointExhausted, isTeam, isEnterprise }
  }, [versionInfo])

  return { isExpired, isPointExhausted, isTeam, isEnterprise }
}
