import { memo } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { Spin } from 'antd'
import { Button, IconFont } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import type { TaskListResult } from '@/apis/task'
import emptyImg from '@/assets/empty.png'
import { TaskListItem } from './TaskListItem'

interface TaskListProps {
  agentId: string
  list: TaskListResult[]
  loading?: boolean
  getConversationId: () => string
  toEdit: (task?: TaskListResult) => void
  toLog: (task: TaskListResult) => void
  onClose: () => void
  onUpdateList?: (refresh?: boolean, task?: TaskListResult) => void
  className?: string
}

export const TaskList = memo((props: TaskListProps) => {
  const {
    list,
    loading,
    getConversationId,
    toEdit,
    toLog,
    onClose,
    onUpdateList,
    className,
  } = props

  const handleNew = useMemoizedFn(() => {
    toEdit()
  })

  return (
    <div
      className={classNames(
        className,
        'w-full flex flex-col h-full overflow-hidden',
      )}
    >
      <div className='flex flex-center h-64px px-16px flex-none'>
        <span className='font-600 mr-auto text-16px'>任务</span>
        <Button
          className='font-normal mr-16px px-8px!'
          type='primary'
          onClick={handleNew}
        >
          新建任务
        </Button>
        <button className='chat-icon-btn w-24px h-24px' onClick={onClose}>
          <IconFont className='text-16px' name='guanbi' />
        </button>
      </div>

      <div className='flex-1 overflow-hidden  pb-16px'>
        <OverlayScrollbarsComponent
          className='overflow-auto h-full px-16px'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <Spin spinning={loading} wrapperClassName='h-full'>
            {list?.map(each => (
              <TaskListItem
                className='hover:bg-bg_3 hover:bg-op-8'
                key={each.task_id}
                task={each}
                getConversationId={getConversationId}
                onSelect={toLog}
                onEdit={toEdit}
                onUpdateList={onUpdateList}
              />
            ))}
            {!list.length && !loading && (
              <div className='h-60% flex-center flex-col'>
                <img className='w-128px' src={emptyImg} alt='' />
                <div className='text-font_1 text-opacity-60'>暂无任务</div>
              </div>
            )}
          </Spin>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  )
})
