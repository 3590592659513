import { create } from 'zustand'
import { applicationStorage } from '@/utils/storage'

interface ApplicationStore {
  currentApplicationId: string
  setCurrentApplication: (id: string) => void
}

export const useApplicationStore = create<ApplicationStore>(set => ({
  currentApplicationId: applicationStorage.get() || '',
  setCurrentApplication: applicationId => {
    applicationStorage.set(applicationId)
    set({
      currentApplicationId: applicationId,
    })
  },
}))
