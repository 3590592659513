import type { MouseEvent } from 'react'
import { useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import { IconFont } from '@/components/icon'

interface IActionHandlerProps {
  collected: boolean
  onChange: (collected: boolean) => void
}

export function ActionHandler(props: IActionHandlerProps) {
  const { collected, onChange } = props

  const ref = useRef<any>(null)

  const handleClick = useMemoizedFn((e: MouseEvent) => {
    e.stopPropagation()
    onChange(!collected)
  })

  return (
    <div ref={ref} className='ml-auto' onClick={handleClick}>
      <IconFont
        className='collect-icon opacity-0'
        style={{
          color: collected ? '#FFC300' : 'rgba(23, 23, 29, 0.3)',
          opacity: collected ? 1 : undefined,
        }}
        name='shouzang'
      />
    </div>
  )
}
