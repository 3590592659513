import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import type { CodeEditorInstance, CodeEditorProps } from './CodeEditor'
import { AceCodeEditor as CodeEditor } from './CodeEditor'

// polyfill
if (!window.requestIdleCallback) {
  ;(window as any).requestIdleCallback = function (
    cb: (arg0: { didTimeout: boolean; timeRemaining: () => number }) => void,
  ) {
    const start = Date.now()
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining() {
          return Math.max(0, 50 - (Date.now() - start))
        },
      })
    }, 1)
  }

  window.cancelIdleCallback = function (id: number) {
    clearTimeout(id)
  }
}

export const LazyCodeEditorWrapper = forwardRef<
  CodeEditorInstance,
  CodeEditorProps
>((props: CodeEditorProps, ref) => {
  const { className, maxHeight, minHeight, height } = props
  const [editorVisible, setEditorVisible] = useState(false)
  const aceEditorRef = useRef<CodeEditorInstance>(null)

  const isDark = className?.includes('ace-gray')

  const style = useMemo(() => {
    return {
      background: isDark ? '#f3f3f7' : '#fff',
      maxHeight,
      minHeight,
      height: height || '100%',
    }
  }, [isDark, maxHeight, minHeight, height])

  useEffect(() => {
    const handle = window.requestIdleCallback(
      () => {
        setEditorVisible(true)
      },
      {
        // timeout: 1500
      },
    )

    return () => {
      handle && window.cancelIdleCallback(handle)
    }
  }, [])

  useImperativeHandle(ref, () => {
    return aceEditorRef.current!
  }, [aceEditorRef.current])

  return editorVisible ? (
    <CodeEditor {...props} ref={aceEditorRef} />
  ) : (
    <div
      className={classNames(
        className,
        'flex items-start justify-between bg-op-8 rounded-6px b-1',
        {
          'py-7 pl-4 pr-6': isDark,
          'py-4 pr-5': !isDark,
        },
      )}
      style={style}
    >
      <span className='min-h-16px!'>{props.value || props.defaultValue}</span>
    </div>
  )
})
