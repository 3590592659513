import { useKeyPress } from 'ahooks'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { NodeType } from '@/features/nodes/base'
import { useSelectNode } from '@/features/nodes/base/hooks'
import { useFlowDraftStore } from '@/store'

export function useNodeCopy() {
  const [isFocusInInputOrTextArea, setIsFocusInInputOrTextArea] =
    useState(false)
  const {
    setPasteStatus,
    copyNodes,
    cutNodes,
    replaceEmptyByCloneNodes,
    pasteNodes,
  } = useFlowDraftStore(s => ({
    setPasteStatus: s.setPasteStatus,
    copyNodes: s.copyNodes,
    cutNodes: s.cutNodes,
    replaceEmptyByCloneNodes: s.replaceEmptyByCloneNodes,
    pasteNodes: s.pasteNodes,
  }))
  const { nextEdges, sourceNode } = useSelectNode()

  const handleHotKeyCopy = (_e: KeyboardEvent, type: 'cut' | 'copy') => {
    const selectedNodes = useFlowDraftStore
      .getState()
      .nodes.filter(n => n.selected)
    if (selectedNodes.length) {
      // 防止复制/剪切操作LOOP_RESULT节点
      const targetOperationNode = selectedNodes[0]
      if (
        [NodeType.LOOP_RESULT].includes(targetOperationNode?.type as NodeType)
      ) {
        message.warning('该节点暂不支持复制/剪切')
        return
      }
      // todo: 暂时通过dom形式判断是否有节点配置面板后续优化
      const hasNodePanelDom =
        !!document.querySelector('.llm_node_panel_dom_target') ||
        !!document.querySelector('.code_node_panel_don_target')
      // 确保事件触发时聚焦在节点容器上
      if (!hasNodePanelDom && !isFocusInInputOrTextArea) {
        if (type === 'copy') {
          copyNodes(selectedNodes, '复制成功！选择节点后可进行粘贴，Esc取消')
        }
        if (type === 'cut') {
          cutNodes(selectedNodes, '剪切成功！选择节点后可进行粘贴，Esc取消')
        }
      }
    }
  }

  useKeyPress(['esc'], () => setPasteStatus(false))

  useKeyPress(['ctrl.c', 'meta.c'], e => handleHotKeyCopy(e, 'copy'))
  useKeyPress(['ctrl.x', 'meta.x'], e => handleHotKeyCopy(e, 'cut'))

  useKeyPress(['ctrl.v', 'meta.v'], e => {
    if (isFocusInInputOrTextArea) return
    const selectedNodes = useFlowDraftStore
      .getState()
      .nodes.filter(n => n.selected)
    if (selectedNodes.length) {
      const currentNode = selectedNodes[0]
      if (
        currentNode.type === NodeType.CONDITION ||
        currentNode.type === NodeType.INTENT
      ) {
        message.warning('该节点存在多个分支，请选择分支后粘贴')
        return
      }
      if (currentNode.type === NodeType.EMPTY) {
        if (
          e.target &&
          (e.target as HTMLElement).dataset.id === currentNode.id
        ) {
          replaceEmptyByCloneNodes(currentNode.id)
        }
      }
      if (
        !!currentNode &&
        ![NodeType.EMPTY, NodeType.END].includes(currentNode.type as NodeType)
      ) {
        pasteNodes({
          target: nextEdges?.target!,
          source: nextEdges?.source!,
          sourceHandleId:
            nextEdges?.sourceHandle || sourceNode?.data?.relation?.branchId,
        })
      }
    }
  })

  const listenFocusIn = (e: any) => {
    if (
      ['input', 'textarea'].includes(
        e.target?.localName?.toLowerCase() as string,
      )
    ) {
      setIsFocusInInputOrTextArea(true)
    }
  }
  const listenFocusOut = (e: any) => {
    if (
      ['input', 'textarea'].includes(
        e.target?.localName?.toLowerCase() as string,
      )
    ) {
      setIsFocusInInputOrTextArea(false)
    }
  }

  useEffect(() => {
    document.addEventListener('focusin', listenFocusIn)
    document.addEventListener('focusout', listenFocusOut)
    return () => {
      document.addEventListener('focusin', listenFocusIn)
      document.removeEventListener('focusout', listenFocusOut)
    }
  }, [])
}
