import type { ReactNode } from 'react'
import { IconFont } from '..'

export interface DetailHeaderProps {
  back?: boolean
  mainInfo: ReactNode
  actions: ReactNode
  onBack: () => void
}

export function DetailHeader(props: DetailHeaderProps) {
  const { back = true, mainInfo, actions, onBack } = props

  return (
    <div className='flex flex-items-center justify-between h-72px px-20px bg-#fff border-b b-#e1e1e5 b-op-60'>
      {back && (
        <span
          onClick={onBack}
          className='mr-12 c-op-60 w-32px h-32px hover:bg-bg_3 hover:bg-op-8 rounded-6px flex-center cursor-pointer'
        >
          <IconFont name='fanhui' className='text-16px' />
        </span>
      )}
      {mainInfo}
      <div id='detail-tabs' className='flex-1 adapt:ml-40px'></div>
      {actions}
    </div>
  )
}
