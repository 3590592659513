import { useRequest } from 'ahooks'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ForkTaskStatus } from '@/apis/datastore/model'
import { checkForkTaskStatus } from '@/apis/datastore/api'

export function useForkTaskCheck(taskKey = 'forkTaskId') {
  const [searchParams] = useSearchParams()
  const forkTaskId = searchParams.get(taskKey)
  const [loading, setLoading] = useState(!!forkTaskId?.trim())
  const { run: runCheckForkTask, cancel: cancelCheckForkTask } = useRequest(
    checkForkTaskStatus,
    {
      manual: true,
      pollingInterval: 3000,
      onSuccess: res => {
        if (res.state === ForkTaskStatus.SUCCESS) {
          cancelCheckForkTask()
          setLoading(false)
        }
        if (res.state === ForkTaskStatus.FAILURE) {
          message.error('知识库复制失败，请检查flow并自行补充知识库')
          cancelCheckForkTask()
          setLoading(false)
        }
      },
    },
  )

  useEffect(() => {
    if (forkTaskId) {
      runCheckForkTask(forkTaskId)
    }
  }, [])

  return loading
}
