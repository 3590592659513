import type { Edge, Node } from 'reactflow'
import { isConditionNode, isLoopNode, isIntentNode } from '../../utils'
import type { NodeBoundary } from './types'
import { getConditionNodeBoundary } from './nodes/condition'
import { getIntentNode } from './nodes/intent'
import { getLoopNodeBoundary } from './nodes/loop'

export function getNestNodeBoundary(
  node: Node,
  nodes: Node[],
  edges: Edge[],
): NodeBoundary {
  if (isConditionNode(node)) {
    return getConditionNodeBoundary(node, nodes, edges, getNestNodeBoundary)
  }

  if (isIntentNode(node)) {
    return getIntentNode(node, nodes, edges, getNestNodeBoundary)
  }

  if (isLoopNode(node)) {
    return getLoopNodeBoundary(node, nodes, edges, getNestNodeBoundary)
  }
  return {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }
}
