import { memo, useRef } from 'react'
import type { MenuProps } from 'antd'
import { Dropdown, message } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { isEmpty } from 'lodash-es'
import type { ChatMessagePCInstance } from '@bty/chat-renderer-pc'
import { Button, IconFont } from '@/components'
import type { AgentDiffModel } from '@/store/agentDiffStore'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import { DiffConfig } from './AgentDiffConfig'
import { AgentChatMessage } from './AgentChatMessage'

const CurrentPanelMenu: MenuProps['items'] = [
  {
    key: 'use',
    label: <div className='px-12px h-36px flex items-center'>单一模型调试</div>,
  },
]

const PanelMenu: MenuProps['items'] = [
  {
    key: 'use',
    label: <div className='px-12px h-36px flex items-center'>单一模型调试</div>,
  },
  {
    key: 'remove',
    label: <div className='px-12px h-36px flex items-center'>移除</div>,
  },
]

export interface AgentDiffMessageProps {
  id: number
  robotId: string
  onUse: (config: AgentDiffModel) => void
  onDelete: (ids: (string | undefined)[]) => void
}

export const AgentDiffMessage = memo((props: AgentDiffMessageProps) => {
  const { id, robotId, onUse, onDelete } = props

  const chatRef = useRef<ChatMessagePCInstance>(null)

  const { hasError, conversationId, modelConfig } = useAgentDiffStore(
    s => s.diffConfigs[id],
  )
  const changeConfig = useAgentDiffStore(s => s.changeDiffModel)
  const deleteConfig = useAgentDiffStore(s => s.deleteDiffConfig)
  const publishStop = useAgentDiffStore(s => s.publishStop)

  const handleMenuClick = useMemoizedFn((menu: any) => {
    if (menu.key === 'remove') {
      deleteConfig(id)
      publishStop()
      onDelete([conversationId])
    }
    if (menu.key === 'use' && !isEmpty(modelConfig)) {
      onUse(modelConfig)
    }
  })

  const handleConfigChange = useMemoizedFn(async (config: AgentDiffModel) => {
    const deleteIds = changeConfig(id, config)
    if (deleteIds.some(e => !!e)) {
      message.success('已开启新会话')
    }
    publishStop()
    await onDelete(deleteIds)
  })

  return (
    <div className='h-full flex flex-col overflow-hidden rounded-6px'>
      <div className='p-20px relative flex'>
        {id === 0 && (
          <div className='absolute w-64px h-40px bg-#7B61FF text-#fff right-[-24px] top-[-12px] flex items-end justify-center rotate-45'>
            <span className='pb-5px text-12px'>当前</span>
          </div>
        )}

        <DiffConfig
          error={hasError}
          value={modelConfig}
          onChange={handleConfigChange}
        />

        <Dropdown
          overlayClassName='[&_.ant-dropdown-menu]:p-4px!'
          menu={{
            items: id === 0 ? CurrentPanelMenu : PanelMenu,
            onClick: handleMenuClick,
          }}
          placement='bottomRight'
        >
          <Button type='text' className='h-32px! px-8px! ml-auto'>
            <IconFont name='gengduo' />
          </Button>
        </Dropdown>
      </div>

      <div className='flex-1 pb-20px overflow-hidden [&_.message-list-item]:pt-0px'>
        <AgentChatMessage
          id={id}
          robotId={robotId}
          ref={chatRef}
          onDelete={onDelete}
        />
      </div>
    </div>
  )
})
