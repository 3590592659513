export function checkGrayscale({
  grayscaleList,
  grayscale_key,
}: {
  grayscaleList?: any[]
  grayscale_key: string
}) {
  const findCurrentGrayscale = grayscaleList?.find(
    item => item.grayscale_key === grayscale_key,
  )
  if (!grayscaleList) {
    return false
  }
  if (!findCurrentGrayscale) {
    return true
  }
  if (findCurrentGrayscale.is_enable || findCurrentGrayscale.is_openness) {
    return true
  } else {
    return false
  }
}
