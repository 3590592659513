import { Empty, Spin, Tooltip } from 'antd'
import { useMemo, useRef } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import type { HitItem } from '@/apis/datastore/types.ts'
import EmptyImage from '@/assets/dataset/empty@2x.png'
import { IconFont } from '@/components'
import MarkdownStyle from '@/pages/datastores/components/MarkdownStyle.tsx'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import KeywordsView from '@/pages/datastores/components/KeywordsView.tsx'
import { useModal } from '@/hooks/useModal.tsx'
import { DocumentType } from '@/apis/datastore/model.ts'
import { HitExtraInfo } from '../../components/HitExtraInfo'
import {
  QAParagraphItemEditorModal,
  answerListToMap,
  NormalParagraphEditorModal,
} from '../paragraph/ParagraphItemEditorModal'
import { transformQAGroupToSplitItem } from '@/apis/datastore/api'

interface HitListProps {
  hitList: HitItem[]
  updateHitList: (list: HitItem[]) => void
  loading: boolean
  costTime?: number
}

export default function HitList(props: HitListProps) {
  const { hitList, loading, updateHitList, costTime } = props
  const { scrollRef } = useScrollBar()
  const [normalParagraphEditorModal] = useModal(NormalParagraphEditorModal)
  const [qaParagraphEditorModal] = useModal(QAParagraphItemEditorModal)

  const onHitItemEdit = (item: HitItem, index: number) => {
    if (item.file_type === DocumentType.QA) {
      const qaParagraph = transformQAGroupToSplitItem(
        item.answer_group_id!,
        item.extra_info ?? {},
        item,
      )

      qaParagraphEditorModal.open({
        operationType: 'update',
        file_id: item.file_id,
        chunk_id: item.chunk_id!,
        paragraph: qaParagraph,
        title: `段落${index + 1}`,
        matched_keywords: item.matched_keywords,
        isSingle: true,
        callback: async params => {
          const newHitList = hitList.map(item => {
            const questionItem = params.qa_info?.questions?.find(
              e => e.id === item.chunk_id,
            )
            if (!questionItem) return item
            item.content = questionItem.value
            item.keywords = questionItem.keywords ?? []
            item.extra_info = answerListToMap(params.qa_info?.answers ?? [])
            return item
          })
          updateHitList(newHitList)
        },
      })
    } else {
      normalParagraphEditorModal.open({
        operationType: 'update',
        title: `段落${index + 1}`,
        file_id: item.file_id,
        chunk_id: item.chunk_id,
        matched_keywords: item.matched_keywords,
        paragraph: { ...item, key: item.chunk_id, enable: true },
        callback: (params: Record<string, any>) => {
          const updated = hitList.map(item => {
            if (item.chunk_id === params.chunk_id) {
              return {
                ...item,
                keywords: params.keywords,
                content: params.content,
              }
            }
            return item
          })

          updateHitList(updated)
        },
      })
    }
  }

  return (
    <div className='relative h-full w-full overflow-hidden flex-1 flex flex-col'>
      <MarkdownStyle />
      <div className='py-19 px-24 flex items-center z-1'>
        <div className='bg-bg w-full flex items-center'>
          <h3 className='font-600 text-18px'>命中测试结果</h3>
          <Tooltip title='命中与测试文本相关的段落' placement='top'>
            <IconFont
              name='jieshishuimeng'
              className='ml-5 text-font_1 text-op-40 cursor-pointer'
            />
          </Tooltip>
        </div>
        {costTime && (
          <span className='c-#8D8D99 line-height-16px text-12px text-nowrap'>
            耗时 {costTime}s
          </span>
        )}
      </div>
      <div
        className={classNames(
          'bg-#000 bg-op-1 w-full h-full absolute top-0 left-0 items-center justify-center hidden',
          {
            '!flex': loading,
          },
        )}
      >
        <Spin />
      </div>
      <div
        className='flex-1 pb-20 h-full overflow-y-auto px-24 '
        ref={scrollRef}
      >
        <div className='flex w-full flex-col gap-20'>
          {hitList.length > 0
            ? hitList.map((hitItem, index) => (
                <HitItemComp
                  hitItem={{ ...hitItem }}
                  level={index + 1}
                  key={hitItem.chunk_id}
                  onClick={() => onHitItemEdit(hitItem, index)}
                />
              ))
            : !loading && (
                <div className='w-full h-full flex-center mt--35px'>
                  <Empty
                    description={
                      <span className='text-font_1'>暂无命中数据</span>
                    }
                    image={EmptyImage}
                    imageStyle={{
                      width: 128,
                      height: 128,
                    }}
                  />
                </div>
              )}
        </div>
      </div>
    </div>
  )
}

const WrapBox = styled.div`
  // &:hover {
  //   box-shadow: 0px 8px 24px 0px rgba(23, 23, 29, 0.12);

  //   .keywords-view {
  //     display: block;
  //   }
  //   .edit-mark {
  //     display: flex;
  //   }
  // }

  .keywords-view {
    display: none;
  }

  &:hover {
    .keywords-view {
      display: block;
    }
    .icon {
      display: none;
    }
  }
  .multi-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* 控制文本的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

function HitItemComp(props: {
  hitItem: HitItem
  onClick?: () => void
  level: number
}) {
  const { hitItem, onClick, level } = props
  const ref = useRef<HTMLDivElement | null>(null)

  const renderContent = useMemo(() => {
    if (hitItem.file_type === DocumentType.QA) {
      return (
        <div className='px-20px'>
          <div className='inline-block py-3px px-4px bg-#7B61FF/12 c-#7B61FF text-12px/14px! font-500 px-4px rounded-4px'>
            问题
          </div>

          <div className='mt-4px text-14px line-height-20px font-500 c-#17171D'>
            {hitItem?.content}
          </div>

          {Object.entries(hitItem?.extra_info ?? {}).map(
            ([key, value], index) => {
              return (
                <div key={index}>
                  <div className='mt-14px'>
                    <span className='inline-block py-3px px-4px bg-#38A6FF/12 c-#38A6FF text-12px/14px! font-500 px-4px rounded-4px'>
                      {key}
                    </span>
                  </div>

                  <div className='mt-4px text-14px line-height-20px font-500 c-#17171D'>
                    {value}
                  </div>
                </div>
              )
            },
          )}
        </div>
      )
    } else {
      return (
        <div className='flex flex-col px-20px'>
          <div className='mt-12 text-one-line ml-8px text-14px line-height-20px'>
            {hitItem?.content}
          </div>
        </div>
      )
    }
  }, [hitItem, hitItem?.extra_info, hitItem?.content])
  return (
    <WrapBox
      onClick={onClick}
      className='rd-8px py-20 bg-white relative cursor-pointer'
    >
      <div className='flex-center-between w-full overflow-hidden px-20 gap-8'>
        <div className='flex-1 overflow-hidden h-26px flex items-center'>
          <div className='flex-1 overflow-hidden items-center'>
            <IconFont
              name='mingzhong'
              className='c-bg_3 c-op-32 text-16px font-bold icon'
            />
            <div className='hidden keywords-view w-full'>
              <KeywordsView
                value={hitItem.keywords ?? []}
                highlightValue={hitItem.matched_keywords}
              />
            </div>
          </div>
        </div>
        <div className='shrink-0 line-height-20px'>
          <div className='flex items-end'>
            <HitExtraInfo
              relevance_score={hitItem.relevance_score}
              rank={level}
            />
          </div>
        </div>
      </div>
      <div className='my-14px'>{renderContent}</div>
      <div
        className='mt-12 editor-wrap betteryeah-markdown h-0px overflow-hidden'
        ref={ref}
      />
      <div
        className='w-full h-92px rd-8px absolute bottom-0 left-0 edit-mark hidden items-end justify-end px-24 py-20'
        style={{
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%)',
        }}
      >
        <span className='line-height-22px c-primary'>编辑段落</span>
      </div>
    </WrapBox>
  )
}
