import './index.css'

import type { ReactNode } from 'react'
import { memo, useMemo } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import type { SelectProps } from 'antd'
import { Tooltip, Select as AntSelect } from 'antd'
import classNames from 'classnames'
import { IconFont } from '@/components'

interface CardProps {
  headLeft: ReactNode
  headTip?: ReactNode
  headCenter?: ReactNode
  headRight?: ReactNode
  content?: ReactNode
}

export const Card = memo((props: CardProps) => {
  const { headLeft, headCenter, headTip, headRight, content } = props

  const sureTip = useMemo(() => {
    if (typeof headTip === 'string') {
      return (
        <Tooltip title={headTip}>
          <QuestionCircleOutlined className='llm-config-card-title-tip-icon' />
        </Tooltip>
      )
    }
    return headTip
  }, [headTip])

  return (
    <div className='llm-config-card'>
      <div className='llm-config-card-title'>
        <div className='llm-config-card-title-left'>{headLeft}</div>
        <div className='llm-config-card-title-tip'>{sureTip}</div>
        <div className='llm-config-card-title-center'>{headCenter}</div>
        <div className='llm-config-card-title-right'>{headRight}</div>
      </div>
      {content && <div className='llm-config-card-content'>{content}</div>}
    </div>
  )
})

export const Select = memo((props: SelectProps) => {
  return (
    <AntSelect
      {...props}
      className={classNames(
        'rounded-6px hover:bg-#E4E5ED [&.ant-select-open]:bg-#E4E5ED [&_.ant-select-selector]:px-4px [&_.ant-select-selection-item]:pr-16px! [&_.ant-select-selection-item]:text-#17171D!',
        props.className,
      )}
      style={{ padding: 0 }}
      variant='borderless'
      size='small'
      placement='bottomRight'
      suffixIcon={
        <IconFont name='arrow' className='text-7px text-font_1 mr-[-4px]' />
      }
      popupMatchSelectWidth={false}
    />
  )
})
