import { Popover } from 'antd'
import { useMemo, useRef } from 'react'
import { CodeBlock } from '@/components/CodeBlock'
import { CodeEditor } from '@/features/editor'
import type { TagsTooltipProps } from '@/features/editor/components/TagsTooltip'
import type { LLMMessageStructType } from '@/features/nodes/utils/llm.ts'
import { useLLMContextExample } from '../../hooks/useLLMContextExample'

export interface LLMContextJSONFieldProps {
  value?: string
  onChange?: (v: string) => void
  readOnly?: boolean
  disabled?: boolean
  anchor?: TagsTooltipProps['anchor']
  completions?: any[]
  model?: string
  modelMessageStructType?: LLMMessageStructType
  variableTipsContainer?: HTMLElement | null
  extraCls?: string
}

export function LLMContextJSONField(props: LLMContextJSONFieldProps) {
  const {
    value = '',
    onChange,
    readOnly,
    completions,
    variableTipsContainer,
    anchor,
    extraCls,
    modelMessageStructType,
    model,
  } = props

  const wrapperRef = useRef<HTMLElement>(null)

  const onValueChange = (v: string) => {
    let jsonData = v
    try {
      jsonData = JSON.parse(v)
    } catch (error) {
      // err
    }
    onChange?.(jsonData)
  }

  const acceptValue = useMemo(
    () => (typeof value !== 'string' ? JSON.stringify(value, null, 2) : value),
    [value],
  )

  const { llmContextExample } = useLLMContextExample({
    modelMessageStructType,
    model,
  })
  return (
    <div className='relative py-1px'>
      <CodeEditor
        className={`ace-gray ${extraCls || ''}`}
        minHeight='16px'
        readOnly={readOnly}
        // maxHeight='200px'
        mode='json'
        placeholder='填写或输入上下文的JSON变量'
        setOptions={{
          showGutter: false,
          showLineNumbers: false,
          maxLines: 12,
        }}
        anchor={anchor}
        variables={readOnly ? undefined : completions}
        variableTipsContainer={variableTipsContainer}
        value={acceptValue}
        onChange={onValueChange}
      />
      <Popover
        trigger={['click']}
        overlayInnerStyle={{
          minWidth: 320,
          padding: '12px 16px',
        }}
        arrow={false}
        placement='bottom'
        getPopupContainer={() => wrapperRef.current!}
        zIndex={6000}
        content={
          <div>
            <div className='mb-12px line-height-16px text-12px font-500 text-#17171d'>
              上下文内容的JSON格式请参照示例
            </div>
            <CodeBlock
              title='示例'
              lang='json'
              str={llmContextExample}
              className='bg-white b-1 b-#e1e1e5 b-op-60'
              codeFont='12px'
              titleFont='12px'
            />
          </div>
        }
      >
        <span
          ref={wrapperRef}
          className='absolute top-10 right-10 text-12px text-primary cursor-pointer'
        >
          示例
        </span>
      </Popover>
    </div>
  )
}
