import { memo, useEffect, useMemo, useState } from 'react'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import { getWorkspaceMonitorUsageDetail, StaticTimeTypes } from '@/apis/monitor'
import MonitorDrawer from '@/components/MonitorDrawer'
import { useMonitorDetailReq } from '@/features/analyze/hooks/useMonitorDetailReq'
import type { StatisticsFlowType } from '..'
import { usePageModal } from '@/components'
import type { GeneralTimeParams } from '@/features/analyze/type'

interface InternalUsageRankingModalProps {
  flowType: StatisticsFlowType
  onClose: () => void
  runType: string[]
  timeParams?: GeneralTimeParams
}

function InternalUsageRankingModal({
  onClose,
  runType,
  flowType,
  timeParams,
}: InternalUsageRankingModalProps) {
  const pageModal = usePageModal()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const baseParams = useMemo(() => {
    return {
      runType,
      page,
      page_size: pageSize,
      statistic_time: timeParams?.type || StaticTimeTypes.WEEKLY,
      start_date: timeParams?.startDate,
      end_date: timeParams?.endDate,
      flow_type: flowType,
      sort_field: 'consumed_points',
      sort_order: 'desc',
    }
  }, [flowType, runType, page, pageSize, timeParams])

  const { fetchList, ...rest } = useMonitorDetailReq({
    api: getWorkspaceMonitorUsageDetail,
    defaultParams: baseParams,
    outerTimeType: timeParams?.type || StaticTimeTypes.CUSTOM,
    open: true,
  })

  useEffect(() => {
    fetchList(baseParams)
  }, [])

  return (
    <MonitorDrawer
      loading={false}
      title='用量排行'
      columns={[
        {
          title: '序号',
          dataIndex: 'index',
          width: 55,
          render: (_, __, index) => {
            return index + 1 + (page - 1) * pageSize
          },
        },
        {
          title: `${flowType === 'AGENT' ? 'Agent' : '工作流'}`,
          dataIndex: 'flow_name',
          ellipsis: true,
          render: flow_name => (
            <Tooltip title={flow_name} placement='topLeft'>
              {flow_name}
            </Tooltip>
          ),
        },
        {
          title: '消耗积分',
          dataIndex: 'consumed_points',
          defaultSortOrder: 'descend',
          sorter: true,
        },
        {
          title: '生成消息',
          dataIndex: 'run_count',
          sorter: true,
        },
        {
          title: '使用者',
          dataIndex: 'unique_users',
          sorter: true,
        },
        {
          title: '操作',
          key: 'operation',
          render: (_, { flow_id }) => {
            return (
              <div
                className='c-#7b61ff cursor-pointer'
                onClick={() => {
                  const url =
                    flowType === 'AGENT'
                      ? `/agent/${flow_id}/log`
                      : `/application/${flow_id}/日志?tab=log`
                  pageModal.show({ url })
                }}
              >
                查看日志
              </div>
            )
          },
        },
      ]}
      {...rest}
      onTableChange={(pagination, ...remain) => {
        rest?.onTableChange?.(pagination, ...remain)
        setPage(pagination.current || 1)
        setPageSize(pagination.pageSize || 10)
      }}
      filter={{
        defaultValue: timeParams?.type || StaticTimeTypes.CUSTOM,
        defaultSubValue: [
          dayjs(timeParams?.startDate) || null,
          dayjs(timeParams?.endDate) || null,
        ],
      }}
      onClose={onClose}
      rowKey='flow_name'
    />
  )
}

export const UsgaeRankingModal = memo(InternalUsageRankingModal)
