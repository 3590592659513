import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { useState } from 'react'
import { IconFont } from '@/components'
import { Track } from '@/features/track'

const Wrap = styled.span`
  &:hover {
    width: calc(100% + 65px);

    .line {
      border-color: #7b61ff;
    }
    .split-icon {
      background: ${props => props.theme.colors.primary} !important;
      color: white !important;
    }
  }
`
export default function SplitMark() {
  const [tipOpen, setTipOpen] = useState(false)

  return (
    <Wrap
      onClick={() => setTipOpen(false)}
      className='cursor-pointer transition-all-200 ease-in-out h-30px line-height-14  w-full flex items-center relative left--45px w-35px z-100'
    >
      <Tooltip
        open={tipOpen}
        onOpenChange={setTipOpen}
        mouseLeaveDelay={0}
        trigger='hover'
        title='分割分段'
        placement='left'
      >
        <Track event='datastore_doc_cut'>
          <span
            onClick={e => {
              e.stopPropagation()
              ;(e.target as HTMLSpanElement).parentElement?.click()
            }}
            className='split-icon group-hover:opacity-100 opacity-0 w-20px h-20px hover:bg-primary  flex-center rounded-4px c-bg_3 c-op-48 hover:c-white font-bold'
          >
            <IconFont name='split' className='pointer-events-none' />
          </span>
        </Track>
      </Tooltip>
      <span className='line b-bg_3 flex-1 b-op-48 ml-6 b-dashed b-b-2'></span>
    </Wrap>
  )
}
