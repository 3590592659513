import type { ChangeEvent, KeyboardEvent } from 'react'
import { memo, useState } from 'react'
import { Popover, Slider } from 'antd'
import cn from 'classnames'
import { isNumber } from 'lodash-es'
import { useMemoizedFn } from 'ahooks'
import { DEFAULT_WEIGHT } from '@/features/prompt/constant/structPrompt.ts'

interface WeightEditorProps {
  value?: number
  onChange?: (value: number) => void
}

export const WeightEditor = memo<WeightEditorProps>(props => {
  const { value, onChange } = props
  const [isEdit, setIsEdit] = useState(false)
  const [inputValue, setInputValue] = useState<string | number | undefined>(
    value ?? DEFAULT_WEIGHT,
  )

  const toFormatNumber = useMemoizedFn((num: number) => {
    if (num >= 1) {
      return 1
    }
    return Math.floor(num * 100) / 100
  })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const toNumberValue = +e.target.value
    setInputValue(e.target.value)
    if (isNumber(toNumberValue)) {
      onChange?.(toFormatNumber(toNumberValue))
    }
  }

  const handleInputConfirm = () => {
    const toNumberValue = inputValue ? +inputValue : ''
    if (isNumber(toNumberValue)) {
      onChange?.(toFormatNumber(toNumberValue))
    } else {
      setInputValue(value)
    }
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      handleInputConfirm()
      setIsEdit(false)
    }
  }

  return (
    <Popover
      open={isEdit}
      onOpenChange={open => {
        setIsEdit(open)
        setInputValue(value ?? DEFAULT_WEIGHT)
      }}
      placement='bottom'
      arrow={false}
      trigger={['click']}
      overlayInnerStyle={{
        padding: '12px 16px',
      }}
      align={{
        offset: [0, 4],
      }}
      content={
        <div>
          {/* 标题 */}
          <div className='font-500 text-12px/16px'>权重</div>
          {/* 内容 */}
          <div>
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={value ?? DEFAULT_WEIGHT}
              onChange={value => {
                onChange?.(value)
                setInputValue(value)
              }}
            />
          </div>
          <div className='font-400px text-12px/16px'>
            AI会优先处理权重更高的提示词
          </div>
        </div>
      }
    >
      <div
        className={cn(
          'flex gap-8 items-center cursor-pointer c-font_1 text-12px font-400 py-6 px-8 hover:bg-bg_3/8 rd-4px',
          {
            'b-1 b-primary !bg-#fff': isEdit,
          },
        )}
      >
        <span>权重</span>
        {isEdit ? (
          <input
            type='text'
            className='outline-0! w-25px h-12px p-0 text-12px'
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onKeyDown={handleInputKeyDown}
          />
        ) : (
          <span>{value ?? DEFAULT_WEIGHT}</span>
        )}
      </div>
    </Popover>
  )
})

WeightEditor.displayName = 'WeightEditor'
