import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import { IconFont } from '@/components'

interface SheetCollapseProps {
  name: string
  defaultOpen?: boolean
}

export default function SheetCollapse(
  props: PropsWithChildren<SheetCollapseProps>,
) {
  const { defaultOpen, name, children } = props
  const [open, setOpen] = useState(defaultOpen)

  return (
    <div className='bg-white rd-8px'>
      <div
        className='header text-16px/20px font-600 pl-4 py-12 w-full flex pr-12'
        onClick={() => setOpen(!open)}
      >
        <span
          className={classNames(
            'cursor-pointer w-16px h-16px flex-center rd-4px text-8px transition-all-150 ease-in-out',
            {
              'rotate-0': open,
              'rotate-270': !open,
            },
          )}
        >
          <IconFont name='desc' />
        </span>
        <span className='truncate flex-1'>{name}</span>
      </div>
      <div className={classNames('px-32 py-12', open ? '' : 'hidden')}>
        {children}
      </div>
    </div>
  )
}
