import {
  TextSelection,
  type EditorState,
  type Transaction,
} from 'prosemirror-state'

export function arrowLeft(
  state: EditorState,
  dispatch?: (tr: Transaction) => void,
) {
  if (!dispatch) return false

  const from = state.selection.from

  const { parent: fromNode } = state.doc.resolve(from)

  const { parent: beforeNode } = state.doc.resolve(from - 1)

  if (
    fromNode?.type.name === 'paragraph' &&
    ['pe-input', 'pe-select'].includes(beforeNode?.type.name)
  ) {
    dispatch(state.tr.setSelection(TextSelection.create(state.doc, from - 1)))
    return true
  }

  if (
    ['pe-input', 'pe-select'].includes(fromNode?.type.name) &&
    beforeNode?.type.name === 'paragraph'
  ) {
    dispatch(state.tr.setSelection(TextSelection.create(state.doc, from - 1)))
    return true
  }

  return false
}
