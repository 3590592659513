import { Panel, useReactFlow } from 'reactflow'
import { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { isUndefined } from 'lodash-es'
import { useInViewport } from 'ahooks'
import { IconFont, Input, LabelTabs } from '@/components'
import { useNodeMetaStore } from '@/store/nodeMeta'
import {
  BaseNodeOptions,
  PluginOrderMap,
  type NodeType,
} from '@/features/nodes/base'
import {
  useFlowInteractionStore,
  useMovableOptionStore,
  useFlowDraftStore,
  FLOW_DRAFT_LOCK_STATUS,
} from '@/store'
import { useEdgesActivate } from '../../hooks/useEdgesActivate'
import { useGenInsertPluginNode } from '../../hooks/useGenInsertPluginNode'
import { useGenInsertSubflowNode } from '../../hooks/useGenInsertSubflowNode'
import { useNodeDataInsert } from '@/hooks/useNodeDataInsert'
import { getNodeMetaByType } from '@/features/nodes'
import { transformPluginData } from '@/features/nodes/plugin/util'
import { useGenMovableOptionData } from '../../hooks/useGenMovableOptionData'
import EmptyImg from '@/assets/flow/empty.png'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { generateNodeName } from '@/features/nodes/utils'
import { useGenInsertTemplateNode } from '../../hooks/useGenInsertTemplateNode'
import { BaseNodeList } from './BaseNodeList'
import { CollapseButton } from './CollapseButton'
import { PluginList } from './PluginList'
import { FlowList } from './FlowList'
import { TemplateList } from './TemplateList'

interface ToolBarProps {
  className?: string
  collapsed?: boolean
  onOpen: () => void
  onClose: () => void
}

type ToolBarTabKey = 'base' | 'template' | 'plugins' | 'flow'

const TABS: { label: string; key: ToolBarTabKey }[] = [
  {
    label: '基础能力',
    key: 'base',
  },
  {
    label: '模板',
    key: 'template',
  },
  {
    label: '插件',
    key: 'plugins',
  },
  {
    label: FLOW_DISPLAYNAME,
    key: 'flow',
  },
]

export function ToolBar(props: ToolBarProps) {
  const { collapsed, onOpen, onClose } = props
  const { getNodes } = useReactFlow()
  const { templateList, pluginList, subFlowList } = useNodeMetaStore()
  const { isAddMode, enterAddMode } = useFlowInteractionStore()
  const { setData } = useMovableOptionStore()

  const [activeTab, setActiveTab] = useState<ToolBarTabKey>('base')
  const [searchValue, setSearchValue] = useState('')
  const [activeKey, setActiveKey] = useState<any>('')
  const containerRef = useRef<HTMLDivElement>(null)
  const baseNodeListRef = useRef<HTMLDivElement | null>(null)
  const templateListRef = useRef<HTMLDivElement | null>(null)
  const pluginListRef = useRef<HTMLDivElement | null>(null)
  const flowListRef = useRef<HTMLDivElement | null>(null)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const [baseInViewport] = useInViewport(baseNodeListRef, {
    threshold: 0.5,
  })
  const [templateInViewport] = useInViewport(templateListRef, {
    threshold: 0.5,
  })
  const [pluginInViewport] = useInViewport(pluginListRef, {
    threshold: 0.3,
  })
  const [flowInViewport] = useInViewport(flowListRef, {
    threshold: 0.1,
  })

  useEffect(() => {
    if (baseInViewport) {
      setActiveTab('base')
      return
    }
    if (templateInViewport) {
      setActiveTab('template')
      return
    }
    if (pluginInViewport) {
      setActiveTab('plugins')
      return
    }
    if (flowInViewport) {
      setActiveTab('flow')
    }
  }, [baseInViewport, templateInViewport, pluginInViewport, flowInViewport])

  const onTabChange = (key: string) => {
    setActiveTab(key as ToolBarTabKey)
    // if (key === 'base') {
    //   baseNodeListRef.current?.scrollIntoView({ behavior: 'smooth' })
    //   return
    // }
    // if (key === 'plugins') {
    //   pluginListRef.current?.scrollIntoView({ behavior: 'smooth' })
    //   return
    // }
    // if (key === 'flow') {
    //   flowListRef.current?.scrollIntoView({ behavior: 'smooth' })
    // }
    if (containerRef.current) {
      const { scrollTop } = containerRef.current
      let h = 0
      if (key === 'base' && baseNodeListRef.current) {
        h = baseNodeListRef.current.offsetTop
      }
      if (key === 'template' && templateListRef.current) {
        h = templateListRef.current.offsetTop
      }
      if (key === 'plugins' && pluginListRef.current) {
        h = pluginListRef.current.offsetTop
      }
      if (key === 'flow' && flowListRef.current) {
        h = flowListRef.current.offsetTop
      }
      containerRef.current.scrollBy({
        top: h - 177 - scrollTop,
        behavior: 'smooth',
      })
    }
  }

  const nodeOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return BaseNodeOptions
    }
    return BaseNodeOptions.map(item => ({
      ...item,
      data: item.data.filter(n =>
        n.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    })).filter(item => item.data.length > 0)
  }, [BaseNodeOptions, searchValue])

  const templateOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return templateList
    }
    return templateList.filter(item =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    )
  }, [templateList, searchValue])

  const pluginOptions = useMemo(() => {
    let list = pluginList
    if (searchValue.trim()) {
      list = list.filter(item =>
        item.display_name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    }
    return transformPluginData(list).sort((a, b) => {
      const orderA = isUndefined(PluginOrderMap[a.label])
        ? 999
        : PluginOrderMap[a.label]
      const orderB = isUndefined(PluginOrderMap[b.label])
        ? 999
        : PluginOrderMap[b.label]
      return orderA - orderB
    })
  }, [pluginList, searchValue])

  const subFlowOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return subFlowList
    }
    return subFlowList.filter(item =>
      item.flow_name.toLowerCase().includes(searchValue.toLowerCase()),
    )
  }, [subFlowList, searchValue])

  const showEmpty = useMemo(() => {
    const count =
      nodeOptions.length +
      pluginOptions.length +
      subFlowOptions.length +
      templateOptions.length
    return !!searchValue.trim() && count <= 0
  }, [searchValue, nodeOptions, subFlowOptions, pluginOptions])

  const { activateEdges } = useEdgesActivate()
  const { generateToBeInsertedPlugin } = useGenInsertPluginNode()
  const { generateToBeInsertedSubflow } = useGenInsertSubflowNode()
  const { generateToBeInsertedTemplate } = useGenInsertTemplateNode()
  const { save } = useNodeDataInsert()
  const { generate } = useGenMovableOptionData()

  const handleSelectChange = (key: any) => {
    activateEdges()
    setActiveKey(key)
    enterAddMode()
    const optionData = generate(key)
    if (optionData) {
      setData(optionData)
    }
  }

  const onBaseNodeSelect = (type: NodeType) => {
    handleSelectChange(type)
    const meta = getNodeMetaByType(type)
    if (!meta) {
      console.error('找不到节点元数据, 添加节点出错')
      return
    }
    save({
      type,
      data: {
        ...meta.initialData,
        name: generateNodeName(getNodes(), meta.type),
      },
    })
  }

  const onTemplateSelect = async (id: string) => {
    handleSelectChange(id)
    const templateData = await generateToBeInsertedTemplate(id)
    if (!templateData) return
    save(templateData)
  }

  const onPluginSelect = (id: string) => {
    handleSelectChange(id)
    const pluginData = generateToBeInsertedPlugin(id)
    if (!pluginData) return
    save(pluginData)
  }

  const onSubflowSelect = (id: string) => {
    handleSelectChange(id)
    const subflowData = generateToBeInsertedSubflow(id)
    if (!subflowData) return
    save(subflowData)
  }

  useEffect(() => {
    if (!isAddMode) {
      setActiveKey('')
    }
  }, [isAddMode])

  return (
    <Panel
      position='top-left'
      // className={className}
      className={classNames('m-0 z-10', {
        'top-12px! left-16px!':
          collapsed || lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        'h-full': !collapsed,
      })}
    >
      {collapsed || lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK ? (
        <CollapseButton icon='zhankaichouti' onClick={onOpen} />
      ) : (
        <div className='flex flex-col w-348px h-full bg-#fff b-r-1 b-#e1e1e5 b-op-60 transition-all'>
          <div className='px-16px py-10px b-b-1 b-#e1e1e5 b-op-60'>
            <div className='flex items-center justify-between mb-12px'>
              <Input
                className='w-276px'
                prefix={<IconFont name='search' className='text-16px' />}
                placeholder='搜索节点'
                allowClear
                value={searchValue}
                onChange={val => setSearchValue(val.target.value)}
              />
              <CollapseButton
                shadow={false}
                icon='shouqichouti'
                onClick={onClose}
              />
            </div>
            <LabelTabs
              labels={TABS}
              activeKey={activeTab}
              onChange={onTabChange}
            />
          </div>
          <div
            ref={containerRef}
            className='flex-1 px-16px py-12px overflow-auto scrollbar scrollbar-w-0'
          >
            {showEmpty ? (
              <div className='flex-center flex-col h-full'>
                <img className='w-80px h-80px' src={EmptyImg} alt='' />
                <div className='text-12px text-font_2'>无搜索结果</div>
              </div>
            ) : (
              <>
                <div ref={baseNodeListRef}>
                  <BaseNodeList
                    data={nodeOptions}
                    activeKey={activeKey}
                    onSelect={onBaseNodeSelect}
                  />
                </div>
                <div ref={templateListRef}>
                  <TemplateList
                    data={templateOptions}
                    activeKey={activeKey}
                    onSelect={onTemplateSelect}
                  />
                </div>
                <div ref={pluginListRef}>
                  <PluginList
                    data={pluginOptions}
                    activeKey={activeKey}
                    onSelect={onPluginSelect}
                  />
                </div>
                <div ref={flowListRef}>
                  <FlowList
                    data={subFlowOptions}
                    activeKey={activeKey}
                    onSelect={onSubflowSelect}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Panel>
  )
}
