import styled from '@emotion/styled'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { message } from 'antd'
import { CodeEditor } from '@/features/editor'

const StyledEditor = styled(CodeEditor)`
  &.ace-gray {
    padding-top: 28px;
  }
`

interface JSONEditorProps {
  value: string
  className?: string
}

export function JSONEditor(props: JSONEditorProps) {
  return (
    <StyledEditor
      mode='json'
      width='100%'
      className={classNames('ace-gray b-none', props.className)}
      showGutter={false}
      setOptions={{
        maxLines: 18,
      }}
      value={props.value}
      readOnly
      copy
      copyPosition='top'
      onCopy={text => {
        copy(text)
        message.success('已复制到剪切板')
      }}
    />
  )
}
