import type { FormInstance } from 'antd'
import { Form } from 'antd'
import cn from 'classnames'
import { get } from 'lodash-es'
import { FormItem, Input, RequiredMark, TextArea } from '@/components'
import { TableNameValidateRule } from '@/features/database/constant/table.ts'
import { checkTableHasDescription } from '../utils/table'
import { DescriptionAutoGen } from './DescriptionAutoGen'
import { FormListColumns } from './FormListColumns'

interface TableEditFormItemsProps<T> {
  actionType?: 'create' | 'edit'
  formItemNamePath?: (string | number)[]
  allowAutoGen?: boolean | ((form: FormInstance<T>) => boolean)
  autoGenLoading?: boolean
  onAutoGen: VoidFunction
  onColumnAdd?: VoidFunction
}

export default function TableEditFormItems<T = any>({
  actionType,
  formItemNamePath,
  allowAutoGen,
  autoGenLoading,
  onAutoGen,
  onColumnAdd,
}: TableEditFormItemsProps<T>) {
  const titleNamePath = formItemNamePath
    ? [...formItemNamePath, 'title']
    : 'title'

  const descriptionNamePath = formItemNamePath
    ? [...formItemNamePath, 'description']
    : 'description'

  const columnsNamePath = formItemNamePath
    ? [...formItemNamePath, 'columns']
    : 'columns'

  return (
    <>
      <FormItem
        name={titleNamePath}
        label='数据表名称'
        rules={[
          { required: true, message: '请输入数据表名称' },
          TableNameValidateRule,
        ]}
        required
      >
        <Input placeholder='请输入数据表名称，例如：粉丝评论' />
      </FormItem>
      <Form.Item shouldUpdate noStyle>
        {form => {
          const notAllowAutoGen =
            typeof allowAutoGen === 'function'
              ? !allowAutoGen(form)
              : allowAutoGen === false

          return (
            <FormItem
              className='[&.ant-form-item_.ant-form-item-label_.ant-form-item-required]:w-full'
              name={descriptionNamePath}
              label={
                <>
                  <span className='text-font font-medium'>数据表描述</span>
                  <RequiredMark />
                  <DescriptionAutoGen
                    className={cn(
                      '!gap-4 ml-auto hover:text-op-60 order-last',
                      {
                        'text-op-60': notAllowAutoGen || autoGenLoading,
                      },
                    )}
                    text='生成/优化描述和字段'
                    loading={autoGenLoading}
                    disabled={notAllowAutoGen}
                    showConfirmIfNeed={() => {
                      const formData = form.getFieldsValue()
                      // fixme: big sb
                      const tableData =
                        'sheets' in formData
                          ? formItemNamePath
                            ? get(formData.sheets, formItemNamePath)
                            : formData.sheets
                          : formData
                      return checkTableHasDescription(tableData)
                    }}
                    showTooltip
                    onAutoGen={onAutoGen}
                  />
                </>
              }
              rules={[{ required: true, message: '请输入数据表描述' }]}
              required
            >
              <TextArea
                showCount
                autoSize={{ minRows: 2 }}
                maxLength={200}
                placeholder='请输入数据表描述，这段文字将作为 Agent 的使用引导'
              />
            </FormItem>
          )
        }}
      </Form.Item>
      <FormListColumns
        namePath={columnsNamePath}
        fullyEditable={actionType !== 'edit'}
        afterAdd={onColumnAdd}
      />
    </>
  )
}
