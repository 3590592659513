import styled from '@emotion/styled'
import { Button } from '@/components'
import { PLUGIN_DISPLAYNAME } from '@/constants/common'

interface ListProps {
  name: string
  description: string
  func_is_bind: boolean
  app_id: string
  icon: string
  flow_id: string
  version_id: string
}
interface SelectAgentContentProps {
  agentList: ListProps[]
  handleAddPlugin: (flow_id: string, version_id: string, app_id: string) => void
  addPluginSuccess: (app_id: string) => void
}
const ItemDiv = styled.div`
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: rgba(225, 225, 229, 0.4);
`
export function SelectAgentContent({
  agentList,
  handleAddPlugin,
  addPluginSuccess,
}: SelectAgentContentProps) {
  return (
    <div className='mt-24px'>
      {agentList.map(
        (
          {
            name,
            description,
            icon,
            func_is_bind,
            app_id,
            flow_id,
            version_id,
          },
          index,
        ) => (
          <ItemDiv
            className='flex items-center justify-between p-16px cursor-pointer hover:bg-#F3F3F7'
            key={index.toString()}
          >
            <div className='flex items-center gap-x-12'>
              <div className='min-w-max'>
                <img
                  src={icon}
                  alt='avatar'
                  className='w-40px h-40px rounded-full'
                />
              </div>
              <div className='max-w-800px'>
                <span className='text-14px/16px block mb-6px'>{name}</span>
                <span className='text-12px/16px color-#8D8D99 block'>
                  {description}
                </span>
              </div>
            </div>
            {func_is_bind ? (
              <div className='text-12px/16px tracking-normal min-w-max'>
                <span className='color-#8D8D99'>
                  已添加此{PLUGIN_DISPLAYNAME}
                </span>
                <span
                  className=' color-#7B67EE ml-8px cursor-pointer hover:opacity-60'
                  onClick={() => addPluginSuccess(app_id)}
                >
                  立即对话
                </span>
              </div>
            ) : (
              <Button
                type='primary'
                className='h-36px'
                onClick={() => handleAddPlugin(flow_id, version_id, app_id)}
              >
                在此Agent使用
              </Button>
            )}
          </ItemDiv>
        ),
      )}
    </div>
  )
}
