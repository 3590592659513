import type { AreaChartProps as VAreaChartProps } from '@visactor/react-vchart'
import { AreaChart as VAreaChart, Area, Tooltip } from '@visactor/react-vchart'
import EmptyImage from '@/assets/app/rocket2.png'
import type { ChartBoxProps } from './ChartBox'
import { ChartBox } from './ChartBox'
import { TOOLTIP_STYLE, TooltipContent } from './TooltipContent'

const getArea: (color?: string) => VAreaChartProps['area'] = color => ({
  style: {
    fill: {
      gradient: 'linear',
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 0.8,
      stops: [
        {
          color,
          offset: 0,
          opacity: 1,
        },
        {
          color,
          offset: 0.6,
          opacity: 0.5,
        },
        {
          color,
          offset: 1,
          opacity: 0,
        },
      ],
    },
  },
})

export const getPoint: (
  color?: string,
) => VAreaChartProps['point'] = color => ({
  style: {
    fill: color,
    size: 5,
  },
})

export const getLine: (color?: string) => VAreaChartProps['line'] = color => ({
  style: {
    stroke: color,
    lineWidth: 2,
  },
})

export const axes: VAreaChartProps['axes'] = [
  {
    type: 'linear',
    orient: 'left',
    tick: {
      noDecimals: true,
    },
    label: {
      style: {
        fontSize: 14,
        fontWeight: 400,
        fill: '#8d8d99',
        fillOpacity: 0.6,
      },
    },
    grid: {
      visible: false,
    },
  },
  {
    type: 'band',
    orient: 'bottom',
    label: {
      style: {
        fontSize: 14,
        fontWeight: 400,
        fill: '#8d8d99',
        fillOpacity: 0.6,
      },
    },
  },
]

export interface AreaChartProps extends Omit<ChartBoxProps, 'children'> {
  values?: any[]
  xField: string
  yField: string
  showTooltip?: boolean
  tooltipTitle?: string
  yFieldLabel?: string
  onXFieldDetailClick?: (xFieldValue: string, flowId?: string) => void
}

// const Demo = NiceModal.create(({ children }) => {
//   const { visible } = NiceModal.useModal()

//   return (
//     <Modal open={visible} title='abc' width={500}>
//       {children}
//     </Modal>
//   )
// })

export function AreaChart({
  className,
  title,
  addonAfterTitle,
  values,
  xField,
  yField,
  color,
  modal,
  showTooltip,
  tooltipTitle,
  yFieldLabel,
  onXFieldDetailClick,
  id,
}: AreaChartProps) {
  const area = getArea(color)

  const point = getPoint(color)

  const line = getLine(color)
  const chart = values?.length ? (
    <VAreaChart
      className='[&>canvas]:!w-full'
      type='area'
      data={[{ values }]}
      tooltip={{
        visible: showTooltip ?? false,
      }}
      point={point}
      line={line}
      axes={axes}
      padding={0}
      // width={560}
      height={228}
      autoFit
    >
      {showTooltip && (
        <Tooltip
          style={TOOLTIP_STYLE}
          enterable
          parentElement={
            document.querySelector('#main-container') as HTMLElement
          }
        >
          <TooltipContent
            chartId={id}
            xField={xField}
            yField={yField}
            yFieldLabel={tooltipTitle ?? yFieldLabel}
            detail={
              onXFieldDetailClick ? { onClick: onXFieldDetailClick } : false
            }
          />
        </Tooltip>
      )}
      <Area xField={xField} yField={yField} area={area} />
    </VAreaChart>
  ) : (
    <div className='w-full h-228px text-14px text-font_1 flex flex-col justify-center items-center'>
      <img className='w-70px object-contain' src={EmptyImage} />
      暂无数据
    </div>
  )

  return (
    <ChartBox
      className={className}
      title={title}
      addonAfterTitle={addonAfterTitle}
      color={color}
      // modal={{
      //   onOpen: () => {
      //     NiceModal.show(Demo, {
      //       children: React.cloneElement(chart),
      //     })
      //   },
      // }}
      modal={modal}
    >
      {chart}
    </ChartBox>
  )
}
