import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import {
  getFavoriteTemplateAppList,
  addFavoriteTemplateApp,
  deleteFavoriteTemplateApp,
} from '@/apis/favorite.ts'

interface FavoriteStore {
  favoriteTemplateAppIdList: string[]
  fetchFavoriteTemplateAppList: () => Promise<void>
  addFavoriteTemplateApp: (application_id: string) => Promise<void>
  deleteFavoriteTemplateApp: (application_id: string) => Promise<void>
}

export const useFavoriteStore = create<FavoriteStore>((set, get) => ({
  favoriteTemplateAppIdList: [],
  fetchFavoriteTemplateAppList: async () => {
    const list = await getFavoriteTemplateAppList()
    set({ favoriteTemplateAppIdList: list })
  },
  addFavoriteTemplateApp: async application_id => {
    addFavoriteTemplateApp(application_id).then(() => {
      set({
        favoriteTemplateAppIdList: [
          ...get().favoriteTemplateAppIdList,
          application_id,
        ],
      })
    })
  },
  deleteFavoriteTemplateApp: async application_id => {
    deleteFavoriteTemplateApp(application_id).then(() => {
      set({
        favoriteTemplateAppIdList: get().favoriteTemplateAppIdList.filter(
          id => id !== application_id,
        ),
      })
    })
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('favoriteStore', useFavoriteStore)
}
