import { createContext } from 'react'
import type { InnerNodeProps } from './types'

type NodeContextProps = Partial<
  Pick<
    InnerNodeProps,
    | 'onSaveChange'
    | 'validateNode'
    | 'registerValidateFunction'
    | 'removeValidateFunction'
    | 'data'
    | 'focused'
    | 'id'
  >
>

export const NodeContext = createContext<NodeContextProps>({
  focused: false,
})
