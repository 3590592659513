import type { ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import {
  type Params,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useMemoizedFn, useRequest, useTitle } from 'ahooks'
import type { ChatPCInstance, ChatPCProps } from '@bty/chat-renderer-pc'
import type { AgentProfileProps } from '@bty/chat-ui'
import {
  ChatbotRunType,
  type Bot,
  type ConversationData,
} from '@bty/chat-types'
import ChatHeader, {
  ChatOperation,
  type ChatHeaderProps,
} from '@/features/chat/components/ChatHeader'
import HistoryList, {
  type HistoryListProps,
} from '@/features/chat/components/HistoryList'
import {
  createConversation,
  getBotById,
  getConversationList,
} from '@/apis/chatbot'
import { IconFont } from '@/components'
import { PRODUCT_TITLE } from '@/constants/common'
import type { StoreBotItem } from '@/store'
import { useBotStore, useUserStore } from '@/store'
import { fetchAgentRule, getConversationById } from '@/apis/agent'
import logo from '@/assets/logo-square.svg?url'
import { useGenTitleFromFirstQandA } from '@/hooks/useGenTitleFromFirstQandA'

import { AgentChat } from '@/features/chat/AgentChat'
import ChatMenu from '../home/components/ChatMenu'
import { isNotAuthRoute } from '@/router'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { TaskInfo } from '@/features/agent/Task/TaskInfo'
import { useTask } from '@/features/agent/Task/hooks/useTask'
import { useChatStore } from '@/store/chat'
import { useBotDetail } from '@/features/chat/hooks/useBotDetail'
import type { BotDetailProps } from '@/features/chat/components/BotDetail'
import BotDetail from '@/features/chat/components/BotDetail'
import { useAgentUploadFileConfig } from '@/features/agent/hooks/useAgentUploadFileConfig'

export async function loader({ params }: { params: Params<'botId'> }) {
  // 这个其实是 flowId
  const botId = params.botId as string
  const bot = await getBotById(botId)
  if (!bot) {
    throw new Response('', {
      status: 404,
      statusText: 'Not Found',
    })
  }
  return bot
}

interface Props {
  /**
   * 给公开 chat 页使用
   */
  openchatConversationId?: string
  // defaultBotDetailCollapsed?: boolean
  /**
   * 给公开 chat 页使用
   */
  canShare?: boolean
  /**
   * 给公开 chat 页使用
   */
  showHistoryList?: boolean
  showPowerBy?: boolean
  showChatMenu?: boolean
  showLogo?: boolean
  regularChatList?: StoreBotItem[]
  showDetailIcon?: boolean
  showTask?: boolean
  BottomChildren?: ReactNode
  extraButton?: ReactNode
  /**
   * 给公开 chat 页使用
   */
  onConversationCreated?: (id: string) => void
  onBeforeSubmit?: ChatPCProps['onBeforeSubmit']
  handleBeforeFileUpload?: ChatPCProps['handleBeforeFileUpload']
}
export default function ({
  openchatConversationId,
  // defaultBotDetailCollapsed = true
  canShare = true,
  showHistoryList = true,
  showPowerBy = false,
  showChatMenu = true,
  showLogo = false,
  regularChatList,
  showDetailIcon = true,
  showTask,
  BottomChildren,
  extraButton,
  onConversationCreated,
  onBeforeSubmit,
  handleBeforeFileUpload,
}: Props) {
  const { fetchBotList, sortListByLatestId } = useBotStore()

  const {
    taskOpen,
    showTaskId,
    taskRecordId,
    handleTaskOpen,
    handleTaskClose,
    handleTaskBack,
    handleOpenTask,
  } = useTask()

  const userStore = useUserStore()

  const unreadMessageCountMap = useChatStore(s => s.unreadMessageCountMap)

  const isLogin = !!userStore.user && !userStore.user?.isAnonymous

  const { conversationId } = useParams()

  const location = useLocation()

  // 是否从模版进入的
  const fromTemplates = location.state?.fromRoute === 'templates'

  const bot = useLoaderData() as Bot

  const botId = bot.robot_id as string

  const [historyListCollapsed, setHistoryListCollapsed] = useState(
    () => localStorage.getItem('historyListCollapsed') === 'false',
  )

  const onHistoryListCollapsedChange = (v: boolean) => {
    setHistoryListCollapsed(v)
    localStorage.setItem('historyListCollapsed', String(historyListCollapsed))
  }

  const [botDetailCollapsed, setBotDetailCollapsed] = useState(true)

  useEffect(() => {
    if (fromTemplates) {
      // 如果从模版进入始终关闭回话面板
      setHistoryListCollapsed(true)
      // 从模板进入始终展开Bot详情
      setBotDetailCollapsed(false)
    } else {
      setHistoryListCollapsed(
        localStorage.getItem('historyListCollapsed') === 'false',
      )
      setBotDetailCollapsed(true)
    }
  }, [botId])

  const chatRef = useRef<ChatPCInstance>(null)

  const handleCollapse = useCallback<ChatHeaderProps['onCollapse']>(
    (type, collapsed) => {
      if (type === 'history') {
        onHistoryListCollapsedChange(collapsed)
        return
      }

      if (type === 'bot') {
        setBotDetailCollapsed(collapsed)
        if (!collapsed) {
          handleTaskClose()
        }
        return
      }

      if (type === 'task') {
        if (!collapsed) {
          setBotDetailCollapsed(true)
          handleTaskOpen()
        } else {
          handleTaskClose()
        }
      }
    },
    [],
  )

  const {
    data: conversations,
    loading: conversationsLoading,
    mutate: setConversations,
    refreshAsync: reloadConversations,
  } = useRequest(() => getConversationList(botId, ChatbotRunType.CHATBOT), {
    loadingDelay: 300,
    refreshDeps: [botId],
  })

  const { typingTitle, isTypingEnd, onTitleGenerate } =
    useGenTitleFromFirstQandA(async () => {
      await reloadConversations()
    })

  const { data: agentRule } = useRequest(() => fetchAgentRule(botId), {
    refreshDeps: [botId],
  })

  const { uploadFileConfig } = useAgentUploadFileConfig(botId)

  const [currentConversationId, setCurrentConversationId] = useState<
    ConversationData['conversation_id']
  >(openchatConversationId || conversationId || '')

  const refreshBotList = useCallback(() => {
    if (!conversations?.length) {
      !isNotAuthRoute() && fetchBotList()
    }
  }, [conversations, fetchBotList])

  const handleConversationEdit = useCallback<
    HistoryListProps['onConversationEdit']
  >(
    async action => {
      if (action === 'create') {
        await createConversation({
          robot_id: botId,
          run_type: ChatbotRunType.CHATBOT,
          title: conversations?.length
            ? `新会话 ${conversations?.length}`
            : '新会话',
        })

        const list = await reloadConversations()
        // 置空，自动选择第一个 conversation

        setCurrentConversationId(list?.[0].conversation_id)
        refreshBotList()

        if (chatRef.current?.chat?.input) {
          chatRef.current.chat.input.focus()
        }

        onConversationCreated?.(list?.[0].conversation_id)

        chatRef.current?.fetchConversationList?.()

        return
      }
      reloadConversations()
    },
    [botId, conversations?.length, reloadConversations],
  )

  // 新建会话快捷键
  // useKeyPress(isMac() ? 'meta.k' : 'ctrl.k', () => {
  //   handleConversationEdit('create')
  // })
  const handlePromptClick = useCallback<BotDetailProps['onPromptClick']>(
    prompt => {
      chatRef.current?.submit?.(prompt)
    },
    [],
  )

  const navigate = useNavigate()

  const backButton = useMemo(
    () =>
      fromTemplates ? (
        <button className='chat-icon-btn' onClick={() => navigate(-1)}>
          <IconFont className='color-[#17171D] text-16px' name='fanhui' />
        </button>
      ) : undefined,
    [fromTemplates],
  )

  const { data: singleConversation, run } = useRequest(
    () => getConversationById(conversationId || ''),
    { manual: true },
  )
  useEffect(() => {
    conversationId && run()
    userStore.fetchUser()
  }, [])

  const currentConversation =
    currentConversationId === undefined
      ? conversations?.[0]
      : [singleConversation || {}, ...(conversations || [])]?.find(
          c => c.conversation_id === currentConversationId,
        ) || conversations?.[0]

  const handleChatSend = async (
    conversationId: string,
    _: string,
    isFirst: boolean,
  ) => {
    const index =
      conversations?.findIndex(el => el.conversation_id === conversationId) ??
      -1
    if (index >= 0) {
      const newConversations = [...conversations!]
      if (isFirst) {
        // await updateConversation(conversationId, content)
        // newConversations[index].title = content
      }
      newConversations[index].latest_create_time = new Date().toISOString()
      const [conversation] = newConversations.splice(index, 1)
      newConversations.unshift(conversation)
      setConversations(newConversations)
    }
    sortListByLatestId(bot.id)
  }

  const getConversationId = useMemoizedFn(
    () => currentConversation?.conversation_id || currentConversationId,
  )

  useTitle(bot.name ? `${bot.name}` : PRODUCT_TITLE, { restoreOnUnmount: true })

  useEffect(() => {
    chatRef.current?.chat?.input?.focus()
  }, [botId, currentConversationId])

  useEffect(() => {
    handleTaskClose()
  }, [botId, currentConversation?.conversation_id, currentConversationId])

  const { couldEdit, isTemplate, onEdit, onFork } = useBotDetail({ bot })

  const botDetailActions = useMemo(() => {
    return [
      couldEdit && {
        content: (
          <div className='flex'>
            <IconFont
              className='text-bg_3 text-opacity-60 mr-8px'
              name='edit-app'
            />
            编辑
          </div>
        ),
        event: onEdit,
      },
      isTemplate && {
        content: (
          <div className='flex'>
            <IconFont className=' text-opacity-60 mr-8px' name='add' />
            复制到我的空间
          </div>
        ),
        buttonType: 'default',
        event: onFork,
      },
    ].filter(Boolean) as AgentProfileProps['actions']
  }, [onEdit, onFork, couldEdit, isTemplate])

  const BotListHeader = useMemo(() => {
    return (
      <div
        className={cn(
          'h-64px relative py-26 px-28 text-16px font-600 cursor-pointer bg-white',
          { 'px-10px!': showLogo },
        )}
        onClick={() => {
          showLogo
            ? (window.location.href = 'https://www.betteryeah.com/')
            : navigate('/explore')
        }}
      >
        {showLogo ? (
          <div className='flex-center'>
            <img
              src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/battleyeah-ai/ai-client/logo-square.svg'
              className='w-25px h-25px ml--10px'
            />
            <span className='text-20px ml-8px'>BetterYeah AI Agent</span>
          </div>
        ) : (
          '对话'
        )}
      </div>
    )
  }, [showLogo])

  return (
    <div className='h-full bg-white rounded-12px overflow-hidden flex items-stretch'>
      <div
        className={cn('w-266px', {
          hidden: fromTemplates || !showChatMenu,
        })}
      >
        <ChatMenu
          showLogo={showLogo}
          BottomChildren={BottomChildren}
          regularChatList={regularChatList}
        />
      </div>

      {showHistoryList && isLogin && (
        <HistoryList
          className={cn(
            'w-264px shrink-0 transition-width',
            historyListCollapsed ? 'w-0! overflow-hidden' : '',
          )}
          typingTitle={typingTitle}
          typingFinished={isTypingEnd}
          currentConversation={currentConversation}
          conversations={conversations}
          loading={conversationsLoading}
          collapsed={historyListCollapsed}
          prefixButton={backButton}
          onCollapse={onHistoryListCollapsedChange}
          onConversationClick={setCurrentConversationId}
          onConversationEdit={handleConversationEdit}
        />
      )}
      <div className='flex-1 flex flex-col overflow-hidden '>
        <div className='hidden'>
          <ChatHeader
            bot={bot}
            currentConversation={currentConversation}
            isLogin={isLogin}
            collapsed={{
              bot: botDetailCollapsed,
              history: historyListCollapsed,
              task: !taskOpen,
            }}
            extraButton={extraButton}
            showDetailIcon={showDetailIcon}
            showTask={agentRule?.preset_tasks_enabled && showTask}
            prefixButton={backButton}
            canShare={canShare}
            typingTitle={typingTitle}
            typingFinished={isTypingEnd}
            onCollapse={handleCollapse}
            onConversationCreate={() => handleConversationEdit('create')}
            getMessageList={() => chatRef.current?.messageList || []}
          />
        </div>
        <div className='flex-1 overflow-hidden'>
          <AgentChat
            ref={chatRef}
            actionType='CONVERSATION'
            runType={ChatbotRunType.CHATBOT}
            robotId={botId}
            actions={['stop']}
            onBotChange={id => {
              navigate(`/chatbot/${id}`)
            }}
            conversationId={
              openchatConversationId || currentConversation?.conversation_id
            }
            features={{
              conversationList: true,
            }}
            headerProps={{
              collapsed: {
                bot: botDetailCollapsed,
                history: historyListCollapsed,
                newConversation: true,
              },
              prefix: backButton,
              hideDefaultSuffix: true,
              suffix: (
                <ChatOperation
                  bot={bot}
                  currentConversation={currentConversation}
                  // isLogin={isLogin}
                  collapsed={{
                    bot: botDetailCollapsed,
                    history: historyListCollapsed,
                    task: !taskOpen,
                  }}
                  // extraButton={extraButton}
                  showDetailIcon={showDetailIcon}
                  showTask={agentRule?.preset_tasks_enabled && showTask}
                  // prefixButton={backButton}
                  canShare={canShare}
                  // typingTitle={typingTitle}
                  // typingFinished={isTypingEnd}
                  onCollapse={handleCollapse}
                  // onConversationCreate={() => handleConversationEdit('create')}
                  getMessageList={() => chatRef.current?.messageList || []}
                />
              ),
            }}
            conversationListProps={{
              unReadMessageConversationCountMap:
                unreadMessageCountMap.conversation,
            }}
            agentProfileProps={{
              actions: botDetailActions,
            }}
            botListProps={{
              prefix: BotListHeader,
              notificationMap: unreadMessageCountMap.robot,
              selectedId: botId,
            }}
            onBeforeSubmit={onBeforeSubmit}
            background='white'
            onChatSend={handleChatSend}
            // onConversationCreated={handleConversationCreated}
            onTitleGenerate={onTitleGenerate}
            disabled={conversationsLoading}
            handleBeforeFileUpload={handleBeforeFileUpload}
            onMessageTaskClick={handleOpenTask}
            uploadFileConfig={uploadFileConfig}
          />
        </div>
        <PrivateDeployGuard>
          {showPowerBy && (
            <div
              onClick={() => window.open('https://www.betteryeah.com/')}
              className='c-#A1A5C2 text-12px flex gap-12px flex-items-center justify-center pb-10px cursor-pointer'
            >
              <img className='h-16px w-16px' src={logo} alt='' />
              Powered by BetterYeah
            </div>
          )}
        </PrivateDeployGuard>
      </div>
      <BotDetail
        className={cn(
          'w-300px shrink-0 transition-width',
          botDetailCollapsed ? 'w-0! overflow-hidden' : '',
        )}
        bot={bot}
        collapsed={botDetailCollapsed}
        onCollapse={setBotDetailCollapsed}
        onPromptClick={handlePromptClick}
      />
      <div
        className={cn(
          'w-480px shrink-0 transition-width overflow-hidden border-left after:border-l-line after:border-op-60',
          !taskOpen ? 'w-0! z-[-1]' : '',
        )}
      >
        {taskOpen && (
          <TaskInfo
            agentId={botId}
            versionId={bot.version_id}
            showTaskId={showTaskId}
            taskRecordId={taskRecordId}
            getConversationId={getConversationId}
            onBack={handleTaskBack}
            onClose={handleTaskClose}
          />
        )}
      </div>
    </div>
  )
}
