import { useState, useEffect } from 'react'

export function useWindowDimensions() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(() => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { windowWidth, windowHeight }
}

export default useWindowDimensions
