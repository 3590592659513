import { Popover } from 'antd'
import { useRef } from 'react'
import { IconFont } from '@/components'
import type { NodeType } from '@/features/nodes/base'
import { usePanelPlacement } from '../hooks/usePanelPlacement'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { InsertNodePanel } from './InsertNodePanel'

interface Props {
  onPanelVisibleToggle: (show: boolean) => void
  onInsert: (data: { type: NodeType; data?: any }) => void
}

export function AddButton(props: Props) {
  const { onPanelVisibleToggle, onInsert } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const placementRef = usePanelPlacement(containerRef)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const locked = lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK

  return (
    <Popover
      arrow={false}
      trigger='click'
      placement={placementRef.current}
      destroyTooltipOnHide
      content={locked ? null : <InsertNodePanel onSelect={onInsert} />}
      getPopupContainer={() => containerRef.current!}
      // overlayClassName='right--304px! top--8px! inset-unset!'
      overlayInnerStyle={{ padding: 0, width: 300, boxShadow: 'none' }}
      autoAdjustOverflow
      onOpenChange={open => {
        onPanelVisibleToggle(open)
      }}
    >
      <div
        ref={containerRef}
        className='relative flex-center w-24px h-24px b-rd-50% bg-#7F69FF cursor-pointer'
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <IconFont name='add' className='text-16px text-white' />
      </div>
    </Popover>
  )
}
