import type { PropsWithChildren, ReactNode } from 'react'
import { useAuth } from '@bty/react-auth'
import { useVersionStore } from '@/store'
import type { Version } from '@/features/pay'
import { Button } from '@/components'
import { useLimitedModal } from '@/features/pay'
import { isWithinValidityPeriod } from '@/utils/date'

interface GuardProps {
  allowVersions: Version[]
  failBackContent?: string | ReactNode
  shouldValidatePeriod?: boolean
  classNames?: string
}

export function PagePaidGuard(props: PropsWithChildren<GuardProps>) {
  const {
    allowVersions,
    children,
    failBackContent,
    shouldValidatePeriod = true,
    classNames = '',
  } = props
  const versionInfo = useVersionStore(state => state.versionInfo)
  const {
    state: { role: workspaceRole },
  } = useAuth()

  const [comboModal] = useLimitedModal()

  if (
    versionInfo &&
    allowVersions.includes(versionInfo?.product_code) &&
    (shouldValidatePeriod
      ? isWithinValidityPeriod(versionInfo.lose_effect_time)
      : true)
  ) {
    return children
  }

  return (
    <div className='w-full h-full'>
      <div
        className={`z-2 bg-#fff bg-op-10 absolute top-0 left-0 w-full h-full flex-center flex-col backdrop-filter backdrop-blur-6px  ${classNames} `}
      >
        <div>{failBackContent}</div>
        <Button
          onClick={() => comboModal.show({ role: workspaceRole })}
          type='primary'
          className='!px-40 mt-24'
        >
          去升级
        </Button>
      </div>
      {children}
    </div>
  )
}
