import type { FC } from 'react'
import { useRef, useMemo, useState } from 'react'
import { Select as AntdSelect, Tooltip } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import type { InputRef } from 'antd'
import { Input, Select, IconFont, usePageModal } from '@/components'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { useWorkspaceStore } from '@/store'
import { SUB_FLOW_STATUS } from '../../base/types'
import { Empty } from '../../components/Empty'

interface FlowSelectProps {
  value?: number
  onChange?: (config: any) => void
  onPreChange?: () => void
  className?: string
  disabled?: boolean
  lastSelectedInfo?: {
    status: SUB_FLOW_STATUS
    name: string
  }
}

const { Option } = AntdSelect

const StyledSelect = styled(Select)`
  .ant-select-selector {
    padding: 8px !important;
    .ant-select-selection-item {
      padding-inline-end: 12px !important;
      .link-action {
        opacity: 1;
      }
    }
  }
`

const SelectedWrapper = styled.div`
  padding: 0px !important;
  &:hover {
    .select-flow_icon {
      opacity: 1;
    }
  }
`

const OptionContent = styled.div`
  &:hover {
    .link-action {
      opacity: 1;
    }
  }
`

export const FlowSelect: FC<FlowSelectProps> = props => {
  const { value, onChange, lastSelectedInfo, onPreChange, disabled } = props
  const subFlowList = useNodeMetaStore(state => state.subFlowList)
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const [searchValue, setSearchValue] = useState('')
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const pageModal = usePageModal()

  const list = useMemo(() => {
    if (!searchValue.trim()) {
      return subFlowList
    }
    return subFlowList.filter(item => {
      return item.flow_name.includes(searchValue)
    })
  }, [subFlowList, searchValue])

  const selectValue = useMemo(() => {
    if (
      !lastSelectedInfo ||
      lastSelectedInfo?.status === SUB_FLOW_STATUS.ACTIVE ||
      !value
    ) {
      return value
    } else {
      switch (lastSelectedInfo?.status) {
        case SUB_FLOW_STATUS.DELETED:
          return `${lastSelectedInfo.name}[已删除]`
        case SUB_FLOW_STATUS.UNABLE:
          return `${lastSelectedInfo.name}[已禁用]`
        default:
          return ''
      }
    }
  }, [value, lastSelectedInfo])

  return (
    <div
      ref={ref}
      className={classNames('nodrag nopan nowheel', props.className)}
    >
      <StyledSelect
        size='small'
        virtual={false}
        className='nodrag nopan'
        value={selectValue}
        onChange={async id => {
          await onPreChange?.()
          onChange?.(id)
        }}
        onDropdownVisibleChange={visible => {
          setSearchValue('')
          if (visible) {
            setTimeout(() => {
              inputRef?.current?.focus?.()
            }, 100)
          }
        }}
        placeholder='请选择Flow'
        notFoundContent={<Empty />}
        optionLabelProp={'label'}
        getPopupContainer={() => ref.current!}
        dropdownRender={menu => {
          return (
            <>
              <Input
                prefix={<IconFont name='search' className='text-16px' />}
                placeholder='搜索Flow名称'
                ref={inputRef}
                size='small'
                value={searchValue}
                onChange={e => {
                  setSearchValue(e.target.value)
                }}
              />
              {menu}
            </>
          )
        }}
      >
        {list.map(item => {
          return (
            <Option
              key={item.flow_id}
              label={
                <SelectedWrapper className='flex items-center nodrag nopan'>
                  {item.icon?.startsWith('http') ? (
                    <img
                      className={classNames(
                        'w-24px h-24px rounded-4px object-contain shrink-0',
                      )}
                      src={item.icon}
                    />
                  ) : (
                    <span
                      className={classNames(
                        'w-24px h-24px rounded-4px text-12px/24px  flex items-center justify-center shrink-0',
                      )}
                      style={{ backgroundColor: item.color }}
                    >
                      {item.icon}
                    </span>
                  )}
                  <span className='ml-8px flex-1'>{item.flow_name}</span>
                  <span
                    className='mr-8px self-end w-24px h-24px rounded-4px hover:bg-bg_3 hover:bg-op-12 cursor-pointer flex-center'
                    onClick={e => {
                      if (disabled) {
                        return
                      }
                      e.stopPropagation()
                      e.preventDefault()
                      pageModal.show({
                        url: `/application/${currentWorkspaceId}/${item.app_id}/flow/${item.flow_id}`,
                      })
                    }}
                  >
                    <Tooltip placement='top' title='查看Flow详情'>
                      <IconFont
                        name='super-link'
                        className={classNames(
                          'c-#626999 opacity-60 text-16px',
                          { 'cursor-not-allowed': disabled },
                        )}
                      />
                    </Tooltip>
                  </span>
                </SelectedWrapper>
              }
              value={item.flow_id}
              className='!p-8px !mt-4px'
            >
              <OptionContent className='flex-center-between h-full flex-1'>
                <div className='flex items-center'>
                  <div className='flex-1 flex items-start overflow-hidden'>
                    {item.icon?.startsWith('http') ? (
                      <img
                        className={classNames(
                          'w-24px h-24px rounded-4px object-contain shrink-0',
                        )}
                        src={item.icon}
                      />
                    ) : (
                      <span
                        className={classNames(
                          'w-24px h-24px rounded-4px text-12px/24px text-center shrink-0',
                        )}
                        style={{ backgroundColor: item.color }}
                      >
                        {item.icon}
                      </span>
                    )}
                    <div className='ml-8 flex-1 overflow-hidden'>
                      <div className='font-medium flex items-center'>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              'leading-16px text-font flex',
                              {},
                            )}
                          >
                            {item.flow_name}
                          </span>
                        </div>
                      </div>
                      <p
                        className={classNames(
                          'mt-6 text-12px/16px max-w-260px truncate font-400 c-#8d8d99 opacity-60',
                        )}
                        title={item.description}
                      >
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
                <span
                  className='link-action w-24px h-24px rounded-4px hover:bg-bg_3 hover:bg-op-12 cursor-pointer flex-center opacity-0'
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    pageModal.show({
                      url: `/application/${currentWorkspaceId}/${item.app_id}/flow/${item.flow_id}`,
                    })
                  }}
                >
                  <Tooltip placement='top' title='查看Flow详情'>
                    <IconFont
                      name='super-link'
                      className='c-#626999 opacity-60 text-16px'
                    />
                  </Tooltip>
                </span>
              </OptionContent>
            </Option>
          )
        })}
      </StyledSelect>
    </div>
  )
}
