import { create } from 'zustand'
import { fetchShowPromptReferenceLinkPromptConfig } from '@/apis/apollo_config'

interface ApolloConfigStore {
  showKnowledgeReferencePromptConfig: string
  initApolloConfigStore: () => void
}

export const useApolloConfig = create<ApolloConfigStore>(set => ({
  showKnowledgeReferencePromptConfig: '',
  initApolloConfigStore: async () => {
    const config = await fetchShowPromptReferenceLinkPromptConfig()
    set({
      showKnowledgeReferencePromptConfig: config,
    })
  },
}))
