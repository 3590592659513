import { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import _ from 'lodash-es'
import { Spin, Tooltip } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import type { AgentPluginSkill } from '@bty/chat-types'
import { useFlowList } from '@/features/application/hooks/useFlowList.ts'
import { useCreateFlow } from '@/features/application/hooks/useCreateFlow.ts'
import type { SkillBaseInfo, ApplicationBaseInfo } from '@/features/agent/types'
import { Skill } from '@/features/agent/components/Skill.tsx'
import { AgentSkillType } from '@/features/agent/constant'
import { Button, IconFont, Input, usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import type { SkillType } from '../types'
import {
  FLOW_DISPLAYNAME,
  PLUGIN_DISPLAYNAME,
  FILEPROCESS_CATEGORY,
} from '@/constants/common'
import { showStartTipsStorage } from '@/utils/storage'
import type { AllListProps } from '@/pages/plugins/index'
import type { ICardItem } from '@/pages/plugins/components/Card'
import Empty from '@/pages/plugins/components/Empty'
import PluginInfo, { PageType } from '@/pages/plugins/pluginInfo'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

export interface SelectedSkill {
  type: AgentSkillType
  id: string
  flowMeta?: ApplicationBaseInfo
}
interface SkillSelectProps {
  fileProcess?: boolean
  value?: SelectedSkill[]
  onDelete?: (id: string) => Promise<void>
  onChange?: (value: SelectedSkill) => void
  selectedSkillMap: Record<'flow' | 'tool', Record<string, true>>
  activeSkillType?: SkillType
  onConfirm?: (id: string) => Promise<void>
  allPluginList: Array<AllListProps>
  onAddPlugin: (selectedPlugins: string[]) => Promise<void>
  toolListTotal?: AgentPluginSkill[]
}

enum RenderType {
  all = 'all',
  searchResult = 'searchResult',
}

interface PluginRenderMode {
  title: string
  icon: string
  id: string
}

export function SkillSelect(props: SkillSelectProps) {
  const {
    allPluginList,
    onAddPlugin,
    onDelete,
    onChange,
    selectedSkillMap,
    activeSkillType,
    onConfirm,
    toolListTotal,
    fileProcess = false,
  } = props
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(
    null,
  )
  const [pluginRenderType, setPluginRenderType] = useState<RenderType>(
    RenderType.all,
  )
  const [searchResult, setSearchResult] = useState<Array<ICardItem>>([])
  const [isHoverList, setHoverList] = useState<string[]>([])
  const [selectedPlugins, setSelectedPlugins] = useState([
    ...Object.keys(selectedSkillMap.tool),
  ])
  const [pluginRenderMode, setPluginRenderMode] = useState<{
    mode: 'info' | 'list'
    info: null | PluginRenderMode
  }>({
    mode: 'list',
    info: null,
  })
  const [pluginInfoHovered, setPluginInfoHovered] = useState<boolean>(false)
  const scrollTopBeforeNavigateRef = useRef<number | null>(null)
  const scrollContent = useRef<HTMLDivElement | null>(null)
  const {
    list: flowList,
    loading: fetchFlowLoading,
    refresh: refreshFlowList,
  } = useFlowList({
    refreshOnWindowFocus: true,
  })

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  // 每次从详情页返回后，将滚动位置重置为跳转之前的位置
  useEffect(() => {
    if (!scrollContainer || scrollTopBeforeNavigateRef.current === null) return

    scrollContainer.scrollTop = scrollTopBeforeNavigateRef.current
  }, [scrollContainer])

  const getTop = (label_id: string) => {
    const child = document.getElementById(label_id)
    if (!child || !scrollContent.current) return null

    const containerRect = scrollContent.current.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    return childRect.top - containerRect.top
  }

  const handleClickLocate = (label_id: string) => {
    setSelectedCategory(label_id)

    if (!scrollContainer || !label_id) return

    const top = getTop(label_id)

    if (_.isNil(top)) return

    scrollContainer.scrollTo({
      top,
      behavior: 'smooth',
    })
  }

  // 从上传文件入口打开的弹窗自动定位到解析能力类目
  useEffect(() => {
    if (scrollTopBeforeNavigateRef.current !== null) return

    if (allPluginList?.length > 0 && scrollContainer) {
      handleClickLocate(
        fileProcess ? FILEPROCESS_CATEGORY : allPluginList[0].label_id,
      )
    }
  }, [allPluginList, fileProcess, scrollContainer])

  useEffect(() => {
    if (activeSkillType !== 'Plugin') return
    setSelectedPlugins(Object.keys(selectedSkillMap.tool))
  }, [activeSkillType, selectedSkillMap])
  const [searchValue, setSearchValue] = useState('')

  const sortByDisabled = (arr: Array<SkillBaseInfo & any>) => {
    return arr.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (b.disabled && !a.disabled) {
        return -1
      }
      return 0
    })
  }
  const pageModal = usePageModal()
  const flowSelectOptions: Array<
    SkillBaseInfo & { applicationInfo: ApplicationBaseInfo }
  > = useMemo(
    () =>
      sortByDisabled(
        flowList
          .filter(item => item.isPublish && item.isEnable)
          .map(item => {
            return {
              name: item.name,
              description: item.description,
              id: item.flowId,
              applicationInfo: {
                id: item.id,
                name: item.name,
                icon: item.icon,
                color: item.color,
                flowId: item.flowId,
              },
              icon: item.icon,
              color: item.color,
              type: AgentSkillType.FLOW,
              disabled: selectedSkillMap.flow[item.flowId],
            }
          })
          .filter(item =>
            item.name
              ?.toLocaleLowerCase()
              .includes(searchValue?.toLocaleLowerCase()),
          ),
      ),
    [flowList, searchValue],
  )

  const handleSearch = (query: string) => {
    const allPlugins = allPluginList.reduce(
      (res: ICardItem[], item: { title: string; child: ICardItem[] }) => {
        if (item.title.includes('推出')) return res
        return res.concat(item.child)
      },
      [],
    )
    if (query.trim().length === 0) {
      setPluginRenderType(RenderType.all)
    } else {
      const result = allPlugins.filter((item: ICardItem) =>
        item.title.toLowerCase().includes(query.trim().toLowerCase()),
      )

      const matchedCategoryIndex = allPluginList.findIndex(
        (item: { title: string }) => item.title === query,
      )
      if (matchedCategoryIndex !== -1) {
        result.push(
          ...allPluginList[matchedCategoryIndex].child.filter(
            (item: ICardItem) => {
              return (
                result.findIndex((v: ICardItem) => v.title === item.title) ===
                -1
              )
            },
          ),
        )
      }

      setPluginRenderType(RenderType.searchResult)
      setSearchResult(result)
    }
  }

  useEffect(() => {
    if (!scrollContainer || !allPluginList.length) return

    scrollContainer.addEventListener(
      'scroll',
      _.debounce(function () {
        const scrollTop = (scrollContainer as HTMLElement).scrollTop
        const min = {
          label_id: '',
          distance: Number.POSITIVE_INFINITY,
        }
        allPluginList.forEach((item: AllListProps) => {
          const top = getTop(item.label_id)

          if (_.isNil(top)) return

          const itemDistance = Math.abs(scrollTop - top)

          if (itemDistance < min.distance) {
            min.label_id = item.label_id
            min.distance = itemDistance
          }
        })

        if (min.label_id.length > 0) setSelectedCategory(min.label_id)
      }, 300),
    )
  }, [scrollContainer, allPluginList])

  const { create: onFlowCreate } = useCreateFlow(flowList, data => {
    showStartTipsStorage.set(true)
    pageModal.show({
      url: `/application/${currentWorkspaceId}/${data.id}/flow/${data.flowId}`,
    })
  })

  const togglePlugin = useCallback(
    (id: string) => {
      if (selectedPlugins.includes(id)) {
        onDelete?.(id as string).then(() => {
          setSelectedPlugins(d => d.filter(item => item !== id))
        })
      } else {
        onAddPlugin([id]).then(() => {
          setSelectedPlugins(selectedPlugins.concat([id]))
        })
      }
    },
    [selectedPlugins, onDelete, onAddPlugin],
  )

  const renderItem = (c: ICardItem) => {
    const consume_mode = (toolListTotal ?? []).find(
      item => item.function_id === c.rel_function_id,
    )?.consume_mode
    return (
      <div
        onClick={() => {
          scrollTopBeforeNavigateRef.current = scrollContainer?.scrollTop ?? 0

          setPluginRenderMode({
            mode: 'info',
            info: {
              id: c.rel_function_id,
              title: c.title,
              icon: c.avatar as string,
            },
          })
        }}
        key={c.rel_function_id}
        style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.4)' }}
        className='cursor-pointer items-center flex justify-between h-72px hover:bg-#f2f2f6 pr-[16px] pl-[16px]'
      >
        <div className='flex gap-12px items-center'>
          <img src={c.avatar} className='w-40px h-40px' />
          <div>
            <p className='text-14px line-height-16px font-500 mb-8px'>
              {c.title}
            </p>
            {c.description.length > 49 ? (
              <Tooltip title={c.description}>
                <p className='c-#767680 text-12px max-w-500px overflow-hidden text-ellipsis whitespace-nowrap'>
                  {c.description}
                </p>
              </Tooltip>
            ) : (
              <p className='c-#767680 text-12px'>{c.description}</p>
            )}
          </div>
        </div>
        <div className='flex flex-row gap-[24px] items-center text-[12px] c-[#8D8D99]'>
          {consume_mode ? <div>{consume_mode}</div> : null}
          <Button
            onMouseEnter={() =>
              setHoverList(isHoverList.concat([c.rel_function_id]))
            }
            onMouseLeave={() =>
              setHoverList(
                isHoverList.filter(item => item !== c.rel_function_id),
              )
            }
            type='primary'
            className={`w-80px h-36px ${
              selectedPlugins.includes(c.rel_function_id)
                ? 'bg-[rgba(98,105,153,0.08)]! c-[#8D8D99]! hover:bg-error! hover:c-#ffffff!'
                : 'bg-primary'
            }`}
            onClick={e => {
              togglePlugin(c.rel_function_id)
              e.stopPropagation()
            }}
          >
            {selectedPlugins.includes(c.rel_function_id)
              ? isHoverList.includes(c.rel_function_id)
                ? '移除'
                : '已添加'
              : '添加'}
          </Button>
        </div>
      </div>
    )
  }

  if (
    (activeSkillType === 'Flow' && fetchFlowLoading) ||
    (activeSkillType === 'Plugin' && allPluginList.length <= 0)
  ) {
    return (
      <div className='w-full h-612px flex-center'>
        <Spin />
      </div>
    )
  }

  return (
    <>
      {activeSkillType === 'Flow' && (
        <>
          <div className='mb-24'>
            <div className='flex items-center'>
              <Input
                className='w-300px important:text-14px'
                prefix={<IconFont name='search' />}
                placeholder='搜索'
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />

              <div className='b-l-1 b-l-line b-l-op-80 h-16px w-1px mx-16px'></div>
              <BrowserRouter>
                <LimitedAccess limitedType='flow'>
                  <Button
                    type='primary'
                    onClick={async () => {
                      await onFlowCreate()
                      // 创建会依赖Flow数量，如果不刷新，会导致重名从而服务端报错，这里需要服务端兼容。
                      refreshFlowList()
                    }}
                  >{`创建${FLOW_DISPLAYNAME}`}</Button>
                </LimitedAccess>
              </BrowserRouter>
            </div>
            {/* <SkillCreateButton label={`创建${FLOW_DISPLAYNAME}`} /> */}
            <div className='mt-12'>
              {flowSelectOptions.map(flow => (
                <Skill
                  key={flow.id}
                  className='group'
                  value={flow}
                  disabled={flow.disabled}
                  suffix={
                    flow.disabled ? (
                      <div className='text-12px pr-14px c-font_1'>已添加</div>
                    ) : (
                      <Button
                        className='invisible group-hover:visible'
                        type='primary'
                      >
                        添加
                      </Button>
                    )
                  }
                  onClick={
                    flow.disabled
                      ? undefined
                      : () => {
                          onConfirm?.(flow.id)
                          onChange?.({
                            type: flow.type,
                            id: flow.id,
                            flowMeta: flow.applicationInfo,
                          })
                        }
                  }
                />
              ))}
            </div>
          </div>
        </>
      )}
      {activeSkillType === 'Plugin' &&
        (pluginRenderMode.mode === 'list' ? (
          <div className='flex h-100%'>
            <div
              style={{
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                background: 'rgba(247, 247, 250, 0.6)',
                borderRight: '1px solid rgba(225, 225, 229, 0.6)',
              }}
              className='h-100% w-216px box-border'
            >
              <div
                style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
                className='h-48px pl-16px pt-12px font-600 text-16px line-height-24px'
              >
                添加{PLUGIN_DISPLAYNAME}
              </div>
              <div
                style={{ height: 'calc(100% - 48px)' }}
                className='flex flex-col box-border p-16px'
              >
                <Input
                  allowClear
                  prefix={<IconFont name='search' className='text-16px' />}
                  className='w-184px mb-16px'
                  placeholder='搜索插件'
                  onChange={e => handleSearch(e.target.value)}
                />
                <div
                  style={{ scrollbarWidth: 'none' }}
                  className='flex flex-col gap-4px flex-1 overflow-scroll'
                >
                  {allPluginList.map(item => (
                    <div
                      key={item.label_id}
                      onClick={() => handleClickLocate(item.label_id)}
                      className={`cursor-pointer h-40px c-[rgba(23,23,29,0.8)] line-height-40px pl-20px text-14px font-500 cursor-pointer rounded-8px ${
                        selectedCategory === item.label_id
                          ? 'c-[rgba(23,23,29)] bg-[--primary-light-8]'
                          : 'hover:bg-[rgba(98,105,153,0.08)]'
                      }`}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='flex-1 p-24px pt-48px'>
              <div
                ref={ref => setScrollContainer(ref)}
                style={{ scrollbarWidth: 'none' }}
                className='h-100% box-border overflow-scroll'
              >
                <div ref={scrollContent}>
                  {pluginRenderType === RenderType.all ? (
                    allPluginList.map((item, index: number) => (
                      <div
                        key={item.label_id}
                        id={item.label_id}
                        style={
                          index === allPluginList.length - 1
                            ? { height: 'calc(75vh - 72px)' }
                            : {}
                        }
                      >
                        <p
                          className={`${
                            index === 0 ? '' : 'mt-24px'
                          } text-14px font-500 line-height-16px mb-12px`}
                        >
                          {item.title}
                        </p>
                        {item.child.map(renderItem)}
                      </div>
                    ))
                  ) : searchResult.length > 0 ? (
                    searchResult.map(renderItem)
                  ) : (
                    <Empty style={{ height: 'calc(75vh - 72px)' }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex h-100% flex-col'>
            <div
              style={{ borderBottom: '1px solid #ededef' }}
              className='flex flex-row justify-between w-100% h-[56px]'
            >
              <div className='flex flex-row items-center pl-[8px] gap-[8px]'>
                <div
                  onClick={() =>
                    setPluginRenderMode({ mode: 'list', info: null })
                  }
                  className='flex justify-center items-center w-[32px] h-[32px] hover:bg-bg_3 hover:bg-op-8 rounded-[4px] cursor-pointer'
                >
                  <IconFont name='fanhui' className='text-16px' />
                </div>
                <img width={32} height={32} src={pluginRenderMode.info?.icon} />
                <div className='text-[16px] font-semibold'>
                  {pluginRenderMode.info?.title}
                </div>
              </div>
              <div className='mr-[56px] flex items-center'>
                <Button
                  type='primary'
                  className={`w-80px h-32px! p-[0px]! text-[12px] ${
                    selectedPlugins.includes(
                      (pluginRenderMode.info as PluginRenderMode).id,
                    )
                      ? 'bg-[rgba(98,105,153,0.08)]! c-[#8D8D99]! hover:bg-error! hover:c-#ffffff!'
                      : 'bg-primary'
                  }`}
                  onClick={() => {
                    togglePlugin((pluginRenderMode.info as PluginRenderMode).id)
                  }}
                  onMouseEnter={() => setPluginInfoHovered(true)}
                  onMouseLeave={() => setPluginInfoHovered(false)}
                >
                  {selectedPlugins.includes(
                    (pluginRenderMode.info as PluginRenderMode).id,
                  )
                    ? pluginInfoHovered
                      ? '移除'
                      : '已添加'
                    : '添加'}
                </Button>
              </div>
            </div>
            <div className='flex-1 overflow-hidden'>
              <BrowserRouter>
                <PluginInfo
                  pageType={PageType.infoInAgent}
                  id={(pluginRenderMode.info as PluginRenderMode).id}
                />
              </BrowserRouter>
            </div>
          </div>
        ))}
    </>
  )
}
