import classNames from 'classnames'
import wxLogo from '@/assets/agent-go/wx.png'
import wxPublicAccount from '@/assets/agent-go/wx-public-account.png'
import wxMiniProgram from '@/assets/agent-go/wx-mini-program.png'
import tiktokMiniProgram from '@/assets/agent-go/tiktok-mini-program.png'
import tiktokFanGroup from '@/assets/agent-go/tiktok.png'
import taobao from '@/assets/agent-go/taobao.png'
import jingdong from '@/assets/agent-go/jingdong.png'
import boss from '@/assets/agent-go/boss.png'
import feishu from '@/assets/agent-go/feishu.png'
import dingtalk from '@/assets/agent-go/dingtalk.png'
import email from '@/assets/agent-go/email.png'
import web from '@/assets/agent-go/web.png'
import app from '@/assets/agent-go/app.png'
import plugin from '@/assets/agent-go/plugin.png'
import { Checkbox } from '@/components'

interface PlatformSelectProps {
  value?: string[]
  onChange?: (value?: string[]) => void
}

const options = [
  {
    label: '微信客服群',
    icon: wxLogo,
    value: 'wx',
  },
  {
    label: '公众号',
    icon: wxPublicAccount,
    value: 'wx-public-account',
  },
  {
    label: '微信小程序',
    icon: wxMiniProgram,
    value: 'wx-mini-program',
  },
  {
    label: '抖音小程序',
    icon: tiktokMiniProgram,
    value: 'tiktok-mini-program',
  },
  {
    label: '抖音粉丝群',
    icon: tiktokFanGroup,
    value: 'tiktok-fan-group',
  },
  {
    label: '淘宝店铺',
    icon: taobao,
    value: 'taobao-shop',
  },
  {
    label: '京东店铺',
    icon: jingdong,
    value: 'jingdong-shop',
  },
  {
    label: 'Boss直聘',
    icon: boss,
    value: 'boss',
  },
  {
    label: '飞书',
    icon: feishu,
    value: 'feishu',
  },
  {
    label: '钉钉',
    icon: dingtalk,
    value: 'dingtalk',
  },
  {
    label: '个人邮箱',
    icon: email,
    value: 'email',
  },
  {
    label: '您的Web',
    icon: web,
    value: 'web',
  },
  {
    label: '您的App',
    icon: app,
    value: 'app',
  },
  {
    label: '开发插件',
    icon: plugin,
    value: 'plugin',
  },
  {
    label: '其他',
    value: 'other',
  },
]

export default function PlatformSelect(props: PlatformSelectProps) {
  const { value, onChange } = props

  const onItemClick = (_value: string) => {
    if (value?.includes(_value)) {
      onChange?.(value?.filter(item => item !== _value))
    } else {
      onChange?.([...(value ?? []), _value])
    }
  }

  return (
    <div className='flex flex-wrap gap-x-24 gap-y-20'>
      {options.map(item => {
        const isSelected = value?.includes(item.value)
        return (
          <div
            key={item.value}
            onClick={() => onItemClick(item.value)}
            className={classNames(
              'cursor-pointer shrink-0 bg-bg_3 hover:bg-op-12 hover:bg-primary bg-op-6 rounded-8px px-12 py-10 flex-center-between w-180px',
              {
                '!bg-primary !bg-op-12': isSelected,
              },
            )}
          >
            <div className='flex items-center'>
              {item.icon && (
                <img
                  className='w-28px h-28px rounded-8px'
                  src={item.icon}
                  alt='logo'
                />
              )}
              <span className='text-16px ml-8 font-400'>{item.label}</span>
            </div>
            <Checkbox checked={isSelected} className='!pb-0 !mb-0' />
          </div>
        )
      })}
    </div>
  )
}
