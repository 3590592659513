import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash-es'
import { ErrorBoundary } from '@bty/monitor'
import Header from '@/features/database/Header.tsx'
import { DatabaseProvider } from '@/features/database/provider/DatabaseProvider.tsx'
import { TableProvider } from '@/features/database/provider/TableProvider.tsx'
import TablePanel from '@/features/database/TablePanel.tsx'

export default function DBDetail() {
  const [qs] = useSearchParams()

  const hideTables = qs.get('hide_tables')

  const showHeader = isNil(hideTables)

  return (
    <DatabaseProvider>
      <TableProvider hideTables={!showHeader}>
        <div className='w-full h-full overflow-hidden flex flex-col'>
          {showHeader && <Header />}
          <ErrorBoundary>
            <TablePanel />
          </ErrorBoundary>
        </div>
      </TableProvider>
    </DatabaseProvider>
  )
}
