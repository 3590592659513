import { useMemo } from 'react'
import type { AgentRuleConfig } from '@bty/chat-types'
import { PromptType } from '@/apis/prompt.ts'

export function useAgentReady(agentRuleConfig?: AgentRuleConfig) {
  const hasSystemPrompt = useMemo(() => {
    if (agentRuleConfig) {
      const promptType = agentRuleConfig?.promptType ?? PromptType.RAW
      if (promptType === PromptType.RAW) {
        return !!agentRuleConfig.messages?.[0]?.content
      } else {
        const structPrompts = agentRuleConfig.structPrompt ?? []
        return structPrompts.some(item => !!item.content.trim())
      }
    }
    return false
  }, [agentRuleConfig])

  return {
    hasSystemPrompt,
  }
}
