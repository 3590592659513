import { Spin } from 'antd'
import { useEffect } from 'react'
import { useVersionStore } from '@/store'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { useWorkspace } from '@/hooks/useWorkspace'
import ComboUseCard from './components/ComboUseCard'
import Base from './Base'

export default function Overview() {
  const { versionInfo, fetchVersionLoading, fetchVersionByWorkspaceId } =
    useVersionStore()
  const { currentWorkspaceItem } = useWorkspace()
  const { scrollRef } = useScrollBar()

  useEffect(() => {
    currentWorkspaceItem?.id &&
      fetchVersionByWorkspaceId(currentWorkspaceItem?.id)
  }, [currentWorkspaceItem?.id])

  return (
    <div
      ref={scrollRef}
      className='h-full pb-20 flex-1 overflow-scroll adapt:px-64'
    >
      <Spin spinning={fetchVersionLoading}>
        <div className='flex flex-col gap-20px'>
          {versionInfo && <ComboUseCard versionInfo={versionInfo} />}
          <Base />
          {/* <DataCard versionInfo={versionInfo} /> */}
        </div>
      </Spin>
    </div>
  )
}
