import { Radio, message } from 'antd'
import { useRequest } from 'ahooks'
import copy from 'copy-to-clipboard'
import { useMemo } from 'react'
import { Button } from '@/components'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { changeApplicationAccess } from '@/apis/application'
import AgentGoWebLinkImg from '@/assets/agent-go/AgentGoWebLinkImg.png'
import { CopyBox } from '../components/CopyBox'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

export default () => {
  const { applicationInfo, refreshApplicationInfo, setOpenInvitePopover } =
    useAgentEdit()

  const { scrollRef } = useScrollBar()
  const { runAsync: changeAccess, loading } = useRequest(
    changeApplicationAccess,
    { manual: true },
  )

  const onToggle = async (checked: boolean) => {
    const res = await changeAccess({
      applicationId: applicationInfo?.id as string,
      publicAccess: checked,
    })
    if (res) {
      message.success('修改成功')
      refreshApplicationInfo()
    }
  }

  const text = useMemo(() => {
    return `${
      import.meta.env.VITE_AGENT_LINKS_URL
    }/chat/quickLink/${applicationInfo?.flowId}`
  }, [applicationInfo])

  const onCopy = () => {
    copy(text)
    message.success('已复制到剪切板')
  }

  return (
    <div
      ref={scrollRef}
      className='p-24px flex-1 w-full h-full overflow-y-auto relative'
    >
      <div className='adapt:mb-24 font-500 text-20px line-height-32px'>
        网页链接
      </div>
      <div className='rounded-8px bg-#fff p-24px'>
        <div className='text-14px font-500'>样式示例</div>
        <div className='py-12px c-font_1 text-14px'>
          用户可以通过链接，和当前 Agent 对话
        </div>
        <img src={AgentGoWebLinkImg} className='w-320px mb-24px' />
        <div className='text-14px font-500 mb-12px'>使用链接</div>
        <CopyBox value={text} />
        <div className='text-14px font-500 mb-12px mt-24px'>可用范围</div>
        <Radio.Group
          disabled={loading}
          size='middle'
          onChange={e => onToggle(e.target.value)}
          value={applicationInfo?.isPublic}
        >
          <Radio value={false}>
            <div className='flex items-center'>
              授权访问·你同意才能使用
              <Button
                type='link'
                size='middle'
                disabled={applicationInfo?.isPublic}
                className='!text-14px !h-36px flex items-center !px-8px'
                onClick={() => setOpenInvitePopover?.(true)}
              >
                邀请用户
              </Button>
            </div>
          </Radio>
          {/* <Radio value={true}>
            <div className='flex items-center'>公开访问·任何人都可使用</div>
          </Radio> */}
        </Radio.Group>
        <div className='flex gap-12px mt-12px'>
          <Button size='middle' onClick={() => window.open(text)}>
            去使用
          </Button>
          <Button size='middle' type='primary' onClick={onCopy}>
            复制链接
          </Button>
        </div>
      </div>
    </div>
  )
}
