import './index.css'

import type { ForwardedRef } from 'react'
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useMemoizedFn, useThrottleFn } from 'ahooks'
import classNames from 'classnames'
import { Spin } from 'antd'
import { isEmpty } from 'lodash-es'
import type { BarRef } from '../scrollbar'
import { Scrollbar } from '../scrollbar'
import { useResize } from '../scrollbar/hooks'
import { moreRef } from '@/utils/react'
import type { ResizeTableProps } from './type'
import { getMinWidth, getWidth } from './util'
import { AddRow, Row } from './components/body-row'
import { HeadRow } from './components/head-row'
import { ResizeTableProvider, useResizeTable } from './components/context'

export const ResizeTableInner = forwardRef(
  (props: ResizeTableProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, loading, heads, rows, addRow, onAddRow } = props

    const { observeWidth } = useResizeTable()

    const scrollRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const vBarRef = useRef<BarRef>(null)
    const hBarRef = useRef<BarRef>(null)
    const [vHas, setVHas] = useState(false)
    const [hHas, setHHas] = useState(false)
    const [widthMap, setWidthMap] = useState<Map<string, number>>()
    const [widthMapId, setWidthMapId] = useState<number>()
    const flexVar = useMemo(
      () => (heads ?? []).map(e => `var(--rt-row-${e.key})`),
      [heads],
    )
    const minWidth = useMemo(
      () => getMinWidth(heads, widthMap),
      [heads, widthMap, widthMapId],
    )
    const flexStyle = useMemo(
      () => getWidth(heads, widthMap),
      [heads, widthMap, widthMapId],
    )

    const handleScroll = useMemoizedFn(() => {
      vBarRef.current?.refresh()
      hBarRef.current?.refresh()
    })

    const handleResize = useMemoizedFn(() => {
      if (!scrollRef.current) return
      setVHas(scrollRef.current.scrollHeight > scrollRef.current.offsetHeight)
      setHHas(scrollRef.current.scrollWidth > scrollRef.current.offsetWidth)
      handleScroll()
    })

    const { run: handleUpdateWidth } = useThrottleFn(
      (widthMap: Map<string, number>) => {
        setWidthMap(widthMap)
        setWidthMapId(Date.now())
      },
      { wait: 16 },
    )

    useResize(scrollRef, handleResize)
    useResize(contentRef, handleResize)
    useEffect(() => observeWidth(handleUpdateWidth), [])

    if (heads?.length && isEmpty(flexStyle)) {
      return null
    }

    return (
      <div
        className={classNames(className, 'resize-table')}
        style={{ '--rt-min-width': minWidth, ...flexStyle } as any}
      >
        <div
          ref={moreRef(ref, scrollRef)}
          className='resize-table-view'
          onScroll={handleScroll}
        >
          <div ref={contentRef} className='resize-table-all'>
            <div className='resize-table-head'>
              <HeadRow heads={heads ?? []} flex={flexVar} />
            </div>

            <div className='resize-table-body'>
              {(rows ?? []).map((row, index) => (
                <Row
                  key={row.key}
                  no={index}
                  heads={heads ?? []}
                  row={row}
                  flex={flexVar}
                />
              ))}
            </div>

            {addRow && (
              <div className='resize-table-foot'>
                <AddRow key='add' onAddRow={onAddRow} />
              </div>
            )}
          </div>
        </div>

        <div
          className={classNames('resize-table-loading', {
            'resize-table-loading-show': loading,
          })}
        >
          <Spin />
        </div>

        {vHas && <Scrollbar ref={vBarRef} scrollRef={scrollRef} />}
        {hHas && <Scrollbar ref={hBarRef} scrollRef={scrollRef} horizontal />}
      </div>
    )
  },
)

export const ResizeTable = memo(
  forwardRef((props: ResizeTableProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { onSelect } = props

    return (
      <ResizeTableProvider onSelect={onSelect}>
        <ResizeTableInner ref={ref} {...props} />
      </ResizeTableProvider>
    )
  }),
)
