import type { PromptStructItem } from '@/apis/prompt.ts'
import { PromptStructType } from '@/apis/prompt.ts'
import {
  TaskType,
  TaskWriteMethods,
  UncertaintyStrategy,
} from '@/features/prompt/constant/generatePrompt.ts'

export function getPromptFromStructPrompt(
  structPrompts: PromptStructItem[],
): string {
  return structPrompts
    .filter(item => !!item.content)
    .sort((a, b) => b.weight - a.weight)
    .map(item => {
      return `## ${item.title}${item.content.trim() ? `\n${item.content.trim()}` : ''}`
    })
    .join('\n\n')
}

type GenerateFunc = (
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
  skills: { name: string; description: string }[],
) => string

const form2PromptMap: Record<PromptStructType, GenerateFunc> = {
  [PromptStructType.ROLE]: getGenerateRolePrompt,
  [PromptStructType.TASK]: getGenerateTaskPrompt,
  [PromptStructType.BACKGROUND]: getGenerateBackgroundPrompt,
  [PromptStructType.OUTPUT]: getGenerateOutputPrompt,
  [PromptStructType.REQUIREMENTS]: getGenerateRequirementPrompt,
  [PromptStructType.SKILL]: getGenerateSkillPrompt,
  [PromptStructType.CUSTOM]: getGenerateCustomPrompt,
}

export function getGeneratePrompt(
  type: PromptStructType,
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
  skills: { name: string; description: string }[],
): string {
  const fn = form2PromptMap[type]
  return fn(formValue, placeholderMap, skills)
}

function getGenerateRolePrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
) {
  let result = `角色信息：${formValue.role || placeholderMap.role}
使用场景：${formValue.scene || placeholderMap.scene}
工作任务：${formValue.task || placeholderMap.task}`
  if (formValue.personality?.length) {
    result += `\n人设性格：${formValue.personality.join(',')}`
  }
  if (formValue.tone?.length) {
    result += `\n人设语气：${formValue.tone.join(',')}`
  }
  if (formValue.outputLength) {
    result += `\n回复长度：${getOutputLengthPrompt(formValue.outputLength)}`
  }
  return result
}

function getGenerateBackgroundPrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
) {
  //   return `业务说明: ${formValue.businessDescription || placeholderMap.businessDescription}
  // 客户特点: ${formValue.customerProfile || placeholderMap.customerProfile}`
  return `业务说明: ${formValue.businessDescription || placeholderMap.businessDescription}`
}

function getGenerateSkillPrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
  skills: { name: string; description: string }[],
) {
  return `技能描述: \n${formValue.skillInfo || placeholderMap.skillInfo}\n已有技能:\n ${skills.map(item => `**${item.name}**: ${item.description}`).join('\n')}`
}

function getGenerateOutputPrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
) {
  const outputType = formValue.outputType
  switch (outputType) {
    case 'json':
      return (
        '按照Json格式输出，输出示例如下：' +
        '{\n' +
        '“产品”：{\n' +
        '“描述”：“适合专业人士的高性能笔记本电脑”，\n' +
        '},\n' +
        '“评论”：[\n' +
        '{\n' +
        '“作者”：“Alice”，\n' +
        '“评论”：“很棒的产品，但有点重。”\n' +
        '}]}'
      )
    case 'table':
      return (
        '按照表格markdwon的格式输出，输出示例如下：\n' +
        '| 名称 | 价格 | 数量 |\n' +
        '| ---- | ---- | ---- |\n' +
        '| 苹果 | 5 元 | 10 个 |\n' +
        '| 香蕉 | 3 元 | 8 根 |'
      )
    case 'text':
      return (
        '1、按照符合人类阅读习惯的文本格式输出；\n' +
        '\n  2、对重要的关键词，可以使用不同的格式突出显示，比如：\n' +
        '\t**这是加粗的文字**\n' +
        '\t*这是斜体的文字*'
      )
    case 'example':
      return `参考下面示例格式输出：
${formValue.outputExample || placeholderMap.outputExample}`
    default:
      return ''
  }
}

function getGenerateRequirementPrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
) {
  let result = ''
  if (formValue.contentRequirements?.length) {
    const contentRequirements = formValue.contentRequirements.map(
      (item: string) => {
        if (item === '自定义要求') {
          return (
            formValue.customContentRequirements ||
            placeholderMap.customContentRequirements
          )
        }
        return item
      },
    )
    result += `\n - 对内容/任务的要求：${contentRequirements.join(',')}`
  }
  if (formValue.showWorkings) {
    result += '\n - 输出思考过程'
  }
  if (formValue.checkAgain) {
    result += '\n - 回答前再次检查答案是否符合要求'
  }
  if (formValue.outputLength) {
    result += `\n - 回复长度：${getOutputLengthPrompt(formValue.outputLength)}`
  }
  return result
}

function getGenerateTaskPrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
) {
  function generateWriteMethods() {
    const methodsMap = {
      [TaskWriteMethods.NUM_EMOTION]:
        '数字情感法：用区别于形容目标对象的数字来强化更为强烈的情感色彩。\n' +
        '    + 写失眠：在凌晨的00点01分，我数到了第934只羊。\n' +
        '    + 写等待：你来的时候，我正好掐灭第14根烟。\n' +
        '    + 写爱：爸爸，我爱你3000次\n' +
        '    + 写穷：用6位数的密码，保护4位数的存款。\n' +
        '    + 写有钱：12345678..., 你在数什么？ 我在数我的存款有几位数。',
      [TaskWriteMethods.ADJ_KILL]:
        '杀死形容词法：文案内容不需要有形容词。\n' +
        '    + 温柔：用筷子夹起一块嫩豆腐\n' +
        '    + 难受：湿折脚穿秋裤\n' +
        '    + 开心：今天，是星期五\n' +
        '    + 不开心：今天星期五，这周单休',
      [TaskWriteMethods.CONFLICT]:
        '矛盾冲突法：通过制造预料完全相反的矛盾与冲突，来强化目标对象\n' +
        '    + 地方特产（卖点：家乡味）\n' +
        '      文案：离开了家乡，但离不开家香 （情绪冲突）\n' +
        '    + 饭店食材（卖点：真材实料）\n' +
        '      文案：良心，才是开不见的金字招牌 （视觉冲突）',
      [TaskWriteMethods.PROVERB]:
        '大众俗语法：用大家耳熟能详的俗语带入到文案创作中。\n' +
        '    + 别墅（卖点：楼间距宽，密度低）\n' +
        '      文案：距离产生别墅（俗语：距离产生美）\n' +
        '    + 升降桌（卖点：调整坐姿，改善弯腰驼背）\n' +
        '      文案：这辈子，直了 （俗语：这辈子值了）\n' +
        '    + 丰田汽车（卖点：越野性能强悍）\n' +
        '      文案：车到山前必有路，有路就有丰田车（俗语：车到山前必有路）',
      [TaskWriteMethods.CUSTOM]:
        formValue.customMethod || placeholderMap.customMethod,
    }
    const selectMethods = formValue.writeMethods || []
    return selectMethods
      .map((item: TaskWriteMethods) => methodsMap[item])
      .join('\n')
  }

  function generalQueryPrompt(
    strategy: UncertaintyStrategy,
    customAction: string,
  ) {
    switch (strategy) {
      case UncertaintyStrategy.UNKNOWN:
        return '输出不知道'
      case UncertaintyStrategy.RECONFIRM:
        return '再次确认问题'
      case UncertaintyStrategy.CUSTOM:
        return customAction
      default:
        return ''
    }
  }

  switch (formValue.taskType) {
    case TaskType.QUERY:
      return `任务：查询知识库
任务要求：
 - 没查到内容时: ${generalQueryPrompt(formValue.handleNoContent, formValue.customHandleNoContent || placeholderMap.customHandleNoContent)}
 - 无法判断内容是否正确时: ${generalQueryPrompt(formValue.resolveUncertainInput, formValue.customResolveUncertainInput || placeholderMap.customResolveUncertainInput)}`
    case TaskType.TAGGING:
      return `
任务：标签打标
 打标要求：
   ${formValue.taggingDesc || placeholderMap.taggingDesc}`
    case TaskType.DETECT_INTENT:
      return `
任务：意图分类
 分类要求：
   ${formValue.intentDesc || placeholderMap.intentDesc}`
    case TaskType.WRITE:
      return `
任务：文案编写
 - 撰写要目标：
  ${formValue.writeTarget || placeholderMap.writeTarget}
 - 撰写方法
  ${generateWriteMethods()}`
    default:
      return ''
  }
}

function getGenerateCustomPrompt(
  formValue: Record<string, any>,
  placeholderMap: Record<string, string>,
) {
  return `要求：
 ${formValue.customTopicDesc || placeholderMap.customTopicDesc}`
}

function getOutputLengthPrompt(length: 1 | 2 | 3) {
  switch (length) {
    case 1:
      return '简短'
    case 2:
      return '适中'
    case 3:
      return '详细'
    default:
      return ''
  }
}
