import { Form } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import type { JsonFormConfig } from '../../components'
import { JsonForm } from '../../components'
import { DatabaseSelect } from '../DatabaseSelect'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { Button } from '@/components'
import type { InnerNodePanelProps } from '../../base'
import { ExecuteResult } from './ExecuteResult'
import { SchemaFormItem } from './SchemaFormItem'

export function DatabaseNodePanel({
  variables,
  nodeElement,
  nodeSize,
  data,
}: InnerNodePanelProps) {
  const {
    run,
    loading: running,
    result,
    registerTriggerNode,
  } = useContext(DebugResultPanelContext)

  const [form] = Form.useForm()

  const database = Form.useWatch<{ id: string; title: string } | undefined>(
    ['inputs', 'database'],
    form,
  )

  const title = database?.title
  const identifier = database?.id

  const [open, setOpen] = useState(false)

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={running}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      async () => {
        await form.validateFields()
        // 下面这行别删，删了会出错!!!
        console.log('====== rest', data)
        run()
        setOpen(true)
      },
    )
  }, [form, data])

  const maxLines = nodeSize?.height
    ? Math.floor((nodeSize?.height - 238) / 16) - 1
    : 20

  const formList = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        className: 'p-16 !mb-0',
        label: '选择数据库',
        name: ['inputs', 'database'],
        required: true,
        rules: [{ required: true, message: '数据库不能为空' }],
        render: props => <DatabaseSelect {...props} />,
      },
      {
        noStyle: true,
        render: () => <SchemaFormItem title={title} identifier={identifier} />,
      },
      {
        noStyle: true,
        render: () => (
          <div className='h-1px w-full bg-[rgba(225,225,229,0.6)]' />
        ),
      },
      {
        className: 'p-16',
        label: 'SQL',
        type: 'CodeEditor',
        name: ['inputs', 'executableSQL'],
        required: true,
        rules: [{ required: true, message: 'SQL 不能为空' }],
        componentProps: {
          mode: 'sql',
          variables,
          setOptions: {
            minLines: maxLines,
            maxLines,
          },
          variableTipsContainer: nodeElement,
        },
      },
    ]
  }, [variables, maxLines, nodeElement, title, identifier])

  return (
    <>
      <JsonForm form={form} list={formList} />
      <ExecuteResult
        open={open}
        value={result?.output?.data}
        status={result?.status}
        error={result?.message}
        onOpenChange={setOpen}
      />
    </>
  )
}
