import Logo from '@/assets/newlogo.svg?url'
import SquareLogo from '@/assets/logo-square.svg?url'

export const passwordValidateRegexp =
  /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/

export const isPrivateVersion =
  import.meta.env.VITE_DEPLOY_TYPE === 'PRIVATE_DEPLOY'

export const DefaultModelIcon =
  'https://resource.bantouyan.com/battleyeah-ai/ai-client/Model_Icon/default.svg'

export const defaultRouterPath = isPrivateVersion ? '/agent' : '/explore'

export const PRODUCT_BASE_NAME = import.meta.env.VITE_APP_NAME ?? 'BetterYeah'

export const PRODUCT_NAME = `${PRODUCT_BASE_NAME}`

export const PRODUCT_TITLE = `${PRODUCT_BASE_NAME} Agent`

export const LOGO = import.meta.env.VITE_APP_LOGO ?? Logo

export const MINI_LOGO = import.meta.env.VITE_APP_MINI_LOGO ?? SquareLogo

export const KNOWLEDGE_ROUTER_PATH = 'knowledge'

export const grayscaleMap = {
  agent: '5cbdd1a8-e92b-4cd5-bc6d-36bf7e82d09b',
  chat: 'BETTER_YEAH_AGENT_CHAT',
  vision_file_action_type: 'VISION_FILE_ACTION_TYPE', // 图片传递方式 开启后链路讲带上文件的资源地址(后端吃该逻辑 前端处理仅添加该灰度字段)
}

export const VariableRegex =
  /^\{\{[a-zA-Z_][a-zA-Z0-9_]*(\.[a-zA-Z0-9_]+)*\}\}$/

export const LEGAL_REQUIRED_TEXT = '文案由AI生成'

export const IS_NEW_PLUGINS = 'IS_NEW_PLUGINS'

export const FLOW_CLIPBOARD = 'FLOW_CLIPBOARD'

export const NEW_FLOW_MAP = 'NEW_FLOW_MAP'

export const SERVER_IP_ADDRESSES = import.meta.env.VITE_IP_WHITELIST
  ? import.meta.env.VITE_IP_WHITELIST.split(',')
  : [
      '47.97.98.87',
      '47.97.90.83',
      '47.97.91.54',
      '121.196.221.25',
      '115.227.108.225',
    ]

export const PLUGIN_DISPLAYNAME = '插件'

export const FLOW_DISPLAYNAME = '工作流'

export const TEMPLATE_DISPLAYNAME = '模板'

export const FILEPROCESS_CATEGORY = '087794449407453da1a16fd2a1958268'
