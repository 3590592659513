import { useMemoizedFn, useSessionStorageState } from 'ahooks'
import { pickBy } from 'lodash-es'

export function useSessionValue(key: string, id: string) {
  const [map, setMap] = useSessionStorageState<Record<string, any>>(key)

  const setValue = useMemoizedFn(value => {
    setMap((prev = {}) => pickBy({ ...prev, [id]: value }))
  })

  return [map?.[id], setValue] as const
}

export function useSetSessionValue(key: string) {
  const [, setMap] = useSessionStorageState<Record<string, any>>(key)

  const setValue = useMemoizedFn((value: any, id: string) => {
    setMap((prev = {}) => pickBy({ ...prev, [id]: value }))
  })

  return setValue
}
