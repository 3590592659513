import { Switch, Tooltip } from 'antd'
import { IconFont } from '@/components'

export interface FormSwitchProps {
  value?: boolean
  onChange?: (value: boolean) => void
  disabled?: boolean
  label?: string
  tooltip?: string
}
export function FormSwitch(props: FormSwitchProps) {
  return (
    <div className='flex items-center'>
      <span className='text-12px font-500'>{props.label}</span>
      {props.tooltip && (
        <Tooltip placement='top' title={props.tooltip}>
          <IconFont
            name='shuimeng'
            className='text-16px ml-4 text-font_1 text-opacity-60 cursor-help'
          />
        </Tooltip>
      )}

      <Switch
        size='small'
        disabled={props.disabled}
        checked={props.value}
        onChange={props.onChange}
        className='ml-10 mt-2'
      />
    </div>
  )
}
