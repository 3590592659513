export enum DocumentType {
  FILE = 1,
  TEXT = 2,
  WEBPAGE = 3,
  TEMPLATE_FILE = 4,
  QA = 5,
  VIDEO = 6,
}

export enum DocumentSplitType {
  CUSTOM = -1, // 自定义分段
  AI = 0,
  Text = 1,
  DOCUMENT = 2,
  PAGE = 3,
  JSON = 4,
  CUSTOM_FAQ = 5,
  NO_SPLIT = 6,
}

export enum DocumentStatus {
  Wait = 0,
  Ing = 1,
  Done = 2,
  Fail = 3,
  Warning = 4,
  AIProcess = 5,
}

export enum ParagraphExtraInfoStatus {
  Error = 'error',
  Done = 'done',
  Process = 'processing',
  Pending = 'pending',
  Warning = 'warning', // 前端逻辑
}

export enum ExtraParagraphExtraInfoStatus {
  All = 0,
}

export enum ForkTaskStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum ProgressStage {
  PENDING = 'pending',
  UPDATE_EXTRACT_TEXT = 'update_extract_text',
  SPLIT_EXTRACT_TEXT = 'split_extract_text',
  SAVE_VECTOR = 'save_vector',
  CONVERT_FILE_EXT = 'convert_file_ext',
  EXTRACT_VIDEO_TEXT = 'extract_video_text',
  DONE = 'done',
}

export const OutputTypes = {
  JSON: 'json',
  TEXT: 'text',
} as const
