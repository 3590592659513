import { baseKeymap, chainCommands } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
import { redo, undo } from 'prosemirror-history'
import { enter } from './enter'
import { backspace } from './backspace'
import { arrowRight } from './arrow-right'
import { arrowLeft } from './arrow-left'
import { tab } from './tab'

export function peCommand() {
  return keymap({
    'Shift-Enter': chainCommands(enter, baseKeymap.Enter),
    Backspace: chainCommands(backspace, baseKeymap.Backspace),
    ArrowRight: chainCommands(arrowRight),
    ArrowLeft: chainCommands(arrowLeft),
    'Mod-a': chainCommands(baseKeymap['Mod-a']),
    'Mod-z': undo,
    'Mod-Shift-z': redo,
    Tab: tab,
  })
}
