import { useRef, useContext, useMemo } from 'react'
import classNames from 'classnames'

import styled from '@emotion/styled'

import ReactDiffViewer, { DiffMethod } from '../../ReactDiffViewer'

import { PromptDiffModalContext } from '..'
import { IconFont } from '@/components'
import theme from '@/constants/theme'

const customStyles = {
  variables: {
    light: {
      emptyLineBackground: 'rgb(244,243,247)',
    },
  },
  line: {
    wordBreak: 'break-word',
    background: 'rgb(244,243,247)',
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  contentText: {
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  diffAdded: {
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  diffRemoved: {
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  diffContainer: {
    width: '100%',
    background: 'rgb(244,243,247)',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  lineNumber: {
    fontSize: '12px !important',
    lineHeight: '20px !important',
  },
}

const TitleWrapper = styled.div`
  color: #7b61ff !important;
  .icon {
    color: ${theme.colors.primary};
  }
`
export function DiffResult(props: any) {
  const { readOnly = false, show = 'current' } = props
  const { oldData, data, getCurrentResult } = useContext(PromptDiffModalContext)

  const diffData = useMemo(() => {
    return {
      newValue: data?.resultInfo
        ? data?.resultInfo?.response
        : getCurrentResult(),
      oldValue: oldData?.resultInfo?.response,
    }
  }, [oldData, data, show])

  const wrapperRef = useRef<HTMLDivElement>(null)

  const component = useMemo(() => {
    return (
      <ReactDiffViewer
        show={show}
        oldValue={diffData.oldValue}
        newValue={diffData.newValue}
        compareMethod={DiffMethod.WORDS}
        splitView={true}
        showDiffOnly={false}
        useDarkTheme={false}
        disableWordDiff={false}
        hideLineNumbers={false}
        styles={customStyles}
      />
    )
  }, [diffData.newValue, diffData.oldValue])

  return (
    <div
      ref={wrapperRef}
      className='flex justify-between items-center flex-1'
      style={{
        pointerEvents: readOnly ? 'none' : 'auto',
        borderTop: '1px solid rgba(225,225,229,0.6)',
      }}
    >
      <div
        className={classNames(
          'opacity-100 relative rd-6px overflow-hidden mt-16px w-100%',
        )}
      >
        <TitleWrapper className='flex line-height-16px text-12px font-500 mb-8px'>
          <IconFont
            className='mr-4px text-16px cursor-pointer'
            name='yunxingjieluo'
          />
          <span className='line-height-16px'>运行结果</span>
        </TitleWrapper>
        {component}
      </div>
    </div>
  )
}
