import { Breadcrumb as AntdBreadcrumb } from 'antd'
import styled from '@emotion/styled'

export const Breadcrumb = styled(AntdBreadcrumb)`
  .ant-breadcrumb-link {
    color: ${props => props.theme.colors.font};
    user-select: none;
    a {
      color: ${props => props.theme.colors.font_1};
      background: none !important;
      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`
