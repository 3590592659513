import type { FC, PropsWithChildren, ReactElement } from 'react'
import { cloneElement } from 'react'
import type {
  FieldControl,
  FieldItemComponentsMap,
  FormSchema,
  MetaState,
} from '@bty/ui-components'
import copy from 'copy-to-clipboard'
import { message, Radio, Switch } from 'antd'
import cn from 'classnames'
import { FieldTypes, SelectViewMode } from '@/features/nodes/start'
import {
  GroupSelect,
  Input,
  JsonEditor,
  RequiredMark,
  Select,
  SliderSingle,
  TagViewSelect,
  TextArea,
} from '@/components'
import { FlowFileUpload } from '@/features/nodes/components/FieldsUpload'
import { MultiInput } from '@/features/nodes/plugin/components/MultiInput'

export const NodeFormItem: FC<
  PropsWithChildren<{
    control: FieldControl
    schema: FormSchema
    meta: MetaState
    className?: string
    noRequiredMark?: boolean
  }>
> = props => {
  const {
    children,
    schema,
    control,
    meta,
    className,
    noRequiredMark = false,
  } = props

  const hasError = meta.errors.length > 0

  const ClonedChildren = cloneElement(children as ReactElement, {
    ...control,
    size: schema.size ?? 'large',
    placeholder: schema.placeholder,
    disabled: meta.disabled,
    status: hasError ? 'error' : '',
    className: cn((children as ReactElement).props.className, schema.className),
    'aria-invalid': hasError ? 'true' : undefined,
  })

  return (
    <div
      className={cn('node-form-item mb-[20px] w-full box-border', className)}
    >
      <div className='pb-12 flex items-center'>
        <span>{schema.label}</span>
        {schema.required && !noRequiredMark && <RequiredMark />}
      </div>
      <div className='w-full'>{ClonedChildren}</div>
      <div
        className={cn(
          'mt-4 flex flex-col op-0 h-0 transition-all-200 ease-in-out',
          {
            'h-auto! op-100!': hasError,
          },
        )}
      >
        {meta.errors.map((error, index) => (
          <div className='c-error text-12px pt-8px pb-12' key={error + index}>
            {error}
          </div>
        ))}
      </div>
      {hasError && <div className='mb--20px'></div>}
    </div>
  )
}

export const defaultNodeFormItemComponentsMap: FieldItemComponentsMap = {
  [FieldTypes.Input]: (schema, control, meta) => {
    return (
      <NodeFormItem schema={schema} meta={meta} control={control}>
        <Input size='large' className='important:text-14px' />
      </NodeFormItem>
    )
  },
  [FieldTypes.Textarea]: (schema, control, meta) => (
    <NodeFormItem schema={schema} meta={meta} control={control}>
      <TextArea autoSize={{ minRows: 3 }} />
    </NodeFormItem>
  ),
  [FieldTypes.Select]: (schema, control, meta) => (
    <NodeFormItem schema={schema} meta={meta} control={control}>
      {schema.viewMode === SelectViewMode.Flat ? (
        <TagViewSelect type='single' options={schema.options} />
      ) : (
        <Select size='large' options={schema.options} className='w-full' />
      )}
    </NodeFormItem>
  ),
  [FieldTypes.MultiSelect]: (schema, control, meta) => (
    <NodeFormItem schema={schema} meta={meta} control={control}>
      {schema.viewMode === SelectViewMode.Flat ? (
        <TagViewSelect type='multi' options={schema.options} />
      ) : (
        <Select
          className='[&_.ant-select-selection-item]:h-24px! w-full'
          size='large'
          mode='multiple'
          options={schema.options}
        />
      )}
    </NodeFormItem>
  ),
  [FieldTypes.Json]: (schema, control, meta) => (
    <NodeFormItem schema={schema} meta={meta} control={control}>
      <JsonEditor
        onCopy={text => {
          copy(text)
          message.success('复制成功')
        }}
      />
    </NodeFormItem>
  ),
  [FieldTypes.File]: (schema, control, meta) => (
    <NodeFormItem schema={schema} meta={meta} control={control}>
      <FlowFileUpload
        hasError={!!meta.errors.length}
        supportFileConfig={schema.supportFileConfig}
        supportFileTypes={schema?.supportFileTypes}
      />
    </NodeFormItem>
  ),

  [FieldTypes.Slider]: (schema, control, meta) => {
    return (
      <NodeFormItem schema={schema} meta={meta} control={control}>
        <SliderSingle
          min={schema?.limit?.min ?? 0}
          max={schema?.limit?.max ?? 3}
          step={1}
          controls={false}
          placeholder={schema.placeholder}
        />
      </NodeFormItem>
    )
  },
  [FieldTypes.Switch]: (schema, control, meta) => {
    return (
      <NodeFormItem schema={schema} meta={meta} control={control}>
        <Switch size='small' />
      </NodeFormItem>
    )
  },
  [FieldTypes.Radio]: (schema, control, meta) => {
    return (
      <NodeFormItem schema={schema} meta={meta} control={control}>
        <Radio.Group options={schema.options} size='small' />
      </NodeFormItem>
    )
  },
  [FieldTypes.MultiInput]: (schema, control, meta) => {
    return (
      <NodeFormItem schema={schema} meta={meta} control={control}>
        <MultiInput
          limit={schema.limit}
          showButton={schema.showAddButton}
          placeholder={schema.placeholder}
          btnText={`添加${schema.label}`}
        />
      </NodeFormItem>
    )
  },
  [FieldTypes.ModelSelect]: (schema, control, meta) => {
    return (
      <NodeFormItem schema={schema} meta={meta} control={control}>
        <GroupSelect {...schema} size='small' />
      </NodeFormItem>
    )
  },
}
