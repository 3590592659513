import { useState, useMemo, useContext } from 'react'
import styled from '@emotion/styled'
import { Form, Radio, message, Tooltip } from 'antd'
import classNames from 'classnames'
import {
  Segmented,
  Button,
  FormItem,
  IconFont,
  Input,
  useConfirmModal,
} from '@/components'
import {
  unescapeUnrecognizedUrls,
  escapeUnrecognizedUrls,
} from '@/features/datastore/utils'
import { SplitSubTypes } from '@/pages/datastores/document-detail/SplitSubTypes.tsx'
import { MAX_SPLIT_SIZE } from '@/features/datastore/constant'
import type { DocumentType } from '@/apis/datastore/model.ts'
import { DocumentSplitType } from '@/apis/datastore/model.ts'
import type {
  DocumentItem,
  DocumentSplitRequest,
} from '@/apis/datastore/types.ts'
import { DocumentContext } from '@/pages/datastores/provider/DocumentProvider.tsx'
import { updateDocumentSplit } from '@/apis/datastore/api.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

interface SplitConfigProps {
  file_id: number
  split_config?: DocumentItem['split_config']
  fileMimetype: string
  fileType: DocumentType
  onBack: () => void
  onReset: () => void
  onPreview: (req: DocumentSplitRequest) => void
  onUpdate?: () => Promise<void> | void
}

const StyledSegmented = styled(Segmented)`
  padding: 2px;
  width: 100%;

  .ant-segmented-item {
    width: 50%;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.font_1};

    &.ant-segmented-item-selected {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.font};
    }
  }
`
const StyledFormItem = styled(FormItem)`
  .keyu-form-item-label {
    font-weight: 500;
    width: auto !important;
  }
`

enum Tabs {
  AUTO = 'auto',
  CUSTOM = 'custom',
}

const splitTip = (
  <div className='line-height-20px'>
    <p className='line-height-20px'>
      分段标识符：当解析到字符串中的特殊字符或控制符时自动分段。
    </p>
    <p className='line-height-20px'>以下是常见的标识符：</p>
    <p className='line-height-20px'>
      {' '}
      \n: 代表换行符，用于在文本中插入一个新行。
    </p>
    <p className='line-height-20px'>
      {' '}
      \t: 代表制表符，用于在文本中插入一个水平制表符，常用于文本对齐。
    </p>
    <p className='line-height-20px'>
      {' '}
      \r: 代表回车符，常与换行符配合使用（\r\n），用于在文本中插入一个回车。{' '}
    </p>
    <p className='line-height-20px'>
      {' '}
      \: 代表反斜杠自身，有时用于转义反斜杠本身。{' '}
    </p>
    <p className='line-height-20px'>
      {' '}
      **&quot;: 代表双引号，用于在字符串中包含双引号。{' '}
    </p>
    <p className='line-height-20px'>
      {' '}
      &apos;: 代表单引号，用于在字符串中包含单引号。{' '}
    </p>
    <p className='line-height-20px'> \b: 代表退格符，用于删除前一个字符。 </p>
    <p className='line-height-20px'>
      {' '}
      \a: 代表响铃符，用于触发计算机发出响铃声。{' '}
    </p>
    <p className='line-height-20px'>
      {' '}
      \0: 代表空字符，通常用于表示字符串的结尾。
    </p>
    <p className='line-height-20px'>
      {' '}
      \v: 代表垂直制表符，用于在文本中插入一个垂直制表符。
    </p>
  </div>
)

const splitTypes = [
  {
    label: '自动分段',
    value: Tabs.AUTO,
  },
  {
    label: '自定义分段',
    value: Tabs.CUSTOM,
  },
]

export function SplitConfig(props: SplitConfigProps) {
  const {
    fileMimetype,
    fileType,
    file_id,
    onBack,
    onPreview,
    onUpdate,
    split_config,
  } = props
  const [form] = Form.useForm<Required<DocumentItem>['split_config']>()
  const [tab, setTab] = useState(
    split_config?.rule_number === DocumentSplitType.CUSTOM ||
      split_config?.rule_number === DocumentSplitType.CUSTOM_FAQ
      ? Tabs.CUSTOM
      : Tabs.AUTO,
  )
  const [splitType, setSplitType] = useState<DocumentSplitType | undefined>(
    split_config?.rule_number,
  )

  const [customSplitType, setCustomSplitType] = useState<
    DocumentSplitType | undefined
  >(
    split_config?.rule_number === DocumentSplitType.CUSTOM ||
      split_config?.rule_number === DocumentSplitType.CUSTOM_FAQ
      ? split_config?.rule_number
      : DocumentSplitType.CUSTOM,
  )

  const [hasUserInteracted, setHasUserInteracted] = useState(false)
  const { onReprocessDocument } = useContext(DocumentContext)

  const subTypeSelectOptions = useMemo(() => {
    return (
      SplitSubTypes.filter(item => item.filter(fileType, fileMimetype)) ?? []
    )
  }, [fileMimetype, fileType])

  const transformSplitConfig = (
    values: Required<DocumentItem>['split_config'],
  ) => {
    let formData
    if (values.rule_number === DocumentSplitType.CUSTOM) {
      formData = values
      formData.split_pattern = unescapeUnrecognizedUrls(
        formData.split_pattern ?? '',
      )
    } else if (values.rule_number === DocumentSplitType.CUSTOM_FAQ) {
      formData = values
      formData.split_character = formData.split_character ?? ''
    } else {
      formData = {
        rule_number: values.rule_number,
        remove_sensitive_data: values.remove_sensitive_data,
      }
    }
    return formData
  }

  const [confirmModal] = useConfirmModal()
  const onSplitConfigChange = () => {
    form
      .validateFields()
      .then(values => {
        if (
          (tab === Tabs.AUTO &&
            (splitType === DocumentSplitType.CUSTOM ||
              splitType === DocumentSplitType.CUSTOM_FAQ)) ||
          (tab === Tabs.CUSTOM &&
            customSplitType !== DocumentSplitType.CUSTOM &&
            customSplitType !== DocumentSplitType.CUSTOM_FAQ)
        ) {
          message.error('至少选择一个分段类型')
          return
        }
        confirmModal.open({
          title: '重新分段后，数据会重置为原始数据，是否确认？',
          onOk: () => {
            if (tab === Tabs.CUSTOM) {
              values.rule_number = customSplitType!
            } else {
              values.rule_number = splitType!
            }

            const formData = transformSplitConfig(values)
            onReprocessDocument(
              async () => {
                await updateDocumentSplit({
                  file_id,
                  split_config: formData,
                })
                await onUpdate?.()
                return true
              },
              () => {
                onBack()
                message.success('分段成功')
              },
            )
            confirmModal.close()
          },
        })
      })
      .catch(() => {
        message.error('请正确表单信息')
      })
  }

  const onSplitPreview = (newValue?: Partial<DocumentItem['split_config']>) => {
    form
      .validateFields()
      .then(values => {
        const formData = transformSplitConfig(
          Object.assign(
            {
              rule_number: tab === Tabs.CUSTOM ? customSplitType : splitType,
            },
            values,
            newValue,
          ),
        )
        if (!formData.rule_number) throw new Error('no rule_number')
        if (
          (formData.rule_number === DocumentSplitType.CUSTOM &&
            !formData.split_pattern) ||
          (formData.rule_number === DocumentSplitType.CUSTOM_FAQ &&
            !formData.split_character)
        ) {
          console.warn('form 表单不完整，不触发分段预览')
          return
        }
        onPreview({
          file_id,
          split_config: formData,
        })
      })
      .catch(() => {
        console.warn('form 表单不完整，不触发分段预览')
      })
  }

  const onReset = () => {
    form.resetFields()
    setTab(
      split_config?.rule_number === DocumentSplitType.CUSTOM ||
        split_config?.rule_number === DocumentSplitType.CUSTOM_FAQ
        ? Tabs.CUSTOM
        : Tabs.AUTO,
    )
    setSplitType(split_config?.rule_number)
    setCustomSplitType(split_config?.rule_number)

    setHasUserInteracted(false)
    props.onReset()
  }

  const { scrollRef } = useScrollBar()

  return (
    <div className='py-24 flex flex-col flex-1 overflow-hidden'>
      <div className='px-24 font-500 text-16px'>选择分段方式</div>
      <div className='px-24 mt-14'>
        <StyledSegmented
          options={splitTypes}
          value={tab}
          onChange={(tab: any) => {
            setTab(tab)
            if (tab === Tabs.CUSTOM) {
              setCustomSplitType(customSplitType)
            } else {
              setSplitType(splitType)
            }
            setTimeout(() => {
              onSplitPreview({
                rule_number: tab === Tabs.AUTO ? splitType : customSplitType,
              })
            }, 200)

            setHasUserInteracted(true)
          }}
        />
      </div>
      <div ref={scrollRef} className='pt-22 px-24 flex-1 overflow-y-auto'>
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            ...split_config,
            split_pattern: escapeUnrecognizedUrls(
              split_config &&
                split_config.rule_number === DocumentSplitType.CUSTOM
                ? (split_config.split_pattern ?? '')
                : '',
            ),
            split_character:
              split_config &&
              split_config.rule_number === DocumentSplitType.CUSTOM_FAQ
                ? (split_config.split_character ?? '')
                : '',
          }}
          onValuesChange={() => setHasUserInteracted(true)}
          className='content'
        >
          {tab === Tabs.AUTO ? (
            subTypeSelectOptions.map(item => {
              return (
                <div
                  key={item.value}
                  className={classNames(
                    'cursor-pointer p-20 mb-12 last-of-type:mb-0 flex-center-between pb-16 rounded-8px b-1 b-#DCDCE1 b-op-80 hover:b-primary hover:bg-[rgba(98,105,153,0.08)]',
                    {
                      'b-primary': item.value === splitType,
                    },
                  )}
                  onClick={() => {
                    setSplitType(item.value)
                    onSplitPreview({ rule_number: item.value })
                    setHasUserInteracted(true)
                  }}
                >
                  <div>
                    <div className='font-500 mb-12'>{item.label}</div>
                    <div className='c-font c-op-80 font-400'>
                      {/* <div className="line-height-28px c-font_1">{item.content}</div> */}
                      <p className='line-height-28px'>
                        <span>按</span>
                        <Tooltip
                          overlayInnerStyle={{ maxWidth: 200 }}
                          title={
                            <>
                              {item.tips.map(text => (
                                <p key={text} className='line-height-20px'>
                                  {text}
                                </p>
                              ))}
                            </>
                          }
                        >
                          <span className='c-primary cursor-pointer'>
                            「{item.splitDescription}」
                          </span>
                        </Tooltip>
                        <span>进行分段</span>
                        <span>，适合{item.content}</span>
                      </p>
                    </div>
                  </div>
                  <Radio checked={item.value === splitType} />
                </div>
              )
            })
          ) : (
            <>
              <div
                className={classNames(
                  'cursor-pointer p-20 mb-12 last-of-type:mb-0 pb-16 rounded-8px b-1 b-#DCDCE1 b-op-80 hover:b-primary hover:bg-[rgba(98,105,153,0.08)]',
                  {
                    '!b-primary': splitType === DocumentSplitType.CUSTOM,
                  },
                )}
                onClick={() => {
                  // setSplitType(DocumentSplitType.CUSTOM)
                  setCustomSplitType(DocumentSplitType.CUSTOM)
                  onSplitPreview({
                    rule_number: DocumentSplitType.CUSTOM,
                    split_pattern: form.getFieldValue('split_pattern'),
                    max_length: form.getFieldValue('max_length'),
                    split_character: undefined,
                  })
                }}
              >
                <div className='flex flex-1 justify-between'>
                  <div className='text-14px mb-12px'>
                    <p className='line-height-16px c-#17171D font-500 mb-12px'>
                      通用自定义分段
                    </p>
                    <p className='c-[rgba(23,23,29,0.8)]'>适用范围: 所有文档</p>
                  </div>
                  <StyledFormItem>
                    <Radio
                      checked={customSplitType === DocumentSplitType.CUSTOM}
                    />
                  </StyledFormItem>
                </div>

                {customSplitType === DocumentSplitType.CUSTOM && (
                  <>
                    <div className='mt-8px b-t-1px mb-20px b-color-[rgba(225,225,229,0.6)]' />
                    <StyledFormItem
                      name='split_pattern'
                      label='设置分段标识符'
                      tooltip={{
                        title: splitTip,
                        icon: (
                          <IconFont
                            style={{
                              color: 'rgba(141,141,153, 0.4)',
                              fontSize: 12,
                              lineHeight: 16,
                            }}
                            name='jieshishuimeng'
                          />
                        ),
                        overlayStyle: {
                          minWidth: 437,
                        },
                      }}
                      rules={[
                        { required: true, message: '分段标识符不能为空' },
                      ]}
                    >
                      <Input
                        size='large'
                        placeholder='请输入分段标识符'
                        onBlur={() => onSplitPreview()}
                        onPressEnter={() => onSplitPreview()}
                      />
                    </StyledFormItem>
                    <StyledFormItem
                      className='m-b-0px'
                      name='max_length'
                      label='设置每段最长字'
                      rules={[
                        { required: true, message: '每段最长字不能为空' },
                        { pattern: /^\d+$/, message: '设置必须为数字' },
                        {
                          type: 'number',
                          transform: value => Number(value),
                          min: 50,
                          max: MAX_SPLIT_SIZE,
                          message: `长度必须在50-${MAX_SPLIT_SIZE}之间`,
                        },
                      ]}
                    >
                      <Input
                        size='large'
                        placeholder={`请输入每段最长字数，数值在50-${MAX_SPLIT_SIZE}之间`}
                        onBlur={() => onSplitPreview()}
                        onPressEnter={() => onSplitPreview()}
                      />
                    </StyledFormItem>
                  </>
                )}
              </div>
              <div
                className={classNames(
                  'cursor-pointer p-20 mb-12 last-of-type:mb-0 pb-16 rounded-8px b-1 b-#DCDCE1 b-op-80 hover:b-primary hover:bg-[rgba(98,105,153,0.08)]',
                  {
                    '!b-primary':
                      customSplitType === DocumentSplitType.CUSTOM_FAQ,
                  },
                )}
                onClick={() => {
                  setCustomSplitType(DocumentSplitType.CUSTOM_FAQ)
                  onSplitPreview({
                    rule_number: DocumentSplitType.CUSTOM_FAQ,
                    split_character: form.getFieldValue('split_character'),
                    max_length: form.getFieldValue('max_length'),
                    split_pattern: undefined,
                  })
                }}
              >
                <div className='flex flex-1 justify-between'>
                  <div className='text-14px mb-12px'>
                    <p className='line-height-16px c-#17171D font-500 mb-12px flex'>
                      <span>FAQ问答分段</span>
                      <Tooltip title='将一对FAQ问答，根据设置分为一段'>
                        <IconFont
                          className='ml-5'
                          style={{
                            color: 'rgba(141,141,153, 0.4)',
                            fontSize: 12,
                            lineHeight: 16,
                          }}
                          name='jieshishuimeng'
                        />
                      </Tooltip>
                    </p>
                    <p className='c-[rgba(23,23,29,0.8)]'>
                      适用范围：FAQ问答形式的文档内容
                    </p>
                  </div>
                  <StyledFormItem>
                    <Radio
                      checked={customSplitType === DocumentSplitType.CUSTOM_FAQ}
                    />
                  </StyledFormItem>
                </div>
                {customSplitType === DocumentSplitType.CUSTOM_FAQ && (
                  <>
                    <div className='mt-8px b-t-1px mb-20px b-color-[rgba(225,225,229,0.6)]' />
                    <StyledFormItem
                      name='split_character'
                      label='设置问题开始字符'
                      tooltip={{
                        title: <span>根据字符识别出FAQ文档的问答</span>,
                        icon: (
                          <IconFont
                            style={{
                              color: 'rgba(141,141,153, 0.4)',
                              fontSize: 12,
                              lineHeight: 16,
                            }}
                            name='jieshishuimeng'
                          />
                        ),
                        overlayStyle: {
                          minWidth: 437,
                        },
                      }}
                      rules={[{ required: true, message: '设置问题开始字符' }]}
                    >
                      <Input
                        size='large'
                        placeholder='请填写问题开始的字符（比如：问：、问题：等）'
                        onBlur={() => onSplitPreview()}
                        onPressEnter={() => onSplitPreview()}
                      />
                    </StyledFormItem>
                    <StyledFormItem
                      name='max_length'
                      label='设置每段最长字'
                      className='m-b-0px'
                      rules={[
                        { required: true, message: '每段最长字不能为空' },
                        { pattern: /^\d+$/, message: '设置必须为数字' },
                        {
                          type: 'number',
                          transform: value => Number(value),
                          min: 50,
                          max: MAX_SPLIT_SIZE,
                          message: `长度必须在50-${MAX_SPLIT_SIZE}之间`,
                        },
                      ]}
                    >
                      <Input
                        size='large'
                        placeholder={`请输入每段最长字数，数值在50-${MAX_SPLIT_SIZE}之间`}
                        onBlur={() => onSplitPreview()}
                        onPressEnter={() => onSplitPreview()}
                      />
                    </StyledFormItem>
                  </>
                )}
              </div>
            </>
          )}
          {/* <StyledFormItem name="remove_sensitive_data" valuePropName="checked"> */}
          {/*  <Checkbox onChange={() => { */}
          {/*    onSplitPreview() */}
          {/*    setHasUserInteracted(true) */}
          {/*  }}> */}
          {/*    <span className="select-none text-14px ml-3">删除敏感信息（包括手机号、电子邮件）</span> */}
          {/*  </Checkbox> */}
          {/* </StyledFormItem> */}
        </Form>
      </div>
      <div className='py-20 px-24 flex gap-12'>
        <Button
          size='large'
          className='w-full'
          onClick={onReset}
          disabled={!hasUserInteracted}
        >
          重置
        </Button>
        <Button
          size='large'
          type='primary'
          className='w-full'
          onClick={onSplitConfigChange}
        >
          重新分段
        </Button>
      </div>
    </div>
  )
}
