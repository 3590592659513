import classNames from 'classnames'
import { FieldTypes, type FiledType } from '..'
import { IconFont } from '@/components'

interface FieldTypeSelectProps {
  value?: FiledType
  onChange?: (value: FiledType) => void
}
export const FiledTypeSelectOptions = [
  {
    title: '单行文本',
    icon: 'newshanxingwenben-moren',
    selectedIcon: 'newshanxingwenben-xuanzhong',
    value: FieldTypes.Input,
  },
  {
    title: '文本域',
    icon: 'newwenbenyu-moren',
    selectedIcon: 'newwenbenyu-xuanzhong',
    value: FieldTypes.Textarea,
  },
  {
    title: '单选项',
    icon: 'newshanxuanxialakuang-moren',
    selectedIcon: 'newshanxuanxialakuang-xuanzhong',
    value: FieldTypes.Select,
  },
  {
    title: '多选项',
    icon: 'newduoxuanxialakuang-moren',
    selectedIcon: 'newduoxuanxialakuang-xuanzhong',
    value: FieldTypes.MultiSelect,
  },
  {
    title: 'JSON',
    icon: 'newjson-moren',
    selectedIcon: 'newjson-xuanzhong',
    value: FieldTypes.Json,
  },
  {
    title: '文件',
    icon: 'newwenjian-moren',
    selectedIcon: 'newwenjian-xuanzhong',
    value: FieldTypes.File,
  },
]
export function FieldTypeSelect(props: FieldTypeSelectProps) {
  return (
    <div className='flex flex-wrap items-center gap-12'>
      {FiledTypeSelectOptions.map(item => {
        const isSelected = props.value === item.value
        return (
          <div
            className={classNames(
              'w-88px h-64px text-font_1 flex flex-col font-400 items-center justify-center border border-solid rounded-6px cursor-pointer',
              {
                'border-color-line border-opacity-60 hover:bg-bg_2':
                  !isSelected,
                'border-color-primary bg-opacity-12 color-primary text-font':
                  isSelected,
              },
            )}
            onClick={() => props.onChange?.(item.value)}
            key={item.value}
          >
            <IconFont
              className='text-20px'
              name={isSelected ? item.selectedIcon : item.icon}
            />
            <span
              className={classNames('text-12px mt-8', {
                'c-[#17171d]': isSelected,
                'c-#8D8D99': !isSelected,
              })}
            >
              {item.title}
            </span>
          </div>
        )
      })}
    </div>
  )
}
