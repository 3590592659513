import { useReactFlow } from 'reactflow'
import { generateUniqueHandleStateId } from '@/features/flow/utils/handle'
import { useFlowInteractionStore } from '@/store'

export function useEdgesActivate() {
  const { getEdges, getNodes } = useReactFlow()
  const { setActiveStates } = useFlowInteractionStore()

  const activateEdges = () => {
    const edges = getEdges()
    if (!edges.length) return
    const nodes = getNodes()
    const handles: string[] = []
    edges.forEach(({ source, target, sourceHandle, type }) => {
      if (type !== 'insert') {
        return
      }
      const sourceNode = nodes.find(n => n.id === source)
      const targetNode = nodes.find(n => n.id === target)
      if (sourceNode) {
        handles.push(
          generateUniqueHandleStateId({
            nodeId: source,
            sourceHandle,
            type: 'source',
          }),
        )
      }
      if (targetNode) {
        handles.push(
          generateUniqueHandleStateId({ nodeId: target, type: 'target' }),
        )
      }
    })
    setActiveStates({
      handles,
      edgeIds: edges.map(e => e.id),
    })
  }

  return {
    activateEdges,
  }
}
