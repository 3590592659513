import { useState, useCallback, useRef } from 'react'
import { Select as AntdSelect } from 'antd'
import styled from '@emotion/styled'
import {
  Modal,
  Select,
  Button,
  IconFont,
  AppLogo,
  usePageModal,
} from '@/components'
import { useModal } from '@/hooks/useModal.tsx'

enum CoverType {
  AGENT = 'Agent',
  FLOW = '工作流',
}
interface ApplicationType {
  name: string
  icon: string
  id: string
  color: string
  workspaceId: string
}

interface CoverModalProps {
  onConfirm: (source_appid: string, target_appid?: string) => void
  dataList: ApplicationType[]
  appId: string
  selectType: string
  coverText?: string
}

interface CoverModalContentProps {
  onConfirm: (source_appid: string, target_appid?: string) => void
  dataList: ApplicationType[]
  appId: string
  selectType: string
}

const ContainSelect = styled.div`
  .ant-select-arrow {
    svg {
      font-size: 9px;
    }
    padding-top: 3px;
    padding-right: 3px;
  }
  .ant-select-selection-item {
    .invisible {
      visibility: visible;
    }
    .iconbox {
      margin-right: 5px;
    }
  }
  .ant-select-item-option-content {
    &:hover {
      .invisible {
        visibility: visible;
      }
    }
  }
`

function CoverModalContent(props: CoverModalContentProps) {
  const { appId, dataList, selectType, onConfirm } = props
  const pageModal = usePageModal()
  const ref = useRef<HTMLDivElement>(null)
  const dataOptions = dataList
    ?.filter(item => item.id !== appId)
    ?.map((k: ApplicationType) => {
      return {
        label: k.name,
        value: k.id,
        icon: k.icon,
        color: k.color,
        workspaceId: k.workspaceId,
        name: k.name,
        id: k.id,
      }
    })
  const showPageModal = (item: ApplicationType, event: React.MouseEvent) => {
    event.stopPropagation()
    const url =
      selectType === CoverType.AGENT
        ? `/agent/${item.id}/design`
        : `/application/${item.workspaceId}/${item.id}/flow/${item.id}`
    pageModal.show({ url })
  }
  const [selectId, setSelectId] = useState()
  return (
    <ContainSelect className='px-16px py-8px relative' ref={ref}>
      <h3 className='text-14px/14px font-500 mb-12px'>
        选择被覆盖的
        {selectType === CoverType.AGENT ? CoverType.AGENT : CoverType.FLOW}
      </h3>
      <Select
        className='w-full'
        placeholder={`选择${
          selectType === CoverType.AGENT ? CoverType.AGENT : CoverType.FLOW
        }`}
        value={selectId}
        defaultValue={null}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onSelect={setSelectId}
        getPopupContainer={() => ref.current!}
        showSearch
        filterOption={(input, option) => {
          if (!option?.label) {
            return false
          }
          return (
            option?.label
              ?.toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          )
        }}
      >
        {dataOptions?.map(item => (
          <AntdSelect.Option
            value={item.value}
            key={item.value}
            label={item.label}
          >
            <div className='flex items-center justify-between w-full'>
              <div className='flex items-center w-300px truncate'>
                {item?.color?.startsWith('http') ? (
                  <img
                    src={item.icon}
                    className='w-24px h-24px rounded-full mr-8px'
                  />
                ) : (
                  <AppLogo
                    type='emoji'
                    value={item.icon}
                    className='w-24px h-24px rounded-4px mr-8px'
                    fillSize={14}
                    size={24}
                    color={item.color}
                  />
                )}
                {item.label}
              </div>
              <div className='w-24px h-24px rounded-4px flex-center mr-15px hover:bg-#3e456320 iconbox'>
                <IconFont
                  name='super-link'
                  onClick={e => showPageModal(item, e)}
                  className='c-#62699999 text-16px invisible'
                />
              </div>
            </div>
          </AntdSelect.Option>
        ))}
      </Select>
      <div className='flex mt-14px text-14px/14px text-#8D8D99'>
        <IconFont name='xiaoshizhi' />
        <span className='ml-5px font-300'>
          确认后，该
          {selectType === CoverType.AGENT ? CoverType.AGENT : CoverType.FLOW}
          的规则配置（关联资源除外）将会被覆盖
        </span>
      </div>
      <div className='flex justify-between mt-14px'>
        <div></div>
        <Button
          type='primary'
          className='mt-12px'
          disabled={!selectId}
          onClick={() => {
            onConfirm(appId, selectId)
          }}
        >
          确定覆盖
        </Button>
      </div>
    </ContainSelect>
  )
}

export function useCoverModal(options: CoverModalProps) {
  const CoverModal = useCallback(
    (props: any) => {
      return (
        <Modal {...props} footer={null} title={options?.coverText}>
          <CoverModalContent
            onConfirm={options.onConfirm}
            appId={options.appId}
            dataList={options.dataList}
            selectType={options.selectType}
          />
        </Modal>
      )
    },
    [options.onConfirm, options.appId],
  )
  return useModal(CoverModal)
}
