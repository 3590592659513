import type { TagProps } from 'antd/es/tag'
import type { FC } from 'react'
import { Tooltip, Tag } from 'antd'
import classNames from 'classnames'
import { IconFont } from '@/components'

interface DocumentTagProps extends TagProps {
  selected?: boolean
  disabled?: boolean
  tip?: string
  maxTagWidth?: number | string
}

export const DocumentTag: FC<DocumentTagProps> = props => {
  const { selected, tip, disabled, maxTagWidth = 71, ...remainingProps } = props

  const className = classNames(
    'rounded-4px shrink-0 m-0 b-line cursor-pointer flex-center py-5',
    {
      'bg-bg_3 bg-op-4 hover:bg-op-12 c-font c-op-80': !selected && !disabled,
      'bg-primary c-white b-none hover:bg-op-80': selected,
      'bg-bg_3 bg-op-4 c-font_1 c-op-60': disabled,
    },
    remainingProps.className,
  )

  const closeIcon = (
    <IconFont name='guanbi' className='c-font_1 text-12px c-op-60' />
  )

  if (tip) {
    return (
      <Tooltip title={tip}>
        <Tag {...remainingProps} className={className} closeIcon={closeIcon}>
          <span
            className='truncate inline-block'
            style={{ maxWidth: maxTagWidth }}
          >
            {props.children}
          </span>
        </Tag>
      </Tooltip>
    )
  }
  return (
    <Tag {...remainingProps} className={className} closeIcon={closeIcon}>
      <span className='truncate inline-block' style={{ maxWidth: maxTagWidth }}>
        {props.children}
      </span>
    </Tag>
  )
}
