import { useSearchParams } from 'react-router-dom'

interface QueryParams {
  [key: string]: string | undefined
}

function useQueryParams<T extends QueryParams>() {
  const [searchParams] = useSearchParams()

  const params: T = Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key as keyof T] = value as T[keyof T]
      return acc
    },
    {} as T,
  )

  return params
}

export default useQueryParams
