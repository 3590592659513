import { memo, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import type { StatisticsFlowType } from '..'
import MonitorDrawer from '@/components/MonitorDrawer'
import { getWorkspaceOverTimeDetail, StaticTimeTypes } from '@/apis/monitor'
import { useStabilityDetailTable } from '../../hooks/useStabilityDetailTable'
import { OverTimeDetailTable } from './OverTimeDetailTable'
import type { StabilityTimeParams } from './StabilityDetails'

const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

interface ErrorOverTimeDetailModalProps {
  flowType: StatisticsFlowType
  onClose: () => void
  runType: string[]
  timeParams?: StabilityTimeParams
}

function InternalErrorOverTimeDetailModal({
  onClose,
  runType,
  flowType,
  timeParams,
}: ErrorOverTimeDetailModalProps) {
  const [filterParams, _setFilterParams] = useState<StabilityTimeParams>(
    () => timeParams!,
  )

  const lastTimeRange = useRef({
    start_date: timeParams?.start_date,
    end_date: timeParams?.end_date,
  })

  const baseParams = useMemo(() => {
    return {
      runType,
      page: 1,
      page_size: 10,
      statistic_time: filterParams?.type || StaticTimeTypes.WEEKLY,
      start_date:
        filterParams?.type === StaticTimeTypes.CUSTOM
          ? filterParams?.start_date
          : undefined,
      end_date:
        filterParams?.type === StaticTimeTypes.CUSTOM
          ? filterParams?.end_date
          : undefined,
      flow_type: flowType,
    }
  }, [flowType, runType, filterParams])

  const { fetchList, ...rest } = useStabilityDetailTable({
    api: getWorkspaceOverTimeDetail,
    defaultParams: baseParams,
    lastTimeRange: lastTimeRange.current,
    transformDateString: date => {
      return dayjs(date).format(TIME_FORMAT)
    },
  })

  useEffect(() => {
    fetchList(baseParams)
  }, [])

  const totalColumn = [
    {
      title: flowType,
      dataIndex: 'flow_name',
    },
    {
      title: '异常记录数量（条）',
      dataIndex: 'count',
      sorter: true,
    },
  ]

  console.log('totalColumn', timeParams)

  return (
    <>
      <MonitorDrawer
        columns={totalColumn}
        onClose={onClose}
        rowKey={'flow_id'}
        filter={{
          defaultValue: timeParams?.type || StaticTimeTypes.WEEKLY,
          defaultSubValue: [
            dayjs(timeParams?.start_date),
            dayjs(timeParams?.end_date),
          ],
        }}
        {...rest}
        onChange={params => {
          const _filter: StabilityTimeParams = {
            type: params.filterValue as StaticTimeTypes,
            start_date: undefined,
            end_date: undefined,
          }
          if (
            params?.subFilterValue?.length === 2 &&
            params?.filterValue === StaticTimeTypes.CUSTOM
          ) {
            _filter.start_date = dayjs(params.subFilterValue[0])
              .startOf('d')
              .format(TIME_FORMAT)
            _filter.end_date = dayjs(params.subFilterValue[1])
              .endOf('d')
              .format(TIME_FORMAT)
            lastTimeRange.current = {
              start_date: _filter.start_date,
              end_date: _filter.end_date,
            }
          }
          _setFilterParams(_filter)
        }}
        title={
          flowType === 'AGENT'
            ? '对话首字响应时长异常记录'
            : '运行响应时长异常记录'
        }
        expandable={{
          expandedRowRender: record => (
            <OverTimeDetailTable
              record={record}
              flowType={flowType}
              filterParams={filterParams}
              runType={runType}
            />
          ),
        }}
      />
    </>
  )
}

export const ErrorOverTimeDetailModal = memo(InternalErrorOverTimeDetailModal)
