import { memo, useState } from 'react'
import { Dropdown, message, Switch } from 'antd'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { IconFont } from '@/components'
import { DeleteConfirm } from '@/components/DeleteConfirm'
import { deleteTask, enableTask, executeTask } from '@/apis/task'
import type { TaskListResult } from '@/apis/task'
import { PanelMenu, PresentPanelMenu, TaskStatusMap } from './const'

interface TaskListItemProps {
  task: TaskListResult
  readonly?: boolean
  getConversationId: () => string
  onEdit?: (task?: TaskListResult) => void
  onSelect?: (task: TaskListResult) => void
  onDelete?: (task: TaskListResult) => void
  onUpdateList?: (refresh?: boolean, task?: TaskListResult) => void
  className?: string
}

export const TaskListItem = memo((props: TaskListItemProps) => {
  const {
    task,
    readonly,
    getConversationId,
    onEdit,
    onSelect,
    onDelete,
    onUpdateList,

    className,
  } = props

  const [showConfirm, setShowConfirm] = useState(false)

  const handleOpenChange = useMemoizedFn((open: boolean) => {
    if (!open) {
      setShowConfirm(false)
    }
  })

  const handleEdit = useMemoizedFn(async (info: any) => {
    if (info.key === 'detail') {
      onEdit?.(task)
    }

    if (info.key === 'edit') {
      onEdit?.(task)
    }

    if (info.key === 'remove') {
      setShowConfirm(true)
    }
  })

  const handleDetail = useMemoizedFn(() => {
    onSelect?.(task)
  })

  const handleEnable = useMemoizedFn(async (enable: boolean) => {
    onUpdateList?.(false, { ...task, is_enable: enable })
    await enableTask(task, getConversationId(), enable)
    window.dispatchEvent(new Event('taskListUpdate'))
  })

  const handleDelete = useMemoizedFn(async () => {
    await deleteTask(task.task_id, getConversationId())
    message.success('删除成功')
    onUpdateList?.(true)
    onDelete?.(task)
    window.dispatchEvent(new Event('taskListUpdate'))
  })

  const handleExecute = useMemoizedFn(async () => {
    await executeTask(task.id)
    message.success('任务触发成功')
  })

  return (
    <div
      className={classNames(
        'border-1px rounded-8px p-16px mb-10px cursor-pointer',
        className,
      )}
      onClick={handleDetail}
    >
      <div className='flex flex-center mb-8px h-24px'>
        <div className='mr-auto flex flex-center overflow-hidden'>
          <span className='mr-auto text-#17171D text-16px font-500 flex-auto truncate'>
            {task.task_name}
          </span>

          {task.is_preset && (
            <span className='ml-8px bg-#E9E7FF rounded-4px leading-18px px-4px text-#7164FF text-nowrap'>
              预设
            </span>
          )}
        </div>

        {!readonly && task.is_enable && (
          <span onClick={e => e.stopPropagation()}>
            <button
              className='chat-icon-btn w-24px h-24px ml-8px'
              onClick={handleExecute}
            >
              <IconFont
                className='text-16px text-primary'
                name='yunxingcishu'
              />
            </button>
          </span>
        )}

        {!readonly && (
          <span className='ml-8px' onClick={e => e.stopPropagation()}>
            <Switch
              size='small'
              checked={task.is_enable}
              onChange={handleEnable}
            />
          </span>
        )}

        {!readonly && task.is_preset && (
          <div className='ml-8px' onClick={e => e.stopPropagation()}>
            <Dropdown
              overlayClassName='[&_.ant-dropdown-menu]:p-4px!'
              menu={{
                items: PresentPanelMenu,
                onClick: handleEdit,
              }}
              placement='bottomRight'
            >
              <button
                className='chat-icon-btn w-24px h-24px text-[rgba(98,105,153,0.6)] [&.ant-dropdown-open]:bg-bg_3 [&.ant-dropdown-open]:bg-op-6'
                onClick={e => e.stopPropagation()}
              >
                <IconFont name='gengduo' />
              </button>
            </Dropdown>
          </div>
        )}

        {!readonly && !task.is_preset && (
          <div className='ml-8px' onClick={e => e.stopPropagation()}>
            <DeleteConfirm
              rootClassName='w-360px [&_.ant-popover-inner]:p-20px!'
              placement='bottomRight'
              align={{ offset: [8, 8] }}
              message='确认删除任务？删除后不再执行此任务'
              open={showConfirm}
              onOpenChange={handleOpenChange}
              onDelete={handleDelete}
            >
              <Dropdown
                overlayClassName='[&_.ant-dropdown-menu]:p-4px!'
                menu={{
                  items: PanelMenu,
                  onClick: handleEdit,
                }}
                placement='bottomRight'
              >
                <button
                  className='chat-icon-btn w-24px h-24px text-[rgba(98,105,153,0.6)] [&.ant-dropdown-open]:bg-bg_3 [&.ant-dropdown-open]:bg-op-6'
                  onClick={e => e.stopPropagation()}
                >
                  <IconFont name='gengduo' />
                </button>
              </Dropdown>
            </DeleteConfirm>
          </div>
        )}
      </div>

      <div className='flex gap-8px'>
        {task.remark && (
          <span className='bg-#E1EFFF rounded-4px leading-18px px-4px text-#5689FF text-nowrap'>
            {task.remark}
          </span>
        )}

        <span className='text-nowrap truncate'>
          {task.last_task_time && (
            <span className='mr-8px leading-18px text-#8D8D99'>
              {dayjs(
                typeof task.last_task_time === 'string'
                  ? task.last_task_time
                  : task.last_task_time * 1000,
              ).format('MM-DD HH:mm')}
            </span>
          )}

          {task.last_task_status && (
            <span className='leading-18px text-#8D8D99'>
              {TaskStatusMap[task.last_task_status].text}
            </span>
          )}
        </span>
      </div>
    </div>
  )
})
