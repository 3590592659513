import type { NodeSpec } from 'prosemirror-model'

export const selectNodeSpec: NodeSpec = {
  name: 'pe-input',
  group: 'inline',
  content: 'text*',
  inline: true,
  atom: false,
  selectable: false,
  attrs: {
    key: { default: '' },
    placeholder: { default: '' },
  },
  toDOM: node => {
    return [
      'span',
      {
        class: 'pe-select',
        'data-key': node.attrs.key,
      },
      [
        'text',
        {
          'data-zero': true,
          contenteditable: 'false',
        },
        '\u200B',
      ],
      [
        'span',
        {
          class: 'pe-select-wrap',
        },
        [
          'span',
          {
            class: 'pe-select-value',
          },
          0,
        ],
        [
          'span',
          {
            class: 'pe-select-placeholder',
            contenteditable: 'false',
          },
          node.attrs.placeholder,
        ],
        [
          'span',
          {
            class: 'pe-select-arrow',
            contenteditable: 'false',
          },
          ' ',
        ],
        [
          'text',
          {
            'data-zero': true,
            contenteditable: 'false',
          },
          '\u200B',
        ],
      ],
    ]
  },
}
