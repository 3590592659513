import type { SchemaFormProps } from '@bty/ui-components'
import { SchemaForm } from '@bty/ui-components'
import { defaultNodeFormItemComponentsMap } from '@/components/nodeForm/fields.tsx'

export interface INodeForm extends Omit<SchemaFormProps, 'fieldComponentsMap'> {
  showEmpty?: boolean
  fieldComponentsMap?: SchemaFormProps['fieldComponentsMap']
}

export function NodeForm(props: INodeForm) {
  const {
    showEmpty = true,
    schema,
    disabled,
    fieldComponentsMap,
    ...resetProps
  } = props

  return (
    <>
      <SchemaForm
        disabled={disabled}
        schema={schema}
        fieldComponentsMap={
          fieldComponentsMap ?? defaultNodeFormItemComponentsMap
        }
        {...resetProps}
      ></SchemaForm>
      {!schema.length && showEmpty && (
        <div className='w-full h-full flex-center left-0 top-0'>
          <span className='c-bg_3 c-op-40'>该应用无输入字段，直接运行即可</span>
        </div>
      )}
    </>
  )
}

NodeForm.useForm = SchemaForm.useForm
