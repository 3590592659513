import { Popover } from 'antd'
import { useRef } from 'react'
import { CodeEditor } from '@/features/editor'
import type { InnerNodeProps } from '../base'
import { CodeBlock } from '@/components/CodeBlock'

// eslint-disable-next-line quotes
const EXAMPLE_TIPS = `knowledge == '1' && knowledge == '2'`
export function LoopConditionNode(props: {
  value?: string
  onChange?: (val: string) => void
  variables?: InnerNodeProps['variables']
  nodeElement?: InnerNodeProps['nodeElement']
}) {
  const { value, onChange, variables, nodeElement } = props
  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={wrapperRef}>
      <CodeEditor
        className='ace-gray w-100% min-h-100px'
        width='100%'
        mode='javascript'
        variables={variables}
        variableTipsContainer={nodeElement}
        placeholder='请输入javascript表达式'
        value={value}
        onChange={onChange}
        setOptions={{
          maxLines: 8,
          minLines: 4,
          showGutter: false,
          printMargin: false,
        }}
      />
      <Popover
        trigger={['click']}
        overlayInnerStyle={{
          minWidth: 320,
          padding: '12px 16px',
        }}
        arrow={false}
        placement='bottom'
        getPopupContainer={() => wrapperRef.current!}
        zIndex={6000}
        content={
          <div>
            <div className='mb-12px line-height-16px text-12px font-500 text-#17171d'>
              循环终止条件javascript格式示例
            </div>
            <CodeBlock
              title='示例'
              lang='json'
              str={EXAMPLE_TIPS}
              className='bg-white b-1 b-#e1e1e5 b-op-60'
              codeFont='12px'
              titleFont='12px'
            />
          </div>
        }
      >
        <span
          ref={wrapperRef}
          className='absolute bottom-10 right-10 text-12px text-primary cursor-pointer'
        >
          示例
        </span>
      </Popover>
    </div>
  )
}
