import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Spin, Tooltip } from 'antd'
import { useDebounceEffect, useRequest } from 'ahooks'
import { ResizableBox } from 'react-resizable'
import { isEmpty, isNil } from 'lodash-es'
import classNames from 'classnames'
import type { ChatPCInstance } from '@bty/chat-renderer-pc'
import { ChatbotRunType } from '@bty/chat-types'
import { type InnerNodePanelProps } from '@/features/nodes/base'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { IconFont } from '@/components'
import { compilerCode, filterDebugVariableList } from '../utils'
import { useEnhancedCodeVariableDeps } from '../../hooks/useVariableDeps'
import { fetchPythonCodeVariablesApi } from '@/apis/nodepanel'
import { useFlowDraftStore, useUserStore, useWorkspaceStore } from '@/store'
import { tokenStorage } from '@/utils/storage.ts'
import CopilotIcon from '@/assets/agent/copilot-icon.svg?url'
import { AgentChat } from '@/features/chat/AgentChat.tsx'
import { BlurBG } from '@/pages/agent/components/BlurBG.tsx'
import { CodeEditorAssistantIdMap } from '@/constants/copilot.ts'
import { useTrack } from '@/features/track/Track.tsx'
import { CodeResultPanel } from './Components/CodeResultPanel'
import { CodeExample } from './Components/CodeExample'
import { PackageManager } from './Components/PackageManager'
import { CodeOutput } from './Components/CodeOutput'
import {
  CodeNodePanelWrapper,
  Handler,
  Overlay,
} from './Components/StyledComponent'
import { type debuggerVariableType, DebugPanel } from './Components/DebugPanel'
import useWindowDimensions from './hooks/useWindowDimensions'
import { DocumentPanel } from './Components/DocumentPanel'
import { CodeEditorIframe } from './Components/CodeEditorIframe'

const DEFAULT_BAR_HEIGHT = 280
const MIN_BAR_HEIGHT = 48
const DEFAULT_LEFT_BAR_WIDTH = 288
const DEFAULT_RIGHT_BAR_WIDTH = 360

export function CodeNodePanel(props: InnerNodePanelProps<any>) {
  const { activatedNodeId, onSaveChange, data } = props
  const { flowId, nodes, edges } = useFlowDraftStore()
  const token = tokenStorage.get()
  const user = useUserStore(state => state.user)
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const { windowWidth, windowHeight } = useWindowDimensions()
  const { doTrack } = useTrack()

  const [isDragging, setIsDragging] = useState<string | boolean>(false)
  const [leftBarHeight, setLeftBarHeight] = useState<number>(DEFAULT_BAR_HEIGHT)
  const [leftBarWidth, setLeftBarWidth] = useState<number>(
    DEFAULT_LEFT_BAR_WIDTH,
  )
  const [rightBarVisible, setRightBarVisible] = useState<boolean>(false)
  const [rightBarWidth, setRightBarWidth] = useState<number>(0)
  const [rightBarHeight, setRightBarHeight] = useState<number>(
    (windowHeight - 96) / 2,
  )
  const [terminalHeight, setTerminalHeight] = useState<number>(0)
  const [terminalVisible, setTerminalVisible] = useState<boolean>(false)
  const [documentVisisble, setDocumentVisible] = useState<boolean>(false)
  const [copilotVisible, setCopilotVisible] = useState(false)
  const [debugPanelVisible, setDebugPanelVisible] = useState<boolean>(false)

  const [loading] = useState<boolean>(false)
  const [debuggerVariableList, setDebuggerVariableList] = useState<
    debuggerVariableType[]
  >([])

  const agentChatRef = useRef<ChatPCInstance>(null)

  const generalLSPApi = useCallback(
    (language: 'javascript' | 'python') => {
      const apiUrlParse = new URL(import.meta.env.VITE_AI_API_BASE_URL)
      const languagePathMap = {
        javascript: 'lsp-server-js/api/v1/javascript',
        python: 'lsp-server-python/api/v1/python',
      }
      return `wss://${apiUrlParse.hostname}/${languagePathMap[language]}?Authorization=${token}&workspaceId=${workspaceId}`
    },
    [token, workspaceId],
  )

  // 记录埋点的额外信息
  const trackMetaData = {
    flowId,
    userId: user?.userId ?? -1,
    codeName: data.name,
    language: data.inputs.language,
  }

  const {
    variablesForCodeNode,
    variablesForCodeNodeFormConfig,
    variablesForCodeNodeKeys,
  } = useEnhancedCodeVariableDeps(activatedNodeId!, nodes, edges)

  const { runAsync: fetchPythonCodeVariables } = useRequest(
    fetchPythonCodeVariablesApi,
    {
      manual: true,
      onSuccess: data => {
        const varList = filterDebugVariableList(data, variablesForCodeNodeKeys)
        const debuggerVariableListFormConfig =
          variablesForCodeNodeFormConfig.filter(i =>
            varList.includes(i.variableName),
          )
        setDebuggerVariableList(debuggerVariableListFormConfig)
      },
    },
  )

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    debuggerVariableList.forEach(item => {
      if (isNil(debugVariables[item.variableName])) {
        val[item.variableName] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const toggleTerminal = useCallback(
    (action?: 'open' | 'close') => {
      if (action === 'open') {
        setTerminalVisible(true)
        setTerminalHeight(DEFAULT_BAR_HEIGHT)
      } else if (action === 'close') {
        setTerminalVisible(false)
        setTerminalHeight(0)
      } else {
        setTerminalVisible(!terminalVisible)
        setTerminalHeight(terminalVisible ? 0 : DEFAULT_BAR_HEIGHT)
      }
    },
    [terminalVisible],
  )

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    // await formRef.current?.validateFields()
    if (!terminalVisible || terminalHeight === MIN_BAR_HEIGHT) {
      toggleTerminal('open')
    }
    run(data)
  }

  const toggleRightBar = (action: 'open' | 'close') => {
    if (action === 'open') {
      setRightBarVisible(true)
      setRightBarWidth(DEFAULT_RIGHT_BAR_WIDTH)
    } else if (action === 'close') {
      setRightBarVisible(false)
      setRightBarWidth(0)
    }
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <>
          <div className='flex flex-items-center '>
            <Tooltip title='助手'>
              <div
                onClick={e => {
                  e.stopPropagation()
                  toggleRightBar('open')
                  setCopilotVisible(true)
                  setRightBarWidth(580)
                  setDebugPanelVisible(false)
                  setDocumentVisible(false)
                  agentChatRef.current?.chat?.list.scrollToBottom()
                }}
                className='bg-#fff b-1px m-l-8px w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-[rgba(98,105,109,0.08)] hover:bg-op-12'
              >
                <img
                  className='h-16px'
                  src={CopilotIcon}
                  alt='Code Editor Copilot'
                />
              </div>
            </Tooltip>
            <Tooltip title='帮助文档'>
              <div
                onClick={e => {
                  e.stopPropagation()
                  toggleRightBar('open')
                  setDocumentVisible(true)
                  setRightBarWidth(580)
                  if (debugPanelVisible) {
                    setDebugPanelVisible(false)
                  }
                  if (copilotVisible) {
                    setCopilotVisible(false)
                  }
                  doTrack('flow_code_doc_open')
                }}
                className='bg-#fff b-1px m-l-8px w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-[rgba(98,105,109,0.08)] hover:bg-op-12'
              >
                <IconFont name='bangchuwendang' className='text-16px' />
              </div>
            </Tooltip>
            <Tooltip title='控制台'>
              <div
                onClick={e => {
                  e.stopPropagation()
                  toggleTerminal()
                }}
                className='bg-#fff b-1px m-l-8px w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-[rgba(98,105,109,0.08)] hover:bg-op-12'
              >
                <IconFont name='qiangzhisi' className='text-16px' />
              </div>
            </Tooltip>
            <div
              onClick={e => {
                e.stopPropagation()
                toggleRightBar('open')
                setDebugPanelVisible(true)
                if (documentVisisble) {
                  setDocumentVisible(false)
                }
                if (copilotVisible) {
                  setCopilotVisible(false)
                }
              }}
              className='bg-#fff px-12 py-10 text-#7B61FF b-1px m-l-8px h-32px box-border b-rd-6px flex flex-items-center cursor-pointer hover:bg-[rgba(98,105,109,0.08)] hover:bg-op-12'
            >
              <IconFont name='yunxing' className='text-12px mr-6' />
              <span className='text-12px font-600'>运行</span>
            </div>
          </div>
        </>
      ),
      () => Promise.resolve(),
    )
  }, [toggleTerminal, documentVisisble, debugPanelVisible])

  const onDynamicValuesChange = (obj: any) => {
    const [entry] = Object.entries(obj)
    const [key, value] = entry
    const usedVariable: any = {}
    debuggerVariableList.forEach(item => {
      usedVariable[item.variableName] = debugVariables[item.variableName]
    })
    setVariables({ ...usedVariable, [key]: value }, false)
  }

  useDebounceEffect(
    () => {
      if (props.data.inputs.language === 'javascript') {
        const varList = compilerCode(
          props.data.inputs.code,
          variablesForCodeNodeKeys,
        )
        const debuggerVariableListFormConfig =
          variablesForCodeNodeFormConfig.filter(i =>
            varList.includes(i.variableName),
          )
        setDebuggerVariableList(debuggerVariableListFormConfig)
      } else if (props.data.inputs.language === 'python') {
        setTimeout(() => {
          fetchPythonCodeVariables({
            flow_id: flowId,
            node_id: activatedNodeId!,
          })
        }, 500)
      }
    },
    [props.data.inputs.code, props.data.inputs.language],
    {
      wait: 300,
    },
  )

  return (
    <Spin spinning={loading} delay={1000}>
      {isDragging && <Overlay isDragging={isDragging} />}
      <CodeNodePanelWrapper
        className='code_node_panel_don_target'
        id='code_node_panel_don_target'
      >
        <ResizableBox
          width={DEFAULT_LEFT_BAR_WIDTH}
          height={Number.POSITIVE_INFINITY}
          axis='x'
          className='will-change-width'
          minConstraints={[DEFAULT_LEFT_BAR_WIDTH, Number.POSITIVE_INFINITY]}
          maxConstraints={[
            Math.max(windowWidth / 4, DEFAULT_LEFT_BAR_WIDTH),
            Number.POSITIVE_INFINITY,
          ]}
          resizeHandles={['e']}
          onResize={(_, { size }) => {
            setLeftBarWidth(size.width)
          }}
          onResizeStart={() => {
            setIsDragging('leftBar')
          }}
          onResizeStop={() => {
            setIsDragging(false)
          }}
          style={{ background: 'rgba(250, 250, 252)' }}
          handle={<Handler axis='e' showLine={isDragging === 'leftBar'} />}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <div
              className='relative'
              style={{ borderRight: '1px solid rgba(225, 225, 229, 0.6)' }}
            >
              <div
                style={{
                  height: `calc(100vh - ${leftBarHeight}px - 48px)`,
                }}
                className='will-change-height'
              >
                <CodeExample
                  flowId={flowId}
                  trackMetaData={trackMetaData}
                  language={props?.data?.inputs?.language}
                  leftBarWidth={leftBarWidth}
                />
              </div>
              <ResizableBox
                height={leftBarHeight}
                width={Number.POSITIVE_INFINITY}
                axis='y'
                style={{ background: 'rgba(250, 250, 252)' }}
                minConstraints={[Number.POSITIVE_INFINITY, MIN_BAR_HEIGHT]}
                maxConstraints={[
                  Number.POSITIVE_INFINITY,
                  Math.max(windowHeight - MIN_BAR_HEIGHT - 48, MIN_BAR_HEIGHT),
                ]}
                handle={
                  <Handler showLine={isDragging === 'leftBarHeight'} axis='y' />
                }
                onResize={(_, { size }) => {
                  setLeftBarHeight(size.height)
                }}
                onResizeStart={() => {
                  setIsDragging('leftBarHeight')
                }}
                onResizeStop={() => {
                  setIsDragging(false)
                }}
                resizeHandles={['n']}
                className='will-change-height z-10'
              >
                <PackageManager language={props?.data?.inputs?.language} />
              </ResizableBox>
            </div>
          </div>
        </ResizableBox>

        {/* 中间栏 */}
        <div
          style={{
            width: `${windowWidth - leftBarWidth - rightBarWidth}px`,
          }}
          className='relative will-change-width'
        >
          <CodeEditorIframe
            className='w-full h-full pt-24px'
            language={data.inputs.language}
            defaultValue={data.inputs.code}
            lspApis={{
              python: generalLSPApi('python'),
              javascript: generalLSPApi('javascript'),
            }}
            tempPath={`${flowId}__${data.name}__${user?.userId}`}
            onChange={code => {
              const newValue = {
                ...data,
                inputs: {
                  ...data.inputs,
                  code,
                },
              }
              onSaveChange(newValue)
            }}
            globalVariables={variablesForCodeNode}
          />
          {terminalVisible && (
            <div className='absolute bottom-0 right-0px w-full z-12'>
              <ResizableBox
                height={terminalHeight}
                width={Number.POSITIVE_INFINITY}
                axis='y'
                style={{ background: 'rgba(250, 250, 252)' }}
                minConstraints={[Number.POSITIVE_INFINITY, MIN_BAR_HEIGHT]}
                maxConstraints={[
                  Number.POSITIVE_INFINITY,
                  Math.max(MIN_BAR_HEIGHT, windowHeight * 0.75),
                ]}
                handle={
                  <Handler
                    showLine={isDragging === 'terminalHeight'}
                    axis='y'
                  />
                }
                onResizeStart={() => {
                  setIsDragging('terminalHeight')
                }}
                onResizeStop={() => {
                  setIsDragging(false)
                }}
                onResize={(_, { size }) => {
                  setTerminalHeight(size.height)
                }}
                resizeHandles={['n']}
                className='will-change-height'
              >
                <CodeResultPanel
                  leftPanelWidth={leftBarWidth}
                  rightPanelWidth={rightBarWidth}
                  toggleTerminal={toggleTerminal}
                  nodeName={data.name}
                />
              </ResizableBox>
            </div>
          )}
        </div>
        {/* 右侧栏 */}
        <ResizableBox
          width={rightBarWidth}
          height={Number.POSITIVE_INFINITY}
          axis='x'
          minConstraints={[DEFAULT_RIGHT_BAR_WIDTH, Number.POSITIVE_INFINITY]}
          maxConstraints={[
            documentVisisble
              ? Math.max(windowWidth / 3, 580)
              : Math.max(windowWidth / 3, DEFAULT_RIGHT_BAR_WIDTH),
            Number.POSITIVE_INFINITY,
          ]}
          resizeHandles={['w']}
          onResize={(_, { size }) => {
            setRightBarWidth(size.width)
          }}
          onResizeStart={() => {
            setIsDragging('rightBar')
          }}
          onResizeStop={() => {
            setIsDragging(false)
          }}
          style={{
            background: 'rgba(250, 250, 252)',
            ...(rightBarVisible ? {} : { display: 'none' }),
          }}
          className='will-change-width'
          handle={<Handler axis='x' showLine={isDragging === 'rightBar'} />}
        >
          <div style={debugPanelVisible ? {} : { display: 'none' }}>
            <DebugPanel
              trackMetaData={trackMetaData}
              rightBarHeight={rightBarHeight}
              toggleRightBar={toggleRightBar}
              debuggerVariableList={debuggerVariableList}
              handleValuesChansge={onDynamicValuesChange}
              runBySingleStep={runBySingleStep}
              btnLoading={btnLoading}
              debugVariables={debugVariables}
            />
            <ResizableBox
              height={rightBarHeight}
              width={Number.POSITIVE_INFINITY}
              axis='y'
              minConstraints={[Number.POSITIVE_INFINITY, MIN_BAR_HEIGHT]}
              maxConstraints={[
                Number.POSITIVE_INFINITY,
                windowHeight - MIN_BAR_HEIGHT - 48,
              ]}
              onResizeStart={() => {
                setIsDragging('rightBarHeight')
              }}
              style={{ background: 'rgba(250, 250, 252)' }}
              onResizeStop={() => {
                setIsDragging(false)
              }}
              handle={
                <Handler axis='y' showLine={isDragging === 'rightBarHeight'} />
              }
              onResize={(_, { size }) => {
                setRightBarHeight(size.height)
              }}
              className='will-change-height'
              resizeHandles={['n']}
            >
              <CodeOutput />
            </ResizableBox>
          </div>
          <div
            style={documentVisisble ? {} : { display: 'none' }}
            className={`h-${windowHeight - 48}px`}
          >
            <DocumentPanel toggleRightBar={toggleRightBar} />
          </div>
          <div
            className={classNames('relative', {
              hidden: !copilotVisible,
            })}
            style={{ height: windowHeight - 48 }}
          >
            <AgentChat
              ref={agentChatRef}
              title={
                <div className='flex-center-between h-48px px-16 bg-[rgba(247,247,250,0.6)] border-bottom after:border-[rgba(225,225,229,0.6)] after:w-auto after:left-0 after:right-0'>
                  <h1 className='font-medium text-16px text-font'>
                    Code编写助手
                  </h1>
                  <div
                    onClick={() => toggleRightBar('close')}
                    className='w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
                  >
                    <IconFont name='guanbi' className='text-16px' />
                  </div>
                </div>
              }
              autoCreateConversation
              actionType='CONVERSATION'
              runType={ChatbotRunType.CHATBOT}
              robotId={
                CodeEditorAssistantIdMap[
                  data.inputs.language as 'python' | 'javascript'
                ]
              }
              actions={['stop', 'clear']}
              inputTips={['enter', 'shift + enter']}
              background='white'
              uploadFileConfig={{
                fileAccepts: [],
                allowFileTypeNameList: [],
                acceptsAllFileTypes: false,
              }}
            />
            <div className='bottom-30 absolute left-0 w-full'>
              <BlurBG position='bottom' style={{ height: 40 }} />
            </div>
          </div>
        </ResizableBox>
      </CodeNodePanelWrapper>
    </Spin>
  )
}
