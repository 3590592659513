import { Form } from 'antd'
import CryptoJS from 'crypto-js'
import React from 'react'
import type { AuthAppWeChat } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'
import type { AgentGoPublishConfProps } from '../components/AgentGoPublishConf'
import { AgentGoPublishConf } from '../components/AgentGoPublishConf'
import { Input } from '@/components'
import { CopyBox } from '../components/CopyBox'
import { useAgentEdit } from '../provider/AgentEditProvider'
import {
  createApp,
  enableApp,
  queryWeChatAppConfig,
  updateApp,
} from '@/apis/agentgo'
import { useQueryAgentGoApp } from '@/features/agent/hooks/useQueryAgentGoApp.ts'
import { LinkText } from '@/features/agent/components/LinkText.tsx'
import renderLabel from '@/features/agent/components/renderLabel.tsx'
import { AgentGoWeChatKFShareQRCode } from '@/features/agent/AgentGo/shareQRCode/AgentGoWeChatKFShareQRCode.tsx'
import { AgentGoFormItem } from '@/features/agent/components/AgentGoFormItem.tsx'
import { WECHAT_DOCS } from './common'

const STEP_ITEMS = [
  { title: '填写企业 ID', disabled: true },
  { title: '配置回调信息', disabled: true },
  { title: '填写 Secret', disabled: true },
]

interface AgentGoWeChatProps {
  mode?: string
  modeIcon?: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

function InternalAgentGoWeChat({
  mode,
  modeIcon,
  onBindSuccess,
  onUnBindSuccess,
}: AgentGoWeChatProps) {
  const [form] = Form.useForm()

  const ctx = useAgentEdit()

  const agentId = ctx.applicationInfo?.flowId

  const { runQueryApp, app, loading, setApp } =
    useQueryAgentGoApp<AuthAppWeChat>({
      agentId,
      source: PUBLISH_MODE.wechat_kf,
      onQuerySuccess: data => {
        const { auth_app_name, auth_app_config } = data
        form.setFieldsValue({
          auth_app_name,
          ...auth_app_config,
        })
      },
    })

  const handleStepChange: NonNullable<
    AgentGoPublishConfProps['onStepChange']
  > = async step => {
    if (step !== 1) {
      return
    }
    if (app.auth_app_id) {
      const { auth_app_name, ...values } = form.getFieldsValue()
      const data = await updateApp({
        auth_app_id: app.auth_app_id,
        auth_app_name,
        auth_app_config: values,
      })
      setApp(data as any as AuthAppWeChat)
      return
    }
    // 微信需要第二步完成之后创建 app，点击完成的时候其实是修改应用了
    if (agentId) {
      const { auth_app_name, ...values } = form.getFieldsValue()
      const data = await createApp({
        agent_id: agentId,
        auth_app_name,
        auth_app_source: PUBLISH_MODE.wechat_kf,
        auth_app_config: Object.assign(values, { Secret: '' }),
      })
      setApp(data as AuthAppWeChat)
    }
  }

  const handleComplete: NonNullable<
    AgentGoPublishConfProps['onComplete']
  > = appId => {
    onBindSuccess?.()
    if (appId && agentId) {
      runQueryApp(agentId)
    }
  }

  const handleCorpIdBlur = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value
    if (!value) {
      return
    }
    const config = await queryWeChatAppConfig(value)
    form.setFieldsValue(
      config
        ? { ...config }
        : {
            Token: CryptoJS.lib.WordArray.random(16).toString(),
            EncodingAESKey: CryptoJS.lib.WordArray.random(22)
              .toString()
              .slice(0, 43),
          },
    )
  }

  const handleValuesChange: NonNullable<
    AgentGoPublishConfProps['onValuesChange']
  > = changedValues => {
    if ('is_enable' in changedValues) {
      setApp({ ...app, is_enable: changedValues.is_enable })
      enableApp(app.auth_app_id!, changedValues.is_enable)
    }
  }

  const handleUnBindSuccess = async () => {
    setApp({})
    onUnBindSuccess?.()
  }

  const toUseAction = (
    <AgentGoWeChatKFShareQRCode
      authAppName={app.auth_app_name ?? ''}
      authAppKFId={app.auth_app_config?.KfIds[0] ?? ''}
      avatar={ctx.applicationInfo?.icon ?? ''}
    />
  )

  return loading ? null : (
    <AgentGoPublishConf
      form={form}
      mode={mode}
      app={app}
      steps={STEP_ITEMS}
      guides={WECHAT_DOCS}
      serviceAvatar={modeIcon}
      onStepChange={handleStepChange}
      onComplete={handleComplete}
      onUnBindSuccess={handleUnBindSuccess}
      onValuesChange={handleValuesChange}
      connectTestCardActionSlot={toUseAction}
    >
      {step => (
        <>
          {step === 0 ? (
            <>
              <div className='text-16px font-medium text-font'>
                登录{' '}
                <LinkText href='https://kf.weixin.qq.com'>
                  微信客服后台
                </LinkText>{' '}
                获取信息
              </div>
              <p className='mt-8px mb-24px text-12px text-font_2'>
                注意！需要使用<span className='text-font'>企业管理员</span>
                的企业微信扫码登陆
              </p>
            </>
          ) : step === 1 ? (
            <div className='text-16px font-medium text-font mb-24px'>
              复制回调信息，填写到{' '}
              <LinkText href='https://kf.weixin.qq.com'>微信客服后台</LinkText>{' '}
              开发配置中
            </div>
          ) : step === 2 ? (
            <div className='text-16px font-medium text-font mb-24px'>
              在{' '}
              <LinkText href='https://kf.weixin.qq.com'>微信客服后台</LinkText>{' '}
              开发配置中获取 Secret
            </div>
          ) : null}
          <AgentGoFormItem
            name='CorpID'
            label={renderLabel('企业 ID')}
            rules={[{ required: true, message: '请输入企业 ID' }]}
            normalize={value => value?.trim()}
            hidden={step !== 0}
          >
            <Input
              placeholder='在企业信息中获取参数'
              onBlur={handleCorpIdBlur}
              spellCheck={false}
              allowClear
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            name='KfIds'
            label={renderLabel('客服账号 ID')}
            rules={[{ required: true, message: '请输入客服账号 ID' }]}
            normalize={value => (value ? [value.trim()] : [])}
            hidden={step !== 0}
          >
            <Input
              placeholder='在客服账号详情中获取参数'
              spellCheck={false}
              allowClear
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            name='auth_app_name'
            label={renderLabel('客服账号名称')}
            rules={[{ required: true, message: '请输入客服账号名称' }]}
            normalize={value => value?.trim()}
            hidden={step !== 0}
          >
            <Input placeholder='输入客服名称' allowClear />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('URL', false)}
            dependencies={['CorpID']}
            hidden={step !== 1}
          >
            {form => {
              const CorpID = form.getFieldValue('CorpID')
              return (
                <CopyBox
                  // prettier-ignore
                  value={CorpID ? `${import.meta.env.VITE_AI_API_BASE_URL}/v1/webhook/agent/weixin_kf/${CorpID}/receive_message` : ''}
                />
              )
            }}
          </AgentGoFormItem>
          <AgentGoFormItem
            name='Token'
            label={renderLabel('Token', false)}
            hidden={step !== 1}
          >
            <CopyBox />
          </AgentGoFormItem>
          <AgentGoFormItem
            name='EncodingAESKey'
            label={renderLabel('EncodingAESKey', false)}
            hidden={step !== 1}
          >
            <CopyBox />
          </AgentGoFormItem>
          <AgentGoFormItem
            name='Secret'
            label={renderLabel('Secret')}
            rules={[{ required: step === 2, message: '请输入 Secret' }]}
            normalize={value => value?.trim()}
            hidden={step !== 2}
          >
            <Input
              placeholder='在开发信息中获取参数'
              spellCheck={false}
              allowClear
            />
          </AgentGoFormItem>
        </>
      )}
    </AgentGoPublishConf>
  )
}

export default React.memo(InternalAgentGoWeChat)
