import { useEffect, useRef } from 'react'
import { useReactFlow } from 'reactflow'
import { NodeType } from '@/features/nodes/base'
import type {
  FieldItem,
  StartNodeData,
  StartNodeTypes,
} from '@/features/nodes/start'

export function useModalInputInfo() {
  const { getNodes } = useReactFlow()
  const currentFlowNodes = getNodes()
  const inputInfoRef = useRef<{
    type: StartNodeTypes | null
    config: FieldItem[]
  }>({
    type: null,
    config: [],
  })

  const setInputInfo = (data: any) => {
    inputInfoRef.current = data
  }

  useEffect(() => {
    const startNode = currentFlowNodes.find(n => n.type === NodeType.START)
    if (startNode) {
      const { type, formConfig } = startNode.data as StartNodeData
      inputInfoRef.current = {
        type,
        config: formConfig,
      }
    }
  }, [currentFlowNodes])

  return {
    inputInfo: inputInfoRef.current,
    setInputInfo,
  }
}
