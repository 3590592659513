import type { NodeType } from '@/features/nodes/base'
import type { Version } from '@/features/pay'
import { BaseNodeCard } from './BaseNodeCard'

interface NodeItem {
  type: NodeType
  name: string
  icon: string
  iconColor: string
  description: string
  allowVersion?: Version[]
}

interface GroupItem {
  groupName: string
  data: NodeItem[]
}

interface BaseNodeListProps {
  data: GroupItem[]
  activeKey: NodeType
  onSelect: (type: NodeType) => void
}

export function BaseNodeList(props: BaseNodeListProps) {
  const { data, activeKey, onSelect } = props

  return (
    data.length > 0 && (
      <>
        <div className='pt-12px pb-24px text-16px font-500 c-#17171d'>
          基础能力
        </div>
        {data.map(({ groupName, data: nodes }) => (
          <div key={groupName}>
            <div className='pb-12px font-500 text-#8d8d99'>{groupName}</div>
            {nodes.map(
              ({ type, icon, iconColor, description, name, allowVersion }) => (
                <BaseNodeCard
                  key={type}
                  type={type}
                  icon={icon}
                  iconColor={iconColor}
                  name={name}
                  description={description}
                  selected={type === activeKey}
                  onClick={onSelect}
                  allowVersion={allowVersion || []} // Provide a default value of an empty array
                />
              ),
            )}
          </div>
        ))}
      </>
    )
  )
}
