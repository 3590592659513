import { omit } from 'lodash-es'
import { memo, useContext, useMemo, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import { getLLMNodeSnapshotPromptByHistoryId } from '@/apis/prompt'
import { JsonForm, type JsonFormConfig } from '@/features/nodes/components'
import type { LLMNodeData } from '../../LLMNode'
import {
  getModelMessageStructTypeByChannel,
  LLMMessageStructType,
} from '@/features/nodes/utils/llm.ts'
import { LLMNodePanelContext } from '../type'
import { PromptDiffModal, PromptDiffModalContext } from './PromptDiffModal'
import {
  DiffLLMContextFormField,
  DiffLLMOutputMethod,
  DiffPromptNode,
  DiffVariableNode,
} from './PromptDiffModal/PromptDiffNode'
import { PromptHistory } from './PromptHistory'
import { DiffResult } from './PromptDiffModal/components/Result'
import { HistorySelect } from './PromptDiffModal/HistorySelect'

interface IPromptPanelProps<T> {
  flowId: string
  data: T
  readOnly?: boolean
  variables: Record<string, any>
  resultInfo: Record<string, any>
  activatedNodeId: string
  onUse: () => void
  onChange: (historyId: string) => void | Promise<void>
  onClose: () => void
}

export const HistoryPanel = memo((props: IPromptPanelProps<LLMNodeData>) => {
  const {
    flowId,
    readOnly,
    data,
    variables,
    resultInfo,
    activatedNodeId = '',
    onUse,
    onChange,
    onClose,
  } = props

  const { getTaskResult } = useContext(LLMNodePanelContext)

  const [historyDiffItem, setHistoryDiffItem] = useState<{
    history_id: undefined | string
    date: string
    isLocal: boolean
  }>({ history_id: undefined, date: '', isLocal: true })

  const [historyId, setHistoryId] = useState<string>('')
  const [historyDate, setHistoryDate] = useState<string>('')
  const [historyData, setHistoryData] = useState<any>({})
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const handleShowHistory = useMemoizedFn(
    (newHistoryId: string, extra?: Record<string, any>) => {
      if (newHistoryId === historyId) {
        setHistoryId('')
        setHistoryDate('')
        onChange('')
        return
      }

      setHistoryId(newHistoryId)
      setHistoryDate(extra?.date)
      onChange(newHistoryId)
    },
  )

  const handleUse = useMemoizedFn(() => {
    setHistoryId('')
    setHistoryDate('')
    onUse()
  })

  const getCurrentResult = useMemoizedFn(() => {
    const res = getTaskResult('current')

    return res?.result ?? ''
  })

  const leftDiffList = useMemo<JsonFormConfig[]>(() => {
    const modelMessageStructType = getModelMessageStructTypeByChannel(
      data.inputs.channel,
    )

    return [
      {
        label: '提示词',
        name: ['inputs'],
        required: true,
        tooltip: '提供给AI的提示，以确保AI按照您的意愿做出响应',
        rules: [{ required: true, message: 'System Prompt不能为空' }],
        render: () => <DiffPromptNode readOnly show='current' />,
      },
      {
        label: '上下文',
        hidden: modelMessageStructType === LLMMessageStructType.NO_CONTEXT,
        name: ['inputs'],
        tooltip: '添加对话上下文，以确保AI能更准确的理解人类的意图',
        render: () => <DiffLLMContextFormField show='current' />,
      },
      {
        label: '输出格式',
        name: ['inputs', 'plugin', 'json_mode'],
        tooltip: '支持设置LLM节点输出的内容格式',
        render: () => {
          return <DiffLLMOutputMethod readOnly show='current' />
        },
      },
      {
        label: '变量',
        tooltip: '',
        hidden: false,
        render: () => (
          <DiffVariableNode
            readOnly
            show='current'
            current={variables}
            old={historyData.variables}
          />
        ),
      },
    ] as JsonFormConfig[]
  }, [variables, historyData, data])

  const rightDiffList = useMemo<JsonFormConfig[]>(() => {
    const modelMessageStructType = getModelMessageStructTypeByChannel(
      historyData?.inputs?.channel,
    )

    return [
      {
        label: '提示词',
        name: ['inputs'],
        required: true,
        tooltip: '提供给AI的提示，以确保AI按照您的意愿做出响应',
        rules: [{ required: true, message: 'System Prompt不能为空' }],
        render: () => <DiffPromptNode show='old' readOnly />,
      },
      {
        label: '上下文',
        hidden: modelMessageStructType === LLMMessageStructType.NO_CONTEXT,
        name: ['inputs'],
        tooltip: '添加对话上下文，以确保AI能更准确的理解人类的意图',
        render: () => <DiffLLMContextFormField show='old' />,
      },
      {
        label: '输出格式',
        name: ['inputs', 'plugin', 'json_mode'],
        tooltip: '支持设置LLM节点输出的内容格式',
        render: () => {
          return <DiffLLMOutputMethod show={'old'} />
        },
      },
      {
        label: '变量',
        tooltip: '',
        hidden: false,
        render: () => (
          <DiffVariableNode
            readOnly
            show='old'
            current={variables}
            old={historyData.variables}
          />
        ),
      },
    ] as JsonFormConfig[]
  }, [variables, historyData])

  const modalContextData = useMemo(() => {
    return {
      ...data,
      variables,
      resultInfo,
    }
  }, [data, variables, readOnly])

  return (
    <PromptDiffModalContext.Provider
      value={{
        visible: modalVisible,
        readOnly: !!readOnly,
        data: modalContextData,
        oldData: historyData || {},
        historyDate,
        getCurrentResult,
        handleClose: () => {
          setModalVisible(false)
        },
        handleOpen: async item => {
          const { history_id } = item
          const data = await getLLMNodeSnapshotPromptByHistoryId(history_id)
          setHistoryData(omit(data, 'created_time'))
          setHistoryDiffItem({
            history_id,
            date: data.created_time,
            isLocal: !history_id,
          })
          setModalVisible(true)
        },
      }}
    >
      <div className='w-260px flex-none b-r-1px b-solid'>
        <PromptHistory
          selectedId={historyId}
          flowId={flowId}
          activatedNodeId={activatedNodeId}
          onShow={handleShowHistory}
          onUse={handleUse}
          onClose={onClose}
        />

        <PromptDiffModal
          rightExtraNode={
            <HistorySelect
              flowId={flowId}
              activePromptItemHistoryId={historyId}
              selectedInfo={historyDiffItem}
              activatedNodeId={activatedNodeId}
              onSelectChange={async (item: any) => {
                setHistoryDiffItem(item)
                const data = await getLLMNodeSnapshotPromptByHistoryId(
                  item.history_id,
                )
                setHistoryData(data)
              }}
            />
          }
          leftNode={
            <div className='mt-16px'>
              <JsonForm list={leftDiffList} />
              <DiffResult show='current' />
            </div>
          }
          rightNode={
            <div className='mt-16px'>
              <JsonForm list={rightDiffList} />
              <DiffResult show='old' />
            </div>
          }
        />
      </div>
    </PromptDiffModalContext.Provider>
  )
})
