import { Version } from '@/features/pay'
import { NodeType } from './types'

interface NodeOption {
  type: NodeType
  name: string
  icon: string
  iconColor: string
  description: string
  group: string
  allowVersion?: Version[]
}

interface GroupOption {
  groupName: string
  data: NodeOption[]
}

export const BASE_NODES: NodeOption[] = [
  {
    type: NodeType.LLM,
    name: 'LLM',
    icon: 'llm',
    iconColor: '#00BD68',
    description: '文本类AI大模型，根据要求来命令AI为你服务',
    group: 'LLM大模型',
  },
  {
    type: NodeType.LLM_BATCH,
    name: 'LLM-批量处理',
    icon: 'llm',
    iconColor: '#00BD68',
    description: '可以为你清洗数据，将数据分段后命令AI并行处理数据',
    group: 'LLM大模型',
  },
  {
    type: NodeType.KNOWLEDGE,
    name: '知识库',
    icon: 'memory',
    iconColor: '#3898FF',
    description: '可以向知识库查询或插入数据',
    group: '知识和数据',
  },
  {
    type: NodeType.DATABASE,
    name: '数据库',
    icon: 'shujukujiedian',
    iconColor: '#FF6161',
    description: '可以向数据库查询或导入数据',
    group: '知识和数据',
    allowVersion: [Version.FREE, Version.ENTERPRISE],
  },
  {
    type: NodeType.PYTHON,
    name: 'Python',
    icon: 'code',
    iconColor: '#FFA100',
    description: '可以通过代码下达指令，可以执行Python代码',
    group: '代码和逻辑',
  },
  {
    type: NodeType.JAVASCRIPT,
    name: 'JavaScript',
    icon: 'code',
    iconColor: '#FFA100',
    description: '可以通过代码下达指令，可以执行JavaScript代码',
    group: '代码和逻辑',
  },
  {
    type: NodeType.CONDITION,
    name: '逻辑分支',
    icon: 'condition',
    iconColor: '#6f59df',
    description: '可以设计你的条件逻辑分支，根据设定条件走向不同的路径',
    group: '代码和逻辑',
  },
  {
    type: NodeType.LOOP,
    name: '循环',
    icon: 'xunhuanjiedian',
    iconColor: '#387dff',
    description:
      '可以制定你的循环计划，循环内的节点支持：数组循环、递增循环两种类型',
    group: '代码和逻辑',
  },
  {
    type: NodeType.API,
    name: 'API',
    icon: 'api',
    iconColor: '#FF63A9',
    description: '可以调用外部API来实现数据的发送或者接收',
    group: '调用',
  },
  {
    type: NodeType.TEXT,
    name: '文本',
    icon: 'wenbenjiedian-1',
    iconColor: '#C7782D',
    description: '可以填写文本知识',
    group: '代码和逻辑',
  },
  {
    type: NodeType.INTENT,
    name: '意图分类',
    icon: 'yitufenleijiedian',
    iconColor: '#AA50F9',
    description: '可以对用户问题进行意图分类',
    group: '代码和逻辑',
  },
  // {
  //   type: NodeType.FLOW,
  //   name: 'Flow',
  //   icon: 'flowjiedian-1',
  //   iconColor: '#00C2A2',
  //   description:
  //     'Flow内可以嵌套其他的Flow作为能力（注意Flow必须是本空间已发布的状态）',
  //   group: '调用',
  // },
]

export const BaseNodeOptions = BASE_NODES.reduce<GroupOption[]>((pre, cur) => {
  const groupItem = pre.find(item => item.groupName === cur.group)
  if (groupItem) {
    groupItem.data.push(cur)
  } else {
    pre.push({
      groupName: cur.group,
      data: [cur],
    })
  }
  return pre
}, [])

export const PluginOrderMap: Record<string, number> = {
  解析能力: 0,
  图像处理: 1,
  知识问答: 2,
  网络搜索: 3,
  小红书: 4,
  抖音: 5,
}

// export const BaseNodeOptions = [
//   {
//     groupName: 'LLM大模型',
//     data: [
//       {
//         type: NodeType.LLM,
//         name: 'LLM',
//         icon: 'llm',
//         iconColor: '#00BD68',
//         description: '文本类AI大模型，根据要求来命令AI为你服务',
//       },
//       {
//         type: NodeType.LLM_BATCH,
//         name: 'LLM-批量处理',
//         icon: 'llm',
//         iconColor: '#00BD68',
//         description: '可以为你清洗数据，将数据分段后命令AI并行处理数据',
//       },
//     ],
//   },
//   {
//     groupName: '知识和数据',
//     data: [
//       {
//         type: NodeType.KNOWLEDGE,
//         name: '知识库',
//         icon: 'memory',
//         iconColor: '#3898FF',
//         description: '可以向知识库查询或插入数据',
//       },
//       {
//         type: NodeType.DATABASE,
//         name: '数据库',
//         icon: 'shujukujiedian',
//         iconColor: '#FF6161',
//         description: '可以向数据库查询或导入数据',
//       },
//     ],
//   },
//   {
//     groupName: '代码和逻辑',
//     data: [
//       {
//         type: NodeType.CODE,
//         name: 'Code',
//         icon: 'code',
//         iconColor: '#FFA100',
//         description: '可以通过代码下达指令，可以执行JavaScript和Python代码',
//       },
//       {
//         type: NodeType.CONDITION,
//         name: '逻辑分支',
//         icon: 'condition',
//         iconColor: '#6f59df',
//         description: '可以设计你的条件逻辑分支，根据设定条件走向不同的路径',
//       },
//       {
//         type: NodeType.LOOP,
//         name: '循环',
//         icon: 'xunhuanjiedian',
//         iconColor: '#387dff',
//         description:
//           '可以制定你的循环计划，循环内的节点支持：数组循环、递增循环两种类型',
//       },
//     ],
//   },
//   {
//     groupName: '调用',
//     data: [
//       {
//         type: NodeType.API,
//         name: 'API',
//         icon: 'api',
//         iconColor: '#FF63A9',
//         description: '可以调用外部API来实现数据的发送或者接收',
//       },
//       {
//         type: NodeType.FLOW,
//         name: 'Flow',
//         icon: 'flowjiedian-1',
//         iconColor: '#00C2A2',
//         description:
//           'Flow内可以嵌套其他的Flow作为能力（注意Flow必须是本空间已发布的状态）',
//       },
//     ],
//   },
// ]
