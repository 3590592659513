import { Navigate } from 'react-router-dom'
import type { AuthRoute } from '@/auth'
import Templates from '@/pages/templates/Templates'
import TemplateDetail from '@/pages/templates/TemplateDetail'
import { defaultRouterPath } from '@/constants/common.ts'
import { ErrorElement } from '@/pages/error'

export const templatesRoutes: AuthRoute[] = [
  {
    path: '/templates',
    auth: 'auth',
    errorElement: <ErrorElement />,
    element: <Navigate to={defaultRouterPath} />,
    children: [
      {
        path: '/templates',
        element: <Templates />,
      },
      {
        path: 'run/:id/:tag?',
        element: <TemplateDetail />,
      },
    ],
  },
]
