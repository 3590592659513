import type { Edge } from 'reactflow'
import type { StartNodeTypes } from '@/features/nodes/start'
import { FLOW_EXECUTE_TIMEOUT } from '@/constants/request'
import type { CustomAxiosRequestConfig } from '@/types/axios'
import { request } from './lib'
import type { LogItem } from './run'

export enum FlowStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAIL = 'FAILED',
}

export enum FlowVersionStatusTypes {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum ActionTypesForNode {
  TRIGGER = 'TRIGGER',
  CODE = 'CODE',
  INTEGRATION = 'INTEGRATION',
  LOOP = 'LOOP',
  BRANCH = 'BRANCH',
  END = 'END',
  MISSING = 'MISSING',
}

export interface FlowNode {
  id: string
  isEnable?: boolean
  actionType: ActionTypesForNode
  relation: Record<string, any> | null
  type: string
  name: string
  layout: {
    width: number
    height: number
    position: {
      x: number
      y: number
    }
  }
  data: {
    type: StartNodeTypes | null
    inputs: Record<string, any>
    formConfig?: any
  }
}

export enum FLOW_STATUS {
  NO_LOCK = 0,
  COPILOT_LOCK = 1,
  BATCH_TEST_LOCK = 2,
}

export interface FlowData {
  icon?: string
  color?: string
  flow_id: string
  flow_name: string
  created_by: string | null
  updated_by: string | null
  created_at: string
  updated_at: string
  app_id: string
  workspace_id: string
  description: string | null
  version_id: string
  version_status: FlowVersionStatusTypes
  config: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  form_config: any
  is_published: boolean
  is_modified: boolean
  is_enable: boolean
  flow_lock: FLOW_STATUS
  max_version_no: number
}

export interface FlowUpdateParams {
  flowId: string
  appId: string
  flowName?: string
  description?: string
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
}

export interface FlowUpdateResponse {
  flowId: string
  appId: string
  flowName?: string
  description?: string
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  is_modified: boolean
}

export enum FlowExecuteEnv {
  TESTING = 'TESTING', // 调试
  ONCE_RUN = 'ONCE_RUN', // 单次执行
  BATCH_RUN = 'BATCH_RUN', // 批量执行
  CORN_RUN = 'CORN_RUN', // 定时执行
  BATCH_TEST = 'BATCH_TEST', // 批量测试
  API = 'API',
  WEBHOOK = 'WEBHOOK',
  CHATBOT = 'CHATBOT',
  AGENT = 'AGENT',
}

export interface FlowExecuteParams {
  flowId: string
  versionId: string
  env: FlowExecuteEnv
  inputData: any
}

export type FlowExecuteResponse =
  | {
      flow_id: string
      durationTime: number
      message: {
        step_name?: string
        step_error_info?: string
      }
      run_result: unknown
      run_type: FlowExecuteEnv
      status: FlowStatus.FAIL
      task_id: string
    }
  | {
      flow_id: string
      durationTime: number
      message: string
      run_result: string
      run_type: FlowExecuteEnv
      status: FlowStatus.SUCCEEDED
      task_id: string
    }

export interface FlowPublishParams {
  draftVersionId: string
}

export interface IterationResult {
  index: number
  durationTime: number
  status: FlowStatus
  output: Record<string, NodeRunResult | LogItem>
}

interface NodeRunResult {
  actionType: string
  durationTime: number
  input: any
  output: string | Record<string, any> | Array<IterationResult>
  message: string
  status: FlowStatus
  type: string
}

export interface FlowPublishResponse {
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  created_at: string
  created_by: string | number
  flowId: string
  id: string | number
  is_modified: boolean
  updated_at: string
  updated_by: string | number
  version_id: string
  version_no: number
  version_status: FlowVersionStatusTypes
}

export function getFlowById(flowId: string, config?: CustomAxiosRequestConfig) {
  return request.get<FlowData>(`/v1/flow/${flowId}`, config)
}

export function getDraftFlowById(
  flowId: string,
  config?: CustomAxiosRequestConfig,
) {
  return request.get<FlowData>(`/v1/flow/draft/${flowId}`, config)
}

export function updateFlowDetail(body: FlowUpdateParams) {
  return request.post<FlowUpdateResponse>('v1/flow/update', body)
}

export function publishFlow(
  body: FlowPublishParams,
  config?: CustomAxiosRequestConfig,
) {
  return request.post<FlowPublishResponse>('v1/flow/publish', body, config)
}

export function executeFlow(body: FlowExecuteParams) {
  return request.post<FlowExecuteResponse>('v1/flow/execute', body, {
    timeout: FLOW_EXECUTE_TIMEOUT,
  })
}

export function getFlowExecuteLog({
  flowId,
  taskId,
}: {
  flowId: string
  taskId: string | number
}) {
  return request.get<LogItem>(`v1/flow/execute_log/${flowId}/${taskId}`)
}

export function shareToChatBot(body: { flowId: string; isBot: boolean }) {
  return request.post<LogItem>('/v1/flow/is_bot', body)
}

export interface ExportFlowLogsParams {
  flowId: string
  userName?: string
  runTypes?: FlowExecuteEnv[]
  runStatus?: FlowStatus
  startTime?: string
  endTime?: string
}

export function exportFlowLogs(body: ExportFlowLogsParams) {
  return request.post<string | null>('v1/flow/export_log', body)
}

/**
 * @desc 获取flow的start节点支持的文件类型配置
 */
export function getStartNodeSupportFileConfig() {
  return request.get<ISupportFileConfig[]>('v1/chat/flow/support')
}

export interface ISubFlowItem {
  app_id: string
  color: string
  description: string
  flow_id: string
  flow_name: string
  form_config: Record<string, any>[]
  icon: string
  workspace_id: string
  visible: boolean
}

export async function fetchSubFlowList(flowId: string) {
  const list = await request.get<ISubFlowItem[]>(`/v1/flow/${flowId}/addition`)

  return list.filter(each => each.visible ?? true)
}

export interface ITemplateItem {
  category: string
  id: string
  icon: string
  color: string
  name: string
  description: string
  thumbnail: string
  thumbnail_width: number
  related_blog: string
  source_id: string
  source_workspace_id: string
}

export function fetchTemplateList() {
  return request.get<ITemplateItem[]>('/v1/flow/suite-template')
}

export function forkTemplate(source_id: string) {
  return request.post(`v1/flow/suite-template/fork?source_id=${source_id}`)
}

export interface ISupportFileConfig {
  name: string
  type: string
  support: string[]
  size: number
  extension: string[]
}
export interface IPluginItem {
  function_id: string
  name: string
  description: string
  display_name: string
  icon: string
  color: string
  package_name: string
  output: {
    comment: string
    ip_label: string
  }[]
  form_config: {
    description: string
    example: unknown
    type: string
    /**
     * 是否选择模型
     */
    llm_model?: boolean
    label: string
    required: boolean
    placeholder: string
    variableName: string
    value?: string
    variableType: string
    layout?: 'normal' | 'inline'
    tooltip?: string
    hasExpand?: boolean
  }[]
  action_type: 'INTEGRATION'
  type: 'PLUGIN'
  plugin_label: string
}
export function fetchPluginList(_pluginId: string): Promise<any> {
  return request.get<IPluginItem[]>('/v1/flow/plugin_template')
}

// deprecated 废弃的
export function uploadFileApi(file: any) {
  return request.post<any>('/v1/chat/file_upload', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

interface IUploadFileInfo {
  file_type: string
  upload_record_id: string
  byte_size: number
  file_name: string
}
export function getUploadFileInfo(upload_record_id: any) {
  return request.get<IUploadFileInfo>(
    `/v1/chat/file/search?upload_record_id=${upload_record_id}`,
  )
}

export interface FlowInputSchema {
  key: string
  description: string
  filed_type: string
  required: boolean
  default_value: string
}

export interface FlowSchema {
  description: string
  inputs: FlowInputSchema[]
  output: { key: string; value: string }
}

export function generateFlowSchema(flowId: string, isDraft = false) {
  return request.get<FlowSchema>(
    `/v1/flow/skill/schema?flow_id=${flowId}${isDraft ? '&is_draft=true' : ''}`,
  )
}

export function getFlowRunResult(flowId: string) {
  return request.get<string | Record<string, unknown> | unknown[]>(
    `/v1/flow/task_result?flow_id=${flowId}`,
    {
      headers: {
        'Application-Id': flowId,
      },
    },
  )
}
