import React, { useCallback, useEffect } from 'react'
import { FlowList } from '@/features/agent/SkillTabs/FlowList'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'

function InternalAgentFlow() {
  const { chatRef, ruleRef, applicationInfo, skillConfigContext } =
    useAgentEdit()
  const { flowList, skillConfigMap, refresh, refreshAsync } = skillConfigContext

  const handleListChange = useCallback(() => {
    refresh()
    ruleRef.current?.refreshRuleConfig()
    setTimeout(() => {
      chatRef?.current?.refetch()
    }, 500)
  }, [refresh])
  useEffect(() => {
    refreshAsync()
  }, [])

  if (!applicationInfo) return null

  return (
    <FlowList
      flowInfo={applicationInfo}
      list={flowList}
      selectedSkillMap={skillConfigMap}
      onListChange={handleListChange}
    />
  )
}

export const AgentFlow = React.memo(InternalAgentFlow)
