import React, { useState, useRef, useContext } from 'react'
import { List, Skeleton } from 'antd'
import { useMemoizedFn, useUnmount } from 'ahooks'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { InfiniteScroll } from '@/components/InfiniteScrollComponent'
import { PromptDiffModalContext } from '../../PromptDiffModal'

import type { IItemInfoChangeParams } from '@/store'
import EmptyPng from '@/assets/list-empty.png'
import { IconFont } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { typeSelectOption } from '@/pages/application/components/appDetail/utils'
import { ActionHandler } from './ActionHandler'

function Empty() {
  return (
    <div className='text-12px line-height-20px text-center flex flex-col items-center mt-48px'>
      <img className='w-80px h-80px' src={EmptyPng} alt='' />
      <span className='c-#8D8D99 mt-8px'>还没有历史记录</span>
    </div>
  )
}

interface IRecordProps {
  selected: string
  list: any[]
  hasMore: boolean
  resetData: () => void
  loadMore: () => void
  onItemClick: (history_id: string, extra?: Record<string, any>) => void
  onItemInfoChange: (params: IItemInfoChangeParams) => void
}

const Records: React.FC<IRecordProps> = props => {
  const {
    selected,
    list,
    hasMore,
    resetData,
    loadMore,
    onItemClick,
    onItemInfoChange,
  } = props

  const { handleOpen } = useContext(PromptDiffModalContext)

  const targetRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)

  const loadMoreData = useMemoizedFn(async () => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      await loadMore()
    } finally {
      setLoading(false)
    }
  })

  useUnmount(() => {
    resetData()
  })

  const onPromptDiff = (item: Record<string, any>) => {
    handleOpen(item)
  }

  return (
    <div ref={targetRef} className='h-full'>
      <OverlayScrollbarsComponent
        className='px-20px h-full overflow-auto'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        <InfiniteScroll
          load={loadMoreData}
          targetRoot={targetRef}
          hasMore={hasMore && !loading}
          loader={
            <Skeleton className='p-20px!' paragraph={{ rows: 3 }} active />
          }
        >
          <div>
            {!loading && !list.length ? (
              <Empty />
            ) : (
              <List
                dataSource={list}
                renderItem={item => (
                  <List.Item className='p-0px! b-0px!' key={item.date}>
                    <div className='flex flex-col color-#17171D w-100%'>
                      <p className='text-12px line-height-16px color-#8D8D99 mb-8px'>
                        {dayjs(item.date).format('YYYY年MM月DD日 dddd') ||
                          '未知时间'}
                      </p>

                      {item.list.map((each: any) => {
                        const type = typeSelectOption.find(
                          e => e.value === each?.run_env,
                        )
                        const collected = each.favorite_id !== null

                        return (
                          <div
                            key={each.history_id}
                            className={classNames(
                              'w-full h-54px px-12px py-8px mb-8px hover:bg-[rgba(127,105,255,0.08)] cursor-pointer rounded-6px [&:hover_.vs-button]:flex [&:hover_.collect-icon]:opacity-100',
                              {
                                '[&_.vs-button]:hidden! [&_.collect-icon]:opacity-100':
                                  each.history_id === selected,
                                'bg-[rgba(127,105,255,0.08)]':
                                  each.history_id === selected,
                              },
                            )}
                            onClick={() => {
                              const { history_id, created_time } = each
                              onItemClick(history_id, { date: created_time })
                            }}
                          >
                            <div className='flex flex-1 items-center w-100% justify-between items-center mb-4px'>
                              <p className='line-height-16px text-12px'>
                                {type?.label || ''}
                                {each.show_name && (
                                  <span>{each.show_name}</span>
                                )}
                                <span className='text-#8D8D99 ml-4px'>
                                  {dayjs(each.date).format('HH:mm:ss')}
                                </span>
                              </p>

                              <ActionHandler
                                collected={collected}
                                onChange={newCollected => {
                                  onItemInfoChange({
                                    type: 'record',
                                    history_id: each.history_id,
                                    favorite_id: each.favorite_id,
                                    action: newCollected ? 'add' : 'remove',
                                  })
                                }}
                              />
                            </div>

                            <div className='flex text-12px'>
                              <p className='line-height-18px text-truncate flex-auto'>
                                {each.response || '-'}
                              </p>

                              <div
                                onClick={e => {
                                  e.stopPropagation()
                                  onPromptDiff(each)
                                }}
                                className='hidden vs-button flex-none flex flex-center rd-4px mx-[-4px] px-4px whitespace-nowrap hover:bg-[rgba(98,105,153,0.08)]'
                              >
                                <IconFont name='vs' className='text-16px' />
                                <span className='ml-4px text-12px line-height-16px c-#7B61FF'>
                                  对比
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </List.Item>
                )}
              />
            )}
          </div>
        </InfiniteScroll>
      </OverlayScrollbarsComponent>
    </div>
  )
}

export default Records
