import { Spin } from 'antd'

export interface ForkTaskLoadingProps {
  text: string
}

export function ForkTaskLoading(props: ForkTaskLoadingProps) {
  const { text } = props

  return (
    <div className='fork-loading flex-center absolute bg-bg_3 bg-op-30 top-0 left-0 w-full h-full z-3'>
      <div className='flex-center flex-col rounded-8px bg-white w-360px h-130px'>
        <Spin />
        <p className='c-bg_3 c-op-40 mt-20'>{text}</p>
      </div>
    </div>
  )
}
