import { memo } from 'react'
import { Tooltip } from 'antd'
import { IconFont } from '@/components'

const documentUrl =
  'https://ai-docs.betteryeah.com/Flow/%E8%8A%82%E7%82%B9%E4%BB%8B%E7%BB%8D%E4%B8%8E%E9%85%8D%E7%BD%AE/JavaScript&Python%E8%8A%82%E7%82%B9.html'

interface DocumentPanelContentProps {
  toggleRightBar: (action: 'open' | 'close') => void
}

function DocumentPanelContent(props: DocumentPanelContentProps) {
  const { toggleRightBar } = props

  const openInNewTab = () => {
    window.open(documentUrl)
  }

  return (
    <div
      style={{
        height: 'calc(100vh - 96px)',
      }}
    >
      <div
        className='px-16px py-8px flex flex-justify-between flex-items-center'
        style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
      >
        <div className='font-size-16px font-medium'>文档</div>
        <div className='flex'>
          <Tooltip title='新标签页打开'>
            <span
              onClick={openInNewTab}
              className='w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
            >
              <IconFont name='super-link-1' className='text-16px' />
            </span>
          </Tooltip>
          <span
            onClick={() => toggleRightBar('close')}
            className='w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12 ml-4px'
          >
            <IconFont name='guanbi' className='text-16px' />
          </span>
        </div>
      </div>
      <iframe
        allow='microphone; clipboard-read; clipboard-write'
        sandbox='allow-popups allow-downloads allow-scripts allow-same-origin allow-forms'
        src={documentUrl}
        className='w-100% h-100%'
        frameBorder='0'
      />
    </div>
  )
}

export const DocumentPanel = memo(DocumentPanelContent)
