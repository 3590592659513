import { useCallback } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { isInIframe } from '@/utils/iframe'

export function usePageBack() {
  const { appId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const onBackDetail = useCallback(() => {
    const inIframe = isInIframe()

    if (inIframe) {
      navigate(`/application/${appId}`)
      return
    }
    const source = searchParams.get('source')
    // const hasPrevPage = history.length > 2
    source ? navigate(source) : navigate(`/application/${appId}`)
  }, [searchParams, navigate, appId])

  return {
    onBackDetail,
  }
}
