import type { CSSProperties } from 'react'

interface MsgCountIconProps {
  count: number
  style: CSSProperties
}

export function MsgCountIcon(props: MsgCountIconProps) {
  const { count, style } = props

  return (
    <div
      className='content-box min-w-20px h-20px px-4px b-rd-402px line-height-16px bg-#ff5219 b-2 text-center text-12px font-600 text-#fff'
      style={style}
    >
      {count > 99 ? '99+' : count}
    </div>
  )
}
