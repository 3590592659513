import type { Node } from 'reactflow'
import { useReactFlow } from 'reactflow'
import {
  getAllBranchNodesFromHasConditionNode,
  isConditionNode,
  isIntentNode,
} from '@/features/flow/utils'
import { generateUniqueHandleStateId } from '@/features/flow/utils/handle'
import { useFlowInteractionStore } from '@/store'
import { NodeType } from '../base'

export function useNodeBranchRemoveConfirm(nodeId: string) {
  const { getNodes, getEdges } = useReactFlow()
  const setDangerStates = useFlowInteractionStore(s => s.setDangerStates)
  const clearDangerStates = useFlowInteractionStore(s => s.clearDangerStates)

  const beforeRemove = (branchId: string) => {
    const nodes = getNodes()
    const currentNode = nodes.find(n => n.id === nodeId)
    if (currentNode && isConditionNode(currentNode)) {
      const handleIds: string[] = []
      const edgeIds: string[] = []
      const nodeIds: string[] = []
      // const { conditions, relation } = currentNode.data
      const edges = getEdges()
      const branchNodeIds = nodes
        .filter(n => n.data.relation?.branchId === branchId)
        .reduce<Node[]>((pre, cur) => {
          if (isConditionNode(cur)) {
            return pre.concat(getAllBranchNodesFromHasConditionNode(cur, nodes))
          }
          return pre.concat(cur)
        }, [])
        .map(n => n.id)
      nodeIds.push(...branchNodeIds)
      const targetEdges = edges.filter(
        e =>
          e.sourceHandle === branchId ||
          branchNodeIds.includes(e.source) ||
          branchNodeIds.includes(e.target),
      )
      targetEdges.forEach(({ id, source, target, sourceHandle }) => {
        edgeIds.push(id)
        handleIds.push(
          generateUniqueHandleStateId({
            nodeId: source,
            sourceHandle,
            type: 'source',
          }),
        )
        const targetNode = nodes.find(n => n.id === target)
        if (targetNode && targetNode.type !== NodeType.CONDITION_RESULT) {
          handleIds.push(
            generateUniqueHandleStateId({ nodeId: target, type: 'target' }),
          )
        }
      })
      setDangerStates({
        handles: handleIds,
        edgeIds,
        nodeIds,
      })
    }

    if (currentNode && isIntentNode(currentNode)) {
      const handleIds: string[] = []
      const edgeIds: string[] = []
      const nodeIds: string[] = []
      // const { conditions, relation } = currentNode.data
      const edges = getEdges()
      const branchNodeIds = nodes
        .filter(n => n.data.relation?.branchId === branchId)
        .reduce<Node[]>((pre, cur) => {
          if (isIntentNode(cur)) {
            return pre.concat(getAllBranchNodesFromHasConditionNode(cur, nodes))
          }
          return pre.concat(cur)
        }, [])
        .map(n => n.id)
      nodeIds.push(...branchNodeIds)
      const targetEdges = edges.filter(
        e =>
          e.sourceHandle === branchId ||
          branchNodeIds.includes(e.source) ||
          branchNodeIds.includes(e.target),
      )
      targetEdges.forEach(({ id, source, target, sourceHandle }) => {
        edgeIds.push(id)
        handleIds.push(
          generateUniqueHandleStateId({
            nodeId: source,
            sourceHandle,
            type: 'source',
          }),
        )
        const targetNode = nodes.find(n => n.id === target)
        if (targetNode && targetNode.type !== NodeType.INTENT_RESULT) {
          handleIds.push(
            generateUniqueHandleStateId({ nodeId: target, type: 'target' }),
          )
        }
      })
      setDangerStates({
        handles: handleIds,
        edgeIds,
        nodeIds,
      })
    }
  }

  const cancel = () => {
    clearDangerStates()
  }

  return {
    beforeRemove,
    cancel,
  }
}
