import { useSessionStorageState } from 'ahooks'
import { Button } from '@/components'
import {
  useApplicationStore,
  useFlowLogStore,
  useWorkspaceStore,
} from '@/store'
import {
  DEBUG_TASK_ID_MAP,
  DEBUG_TEST_ID_MAP,
  FLOW_ERROR_LOG,
} from '../constants'
import { useSetSessionValue } from './loggerModal/hooks/useSessionValue'

export function ReproduceErrorBtn({ nodeName }: { nodeName: string }) {
  // const navigate = useNavigate()
  const { currentLog, currentFlowId } = useFlowLogStore()
  const { currentApplicationId } = useApplicationStore()
  const { currentWorkspaceId } = useWorkspaceStore()

  const [, setFLowErrorLog] = useSessionStorageState(FLOW_ERROR_LOG, undefined)
  const setDebugTaskId = useSetSessionValue(DEBUG_TASK_ID_MAP)
  const setTestDataId = useSetSessionValue(DEBUG_TEST_ID_MAP)

  const onClick = () => {
    if (
      [
        nodeName,
        currentLog,
        currentApplicationId,
        currentFlowId,
        currentWorkspaceId,
      ].some(item => !item)
    ) {
      return
    }

    if (currentFlowId) {
      setDebugTaskId(currentLog?.task_id, currentFlowId)
      setTestDataId(null, currentFlowId)
    }
    setFLowErrorLog({
      errorNodeName: nodeName,
      run_type: currentLog?.run_type,
    })

    window.open(
      `/application/${currentWorkspaceId}/${currentApplicationId}/flow/${currentFlowId}`,
    )
  }

  if (
    [
      nodeName,
      currentLog,
      currentApplicationId,
      currentFlowId,
      currentWorkspaceId,
    ].some(item => !item)
  ) {
    return null
  }

  return (
    <Button size='small' type='link' onClick={onClick}>
      尝试复现此错误
    </Button>
  )
}
