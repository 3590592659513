import type { ChatPCInstance, ChatPCProps } from '@bty/chat-renderer-pc'
import { ChatPC } from '@bty/chat-renderer-pc'
import type { ReactNode } from 'react'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useAuth } from '@bty/react-auth'
import { message } from 'antd'
import { useUserStore, useVersionStore, useWorkspaceStore } from '@/store'
import { IssueReport, SceneTypes, usePageModal } from '@/components'
import { checkGrayscale } from '@/utils/grayscale'
import { grayscaleMap } from '@/constants/common'
import { API_CODE_CODE } from '@/constants/request'
import { useChatStore } from '@/store/chat.ts'
import { useGenerateMarkdownLimitedInfo } from '@/features/pay/LimitedAlert'
import { useReportFileUpload } from '@/hooks/useReportFileUpload'
import { FormMessageComponentMap } from '@/features/agent/components/FormMessage.tsx'
import { tokenStorage } from '@/utils/storage.ts'
import { usePluginActionOnAgent } from './hooks/usePluginActionOnAgent'
import { generateBotMessage } from './utils/chatMessage'
import { TaskLogModal } from '.'

let time: any = null

export interface AgentChatProps extends Partial<ChatPCProps> {
  testMode?: boolean
  actionType?: ChatPCProps['actionType']
  actions?: ChatPCProps['actions']
  inputTips?: string[]
  showPlaceholder?: ChatPCProps['showPlaceholder']
  placeholder?: ChatPCProps['placeholder']
  runType?: ChatPCProps['runType']
  robotId?: ChatPCProps['robotId']
  robotAvatarInfo?: ChatPCProps['robotAvatarInfo']
  conversationId?: string
  hideHeader?: ChatPCProps['hideHeader']
  clearText?: ChatPCProps['clearText']
  title?: ChatPCProps['title']
  background?: ChatPCProps['background']
  disabled?: ChatPCProps['disabled']
  botMessageProps?: ChatPCProps['botMessageProps']
  userMessageProps?: ChatPCProps['userMessageProps']
  autoCreateConversation?: ChatPCProps['autoCreateConversation']
  hiddenPadding?: boolean
  hiddenVarModal?: boolean
  features?: ChatPCProps['features']
  onTitleGenerate?: ChatPCProps['onTitleGenerate']
  onConversationCreated?: ChatPCProps['onConversationCreated']
  onChatSend?: ChatPCProps['onChatSend']
  onBeforeSubmit?: ChatPCProps['onBeforeSubmit']
  handleBeforeFileUpload?: ChatPCProps['handleBeforeFileUpload']
  initVariablesValue?: ChatPCProps['initVariablesValue']
  onMessageTaskClick?: ChatPCProps['onMessageTaskClick']
  onBotChange?: ChatPCProps['onBotChange']
  pluginItemOnMessageRender?: ChatPCProps['pluginItemOnMessageRender']
  uploadFileConfig?: ChatPCProps['uploadFileConfig']
}

export const AgentChat = forwardRef((props: AgentChatProps, ref) => {
  const {
    testMode,
    actionType,
    actions = ['stop', 'clear'],
    inputTips,
    placeholder,
    showPlaceholder,
    runType,
    robotId,
    robotAvatarInfo,
    conversationId,
    // hiddenPadding = false,
    clearText,
    title,
    hideHeader,
    autoCreateConversation = false,
    background,
    botMessageProps,
    userMessageProps,
    features,
    onTitleGenerate,
    onConversationCreated,
    onChatSend,
    onBeforeSubmit,
    handleBeforeFileUpload,
    hiddenVarModal,
    initVariablesValue,
    onMessageTaskClick,
    onBotChange,
    uploadFileConfig = {
      fileAccepts: [],
      allowFileTypeNameList: [],
    },
    ...restProps
  } = props
  const chatRef = useRef<ChatPCInstance>(null)
  const {
    unreadMessageCountMap,
    unreadMessageCountForAgentTestMap,
    fetchUnreadMessageCount,
  } = useChatStore()

  const user = useUserStore(state => state.user)
  const auth = useAuth()
  const {
    state: { grayscaleList = [] },
  } = auth
  const enableUploadImgSkill = checkGrayscale({
    grayscaleList,
    grayscale_key: grayscaleMap.vision_file_action_type,
  })
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const fetchVersionByWorkspaceId = useVersionStore(
    state => state.fetchVersionByWorkspaceId,
  )

  const { addFileProcessingPlugin, pluginItemOnMessageRender } =
    usePluginActionOnAgent()

  const modal = usePageModal()

  const onStop = useCallback(
    () => fetchVersionByWorkspaceId(workspaceId),
    [workspaceId],
  )

  const [limitedMarkdownInfo, handleLimitedModalClick] =
    useGenerateMarkdownLimitedInfo({
      prefix: '抱歉我无法回答你的问题，我所在的',
    })

  const onChatResponse: ChatPCProps['onChatResponse'] = data => {
    if (!data.success) {
      if (data.code === API_CODE_CODE.COMBO_NOT_ENOUGH) {
        chatRef.current?.chatMessage.replaceLast(
          generateBotMessage({
            content: limitedMarkdownInfo,
            showRegenerateBtn: false,
            hideActions: true,
          }),
        )
      } else {
        message.error(data.message)
      }
    }
  }

  const onChatEnd: ChatPCProps['onChatEnd'] = () => {
    fetchVersionByWorkspaceId(workspaceId)
  }

  const [currentMessageList, setCurrentMessageList] = useState([])

  useEffect(() => {
    time && clearTimeout(time)
    // 未知原因useImperativeHandle中获取到的messageList在延迟大约500毫秒后才会拿到最新值
    time = setTimeout(
      () =>
        setCurrentMessageList(
          JSON.parse(JSON.stringify(chatRef.current?.messageList || [])),
        ),
      500,
    )
  }, [conversationId])

  useImperativeHandle(ref, () => {
    return { ...chatRef.current, messageList: currentMessageList }
  }, [currentMessageList, chatRef.current])

  const { upload } = useReportFileUpload()

  return (
    <ChatPC
      {...restProps}
      ref={chatRef}
      env={__DEFINE_ENV__}
      user={{
        id: user?.username || '',
        name: user?.username || '',
        avatar: user?.avatar,
      }}
      features={features}
      title={title}
      // hiddenPadding={hiddenPadding}
      loggerComponent={TaskLogModal}
      onBotChange={onBotChange}
      actionType={actionType}
      actions={actions}
      inputTips={inputTips}
      showPlaceholder={showPlaceholder}
      onHrefTagClick={(url, blank?: boolean) => {
        if (blank) {
          window.open(url, '_blank')
          return
        }
        // TODO 后续优化代码 暂时使用写死方法
        if (url.includes('/api/v1/dataset/file/download')) {
          window.open(url, '_blank')
        } else if (url.includes('show-bty-limited-action')) {
          handleLimitedModalClick(url)
        } else {
          modal.show({ url })
        }
      }}
      onMessageTaskClick={onMessageTaskClick}
      placeholder={placeholder}
      workspaceId={workspaceId}
      runType={runType}
      onBeforeSubmit={onBeforeSubmit}
      robotId={robotId as string}
      onStop={onStop}
      onChatResponse={onChatResponse}
      hideHeader={hideHeader}
      robotAvatarInfo={robotAvatarInfo}
      clearText={clearText}
      conversationId={conversationId}
      background={background}
      botMessageProps={botMessageProps}
      userMessageProps={userMessageProps}
      onTitleGenerate={onTitleGenerate}
      onChatSend={onChatSend}
      onChatEnd={onChatEnd}
      autoCreateConversation={autoCreateConversation}
      onConversationCreated={onConversationCreated}
      unReadMessageConversationCountMap={
        testMode
          ? unreadMessageCountForAgentTestMap.conversation
          : unreadMessageCountMap.conversation
      }
      unReadRobotMessageCountMap={unreadMessageCountMap.robot}
      onConversationMessageRead={() => {
        fetchUnreadMessageCount(testMode)
        window.dispatchEvent(new Event('chatMessageUpdate'))
      }}
      handleBeforeFileUpload={handleBeforeFileUpload}
      grayFeat={{ enableImgSkill: enableUploadImgSkill }}
      issueReportRenderer={({ conversationId }: { conversationId: string }) => {
        return (issue: string, data?: any, children?: ReactNode) => {
          return (
            <IssueReport
              sceneType={SceneTypes.AGENT_EDIT}
              defaultDesc={issue}
              className='ml-8px p-0! h-auto!'
              extraData={{ ...(data || {}), conversationId, agentId: robotId }}
              getPopupContainer={() => document.body}
              upload={upload}
            >
              {children}
            </IssueReport>
          )
        }
      }}
      hiddenVarModal={hiddenVarModal}
      initVariablesValue={initVariablesValue}
      formMessageComponentMap={FormMessageComponentMap}
      uploadFileConfig={{
        ...uploadFileConfig,
        addFileProcessingPlugin,
      }}
      pluginItemOnMessageRender={pluginItemOnMessageRender}
      authorization={tokenStorage.get()}
    />
  )
})
