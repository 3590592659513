import { useEffect, useRef } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import type { Bot } from '@bty/chat-types'
import { usePageModal } from '@/components'
import { fetchAgentDetail } from '@/apis/agent'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { useWorkspaceSelectModal } from '@/pages/application/components/WorkSpaceSelect'
import { forkApplication } from '@/apis/application'
import { RoleCode } from '@/apis/authority'

// const DEFAULT_SKILL_DISPLAY_COUNT = 10

export interface BotDetailProps {
  bot: Bot
}

export function useBotDetail({ bot }: BotDetailProps) {
  const botId = bot.robot_id as string

  const {
    currentWorkspaceId,
    workspaceList,
    setCurrentWorkspace,
    fetchWorkspaceList,
  } = useWorkspaceStore()

  const setBotWorkspaceItem = useVersionStore(
    state => state.setBotWorkspaceItem,
  )

  const { data } = useRequest(() => fetchAgentDetail(botId), {
    refreshDeps: [botId, currentWorkspaceId],
    onSuccess: res => {
      if (res?.workspace_id && res?.workspace_id !== currentWorkspaceId) {
        setBotWorkspaceItem({
          workspaceId: res?.workspace_id,
          developerName: res?.developer,
          workspaceName: res?.workspace_name,
        })
      } else {
        setBotWorkspaceItem(undefined)
      }
    },
  })

  const pageModal = usePageModal()

  const agentIdForCreateRef = useRef('')

  const { runAsync: createAgentByTemplate } = useRequest(forkApplication, {
    manual: true,
  })

  // 用于单独打开的 chatbot 页面，获取 workspaceList 和 currentWorkspaceItem
  useEffect(() => {
    if (workspaceList?.length === 0 && currentWorkspaceId) {
      fetchWorkspaceList().then(() => {
        setCurrentWorkspace(currentWorkspaceId)
      })
    }
  }, [workspaceList, currentWorkspaceId])

  // 关掉对话框，重置 botworkspaceItem
  useEffect(() => {
    return () => {
      setBotWorkspaceItem(undefined)
    }
  }, [botId, currentWorkspaceId])

  const [workspaceSelectModal] = useWorkspaceSelectModal({
    copyType: '复制Agent',
    onConfirm: async (workspaceId: string, selectedConnect?: boolean) => {
      const res = await createAgentByTemplate({
        source_appid: agentIdForCreateRef.current,
        source_workspace_id: bot.workspace_id,
        target_workspace_id: workspaceId,
        is_force_fork: selectedConnect,
      })
      // pageModal.show({
      //   url: `/agent/${res.application_id}?forkTaskId=${res.task_id}`,
      // })
      window.open(
        `/agent/${res.application_id}?forkTaskId=${res.task_id}`,
        '_blank',
      )
      workspaceSelectModal.close()
      setCurrentWorkspace(workspaceId)
    },
    defaultWorkspaceId: currentWorkspaceId,
    showConnectComponent: true,
    appId: data?.appId,
    checkLimit: 'app',
  })

  const workspace = workspaceList.find(el => el.id === data?.workspace_id)

  const couldEdit =
    workspace !== undefined &&
    [RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER].includes(workspace.roleCode)

  const onEdit = useMemoizedFn(() => {
    const url =
      bot.type === 'AI-AGENT'
        ? `${location.origin}/agent/${bot.app_id}`
        : `${location.origin}/application/${currentWorkspaceId}/${bot.app_id}/flow/${bot.flow_id}`
    pageModal.show({ url })
  })

  const onFork = useMemoizedFn(() => {
    agentIdForCreateRef.current = data?.appId || ''
    workspaceSelectModal.open()
  })

  return {
    couldEdit,
    isTemplate: data?.isTemplate,
    onEdit,
    onFork,
  }
}
