import type { FC, PropsWithChildren } from 'react'

export const LinkText: FC<PropsWithChildren<{ href: string }>> = ({
  children,
  href,
}) => {
  return (
    <a
      href={href}
      target='_blank'
      className='text-primary cursor-pointer'
      rel='noreferrer'
    >
      {children}
    </a>
  )
}
