import { omit } from 'lodash-es'
import { NodeType } from '@/features/nodes/base'
import type { FlowOutput } from '../types'
import type { LogItem } from '@/apis/run'

export function logItem2InputInfo(currentItem: LogItem) {
  if (currentItem?.flow_node_type && currentItem.form_config) {
    return {
      type: currentItem.flow_node_type,
      config: currentItem.form_config,
    }
  }
  return {
    type: currentItem?.flow_node_type,
    config: currentItem?.form_config || [],
  }
}

export function logItem2FormValues(currentItem: LogItem) {
  if (!currentItem) return {}
  const { run_result } = currentItem
  const startNode = Object.values(run_result.actionOutputs).find(
    n => n.type === NodeType.START,
  )
  return omit(startNode.input, 'run_args')
}

export function addChildrenToFlowOutput(
  items: FlowOutput[],
  key: string,
  children: FlowOutput[],
): FlowOutput[] {
  return items.map(item => {
    const newItem = { ...item }

    // 检查当前项的 key 值，如果匹配则添加 tag
    if (newItem.value === key) {
      newItem.children = children
    } else {
      // 递归处理子项
      if (newItem.children) {
        newItem.children = addChildrenToFlowOutput(
          newItem.children,
          key,
          children,
        )
      }
    }

    return newItem
  })
}
