import { useState } from 'react'
import { Checkbox } from '@/components'

export function CheckContainer(props: {
  defaultValue?: boolean
  onChange?: (value: boolean) => void
  title?: string
  label?: string | string[]
}) {
  const { title, label } = props
  const [value, setValue] = useState(false)
  return (
    <div
      className='flex items-start bg-bg_3 bg-op-6 rounded-8px p-12 cursor-pointer'
      onClick={() => {
        setValue(!value)
        props.onChange?.(!value)
      }}
    >
      <Checkbox className='!m-0' checked={value} name='check' />
      <div className='ml-8'>
        <p className='line-height-20px mb-4 font-400'>{title}</p>
        {typeof label === 'string' && (
          <p className='text-font_2 text-12px font-400'>{label}</p>
        )}
        {Array.isArray(label) &&
          label.map((item, key) => {
            return (
              <p key={key} className='text-font_2 text-12px/21px font-400'>
                {item}
              </p>
            )
          })}
      </div>
    </div>
  )
}
