import { message } from 'antd'
import copy from 'copy-to-clipboard'
import styled from '@emotion/styled'
import type { CSSProperties } from 'react'
import classNames from 'classnames'
import { IconFont } from '@/components'

const HoverIcon = styled(IconFont)`
  &:hover {
    color: rgba(98, 105, 153, 0.12) !important;
  }
`

export default function ContentURL({
  url,
  method,
  style = {},
  className = '',
}: {
  url: string
  method?: string
  style?: CSSProperties
  className?: string
}) {
  return (
    <div
      className={classNames(
        'flex flex-nowrap border-rd-8px overflow-hidden',
        className,
      )}
      style={style}
    >
      {method && (
        <div className='text-center py-10 px-12px text-14px font-500 bg-#eae9f1'>
          {method}
        </div>
      )}
      <div className='flex flex-justify-between flex-items-center overflow-hidden flex-1 py-10 px-12px text-14px bg-bg_3 bg-op-6'>
        <div title={url} className='truncate w-[calc(100%_-_30px)]'>
          {url}
        </div>
        <HoverIcon
          name='copy'
          style={{ color: 'rgba(141, 141, 153, 0.6)' }}
          className='text-16px cursor-pointer'
          onClick={() => {
            copy(url)
            message.success('复制成功！')
          }}
        />
      </div>
    </div>
  )
}
