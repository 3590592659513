import { useRef } from 'react'
import { useMemoizedFn } from 'ahooks'

interface Option {
  onDragStart: () => void
  onDragChange: (diff: number) => void
  onDragEnd: () => void
}

export function useMouseDrag({ onDragStart, onDragChange, onDragEnd }: Option) {
  const yRef = useRef(-1)
  const isDragging = useRef(false)

  const handleMove = useMemoizedFn(() => {
    const globalMouseMove = (event: any) => {
      event.stopPropagation()
      event.preventDefault()
      isDragging.current = true
      const nowY = event.clientY
      onDragChange(nowY - yRef.current)
    }

    const globalMouseUp = () => {
      document.removeEventListener('mousemove', globalMouseMove)
      document.removeEventListener('mouseup', globalMouseUp)
      if (!isDragging.current) return
      setTimeout(() => {
        isDragging.current = false
        onDragEnd()
      }, 100)
    }

    document.addEventListener('mouseup', globalMouseUp)
    document.addEventListener('mousemove', globalMouseMove)
  })

  const handleMouseDown = useMemoizedFn((event: any) => {
    yRef.current = event.clientY
    handleMove()
    onDragStart()
  })

  return handleMouseDown
}
