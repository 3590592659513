import styled from '@emotion/styled'
import { isString } from 'markdown-it/lib/common/utils'
import { memo, useRef } from 'react'
import { IconFont } from '@/components'

const Container = styled.div<{
  top: number
  width: number
  right: number
  bottom: number
}>`
  position: fixed;
  top: ${({ top }) => top}px;
  bottom: ${({ bottom }) => bottom}px;
  right: ${({ right }) => right}px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  border-radius: 8px;
  background-color: #fff;
  backdrop-filter: blur(16px);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  & {
    .run-btn {
      &:disabled {
        color: #f7f7ff;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
          ),
          #7b61ff;
      }
    }
  }
`

export interface BaseModalProps {
  top?: number
  width?: number
  right?: number
  bottom?: number
  zIndex?: number
  title?: React.ReactNode
  children: React.ReactNode
  onClose?: () => void
}

export const BaseModal = memo<BaseModalProps>(props => {
  const {
    top = 68,
    right = 20,
    width = 600,
    bottom = 20,
    zIndex = 6,
    children,
    title,
    onClose,
  } = props
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Container
      width={width}
      ref={containerRef}
      top={top}
      bottom={bottom}
      style={{ zIndex }}
      right={right}
    >
      <div className='flex flex-items-center flex-justify-between h-56px shrink-0 flex-grow-0 p-l-20px p-r-16px border-b-1 border-b-#e1e1e5 border-b-op-60'>
        <div className='flex flex-items-center'>
          {isString(title) ? (
            <span className='m-r-12 text-16px font-600 c-#17171d'>{title}</span>
          ) : (
            title
          )}
        </div>
        <div
          className='w-24px h-24px b-rd-4px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
          onClick={onClose}
        >
          <IconFont name='guanbi' className='text-16px' />
        </div>
      </div>
      <div className='flex-1 overflow-auto'>{children}</div>
    </Container>
  )
})
