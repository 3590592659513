import type { AuthRoute } from '@/auth'
import PluginInfo from '@/pages/plugins/pluginInfo'
import { ErrorElement } from '@/pages/error'

export const pluginsRoutes: AuthRoute[] = [
  {
    path: '/plugin/info/:pluginId',
    auth: 'auth',
    errorElement: <ErrorElement />,
    element: <PluginInfo />,
  },
]
