import html2canvas from 'html2canvas'
import { useState } from 'react'

export function useScreenShot() {
  const [screenShot, setScreenShot] = useState('')
  const [blob, setBlob] = useState<Blob | null>(null)

  const takeScreenShot = async () => {
    // const clientApiExist = !!navigator.mediaDevices.getDisplayMedia
    // if (clientApiExist) {
    //   const canvas = document.createElement("canvas");
    //   const context = canvas.getContext("2d");
    //   const video = document.createElement("video");

    //   try {
    //     const captureStream = await navigator.mediaDevices.getDisplayMedia(
    //       {
    //         video: {
    //           // displaySurface: 'browser',
    //           width: { ideal: window.innerWidth, max: 1920 },
    //           height: { ideal: window.innerHeight, max: 1080 }
    //         }
    //       }
    //     );
    //     video.srcObject = captureStream;
    //     video.autoplay = true
    //     video.addEventListener("loadedmetadata", () => {
    //       canvas.width = video.videoWidth
    //       canvas.height = video.videoHeight
    //       if (context) {
    //         context.drawImage(video, 0, 0, window.innerWidth, window.innerHeight);
    //         // const frame = canvas.toDataURL("image/png");
    //         // setScreenShot(frame)
    //         canvas.toBlob((blob) => {
    //           if (blob) {
    //             // const imgUrl = URL.createObjectURL(blob);
    //             // setScreenShot(imgUrl)
    //             setBlob(blob)
    //             captureStream.getTracks().forEach(track => track.stop());
    //           }
    //         })
    //       }
    //     })
    //     video.play()

    //   } catch (err) {
    //     console.error("Error: " + err);
    //   }
    // } else {
    //   const canvas = await html2canvas(document.body, { useCORS: true })
    //   const base64image = canvas.toDataURL('image/png')
    //   setScreenShot(base64image)
    //   canvas.toBlob(blob => {
    //     if (blob) {
    //       setBlob(blob)
    //     }
    //   })
    // }

    const imgElements =
      document.body.querySelectorAll<HTMLImageElement>('#root img')
    const imgList = Array.from(imgElements)
    const base64List = await Promise.all(
      imgList.map(img => convertURLToBase64(img.getAttribute('src') || '')),
    )
    base64List.forEach((item, i) => {
      const image = imgList[i]
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = item.dataURL
    })

    const canvas = await html2canvas(document.body, {
      allowTaint: true,
      useCORS: true,
      foreignObjectRendering: true,
      ignoreElements(element) {
        // 截图时去掉弹窗元素
        return element.classList.contains('issue-report-popover')
      },
    })
    const base64image = canvas.toDataURL('image/png')
    setScreenShot(base64image)
    canvas.toBlob(blob => {
      if (blob) {
        setBlob(blob)
      }
    })
  }

  return {
    screenShot,
    takeScreenShot,
    blob,
  }
}

function convertURLToBase64(
  url: string,
): Promise<{ dataURL: string; type: string; ext: string }> {
  return new Promise(resolve => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = url
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ;(ctx as CanvasRenderingContext2D).drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
      )
      const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
      const dataURL = canvas.toDataURL(`image/${ext}`)
      const base64 = {
        dataURL,
        type: `image/${ext}`,
        ext,
      }
      resolve(base64)
    }
  })
}
