import type { MutableRefObject } from 'react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

import { useMemoizedFn } from 'ahooks'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash-es'
import Loading from '@/pages/datastores/document-detail/Loading.tsx'
import ParagraphList from '@/pages/datastores/document-detail/paragraph/ParagraphList.tsx'
import type {
  useParagraph,
  IParagraphSearchParams,
} from '@/pages/datastores/hooks/useParagraph.ts'
import { DocumentStatus, DocumentType } from '@/apis/datastore/model.ts'
import Error from '@/pages/datastores/document-detail/Error.tsx'
import { useOSSClient } from '@/hooks/useOSSClient'
import { Button, ConfirmModal, IconFont } from '@/components'
import { useModal } from '@/hooks/useModal'
import type { DocumentItem } from '@/apis/datastore/types'
import { moreRef } from '@/utils/react'
import { wait } from '@/utils/wait'
import { PARAGRAPH_TRIGGER_SCROLL } from '@/constants/message'
import ParagraphSearch from './paragraph/ParagraphSearch'
import { QAParagraphItemEditorModal } from './paragraph/ParagraphItemEditorModal'

interface DetailProps {
  className?: string
  border?: boolean
  hidden?: boolean
  loading?: boolean
  reprocessLoading?: boolean
  scrollRef?: MutableRefObject<HTMLDivElement | null>
  paragraph: ReturnType<typeof useParagraph>
  documentInfo?: DocumentItem
  isHitTest?: boolean
  onRetry?: () => void
  setDocumentInfo?: (params: DocumentItem) => void
  onSelectParagraph?: (id: number | string) => void
  onListItemClick?: (type: string, params: Record<string, any>) => void
}

export const Detail = memo((props: DetailProps) => {
  const {
    className,
    border,
    hidden,
    loading,
    reprocessLoading,
    scrollRef,
    paragraph,
    documentInfo,
    isHitTest,
    onRetry,
    onSelectParagraph,
    onListItemClick,
  } = props
  const [searchParams] = useSearchParams()
  const paragraphId = searchParams.get('paragraphId')
  const prevFileId = useRef(documentInfo?.file_id)
  const wrapRef = useRef<HTMLDivElement | null>(null)
  const paragraphBox = useRef<HTMLDivElement | null>(null)
  const [activeId, setActiveId] = useState(
    paragraphId ? Number(paragraphId) : undefined,
  )
  const [initReady, setInitReady] = useState(!activeId)
  const loadedRef = useRef(false)

  const { ossClient } = useOSSClient({ business: 'dataset' })
  const [qaParagraphEditorModal] = useModal(QAParagraphItemEditorModal)

  const handleRetry = useMemoizedFn(() => {
    onRetry?.()
  })

  const addParagraph = useMemoizedFn(() => {
    qaParagraphEditorModal.open({
      operationType: 'add',
      file_id: documentInfo?.file_id!,
      paragraph: {
        content: '',
        enable: true,
        qa_info: {
          questions: [{ id: Date.now(), new: true, value: '', keywords: [] }],
          answers: [{ id: Date.now(), new: true, value: '', title: '答案' }],
        },
      },
      title: '新增问答',
      callback: values => {
        paragraph.addParagraphsItem(values)
        setTimeout(() => {
          paragraphBox.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }, 0)
      },
    })
  })

  const [confirmModal] = useModal(ConfirmModal, {
    okButtonProps: {
      danger: false,
    },
  })

  const handScrollToParagraph = useMemoizedFn(async (loop = 0) => {
    if (!activeId) return

    if (loop > 3) return

    const scroll = paragraphBox.current
    if (!scroll) return

    const idDom = scroll.querySelector(`[data-id="${activeId}"]`) as HTMLElement

    if (!idDom) return

    await wait(200)

    scroll.scrollTo({
      top: idDom.offsetTop - scroll.offsetTop - 20,
    })
    if (idDom.offsetTop !== scroll.scrollTop) {
      handScrollToParagraph(loop + 1)
    }
  })

  const handleInit = useMemoizedFn(async (newActiveId?: number) => {
    paragraphBox.current?.scrollTo({
      top: 0,
    })
    setInitReady(false)
    await paragraph.init(newActiveId ?? activeId)
    await wait(100)
    await handScrollToParagraph()
    setInitReady(true)
  })

  const handleActiveChange = useMemoizedFn(
    async (activeId?: number, fileId?: number) => {
      // 重复多次选中，需要有闪的动画
      setActiveId(undefined)

      if (isNil(activeId)) return
      await wait(100)
      setActiveId(activeId)

      if (!initReady) return

      const scroll = paragraphBox.current
      if (!scroll) return
      const idDom = scroll.querySelector(
        `[data-id="${activeId}"]`,
      ) as HTMLElement
      if (idDom) {
        scroll.scrollTo({
          top: idDom.offsetTop - scroll.offsetTop - 20,
        })
        // 是同一个文件，但目前段落未显示，则重新初始化
      } else if (prevFileId.current === fileId) {
        paragraph.clean()
        handleInit(activeId)
      }
    },
  )

  useEffect(() => {
    return () => {
      loadedRef.current = true
      paragraph.clean()
    }
  }, [documentInfo?.file_id])

  useEffect(() => {
    prevFileId.current = documentInfo?.file_id
    if (
      documentInfo?.file_id &&
      (documentInfo?.status === DocumentStatus.Done ||
        documentInfo?.status === DocumentStatus.Warning ||
        documentInfo?.status === DocumentStatus.AIProcess)
    ) {
      if (!paragraph.paragraphs.length || loadedRef.current) {
        loadedRef.current = false
        handleInit()
      }
    }
  }, [documentInfo?.file_id, documentInfo?.status])

  useEffect(() => {
    const handle = (event: any) => {
      handleActiveChange(
        event.paragraphId ? Number(event.paragraphId) : undefined,
        event.fileId ? Number(event.fileId) : undefined,
      )
    }

    window.addEventListener(PARAGRAPH_TRIGGER_SCROLL, handle)
    return () => {
      window.removeEventListener(PARAGRAPH_TRIGGER_SCROLL, handle)
    }
  }, [])

  const onParagraphSearch = async (data?: IParagraphSearchParams) => {
    return new Promise(resolve => {
      setTimeout(async () => {
        resolve(await paragraph?.onSearch?.(data))
      }, 100)
    })
  }

  const isLoading = loading || reprocessLoading

  const batchBtnDisabled = useMemo(() => {
    if (documentInfo) {
      return (
        documentInfo.status !== DocumentStatus.Done &&
        documentInfo?.status !== DocumentStatus.Warning
      )
    }
  }, [documentInfo?.status])

  const renderExtraNode = useMemo(() => {
    return (
      documentInfo &&
      documentInfo.file_type === DocumentType.QA &&
      (documentInfo.status === DocumentStatus.Done ||
        documentInfo.status === DocumentStatus.AIProcess ||
        documentInfo.status === DocumentStatus.Warning) &&
      !hidden && (
        <div className='min-h-36px flex ml-12px'>
          <div className='flex flex-1 justify-end'>
            <Button
              icon={<IconFont name='add' />}
              type='primary'
              onClick={addParagraph}
            >
              添加问答
            </Button>
          </div>
        </div>
      )
    )
  }, [documentInfo, addParagraph])

  const renderEditableTriggerNode = useMemo(() => {
    if (
      documentInfo &&
      documentInfo.file_type !== DocumentType.QA &&
      (documentInfo.status === DocumentStatus.Done ||
        documentInfo.status === DocumentStatus.Warning) &&
      !hidden
    ) {
      return (
        <div className='min-h-36px flex mr-30px'>
          {paragraph.isEditing ? (
            <div className='flex flex-1 justify-end'>
              <Button
                icon={<IconFont name='tuichubianji' />}
                disabled={batchBtnDisabled || paragraph.saveLoading}
                onClick={() => {
                  if (paragraph.hasEdited) {
                    confirmModal.open({
                      title: '存在修改的内容，请确认是否退出？',
                      okText: '确定',
                      cancelText: '取消',
                      onOk: async () => {
                        paragraph.handleExitEdit()
                        confirmModal.close()
                      },
                      onCancel: () => {
                        confirmModal.close()
                      },
                    })
                  } else {
                    paragraph.handleExitEdit()
                  }
                }}
              >
                退出编辑
              </Button>
              <Button
                className='ml-12px'
                type='primary'
                disabled={!paragraph.hasEdited || paragraph.saveLoading}
                onClick={async () => {
                  await paragraph.onSave()
                }}
              >
                保存
              </Button>
            </div>
          ) : (
            <div className='flex flex-1 justify-end'>
              <Button
                disabled={batchBtnDisabled || paragraph.saveLoading}
                icon={<IconFont name='bianji' />}
                type='primary'
                onClick={() => {
                  paragraph.handleStartEdit()
                }}
              >
                编辑段落
              </Button>
            </div>
          )}
        </div>
      )
    }
    return null
  }, [documentInfo, addParagraph, hidden, paragraph])

  if (!documentInfo || isLoading) {
    return (
      <Loading
        progress_stage={documentInfo?.progress_stage}
        reprocessDocumentLoading={reprocessLoading}
      />
    )
  }

  return (
    <div
      ref={wrapRef}
      data-id={documentInfo.file_id}
      className={classNames(className, 'h-full flex flex-col overflow-hidden', {
        '!hidden': hidden,
      })}
    >
      {documentInfo.status === DocumentStatus.Fail && (
        <Error onRetry={handleRetry} />
      )}
      {!isHitTest &&
        documentInfo.status !== DocumentStatus.Ing &&
        documentInfo.status !== DocumentStatus.Wait &&
        documentInfo.status !== DocumentStatus.Fail && (
          <div className='flex justify-between'>
            {!paragraph.isEditing ? (
              <ParagraphSearch
                className='flex-1'
                onSearch={onParagraphSearch}
                documentType={documentInfo.file_type}
                pageInfo={{
                  total: paragraph.total,
                  extra_info: documentInfo.extra_info,
                }}
                extraNode={renderExtraNode}
              />
            ) : (
              <div className='flex flex-1' />
            )}
            {renderEditableTriggerNode}
          </div>
        )}
      <ParagraphList
        ref={moreRef(paragraphBox, scrollRef)}
        ossClient={ossClient}
        file_type={documentInfo.file_type}
        border={border}
        {...paragraph}
        handleGenerateAISummary={id => {
          if (documentInfo.file_id) {
            // setDocumentInfo?.({
            //   ...documentInfo,
            //   status: DocumentStatus.AIProcess,
            // })
            paragraph.handleGenerateAISummary(id)
          }
        }}
        initReady={initReady || isLoading}
        readonly={paragraph.saveLoading}
        activeChunkId={initReady ? activeId : undefined}
        file_id={documentInfo.file_id}
        onSelectParagraph={onSelectParagraph}
        onListItemClick={onListItemClick}
      />
    </div>
  )
})
