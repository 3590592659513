import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Form, message, Tooltip } from 'antd'
import { assign, get, isEmpty, isNil, uniqBy } from 'lodash-es'
import { useMemoizedFn } from 'ahooks'
import type { SegmentedValue } from 'antd/es/segmented'
import type { InnerNodePanelProps } from '@/features/nodes/base'

import {
  JsonForm,
  NodeFormItem,
  type JsonFormConfig,
} from '@/features/nodes/components'

import { Button, IconFont } from '@/components'
import { useDynamicValues } from '../hooks/useDynamicValues'
import { DynamicForm } from '../base/components/DynamicForm'
import { DebugResultPanelContext } from '../base/DebugResultPanel'
import useStartFileNodeVariableKeyList from '../hooks/useStartFileNodeVariableKeyList'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { useNodeBranchRemoveConfirm } from '../hooks/useNodeBranchRemoveConfirm'
import { LLMContextType } from '../llm/const'
import type { Message } from '../hooks/useModelChange'
import { useModelChange } from '../hooks/useModelChange'
import {
  fillDynamicValues,
  isLLMNodeFormValues,
  transformLLMNodeData2LLMNodeFormData,
} from '../llm/LLMNodePanel/utils'
import { checkedJsonModel, LLMMessageStructType } from '../utils/llm'
import { LLMContextFormField } from '../llm/LLMNode/components/LLMContextFormField'
import type { IntentNodeData } from './IntentNode'
import {
  BranchTypes,
  INTENT_MAX_CONTENT_SIZE,
  IntentAlertNode,
  IntentForm,
  LLMModelSelect,
  showIntentAlertNode,
} from './IntentForm'
import type { IntentItem } from './IntentForm'
import { IntentExampleItem } from './IntentForm/IntentExampleItem'
import { IntentRecordPopover } from './IntentRecordPopover'

const MAX_COUNT = 10
function _extractIntents(inputString: string) {
  // hack写法 后端返回的结果遵循regex的模版 后端暂时无法提供json能力
  const regex = /AI识别意图:(.*?)\n 命中意图:(.*)/
  const match = inputString.match(regex)
  if (match) {
    return {
      intent: match[1],
      hitIntent: match[2],
    }
  } else {
    return null
  }
}

export function IntentPanelNode(props: InnerNodePanelProps<IntentNodeData>) {
  const {
    data,
    activatedNodeId,
    nodeElement,
    variables,
    startNodeFormItemType = [],
    onSaveChange,
  } = props
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { conditions, inputs } = data
  const {
    registerTriggerNode,
    setDebuggerPanelExtraButton,
    run,
    loading: btnLoading,
    variables: debugVariables,
    result,
    setVariables,
  } = useContext(DebugResultPanelContext)
  const [form] = Form.useForm()
  const [popoverOpt, setPopoverOpt] = useState({
    open: false,
    value: {
      query: '',
      intent: '',
    },
  })

  const formConditionsVal = Form.useWatch('conditions', form)
  const modelVal = Form.useWatch(['inputs', 'model'], form)

  const addBranch = useFlowDraftStore(s => s.addBranch)
  const removeBranch = useFlowDraftStore(s => s.removeBranch)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const contextType = useMemo(() => {
    return inputs?.context_type || LLMContextType.JSON_VARIABLE
  }, [inputs?.context_type])

  const { beforeChange, modelMessageStructType } = useModelChange(
    form,
    inputs?.channel!,
  )

  const { beforeRemove, cancel } = useNodeBranchRemoveConfirm(
    props.activatedNodeId!,
  )

  const chatValidator = (_rule: any, value: any, callback: any) => {
    const hasEmptyContent = value.some((item: Message) => {
      if (
        item.role !== 'system' &&
        modelMessageStructType !== LLMMessageStructType.NO_CONTEXT
      ) {
        return !item.content
      }
      return false
    })
    if (hasEmptyContent) {
      callback('Chat不能有空值')
    } else {
      callback()
    }
  }

  const beforeJsonFormChange = useMemoizedFn((values: unknown) => {
    if (!isLLMNodeFormValues(values)) {
      console.error('数据格式错误')
      return values
    }
    const { modelSetting, stream, messages, context_type } = values.inputs

    const inputsData = beforeChange({
      modelSetting,
      stream,
      messages: context_type === LLMContextType.MSG_LIST ? messages : undefined,
    })

    inputsData.pre_defined_system_content = undefined
    inputsData.messages = inputsData.messages?.filter(
      item => item.role !== 'system',
    )

    if (!checkedJsonModel(inputsData.model)) {
      inputsData.plugin = {}
    }
    const newInput = { ...values.inputs, ...inputsData }
    delete (newInput as any).modelSetting

    const newValues = {
      ...values,
      inputs: newInput,
    }

    return newValues
  })

  const options = useMemo(() => {
    const opt = (formConditionsVal as IntentItem[])
      ?.filter(item => !!item.statement)
      ?.map(item => {
        return {
          label: item.statement,
          value: item.statement,
        }
      })
      .concat([
        {
          label: '其他意图',
          value: '其他意图',
        },
      ])
    return uniqBy(opt, 'value')
  }, [formConditionsVal])

  const onTypeChange = useMemoizedFn((t: SegmentedValue) => {
    const values = form?.getFieldsValue()
    values.inputs.context_type = t as LLMContextType
    const newValues = beforeJsonFormChange(values)
    props?.onSaveChange(newValues as any)
  })

  useEffect(() => {
    if (form) {
      const fieldValue = form.getFieldValue('conditions')
      // 当删除或增减的时候立即更新表单值
      if (fieldValue?.length !== props.data.conditions.length) {
        form.setFieldValue('conditions', props.data.conditions)
      }
    }
  }, [props.data.conditions, form, props])

  const onAdd = useCallback(async () => {
    const insertIndex = conditions.findIndex(
      item => item.type === BranchTypes.ELSE,
    )
    if (insertIndex > -1) {
      const res = await addBranch({
        id: props.activatedNodeId!,
        conditionIndex: insertIndex,
        condition: {
          type: BranchTypes.ELSEIF,
          statement: '',
        },
      })
      if (res) {
        const { nodes } = res
        const currentNode = nodes.find(
          (item: { id: string }) => item.id === props.activatedNodeId,
        )
        onSaveChange?.({ conditions: currentNode?.data?.conditions })
      }
    }
  }, [conditions, props.activatedNodeId])

  const onDelete = useCallback(
    async (id: string) => {
      const res = await removeBranch({
        id: props.activatedNodeId!,
        conditionId: id,
      })
      if (res) {
        const { nodes } = res
        const currentNode = nodes.find(
          (item: { id: string }) => item.id === props.activatedNodeId,
        )
        onSaveChange?.({ conditions: currentNode?.data?.conditions })
      }
    },
    [props.activatedNodeId, conditions],
  )

  const handleAddIntentExample = (item: { query: string; intent: string }) => {
    const intentExample = form.getFieldValue(['inputs', 'intent_example']) || []
    if (intentExample?.length >= MAX_COUNT) {
      message.error('添加样本数据失败，超过最大限制')
    } else {
      const newIntentExample = [...intentExample, item]
      onSaveChange({
        inputs: { ...inputs, intent_example: newIntentExample },
      })
      message.success('添加样本数据成功')
    }
  }

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType,
    variables,
  )

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: form?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (values: InnerNodePanelProps<any>['data']) => {
      const { conditions = [], inputs = {} } = values
      const { background, question } = inputs
      return [
        {
          content: background || '',
        },
        {
          content: question || '',
        },
        {
          content: get(values, 'inputs.pre_defined_messages'),
        },
        { content: get(values, 'inputs.system_content') },
        ...(get(values, 'inputs.messages') || []),
        ...conditions.map((item: { statement: any }) => {
          return {
            content: item.statement || '',
          }
        }),
      ]
    },
  })

  const { usedKeyListByNodeId } = val

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await form?.validateFields()
    run(data)
  }

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })

    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const showAlertInfo = useMemo(() => {
    return showIntentAlertNode(formConditionsVal?.length)
  }, [formConditionsVal])

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        required: true,
        name: ['inputs'],
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        label: '分类模型',
        render: () => <LLMModelSelect nodeElement={nodeElement} />,
      },
      {
        label: '用户问题',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        name: ['inputs', 'question'],
        required: true,
        type: 'TextEditor',
        rules: [{ required: true, message: '用户问题不能为空' }],
        componentProps: {
          rows: 1,
          maxHeight: 20,
          minHeight: 20,
          placeholder: '输入需要识别意图的内容或者问题',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
      {
        label: '意图分类',
        required: true,
        className: '!mb-0',
        render: () => {
          return (
            <div className='relative'>
              <NodeFormItem
                name={'conditions'}
                required
                rules={[
                  {
                    required: true,
                    validator: (_, value: IntentItem[]) => {
                      const hasEmptyStatement = value
                        .filter(v => v.type !== BranchTypes.ELSE)
                        .some(item => item.statement === '')

                      const hasLongStatement = value
                        .filter(v => v.type !== BranchTypes.ELSE)
                        .some(
                          item =>
                            item.statement?.length >= INTENT_MAX_CONTENT_SIZE,
                        )

                      if (hasLongStatement) {
                        return Promise.reject(
                          new Error(
                            `请检查意图分类，不能超过${INTENT_MAX_CONTENT_SIZE}字`,
                          ),
                        )
                      }

                      if (hasEmptyStatement) {
                        return Promise.reject(
                          new Error('请检查意图分类，不能为空'),
                        )
                      }

                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <IntentForm
                  variables={props.variables}
                  variableTipsContainer={props.nodeElement}
                  onAdd={onAdd}
                  onDelete={onDelete}
                  onBeforeRemove={beforeRemove}
                  onCancel={cancel}
                  showHandle={false}
                  onChange={values => {
                    onSaveChange?.({ conditions: values })
                  }}
                  disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                />
              </NodeFormItem>
              <NodeFormItem
                validateTrigger={['onSubmit']}
                className='absolute right-0 top-[-32px]'
                name={['inputs', 'intent_example']}
              >
                <IntentExampleItem options={options} />
              </NodeFormItem>
            </div>
          )
        },
      },
      {
        hidden: !showAlertInfo,
        render: () => <IntentAlertNode />,
      },
      {
        label: '背景知识',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        name: ['inputs', 'background'],
        type: 'TextEditor',
        tooltip:
          '当分类不准时，输入分类的规则说明和背景知识，帮助AI知道如何更准确地分类',
        componentProps: {
          placeholder:
            '输入分类说明或背景知识，如：打招呼：当用户表示问候、问好或寒暄时使用此意图（例如：“你好”、“2333”）。',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
      {
        label: '上下文',
        rules:
          contextType === LLMContextType.JSON_VARIABLE
            ? []
            : [
                {
                  validator: chatValidator,
                },
              ],
        name:
          contextType === LLMContextType.JSON_VARIABLE
            ? ['inputs', 'pre_defined_messages']
            : ['inputs', 'messages'],
        validateTrigger: ['onSubmit'],
        tooltip: '可创建或读取上下文故事线，让AI更准确的理解意图并处理',
        render: () => (
          <LLMContextFormField
            contextType={contextType}
            onTypeChange={onTypeChange}
            modelMessageStructType={modelMessageStructType}
            model={modelVal}
            completions={variables}
            variableTipsContainer={nodeElement}
            readOnly={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          />
        ),
      },
    ]
  }, [
    props.nodeElement,
    props.variables,
    onAdd,
    onDelete,
    lockStatus,
    showAlertInfo,
    contextType,
    options,
    onTypeChange,
    modelMessageStructType,
  ])

  const extraPopover = useMemo(() => {
    return (
      <IntentRecordPopover
        options={options}
        open={popoverOpt.open}
        value={popoverOpt.value}
        onSave={values => {
          setPopoverOpt({ value: values, open: false })
          handleAddIntentExample(values)
        }}
        onCancel={() => {
          setPopoverOpt({ ...popoverOpt, open: false })
        }}
      >
        <Tooltip title='录入用户问题和意图数据，训练模型，提高识别准确率'>
          <Button
            onClick={() => {
              if (popoverOpt.open) {
                return
              }
              const question = form.getFieldValue(['inputs', 'question'])
              const res = _extractIntents(result?.output || '')
              const realQuestion = fillDynamicValues(question, debugVariables)
              setPopoverOpt({
                value: { query: realQuestion, intent: res?.hitIntent || '' },
                open: true,
              })
            }}
            className='flex-center h-32px! text-12px font-500 b-op-80'
            icon={
              <IconFont name='luruzhili' className='text-16px text-font_1' />
            }
          >
            录入样本数据
          </Button>
        </Tooltip>
      </IntentRecordPopover>
    )
  }, [options, popoverOpt, result, form, data, debugVariables])

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables, form])

  useEffect(() => {
    setDebuggerPanelExtraButton(extraPopover)
  }, [debugVariables, form, list, extraPopover, onSaveChange])

  return (
    <div ref={wrapperRef}>
      <div className='p-16px' onWheel={e => e.stopPropagation()}>
        <JsonForm
          list={list}
          form={form}
          beforeChange={beforeJsonFormChange}
          initialValues={transformLLMNodeData2LLMNodeFormData(data)}
          validateTrigger={['onSubmit']}
        ></JsonForm>
        <div className='mt-16px'>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
      </div>
    </div>
  )
}
