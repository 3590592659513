import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { PlusOutlined } from '@ant-design/icons'
import { useRequest } from 'ahooks'
import { useAuth } from '@bty/react-auth'
import { useLayoutStore, useWorkspaceStore } from '@/store'
import { useLayoutMenus } from '../hooks/useLayoutMenus'
import { useRouterPathMemory } from '../hooks/useRouterPathMemory'
import { useChatStore } from '@/store/chat'
import { KEEP_ROUTER_PATHS } from '../contants'
import { robotStorage } from '@/utils/storage'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { useModal } from '@/hooks/useModal'
import { AgentCreateModal } from '@/pages/agent/list/AgentCreateModal'
import type { ApplicationBodyType } from '@/apis/application'
import { getApplicationsList } from '@/apis/application'
import { Button } from '@/components'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { useUnreadMessageListener } from '@/pages/home/hooks/useUnreadMessageListener.ts'
import { MsgCountIcon } from './MsgCountIcon'
import { Logo } from './Logo'
import { SidebarMenuItem } from './SidebarMenuItem'
import { CollapseButton } from './CollapseButton'
import { WorkspaceList } from './WorkspaceList'
import { PointsPanel } from './PointsPanel'
import { User } from './User'
import { HelpPopover } from './HelpPopover'
import { PushNotification } from './PushNotification'

const ScrollBox = styled.div`
  .scrollbar-track.scrollbar-track-x,
  .scrollbar-track.scrollbar-track-x .scrollbar-thumb {
    height: 4px;
  }

  .scrollbar-track.scrollbar-track-y,
  .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 4px;
  }
`

export function LayoutSideBar() {
  const { sideBarCollapsed: collapsed, toggleSideBarCollapsed } =
    useLayoutStore()
  const location = useLocation()
  const pathname = useMemo(() => {
    if (location.state?.fromRoute === 'templates') {
      return 'explore'
    }
    return location.pathname.split('/')[1] || 'application'
  }, [location])
  const navigate = useNavigate()
  const { currentWorkspaceId } = useWorkspaceStore()
  const { topMenus, bottomMenus } = useLayoutMenus(pathname)
  const { navigateWithMemory } = useRouterPathMemory()

  const { access } = useAuth()
  const [data, setData] = useState<ApplicationBodyType[]>([])
  const { runAsync } = useRequest(
    () => getApplicationsList(currentWorkspaceId, { applicationType: 'AGENT' }),
    {
      ready: !!currentWorkspaceId,
      refreshDeps: [currentWorkspaceId],
      onSuccess: (e: ApplicationBodyType[]) => setData(e),
    },
  )
  const [createAgentModal] = useModal(AgentCreateModal)
  const onAgentCreate = () => {
    createAgentModal.open({
      access,
      agentList: data,
      onCancel: runAsync,
    })
  }

  // 监听chat未读消息数
  useUnreadMessageListener()
  const { unreadMessageCount } = useChatStore()

  const renderMenuExtraIcon = useCallback(
    (path: string) =>
      path === 'chatbot' && unreadMessageCount > 0 ? (
        <MsgCountIcon
          count={unreadMessageCount}
          style={{
            borderColor: path === pathname ? '#fff' : '#eceef5',
          }}
        />
      ) : null,
    [unreadMessageCount, pathname],
  )

  const checkSettingPathReg = useMemo(
    () => new RegExp(`^/workspace/${currentWorkspaceId}/settings`),
    [currentWorkspaceId],
  )

  const handleMenuClick = useCallback(
    (path: string) => {
      if (path === 'workspace') {
        navigate(`/workspace/${currentWorkspaceId}/settings/overview`)
      } else if (path === 'statistics') {
        navigate('/statistics/usageStatistics')
      } else if (KEEP_ROUTER_PATHS.includes(path)) {
        navigateWithMemory(path)
      } else if (path === 'chatbot') {
        navigate(`/${path}/${robotStorage.get()}`)
      } else {
        navigate(`/${path}`)
      }
    },
    [checkSettingPathReg, navigateWithMemory, currentWorkspaceId],
  )

  const { scrollRef } = useScrollBar({ alwaysShowTracks: true })

  return (
    <div
      className={classNames(
        'relative flex flex-col pt-20px pb-20 bg-transparent transition-width',
        {
          'w-248px': !collapsed,
          'w-72px': collapsed,
        },
      )}
    >
      <CollapseButton
        collapsed={collapsed}
        className='absolute top-25px right-0'
        style={{
          backgroundColor: collapsed ? '#fff' : '#edeef5',
          transform: `translateX(${collapsed ? '50%' : '-50%'})`,
        }}
        onToggle={toggleSideBarCollapsed}
      />
      <div className='px-12 w-full overflow-hidden'>
        <Logo collapsed={collapsed} />
        <div className='px-6px'>
          <LimitedAccess limitedType='agent'>
            <Button
              className='mt-24px h-36px'
              type='primary'
              block
              icon={<PlusOutlined />}
              onClick={onAgentCreate}
            >
              {collapsed ? '' : '新建Agent'}
            </Button>
          </LimitedAccess>
        </div>
        <div className='mt-12px flex flex-col gap-4px pb-12px b-b b-#626999 b-op-8'>
          {topMenus.map(({ path, label, icon }) => (
            <SidebarMenuItem
              key={path}
              collapsed={collapsed}
              active={pathname === path}
              path={path}
              label={label}
              icon={icon}
              extra={renderMenuExtraIcon(path)}
              onClick={handleMenuClick}
            />
          ))}
        </div>
        <WorkspaceList collapsed={collapsed} />
      </div>
      <ScrollBox
        ref={scrollRef}
        className='flex-1 overflow-y-auto overflow-x-hidden'
      >
        <div className='flex px-12 overflow-hidden flex-col gap-4px'>
          {bottomMenus.map(({ path, label, icon }) => (
            <SidebarMenuItem
              key={path}
              collapsed={collapsed}
              active={pathname === path}
              path={path}
              label={label}
              icon={icon}
              onClick={handleMenuClick}
            />
          ))}
        </div>
      </ScrollBox>
      <div className='px-12'>
        <PrivateDeployGuard>
          <PointsPanel
            collapsed={collapsed}
            className={`mt-16px ${collapsed ? 'mb-12px' : 'mb-24px'}`}
          />
        </PrivateDeployGuard>
        <div
          className={classNames('flex items-center py-4px', {
            'flex-col gap-12px': collapsed,
          })}
        >
          <User collapsed={collapsed} />
          <PushNotification className={collapsed ? '' : 'ml-auto'} />
          <PrivateDeployGuard>
            <HelpPopover />
          </PrivateDeployGuard>
        </div>
      </div>
    </div>
  )
}
