import React, { useState, useRef, useCallback, useMemo } from 'react'
import { List, Select, Spin } from 'antd'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash-es'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { InfiniteScroll } from '@/components/InfiniteScrollComponent'
import { getLLMNodePromptRecordList } from '@/apis/prompt'
import type { IPromptListRequest } from '@/apis/prompt'

const FORMAT_DATE = 'YYYY年MM月DD日 HH:mm:ss'

const ListWrapper = styled.div`
  height: 300px;
  overflow: auto;
  padding: 0;

  .ant-list-items {
    width: 100%;
    overflow: hidden;
  }

  .ant-list-item {
    width: 100%;
    box-sizing: border-box;
    border: 0px;
    padding: 0px;
  }
`

const SelectWrapper = styled(Select)`
  width: 192px;
  height: 32px;
  overflow: hidden;
  margin-left: 12px;
  .ant-select-selector {
    height: 32px !important;
    background: rgba(98, 105, 153, 0.06) !important;
  }
  .ant-select-selection-item {
    font-size: 12px !important;
    line-height: 32px;
  }
`

const ListItemWrapper = styled(List.Item)`
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  padding: 0px
  border-radius: 6px;
  :hover{
    background: rgba(98, 105, 153, 0.0784);
  }
  font-size: 12px !important;
`

interface IHistorySelectProps {
  flowId: string
  activatedNodeId: string
  onSelectChange: (params: any) => void
  selectedInfo: Record<string, any>
  activePromptItemHistoryId: string | undefined
}
export const HistorySelect: React.FC<IHistorySelectProps> = props => {
  const {
    flowId,
    activatedNodeId,
    onSelectChange,
    selectedInfo,
    activePromptItemHistoryId,
  } = props

  const [listInfo, setList] = useState<any>({ list: [], count: 1 })

  const [open, setOpen] = useState(false)

  const hasMore = useMemo(() => {
    return listInfo.count > listInfo.list.length
  }, [listInfo])

  const opts = useRef<IPromptListRequest>({
    page_number: 0,
    page_size: 10,
    flow_id: flowId,
    node_id: activatedNodeId,
  })

  const fetchApi = async (params: IPromptListRequest) => {
    const res = await getLLMNodePromptRecordList(params)
    const newList = uniqBy([...listInfo.list, ...res.list], 'history_id')
    setList({ list: newList, count: res.total })
  }
  const fetchListData = async () => {
    opts.current = {
      ...opts.current,
      page_number: opts.current.page_number + 1,
    }
    await fetchApi(opts.current)
  }

  const list = useMemo(() => {
    const newList = listInfo.list.reduce((groups: any, item: any) => {
      // 使用dayjs进行日期转换
      item.date = dayjs(item.created_time).valueOf()
      if (activePromptItemHistoryId !== item.history_id) {
        groups.push(item)
      }

      return groups
    }, [])
    return newList
  }, [listInfo.list])

  const [loading, setLoading] = useState(false)

  const loadMoreData = useCallback(async () => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      await fetchListData()
    } finally {
      setLoading(false)
    }
  }, [listInfo])

  const targetRef = useRef<any>(null)
  const value = useMemo(() => {
    return selectedInfo.isLocal
      ? '当前'
      : dayjs(selectedInfo.date).format(FORMAT_DATE)
  }, [selectedInfo])
  return (
    <SelectWrapper
      placeholder='历史记录'
      value={value}
      open={open}
      onDropdownVisibleChange={visible => setOpen(visible)}
      dropdownRender={() => (
        <ListWrapper ref={targetRef}>
          <div
            id='scrollableDiv_ac'
            style={{
              height: 200,
            }}
          >
            {targetRef.current && (
              <InfiniteScroll
                load={loadMoreData}
                targetRoot={targetRef?.current}
                hasMore={hasMore}
                loader={
                  <div className='w-100% flex items-center flex-center'>
                    <Spin />
                  </div>
                }
              >
                <div>
                  {!loading && !list.length ? (
                    '暂无记录'
                  ) : (
                    <List
                      dataSource={list}
                      locale={{
                        emptyText: loading ? <div /> : <div />,
                      }}
                      renderItem={(item: any) => {
                        return (
                          <ListItemWrapper
                            key={item.date}
                            onClick={() => {
                              setOpen(false)
                              onSelectChange(item)
                            }}
                          >
                            <div
                              className={classNames(
                                'flex px-10px py-10px items-center color-#17171D w-100% cursor-pointer hover:bg-[rgba(98,105,153,0.0784)]',
                                {
                                  'important:bg-[rgba(127,105,255,0.08)]':
                                    selectedInfo.history_id === item.history_id,
                                },
                              )}
                            >
                              <span className='text-12px'>
                                {item.isLocal
                                  ? '当前'
                                  : dayjs(item.date).format(FORMAT_DATE) ||
                                    '未知时间'}
                              </span>
                            </div>
                          </ListItemWrapper>
                        )
                      }}
                    />
                  )}
                </div>
              </InfiniteScroll>
            )}
          </div>
        </ListWrapper>
      )}
    />
  )
}
