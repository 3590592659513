import type { NodeType } from '@/features/nodes/base'
import { IconFont } from '@/components'
import type { Version } from '@/features/pay'
import { NodeOptionItem } from './NodeOptionItem'

interface NodeItem {
  type: NodeType
  name: string
  icon: string
  iconColor: string
  description: string
  allowVersion?: Version[]
}

interface GroupItem {
  groupName: string
  data: NodeItem[]
}

interface BaseNodeListProps {
  data: GroupItem[]
  // activeKey: NodeType
  onSelect: (type: NodeType) => void
}

export function BaseNodeList(props: BaseNodeListProps) {
  const { data, onSelect } = props

  return (
    data.length > 0 && (
      <>
        <div className='p-8px line-height-16px font-500 c-#17171d'>
          基础能力
        </div>
        {data.map(({ groupName, data: nodes }) => (
          <div key={groupName}>
            <div className='py-4px px-8px line-height-16px text-12px font-500 text-#8d8d99'>
              {groupName}
            </div>
            {nodes.map(
              ({ type, icon, iconColor, name, description, allowVersion }) => (
                <NodeOptionItem
                  key={type}
                  id={type}
                  icon={
                    <div
                      className='flex-center mr-8px w-24px h-24px b-rd-4px'
                      style={{ backgroundColor: iconColor }}
                    >
                      <IconFont name={icon} />
                    </div>
                  }
                  name={name}
                  description={description}
                  onClick={type => {
                    onSelect(type)
                  }}
                  allowVersion={allowVersion}
                />
              ),
            )}
          </div>
        ))}
      </>
    )
  )
}
