import type { LoggerTab } from './type'

export const ALL_TABS: { value: LoggerTab; label: string }[] = [
  {
    value: 'detail',
    label: '详情',
  },
  {
    value: 'input',
    label: '输入',
  },
  {
    value: 'output',
    label: '输出',
  },
  {
    value: 'log',
    label: '日志',
  },
]
