import 'reactflow/dist/base.css'

import { memo, useEffect, useMemo } from 'react'
import { Background, ReactFlow } from 'reactflow'
import { edgeTypes } from '@/features/flow/edges'
import { flowAutoLayout } from '@/features/flow/layout/autolayout'
import { initFlowNodes } from '@/features/nodes'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'

const flowNodes = initFlowNodes()
const nodeTypes = flowNodes.reduce<Record<string, any>>((pre, cur) => {
  pre[cur.meta.type] = cur.node
  return pre
}, {})

export const FlowPreview = memo(() => {
  const {
    nodes,
    edges,

    onConnect,
    setFlowLock,
  } = useFlowDraftStore()

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
    return flowAutoLayout(nodes, edges)
  }, [nodes, edges])

  useEffect(() => {
    setFlowLock(FLOW_DRAFT_LOCK_STATUS.LOCK_PREVIEW)
  }, [])

  return (
    <div
      className='w-full h-full bg-#f4f4f6'
      style={{ transform: 'translate3d(0,0,0)' }}
    >
      <ReactFlow
        nodes={layoutedNodes}
        edges={layoutedEdges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        elementsSelectable={false}
        nodesConnectable={false}
        onConnect={onConnect}
        minZoom={0.05}
        zoomOnPinch={true}
        zoomOnDoubleClick={false}
        zoomOnScroll={false}
        panOnScroll={true}
        fitView
        proOptions={{
          hideAttribution: true,
        }}
      >
        <Background gap={60} size={4} />
      </ReactFlow>
    </div>
  )
})
