import { Modal } from '@/components'
import { PasswordReserForm } from './PasswordResetForm'

interface PasswordResetModalProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export function PasswordResetModal(props: PasswordResetModalProps) {
  const { open, onClose, onSuccess } = props

  return (
    <Modal
      title='重置密码'
      width={480}
      bodyStyle={{
        padding: 40,
      }}
      open={open}
      footer={null}
      onCancel={onClose}
    >
      <PasswordReserForm onSuccess={onSuccess} />
    </Modal>
  )
}
