import type { Node, Edge } from 'reactflow'
import { SUB_FLOW_STATUS, type NodeType } from '../../nodes/base'
import { forkTemplate } from '@/apis/flow'
import type { InsertConfigData } from './BaseNodeOperation'
import { SingleNodeOperation } from './SingleNodeOperation'

export class TemplateNodeOperation extends SingleNodeOperation {
  async insert(options: {
    type: NodeType
    data?: any
    source: string
    target: string
    sourceHandleId?: string | null
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }): Promise<InsertConfigData> {
    let newData: any = null
    if (options.data?.source_id) {
      const flow = await forkTemplate(options.data?.source_id)
      newData = {
        ...options.data,
        flow_id: flow.flowId,
        flow_status: SUB_FLOW_STATUS.ACTIVE,
      }
      // message.success(
      //   `【${options.data.displayName}】已添加到【工作流】-⧼工作流模版⧽分组中`,
      // )
    }
    return super.insert({ ...options, data: newData })
  }
}
