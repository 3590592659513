import { IconFont } from '@/components'

export function getIcon(type: string) {
  switch (type) {
    case 'input':
      return <IconFont name='newshanxingwenben-moren' />
    case 'textarea':
      return <IconFont name='newwenbenyu-moren' />
    case 'select':
      return <IconFont name='newshanxuanxialakuang-moren' />
    case 'multiSelect':
      return <IconFont name='newduoxuanxialakuang-moren' />
    case 'json':
      return <IconFont name='newjson-moren' />
    case 'file':
      return <IconFont name='newwenjian-moren' />
    case 'output':
      return <IconFont name='yunxingjieluo1x' />
    case 'similarity':
      return <IconFont name='jieluobiaozhu1x' />
    default:
      return <IconFont name='newshanxingwenben-moren' />
  }
}

export const prevIcon = (
  <div className='w-full h-full flex flex-center'>
    <IconFont
      name='right-arrow'
      className='text-12px text-[rgba(141,141,153,0.4)]'
    />
  </div>
)

export const nextIcon = (
  <div className='w-full h-full flex flex-center'>
    <IconFont
      name='left-arrow'
      className='text-12px text-[rgba(141,141,153,0.4)]'
    />
  </div>
)

export const selectIcon = (
  <IconFont name='arrow' className='text-12px color-#626999' />
)
