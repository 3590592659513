import { useBoolean, useRequest } from 'ahooks'
import { Button, Form, message } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { getUserProfile, saveOrUpdateUserProfile } from '@/apis/user.ts'
import {
  NO_FREE_WORKSPACE_CODE,
  createWorkspace,
  roleSelectOptions,
  roles,
} from '@/apis/workspace'
import { FormItem, Input, Select, TextArea } from '@/components'
import { useUserStore, useVersionStore, useWorkspaceStore } from '@/store'

export interface CreateWorkspaceProps {
  inModal?: boolean
  onSuccess?: (res?: any) => void
}

interface FieldValues {
  name: string
  enterpriseName: string
  role?: string
  otherRole: string
  demand: string
}
export function CreateWorkspace(props: CreateWorkspaceProps) {
  const { inModal = true } = props
  const user = useUserStore(state => state.user)
  const [fieldValues, setFiledValues] = useState<FieldValues>({
    name: '',
    enterpriseName: '',
    otherRole: '',
    demand: '',
  })

  const { fetchVersionByWorkspaceId } = useVersionStore()
  const { fetchWorkspaceList, workspaceList, setCurrentWorkspace } =
    useWorkspaceStore()
  const fetchUser = useUserStore(state => state.fetchUser)

  const [form] = Form.useForm<FieldValues>()

  const [loading, { toggle: toggleLoading, setFalse: hideLoading }] =
    useBoolean(false)

  const { runAsync: createWorkspaceApi } = useRequest(createWorkspace, {
    manual: true,
  })
  const { runAsync: saveOrUpdateUserProfileApi } = useRequest(
    saveOrUpdateUserProfile,
    { manual: true },
  )
  const { data: userProfile, run: getUserProfileApi } = useRequest(
    getUserProfile,
    { manual: true },
  )

  useEffect(() => {
    fetchUser().then(res => {
      if (res) {
        getUserProfileApi(res.userId)
      }
    })
  }, [])

  useEffect(() => {
    // 用户没有工作空间时，自动填入空间名
    if (!workspaceList.length && user) {
      const newValues = {
        ...fieldValues,
        name: `${user.username}的工作空间`,
      }
      setFiledValues(newValues)
      form.setFieldsValue(newValues)
    }
  }, [workspaceList, user])
  useEffect(() => {
    if (userProfile && userProfile.profileJSON) {
      const data = userProfile.profileJSON
      const newValues = {
        ...fieldValues,
        role: data.role
          ? roles.includes(data.role)
            ? data.role
            : '其他'
          : undefined,
        otherRole: data.role
          ? roles.includes(data.role)
            ? ''
            : data.role
          : '',
        enterpriseName: data.enterpriseName ?? '',
      }
      setFiledValues(newValues)
      form.setFieldsValue(newValues)
    }
  }, [userProfile])

  const handleCreateWorkspace = useCallback(async () => {
    const values = await form.validateFields()
    toggleLoading()

    if (user) {
      await saveOrUpdateUserProfileApi(user.userId, {
        role: values.role === '其他' ? values.otherRole : values.role,
        enterpriseName: values.enterpriseName,
      })
    } else {
      // 没获取到用户
      message.warning('系统开小差了，请刷新重试')
    }
    try {
      const res = await createWorkspaceApi({
        name: values.name,
        extension: { demand: values.demand, source: 'AI' },
      })

      if (res?.id) {
        setCurrentWorkspace(res.id)
        await Promise.all([fetchWorkspaceList(), fetchUser()])
        await fetchVersionByWorkspaceId(res.id)
      }
      hideLoading()
      res && props?.onSuccess?.(res)
    } catch (err: any) {
      hideLoading()
      if (
        err?.response?.data?.responseMeta?.error?.code ===
        NO_FREE_WORKSPACE_CODE
      ) {
        message.warning(
          '免费空间额度不足，请升级当前已有的免费空间为付费空间后再创建',
        )
      } else {
        message.warning(
          err?.response?.data?.responseMeta?.error?.message || err.message,
        )
      }
    }
  }, [fieldValues, toggleLoading])

  const onValuesChange = useCallback((_: any, s: any) => {
    setFiledValues(s)
  }, [])

  return (
    <div
      className='relative overflow-hidden'
      style={{
        width: inModal ? 600 : 400,
        padding: inModal ? '60px 48px' : 0,
      }}
    >
      {inModal && (
        <>
          <div
            className='w-208px z-1 h-132px absolute right-46px top--25px bg-#A200FF bg-op-20'
            style={{
              filter: 'blur(70px)',
            }}
          />
          <div
            className='w-208px z-2 h-132px absolute left-43px top-6px bg-#00FFDD bg-op-20 opacity-60'
            style={{
              filter: 'blur(50px)',
            }}
          />
        </>
      )}
      <h3 className='text-font font-800 text-24px'>
        {user?.username ? `${user?.username}，` : ''}欢迎创建工作空间🌟
      </h3>
      <div className='mt-34'>
        <Form
          form={form}
          layout='vertical'
          initialValues={fieldValues}
          onValuesChange={onValuesChange}
        >
          <FormItem
            label='工作空间名称'
            name='name'
            rules={[{ required: true, message: '请输入工作空间名称' }]}
          >
            <Input
              type='text'
              placeholder='请输入空间名称'
              showCount
              size='large'
              maxLength={50}
            ></Input>
          </FormItem>
          <FormItem
            label='企业名称'
            name='enterpriseName'
            rules={[{ message: '请输入企业名称' }]}
          >
            <Input placeholder='请输入企业名称' size='large'></Input>
          </FormItem>

          <FormItem
            label='角色'
            name='role'
            rules={[{ required: true, message: '请选择企业身份' }]}
          >
            <Select
              size='large'
              options={roleSelectOptions}
              placeholder='请选择企业身份'
            ></Select>
          </FormItem>

          {fieldValues.role === '其他' && (
            <FormItem
              name='otherRole'
              rules={[{ required: true, message: '请填写企业身份' }]}
            >
              <Input
                size='large'
                placeholder='请输入企业身份'
                maxLength={10}
                value={fieldValues.otherRole}
                onChange={e => {
                  form.setFieldValue('otherRole', e.target.value)
                  setFiledValues({ ...fieldValues, otherRole: e.target.value })
                  form.validateFields(['otherRole'])
                }}
              ></Input>
            </FormItem>
          )}

          <FormItem
            label='需求描述'
            name='demand'
            rules={[{ message: '请输入你的需求' }]}
          >
            <TextArea
              showCount
              maxLength={500}
              placeholder='请输入需求描述'
              rows={3}
              className='important:resize-none'
            />
          </FormItem>
        </Form>
      </div>
      <Button
        size='large'
        className='mt-30'
        type='primary'
        block
        loading={loading}
        onClick={handleCreateWorkspace}
      >
        创建工作空间
      </Button>
    </div>
  )
}
