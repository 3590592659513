import { useEffect, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import { wait } from '@/utils/wait'

const MAX_LOOP = 5

export function useGetConversationId(get: () => string) {
  const [conversationId, setConversationId] = useState<string>('')

  const loopGet = useMemoizedFn(async (count = 0) => {
    if (count > MAX_LOOP) return
    if (conversationId) return
    const newId = get()
    if (newId) {
      setConversationId(newId)
      return
    }
    await wait(500)
    loopGet(count + 1)
  })

  useEffect(() => {
    loopGet()
  }, [])

  return get() ?? conversationId
}
