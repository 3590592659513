import classNames from 'classnames'
import { IconFont } from '@/components'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'

interface CollapseButtonProps {
  shadow?: boolean
  icon: string
  onClick: () => void
}

export function CollapseButton(props: CollapseButtonProps) {
  const { shadow = true, icon, onClick } = props
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  return (
    <div
      className={classNames(
        'flex-center w-32px h-32px b-rd-6px bg-#fff cursor-pointer',
        {
          'shadow-[0px_0px_6px_0px_rgba(0,0,0,0.1)]': shadow,
          'cursor-not-allowed': lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
          'hover:bg-#f3f3f7': lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        },
      )}
      onClick={onClick}
    >
      <IconFont
        name={icon}
        className={classNames({
          'text-#8d8d9966': lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        })}
      />
    </div>
  )
}
