import {
  AGENT_KEEP_ROUTER,
  APPLICATION_KEEP_ROUTER,
  DATASTORES_KEEP_ROUTER,
} from '@/constants/storage'
import { isPrivateVersion } from '@/constants/common.ts'
import { BetaTag } from '@/components'
import { FLOW_DISPLAYNAME, PLUGIN_DISPLAYNAME } from '@/constants/common'

export interface SidebarMenuItemData {
  path: string
  title: string
  icon: string
  iconColor: string
  group: 'top' | 'bottom'
}

export const KEEP_ROUTER_PATHS = ['datastores', 'agent', 'application']

export const storageRouterMap: Record<string, string> = {
  agent: AGENT_KEEP_ROUTER,
  application: APPLICATION_KEEP_ROUTER,
  datastores: DATASTORES_KEEP_ROUTER,
}
export const SidebarMenuData: SidebarMenuItemData[] = [
  {
    path: 'explore',
    title: '发现',
    icon: 'explore',
    iconColor: '#3ba6ff',
    group: 'top',
  },
  {
    path: 'chatbot',
    title: '对话',
    icon: 'chat',
    iconColor: '#7b61ff',
    group: 'top',
  },
  {
    path: 'agent',
    title: 'Agent',
    icon: 'ai',
    iconColor: '#f76bcd',
    group: 'bottom',
  },
  {
    path: 'plugin',
    title: PLUGIN_DISPLAYNAME,
    icon: 'tool',
    iconColor: '#7b61ff',
    group: 'bottom',
  },
  {
    path: 'application',
    title: FLOW_DISPLAYNAME,
    icon: 'flow',
    iconColor: '#5adbc3',
    group: 'bottom',
  },
  {
    path: 'datastores',
    title: '知识库',
    icon: 'data',
    iconColor: '#55adff',
    group: 'bottom',
  },
  {
    path: 'db',
    title: (
      <span className='gap-4 inline-flex flex-wrap justify-center items-center'>
        <span>数据库</span>
        <BetaTag />
      </span>
    ),
    icon: 'icon-logo-5',
    iconColor: '#FF6161',
    group: 'bottom',
  },
  // 侧边栏去掉 Agent Go 入口
  // {
  //   path: 'agentgo',
  //   title: 'Agent Go',
  //   icon: 'agentgo',
  //   iconColor: '#ffa200',
  //   group: 'bottom',
  // },
  {
    path: 'statistics',
    title: '数据统计',
    icon: 'shujutongji',
    iconColor: '#FFA200',
    group: 'bottom',
  },
  {
    path: 'workspace',
    title: '设置',
    icon: 'kongjianshezhi',
    iconColor: '#2f75ff',
    group: 'bottom',
  },
].filter(item =>
  isPrivateVersion ? item.path !== 'agentgo' : true,
) as SidebarMenuItemData[]
