import type { ReactNode } from 'react'
import { memo, useState } from 'react'
import dayjs from 'dayjs'
import { useMemoizedFn } from 'ahooks'
import { BaseModal } from '../BaseModal'
import type { FlowStatus } from '@/apis/flow'
import { RunTag } from './components/RunTag'
import type { LoggerProps } from './components/Content'
import { LoggerContent } from './components/Content'

export interface LoggerModalProps extends LoggerProps {
  showHeader?: boolean
  title?: ReactNode
  onClose?: () => void
}

export const LoggerModal = memo((props: LoggerModalProps) => {
  const { showHeader, title, onClose, ...rest } = props

  const [width, setWidth] = useState(600)

  const handleDiff = useMemoizedFn((diff: boolean) => {
    setWidth(diff ? 1200 : 600)
  })

  return (
    <BaseModal
      top={showHeader ? undefined : 20}
      width={width}
      title={title}
      onClose={onClose}
    >
      <LoggerContent {...rest} onDiff={handleDiff} />
    </BaseModal>
  )
})

export interface LoggerTitleProps {
  time?: string
  status?: FlowStatus
}

export const LoggerTitle = memo((props: LoggerTitleProps) => {
  const { time, status } = props

  return (
    <div className='flex items-center'>
      <div className='m-r-12 text-16px font-600 c-font'>
        日志 {dayjs(time).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      {status && <RunTag status={status} />}
    </div>
  )
})

export { LoggerContent }
