import { useRafState, useSize } from 'ahooks'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import type { ResizableProps } from 'react-resizable'

export function useResizeX(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  options: {
    offset: number
    max: (outerWidth: number) => number
    afterResize?: VoidFunction
  },
) {
  const outerWidth = useSize(ref)?.width ?? window.innerWidth - options.offset

  const min = useMemo<[number, number]>(() => [outerWidth / 2, 0], [outerWidth])

  const max = useMemo<[number, number]>(
    () => [Math.max(0, options.max(outerWidth)), Number.POSITIVE_INFINITY],
    [outerWidth],
  )

  const [width, setWidth] = useRafState(() => {
    const initial =
      Number(localStorage.getItem('AgentDesignWidth')) || outerWidth / 2
    return Math.min(max[0], initial)
  })

  useEffect(() => {
    const initial =
      Number(localStorage.getItem('AgentDesignWidth')) || outerWidth / 2
    const width = Math.max(Math.min(max[0], initial), min[0])
    setWidth(width)
  }, [outerWidth, min[0], max[0]])

  const [cursor, setCursor] = useRafState<string>()

  const cursorStyle = useRef<HTMLStyleElement | null>(null)

  useLayoutEffect(() => {
    if (cursorStyle.current) {
      cursorStyle.current.innerHTML = `*{cursor: ${cursor} !important;pointer-events:none !important;}`
    }
  }, [cursor])

  const onStart = useCallback<NonNullable<ResizableProps['onResizeStart']>>(
    _ => {
      if (cursorStyle.current === null) {
        cursorStyle.current = document.createElement('style')
        document.head.appendChild(cursorStyle.current)
      }
    },
    [],
  )

  const onResize = useCallback<NonNullable<ResizableProps['onResize']>>(
    (event, data) => {
      let nextWidth
      const mouseX = (event as unknown as MouseEvent).x - options.offset
      if (mouseX < width) {
        nextWidth = width <= min[0] ? min[0] : Math.max(min[0], data.size.width)
      } else {
        nextWidth = width >= max[0] ? max[0] : Math.min(data.size.width, max[0])
      }
      if (width !== nextWidth) {
        setWidth(nextWidth)
      }

      let nextCursor = 'auto'
      if (width >= max[0] && mouseX >= max[0]) {
        nextCursor = 'w-resize'
      } else if (width <= min[0] && mouseX <= min[0]) {
        nextCursor = 'e-resize'
      } else {
        nextCursor = 'col-resize'
      }
      if (cursor !== nextCursor) {
        setCursor(nextCursor)
      }
    },
    [min, max, width, cursor],
  )

  const onStop = useCallback<NonNullable<ResizableProps['onResizeStop']>>(
    (_, data) => {
      if (cursorStyle.current) {
        document.head.removeChild(cursorStyle.current)
        cursorStyle.current = null
      }
      const finallyWidth = data.size.width
      if (finallyWidth * 2 === outerWidth) {
        localStorage.removeItem('AgentDesignWidth')
        return
      }
      localStorage.setItem('AgentDesignWidth', finallyWidth.toString())

      options.afterResize?.()
    },
    [],
  )

  return {
    min,
    max,
    width,
    onStart,
    onResize,
    onStop,
  }
}
