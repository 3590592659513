import { Form, message, Popover } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Button, IconFont, Input, usePageModal } from '@/components'
import type { GetFlowsWithFormResponse } from '@/apis/agentgo'
import { getFlowsWithForm, updateFormTemplateForFlows } from '@/apis/agentgo'

interface DingTalkFormTemplateProps {
  agentId: string
}

function InternalDingTalkFormTemplate({ agentId }: DingTalkFormTemplateProps) {
  const pageModal = usePageModal()

  const [form] = Form.useForm<{
    templates: GetFlowsWithFormResponse
  }>()

  const [collapsed, setCollapsed] = useState(false)

  const [flows, setFlows] = useState<GetFlowsWithFormResponse>()

  useEffect(() => {
    getFlowsWithForm(agentId).then(setFlows)
  }, [])

  if (!flows?.length) {
    return null
  }

  const initialValues = flows.map(el => ({
    icon: el.icon,
    color: el.color,
    app_id: el.app_id,
    workspace_id: el.workspace_id,
    flow_id: el.flow_id,
    flow_name: el.flow_name,
    dingtalk_card_template_id: el.dingtalk_card_template_id,
  }))

  const handleFinish = async (values: {
    templates: GetFlowsWithFormResponse
  }) => {
    const templates = values.templates.map(el => ({
      flow_id: el.flow_id,
      template_id: el.dingtalk_card_template_id!,
    }))
    try {
      await updateFormTemplateForFlows(templates)
      message.success('互动表单配置完成')
    } catch (_) {
      message.error('配置失败')
    }
  }

  return (
    <div className='mt-24 rounded-8px bg-white border border-line border-op-40'>
      <div
        className='flex items-center h-56px px-20 cursor-pointer'
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        <IconFont
          className={classNames('text-16px', { 'rotate-270': collapsed })}
          name='arrow-1'
        />
        <span className='ml-4 mr-8 font-medium leading-16px'>钉钉互动表单</span>
        <div onClick={event => event.stopPropagation()}>
          <Popover
            overlayClassName='pt-6 [&_.ant-popover-inner]:px-20 [&_.ant-popover-inner]:py-16 [&_.ant-popover-inner]:shadow-[0px_12px_32px_0px_rgba(153,162,176,0.24)] [&_.ant-popover-inner]:border [&_.ant-popover-inner]:border-[rgba(141,141,153,0.08)]'
            content={
              <>
                <img
                  className='w-210px h-238px object-contain rounded-8px'
                  src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/dingtalk-form-template.png'
                />
                <p className='text-font_1 text-op-60 text-center mt-16'>
                  通过「互动表单」收集信息
                </p>
              </>
            }
            placement='bottomLeft'
            arrow={false}
            destroyTooltipOnHide
          >
            <span className='text-12px text-#7b67ee hover:text-op-60'>
              示例
            </span>
          </Popover>
        </div>
        <span className='border-left h-12px mx-8 after:border-line after:border-op-80' />
        <a
          className='text-12px text-#7b67ee hover:text-op-60'
          href='https://ai-docs.betteryeah.com/%E5%8F%91%E5%B8%83Agent/%E9%92%89%E9%92%89%E4%BA%92%E5%8A%A8%E8%A1%A8%E5%8D%95%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95.html'
          target='_blank'
          rel='noreferrer'
        >
          帮助文档
        </a>
      </div>
      <Form
        className={classNames('-mt-6 px-20 pb-20', { hidden: collapsed })}
        form={form}
        initialValues={{ templates: initialValues }}
        autoComplete='off'
        onFinish={handleFinish}
      >
        <div className='h-38px flex items-center gap-24 px-12 bg-#f6f6f9 rounded-8px text-font_1 font-semibold'>
          <span className='flex-35'>工作流</span>
          <span className='flex-65'>钉钉卡片模版 ID</span>
        </div>
        <Form.List name='templates'>
          {fields =>
            fields?.map((el, index) => {
              const flow = initialValues[index]

              return (
                <div
                  key={flow.flow_id}
                  className='flex items-center gap-24 px-12 mt-12'
                >
                  <div className='flex-35 flex items-center overflow-hidden'>
                    {flow.icon?.startsWith('http') ? (
                      <img
                        className='w-24px h-24px rounded-4px object-contain shrink-0'
                        src={flow.icon}
                      />
                    ) : (
                      <span
                        className='w-24px h-24px rounded-4px text-12px/24px text-center shrink-0'
                        style={{ background: flow.color }}
                      >
                        {flow.icon}
                      </span>
                    )}
                    <span className='ml-4 mr-12 truncate'>
                      {flow.flow_name}
                    </span>
                    <span
                      onClick={e => {
                        e.stopPropagation()
                        pageModal.show({
                          url: `/application/${flow.workspace_id}/${flow.app_id}/flow/${flow.flow_id}`,
                        })
                      }}
                    >
                      <IconFont
                        className='cursor-pointer text-16px text-bg_3 text-op-60 shrink-0 hover:text-op-100'
                        name='super-link'
                      />
                    </span>
                  </div>
                  <div className='flex-65 shrink-0'>
                    <Form.Item
                      name={[el.name, 'dingtalk_card_template_id']}
                      noStyle
                    >
                      <Input placeholder='模版 ID' />
                    </Form.Item>
                  </div>
                </div>
              )
            })
          }
        </Form.List>
        <div className='text-right mt-14'>
          <Form.Item shouldUpdate noStyle>
            {form => {
              const touched = form.isFieldTouched('templates')

              return (
                <Button
                  className='w-80px'
                  type='primary'
                  htmlType='submit'
                  disabled={!touched}
                >
                  保存
                </Button>
              )
            }}
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export const DingTalkFormTemplate = React.memo(InternalDingTalkFormTemplate)
