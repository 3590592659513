import { useBoolean } from 'ahooks'
import { useCallback, useRef } from 'react'
import type { OnResize, ResizeDragEvent, ResizeParams } from 'reactflow'
import { useFlowDraftStore } from '@/store'

export function useNodeResize(nodeId: string) {
  const onNodeResize = useFlowDraftStore(s => s.onNodeResize)

  // 这里如果用state存储nodeSize会导致onResize中的更新被batch合并，需要强制调用flushSync更新，故改用ref实现
  const nodeSizeRef = useRef({ width: 420, height: 0 })
  const [isResizing, { setFalse: setResizingFalse, setTrue: setResizingTrue }] =
    useBoolean(false)

  const onResize: OnResize = (_, { width, height }) => {
    nodeSizeRef.current = { width, height }
  }

  const onResizeStart = () => setResizingTrue()

  const onResizeEnd = useCallback(
    (_: ResizeDragEvent, ResizeParams: ResizeParams) => {
      setResizingFalse()
      onNodeResize(nodeId, ResizeParams, nodeSizeRef.current)
    },
    [onNodeResize, nodeSizeRef],
  )

  return {
    onResizeStart,
    onResize,
    onResizeEnd,
    setResizingTrue,
    setResizingFalse,
    nodeSize: nodeSizeRef.current,
    isResizing,
  }
}
