import { useMemo, useRef, useEffect, useCallback } from 'react'
import { Switch, Form, Tooltip } from 'antd'
import { useMemoizedFn, useRequest } from 'ahooks'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'
import type { ActionTypesForNode } from '@/apis/flow'
import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm, NodeFormItem } from '@/features/nodes/components'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { MemoryTagSelect } from '@/features/nodes/memory/MemoryTagSelect.tsx'
import { MemoryOutputTypeSelect } from '@/features/nodes/memory/MemoryOutputTypeSelect.tsx'
import { OutputTypes } from '@/apis/datastore/model.ts'
import { VariableRegex } from '@/constants/common.ts'
import { CodeEditor } from '@/features/editor'
import { IconFont, InputNumber } from '@/components'

export interface MemoryNodeData {
  name: string
  packageName: string
  isSelectAllTags?: boolean
  inputs?: MemoryDataValue
  actionType?: ActionTypesForNode.INTEGRATION
}

export interface MemoryDataValue {
  maxResultNum?: number
  searchContent?: string
  memory?: number
  minSimilar?: number
  tags?: string[]
  outputType: string
  queryFullTag: boolean
}

export const MemoryNode: NodeComponent<MemoryNodeData> = props => {
  const datasetList = useNodeMetaStore(state => state.datasetList)
  const datasetTagsMap = useNodeMetaStore(state => state.datasetTagsMap)
  const getDatasetTags = useNodeMetaStore(state => state.getDatasetTags)
  const selectPopupRef = useRef<any>(null)
  const [form] = Form.useForm()
  const knowledgeType = Form.useWatch(['inputs', 'name1'], form)

  const fetchDatasetTags = useMemoizedFn(async () => {
    if (props.data.inputs?.memory) {
      getDatasetTags(props.data.inputs.memory, true)
    }
    return true
  })

  useRequest(fetchDatasetTags, { refreshOnWindowFocus: true })

  // [兼容用户体验处理]删除已选tag中不存在的tag
  useEffect(() => {
    const inputs = props.data.inputs
    if (
      inputs?.memory &&
      inputs.tags?.length &&
      datasetTagsMap[inputs.memory]
    ) {
      const datasetTags = datasetTagsMap[inputs.memory]
      const newTags = inputs.tags.filter(
        tag =>
          VariableRegex.test(tag) ||
          datasetTags.findIndex(i => i.tag === tag) !== -1,
      )
      form.setFieldValue(['inputs', 'tags'], newTags)
    }
  }, [datasetTagsMap])

  const selected_memory = form.getFieldValue(['inputs', 'memory'])
  const onBeforeChange = useMemoizedFn((values: any) => {
    // 知识库变化或者全选(isSelectAllTags = true || undefined)知识库标签，清空之前选择的标签, 并且设置不查询所有标签下的数据
    if (
      values.isSelectAllTags !== false ||
      values.inputs?.memory !== selected_memory
    ) {
      form.setFieldValue(['inputs', 'tags'], [])
      form.setFieldValue(['inputs', 'queryFullTag'], false)
      values.inputs.tags = []
      values.inputs.queryFullTag = false
    }
    return values
  })

  const handleLanguageChange = useCallback(
    (value: unknown) => {
      const formData = form.getFieldsValue()
      props.onSaveChange({ ...formData, name1: value })
    },
    [form, props.onSaveChange],
  )

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: '类型',
        name: ['inputs', 'name1'],
        type: 'Segmented',
        componentProps: {
          options: [
            { label: '查询知识库', value: 1 },
            { label: '插入知识库', value: 2 },
          ],
          onChange: handleLanguageChange,
        },
      },
      {
        label: '知识库',
        name: ['inputs', 'memory'],
        required: true,
        rules: [{ required: true, message: '请选择知识库' }],
        type: 'DatasetSelect',
        componentProps: {
          onChange: (id: number | string) => {
            if (id && !VariableRegex.test(String(id))) {
              getDatasetTags(Number(id))
            }
          },
        },
      },
      ...(knowledgeType ? [] : []),
      {
        noStyle: true,
        hidden: !form.getFieldValue(['inputs', 'memory']),
        render: () => {
          return (
            <>
              <div className='flex items-center text-12px font-500 mb-12'>
                <div className='flex items-center'>
                  <span>数据标签</span>
                  <span className='color-error ml-5 text-18px line-height-14px position-relative top-3px'>
                    *
                  </span>
                </div>
                <div className='flex items-center ml-12'>
                  <span className='mr-8'>全选</span>
                  <Form.Item noStyle name='isSelectAllTags'>
                    <TagSwitch />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.isSelectAllTags !== curr.isSelectAllTags ||
                  prev.inputs.memory !== curr.inputs.memory
                }
              >
                {({ getFieldValue, getFieldError }) => {
                  const isSelectedAllTags = getFieldValue('isSelectAllTags')
                  const memory = getFieldValue(['inputs', 'memory'])
                  const tags = datasetTagsMap[memory] ?? []
                  if (isSelectedAllTags !== false) return null
                  return (
                    <NodeFormItem
                      noStyle
                      name={['inputs', 'tags']}
                      rules={[
                        {
                          required: true,
                          type: 'array',
                          message: '至少选择一个标签',
                        },
                      ]}
                    >
                      <MemoryTagSelect
                        tags={tags}
                        error={getFieldError(['inputs', 'tags'])}
                        variables={props.variables}
                        variableTipsContainer={props.nodeElement as HTMLElement}
                      />
                    </NodeFormItem>
                  )
                }}
              </Form.Item>
            </>
          )
        },
      },
      {
        noStyle: true,
        render: () => {
          return (
            <>
              <div className='flex items-center text-12px font-500 mb-12'>
                <div className='flex items-center'>
                  <span>查询内容</span>
                  <span className='color-error ml-5 text-18px line-height-14px position-relative top-3px'>
                    *
                  </span>
                </div>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.isSelectAllTags !== curr.isSelectAllTags ||
                    prev.inputs.tags?.length !== curr.inputs.tags?.length
                  }
                >
                  {({ getFieldValue }) => {
                    const isSelectAllTags = getFieldValue('isSelectAllTags')
                    const tags = getFieldValue(['inputs', 'tags']) ?? []

                    const canSelectAllTagData =
                      !isSelectAllTags && tags.length > 0

                    return (
                      <div className='flex items-center ml-12'>
                        <div className='mr-8 flex items-center'>
                          <span>按标签查询数据</span>
                          <Tooltip
                            placement='top'
                            title='按标签下的段落顺序查出最多前500条数据； 如果有多标签，建议在【数据标签】中通过变量来控制需要查询的标签'
                          >
                            <IconFont
                              className='keyu-form-item-tooltip-icon text-font_1 text-16px text-opacity-40 ml-5'
                              name='shuimeng'
                            />
                          </Tooltip>
                        </div>
                        <Tooltip
                          placement='top'
                          title={canSelectAllTagData ? '' : '请填写标签'}
                        >
                          <div>
                            <Form.Item
                              valuePropName='checked'
                              noStyle
                              name={['inputs', 'queryFullTag']}
                            >
                              <Switch
                                disabled={!canSelectAllTagData}
                                size='small'
                              />
                            </Form.Item>
                          </div>
                        </Tooltip>
                      </div>
                    )
                  }}
                </Form.Item>
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.inputs.queryFullTag !== curr.inputs.queryFullTag ||
                  prev.inputs.tags?.length !== curr.inputs.tags?.length
                }
              >
                {({ getFieldValue }) => {
                  const queryFullTag = getFieldValue(['inputs', 'queryFullTag'])
                  const tags = getFieldValue(['inputs', 'tags']) ?? []

                  if (queryFullTag && tags.length) {
                    return (
                      <div className='bg-warning bg-op-8 flex items-center rounded-6px py-8 pl-9 pr-22 text-12px mb-16'>
                        <IconFont name='shuimeng' className='c-warning' />
                        <span className='line-height-16px text-12px ml-4'>
                          注意！若查询数据量超出处理模型的token限制，模型会报错
                        </span>
                      </div>
                    )
                  }
                  return (
                    <NodeFormItem
                      name={['inputs', 'searchContent']}
                      rules={[{ required: true, message: '查询内容不能为空' }]}
                    >
                      <CodeEditor
                        placeholder='输入变量或者文本，根据相似度设置，从知识库匹配查询相关段落'
                        className='ace-gray min-h-120px'
                        variables={props.variables}
                        variableTipsContainer={props.nodeElement}
                        mode='text'
                      />
                    </NodeFormItem>
                  )
                }}
              </Form.Item>
            </>
          )
        },
      },
      {
        noStyle: true,
        render: () => {
          return (
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) =>
                prev.inputs.queryFullTag !== curr.inputs.queryFullTag
              }
            >
              {({ getFieldValue }) => {
                const queryFullTag = getFieldValue(['inputs', 'queryFullTag'])
                return (
                  <div className='flex items-center w-full gap-12'>
                    <NodeFormItem
                      required
                      hidden={queryFullTag}
                      className='flex-1'
                      label='最大结果数'
                      name={['inputs', 'maxResultNum']}
                      rules={[
                        { required: true, message: '最大结果数不能为空' },
                      ]}
                      tooltip={`从从查询结果中取相关度最高的${props.data.inputs?.maxResultNum}条`}
                    >
                      <InputNumber
                        size='small'
                        className='nodrag nopan'
                        min={1}
                        max={30}
                        placeholder='最大结果数'
                      />
                    </NodeFormItem>
                    {/* <NodeFormItem
                      required
                      hidden={queryFullTag}
                      label='最低相似度'
                      className='flex-1'
                      name={['inputs', 'minSimilar']}
                      rules={[
                        { required: true, message: '最大结果数不能为空' },
                      ]}
                      tooltip='设定的一个阈值，用于过滤掉相似度低于此值的匹配结果。确保返回的匹配结果都达到一定的相关度，减少不相关或低相关性的内容，提高检索准确性。'
                    >
                      <Steps
                        sliderClassName='nodrag nopan'
                        min={0}
                        max={1}
                        step={0.01}
                        maxLength={4}
                      />
                    </NodeFormItem> */}
                  </div>
                )
              }}
            </Form.Item>
          )
        },
      },
      {
        label: '输出设置',
        required: true,
        className: 'important:mb-0',
        name: ['inputs', 'outputType'],
        render: () => <MemoryOutputTypeSelect />,
      },
    ]
  }, [datasetList, props.variables, props.nodeElement])

  return useMemo(
    () => (
      <div
        className='p-16px w-420px'
        onWheel={e => e.stopPropagation()}
        ref={selectPopupRef}
      >
        <JsonForm
          form={form}
          list={list}
          beforeChange={onBeforeChange}
        ></JsonForm>
      </div>
    ),
    [form, list, onBeforeChange],
  )
}

function TagSwitch(props: {
  value?: boolean
  onChange?: (value: boolean) => void
}) {
  return (
    <Switch
      {...props}
      size='small'
      checked={props.value === undefined ? true : props.value}
    />
  )
}

MemoryNode.meta = {
  type: NodeType.MEMORY,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'INTEGRATION',
  typeName: '知识库',
  icon: 'memory',
  description: '从知识库中，查询数据',
  backgroundColor: '#3898FF',
  canDelete: true,
  initialData: {
    isSelectAllTags: true,
    inputs: {
      memory: undefined,
      tags: [],
      maxResultNum: 1,
      // minSimilar: 0.8, //后端实现新逻辑暂时不使用该字段
      searchContent: '',
      outputType: OutputTypes.TEXT,
      queryFullTag: false,
    },
    name: 'knowledge_1',
    packageName: 'integration.memory.MemoryAction',
  },
}
