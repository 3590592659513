import type { ICommand } from 'react-ace'

function emptyFn() {}

export const defaultTriggerCommand: ICommand = {
  name: 'slashTrigger',
  bindKey: { win: '/', mac: '/' },
  exec: editor => {
    editor.insert('/')
    editor.completers = [
      editor.completers.find(v => v.id === 'customCompleter') as any,
    ]
    editor.execCommand('startAutocomplete')
  },
}

export const textModelTriggerCommand: ICommand = {
  name: 'textModelTrigger',
  bindKey: { win: '{', mac: '{' },
  exec: ace => {
    ace.insert('{')
    ace.execCommand('startAutocomplete')
  },
}

export const singleLineCommand: ICommand = {
  name: 'singleLine',
  bindKey: { win: 'Enter', mac: 'Enter' },
  exec: emptyFn,
}

export const disableFindCommand: ICommand = {
  name: 'disableCtrlF',
  bindKey: { win: 'Ctrl-F', mac: 'Command-F' },
  exec: emptyFn,
}
