import React, { useCallback } from 'react'
import { List } from 'antd'
import type { AgentDetailDatabase } from '@bty/chat-types'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import type { BindingTableEvents } from '../types'
import { BindingTableListItem } from './BindingTableListItem'
import type { BindingTableListItemProps } from './BindingTableListItem'

export interface BindingTableListProps extends BindingTableEvents {
  database: AgentDetailDatabase
  filterOption?: (inputValue: string) => boolean
  onClick?: BindingTableListItemProps['onClick']
}

function InternalBindingTableList({
  database,
  filterOption,
  onClick,
  onUpdate,
  onEnableChange,
}: BindingTableListProps) {
  const { scrollRef } = useScrollBar()

  const handleUpdate = useCallback<BindingTableListItemProps['onUpdate']>(
    (_, table_id) => {
      onUpdate(database, table_id)
    },
    [database],
  )

  const tables = filterOption
    ? database.table_list?.filter(el => filterOption(el.title))
    : database.table_list

  return (
    <div className='mt-20 flex-1 rounded-8px flex flex-col overflow-hidden'>
      <div className='shrink-0 h-48px px-20 flex justify-between items-center text-14px text-font_1 font-semibold border-bottom after:border-line after:border-op-40'>
        <span>数据表</span>
        <span>操作</span>
      </div>
      <div ref={scrollRef} className='flex-1 overflow-scroll'>
        <List
          className='border-bottom after:border-line after:border-op-40'
          dataSource={tables}
          renderItem={item => (
            <BindingTableListItem
              data={item}
              onClick={onClick}
              onUpdate={handleUpdate}
              onEnableChange={onEnableChange}
            />
          )}
        />
      </div>
      {/* <div className='shrink-0 border-top after:border-line after:border-op-40 flex justify-between items-center px-16 py-18'>
        <span>xxx</span>
        <Pagination
          current={1}
          showSizeChanger
          onChange={() => {}}
          onShowSizeChange={() => {}}
        />
      </div> */}
    </div>
  )
}

export const BindingTableList = React.memo(InternalBindingTableList)
