export default class OssSingleton<T> {
  private static instances: Map<string, Promise<OssSingleton<any>>> = new Map()
  private static expirations: Map<string, number> = new Map()

  private data: T

  private constructor(data: T) {
    this.data = data
  }

  public static async getInstance<T>(
    business: string,
    callback: () => Promise<T>,
    expiresTime = 0,
  ): Promise<OssSingleton<T>> {
    const now: number = Date.now()
    if (
      !OssSingleton.instances.has(business) ||
      (OssSingleton.expirations.get(business)! &&
        now > OssSingleton.expirations.get(business)!)
    ) {
      OssSingleton.expirations.set(business, now + expiresTime)
      OssSingleton.instances.set(
        business,
        // eslint-disable-next-line no-async-promise-executor
        new Promise<OssSingleton<T>>(async resolve => {
          const data = await callback()
          resolve(new OssSingleton(data))
        }),
      )
    }
    return OssSingleton.instances.get(business)! as Promise<OssSingleton<T>>
  }

  public getData(): T {
    return this.data
  }
}
