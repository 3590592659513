import type { Edge, Node } from 'reactflow'
import {
  CONDITION_START_EDGE_GAP,
  NODE_WIDTH,
  SPACE_BETWEEN_NODES,
} from '../..'
import { CustomEdgePathType } from '../../../edges/InsertEdge'
import type { ConditionNodeData } from '@/features/nodes/condition'
import { NodeType } from '@/features/nodes/base'
import { getNextNode } from '..'
import { getNodeBoundary } from '../../boundary'
import type { NestNodeAutoLayoutFn } from '../types'
import { getRelateBydTargetId, isNestableNode } from '@/features/flow/utils'

interface BranchNodesData {
  branchId: string
  branchNodes: Node[]
  start: Node | null
  end: Node | null
  x: number
  y: number
}

function getCurrentBranchYPosition(
  conditionId: string,
  currentBranchId: string,
  nodes: Node[],
  edges: Edge[],
): number {
  const conditioNode = nodes.find(
    n => n.id === conditionId,
  ) as Node<ConditionNodeData>
  const { conditions } = conditioNode.data
  const currentIndex = conditions.map(c => c.id).indexOf(currentBranchId)
  if (currentIndex <= 0) {
    // return conditioNode.position.y + 40 + 16 + 24
    return conditioNode.position.y
  }
  const prevBranchId = conditions[currentIndex - 1].id!
  const prevBranchNodes = nodes.filter(
    n => n.data.relation?.branchId === prevBranchId,
  )
  if (!prevBranchNodes.length) {
    return (
      getCurrentBranchYPosition(conditionId, prevBranchId, nodes, edges) + 100
    )
  }
  const boundaries = prevBranchNodes.map(n => getNodeBoundary(n, nodes, edges))
  return Math.max(...boundaries.map(b => b.bottom)) + 100
}

export function conditionNodeAutoLayout(
  node: Node<ConditionNodeData>,
  nodes: Node[],
  edges: Edge[],
  nestNodeAutoLayoutFn: NestNodeAutoLayoutFn,
) {
  const { conditions, relation } = node.data
  const resultNode = nodes.find(n => n.id === relation.conditionResultId)
  if (!resultNode) {
    console.error('condition节点数据出错: 缺失result节点')
    return
  }

  const allBranchNodes = nodes.filter(
    n => n.data.relation?.conditionNodeId === node.id,
  )
  const branchNodesData: BranchNodesData[] =
    conditions?.map(c => ({
      branchId: c.id!,
      branchNodes: allBranchNodes.filter(
        n => n.data.relation?.branchId === c.id,
      ),
      start: null,
      end: null,
      x: 0,
      y: 0,
    })) || []
  // console.log('branchNodesData==', branchNodesData)
  // 处理分支上所有节点位置分布
  branchNodesData.forEach((data, index) => {
    const { branchId, branchNodes } = data
    const x =
      node.position.x +
      (node.width || 0)! +
      CONDITION_START_EDGE_GAP * (conditions.length + 2) +
      18 * 2
    const y = getCurrentBranchYPosition(node.id, branchId, nodes, edges)
    // console.log('each branchId', branchId, x, y)
    if (branchNodes.length) {
      branchNodes.forEach(n => (n.position.y = y))
      const startId = edges.find(e => e.sourceHandle === branchId)
        ?.target as string
      const start = branchNodes.find(n => n.id === startId)!
      data.start = start
      data.x = x
      data.y = y + 18
      if (start) {
        start.position.y = y
        start.position.x = x
      }
      let current: Node | null = null
      let next: Node | null = start
      while (next) {
        if (isNestableNode(next)) {
          nestNodeAutoLayoutFn(next, nodes, edges)
        }
        if (current && next) {
          const currentNodeBoundary = getNodeBoundary(current, nodes, edges)
          const nextNodeBoundary = getNodeBoundary(next, nodes, edges)
          const gap = nextNodeBoundary.left - currentNodeBoundary.right
          const isGapProper = gap >= SPACE_BETWEEN_NODES - 2 && gap <= 100
          if (!isGapProper) {
            next.position.x = currentNodeBoundary.right + SPACE_BETWEEN_NODES
            nestNodeAutoLayoutFn(next, nodes, edges)
          }
          // 不更改end和result节点的垂直方向位置
          if (
            next.type !== NodeType.END &&
            next.type !== NodeType.CONDITION_RESULT
          ) {
            next.position.y = current.position.y
          }
        }
        current = next
        next = getNextNode(nodes, edges, current, NodeType.CONDITION_RESULT)
      }
      // 若当前分支用于计算布局时最后一个节点为条件节点，则将end置为其对应的result节点用于edge的path计算
      data.end =
        current?.type === NodeType.CONDITION ||
        current?.type === NodeType.LOOP ||
        current?.type === NodeType.INTENT
          ? getRelateBydTargetId(current, nodes)
          : current
    } else {
      // 记录当前分支垂直方向位置
      data.x = x
      data.y = y + (index === 0 ? 18 : 0)
    }
  })
  const allBranchNodeBoundaries = allBranchNodes.map(n =>
    getNodeBoundary(n, nodes, edges),
  )
  const resultX = allBranchNodes.length
    ? Math.max(...allBranchNodeBoundaries.map(b => b.right)) +
      SPACE_BETWEEN_NODES
    : node.position.x +
      NODE_WIDTH * 2 +
      SPACE_BETWEEN_NODES +
      CONDITION_START_EDGE_GAP * conditions.length
  resultNode.position.x = resultX
  resultNode.position.y = node.position.y + 13

  branchNodesData.forEach((data, index) => {
    // console.log('edge handle: ', data, resultNode.position, resultNode.width)
    const { branchId, branchNodes, start, end, x, y } = data
    if (branchNodes.length && start && end) {
      // 分支上有节点，处理开始边和结束边绘制
      const startEdge = edges.find(
        e => e.target === start.id && e.source === node.id,
      )
      const endEdge = edges.find(
        e => e.source === end.id && e.target === resultNode.id,
      )
      if (startEdge) {
        startEdge.data = {
          customPathOptions: {
            pathType: CustomEdgePathType.BRANCH_START,
            points: [
              {
                x:
                  x -
                  (index === 0 ? conditions.length : index + 1) *
                    CONDITION_START_EDGE_GAP,
                y,
              },
              {
                x,
                y,
              },
            ],
          },
        }
      }
      if (endEdge) {
        // endEdge.zIndex = branchNodesData.length - index
        endEdge.data = {
          customPathOptions: {
            pathType: CustomEdgePathType.BRANCH_END,
            points: [
              {
                // x: resultNode.position.x - (resultNode.width || 0) - (conditions.length - index) * CONDITION_START_EDGE_GAP,
                x:
                  resultNode.position.x +
                  (resultNode.width ? resultNode.width / 2 : 0),
                y,
              },
            ],
          },
        }
      }
    } else {
      // 分支上没有节点，处理单条边的绘制
      const branchEdge = edges.find(e => e.sourceHandle === branchId)
      const branchPathPoints = [
        {
          x:
            x -
            (index === 0 ? conditions.length : index + 1) *
              CONDITION_START_EDGE_GAP,
          y,
        },
        {
          x,
          y,
        },
        {
          // x: resultNode.position.x - (resultNode.width || 0) - (conditions.length - index) * CONDITION_START_EDGE_GAP,
          x:
            resultNode.position.x +
            (resultNode.width ? resultNode.width / 2 : 0),
          y,
        },
      ]
      if (branchEdge) {
        // branchEdge.zIndex = branchNodesData.length - index
        branchEdge.data = {
          customPathOptions: {
            pathType: CustomEdgePathType.BRANCH,
            points: branchPathPoints,
          },
        }
      }
    }
  })
}
