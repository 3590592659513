import { Popover } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { AreaChart as VAreaChart, Area, Tooltip } from '@visactor/react-vchart'
import type { AreaChartProps as VAreaChartProps } from '@visactor/react-vchart'
import classNames from 'classnames'
import { Button, IconFont, Input } from '@/components'
import { getIrlTimeout, updateIrlTimeout } from '@/apis/monitor'
import EmptyImage from '@/assets/app/rocket2.png'
import { AppStabilityPaneContext } from '@/features/analyze/components/AppStabilityPaneContext'
import { TOOLTIP_STYLE, TooltipContent } from './TooltipContent'
import { axes, getLine, getPoint, type AreaChartProps } from './AreaChart'
import { ChartBox } from './ChartBox'

interface SettingTimeoutProps {
  value?: number
  onChange: (value: number | undefined) => void
}

function SettingTimeout({ value, onChange }: SettingTimeoutProps) {
  return (
    <div className='text-font flex items-center'>
      <span className='font-medium mr-8'>规则设置：</span>
      响应时长超过
      <Input
        className='mx-8 w-80px h-36px [&_.ant-input-suffix]:!text-14px [&_.ant-input-suffix]:!text-font_1'
        value={value}
        suffix='秒'
        onChange={event => {
          const second = event.target.value
          if (second === '') {
            onChange(undefined)
            return
          }
          onChange(+second)
        }}
        onBlur={event => {
          const second = event.target.value
          if (second === '') {
            onChange(5)
          }
        }}
      />
      为异常
    </div>
  )
}

interface SettingPopconfirmProps {
  value?: number
  onOk: (value?: number) => void
}

function SettingTimeoutPopconfirm({ value, onOk }: SettingPopconfirmProps) {
  const [open, setOpen] = useState(false)

  const [second, setSecond] = useState<number | undefined>(value)

  return (
    <Popover
      overlayClassName='[&_.ant-popover-inner]:!p-16 [&_.ant-popover-inner]:!rounded-8px [&_.ant-popover-inner]:backdrop-blur-lg [&_.ant-popover-inner]:!shadow-[0px_8px_24px_0px_rgba(0,0,0,0.2)]'
      open={open}
      trigger='click'
      content={
        <>
          <SettingTimeout value={second} onChange={setSecond} />
          <p className='text-font_1 text-op-60 mt-12'>
            修改规则后将清空已有记录，并重新统计
          </p>
          <div className='text-right mt-24'>
            <Button
              onClick={event => {
                event.stopPropagation()
                setOpen(false)
              }}
            >
              取消
            </Button>
            <Button
              className='ml-12'
              type='primary'
              onClick={async event => {
                event.stopPropagation()
                onOk(second)
                setOpen(false)
              }}
            >
              保存
            </Button>
          </div>
        </>
      }
      placement='bottom'
      arrow={false}
      destroyTooltipOnHide
      onOpenChange={setOpen}
    >
      <button className='ml-4 text-14px text-bg_3 text-op-60'>
        <IconFont name='shezhi' />
      </button>
    </Popover>
  )
}

const area: VAreaChartProps['area'] = {
  style: {
    fill: {
      gradient: 'linear',
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 0.7,
      stops: [
        {
          offset: 0,
          color: 'rgb(254, 151, 0)',
        },
        {
          offset: 1,
          color: '#fff',
        },
      ],
    },
  },
}

const point = getPoint('rgb(254, 151, 0)')

const line = getLine('rgb(254, 151, 0)')

interface AbnormalFirstResponseTimeStatsProps extends AreaChartProps {
  onDetailClick: () => void
}

export function AbnormalFirstResponseTimeStats({
  className,
  title,
  values,
  xField,
  yField,
  modal,
  showTooltip,
  tooltipTitle,
  yFieldLabel,
  onDetailClick,
  onXFieldDetailClick,
  id,
}: AbnormalFirstResponseTimeStatsProps) {
  const ctx = useContext(AppStabilityPaneContext)

  const [monitoring, setMonotoring] = useState(false)

  const [second, setSecond] = useState<number | undefined>(5)

  useEffect(() => {
    const flowId = ctx?.id
    if (flowId) {
      getIrlTimeout(flowId).then(res => {
        const second = res?.irl_time_out
        if (second) {
          setMonotoring(true)
          setSecond(second)
        }
      })
    }
  }, [])

  const hasData = !!values?.length

  return (
    <ChartBox
      className={className}
      title={title}
      addonAfterTitle={
        monitoring ? (
          <>
            <SettingTimeoutPopconfirm
              value={second}
              onOk={value => {
                const id = ctx?.id
                if (id && value) {
                  updateIrlTimeout(id, value)
                  setMonotoring(true)
                }
              }}
            />
            {hasData && (
              <button
                className='ml-12 text-14px text-#9e9e9e'
                onClick={onDetailClick}
              >
                明细
              </button>
            )}
          </>
        ) : null
      }
      color='rgb(254, 151, 0)'
      modal={modal}
    >
      <div
        className={classNames(
          'h-228px flex flex-col justify-center items-center',
          { 'bg-#f6f6f9 rounded-12px': !monitoring },
        )}
      >
        {monitoring ? (
          hasData ? (
            <VAreaChart
              className='[&>canvas]:!w-full'
              type='area'
              data={[{ values }]}
              tooltip={{ visible: showTooltip ?? false }}
              point={point}
              line={line}
              axes={axes}
              padding={0}
              // width={560}
              height={228}
              autoFit
            >
              {showTooltip && (
                <Tooltip style={TOOLTIP_STYLE} enterable>
                  <TooltipContent
                    chartId={id}
                    xField={xField}
                    yField={yField}
                    yFieldLabel={tooltipTitle ?? yFieldLabel}
                    detail={
                      onXFieldDetailClick
                        ? { onClick: onXFieldDetailClick }
                        : false
                    }
                  />
                </Tooltip>
              )}
              <Area xField={xField} yField={yField} area={area} />
            </VAreaChart>
          ) : (
            <>
              <img className='w-70px object-contain' src={EmptyImage} />
              <span className='text-14px text-font_1'>暂无数据</span>
            </>
          )
        ) : (
          <>
            <SettingTimeout value={second} onChange={setSecond} />
            <Button
              className='!h-32px text-12px !font-normal mt-20'
              onClick={event => {
                event.stopPropagation()
                const id = ctx?.id
                if (id && second) {
                  updateIrlTimeout(id, second)
                  setMonotoring(true)
                }
              }}
            >
              保存并开启监控
            </Button>
          </>
        )}
      </div>
    </ChartBox>
  )
}
