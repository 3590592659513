import type { Edge, Node } from 'reactflow'
import { isConditionNode, isLoopNode, isIntentNode } from '../../utils'
import { conditionNodeAutoLayout } from './nodes/condition'
import { intentNodeAutoLayout } from './nodes/intent'
import { LoopNodeAutoLayout } from './nodes/loop'

export function nestNodeAutoLayout(node: Node, nodes: Node[], edges: Edge[]) {
  if (isConditionNode(node)) {
    conditionNodeAutoLayout(node, nodes, edges, nestNodeAutoLayout)
  }
  if (isIntentNode(node)) {
    intentNodeAutoLayout(node, nodes, edges, nestNodeAutoLayout)
  }
  if (isLoopNode(node)) {
    LoopNodeAutoLayout(node, nodes, edges, nestNodeAutoLayout)
  }
}
