export enum EmptyNodeTypes {
  BASE = 'base',
  PLUGIN = 'plugin',
}

export const EmptyNodeTypeOptions = [
  {
    label: '基础节点',
    value: EmptyNodeTypes.BASE,
  },
  {
    label: 'Plugin',
    value: EmptyNodeTypes.PLUGIN,
  },
]
