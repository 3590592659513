import { useRequest } from 'ahooks'
import { useWorkspaceStore } from '@/store'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { getApplicationsList } from '@/apis/application.ts'

interface Options {
  manual?: boolean
  refreshOnWindowFocus?: boolean
  onSuccess?: (e: ApplicationBodyType[]) => void
}
export function useFlowList(options?: Options) {
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { data, run, loading, runAsync } = useRequest(
    () => getApplicationsList(currentWorkspaceId),
    {
      ready: !!currentWorkspaceId,
      refreshDeps: [currentWorkspaceId],
      ...options,
    },
  )

  return {
    list: data ?? [],
    refresh: run,
    refreshAsync: runAsync,
    loading,
  }
}
