import { Popconfirm, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useState } from 'react'
import LoadingIcon from '@/assets/agent/circle.png'
import { IconFont } from '@/components'

interface DescriptionAutoGenProps {
  className?: string
  text: string
  loading?: boolean
  disabled?: boolean
  showTooltip?: boolean
  showConfirmIfNeed: () => boolean
  onAutoGen: VoidFunction
}

function InternalDescriptionAutoGen({
  className,
  text,
  loading,
  disabled,
  showTooltip,
  showConfirmIfNeed,
  onAutoGen,
}: DescriptionAutoGenProps) {
  const [open, setOpen] = useState(false)

  const innerDisabled = disabled || loading

  return (
    <Popconfirm
      overlayClassName='w-275px [&_.ant-popover-inner]:rounded-8px [&_.ant-popover-inner]:p-16 [&_.ant-popover-inner]:shadow-[0px_8px_32px_0px_rgba(153,162,176,0.24)] [&_.ant-popover-inner_.ant-btn]:w-60px [&_.ant-popover-inner_.ant-btn]:h-36px [&_.ant-popover-inner_.ant-btn]:ml-12 [&_.ant-popover-inner_.ant-popconfirm-message]:mb-16'
      open={open}
      title={null}
      icon={null}
      arrow={false}
      placement='bottom'
      description={
        <span className='text-14px text-font font-medium leading-20px'>
          将会覆盖你目前的内容，是否继续生成？
        </span>
      }
      disabled
      onConfirm={() => {
        onAutoGen()
      }}
      onOpenChange={setOpen}
    >
      <Tooltip
        title='基于数据表名称自动生成'
        mouseEnterDelay={showTooltip && innerDisabled ? 0.1 : 9999}
        destroyTooltipOnHide
      >
        <button
          className={cn(
            'flex justify-center items-center gap-6 text-14px font-medium text-#7b67ee border-0',
            innerDisabled ? 'disabled:cursor-not-allowed' : 'cursor-pointer',
            className,
          )}
          disabled={innerDisabled}
          onClick={event => {
            event.stopPropagation()
            if (showConfirmIfNeed()) {
              setOpen(true)
              return
            }
            onAutoGen()
          }}
        >
          {loading ? (
            <img
              className='w-16px object-contain animate-spin'
              src={LoadingIcon}
            />
          ) : (
            <IconFont className='text-16px' name='auto' />
          )}
          {text}
        </button>
      </Tooltip>
    </Popconfirm>
  )
}

export const DescriptionAutoGen = React.memo(InternalDescriptionAutoGen)
