import type { InputRef } from 'antd'
import { Input } from 'antd'
import type { ChangeEvent } from 'react'
import { memo, useMemo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import { isNil } from 'lodash-es'
import type { TextAreaProps } from 'antd/es/input'
import classNames from 'classnames'
import type { RenderProps } from './type'

export const AreaRender = memo(
  (props: RenderProps<string> & Omit<TextAreaProps, 'onChange'>) => {
    const {
      className,
      name,
      disabled,
      placeholder,
      value = '',
      onChange,
      supportFileTypes,

      ...rest
    } = props

    const inputRef = useRef<InputRef>(null)

    const handleChange = useMemoizedFn(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value, name)
      },
    )

    const sureValue = useMemo(() => {
      if (typeof value !== 'object' || isNil(value)) return value
      return JSON.stringify(value)
    }, [value])

    return (
      <Input.TextArea
        className={classNames(className, 'bg-#fff! text-#17171D!')}
        {...rest}
        autoSize={{ minRows: 4, maxRows: 10 }}
        ref={inputRef}
        value={sureValue}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
      />
    )
  },
)
