import { useRequest } from 'ahooks'
import { useState } from 'react'
import type { GenerateTitleParams } from '@bty/chat-types'
import { generateTitleFromConversation } from '@/apis/chatbot'
import { useTypingString } from './useTypingString'

export function useGenTitleFromFirstQandA(callback?: () => void) {
  const [conversationTitle, setConversationTitle] = useState('')
  const { runAsync: generateTitle } = useRequest(
    generateTitleFromConversation,
    { manual: true },
  )
  const {
    displayString: typingTitle,
    finished: isTypingEnd,
    setDisplayString,
  } = useTypingString(conversationTitle)

  const onTitleGenerate = async (data: GenerateTitleParams) => {
    setDisplayString('')
    const { title } = await generateTitle(data)
    if (title) {
      setConversationTitle(title)
      callback?.()
    }
  }

  return {
    typingTitle,
    isTypingEnd,
    onTitleGenerate,
  }
}
