import { memo, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { FlowFileUpload } from '@/features/nodes/components/FieldsUpload'
import { useNodeMetaStore } from '@/store/nodeMeta'
import type { RenderProps } from './type'

export const UploadRender = memo((props: RenderProps<string>) => {
  const {
    name,
    disabled,
    placeholder,
    value = '',
    supportFileTypes,
    onChange,

    ...rest
  } = props

  const supportFileConfigList = useNodeMetaStore(
    state => state.supportFileConfigList,
  )

  const supportFileConfig = useMemo(
    () =>
      supportFileConfigList.filter(i =>
        (supportFileTypes || []).includes(i.type),
      ),
    [supportFileTypes, supportFileConfigList],
  )

  const handleChange = useMemoizedFn((url: string) => {
    onChange(url, name)
  })

  return (
    <FlowFileUpload
      {...rest}
      className={classNames('llm-node-upload', {
        'llm-node-upload-has-value': !!value,
      })}
      clear
      syncValue={value}
      supportFileTypes={supportFileTypes}
      supportFileConfig={supportFileConfig}
      onChange={handleChange}
      disabled={disabled}
    />
  )
})
