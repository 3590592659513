import classNames from 'classnames'
import { IconFont } from '@/components'

export interface ChartBoxProps extends React.PropsWithChildren {
  className?: string
  title?: string
  color?: string
  /**
   * 是否可以以弹窗形式展示
   */
  modal?:
    | false
    | {
        onOpen: VoidFunction
      }
  addonAfterTitle?: React.ReactNode
  id?: string | number
}

export function ChartBox({
  className,
  title,
  color,
  modal,
  addonAfterTitle,
  children,
}: ChartBoxProps) {
  const hasGap = !!(title || addonAfterTitle || modal)

  return (
    <div className={classNames('h-full', className)}>
      <div className={classNames('flex items-center', { 'mb-24': hasGap })}>
        {title ? (
          <>
            <IconFont
              className='text-11px mr-4'
              style={{
                color: color || '#7b61ff',
              }}
              name='tuli'
            />
            <span className='text-14px text-font'>{title}</span>
          </>
        ) : null}
        {addonAfterTitle}
        {modal && (
          <button
            className='ml-auto'
            onClick={event => {
              event.stopPropagation()
              modal.onOpen()
            }}
          >
            <IconFont
              className='text-16px text-bg_3 text-op-60'
              name='fangtai-1'
            />
          </button>
        )}
      </div>
      {children}
    </div>
  )
}
