import type { FC, SVGProps } from 'react'

interface IconFontProps extends SVGProps<SVGAElement> {
  name: string
}
export const IconFont: FC<IconFontProps> = ({ name, ...rest }) => {
  return (
    <svg {...(rest as any)} className={`icon ${rest.className ?? ''}`}>
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  )
}
