import type { Version, VersionStatus } from '@/features/pay'
import { request } from './lib'

export interface VersionConfig {
  id: number | string
  deleted: boolean
  created_at: string
  updated_at: string
  advanced_features_access: {
    senior_api_access: boolean
  } | null
  agent_count_limit: number
  llm_config: null
  memory_count_limit: number
  package_type: number // 后端用做区分产品套餐类型的字段：1代表是给前端的产品套餐，用作页面展示，2是自定义的企业产品套餐
  price: number
  running_points_count_limit: number
  service_and_support: string
  service_code: Version
  service_name: string
  user_total_user_count_limit: number
  users_count_limit: number
  year_discount: number
}

export interface VersionInfo {
  app_consume_num: number
  app_count_limit: number
  exist_user_count: number
  total_user_count_limit: number
  lose_effect_time: string
  days_remaining: number
  memory_consume_num: number
  memory_count_limit: number
  next_refresh_time: string
  points_consume_num: number
  points_count_limit: number
  product_code: Version
  product_name: string
  senior_api_access: boolean
  status: VersionStatus
  task_effect_time: string
  dosage_package_points_consume_num: number
  dosage_package_points_count_total: number
  dosage_package_user_count_total: number
  dosage_package_app_count_total: number
  dosage_package_memory_total: number
}

export function getVersionList() {
  return request.get<VersionConfig[]>('/v1/product_package/query')
}

export function getVersionInfoByWorkspaceId(workspaceId: string) {
  return request.get<VersionInfo>(`/v1/product_service/${workspaceId}`, {
    ignoreError: true,
    noRedirect: true,
  })
}
