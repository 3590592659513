import cn from 'classnames'
import { IconFont } from '@/components'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

interface AgentGoPublishModeProps {
  name: string
  image: string
  isBind?: boolean
  description?: string
  selected?: boolean
  onClick?: VoidFunction
  needUpgradeToEnterprise?: boolean
}

export function AgentGoPublishMode({
  name,
  image,
  description,
  selected,
  onClick,
  isBind,
  needUpgradeToEnterprise = false,
}: AgentGoPublishModeProps) {
  return (
    <LimitedAccess
      limitedType={needUpgradeToEnterprise && 'shouldCheckTeamVersion'}
      limitFeatureName={name}
    >
      <li
        className={cn(
          'h-82px mt-12px rounded-8px border border-solid border-[rgba(225,225,229,0.4)] flex items-center cursor-pointer hover:bg-bg_3 hover:bg-op-6 transition-colors duration-200 ease-[cubic-bezier(0.645,0.045,0.355,1)]',
          { '!border-primary bg-bg_3 bg-op-6': selected },
        )}
        onClick={onClick}
      >
        <img className='w-48px h-48px ml-16px mr-8px rounded-8px' src={image} />
        <div>
          <span className='text-14px/16px font-medium text-font'>{name}</span>
          <p className='text-12px/16px text-font_1 mt-8px'>{description}</p>
        </div>
        <div className='flex items-center ml-auto'>
          {isBind && (
            <span className='c-font_1 text-12px font-400'>已接入</span>
          )}
          {needUpgradeToEnterprise && (
            <IconFont
              className='text-bg_3 text-opacity-60 !w-20px ml-4 mr-12px'
              name='suoding'
            />
          )}
          <IconFont
            className='text-bg_3 text-opacity-60 !w-20px ml-4 mr-14px'
            name='left-arrow'
          />
        </div>
      </li>
    </LimitedAccess>
  )
}
