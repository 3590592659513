import { useCallback, useMemo } from 'react'
import { isEmpty, isObject, isString } from 'lodash-es'
import { CodeEditor } from '@/features/editor'
import { IconFont } from '@/components'
import { AddButton } from '.'

interface LabelProps {
  key: string
  value: string
}
interface FormListProps {
  value?: string
  type?: 'JSON' | 'FormData'
  disabled?: boolean
  onChange?: (value: string) => void
  variables?: Array<{ label: string; type?: string }>
  variableTipsContainer?: HTMLElement | null // 变量提示锚定位置  默认为编辑器本身
  placeholder?: string
}
const DEFAULT_VALUE: LabelProps[] = []

function decodeValue(value: string) {
  // const isPureString = value?.startsWith?.('{{') && value?.endsWith?.('}}')

  return value.replace(/{{/g, '"{{').replaceAll(/}}/g, '}}"')
}

function encodeValue(object: Record<string, any> | Array<any>) {
  const stringVal = JSON.stringify(object, null, 2)
  return stringVal.replaceAll('"{{', '{{').replaceAll('}}"', '}}')
}

function transformValue(value: string) {
  if (!value) return DEFAULT_VALUE

  try {
    const objectValue: any = JSON.parse(decodeValue(value))

    if (Array.isArray(objectValue)) {
      return objectValue.length ? objectValue : DEFAULT_VALUE
    } else if (isObject(objectValue)) {
      return isEmpty(objectValue)
        ? value
        : Object.entries(objectValue).map(([key, value]) => ({
            key,
            value: value as string,
          }))
    } else if (isString(objectValue)) {
      return value
    } else {
      return DEFAULT_VALUE
    }
  } catch (error) {
    return value as any
  }
}
export function FormList(props: FormListProps) {
  const { value, type = 'JSON', disabled, placeholder } = props

  const arrValue = useMemo<LabelProps[]>(() => {
    const val = transformValue(value || '')
    return type === 'FormData' && isString(val) ? DEFAULT_VALUE : val
  }, [value, type])

  const stringJsonValue = useMemo(() => {
    if (!value) return ''
    if (Array.isArray(arrValue)) {
      const filteredEmptyValue = arrValue.filter((v: any) => !!v.key)
      if (!filteredEmptyValue.length) {
        return JSON.stringify(arrValue)
      }

      return encodeValue(
        filteredEmptyValue.reduce((acc: any, cur: any) => {
          return { ...acc, [cur.key]: cur.value }
        }, {}),
      )
    }
    return value
  }, [arrValue, value])

  const handleEditorChange = (
    value: string,
    index: number,
    type = 'key' || 'value',
  ) => {
    const newValue = arrValue?.map((item, i) => {
      return index === i ? { ...item, [type]: value } : item
    })
    props.onChange?.(encodeValue(newValue))
  }

  const handleAdd = useCallback(() => {
    if (disabled) {
      return
    }
    props.onChange?.(encodeValue([...arrValue, { key: '', value: '' }]))
  }, [arrValue, props.onChange])

  const handleRemove = useCallback(
    (index: number) => {
      const newValue = arrValue.filter((_v, i) => i !== index)

      props.onChange?.(encodeValue(newValue))
    },
    [arrValue, props.onChange],
  )

  const handleJsonTypeValueChange = (value: string) => {
    const formattedValue = transformValue(value)

    props.onChange?.(
      !value
        ? value
        : isString(formattedValue)
        ? formattedValue
        : encodeValue(formattedValue),
    )
  }

  if (type === 'JSON')
    return (
      <CodeEditor
        minHeight='120px'
        mode='json'
        showGutter
        value={stringJsonValue}
        className='min-h-120px'
        onChange={handleJsonTypeValueChange}
        variableTipsContainer={props.variableTipsContainer}
        variables={props.variables}
        placeholder={
          placeholder ||
          `{
                    \xA0\xA0\xA0\xA0"id":1,
                    \xA0\xA0\xA0\xA0"name":{{code_1.name}},
                    \xA0\xA0\xA0\xA0"age": 10
                  }`
        }
      />
    )

  return (
    <div>
      {(arrValue || [])?.map?.((item, index) => {
        return (
          <div key={index} className='flex items-center mb-4'>
            <CodeEditor
              className='ace-gray h-32px w-96px mr-4'
              wrapEnabled={false}
              singleLine
              height='32px'
              value={item.key}
              width='100%'
              setOptions={{ maxLines: 1 }}
              onChange={value => handleEditorChange(value, index, 'key')}
              variableTipsContainer={props.variableTipsContainer}
              variables={props.variables}
              placeholder='key'
            />
            <CodeEditor
              className='ace-gray h-32px flex-1'
              wrapEnabled={false}
              singleLine
              height='32px'
              value={`${item.value}`}
              width='100%'
              setOptions={{ maxLines: 1 }}
              variableTipsContainer={props.variableTipsContainer}
              variables={props.variables}
              onChange={value => handleEditorChange(value, index, 'value')}
              placeholder='value'
            />
            <div
              onClick={() => handleRemove(index)}
              className='ml-4 mr--4px flex items-center justify-center w-20px h-20px text-opacity-60  text-font_1 rounded-4px hover:bg-error hover:bg-opacity-12 hover:text-error'
            >
              <IconFont name='guanbi' className='text-10px  cursor-pointer ' />
            </div>
          </div>
        )
      })}

      <AddButton onClick={handleAdd} disabled={disabled}>
        添加更多
      </AddButton>
    </div>
  )
}
