import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import { ChatHistoryItem } from '@bty/chat-ui'
import type { ConversationData } from '@bty/chat-types'
import { IconFont } from '@/components'
import { updateConversation, deleteConversation } from '@/apis/chatbot'
import { useChatStore } from '@/store/chat.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

const TIME_GROUPS: { key: string; label?: string; threshold: number }[] = [
  {
    key: 'inOneWeek',
    threshold: 0,
  },
  {
    key: 'aWeekAgo',
    label: '1周前',
    threshold: 7,
  },
  {
    key: 'aMonthAgo',
    label: '1个月前',
    threshold: 30,
  },
  {
    key: 'threeMonthsAgo',
    label: '3个月前',
    threshold: 90,
  },
  {
    key: 'sixMonthsAgo',
    label: '6个月前',
    threshold: 180,
  },
  {
    key: 'oneYearAgo',
    label: '1年前',
    threshold: 365,
  },
]

export interface HistoryListProps {
  className?: string
  currentConversation?: ConversationData
  conversations?: ConversationData[]
  loading?: boolean
  typingTitle: string
  typingFinished: boolean
  collapsed: boolean
  // todo: 💩
  prefixButton?: React.ReactNode
  onCollapse: (collapsed: boolean) => void
  onConversationClick: (id: ConversationData['conversation_id']) => void
  onConversationEdit: (action: 'create' | 'update' | 'delete') => void
}

function HistoryList({
  className,
  currentConversation,
  conversations,
  loading,
  collapsed,
  prefixButton,
  typingTitle,
  typingFinished,
  onCollapse,
  onConversationClick,
  onConversationEdit,
}: HistoryListProps) {
  const { unreadMessageCountMap } = useChatStore()
  const conversationGroups = useMemo(() => {
    if (!conversations?.length) {
      return []
    }

    const groups = TIME_GROUPS.reduce<
      Record<string, { label?: string; data: ConversationData[] }>
    >((map, value) => {
      map[value.key] = {
        label: value.label,
        data: [],
      }
      return map
    }, {})

    conversations.forEach(item => {
      const delta = dayjs().diff(dayjs(item.latest_create_time), 'day')

      const foundGroup = TIME_GROUPS.find((value, index, values) => {
        if (index === values.length - 1) {
          return delta >= value.threshold
        }
        return delta >= value.threshold && delta < values[index + 1].threshold
      })

      if (foundGroup) {
        groups[foundGroup.key].data.push(item)
      }
    })

    return Object.keys(groups).map(key => ({
      key,
      ...groups[key],
    }))
  }, [conversations])

  const handleConversationRename = async (id: string, title: string) => {
    await updateConversation(id, title)
    onConversationEdit('update')
  }

  const handleConversationDelete = async (id?: string) => {
    if (!id) {
      return
    }
    await deleteConversation(id)
    onConversationEdit('delete')
  }

  const handleConversationClick = (id?: string) => {
    if (!id) {
      return
    }
    onConversationClick(id)
  }

  const list = conversationGroups.map(group =>
    group.data.length ? (
      <div key={group.key} className='group'>
        <span className='block pl-12 text-font_1 text-12px/36px font-semibold'>
          {group.label}
        </span>
        {group.data.map(conversation => {
          const selected =
            currentConversation?.conversation_id ===
            conversation.conversation_id
          return (
            <ChatHistoryItem
              key={conversation.conversation_id}
              id={conversation.conversation_id}
              className='bg-transparent last:mb-0'
              actionWrapClassName=''
              selected={selected}
              content={
                selected
                  ? typingFinished
                    ? conversation.title
                    : typingTitle
                  : conversation.title
              }
              unReadCount={
                unreadMessageCountMap.conversation[conversation.conversation_id]
              }
              isTitleTyping={selected && !typingFinished}
              onClick={handleConversationClick}
              onEditSave={handleConversationRename}
              onDelete={handleConversationDelete}
            />
          )
        })}
      </div>
    ) : undefined,
  )

  const { scrollRef } = useScrollBar()

  return (
    <div
      className={cn(
        className,
        'flex flex-col border-right after:border-r-line after:border-op-60',
      )}
    >
      <div className='shrink-0 h-64px  flex items-center flex-nowrap gap-12 p-16'>
        {prefixButton}
        <button
          className='chat-icon-btn'
          onClick={() => onCollapse(!collapsed)}
        >
          <IconFont className='color-[#17171D] text-16px' name='shouqichouti' />
        </button>
        <button
          className='flex-1 inline-flex items-center justify-center gap-6 rounded-8px bg-white h-40px text-14px/16px font-medium text-font border border-line border-op-80 hover:[&:not(:disabled)]:btn-linear disabled:cursor-not-allowed break-keep'
          disabled={loading}
          onClick={() => onConversationEdit('create')}
        >
          <IconFont className='text-#17171d text-16px' name='tianjiahuihua' />
          新会话
        </button>
      </div>
      <div
        ref={scrollRef}
        className='flex-1 pt-10px p-16px overflow-x-hidden overflow-y-scroll'
      >
        <div>
          {list.length ? (
            list
          ) : (
            <div className='text-center text-font_1 text-opacity-60 text-14px/20px mt-60'>
              暂无会话
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(HistoryList)
