import classNames from 'classnames'

interface Props {
  active: boolean
  onClick: () => void
}

export function InsertButton(props: Props) {
  const { active, onClick } = props

  return (
    <div
      className={classNames(
        'flex-center w-48px h-32px rounded-6px b-dashed b-1 b-#7b61ff  hover:bg-#7b61ff font-500 hover:text-#fff',
        {
          'bg-#7b61ff text-#fff': active,
          'bg-#f4f4f6 text-#7b61ff': !active,
        },
      )}
      onClick={e => {
        // 防止冒泡触发上层onEdgeClick，造成二次插入
        e.stopPropagation()
        onClick()
      }}
    >
      插入
    </div>
  )
}
