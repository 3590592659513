import { Dropdown, Tooltip } from 'antd'
import React from 'react'
import { IconFont } from '@/components'

type MenuOption = Readonly<{ key: string; label: string }[]>

export interface DropdownControlProps<T extends MenuOption>
  extends React.PropsWithChildren {
  menu: T
  value?: string
  label: string
  tooltip?: string
  onChange: (key: T[number]['key']) => void
}

export function DropdownControl<T extends MenuOption>({
  menu,
  value,
  label,
  tooltip,
  children,
  onChange,
}: DropdownControlProps<T>) {
  const items = menu.map(el => ({
    ...el,
    label: <div className='text-14px/36px text-font pl-12'>{el.label}</div>,
  }))

  const handleClick = ({ key }: { key: string }) => {
    onChange(key)
  }

  return (
    <>
      <div className='flex items-center h-48px'>
        <span className='text-14px text-font font-medium'>{label}</span>
        <Tooltip title={tooltip} placement='top'>
          <IconFont
            name='jieshishuimeng'
            className='text-font_1 text-14px text-op-40 ml-4 cursor-pointer'
          />
        </Tooltip>
        <Dropdown
          overlayClassName='w-138px [&_.ant-dropdown-menu-item:hover]:!bg-bg_3 [&_.ant-dropdown-menu-item:hover]:!bg-op-8'
          menu={{ items, onClick: handleClick }}
          trigger={['click']}
        >
          <span className='cursor-pointer inline-flex items-center text-font ml-auto px-4 py-5 rounded-6px hover:bg-#E4E5ED'>
            {menu.find(el => el.key === value)?.label}
            <span className='w-16px h-16px inline-flex justify-center items-center'>
              <IconFont className='text-7px/16px text-font_1' name='arrow' />
            </span>
          </span>
        </Dropdown>
      </div>
      {children}
    </>
  )
}
