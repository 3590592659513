import { Popconfirm, Spin, message } from 'antd'
import classNames from 'classnames'
import { forwardRef, useImperativeHandle } from 'react'
import { useAutoFlowDesc } from '../hooks/useAutoFLowDesc'
import type { FlowSchema } from '@/apis/flow'

interface AutoDescButtonProps {
  onLoading?: (loading: boolean) => void
  onGenerated?: (data: FlowSchema) => any
  onConfirm?: () => void
  className?: string
  trigger?: 'click' | 'manual'
  description?: React.ReactNode
  flowId: string
  isDraft?: boolean
  okText?: string
  cancelText?: string
  children?: React.ReactNode
}

function DefaultDescription() {
  return (
    <div className='text-14px font-500'>
      <p>自动生成的内容将覆盖你当前的设置，</p>
      <p className='mt-6 mb-6'>确认要继续生成吗？</p>
    </div>
  )
}

export interface AutoDescButtonInstance {
  show: () => void
  hide: () => void
  generate: () => void
}

export const AutoDescButton = forwardRef<
  AutoDescButtonInstance,
  AutoDescButtonProps
>(function AutoDescButton(
  {
    onLoading,
    onGenerated,
    onConfirm,
    className,
    okText = '确认',
    cancelText = '取消',
    flowId,
    children,
    trigger = 'click',
    isDraft = false,
    description = <DefaultDescription />,
  },
  ref,
) {
  const {
    generating,
    generateFlowDesc,
    genPopConfirmVisible,
    showGenPopConfirm,
    hideGenPopConfirm,
  } = useAutoFlowDesc({ isDraft })

  const handleGenConfirm = async () => {
    if (onConfirm) {
      onConfirm()
      return
    }
    onLoading?.(true)

    const flowSchemaResult = await generateFlowDesc(flowId).finally(() => {
      onLoading?.(false)
    })

    message.success('自动生成已完成')

    onGenerated?.(flowSchemaResult)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        show: showGenPopConfirm,
        hide: hideGenPopConfirm,
        generate: handleGenConfirm,
      }
    },
    [showGenPopConfirm, hideGenPopConfirm, handleGenConfirm],
  )
  return (
    <Popconfirm
      title={null}
      overlayInnerStyle={{ padding: '16px' }}
      open={genPopConfirmVisible}
      description={description}
      icon={null}
      onCancel={hideGenPopConfirm}
      onConfirm={handleGenConfirm}
      okButtonProps={{
        size: 'middle',
        loading: generating,
        disabled: false,
      }}
      cancelButtonProps={{
        size: 'middle',
      }}
      okText={okText}
      cancelText={cancelText}
    >
      <div
        onClick={() => (trigger === 'click' ? showGenPopConfirm() : null)}
        className={classNames(className)}
      >
        <Spin spinning={generating} size='small'>
          {children}
        </Spin>
      </div>
    </Popconfirm>
  )
})
