import styled from '@emotion/styled'
import type { FC } from 'react'
import { useRef, useEffect, useState, useMemo } from 'react'
import classNames from 'classnames'
import { Tooltip, Select as AntdSelect } from 'antd'
import { useRequest } from 'ahooks'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { Select, IconFont, usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import { VariableRegex } from '@/constants/common'
import { getFileIconByFileTypeOrMimeType } from '@/features/datastore/utils'
import { Empty } from '../components/Empty'

interface DocumentSelectProps {
  value?: number
  onChange?: (value: number) => void
  memoryId?: number
  isMultiple?: boolean
}

const StyledSelect = styled(Select)`
  && {
    .ant-select-selector {
      height: auto;
      .ant-select-selection-item {
        padding-inline-end: 12px !important;
        .link-action {
          opacity: 1;
        }
      }
      .ant-select-selection-search {
        display: flex;
        align-items: center;
      }
    }

    &.ant-select-sm {
      .ant-select-selector {
        height: auto;
        padding: 4px 12px;

        .ant-select-selection-placeholder {
          line-height: 23px;
        }
        .ant-select-selection-item {
          height: 23px;
        }
      }
    }

    &.ant-select-multiple {
      &.ant-select-sm {
        .ant-select-selector {
          padding-inline-end: 24px;
        }
      }
      .ant-select-selection-item {
        margin: 2px 4px 2px 0;
      }
    }
  }
`

const { Option } = AntdSelect

const OptionContent = styled.div`
  &:hover {
    .link-action {
      opacity: 1;
    }
  }
`

export const DocumentSelect: FC<DocumentSelectProps> = props => {
  const ref = useRef<HTMLDivElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const getDatasetDocuments = useNodeMetaStore(
    state => state.getDatasetDocuments,
  )
  const datasetDocumentsMap = useNodeMetaStore(
    state => state.datasetDocumentsMap,
  )
  const isFirstLoad = useRef(true)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const pageModal = usePageModal()
  const documents = props.memoryId
    ? datasetDocumentsMap[props.memoryId] ?? []
    : []
  const fetchDocumentList = async (force = true) => {
    if (props.memoryId && !VariableRegex.test(String(props.memoryId))) {
      getDatasetDocuments(props.memoryId, force)
      isFirstLoad.current = false
    }
    return true
  }

  useRequest(fetchDocumentList, {
    manual: true,
    refreshOnWindowFocus: true,
  })

  useEffect(() => {
    fetchDocumentList(isFirstLoad.current)
  }, [props.memoryId])

  const filteredDocuments = useMemo(() => {
    if (!searchValue.trim()) {
      return documents
    }
    return documents.filter(item => {
      return item.file_name.toLowerCase().includes(searchValue.toLowerCase())
    })
  }, [documents, searchValue])

  return (
    <div ref={ref}>
      <StyledSelect
        mode={props.isMultiple ? 'multiple' : undefined}
        size='small'
        virtual={false}
        className='nodrag nopan'
        value={props.value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onChange={props.onChange}
        placeholder='请选择数据文件'
        notFoundContent={
          searchValue && filteredDocuments.length === 0 ? <Empty /> : <div />
        }
        getPopupContainer={() => ref.current!}
        showSearch
        searchValue={searchValue}
        onSearch={value => {
          setSearchValue(value)
        }}
        filterOption={false}
        dropdownRender={menu => {
          return (
            <>
              {menu}
              <div
                className={classNames({
                  'b-t-1 b-t-line b-t-op-60 mt-4 pt-4': documents.length,
                })}
              >
                <div
                  className='w-100% h-32px cursor-pointer flex-center text-12px hover:bg-bg_3 hover:bg-op-8 rounded-6px'
                  onClick={e => {
                    e.stopPropagation()
                    pageModal.show({
                      url: `/datastores/${currentWorkspaceId}/${props.memoryId}/documents`,
                    })
                  }}
                >
                  <IconFont name='add' className='text-16px' />
                  创建数据文件
                </div>
              </div>
            </>
          )
        }}
      >
        {filteredDocuments.map(item => {
          return (
            <Option
              key={item.file_id}
              label={item.file_name}
              value={item.file_id}
              className='important:pr-4px'
            >
              <OptionContent className='flex items-center h-full flex-1'>
                <span className='px-2px py-1px mr-4px '>
                  <img
                    className='w-14px h-16px shrink-0'
                    src={getFileIconByFileTypeOrMimeType(
                      item.mimetype,
                      item.file_type,
                    )}
                    alt=''
                  />
                </span>
                <span className='flex-1 min-w-0 truncate'>
                  {item.file_name}
                </span>
                <span
                  className='link-action shrink-0 w-24px h-24px rounded-4px hover:bg-bg_3 hover:bg-op-12 cursor-pointer flex-center opacity-0'
                  onClick={() => {
                    pageModal.show({
                      url: `/datastores/${currentWorkspaceId}/${item.partition_id}/${item.file_id}/content`,
                    })
                  }}
                >
                  <Tooltip placement='top' title='查看文档详情'>
                    <IconFont
                      name='super-link'
                      className='c-#626999 c-op-60 text-16px shrink-0'
                    />
                  </Tooltip>
                </span>
              </OptionContent>
            </Option>
          )
        })}
      </StyledSelect>
    </div>
  )
}
