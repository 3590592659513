import { useSearchParams } from 'react-router-dom'

export function IframeNestedAuthComponent(props: React.PropsWithChildren) {
  const { children } = props
  const [searchParams] = useSearchParams()
  const noLayout = searchParams.get('independent') === '1'

  return noLayout ? null : children
}

export default IframeNestedAuthComponent
