import { request } from './lib'

export enum StaticTimeTypes {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
  CUMULATIVE = 'cumulative',
  CUSTOM = 'custom',
}

export type AppMonitorType = 'AGENT' | 'FLOW'

interface AppMonitorOverviewBaseParams {
  flow_id?: string
  flow_type?: AppMonitorType
  run_type?: string[]
}

interface AppMonitorOverviewParams extends AppMonitorOverviewBaseParams {
  statistic_time: StaticTimeTypes
  start_date?: string
  end_date?: string
}

export interface AppMonitorOverviewResponse {
  run_count: number
  unique_users: number
  consumed_points: number
  run_count_change?: number
  unique_users_change?: number
  consumed_points_change?: number
}

export function getAppMonitorDailyOverview(
  params: AppMonitorOverviewBaseParams,
) {
  return request.get<AppMonitorOverviewResponse>(
    '/v1/app_monitor/usage-daily-overview',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

export function getAppMonitorOverview(params: AppMonitorOverviewParams) {
  return request.get<AppMonitorOverviewResponse>(
    '/v1/app_monitor/usage-overview',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

export type ListPageParams<T> = {
  page: number
  page_size: number
} & T

export interface ListResponse<T> {
  totals: number
  page: number
  page_size: number
  rows: T[]
}

export interface AppUsageItem {
  record_date: string
  run_count: number
  unique_users: number
  consumed_points: number
}

export type AppUsageRankingParams = AppMonitorOverviewParams & {
  search_content?: string
  sort_field?: string
  sort_order?: 'desc' | 'asc'
}

export interface AppUsageRankingItem {
  consumed_points: number
  usage_count: number
  user_id: string
  username: string
}

interface ErrorRecordItem {
  record_date: string
  run_type: string
  user_id: string
  username: string
}

export interface OvertimeRecordItem {
  id: string | number
  irl_time: number // 秒
  biz_id: string // 作为查找记录的record_id
  flow_id: string
  flow_name: string
  user_name: string
  run_type: string
  created_at: string
}

// 用量明细
export function getAppMonitorUsageDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<AppUsageItem>>(
    '/v1/app_monitor/usage-detail',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

// 空间用量明细
export function getWorkspaceMonitorUsageDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<any>>(
    '/v1/app_monitor/workspace-application-usage-detail',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

// 排行榜明细
export function getAppMonitorUsageRankingDetail(
  params: ListPageParams<AppUsageRankingParams>,
) {
  return request.get<ListResponse<AppUsageRankingItem>>(
    '/v1/app_monitor/user-usage-ranking-detail',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

// 工作空间维度异常
export function getWorkspaceErrorRecordsDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<ErrorRecordItem>>(
    '/v1/app_monitor/workspace-error-records-detail',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

// 工作空间维度超时异常
export function getWorkspaceOverTimeDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<ErrorRecordItem>>(
    '/v1/app_monitor/flow-irl-count',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

// 错误明细
export function getAppMonitorErrorRecordsDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<ErrorRecordItem>>(
    '/v1/app_monitor/dialogue-error-records-detail',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

// 超时明细
export function getAppMonitorOvertimeDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<OvertimeRecordItem>>(
    '/v1/app_monitor/irl-detail',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

export interface ChatRecordsParams {
  record_id: string | number
  limit: number
}

interface AgentChatRecordsParams {
  robot_id: string
  page_no: number
  page_size: number
  record_status: 'SUCCEEDED' | 'FAILED'
  user_name?: string
  run_type?: string[]
  start_time?: string
  end_time?: string
}

export interface AgentChatRecordItem {
  create_time: string
  record_id: string
  record_status: 'SUCCEEDED' | 'FAILED'
  run_type: string[]
  uid: number | string
  user_name: string
}

interface AgentChatRecordsResponse {
  data: AgentChatRecordItem[]
  total: number
  page_no: number
  page_size: number
}

export function fetchAgentChatRecords(body: AgentChatRecordsParams) {
  return request.post<AgentChatRecordsResponse>('/v1/chat/agent_log', body)
}

interface ChatRecordItem {
  id: string
  question: string
  response: string
  conversation_id: string
  session_id: string
  uid: number
  create_time: string
}

export function getPrevChatRecordsById(params: ChatRecordsParams) {
  return request.get<ChatRecordItem[]>(
    '/v1/chat/records/previous_limit_records',
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  )
}

interface UsageTrendRequest extends AppMonitorOverviewParams {
  display_time: string
}
export interface UsageTrendType {
  title: string
  value: { display_time: string; value: number }[]
}
export function queryUsageTrend(
  params: UsageTrendRequest,
): Promise<UsageTrendType[]> {
  return request.get('/v1/app_monitor/usage-trend', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

export function queryWorkspaceUsageTrend(
  params: UsageTrendRequest,
): Promise<UsageTrendType[]> {
  return request.get('/v1/app_monitor/workspace-usage-trend', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

interface UserUsageRankRequest extends AppMonitorOverviewParams {
  limit: number
}
export interface UserUsageRankType {
  title: string
  value: { user_id: number; username: string; value: number }[]
}
export function queryUserUsageRank(
  params: UserUsageRankRequest,
): Promise<UserUsageRankType[]> {
  return request.get('/v1/app_monitor/user-usage-ranking', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

export function queryFlowUsageRank(
  params: AppMonitorOverviewParams,
): Promise<any[]> {
  return request.get('/v1/app_monitor/flow-usage-ranking', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

interface AppStabilityRequest extends AppMonitorOverviewParams {
  display_time: string
}
export type AppStabilityType = UsageTrendType
export function queryAppStability(
  params: AppStabilityRequest,
): Promise<AppStabilityType[]> {
  return request.get('/v1/app_monitor/dialogue-error-records', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

type IrlTrendRequest = AppStabilityRequest
export type IrlTrendType = AppStabilityType
export function queryIrlTrend(params: IrlTrendRequest): Promise<IrlTrendType> {
  return request.get('/v1/app_monitor/irl-trend', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

export function getIrlTimeout(
  flowId: string,
): Promise<{ irl_time_out?: number } | null> {
  return request.get(`/v1/app_monitor/conf/${flowId}`)
}

export function updateIrlTimeout(flowId: string, value: number) {
  return request.post(`/v1/app_monitor/conf/${flowId}`, { irl_time_out: value })
}

export interface WorkspaceUsageOverview extends Record<string, number> {
  daily_consumed_points: number
  daily_consumed_points_change: number
  weekly_consumed_points: number
  weekly_consumed_points_change: number
  monthly_consumed_points: number
  monthly_consumed_points_change: number
}
export function getWorkspaceUsageOverview(
  params: AppMonitorOverviewParams,
): Promise<WorkspaceUsageOverview> {
  return request.get('/v1/app_monitor/workspace-usage-overview', {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
}

// 通过当前flowId或agentId查找旧的applicationId
export function getFormalApplicationId(id: string) {
  return request.get(`/v1/flow/detail?flow_id=${id}`)
}
