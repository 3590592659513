import { useMemo } from 'react'
import { useRequest } from 'ahooks'
import { LLMMessageStructType } from '../../utils/llm'
import { getLLMModelContextExample } from '@/apis/llm/api'
import { BaiduContextExampleValue, GPTContextExampleValue } from '../const'

interface LLMContextExampleProps {
  model?: string
  modelMessageStructType?: LLMMessageStructType
}

export function useLLMContextExample(props: LLMContextExampleProps): {
  llmContextExample: string
} {
  const { model, modelMessageStructType } = props
  const { data: llmContextExampleDict } = useRequest(() =>
    getLLMModelContextExample(),
  )

  const llmContextExample = useMemo(() => {
    if (model && llmContextExampleDict?.[model]) {
      return JSON.stringify(llmContextExampleDict[model], null, 2)
    } else {
      return modelMessageStructType === LLMMessageStructType.LIKE_GPT
        ? GPTContextExampleValue
        : BaiduContextExampleValue
    }
  }, [llmContextExampleDict, model, modelMessageStructType])
  return { llmContextExample }
}
