import type {
  DocumentSplitType,
  DocumentStatus,
  ProgressStage,
  DocumentType,
  ParagraphExtraInfoStatus,
  ExtraParagraphExtraInfoStatus,
} from '@/apis/datastore/model.ts'

export interface IParagraphExtraInfo {
  chunk_status?: ParagraphExtraInfoStatus
  enable_ai_support?: boolean // 是否支持ai处理
  ai_created?: boolean // 是否是ai生成
  page_bboxes?: {
    page: number
    bbox: number[]
  }[] // pdf定位信息
  [key: string]: any
}

export enum PartitionCategoryType {
  Document = 'document_repo',
  QA = 'qa_repo',
}

export interface DataStoreItem {
  partition_category?: PartitionCategoryType
  partition_id: number
  partition_name: string
  partition_describe?: string
  partition_icon: string
  partition_icon_color: string
  partition_group_id?: string
  file_num: number
  application: string[]
  oss_path?: string
  enable: boolean // 预留字段，表示知识库是否可用
  total_text_length?: number
  partition_model?: string
  workspace_id?: string
  update_user?: string
  update_time?: string
  partition_size?: string
}

export interface DataStoreResponse {
  partitions: DataStoreItem[]
}

export type CreatePartitionRequest = Omit<
  DataStoreItem,
  'application' | 'enable' | 'file_num' | 'partition_id'
>
export type UpdatePartitionRequest = Pick<DataStoreItem, 'partition_id'>
interface DocumentFile {
  file_name: string
  mimetype: string
  file_type: DocumentType
  enable: boolean
}
export interface CreateTextDocumentRequest {
  partition: CreatePartitionRequest | UpdatePartitionRequest
  files: Array<DocumentFile & { file_content: string }>
}
export interface CreateFileDocumentRequest {
  partition: CreatePartitionRequest | UpdatePartitionRequest
  files: Array<DocumentFile & { oss_path: string; file_size: number }>
}

export interface BaseDocument {
  mimetype: string
  extracted_text_length: number
  enable: boolean
  file_type: DocumentType
  file_name: string
  tags: string[]
}

export interface BaseSplitConfig {
  rule_number: Exclude<
    DocumentSplitType,
    DocumentSplitType.CUSTOM | DocumentSplitType.CUSTOM_FAQ
  >
  remove_sensitive_data: boolean
}

export interface CustomSplitConfig {
  rule_number: DocumentSplitType.CUSTOM | DocumentSplitType.CUSTOM_FAQ
  max_length: number
  split_character: string // DocumentSplitType.CUSTOM_FAQ 消费的字段
  split_pattern: string // DocumentSplitType.CUSTOM 消费的字段
  remove_sensitive_data: boolean
}

export type SplitConfig = BaseSplitConfig | CustomSplitConfig

export interface DocumentItem extends BaseDocument {
  file_name: string
  file_size: number
  file_id: number
  status: DocumentStatus
  progress_stage: ProgressStage | null
  oss_path: string
  partition_id: number
  update_time: string
  // 文档详情中会有:
  extracted_text?: string
  split_config?: SplitConfig
  failed_reason?: string
  problem_reported?: boolean
  extra_info: {
    error_count?: number
    process_content_count?: number
  }
}

export interface IDocumentListReq {
  partitionId: number
  file_name?: string
  tags?: string[]
  file_status?: any
  page?: number
  page_size?: number
  sort_order?: string
  sort_by?: string
}

export type UpdateDataStoreInfoRequest = Pick<
  DataStoreItem,
  | 'partition_id'
  | 'partition_icon'
  | 'partition_icon_color'
  | 'partition_describe'
  | 'partition_name'
  | 'partition_group_id'
>

export interface QAQuestion {
  id: string | number
  new?: boolean
  value: string
  keywords: string[]
}

export interface QAAnswer {
  id: string | number
  new?: boolean
  value: string
  title: string
}

export interface BaseParagraphItem {
  vector_id: string
  sort_order: number
  chunk_id: number
  content: string
  enable: boolean
  file_id: number
  keywords: string[]
  extra_info?: IParagraphExtraInfo
  // TODO QA 专用 后续可拆
  qa_info?: {
    questions: QAQuestion[]
    answers: QAAnswer[]
  }
}

export type HitItem = BaseParagraphItem & {
  file_name: string
  distance: number
  mimetype: string
  relevance_score: {
    reranker: number
    keywords: number
    vector: number
  }
  file_type: DocumentType
  matched_keywords: string[]
  // TODO QA 专用 分组 ID
  answer_group_id?: number
}

export interface SearchResponse {
  match_contents: HitItem[]
  usage: {
    total_tokens: number
  }
  cost_time: number
}

export interface SearchRequest {
  // match_threshold: number // 匹配度 //后端改造后，该字段不需要
  content: string
  tags?: string[]
  partition_id: number // 指定知识库
  file_ids?: number | number[] // 指定文档
  top_k: number // 返回结果数量
}

export interface SearchHistoryItem {
  update_time: string
  id: number
  user_id: number
  content: string
}

export interface SearchHistoryRequest {
  page: number
  pageSize: number
  partition_id?: number
  file_ids?: number | number[]
}

export type DocumentUpdateRequest = {
  file_id: number
} & Partial<BaseDocument>

export interface DocumentSplitRequest {
  file_id: number
  split_config: DocumentItem['split_config']
}

export interface SplitContentRequest {
  direction?: 'up' | 'down'
  need_total?: boolean
  prev_chunk_id?: number
  next_chunk_id?: number
  page_size: number
  file_id: number
  search_words?: string
  chunk_status?: ParagraphExtraInfoStatus | ExtraParagraphExtraInfoStatus
}

export type SplitItem = Pick<
  HitItem,
  | 'vector_id'
  | 'chunk_id'
  | 'content'
  | 'file_id'
  | 'enable'
  | 'keywords'
  | 'extra_info'
  | 'qa_info'
>

export interface SplitContentResponse {
  contents: SplitItem[]
  current_index_id: number
  prev_chunk_id: number
  next_chunk_id: number
  page_size: number
  totals: number
}

export interface UpdateSplitContentRequest {
  file_id: number
  chunk_id: number
  content: string
  enable: boolean
  keywords?: string[]
  extra_info?: Record<string, any>
}

export interface AddSplitContentRequest {
  file_id: number
  content: string
  enable: boolean
  keywords?: string[]
  insert_above_chunk_id?: number
  extra_info?: Record<string, any>
}

export interface AssociatedApplication {
  flow_id: string
  id: number
  description: string | null
  app_id: string
  flow_status: 'DRAFT' | 'PUBLISHED'
  application_type: 'AI' | 'AGENT'
  version_status: string
  update_at: string
  flow_name: string
  workspace_id: string
  version_id: string
  create_at: string
  is_deleted: boolean
}

interface SplitOperation {
  action: 'split'
  original_identifier: string | number
  identifier: string | number
}

interface MergeOperation {
  action: 'merge'
  identifier: string | number
  merge_identifiers: (number | string)[]
}

interface UpdateOperation {
  action: 'update'
  identifier: string | number
  enable: boolean
}

interface DeleteOperation {
  action: 'delete'
  identifier: string | number
}

export type Operation =
  | SplitOperation
  | MergeOperation
  | UpdateOperation
  | DeleteOperation

export interface UpdateDocumentBySplitRequest {
  file_id: number
  operations: Operation[]
  content_updates: Record<string | number, string>
  keywords_updates?: Record<string | number, string[]>
  extra_info_updates?: Record<string | number, Record<string, string | number>>
}

export interface QAGroupItem {
  answer_group_id: number
  contents: BaseParagraphItem[]
  answers: Record<string, string>
}

export interface QAContentResponse {
  groups: QAGroupItem[]
  current_index_id: number
  prev_group_id: number
  next_group_id: number
  page_size: number
  totals: number
}

export interface IQAQuestionItem {
  chunk_id?: number | string
  content: string
  keywords: string[]
  enable: boolean
}

export interface SaveQAGroupRequest {
  file_id: number
  answer_group_id?: number
  insert_above_answer_group_id?: number
  chunks_to_delete?: number[]
  contents: IQAQuestionItem[]
  answers: Record<string, string>
}

export interface DeleteQAGroupRequest {
  file_id: number
  answer_group_id?: number
}
