import type { IHead, IRow } from '@/components/resize-table/type'
import { getIcon } from '../icon'
import { InputRender } from './input'
import { AreaRender } from './area'
import { SelectRender } from './single-select'
import { MultipleSelectRender } from './multiple-select'
import { UploadRender } from './upload'
import { SimilarityRender } from './similarity'
import { OutputRender } from './output'
import { RemarksRender } from './remarks'

export function getComponent(type: string) {
  switch (type) {
    case 'input':
      return InputRender
    case 'textarea':
      return AreaRender
    case 'select':
      return SelectRender
    case 'multiSelect':
      return MultipleSelectRender
    case 'json':
      return AreaRender
    case 'file':
      return UploadRender
    case 'similarity':
      return SimilarityRender
    case 'output':
      return OutputRender
    case 'remarks':
      return RemarksRender
    default:
      return () => <div></div>
  }
}

export function getRender(
  type: string,
  onChange: (value: any, path: string[]) => void,
  validate?: boolean,
) {
  const Component = getComponent(type)
  return (value: any, cell: IRow, head: IHead) => {
    return (
      <Component
        className={`test-table-form-cell-${type}`}
        validate={validate}
        value={value}
        cell={cell}
        head={head}
        onChange={onChange}
      />
    )
  }
}

export function getTitle(type: string, title: string, require?: boolean) {
  return (
    <>
      <span className='text-16px mr-4px'>{getIcon(type)}</span>
      <span className='truncate'>{title}</span>
      {require && (
        <span className='color-error ml-4px text-16px relative top-3px'>*</span>
      )}
    </>
  )
}
