import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useRequest } from 'ahooks'
import Logo from '@/assets/logo-square.svg?url'
import { Button, IconFont } from '@/components'
import { PRODUCT_BASE_NAME } from '@/constants/common.ts'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

interface VideoWithButtonProps {
  videoUrl: string
  label?: string | null
}
const introduces = [
  {
    title: '版本',
    label: '1.1',
  },
  {
    title: '大小',
    label: '210k',
  },
  {
    title: '开发者',
    label: 'BetterYeah',
  },
]
const videoUrl = [
  {
    url: 'https://resource.bantouyan.com/battleyeah-ai/home/use-plugin01.mov',
    label: '01、【自动抓取】第三方文档添加到BatterYeah知识库',
  },
  {
    url: 'https://resource.bantouyan.com/battleyeah-ai/home/use-plugin02.mov',
    label: '02、【一键解析】网页数据并添加到BatterYeah知识库',
  },
  {
    url: 'https://resource.bantouyan.com/battleyeah-ai/home/use-plugin03.mov',
    label: '03、【手动选择】网页数据解析并添加到BatterYeah知识库',
  },
]

const HomePage = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #fff;
  display: grid;
  justify-content: center;
  grid-template-columns: 288px 832px;
  grid-gap: 40px;
  overflow-x: auto;
  ::before {
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    z-index: 1;
    height: 50%;
    background: linear-gradient(
      287deg,
      rgba(161, 0, 255, 0.09) 16%,
      rgba(105, 85, 255, 0.12) 46%,
      rgba(168, 203, 255, 0.17) 73%,
      rgba(168, 203, 255, 0) 92%
    );
  }
  ::after {
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 50%;
    z-index: 2;
    background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0) 90%);
  }
`
const HighLightDiv = styled.div`
  padding: 16px;
  font-weight: 500;
  background: rgba(0, 133, 242, 0.1);
  border-radius: 4px;
  border: 1px solid rgb(205, 205, 205, 0.6);
  border-left-width: 4px;
  border-left-color: rgb(0, 133, 242);
`

const VideoWidthButton: React.FC<VideoWithButtonProps> = ({
  videoUrl,
  label,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isPlaying, setPlaying] = useState(false)
  const handleVideoIconClick = () => {
    if (videoRef.current) {
      setPlaying(!isPlaying)
      isPlaying ? videoRef.current.pause() : videoRef.current.play()
    }
  }
  return (
    <>
      {label && <div className='mt-20px'>{label}</div>}
      <div className='relative w-832px'>
        <video
          ref={videoRef}
          className='rounded-8px mt-20px w-832px'
          src={videoUrl}
          controls
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
        ></video>
        {!isPlaying && (
          <button
            className='absolute top-50% left-50% rounded-full transform-translate-x-[-50%] transform-translate-y-[-50%] w-72px h-72px  bg-white shadow-[0px_0px_17.28px_0px_rgba(0,0,0,0.16)]'
            onClick={handleVideoIconClick}
          >
            <IconFont name='yunxing' className='mx-auto text-22px  c-#7B61FF' />
          </button>
        )}
      </div>
    </>
  )
}

function DataStorePlugin() {
  const pluginURl = () => {
    const pluginUrl = [
      'https://bty-crx.oss-cn-hangzhou.aliyuncs.com/betteryeah-extension/',
      '.zip',
    ]
    const config: any = {
      local: 'dev/betteryeah_ext_0508',
      development: 'dev/betteryeah_ext_0508',
      pre: 'pre/betteryeah_ext_0508',
      production: 'prod/betteryeah_ext_0508',
    }
    return (
      pluginUrl[0] + config[process.env.NODE_ENV || 'production'] + pluginUrl[1]
    )
  }
  const downloadFile = (url: string, fileName: string) => {
    try {
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = fileName // 设置下载的文件名
      downloadLink.click()
    } catch (error) {
      console.error('文件下载出错：', error)
    }
  }

  const { fetchWorkspaceList, setCurrentWorkspace, currentWorkspaceId } =
    useWorkspaceStore(state => ({
      currentWorkspaceId: state.currentWorkspaceId,
      fetchWorkspaceList: state.fetchWorkspaceList,
      setCurrentWorkspace: state.setCurrentWorkspace,
    }))

  const { fetchVersionByWorkspaceId } = useVersionStore(state => ({
    fetchVersionByWorkspaceId: state.fetchVersionByWorkspaceId,
  }))

  const { loading } = useRequest(
    async () => {
      await Promise.all([
        fetchWorkspaceList(),
        fetchVersionByWorkspaceId(currentWorkspaceId),
      ])
      setCurrentWorkspace(currentWorkspaceId)
    },
    {
      refreshDeps: [currentWorkspaceId],
    },
  )

  return (
    <HomePage>
      <aside className='w-288px h-[calc(100%-120px)] px-40px border-r-1 border-solid border-[rgba(225, 225, 229, 0.6)] z-3 my-60px'>
        <div className='fixed top-60px'>
          <div
            className='w-60px h-60px rounded-14px bg-#fff border border-[rgba(225, 225, 229, 0.6)] flex justify-center items-center'
            style={{ boxShadow: '0px 4px 16px 0px rgba(23, 26, 29, 0.1)' }}
          >
            {' '}
            <img src={Logo} alt='' className='w-40px h-35.4px' />{' '}
          </div>
          <span className='font-600 text-16px/16px mt-20px block'>
            {PRODUCT_BASE_NAME}网页解析插件
          </span>
          <LimitedAccess
            limitedType={!loading && 'shouldCheckTeamVersion'}
            limitFeatureName='知识库插件'
          >
            <Button
              type='primary'
              className='mt-20px w-208px'
              icon={<IconFont name='xiazai' />}
              style={{ height: '40px' }}
              onClick={() =>
                downloadFile(pluginURl(), `${PRODUCT_BASE_NAME}网页解析插件`)
              }
              loading={loading}
            >
              一键下载
            </Button>
          </LimitedAccess>
          <div className='mt-32px'>
            {introduces.map((item, index) => (
              <div key={index.toString()} className='mb-32px'>
                <span className='text-14px/14px font-600 block'>
                  {item.title}
                </span>
                <span className='text-14px/14px font-400 block mt-8px'>
                  {item.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      </aside>
      <article className='z-3 w-832px mt-60px pb-60px'>
        <span className='font-600 text-20px/20px block'>概述</span>
        <span className='font-400 text-16px/16px tracking-normal block mt-20px'>
          在chrome浏览器上使用该插件，实时获取网页数据到Better Yeah AI知识库
        </span>
        <span className='font-600 text-20px/20px block mt-48px'>安装</span>
        <span className='font-400 text-16px/16px tracking-normal block mt-20px'>
          第一步：点击【一键下载】并解压插件
        </span>
        <span className='font-400 text-16px/16px tracking-normal block mt-20px leading-28px'>
          第二步：点击chrome浏览器——点击【扩展程序】-【管理扩展程序】——打开【开发者模式】——点击【已解压的扩展程序】——选择安装已解压的插件文件
        </span>
        <VideoWidthButton videoUrl='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/battleyeah-ai/home/plugin-installation-20231123.mov' />
        <span className='font-600 text-20px/20px block mt-48px mb-20px'>
          使用
        </span>
        <HighLightDiv>
          请注意：如更新插件后无法打开浮窗，请刷新页面或重启浏览器
        </HighLightDiv>
        <VideoWidthButton
          videoUrl={videoUrl[0].url}
          label={videoUrl[0].label}
        />
        <VideoWidthButton
          videoUrl={videoUrl[1].url}
          label={videoUrl[1].label}
        />
        <VideoWidthButton
          videoUrl={videoUrl[2].url}
          label={videoUrl[2].label}
        />
      </article>
    </HomePage>
  )
}

export default DataStorePlugin
