import type { ChangeEvent, KeyboardEvent } from 'react'
import { memo, useRef } from 'react'
import { useBoolean, useClickAway, useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { IconFont, Input } from '@/components'

interface EditableTextProps {
  index: number
  value: string
  onChange: (value: string, index: number) => void
}

export const EditableText = memo((props: EditableTextProps) => {
  const { index, value, onChange } = props

  const ref = useRef<HTMLDivElement>(null)
  const [isEdit, { setFalse, setTrue }] = useBoolean(false)

  const handleEnter = useMemoizedFn((event: KeyboardEvent) => {
    setFalse()
    onChange((event.target as HTMLInputElement).value, index)
  })

  const handleChange = useMemoizedFn((event: ChangeEvent) => {
    onChange((event.target as HTMLInputElement).value, index)
  })

  useClickAway(() => {
    if (isEdit) {
      setFalse()
    }
  }, ref)

  return (
    <div className='inline-flex items-center' ref={ref}>
      {isEdit && (
        <Input
          size='small'
          value={value}
          maxLength={10}
          count={{
            show: true,
            max: 10,
          }}
          className='w-200px h-24px! text-14px! c-#17171D font-medium'
          autoFocus
          onPressEnter={handleEnter}
          onChange={handleChange}
        />
      )}

      <div
        className={classNames('flex text-14px c-#17171D font-medium', {
          hidden: isEdit,
        })}
      >
        {value}
      </div>

      <div
        className={classNames('ml-4px p-4px hover:bg-#62696D/8 rounded-8px', {
          hidden: isEdit,
        })}
      >
        <IconFont
          onClick={setTrue}
          name='bianji'
          className='text-16px c-#626999/60 cursor-pointer'
        />
      </div>
    </div>
  )
})
