import { Handle, Position } from 'reactflow'
import { useMemo } from 'react'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'
import {
  HandleStatus,
  statusStyles,
} from '@/features/flow/components/DefaultHandle'
import { useFlowInteractionStore } from '@/store'

export const ConditionResultNode: NodeComponent<any> = props => {
  const { id } = props
  const { dangerStateNodeIds, activeHandles } = useFlowInteractionStore()

  const style = useMemo(() => {
    const isDanger = dangerStateNodeIds.includes(id)
    const isResultHandleActive = activeHandles.some(item => item.startsWith(id))
    if (isResultHandleActive) {
      return statusStyles[HandleStatus.EDGE_HOVERING]
    }
    if (isDanger) {
      return statusStyles[HandleStatus.TO_BE_REMOVED]
    }
    return {}
  }, [id, dangerStateNodeIds, activeHandles])

  return (
    <div
      className='flex-center w-10px h-10px b-rd-50% bg-#f4f4f6 b-#c5c5cc b-1 shadow-[0_0_0_2px_#f4f4f6]'
      style={style}
    >
      <div></div>
      <Handle
        className='!w-0 !h-0 !min-w-0 !min-h-0'
        type='source'
        position={Position.Right}
        style={{ top: 0, right: 5 }}
      />
      <Handle
        className='!w-0 !h-0 !min-w-0 !min-h-0'
        type='target'
        position={Position.Left}
        style={{ top: 0 }}
      />
    </div>
  )
}

ConditionResultNode.meta = {
  type: NodeType.CONDITION_RESULT,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'CONDITION_RESULT',
  typeName: 'condition_result',
  isToolNode: true,
}
