import { Empty, Popconfirm, Tooltip } from 'antd'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import type { KeyboardEvent } from 'react'
import { memo, useMemo, useRef, useState } from 'react'
import { useMemoizedFn, useRequest, useThrottleFn } from 'ahooks'
import classNames from 'classnames'
import { ReactComponent as AICreate } from '@/assets/agent/ai-create.svg'
import { Button, IconFont, Markdown } from '@/components'
import type { IERef } from '@/features/editor/PlaceholderEditor'
import { PlaceholderEditor } from '@/features/editor/PlaceholderEditor'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import type { IPromptTemplate, PromptStructItem } from '@/apis/prompt'
import { getPromptTemplate } from '@/apis/prompt'
import { tokenStorage } from '@/utils/storage'
import {
  State,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket'
import { tryGetObject } from '@/features/logger/components/loggerModal/util'
import { getPromptFromStructPrompt } from '../../utils/prompt'

interface CreateModalProps {
  templateType?: 'Agent' | 'Flow'
  getPrompt: () => string
  onConfirm: (struct: PromptStructItem[]) => void
}

export const CreateModal = memo((props: CreateModalProps) => {
  const { getPrompt, onConfirm, templateType = 'Agent' } = props
  const token = tokenStorage.get()
  const { data: templateList } = useRequest(getPromptTemplate)

  const eRef = useRef<IERef>()
  const [text, setText] = useState('')
  const [activeIndex, setActiveIndex] = useState(-1)
  const [prompt] = useState(getPrompt)
  const [twiceConfirm, setTwiceConfirm] = useState(false)

  const handleUseTemplate = useMemoizedFn(
    (temp?: IPromptTemplate, index?: number) => {
      setActiveIndex(index ?? -1)
      if (!temp) {
        eRef.current?.setTemplate?.('')
        eRef.current?.setOptionMap({})
        return
      }
      eRef.current?.setTemplate?.(temp.prompt)
      eRef.current?.setOptionMap(temp.ext)
    },
  )

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const {
    state,
    content,

    handleSendMessage,
    handleStop,
  } = useGeneratePromptSocket(socketUrl)

  const handleGenerate = useMemoizedFn(() => {
    handleSendMessage(
      JSON.stringify({
        action:
          templateType === 'Agent'
            ? 'struct_prompt_template'
            : 'struct_prompt_template_flow',
        params: {
          message: text,
          type: templateList?.[activeIndex]?.template_name ?? '自定义生成',
        },
      }),
    )
  })

  const handleKeyDown = useMemoizedFn((event: KeyboardEvent) => {
    if (event.key === 'Tab' && !text) {
      event.preventDefault()
      eRef.current?.setTemplate?.(
        '创建一个广告文案的编写专家，目的是提炼产品价值，创造营销效果',
      )
      eRef.current?.setOptionMap({})
    }

    if (event.key === 'Enter' && text) {
      handleGenerate()
    }
  })

  const handleTwiceConfirm = useMemoizedFn(() => {
    const struct = tryGetObject(content)
    onConfirm(struct.prompt ?? [])
    setTwiceConfirm(false)
  })

  const handleConfirm = useMemoizedFn(() => {
    if (prompt) {
      setTwiceConfirm(true)
      return
    }
    handleTwiceConfirm()
  })

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const { run: scrollToBottom, cancel } = useThrottleFn(() => {
    setTimeout(() => {
      const viewport = scrollRef.current?.osInstance()?.elements().viewport
      viewport?.scroll({
        top: viewport.scrollHeight + 200,
        behavior: 'smooth',
      })
    })
  })

  const scrollToTop = useMemoizedFn(() => {
    cancel()
    const viewport = scrollRef.current?.osInstance()?.elements().viewport
    viewport?.scroll({
      top: 0,
      behavior: 'smooth',
    })
  })

  const showContent = useMemo(() => {
    if ([State.end, State.result].includes(state)) {
      scrollToTop()
      const struct = tryGetObject(content)
      return getPromptFromStructPrompt(struct.prompt ?? [])
    } else {
      scrollToBottom()
      return content ?? ''
    }
  }, [content, state])

  const isError = useMemo(() => {
    if (![State.end, State.result].includes(state)) return false
    if (content && !showContent) return true
    return false
  }, [state, showContent, content])

  return (
    <div className='h-720px p-24px flex flex-col'>
      <div className='flex gap-8px'>
        <div
          className={classNames(
            'flex flex-center gap-6px h-32px px-12px cursor-pointer border border-solid border-line rounded-8px cursor-pointer hover:bg-bg_3/8',
            {
              'bg-primary/8! border-primary! text-primary': activeIndex === -1,
            },
          )}
          onClick={() => handleUseTemplate(undefined)}
        >
          <IconFont className='text-16px' name='shizhicizidingyi' />
          自定义
        </div>

        {templateList?.map((each, index) => (
          <div
            key={index}
            className={classNames(
              'flex flex-center gap-6px h-32px px-12px border border-solid border-line rounded-8px cursor-pointer hover:bg-bg_3/8',
              {
                'bg-primary/8! border-primary! text-primary':
                  activeIndex === index,
              },
            )}
            onClick={() => handleUseTemplate(each, index)}
          >
            <IconFont className='text-16px' name={each.icon} />
            <span>{each.template_name}</span>
          </div>
        ))}
      </div>

      <div className='prompt-ai-create-input min-h-58px p-12px flex items-center mt-8px border border-solid border-line rounded-8px focus-within:border-primary'>
        <OverlayScrollbarsComponent
          className='overflow-y-auto flex-auto max-h-76px overflow-auto'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <div className='relative' onKeyDown={handleKeyDown}>
            <PlaceholderEditor eRef={eRef} onChange={setText} />
            {!text && (
              <div className='absolute top-0 left-0 z-[-1]'>
                <span className='leading-24px event-none c-font_1/60'>
                  创建一个广告文案的编写专家，目的是提炼产品价值，创造营销效果
                </span>
                <span className='py-1 px-4 c-font_1/60 b-1 ml-5 b-line/60 bg-bg_3/4 rd-4px'>
                  Tab
                </span>
              </div>
            )}
          </div>
        </OverlayScrollbarsComponent>
        <div className='ml-8px flex justify-end self-end'>
          {state === State.start && (
            <Button className='h-32px! px-12px!' onClick={handleStop}>
              <IconFont name='tingzhishengcheng' />
              停止生成
            </Button>
          )}
          {state !== State.start && (
            <Tooltip
              open={!text ? false : undefined}
              title='⌘+Enter'
              destroyTooltipOnHide
            >
              <Button
                className='h-32px! px-12px!'
                type='primary'
                loading={state === State.pending}
                disabled={!text}
                onClick={handleGenerate}
              >
                {state === State.none ? '生成' : '重新生成'}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <div className='flex h-full mt-24px min-h-0px'>
        <div className='flex-1 h-full'>
          {!showContent && !isError && (
            <div className='h-full flex flex-center'>
              <Empty
                className='ant-empty-small [&_.ant-empty-image]:h-auto!'
                image={
                  <div className='h-100px!'>
                    <AICreate />
                  </div>
                }
                description={
                  <div className='text-[rgba(141,141,153,0.6)]'>
                    <p>根据上方指令生成的提示词</p>
                    <p className='mt-8px'>将会显示在这里</p>
                  </div>
                }
              />
            </div>
          )}

          {(showContent || isError) && (
            <div className='h-full flex flex-col'>
              {/* <div className='leading-24px mb-8px'>生成的提示词</div> */}
              <div
                className={classNames('min-h-0px flex-1 relative rounded-8px', {
                  'outline-error outline-1px outline-solid': isError,
                })}
              >
                <OverlayScrollbarsComponent
                  ref={scrollRef}
                  className='overflow-y-auto h-full bg-bg_3/6 p-12px rounded-8px text-#17171d'
                  element='div'
                  options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
                  defer
                >
                  <Markdown text={showContent} />
                </OverlayScrollbarsComponent>
                {isError && (
                  <span className='text-error absolute top-100% mt-12px'>
                    生成失败，请重新生成
                  </span>
                )}
              </div>

              <div className='flex justify-end pt-12'>
                <Popconfirm
                  title={null}
                  description={
                    <div className='w-240px line-height-22px font-400'>
                      使用提示词会覆盖现有配置，是否确认使用？
                    </div>
                  }
                  icon={null}
                  open={twiceConfirm}
                  okText='确认'
                  cancelText='取消'
                  okButtonProps={{ size: 'middle' }}
                  cancelButtonProps={{ size: 'middle' }}
                  onConfirm={handleTwiceConfirm}
                  onCancel={() => setTwiceConfirm(false)}
                >
                  <Button
                    disabled={
                      ![State.end, State.result].includes(state) || isError
                    }
                    type='primary'
                    onClick={handleConfirm}
                  >
                    使用
                  </Button>
                </Popconfirm>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
