import { useEffect, useRef } from 'react'
import { useRequest } from 'ahooks'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { tokenStorage } from '@/utils/storage'

const CACHE_TIME = 1000
const CACHE_KEY = 'versionKey'
export function useWorkspace() {
  const {
    setCurrentWorkspace,
    currentWorkspaceId,
    workspaceList,
    fetchWorkspaceList,
    currentWorkspaceItem,
  } = useWorkspaceStore()

  const { fetchVersionByWorkspaceId } = useVersionStore()

  const readyRef = useRef(false)

  const { runAsync: run } = useRequest(fetchVersionByWorkspaceId, {
    manual: true,
    refreshOnWindowFocus: true,
    cacheKey: CACHE_KEY,
    cacheTime: CACHE_TIME,
    refreshDeps: [currentWorkspaceId],
  })

  const initWorkspace = async (urlWorkspaceId?: string) => {
    const initialWorkspaceId = urlWorkspaceId || currentWorkspaceId
    const noWorkspace = !initialWorkspaceId && workspaceList?.length
    const errorWorkspace =
      initialWorkspaceId &&
      workspaceList?.length &&
      !workspaceList?.find(v => v.id === initialWorkspaceId)
    const searchParams = new URLSearchParams(window.location.search)
    const inviteWorkspaceId = searchParams.get('workspaceId')
    let workspaceId = ''
    if (inviteWorkspaceId) {
      workspaceId = inviteWorkspaceId
    }
    // 如果没有工作空间或者工作空间列表为空或者当前工作空间不在列表中，则设置当前工作空间为列表第一个
    if (noWorkspace || errorWorkspace) {
      workspaceId = workspaceList?.[0].id
    }

    // currentWorkspaceItem不存在，且没有工作空间，且工作空间列表不为空，则设置当前工作空间为列表第一个
    if (!errorWorkspace && !currentWorkspaceItem && !noWorkspace) {
      workspaceId = initialWorkspaceId
    }

    if (workspaceId) {
      setCurrentWorkspace(workspaceId)
    }

    ;(workspaceId || initialWorkspaceId) &&
      tokenStorage.get() &&
      run(workspaceId || initialWorkspaceId)
  }

  useEffect(() => {
    if (!readyRef.current) return
    initWorkspace()
  }, [currentWorkspaceId, workspaceList, readyRef.current])

  const readyInitWorkspace = (initialWorkspaceId?: string) => {
    readyRef.current = true
    initWorkspace(initialWorkspaceId)
  }

  return {
    fetchWorkspaceList,
    fetchVersionByWorkspaceId,
    readyInitWorkspace,
    currentWorkspaceItem,
  }
}
