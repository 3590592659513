export enum EditorNodeType {
  text = 'text',
  input = 'input',
  select = 'select',
}

export interface EditorNode {
  type: EditorNodeType
  text: string
  key?: string
}
