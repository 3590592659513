export enum LLMChannels {
  GPT = 'GPT',
  TongYiQWen = 'TongYiQWen',
  ZhiPu = 'ZhiPu',
  QianFan = 'QianFan',
  XunFei = 'XunFei',
  Tencent = 'Tencent',
  Amazon = 'Amazon',
  Azure = 'Azure',
  ChatGLM3 = 'ChatGLM3',
  Moonshot = 'Moonshot',
  Volcano = 'Volcano',
  Amazon_Llama = 'Amazon_Llama',
}
