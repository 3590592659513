import copy from 'copy-to-clipboard'
import { message } from 'antd'
import type { PublishModeValue } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'
import AgentGoDingTalk1_1 from '@/assets/agent/agent-go-dingtalk-1-1.png'
import AgentGoDingTalk1_2 from '@/assets/agent/agent-go-dingtalk-1-2.png'
import AgentGoDingTalk2_1 from '@/assets/agent/agent-go-dingtalk-2-1.png'
import AgentGoDingTalk2_2 from '@/assets/agent/agent-go-dingtalk-2-2.png'
import AgentGoDingTalk2_2_2 from '@/assets/agent/agent-go-dingtalk-2-2-2.png'
import AgentGoDingTalk2_2_3 from '@/assets/agent/agent-go-dingtalk-2-2-3.png'
import AgentGoDingTalk2_2_4 from '@/assets/agent/agent-go-dingtalk-2-2-4.png'
import AgentGoDingTalk2_2_5 from '@/assets/agent/agent-go-dingtalk-2-2-5.png'
import AgentGoDingTalk3_1 from '@/assets/agent/agent-go-dingtalk-3-1.png'
import AgentGoDingTalk3_2 from '@/assets/agent/agent-go-dingtalk-3-2.png'
import AgentGoDingTalk4_1 from '@/assets/agent/agent-go-dingtalk-4-1.png'
import GZH_1_1 from '@/assets/agent/gh-1-1.png'
import GZH_1_2 from '@/assets/agent/gh-1-2.png'
import GZH_2_1 from '@/assets/agent/gh-2-1.png'
import GZH_2_2 from '@/assets/agent/gh-2-2.png'
import GZH_3_1 from '@/assets/agent/gh-3-1.png'
import GZH_3_2 from '@/assets/agent/gh-3-2.png'
import GZH_4_1 from '@/assets/agent/gh-4-1.png'
import GZH_4_2 from '@/assets/agent/gh-4-2.png'
import GZH_5_1 from '@/assets/agent/gh-5-1.png'
import QW_1_1 from '@/assets/agent/qw-1-1.png'
import QW_1_2 from '@/assets/agent/qw-1-2.png'
import QW_2_1 from '@/assets/agent/qw-2-1.png'
import QW_3_1 from '@/assets/agent/qw-3-1.png'
import QW_4_1 from '@/assets/agent/qw-4-1.png'
import FlyBook_1_1 from '@/assets/agent/fly-book-1-1.png'
import FlyBook_1_2 from '@/assets/agent/fly-book-1-2.png'
import FlyBook_1_3 from '@/assets/agent/fly-book-1-3.png'
import FlyBook_2_1 from '@/assets/agent/fly-book-2-1.png'
import FlyBook_2_2 from '@/assets/agent/fly-book-2-2.png'
import FlyBook_2_3 from '@/assets/agent/fly-book-2-3.png'
import FlyBook_3_1 from '@/assets/agent/fly-book-3-1.png'
import FlyBook_3_2 from '@/assets/agent/fly-book-3-2.png'
import FlyBook_4_1 from '@/assets/agent/fly-book-4-1.png'
import FlyBook_4_2 from '@/assets/agent/fly-book-4-2.png'
import FlyBook_5_1 from '@/assets/agent/fly-book-5-1.png'
import FlyBook_5_2 from '@/assets/agent/fly-book-5-2.png'
import { LinkText } from '@/features/agent/components/LinkText.tsx'

function FeishuParagraph() {
  const onCopy = (data: string) => {
    copy(data)
    message.success('已复制到剪贴板')
  }
  return (
    <>
      <span>2.进入「权限管理」，依次搜索并开通以下权限：</span>
      {[
        '获取与发送单聊、群组消息',
        '获取用户在群组中@机器人的消息',
        '接收群聊中@机器人事件',
        '获取群组中所有消息',
        '获取用户发给机器人的单聊消息',
        '读取用户发给机器人的单聊消息',
      ].map((item, index) => (
        <>
          <span
            className='text-font_1 font-normal border-b-1 border-dashed border-font_1 cursor-pointer hover:opacity-60 '
            key={index.toString()}
            onClick={() => onCopy(item)}
          >
            {item}
          </span>
          <span className='text-font_1 font-normal'>；</span>
        </>
      ))}
    </>
  )
}

export const PUBLISH_LIST: {
  group: string
  items: {
    key: PublishModeValue
    label: string
    description: string
    image: string
    needUpgradeToEnterprise?: boolean
  }[]
}[] = [
  {
    group: '直接使用',
    items: [
      {
        key: PUBLISH_MODE.link,
        label: '网页链接',
        description: '用户通过网页链接使用',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/link%402x.png',
      },
      {
        key: PUBLISH_MODE.chat,
        label: '发布到【对话】',
        description: '用户在【对话】中使用',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/chat%402x.png',
      },
    ],
  },
  {
    group: '在外部使用',
    items: [
      {
        key: PUBLISH_MODE.api,
        label: 'API 访问',
        description: '通过 API，在自己的业务系统中调用',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/api%402x.png',
        needUpgradeToEnterprise: true,
      },
      {
        key: PUBLISH_MODE.sdk,
        label: 'SDK 集成',
        description: '把 Agent 嵌入到自己的网页中',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/sdk%402x.png',
        needUpgradeToEnterprise: true,
      },
      {
        key: PUBLISH_MODE.wechat_kf,
        label: '微信客服',
        description: '在微信中使用',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/wechat%402x.png',
      },
      {
        key: PUBLISH_MODE.wechat_open_app,
        label: '微信公众号',
        description: '在微信公众号中使用',
        image:
          'https://resource.bantouyan.com/betteryeah/agent/agentgo/wechat_open_app.png',
      },
      {
        key: PUBLISH_MODE.wechat_enterprise,
        label: '企业微信',
        description: '在企业微信中使用',
        image:
          'https://resource.bantouyan.com/betteryeah/agent/agentgo/wechat_enterprise.png',
      },
      {
        key: PUBLISH_MODE.dingtalk,
        label: '钉钉机器人',
        description: '在钉钉中使用',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/dingtalk%402x.png',
      },
      {
        key: PUBLISH_MODE.dingtalk_web,
        label: '钉钉网页应用',
        description: '在钉钉中使用',
        image:
          'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/dingtalkweb.png',
      },
      {
        key: PUBLISH_MODE.feishu,
        label: '飞书机器人',
        description: '在飞书中使用',
        image:
          'https://resource.bantouyan.com/betteryeah/agent/agentgo/fly_book_2x.png',
      },
    ],
  },
]

export const WECHAT_DOCS = [
  [
    {
      paragraph: (
        <span>
          1.登录
          <LinkText href='https://kf.weixin.qq.com'>微信客服后台</LinkText>
          ，找到企业信息，复制企业 ID
        </span>
      ),
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/step-1.1.png',
    },
    {
      paragraph: '2.选择要接入 Agent 的客服账号，复制账号 ID 和 名称',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/step-1.2.png',
    },
  ],
  [
    {
      paragraph: (
        <span>
          1.在<LinkText href='https://kf.weixin.qq.com'>微信客服后台</LinkText>
          ，找到开发配置，点击开始使用
        </span>
      ),
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/step-2.1.png',
    },
    {
      paragraph: '2.将回调信息填写完整',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/step-2.2.png',
    },
  ],
  [
    {
      paragraph: (
        <span>
          在<LinkText href='https://kf.weixin.qq.com'>微信客服后台</LinkText>
          ，开发配置中复制Secret
        </span>
      ),
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/step-3.1.png',
    },
  ],
  [
    {
      paragraph: (
        <span>
          在<LinkText href='https://kf.weixin.qq.com'>微信客服后台</LinkText>
          ，点击开始接入，按您的需求场景接入微信客服
        </span>
      ),
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/step-ok.png',
    },
  ],
]

export function getDingTalkDocs(isStream: boolean) {
  return [
    [
      {
        paragraph: '1.在应用开发中，创建钉钉应用',
        diagram: AgentGoDingTalk1_1,
      },
      {
        paragraph: '2.进入应用详情，在凭证与基本信息中获取参数',
        diagram: AgentGoDingTalk1_2,
      },
    ],
    isStream
      ? [
          {
            paragraph: '1.在刚刚的应用详情中找到添加应用能力，添加机器人',
            diagram: AgentGoDingTalk2_1,
          },
          {
            paragraph:
              '2.配置机器人的消息接受模式，消息模式选「Stream 模式」，然后发布',
            diagram: AgentGoDingTalk2_2_2,
          },
          {
            paragraph: (
              <>
                3.进入「权限管理」，依次搜索并开通以下权限：
                {[
                  'AI卡片流式更新权限',
                  '互动卡片实例写权限',
                  '企业内机器人发送消息权限',
                ].map((item, index) => (
                  <>
                    <span
                      className='text-font_1 font-normal border-b-1 border-dashed border-font_1 cursor-pointer hover:opacity-60 '
                      key={index.toString()}
                      onClick={() => {
                        copy(item)
                        message.success('已复制到剪贴板')
                      }}
                    >
                      {item}
                    </span>
                    <span className='text-font_1 font-normal'>；</span>
                  </>
                ))}
              </>
            ),
            diagram: AgentGoDingTalk2_2_5,
          },
          {
            paragraph: (
              <span>
                4.打开
                <LinkText href='https://open-dev.dingtalk.com/fe/card'>
                  卡片平台
                </LinkText>
                新建模版，选择 AI 卡片，关联应用，完成创建
              </span>
            ),
            diagram: AgentGoDingTalk2_2_3,
          },
          {
            paragraph:
              '5.直接发布模版，并返回模版列表，在列表页获取卡片模版 ID',
            diagram: AgentGoDingTalk2_2_4,
          },
        ]
      : [
          {
            paragraph: '1.在刚刚的应用详情中找到添加应用能力，添加机器人',
            diagram: AgentGoDingTalk2_1,
          },
          {
            paragraph: '2.开启机器人配置，消息模式选 HTTP 模式，完成配置后发布',
            diagram: AgentGoDingTalk2_2,
          },
        ],
    [
      {
        paragraph: '1.进入版本管理与发布，将钉钉应用发布上线',
        diagram: AgentGoDingTalk4_1,
      },
    ],
    [
      {
        paragraph:
          '在单聊中使用，通过搜索框搜索创建的机器人，在功能中找到机器人，开始一对一对话',
        diagram: AgentGoDingTalk3_1,
      },
      {
        paragraph:
          '在群聊中使用，进入群设置，选择添加机器人，找到刚刚创建的机器人，在群内@机器人即可对话',
        diagram: AgentGoDingTalk3_2,
      },
    ],
  ]
}

export const DING_TALK_WEB_DOCS = [
  [
    {
      paragraph: '1.登录「钉钉开放平台-开发者后台」，在「首页」获取 CorpId',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/1-1@2x.png',
    },
    {
      paragraph: '2.在「应用开发」页，创建一个新的钉钉应用',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/1-2@2x.png',
    },
    {
      paragraph:
        '3.进入「应用详情」，在「凭证与基础信息」中获取 Client ID、Client Secret 和应用名称',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/1-3@2x.png',
    },
  ],
  [
    {
      paragraph: '1.在「添加应用能力」中找到「网页应用」并点击添加按钮',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/2-1@2x.png',
    },
    {
      paragraph: '2.在新添加的「网页应用」中，填写应用首页地址、PC端首页地址',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/2-2@2x.png',
    },
  ],
  [
    {
      paragraph: (
        <>
          1.复制参数，到「安全设置」中填写服务器出口 IP、重定向 URL
          <span className='color-#FF5219'>（均为必填）</span>
        </>
      ),
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/3-1@2x.png',
    },
  ],
  [
    {
      paragraph: '1.进入「版本管理与发布」，创建新版本并发布',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/4-1@2x.png',
    },
  ],
  [
    {
      paragraph: '1.钉钉管理员打开工作台，添加新发布的应用',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/5-1@2x.png',
    },
    {
      paragraph: '2.员工在工作台中打开应用，即可发起对话',
      diagram:
        'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/agentgo/5-2@2x.png',
    },
  ],
]

export const WECHAT_OPEN_APP_DOCS = [
  [
    {
      paragraph: (
        <span>
          1.登录
          <LinkText href='https://mp.weixin.qq.com/'>微信公众号后台</LinkText>
          ，在「账号详情」中获取公众号名称和原始ID
        </span>
      ),
      diagram: GZH_1_1,
    },
    {
      paragraph: '2.在「设置与开发-基本配置」中获取参数',
      diagram: GZH_1_2,
    },
  ],
  [
    {
      paragraph: '1.在「设置与开发-基本配置」开启服务器配置',
      diagram: GZH_2_1,
    },
    {
      paragraph: '2.填写服务器配置，注意「消息加密方式」请选择「安全模式」',
      diagram: GZH_2_2,
    },
  ],
  [
    {
      paragraph: '1.在「设置与开发-基本配置」填写IP地址',
      diagram: GZH_3_1,
    },
    {
      paragraph: null,
      diagram: GZH_3_2,
    },
  ],
  [
    {
      paragraph: '1.在「新的功能-广告与服务」中找到客服，点击开通',
      diagram: GZH_4_1,
    },
    {
      paragraph: '2.开通后，在「客服-账号管理」添加客服',
      diagram: GZH_4_2,
    },
  ],
  [
    {
      paragraph: '关注公众号，给公众号发消息即可和Agent对话',
      diagram: GZH_5_1,
    },
  ],
]

export const WECHAT_ENTERPRISE_DOCS = [
  [
    {
      paragraph: (
        <span>
          1.登录
          <LinkText href='https://work.weixin.qq.com/'>
            企业微信管理后台
          </LinkText>{' '}
          ，在「我的企业-企业信息」中获取企业ID
        </span>
      ),
      diagram: QW_1_1,
    },
    {
      paragraph: '2.点击应用管理，创建/选择一个自建应用，并获取Secret',
      diagram: QW_1_2,
    },
  ],
  [
    {
      paragraph: '1.进入应用详情，接收消息-设置API接受，将复制的信息填入',
      diagram: QW_2_1,
    },
  ],
  [
    {
      paragraph: '1.进入应用详情，下拉找到企业可信IP，完成配置',
      diagram: QW_3_1,
    },
  ],
  [
    {
      paragraph: '在企业微信客户端-工作台找到应用，开始对话',
      diagram: QW_4_1,
    },
  ],
]

export const FLY_BOOK_DOCS = [
  [
    {
      paragraph: '1.登录「飞书开放平台-开发者后台」创建一个应用',
      diagram: FlyBook_1_1,
    },
    {
      paragraph: '2.进入应用详情，在「凭证与基础信息」中获取App id和App Secret',
      diagram: FlyBook_1_2,
    },
    {
      paragraph:
        '3.在「事件与回调-加密策略」中获取Encrypt Key和Verification Token，新应用需要点击重置生成Encrypt Key',
      diagram: FlyBook_1_3,
    },
  ],
  [
    {
      paragraph: '1.复制请求地址，填写到「事件与回调-事件配置」中',
      diagram: FlyBook_2_1,
    },
    {
      paragraph: '2.添加事件「接收消息v2.0」, 开通所有所需权限',
      diagram: FlyBook_2_2,
    },
    {
      paragraph: undefined,
      diagram: FlyBook_2_3,
    },
  ],
  [
    {
      paragraph:
        '1.在应用详情「添加应用能力」找到机器人并添加，添加即可不需要任何设置',
      diagram: FlyBook_3_1,
    },
    {
      paragraph: <FeishuParagraph />,
      diagram: FlyBook_3_2,
    },
  ],
  [
    {
      paragraph: '1.在应用详情「版本管理与发布」创建版本并发布',
      diagram: FlyBook_4_1,
    },
    {
      paragraph: '2.发布后的应用状态显示为已启用',
      diagram: FlyBook_4_2,
    },
  ],
  [
    {
      paragraph:
        '在单聊中使用：通过搜索框搜索，在「应用」中找到机器人，开始一对一对话',
      diagram: FlyBook_5_1,
    },
    {
      paragraph:
        '在群聊中使用：进入群设置，找到「机器人」添加机器人，在群内@机器人即可对话',
      diagram: FlyBook_5_2,
    },
  ],
]
