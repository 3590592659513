import { useBoolean } from 'ahooks'
import { Button, Modal } from '@/components'
import customerServiceQRCode from '@/assets/customerServiceQRCode.png'
import { PayIcon } from './PayIcon'

export interface PayFailedProps {
  title: string | React.ReactNode
  confirmText: string
  onConfirm?: () => void
  showCancel?: boolean
  onCancel?: () => void
  loading?: boolean
  showService?: boolean
}

export function PaySuccess(props: PayFailedProps) {
  const {
    title,
    onConfirm,
    confirmText,
    showCancel,
    onCancel,
    loading,
    showService,
  } = props

  const [wechatVisible, { toggle: toggleWechat }] = useBoolean(false)
  return (
    <div className='relative'>
      {showService && (
        <a
          className='c-primary absolute right--354px top--42px cursor-pointer'
          onClick={toggleWechat}
        >
          联系客服
        </a>
      )}
      <div className='flex flex-col items-center'>
        <PayIcon type='success' content={title}></PayIcon>
        <div className='mt-48'>
          {showCancel && (
            <Button
              size='large'
              className='mr-12 w-120px'
              loading={loading}
              onClick={() => onCancel?.()}
            >
              取消订单
            </Button>
          )}
          <Button type='primary' className='w-120px' onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
        <Modal
          title='联系客服'
          width={400}
          open={wechatVisible}
          footer={false}
          onCancel={toggleWechat}
        >
          <div className='flex flex-col flex-items-center text-14px c-font'>
            <div>请扫码联系客服</div>
            <img
              className='h-126px w-126px mt-32px mb-12px'
              src={customerServiceQRCode}
            />
            <div className='mb-30px'>微信扫一扫</div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
