import { useCallback } from 'react'
import { message } from 'antd'
import classNames from 'classnames'
import { AddButton } from '../../../components'
import type { Message } from '../../LLMNode'
import { LLMMessageStructType } from '@/features/nodes/utils/llm.ts'
import type { TagsTooltipProps } from '@/features/editor/components/TagsTooltip'
import { TextEditor } from '@/features/editor/TextEditor'

export interface ChatProps {
  readOnly?: boolean
  disabled?: boolean
  value?: Message[]
  completions?: any[]
  anchor?: TagsTooltipProps['anchor']
  viewport?: {
    zoom: number
    x?: number
    y?: number
  }
  setOptions?: null | { minLines?: number; maxLines?: number }
  btnCls?: string
  extraCls?: string
  variableTipsContainer?: HTMLElement | null
  onChange?: (value: Message[]) => void
  modelMessageStructType?: LLMMessageStructType
  model?: string
  'aria-invalid'?: string
}

export const ENUM_ROLE_TEXT = {
  system: 'SYSTEM',
  user: 'USER',
  assistant: 'ASST',
}

export function Chat(props: ChatProps) {
  const {
    value = [],
    onChange,
    completions = [],
    variableTipsContainer,
    modelMessageStructType,
    extraCls,
    btnCls,
    readOnly = false,
    disabled = false,
    anchor,
  } = props

  const formattedValue = value.filter(item => item.role !== 'system')
  // const formattedValue = value?.slice(1, value.length)
  // const systemValue = value?.slice(0, 1)
  const onMessageChange = useCallback(
    (index: number, message: string) => {
      const newValue = formattedValue.map((v, i) => {
        return i === index ? { ...v, content: message } : v
      })
      onChange?.([...newValue])
    },
    [formattedValue, onChange],
  )

  const onDelete = useCallback(
    (index: number) => {
      if (modelMessageStructType === LLMMessageStructType.LIKE_BAIDU) {
        // 类百度模型，对话有限制, 一次需要删除一组对话
        const otherIndex = (index + 1) % 2 === 0 ? index - 1 : index + 1
        const newValue = formattedValue.filter(
          (_v, i) => i !== index && i !== otherIndex,
        )
        onChange?.([...newValue])
      } else {
        const newValue = formattedValue.filter((_v, i) => i !== index)
        onChange?.([...newValue])
      }
    },
    [formattedValue, onChange],
  )

  const onRoleClick = useCallback(
    (index: number, role: 'system' | 'user' | 'assistant') => {
      if (readOnly || disabled) {
        return
      }
      if (modelMessageStructType === LLMMessageStructType.LIKE_GPT) {
        // 只有类GPT模型，对话身份才可切换
        const newValue = formattedValue.map((v, i) => {
          return i === index ? { ...v, role } : v
        })

        onChange?.([...newValue])
      } else {
        message.warning('模型限制，此模型不可切换对话角色')
      }
    },
    [formattedValue, onChange],
  )

  const onAdd = useCallback(() => {
    if (modelMessageStructType === LLMMessageStructType.LIKE_BAIDU) {
      // 类百度模型，对话有限制, 一次需要添加一组对话
      onChange?.([
        ...value,
        { role: 'assistant', content: '' },
        { role: 'user', content: '' },
      ])
    } else {
      // 类GPT模型
      const lastRole = value?.[value.length - 1]?.role
      onChange?.([
        ...value,
        { role: lastRole === 'user' ? 'assistant' : 'user', content: '' },
      ])
    }
  }, [value, onChange])

  return (
    <div>
      {Array.isArray(formattedValue) &&
        formattedValue.map((item, index) => {
          return (
            <TextEditor
              key={index}
              minHeight={42}
              maxHeight={320}
              readonly={readOnly}
              disabled={disabled}
              placeholder='请输入对话'
              value={item.content}
              onChange={value => onMessageChange(index, value)}
              anchor={anchor}
              variables={readOnly || disabled ? undefined : completions}
              variableTipsContainer={variableTipsContainer}
              aria-invalid={
                props['aria-invalid'] && !item.content ? 'true' : undefined
              }
              focusScroll
              showDelete={!readOnly && !disabled}
              onDelete={() => onDelete(index)}
              role={ENUM_ROLE_TEXT[item.role]}
              onRoleClick={() =>
                onRoleClick(index, item.role === 'user' ? 'assistant' : 'user')
              }
              className={classNames('mb-4', extraCls)}
            />
          )
        })}

      <AddButton
        className={btnCls}
        disabled={readOnly || disabled}
        onClick={onAdd}
      >
        添加对话
      </AddButton>
    </div>
  )
}
