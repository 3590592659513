import { useEffect, useState } from 'react'
import { useMatch, Outlet, useNavigate } from 'react-router-dom'
import { LabelTabs } from '@/components'

const allTabs = [
  {
    label: '用量统计',
    key: 'usageStatistics',
  },
  {
    label: '应用统计',
    key: 'resourceStatistics',
  },
]

type TabKey = (typeof allTabs)[number]['key']

export function Statistics() {
  const match = useMatch('/statistics/:tab')
  const tab = match?.params.tab as TabKey
  const [activeKey, setActiveKey] = useState<TabKey>(() => tab)
  const navigate = useNavigate()

  useEffect(() => {
    setActiveKey(tab)
  }, [tab])

  const handleTabChange = (key: TabKey) => {
    setActiveKey(key)
    navigate(key)
  }

  return (
    <div className='h-full'>
      <div className='h-100% adapt:pt-40 bg-bg b-rd-12px flex flex-col'>
        <div className='adapt:px-64'>
          <div className='line-height-36px text-20px font-600 pb-16'>
            数据统计
          </div>
        </div>
        <div className='flex adapt:mb-20 adapt:px-64'>
          <LabelTabs
            labels={allTabs}
            activeKey={activeKey}
            onChange={handleTabChange}
          />
        </div>
        <div className='flex-1 overflow-hidden'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
