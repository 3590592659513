import { Upload } from 'antd'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import mime from 'mime'
import { useUploadFileToOss } from '@/hooks/useOssUpload'

interface RcFile extends File {
  uid: string
}

const StyledUpload = styled(Upload.Dragger)<{ hasError?: boolean }>`
  .ant-upload-drag {
    ${props =>
      props.hasError && `border-color: ${props.theme.colors.error} !important;`}
    background: rgba(98, 105, 153, 0.06);
  }
`

interface FileType {
  name: string
  type?: string
  size?: number
  id?: string
  upload_file_id?: string
  url?: string
}

export const MAX_FILE_SIZE = 20 * 1024 * 1024

const ACCEPT_FILE_TYPE = ['.txt', ',pdf', '.doc', '.docx']

export type PromptFileType = Required<FileType> & {
  upload_file_id?: string
  file_action?: 'FILE_URL' | 'FILE_DATA'
  url?: string
  rawFile?: PromptFileType
  needUpload?: boolean
}

interface FileUploadResponse extends Record<string, unknown> {
  status?: number
  success?: boolean
  data?: {
    upload_file_id: string
    file_url: string
  }
}

const FileUpload = forwardRef(
  (
    {
      className,
      disabled = false,
      children,
      mimeTypeSizeMap = {},
      beforeFileUpload,
      onStart,
      onOk,
      onFail,
      fileAccept = ACCEPT_FILE_TYPE,
      file,
      hasError = false,
    }: any,
    ref,
  ) => {
    const uploadRef = useRef<any>(null)
    const { uploadFileToOssApi } = useUploadFileToOss()

    const resolver = useRef<any>()

    const beforeUpload = async (file: RcFile) => {
      const fileType = file.type || mime.getType(file.name) || ''
      const maxSize = mimeTypeSizeMap[fileType] || MAX_FILE_SIZE
      if (file.size > maxSize) {
        onFail?.(
          file,
          `文件超过${Number.parseInt(
            `${maxSize / 1024 / 1024}`,
          )}MB限制，无法上传`,
        )
        return false
      }

      const res = await beforeFileUpload?.(file, [])

      if (!res) {
        return false
      }

      return true
    }

    const handleSuccess = (data: FileUploadResponse, file: RcFile) => {
      const upload_file_id = data?.upload_file_id || ''
      if (data) {
        onOk?.({
          name: file.name,
          type: file.type,
          size: file.size,
          upload_file_id,
        } as any)
        resolver.current?.({
          name: file.name,
          type: file.type,
          size: file.size,
          upload_file_id,
          rawFile: file,
        })
        resolver.current = null
      }
    }
    const accept = useMemo(() => {
      return fileAccept.join(',')
    }, [fileAccept])

    const customUpload = (file: File) => {
      return new Promise(resolve => {
        resolver.current = resolve
        uploadRef?.current?.uploader?.onChange({
          target: {
            files: [file],
          },
        })
      })
    }

    useImperativeHandle(
      ref,
      () => ({
        customUpload,
      }),
      [ref, customUpload, uploadRef],
    )

    const handleUploadFile = async (options: any) => {
      const { file } = options

      try {
        onStart?.(file)
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadFileToOssApi(formData)
        handleSuccess?.(res, file)
      } catch {
        onFail?.(file, '文件上传失败')
      }
    }

    const fileList = useMemo(() => {
      return file ? [file] : []
    }, [file])

    return (
      <>
        <StyledUpload
          ref={uploadRef}
          hasError={hasError}
          defaultFileList={fileList}
          className={cn(className)}
          showUploadList={false}
          fileList={fileList}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          disabled={disabled}
          data={file => {
            const type = file instanceof Blob ? file.type : 'text/plain'
            const fileAction = 'FILE_URL'
            return {
              file_type: type,
              file_action: fileAction,
            }
          }}
          customRequest={handleUploadFile}
          accept={accept}
          beforeUpload={(file: RcFile) => {
            return beforeUpload(file)
          }}
        >
          {children}
        </StyledUpload>
      </>
    )
  },
)
export default FileUpload
