import type { NodeSpec } from 'prosemirror-model'

export const inputNodeSpec: NodeSpec = {
  name: 'pe-input',
  group: 'inline',
  content: 'text*',
  inline: true,
  atom: false,
  selectable: false,
  attrs: {
    key: { default: '' },
    placeholder: { default: '' },
  },
  // 将节点转换为 DOM 时的规则
  toDOM: node => {
    return [
      'span',
      {
        class: 'pe-input',
        'data-key': node.attrs.key,
      },
      [
        'text',
        {
          'data-zero': true,
          contenteditable: 'false',
        },
        '\u200B',
      ],
      [
        'span',
        {
          class: 'pe-input-wrap',
        },
        [
          'span',
          {
            class: 'pe-input-value',
          },
          0,
        ],
        [
          'span',
          {
            class: 'pe-input-placeholder',
            contenteditable: 'false',
          },
          node.attrs.placeholder,
        ],
      ],
      [
        'text',
        {
          'data-zero': true,
          contenteditable: 'false',
        },
        '\u200B',
      ],
    ]
  },
}
