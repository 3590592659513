import { rgba } from 'polished'
import { FlowStatus } from '@/apis/flow'
import theme from '@/constants/theme'

const statusInfo = {
  [FlowStatus.SUCCEEDED]: {
    text: '运行成功',
    color: '#2cb969',
  },
  [FlowStatus.FAIL]: {
    text: '运行失败',
    color: theme.colors.error,
  },
}

export const RunTag: React.FC<{ status: FlowStatus }> = ({ status }) => {
  const { text, color } = statusInfo[status] || {}

  return (
    <span
      className='h-20px line-height-20px p-l-8 p-r-8 text-12px font-500 b-rd-4px'
      style={{
        color: `${color}`,
        backgroundColor: rgba(color, 0.12),
      }}
    >
      {text}
    </span>
  )
}
