import { useEffect, useState } from 'react'
import { useBoolean } from 'ahooks'
import { Modal } from '@/components'
import { useModal } from '@/hooks/useModal'
import { Login } from './Login'
import { Register } from './Register'
import { RegisterSuccess } from './RegisterSuccess'
import { CreateWorkspace } from './CreateWorkspace'
import { Invite } from './Invite'

export type ModalType =
  | 'login'
  | 'register'
  | 'register-success'
  | 'workspace'
  | 'invite'
export interface LoginProps {
  type?: ModalType
  open?: boolean
  closable?: boolean
  link_id?: string
  onSuccess?: (res?: any) => void
  onCancel?: () => void
}

export function LoginContent(props: LoginProps) {
  const { type = 'login', onSuccess, link_id } = props
  const [_type, setShowType] = useState(type)

  console.log(props)
  const Component = {
    login: () => <Login onChangType={setShowType} onLoginSuccess={onSuccess} />,
    register: () => (
      <Register
        onLogin={() => setShowType('login')}
        onChangType={setShowType}
      />
    ),
    'register-success': () => <RegisterSuccess onButtonClick={onSuccess} />,
    workspace: () => <CreateWorkspace onSuccess={onSuccess} />,
    invite: () => <Invite link_id={link_id} />,
  }[_type]

  return Component()
}

export function LoginModal(props: LoginProps) {
  const {
    type = 'login',
    open = false,
    closable = false,
    onSuccess,
    onCancel,
    link_id,
  } = props

  const [modalVisible, { toggle: toggleModalVisible, set }] = useBoolean(open)

  const handleCancel = () => {
    onCancel?.()
    toggleModalVisible()
  }

  useEffect(() => {
    set(open)
  }, [open])

  return (
    <Modal
      open={modalVisible}
      width={type === 'workspace' ? 600 : 448}
      footer={null}
      closeIcon={false}
      closable={closable}
      maskClosable
      onCancel={handleCancel}
      bodyStyle={{ padding: 0 }}
    >
      <LoginContent type={type} onSuccess={onSuccess} link_id={link_id} />
    </Modal>
  )
}

export function useLoginModal() {
  return useModal(LoginModal)
}
