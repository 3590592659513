import { memo, useMemo, useRef, useState } from 'react'
import { isEqual, uniqBy } from 'lodash-es'
import cn from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { IconFont } from '@/components'

interface FlatViewSelectProps {
  value?: string[]
  onChange?: (value: string[]) => void
  options: string[]
}

export const FlatViewSelect = memo<FlatViewSelectProps>(props => {
  const { options, onChange } = props
  const value = Array.isArray(props.value) ? props.value : []

  const [customOptions, setCustomOptions] = useState(
    Array.isArray(value) ? value.filter(item => !options.includes(item)) : [],
  )

  const [isCustomEditing, setIsCustomEditing] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const finalOptions = useMemo(() => {
    return uniqBy(
      [
        ...options.map(item => ({
          value: item,
          isCustom: false,
        })),
        ...customOptions.map(item => ({
          value: item,
          isCustom: true,
        })),
      ],
      'value',
    )
  }, [options, customOptions])

  const handleSelect = useMemoizedFn(selectValue => {
    let newValue = [...(value ?? [])]
    if (newValue.includes(selectValue)) {
      newValue = newValue.filter(item => item !== selectValue)
    } else {
      newValue.push(selectValue)
    }
    onChange?.(newValue)
  })

  const handleSelectCustomValue = useMemoizedFn(() => {
    if (inputValue.trim()) {
      if (!finalOptions.find(item => item.value === inputValue)) {
        setCustomOptions([...customOptions, inputValue])
      }
      handleSelect(inputValue)
    }
    setIsCustomEditing(false)
    setInputValue('')
  })

  const handleDeleteOptions = useMemoizedFn((option: string) => {
    setCustomOptions(customOptions.filter(item => item !== option))
    if (value.includes(option)) {
      onChange?.(value.filter(item => item !== option))
    }
  })

  return (
    <div className='flex gap-8 flex-wrap transition-all-150 ease-in-out'>
      {finalOptions.map((option, index) => {
        const isSelect = value?.includes(option.value)
        return (
          <div
            key={`${option}-${index}`}
            className={cn(
              'font-400 flex items-center py-5 px-7 max-w-full cursor-pointer hover:bg-bg_3/8 b-1 b-line rd-4px',
              {
                '!b-primary c-primary bg-primary/8': isSelect,
              },
            )}
            onClick={() => handleSelect(option.value)}
          >
            {option.value}
            {option.isCustom && (
              <IconFont
                name='guanbi'
                onClick={() => handleDeleteOptions(option.value)}
                className='c-font_1/60 ml-4 cursor-pointer font-400 text-12px w-12px h-12px'
              />
            )}
          </div>
        )
      })}
      <div
        className={cn(
          'flex px-7 py-5 rd-4px b-1 hover:bg-bg_3/8 b-line c-font_1/60 cursor-pointer gap-4',
          {
            '!hidden': isCustomEditing,
          },
        )}
        onClick={() => {
          setIsCustomEditing(true)
          setTimeout(() => {
            inputRef.current?.focus()
          })
        }}
      >
        <IconFont name='add' />
        <span>自定义</span>
      </div>
      <input
        ref={inputRef}
        type='text'
        autoFocus
        placeholder='请输入'
        value={inputValue}
        className={cn(
          'outline-0 b-1 b-line px-7px rd-4px h-26px hover:b-primary focus:b-primary w-76px',
          {
            '!hidden': !isCustomEditing,
          },
        )}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={e => {
          if (e.code === 'Enter') {
            e.preventDefault()
            handleSelectCustomValue()
          }
        }}
        onBlur={() => handleSelectCustomValue()}
      />
    </div>
  )
}, isEqual)
