import { memo, useEffect, useMemo, useState } from 'react'
import { Form, message } from 'antd'
import { useMemoizedFn } from 'ahooks'
import cn from 'classnames'
import type { Variable } from '@/features/editor/TextEditor'
import type { PromptStructItem } from '@/apis/prompt.ts'
import { IconFont } from '@/components'
import { generateCustomStructPrompt } from '@/features/prompt/constant/structPrompt.ts'
import { getRandomPlaceholderMap } from '@/features/prompt/utils/placeholder.ts'
import { PromptStructItem as PromptStructItemComp } from './PromptStructItem.tsx'

interface PromptStructEditorProps {
  className?: string
  mini?: boolean
  variables?: Variable[]
  variableTipsContainer?: HTMLElement | null
  anchor?: 'left' | 'right'
  value?: PromptStructItem[]
  skillSyncEnable?: boolean
  skills?: { name: string; description: string }[] // 需要一键同步的插件和工作流
  onChange?: (value: PromptStructItem[]) => void
  onFullscreenChange?: (state: boolean) => void
}

export const FormListName = 'structPrompt'

export const PromptStructEditor = memo<PromptStructEditorProps>(props => {
  const {
    className,
    mini = false,
    variables,
    variableTipsContainer,
    anchor,
    value,
    skillSyncEnable,
    skills,
    onChange,
    onFullscreenChange,
  } = props
  const [form] = Form.useForm<{ [FormListName]: PromptStructItem[] }>()
  const [fullScreenIndex, setFullScreenIndex] = useState<number>(-1)

  const handleValueChange = useMemoizedFn(
    (_value: any, values: { [FormListName]: PromptStructItem[] }) => {
      onChange?.(values[FormListName])
    },
  )

  const handleToggleFullScreen = useMemoizedFn(index => {
    if (fullScreenIndex === index) {
      setFullScreenIndex(-1)
      onFullscreenChange?.(false)
    } else {
      setFullScreenIndex(index)
      onFullscreenChange?.(true)
    }
  })

  // 手动触发单个字段的更新
  // 因为antd form setFieldValue 无法触发 form onValuesChange 事件
  const handleCustomValueChange = (index: number, key: string, value: any) => {
    form.setFieldValue([FormListName, index, key], value)
    onChange?.(form.getFieldsValue()[FormListName])
  }

  const handleSkillSync = useMemoizedFn((index: number) => {
    if (!skills?.length) {
      message.warning('暂无工作流/插件，请添加后尝试')
    } else {
      const skillContent = skills
        .map(item => {
          return `**${item.name}**: ${item.description};`
        })
        .join('\n')
      handleCustomValueChange(index, 'content', skillContent)
      message.success('同步成功')
    }
  })

  const placeholderMap = useMemo(() => {
    return getRandomPlaceholderMap()
  }, [])

  useEffect(() => {
    form.setFieldValue(FormListName, value)
  }, [value])

  return (
    <Form
      className={className}
      form={form}
      component={false}
      initialValues={{ [FormListName]: value }}
      onValuesChange={handleValueChange}
    >
      <div className='w-full flex flex-col gap-8'>
        <Form.List name={FormListName}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((item, index) => {
                  return (
                    <PromptStructItemComp
                      form={form}
                      index={item.name}
                      variables={variables}
                      variableTipsContainer={variableTipsContainer}
                      anchor={anchor}
                      onRemove={() => remove(index)}
                      fullscreen={fullScreenIndex === index}
                      toggleFullScreen={() => handleToggleFullScreen(index)}
                      onSkillSync={handleSkillSync}
                      placeholderMap={placeholderMap}
                      skillSyncEnable={skillSyncEnable}
                      onValueChange={handleCustomValueChange}
                      className={cn({
                        '!hidden':
                          fullScreenIndex !== -1 && fullScreenIndex !== index,
                      })}
                      textareaClassName={cn({
                        'text-12px! [&_textarea]:text-12px! bg-transparent!':
                          mini,
                      })}
                      skills={skills ?? []}
                      key={`struct-prompt-${item.key}`}
                      showTools={!mini}
                      mini={mini}
                    />
                  )
                })}
                <div
                  className={cn(
                    'h-36px flex-center rd-6px bg-#fff cursor-pointer b-1 b-line/80 hover:bg-bg_3/8',
                    {
                      'hidden!': fullScreenIndex !== -1,
                      'h-32px! text-12px!': mini,
                    },
                  )}
                  onClick={() => {
                    add(generateCustomStructPrompt())
                  }}
                >
                  <div className='flex-center gap-6'>
                    <IconFont name='add' className='text-14px' />
                    <span>添加主题</span>
                  </div>
                </div>
              </>
            )
          }}
        </Form.List>
      </div>
    </Form>
  )
})

PromptStructEditor.displayName = 'PromptStructEditor'
