import { DatePicker } from 'antd'
import styled from '@emotion/styled'

const { RangePicker } = DatePicker

export const RangePickerWrapper = styled(RangePicker)`
  background: rgba(98, 105, 153, 0.08);
  border-color: transparent;
  box-shadow: none;
  .ant-picker-input > input {
    font-size: 14px !important;
  }
`
