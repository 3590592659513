import type {
  UseOverlayScrollbarsInstance,
  UseOverlayScrollbarsParams,
} from 'overlayscrollbars-react'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import { useEffect, useRef } from 'react'
import Scrollbar from 'smooth-scrollbar'
import type { ScrollbarOptions } from 'smooth-scrollbar/interfaces'
import 'overlayscrollbars/overlayscrollbars.css'
import { useMemoizedFn } from 'ahooks'
import { wait } from '@/utils/wait'

export function useScrollBar(options?: Partial<ScrollbarOptions>) {
  const scrollRef = useRef<any>(null)
  const scrollBar = useRef<Scrollbar>()

  const init = () => {
    if (scrollRef.current) {
      scrollBar.current = Scrollbar.init(
        scrollRef.current,
        Object.assign(
          {
            damping: 0.5,
          },
          options,
        ),
      )
    }
  }

  useEffect(init, [scrollRef.current])

  return {
    scrollBar,
    scrollRef,
  }
}

export const DEFAULT_OVERLAY_SCROLLBAR_OPTIONS = {
  overflow: {
    x: 'hidden',
    y: 'scroll',
  },
  scrollbars: {
    autoHide: 'leave',
    autoHideDelay: 1000,
  },
} as UseOverlayScrollbarsParams['options']

export function useSafeScrollbar<T extends HTMLElement>(
  options?: UseOverlayScrollbarsParams['options'],
): [React.MutableRefObject<T | null>, UseOverlayScrollbarsInstance] {
  const ref = useRef<T | null>(null)

  const [initialize, instance] = useOverlayScrollbars({
    options: {
      ...DEFAULT_OVERLAY_SCROLLBAR_OPTIONS,
      ...options,
    },
    defer: true,
  })

  useEffect(() => {
    if (ref.current) {
      initialize(ref.current)
    }
  }, [initialize])

  return [ref, instance]
}

const scrollTopCacheMap = new Map<string, any>()

export function useBackBefore(
  ref: React.MutableRefObject<Scrollbar | undefined>,
  localKey: string,
  dep: any[] = [],
) {
  const count = useRef(0)

  const backToScroll = useMemoizedFn(async () => {
    if (!scrollTopCacheMap.has(localKey)) return
    const oldValue = scrollTopCacheMap.get(localKey)

    ref.current?.scrollTo(0, oldValue || 0, 300)
    ref.current?.update()

    if (oldValue === ref.current?.offset?.y || count.current > 4) {
      count.current = 0
      scrollTopCacheMap.delete(localKey)
    }

    await wait(400)
    count.current += 1
    backToScroll()
  })

  useEffect(() => {
    return () => {
      scrollTopCacheMap.set(localKey, ref.current?.offset?.y || 0)
    }
  }, [])

  useEffect(() => {
    if (dep.every(each => !!each)) return
    backToScroll()
  }, dep)

  return backToScroll
}

// export function useSafeBackBefore(
//   osInstance: ReturnType<UseOverlayScrollbarsInstance>,
//   localKey: string,
//   deps: unknown[],
// ) {
//   osInstance?.on('scroll', event => {
//     console.log('====== scroll', event)
//   })
//   const count = useRef(0)

//   const backToScroll = useMemoizedFn(async () => {
//     if (!scrollTopCacheMap.has(localKey)) {
//       return
//     }

//     const oldValue = scrollTopCacheMap.get(localKey)

//     if (osInstance) {
//       const viewport = osInstance.elements().viewport
//       console.log('====== oldValue', oldValue)
//       viewport.scrollTo({ top: oldValue || 0, behavior: 'smooth' })
//       osInstance.update()

//       const { scrollTop } = viewport
//       if (oldValue === scrollTop || count.current > 4) {
//         count.current = 0
//         scrollTopCacheMap.delete(localKey)
//       }
//     }

//     await wait(400)
//     count.current += 1
//     backToScroll()
//   })

//   useLayoutEffect(() => {
//     console.log('====== useLayoutEffect')
//     return () => {
//       console.log(
//         '====== osInstance?.elements().viewport.scrollTop',
//         osInstance?.elements().viewport.scrollTop,
//       )
//       scrollTopCacheMap.set(
//         localKey,
//         osInstance?.elements().viewport.scrollTop || 0,
//       )
//     }
//   })

//   useEffect(() => {
//     if (deps.every(each => !!each)) {
//       return
//     }
//     backToScroll()
//   }, deps)

//   return backToScroll
// }
