import { useRequest } from 'ahooks'
import { xor } from 'lodash-es'
import { useState } from 'react'
import type { AgentSkillBaseRequest } from '@bty/chat-types'
import { fetchAgentSkillList } from '@/apis/agent.ts'
import { AgentSkillType } from '@/features/agent/constant'
import type { FieldItem } from '@/features/nodes/start'

export interface SkillError {
  type: 'warning' | 'error'
  message: string
}
export function useSkillConfig() {
  const [enabledSkills, setEnabledSkills] = useState<string[]>([])
  const { data, refresh, refreshAsync, loading, runAsync } = useRequest(
    fetchAgentSkillList,
    {
      manual: true,
      onSuccess: data => {
        const { utility, flows } = data
        const skills = utility
          .filter(item => {
            return item?.is_enable
          })
          .map(item => {
            return item.name
          })
        const flowSkills = flows
          .filter(item => {
            return item?.is_enable
          })
          .map(item => {
            return item.app_id
          })
        setEnabledSkills([...skills, ...flowSkills])
      },
    },
  )

  const skillConfigMap: Record<'flow' | 'tool', Record<string, true>> = {
    flow: {},
    tool: {},
  }
  const flowList =
    data?.flows?.map(item => {
      skillConfigMap.flow[item.flow_id] = true
      let error: SkillError | undefined
      if (item.agent_rel_flow_status === 'DELETED') {
        error = {
          type: 'error',
          message: 'Flow被删除',
        }
      } else if (!item.flow_enabled) {
        error = {
          type: 'error',
          message: 'Flow被改为“已停用”',
        }
      } else if (
        checkSkillVariableDiff(item.rel_flow_form_config, item.inputs)
      ) {
        error = {
          type: 'warning',
          message: '变量改变注意查看',
        }
      }

      return {
        id: item.id,
        name: item.name,
        description: item.description,
        type: AgentSkillType.FLOW,
        icon: item.icon,
        color: item.color,
        meta: item,
        error,
      }
    }) ?? []

  const toolList =
    data?.utility?.map(item => {
      skillConfigMap.tool[item.function_id] = true
      return {
        id: item.function_id,
        name: item.display_name,
        description: item.description,
        type: AgentSkillType.TOOL,
        icon: item.metadata?.icon,
        color: item.metadata?.color,
        meta: item,
      }
    }) ?? []

  const hasError = flowList.some(item => item.error)

  return {
    flowList,
    toolList,
    skillConfigMap,
    refresh,
    refreshAsync,
    loading,
    runAsync,
    hasError,
    enabledSkills,
  }
}

/**
 * 检查skill的输入变量是否有变动
 */
function checkSkillVariableDiff(
  flowFields: FieldItem[],
  skillInputs: AgentSkillBaseRequest['inputs'],
) {
  const flowVariables = flowFields.map(item => item.variableName)
  const skillVariables = skillInputs?.map(item => item.key)
  const diff = xor(flowVariables, skillVariables)
  return !!diff.length
}
