import { useAuth } from '@bty/react-auth'
import { Tag } from 'antd'
import { useLimitedModal } from '@/features/pay'
import { isPrivateVersion, DefaultModelIcon } from '@/constants/common.ts'
import type { LLMModelLimitedItem } from '@/apis/llm/type.ts'
import { SquareDots } from './SquareDots'
import type { ModelSelectOption } from './ModelSelect'

interface ModelOptionItemProps {
  opt: ModelSelectOption
  findLimitItem?: LLMModelLimitedItem
}

export function ModelOptionItem({ opt, findLimitItem }: ModelOptionItemProps) {
  const {
    state: { role },
  } = useAuth()
  const [upgradeModal] = useLimitedModal()
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (opt.needLevelUp && !opt.disabled) {
      upgradeModal.open({
        role,
        showVersions: ['Free', 'Team', 'Enterprise'],
      })
      e.stopPropagation()
    }
  }
  return (
    <div
      className='px-12 py-8px text-12px w-100% h-full flex flex-col gap-y-6px'
      onClick={handleClick}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <img
            src={opt?.feature?.icon || DefaultModelIcon}
            alt='model_icon'
            className='w-16px h-16px mr-6px'
          />
          <h4 className='text-12px/16px font-500'>{opt.model}</h4>
          {opt?.feature?.length && (
            <span className='c-#8D8D99 text-12px/12px ml-6px'>
              ({opt?.feature?.length})
            </span>
          )}
          {opt?.feature?.suggest && (
            <div className='w-26px h-14px bg-#FFECE6 c-#FF8056 text-9px/10px flex-center ml-8px rounded-100px'>
              推荐
            </div>
          )}
          {/* 后端没有给出枚举类型 todo后续需要让后端给出类型定义
          chat代表多模态 */}
          {opt?.type === 'chat' && (
            <div className='w-35px h-14px bg-#EFF8FF c-#38A6FF text-9px/10px flex-center ml-8px rounded-100px'>
              多模态
            </div>
          )}
        </div>
        {findLimitItem?.llm_enabled && !isPrivateVersion && (
          <span>{findLimitItem.llm_consume_points}积分/次</span>
        )}
      </div>

      <div className='flex  flex-items-center justify-between'>
        <div className='flex'>
          {/* <span className='text-12px/12px c-#8D8D99 mr-6px'>稳定</span>
          <SquareDots activeCount={opt?.feature?.stability || 0} /> */}
          {opt?.feature?.speed && (
            <>
              <span className='text-12px/12px c-#8D8D99 mr-6px'>速度</span>
              <SquareDots activeCount={opt?.feature?.speed || 0} />
            </>
          )}
        </div>
        <div className=''>
          {opt?.feature?.ability?.map((item, index) => (
            <Tag
              key={index}
              className='c-#8D8D99 me-0 ml-4px b-#E1E1E599 rounded-4px bg-#FFFFFF'
            >
              {item}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  )
}
