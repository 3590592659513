import { useCallback } from 'react'
import { Modal } from '@/components'
import { useModal } from '@/hooks/useModal'
import customerServiceQRCode from '@/assets/customerServiceQRCode.png'

export function useContactCustomerServiceModal() {
  const ContactCustomerServiceModal = useCallback((props: any) => {
    return (
      <Modal
        zIndex={1032}
        width={480}
        {...props}
        footer={null}
        title='联系客服'
        styles={{
          body: {
            padding: '60px 155px',
          },
        }}
      >
        <div>
          <div className='mb-16px'>
            <img src={customerServiceQRCode} className='w-170px' />
          </div>
          <div className='text-center'>微信扫码联系客服</div>
        </div>
      </Modal>
    )
  }, [])
  return useModal(ContactCustomerServiceModal)
}
