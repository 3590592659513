import { memo, useMemo } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import type { ApplicationBodyType } from '@/apis/application'
import { usePluginActionOnAgent } from '@/features/chat/hooks/usePluginActionOnAgent'
import type { AgentDiffMessageProps } from './AgentDiffMessage'
import { AgentDiffMessage } from './AgentDiffMessage'
import { AgentDiffInput } from './AgentDiffInput'

export const DEFAULT_OVERLAY_SCROLLBAR_OPTIONS = {
  overflow: {
    x: 'scroll',
    y: 'hidden',
  },
  scrollbars: {
    autoHide: 'leave',
    autoHideDelay: 1000,
  },
} as const

interface AgentDiffProps {
  applicationInfo: ApplicationBodyType
  onUse: AgentDiffMessageProps['onUse']
  onDelete: AgentDiffMessageProps['onDelete']
  onReNew: () => Promise<void>
  uploadFileConfig: {
    fileAccepts: string[]
    allowFileTypeNameList: string[]
    acceptsAllFileTypes?: boolean
    addFileProcessingPlugin?: () => void
  }
}

export const AgentDiff = memo((props: AgentDiffProps) => {
  const { applicationInfo, onUse, onDelete, onReNew, uploadFileConfig } = props
  const diffConfigs = useAgentDiffStore(s => s.diffConfigs)

  const config = useMemo(() => {
    return applicationInfo?.config as Exclude<
      ApplicationBodyType['config'],
      string
    >
  }, [applicationInfo?.config])

  const { addFileProcessingPlugin } = usePluginActionOnAgent()

  return (
    <div className='h-full flex flex-col relative'>
      <div className='flex-1 overflow-hidden'>
        <OverlayScrollbarsComponent
          className='overflow-scroll h-full w-full pb-96px'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <div className='h-full flex px-24px gap-12px w-fit min-w-100% max-w-1070px relative'>
            {diffConfigs.map((_each, index) => {
              return (
                <div
                  key={index}
                  className='min-w-334px flex-1 bg-#fff rounded-8px'
                  style={{ border: '1px solid rgba(225, 225, 229, 0.6)' }}
                >
                  <AgentDiffMessage
                    id={index}
                    robotId={applicationInfo.flowId}
                    onUse={onUse}
                    onDelete={onDelete}
                  />
                </div>
              )
            })}
          </div>
        </OverlayScrollbarsComponent>
      </div>

      <AgentDiffInput
        config={config}
        onReNew={onReNew}
        uploadFileConfig={{
          ...uploadFileConfig,
          addFileProcessingPlugin,
        }}
      />
    </div>
  )
})
