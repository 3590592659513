import type { IPluginItem } from '@/apis/flow'
import { PluginItemCard } from './PluginItemCard'

interface PluginLabelItem {
  label: string
  data: IPluginItem[]
}

interface PluginListProps {
  data: PluginLabelItem[]
  activeKey: string
  onSelect: (id: string) => void
}

export function PluginList(props: PluginListProps) {
  const { data, activeKey, onSelect } = props

  return (
    data.length > 0 && (
      <>
        <div className='pt-12px pb-24px text-16px font-500 c-#17171d'>插件</div>
        {data.map(({ label, data: list }) => (
          <div key={label}>
            <div className='pb-12px font-500 text-#8d8d99'>{label}</div>
            {list.map(
              ({
                function_id,
                display_name,
                icon,
                description,
                form_config,
              }) => (
                <PluginItemCard
                  key={function_id}
                  id={function_id}
                  name={display_name}
                  iconURL={icon}
                  description={description}
                  fields={form_config}
                  selected={function_id === activeKey}
                  onClick={onSelect}
                />
              ),
            )}
          </div>
        ))}
      </>
    )
  )
}
