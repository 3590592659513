import { useCallback, useMemo } from 'react'
import { isString } from 'lodash-es'
import classNames from 'classnames'
import { CodeEditor } from '@/features/editor'
import { IconFont } from '@/components'
import { AddButton } from '../../components'
import type { FieldExtraItem } from '../../start'

// import { AddButton } from '.'

interface LabelProps {
  key?: string
  value: string
}
interface MultiInputProps {
  value?: LabelProps[]
  type?: 'JSON' | 'FormData'
  disabled?: boolean
  onChange?: (value: LabelProps[]) => void
  variables?: Array<{ label: string; type?: string }>
  limit?: FieldExtraItem['limit']
  variableTipsContainer?: HTMLElement | null // 变量提示锚定位置  默认为编辑器本身
  showButton?: boolean
  placeholder?: string
  btnText?: string
}
const DEFAULT_VALUE: LabelProps[] = [{ value: '' }]

function transformValue(value: MultiInputProps['value']) {
  if (!value) return DEFAULT_VALUE
  const tValue: Record<string, any> | string = value
  if (Array.isArray(tValue)) {
    return tValue.length ? tValue : DEFAULT_VALUE
  } else if (isString(tValue)) {
    return tValue ? [{ value: tValue }] : DEFAULT_VALUE
  } else {
    return DEFAULT_VALUE
  }
}
export function MultiInput(props: MultiInputProps) {
  const {
    value = [],
    showButton,
    limit = { min: 1, max: 2 },
    placeholder = '',
    btnText = '添加更多',
    disabled,
  } = props
  const arrValue = useMemo<LabelProps[]>(() => {
    const val = transformValue(value)
    return isString(val) ? (val ? [{ value: val }] : DEFAULT_VALUE) : val
  }, [value])

  const handleEditorChange = (
    value: string,
    index: number,
    type = 'key' || 'value',
  ) => {
    const newValue = arrValue?.map((item, i) => {
      return index === i ? { ...item, [type]: value } : item
    })
    props.onChange?.(newValue)
  }

  const handleAdd = useCallback(() => {
    if (disabled) {
      return
    }
    props.onChange?.([...arrValue, { value: '' }])
  }, [arrValue, disabled, props.onChange])

  const disabledRemove = useMemo(() => {
    return arrValue?.length <= limit.min
  }, [limit.min, arrValue])

  const disabledAdd = useMemo(() => {
    return arrValue?.length >= limit.max
  }, [limit.max, arrValue])

  const handleRemove = useCallback(
    (index: number) => {
      if (disabledRemove) {
        return
      }
      const newValue = arrValue.filter((_v, i) => i !== index)

      props.onChange?.(newValue)
    },
    [arrValue, props.onChange],
  )

  return (
    <div>
      {(arrValue || [])?.map?.((item, index) => {
        return (
          <div key={index} className='flex items-center mb-4'>
            <CodeEditor
              className='ace-gray h-32px flex-1'
              wrapEnabled={false}
              singleLine
              height='32px'
              disabled={disabled}
              value={`${item.value}`}
              width='100%'
              setOptions={{ maxLines: 1 }}
              variableTipsContainer={props.variableTipsContainer}
              variables={props.variables}
              onChange={value => handleEditorChange(value, index, 'value')}
              placeholder={placeholder}
            />
            <div
              onClick={() => handleRemove(index)}
              className={classNames(
                'ml-4 mr--4px flex items-center justify-center w-20px h-20px text-opacity-60 hover:bg-opacity-12 text-font_1 rounded-4px',
                {
                  'cursor-not-allowed': disabledRemove,
                  'hover:bg-error hover:text-error': !disabledRemove,
                },
              )}
            >
              <IconFont
                name='guanbi'
                className={classNames('text-10px cursor-pointer', {
                  '!cursor-not-allowed': disabledRemove,
                  'c-[rgba(98,105,153,0.24)]': disabledRemove,
                })}
              />
            </div>
          </div>
        )
      })}

      {showButton && (
        <AddButton disabled={disabledAdd || disabled} onClick={handleAdd}>
          {btnText}
        </AddButton>
      )}
    </div>
  )
}
