import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import type { Bot } from '@bty/chat-types'
import Chatbot from '../chatbot/index'
import useShareAgentAuthentic from '@/hooks/useShareAgentAuthentic'
import { isMobileDevice } from '@/utils/device.ts'
import { usePublicStore } from '@/store'
import { useUnreadMessageListener } from '@/pages/home/hooks/useUnreadMessageListener.ts'
import { AgentNotAllowed } from './components/AgentNotAllowed'

export function Text({ text }: { text: string }) {
  return (
    <div className='c-bg_3 text-20px font-500 c-op-40 w-100% h-100vh flex flex-items-center justify-center'>
      {text}
    </div>
  )
}

export function Chat() {
  const navigate = useNavigate()

  const { botId } = useParams()

  const setIsPublic = usePublicStore(state => state.setIsPublic)

  const bot = useLoaderData() as Bot

  const openchat = localStorage.getItem('openchat')
  const openchatMap = openchat ? JSON.parse(openchat) : {}

  const [openchatConversationId, setOpencatConversationId] = useState(
    botId ? openchatMap[botId] : undefined,
  )

  // 监听chat未读消息数
  useUnreadMessageListener()

  const { loading, isEnable, isPublic, isLogin, authentic, roleCode, getUser } =
    useShareAgentAuthentic({ bot, manual: true })

  useEffect(() => {
    setIsPublic(!!isPublic)
    if (isPublic === false) {
      getUser()
    }
  }, [isPublic, isLogin])

  if (loading) {
    return <Spin />
  }

  if (isPublic) {
    return (
      <div className='h-100vh'>
        <Chatbot
          openchatConversationId={openchatConversationId}
          canShare={false}
          showHistoryList={false}
          showPowerBy={true}
          showChatMenu={false}
          showTask
          onConversationCreated={id => {
            if (botId && id) {
              const openchat = localStorage.getItem('openchat')
              const openchatMap = openchat ? JSON.parse(openchat) : {}
              openchatMap[botId] = id
              setOpencatConversationId(id)
              localStorage.setItem('openchat', JSON.stringify(openchatMap))
            }
          }}
        />
      </div>
    )
  }

  if (isEnable === false) {
    // agent未启用
    return <Text text='agent未启用' />
  }

  if (!isLogin) {
    // 没有登录，等待登录接口返回
    navigate(
      `/login?redirect=${encodeURIComponent(
        window.location.pathname + window.location.search,
      )}&channel=ai-share`,
    )
    return
  }

  // 已登录，无权限
  if (authentic === false) {
    return <AgentNotAllowed agentId={botId} roleCode={roleCode} />
  }

  if (authentic === null) {
    return
  }

  return (
    <div className='h-100vh'>
      <Chatbot showTask showPowerBy={true} showChatMenu={false} />
    </div>
  )
}

export default function ChatWithMobileView() {
  const { botId } = useParams()

  if (isMobileDevice()) {
    location.href = `${
      import.meta.env.VITE_CHATBOT_MOBILE_URL
    }/openchat?botId=${botId}`
    return
  }

  return <Chat />
}
