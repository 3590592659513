import styled from '@emotion/styled'
import { Checkbox } from 'antd'
import { Button, Input } from '@/components'

export const LoginButton = styled(Button)`
  &.ant-btn-primary {
    border-radius: 6px;
  }
  &.ant-btn-primary:disabled {
    color: #fff;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
  }
`

export const LoginTextButton = styled(Button)`
  &.ant-btn {
    color: #7b61ff;
    font-weight: 400;
    border-radius: 6px;
  }
  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: #7b61ff;
  }
  &.ant-btn:disabled {
    color: #7b61ff;
    opacity: 0.4;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border: 1px solid rgba(141, 141, 153, 0.6);
    /* box-shadow: 0px 2px 4px 0px rgba(23, 26, 29, 0.08); */
    border-radius: 4px;
  }
`

export const StyledInput = styled(Input)``
