import type { CustomEdgePathOptions } from '../edges/InsertEdge'
import { CustomEdgePathType } from '../edges/InsertEdge'
import { NODE_WIDTH, SPACE_BETWEEN_NODES } from '../layout'
import type { ICurveDirection } from './svgPath'
import { getCurveBySinglePoint } from './svgPath'

export function getEdgeCenter({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
}): [number, number, number, number] {
  const xOffset = Math.abs(targetX - sourceX) / 2
  const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset

  const yOffset = Math.abs(targetY - sourceY) / 2
  const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset

  return [centerX, centerY, xOffset, yOffset]
}

export interface GetConditionBranchPathParams {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  options: CustomEdgePathOptions
}

export function getConditionBranchPath({
  sourceX,
  sourceY,
  targetX,
  targetY,
  options,
}: GetConditionBranchPathParams): [
  path: string,
  labelX: number,
  labelY: number,
] {
  const { points, pathType } = options
  // console.log('绘制条件分支path\n', pathType, `\n(${sourceX}, ${sourceY}) => ${points.map(({ x, y }) => `(${x}, ${y})`).join(' => ')} => (${targetX}, ${targetY})`)

  let [labelX, labelY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })

  let middlePath = ''
  if (pathType === CustomEdgePathType.BRANCH) {
    // const { x: startX, y: startY } = points[0]
    // const { x: endX, y: endY } = points.slice(-1)[0]
    // middlePath += `L${startX} ${sourceY} `
    // points.forEach(({ x, y }) => {
    //   middlePath += ` L${x} ${y} `
    // })
    // middlePath += `L${endX} ${endY} L${endX} ${targetY} `
    const pathPointsData: {
      point: [number, number]
      dir: [ICurveDirection, ICurveDirection]
    }[] = [
      {
        point: [points[0].x, sourceY],
        dir: [
          { x: 1, y: 0 },
          { x: 0, y: points[0].y > sourceY ? 1 : -1 },
        ],
      },
      {
        point: [points[0].x, points[1].y],
        dir: [
          { x: 0, y: points[0].y > sourceY ? 1 : -1 },
          { x: 1, y: 0 },
        ],
      },
      {
        point: [points[2].x, points[2].y],
        dir: [
          { x: 1, y: 0 },
          { x: 0, y: -1 },
        ],
      },
    ]
    pathPointsData.forEach(({ point, dir }, i) => {
      if (i === pathPointsData.length - 1 && point[1] === targetY) {
        middlePath += `L${point[0]} ${point[1]} `
      } else {
        const { start, end, path } = getCurveBySinglePoint(point, dir)
        middlePath += `L${start[0]} ${start[1]} `
        middlePath += path
        middlePath += `L${end[0]} ${end[1]} `
      }
    })

    labelX = points[1].x + NODE_WIDTH / 2
    // labelX = (startX + endX) / 2
    labelY = points[0].y
  } else if (pathType === CustomEdgePathType.BRANCH_END) {
    const { x: endX, y: endY } = points.slice(-1)[0]
    // middlePath += `L${endX} ${sourceY} `
    // points.forEach(({ x, y }) => {
    //   middlePath += ` L${x} ${y} `
    // })
    // middlePath += `L${endX} ${targetY}`
    if (points[0].y === targetY) {
      middlePath += `L${points[0].x} ${points[0].y} `
    } else {
      const dir: [ICurveDirection, ICurveDirection] = [
        { x: 1, y: 0 },
        { x: 0, y: -1 },
      ]
      const { start, end, path } = getCurveBySinglePoint(
        [points[0].x, points[0].y],
        dir,
      )
      middlePath += `L${start[0]} ${start[1]} `
      middlePath += path
      middlePath += `L${end[0]} ${end[1]} `
    }
    const distanceX = sourceX + SPACE_BETWEEN_NODES + NODE_WIDTH / 2
    labelX = distanceX > endX ? (endX + sourceX) / 2 : distanceX
    labelY = endY
  } else if (pathType === CustomEdgePathType.BRANCH_START) {
    const { x: startX, y: startY } = points[0]
    const { x: endX } = points.slice(-1)[0]
    // middlePath += ` L${startX} ${sourceY} `
    // points.forEach(({ x, y }) => {
    //   middlePath += ` L${x} ${y} `
    // })
    const pathPointsData: {
      point: [number, number]
      dir: [ICurveDirection, ICurveDirection]
    }[] = [
      {
        point: [points[0].x, sourceY],
        dir: [
          { x: 1, y: 0 },
          { x: 0, y: targetY > sourceY ? 1 : -1 },
        ],
      },
      {
        point: [points[0].x, points[1].y],
        dir: [
          { x: 0, y: targetY > sourceY ? 1 : -1 },
          { x: 1, y: 0 },
        ],
      },
    ]
    pathPointsData.forEach(({ point, dir }) => {
      const { start, end, path } = getCurveBySinglePoint(point, dir)
      middlePath += `L${start[0]} ${start[1]} `
      middlePath += path
      middlePath += `L${end[0]} ${end[1]} `
    })

    labelX = (startX + endX) / 2
    labelY = startY
  }

  const edgePath = `M${sourceX} ${sourceY} ${middlePath} ${
    targetX + 10
  } ${targetY}`
  return [edgePath, labelX, labelY]
}
