import classNames from 'classnames'
import type { ReactNode } from 'react'
import Keywords from '@/pages/datastores/components/Keywords.tsx'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

function KeywordsView(props: {
  className?: string
  value: string[]
  highlightValue?: string[]
  empty?: ReactNode
}) {
  const { scrollRef } = useScrollBar()

  return (
    <div
      ref={scrollRef}
      className={classNames(
        'whitespace-nowrap overflow-x-scroll',
        props.className,
      )}
    >
      <div className='w-full flex gap-8 items-center'>
        <span className='font-normal !line-height-20px text-[12px] c-font_1'>
          关键词
        </span>
        {props.value.length ? (
          <Keywords value={props.value} highlightValue={props.highlightValue} />
        ) : (
          <span className='c-font_1 c-op-60 text-[12px] c-font_1 !line-height-26px'>
            {props.empty ?? '该段落暂无关键词'}
          </span>
        )}
      </div>
    </div>
  )
}

export default KeywordsView
