import { useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import type { Bot } from '@bty/chat-types'
import { useUserStore } from '@/store'
import { getApplicationById } from '@/apis/application'
import { RoleCode, getUserRoleAppInfos } from '@/apis/authority'

export default function useShareAgentAuthentic({
  bot,
  manual = false,
}: {
  bot: Partial<Bot>
  manual?: boolean
}) {
  const userStore = useUserStore()

  const [loaded, setLoaded] = useState(true)

  const [authentic, setAuthentic] = useState<any>(null)

  const [roleCode, setRoleCode] = useState<RoleCode>()

  const getUser = async (e: any) => {
    setLoaded(true)
    try {
      const res = await getUserRoleAppInfos(e.workspaceId)
      if (res.roleCode) {
        setRoleCode(res.roleCode)
        setAuthentic(
          res.roleCode === RoleCode.VIEWER
            ? !!(res.applications || []).find((item: any) => item.id === e?.id)
            : true,
        )
      }
    } catch (_) {
      setAuthentic(false)
    }
    const user = await userStore.fetchUser()
    setLoaded(false)
    return !!user && !user?.isAnonymous
  }

  const { data, loading, runAsync } = useRequest(
    () => getApplicationById(bot.app_id || ''),
    {
      manual: true,
      async onSuccess(e) {
        if (manual) {
          setLoaded(false)
          return
        }
        getUser(e)
      },
    },
  )

  useEffect(() => {
    bot?.app_id && runAsync()
  }, [bot?.app_id])

  const { isEnable, isPublic } = data || {}

  const isLogin =
    userStore.user === undefined
      ? undefined
      : !!userStore.user && !userStore.user?.isAnonymous

  return {
    loading: loading || loaded || !data || !bot,
    isEnable,
    isPublic,
    authentic,
    isLogin,
    roleCode,
    getUser: () => getUser(data),
    user: userStore.user,
  }
}
