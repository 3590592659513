import { useCallback, useLayoutEffect, useRef } from 'react'
import { useLatest } from 'ahooks'
import type { IError, EventSourceRequestHeaders } from '@/apis/sse'
import sse from '@/apis/sse'

export const EventSourceStatus = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSED: 2,
} as const

export type EventSourceStatusType =
  (typeof EventSourceStatus)[keyof typeof EventSourceStatus]

interface EventSourceReturn<TPayload = unknown> {
  run: (paylod: TPayload) => void
  cancel: () => void
}

export interface IDataRefConfig {
  duration_time?: number
  message: string
  message_type: 'aggregate' | 'piecewise' | 'error' | undefined
}

type Callbacks = Partial<{
  onConnecting: (state: typeof EventSourceStatus.CONNECTING) => void
  onOpen: (state: typeof EventSourceStatus.OPEN) => void
  onMessage: (
    state: typeof EventSourceStatus.OPEN,
    content: IDataRefConfig,
  ) => void
  onClose: (state: typeof EventSourceStatus.CLOSED) => void
  onError: (state: typeof EventSourceStatus.CLOSED, error?: IError) => void
}>

export function useEventSource<TPayload>(
  url: string,
  headers: EventSourceRequestHeaders,
  callbacks: Callbacks,
): EventSourceReturn<TPayload> {
  const { onConnecting, onOpen, onMessage, onClose, onError } = callbacks
  const onConnectingRef = useLatest(onConnecting)
  const onOpenRef = useLatest(onOpen)
  const onMessageRef = useLatest(onMessage)
  const onCloseRef = useLatest(onClose)
  const onErrorRef = useLatest(onError)

  const statusRef = useRef<EventSourceStatusType>(EventSourceStatus.CLOSED)

  const errorRef = useRef<IError>()

  const dataRef = useRef<IDataRefConfig>({
    message: '',
    message_type: undefined,
  })

  const abortRef = useRef<ReturnType<typeof sse>>()

  const sseRef = useRef<(payload: TPayload) => ReturnType<typeof sse>>()

  useLayoutEffect(() => {
    sseRef.current = function fetchEventSource(payload) {
      // sse 连接中
      statusRef.current = EventSourceStatus.CONNECTING
      onConnectingRef.current?.(EventSourceStatus.CONNECTING)

      return sse<TPayload>(url, payload, headers, {
        onOpen: () => {
          // sse 连接成功，开始通信
          statusRef.current = EventSourceStatus.OPEN
          onOpenRef.current?.(EventSourceStatus.OPEN)
        },
        onMessage: content => {
          if (content.message_type === 'aggregate') {
            dataRef.current = {
              duration_time: content.duration_time,
              message: content.message,
              message_type: 'aggregate',
            }
          } else if (content.message_type === 'piecewise') {
            dataRef.current = {
              duration_time: content.duration_time,
              message: dataRef.current.message + content.message,
              message_type: 'piecewise',
            }
          } else {
            dataRef.current = {
              duration_time: content.duration_time,
              message: dataRef.current.message + content.message,
              message_type: 'error',
            }
          }
          statusRef.current = EventSourceStatus.OPEN
          onMessageRef.current?.(EventSourceStatus.OPEN, dataRef.current)
        },
        onFinish: () => {
          dataRef.current = {
            message: '',
            message_type: undefined,
          }
          statusRef.current = EventSourceStatus.CLOSED
          onCloseRef.current?.(EventSourceStatus.CLOSED)
        },
        onError: error => {
          dataRef.current = {
            message: '',
            message_type: undefined,
          }
          errorRef.current = error
          onErrorRef.current?.(EventSourceStatus.CLOSED, error)
        },
      })
    }
  }, [url, JSON.stringify(headers)])

  const run = useCallback<(payload: TPayload) => void>(payload => {
    abortRef.current = sseRef.current?.(payload)
  }, [])

  // 用户主动调用的 abort 不再触发 onClose
  const cancel = useCallback(() => {
    dataRef.current = {
      message: '',
      message_type: 'piecewise',
    }
    statusRef.current = EventSourceStatus.CLOSED
    abortRef.current?.()
  }, [])

  return { run, cancel }
}

export default useEventSource
