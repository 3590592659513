import classNames from 'classnames'
import { IconFont } from '@/components'

interface CardDataLabelProps {
  iconName: string
  count: number
  title?: string
  className?: string
}

export function CardDataLabel(props: CardDataLabelProps) {
  const { iconName, count, title, className } = props

  return (
    <div
      className={classNames('flex flex-items-center text-#8d8d99', className)}
    >
      <IconFont name={iconName} className='mr-4px text-12px' />
      <span className='mr-4px text-12px'>{count}</span>
      {title && <span className='text-12px break-keep'>{title}</span>}
    </div>
  )
}
