import type { InputProps } from 'antd'
import { memo } from 'react'
import type { TextAreaProps } from 'antd/es/input'
import cn from 'classnames'
import { Input, TextArea } from '@/components'
import type { TextEditorProps } from '@/features/editor/TextEditor'
import { TextEditor } from '@/features/editor/TextEditor'

interface WithQuickFillInputControl {
  mini?: boolean
  value?: string
  onChange?: (value: string) => void
}

export const WithQuickFillInput = memo<InputProps & WithQuickFillInputControl>(
  props => {
    const { placeholder, ...restProps } = props

    return (
      <div className='relative of-hidden'>
        <div
          className={cn(
            'absolute line-height-22px pointer-events-none px-12 py-8 c-font_1/60 font-400 top-0 left-0 z-1 w-full h-full',
            {
              '!hidden': !!props.value,
            },
          )}
        >
          <div className='flex items-center'>
            <span className='line-height-20px whitespace-pre-line'>
              {placeholder}
            </span>
            <span className='py-1 px-4 c-font_1/60 b-1 mx-5 b-line/60 bg-bg_3/4 rd-4px'>
              Tab
            </span>
          </div>
        </div>
        <Input
          {...restProps}
          onKeyDown={e => {
            if (e.code === 'Tab' && !(e.target as HTMLTextAreaElement).value) {
              e.preventDefault()
              if (props.onChange && props.placeholder) {
                props.onChange(props.placeholder)
              }
            }
            props.onKeyDown?.(e)
          }}
        />
      </div>
    )
  },
)

WithQuickFillInput.displayName = 'Input'

export const WithQuickFillTextArea = memo<
  TextAreaProps & WithQuickFillInputControl
>(props => {
  const { placeholder, ...restProps } = props

  return (
    <div className='relative of-hidden'>
      <div
        className={cn(
          'absolute line-height-22px pointer-events-none px-12 py-5 c-font_1/60 font-400 top-0 left-0 z-1 w-full h-full',
          {
            '!hidden': !!props.value,
          },
        )}
      >
        <span className='line-height-22px whitespace-pre-line'>
          {placeholder}
        </span>
        <span className='py-1 px-4 c-font_1/60 b-1 mx-5 b-line/60 bg-bg_3/4 rd-4px'>
          Tab
        </span>
      </div>
      <TextArea
        {...restProps}
        onKeyDown={e => {
          if (e.code === 'Tab' && !(e.target as HTMLTextAreaElement).value) {
            e.preventDefault()
            if (props.onChange && props.placeholder) {
              props.onChange(props.placeholder)
            }
          }
          props.onKeyDown?.(e)
        }}
      />
    </div>
  )
})

WithQuickFillTextArea.displayName = 'TextArea'

export const WithQuickFillTextEditor = memo<
  TextEditorProps & WithQuickFillInputControl
>(props => {
  const { mini = false, placeholder, ...restProps } = props
  return (
    <div className='relative of-hidden'>
      <div
        className={cn(
          'absolute line-height-20px pointer-events-none px-12 py-0 c-font_1/60 font-400 top-0 left-0 z-2 w-full h-full',
          {
            '!hidden': !!props.value,
            'text-12px!': mini,
            'pt-2px!': !mini,
          },
        )}
      >
        <span className='line-height-20px whitespace-pre-line'>
          {placeholder}
        </span>
        <span className='py-1 px-4 c-font_1/60 b-1 ml-5 b-line/60 bg-bg_3/4 rd-4px'>
          Tab
        </span>
      </div>
      <TextEditor
        {...restProps}
        onKeyDown={e => {
          if (e.code === 'Tab' && !(e.target as HTMLTextAreaElement).value) {
            e.preventDefault()
            if (props.onChange && props.placeholder) {
              props.onChange(props.placeholder)
            }
          }
          props.onKeyDown?.(e)
        }}
      />
    </div>
  )
})

WithQuickFillTextEditor.displayName = 'TextEditor'
