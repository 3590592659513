import type { SelectProps } from 'antd'
import { Form, Tooltip, Select as AntdSelect } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import EmptyImage from '@/assets/app/rocket2.png'
import { getDBList } from '@/apis/database/api'
import type { Database } from '@/apis/database/types'
import { IconFont, Select, usePageModal } from '@/components'

interface Value {
  id: string
  title: string
}

interface DatabaseSelectProps {
  value?: Value
  disabled?: boolean
  onChange?: (value: Value) => void
}

function InternalDatabaseSelect({
  value,
  onChange,
  disabled,
}: DatabaseSelectProps) {
  const form = Form.useFormInstance()

  const [databases, setDatabases] = useState<Database[]>()

  useEffect(() => {
    async function query() {
      const databases = await getDBList()
      setDatabases(databases.list)
    }
    query()
  }, [])

  const pageModal = usePageModal()

  const dropdownRender: SelectProps['dropdownRender'] = menu => {
    return (
      <>
        {menu}
        <div
          className={
            databases?.length
              ? 'border-t border-t-line border-t-op-60 mt-4 pt-4'
              : ''
          }
        >
          <div
            className='w-full h-32px cursor-pointer flex-center text-12px hover:bg-bg_3 hover:bg-op-8 rounded-6px'
            onClick={e => {
              e.stopPropagation()
              pageModal.show({ url: '/db' })
            }}
          >
            <IconFont name='add' className='text-16px' />
            创建数据库
          </div>
        </div>
      </>
    )
  }

  return (
    <Select
      className='nodrag nopan'
      value={value?.id}
      placeholder='请选择数据库'
      dropdownRender={dropdownRender}
      notFoundContent={
        <div className='text-center pt-50 pb-66'>
          <img
            className='w-100px h-100px object-contain mx-auto'
            src={EmptyImage}
            alt='empty'
          />
          <span className='text-12px text-font_2'>无搜索结果</span>
        </div>
      }
      getPopupContainer={triggerNode => triggerNode}
      optionFilterProp='title'
      virtual={false}
      allowClear
      showSearch
      onChange={(value, option) => {
        const title = (option as unknown as Database).title
        onChange?.({
          title,
          id: value,
        })
        form.setFieldValue(['inputs', 'baseId'], value)
      }}
    >
      {databases?.map(el => (
        <AntdSelect.Option key={el.id} title={el.title} value={el.id}>
          <div className='w-full flex items-center overflow-hidden group/database'>
            <div className='text-12px/16px flex-1 overflow-hidden'>
              <span className='text-font truncate block'>{el.title}</span>
            </div>
            <div className='w-24px h-24px invisible shrink-0 group-hover/database:visible'>
              <Tooltip title='查看数据库详情' destroyTooltipOnHide>
                <button
                  className='flex justify-center items-center w-full h-full'
                  disabled={disabled}
                  onClick={event => {
                    event.stopPropagation()
                    pageModal.show({
                      url: `/db/${el.workspace_id}/${el.id}`,
                    })
                  }}
                >
                  <IconFont
                    name='super-link'
                    className={classNames('text-bg_3 text-op-60 text-16px')}
                  />
                </button>
              </Tooltip>
            </div>
          </div>
        </AntdSelect.Option>
      ))}
    </Select>
  )
}

const MemoizedDatabaseSelect = React.memo(InternalDatabaseSelect)

export function DatabaseSelect({
  value,
  onChange,
  disabled,
}: DatabaseSelectProps) {
  const onChangeRef = useRef<DatabaseSelectProps['onChange']>()

  useEffect(() => {
    onChangeRef.current = onChange
  })

  const handleChange = useCallback<
    NonNullable<DatabaseSelectProps['onChange']>
  >(value => {
    onChangeRef.current?.(value)
  }, [])

  return (
    <MemoizedDatabaseSelect
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  )
}
