import { Form, Popover } from 'antd'
import React, { useRef, useState } from 'react'
import { IconFont } from '@/components'
import { SchemaCollapse } from './SchemaCollapse'

function InternalSchemaPopover() {
  const inputs = Form.useWatch<
    { database?: { id: string; title: string } } | undefined
  >('inputs')

  const title = inputs?.database?.title
  const identifier = inputs?.database?.id

  const [open, setOpen] = useState(false)

  const content =
    title && identifier ? (
      <SchemaCollapse
        classNames={{ refreshButton: '-mt-1', closeButton: '-mt-1 -mr-4' }}
        title={title}
        identifier={identifier}
        showClose
        onClose={() => {
          setOpen(false)
        }}
      />
    ) : null

  const popupContainerRef = useRef<HTMLSpanElement | null>(null)

  return (
    <span className='ml-auto' ref={popupContainerRef}>
      <Popover
        open={open}
        content={content}
        placement='rightTop'
        trigger='click'
        overlayClassName='nodrag nopan [&_.ant-popover-inner-content]:h-full [&_.ant-popover-inner-content]:flex [&_.ant-popover-inner-content]:flex-col'
        overlayInnerStyle={{
          width: '250px',
          height: '370px',
          borderRadius: '8px',
          boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.2)',
        }}
        getPopupContainer={() => popupContainerRef.current || document.body}
        destroyTooltipOnHide
        onOpenChange={setOpen}
      >
        <span
          className='inline-flex items-center rounded-6px text-center px-4 h-24px text-12px/16px font-medium text-#9f9f9f hover:bg-#F6F6F9 data-[open=true]:bg-#F6F6F9'
          data-open={open}
        >
          <IconFont className='mr-2' name={open ? 'guanbi' : 'schema'} />
          Schema
        </span>
      </Popover>
    </span>
  )
}

export const SchemaPopover = React.memo(InternalSchemaPopover)
