import { UITypes } from '@bty/smartsheet/src/nocodb-sdk/ui-types'
import dayjs from 'dayjs'
import type { SheetColumnType } from '@/features/database/types/tableImport.ts'

function getColVal(row: any, col?: number) {
  return row && col !== undefined ? row[col] : row
}

function validateEmail(v: string) {
  return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    v,
  )
}

// 将多少行的数据用来推断列的类型
export const maxRowsToParse = 50

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/

const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/

export function isURL(str: string) {
  const match = str.match(protocolAndDomainRE)
  if (!match) {
    return false
  }

  const everythingAfterProtocol = match[1]
  if (!everythingAfterProtocol) {
    return false
  }

  return (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  )
}

export function isMultiLineTextType(values: [], col?: number) {
  return values.some(
    r =>
      (getColVal(r, col) || '').toString().match(/[\r\n]/) ||
      (getColVal(r, col) || '').toString().length > 255,
  )
}

export function isUrlType(colData: [], col?: number) {
  return colData.some((r: any) => {
    const v = getColVal(r, col)
    // convert to string since isURL only accepts string
    // and cell data value can be number or any other types
    return v && isURL(v.toString())
  })
}

export function isEmailType(colData: [], col?: number) {
  return colData.some((r: any) => {
    const v = getColVal(r, col)
    return v && validateEmail(v)
  })
}

const booleanOptions = [
  { checked: true, unchecked: false },
  { x: true, '': false },
  { yes: true, no: false },
  { y: true, n: false },
  { 1: true, 0: false },
  { '[x]': true, '[]': false, '[ ]': false },
  { '☑': true, '': false },
  { '✅': true, '': false },
  { '✓': true, '': false },
  { '✔': true, '': false },
  { enabled: true, disabled: false },
  { on: true, off: false },
  { done: true, '': false },
  { true: true, false: false },
]

const aggBooleanOptions: any = booleanOptions.reduce(
  (obj, o) => ({ ...obj, ...o }),
  {},
)

export const isCheckboxType: any = (values: [], col?: number) => {
  let options = booleanOptions
  for (let i = 0; i < values.length; i++) {
    const val = getColVal(values[i], col)
    if (val === null || val === undefined || val.toString().trim() === '') {
      continue
    }
    options = options.filter(v => val in v)
    if (!options.length) {
      return false
    }
  }
  return true
}

export function getCheckboxValue(value: any) {
  return value && aggBooleanOptions[value]
}

export function extractMultiOrSingleSelectProps(colData: []) {
  const maxSelectOptionsAllowed = 64
  const colProps: any = {}
  if (colData.some((v: any) => v && (v || '').toString().includes(','))) {
    const flattenedVals = colData.flatMap((v: any) =>
      v
        ? v
            .toString()
            .trim()
            .split(/\s*,\s*/)
        : [],
    )

    const uniqueVals = [
      ...new Set(
        flattenedVals
          .filter(v => v !== null && v !== undefined)
          .map((v: any) => v.toString().trim()),
      ),
    ]

    if (uniqueVals.length > maxSelectOptionsAllowed) {
      // too many options are detected, convert the column to SingleLineText instead
      colProps.uidt = UITypes.SingleLineText
      // _disableSelect is used to disable the <a-select-option/> in TemplateEditor
      colProps._disableSelect = true
    } else {
      // assume the column type is multiple select if there are repeated values
      if (
        flattenedVals.length > uniqueVals.length &&
        uniqueVals.length <= Math.ceil(flattenedVals.length / 2)
      ) {
        colProps.uidt = UITypes.MultiSelect
      }
      // set dtxp here so that users can have the options even they switch the type from other types to MultiSelect
      // once it's set, dtxp needs to be reset if the final column type is not MultiSelect
      // eslint-disable-next-line prettier/prettier
      colProps.dtxp = `${uniqueVals.map(v => `'${v.replace(/'/gi, '\'\'')}'`).join(',')}`
    }
  } else {
    const uniqueVals = [
      ...new Set(
        colData
          .filter(v => v !== null && v !== undefined)
          .map((v: any) => v.toString().trim()),
      ),
    ]

    if (uniqueVals.length > maxSelectOptionsAllowed) {
      // too many options are detected, convert the column to SingleLineText instead
      colProps.uidt = UITypes.SingleLineText
      // _disableSelect is used to disable the <a-select-option/> in TemplateEditor
      colProps._disableSelect = true
    } else {
      // assume the column type is single select if there are repeated values
      // once it's set, dtxp needs to be reset if the final column type is not Single Select
      if (
        colData.length > uniqueVals.length &&
        uniqueVals.length <= Math.ceil(colData.length / 2)
      ) {
        colProps.uidt = UITypes.SingleSelect
      }
      // set dtxp here so that users can have the options even they switch the type from other types to SingleSelect
      // once it's set, dtxp needs to be reset if the final column type is not SingleSelect
      // eslint-disable-next-line prettier/prettier
      colProps.dtxp = `${uniqueVals.map(v => `'${v.replace(/'/gi, '\'\'')}'`).join(',')}`
    }
    return colProps
  }
}

export const dateFormats = [
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'DD-MM-YYYY',
  'MM-DD-YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'DD MM YYYY',
  'MM DD YYYY',
  'YYYY MM DD',
]

export function getDateFormat(v: string) {
  for (const format of dateFormats) {
    if (dayjs(v, format, true).isValid()) {
      return format
    }
  }
  return 'YYYY/MM/DD'
}

export function extractFileName(name: string) {
  const fileNameWithoutExtension = name.split('.').slice(0, -1).join('.')
  return fileNameWithoutExtension || name
}

export const timeFormats = ['HH:mm', 'HH:mm:ss', 'HH:mm:ss.SSS']

export function validateDateWithUnknownFormat(v: string) {
  for (const format of dateFormats) {
    if (dayjs(v, format, true).isValid() as any) {
      return true
    }
    for (const timeFormat of timeFormats) {
      if (dayjs(v, `${format} ${timeFormat}`, true).isValid() as any) {
        return true
      }
    }
  }
  return false
}

export function isDecimalType(colData: []) {
  return colData.some((v: any) => {
    return v && Number.parseInt(v) !== +v
  })
}

export function getSafeValueByUidtType(value: any, uidt: UITypes) {
  if (uidt === UITypes.Checkbox) {
    return getCheckboxValue(value)
  } else if (uidt === UITypes.SingleSelect || uidt === UITypes.MultiSelect) {
    return (value || '').toString().trim() || null
  } else if (uidt === UITypes.SingleLineText || uidt === UITypes.LongText) {
    return value === null || value === undefined ? null : `${value}`
  } else if (uidt === UITypes.Number) {
    const parsedValue = Math.round(value)
    return Number.isNaN(parsedValue) ? null : parsedValue
  } else if (uidt === UITypes.Decimal) {
    const parsedValue = Number(value)
    if (Number.isNaN(parsedValue)) return null
    return Number.isInteger(parsedValue) ? parsedValue.toFixed(2) : parsedValue
  } else {
    return value
  }
}

export function findNameOrUidtChangedColumns(columns: SheetColumnType[]) {
  return columns.filter(column => {
    return (
      column.uidt !== column.ref_uidt ||
      column.column_name !== column.ref_column_name
    )
  })
}

/**
 * @description 当用户解析完表格之后，可能会修改列名和列类型，这个fix函数需要为用户的修改做兜底
 * 防止列和数据对不上
 */
export function fixTableDataByColumn(
  columns: SheetColumnType[],
  data: any[],
): any[] {
  const changedColumns = findNameOrUidtChangedColumns(columns)
  if (changedColumns.length) {
    return data.map(dataItem => {
      changedColumns.forEach(column => {
        // 修改列名
        if (column.ref_column_name !== column.column_name) {
          dataItem[column.column_name] = dataItem[column.ref_column_name]
          delete dataItem[column.ref_column_name]
        }
        // 兼容列类型
        if (column.ref_uidt !== column.uidt) {
          dataItem[column.column_name] = getSafeValueByUidtType(
            dataItem[column.column_name],
            column.uidt as UITypes,
          )
        }
      })
      return dataItem
    })
  }
  return data
}

export function fixName(variableName: string, prefixName = 'table_') {
  // 将所有字符转为小写
  variableName = variableName.toLowerCase()
  // 检查并修复如果变量名以数字或下划线开头的情况
  if (/^[0-9_]/.test(variableName)) {
    variableName = `${prefixName}${variableName}`
  }
  // 替换所有不符合要求的字符为 "__"
  variableName = variableName.replace(/[^a-z0-9\u4E00-\u9FA5_]/g, '_')

  return variableName.trim()
}
