interface SquareDotsProps {
  total?: number
  activeCount: number
  width?: number
  height?: number
}
export enum SquareColor {
  GREEN = '#2CB969',
  RED = '#FF5219',
  YELLOW = '#FFB535',
  DEFAULT = '#E1E1E5',
}
function getColorBasedOnActiveCount(activeCount: number): string {
  switch (true) {
    case activeCount < 2:
      return SquareColor.RED
    case activeCount >= 2 && activeCount <= 3:
      return SquareColor.YELLOW
    case activeCount > 3:
      return SquareColor.GREEN
    default:
      return SquareColor.DEFAULT
  }
}

export function SquareDots({
  total = 6,
  activeCount,
  width = 2,
  height = 8,
}: SquareDotsProps) {
  const activeColor = getColorBasedOnActiveCount(activeCount)
  const dots = Array.from({ length: total }, (_, index) =>
    index < activeCount ? activeColor : SquareColor.DEFAULT,
  )

  return (
    <div className='flex-center gap-x-2px'>
      {dots.map((color, index) => (
        <div
          key={index}
          className=''
          style={{
            backgroundColor: color,
            width: `${width}px`,
            height: `${height}px`,
          }}
        ></div>
      ))}
    </div>
  )
}
