import { LoadingOutlined } from '@ant-design/icons'
import { memo, useCallback, useEffect, useRef } from 'react'
import { Dropdown, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import classNames from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import { AppLogo, Button, IconFont, Modal } from '@/components'
import { useModal } from '@/hooks/useModal'
import type { ApplicationBodyType } from '@/apis/application'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import type { FlowDataItemType } from '../draft'
import { useBatchTestProgress } from '../hooks/useBatchTestProgress'
import { FLOW_STATUS } from '@/apis/flow'
import { NodeType } from '@/features/nodes/base'
import { SkillConfModal } from '@/features/agent/SkillTabs/SkillConfModal'
import { saveFlowFormConfig, SkillFlowViewMode } from '@/apis/run'
import { useFlowDraftInit } from '../hooks/useFlowDraftInit'
import { useVersion } from '@/hooks/useVersion'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { useSocket } from '@/hooks/useSocket'

interface FlowHeaderProps {
  isSaving?: boolean
  isPublishing: boolean
  allowPublish?: boolean
  applicationInfo?: ApplicationBodyType
  SelectedItemData: FlowDataItemType
  testUpdateId?: string
  onBack: () => void
  onEditOpen: () => void
  onRunTest: () => void
  onPublish: () => void
  onOpenVersion: () => void
  onToolBarClose: () => void
  onBackCurrent: () => void
  onRunFlowVersionList: () => void
  handleRecoverVersion: ({
    flowId,
    versionId,
  }: {
    flowId: string
    versionId: string
  }) => void
  onToggleTestTableOpen: () => void
  onLogsOpen: () => void
}
export function regainModal({
  open,
  onCancel,
  onOk,
}: {
  open: boolean
  onCancel: () => void
  onOk: () => void
}) {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={null}
      okText='恢复'
      closable={false}
      footer={
        <div className='px-20px py-20px'>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type='primary'
            className='ml-12px'
            onClick={() => {
              onOk()
              onCancel()
            }}
          >
            恢复
          </Button>
        </div>
      }
    >
      <span className='text-14px/16px font-500'>
        请确认恢复此版本作为线上版本？
      </span>
    </Modal>
  )
}

export const FlowHeader = memo((props: FlowHeaderProps) => {
  const {
    isSaving,
    isModified: allowPublish,
    lockStatus,
    startNodeType,
  } = useFlowDraftStore(s => ({
    isSaving: s.isSaving,
    isModified: s.isModified,
    lockStatus: s.lockStatus,
    startNodeType: s.nodes?.find(e => e.type === NodeType.START)?.data?.type,
  }))

  const { fetchDraftFlow } = useFlowDraftInit({ manual: true })

  const { isTeam } = useVersion()

  const {
    isPublishing,
    applicationInfo,
    SelectedItemData,
    testUpdateId,
    onBack,
    onEditOpen,
    onRunTest,
    onPublish,
    onOpenVersion,
    onToolBarClose,
    onBackCurrent,
    handleRecoverVersion,
    onRunFlowVersionList,
    onToggleTestTableOpen,
    onLogsOpen,
  } = props
  const [modal] = useModal(regainModal)
  const showHistoryVerison = async () => {
    if (lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK) {
      onOpenVersion()
      return
    }
    onOpenVersion()
    await onRunFlowVersionList()
    onToolBarClose()
  }

  const onFlowConfigOpen = useCallback(() => {
    // @ts-expect-error 未知原因
    NiceModal.show(SkillConfModal, {
      type: 'Flow',
      identifier: applicationInfo?.flowId,
      conf: applicationInfo,
      tips: '调用配置会在工作流发布后生效',
      hideSuperLink: true,
      hideOutputConfig: true,
      isDraft: true,
      customSave: async values => {
        await saveFlowFormConfig({
          flow_id: applicationInfo?.flowId || '',
          skill_desc: values.description,
          inputs: values.inputs,
          inputs_properties: {
            inputsViewMode: SkillFlowViewMode.RAW,
            formWelcome: '',
            ...values.inputs_properties,
          },
        })
        fetchDraftFlow()
      },
      getExtraParams: () => ({
        // 我也不明白为什么这里用 flow_id 下面用 agent_id，但是这样就对了
        flow_id: applicationInfo?.flowId,
        // version_id: applicationInfo?.versionId,
      }),
      onOk: () => {},
    })
  }, [applicationInfo])

  const containerRef = useRef<HTMLHeadElement | null>(null)
  const items = [
    {
      key: '1',
      label: (
        <div
          className='border-0px w-130px text-14px/14px py-10px pl-12px'
          onClick={onEditOpen}
        >
          编辑信息
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className='border-0px w-130px text-14px/14px py-10px pl-12px'
          onClick={onFlowConfigOpen}
        >
          调用配置
        </div>
      ),
    },
  ]

  const setFlowLock = useFlowDraftStore(s => s.setFlowLock)

  const { subscribe, open } = useSocket()

  const {
    refresh: refreshBatchTestProgress,
    runningDoneSteps,
    totalSteps,
    testCount,
    updateTestCount,
  } = useBatchTestProgress({
    flowId: applicationInfo?.flowId || '',
    onSuccess: data => {
      if (data?.batch_test_run_status === 'FINISH' || !data) {
        setFlowLock(FLOW_DRAFT_LOCK_STATUS.UNLOCK)
        message.success('批量调试完成')
      }
    },
  })

  useEffect(() => {
    return subscribe(refreshBatchTestProgress)
  }, [])

  useEffect(() => {
    if (applicationInfo?.flow_lock === FLOW_STATUS.BATCH_TEST_LOCK) {
      open()
      refreshBatchTestProgress()
    }
  }, [applicationInfo?.flow_lock])

  useEffect(() => {
    if (!testUpdateId) return
    updateTestCount()
  }, [testUpdateId])

  useEffect(() => {
    if (!startNodeType) return
    updateTestCount()
  }, [startNodeType])

  return (
    <header
      ref={containerRef}
      className='flex flex-items-center h-60px p-l-16 p-r-16 bg-#fff b-b-1 b-#e1e1e5 b-op-60'
    >
      <IconFont
        onClick={onBack}
        name='fanhui'
        style={{
          boxSizing: 'content-box',
          borderRadius: 4,
        }}
        className='text-16px mr-12px ml-4px cursor-pointer p-8px hover:bg-#62696D hover:bg-op-8'
      />
      <div className='flex flex-1 flex-items-center h-100%'>
        {applicationInfo && (
          <>
            <AppLogo
              type='emoji'
              value={applicationInfo.icon}
              size={36}
              fillSize={18}
              className='m-r-8 rounded-6px'
              color={applicationInfo.color}
              imgStyle={{ borderRadius: '6px' }}
            />
            <Dropdown
              menu={{
                items,
              }}
              placement='bottom'
              trigger={['click']}
            >
              <div className='flex flex-items-center cursor-pointer h-100%'>
                <span className='text-16px font-600 c-#17171D'>
                  {applicationInfo.name}
                </span>
                <div className='flex flex-items-center py-8 pl-4px'>
                  <IconFont
                    name='yongqi211'
                    className='text-16px  m-r-4 c-#17171D'
                  />
                </div>
              </div>
            </Dropdown>
          </>
        )}
        {lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION && (
          <>
            <span className='ml-12px text-12px/12px'>
              {' '}
              {dayjs(SelectedItemData?.modifiedAt)?.format(
                'YYYY年M月D日 HH:mm',
              )}
            </span>
            <span className='c-#8D8D99 text-12px/12px mr-12px'>
              &nbsp;·&nbsp;仅可查看
            </span>
            <Button
              icon={<IconFont name='fanhui' className='mr--2px text-16px' />}
              type='primary'
              onClick={onBackCurrent}
            >
              返回编辑
            </Button>
          </>
        )}
      </div>
      {lockStatus === FLOW_DRAFT_LOCK_STATUS.UNLOCK && (
        <div className='flex flex-items-center m-r-8'>
          {isSaving ? (
            <LoadingOutlined className='text-16px m-r-6' />
          ) : (
            <IconFont name='chenggongbaocun' className='text-16px m-r-6' />
          )}
          <span className='text-12px font-500 c-#a9aab8'>
            {isSaving ? '正在保存' : '已保存'}
          </span>
        </div>
      )}
      {lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION && (
        <Button
          type='primary'
          icon={<IconFont name='huifulishi' className='mr--2px text-16px' />}
          className='mr-12px'
          onClick={() =>
            modal.open({
              onOk: async () => {
                await handleRecoverVersion({
                  flowId: SelectedItemData.flowId,
                  versionId: SelectedItemData.versionId,
                })
                onRunFlowVersionList()
                message.success('已恢复至此版本')
              },
              onCancel: () => {},
            })
          }
        >
          恢复
        </Button>
      )}
      {lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_BATCH_TEST_RUN ? (
        <Button
          // loading
          className='c-primary flex-center text-14px m-r-12'
          icon={<LoadingOutlined />}
          onClick={onToggleTestTableOpen}
        >
          批量调试中：{runningDoneSteps}/{totalSteps}
        </Button>
      ) : (
        <>
          {isTeam ? (
            <LimitedAccess
              limitedType='shouldCheckTeamVersion'
              limitFeatureName='批量调试'
            >
              <Button
                className='c-#626999 c-op-60 flex-center text-14px m-r-12 '
                icon={<IconFont name='suoding' className='text-16px' />}
                disabled={lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION}
              >
                批量调试
              </Button>
            </LimitedAccess>
          ) : (
            <Button
              className='c-#17171D flex-center text-14px m-r-12 '
              icon={<IconFont name='piliangceshi' className='text-16px' />}
              disabled={lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION}
              onClick={onToggleTestTableOpen}
            >
              <span>
                批量调试
                {!!testCount && (
                  <span className='text-#8D8D99 ml-4px'>· {testCount}</span>
                )}
              </span>
            </Button>
          )}
        </>
      )}

      <Button
        className='c-#17171D flex-center text-#7b61ff w-88px b-rd-r-0! hover:text-#7b61ff!'
        icon={
          <IconFont
            name='yunxing'
            className={classNames('text-16px text-#7b61ff', {
              'opacity-40': lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
            })}
          />
        }
        disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
        onClick={onRunTest}
      >
        <span
          className={classNames('text-primary', {
            'opacity-40': lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
          })}
        >
          运行
        </span>
      </Button>
      <Tooltip title='查看运行日志'>
        <Button
          className='b-rd-l-0! b-l-none'
          icon={<IconFont name='yunxingrizhi' className='text-16px' />}
          disabled={lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION}
          onClick={onLogsOpen}
        ></Button>
      </Tooltip>
      <div className='h-20px w-1px bg-#E1E1E5 mx-16px'></div>
      <Button
        loading={isPublishing}
        disabled={!allowPublish || lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
        type='primary'
        className='min-w-56px bg-op-60 flex items-center'
        icon={<IconFont name='fabu' className='text-16px' />}
        onClick={onPublish}
      >
        发布
      </Button>
      <Tooltip title='历史版本'>
        <Button
          className='ml-12px w-36px!'
          icon={<IconFont name='lishibanben' className='text-16px' />}
          disabled={lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_BATCH_TEST_RUN}
          onClick={showHistoryVerison}
        ></Button>
      </Tooltip>
    </header>
  )
})
