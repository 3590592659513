import { isEqual } from 'lodash-es'
import type { Node, Edge } from 'reactflow'
import { useFlowDraftStore } from '@/store'

export function useSelectNode(): {
  sourceNode?: Node
  targetNode?: Node
  nextEdges?: Edge
} {
  return useFlowDraftStore(s => {
    const { nodes, edges } = s
    const currentSelectNode = nodes.filter(n => n.selected)?.[0]
    const sourceNode = nodes.find(node => node.id === currentSelectNode?.id)
    const nextEdges = edges.find(node => node.source === sourceNode?.id)
    const targetNode = nodes.find(node => node.id === nextEdges?.target)
    return { sourceNode, targetNode, nextEdges }
  }, isEqual)
}
