import { parse } from 'papaparse'
import { UITypes } from '@bty/smartsheet/src/nocodb-sdk/ui-types'
import { isNaN } from 'lodash-es'
import {
  extractFileName,
  extractMultiOrSingleSelectProps,
  fixName,
  getDateFormat,
  getSafeValueByUidtType,
  isCheckboxType,
  isEmailType,
  isMultiLineTextType,
  isUrlType,
  maxRowsToParse,
  validateDateWithUnknownFormat,
} from '@/features/database/utils/parseHelpers.ts'
import type {
  Sheet,
  SheetColumnType,
} from '@/features/database/types/tableImport.ts'

export function extractCSVData(file: File): Promise<Sheet[]> {
  const table_name = fixName(extractFileName(file.name), 'table_')

  return new Promise((resolve, reject) => {
    let steppers = 0
    const result: Sheet[] = []
    const data: any[] = []
    const columns: SheetColumnType[] = []
    const headers: string[] = []
    const columnValues: Record<number, []> = {}
    const detectedColumnTypes: Record<number, Record<string, number>> = {}
    const distinctValues: Record<number, Set<string>> = {}

    function getPossibleUidt(columnIdx: number) {
      const detectedColTypes = detectedColumnTypes[columnIdx]
      const len = Object.keys(detectedColTypes).length
      // all records are null
      if (len === 0) {
        return UITypes.SingleLineText
      }
      // handle numeric case
      if (
        len === 2 &&
        UITypes.Number in detectedColTypes &&
        UITypes.Decimal in detectedColTypes
      ) {
        return UITypes.Decimal
      }
      // if there are multiple detected column types
      // then return either LongText or SingleLineText
      if (len > 1) {
        if (UITypes.LongText in detectedColTypes) {
          return UITypes.LongText
        }
        return UITypes.SingleLineText
      }
      // otherwise, all records have the same column type
      return Object.keys(detectedColTypes)[0]
    }

    parse(file, {
      skipEmptyLines: 'greedy',
      step: (row: any) => {
        steppers += 1
        if (steppers === 1) {
          // 解析表头
          // 初始化表头
          const columnNames = row.data as string[]
          const columnNameRowExist = +columnNames.every(
            (v: any) => v === null || typeof v === 'string',
          )
          const columnNamePrefixRef: Record<string, any> = { id: 0 }

          for (const [columnIdx, columnName] of columnNames.entries()) {
            const defaultName: string = columnNameRowExist
              ? columnName.toString()
              : ''
            const title = (
              fixName(defaultName, 'column_') || `field_${columnIdx + 1}`
            ).trim()
            let cn: string = title
            while (cn in columnNamePrefixRef) {
              cn = `${cn}${++columnNamePrefixRef[cn]}`
            }
            columnNamePrefixRef[cn] = 0

            detectedColumnTypes[columnIdx] = {}
            distinctValues[columnIdx] = new Set<string>()
            columnValues[columnIdx] = []
            columns.push({
              title,
              column_name: cn,
              ref_column_name: cn,
              meta: {},
              uidt: UITypes.SingleLineText,
              ref_uidt: UITypes.SingleLineText,
            })

            headers.push(cn)
          }
        } else {
          // 识别列类型
          const rowData = row.data as []
          for (let columnIdx = 0; columnIdx < rowData.length; columnIdx++) {
            // skip null data
            if (!rowData[columnIdx]) continue
            const colData: any = [rowData[columnIdx]]
            const colProps = { uidt: detectInitialUidt(rowData[columnIdx]) }
            // TODO(import): centralise
            if (isMultiLineTextType(colData)) {
              colProps.uidt = UITypes.LongText
            } else if (colProps.uidt === UITypes.SingleLineText) {
              if (isEmailType(colData)) {
                // colProps.uidt = UITypes.Email
                colProps.uidt = UITypes.SingleLineText
              } else if (isUrlType(colData)) {
                colProps.uidt = UITypes.URL
              } else if (isCheckboxType(colData)) {
                // colProps.uidt = UITypes.Checkbox
                colProps.uidt = UITypes.SingleLineText
              } else {
                if (rowData[columnIdx] && columnIdx < maxRowsToParse) {
                  columnValues[columnIdx].push(rowData[columnIdx])
                  // colProps.uidt = UITypes.SingleSelect
                  colProps.uidt = UITypes.SingleLineText
                }
              }
            } else if (colProps.uidt === UITypes.Number) {
              colProps.uidt = UITypes.Decimal
              // if (isDecimalType(colData)) {
              //   colProps.uidt = UITypes.Decimal
              // }
            } else if (colProps.uidt === UITypes.DateTime) {
              if (rowData[columnIdx] && columnIdx < maxRowsToParse) {
                columnValues[columnIdx].push(rowData[columnIdx])
              }
            }

            if (!(colProps.uidt in detectedColumnTypes[columnIdx])) {
              detectedColumnTypes[columnIdx] = {
                ...detectedColumnTypes[columnIdx],
                [colProps.uidt]: 0,
              }
            }
            detectedColumnTypes[columnIdx][colProps.uidt] += 1

            if (rowData[columnIdx]) {
              distinctValues[columnIdx].add(rowData[columnIdx])
            }
          }
        }
        // 解析表数据
        // 2是要跳过表头
        if (row && steppers >= 2) {
          const rowData: Record<string, any> = {}
          for (let columnIdx = 0; columnIdx < headers.length; columnIdx++) {
            const column = columns[columnIdx]
            const data: any =
              (row.data as [])[columnIdx] === ''
                ? null
                : (row.data as [])[columnIdx]

            const value = typeof data === 'string' ? data.trim() : data

            rowData[column.column_name] = getSafeValueByUidtType(
              value,
              column.uidt as UITypes,
            )
          }
          data.push(rowData)
        }
      },
      complete: () => {
        for (let columnIdx = 0; columnIdx < headers.length; columnIdx++) {
          const uidt = getPossibleUidt(columnIdx)
          if (columnValues[columnIdx].length > 0) {
            if (uidt === UITypes.DateTime) {
              const dateFormat: Record<string, number> = {}
              if (
                columnValues[columnIdx]
                  .slice(1, maxRowsToParse)
                  .every((v: any) => {
                    const isDate = v.split(' ').length === 1
                    if (isDate) {
                      dateFormat[getDateFormat(v)] =
                        (dateFormat[getDateFormat(v)] || 0) + 1
                    }
                    return isDate
                  })
              ) {
                // columns[columnIdx].uidt = UITypes.Date
                columns[columnIdx].uidt = UITypes.DateTime
                // take the date format with the max occurrence
                const objKeys = Object.keys(dateFormat)
                columns[columnIdx].meta.date_format = objKeys.length
                  ? objKeys.reduce((x, y) =>
                      dateFormat[x] > dateFormat[y] ? x : y,
                    )
                  : 'YYYY-MM-DD HH:mm:ss'
              } else {
                // Datetime
                columns[columnIdx].uidt = uidt
              }
            } else if (
              uidt === UITypes.SingleSelect ||
              uidt === UITypes.MultiSelect
            ) {
              // assume it is a SingleLineText first
              columns[columnIdx].uidt = UITypes.SingleLineText
              // override with UITypes.SingleSelect or UITypes.MultiSelect if applicable
              Object.assign(
                columns[columnIdx],
                extractMultiOrSingleSelectProps(columnValues[columnIdx]),
              )
            } else {
              columns[columnIdx].uidt = uidt as any
            }
            delete columnValues[columnIdx]
          } else {
            columns[columnIdx].uidt = uidt as any
          }
          columns[columnIdx].ref_uidt = columns[columnIdx].uidt as any
        }
        result.push({
          title: table_name,
          data,
          columns,
          description: '',
          sheetName: table_name,
        })
        resolve(result)
      },
      error: reject,
    })
  })
}

function detectInitialUidt(v: string) {
  if (!isNaN(Number(v)) && !isNaN(Number.parseFloat(v))) {
    if (v.toString().length <= 10) {
      return UITypes.Number
    }
    return UITypes.SingleLineText
  }
  if (validateDateWithUnknownFormat(v)) return UITypes.DateTime
  if (isCheckboxType(v)) {
    // return UITypes.Checkbox
    return UITypes.SingleLineText
  }
  return UITypes.SingleLineText
}
