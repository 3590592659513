import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'

export interface EndNodeData {}

export const EndNode: NodeComponent = () => {
  return (
    <div className='px-144 py-22'>
      <span className='text-12px font-400 text-font_1'>流程运行结果</span>
    </div>
  )
}

EndNode.meta = {
  type: NodeType.END,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'END',
  typeName: '输出',
  icon: 'output',
  canDelete: false,
  description: '结束节点',
  backgroundColor: '#525880',
  isEnd: true,
  initialData: {
    name: 'output',
  },
}
