import type { FC } from 'react'
import { useRef, useMemo, useState } from 'react'
import { Select as AntdSelect, Tooltip } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import type { InputRef } from 'antd'
import { Select, IconFont, usePageModal, AppLogo } from '@/components'
import { useWorkspaceStore } from '@/store'
import emptyImage from '@/assets/dataset/empty@2x.png'

export interface DatasetSelectProps {
  value?: number
  onChange?: (config: any) => void
  className?: string
  datasetList: any[]
}

const { Option } = AntdSelect

const StyledSelect = styled(Select)`
  .ant-select-selector {
    padding: 8px !important;
    .ant-select-selection-item {
      padding-inline-end: 12px !important;
      .link-action {
        opacity: 1;
      }
    }
  }
`

const SelectedWrapper = styled.div`
  padding: 0px !important;
  &:hover {
    .select-flow_icon {
      opacity: 1;
    }
  }
`

const OptionContent = styled.div`
  &:hover {
    .link-action {
      opacity: 1;
    }
  }
`

export function Empty() {
  return (
    <div className='text-12px line-height-20px text-center flex flex-col items-center my-48px'>
      <img className='w-80px h-80px' src={emptyImage} alt='' />
      <span className='text-font_2 mt-8px font-400'>暂无其他知识库</span>
    </div>
  )
}

export const DatasetSelect: FC<DatasetSelectProps> = props => {
  const { value, onChange, datasetList = [] } = props
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const [searchValue, setSearchValue] = useState('')
  const pageModal = usePageModal()

  const list = useMemo(() => {
    if (!searchValue.trim()) {
      return datasetList
    }
    return datasetList.filter(item => {
      return item.partition_name.includes(searchValue)
    })
  }, [datasetList, searchValue])

  return (
    <div
      ref={ref}
      className={classNames('nodrag nopan nowheel', props.className)}
    >
      <StyledSelect
        size='small'
        virtual={false}
        className='nodrag nopan'
        value={value}
        onChange={async id => {
          onChange?.(id)
        }}
        onDropdownVisibleChange={visible => {
          setSearchValue('')
          if (visible) {
            setTimeout(() => {
              inputRef?.current?.focus?.()
            }, 100)
          }
        }}
        placeholder='请选择目标知识库'
        notFoundContent={<Empty />}
        optionLabelProp={'label'}
        getPopupContainer={() => ref.current!}
        dropdownRender={menu => {
          return menu
        }}
      >
        {list.map(item => {
          return (
            <Option
              key={item.partition_id}
              label={
                <SelectedWrapper className='flex items-center nodrag nopan'>
                  <AppLogo
                    type='icon'
                    size={24}
                    fillSize={12}
                    color={item.partition_icon_color!}
                    value={item.partition_icon ?? 'app'}
                  />
                  <span className='ml-8px flex-1'>{item.partition_name}</span>
                  <span
                    className='mr-8px self-end w-24px h-24px rounded-4px hover:bg-bg_3 hover:bg-op-12 cursor-pointer flex-center'
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      pageModal.show({
                        url: `/datastores/${currentWorkspaceId}/${item.partition_id}/documents`,
                      })
                    }}
                  >
                    <Tooltip placement='top' title='查看知识库详情'>
                      <IconFont
                        name='super-link'
                        className='c-#626999 opacity-60 text-16px'
                      />
                    </Tooltip>
                  </span>
                </SelectedWrapper>
              }
              value={item.partition_id}
              className='!p-8px !mt-4px'
            >
              <OptionContent className='flex-center-between h-full flex-1'>
                <div className='flex items-center flex-1'>
                  <div className='flex-1 flex items-center overflow-hidden'>
                    <AppLogo
                      type='icon'
                      size={24}
                      fillSize={12}
                      color={item.partition_icon_color!}
                      value={item.partition_icon ?? 'app'}
                    />
                    <div className='ml-8 flex-1 overflow-hidden flex items-center'>
                      <div className='font-medium flex items-center flex-1'>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              'leading-16px text-font flex',
                              {},
                            )}
                          >
                            {item.partition_name}
                          </span>
                          <span
                            className='link-action w-24px h-24px rounded-4px hover:bg-bg_3 hover:bg-op-12 cursor-pointer flex-center opacity-0'
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              pageModal.show({
                                url: `/datastores/${currentWorkspaceId}/${item.partition_id}/documents`,
                              })
                            }}
                          >
                            <Tooltip placement='top' title='查看知识库详情'>
                              <IconFont
                                name='super-link'
                                className='c-#626999 opacity-60 text-16px'
                              />
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                      <span className='c-#8D8D99 text-12px/12px'>
                        {item.file_num} 文档
                      </span>
                    </div>
                  </div>
                </div>
              </OptionContent>
            </Option>
          )
        })}
      </StyledSelect>
    </div>
  )
}
