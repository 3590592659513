import { Form, Popover } from 'antd'
import { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { sample } from 'lodash-es'
import type { Rule } from 'antd/es/form'
import { v4 as uuidv4 } from 'uuid'
import type { ButtonProps } from 'antd/es/button/button'
import { useBoolean } from 'ahooks'
import classNames from 'classnames'
import {
  Modal,
  IconFont,
  FormItem,
  Input,
  TextArea,
  AppLogo,
  Button,
} from '@/components'
import { colors } from '@/constants/theme.ts'
import { fontIcons } from '@/constants/icon.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

import type { IEmbeddingModalItem } from '@/apis/datastore/api'
import { ColorSelect } from '@/components/createAppModal/ColorSelect'
import { IconSelect } from '@/components/createAppModal/IconSelect'
import { EmbeddingModelPicker } from '../components/EmbeddingModelPicker'
import { PartitionCategoryType } from '@/apis/datastore/types'

type FieldKeys = 'name' | 'description' | 'icon'

type FieldConfig = {
  [key in FieldKeys]?: {
    placeholder?: string
    className?: string
    rules?: Rule[]
  }
}

interface DataStoreBaseInfo {
  partition_name: string
  partition_describe: string
  partition_icon: string
  partition_icon_color: string
  partition_model: string
  partition_category: PartitionCategoryType
  partition_group_id?: string
}

interface CreateDataStoreDatasetModalProps {
  open?: boolean
  uploadFileHashPath?: string
  onCancel?: () => void
  okButtonProps?: ButtonProps
  title: string
  fieldConfig?: FieldConfig
  defaultValue?: Partial<DataStoreBaseInfo>
  onFinish?: (data: {
    partition: Required<DataStoreBaseInfo>
    files: any[]
  }) => void

  embeddingModelList?: IEmbeddingModalItem[]
}

const StyledFormItem = styled(FormItem)`
  .keyu-form-item-label {
    font-weight: 500;
  }
`

export function getPartitionAvatarInfo(type?: PartitionCategoryType) {
  return {
    partition_icon:
      type === PartitionCategoryType.QA ? fontIcons[16] : fontIcons[1]!,
    partition_icon_color: sample(colors)!,
  }
}
export function CreateDataStoreDatasetModal(
  props: CreateDataStoreDatasetModalProps,
) {
  const {
    open,
    title,
    fieldConfig,
    onCancel,
    uploadFileHashPath,
    embeddingModelList,
  } = props
  const [logoPopoverOpen, setLogoPopoverOpen] = useState(false)
  const [expanded, { toggle: toggleExpanded }] = useBoolean(false)

  const hashPath = useMemo(
    () => uploadFileHashPath ?? uuidv4(),
    [open, uploadFileHashPath],
  )

  const [form] = Form.useForm()

  const defaultValue = useMemo(() => {
    return Object.assign(
      {},
      {
        ...getPartitionAvatarInfo(props?.defaultValue?.partition_category),
        partition_model: embeddingModelList?.[0].model_name,
        oss_path: hashPath,
      },
      props.defaultValue,
    )
  }, [embeddingModelList, hashPath])

  const onFinish = () => {
    form.validateFields().then(res => {
      const data = Object.assign({}, defaultValue, res)
      props.onFinish?.({ partition: data, files: [] })
    })
  }

  const { scrollRef } = useScrollBar()

  const logoEditContent = (
    <div
      ref={scrollRef}
      className='overflow-y-auto max-h-221px'
      style={{ padding: 12 }}
    >
      <FormItem noStyle>
        <div className='mb-10'>
          <p className='font-500 lh-20px mb-5'>背景</p>
          <FormItem name='partition_icon_color' noStyle>
            <ColorSelect colors={colors} />
          </FormItem>
        </div>
        <div>
          <p className='font-500 lh-20px mb-5'>图标</p>
          <FormItem name='partition_icon' noStyle>
            <IconSelect iconType={'icon'} icons={fontIcons} />
          </FormItem>
        </div>
      </FormItem>
    </div>
  )

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      width={600}
      footer={null}
      closeIcon={<IconFont name='guanbi' className='color-#17171D text-16px' />}
    >
      <Form
        form={form}
        initialValues={defaultValue}
        layout='vertical'
        requiredMark={false}
        className='mt-2 px-16'
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => {
            return (
              prev.partition_icon !== curr.partition_icon ||
              prev.partition_icon_color !== curr.partition_icon_color
            )
          }}
        >
          {({ getFieldValue }) => {
            const icon = getFieldValue('partition_icon')
            const color = getFieldValue('partition_icon_color')
            return (
              <div className='flex w-full mb-24 justify-center'>
                <Popover
                  trigger='click'
                  placement='bottom'
                  onOpenChange={setLogoPopoverOpen}
                  open={logoPopoverOpen}
                  arrow={false}
                  overlayInnerStyle={{
                    padding: 0,
                    width: 421,
                  }}
                  content={logoEditContent}
                >
                  <div
                    className='w-80px h-80px overflow-hidden cursor-pointer relative'
                    style={{ borderRadius: 8 }}
                  >
                    <div className='absolute z-1 w-full h-full top-0 bg-black bg-op-40 left-0 absolute flex-center op-0 hover:!op-100'>
                      <IconFont name='bianji' className='text-16px c-#fff' />
                    </div>
                    <AppLogo
                      value={icon}
                      type='icon'
                      color={color}
                      size={80}
                      fillSize={36}
                    />
                  </div>
                </Popover>
              </div>
            )
          }}
        </Form.Item>
        <StyledFormItem
          label='名称'
          name='partition_name'
          required
          rules={[
            { required: true, message: '请输入知识库名称' },
            ...(fieldConfig?.name?.rules ?? []),
          ]}
        >
          <Input placeholder='请输入知识库名称' showCount maxLength={50} />
        </StyledFormItem>
        <StyledFormItem label='描述' name='partition_describe'>
          <TextArea
            rows={3}
            placeholder='请输入知识库描述，这段文字将作为知识库的使用引导'
            showCount
            maxLength={200}
          />
        </StyledFormItem>
        <div>
          <p
            className='flex items-center text-font_2 text-12px/14px mb-12px cursor-pointer'
            onClick={() => {
              toggleExpanded()
            }}
          >
            <IconFont
              name='arrow-1'
              className='text-16px mr-8 c-[rgba(141,141,153)]'
              style={{ transform: `rotate(${expanded ? 0 : 270}deg)` }}
            />
            <p>
              <span>高级设置</span>
              <span className='c-[rgba(141,141,153,0.6)]'>
                （适用于高阶用户）
              </span>
            </p>
          </p>

          <div
            className={classNames('overflow-hidden', { 'h-0px': !expanded })}
          >
            <StyledFormItem
              label='知识学习方式 (Embedding)'
              required
              name='partition_model'
            >
              <EmbeddingModelPicker list={embeddingModelList} />
            </StyledFormItem>
          </div>
        </div>
        <div className='flex items-center justify-end gap-12 mb-8'>
          <Button onClick={onCancel}>取消</Button>
          <Button type='primary' onClick={onFinish}>
            完成
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
