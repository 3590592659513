import { Popover, Tag } from 'antd'
import { rgba } from 'polished'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { DocumentStatus } from '@/apis/datastore/model'
import { DocumentStatusMap } from '../constant'
import { IconFont } from '@/components'
import { ColorfulBg, ColorfulText } from './ColorfulStyle'

interface Props {
  status: DocumentStatus
  popContent: ReactNode
  className?: string
  popTitle?: ReactNode
}

export function StatusTag(props: Props) {
  const { status, className, popContent, popTitle } = props

  const text = useMemo(() => {
    return DocumentStatusMap[status].text
  }, [status])

  const renderTag = () => {
    if (status === DocumentStatus.AIProcess) {
      return (
        <ColorfulBg className='flex-center py-5 px-8px rounded-4px cursor-pointer'>
          <ColorfulText className='text-12px/14px'>
            <span style={{ color: DocumentStatusMap[status].color }}>
              {text}
            </span>
          </ColorfulText>
        </ColorfulBg>
      )
    }
    return (
      <Tag
        className={className}
        color={rgba(DocumentStatusMap[status].color, 0.12)}
      >
        <div className='flex-center py-5'>
          <span style={{ color: DocumentStatusMap[status].color }}>{text}</span>
          {(status === DocumentStatus.Fail ||
            status === DocumentStatus.Warning) && (
            <IconFont
              className='ml-4 cursor-pointer text-11px shrink-0'
              style={{ color: DocumentStatusMap[status].color }}
              name='jieshishuimeng'
            />
          )}
        </div>
      </Tag>
    )
  }

  return status === DocumentStatus.Fail ||
    status === DocumentStatus.Warning ||
    status === DocumentStatus.AIProcess ? (
    <Popover
      title={popTitle}
      content={popContent}
      destroyTooltipOnHide
      overlayInnerStyle={{
        padding: 12,
      }}
    >
      {renderTag()}
    </Popover>
  ) : (
    renderTag()
  )
}
