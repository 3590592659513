import { uniq } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useApplicationStore, useNodePanelStore } from '@/store'
import { extractVars } from '../llm/LLMNodePanel/utils'

interface IDynamicValuesHookParams {
  values: any
  data: Record<string, any>
  nodeId: string
  ruleCallback: (
    values: any,
  ) => { content: string; variableFieldType?: 'json' }[]
  variables?: string[] | undefined
}
export function useDynamicValues(props: IDynamicValuesHookParams) {
  const { values, data, nodeId, ruleCallback, variables } = props
  const { currentApplicationId } = useApplicationStore()
  const {
    setUsedKeyListV1,
    setDynamicValuesV1,
    getDynamicValuesV1,
    dynamicValuesV1,
  } = useNodePanelStore()

  const onDynamicValuesChangeV1 = (key: string, value: any) => {
    setDynamicValuesV1(currentApplicationId, nodeId, key, value)
  }

  const getUsedKeyList = () => {
    const messages = ruleCallback(values) || []
    const keys: string[] = []
    messages.forEach(item => {
      const result = extractVars(item.content, variables)
      keys.push(...result)
    })
    return uniq(keys)
  }

  useEffect(() => {
    setUsedKeyListV1(getUsedKeyList())
  }, [data])

  const usedKeyListByNodeId = useMemo(() => {
    return getUsedKeyList()
  }, [getUsedKeyList])

  const getKeyFieldTypes = () => {
    const messages = ruleCallback(values) || []
    const fieldTypes: Record<string, any> = {}
    messages.forEach(item => {
      if (!item.variableFieldType) return
      const result = extractVars(item.content, variables)
      if (item.variableFieldType && result[0]) {
        fieldTypes[result[0]] = item.variableFieldType
      }
    })
    return fieldTypes
  }

  const keyFieldTypes = useMemo(() => {
    return getKeyFieldTypes()
  }, [getKeyFieldTypes])

  const getDynamicValueByUsedKeyListV1 = useMemo(() => {
    const usedKeyList = getUsedKeyList()
    const res = usedKeyList.reduce(
      (total: Record<string, any>, current: string) => {
        const values = getDynamicValuesV1(currentApplicationId, nodeId) || {}
        total[current] = values[current] || ''
        return total
      },
      {},
    )
    return res
  }, [
    getUsedKeyList,
    getDynamicValuesV1,
    currentApplicationId,
    dynamicValuesV1,
    nodeId,
  ])

  const currentDynamicValuesV1 = useMemo(() => {
    return getDynamicValueByUsedKeyListV1
  }, [
    getDynamicValueByUsedKeyListV1,
    currentApplicationId,
    nodeId,
    dynamicValuesV1,
  ])

  return {
    onDynamicValuesChangeV1,
    currentDynamicValuesV1,
    usedKeyListByNodeId,
    keyFieldTypes,
  }
}
