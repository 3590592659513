import type { Edge, Node } from 'reactflow'
import { getIncomers, getOutgoers } from 'reactflow'

export function getPrevNode(node: Node, nodes: Node[], edges: Edge[]) {
  const prevNodes = getIncomers(node, nodes, edges)
  return prevNodes[0] || null
}

export function getNextNode(node: Node, nodes: Node[], edges: Edge[]) {
  const nextNodes = getOutgoers(node, nodes, edges)
  return nextNodes[0] || null
}
