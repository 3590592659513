import { message } from 'antd'
import { cloneDeep } from 'lodash-es'
import type { MouseEvent } from 'react'
import type {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlowActions,
  ResizeParams,
} from 'reactflow'
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getOutgoers,
} from 'reactflow'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import type { FlowData } from '@/apis/flow'
import { FLOW_STATUS, updateFlowDetail } from '@/apis/flow'
import { FLOW_CLIPBOARD, NEW_FLOW_MAP } from '@/constants/common'
import { AIWorkFlow } from '@/features/flow/AIWorkFlow'
import { NODE_WIDTH, flowAutoLayout } from '@/features/flow/layout'
import type {
  AddConditionData,
  RemoveConditionData,
} from '@/features/flow/operation/MultiBranchNodeOperation'
import type { ActionType } from '@/features/nodes/base'
import { NodeType } from '@/features/nodes/base'
import {
  convertFlowNodesToRenderNodes,
  convertRenderNodesToFlowNodes,
} from '@/pages/flowPage/util'
import { toggleNodeEnableOnFlow } from '@/features/flow/actions'
import { setPluginData, PluginDataType, PluginDataFrom } from '@/apis/plugins'
import { pubSignal } from '@/hooks/useSignal'
import { UPDATE_FLOW_SIGNAL } from '@/pages/flowPage/const'
import { useFlowInteractionStore } from '.'

interface Modification {
  nodes: Node[]
  edges: Edge[]
}

export enum FLOW_DRAFT_LOCK_STATUS {
  UNLOCK = 'UNLOCK',
  LOCK_PREVIEW = 'LOCK_PREVIEW',
  LOCK_IN_VERSION = 'LOCK_IN_VERSION',
  LOCK_BATCH_TEST_RUN = 'LOCK_BATCH_TEST_RUN',
}

export interface ReplaceNodeData<T = any> {
  type: NodeType
  data: T & {
    name: string
    relation: any
    actionType: ActionType
  }
}

export type NodeValidateFunction = () => boolean | Promise<boolean>

async function validateNode(validateFunction: NodeValidateFunction) {
  let result: boolean
  try {
    result = !!(await validateFunction())
  } catch (e) {
    result = false
  }
  return Promise.resolve(result)
}

const initialNodes: Node[] = []
const initialEdges: Edge[] = []

const MODIFICATION_MAX_LENGTH = 10

function enqueuePastModifications(
  origin: FlowDraftState['pastModifications'],
  nodes: Node[],
  edges: Edge[],
) {
  const newHistory = origin.slice()
  if (newHistory.length >= MODIFICATION_MAX_LENGTH) {
    newHistory.shift()
  }
  newHistory.push({
    nodes,
    edges,
  })
  return newHistory
}

function checkIsTemplateFlow() {
  const u = new URLSearchParams(location.search)
  return u.get('isTemplateFlow') === 'true'
}

let pasteStatusTimer: NodeJS.Timeout

export interface FlowDraftState {
  isTemplateFlow: boolean
  flow: AIWorkFlow | null
  appId: string
  flowId: string
  lockStatus: FLOW_DRAFT_LOCK_STATUS
  lockMessage: string
  isSaving: boolean
  isModified: boolean
  nodes: Node[]
  edges: Edge[]
  /**
   * 保存前10次的状态，用于 undo
   */
  pastModifications: Modification[]
  futureModifications: Modification[]
  nodeValidateFunctionMap: Record<string, NodeValidateFunction>
  nodeValidateResultMap: Record<string, boolean>
  nodeErrorStatusMap: Record<string, boolean>
  viewPortNodeId: string
  pasteStatus: boolean
  newFlowList: Record<string, boolean>
  isDraftInitialized: boolean
  setFlowLock: (lockStatus: FLOW_DRAFT_LOCK_STATUS, message?: string) => void // Add a colon after the parameter type declaration
  setViewPortNodeId: (id: string) => void
  setNodeErrorStatusMap: (data: Record<string, boolean>) => void
  setNewFlowList: (e: Record<string, boolean>) => void
  initFlow: (data: FlowData) => void
  undo: VoidFunction
  redo: VoidFunction
  toggleNodeEnable: (nodeId: string, enable: boolean) => void
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
  onNodeClick: (e: MouseEvent, data: Node, isMultiSelect?: boolean) => void
  onNodeResize: (
    nodeId: string,
    resizeParams: ResizeParams,
    formalSize: { width: number; height: number },
  ) => void
  setNodes: ReactFlowActions['setNodes']
  setConfig: (config: { edges: Edge[]; nodes: Node[] }) => void
  setPasteStatus: (pasteStatus: boolean) => void
  setIsModified: (isModified: boolean) => void
  reLayoutFlow: () => void
  registerNodeValidateFunction: (
    nodeId: string,
    validateFunc: NodeValidateFunction,
  ) => void
  removeNodeValidateFunction: (nodeId: string) => void
  validateFlow: (nodeId?: string) => Promise<{
    validated: boolean
    firstFailedNode: Node | null
  }>
  updateFlow: (data: {
    nodes: Node[]
    edges: Edge[]
    isOptimisticUpdate?: boolean // 是否乐观更新
    onError?: (err: Error) => void
  }) => void
  insertNode: (data: {
    type: NodeType
    data?: any
    target: string
    source: string
    sourceHandleId?: string | null
  }) => void
  replaceEmptyNode: (data: {
    id: string
    node: ReplaceNodeData
    sourceHandleId?: string
  }) => void
  deleteNodes: (ids: string[]) => void
  updateNode: (data: { id: string; node: Partial<Node> }) => void
  addBranch: (data: AddConditionData) => any
  removeBranch: (data: RemoveConditionData) => any
  copyNodes: (targetNodes: Node[], successMessage?: string) => boolean
  copyNodeById: (id: string, successMessage?: string) => void
  cutNodes: (targetNodes: Node[], successMessage: string) => void
  replaceEmptyByCloneNodes: (id: string) => void
  pasteNodes: (e: {
    source: string
    target: string
    sourceHandleId?: string
  }) => void
  clear: () => void
}

export const useFlowDraftStore = createWithEqualityFn<FlowDraftState>(
  (set, get) => ({
    isTemplateFlow: checkIsTemplateFlow(),
    flow: null,
    appId: '',
    flowId: '',
    isSaving: false,
    isModified: false,
    lockStatus: FLOW_DRAFT_LOCK_STATUS.UNLOCK,
    lockMessage: '',
    nodeValidateFunctionMap: {},
    nodeValidateResultMap: {},
    nodeErrorStatusMap: {},
    pastModifications: [],
    futureModifications: [],
    viewPortNodeId: '',
    pasteStatus: false,
    nodes: initialNodes,
    edges: initialEdges,
    newFlowList: JSON.parse(localStorage.getItem(NEW_FLOW_MAP) || '{}'),
    isDraftInitialized: false,
    setFlowLock(lockStatus, message) {
      lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK
        ? set({
            lockStatus,
            lockMessage: message || '',
          })
        : set({
            lockStatus,
            lockMessage: '',
          })
    },
    setNewFlowList: (newFlowList: Record<string, boolean>) =>
      set({ newFlowList }),
    onNodesChange: (changes: NodeChange[]) => {
      console.log('nodechanges', changes)
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      })
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      })
    },
    onConnect: (connection: Connection) => {
      set({
        edges: addEdge(connection, get().edges),
      })
    },
    onNodeClick: (_, node, isMultiSelect) => {
      const { nodes } = get()
      const sortCopyIndex = nodes.filter(i => i.selected).length
      set({
        nodes: get().nodes.map(item => ({
          ...item,
          data: {
            ...(item.data || {}),
            sortCopyIndex:
              node.id === item.id
                ? sortCopyIndex
                : item.selected
                  ? item.data?.sortCopyIndex
                  : -1,
          },
          selected: isMultiSelect
            ? item.id === node.id || item.selected
            : item.id === node.id,
        })),
        nodeErrorStatusMap: {},
      })
    },
    onNodeResize: (nodeId, resizeParams, formalSize) => {
      const handleNodeSizeLayout = (
        node: Node,
        diff: number,
        nodes: Node[],
        edges: Edge[],
      ) => {
        const afterNodes = getOutgoers(node, nodes, edges)
        if (afterNodes.length) {
          afterNodes.forEach(item => {
            item.position.x += diff
            handleNodeSizeLayout(item, diff, nodes, edges)
          })
        }
      }
      const { nodes, edges } = get()
      const resizeNode = nodes.find(item => item.id === nodeId)
      if (resizeNode) {
        const wDiff = resizeParams.width - (formalSize.width || NODE_WIDTH)
        handleNodeSizeLayout(resizeNode, wDiff, nodes, edges)
      }
    },
    setIsModified: isModified => {
      set({
        isModified,
      })
    },
    setPasteStatus: pasteStatus => set({ pasteStatus }),
    setNodes: nodes => {
      set({
        nodes,
      })
    },
    setConfig: ({ edges, nodes }) => {
      set({
        edges,
      })
      set({
        nodes: convertFlowNodesToRenderNodes(nodes as any),
      })
    },
    undo: () => {
      const { pastModifications, futureModifications, nodes, edges } = get()
      const _pastModifications = pastModifications.slice()
      const lastModify = _pastModifications.pop()
      if (lastModify) {
        set({
          nodes: lastModify.nodes,
          edges: lastModify.edges,
          pastModifications: _pastModifications,
          futureModifications: [{ nodes, edges }, ...futureModifications],
        })
      }
    },
    redo: () => {
      const { pastModifications, futureModifications, nodes, edges } = get()
      const _futureModifications = futureModifications.slice()
      const nextModify = _futureModifications.shift()
      if (nextModify) {
        set({
          nodes: nextModify.nodes,
          edges: nextModify.edges,
          pastModifications: [...pastModifications, { nodes, edges }],
          futureModifications: _futureModifications,
        })
      }
    },
    toggleNodeEnable: async (nodeId, enable) => {
      const { nodes, edges, updateFlow, flow } = get()
      const currentNode = nodes.find(n => n.id === nodeId)
      if (currentNode && flow) {
        const config = toggleNodeEnableOnFlow(nodes, edges, currentNode, enable)
        await updateFlow(config)
        set({
          nodes: flow.getNodes(),
          edges: flow.getEdges(),
        })
      }
    },
    initFlow: data => {
      const { app_id, flow_id, config, is_modified, flow_lock } = data
      const instance = new AIWorkFlow(
        convertFlowNodesToRenderNodes(config.nodes),
        config.edges,
      )

      const { setFlowLock } = get()

      // console.log('flowInstance', instance)
      set({
        appId: app_id,
        flowId: flow_id,
        isModified: is_modified,
        flow: instance,
        nodes: instance.getNodes(),
        edges: instance.getEdges(),
        isDraftInitialized: true,
      })

      if (flow_lock === FLOW_STATUS.BATCH_TEST_LOCK) {
        setFlowLock(
          FLOW_DRAFT_LOCK_STATUS.LOCK_BATCH_TEST_RUN,
          '批量调试运行状态下不可编辑',
        )
      }
    },
    reLayoutFlow: () => {
      const { nodes, edges } = get()
      const layoutConfig = flowAutoLayout(nodes, edges)
      set({
        nodes: layoutConfig.nodes,
        edges: layoutConfig.edges,
      })
    },
    registerNodeValidateFunction: (nodeId, validateFunc) => {
      const { nodes } = get()
      const node = nodes.find(n => n.id === nodeId)
      // 禁用节点不做字段校验
      if (node?.data.isEnable) {
        set({
          nodeValidateFunctionMap: {
            ...get().nodeValidateFunctionMap,
            [nodeId]: validateFunc,
          },
        })
      }
    },
    removeNodeValidateFunction: nodeId => {
      const newMap = { ...get().nodeValidateFunctionMap }
      delete newMap[nodeId]
      set({
        nodeValidateFunctionMap: newMap,
      })
    },
    /**
     * @description 校验Flow中的节点，如果传了节点id，只校验单个节点，否则，校验所有节点
     */
    validateFlow: async (nodeId?: string) => {
      const { nodes, nodeValidateFunctionMap, nodeValidateResultMap } = get()
      const readyNodeValidateFunctionMap =
        nodeId && nodeValidateFunctionMap[nodeId]
          ? { [nodeId]: nodeValidateFunctionMap[nodeId] }
          : nodeValidateFunctionMap
      const validateFunctions: Promise<{ nodeId: string; result: boolean }>[] =
        Object.entries(readyNodeValidateFunctionMap).map(
          async ([nodeId, validateFunction]) => {
            const result = await validateNode(validateFunction)
            return {
              result,
              nodeId,
            }
          },
        )
      const result = await Promise.all(validateFunctions)
      const newNodeValidateResultMap = result.reduce<Record<string, boolean>>(
        (pre, cur) => {
          pre[cur.nodeId] = cur.result
          return pre
        },
        {},
      )
      set({
        nodeValidateResultMap: {
          ...nodeValidateResultMap,
          ...newNodeValidateResultMap,
        },
      })

      const failIds = result.filter(v => !v.result).map(v => v.nodeId)

      if (!failIds.length) {
        return {
          validated: true,
          firstFailedNode: null,
        }
      }
      const failNodes = nodes.filter(n => failIds.includes(n.id))
      const minPosX = Math.min.apply(
        null,
        failNodes.map(n => n.position.x),
      )
      const firstFailedNode = failNodes.find(n => n.position.x === minPosX)
      if (firstFailedNode) {
        set({
          viewPortNodeId: firstFailedNode.id,
        })
      }
      return {
        validated: false,
        firstFailedNode: firstFailedNode || null,
      }
    },
    updateFlow: async ({
      nodes,
      edges,
      isOptimisticUpdate = false,
      onError,
    }) => {
      const {
        isSaving,
        appId,
        flowId,
        flow,
        nodes: lastNodes,
        edges: lastEdges,
        pastModifications,
        lockStatus,
      } = get()
      if (isSaving) {
        console.warn('正在编辑画布')
        return
      }
      if (lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK) {
        return
      }
      const pastNodes = cloneDeep(lastNodes)
      const pastEdges = cloneDeep(lastEdges)
      set({
        isSaving: true,
      })
      try {
        const res = await updateFlowDetail({
          appId,
          flowId,
          config: {
            nodes: convertRenderNodesToFlowNodes(nodes),
            edges,
          },
        })
        pubSignal(UPDATE_FLOW_SIGNAL, window.parent)
        if (res) {
          const { config, is_modified } = res
          const newHistory = enqueuePastModifications(
            pastModifications,
            pastNodes,
            pastEdges,
          )
          set({
            isModified: is_modified,
            ...(isOptimisticUpdate
              ? {}
              : {
                  pastModifications: newHistory,
                  futureModifications: [],
                }),
          })
          if (flow && config?.nodes && config?.edges) {
            const newNodes = convertFlowNodesToRenderNodes(config.nodes)
            flow.update(newNodes, config.edges)
          }
        }
      } catch (error) {
        onError?.(error as any)
      } finally {
        set({
          isSaving: false,
        })
      }
    },
    insertNode: async data => {
      const {
        flow,
        updateFlow,
        nodes: prevNodes,
        edges: prevEdges,
        pastModifications,
      } = get()
      if (flow) {
        const config = await flow.insertNode(data)

        if (config) {
          const { nodes, edges, newNode } = config
          flow.update(nodes, edges)
          const newHistory = enqueuePastModifications(
            pastModifications,
            prevNodes,
            prevEdges,
          )
          set({
            nodes: flow.getNodes(),
            edges: flow.getEdges(),
            viewPortNodeId: newNode?.id,
            pastModifications: newHistory,
            futureModifications: [],
          })
          useFlowInteractionStore.getState().exitAddMode()

          await updateFlow({
            nodes,
            edges,
            isOptimisticUpdate: true,
            onError: err => {
              console.error(err)
              set({
                nodes: prevNodes,
                edges: prevEdges,
                viewPortNodeId: '',
              })
            },
          })
        }

        data?.type === 'PLUGIN' &&
          setPluginData(
            PluginDataType.add,
            data?.data?.plugin_id,
            PluginDataFrom.flow,
          )
      }
    },
    replaceEmptyNode: async () => {
      message.warning('该节点已废弃，请使用其他方式添加节点')
    },
    deleteNodes: async ids => {
      const { flow, updateFlow } = get()
      if (flow) {
        const config = flow.removeNodes(ids)
        if (config) {
          await updateFlow(config)
          set({
            nodes: flow.getNodes(),
            edges: flow.getEdges(),
          })
        }
      }
    },
    updateNode: async ({ id, node }) => {
      const { flow, updateFlow, nodes, edges } = get()
      if (flow) {
        await updateFlow({
          nodes: nodes.map(item =>
            item.id === id ? { ...item, ...node } : item,
          ),
          edges,
        })
        set({
          nodes: flow.getNodes(),
          edges: flow.getEdges(),
        })
      }
    },
    addBranch: async data => {
      const { flow, updateFlow } = get()
      if (flow) {
        const config = flow.addBranch(data)
        if (config) {
          await updateFlow(config)
          set({
            nodes: flow.getNodes(),
            edges: flow.getEdges(),
          })
        }
        return config
      }
    },
    removeBranch: async data => {
      const { flow, updateFlow } = get()
      if (flow) {
        const config = flow.removeBranch(data)
        if (config) {
          await updateFlow(config)
          set({
            nodes: flow.getNodes(),
            edges: flow.getEdges(),
          })
          return config
        }
      }
    },
    copyNodes: (targetNodes, successMessage = '复制成功，添加节点时粘贴') => {
      let success = false
      const { flow } = get()
      if (flow) {
        const originNodes = targetNodes
          .filter(
            item =>
              ![NodeType.EMPTY, NodeType.START, NodeType.END].includes(
                item.type as NodeType,
              ),
          )
          .sort(
            (a, b) =>
              (a?.data?.sortCopyIndex || 0) - (b?.data?.sortCopyIndex || 0),
          )
        const cloneData = flow.copyNodes(originNodes)
        if (cloneData.length) {
          localStorage.setItem(FLOW_CLIPBOARD, JSON.stringify(cloneData || []))
          set({ pasteStatus: true })
          clearTimeout(pasteStatusTimer)
          pasteStatusTimer = setTimeout(
            () => {
              set({ pasteStatus: false })
            },
            2 * 60 * 1000,
          )
          message.success(successMessage)
          success = true
        }
      }
      return success
    },
    copyNodeById: (id, successMessage) => {
      const { nodes, copyNodes } = get()
      const originNode = nodes.find(n => n.id === id)
      if (!originNode) {
        return
      }
      copyNodes([originNode], successMessage)
    },
    cutNodes: (targetNodes, successMessage) => {
      const { copyNodes, deleteNodes } = get()
      const copySuccess = copyNodes(targetNodes, successMessage)
      if (copySuccess) {
        const ids = targetNodes.map(n => n.id)
        deleteNodes(ids)
      }
    },
    replaceEmptyByCloneNodes: async id => {
      const { flow, nodes, edges, updateFlow } = get()
      if (flow) {
        const config = flow.removeNodeById(id)
        const sourceNode = nodes.find(item => item.id === id)
        const findTargetEdges = edges.find(item => item.source === id)
        const findSourceEdges = edges.find(item => item.target === id)
        if (!config || !sourceNode || !findSourceEdges || !findTargetEdges) {
          return
        }
        const newConfig = flow.pasteNodesOnEdge({
          source: findSourceEdges.source,
          target: findTargetEdges.target,
          sourceHandleId:
            findTargetEdges.sourceHandle || sourceNode.data.relation?.branchId,
          nodes: config.nodes,
          edges: config.edges,
        })
        await updateFlow(newConfig)
        set({
          nodes: flow.getNodes(),
          edges: flow.getEdges(),
          pasteStatus: false,
        })
      }
    },
    pasteNodes: async ({ source, target, sourceHandleId }) => {
      const { flow, updateFlow } = get()
      if (flow) {
        const config = await flow.pasteNodesOnEdge({
          source,
          target,
          sourceHandleId,
        })
        await updateFlow(config)
        set({
          nodes: flow.getNodes(),
          edges: flow.getEdges(),
          // viewPortNodeId: config.nodes[config.nodes.length - 1]?.id,
          pasteStatus: false,
        })
      }
    },
    clear: () => {
      set({
        nodes: [],
        edges: [],
        isDraftInitialized: false,
      })
    },
    setNodeErrorStatusMap: data => {
      set({
        nodeErrorStatusMap: data,
      })
    },
    setViewPortNodeId: id => {
      set({
        viewPortNodeId: id,
      })
    },
  }),
  shallow,
)

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('flowDraftStore', useFlowDraftStore)
}
