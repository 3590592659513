import { NodeType } from '../base/types'

export function generateNodeName(
  node: Record<string, any>[],
  nodeType: string,
  packageName?: string,
) {
  let nodeNames
  let name
  if (nodeType === NodeType.PLUGIN) {
    nodeNames = node
      .filter(v => v.data?.pluginName === packageName)
      .map(v => v.data.name)
    name = `${(packageName || '').toLowerCase()}_${nodeNames.length + 1}`
  } else {
    nodeNames = node.filter(v => v.type === nodeType).map(v => v.data.name)
    name = `${(packageName || nodeType).toLowerCase()}_${nodeNames.length + 1}`
  }
  const hasRepeatName = nodeNames.includes(name)
  return hasRepeatName ? `${name}_1` : name
}
