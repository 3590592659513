import { useAuth } from '@bty/react-auth'
import { SidebarMenuData } from '../contants'
import { RoleCode } from '@/apis/authority'
import { checkGrayscale } from '@/utils/grayscale'
import { grayscaleMap, isPrivateVersion } from '@/constants/common'
import { transformMenuData } from '../utils'

// 处理部分菜单权限
export function useLayoutMenus(pathname: string) {
  const {
    access,
    state: { grayscaleList },
  } = useAuth()
  let menus = [...SidebarMenuData]
  // 私有化权限
  if (isPrivateVersion) {
    menus = menus.filter(m => m.path !== 'explore')
  }
  // 角色权限
  if (!access.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])) {
    menus = menus.filter(m => m.path !== 'datastores' && m.path !== 'db')
  }
  // 灰度权限
  if (!checkGrayscale({ grayscaleList, grayscale_key: grayscaleMap.agent })) {
    menus = menus.filter(m => m.path !== 'agent')
  }
  return transformMenuData(menus, pathname)
}
