import type { Edge, Node } from 'reactflow'
import { isNestableNode } from '../../utils'
import { getNormalNodeBoundary } from './util'
import { getNestNodeBoundary } from './nest'

export function getNodeBoundary(node: Node, nodes: Node[], edges: Edge[]) {
  let boundary
  if (isNestableNode(node)) {
    boundary = getNestNodeBoundary(node, nodes, edges)
  } else {
    boundary = getNormalNodeBoundary(node)
  }
  return boundary
}
