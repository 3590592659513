import React, { useContext, useEffect, useRef } from 'react'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import { useMemoizedFn, useRequest } from 'ahooks'
import { Tooltip } from 'antd'
import {
  DocumentContext,
  DocumentProvider,
} from '@/pages/datastores/provider/DocumentProvider'
import { Detail } from '@/pages/datastores/document-detail/Detail'
import { DocumentStatus, DocumentType } from '@/apis/datastore/model'
import {
  fileRetry,
  getDataStoreDetail,
  getQAGroupList,
  getSplitContent,
} from '@/apis/datastore/api'
import {
  Button,
  IconFont,
  IssueReport,
  SceneTypes,
  usePageModal,
} from '@/components'
import { StatusTag } from '@/features/datastore/components/StatusTag'
import { getFileIconByFileTypeOrMimeType } from '@/features/datastore/utils'
import { useWorkspaceStore } from '@/store/workspace'
import { useParagraph } from '@/pages/datastores/hooks/useParagraph'
import { KNOWLEDGE_RELOAD } from '@/constants/message'
import { useReportFileUpload } from '@/hooks/useReportFileUpload'
import { fileTypeMimeMap } from '@/features/datastore/constant'
import {
  AGENT_FROM,
  AGENT_KNOWLEDGE_ROUTER_MAP,
} from '@/features/agent/constant/base'
import { useSubSignal } from '@/hooks/useSignal'

function InternalAgentKnowledgeDetail() {
  const {
    documentInfo,
    reprocessDocumentLoading,
    loading: fetchDocumentLoading,
    onReprocessDocument,
  } = useContext(DocumentContext)
  const navigate = useNavigate()
  const { data: info, run: fetchDatasetDetail } = useRequest(
    getDataStoreDetail,
    {
      manual: true,
    },
  )

  const reactParams = useParams()
  const location = useLocation()
  const { datastoreId, id: agentId } = reactParams
  const { run: retryFile } = useRequest(fileRetry, { manual: true })
  const pageModal = usePageModal()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const paragraph = useParagraph(
    documentInfo?.file_id,
    documentInfo?.file_type === DocumentType.QA
      ? getQAGroupList
      : getSplitContent,
  )
  const nowParagraphId = useRef(-1)

  const onRetry = useMemoizedFn(() => {
    if (documentInfo?.file_id) {
      onReprocessDocument(() => retryFile(documentInfo.file_id))
    }
  })

  const open = useMemoizedFn((type: string) => {
    if (!documentInfo) return
    const query = ['independent=1']

    if (nowParagraphId.current >= 0) {
      query.push(`&paragraphId=${nowParagraphId.current}`)
    }

    const knowledgeId = documentInfo.partition_id
    const fileId = documentInfo.file_id
    const queryString = query.join('&')
    const url = `/datastores/${workspaceId}/${knowledgeId}/${fileId}/${type}?${queryString}`

    pageModal.show({ url })
  })

  const toDocumentDetail = useMemoizedFn(() => {
    open('content')
  })

  const toDocumentSource = useMemoizedFn(() => {
    open('source-preview')
  })

  const toDocumentSplit = useMemoizedFn(() => {
    open('split')
  })

  const toDocumentHit = useMemoizedFn(() => {
    open('hit-test')
  })

  const handleSelect = useMemoizedFn((id: string | number) => {
    nowParagraphId.current = Number(id)
  })

  const handleReload = useMemoizedFn(() => {
    if (!documentInfo) return
    onReprocessDocument(() => retryFile(documentInfo.file_id))
  })

  useEffect(() => {
    if (!documentInfo) return
    fetchDatasetDetail(documentInfo?.partition_id)
  }, [documentInfo?.partition_id])

  useSubSignal(KNOWLEDGE_RELOAD, handleReload)

  const { upload } = useReportFileUpload()

  if (!documentInfo || !info) return

  return (
    <div className='pt-24 flex flex-col overflow-hidden h-full'>
      <div className='flex items-center shrink-0 pb-20 px-24'>
        <div className='flex items-center grow-1 pr-12 overflow-hidden'>
          <div className='flex items-center grow-1 shrink-1 pr-12  overflow-hidden'>
            <span
              className='text-16px font-500 truncate color-#8D8D99 cursor-pointer'
              onClick={() => {
                if (
                  location.state?.[AGENT_FROM] ===
                  AGENT_KNOWLEDGE_ROUTER_MAP.DATASTORE
                ) {
                  navigate(-1)
                } else {
                  navigate(`/agent/${agentId}/design/knowledge`, {
                    replace: true,
                  })
                }
              }}
            >
              知识
            </span>
            <IconFont
              className='mx-6 color-#8D8D99 text-10px'
              name='left-arrow'
            />
            <span
              className='text-16px font-500 truncate color-#8D8D99 cursor-pointer max-w-15vw'
              onClick={() => {
                if (
                  location.state?.[AGENT_FROM] ===
                  AGENT_KNOWLEDGE_ROUTER_MAP.DOCUMENT
                ) {
                  navigate(-1)
                } else {
                  navigate(
                    `/agent/${agentId}/design/knowledge/datastores/${datastoreId}`,
                    {
                      replace: true,
                    },
                  )
                }
              }}
            >
              {info?.partition.partition_name}
            </span>
            <IconFont
              className='mx-6 color-#8D8D99 text-10px'
              name='left-arrow'
            />
            <img
              className='w-20px h-24px mr-6 shrink-0'
              src={getFileIconByFileTypeOrMimeType(
                documentInfo.mimetype,
                documentInfo.file_type,
              )}
              alt=''
            />
            <span className='text-16px font-500 truncate max-w-15vw'>
              {documentInfo.file_name}
            </span>

            <span
              className='shrink-0 text-16px ml-4 cursor-pointer c-font_1 c-op-60 hover:bg-bg_3 hover:bg-op-12 w-24px h-24px rounded-4px flex-center'
              onClick={toDocumentDetail}
            >
              <IconFont name='super-link' />
            </span>
          </div>

          <StatusTag
            status={documentInfo.status}
            className='rounded-4px font-500 text-12px w-52px text-center !m-0 cursor-pointer'
            popContent={
              <div className='flex items-center line-height-18px'>
                <span className='mr-8px text-#17171d text-12px'>
                  {documentInfo.failed_reason}
                </span>
                <IssueReport
                  sceneType={SceneTypes.KNOWLEDGE}
                  defaultDesc={documentInfo.failed_reason}
                  className='p-0! h-auto!'
                  extraData={{
                    dataset_id: documentInfo.file_id,
                    dataset_file_name: documentInfo.file_name,
                    dataset_file_url: documentInfo.oss_path,
                  }}
                  upload={upload}
                />
              </div>
            }
          />
        </div>

        {documentInfo.file_type === DocumentType.FILE &&
          documentInfo.mimetype !== fileTypeMimeMap.msg && (
            <Tooltip title='预览源文件'>
              <Button
                className='mr-12 shrink-0 w-36px!'
                icon={<IconFont name='yulanyuanwenjian1x' />}
                disabled={paragraph.hasEdited}
                onClick={toDocumentSource}
              />
            </Tooltip>
          )}
        {documentInfo.status === DocumentStatus.Done &&
          documentInfo.file_type !== DocumentType.QA && (
            <Tooltip title='重新分段'>
              <Button
                className='mr-12 shrink-0 w-36px!'
                icon={<IconFont name='tongxinfenduan' />}
                disabled={paragraph.hasEdited}
                onClick={toDocumentSplit}
              />
            </Tooltip>
          )}
        {documentInfo.status === DocumentStatus.Done && (
          <Tooltip title='命中测试'>
            <Button
              className='mr-12 shrink-0 w-36px!'
              icon={<IconFont name='mingzhong' />}
              disabled={paragraph.hasEdited}
              onClick={toDocumentHit}
            />
          </Tooltip>
        )}
      </div>

      <Detail
        border
        loading={fetchDocumentLoading}
        reprocessLoading={reprocessDocumentLoading}
        paragraph={paragraph}
        documentInfo={documentInfo}
        onRetry={onRetry}
        onSelectParagraph={handleSelect}
      />
    </div>
  )
}

export const AgentKnowledgeDetail = React.memo(() => {
  const match = useMatch(
    '/agent/:agentId/design/knowledge/datastores/:datastoreId/:documentId',
  )
  const documentId = match?.params.documentId

  return (
    <DocumentProvider documentId={documentId!}>
      <InternalAgentKnowledgeDetail />
    </DocumentProvider>
  )
})
