import { Tooltip } from 'antd'
import classNames from 'classnames'
import { useMemo, useRef } from 'react'

export interface TextWithTooltipProps {
  className?: string
  maxWidth: number
  text: string
  font?: string
}

function isTextWidthExceed(text: string, maxWidth: number, font?: string) {
  // 创建一个临时的 DOM 元素
  const tempElem = document.createElement('span')
  // 设置文本和字体样式
  tempElem.textContent = text
  tempElem.style.font = font || 'inherit'
  // 将临时元素添加到页面中，这样才能获取其宽度
  document.body.appendChild(tempElem)
  // 获取文本的宽度
  const textWidth = tempElem.offsetWidth
  // 移除临时元素
  document.body.removeChild(tempElem)
  // 判断文本宽度是否大于最大宽度
  return textWidth > maxWidth
}

export function TextWithTooltip(props: TextWithTooltipProps) {
  const { className, maxWidth, text, font } = props
  const textRef = useRef<HTMLElement>(null)
  const isExceed = useMemo(() => {
    return isTextWidthExceed(text, maxWidth, font)
  }, [text, maxWidth])

  return (
    <>
      {isExceed ? (
        <Tooltip title={text}>
          <div
            className={classNames('truncate', className)}
            style={{
              maxWidth,
            }}
          >
            <span ref={textRef}>{text}</span>
          </div>
        </Tooltip>
      ) : (
        <div className={classNames(className)}>{text}</div>
      )}
    </>
  )
}
