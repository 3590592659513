import { request } from './lib'

export interface ReportIssueParams {
  description: string
  attachments: string[]
  location_type: 1 | 2 | 3 | 4
  scene_info: any
}

export function reportIssue(body: ReportIssueParams) {
  return request.post('/v1/report', body)
}
