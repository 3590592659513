import { Spin } from 'antd'
import _ from 'lodash-es'
import { useState, useEffect, useRef } from 'react'
import { ResponsiveCardGrid } from '@/components/list/ResponsiveCardGrid.tsx'
import { LimitedAlertBar } from '@/features/pay/LimitedAlert'
import { GroupCard } from './components/GroupCard'
import { Header } from './components/Header'
import { usePluginsPageConfig } from './hooks'
import { type ICardItem, Card } from './components/Card'
import Categories from './components/Categories'
import Empty from './components/Empty'
import SelectAgentModal from './components/SelectAgentModal'

export interface AllListProps {
  child: ICardItem[]
  label_id: string
  title: string
  action?: () => void
}

enum RenderType {
  all = 'all',
  searchResult = 'searchResult',
}

function PluginsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const {
    allList = [],
    loading,
    functionId,
  } = usePluginsPageConfig({ openModal })

  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [searchResult, setSearchResult] = useState([])
  const scrollContainer = useRef<HTMLDivElement | null>(null)
  const scrollContent = useRef<HTMLDivElement | null>(null)
  const [renderType, setRenderType] = useState<RenderType>(RenderType.all)

  useEffect(() => {
    if (!allList.length) return

    setSelectedCategory(allList[0].label_id)
  }, [allList])

  const handleSearch = (query: string) => {
    const allPlugins = allList.reduce(
      (res: ICardItem[], item: { title: string; child: ICardItem[] }) => {
        if (item.title.includes('推出')) return res
        return res.concat(item.child)
      },
      [],
    )
    if (query.trim().length === 0) {
      setRenderType(RenderType.all)
    } else {
      const result = allPlugins.filter((item: ICardItem) =>
        item.title.toLowerCase().includes(query.trim().toLowerCase()),
      )

      // 如果搜索的是某个分类
      const matchedCategoryIndex = allList.findIndex(
        (item: { title: string }) => item.title === query,
      )
      if (matchedCategoryIndex !== -1) {
        result.push(
          ...allList[matchedCategoryIndex].child.filter((item: ICardItem) => {
            return (
              result.findIndex((v: ICardItem) => v.title === item.title) === -1
            )
          }),
        )
      }

      setRenderType(RenderType.searchResult)
      setSearchResult(result)
    }
  }

  const getTop = (label_id: string) => {
    const child = document.getElementById(label_id)
    if (!child || !scrollContent.current) return 0

    const containerRect = scrollContent.current.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    return childRect.top - containerRect.top
  }

  const handleClickLocate = (label_id: string) => {
    setSelectedCategory(label_id)

    if (!scrollContainer.current || !label_id) return

    scrollContainer.current.scrollTo({
      top: getTop(label_id),
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (!scrollContainer.current || !allList.length) return

    const onScroll = _.debounce(function () {
      const scrollTop = (scrollContainer.current as HTMLElement).scrollTop
      const min = {
        label_id: '',
        distance: Number.POSITIVE_INFINITY,
      }
      allList.forEach((item: AllListProps) => {
        const itemDistance = Math.abs(scrollTop - getTop(item.label_id))

        if (itemDistance < min.distance) {
          min.label_id = item.label_id
          min.distance = itemDistance
        }
      })

      if (min.label_id.length > 0) setSelectedCategory(min.label_id)
    }, 300)

    scrollContainer.current.addEventListener('scroll', onScroll)

    return () =>
      scrollContainer.current?.removeEventListener('scroll', onScroll)
  }, [allList])

  return (
    <div className='relative adapt:py-40px w-100% h-100% overflow-hidden flex flex-col'>
      <Spin spinning={loading}>
        <div className='adapt:px-64px'>
          <Header handleSearch={handleSearch} />
          <LimitedAlertBar className='mb-20px' />
          {renderType === RenderType.all ? (
            <Categories
              selectedCategory={selectedCategory}
              labels={allList.map((item: AllListProps) => ({
                label_id: item.label_id,
                title: item.title,
              }))}
              handleChangeSelected={handleClickLocate}
            />
          ) : null}
          <div
            className='h-1px w-full '
            style={{ backgroundColor: 'rgba(225, 225, 229, 0.4)' }}
          ></div>
        </div>

        {renderType === RenderType.all ? (
          <div
            id='plugin-scroll-container'
            style={{ height: 'calc(100vh - 154px)', scrollbarWidth: 'none' }}
            ref={scrollContainer}
            className='overflow-scroll adapt:px-64px'
          >
            <div
              id='plugin-scroll-content'
              ref={ref => (scrollContent.current = ref)}
            >
              {allList?.map((item: AllListProps, index: number) => {
                return (
                  <div
                    id={item.label_id}
                    key={item.title}
                    style={
                      index === allList.length - 1
                        ? { height: 'calc(100vh - 154px)' }
                        : {}
                    }
                  >
                    <GroupCard title={item.title} list={item.child} />
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div
            style={{ height: 'calc(100vh - 144px)', scrollbarWidth: 'none' }}
            className='adapt:px-64px mt-20px overflow-auto'
          >
            <ResponsiveCardGrid className='flex flex-wrap gap-24px'>
              {(searchResult ?? []).length > 0 ? (
                (searchResult ?? []).map((item: ICardItem, idx: number) => {
                  return (
                    <Card
                      checkInfo={item.checkInfo}
                      action={item.action}
                      key={idx}
                      title={item.title}
                      description={item.description}
                      avatar={item.avatar}
                    />
                  )
                })
              ) : (
                <Empty
                  style={{ height: 'calc(100vh - 144px)', width: '100%' }}
                />
              )}
            </ResponsiveCardGrid>
          </div>
        )}
      </Spin>
      <SelectAgentModal
        functionId={functionId as string}
        open={isModalOpen}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default PluginsPage
