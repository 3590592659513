import { Button } from '@/components'
import SuccessIcon from '@/assets/agent-go/success.svg?url'

export default function SuccessPanel(props: { onBack: () => void }) {
  return (
    <div className='px-40 py-60 bg-white rounded-12px w-full flex-center'>
      <div className='flex-center flex-col'>
        <img src={SuccessIcon} className='w-64px h-64px' alt='success' />
        <div className='text-16px font-600 mt-24'>
          我们已收到您的信息，稍后运营人员会和您联系
        </div>
        <Button
          onClick={props.onBack}
          type='primary'
          size='large'
          className='mt-60 !px-46'
        >
          返回
        </Button>
      </div>
    </div>
  )
}
