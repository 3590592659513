import { simple, ancestor } from 'acorn-walk'
import { parse } from 'acorn'

const builtInGlobalObjects = [
  // 通用全局对象
  'Infinity',
  'NaN',
  'undefined',
  'eval',
  'isFinite',
  'isNaN',
  'parseFloat',
  'parseInt',
  'decodeURI',
  'decodeURIComponent',
  'encodeURI',
  'encodeURIComponent',
  'Array',
  'Date',
  'RegExp',
  'Promise',
  'Proxy',
  'Map',
  'WeakMap',
  'Set',
  'WeakSet',
  'Symbol',
  'Object',
  'Function',
  'Boolean',
  'String',
  'Number',
  'Math',
  'JSON',
  'Reflect',
  // 浏览器特有全局对象
  'window', // 或 'self' 在 Web Workers 中
  'document',
  'console',
  'localStorage',
  'sessionStorage',
  'navigator',
  'history',
  'screen',
  'alert',
  'confirm',
  'prompt',
  'XMLHttpRequest',
  'fetch',
  'requestAnimationFrame',
  'cancelAnimationFrame',
  'getComputedStyle',
  'Blob',
  'File',
  'FileReader',
  // Node.js 特有全局对象
  'console', // 注意：console 同时出现在浏览器和 Node.js 中
  'setImmediate',
  'clearImmediate',
  'setInterval',
  'clearInterval',
  'setTimeout',
  'clearTimeout',
]

export function filterDebugVariableList(result: string[], variables: any): any {
  if (Array.isArray(variables) && variables.length) {
    const filteredResult = new Set()
    const variablesSet = new Set(variables)
    result.forEach(item => {
      for (const variable of variablesSet) {
        if (
          item === variable ||
          item.startsWith(`${variable}.`) ||
          item.startsWith(`${variable}[`)
        ) {
          filteredResult.add(variable)
          break // 找到匹配后即跳出循环，避免不必要的检查
        }
      }
    })

    return Array.from(filteredResult)
  } else {
    return Array.from(result)
  }
}

export function compilerCode(_code: string, variables: string[] = []) {
  try {
    const wrapperCode = _code
    const ast = parse(wrapperCode, {
      ecmaVersion: 'latest',
    })
    const declaredVariables = new Set<string>()
    const result = new Set<string>()
    const globalObjects = new Set(builtInGlobalObjects)
    // First pass to collect declared variables
    simple(ast, {
      // FunctionDeclaration(node: any) {
      //   if (node.id) {
      //     // Check if id is not null
      //     declaredVariables.add(node.id.name)
      //   }
      //   node.params.forEach((param: any) => {
      //     if ('name' in param) {
      //       declaredVariables.add((param as any).name)
      //     }
      //   })
      // },
      VariableDeclarator(node: any) {
        declaredVariables.add(node.id.name)
      },
      // ArrowFunctionExpression(node: any) {
      //   node.params.forEach((param: any) => {
      //     if (param.type === 'Identifier') {
      //       declaredVariables.add(param.name)
      //     }
      //   })
      // },
      // FunctionExpression(node: any) {
      //   node.params.forEach((param: any) => {
      //     if (param.type === 'Identifier') {
      //       declaredVariables.add(param.name)
      //     }
      //   })
      // },
    } as any)

    // 第二次通过查找未申报的变量
    ancestor(ast, {
      Identifier(node, ancestors: any) {
        const parent = ancestors[ancestors.length - 2]
        if (
          parent &&
          parent.type === 'MemberExpression' &&
          parent.object === node
        ) {
          // 如果Identifier是MemberExpression的一部分，则不视为未声明的变量
          return
        }
        const isDeclared = ancestors.some((ancestor: any) => {
          // 新增逻辑：如果当前标识符是某个箭头函数的参数，则它是已声明的
          return (
            (ancestor.type === 'ArrowFunctionExpression' ||
              ancestor.type === 'FunctionExpression') &&
            ancestor.params.some(
              (param: any) =>
                param.type === 'Identifier' && param.name === node.name,
            )
          )
        })

        if (
          !isDeclared &&
          !declaredVariables.has(node.name) &&
          !globalObjects.has(node.name)
        ) {
          let isUndeclared = true
          // 检查标识符是否是属性访问的一部分
          const parent = ancestors[ancestors.length - 1]
          if (
            parent.type === 'MemberExpression' &&
            parent.property === node &&
            !parent.computed
          ) {
            isUndeclared = false
          }

          // 检查标识符是否是对象模式中的
          if (
            parent.type === 'Property' &&
            parent.key === node &&
            !parent.computed
          ) {
            isUndeclared = false
          }

          // 如果标识符不是声明的一部分，并且未声明，请将其添加到结果中
          if (isUndeclared) {
            result.add(node.name)
          }
        }
      },
      MemberExpression(node: any) {
        if (
          node.object.type === 'Identifier' &&
          !declaredVariables.has(node.object.name) &&
          !globalObjects.has(node.object.name)
        ) {
          if (node.computed) {
            // 处理计算属性，例如 message['a']
            let propertyName
            if (node.property.type === 'Literal') {
              // 如果属性是字面量，直接使用字面量的值
              propertyName = node.property.value
            } else if (node.property.type === 'Identifier') {
              // 如果属性是一个标识符，使用标识符的名称
              propertyName = node.property.name
            }
            // 只有当我们成功获取到属性名称时，才添加到结果集中
            if (propertyName !== undefined) {
              result.add(`${node.object.name}['${propertyName}']`)
            }
          } else {
            // 非计算属性，使用点符号
            result.add(`${node.object.name}.${node.property.name}`)
          }
        }
      },
    })
    return filterDebugVariableList(Array.from(result), variables)
  } catch (e) {
    console.error(e)
    return []
  }
}

export function safeJSONStringify<T>(obj: T, space: number = 2): string | T {
  try {
    return JSON.stringify(obj, null, space)
  } catch (error) {
    return obj
  }
}
