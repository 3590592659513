import { useBoolean, useMemoizedFn, useRequest } from 'ahooks'
import { Button, Form, message } from 'antd'
import { sample } from 'lodash-es'
import type { FC } from 'react'
import { useMemo, useRef, useState } from 'react'
import type { AgentPluginSkill, AgentSkillBaseRequest } from '@bty/chat-types'
import {
  createAgentSkill,
  createAgentSkillFunction,
  updateAgentSkill,
} from '@/apis/agent.ts'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { Modal } from '@/components'
import type { SkillEditDefaultValue } from '@/features/agent/SkillEditModal/SkillEdit.tsx'
import { SkillEdit } from '@/features/agent/SkillEditModal/SkillEdit.tsx'
import type { SelectedSkill } from '@/features/agent/SkillEditModal/SkillSelect.tsx'
import { SkillSelect } from '@/features/agent/SkillEditModal/SkillSelect.tsx'
import { AgentSkillType } from '@/features/agent/constant'
import type { ApplicationBaseInfo } from '@/features/agent/types'
import type { SkillType } from '../types'
import { FLOW_DISPLAYNAME, PLUGIN_DISPLAYNAME } from '@/constants/common'
import type { AllListProps } from '@/pages/plugins'
import type { AutoDescButtonInstance } from '../components/AutoDescButton'
import { AutoDescButton } from '../components/AutoDescButton'
import type { nodeConfigType } from '@/apis/run'
import { setPluginData, PluginDataType, PluginDataFrom } from '@/apis/plugins'

interface ItemData {
  display_name: string
  tips: string[]
}

interface SkillEditorModalProps {
  fileProcess?: boolean
  allPluginList?: Array<AllListProps>
  open?: boolean
  onDelete?: (id: string) => Promise<void>
  onCancel?: () => void
  onFinish?: (value?: string[], notClose?: boolean) => void
  isEdit?: boolean
  flowInfo: ApplicationBodyType
  selectedSkillMap?: Record<'flow' | 'tool', Record<string, true>>
  activeSkillType: SkillType
  editMeta?: {
    id: string
    defaultValue: SkillEditDefaultValue
    applicationInfo: ApplicationBaseInfo
  }
  toolListTotal?: AgentPluginSkill[]
}

const activeSkillTypeDisplayMap = {
  Plugin: PLUGIN_DISPLAYNAME,
  Flow: FLOW_DISPLAYNAME,
}

export const SkillEditorModal: FC<SkillEditorModalProps> = props => {
  const {
    fileProcess = false,
    toolListTotal,
    open,
    onCancel,
    onDelete,
    isEdit,
    editMeta,
    onFinish,
    flowInfo,
    activeSkillType,
    allPluginList,
    selectedSkillMap = { flow: {}, tool: {} },
  } = props

  const [editForm] =
    Form.useForm<
      Pick<
        AgentSkillBaseRequest,
        'description' | 'inputs' | 'outputs' | 'output_mode'
      >
    >()
  const [selectedSkill, setSelectedSkill] = useState<SelectedSkill[]>([])
  const [mode, setMode] = useState<'edit' | 'select'>(
    isEdit ? 'edit' : 'select',
  )

  const { runAsync: createAgentSkillApi, loading: createAgentSkillLoading } =
    useRequest(createAgentSkill, { manual: true })
  const { runAsync: updateAgentSkillApi, loading: updateAgentSkillLoading } =
    useRequest(updateAgentSkill, { manual: true })
  const {
    runAsync: createAgentSystemSkill,
    loading: createAgentSystemSkillLoading,
  } = useRequest(createAgentSkillFunction, { manual: true })

  const [title, setTitle] = useState(
    isEdit
      ? `编辑${FLOW_DISPLAYNAME} / ${editMeta?.applicationInfo.name}`
      : `添加${activeSkillTypeDisplayMap[activeSkillType]}`,
  )

  const [nodeConfig, setNodeConfig] = useState<nodeConfigType>()

  const [autoGenerateLoading, { set: setAutoGenerateLoading }] =
    useBoolean(false)

  const AutoDescButtonRef = useRef<AutoDescButtonInstance>(null)

  const onConfirm = useMemoizedFn(async () => {
    AutoDescButtonRef?.current?.hide?.()
    if (mode === 'edit') {
      try {
        // 编辑态，修改完提交/第一次设置完保存
        const { output_mode, outputs, ...values } =
          await editForm.validateFields()
        // 改版后新增的“输出设置”还是放到outputs中保存
        const handleOutputs = outputs?.find(item => item.key === 'output_mode')
          ? outputs?.map(item =>
              item.key === 'output_mode'
                ? { key: 'output_mode', value: output_mode || '' }
                : item,
            )
          : [
              { key: 'output_mode', value: output_mode || '' },
              ...(outputs || []),
            ]
        const requestData: AgentSkillBaseRequest & { id?: string } = {
          ...values,
          outputs: handleOutputs,
          flow_id: flowInfo.flowId,
          version_id: flowInfo.versionId,
          app_id: isEdit
            ? editMeta!.applicationInfo.id
            : selectedSkill[0]!.flowMeta!.id,
          rel_flow_id: isEdit
            ? editMeta!.applicationInfo.flowId
            : selectedSkill[0]!.id,
          type: 'flow',
        }
        if (isEdit) {
          requestData.id = editMeta!.id
        }
        const fn = isEdit ? updateAgentSkillApi : createAgentSkillApi
        await fn(requestData)
        onFinish?.()

        if (!isEdit) {
          message.success('添加完成')
        }
      } catch (error) {
        // AutoDescButtonRef?.current?.show()
      }
    } else if (activeSkillType === 'Plugin') {
      // 选中系统工具，确认完成
      const res = await createAgentSystemSkill(
        selectedSkill?.map(item => item.id),
        flowInfo.flowId,
        flowInfo.versionId,
      )
      const tipsData = res?.map((item: ItemData) => ({
        label: item?.display_name,
        content: [sample(item?.tips)],
      }))
      onFinish?.(tipsData || [])
    } else if (activeSkillType === 'Flow') {
      // 选中Flow，进入下一步，编辑模式
      setMode('edit')
    }
  })

  const handleConfirm = useMemoizedFn(() => {
    if (!nodeConfig?.skill_desc && editForm.getFieldValue('description')) {
      AutoDescButtonRef?.current?.generate()
      return
    }
    AutoDescButtonRef?.current?.show()
  })

  const onAddPlugin = async (selectedPlugins: string[]) => {
    if (activeSkillType === 'Plugin') {
      // 选中系统工具，确认完成
      const res = await createAgentSystemSkill(
        selectedPlugins,
        flowInfo.flowId,
        flowInfo.versionId,
      )
      const tipsData = res?.map((item: ItemData) => ({
        label: item?.display_name,
        content: [sample(item?.tips)],
      }))
      onFinish?.(tipsData || [], true)

      selectedPlugins?.length &&
        selectedPlugins.map(id =>
          setPluginData(PluginDataType.add, id, PluginDataFrom.agent),
        )
    }
  }

  const okBtnText = useMemo(() => {
    if (autoGenerateLoading) return '生成'
    if (mode === 'edit') {
      return '确认'
    } else if (selectedSkill[0]?.type === AgentSkillType.FLOW) {
      return '下一步'
    } else if (activeSkillType === 'Plugin') {
      return '添加'
    }
    return '确定'
  }, [mode, selectedSkill, autoGenerateLoading])

  const isFlowEditByList = useMemo(() => {
    return mode === 'edit' && activeSkillType === 'Flow' && !editMeta
  }, [mode, activeSkillType])

  const onCancelFn = () => {
    if (isFlowEditByList) {
      setTitle(`添加${FLOW_DISPLAYNAME}`)
      setMode('select')
    } else {
      onCancel?.()
    }
  }

  const confirmConfig = Form.useWatch(values => {
    const hasEmptyDesc =
      !values.description || values?.inputs?.some((v: any) => !v.description)
    return hasEmptyDesc
      ? {
          content: (
            <div className='text-14px font-500'>
              <p>字段未填写完整，是否让AI自动生成后</p>
              <p className='mt-6 mb-6'>保存？</p>
            </div>
          ),
          okText: '自动生成',
          cancelText: '手动补充',
        }
      : {
          content: (
            <div className='text-14px font-500'>
              <p>配置内容将覆盖所有该工作流的调用配置，</p>
              <p className='mt-6 mb-6'>请确认是否发布配置。</p>
            </div>
          ),
          okText: '确认更新',
          cancelText: '取消',
          onConfirm,
        }
  }, editForm)

  const modalFooter =
    mode !== 'edit' &&
    (activeSkillType === 'Flow' || activeSkillType === 'Plugin')
      ? { footer: false }
      : {
          footer: (
            <div className='flex  justify-end  px-16 py-12'>
              <Button onClick={onCancelFn} className='mr-10px h-36px'>
                {isFlowEditByList ? '返回' : '取消'}
              </Button>
              <AutoDescButton
                flowId={selectedSkill?.[0]?.id}
                ref={AutoDescButtonRef}
                // ref={AutoDescButtonRef}
                okText={confirmConfig?.okText}
                cancelText={confirmConfig?.cancelText}
                trigger='manual'
                onLoading={setAutoGenerateLoading}
                onConfirm={confirmConfig?.onConfirm}
                description={confirmConfig?.content}
                onGenerated={flowSchemaResult =>
                  editForm.setFieldsValue(flowSchemaResult)
                }
              >
                <Button
                  type='primary'
                  className='h-36px'
                  loading={
                    createAgentSkillLoading ||
                    updateAgentSkillLoading ||
                    createAgentSystemSkillLoading ||
                    autoGenerateLoading
                  }
                  disabled={isEdit ? false : !selectedSkill.length}
                  onClick={handleConfirm}
                >
                  {okBtnText}
                </Button>
              </AutoDescButton>
            </div>
          ),
        }
  return (
    <Modal
      wrapClassName='modal-wrapper'
      title={activeSkillType === 'Plugin' ? null : title}
      open={open}
      width={1120}
      styles={{
        body:
          activeSkillType === 'Plugin'
            ? {
                padding: 0,
                height: '75vh',
              }
            : {
                padding: '20px 24px',
                maxHeight: '70vh',
                minHeight: 612,
                overflow: 'auto',
              },
      }}
      onCancel={onCancelFn}
      footerClassName={`px-16 py-12 ${
        activeSkillType === 'Plugin' ? 'hidden' : ''
      }`}
      // okText={okBtnText}
      // cancelText={isFlowEditByList ? '返回' : '取消'}
      {...modalFooter}
      // onOk={onConfirm}
      // okButtonProps={{
      //   loading:
      //     createAgentSkillLoading ||
      //     updateAgentSkillLoading ||
      //     createAgentSystemSkillLoading ||
      //     autoGenerateLoading,
      //   disabled: isEdit ? false : !selectedSkill.length,
      // }}
    >
      {mode === 'edit' ? (
        <SkillEdit
          form={editForm}
          onLoading={setAutoGenerateLoading}
          onConfigFetched={setNodeConfig}
          flowId={
            isEdit
              ? (editMeta?.applicationInfo.flowId as string)
              : (selectedSkill[0]?.id as string)
          }
          applicationInfo={
            isEdit
              ? (editMeta?.applicationInfo as ApplicationBaseInfo)
              : (selectedSkill[0]?.flowMeta as ApplicationBaseInfo)
          }
          defaultValue={editMeta?.defaultValue}
        />
      ) : (
        <SkillSelect
          fileProcess={fileProcess}
          toolListTotal={toolListTotal}
          allPluginList={allPluginList as AllListProps[]}
          activeSkillType={activeSkillType}
          onConfirm={onConfirm}
          onAddPlugin={onAddPlugin}
          selectedSkillMap={selectedSkillMap}
          value={selectedSkill}
          onDelete={onDelete}
          onChange={e => {
            if (activeSkillType === 'Flow') {
              setSelectedSkill([e])
              setTitle?.(
                `添加${activeSkillTypeDisplayMap[activeSkillType]} / ${e.flowMeta?.name}`,
              )
              return
            }
            !selectedSkill.find(item => item.id === e.id)
              ? setSelectedSkill([e, ...selectedSkill])
              : setSelectedSkill(selectedSkill.filter(item => item.id !== e.id))
          }}
        />
      )}
    </Modal>
  )
}
