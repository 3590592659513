import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { memo, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { IconFont } from '@/components'
import type { RenderProps } from './type'

export const MultipleSelectRender = memo(
  (props: RenderProps<string[]> & Omit<SelectProps, 'onChange'>) => {
    const {
      name,
      disabled,
      placeholder,
      value = '',
      options,
      onChange,
      supportFileTypes,

      ...rest
    } = props

    const handleChange = useMemoizedFn((newValue: string[]) => {
      onChange(newValue, name)
    })

    const sureValue = useMemo(() => {
      if (typeof value === 'string' && !isEmpty(value)) return [value]
      if (Array.isArray(value)) return value.filter(e => !isEmpty(e))
      return []
    }, [value])

    return (
      <Select<string[]>
        showSearch={false}
        mode='tags'
        suffixIcon={<IconFont name='arrow' className='text-7px text-font_1' />}
        {...rest}
        className={classNames(
          rest.className,
          'llm-node-multiple-select h-36px',
        )}
        value={sureValue}
        options={options ?? []}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
      />
    )
  },
)
