import { Form } from 'antd'
import type { FormItemProps, FormProps, Rule } from 'antd/es/form'
import type { NamePath } from 'antd/es/form/interface'
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
} from 'react'
import { transformAntdFormValidate } from '@/features/nodes/utils'
import { NodeContext } from '@/features/nodes/base/context'
import { NodeFormItem } from '..'
import type { BaseConfig } from './register'
import { RegisteredComponents } from './register'

export type JsonFormConfig = BaseConfig & {
  label?: React.ReactNode
  required?: boolean
  hidden?: boolean
  disabled?: boolean
  name?: NamePath
  rules?: Rule[]
  className?: string
  tooltip?: FormItemProps['tooltip']
  valuePropName?: string
  validateTrigger?: string | string[]
}
export interface JsonFormProps {
  form?: FormProps['form']
  // initialValues?: FormProps['initialValues']
  // onValuesChange?: FormProps['onValuesChange']
  registerValidate?: boolean
  validateTrigger?: Array<string>
  requiredMark?: FormProps['requiredMark']
  className?: string
  list: JsonFormConfig[]
  disabled?: boolean
  initialValues?: Record<string, any>
  beforeChange?: (values: unknown) => unknown | Promise<unknown> // 提交之前的对提交参数做一些处理
}

export const JsonForm = forwardRef((props: JsonFormProps, ref) => {
  const {
    list,
    className = '',
    requiredMark = false,
    registerValidate = true,
    validateTrigger = ['onSubmit'],
    disabled = false,
    initialValues: customInitialValues,
  } = props
  const {
    data,
    onSaveChange,
    focused,
    registerValidateFunction,
    removeValidateFunction,
    validateNode,
  } = useContext(NodeContext)
  const [formInstance] = Form.useForm()
  const form = props.form || formInstance

  const _onValuesChange = useCallback(
    async (_values: any, s: any) => {
      const newValue = await props.beforeChange?.(s)
      onSaveChange?.(newValue ?? s)
      validateTrigger.includes('onChange') && validateNode?.()
    },
    [validateTrigger, onSaveChange, validateNode],
  )

  // 注册校验函数
  useEffect(() => {
    if (data?.isEnable) {
      registerValidate &&
        registerValidateFunction?.(
          transformAntdFormValidate(form.validateFields),
        )
    } else {
      removeValidateFunction?.()
    }
  }, [
    registerValidateFunction,
    form.validateFields,
    registerValidate,
    removeValidateFunction,
    data?.isEnable,
  ])

  const initialValues = Object.assign({}, data, customInitialValues)

  // 变量依赖变化重置表单
  useEffect(() => {
    !focused && form.setFieldsValue(initialValues)
  }, [initialValues, focused])

  useImperativeHandle(ref, () => form, [form])

  return (
    <Form
      initialValues={initialValues}
      form={form}
      requiredMark={requiredMark}
      onValuesChange={_onValuesChange}
      layout='vertical'
      className={className}
      disabled={disabled}
    >
      {list.map((item, index) => {
        const {
          componentProps,
          render,
          type: _type,
          ...rest
        } = item as JsonFormConfig & { render?: any; type: any }
        const { component: Component, defaultProps = {} } =
          // @ts-expect-error ts(2339)
          RegisteredComponents?.[item.type] || {}
        const itemProps = { ...defaultProps, ...componentProps }
        const clsName = defaultProps.className
          ? `${defaultProps.className} ${itemProps.className}`
          : itemProps.className

        const getFormItemProps = (type: string) => {
          const { label, ...restProps } = rest
          const props: Record<string, any> = restProps

          if (type !== 'Switch') {
            props.label = label
          }
          return props
        }
        if (!Component && !render) {
          return null
        }

        const genItemProps = (type: string) => {
          const _itemProps = itemProps
          if (type === 'Switch') {
            _itemProps.valuePropName = 'checked'
            _itemProps.label = rest.label
            _itemProps.tooltip = rest.tooltip
          }
          return _itemProps
        }

        return (
          <NodeFormItem key={index} {...getFormItemProps(_type)}>
            {render ? (
              render?.()
            ) : (
              <Component
                {...(genItemProps(_type) as any)}
                className={clsName}
                disabled={item.disabled}
                // viewport={viewport}
              />
            )}
          </NodeFormItem>
        )
      })}
    </Form>
  )
})
