import { useRequest } from 'ahooks'
import { useEffect } from 'react'
import { useLLMStore } from '@/store/llm'
import { useNodeMetaStore } from '@/store/nodeMeta'

export function useFlowExtraDataInit() {
  const { initNodeMetaData } = useNodeMetaStore()
  const { initLLMData } = useLLMStore()

  useRequest(
    () => {
      return Promise.all([initNodeMetaData(), initLLMData()])
    },
    {
      manual: true,
      refreshOnWindowFocus: true,
    },
  )

  useEffect(() => {
    initNodeMetaData()
    initLLMData()
  }, [])
}
