import type { FormInstance } from 'antd'
import { Form } from 'antd'
import { Global, css } from '@emotion/react'
import { useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { IconFont, Input, TextArea } from '@/components'

function GlobalMultipleAddSynonymLibraryStyle() {
  return (
    <Global
      styles={css`
        .ant-form-item .ant-form-item-label > label::after {
          content: '';
          display: none;
        }
        .ant-form-item
          .ant-form-item-label
          > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
          )::before {
          margin-left: 4px;
        }
        .ant-form-vertical .ant-form-item-label > label {
          flex-direction: row-reverse;
        }
      `}
    />
  )
}

const DivWrapper = styled.div<{ synonymError: boolean }>`
  border: 1px solid transparent;
  ${({ synonymError }) =>
    synonymError
      ? 'border: 1px solid #ff5219'
      : `&:hover{
    border: 1px solid #7B61FF
  }`}
`

function NormalTag({
  title,
  error,
  onClose,
}: {
  title: string
  error?: boolean
  onClose?: () => void
}) {
  return (
    <div
      className='flex gap-8px text-14px pl-8px pr-6px py-4px border-rd-4px'
      style={{
        border: error ? '1px solid #FF5219' : '1px solid #E1E1E5',
        background: '#fff',
        color: 'rgba(23, 23, 29)',
      }}
    >
      {title}
      <IconFont
        name='guanbi'
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onClose?.()
        }}
        className='c-#8d8d99 c-op-60 hover:c-#17171d hover:c-op-80 cursor-pointer'
      />
    </div>
  )
}

const FormItemWrapper = styled(Form.Item)`
  .ant-form-item-control-input {
    min-height: 0;
  }
`

export function MultipleAddSynonymLibrary({ form }: { form: FormInstance }) {
  const synonym = Form.useWatch(values => values.synonym, form)
  const [inputValue, setInputValue] = useState('')
  const [synonymError, setSynonymError] = useState<{
    errorIndexList: number[]
  } | null>(null)
  const tagList = useMemo(() => {
    return (synonym || '').split(/,|，/)?.filter((item: any) => !!item)
  }, [synonym])
  const InputRef = useRef<any>(null)
  const handleFormValidate = (list: string) => {
    form.setFieldValue('synonym', list)
    form.validateFields(['synonym'], { recursive: true })
  }
  const isOnKeyDownGetValue = useRef<boolean>(false)
  const [isBlur, setIsBlur] = useState(true)
  return (
    <Form layout='vertical' form={form}>
      <GlobalMultipleAddSynonymLibraryStyle />
      <Form.Item
        name='master_word'
        label='知识实体词'
        rules={[{ required: true, message: '请输入实体词' }]}
      >
        <Input
          showCount
          maxLength={40}
          placeholder='请填写实体词，最多40个字符'
        />
      </Form.Item>
      <div className='relative mb-12px'>
        近义词
        <span
          className='c-#ff5219 ml-4px'
          style={{ fontFamily: 'SimSun,sans-serif' }}
        >
          *
        </span>
        <span className='absolute left-50px c-font_1'>{`（${
          tagList.length || 0
        }/25）`}</span>
      </div>
      <DivWrapper
        synonymError={!!synonymError}
        style={{
          background: 'rgba(98, 105, 153, 0.06)',
          border: synonymError
            ? '1px solid #ff5219'
            : !isBlur
            ? '1px solid #7B61FF'
            : '',
        }}
        className='relative min-h-80px h-100% border-rd-6px'
      >
        <div
          className='flex flex-wrap items-start gap-8px min-h-80px w-100% h-100% relative z-11 px-12px py-8px'
          onClick={() => tagList.length < 25 && InputRef.current.focus?.()}
        >
          {tagList.map((item: any, key: number) => (
            <NormalTag
              onClose={() =>
                handleFormValidate(
                  tagList
                    .filter((_: any, index: number) => index !== key)
                    .join(','),
                )
              }
              error={synonymError?.errorIndexList.includes(key)}
              key={key}
              title={item}
            />
          ))}
          {tagList.length < 25 && (
            <TextArea
              variant='borderless'
              ref={InputRef}
              onChange={e => {
                if (
                  e.target.value.split(/,|，/)?.filter(item => !!item).length >=
                  2
                ) {
                  isOnKeyDownGetValue.current = true
                  handleFormValidate(`${tagList.join(',')},${e.target.value}`)
                  InputRef.current.blur?.()
                } else {
                  e.target.value.length <= 40 && setInputValue(e.target.value)
                }
              }}
              onFocus={() => setIsBlur(false)}
              onBlur={e => {
                setIsBlur(true)
                if (e.target.value && !isOnKeyDownGetValue.current) {
                  handleFormValidate([...tagList, inputValue].join(','))
                }
                isOnKeyDownGetValue.current = false
                setInputValue('')
              }}
              value={inputValue}
              size='small'
              autoSize
              onKeyDown={e => {
                if (e.keyCode === 8 && tagList.length > 0 && !inputValue) {
                  handleFormValidate(
                    tagList.slice(0, tagList.length - 1).join(','),
                  )
                  return
                }
                if ([188, 13].includes(e.keyCode)) {
                  handleFormValidate([...tagList, inputValue].join(','))
                  setTimeout(() => setInputValue(''))
                }
              }}
              style={{
                background: 'none',
                height: 24,
                width: 'auto',
                flex: 1,
                fontSize: 14,
                lineHeight: '24px',
                minWidth: '30%',
              }}
            />
          )}
        </div>
        {!inputValue && tagList.length <= 0 && (
          <div
            style={{ color: 'rgba(141, 141, 153, 0.6)' }}
            className='w-100% h-100% z-5 text-14px absolute px-16px py-8px bg-none line-height-22px top-0'
          >
            请填写近义词，使用逗号分隔，每个词最多40个字符
          </div>
        )}
      </DivWrapper>
      <FormItemWrapper
        name='synonym'
        required={false}
        rules={[
          {
            required: true,
            validator(_, value) {
              const list =
                value?.split(/,|，/).filter((item: string) => !!item) || []
              const errorIndexList = list
                .map((item: string, index: number) =>
                  item.length > 40 ? index : -1,
                )
                .filter((item: number) => item !== -1)
              if (
                !errorIndexList.length &&
                list.length > 0 &&
                list.length <= 25
              ) {
                setSynonymError(null)
                return Promise.resolve()
              }
              if (list.length > 25) {
                setSynonymError({ errorIndexList })
                return Promise.reject(new Error('近义词数量超过25个'))
              }
              if (errorIndexList.length) {
                setSynonymError({ errorIndexList })
                return Promise.reject(new Error('近义词内容超过40字'))
              }
              console.log(32445689)
              setSynonymError({ errorIndexList })
              return Promise.reject(new Error('请填写近义词'))
            },
          },
        ]}
      ></FormItemWrapper>
    </Form>
  )
}
