import { useCallback, useEffect, useRef, useState } from 'react'
import type { GridColumnType, TableType } from '@bty/smartsheet'
import { getTableColumns, getTableDetail } from '@/apis/database/api'

export default function useTableDetail(tableId?: string) {
  const [table, setTable] = useState<TableType>()

  const tableIdRef = useRef(tableId)

  const fetch = async (id?: string) => {
    if (!id) {
      return
    }

    const table = await getTableDetail(id)

    const viewId = table.views?.[0]?.id

    if (viewId) {
      const columns = await getTableColumns(viewId)

      const columnMap = columns.reduce((m, col) => {
        m.set(col.fk_column_id, col)
        return m
      }, new Map<string, GridColumnType>())

      table.columns = table.columns?.filter(
        col =>
          col.column_name !== 'created_by' && col.column_name !== 'updated_by',
      )

      table.columns?.forEach(col => {
        const meta = columnMap.get(col.id)
        col.grid_column_meta = meta
        col.order = meta?.order
      })

      table.columns?.sort((a, b) => {
        if (a.order === undefined || b.order === undefined) {
          return 0
        }
        return a.order - b.order
      })
    }

    if (table) {
      setTable(table)
    }
  }

  useEffect(() => {
    tableIdRef.current = tableId
    fetch(tableId)
  }, [tableId])

  const reload = useCallback(() => {
    fetch(tableIdRef.current)
  }, [])

  return { table, reload }
}
