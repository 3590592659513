import type { ReactNode } from 'react'

export interface ErrorProps {
  image?: string
  content: ReactNode
}

export function Error(props: ErrorProps) {
  const { image, content } = props

  return (
    <div className='w-100% h-100% flex-center flex-col'>
      {image && <img className='w-124px' src={image} />}
      <div className='m-t-12px c-bg_3 c-op-40'>{content}</div>
    </div>
  )
}
