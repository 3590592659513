export interface ICurveDirection {
  x: 0 | 1 | -1
  y: 0 | 1 | -1
}

export function getCurveBySinglePoint(
  point: [number, number],
  dir: [ICurveDirection, ICurveDirection],
  gap = 10,
) {
  const [x, y] = point
  const start = [x - dir[0].x * gap, y - dir[0].y * gap]
  const end = [x + dir[1].x * gap, y + dir[1].y * gap]
  const path = `C${start[0]} ${start[1]}, ${x} ${y}, ${end[0]} ${end[1]} `
  return {
    start,
    end,
    path,
  }
}
