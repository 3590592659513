import type { ReactNode, FC, Key, MutableRefObject } from 'react'
import { useState, useRef, useMemo, useEffect } from 'react'
import type { PopoverProps, TooltipProps, InputRef } from 'antd'
import { Popover, Tooltip } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { Input, IconFont, AppLogo } from '@/components'
import { colors } from '@/constants/theme'
import type { PartitionCategoryType } from '@/apis/datastore/types'
import { Empty } from '../../Empty'
import { PartitionCategory } from '../DatasetSelect'

const OptionItemWrapper = styled.div`
  &:hover {
    .link-action {
      opacity: 1;
    }
  }
`

/**
 * 用于标识上层组件是知识库选择框，还是文件选择框
 */
type datasetCompSelectType = 'dataset' | 'document'

function Option(props: {
  label: string
  value: unknown
  description?: any
  count?: number
  checked: boolean
  indeterminate?: boolean
  category?: PartitionCategoryType
  icon: string
  iconColor: string
  onClick: () => void
  onPreview?: (value: number) => void
  type?: datasetCompSelectType
}) {
  const showCheckedStatus = useMemo(() => {
    return props.checked && props.type === 'document'
  }, [props])
  return (
    <OptionItemWrapper
      key={props.value as Key}
      className={classNames(
        'px-8 rounded-6px cursor-pointer hover:bg-bg_3 hover:bg-op-8 flex items-center mb-4px',
        {
          'bg-#7F69FF! bg-op-8!': showCheckedStatus,
        },
      )}
      title={props.label}
      onClick={props.onClick}
    >
      <div className='h-[32px] text-[12px] flex-1 flex items-center overflow-hidden'>
        {props?.type === 'document' ? (
          <img
            className='w-14px h-16px shrink-0 mr-8px'
            src={props.icon}
            alt=''
          />
        ) : (
          <AppLogo
            type='icon'
            size={24}
            fillSize={12}
            color={props.iconColor ?? colors[0]}
            value={props.icon ?? 'app'}
            className='rounded-4px mr-8px'
          />
        )}

        <span className='max-w-[270px] mr-[8px] truncate'>{props.label}</span>
        {props?.category && (
          <span className='color-#8D8D99 px-4px py-2px rounded-4px box-border b-1px b-color-#E1E1E5 b-op-60 text-11px/12px'>
            {PartitionCategory[props.category]}
          </span>
        )}
      </div>
      <div
        className='link-action w-[24px] h-[24px] rounded-[4px] hover:bg-bg_3 hover:bg-op-12 cursor-pointer flex justify-center items-center opacity-0'
        onClick={e => {
          e.stopPropagation()
          props.onPreview?.(props.value as number)
        }}
      >
        <Tooltip
          placement='top'
          title={props?.type === 'document' ? '查看文档详情' : '查看知识库详情'}
        >
          <IconFont
            name='super-link'
            className='c-[#626999] c-op-60 text-[16px]'
          />
        </Tooltip>
      </div>
      {showCheckedStatus && (
        <IconFont
          name='success'
          className='c-primary c-op-60 text-12px ml-2px'
        />
      )}
    </OptionItemWrapper>
  )
}

export interface SelectOptionsType {
  label: string
  count?: number
  value: unknown
  category?: PartitionCategoryType
  icon: string
  iconColor: string
  description?: string
  type?: datasetCompSelectType
}

interface SelectComponentProps extends PopoverProps {
  options?: Array<SelectOptionsType>
  defaultValue?: string[]
  value?: unknown
  onChange?: (value: unknown) => void
  width?: number
  disabled?: boolean
  placement?: TooltipProps['placement']
  onOpenChange?: (visible: boolean) => void
  triggerRender: (
    value: unknown,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => ReactNode
  extraRender?: ReactNode
  handlePreviewClick?: (partition_id?: number) => void
  type?: datasetCompSelectType
}

export const SelectComponent: FC<SelectComponentProps> = props => {
  const {
    defaultValue,
    triggerRender,
    options = [],
    onChange,
    value: propsValue,
    disabled,
    placement = 'bottomRight',
    extraRender,
    handlePreviewClick,
    type,
    ...remainingProps
  } = props
  const [__value, setValue] = useState<unknown>(defaultValue ?? undefined)
  const value = propsValue ?? __value
  const triggerRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<InputRef>(null)
  const [searchValue, setSearchValue] = useState('')
  const popoverWidth = triggerRef.current?.offsetWidth || props.width || 320

  useEffect(() => {
    // 关闭的时候，清空搜索框的内容
    if (!remainingProps.open) {
      setSearchValue('')
    }
  }, [remainingProps.open])

  const onSelect = (_value?: unknown) => {
    setValue(_value)
    onChange?.(_value)
  }

  const filteredOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return options
    }
    return options.filter(item => {
      return item.label
        ?.toLocaleLowerCase()
        .includes(searchValue?.toLocaleLowerCase())
    })
  }, [options, searchValue])

  const searchPlaceHolder = useMemo(() => {
    return type === 'document' ? '搜索文档' : '搜索知识库'
  }, [type])

  return (
    <Popover
      arrow={false}
      trigger='click'
      overlayInnerStyle={{ padding: 0, borderRadius: 6, width: popoverWidth }}
      placement={placement}
      onOpenChange={vis => {
        props?.onOpenChange?.(vis)
      }}
      open
      content={
        disabled ? null : (
          <>
            <div className='p-4 nodrag nopan '>
              <Input
                prefix={<IconFont name='search' className='text-16px' />}
                className='mb-4px'
                placeholder={searchPlaceHolder}
                ref={inputRef}
                size='small'
                value={searchValue}
                onChange={e => {
                  setSearchValue(e.target.value)
                }}
              />
              <div className='nowheel max-h-256px overflow-y-auto'>
                {filteredOptions.map(option => {
                  return (
                    <Option
                      onPreview={handlePreviewClick}
                      key={option.value as Key}
                      label={option.label}
                      value={option.value}
                      icon={option.icon}
                      category={option.category}
                      iconColor={option.iconColor}
                      description={option.description}
                      count={option.count}
                      checked={(value as Array<unknown>)?.includes(
                        option.value,
                      )}
                      onClick={() => onSelect(option.value)}
                      type={option.type}
                    />
                  )
                })}
                {searchValue && filteredOptions.length === 0 && (
                  <>
                    <Empty />
                  </>
                )}
              </div>
              {extraRender}
            </div>
          </>
        )
      }
      {...remainingProps}
    >
      {triggerRender(value, triggerRef)}
    </Popover>
  )
}
