import type { ReactNode } from 'react'
import type { Version } from '@/features/pay'
import dataBaseShowCase from '@/assets/database/database_showcase.png'
import { PagePaidGuard } from '@/features/guard/PagePaidGuard.tsx'
import { isPrivateVersion } from '@/constants/common.ts'

interface WithVersionGuardProps {
  comp: ReactNode
  pageTitle?: string
  allowVersions: Version[]
  content?: ReactNode | string
}

export function withVersionGuard(params: WithVersionGuardProps) {
  const { comp, pageTitle, allowVersions, content } = params

  if (isPrivateVersion) return comp

  return (
    <div className='w-full h-full relative'>
      {pageTitle && (
        <div className='c-font text-20px font-500 adapt:pt-40 adapt:px-64px line-height-36px absolute'>
          {pageTitle}
        </div>
      )}
      <PagePaidGuard
        shouldValidatePeriod={false}
        allowVersions={allowVersions}
        failBackContent={
          <div className='w-full h-full flex'>
            <div className='flex flex-col items-center'>
              {/* 目前只有数据库的 showcase 需要展示，后期添加类型可以加一个映射处理 */}
              <img src={dataBaseShowCase} alt='' className='w-400px h-auto' />
              <p className='text-16px/16px c-#17171D'>
                {content || '团队版无法使用数据库，请升级到企业版'}
              </p>
            </div>
          </div>
        }
      >
        {comp}
      </PagePaidGuard>
    </div>
  )
}
