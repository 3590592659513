import { iconColors } from '@/constants/icon.ts'

const colorMap = new Map()

function getColor(name: string) {
  if (colorMap.get(name)) {
    return colorMap.get(name)
  } else {
    const mapSize = colorMap.size > 6 ? colorMap.size % 6 : colorMap.size
    const color = iconColors[mapSize]
    colorMap.set(name, color)
    return colorMap.get(name)
  }
}

export function PersonName({ name }: { name: string }) {
  return (
    <div className='flex gap-8px flex-nowrap flex-items-center'>
      <div
        className='color-#fff border-rd-50% flex flex-justify-center flex-items-center text-14px font-600 h-24px w-24px'
        style={{ background: getColor(name), flex: '0 0 24px' }}
      >
        {name?.[0]}
      </div>
      {name}
    </div>
  )
}
