import classNames from 'classnames'
import { isNumber, isString } from 'lodash-es'
import type { ReactNode } from 'react'

interface CountDataItem {
  title: string
  count?: number
  change?: number
}

interface AnalyzeCardProps {
  className?: string
  title: ReactNode
  details?: boolean
  data: CountDataItem[]
  deltaLabel?: string
  onDetailEnter?: () => void
}

export function AnalyzeCard(props: AnalyzeCardProps) {
  const { title, details = true, data, className, onDetailEnter } = props

  return (
    <div
      className={classNames(
        'h-160px min-w-468px p-24 bg-white rounded-12px',
        className,
      )}
    >
      <div className='mb-24 flex items-center'>
        {isString(title) ? (
          <div className='text-font text-16px font-semibold'>{title}</div>
        ) : (
          title
        )}
        {details && (
          <span
            className='ml-12 text-#9e9e9e cursor-pointer'
            onClick={onDetailEnter}
          >
            明细
          </span>
        )}
      </div>
      <div className='flex'>
        {data.map(item => {
          if (!isNumber(item.count)) {
            return (
              <div key={item.title} className='flex-1'>
                <div className='text-font'>{item.title}</div>
                <div className='text-font text-32px/32px font-medium'>-</div>
              </div>
            )
          }

          const count = item.count
          const countLabel =
            count >= 10000 ? (
              <>
                {(count / 10000).toFixed(1)}
                <span className='text-14px font-medium'>万</span>
              </>
            ) : (
              count.toLocaleString()
            )

          return (
            <div key={item.title} className='flex-1'>
              <div className='text-font'>{item.title}</div>
              <div className='text-24px font-medium my-6'>{countLabel}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
