import type { ModalProps } from 'antd'
import { Modal as AntdModal, Space } from 'antd'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { Button, IconFont } from '@/components'

const StyledModal = styled(AntdModal)`
  .ant-modal-header {
    margin-bottom: 0;
  }
`

type BaseModalProps = ModalProps & {
  extraFooter?: React.ReactNode
  footerClassName?: string
  hiddenFooter?: boolean
}

export function Modal(props: BaseModalProps) {
  const onCancel = (e: any) => {
    props.onCancel?.(e)
  }
  const onOk = (e: any) => {
    props.onOk?.(e)
  }
  const Footer = (
    <div
      className={classNames(
        'flex flex-items-center flex-justify-end p-16 border-t border-font_1 border-opacity-8',
        props.footerClassName,
      )}
    >
      {props.extraFooter}
      <Space>
        <Button onClick={onCancel} {...props.cancelButtonProps}>
          {props.cancelText || '取消'}
        </Button>
        <Button
          onClick={onOk}
          type={(props.okType as any) ?? 'primary'}
          {...props.okButtonProps}
        >
          {props.okText || '确定'}
        </Button>
      </Space>
    </div>
  )

  const header = (
    <div className='p-16px border-b border-solid border-font_1 border-opacity-8 line-height-16px'>
      {props.title}
    </div>
  )

  return (
    <StyledModal
      centered
      footer={props.hiddenFooter ? null : (props.footer ?? Footer)}
      {...props}
      wrapClassName={`keyu-modal ${props.wrapClassName ?? ''}`}
      styles={{
        body: {
          padding: 16,
          ...(props.styles?.body ?? props.bodyStyle ?? {}),
        },
        mask: {
          ...(props.styles?.mask ?? props.maskStyle ?? {}),
          backgroundColor: 'transparent',
          backdropFilter: 'blur(16px)',
        },
        content: {
          ...props.styles?.content,
        },
      }}
      closeIcon={<IconFont name='guanbi' />}
      title={typeof props.title === 'string' ? header : props.title}
    >
      {props.children}
    </StyledModal>
  )
}
