import { Form } from 'antd'
import { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { sample } from 'lodash-es'
import type { ButtonProps } from 'antd/es/button/button'
import { Modal, IconFont, FormItem, Button } from '@/components'
import { colors } from '@/constants/theme.ts'
import { fontIcons } from '@/constants/icon.ts'
import { DatasetSelect, type DatasetSelectProps } from './DatasetSelect'

// interface DataStoreBaseInfo {
//   partition_name: string
//   partition_describe: string
//   partition_icon: string
//   partition_icon_color: string
//   partition_model: string
// }

interface CopyDocumentsModalProps {
  open: boolean
  onCancel?: () => void
  okButtonProps?: ButtonProps
  title: string
  datasetList: DatasetSelectProps['datasetList']
  onFinish?: (data: { target_partition_id: number }) => void
}

const StyledFormItem = styled(FormItem)`
  .keyu-form-item-label {
    font-weight: 500;
  }
`

export function CopyDocumentsModal(props: CopyDocumentsModalProps) {
  const { open, onCancel, datasetList } = props
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const defaultValue = useMemo(() => {
    return Object.assign(
      {},
      {
        partition_icon: sample(fontIcons)!,
        partition_icon_color: sample(colors)!,
      },
    )
  }, [])

  const onFinish = () => {
    form.validateFields().then(async res => {
      const data = Object.assign({}, defaultValue, res)
      try {
        setLoading(true)
        await props.onFinish?.(data)
      } finally {
        setLoading(false)
      }
    })
  }

  return (
    <Modal
      title='复制到知识库'
      open={open}
      onCancel={onCancel}
      width={480}
      footer={null}
      closeIcon={<IconFont name='guanbi' className='color-#17171D text-16px' />}
    >
      <Form
        form={form}
        initialValues={defaultValue}
        layout='vertical'
        requiredMark={false}
        className='mt-2 px-16'
      >
        <StyledFormItem
          label='选择复制到'
          name='target_partition_id'
          rules={[{ required: true, message: '请选择需要复制的知识库' }]}
        >
          {/* TODO: 过滤自身 */}
          <DatasetSelect datasetList={datasetList} />
        </StyledFormItem>
        <p className='c-#8D8D99 text-12px/14px font-400 mt-[-12px] flex items-center'>
          <IconFont name='xiaoshizhi' className='text-12px mr-4px' />
          <span>暂不支持不同学习方式（Embedding）知识库间移动</span>
        </p>
        <div className='flex items-center justify-end gap-12 mb-8'>
          <Button type='primary' onClick={onFinish} loading={loading}>
            复制
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
