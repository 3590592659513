import type { ITooltipRenderProps } from '@visactor/react-vchart'

export const TOOLTIP_STYLE = {
  panel: {
    border: {
      width: 0,
      radius: 8,
    },
    shadow: {
      x: 0,
      y: 8,
      blur: 24,
      spread: 0,
      color: 'rgba(0,0,0,0.2)',
    },
  },
}

interface TooltipContentProps extends Partial<ITooltipRenderProps> {
  xField: string
  yField: string
  yFieldLabel?: string
  chartId?: string | number
  detail?:
    | false
    | {
        onClick?: (
          xFieldValue: string,
          flowId?: string,
          chartId?: string | number,
        ) => void
      }
}

export function TooltipContent({
  xField,
  yField,
  yFieldLabel,
  detail,
  actualTooltip,
  chartId,
}: TooltipContentProps) {
  const xFieldValue = actualTooltip?.content?.[0]?.datum[xField] as string
  const yFieldValue = actualTooltip?.content?.[0]?.datum[yField]

  return (
    <div className='p-8'>
      <div className='flex justify-between text-12px'>
        <span className='text-font_1'>{xFieldValue}</span>
        {detail ? (
          <span
            className='text-#7b67ee cursor-pointer ml-12'
            onClick={event => {
              event.stopPropagation()
              detail.onClick?.(
                xFieldValue,
                actualTooltip?.content?.[0]?.datum?.flow_id,
                chartId,
              )
            }}
          >
            日志
          </span>
        ) : null}
      </div>
      <div className='flex justify-between gap-26 text-14px text-font mt-14'>
        <span>{yFieldLabel}</span>
        <span>{yFieldValue}</span>
      </div>
    </div>
  )
}
