import { memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import type { IRow, IHead } from '../type'
import { IconFont } from '@/components'
import { Cell, CheckCell } from './body-cell'
import { useResizeTable } from './context'

interface RowProps {
  no: number
  heads: IHead[]
  flex: string[]
  row: IRow
}

export const Row = memo(({ no, heads, flex, row }: RowProps) => {
  const { observeRow, observeCell } = useResizeTable()
  const [select, setSelect] = useState(false)
  const [cellSelect, setCellSelect] = useState(false)

  useEffect(() => {
    return observeRow(row.key, setSelect)
  }, [row.key])

  useEffect(() => {
    return observeCell(row.key, (_select, id) => {
      setCellSelect(id.startsWith(row.key))
    })
  }, [row.key])

  const className = useMemo(() => {
    return classNames('resize-table-row', {
      'resize-table-row-selected': select,
      'resize-table-row-child-selected': cellSelect,
    })
  }, [select, cellSelect])

  return (
    <div className={className}>
      {heads.map((each, index) => {
        const id = `${row.key}-${each.key}`

        if (each.isRowSelect) {
          return (
            <CheckCell
              key={id}
              no={no}
              select={select}
              row={row}
              flex={flex[index]}
            />
          )
        }

        return <Cell key={id} head={each} row={row} flex={flex[index]} />
      })}
    </div>
  )
})

interface AddRowProps {
  onAddRow?: () => void
}

export const AddRow = memo((props: AddRowProps) => {
  return (
    <div className='resize-table-row' onClick={props.onAddRow}>
      <div
        className='resize-table-cell resize-table-cell-sticky resize-table-cell-add'
        style={{ flex: '0 0 50px', height: 40 }}
      >
        <IconFont className='text-16px' name='add' />
      </div>
      <div className='resize-table-cell' style={{ flex: '1 1 auto' }} />
    </div>
  )
})
