import { useState } from 'react'
import { updateTableInfo } from '@/apis/database/api.ts'

export function useTableRename() {
  const [renamingTableId, setRenamingTableId] = useState<string | null>(null)

  const onRename = (id: string, base_id: string, newName: string) => {
    return updateTableInfo({
      table_id: id,
      base_id,
      table_name: newName,
      title: newName,
    })
  }

  return {
    onRename,
    renamingTableId,
    setRenamingTableId,
  }
}
