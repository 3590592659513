import { Divider, Form } from 'antd'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useAuth } from '@bty/react-auth'
import type { AuthAppDingTalk } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'
import type { AgentGoPublishConfProps } from '@/features/agent/components/AgentGoPublishConf'
import { AgentGoPublishConf } from '@/features/agent/components/AgentGoPublishConf'
import { CopyBox } from '@/features/agent/components/CopyBox.tsx'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { useQueryAgentGoApp } from '@/features/agent/hooks/useQueryAgentGoApp.ts'
import { Checkbox, Input } from '@/components'
import { getDingTalkDocs } from '@/features/agent/AgentGo/common.tsx'
import { LinkText } from '@/features/agent/components/LinkText.tsx'
import { enableApp } from '@/apis/agentgo.ts'
import renderLabel from '@/features/agent/components/renderLabel.tsx'
import { AgentGoFormItem } from '@/features/agent/components/AgentGoFormItem.tsx'
import { DingTalkMessageReceivingMode } from '../components/DingTalkMessageReceivingMode'
import { CheckContainer } from '../components/CheckContainer'
import { DingTalkFormTemplate } from '../components/DingTalkFormTemplate'

interface DingTalkBindRequest {
  AppKey: string
  AppSecret: string
  auth_app_name: string
  auth_app_source: AuthAppDingTalk['auth_app_source']
}

interface AgentGoDingTalkProps {
  mode?: string
  modeIcon?: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

const [http, stream] = PUBLISH_MODE.dingtalk.split('|')

export default function AgentGoDingTalk({
  mode,
  modeIcon,
  onBindSuccess,
  onUnBindSuccess,
}: AgentGoDingTalkProps) {
  const auth = useAuth()

  const [form] = Form.useForm<DingTalkBindRequest>()

  const { applicationInfo } = useAgentEdit()

  const { runQueryApp, app, setApp, loading } =
    useQueryAgentGoApp<AuthAppDingTalk>({
      agentId: applicationInfo?.flowId,
      source: http as AuthAppDingTalk['auth_app_source'],
      onQuerySuccess: data => {
        const { auth_app_name, auth_app_config } = data
        form.setFieldsValue({
          auth_app_source: data.auth_app_source,
          auth_app_name,
          ...auth_app_config,
        })
      },
    })

  const handleComplete: NonNullable<
    AgentGoPublishConfProps['onComplete']
  > = appId => {
    onBindSuccess?.()
    if (appId && applicationInfo) {
      runQueryApp(applicationInfo.flowId)
    }
  }

  const steps = [
    {
      title: '填写应用信息',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-font'>
            登录{' '}
            <LinkText href='https://open-dev.dingtalk.com/'>
              钉钉开放平台
            </LinkText>{' '}
            获取信息
          </div>
          <p className='mt-8 text-12px text-font_2 font-normal mb-24'>
            注意！需要使用<span className='text-font'>企业管理员</span>
            的钉钉账号扫码登录
          </p>
        </>
      ),
    },
    {
      title: '完成消息接收配置',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium'>
            为应用添加机器人能力，完成消息配置并发布
          </div>
          <p className='mt-8 text-12px text-font_2 font-normal mb-24'>
            在钉钉应用开发-应用能力中可找到机器人
          </p>
        </>
      ),
      confirminfo: {
        fieldName: 'confirmOnMessage',
        title: '已完成「机器人配置」并发布',
        description: '未完成「机器人配置」将无法正常使用',
      },
    },
    {
      title: '发布钉钉应用',
      disabled: true,
      content: (
        <div className='text-16px font-medium text-font mb-24'>
          在{' '}
          <LinkText href='https://open-dev.dingtalk.com/'>
            钉钉开放平台
          </LinkText>{' '}
          版本管理中发布当前应用
        </div>
      ),
      confirminfo: {
        fieldName: 'confirmBeforeComplete',
        title: '钉钉应用已发布',
        description: '未发布的应用将无法正常使用',
      },
    },
  ]

  const handleValuesChange: NonNullable<
    AgentGoPublishConfProps['onValuesChange']
  > = changedValues => {
    if ('is_enable' in changedValues) {
      setApp({ ...app, is_enable: changedValues.is_enable })
      enableApp(app.auth_app_id!, changedValues.is_enable)
    }
  }

  const handleUnBindSuccess = async () => {
    setApp({})
    onUnBindSuccess?.()
  }

  const appKey = Form.useWatch('AppKey', form)

  const url = useMemo(
    () =>
      `${
        import.meta.env.VITE_AI_API_BASE_URL
      }/v1/webhook/agent/dingtalk_orgapp_robot/${appKey}/receive_message`,
    [appKey],
  )

  const authAppSource = Form.useWatch('auth_app_source', form)

  const isStream = authAppSource === stream

  const canUseDingTalkFormTemplate = auth.access.grayscale(
    'DINGTALK_CARD_TEMPLATE',
  )

  return loading ? null : (
    <AgentGoPublishConf
      form={form}
      mode={mode}
      app={app}
      steps={steps}
      guides={getDingTalkDocs(isStream)}
      serviceAvatar={modeIcon}
      completeDeps={['confirmBeforeComplete']}
      addonComplete={
        canUseDingTalkFormTemplate ? DingTalkFormTemplate : undefined
      }
      onComplete={handleComplete}
      onValuesChange={handleValuesChange}
      onUnBindSuccess={handleUnBindSuccess}
    >
      {step => {
        const notStep0 = step !== 0
        const step1 = step === 1

        return (
          <>
            {steps[step].content}
            <AgentGoFormItem
              label={renderLabel('Client ID', true)}
              name='AppKey'
              hidden={notStep0}
              normalize={(value: string) => value?.trim()}
              rules={[{ required: true, message: 'Client ID 不能为空' }]}
            >
              <Input
                placeholder='在钉钉应用-凭证与基础信息中获取参数'
                spellCheck={false}
              />
            </AgentGoFormItem>
            <AgentGoFormItem
              label={renderLabel('Client Secret', true)}
              name='AppSecret'
              hidden={notStep0}
              normalize={(value: string) => value?.trim()}
              rules={[{ required: true, message: 'Client Secret 不能为空' }]}
            >
              <Input
                placeholder='在钉钉应用-凭证与基础信息中获取参数'
                spellCheck={false}
              />
            </AgentGoFormItem>
            <AgentGoFormItem
              label={renderLabel('应用名称', true)}
              name='auth_app_name'
              hidden={notStep0}
              normalize={(value: string) => value?.trim()}
              rules={[{ required: true, message: '应用名称不能为空' }]}
            >
              <Input placeholder='在钉钉应用-凭证与基础信息中获取参数' />
            </AgentGoFormItem>

            <AgentGoFormItem
              label={renderLabel('消息接收模式', false)}
              name='auth_app_source'
              initialValue={http}
              hidden={!step1}
              required
            >
              <DingTalkMessageReceivingMode
                onChange={value => setApp({ ...app, auth_app_source: value })}
              />
            </AgentGoFormItem>
            <AgentGoFormItem
              label={renderLabel('消息接收地址', false)}
              hidden={!step1 || isStream}
            >
              <CopyBox value={url} />
            </AgentGoFormItem>
            {step1 && (
              <div className='text-14px/16px font-medium mb-8'>发布机器人</div>
            )}

            <div
              className={classNames(
                'bg-bg_3 bg-op-6 rounded-8px p-12',
                steps[step]?.confirminfo ? '' : 'hidden',
              )}
            >
              <Form.Item
                name={steps[step]?.confirminfo?.fieldName}
                valuePropName='checked'
                preserve={true}
                hidden={!steps[step]?.confirminfo}
                rules={[
                  {
                    validator: (_, value) => {
                      if (steps[step]?.confirminfo) {
                        return value ? Promise.resolve() : Promise.reject()
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
                noStyle
              >
                <Checkbox className='!mb-4'>
                  <span className='text-font text-14px/20px'>
                    {steps[step].confirminfo?.title}
                  </span>
                </Checkbox>
              </Form.Item>
              <p className='text-font_2 text-12px/20px ml-24'>
                {steps[step].confirminfo?.description}
              </p>
            </div>

            {step1 && isStream && (
              <div className='text-14px/16px font-medium mb-8'>开通权限</div>
            )}
            <AgentGoFormItem
              name='addEvent'
              rules={
                step1 && isStream
                  ? [
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('使用前必须确保配置事件订阅'),
                              ),
                      },
                    ]
                  : undefined
              }
              hidden={!step1 || !isStream}
            >
              <CheckContainer
                title='已确认开通以下权限'
                label={[
                  '- AI 卡片流式更新权限',
                  '- 互动卡片实例写权限',
                  '- 企业内机器人发送消息权限',
                ]}
              />
            </AgentGoFormItem>

            {step1 && isStream && (
              <>
                <Divider />
                <div className='text-16px font-medium text-font'>
                  去{' '}
                  <LinkText href='https://open-dev.dingtalk.com/fe/card'>
                    卡片平台
                  </LinkText>{' '}
                  创建 AI 卡片并关联应用
                </div>
                <p className='mt-8 text-12px text-font_2 font-normal mb-24'>
                  在钉钉开放平台-开放能力中可找到卡片平台
                </p>
              </>
            )}
            <AgentGoFormItem
              label={renderLabel('卡片模版 ID')}
              name='AICardTemplateID'
              hidden={!step1 || !isStream}
              rules={[
                {
                  required: step1 && isStream,
                  message: '卡片模版 ID 不能为空',
                },
              ]}
            >
              <Input placeholder='在钉钉开放平台-卡片平台中获取参数' />
            </AgentGoFormItem>
          </>
        )
      }}
    </AgentGoPublishConf>
  )
}
