import { useRequest } from 'ahooks'
import { Divider, Form, message, Switch, Tooltip } from 'antd'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useBeforeUnload } from 'react-router-dom'
import { get, isEqual, keys, pick } from 'lodash-es'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import cn from 'classnames'
import type { AgentRuleConfig } from '@bty/chat-types'
import { fetchAgentRule, updateAgentRule } from '@/apis/agent.ts'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { Button, IconFont, TextArea } from '@/components'
import { useLLMStore } from '@/store/llm.ts'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar.ts'
import { ModelSettings } from '../components/ModelSettings'
import {
  AGENT_DESIGN_ROLE_FULLSCREEN,
  SYSTEM_PROMPT_NAME_PATH,
} from '../constant/base'
import { SystemPromptOptimize } from '../components/SystemPromptOptimize'
import { TextEditor } from '@/features/editor/TextEditor'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import type { PromptStructItem } from '@/apis/prompt.ts'
import { PromptType } from '@/apis/prompt.ts'
import { PromptStructEditor } from '@/features/prompt/components/PromptStructEditor/PromptStructEditor.tsx'
import { usePromptTypeChange } from '@/features/prompt/hooks/usePromptTypeChange.ts'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { EditorTypeSelector } from '@/features/prompt/components/PromptStructEditor/EditorTypeSelector.tsx'
import type { RuleFormValues } from '@/features/agent/types/rule.ts'
import { PromptAICreateModal } from '@/features/prompt/components/PromptAICreateModal'
import { getPromptFromStructPrompt } from '@/features/prompt/utils/prompt'
import { PromptSyncTransformer } from '@/features/prompt/components/PromptStructEditor/PromptSyncTransformer'
import { RelatedQuestionControl } from './RelatedQuestionControl'
import { FormListQuestionGuide } from './FormListQuestionGuide'
import { FormListVariable } from './FormListVariable'
import { WelcomeSwitchControl } from './WelcomeSwitchControl'
import { ForcedInvokeControl } from './ForcedInvokeControl'
import { FormTaskList } from './FormTaskList'
import { FormQuickInputList } from './FormQuickInputList'
import { PromptMermaidWithFormItem } from './PromptMermaidWithFormItem'

export interface RuleConfigProps {
  className?: string
  flowInfo: ApplicationBodyType
  onRuleChange: (
    rule: Pick<AgentRuleConfig, 'welcome'>,
    onlyGetRule: boolean,
  ) => void
  onAppRefresh?: VoidFunction
}

export interface RuleConfigInstance {
  changeRule: (rule: Partial<AgentRuleConfig>, autoSave: boolean) => void
  refreshRuleConfig: () => void
  onSave: () => void
  hasSaved: boolean
  getRule: () => AgentRuleConfig
}

const TaskTips = memo(() => {
  return (
    <div className='text-[12px] leading-[18px] flex gap-[4px]'>
      <div>让Agent可以执行定时任务</div>
      <a
        className='underline text-primary'
        target='_blank'
        href='https://ai-docs.betteryeah.com/%E6%90%AD%E5%BB%BAAgent/%E4%BB%BB%E5%8A%A1.html'
        rel='noreferrer'
      >
        查看详情
      </a>
    </div>
  )
})

export const RuleConfig = forwardRef<RuleConfigInstance, RuleConfigProps>(
  ({ flowInfo, onRuleChange, className }, ref) => {
    const diffOpen = useAgentDiffStore(s => s.open)
    const [ruleForm] = Form.useForm<RuleFormValues>()

    const taskOpen = Form.useWatch('preset_tasks_enabled', ruleForm)

    const [hasSaved, setHasSaved] = useState(true)
    const [hasRawPrompt, setHasRawPrompt] = useState(true)

    const initLLMData = useLLMStore(state => state.initLLMData)

    const { skillConfigContext } = useAgentEdit()

    const skills = useMemo(() => {
      const { flowList = [], toolList = [] } = skillConfigContext
      const allSKills = [...flowList, ...toolList]
      return allSKills.map(item => ({
        name: item.name,
        description: item.description,
      }))
    }, [skillConfigContext])

    const {
      data: rule,
      mutate: setRule,
      refresh: refreshRuleConfig,
    } = useRequest(fetchAgentRule, {
      defaultParams: [flowInfo.flowId, flowInfo.versionId],
    })

    const dataset = (
      flowInfo.config as Exclude<ApplicationBodyType['config'], string>
    ).dataset

    const isDatasetForcedQuery = Array.isArray(dataset)
      ? dataset.some(el => el.query_mode?.mode === 'FORCE')
      : false

    const onlyGetRuleRef = useRef(true)

    useEffect(() => {
      if (!rule) {
        return
      }
      const clonedRule: AgentRuleConfig = JSON.parse(JSON.stringify(rule))
      const {
        model,
        channel,
        welcome_enabled,
        question_guide,
        related_questions_obj,
        enforce_execution,
      } = clonedRule
      const formValues = Object.assign(clonedRule, {
        model: { model, channel },
        welcome_enabled: welcome_enabled === undefined ? true : welcome_enabled,
        question_guide: question_guide?.length ? question_guide : [''],
        related_questions_obj: related_questions_obj ?? {
          related_questions_type: 'AI',
        },
        enforce_execution: enforce_execution ?? {
          type: isDatasetForcedQuery ? 'DATASET' : 'CLOSE',
          tool_id: '',
        },
      })
      setHasRawPrompt(!!get(formValues, SYSTEM_PROMPT_NAME_PATH))
      ruleForm.setFieldsValue(formValues)
      onRuleChange?.(clonedRule, onlyGetRuleRef.current)
      onlyGetRuleRef.current = true
    }, [rule, isDatasetForcedQuery])

    const { promptTypeChangeEffect } = usePromptTypeChange(
      ruleForm,
      'structPrompt',
      SYSTEM_PROMPT_NAME_PATH,
      'Agent',
    )

    const [updating, setUpdating] = useState(false)

    useEffect(() => {
      initLLMData()
    }, [])

    const handleValuesChange = useCallback(() => {
      setHasSaved(false)
      setHasRawPrompt(!!ruleForm.getFieldValue(SYSTEM_PROMPT_NAME_PATH))
    }, [])

    const promptTypeValue = Form.useWatch('promptType', ruleForm)

    const getPrompt = useCallback(() => {
      return ruleForm.getFieldValue(SYSTEM_PROMPT_NAME_PATH)
    }, [])

    const getPromptStruct = useCallback(() => {
      return getPromptFromStructPrompt(
        ruleForm.getFieldValue('structPrompt') ?? [],
      )
    }, [])

    const getPromptType = useCallback(() => {
      return ruleForm.getFieldValue('promptType')
    }, [])

    const handlePromptChange = useCallback((newPrompt: string) => {
      setHasSaved(false)
      return ruleForm.setFieldValue(SYSTEM_PROMPT_NAME_PATH, newPrompt)
    }, [])

    const getPromptWithStruct = useCallback(() => {
      if (ruleForm.getFieldValue('promptType') === PromptType.STRUCT) {
        return getPromptFromStructPrompt(
          ruleForm.getFieldValue('structPrompt') ?? [],
        )
      }
      return ruleForm.getFieldValue(SYSTEM_PROMPT_NAME_PATH)
    }, [])

    const handleUseAiStruct = useCallback((struct: PromptStructItem[]) => {
      setHasSaved(false)
      if (ruleForm.getFieldValue('promptType') === PromptType.STRUCT) {
        ruleForm.setFieldValue('structPrompt', struct)
      } else {
        ruleForm.setFieldValue(
          SYSTEM_PROMPT_NAME_PATH,
          getPromptFromStructPrompt(struct),
        )
      }
    }, [])

    const onSave = async () => {
      try {
        // ----需求：对于没有填写key和value的agent_variables的要帮用户删除掉
        const values = ruleForm.getFieldsValue(true) as RuleFormValues
        const question_guide = values.question_guide?.filter(item => !!item)
        const agent_variables = values.agent_variables?.filter(
          item => !!item.label || !!item.variable_name,
        )

        ruleForm.setFieldsValue({
          agent_variables,
          question_guide: !question_guide?.length ? [''] : question_guide,
        })

        const rule = await ruleForm.validateFields()
        rule.question_guide = question_guide ?? []
        rule.agent_variables =
          agent_variables?.map(item => ({
            ...item,
            type: 'single_text',
            placeholder: item.label,
          })) ?? []

        setUpdating(true)

        const newRuleValue = {
          ...values,
          ...rule,
          ...values.model,
          messages: [
            {
              role: 'system' as const,
              content: rule.messages?.[0]?.content ?? '',
            },
          ],
          related_questions_enabled:
            rule.related_questions_obj?.related_questions_type !== 'CLOSE',
        }
        await updateAgentRule(newRuleValue, {
          flow_id: flowInfo.flowId,
          version_id: flowInfo.versionId,
        })
        onlyGetRuleRef.current = false
        setRule(newRuleValue)
        message.success('保存成功')
        setUpdating(false)
        setHasSaved(true)
        return true
      } catch (e) {
        console.log('rule config save error: ', e)
        setUpdating(false)
        return false
      }
    }

    const changeRule = useCallback(
      (rule: Partial<AgentRuleConfig>, autoSave = false) => {
        const oldValue = ruleForm.getFieldsValue(true)

        const { model, channel, ...rest } = rule

        const newValue = Object.assign(rest, {
          model: { model, channel },
        })

        if (!isEqual(newValue, pick(oldValue, keys(newValue)))) {
          ruleForm.setFieldsValue(newValue)
          handleValuesChange()
          if (autoSave) {
            onSave()
          }
        }
      },
      [handleValuesChange, onSave],
    )

    const getRule = useCallback(() => {
      const { model, ...rest } = ruleForm.getFieldsValue(true)

      return {
        channel: model.channel,
        model: model.model,
        ...rest,
      }
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        refreshRuleConfig,
        onSave,
        hasSaved,
        changeRule,
        getRule,
      }),
      [hasSaved, onSave],
    )

    useBeforeUnload(
      e => {
        if (!hasSaved) {
          e.preventDefault()
          e.returnValue = ''
        }
      },
      { capture: true },
    )

    const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

    const scrollToBottom = useCallback(() => {
      setTimeout(() => {
        const viewport = scrollRef.current?.osInstance()?.elements().viewport
        viewport?.scroll({
          top: viewport.scrollHeight + 200,
          behavior: 'smooth',
        })
      })
    }, [])

    const containerRef = useRef<HTMLDivElement>(null)

    const getContainer = useCallback(() => {
      return containerRef.current ?? document.body
    }, [])

    const [fullscreen, setFullscreen] = useState(
      () => localStorage.getItem(AGENT_DESIGN_ROLE_FULLSCREEN) === '1',
    )

    // useEffect(() => {
    //   flowInfo.flowId &&
    //     modal.show({
    //       flowId: flowInfo.flowId,
    //       versionId: flowInfo.versionId,
    //     })
    // }, [flowInfo])

    return (
      <>
        <Form
          className={cn('h-full flex-col flex', className)}
          form={ruleForm}
          colon={false}
          layout='vertical'
          requiredMark={false}
          onValuesChange={handleValuesChange}
        >
          <OverlayScrollbarsComponent
            ref={scrollRef}
            className='flex-1 overflow-y-auto p-24 pt-12 pb-0'
            element='div'
            options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
            defer
          >
            <div ref={containerRef} className='relative'>
              <h2 className='flex justify-between items-center text-16px font-medium text-font h-36px mb-20'>
                规则
                <ModelSettings disabled={diffOpen} />
              </h2>
              <div className='bg-bg_3 bg-op-6 rounded-8px mb-20 px-16 pb-16'>
                <div className='flex items-center h-48px'>
                  <span className='text-14px text-font font-medium'>
                    角色设定
                  </span>
                  {/* <RequiredMark /> */}
                  <Form.Item noStyle name='promptType'>
                    <EditorTypeSelector
                      className='ml-8px'
                      onChange={promptTypeChangeEffect}
                    />
                  </Form.Item>
                  {/* <RequiredMark /> */}
                  <div className='ml-auto flex items-center'>
                    <PromptSyncTransformer
                      className='mr-8px'
                      promptType={promptTypeValue}
                      getPromptType={getPromptType}
                      getPromptText={getPrompt}
                      getPromptStructText={getPromptStruct}
                      onPromptTypeChange={promptTypeChangeEffect}
                    />
                    <PromptMermaidWithFormItem getContainer={getContainer} />
                    <Divider className='mx-4px' type='vertical' />
                    <PromptAICreateModal
                      getPrompt={getPromptWithStruct}
                      onConfirm={handleUseAiStruct}
                    />
                  </div>
                </div>
                <Form.Item<
                  Pick<RuleFormValues, 'agent_variables' | 'promptType'>
                >
                  shouldUpdate={(prev, next) =>
                    prev.agent_variables !== next.agent_variables ||
                    prev.promptType !== next.promptType
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const promptType = getFieldValue('promptType')
                    const variablesField =
                      getFieldValue('agent_variables') ?? []
                    const variables = variablesField
                      .filter((item: any) => !!item.variable_name)
                      .map((item: any) => ({
                        label: item.variable_name,
                        type: 'input',
                      }))
                    return (
                      <>
                        <Form.Item
                          noStyle
                          name='structPrompt'
                          hidden={promptType !== PromptType.STRUCT}
                        >
                          <PromptStructEditor
                            variables={variables}
                            skillSyncEnable={true}
                            skills={skills}
                            onFullscreenChange={setFullscreen}
                          />
                        </Form.Item>
                        <div className='relative'>
                          {promptType !== PromptType.STRUCT && (
                            <div className='absolute w-24px right-37 z-2 top-9 flex justify-end'>
                              <SystemPromptOptimize
                                disabled={!hasRawPrompt}
                                getPrompt={getPrompt}
                                onChange={handlePromptChange}
                              />
                            </div>
                          )}
                          <Form.Item
                            className='mb-0'
                            name={SYSTEM_PROMPT_NAME_PATH}
                            // rules={[
                            //   {
                            //     required: promptType === PromptType.RAW,
                            //     message: '角色设定不能为空',
                            //   },
                            // ]}
                            hidden={promptType === PromptType.STRUCT}
                          >
                            <TextEditor
                              className={cn(
                                'text-14px! bg-white! b-#e1e1e5!',
                                '[&_.text-editor-content]:line-height-[1.5]! [&_textarea]:line-height-[1.5]!',
                                '[&_.text-editor-content]:pr-54px! [&_textarea]:pr-54px!',
                              )}
                              // contentClassName='duration-300'
                              height={
                                fullscreen ? 'calc(100vh - 318px)' : '400px'
                              }
                              variables={variables}
                              anchor='right'
                              placeholder={
                                <div>
                                  <span>你是一个广告文案撰写专家。点击</span>
                                  <span className='py-1 px-4 c-font_1/60 b-1 mx-4 b-line/60 bg-bg_3/4 rd-4px'>
                                    Shift+Tab
                                  </span>
                                  <span>快速生成角色设定。</span>
                                </div>
                              }
                              showFullscreen
                              fullscreen={fullscreen}
                              onFullscreenChange={fullscreen => {
                                if (promptType !== PromptType.STRUCT) {
                                  localStorage.setItem(
                                    AGENT_DESIGN_ROLE_FULLSCREEN,
                                    fullscreen ? '1' : '0',
                                  )
                                  setFullscreen(fullscreen)
                                }
                              }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )
                  }}
                </Form.Item>
              </div>
              {!fullscreen && (
                <>
                  <div className='bg-bg_3 bg-op-6 rounded-8px mb-20 px-16'>
                    <Form.Item name='welcome_enabled' noStyle>
                      <WelcomeSwitchControl />
                    </Form.Item>
                    <Form.Item<Pick<RuleFormValues, 'welcome_enabled'>>
                      shouldUpdate={(prevValue, nextValue) =>
                        prevValue.welcome_enabled !== nextValue.welcome_enabled
                      }
                      noStyle
                    >
                      {form =>
                        form.getFieldValue('welcome_enabled') ? (
                          <Form.Item name='welcome' noStyle>
                            <TextArea
                              className='!resize-none py-6 !bg-white hover:border-primary hover:border-op-100 not-focus:border-line not-focus:border-op-80'
                              autoSize={{ minRows: 2, maxRows: 17 }}
                              placeholder='您好，请问有什么可以帮助您？'
                            />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                    <FormListQuestionGuide />
                  </div>
                  <div className='bg-bg_3 bg-op-6 rounded-8px mb-20 px-16'>
                    <Form.Item name='related_questions_obj' noStyle>
                      <RelatedQuestionControl
                        agent={{
                          flowId: flowInfo.flowId,
                          versionId: flowInfo.versionId,
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='bg-bg_3 bg-op-6 rounded-8px px-16 mb-20 overflow-hidden'>
                    <FormListVariable afterAdd={scrollToBottom} />
                  </div>
                  <div className='bg-bg_3 bg-op-6 rounded-8px px-16 mb-20'>
                    <Form.Item name='enforce_execution' noStyle>
                      <ForcedInvokeControl
                        agent={{
                          flowId: flowInfo.flowId,
                          versionId: flowInfo.versionId,
                          workspaceId: flowInfo.workspaceId,
                        }}
                        filterMenuOption={key => {
                          if (key === 'CLOSE') {
                            return true
                          }
                          const config = flowInfo.config as Exclude<
                            ApplicationBodyType['config'],
                            string
                          >
                          return !!(key === 'FLOW'
                            ? config.flows?.length
                            : config.dataset?.length)
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='bg-bg_3 bg-op-6 rounded-8px mb-20'>
                    <div className='flex items-center h-48px px-16'>
                      <span className='mr-auto flex flex-center'>
                        <span className='text-14px text-font font-medium'>
                          任务
                        </span>
                        <Tooltip placement='top' title={<TaskTips />}>
                          <IconFont
                            className='text-font_1 text-14px text-opacity-40 ml-4 cursor-pointer'
                            name='jieshishuimeng'
                          />
                        </Tooltip>
                      </span>
                      <Form.Item name='preset_tasks_enabled' noStyle>
                        <Switch size='small' />
                      </Form.Item>
                    </div>

                    {taskOpen && (
                      <div className='px-16 overflow-hidden'>
                        <FormTaskList
                          agentId={flowInfo.flowId}
                          versionId={flowInfo.versionId}
                        />
                      </div>
                    )}
                  </div>
                  <div className='bg-bg_3 bg-op-6 rounded-8px mb-120 pr-16 overflow-hidden'>
                    <FormQuickInputList
                      form={ruleForm}
                      flowId={flowInfo.flowId}
                      versionId={flowInfo.versionId}
                      onEdit={() => setHasSaved(false)}
                      onSort={() => setHasSaved(false)}
                    />
                  </div>
                </>
              )}
            </div>
          </OverlayScrollbarsComponent>
          <div className='shrink-0 p-24 pt-12'>
            <Form.Item<Pick<RuleFormValues, 'messages' | 'enforce_execution'>>
              shouldUpdate={(prevValue, nextValue) =>
                get(prevValue, SYSTEM_PROMPT_NAME_PATH) !==
                  get(nextValue, SYSTEM_PROMPT_NAME_PATH) ||
                prevValue.enforce_execution !== nextValue.enforce_execution
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const forcedInvoke = getFieldValue('enforce_execution')
                const disabled =
                  hasSaved ||
                  // !getFieldValue(SYSTEM_PROMPT_NAME_PATH) ||
                  (forcedInvoke?.type === 'FLOW' && !forcedInvoke?.tool_id)

                return (
                  <Button
                    className='[&.ant-btn-primary]:h-44px w-full'
                    size='middle'
                    type='primary'
                    loading={updating}
                    disabled={disabled}
                    onClick={onSave}
                  >
                    {hasSaved ? '已保存' : '保存生效'}
                  </Button>
                )
              }}
            </Form.Item>
          </div>
        </Form>
      </>
    )
  },
)
