interface Props {
  onClick: () => void
}

export function PasteButton(props: Props) {
  const { onClick } = props

  return (
    <div
      className='relative text-12px bg-#7F69FF text-white text-nowrap px-8px py-6px line-height-12px border-rd-14px b-2 b-#f5f4f6'
      onClick={onClick}
    >
      粘贴
      <span className='absolute left-50% top-0 font-350 text-12px c-#8D8D99 flex flex-nowrap gap-4px items-center translate-x-[-50%] translate-y-[calc(-100%-8px)]'>
        点击
        <span className='font-500 p-1px line-height-14px bg-#E2E3EA b-rd-2px'>
          Esc
        </span>
        取消复制
      </span>
    </div>
  )
}
