import React, { memo, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { uniqueId } from 'lodash-es'
import { useSize } from 'ahooks'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { Table as AntdTable, type TableProps } from 'antd'
import { IconFont } from '../icon'
import { Select } from '../select'
import { RangePickerWrapper } from '../rangePicker'
import { Input } from '../input'
import { useMonitorModalStore } from '@/store/monitorDrawerStore'

type OnChange = NonNullable<TableProps<any>['onChange']>
type GetSingle<T> = T extends (infer U)[] ? U : never
type Sorts = GetSingle<Parameters<OnChange>[2]>

const Table = styled(AntdTable)`
  .ant-table-thead {
    .ant-table-cell {
      border: none;
      padding: 15px 12px;
      &::before {
        display: none;
      }
      font-size: 14px;
      color: rgba(141, 141, 153, 1);
      font-weight: 600;
      &:first-of-type {
        border-radius: 8px 0px 0px 8px;
      }
      &:last-of-type {
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
  .ant-table-tbody {
    tr td {
      border-bottom: none;
    }
    .ant-table-row {
      &:hover {
        .ant-table-cell {
          background-color: rgba(98, 105, 153, 0.08);
          border-bottom: 1px solid transparent;
          &:first-of-type {
            border-radius: 8px 0px 0px 8px;
          }
          &:last-of-type {
            border-radius: 0px 8px 8px 0px;
          }
        }
      }
      .ant-table-cell {
        padding: 18px 12px;
        border-bottom: 1px solid rgba(225, 225, 229, 0.4);
      }
    }
  }
`

interface FilterOption {
  label: string
  value: string
  subOptions?: FilterOption[]
}

interface FilterType {
  defaultValue?: string
  defaultSubValue?: [Dayjs | null, Dayjs | null]
  customFilterValue?: string
  options?: FilterOption[]
}

interface SearchType {
  placeholder?: string
}

type subFilterValueType = [Dayjs | null, Dayjs | null] | null

interface tableChangeType {
  filterValue?: string | undefined
  subFilterValue?: subFilterValueType
  current?: number
  pageSize?: number
  searchValue?: string | undefined
  sorters?: Sorts | undefined
}

export interface MonitorDrawerProps extends TableProps {
  title?: any
  onClose: () => void
  filter?: false | FilterType
  searcher?: false | SearchType
  columns: TableProps['columns']
  dataSource: TableProps['dataSource']
  total: number
  /**
   * 所有 change 项透传
   */
  onChange?: (value: tableChangeType) => void
  /**
   * 透传 antd Table onChange
   */
  onTableChange?: OnChange
  /**
   * 透传 filter onChange
   */
  onFilterChange?: (value: string) => void
  /**
   * 透传 rangepicker onChange
   */
  onRangePickerChange?: (value: subFilterValueType) => void
  /**
   * 透传 search onChange
   */
  onSearchChange?: (value: string) => void
  loading?: boolean
  /**
   * 如果 right > 8 就是二层抽屉
   */
  right?: number
  expandable?: TableProps['expandable']
  rowKey?: TableProps['rowKey']
}

const DEFAULT_FILTER_OPTIONS: FilterType['options'] = [
  {
    label: '本周',
    value: 'weekly',
  },
  {
    label: '本月',
    value: 'monthly',
  },
  {
    label: '本季度',
    value: 'quarterly',
  },
  {
    label: '本年',
    value: 'yearly',
  },
  {
    label: '自定义',
    value: 'custom',
  },
]

const ModalWrapper = styled.div<{ top: number; right?: number }>`
  position: fixed;
  top: ${({ top }) => top}px;
  bottom: 8px;
  right: ${({ right = 8 }) => right}px;
  display: flex;
  flex-direction: column;
  width: 600px;
  border-radius: 8px;
  background-color: #fff;
  backdrop-filter: blur(16px);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
`

const defaultDateRange: [Dayjs, Dayjs] = [dayjs().subtract(1, 'week'), dayjs()]

function isValidDateRange(value: any): value is [Dayjs, Dayjs] {
  return (
    Array.isArray(value) &&
    value.length === 2 &&
    value.every(v => dayjs(v).isValid())
  )
}

const MonitorDrawer: React.FC<MonitorDrawerProps> = ({
  onClose,
  title,
  filter,
  columns,
  dataSource,
  searcher,
  total,
  loading,
  right = 8,
  onChange,
  onTableChange,
  onFilterChange,
  onRangePickerChange,
  onSearchChange,
  ...tableProps
}) => {
  const [modalId] = useState<string>(() => {
    if (right > 8) {
      return `monitorDrawer-second-${uniqueId()}`
    }
    return `monitorDrawer-first-${uniqueId()}`
  })
  const { activeModals, openModal, closeModal } = useMonitorModalStore(
    state => {
      return {
        activeModals: state.activeModals,
        openModal: state.openModal,
        closeModal: state.closeModal,
      }
    },
  )

  useEffect(() => {
    openModal(modalId)
    return () => {
      closeModal(modalId)
    }
  }, [])

  useEffect(() => {
    const index = activeModals.findIndex(modal => modal === modalId)
    const firstLayModals = activeModals.filter(modalId =>
      modalId.includes('monitorDrawer-first-'),
    )
    const secondLayModals = activeModals.filter(modalId =>
      modalId.includes('monitorDrawer-second-'),
    )
    const activeModalArr = modalId.includes('monitorDrawer-first-')
      ? firstLayModals
      : secondLayModals
    if (activeModalArr?.length > 1 && index !== activeModalArr.length - 1) {
      onClose()
    }
  }, [activeModals])

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [pagintaion, setPagination] = useState({ current: 1, pageSize: 10 })
  const hasFilter = !!filter
  const hasSearcher = !!searcher

  const filterOptions = hasFilter
    ? filter.options || DEFAULT_FILTER_OPTIONS
    : undefined

  const [filterValue, setFilterValue] = useState<string | undefined>(() => {
    return filter && filter?.defaultValue
      ? filter?.defaultValue
      : filterOptions
        ? filterOptions[0].value
        : undefined
  })

  const containerSize = useSize(wrapperRef)

  const [searchValue, setSearchValue] = useState<string | undefined>()

  const [initialSubFilterValue] = useState<subFilterValueType>(() => {
    if (
      filter &&
      'defaultSubValue' in filter &&
      isValidDateRange(filter.defaultSubValue)
    ) {
      return filter.defaultSubValue
    }
    return defaultDateRange
  })

  const [subFilterValue, setSubFilterValue] = useState<subFilterValueType>(
    initialSubFilterValue,
  )

  const [lastCustomRange, setLastCustomRange] = useState<subFilterValueType>(
    initialSubFilterValue,
  )

  const [sortedInfo, setSortedInfo] = useState<Sorts>({})

  const handleChange = (value: tableChangeType) => {
    const _pagination = { ...pagintaion }
    if (value.current) {
      _pagination.current = value.current
    }
    if (value.pageSize) {
      _pagination.pageSize = value.pageSize
    }
    setPagination(_pagination)
    onChange?.({
      ...pagintaion,
      filterValue,
      subFilterValue,
      searchValue,
      sorters: sortedInfo,
      ...value,
    })
  }
  return (
    <>
      <ModalWrapper top={8} ref={wrapperRef} right={right || 8}>
        <div className='flex flex-items-center flex-justify-between h-56px shrink-0 flex-grow-0 p-l-20px p-r-16px border-b-1 border-b-#e1e1e5 border-b-op-60'>
          <div className=' c-font font-500 text-16px/16px'>{title}</div>
          <div
            className='w-24px h-24px b-rd-4px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
            onClick={onClose}
          >
            <IconFont name='guanbi' className='text-16px' />
          </div>
        </div>
        <div className='py-16px px-8px h-full'>
          <div className='flex'>
            {hasFilter && (
              <>
                <Select
                  className='w-140px h-36px mb-16px'
                  value={filterValue}
                  options={filterOptions}
                  onChange={value => {
                    setFilterValue(value)
                    onFilterChange?.(value)
                    if (value === 'custom') {
                      const customRange = isValidDateRange(lastCustomRange)
                        ? lastCustomRange
                        : initialSubFilterValue
                      setSubFilterValue(customRange)
                      onRangePickerChange?.(customRange)
                      handleChange({
                        current: 1,
                        filterValue: value,
                        subFilterValue: customRange,
                      })
                    } else {
                      handleChange({
                        current: 1,
                        filterValue: value,
                        subFilterValue: undefined,
                      })
                    }
                  }}
                />
                {(filterValue === 'custom' ||
                  filterValue === filter?.customFilterValue) && (
                  <RangePickerWrapper
                    value={subFilterValue as [Dayjs, Dayjs]}
                    onChange={value => {
                      if (isValidDateRange(value)) {
                        setSubFilterValue(value)
                        setLastCustomRange(value)
                        onRangePickerChange?.(value)
                        handleChange({
                          current: 1,
                          subFilterValue: value,
                        })
                      }
                    }}
                    className='h-36px ml-12 mr-12'
                  />
                )}
              </>
            )}
            {hasSearcher && (
              <Input
                className='w-200px ml-auto mb-16px b-rd-8px'
                prefix={<IconFont className='text-16px' name='search' />}
                placeholder={searcher.placeholder}
                onChange={e => {
                  const inputValue = e.target.value
                  setSearchValue(inputValue)
                  onSearchChange?.(inputValue)
                  handleChange({
                    current: 1,
                    searchValue: inputValue,
                  })
                }}
              />
            )}
          </div>
          <Table
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              total,
              size: 'small',
              ...pagintaion,
            }}
            onChange={(_pagination, _filters, _sorters, _extra) => {
              onTableChange?.(_pagination, _filters, _sorters, _extra)
              const _new_pagination = {
                ...pagintaion,
                ..._pagination,
              }
              setSortedInfo(_sorters as Sorts)
              handleChange({
                current: _new_pagination.current,
                pageSize: _new_pagination.pageSize,
                sorters: _sorters as Sorts,
              })
            }}
            scroll={{
              y: containerSize && containerSize.height - 250,
            }}
            {...tableProps}
          />
        </div>
      </ModalWrapper>
    </>
  )
}

export default memo(MonitorDrawer)
