import { useWebSocket } from 'ahooks'
import type { PropsWithChildren } from 'react'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from '@bty/react-auth'

interface PushNotificationContextValue {
  hasUnreadNotification: boolean
  clearUnreadNotification: VoidFunction
}

export const PushNotificationContext =
  createContext<PushNotificationContextValue>({
    hasUnreadNotification: false,
    clearUnreadNotification: () => {},
  })

export function PushNotificationProvider({ children }: PropsWithChildren) {
  const { state } = useAuth()

  const { latestMessage } = useWebSocket(
    `${import.meta.env.VITE_AI_WS_BASE_URL}/v1/permission_event?Authorization=${state.token}`,
    {
      onOpen: (_, ws) => {
        // 连接成功后查询当前有多少未读
        ws.send(
          JSON.stringify({
            request_id: Date.now(),
            request_type: 1,
            data: null,
          }),
        )
      },
    },
  )

  const [hasUnreadNotification, setHasUnreadNotification] = useState(false)

  useEffect(() => {
    if (latestMessage?.data) {
      try {
        const message = JSON.parse(latestMessage.data)
        if (message.request_type === 1) {
          // 客户端主动请求的响应
          if (message.data) {
            setHasUnreadNotification(true)
          }
          return
        }
        if (message.increase) {
          // 服务端主动推送
          setHasUnreadNotification(true)
        }
      } catch (_) {}
    }
  }, [latestMessage])

  const value = useMemo(
    () => ({
      hasUnreadNotification,
      clearUnreadNotification: () => setHasUnreadNotification(false),
    }),
    [hasUnreadNotification],
  )

  return (
    <PushNotificationContext.Provider value={value}>
      {children}
    </PushNotificationContext.Provider>
  )
}
