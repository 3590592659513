import type { ReactNode, FC, Key, MutableRefObject } from 'react'
import { useState, useRef, useMemo, useEffect } from 'react'
import type { PopoverProps, TooltipProps } from 'antd'
import { Popover, Empty } from 'antd'
import classNames from 'classnames'
import { Checkbox, IconFont, Input } from '@/components'

interface TagDropdownSelectProps extends PopoverProps {
  options?: Array<{
    label: string
    count?: number
    value: unknown
    description?: string
  }>
  defaultValue?: string[]
  value?: unknown[]
  onChange?: (value: unknown[]) => void
  width?: number
  disabled?: boolean
  allEnable?: boolean
  placement?: TooltipProps['placement']
  onOpenChange?: (visible: boolean) => void
  contentClassName?: string
  triggerRender: (
    value: unknown,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => ReactNode
}

export const TagDropdownSelect: FC<TagDropdownSelectProps> = props => {
  const {
    defaultValue,
    triggerRender,
    options = [],
    onChange,
    allEnable,
    value: propsValue,
    disabled,
    placement = 'bottomRight',
    contentClassName,
    ...remainingProps
  } = props
  const [__value, setValue] = useState<unknown[]>(defaultValue ?? [])
  const value = propsValue ?? __value
  const triggerRef = useRef<HTMLDivElement | null>(null)
  const popoverWidth = triggerRef.current?.offsetWidth || props.width || 320
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    // 关闭的时候，清空搜索框的内容
    if (!remainingProps.open) {
      setSearchValue('')
    }
  }, [remainingProps.open])

  const onSelect = (_value?: unknown) => {
    let newValue = [...value]
    if (newValue.includes(_value)) {
      newValue = newValue.filter(item => item !== _value)
    } else {
      newValue.push(_value)
    }
    setValue(newValue)
    onChange?.(newValue)
  }

  const filteredOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return options
    }
    return options.filter(item => {
      return item.label
        ?.toLocaleLowerCase()
        .includes(searchValue?.toLocaleLowerCase())
    })
  }, [options, searchValue])

  const onSelectAll = () => {
    if (
      filteredOptions.length !== 0 &&
      value.length === filteredOptions.length
    ) {
      setValue([])
      onChange?.([])
    } else if (
      filteredOptions.length !== 0 &&
      value.length !== filteredOptions.length
    ) {
      const _value = filteredOptions.map(opt => opt.value)
      setValue(_value)
      onChange?.(_value)
    }
  }

  return (
    <Popover
      arrow={false}
      trigger='click'
      overlayInnerStyle={{ padding: 0, borderRadius: 6, width: popoverWidth }}
      placement={placement}
      onOpenChange={vis => {
        props?.onOpenChange?.(vis)
        if (!vis) {
          setSearchValue('')
        }
      }}
      content={
        disabled ? null : (
          <>
            <div
              className={classNames(
                'p-4 nodrag nopan nowheel',
                contentClassName,
              )}
            >
              <Input
                prefix={<IconFont name='search' className='text-16px' />}
                className='mb-4px'
                placeholder='搜索标签'
                size='small'
                value={searchValue}
                onChange={e => {
                  setSearchValue(e.target.value)
                }}
              />
              <div className='nowheel max-h-246px overflow-y-auto'>
                {filteredOptions.map(option => {
                  return (
                    <Option
                      key={option.value as Key}
                      label={option.label}
                      value={option.value}
                      description={option.description}
                      count={option.count}
                      checked={value.includes(option.value)}
                      onClick={() => onSelect(option.value)}
                    />
                  )
                })}
              </div>
              {!options.length && (
                <div className='flex items-center w-full'>
                  <Empty
                    className='flex-center flex-col w-full'
                    imageStyle={{ width: 100, height: 100 }}
                    description={null}
                  >
                    <span className='c-font_1 c-op-60 relative top--20px'>
                      暂无可选标签
                    </span>
                  </Empty>
                </div>
              )}
            </div>
            {allEnable && !!options.length && (
              <div className='p-4 b-t b-line b-op-60'>
                <Option
                  label='全选'
                  value='__all'
                  checked={
                    filteredOptions.length !== 0 &&
                    value.length === filteredOptions.length
                  }
                  indeterminate={
                    value.length > 0 && value.length !== options.length
                  }
                  onClick={onSelectAll}
                />
              </div>
            )}
          </>
        )
      }
      {...remainingProps}
    >
      {triggerRender(value, triggerRef)}
    </Popover>
  )
}

function Option(props: {
  label: string
  value: unknown
  description?: any
  count?: number
  checked: boolean
  indeterminate?: boolean
  onClick: () => void
}) {
  return (
    <div
      key={props.value as Key}
      className='py-5 px-12 rounded-6px cursor-pointer hover:bg-bg_3 hover:bg-op-8 flex items-center'
      title={props.label}
      onClick={props.onClick}
    >
      <Checkbox
        className='!m-0'
        indeterminate={props.indeterminate}
        checked={props.checked}
      />
      <div className='ml-8 text-12px flex-1 flex items-center overflow-hidden'>
        <span className='flex-1 truncate'>{props.label}</span>
        {!!props.description && (
          <p className='text-right max-w-70% truncate ml-48px text-font_1 text-opacity-60 font-normal'>
            <span className=''>{props.description}</span>
          </p>
        )}
        {props.count && (
          <span className='ml-12 c-font_1'>{props.count}文档</span>
        )}
      </div>
    </div>
  )
}
