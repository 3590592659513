import { hasIn, isEmpty } from 'lodash-es'
import { create } from 'zustand'
import type { SendMessageParams } from '@bty/chat-renderer-pc'
import type { LLMChannels } from '@/apis/llm/model'

type Call = (value: Record<string, string>, update: boolean) => void
type MessageCall = (
  message: string,
  file?: SendMessageParams['file'],
  action?: 'regenerate' | 'continue',
) => void
type RuleCall = () => void
type StopCall = () => void

export interface AgentDiffModel {
  model: string
  channel: LLMChannels
  temperature: number
  length_prompt?: number
  time_prompt?: boolean
}

export interface AgentDiffConfig {
  hasError?: boolean
  index?: number
  conversationId?: string
  modelConfig?: AgentDiffModel
}

interface AgentDiffStore {
  generateMap: Map<number, boolean>
  generating: boolean
  changeGenerate: (id: number, state: boolean) => void

  open: boolean
  initOpen: (open: boolean) => void
  openDiff: () => void
  closeDiff: () => void

  diffConfigs: AgentDiffConfig[]
  initDiffConfigs: (diffConfigs: AgentDiffConfig[]) => void
  clearDiffConfigs: () => string[]
  checkDiffConfigs: () => boolean
  deleteDiffConfig: (index: number) => void
  clearDiffIds: () => string[]
  addDiffModel: (config?: AgentDiffModel) => void
  changeDiffId: (index: number, id: string) => void
  changeDiffModel: (index: number, config: AgentDiffModel) => string[]

  variableValue: Record<string, string>
  setVariableValue: (
    value: Record<string, string>,
    updateSession?: boolean,
  ) => void
  checkVariableValue: (
    list: string[],
    value?: Record<string, string>,
  ) => boolean

  variableCallList: Set<Call>
  subscribeVariableValue: (call: Call) => () => void

  messageCallList: Set<MessageCall>
  publishMessage: MessageCall
  subscribeMessage: (call: MessageCall) => void

  changeCallList: Set<MessageCall>
  publishChange: MessageCall
  subscribeChange: (call: MessageCall) => void

  ruleChangeCallList: Set<RuleCall>
  publishRuleChange: RuleCall
  subscribeRuleChange: (call: RuleCall) => void

  stopCallList: Set<StopCall>
  publishStop: StopCall
  subscribeStop: (call: StopCall) => () => void
}

export const useAgentDiffStore = create<AgentDiffStore>((set, get) => ({
  generateMap: new Map(),
  generating: false,
  changeGenerate: (id: number, state: boolean) => {
    const map = get().generateMap
    map.set(id, state)

    set({
      generating: [...map.values()].some(e => !!e),
    })
  },

  open: false,
  initOpen: (open: boolean) => {
    set({ open })
  },
  openDiff: () => {
    const nowDiffConfigs = get().diffConfigs

    if (nowDiffConfigs.length === 0) {
      set({
        open: true,
        diffConfigs: [{ conversationId: '' }, { conversationId: '' }],
      })
    }

    set({
      open: true,
    })
  },
  closeDiff: () => {
    set({
      open: false,
    })
  },

  diffConfigs: [],
  initDiffConfigs: (diffConfigs: AgentDiffConfig[]) => {
    set({ diffConfigs })
  },
  clearDiffIds: () => {
    const nowDiffConfigs = get().diffConfigs
    const oldIds = nowDiffConfigs.map(e => e.conversationId!)
    set({
      diffConfigs: nowDiffConfigs.map(e => ({ ...e, conversationId: '' })),
    })
    return oldIds
  },
  clearDiffConfigs: () => {
    const nowDiffConfigs = get().diffConfigs
    const oldIds = nowDiffConfigs.map(e => e.conversationId!)
    set({ diffConfigs: [] })
    return oldIds
  },
  addDiffModel: (config?: AgentDiffModel) => {
    const nowDiffConfigs = get().diffConfigs
    set({
      diffConfigs: [
        ...nowDiffConfigs,
        { conversationId: '', modelConfig: config },
      ],
    })
  },
  deleteDiffConfig: (index: number) => {
    const nowDiffConfigs = get().diffConfigs
    set({
      diffConfigs: nowDiffConfigs.filter((_, i) => i !== index),
    })
  },
  changeDiffId: (index: number, id: string) => {
    set(prev => {
      return {
        diffConfigs: prev.diffConfigs.map((each, i) => {
          if (i !== index) {
            return each
          }

          return {
            ...each,
            conversationId: id,
          }
        }),
      }
    })
  },
  changeDiffModel: (index: number, config: AgentDiffModel) => {
    const nowDiffConfigs = get().diffConfigs
    const oldIds = nowDiffConfigs.map(e => e.conversationId!)
    set({
      diffConfigs: nowDiffConfigs.map((each, i) => {
        if (i !== index) {
          return { ...each, conversationId: '' }
        }

        return {
          ...each,
          conversationId: '',
          hasError: false,
          modelConfig: config,
        }
      }),
    })

    return oldIds
  },
  checkDiffConfigs: () => {
    const nowDiffConfigs = get().diffConfigs
    let hasError = false
    const newConfig = nowDiffConfigs.map((each, index) => {
      if (isEmpty(each.modelConfig) && index < 2) {
        hasError = true
        return {
          ...each,
          hasError: true,
        }
      }
      return each
    })

    if (!hasError) return true

    set({
      diffConfigs: newConfig,
    })
    return false
  },

  variableCallList: new Set(),
  subscribeVariableValue: (call: Call) => {
    const set = get().variableCallList
    set.add(call)
    return () => {
      set.delete(call)
    }
  },

  variableValue: {},
  checkVariableValue: (list: string[], value?: Record<string, string>) => {
    const checkValue = value ?? get().variableValue
    return list.every(e => hasIn(checkValue, e))
  },
  setVariableValue: (newValue: Record<string, string>, update = true) => {
    set({
      variableValue: newValue,
    })

    get().variableCallList.forEach(call => {
      call(newValue, update)
    })
  },

  messageCallList: new Set(),
  publishMessage: (
    message: string,
    file?: SendMessageParams['file'],
    action?: 'regenerate' | 'continue',
  ) => {
    const set = get().messageCallList
    set.forEach(call => {
      call(message, file, action)
    })
  },
  subscribeMessage: (call: MessageCall) => {
    const set = get().messageCallList
    set.add(call)
    return () => {
      set.delete(call)
    }
  },

  changeCallList: new Set(),
  publishChange: (message: string, file?: SendMessageParams['file']) => {
    const set = get().changeCallList
    set.forEach(call => {
      call(message, file)
    })
  },
  subscribeChange: (call: MessageCall) => {
    const set = get().changeCallList
    set.add(call)
    return () => {
      set.delete(call)
    }
  },

  ruleChangeCallList: new Set(),
  publishRuleChange: () => {
    const set = get().ruleChangeCallList
    set.forEach(call => {
      call()
    })
  },
  subscribeRuleChange: (call: RuleCall) => {
    const set = get().ruleChangeCallList
    set.add(call)
    return () => {
      set.delete(call)
    }
  },

  stopCallList: new Set(),
  publishStop: () => {
    const set = get().stopCallList
    set.forEach(call => {
      call()
    })
  },
  subscribeStop: (call: StopCall) => {
    const set = get().stopCallList
    set.add(call)
    return () => {
      set.delete(call)
    }
  },
}))
