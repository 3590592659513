import type { FC } from 'react'
import classNames from 'classnames'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import OSS from 'ali-oss'
import type { AppLogoProps } from '@/components'
import { IconFont } from '@/components'
import { getSTSToken } from '@/apis/oss'

interface IconSelectProps {
  value?: string
  onChange?: (value: string) => void
  icons?: string[]
  iconType: AppLogoProps['type']
}

export const IconSelect: FC<IconSelectProps> = props => {
  const { value, onChange, icons = [], iconType } = props

  const handleUploadFile = async (file: any) => {
    try {
      const token = await getSTSToken()
      if (token) {
        const ossClient = new OSS({
          region: 'oss-cn-hangzhou',
          accessKeyId: token!.access_key_id,
          accessKeySecret: token!.access_key_secret,
          stsToken: token!.security_token,
          bucket: import.meta.env.VITE_AI_DATASTORE_RESOURCE_BUCKET,
        })
        const updateResult = await ossClient.multipartUpload(
          `/ai/flow/avatar/${new Date().getTime()}${file?.name}`,
          file,
          {},
        )

        const result = (updateResult.res as any)?.requestUrls?.[0]?.split(
          '?',
        )[0]
        onChange?.(result)
      }
    } catch {}
  }

  return (
    <div className='flex flex-wrap gap-10 overflow-auto'>
      <ImgCrop
        rotationSlider={false}
        zoomSlider={false}
        aspectSlider={false}
        showReset={false}
        showGrid={true}
        cropShape='round'
        modalTitle='编辑图片'
        modalOk='确定'
        modalCancel='取消'
      >
        <Upload
          multiple={false}
          listType='picture'
          customRequest={e => handleUploadFile(e.file)}
          showUploadList={false}
        >
          <div
            style={{ transform: 'translate(4px, 9px)' }}
            className='h-22px w-22px p-2px ml-6px mr-11px flex items-center justify-center border-rd-50% bg-primary bg-op-8 hover:bg-op-16 cursor-pointer'
          >
            <IconFont name='add' className='text-16px c-primary text-sm' />
          </div>
        </Upload>
      </ImgCrop>
      {icons.map(icon => {
        return (
          <span
            onClick={() => {
              onChange?.(icon)
            }}
            className={classNames(
              'cursor-pointer b-1 b-transparent w-40px h-40px rounded-10px flex-center',
              {
                'hover:bg-bg_3 hover:bg-opacity-6': value !== icon,
                'bg-bg_3 bg-opacity-6 !b-primary': value === icon,
              },
            )}
            key={icon}
          >
            <span className='text-20px c-font_1'>
              {iconType === 'icon' ? <IconFont name={icon} /> : icon}
            </span>
          </span>
        )
      })}
    </div>
  )
}
