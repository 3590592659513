import DataStorePlugin from '@/pages/datastores/DataStorePlugin'
import type { AuthRoute } from '@/auth'
import { ErrorElement } from '@/pages/error'

export const chromePluginRoutes: AuthRoute[] = [
  {
    path: '/chrome-plugin',
    // auth: 'auth',
    element: <DataStorePlugin />,
    errorElement: <ErrorElement />,
  },
]
