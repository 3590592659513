import { useRequest, useTitle } from 'ahooks'
import { useContext, useMemo } from 'react'
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Dropdown, Tooltip } from 'antd'
import classNames from 'classnames'
import {
  getAssociatedApplication,
  updateDataStore,
  deleteDataStore,
} from '@/apis/datastore/api'
import {
  AppLogo,
  DetailHeader,
  DetailTabs,
  usePageModal,
  Button,
  IconFont,
  useConfirmModal,
} from '@/components'
import { CreateModal } from '@/components/createAppModal'
import { PRODUCT_TITLE } from '@/constants/common'
import { colors } from '@/constants/theme'
import { useWorkspaceStore } from '@/store'
import DetailOverviewInfo from '../application/components/appDetail/components/DetailOverviewInfo'
import { useModal } from '@/hooks/useModal.tsx'
import AssociatedAgentPopover from '@/features/application/AssociatedAgentPopover.tsx'
import { PartitionCategoryType } from '@/apis/datastore/types'
import {
  DataStoreContext,
  DataStoreProvider,
} from './provider/DataStoreProvider'

const documentsKey = 'documents'

const RouterMap = {
  [documentsKey]: '-',
  synonymLibrary: '近义词库',
  hitTest: '命中测试',
  // applications: '关联应用',
  // settings: '设置',
}

type RouterMapKey = keyof typeof RouterMap

function DataStoreLayout() {
  const location = useLocation()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const match = useMatch('/datastores/:workspaceId/:id/:page')
  const id = match?.params.id
  const page = match?.params.page

  const defaultActiveTab = Object.keys(RouterMap).find(
    key => key === page,
  ) as RouterMapKey

  const navigate = useNavigate()

  const onTabChange = (key: any) => {
    navigate(
      `/datastores/${currentWorkspaceId}/${id}/${key}${location.search}${location.hash}`,
    )
  }

  const { dataStoreInfo, refresh } = useContext(DataStoreContext)

  const tabs = useMemo(() => {
    return Object.entries(RouterMap).map(([key, value]) => {
      const item = {
        label: value,
        key,
      }
      if (item.key === documentsKey && dataStoreInfo) {
        if (dataStoreInfo?.partition_category === PartitionCategoryType.QA) {
          item.label = '问答'
        } else {
          item.label = '文档'
        }
      }
      return item
    })
  }, [dataStoreInfo?.partition_category])

  const { runAsync: updateDataStoreApi } = useRequest(updateDataStore, {
    manual: true,
  })
  const { runAsync: deleteDataStoreApi } = useRequest(deleteDataStore, {
    manual: true,
  })

  const { data } = useRequest(getAssociatedApplication, {
    defaultParams: [Number(id)],
  })

  const pageModal = usePageModal()
  const [deleteModal] = useConfirmModal()

  useTitle(
    dataStoreInfo?.partition_name
      ? `${dataStoreInfo?.partition_name}`
      : PRODUCT_TITLE,
    { restoreOnUnmount: true },
  )

  const [appBaseInfoModal] = useModal(CreateModal)
  const onBaseInfoEdit = () => {
    appBaseInfoModal.open({
      title: '编辑知识库信息',
      fieldConfig: {
        name: { placeholder: '请输入知识库名称' },
        description: {
          placeholder: '请输入知识库描述，这段文字将作为知识库的使用引导',
        },
      },
      logoType: 'icon',
      defaultValue: {
        name: dataStoreInfo?.partition_name,
        description: dataStoreInfo?.partition_describe,
        icon: dataStoreInfo?.partition_icon,
        color: dataStoreInfo?.partition_icon_color,
      },
      onFinish: async formData => {
        if (dataStoreInfo) {
          await updateDataStoreApi({
            partition_icon: formData.icon,
            partition_icon_color: formData.color,
            partition_name: formData.name,
            partition_describe: formData.description,
            partition_id: dataStoreInfo.partition_id,
          })
          refresh()
          appBaseInfoModal.close()
        }
      },
    })
  }

  return (
    <div className='h-full'>
      <div className='flex flex-col h-full b-rd-12px bg-bg overflow-hidden'>
        <DetailHeader
          onBack={() => {
            navigate('/datastores')
          }}
          mainInfo={
            <div className='rounded-8px flex items-center bg-white'>
              <AppLogo
                type='icon'
                className='cursor-pointer'
                size={40}
                onClick={() => onBaseInfoEdit()}
                color={dataStoreInfo?.partition_icon_color ?? colors[0]}
                value={dataStoreInfo?.partition_icon ?? 'app'}
              />
              <div
                className='mx-8 text-20px font-600 cursor-pointer'
                onClick={() => onBaseInfoEdit()}
              >
                {dataStoreInfo?.partition_name}
              </div>
              <AssociatedAgentPopover
                data={(data || [])?.map((item: any) => ({
                  ...item,
                  name: item.flow_name,
                }))}
                onClick={data => {
                  const url =
                    data.application_type === 'AGENT'
                      ? `/agent/${data.app_id}`
                      : `/application/${currentWorkspaceId}/${data.app_id}/flow/${data.flow_id}`
                  pageModal.show({ url })
                }}
              />
            </div>
          }
          actions={
            <>
              <div className='mr-12px' onClick={e => e.stopPropagation()}>
                <DetailOverviewInfo />
              </div>

              <Dropdown
                overlayClassName='w-156px'
                trigger={['click']}
                menu={{
                  items: [
                    {
                      label: (
                        <Tooltip
                          title={
                            (data || [])?.length > 0 ? '有关联应用不可删除' : ''
                          }
                        >
                          <div
                            className={classNames('w-100%', {
                              'op-60': (data || [])?.length > 0,
                            })}
                          >
                            删除
                          </div>
                        </Tooltip>
                      ),
                      key: '2',
                      disabled: (data || [])?.length > 0,
                      style: {
                        color: '#FF5219',
                        height: 32,
                        padding: '0 28px 0 10px',
                      },
                      onClick: ({ domEvent }) => {
                        domEvent.stopPropagation()
                        if (dataStoreInfo) {
                          deleteModal.open({
                            title: '确定删除知识库？',
                            okText: '删除',
                            onOk: async () => {
                              await deleteDataStoreApi(
                                dataStoreInfo.partition_id,
                              )
                              deleteModal.close()
                              navigate('/datastores')
                            },
                          })
                        }
                      },
                    },
                  ],
                }}
              >
                <Button
                  onClick={e => e.stopPropagation()}
                  className='!w-36px'
                  icon={<IconFont name='gengduo' className='c-#17171d' />}
                ></Button>
              </Dropdown>
            </>
          }
        />
        <DetailTabs
          tabs={tabs}
          noContent={true}
          defaultKey={defaultActiveTab}
          onChange={onTabChange}
        />
        <div className='adapt:p-24px flex-1 flex overflow-hidden'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

function DataStoreLayoutWithProvider() {
  return (
    <DataStoreProvider>
      <DataStoreLayout />
    </DataStoreProvider>
  )
}

export default DataStoreLayoutWithProvider
