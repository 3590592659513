import type { RadioChangeEvent } from 'antd'
import { Checkbox, Divider, Radio, Table, Tooltip, message } from 'antd'
import { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useBoolean, useCountDown, useRequest } from 'ahooks'
import { useNavigate } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { PayType, PayStep, OrderType } from '../const'
import { padZero } from '../util'
import { Button, IconFont, Modal } from '@/components'
import { doToBPay, type OrderDetail } from '@/apis/order'
import { PRIVACY_URL, SERVICE_URL } from '@/constants/url'
import { ToBSteps } from './components/ToBSteps'

const columns = [
  {
    title: '版本名称',
    dataIndex: 'productServiceName',
    key: 'productServiceName',
  },
  {
    title: '单价',
    dataIndex: 'price',
    key: 'price',
    render: (text: string) => `￥${text}`,
  },
  {
    title: '服务时长',
    dataIndex: 'durationOfService',
    key: 'durationOfService',
    render: (text: string | number) => {
      if (text === 12 || text === '12') {
        return <div>1年</div>
      }
      return <div>{text}个月</div>
    },
  },
  {
    title: '预估服务有效期',
    dataIndex: 'expired',
    key: 'expired',
    render: (_text: string, record: OrderDetail) => {
      return (
        <div>
          {dayjs(record.effectiveStartTime).format('YYYY-MM-DD')}-
          {dayjs(record.effectiveEndTime).format('YYYY-MM-DD')}
        </div>
      )
    },
  },
  {
    title: '优惠金额',
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    render: (_text: string, record: OrderDetail) => {
      return (
        <div>
          {(
            Number.parseFloat(record.discountAmount) +
            Number.parseFloat(record.taskRecoveryAmount)
          ).toFixed(2)}
        </div>
      )
    },
  },
  {
    title: '订单金额',
    dataIndex: 'realPayAmount',
    key: 'realPayAmount',
  },
]

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border: 1px solid rgba(141, 141, 153, 0.6);
    box-shadow: 0px 2px 4px 0px rgba(23, 26, 29, 0.08);
    border-radius: 4px;
  }
`
export function OrderConfirm(props: {
  order: OrderDetail
  onCancelOrder: (orderId: string) => void
  cancelLoading?: boolean
}) {
  const { order, cancelLoading, onCancelOrder } = props
  const [payType, setPayType] = useState(PayType.ALI_PAY)

  const [checked, { toggle: toggleCheck }] = useBoolean(false)

  const [stepVisible, { toggle: toggleStepVisible }] = useBoolean(false)

  const { runAsync: doToBPayApi, loading } = useRequest(doToBPay, {
    manual: true,
  })

  const onChange = (e: RadioChangeEvent) => {
    const value = e.target.value
    value === PayType.TO_B && toggleStepVisible()
    setPayType(value)
  }

  const navigate = useNavigate()

  const [_, formattedResult] = useCountDown({
    targetDate: order?.orderExpireTime,
  })

  const dataSource = useMemo(() => [order], [order])

  const payUrl = useMemo(() => {
    const baseUrl =
      import.meta.env.MODE === 'development'
        ? '/api'
        : import.meta.env.VITE_AI_API_BASE_URL
    return payType === PayType.TO_B
      ? 'javascript:void(0)'
      : `${baseUrl}/v1/pay/send_pay?order_no=${order?.orderNo}&pay_type=${payType}`
  }, [payType, order?.orderNo])

  const handlePay = async () => {
    if (payType === PayType.TO_B) {
      await doToBPayApi({
        order_no: order?.orderNo,
        pay_type: payType,
      })
    }
    setTimeout(
      () => {
        navigate(`/pay?orderId=${order?.orderNo}&step=${PayStep.PayResult}`)
      },
      payType === PayType.TO_B ? 0 : 1000,
    )
  }

  const handleCopy = () => {
    copy(order?.orderNo)
    message.success('订单号复制成功')
  }

  return (
    <div className='p-60 bg-white rd-12px w-1000px'>
      <h3 className='text-16px font-600'>订单详情</h3>
      <div className='flex items-center text-12px mt-24'>
        <span>订单编号：{order?.orderNo}</span>{' '}
        <IconFont
          name='copy'
          onClick={handleCopy}
          className='text-font_1 ml-10 cursor-pointer'
        />
      </div>
      <Table
        className='mt-14'
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />

      {order?.orderType === OrderType.SUB &&
        Number.parseFloat(order.discountAmount) +
          Number.parseFloat(order.taskRecoveryAmount) !==
          0 && (
          <p className='text-12px text-font_1 mt-16'>
            升级版本，将根据下单时的套餐剩余用量，折算为抵扣费用，该费用每小时重新计算一次。优惠保留时间{' '}
            <span className='text-#E54A17'>
              {padZero(formattedResult.minutes)}:
              {padZero(formattedResult.seconds)}
            </span>
          </p>
        )}
      <h3 className='text-16px font-600 mt-40'>支付方式</h3>

      <Radio.Group className='mt-16' onChange={onChange} value={payType}>
        <Radio value={PayType.ALI_PAY}>
          <div className='flex items-center mt-4'>
            <IconFont className='text-24px mr-6' name='zhifubao'></IconFont>{' '}
            <div>支付宝</div>
          </div>
        </Radio>
        <Radio value={PayType.TO_B}>
          <div className='flex items-center mt-4'>
            <IconFont
              className='text-24px mr-6'
              name='duigongzhuanzhang'
            ></IconFont>{' '}
            <div>对公转账</div>
          </div>
        </Radio>
      </Radio.Group>

      <Divider className='my-40' />
      <div className='flex flex-col items-end'>
        <div className='flex items-end'>
          <div className='text-16px font-600'>实际应付</div>
          <div className='text-#E54A17 text-24px font-800 ml-8'>
            <span className='text-16px'>¥</span>
            {order?.realPayAmount}
          </div>
        </div>
        <div className='mt-20'>
          <Button
            className='mr-12 w-120px'
            loading={cancelLoading}
            onClick={() => onCancelOrder(order.orderNo)}
          >
            取消订单
          </Button>
          <Tooltip
            title='请阅读并勾选下方协议'
            trigger={checked ? [] : ['hover']}
          >
            <a
              href={payUrl}
              target={payType === PayType.ALI_PAY ? '_blank' : '_self'}
              rel='noreferrer'
            >
              <Button
                type='primary'
                className='w-120px'
                onClick={handlePay}
                disabled={!checked}
                loading={loading}
              >
                确认支付
              </Button>
            </a>
          </Tooltip>
        </div>
        <div className='mt-20'>
          <StyledCheckbox checked={checked} onChange={toggleCheck}>
            <p className='text-font_1 text-12px'>
              我已阅读并同意{' '}
              <a
                href={SERVICE_URL}
                target='_blank'
                className='text-primary'
                rel='noreferrer'
              >
                用户服务协议
              </a>{' '}
              和{' '}
              <a
                href={PRIVACY_URL}
                target='_blank'
                className='text-primary'
                rel='noreferrer'
              >
                隐私协议
              </a>
            </p>
          </StyledCheckbox>
        </div>
        <Modal
          open={stepVisible}
          title='对公转账步骤'
          footer={false}
          onCancel={toggleStepVisible}
          width={600}
        >
          <ToBSteps price={order?.realPayAmount} orderId={order.orderNo} />
        </Modal>
      </div>
    </div>
  )
}
