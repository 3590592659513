import type { Node } from 'reactflow'
import { NodeType } from '@/features/nodes/base'
import { EMPTY_NODE_HEIGHT, NODE_WIDTH } from '../constant'
import { getDomByNodeId } from '../../utils'
import type { NodeBoundary } from './types'

export function getDefaultNodeDimension(node: Node) {
  return {
    width:
      node.type === NodeType.CONDITION_RESULT ||
      node.type === NodeType.INTENT_RESULT
        ? 10
        : NODE_WIDTH,
    height:
      node.type === NodeType.CONDITION_RESULT ||
      node.type === NodeType.INTENT_RESULT
        ? 10
        : EMPTY_NODE_HEIGHT,
  }
}

export function getNormalNodeBoundary(node: Node): NodeBoundary {
  let { width, height } = node
  const { x, y } = node.position
  const dom = getDomByNodeId(node.id)

  if (!width || !height) {
    const { width: defaultWidth, height: defaultHeight } =
      getDefaultNodeDimension(node)
    width = defaultWidth
    height = defaultHeight
  }
  if (dom) {
    width = dom.clientWidth
    height = dom.clientHeight
  }
  node.width = width
  node.height = height
  return {
    top: y,
    left: x,
    right: x + width,
    bottom: y + height,
  }
}
