import copy from 'copy-to-clipboard'
import { message } from 'antd'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { IconFont } from '@/components'

interface CopyBoxProps {
  className?: string
  valueClassName?: string
  children?: string | ReactNode
  value?: string
}

export function CopyBox({
  className,
  valueClassName,
  value,
  children,
}: CopyBoxProps) {
  const onCopy = () => {
    if (value) {
      copy(value)
      message.success('已复制到剪切板')
    }
  }

  return (
    <div
      className={classNames(
        'flex justify-between flex-1 p-10 pl-12 bg-bg_3 bg-op-5.88 rounded-8px cursor-pointer',
        className,
      )}
      onClick={onCopy}
    >
      <span
        className={classNames('font-400 truncate flex-1 pr-12', valueClassName)}
        title={value}
      >
        {children || value}
      </span>
      <span className='w-16px h-16px flex-center text-16px c-font_1 cursor-pointer'>
        <IconFont name='copy' />
      </span>
    </div>
  )
}
