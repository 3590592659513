import { type EditorState, type Transaction } from 'prosemirror-state'

export function tab(state: EditorState, dispatch?: (tr: Transaction) => void) {
  if (!dispatch) return true

  const from = state.selection.from
  const to = state.selection.to

  if (from !== to) return true

  const { parent: fromNode } = state.doc.resolve(from)

  if (
    ['pe-input', 'pe-select'].includes(fromNode?.type.name) &&
    !fromNode.textContent &&
    fromNode.attrs.placeholder
  ) {
    const tr = state.tr.replaceWith(
      from,
      from + 1,
      state.schema.text(fromNode.attrs.placeholder),
    )

    dispatch(tr)
    return true
  }

  return true
}
