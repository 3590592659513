import { memo, useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import copy from 'copy-to-clipboard'
import { useMemoizedFn } from 'ahooks'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { omit } from 'lodash-es'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CodeEditor } from '@/features/editor'
import { Checkbox, NodeForm } from '@/components'
import { Webhook } from '@/features/nodes/start/components/Webhook'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { useLogger } from '../context'
import { useSessionValue } from '../hooks/useSessionValue'
import { DEBUG_TEST_ID_MAP } from '@/features/logger/constants'
import { wait } from '@/utils/wait'
import { updateBatchTestData } from '@/apis/batchTest'
import { TestDataSelect } from './TestDataSelect'

interface LoggerInputEditableProps {
  onSaveToTest?: () => void
}

export const LoggerInputEditable = memo((props: LoggerInputEditableProps) => {
  const { onSaveToTest } = props

  const {
    flowId,
    flowLog,
    startType,
    startConfig,
    form,
    webhookJson,
    setJson,

    diff,
    setCorrectResult,
    setRemarks,

    subscribeRun,
    subscribeClear,
  } = useLogger()

  const [saveToTest, setSaveToTest] = useState(false)
  const [testOptionId, setTestOptionId] = useState('')
  const [nowJson, setNowJson] = useState(webhookJson)
  const [testDataId, setTestDataId] = useSessionValue(DEBUG_TEST_ID_MAP, flowId)
  const block = useRef(false)

  const handleCopy = useMemoizedFn((text: string) => {
    copy(text)
    message.success('复制成功')
  })

  const handleSaveToTest = useMemoizedFn(async (event: CheckboxChangeEvent) => {
    setSaveToTest(event.target.checked)
  })

  const handleChangeForm = useMemoizedFn(() => {
    if (block.current) return
    setTestDataId(null)
    setCorrectResult('')
    setRemarks('')
  })

  const handleChangeJson = useMemoizedFn((newValue: string) => {
    if (block.current) return
    setTestDataId(null)
    setCorrectResult('')
    setRemarks('')
    setNowJson(newValue)
    setJson(newValue)
  })

  const handleTestDataChange = useMemoizedFn(
    (value: string, data?: Record<string, any>) => {
      setTestDataId(value)

      setCorrectResult(data?.correct_result ?? '')
      setRemarks(data?.remarks ?? '')

      if (data) {
        block.current = true
        const inputs = omit(data, 'correct_result', 'remarks')
        if (startType === 'webhook') {
          handleChangeJson(JSON.stringify(inputs.start, null, 2))
        } else {
          form.setFieldsValue(inputs)
        }
      }

      wait(100).then(() => {
        block.current = false
      })
    },
  )

  const saveDataToTest = useMemoizedFn(async () => {
    let data = form.getFieldsValue(true)

    if (startType === 'webhook') {
      data = {
        start: nowJson,
      }
    }
    const res = await updateBatchTestData(flowId, data)
    if (!res) return
    setTestDataId(res.test_data_id)
    setTestOptionId(res.test_data_id)
    return res
  })

  const handleRun = useMemoizedFn(async () => {
    if (testDataId || !saveToTest) return
    await saveDataToTest()
    onSaveToTest?.()
  })

  const handleClear = useMemoizedFn(async () => {
    form.resetFields()
    setNowJson('')
    setSaveToTest(false)
    setTestDataId('')
  })

  useEffect(() => {
    setNowJson(webhookJson)
    setJson(webhookJson)
  }, [webhookJson])

  useEffect(() => {
    if (flowLog?.test_data_id) {
      setTestDataId(flowLog.test_data_id)
    }
  }, [flowLog?.test_data_id])

  useEffect(() => {
    return subscribeRun(handleRun)
  }, [])

  useEffect(() => {
    return subscribeClear(handleClear)
  }, [])

  return (
    <div className='w-full h-full flex flex-col'>
      <OverlayScrollbarsComponent
        className='w-full h-full px-16px'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {startType === 'form' && !!startConfig.length && (
          <TestDataSelect
            className='mb-24px'
            flowId={flowId}
            value={testDataId}
            optionId={testOptionId}
            onChange={handleTestDataChange}
            popWidth={diff ? 944 : 544}
          />
        )}

        {startType === 'form' && (
          <NodeForm
            form={form}
            schema={transformConfig2SchemaConfig(startConfig)}
            onValuesChange={handleChangeForm}
          />
        )}

        {startType === 'webhook' && (
          <div className='flex flex-col h-full'>
            <Webhook style={{ padding: 0, marginBottom: 20 }} />
            <div className='flex-1'>
              <CodeEditor
                width='100%'
                theme='tomorrow'
                mode='json'
                setOptions={{
                  maxLines: 31,
                  hasCssTransforms: false,
                }}
                placeholder='填写JSON参数，模拟Webhook调用'
                copy
                onCopy={handleCopy}
                value={nowJson}
                onChange={handleChangeJson}
              />
            </div>
          </div>
        )}
      </OverlayScrollbarsComponent>

      {!testDataId && !!startConfig.length && (
        <div className='flex mt-8px px-16px'>
          <Checkbox
            className='mb-0px!'
            checked={saveToTest}
            onChange={handleSaveToTest}
          >
            运行结束后，把输入加入测试集
          </Checkbox>
        </div>
      )}
    </div>
  )
})
