import type { ReactNode } from 'react'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

interface OptionPosition {
  x: number
  y: number
}

interface OptionData {
  icon: ReactNode
  name: string
  description: string
}

interface MovableOptionStore {
  data: OptionData | null
  visible: boolean
  position: OptionPosition
  init: (pos: OptionPosition) => void
  show: () => void
  hide: () => void
  setPosition: (pos: OptionPosition) => void
  setData: (data: OptionData) => void
}

export const useMovableOptionStore = create<MovableOptionStore>(
  (set, _get) => ({
    data: null,
    visible: false,
    position: { x: 0, y: 0 },
    setPosition: pos => {
      set({
        position: pos,
      })
    },
    init: pos => {
      set({
        visible: true,
        position: pos,
      })
    },
    show: () => {
      set({
        visible: true,
      })
    },
    hide: () => {
      set({
        visible: false,
      })
    },
    setData: data => {
      set({
        data,
      })
    },
  }),
)

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('movableOptionStore', useMovableOptionStore)
}
