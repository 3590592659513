import { memo } from 'react'
import { Modal } from '@/components'
import type { PromptGenerateProps } from '@/features/prompt/components/PromptStructEditor/PromptGenerate.tsx'
import { PromptGenerate } from '@/features/prompt/components/PromptStructEditor/PromptGenerate.tsx'

interface PromptGenerateModalProps
  extends Omit<PromptGenerateProps, 'onClose'> {
  open?: boolean
  onCancel?: () => void
}

export const PromptGenerateModal = memo<PromptGenerateModalProps>(props => {
  const {
    title,
    type,
    value,
    listForm,
    onChange,
    currentPrompt,
    onPromptReplace,
    open,
    skills,
    onCancel,
    placeholderMap,
  } = props
  return (
    <Modal
      title={null}
      footer={null}
      styles={{
        body: {
          padding: 0,
        },
      }}
      closable={false}
      width='auto'
      open={open}
      onCancel={onCancel}
    >
      <PromptGenerate
        title={title}
        type={type}
        onClose={onCancel}
        value={value}
        skills={skills}
        listForm={listForm}
        onChange={onChange}
        currentPrompt={currentPrompt}
        onPromptReplace={onPromptReplace}
        placeholderMap={placeholderMap}
      />
    </Modal>
  )
})

PromptGenerateModal.displayName = 'PromptGenerateModal'
