import classNames from 'classnames'
import { Tooltip } from 'antd'
import { memo, useMemo, useState } from 'react'
import { NodeType } from '..'
import { MorePopover } from '../../components'
import { IconFont, TextWithTooltip } from '@/components'
import { useNodeRemoveConfirm } from '../hooks/useNodeRemoveConfirm'
import { EDIT_TITLE_THEME, EditTitle } from './EditTitle'

export interface Operation {
  icon: string
  name: string
  tooltip?: React.ReactNode
  action?: (...args: any[]) => void
  hide?: boolean
  ignoreHoverControl?: boolean
  children?: Operation[]
  text?: string
  disabled?: boolean
}

export interface NodeHeaderProps {
  onDoubleClick?: () => void
  getPopupContainer?: () => HTMLElement
  error?: boolean
  style?: React.CSSProperties
  icon?: string
  name: string
  disabled?: boolean
  value: string
  nodeId: string
  onSave?: (value: string) => void
  onStatusChange?: (status: 'error' | 'normal') => void
  nodeType: NodeType
  operations?: Array<Operation>
  isFocusWithin: boolean
  nodeEnable: boolean
  nodeElement: HTMLElement | null
}

export const NodeHeader = memo((props: NodeHeaderProps) => {
  const {
    error = false,
    style,
    icon,
    name,
    disabled,
    value,
    nodeId,
    nodeType,
    operations = [],
    getPopupContainer,
    onSave,
    onDoubleClick,
    onStatusChange,
    isFocusWithin,
    nodeEnable,
    nodeElement,
  } = props

  const [nameInputFocused, setNameInputFocused] = useState(false)
  const onFocus = () => {
    setNameInputFocused(true)
  }
  const onBlur = () => {
    setNameInputFocused(false)
  }

  const { beforeRemove, cancel } = useNodeRemoveConfirm(nodeId)

  const onDeleteConfirm = () => {
    onStatusChange?.('error')
    beforeRemove()
  }

  const onDeleteCancel = () => {
    onStatusChange?.('normal')
    cancel()
  }

  const isWhiteBG = useMemo(
    () => [NodeType.PLUGIN, NodeType.TEMPLATE].includes(nodeType),
    [nodeType],
  )

  const theme = useMemo(() => {
    if (isWhiteBG) {
      return {
        icon: 'text-[rgba(98,105,153,0.6)]',
        operation: nodeEnable ? '!hover:bg-#626999 !hover:bg-op-8' : '',
        border: '!b-b-0px',
      }
    } else {
      return {
        icon: 'text-white',
        border: '',
      }
    }
  }, [isWhiteBG, nodeEnable])

  const iconEle = useMemo(() => {
    return icon?.startsWith('http') ? (
      <img
        className={classNames('w-[24px] h-[24px] rounded-[6px] object-contain')}
        src={icon}
      />
    ) : (
      <IconFont
        className={classNames('text-[15px] text-white', {
          '!text-#17171D': isWhiteBG,
        })}
        name={icon || ''}
      />
    )
  }, [nodeType, nodeEnable, icon, isWhiteBG])
  return (
    <div
      onDoubleClick={onDoubleClick}
      className={classNames(
        'py-18 pl-18 pr-8 h-[40px] rounded-t-8px flex justify-between items-center text-white text-[14px] b--1 b-b-none relative',
        theme.border,
        {
          '!border-error': error,
          'op-40': !nameInputFocused && !nodeEnable,
          'hover:op-100': !nodeEnable,
        },
      )}
      style={style}
    >
      <div className='flex items-center'>
        {iconEle}
        <TextWithTooltip
          className={classNames('ml-8 mr-8 truncate', {
            '!text-#17171D': isWhiteBG,
            'font-500': isWhiteBG,
          })}
          text={name}
          maxWidth={nodeEnable ? 126 : 54}
        />
        <EditTitle
          theme={isWhiteBG ? EDIT_TITLE_THEME.BLACK : EDIT_TITLE_THEME.WHITE}
          nodeId={nodeId}
          value={value}
          disabled={disabled}
          onSave={onSave}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      <div className='flex items-center justify-between gap-8px'>
        {operations
          .filter(v => !v.hide)
          .map(
            ({
              icon,
              name,
              tooltip,
              action,
              ignoreHoverControl,
              children,
              disabled: nodeDisabled,
            }) => {
              switch (name) {
                case 'more':
                  return (
                    <MorePopover
                      key={name}
                      nodeType={nodeType}
                      disabled={nodeDisabled}
                      onDeleteConfirm={onDeleteConfirm}
                      content={children?.filter(item => !item.hide) || []}
                      onDeleteCancel={onDeleteCancel}
                      nodeEnable={nodeEnable}
                      getPopupContainer={() => nodeElement!}
                    >
                      <span
                        className={classNames(
                          'relative w-24px h-24px flex-center b-white hover:bg-#17171D hover:bg-op-12 rounded-4px cursor-pointer after:content-[""] hover:after:absolute hover:after:left--8px hover:after:top-0 hover:after:w-40px hover:after:h-[calc(100%+8px)]',
                          theme.operation,
                          {
                            'hidden group-hover:flex-inline':
                              !ignoreHoverControl && !isFocusWithin,
                            'cursor-not-allowed': nodeDisabled,
                          },
                        )}
                        onClick={e => {
                          e.stopPropagation()
                          if (!nodeDisabled) {
                            action?.()
                          }
                        }}
                        onDoubleClick={e => e.stopPropagation()}
                      >
                        <IconFont
                          name={icon}
                          className={classNames('text-15px', theme.icon, {
                            'cursor-not-allowed': nodeDisabled,
                          })}
                        />
                      </span>
                    </MorePopover>
                  )
                default:
                  return (
                    <Tooltip
                      key={name}
                      title={tooltip}
                      getPopupContainer={getPopupContainer}
                      arrow={false}
                    >
                      <span
                        className={classNames(
                          'w-24px h-24px flex-center b-white rounded-4px cursor-pointer',
                          theme.operation,
                          {
                            'hover:bg-#17171D hover:bg-op-12': nodeEnable,
                            'hidden group-hover:flex-inline':
                              !ignoreHoverControl && !isFocusWithin,
                            'cursor-not-allowed': nodeDisabled,
                          },
                        )}
                        onClick={e => {
                          e.stopPropagation()
                          if (nodeEnable && !nodeDisabled) {
                            action?.()
                          }
                        }}
                        onDoubleClick={e => e.stopPropagation()}
                      >
                        <IconFont
                          name={icon}
                          className={classNames('text-15px', theme.icon, {
                            'op-40 cursor-not-allowed': !nodeEnable,
                          })}
                        />
                      </span>
                    </Tooltip>
                  )
              }
            },
          )}
      </div>
      {isWhiteBG && (
        <div className='bg-[rgba(225,225,229,0.6)] absolute w-[100%] h-[1px] bottom-[-1px] left-[0px]' />
      )}
    </div>
  )
})
