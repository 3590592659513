import NiceModal from '@ebay/nice-modal-react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { useCallback, useEffect, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useLocation, useNavigate } from 'react-router-dom'
import type {
  AgentListSystemSkillItem,
  AgentPluginSkill,
} from '@bty/chat-types'
import {
  deleteAgentSkillFunction,
  enableAgentSkillFunction,
  fetchAgentFunctionList,
} from '@/apis/agent.ts'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { getPluginPageConfig } from '@/apis/plugins'
import { IS_NEW_PLUGINS, PLUGIN_DISPLAYNAME } from '@/constants/common'
import { Button, IconFont, Input } from '@/components'
import { SkillEditorModal } from '@/features/agent/SkillEditModal/Modal.tsx'
import type { SkillValue } from '@/features/agent/SkillTabs/SkillListItem'
import { SkillListItem } from '@/features/agent/SkillTabs/SkillListItem'
import { useModal } from '@/hooks/useModal.tsx'
import EmptyImage from '@/assets/app/rocket2.png'
import { PluginListRouteAction, type SkillBaseInfo } from '../types'
import { Empty } from '@/components/empty'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { SkillConfModal } from './SkillConfModal'

export interface TipDataProps {
  content: string[]
  label: string
}

interface SkillsConfigProps {
  flowInfo: ApplicationBodyType
  list: Array<SkillBaseInfo & { meta: AgentListSystemSkillItem }>
  selectedSkillMap: Record<'flow' | 'tool', Record<string, true>>
  onListChange: () => void
  handleTipsData: (value: TipDataProps[]) => void
}

export function PluginList({
  flowInfo,
  list,
  onListChange,
  selectedSkillMap,
  handleTipsData,
}: SkillsConfigProps) {
  const { updateStatistics, refreshAgentUploadLoadSupportFileTypes } =
    useAgentEdit()

  const location = useLocation()
  const navigate = useNavigate()

  const { data: pluginDate } = useRequest(getPluginPageConfig)
  const [allPluginList, setAllPluginList] = useState([])
  const [skillEditorModal] = useModal(SkillEditorModal)

  const [newToolList, setNewToolList] = useState<AgentPluginSkill[]>([])

  useEffect(() => {
    if (pluginDate?.length > 0) {
      setAllPluginList(
        pluginDate
          .map((item: any) => ({
            ...item,
            child: item.child?.filter(
              (v: any) =>
                v.title !== '知乎内容搜索' &&
                v.title !== 'Bilibili实时热榜' &&
                v.title !== '抖音实时热榜' &&
                v.title !== 'Google搜索',
              // v.title !== 'Bilibili视频搜索' &&
              // v.title !== '视频评论获取',
            ),
          }))
          .filter((v: any) => !['今日头条', '微博', '知乎'].includes(v.title)),
      )
    }
  }, [pluginDate])

  const { runAsync: enableAgentSkillFunctionApi } = useRequest(
    enableAgentSkillFunction,
    { manual: true },
  )

  const { runAsync: deleteAgentSkillFunctionApi } = useRequest(
    deleteAgentSkillFunction,
    { manual: true },
  )

  const { data: toolListTotal } = useRequest(fetchAgentFunctionList)

  const handleSetNewToolList = useCallback(() => {
    const storageNewPluginMap = JSON.parse(
      localStorage.getItem(IS_NEW_PLUGINS) || '{}',
    )

    setNewToolList(
      (toolListTotal || []).filter(
        item =>
          item.is_new &&
          item.type === 'system' &&
          !storageNewPluginMap[item.function_id],
      ),
    )
  }, [toolListTotal])

  useEffect(() => {
    handleSetNewToolList()
  }, [toolListTotal])

  const handleNewPluginStorage = useCallback(() => {
    const storageNewPluginMap = JSON.parse(
      localStorage.getItem(IS_NEW_PLUGINS) || '{}',
    )
    newToolList.forEach(item => (storageNewPluginMap[item.function_id] = true))
    localStorage.setItem(IS_NEW_PLUGINS, JSON.stringify(storageNewPluginMap))
  }, [newToolList])

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteAgentSkillFunctionApi(id, flowInfo.flowId, flowInfo.versionId)
      onListChange()
      refreshAgentUploadLoadSupportFileTypes()

      updateStatistics()
    },
    [flowInfo.flowId, flowInfo.versionId],
  )

  const handleCreate = useMemoizedFn((fileProcess?: boolean) => {
    skillEditorModal.open({
      fileProcess: fileProcess === true,
      toolListTotal,
      allPluginList,
      flowInfo,
      selectedSkillMap,
      activeSkillType: 'Plugin',
      onCancel: () => {
        handleNewPluginStorage()
        handleSetNewToolList()
      },
      onDelete: (id: string) => handleDelete(id),
      onFinish: (res: any, notClose?: boolean) => {
        handleNewPluginStorage()
        handleSetNewToolList()
        handleTipsData(res)
        onListChange()
        refreshAgentUploadLoadSupportFileTypes()
        !notClose && skillEditorModal.close()

        updateStatistics()
      },
    })
  })

  const handleEdit = useCallback(
    (skill: SkillValue) => {
      const plugin = skill as SkillBaseInfo & {
        meta: AgentListSystemSkillItem
      }
      NiceModal.show(SkillConfModal, {
        type: 'Plugin',
        identifier: plugin.id,
        conf: plugin.meta,
        getExtraParams: () => ({
          agent_id: flowInfo.flowId,
          version_id: flowInfo.versionId,
        }),
        onOk: onListChange,
      })
    },
    [flowInfo.flowId, flowInfo.versionId],
  )

  const handleEnableChange = useCallback(
    async (enabled: boolean, skill: SkillValue) => {
      await enableAgentSkillFunctionApi({
        flow_id: flowInfo.flowId,
        version_id: flowInfo.versionId,
        function_id: skill.id,
        enabled,
      })
      onListChange()
    },
    [flowInfo.flowId, flowInfo.versionId],
  )

  useEffect(() => {
    const action = location.state?.action
    if (action) {
      navigate(location.pathname, {
        replace: true,
        state: {},
      })
    }
    switch (action) {
      case PluginListRouteAction.OpenAddPluginModal:
        setTimeout(() => handleCreate(true), 300)
        break

      case PluginListRouteAction.ShowTipsData:
        handleTipsData(location.state.tipsData)
        break
    }
  }, [location, navigate, handleCreate, handleTipsData])

  const [searchKey, setSearchKey] = useState<string>()

  if (!list?.length) {
    return (
      <Empty
        text={`添加${PLUGIN_DISPLAYNAME}，让 Agent 做更多的事情`}
        btnText={`添加${PLUGIN_DISPLAYNAME}`}
        image={EmptyImage}
        onCreate={handleCreate}
      />
    )
  }

  const filteredList = searchKey
    ? list.filter(el => el.name.includes(searchKey))
    : list

  return (
    <div className='p-24 pt-12 flex flex-col h-full'>
      <h2 className='flex items-center h-36px text-16px font-medium text-font shrink-0'>
        插件
        <Input
          className='rounded-8px ml-auto w-140px'
          placeholder='搜索'
          prefix={<IconFont className='text-16px' name='search' />}
          onChange={event => {
            const searchKey = event.target.value
            setSearchKey(searchKey)
          }}
        />
        <span className='mx-12 px-4 h-16px border-right after:right-4 after:border-line' />
        <Button
          className='!p-0 w-96px'
          type='primary'
          onClick={() => handleCreate()}
        >
          <IconFont name='add' />
          <span className='ml-4'>添加插件</span>
        </Button>
      </h2>
      <OverlayScrollbarsComponent
        className='flex-1 mt-20'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {filteredList.map(skill => (
          <SkillListItem
            showRemovalNotice={
              Array.isArray(toolListTotal)
                ? toolListTotal.findIndex(v => v.function_id === skill.id) ===
                  -1
                : false
            }
            key={skill.id}
            type='Plugin'
            skill={skill}
            onEdit={handleEdit}
            onEnableChange={handleEnableChange}
            onDelete={handleDelete}
          />
        ))}
      </OverlayScrollbarsComponent>
    </div>
  )
}
