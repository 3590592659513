import { Button, Form, Popover } from 'antd'
import { useMemo } from 'react'
import type { PopoverProps } from 'antd'
import { FormItem, IconFont, Input, Select } from '@/components'
import { Track } from '@/features/track'

export interface IntentRecordPopoverProps {
  open?: boolean
  onSave?: (values: IntentRecordPopoverProps['value']) => void
  onCancel?: () => void
  options?: Array<{ label: string; value: string }>
  value: { query: string; intent: string }
  children?: React.ReactNode
  getPopupContainer?: PopoverProps['getPopupContainer']
}
export function IntentRecordPopover(props: IntentRecordPopoverProps) {
  const { value, options, open, children, getPopupContainer } = props
  const [form] = Form.useForm<IntentRecordPopoverProps['value']>()
  const onSave = (values: IntentRecordPopoverProps['value']) => {
    props.onSave?.(values)
  }

  const onCancel = () => {
    props.onCancel?.()
  }

  const renderPopoverTitle = useMemo(() => {
    return (
      <div className='w-100% flex items-center justify-around'>
        <span>录入样本数据</span>
        <span
          className='ml-auto text-16px w-16px h-16px cursor-pointer'
          onClick={() => {
            onCancel()
          }}
        >
          <IconFont name='guanbi' />
        </span>
      </div>
    )
  }, [onCancel])

  const renderPopoverContent = useMemo(() => {
    return (
      <div className='w-100%'>
        <p className='text-12px/20px c-font_1 text-op-60'>
          录入用户问题和意图数据，可在“样本数据”中查看
        </p>
        <div className='mt-16px'>
          <div className='flex-1'>
            <Form
              form={form}
              layout='vertical'
              className='[&_.keyu-form-item-label]:text-12px/16px [&_.keyu-form-item-label]:font-medium'
              initialValues={value}
            >
              <FormItem
                label='用户问题'
                name='query'
                rules={[{ required: true, message: '请输入用户问题' }]}
              >
                <Input
                  type='text'
                  size='small'
                  placeholder='请输入用户问题'
                ></Input>
              </FormItem>
              <FormItem
                label='所属分类'
                name='intent'
                className='w-100% flex-1'
                rules={[{ message: '请选择所属分类' }]}
              >
                <Select
                  className='nodrag nopan w-100% flex-1'
                  placeholder='请选择所属分类'
                  size='small'
                  options={options}
                />
              </FormItem>
            </Form>
            <div className='flex justify-end'>
              <Button
                className='text-12px/16px'
                onClick={() => {
                  onCancel()
                }}
              >
                取消
              </Button>
              <Track
                event='intent_sample_data_add'
                properties={{
                  actionType: 'intent_result_panel_sample_data_add',
                  name: '意图节点结果面板录入样本数据',
                }}
              >
                <Button
                  type='primary'
                  className='ml-8px text-12px/16px'
                  onClick={() => {
                    form.validateFields().then(values => {
                      onSave(values)
                    })
                  }}
                >
                  保存
                </Button>
              </Track>
            </div>
          </div>
        </div>
      </div>
    )
  }, [value, form, onSave, onCancel, options])

  return (
    <div>
      <Popover
        arrow={false}
        open={open}
        destroyTooltipOnHide
        getPopupContainer={getPopupContainer}
        overlayClassName='nodrag nopan w-420px z-100 bg-#fff [&_.ant-popover-inner]:p-16px !rounded-8px'
        placement='bottomLeft'
        title={renderPopoverTitle}
        content={renderPopoverContent}
      >
        {children}
      </Popover>
    </div>
  )
}
