import NiceModal, { useModal } from '@ebay/nice-modal-react'
import styled from '@emotion/styled'
import { Modal } from '@/components'
import payModalBg from '@/assets/order/payModalBg.png'
import type { RoleCode } from '@/apis/authority'
import { LimitedPanel } from './LimitedPanelContent'

const StyledOrderModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    width: 1120px;
    overflow-x: auto;
  }
`

export interface LimitedModalProps {
  closable?: boolean
  role?: RoleCode
  showVersions?: string[]
  // navigate?: NavigateFunction
  onCancel?: () => void
}

export const LimitedModal = NiceModal.create((props: LimitedModalProps) => {
  const { onCancel, closable = true } = props

  const modal = useModal()
  const handleCancel = () => {
    onCancel?.()
    modal.hide()
  }

  return (
    <StyledOrderModal
      width='auto'
      open={modal.visible}
      footer={null}
      closeIcon={false}
      closable={closable}
      maskClosable={false}
      destroyOnClose
      onCancel={handleCancel}
      zIndex={1032}
      styles={{
        body: {
          padding: 0,
          backgroundImage: `url(${payModalBg})`,
          backgroundSize: '110%',
          backgroundPosition: 'left -50px top -37px',
          backgroundRepeat: 'no-repeat',
          height: 'calc(100vh - 48px)',
          overflowY: 'hidden',
          maxWidth: '1120px',
        },
      }}
    >
      <LimitedPanel
        closeModal={handleCancel}
        showVersions={props.showVersions}
      />
    </StyledOrderModal>
  )
})

export function useLimitedModal() {
  const modal = useModal(LimitedModal)
  return [{ ...modal, open: modal.show }]
}

export const LimitedModalId = 'limited-modal'
NiceModal.register(LimitedModalId, LimitedModal)
