import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useRef } from 'react'
import {
  AppDetailTabs,
  tabsMap,
  type tabsType,
} from '@/pages/application/constant'
import type { ApplicationBodyType } from '@/apis/application'
import type { nodeConfigType } from '@/apis/run'
import { useVersionStore, useWorkspaceStore } from '@/store'
import type { DetailTabItem, DropdownTabItem } from '@/components'
import { useLimitedAccessModal } from '@/features/pay/LimitedAccess'
import { useVersion } from '@/hooks/useVersion'
import { RoleCode } from '@/apis/authority'
import useQueryParams from '@/hooks/useQueryParams'

export interface GenTabsOptions {
  tabs: tabsType[]
  data?: ApplicationBodyType
  firstNodeConfig: nodeConfigType | null
  handleBeforeRun: () => Promise<boolean>
}

const originTabs = Object.keys(AppDetailTabs).map(k => ({
  key: k,
  data: AppDetailTabs[k as tabsType],
}))

export function useFlowDetailTabs(options: GenTabsOptions) {
  const { tabs, data, firstNodeConfig, handleBeforeRun } = options
  const lastTabQuery = useRef('')
  const lastGroupKey = useRef('')
  const { versionInfo } = useVersionStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParams = useQueryParams()

  const { isTeam } = useVersion()
  const { currentWorkspaceItem } = useWorkspaceStore(state => ({
    currentWorkspaceItem: state.currentWorkspaceItem,
  }))

  const [LimitedAccessModal] = useLimitedAccessModal({
    hideShowUpdateModal: !(
      currentWorkspaceItem?.roleCode === RoleCode.ADMINISTRATOR
    ),
  })

  const defaultKey = searchParams.get('tab') || undefined

  const displayTabs: DetailTabItem[] = useMemo(() => {
    if (!data || !firstNodeConfig) {
      return []
    }
    const filterGroupItemKeys = originTabs
      .filter(
        item =>
          item.data.group === lastGroupKey.current && !item.data.keepAlive,
      )
      .map(o => o.key)
      .filter(k => k !== lastTabQuery.current)
    const filterTabs = tabs
      .filter(k => !filterGroupItemKeys.includes(k))
      .map(tab => {
        return {
          beforeChange: () => {
            if (AppDetailTabs[tab].needUpgradeToEnterprise && isTeam) {
              LimitedAccessModal.open({
                title: `团队版无法设置${AppDetailTabs[tab].label}，请升级到企业版使用。`,
              })
              return Promise.resolve(false)
            }
            return Promise.resolve(true)
          },
          title: AppDetailTabs[tab].label,
          keepAlive: AppDetailTabs[tab].keepAlive,
          hide:
            AppDetailTabs[tab].keepAlive &&
            lastGroupKey.current === AppDetailTabs[tab].group &&
            lastTabQuery.current !== tab,
          group:
            tab === lastTabQuery.current ||
            lastGroupKey.current === AppDetailTabs[tab].group
              ? undefined
              : AppDetailTabs[tab].group,
          key: tab,
          label:
            tab !== lastTabQuery.current &&
            AppDetailTabs[tab].group &&
            AppDetailTabs[tab].icon ? (
              <div className='flex items-center justify-between gap-8px p-8px text-14px'>
                {AppDetailTabs[tab].icon}
                {AppDetailTabs[tab].label}
              </div>
            ) : (
              AppDetailTabs[tab].label
            ),
          content: AppDetailTabs[tab].render({
            data,
            firstNodeConfig,
            handleBeforeRun,
          }),
        }
      })
      .filter(tab => tabsMap[firstNodeConfig.first_node_type].includes(tab.key))
      .reduce<DetailTabItem[]>((pre, curr) => {
        const { group } = curr
        if (!group) {
          return pre.concat(curr)
        }
        const item = pre.find(t => t.key === group)
        return item
          ? pre.map(t =>
              t.key === group
                ? { ...t, options: (t as DropdownTabItem).options.concat(curr) }
                : t,
            )
          : pre.concat({ key: group, type: 'dropdown', options: [curr] })
      }, [])
    return filterTabs
  }, [firstNodeConfig, data, tabs, versionInfo, handleBeforeRun, searchParams])

  const onTabChange = (e: string) => {
    const currentTabQuery = searchParams.get('tab')
    const currentTabItem = originTabs.find(
      item => item.key === currentTabQuery,
    )?.data
    const isCurrentTabOnGroup = !!currentTabItem?.group
    const nextTabItem = originTabs.find(item => item.key === e)?.data
    const isNextTabOnGroup = !!nextTabItem?.group
    if (currentTabQuery && e !== currentTabQuery) {
      if (!isNextTabOnGroup && isCurrentTabOnGroup) {
        lastTabQuery.current = currentTabQuery
        lastGroupKey.current = currentTabItem.group as string
      } else if (isNextTabOnGroup) {
        lastTabQuery.current = ''
        lastGroupKey.current = ''
      }
    }
    /**
     * 如果进来的 url 是 ?tab=log&a=1 的形式，就正常回显 url
     * 如果在切换的情况下, ?tab=log&a=1 切换到 log=api，就把 a=1 去掉
     */
    e &&
      setSearchParams(
        queryParams?.tab === e ? { ...queryParams, tab: e } : { tab: e },
      )
  }

  useEffect(() => {
    if (displayTabs.length && !searchParams.get('tab')) {
      const current = displayTabs[0]
      const tab =
        current.type === 'dropdown' ? current.options[0].key : current.key
      setSearchParams({
        tab,
      })
    }
  }, [displayTabs, searchParams])

  return {
    displayTabs,
    onTabChange,
    defaultKey,
  }
}
