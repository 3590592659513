import type { Edge } from 'reactflow'
import { useReactFlow } from 'reactflow'
import type { MouseEvent } from 'react'
import {
  useFlowInteractionStore,
  useFlowDraftStore,
  FLOW_DRAFT_LOCK_STATUS,
} from '@/store'
import { generateUniqueHandleStateId } from '@/features/flow/utils/handle'

export function useEdgeMouseEvents() {
  const { getNodes, getEdges } = useReactFlow()
  const { isAddMode, setInsertActiveEdgeId } = useFlowInteractionStore()
  const setActiveStates = useFlowInteractionStore(s => s.setActiveStates)
  const clearActiveStates = useFlowInteractionStore(s => s.clearActiveStates)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const onEdgeMouseEnter = (_e: MouseEvent, edge: Edge) => {
    if (lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK) {
      return
    }
    if (isAddMode) {
      setInsertActiveEdgeId(edge.id)
      return
    }
    const { source, target, sourceHandle } = edge
    const edges = getEdges()
    const isExist = !!edges.find(e => e.id === edge.id)
    if (!edge || !isExist) {
      console.error('当前边不存在')
      return
    }
    const nodes = getNodes()
    const handles = []
    const sourceNode = nodes.find(n => n.id === source)
    const targetNode = nodes.find(n => n.id === target)
    if (sourceNode) {
      handles.push(
        generateUniqueHandleStateId({
          nodeId: source,
          sourceHandle,
          type: 'source',
        }),
      )
    }
    if (targetNode) {
      handles.push(
        generateUniqueHandleStateId({ nodeId: target, type: 'target' }),
      )
    }
    setActiveStates({
      handles,
      edgeIds: [edge.id],
    })
  }

  const onEdgeMouseLeave = () => {
    if (isAddMode) {
      setInsertActiveEdgeId('')
      return
    }
    // 插入面板存在时不取消当前edge的激活态
    const panelDom = document.getElementById('insertNodePanel')
    if (!panelDom) {
      clearActiveStates()
    }
  }

  return {
    onEdgeMouseEnter,
    onEdgeMouseLeave,
  }
}
