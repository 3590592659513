import { useRequest } from 'ahooks'
import { message } from 'antd'
import { sample, sortBy } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { createApplication } from '@/apis/application'
import {
  getAddPluginToAgent,
  getAgentList,
  getSkillList,
  getPluginPageConfig,
  setPluginData,
  PluginDataType,
  PluginDataFrom,
} from '@/apis/plugins'
import { iconColors } from '@/constants/icon.ts'
import { avatars } from '@/features/agent/constant/base.ts'
import { getName } from '@/pages/application/utils.tsx'
import { useWorkspaceStore } from '@/store'
import { type ICardItem } from '../components/Card'
import { prompt } from './helpers'

interface PluginProps {
  openModal: () => void
}

export function useSelectAgent(functionId: string | undefined) {
  const { currentWorkspaceId } = useWorkspaceStore()
  const [agentList, setAgentList] = useState([]) as any
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!functionId) return

    setLoading(true)

    getAgentList({
      function_id: functionId,
      workspace_id: currentWorkspaceId,
    }).then(agentListResponse => {
      setLoading(false)
      const sortedAgentList = sortBy(agentListResponse, 'func_is_bind') || []
      setAgentList(sortedAgentList)
    })
  }, [functionId])

  const handleCreatePlugin = useCallback(async () => {
    const pluginData = await getSkillList({
      function_id: functionId,
    })
    if (pluginData.function_id) {
      const {
        name,
        type,
        metadata_config,
        parameters,
        description,
        function_id,
        display_name,
      } = pluginData

      const res: any = await createApplication(
        {
          icon: sample(avatars),
          color: iconColors[Math.min(Math.floor(Math.random() * 10), 5)],
          description: '暂无描述',
          name: getName(agentList || [], 'agent'),
          applicationType: 'AGENT',
          config: JSON.stringify({
            rule: {
              channel: 'GPT',
              model: 'gpt-3.5-turbo',
              related_questions_enabled: true,
              prompt_plugins: {
                time_prompt: true,
              },
              stream: true,
              temperature: 0.7,
              messages: [{ role: 'system', content: prompt }],
              welcome: '',
              rule_id: uuidv4(),
            },
            flows: [],
            dataset: [],
            utility: [
              {
                name,
                type,
                metadata: metadata_config,
                is_enable: true,
                parameters,
                description,
                function_id,
                display_name,
              },
            ],
          }),
        },
        currentWorkspaceId,
      )
      const tips = [
        { label: pluginData?.display_name, content: pluginData?.tips },
      ]
      sessionStorage.setItem('tipsData', JSON.stringify(tips))
      navigate(
        `/agent/${res.id}/design/plugin?source=${encodeURIComponent(
          '/plugin',
        )}`,
      )

      setPluginData(PluginDataType.add, function_id, PluginDataFrom.plugin)
    }
  }, [currentWorkspaceId, agentList, functionId])

  const handleAddPlugin = useCallback(
    async (flow_id: string, version_id: string, app_id: string) => {
      const res = await getAddPluginToAgent({
        flow_id,
        version_id,
        function_ids: [functionId],
      })
      if (res[0]?.id) {
        const tips = [{ label: res[0]?.display_name, content: res[0]?.tips }]
        sessionStorage.setItem('tipsData', JSON.stringify(tips))
        navigate(
          `/agent/${app_id}/design/plugin?source=${encodeURIComponent(
            '/plugin',
          )}`,
        )
        message.success('添加成功')
      }

      functionId &&
        setPluginData(PluginDataType.add, functionId, PluginDataFrom.plugin)
    },
    [currentWorkspaceId, functionId],
  )

  const addPluginSuccess = async (app_id: string) => {
    const pluginData = await getSkillList({
      function_id: functionId,
    })
    const tips = [
      { label: pluginData?.display_name, content: pluginData?.tips },
    ]
    sessionStorage.setItem('tipsData', JSON.stringify(tips))
    navigate(
      `/agent/${app_id}/design/plugin?source=${encodeURIComponent('/plugin')}`,
    )
  }

  return {
    loading,
    agentList,
    handleCreatePlugin,
    handleAddPlugin,
    addPluginSuccess,
  }
}

export function usePluginsPageConfig(props: PluginProps) {
  const [allList, setAllList] = useState() as any
  const [functionId, setFunctionId] = useState<string>()
  const navigate = useNavigate()

  const handleModal = useCallback(
    async (functionId: string) => {
      setFunctionId(functionId)
      props.openModal()
    },
    [functionId],
  )

  const { loading, data } = useRequest(getPluginPageConfig)

  useEffect(() => {
    if (!data) {
      return
    }

    setAllList(
      data
        .map((item: { child: Array<ICardItem> }) => ({
          ...item,
          child: item.child
            .map((c: ICardItem) => ({
              ...c,
              action: () => handleModal(c.rel_function_id),
              checkInfo: () =>
                navigate(
                  `/plugin/${c.rel_function_id}?pageType=infoWithSidebar`,
                ),
            }))
            .filter(
              (v: any) =>
                v.title !== '知乎内容搜索' &&
                v.title !== 'Bilibili实时热榜' &&
                v.title !== '抖音实时热榜' &&
                v.title !== 'Google搜索',
            ),
        }))
        .filter(
          (v: any) =>
            !['今日头条', '微博', '知乎', 'Bilibili'].includes(v.title),
        ),
    )
  }, [data, handleModal])

  return {
    allList,
    loading,
    functionId,
  }
}
