import styled from '@emotion/styled'
import { Button } from 'antd'

export const ActionButton = styled(Button)`
  border-color: #e1e1e5 !important;
  &:hover {
    background: rgba(98, 105, 153, 0.08) !important;
    color: #17171d !important;
  }
`

export const ExecuteBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  &:hover {
    background: #9980ff !important;
  }
  .ant-btn-icon {
    vertical-align: text-bottom;
  }
`
