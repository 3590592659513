import { useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { editApplication } from '@/apis/application'
import { CreateModal } from '@/components/createAppModal'
import { useModal } from '@/hooks/useModal.tsx'
import { useNodeStore } from '@/store'
import { fullscreenNodePanelType } from '@/features/nodes/base/const'
import { LoggerModal } from '@/features/logger/components/loggerModal'
import { LoggerListModal } from '@/features/logger/components/LoggerListModal'
import useQueryParams from '@/hooks/useQueryParams'
import { useSubSignal } from '@/hooks/useSignal'
import { SocketProvider } from '@/hooks/useSocket'
import { FlowHeader } from './components/Header'
import { FlowDraft } from './draft'
import { useApplicationInit } from './hooks/useApplicationInit'
import { useErrorNodesMarkByLogs } from './hooks/useErrorNodesMarkByLogs'
import { useFlowDraftInit } from './hooks/useFlowDraftInit'
import { useFlowDraftPublish } from './hooks/useFlowDraftPublish'
import { useFlowDraftRunDebug } from './hooks/useFlowDraftRunDebug'
import { useFlowExtraDataInit } from './hooks/useFlowExtraDataInit'
import { useModalInputInfo } from './hooks/useModalInputInfo'
import { usePageBack } from './hooks/usePageBack'
import { useVersionVisible } from './hooks/useVersionVisible'
import { useToolbarCollapse } from './hooks/useToolbarCollapse'
import { useSelectedVersion } from './hooks/useSelectedVersion'
import { TestTable } from './components/table'
import { PUB_FLOW_SIGNAL, RUN_FLOW_SIGNAL } from './const'

export default function FlowPage() {
  const queryParams = useQueryParams()

  const testTableOpened = useRef(false)
  const [
    testTableOpen,
    { setFalse: hideTestTable, toggle: toggleTestTableOpen },
  ] = useBoolean(false)
  const { activatedNodeId, activatedNodeType } = useNodeStore()

  const [testUpdateId, setTestUpdateId] = useState<string>()

  const { inputInfo } = useModalInputInfo()

  const { applicationInfo, onRefreshApp } = useApplicationInit()

  useFlowExtraDataInit()

  const { versionId, flowDataRes, fetchDraftFlow } = useFlowDraftInit()

  const {
    flowId,
    debugOpen,
    logsOpen,
    startDebug,
    showLogs,
    onClose,
    debugByTaskId,
  } = useFlowDraftRunDebug()

  const setLogs = useErrorNodesMarkByLogs()

  const { onOpenVersion, isShowVersion, onCloseVersion } = useVersionVisible()
  const {
    selectedVersion,
    onSelectedVersion,
    FlowVersionData,
    SelectedItemData,
    onBackCurrent,
    onRunFlowVersionList,
    handleRecoverVersion,
  } = useSelectedVersion(isShowVersion, flowDataRes, fetchDraftFlow)
  const { isPublishing, onPublish } = useFlowDraftPublish(
    versionId,
    onRefreshApp,
    onRunFlowVersionList,
  )

  const { onBackDetail } = usePageBack()
  const { collapsed, toolbarClassnames, onToolBarOpen, onToolBarClose } =
    useToolbarCollapse()

  const showHeader = useMemo(() => {
    if (queryParams.hiddenHeader) return false

    return (
      !activatedNodeId || !fullscreenNodePanelType.includes(activatedNodeType!)
    )
  }, [activatedNodeId, activatedNodeType])

  const [appBaseInfoModal] = useModal(CreateModal)
  const onAppInfoEdit = useMemoizedFn(() => {
    appBaseInfoModal.open({
      title: '编辑Flow信息',
      fieldConfig: {
        name: { placeholder: '请输入Flow名称' },
        description: {
          placeholder: '请输入Flow描述，这段文字将作为Flow的使用引导',
        },
      },
      logoType: 'emoji',
      defaultValue: applicationInfo,
      onFinish: async formData => {
        if (applicationInfo) {
          await editApplication({
            id: applicationInfo.id,
            applicationType: 'AI',
            ...formData,
          })
          onRefreshApp()
          appBaseInfoModal.close()
        }
      },
    })
  })

  useEffect(() => {
    // 当前页面禁止捏合缩放
    window.addEventListener(
      'wheel',
      e => {
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault()
        }
      },
      { passive: false },
    )
  }, [])

  const updateTestData = () => {
    setTestUpdateId(String(Date.now()))
  }

  const closeTestTable = () => {
    updateTestData()
    hideTestTable()
    onRefreshApp()
    fetchDraftFlow()
  }

  useSubSignal(RUN_FLOW_SIGNAL, startDebug)
  useSubSignal(PUB_FLOW_SIGNAL, onPublish)

  return (
    <SocketProvider
      url='/v1/ws/batch_test/execute/show_run_result'
      params={{ flow_id: flowId }}
    >
      <div className='h-100vh flex flex-col relative'>
        {showHeader && (
          <FlowHeader
            isPublishing={isPublishing}
            applicationInfo={applicationInfo}
            SelectedItemData={SelectedItemData}
            onBack={onBackDetail}
            onEditOpen={onAppInfoEdit}
            onRunTest={startDebug}
            onPublish={onPublish}
            onOpenVersion={onOpenVersion}
            onToolBarClose={onToolBarClose}
            onBackCurrent={onBackCurrent}
            handleRecoverVersion={handleRecoverVersion}
            onRunFlowVersionList={onRunFlowVersionList}
            testUpdateId={testUpdateId}
            onToggleTestTableOpen={() => {
              testTableOpened.current = true
              onClose()
              toggleTestTableOpen()
            }}
            onLogsOpen={showLogs}
          />
        )}
        <FlowDraft
          showHeader={showHeader}
          isShowVersion={isShowVersion}
          onCloseVersion={onCloseVersion}
          collapsed={collapsed}
          toolbarClassnames={toolbarClassnames}
          onToolBarOpen={onToolBarOpen}
          onToolBarClose={onToolBarClose}
          applicationInfo={applicationInfo}
          selectedVersion={selectedVersion}
          FlowVersionData={FlowVersionData}
          onSelectedVersion={onSelectedVersion}
          onRunFlowVersionList={onRunFlowVersionList}
          handleRecoverVersion={handleRecoverVersion}
        />

        {(testTableOpened.current || testTableOpen) && (
          <div
            className={classNames('absolute inset-0 bg-white', {
              '!hidden': !testTableOpen,
            })}
          >
            <TestTable
              show={testTableOpen}
              flowId={flowId}
              debugTask={debugByTaskId}
              onClose={closeTestTable}
            />
          </div>
        )}

        {logsOpen && (
          <LoggerListModal
            flowId={flowId}
            onReRun={debugByTaskId}
            onClose={onClose}
          />
        )}

        {debugOpen && (
          <LoggerModal
            showHeader={showHeader}
            title='运行调试'
            mode='debug'
            flowId={flowId}
            versionId={versionId}
            tabs={['input', 'output', 'log']}
            defaultTab='input'
            startType={inputInfo.type!}
            startConfig={inputInfo.config}
            onLog={setLogs}
            onSaveToTest={updateTestData}
            onClose={onClose}
          />
        )}
      </div>
    </SocketProvider>
  )
}
