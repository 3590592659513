interface HandleStateIdOptions {
  nodeId: string
  sourceHandle?: string | null
  type: 'source' | 'target'
}

export function generateUniqueHandleStateId({
  nodeId,
  sourceHandle,
  type,
}: HandleStateIdOptions) {
  return `${nodeId}__${sourceHandle || 'null'}__${type}`
}
