import { useCallback } from 'react'
import type { NodeMouseHandler } from 'reactflow'
import { useKeyPress } from 'reactflow'
import { useFlowDraftStore, useFlowInteractionStore } from '@/store'

export function useNodeClick() {
  const { onNodeClick } = useFlowDraftStore()
  const { isAddMode, clearActiveStates } = useFlowInteractionStore()

  const metaPressed = useKeyPress('Meta')
  const handleNodeClick: NodeMouseHandler = useCallback(
    (e, node) => {
      if (isAddMode) return
      clearActiveStates()
      onNodeClick(e, node, metaPressed)
    },
    [onNodeClick, metaPressed, isAddMode],
  )

  return {
    handleNodeClick,
  }
}
