type Position = [number, number]

export function getTextPositions(
  text: string,
  regex: RegExp,
): { start: Position; end: Position }[] {
  const result: { start: Position; end: Position }[] = []
  const lines = text.split('\n')

  for (let i = 0; i < lines.length; i++) {
    let match
    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(lines[i]))) {
      const startColumn = match.index

      console.log(match, 'match')
      const endColumn = match.index + match[0].length - 1
      const startPosition: Position = [i, startColumn]
      const endPosition: Position = [i, endColumn + 1]
      result.push({ start: startPosition, end: endPosition })
    }
  }

  return result
}

export function tryParseToJsonObject(text: string) {
  try {
    return JSON.parse(text)
  } catch {}

  return text
}

export function tryParseToObject(json: string) {
  if (typeof json === 'object') return json

  try {
    return JSON.parse(json)
  } catch (e) {
    return {}
  }
}
