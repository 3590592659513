import loadingPng from '@/assets/agent/circle.png'
import { AppLogo, IconFont } from '@/components'

interface TaskBarProps {
  status: 'success' | 'error' | 'loading'
  icon: string
  color: string
  title: string
  type?: 'icon' | 'emoji' | 'image'
  onClick?: () => void
}

export function TaskBar({
  status,
  icon,
  color,
  title,
  onClick,
  type = 'icon',
}: TaskBarProps) {
  const statusMap = {
    success: 'chenggongbaocun',
    error: 'cuowu',
    loading: 'icon-loading',
  }

  return (
    <div
      className='flex w-full w-300px items-center justify-between px-16px cursor-pointer py-8px bg-white hover:bg-bg_3 hover:bg-opacity-8 rounded-8px'
      onClick={onClick}
    >
      <div className='flex items-center'>
        <AppLogo
          type={type}
          value={icon}
          className='!rounded-4px'
          color={color}
          fillSize={16}
          size={26}
        />
        <p className='ml-8px'>{title}</p>
      </div>
      {status === 'loading' ? (
        <img className='w-15px h-15px animate-spin' src={loadingPng}></img>
      ) : (
        <IconFont name={statusMap[status]} className='font-700' />
      )}
    </div>
  )
}
