import { useRef } from 'react'
import { Tooltip } from 'antd'
import styled from '@emotion/styled'
import { useHover } from 'ahooks'
import { useAuth } from '@bty/react-auth'
import { RoleCode } from '@/apis/authority.ts'
import { ResponsiveCard } from '@/components/list/ResponsiveCardGrid.tsx'
import { IconFont } from '@/components'

const CardWrapper = styled(ResponsiveCard)`
  height: 184px;
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 16px;
  border-radius: 12px;
  opacity: 1;
  background: #ffffff;
  cursor: pointer;
  overflow: hidden;

  .myActBtn {
    position: relative;
    top: 60px;
    right: 74px;
    transition: top 0.3s;
    will-change: top;
    width: 0;
    overflow: visible;
  }

  :hover {
    box-shadow: 0px 8px 24px 0px rgba(23, 23, 29, 0.12);

    .myActBtn {
      top: 0px;
    }
  }
  .ant-tooltip-content {
    left: 80% !important;
  }
`

export interface ICardItem {
  description: string
  title: string
  avatar?: string
  rel_function_id: string
  action: () => void
  checkInfo: () => void
  extra?: any
  tooltip?: any
}
interface ICardProps extends Partial<ICardItem> {
  className?: string
}

export function Card(props: ICardProps) {
  const {
    className,
    description,
    title,
    checkInfo,
    action,
    avatar = 'https://resource.bantouyan.com/betteryeah/agent/plugins/default.png',
  } = props
  const {
    state: { role },
  } = useAuth()

  const ref = useRef(null)

  const isHovering = useHover(ref)

  return (
    <CardWrapper onClick={checkInfo} className={className} ref={ref}>
      <div className='flex justify-between'>
        <img className='w-36px h-36px rounded-8px' src={avatar} alt='' />
      </div>

      <div className='flex justify-between items-center'>
        <div className='flex flex-col mt-16px'>
          <p className='c-#17171D font-600 text-16px line-height-16px'>
            {title}
          </p>
          {(description ?? '').length >= 34 ? (
            <Tooltip title={description} placement='top'>
              <p className='multi-line-text mt-12px text-13px line-height-20px c-[#8d8d99]'>
                {description}
              </p>
            </Tooltip>
          ) : (
            <p className='multi-line-text mt-12px text-13px line-height-20px c-[#8d8d99]'>
              {description}
            </p>
          )}
          <p className='absolute left-[20px] bottom-[16px] gap-4px flex cursor-pointer mt-12px h-16px text-13px font-500 line-height-16px color-primary'>
            {isHovering && role !== RoleCode.VIEWER ? (
              <div
                className='flex flex-row gap-[4px]'
                onClick={e => {
                  action?.()
                  e.stopPropagation()
                }}
              >
                <span>立即使用</span>
                <IconFont name='qianwang' />
              </div>
            ) : (
              ''
            )}
          </p>
        </div>
      </div>
    </CardWrapper>
  )
}
