import { Popover, Tooltip } from 'antd'
import { useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import type { Operation } from '../../base/components/NodeHeader'
import { IconFont } from '@/components'
import { DeletePopover } from '..'
import { NodeType } from '../../base'

interface MorePopoverProps {
  children: React.ReactNode
  content: Operation[]
  nodeEnable: boolean
  disabled?: boolean
  onDeleteConfirm: () => void
  onDeleteCancel: () => void
  nodeType: NodeType
  getPopupContainer?: () => HTMLElement
}

export function MorePopover(props: MorePopoverProps) {
  const {
    children,
    content,
    // nodeEnable,
    onDeleteConfirm,
    onDeleteCancel,
    nodeType,
    disabled,
    getPopupContainer,
  } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [openDeletePopover, setOpenDeletePopover] = useState<boolean>(false)

  const title = useMemo(() => {
    if (nodeType === NodeType.CONDITION || nodeType === NodeType.INTENT) {
      return '连接的所有分支和节点，都会被删除，是否确认删除'
    }
    if (nodeType === NodeType.LOOP) {
      return '所有循环内节点都会被删除，是否确认删除'
    }
    return '请确认是否要删除节点?'
  }, [nodeType])
  return (
    <div ref={ref}>
      <Popover
        zIndex={2200}
        arrow={false}
        open={open}
        onOpenChange={e => !disabled && !openDeletePopover && setOpen(e)}
        overlayClassName='top-0! right--104px! inset-unset!'
        overlayInnerStyle={{ width: 96, padding: 0 }}
        getPopupContainer={getPopupContainer || (() => ref.current!)}
        content={
          <div className='p-4px op-100!'>
            {content.map(({ name, tooltip, action, icon, text, disabled }) =>
              name === 'delete' ? (
                <DeletePopover
                  key={name}
                  title={title}
                  onDelete={() => action?.()}
                  onOpen={() => {
                    setOpenDeletePopover(true)
                    onDeleteConfirm()
                  }}
                  onClose={() => {
                    setOpenDeletePopover(false)
                    onDeleteCancel()
                  }}
                >
                  <Tooltip
                    zIndex={2400}
                    placement='left'
                    title={tooltip}
                    arrow={false}
                    getPopupContainer={
                      getPopupContainer || (() => ref.current!)
                    }
                  >
                    <div
                      className={classNames(
                        'cursor-pointer p-8px gap-8px flex items-center text-12px border-rd-6px hover:bg-#f2f3f7',
                        {
                          'opacity-60': disabled,
                          'cursor-not-allowed': disabled,
                        },
                      )}
                      style={{
                        color: name === 'delete' ? '#FF5219' : '#17171D',
                      }}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <IconFont
                        name={icon}
                        className='text-16px'
                        style={{
                          color:
                            name === 'delete'
                              ? '#FF5219'
                              : 'rgba(98, 105, 153, 0.6)',
                        }}
                      />
                      {text}
                    </div>
                  </Tooltip>
                </DeletePopover>
              ) : (
                <Tooltip
                  zIndex={2400}
                  placement='left'
                  key={name}
                  title={tooltip}
                  arrow={false}
                  getPopupContainer={getPopupContainer || (() => ref.current!)}
                >
                  <div
                    onClick={e => {
                      e.stopPropagation()
                      if (!disabled) {
                        action?.()
                      }
                    }}
                    onDoubleClick={e => {
                      e.stopPropagation()
                    }}
                    className={classNames(
                      'cursor-pointer p-8px flex justify-between items-center border-rd-6px hover:bg-#f2f3f7',
                      {
                        'opacity-60': disabled,
                        'cursor-not-allowed': disabled,
                      },
                    )}
                  >
                    <div
                      className={classNames(
                        'flex gap-8px items-center text-12px',
                      )}
                    >
                      <IconFont
                        name={icon}
                        className='text-16px'
                        style={{ color: 'rgba(98, 105, 153, 0.6)' }}
                      />
                      {text}
                    </div>
                  </div>
                </Tooltip>
              ),
            )}
          </div>
        }
        placement='rightTop'
      >
        {children}
      </Popover>
    </div>
  )
}
