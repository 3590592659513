import type { TableType } from '@bty/smartsheet'
import axios from 'axios'
import type { ISubFlowItem } from '@/apis/flow.ts'
import type { Database } from './database/types'
import type { DataStoreItem, DocumentItem } from './datastore/types'
import { request } from './lib'

interface IKnowledge extends DataStoreItem {
  tags: string[]
  file_info: DocumentItem[]
}

interface IDatabase extends Database {
  tables: TableType[]
}

interface IIntergrationResource {
  database: {
    list: IDatabase[]
  }
  knowledge: IKnowledge[]
  plugin_template: ISubFlowItem[]
}

export interface IInstalledPackages {
  name: string
  version: string
  document: string
  github: string
}

export const labels = [
  { label: '场景示例', key: 'SCENARIO_EXAMPLE' },
  {
    label: '内置功能',
    key: 'BUILDIN_FEATURES',
  },
] as const

export type labelKeys = (typeof labels)[number]['key']

type LabelCodeList = {
  [key in labelKeys]: CodeExampleType[]
}

export interface CodeExampleType {
  title: string
  code?: string
  children?: CodeExampleType[]
}

export interface CodeExampleListType {
  javascript: LabelCodeList
  python: LabelCodeList
}

export function getIntegrationResources(flow_id: string) {
  return request.get<IIntergrationResource>('/v1/integration/resources', {
    params: {
      flow_id,
    },
  })
}

export function getInstalledPackages(language: 'python' | 'javascript') {
  return request.get<IInstalledPackages[]>(
    `/v1/action/installed-packages/${language}`,
  )
}

export async function getCodeExample(): Promise<CodeExampleListType> {
  let envMark: string
  switch (__DEFINE_ENV__) {
    case 'pre':
      envMark = '-pre'
      break
    case 'production':
      envMark = ''
      break
    default:
      envMark = '-development'
      break
  }
  const res = await axios.get(
    `https://resource.bantouyan.com/betteryeah/flow/code-example${envMark}.json`,
    {
      params: {
        t: new Date(),
      },
    },
  )
  return res.data
}
