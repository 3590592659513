import { useAuth } from '@bty/react-auth'
import type { ReactNode } from 'react'
import type { RoleCode } from '@/apis/authority'
import { MemberTypes } from '@/pages/workspace/constant'
import { RoleOptionCard } from '@/pages/workspace/settings/components/RoleOptionCard'

export interface RoleOption {
  key: RoleCode
  value: RoleCode
  title: string
  label: ReactNode
}

export function useAuthRoleOptions(useColor = true): RoleOption[] {
  const { state } = useAuth()
  const allowedRoles =
    MemberTypes.find(item => state.role === item.value)
      ?.allowChangedRoleOptions ?? []
  return MemberTypes.filter(item =>
    allowedRoles.includes(item.value as any),
  ).map(({ value, label, description }) => ({
    key: value as RoleCode,
    value: value as RoleCode,
    title: label,
    label: (
      <RoleOptionCard
        useColor={useColor}
        label={label}
        description={description!}
      />
    ),
  }))
}
