import type { AlarmRuleConfigType } from '@/apis/alarm'
import { AlarmLevels, AlarmTypes } from '@/apis/alarm'
import lowIcon from '@/assets/alarm/low@2x.png'
import middleIcon from '@/assets/alarm/middle@2x.png'
import highIcon from '@/assets/alarm/high@2x.png'
import exigencyIcon from '@/assets/alarm/exigency@2x.png'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { transformTimeOptions } from './util'

export const configTypeMap: Record<AlarmRuleConfigType, string> = {
  Workspace: '工作空间',
  Agent: 'Agent',
  AI: FLOW_DISPLAYNAME,
}

export const AlarmLevelSortMap = {
  [AlarmLevels.EXIGENCY]: 3,
  [AlarmLevels.HIGH]: 2,
  [AlarmLevels.MIDDLE]: 1,
  [AlarmLevels.LOW]: 0,
}

export const AlarmLevelIcons = {
  [AlarmLevels.EXIGENCY]: exigencyIcon,
  [AlarmLevels.HIGH]: highIcon,
  [AlarmLevels.MIDDLE]: middleIcon,
  [AlarmLevels.LOW]: lowIcon,
}

export const AlarmTypeNames = {
  [AlarmTypes.WORKSPACE_POINT_MARGIN]: '空间积分余量',
  [AlarmTypes.WORKSPACE_POINTS_CONSUME]: '空间积分消耗',
  [AlarmTypes.WORKSPACE_EXPIRE]: '空间到期',
  [AlarmTypes.CHAT_ERROR]: '对话错误',
  [AlarmTypes.FIRST_WORD_RESPONSE]: '对话「首字响应时长」异常',
  [AlarmTypes.EXECUTE_ERROR]: '运行错误',
  [AlarmTypes.FLOW_RUN_FIRST_WORD_RESPONSE]: '运行结果「首字响应时长」异常',
  [AlarmTypes.APP_POINTS_CONSUME]: '应用积分消耗',
}

export const DisabledAlarmTypes: AlarmTypes[] = [
  // AlarmTypes.CHAT_ERROR,
  // AlarmTypes.EXECUTE_ERROR,
]

export const AlarmTypeTargets: Record<AlarmTypes, AlarmRuleConfigType[]> = {
  [AlarmTypes.APP_POINTS_CONSUME]: ['AI', 'Agent'],
  [AlarmTypes.CHAT_ERROR]: ['Agent'],
  [AlarmTypes.FIRST_WORD_RESPONSE]: ['Agent'],
  [AlarmTypes.FLOW_RUN_FIRST_WORD_RESPONSE]: ['AI'],
  [AlarmTypes.WORKSPACE_POINTS_CONSUME]: ['Workspace'],
  [AlarmTypes.WORKSPACE_POINT_MARGIN]: ['Workspace'],
  [AlarmTypes.WORKSPACE_EXPIRE]: ['Workspace'],
  [AlarmTypes.EXECUTE_ERROR]: ['AI'],
}

export const AlarmChannelNames: Record<string, string> = {
  ding_notify_channel: '钉钉',
  sms_notify_channel: '短信',
}

export const AlarmChannelIcons: Record<string, string> = {
  ding_notify_channel: 'dingding',
  sms_notify_channel: 'huihua',
  vms_notify_channel: 'dianhua',
}

// 时间以分钟处理
export const ConsumeAlarmTimeOptions = transformTimeOptions([
  '1h',
  '2h',
  '3h',
  '6h',
  '12h',
  '24h',
])

export const ErrorAlarmTimeOptions = transformTimeOptions([
  '5min',
  '10min',
  '15min',
  '30min',
  '1h',
  '2h',
  '3h',
  '6h',
  '12h',
  '24h',
])

export const SortOrderMap: Record<string, 'asc' | 'desc'> = {
  ascend: 'asc',
  descend: 'desc',
}

export const MAX_TAGS_COUNT = 5
export const MAX_CONFIG_LENTH = 4
