import type { ReactNode } from 'react'
import classNames from 'classnames'
import type { SkillBaseInfo } from '@/features/agent/types'
import { IconFont } from '@/components'

export interface SkillProps {
  className?: string
  value: SkillBaseInfo
  titleSuffix?: ReactNode
  suffix?: ReactNode
  forcedInvoke?: boolean
  disabled?: boolean
  onClick?: () => void
  showRemovalNotice?: boolean
}

export function Skill({
  className,
  value,
  titleSuffix,
  suffix,
  forcedInvoke,
  disabled,
  onClick,
  showRemovalNotice = false,
}: SkillProps) {
  return (
    <div
      className={classNames(
        'h-72px px-16 border-bottom after:border-line after:border-op-40 relative flex items-center',
        className,
        disabled
          ? 'cursor-not-allowed'
          : 'hover:bg-bg_3 hover:bg-op-8 cursor-pointer',
      )}
      onClick={disabled ? undefined : onClick}
    >
      <div className='flex-1 flex items-center overflow-hidden'>
        {value.icon?.startsWith('http') ? (
          <img
            className={classNames(
              'w-40px h-40px rounded-8px object-contain shrink-0',
              { 'op-40': disabled },
            )}
            src={value.icon}
          />
        ) : (
          <span
            className={classNames(
              'w-40px h-40px rounded-8px text-20px/40px text-center shrink-0',
              { 'op-40': disabled },
            )}
            style={{ background: value.color }}
          >
            {value.icon}
          </span>
        )}
        <div className='ml-12 flex-1 overflow-hidden'>
          <div className='font-medium flex items-center'>
            <div className='flex items-center'>
              <span
                className={classNames(
                  'leading-16px text-font flex items-center',
                  {
                    'op-40': disabled,
                  },
                )}
              >
                {value.name}
                {showRemovalNotice ? (
                  <div className='ml-12px px-5px py-2px text-12px font-normal leading-16px flex items-center c-error bg-[rgba(255,82,25,0.12)] rounded-4px'>
                    <IconFont
                      className='text-12px mr-4px'
                      name='yunxingyibai'
                    />
                    该插件已下架，无法运行，请重新选择
                  </div>
                ) : null}
                {forcedInvoke && (
                  <span className='border border-#7b67ee h-16px ml-6 px-4 text-12px/14px text-#7b67ee rounded-4px'>
                    强制调用
                  </span>
                )}
                {value.is_new && (
                  <div className='ml-4px px-5px text-12px font-normal pb-3px pt-1px font-italic h-14px flex items-center c-#FF8056 bg-#FFECE6 border-rd-7px'>
                    new
                  </div>
                )}
              </span>
            </div>
            {titleSuffix}
          </div>
          <p
            className={classNames(
              'mt-6 text-12px/16px text-font_1 max-w-80% truncate',
              { 'op-40': disabled },
            )}
            title={value.description}
          >
            {value.description}
          </p>
        </div>
      </div>
      {suffix}
    </div>
  )
}
