import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'ahooks'
import { OrderStatus } from '../const'
import type { OrderDetail } from '@/apis/order'
import { useWorkspaceStore } from '@/store'
import { WaitPayToB } from './components/WaitPayToB'
import { PayFailed } from './components/PayFailed'
import { WaitPay } from './components/WaitPay'
import { PaySuccess } from './components/PaySuccess'

export function PayResult(props: {
  order: OrderDetail
  onCancelOrder: (orderId: string) => void
  cancelLoading?: boolean
  onPay?: () => void
  onRefresh?: (...args: any[]) => any
}) {
  const { order, onCancelOrder, cancelLoading, onPay, onRefresh } = props

  const navigate = useNavigate()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const [
    showUploadCert,
    { setTrue: setUploadCertShow, setFalse: setUploadCertHide },
  ] = useBoolean(false)

  const handleGoOrderList = () => {
    navigate(`/workspace/${currentWorkspaceId}/settings/orders`)
  }

  const handleCancel = (orderId: string) => {
    onCancelOrder?.(orderId)
  }

  const handleRefresh = () => {
    setUploadCertHide()
    onRefresh?.(order?.orderNo)
  }

  const orderViewMap = useMemo<Record<OrderStatus, React.ReactNode>>(() => {
    return {
      [OrderStatus.WAIT_PAY]: (
        <WaitPay
          order={order}
          onCancelOrder={onCancelOrder}
          cancelLoading={cancelLoading}
          onPay={onPay}
        />
      ),
      [OrderStatus.CLOSED]: (
        <PayFailed
          reason=''
          title='订单关闭'
          hideRetryBtn
          cancelText='返回'
          onCancel={handleGoOrderList}
        />
      ),
      [OrderStatus.AUDIT_DISMISS]: (
        <PayFailed
          loading={cancelLoading}
          reason={`驳回原因：${order?.orderDesc}`}
          title='审核驳回'
          retryText='重新提交'
          onRetry={setUploadCertShow}
          onCancel={() => handleCancel(order.orderNo)}
        />
      ),
      [OrderStatus.COMPLETED]: (
        <PaySuccess
          title='支付成功'
          confirmText='返回空间'
          onConfirm={handleGoOrderList}
        />
      ),
      [OrderStatus.WAIT_AUDIT]: (
        <PaySuccess
          title='提交成功,审核中'
          confirmText='返回空间'
          loading={cancelLoading}
          showService
          showCancel
          onCancel={() => handleCancel(order.orderNo)}
          onConfirm={handleGoOrderList}
        />
      ),
      [OrderStatus.PLACE_ORDER]: <WaitPayToB {...props} />,
      [OrderStatus.WAIT_UPLOAD_CERT]: (
        <WaitPayToB {...props} submitCallback={handleRefresh} />
      ),
    }
  }, [order, cancelLoading, onCancelOrder, onPay, showUploadCert])
  return (
    <div className='p-60 bg-white rd-12px w-1000px flex-center'>
      <div className='flex flex-col items-center'>
        {/*  驳回重新提交上传 */}
        {showUploadCert ? (
          <WaitPayToB {...props} submitCallback={handleRefresh} />
        ) : (
          orderViewMap?.[order?.orderStatus]
        )}
      </div>
    </div>
  )
}
