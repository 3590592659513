import { useRequest } from 'ahooks'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getDraftFlowById } from '@/apis/flow'
import { useFlowDraftStore } from '@/store'
import { useNodeMetaStore } from '@/store/nodeMeta'

interface FlowDraftInitOptions {
  manual?: boolean
}
export function useFlowDraftInit(options?: FlowDraftInitOptions) {
  const { manual } = options || {}
  const { id } = useParams()

  const {
    fetchPluginListByFlowId,
    fetchSubFlowListByFlowId,
    fetchTemplateListByFlowId,
  } = useNodeMetaStore()
  const { initFlow } = useFlowDraftStore()

  const { data: flowDataRes, runAsync: fetchDraftFlow } = useRequest(
    () => getDraftFlowById(id as string),
    {
      refreshDeps: [id],
      ready: !!id,
      manual,
    },
  )

  const versionId = useMemo(() => {
    return flowDataRes?.version_id
  }, [flowDataRes])

  useRequest(
    () =>
      Promise.all([
        fetchSubFlowListByFlowId(flowDataRes?.flow_id!),
        fetchPluginListByFlowId(flowDataRes?.flow_id!),
        fetchTemplateListByFlowId(),
      ]),
    {
      refreshDeps: [flowDataRes?.flow_id],
      ready: !!flowDataRes?.flow_id,
    },
  )

  useEffect(() => {
    if (flowDataRes) {
      initFlow(flowDataRes)
    }
  }, [flowDataRes])

  return {
    versionId,
    flowDataRes,
    fetchDraftFlow,
  }
}
