import type { Node } from 'reactflow'
import type { FormInstance } from 'antd'

export function replaceDoubleInterpolationVariable(
  originalString: string,
  originalVariable: string,
  newVariable: string,
  noDoubleInterpolation = false,
): string {
  if (noDoubleInterpolation) {
    return originalString.replaceAll(originalVariable, newVariable)
  }
  const variablePath = originalVariable.replace(/\./g, '\\.') // Escaping dots for RegExp
  const leftBrace = !noDoubleInterpolation ? '{{' : ''
  const rightBrace = !noDoubleInterpolation ? '}}' : ''
  const variablePattern = new RegExp(
    `${leftBrace}${variablePath}(\\..+)?${rightBrace}`,
    'g',
  )
  const replacedString = originalString.replace(
    variablePattern,
    `${leftBrace}${newVariable}$1${rightBrace}`,
  )
  return replacedString
}

function transformNodeModifiedVariable(
  node: Node,
  originalVariable: string,
  newVariable: string,
  noDoubleInterpolation = true,
): Node {
  const newInputs = Object.entries(node.data.inputs).reduce(
    (acc, [key, value]) => {
      let newValue = value

      if (typeof value === 'string') {
        newValue = replaceDoubleInterpolationVariable(
          value,
          originalVariable,
          newVariable,
          noDoubleInterpolation,
        )
      } else if (Array.isArray(value)) {
        newValue = value.map(v => {
          if (typeof v === 'string') {
            return replaceDoubleInterpolationVariable(
              v,
              originalVariable,
              newVariable,
              noDoubleInterpolation,
            )
          } else if (typeof v === 'object') {
            return Object.entries(v).reduce((_acc, [_key, _value]) => {
              return {
                ..._acc,
                [_key]:
                  typeof _value === 'string'
                    ? replaceDoubleInterpolationVariable(
                        _value,
                        originalVariable,
                        newVariable,
                        noDoubleInterpolation,
                      )
                    : _value,
              }
            }, {})
          }
          return v
        })
      }
      return {
        ...acc,
        [key]: newValue,
      }
    },
    {},
  )

  if (node.data?.conditions?.length) {
    node.data.conditions = node.data.conditions.map((condition: any) => {
      return {
        ...condition,
        statement: replaceDoubleInterpolationVariable(
          condition.statement,
          originalVariable,
          newVariable,
          noDoubleInterpolation,
        ),
      }
    })
  }
  return {
    ...node,
    data: {
      ...node.data,
      inputs: newInputs,
    },
  }
}

export function transformNodesModifiedVariable(
  nodes: Node[],
  newVariableName: string,
  oldVariableName: string,
) {
  return nodes.map(node => {
    const noDoubleInterpolation = node.type === 'CODE'
    return transformNodeModifiedVariable(
      node,
      oldVariableName,
      newVariableName,
      noDoubleInterpolation,
    )
  })
}

type TransformedAntdFormValidateFunc = () => Promise<boolean>
export function transformAntdFormValidate(
  antdValidateFunc: FormInstance['validateFields'],
): TransformedAntdFormValidateFunc {
  return () => {
    return new Promise(resolve => {
      antdValidateFunc()
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          resolve(err.errorFields.length <= 0)
        })
    })
  }
}
