import emptyImg from '@/assets/dataset/empty@2x.png'

function Empty(props: {
  className?: string
  style?: Record<string, string | number>
}) {
  return (
    <div
      style={props.style}
      className={`flex flex-col justify-center items-center ${props.className}`}
    >
      <img className='w-140px h-140px' src={emptyImg} />
      <p
        style={{ color: 'rgba(98, 105, 153, 0.48)' }}
        className='text-14px line-height-16px'
      >
        无搜索结果
      </p>
    </div>
  )
}

export default Empty
