import RocketImage from '@/assets/app/rocket.png'
import RocketImage2 from '@/assets/app/rocket2.png'

export default ({
  desc = '联系管理员授权应用后使用',
  viewer = false,
  children,
}: {
  desc?: string
  viewer?: boolean
  children?: React.ReactNode
}) => {
  return (
    <div
      className='flex flex-col gap-16px flex-items-center justify-center text-14px w-100% h-100%'
      style={{ color: 'rgba(98, 105, 153, 0.48)' }}
    >
      <img
        className='w-110px mb--12px'
        src={viewer ? RocketImage2 : RocketImage}
        alt='not-assess-image'
      />
      <div>{desc}</div>
      {children}
      <div
        style={{
          background: 'rgba(225, 225, 229, 0.32)',
          height: '1px',
          width: '100%',
          marginTop: '54px',
        }}
      />
    </div>
  )
}
