import type { TreeDataNode, TreeProps } from 'antd'
import { Tree } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useUnmount, useUpdateEffect } from 'ahooks'
import styled from '@emotion/styled'
import { Input, IconFont } from '@/components'
import EmptyImg from '@/assets/flow/empty.png'

const StyledTree = styled(Tree)<{ noGroup: boolean }>`
  .ant-tree-treenode {
    padding-left: 6px;
    padding-bottom: 0;
    margin-bottom: 4px;
    /* align-items: center; */
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 6px;
    }
    .ant-tree-node-content-wrapper {
      line-height: 34px;
      &:hover {
        background-color: transparent;
      }
    }
    .ant-tree-checkbox {
      margin-top: 8px;
    }
    .ant-tree-switcher {
      ${props =>
        props.noGroup
          ? `
        width: 0;
        height: 0;
      `
          : ''}
      margin: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

interface AppSearchTreeProps {
  treeData: TreeDataNode[]
  searchPlaceholder: string
  defaultCheckedKeys: string[]
  allChecked: boolean
  onChange: (checkedKeys: string[]) => void
}

export function AppSearchTree(props: AppSearchTreeProps) {
  const {
    treeData,
    searchPlaceholder,
    defaultCheckedKeys,
    allChecked,
    onChange,
  } = props
  const [searchName, setSearchName] = useState('')
  const [checkedKeys, setCheckedKeys] = useState(defaultCheckedKeys)

  const noGroup = useMemo(() => {
    return treeData.every(g => !g.children)
  }, [treeData])

  const displayTreeData = useMemo(() => {
    if (!searchName) {
      return treeData
    }
    const noGroup = treeData.every(g => !g.children)
    if (noGroup) {
      return treeData
        .filter(item =>
          (item.title as string)
            .toLowerCase()
            .includes(searchName.toLowerCase()),
        )
        .map(item => ({
          ...item,
          title: (
            <TextWithHighlight
              text={item.title as string}
              keyword={searchName}
            />
          ),
        }))
    }
    return treeData
      .map(group => {
        return {
          ...group,
          children: group.children
            ?.filter(item =>
              (item.title as string)
                .toLowerCase()
                .includes(searchName.toLowerCase()),
            )
            .map(item => ({
              ...item,
              title: (
                <TextWithHighlight
                  text={item.title as string}
                  keyword={searchName}
                />
              ),
            })),
        }
      })
      .filter(group => group.children?.length)
  }, [treeData, searchName])

  const onCheck: TreeProps['onCheck'] = checkedKeys => {
    setCheckedKeys(checkedKeys as string[])
    onChange(checkedKeys as string[])
  }

  useEffect(() => {
    if (allChecked) {
      const keys = treeData.reduce<string[]>((acc, cur) => {
        return acc.concat([
          cur.key as string,
          ...(cur.children?.map(item => item.key as string) || []),
        ])
      }, [])
      setCheckedKeys(keys)
    }
  }, [])

  useUpdateEffect(() => {
    if (allChecked) {
      const keys = treeData.reduce<string[]>((acc, cur) => {
        return acc.concat([
          cur.key as string,
          ...(cur.children?.map(item => item.key as string) || []),
        ])
      }, [])
      setCheckedKeys(keys)
    } else {
      setCheckedKeys([])
    }
  }, [allChecked])

  useUnmount(() => {
    // 切换tab后清除搜索框
    setSearchName('')
  })

  return (
    <div className='flex flex-col h-336px px-24px'>
      <Input
        className='mb-4px'
        prefix={<IconFont name='search' />}
        placeholder={searchPlaceholder}
        value={searchName}
        onChange={e => {
          setSearchName(e.target.value.trim())
        }}
      />
      {displayTreeData.length ? (
        <StyledTree
          className='flex-1 overflow-y-auto'
          noGroup={noGroup}
          blockNode
          checkable
          selectable={false}
          treeData={displayTreeData}
          defaultExpandAll
          defaultCheckedKeys={defaultCheckedKeys}
          checkedKeys={checkedKeys}
          onCheck={onCheck as any}
        />
      ) : (
        <div className='flex-center flex-col flex-1'>
          <img className='w-108px h-108px' src={EmptyImg} alt='' />
          <div className='text-12px text-font_2'>无搜索结果</div>
        </div>
      )}
    </div>
  )
}

function TextWithHighlight(props: { text: string; keyword: string }) {
  const { text, keyword } = props
  const start = text.toLowerCase().indexOf(keyword.toLowerCase())
  if (start === -1) return null
  const prefix = text.slice(0, start)
  const suffix = text.slice(start + keyword.length)

  return (
    <div className='my-5px line-height-24px'>
      {prefix}
      <span className='text-#7b67ee'>
        {text.slice(start, start + keyword.length)}
      </span>
      {suffix}
    </div>
  )
}
