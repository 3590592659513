import type { ReactNode } from 'react'

export function Header({
  title,
  extraNode = null,
  model,
  modelColor = '',
  temperature,
  temperatureColor = '',
  topP,
  topPColor = '',
  presencePenalty,
  presencePenaltyColor = '',
  frequencyPenalty,
  frequencyPenaltyColor = '',
}: {
  title: string
  extraNode?: ReactNode
  model: string
  modelColor: string
  temperature: string
  temperatureColor: string
  topP: string
  topPColor: string
  presencePenalty: string
  presencePenaltyColor: string
  frequencyPenalty: string
  frequencyPenaltyColor: string
}) {
  return (
    <div className='header pb-16px b-b-1 b-bg-[rgba(225,225,229,0.6)]'>
      <div className='h-32px flex items-center'>
        <span className='title text-14px line-height-16px c-#17171D font-600'>
          {title}
        </span>
        {extraNode}
      </div>
      <div className='mt-14px flex justify-start text-12px line-height-16px'>
        <p className='w-50%'>
          <span className='font-500'>Model : </span>
          <span
            style={{
              background: modelColor,
            }}
            className='px-2px'
          >
            {model}
          </span>
        </p>
      </div>
      <div className='mt-14px flex justify-start text-12px line-height-16px'>
        <p className='w-25%'>
          <span className='font-500'>生成随机性 : </span>
          <span
            className='px-2px'
            style={{
              background: temperatureColor,
            }}
          >
            {temperature}
          </span>
        </p>
        <p className='w-25%'>
          <span className='font-500'>Top P : </span>
          <span
            className='px-2px'
            style={{
              background: topPColor,
            }}
          >
            {topP}
          </span>
        </p>
        <p className='w-25%'>
          <span className='font-500'>重复语句惩罚 : </span>
          <span
            className='px-2px'
            style={{
              background: presencePenaltyColor,
            }}
          >
            {presencePenalty}
          </span>
        </p>
        <p className='w-25%'>
          <span className='font-500'>重复主题惩罚 : </span>
          <span
            className='px-2px'
            style={{
              background: frequencyPenaltyColor,
            }}
          >
            {frequencyPenalty}
          </span>
        </p>
      </div>
    </div>
  )
}
