import { memo, useEffect, useState } from 'react'
import { Form } from 'antd'
import { useMemoizedFn } from 'ahooks'
import type { ParametersWithInput } from '@bty/chat-types'
import type { IPluginItem } from '@/apis/flow'
import { fetchPluginList, getFlowById } from '@/apis/flow'
import { JsonEditor, NodeForm } from '@/components'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils'
import type { FieldItem } from '@/features/nodes/start'
import { NodeType } from '@/features/nodes/base'
import { useTaskData } from './TaskContext'
import { TaskType } from './const'

interface TaskInputProps {
  className?: string
  title?: string
  disabled?: boolean
}

export const TaskInput = memo((props: TaskInputProps) => {
  const { className, title, disabled } = props

  const {
    pluginListRef,
    form,
    inputTypeRef,
    varForm,

    subWebhooksJson,
    pubWebhooksJson,
  } = useTaskData()

  const taskType = Form.useWatch('execution_method', form)
  const taskFlowId = Form.useWatch('rel_flow_plugin_id', form)
  const taskPluginId = Form.useWatch('rel_flow_plugin_id', form)
  const [pluginList, setPluginList] = useState<IPluginItem[]>([])

  const [inputType, setInputType] = useState('form')
  const [formConfig, setFormConfig] = useState<FieldItem[]>([])
  const [webhookJson, setWebhookJson] = useState('')

  const handleChangeJson = useMemoizedFn((newValue: string) => {
    pubWebhooksJson(newValue)
  })

  const handleChangeFormConfig = useMemoizedFn((newConfig?: FieldItem[]) => {
    inputTypeRef.current = 'form'
    setInputType('form')
    setFormConfig(newConfig ?? [])
  })

  useEffect(() => {
    setFormConfig([])

    if (taskType === TaskType.FLOW && taskFlowId) {
      getFlowById(taskFlowId, {
        headers: { 'Application-Id': taskFlowId },
      }).then(res => {
        const startNode = res.config.nodes.find(
          each => each.type === NodeType.START,
        )

        if (startNode?.data?.type === 'webhook') {
          inputTypeRef.current = 'json'
          setInputType('json')
        } else {
          handleChangeFormConfig(res.form_config)
        }
      })
      return
    }

    if (taskType === TaskType.PLUGIN && taskPluginId) {
      try {
        const pluginConfig = pluginListRef.current.find(
          e => e.function_id === taskPluginId,
        )
        if (pluginConfig) {
          const initValue = (
            pluginConfig?.parameters as ParametersWithInput
          )?.inputs?.reduce(
            (acc, cur) => {
              if (cur.default) {
                acc[cur.key] = cur.default
              }
              return acc
            },
            {} as Record<string, any>,
          )
          varForm.setFieldsValue(initValue)
        }
      } catch (e) {
        console.error(e)
      }

      const info = pluginList?.find(each => each.function_id === taskPluginId)
      if (!info) {
        fetchPluginList(taskPluginId).then((res: IPluginItem[]) => {
          setPluginList(res)
          const newInfo = res.find(
            (each: IPluginItem) => each.function_id === taskPluginId,
          )

          handleChangeFormConfig(newInfo?.form_config as FieldItem[])
        })
        return
      }

      handleChangeFormConfig(info?.form_config as FieldItem[])
    }
  }, [taskType, taskFlowId, taskPluginId])

  useEffect(() => {
    return subWebhooksJson(v => setWebhookJson(v ?? ''))
  }, [])

  if (!taskFlowId && !taskPluginId) return

  if (inputType === 'form' && (!formConfig || !formConfig.length)) return null

  return (
    <div className={className}>
      {title && <div className='font-600 mb-24px text-16px'>{title}</div>}

      {inputType === 'form' && (
        <NodeForm
          disabled={disabled}
          className='[&_.node-form-item:last-of-type]:mb-0px! [&_.node-form-item_.pb-12]:pb-8 [&_.ant-select-multiple_.ant-select-selector]:h-auto! [&_.ant-select-multiple_.ant-select-selector]:pt-3px! [&_.ant-select-multiple_.ant-select-selector]:pb-0px! [&_.ant-select-multiple_.ant-select-selection-item]:h-28px! [&_.ant-select-multiple_.ant-select-selection-item]:mb-3px! [&_.ant-select-multiple_.ant-select-selection-item]:bg-[rgba(98,105,153,0.12)]!'
          form={varForm}
          schema={transformConfig2SchemaConfig(formConfig, { size: 'middle' })}
        />
      )}

      {inputType === 'json' && (
        <JsonEditor
          disabled={disabled}
          value={webhookJson}
          onChange={handleChangeJson}
        />
      )}
    </div>
  )
})
