import { Global, css } from '@emotion/react'

export default function MarkdownStyle() {
  return (
    <Global
      styles={css`
        .betteryeah-markdown {
          // markdown 样式

          line-height: 1.5em;
          word-break: break-word;

          * {
            line-height: 22px;
          }

          h1, h2, h3, h4, h5, h6, p, ul, ol, img, center, blockquote {
            margin-bottom: 15px;
          }

          img {
            min-width: 24px;
            min-height: 24px;
            max-width: 400px;
            max-height: 200px;
            display: block;
          }

          h1, h2, h3, h4, h5, h6 {
            font-weight: bold;
          }

          h1 {
            font-size: 22px;
          }

          h2 {
            font-size: 20px;
          }

          h3 {
            font-size: 18px;
          }

          h4 {
            font-size: 16px;
          }

          h5 {
            font-size: 14px;
          }

          h6 {
            font-size: 12px;
          }

          a {
            color: #7B61FF;
            cursor: pointer;
          }

          ul {
            li {
              list-style: disc !important;

              &::marker {
                font-size: 20px;
              }
            }
          }

          ol {
            counter-reset: item;

            li {
              counter-increment: item;

              &::marker {
                font-size: 14px;
                color: #8d8d99;
                content: counter(item) ". ";
              }
            }

            ol {
              counter-reset: subitem;

              li {
                counter-increment: subitem;

                &::marker {
                  color: #8d8d99;
                  font-size: 14px;
                  content: counter(item) ". " counter(subitem) " "; /* 显示一级和二级计数器 */
                }
              }
            }
          }

          ul > li,
          ol > li {
            margin-left: 20px;
            padding: 4px 0;

            ul, ol {
              margin: 0;
            }
          }

          center {
            text-align: center;
          }

          s,
          del {
            color: #8D8D99;
            text-decoration-color: #8D8D99;
          }

          blockquote {
            background: #f6f6f6;
            border-left: 4px solid #7B61FF;
            border-radius: 4px;
            position: relative;
            width: 100%;
            padding: 16px;

            footer {
              padding: 0;
              text-align: justify;
              color: inherit;
              font-style: italic;

              cite {
                &::before {
                  content: "----";
                  padding: 0 0.3em;
                  color: #888;
                  margin-left: 1em;
                }
              }
            }
          }

          blockquote,
          p,
          ul,
          ol {
            text-align: left;
            word-wrap: normal;
          }

          pre {
            display: block;
            box-sizing: border-box;
            margin-top: 16px;
            margin-bottom: 16px;
            overflow: auto;
            background: #fff7ea;
            border: 1px solid #ffebcb;
            padding: 16px;
            border-radius: 4px;

            & > code:not([class]) {
              background: transparent;
            }
          }

          div > pre {
            & > code:not([class]) {
              padding: 0;
              margin: 0;
              background: transparent;
              color: rgba(68, 68, 68, 0.9);
            }
          }

          code {
            &:not([class]) {
              word-break: break-all;
              color: #c74f00;
              border-radius: 2px;
            }

          }
      `}
    />
  )
}
