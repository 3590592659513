import React from 'react'
import type { AgentRuleConfig } from '@bty/chat-types'
import { RuleConfig } from '@/features/agent/Rule'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'
import { useAgentDiffStore } from '@/store/agentDiffStore'

function InternalAgentRule({ className }: { className?: string }) {
  const { chatRef, ruleRef, applicationInfo, refreshApplicationInfo } =
    useAgentEdit()

  const { publishRuleChange } = useAgentDiffStore()

  const onAgentRuleChange = (
    data: Pick<AgentRuleConfig, 'welcome_enabled' | 'welcome'>,
    onlyGetRule: boolean,
  ) => {
    if (onlyGetRule) {
      chatRef.current?.refetch()
      return
    }
    refreshApplicationInfo()
    chatRef.current?.refresh(
      '规则已修改，新会话开始',
      data.welcome_enabled ? data.welcome : undefined,
      data.welcome_enabled,
    )
    publishRuleChange()
    window.dispatchEvent(new Event('agentRuleChange'))
  }

  if (!applicationInfo) {
    return
  }

  return (
    <RuleConfig
      ref={ruleRef}
      className={className}
      flowInfo={applicationInfo}
      onRuleChange={onAgentRuleChange}
      onAppRefresh={() => {
        refreshApplicationInfo()
        setTimeout(() => {
          chatRef?.current?.refetch()
        }, 500)
      }}
    />
  )
}

export const AgentRule = React.memo(InternalAgentRule)
