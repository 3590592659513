import { EmbeddingModelSwitch } from '../components/EmbeddingModelSwitch'
import { EmbeddingModelSelect } from '../components/EmbeddingModelSelect'
import type { IEmbeddingModalItem } from '@/apis/datastore/api'

interface DocumentTypePickerProps {
  value?: IEmbeddingModalItem['model_name']
  list?: IEmbeddingModalItem[]
  onChange?: (value: string) => void
}
const MAX_SWITCH_ITEMS = 2

export function EmbeddingModelPicker(props: DocumentTypePickerProps) {
  const { list } = props

  return list && list.length <= MAX_SWITCH_ITEMS ? (
    <EmbeddingModelSwitch {...props} list={list ?? []} />
  ) : (
    <EmbeddingModelSelect {...props} list={list ?? []} />
  )
}
