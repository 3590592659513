import { useRef, useState } from 'react'
import { Input, IconFont } from '@/components'

interface SearchProps {
  onSearch: (value: string) => void
}
export function Search({ onSearch }: SearchProps) {
  const isTypingChineseRef = useRef(false)

  const [inputValue, setInputValue] = useState<string>()

  const handleComposition = (
    event: React.CompositionEvent<HTMLInputElement>,
  ) => {
    if (event.type === 'compositionend') {
      isTypingChineseRef.current = false
      const value = (event.target as HTMLInputElement).value
      onSearch(value)
    } else {
      isTypingChineseRef.current = true
    }
  }

  return (
    <Input
      className='w-400px !text-14px'
      prefix={<IconFont name='search' />}
      placeholder='搜索数据库名称'
      value={inputValue}
      onChange={event => {
        const value = event.target.value
        setInputValue(value)
        if (!isTypingChineseRef.current) {
          onSearch(value)
        }
      }}
      onCompositionStart={handleComposition}
      onCompositionUpdate={handleComposition}
      onCompositionEnd={handleComposition}
    />
  )
}
