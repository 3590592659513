import styled from '@emotion/styled'
import { Modal } from 'antd'
import { useKeyPress } from 'ahooks'
import Iframe from 'react-iframe'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import cn from 'classnames'
import { IconFont } from '@/components/icon'
import { isInIframe } from '@/utils/iframe'
import { isMac } from '@/features/nodes/llm/LLMNodePanel/utils'
import { DOWNLOAD_API_URL } from '@/apis/download'

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`

export interface IPageModalProps {
  url: string
  footer?: React.ReactNode
}

const OPEN_MESSAGE_NAME = 'window-open'

const MAC_FULL_SCREEN_KEY = 'meta.o'
const WINDOWS_FULL_SCREEN_KEY = 'ctrl.o'
const MAC_CLOSE_KEY = 'meta.e'
const WINDOWS_CLOSE_KEY = 'ctrl.e'

const FULL_SCREEN_KEY = isMac() ? MAC_FULL_SCREEN_KEY : WINDOWS_FULL_SCREEN_KEY
const CLOSE_KEY = isMac() ? MAC_CLOSE_KEY : WINDOWS_CLOSE_KEY

const DOWNLOAD_FILE_ENDS = [
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.pdf',
  '.zip',
  '.rar',
  '.7z',
  '.tar',
  '.gz',
  '.tgz',
  '.bz2',
  '.bz',
  '.csv',
  '.md',
  '.txt',
  '.log',
  '.sql',
  '.json',
  '.xml',
  '.html',
  '.htm',
  '.js',
  '.css',
  '.less',
  '.sass',
  '.scss',
  '.java',
  '.exe',
  '.dmg',
]

const IGNORE_URLS = ['douyin', 'zhihu', 'qq', 'toutiao', 'xiaohongshu']

export const PageModal = NiceModal.create((props: IPageModalProps) => {
  const { url } = props

  const [id] = useState(`iframe-${uuidv4()}`)

  const modal = useModal()

  const handleClose = () => {
    modal.hide()

    setTimeout(() => {
      modal.remove()
    }, 200)
  }

  const handleFullScreen = () => {
    const _url = url.startsWith('http')
      ? url
      : `${window.location.origin}${url}`
    window.parent.postMessage(
      { type: OPEN_MESSAGE_NAME, payload: { url: _url } },
      '*',
    )
    handleClose()
  }

  useKeyPress([FULL_SCREEN_KEY], e => {
    handleFullScreen()
    e.preventDefault()
  })

  useKeyPress([CLOSE_KEY], e => {
    handleClose()
    e.preventDefault()
  })

  return (
    <StyledModal
      open={modal.visible}
      footer={null}
      closable={false}
      width='90vw'
      styles={{
        body: { padding: 0, height: '90vh' },
      }}
      centered
    >
      <div className='relative h-full flex flex-col'>
        <div className='absolute right--40px top-2px'>
          <div
            className='bg-black bg-op-70 w-30px h-30px flex-center rounded-13px hover:translate-y--2px transition ease'
            onClick={handleClose}
          >
            <IconFont
              name='guanbi'
              className='cursor-pointer text-white text-16px'
            />
          </div>
          <div
            className='bg-black bg-op-70 w-30px h-30px flex-center rounded-13px mt-8px hover:translate-y--2px transition ease'
            onClick={handleFullScreen}
          >
            <IconFont
              name='shiyinghuabu'
              className='cursor-pointer text-white text-16px'
            />
          </div>
        </div>
        <Iframe
          id={id}
          className={cn('w-full rounded-8px bg-#fff flex-1')}
          url={url}
        />
      </div>
    </StyledModal>
  )
})

export function usePageModal() {
  const modal = useModal(PageModal)

  const show: typeof modal.show = async (props: any) => {
    const { url } = props
    const isHTTP = url && url.startsWith('http') && !url.startsWith('https')
    const isFile = DOWNLOAD_FILE_ENDS.some(
      endsName => url && url.endsWith(endsName),
    )

    if (isHTTP) {
      window.open(url)
      return
    }
    if (isFile) {
      if (isHTTP) {
        window.open(`${DOWNLOAD_API_URL}?url=${encodeURIComponent(url)}`)
      } else {
        window.open(url)
      }
      return
    }

    if (IGNORE_URLS.some(ignoreUrl => url.includes(ignoreUrl))) {
      window.open(url)
      return
    }
    const inIframe = isInIframe()
    if (inIframe) {
      window.parent.postMessage(
        { type: OPEN_MESSAGE_NAME, payload: props },
        '*',
      )
    } else {
      await modal.show(props)
    }
  }

  return {
    ...modal,
    show,
  }
}
