import { useEffect, useRef, useState } from 'react'
import { useFlowDraftStore } from '@/store'

interface NodeLayoutOptions {
  data: any
  isFocusWithin: boolean
  nodeElement: HTMLDivElement | null
  toBeRemoved: boolean
}

export const NODE_ZINDEX = 1000

export function useNodeLayout(
  nodeRef: React.RefObject<HTMLDivElement>,
  { data, isFocusWithin, nodeElement, toBeRemoved }: NodeLayoutOptions,
) {
  const reLayoutFlow = useFlowDraftStore(s => s.reLayoutFlow)

  const [needLayout, setNeedLayout] = useState(false)

  const observerRef = useRef<MutationObserver | null>(null)

  useEffect(() => {
    if (nodeRef.current) {
      observerRef.current = new MutationObserver(mutationList => {
        for (const mutation of mutationList) {
          if (
            mutation.type === 'childList' &&
            mutation.target !== nodeRef.current &&
            nodeRef.current
          ) {
            const nodeOnBranch = data?.relation?.branchId
            if (nodeOnBranch) {
              setNeedLayout(true)
            }
          }
        }
      })
      observerRef.current.observe(nodeRef.current, {
        attributes: true,
        attributeOldValue: true,
        childList: true,
        subtree: true,
      })
    }
  }, [nodeRef, data])

  useEffect(() => {
    if (!isFocusWithin && needLayout) {
      reLayoutFlow()
    }
    if (toBeRemoved && nodeElement) {
      nodeElement.style.zIndex = (NODE_ZINDEX + 1).toString()
      return
    }
    if (isFocusWithin && nodeElement) {
      nodeElement.style.zIndex = NODE_ZINDEX.toString()
    }
  }, [isFocusWithin, nodeElement, toBeRemoved])

  useEffect(() => {
    // todo: hack方式，当节点在条件分支上时，防止节点被下一个分支的edge的svg遮挡
    if (nodeElement) {
      nodeElement.style.zIndex = NODE_ZINDEX.toString()
      // if (nodeElement.style.visibility === 'hidden') {
      //   reLayoutFlow()
      // }
    }
  }, [nodeElement])

  useEffect(() => {
    return () => {
      observerRef.current?.disconnect()
    }
  }, [])

  return {}
}
