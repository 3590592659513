import type { FormInstance } from 'antd'
import { useMemoizedFn } from 'ahooks'
import type { NamePath } from 'antd/es/form/interface'
import type { PromptStructItem } from '@/apis/prompt.ts'
import { PromptType } from '@/apis/prompt.ts'
import { getDefaultStructPrompt } from '@/features/prompt/constant/structPrompt.ts'

// 当切换PromptType时，执行的副作用hooks
// 1，当没有结构化数据时，添加一份默认的结构化数据
export function usePromptTypeChange(
  form: FormInstance,
  structPromptNamePath: NamePath,
  promptNamePath: NamePath,
  defaultStructPromptType: 'Agent' | 'Flow',
) {
  const promptTypeChangeEffect = useMemoizedFn(
    (
      type: PromptType,
      prompt?: { raw?: string; struct?: PromptStructItem[] },
    ) => {
      if (type === PromptType.STRUCT) {
        if (prompt?.struct) {
          form.setFieldValue(structPromptNamePath, prompt?.struct)
          return
        }

        const structPrompt = form.getFieldValue(structPromptNamePath)
        if (!structPrompt) {
          form.setFieldValue(
            structPromptNamePath,
            getDefaultStructPrompt(defaultStructPromptType),
          )
        }
      } else {
        if (prompt?.raw) {
          form.setFieldValue(promptNamePath, prompt.raw)
        }
      }
    },
  )

  return { promptTypeChangeEffect }
}
