import type { Task } from '@bty/chat-types'
import { useMemoizedFn } from 'ahooks'
import { useState } from 'react'
import { wait } from '@/utils/wait'

export function useTask() {
  const [taskOpen, setTaskOpen] = useState(false)
  const [taskList, setTaskList] = useState([])
  const [showTaskId, setShowTaskId] = useState('')

  const [taskRecordId, setTaskRecordId] = useState('')

  const handleTaskOpen = useMemoizedFn(() => {
    setTaskOpen(true)
  })

  const handleTaskClose = useMemoizedFn(async () => {
    setShowTaskId('')
    setTaskRecordId('')
    setTaskOpen(false)
  })

  const handleTaskBack = useMemoizedFn(async () => {
    setTaskRecordId('')
    setShowTaskId('')
  })

  const handleOpenTask = useMemoizedFn(
    async (task: Task, metaData?: Record<string, any>) => {
      setShowTaskId(task.id)
      setTaskRecordId(metaData?.id)
      await wait(100)
      setTaskOpen(true)
    },
  )

  return {
    taskOpen,
    taskList,
    showTaskId,
    taskRecordId,
    setTaskList,
    handleTaskOpen,
    handleTaskBack,
    handleTaskClose,
    handleOpenTask,
  }
}
