import { memo, useCallback, useEffect, useRef, useState } from 'react'
import type { CodeEditorInstance, Variable } from '@/features/editor'
import { CodeEditor } from '@/features/editor'

function InnerTagVariableEditor(props: {
  variables: Variable[]
  variableTipsContainer?: HTMLElement
  onChange?: (value: string) => void
  placeholder?: string
}) {
  const [input, setInput] = useState('')
  const codeEditorRef = useRef<CodeEditorInstance | null>(null)

  const onConfirm = useCallback(() => {
    if (input.trim()) {
      props.onChange?.(input)
      setInput('')
    }
  }, [props.onChange, input])

  useEffect(() => {
    const codeEditor = codeEditorRef.current?.editor
    if (codeEditor) {
      codeEditor.commands.removeCommand('onEnter')
      codeEditor.commands.addCommand({
        name: 'onEnter',
        bindKey: { win: 'Enter', mac: 'Enter' },
        exec: onConfirm,
      })
    }
  }, [onConfirm])

  return (
    <div className='min-w-[60px] flex-1 -ml-[4px]'>
      <CodeEditor
        {...props}
        ref={codeEditorRef}
        className='w-full border-none shrink-0 !bg-transparent'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={input}
        onChange={v => {
          setInput(v)
        }}
        onBlur={onConfirm}
        singleLine
        wrapEnabled={false}
        variables={props.variables}
        placeholder={props.placeholder}
        setOptions={{
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
      />
    </div>
  )
}

export const TagVariableEditor = memo(InnerTagVariableEditor)
