import type { TrackConfig } from './type'

export interface TrackOptions {
  // env: TrackEnv
  trackList: TrackConfig[]
}

export class DataTrack {
  static trackList: TrackConfig[] = []
  // static env: TrackEnv
  static init(options: TrackOptions) {
    DataTrack.trackList = options.trackList
    // DataTrack.env = options.env
  }
}
