import styled from '@emotion/styled'
import { Form, Popconfirm, Switch, Tooltip } from 'antd'
import { TextArea, FormItem, Modal, IconFont } from '@/components'
import type { AgentDataset } from '@/apis/agent'
import { ShowKnowledgeReferenceComponent } from '../components/ShowKnowledgeReferenceComponent'
import { DEFAULT_AGENT_DATASET_CONFIG } from '@/features/datastore/constant'

interface IKnowledgeDatasetSettingModal {
  open?: boolean
  onCancel?: () => void
  createLoading?: boolean
  onOk: (values: Record<string, any>) => void
  datasetConfig?: AgentDataset
  onChange?: (v: any) => void
}

const AgentSearchMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export default function KnowledgeDatasetSettingModal(
  props: IKnowledgeDatasetSettingModal,
) {
  const { open, onCancel, onOk, createLoading, datasetConfig } = props
  const [form] = Form.useForm()
  const show_knowledge_url = Form.useWatch('show_knowledge_url', form)
  const description = Form.useWatch('description', form)

  const resetDescription = () => {
    form.setFieldValue('description', DEFAULT_AGENT_DATASET_CONFIG.description)
  }
  if (!datasetConfig) {
    return null
  }

  return (
    <Modal
      okButtonProps={{
        loading: createLoading,
        className: '!font-400',
      }}
      styles={{
        body: {
          padding: '16px 16px 0 16px',
        },
      }}
      width={1120}
      maskClosable={false}
      cancelButtonProps={{ className: '!font-400' }}
      onOk={async () => {
        const values = form.getFieldsValue(true)
        if (values) {
          await onOk({
            ...values,
          })
        }
      }}
      title='知识库设置'
      open={open}
      onCancel={onCancel}
    >
      <div className='min-h-344px flex flex-col gap-24px p-8px'>
        <Form
          requiredMark={false}
          form={form}
          layout='vertical'
          initialValues={{
            description: datasetConfig?.description || '',
            show_knowledge_url: !!datasetConfig?.show_knowledge_url,
          }}
        >
          <AgentSearchMethodWrapper>
            <div className='flex flex-1 justify-between'>
              <div>知识库描述</div>
              <div
                className='text-primary text-12px cursor-pointer flex hover:text-opacity-60 items-center'
                onClick={() => {}}
              >
                <IconFont name='auto' className='text-16px'></IconFont>
                {description?.trim() ? (
                  <Popconfirm
                    title={null}
                    icon={null}
                    placement='bottom'
                    description={
                      <p className='text-12px/18px w-180px'>
                        将会使用系统描述覆盖你目前的内容，是否覆盖?
                      </p>
                    }
                    okText='确定'
                    cancelText='取消'
                    onConfirm={resetDescription}
                  >
                    <p className='ml-4 mt-1px text-14px'>重置</p>
                  </Popconfirm>
                ) : (
                  <p
                    className='ml-4 mt-1px text-14px'
                    onClick={resetDescription}
                  >
                    重置
                  </p>
                )}
              </div>
            </div>
            <FormItem name='description' className='w-full flex-1' noStyle>
              <TextArea
                size='middle'
                placeholder='告诉Agent，什么时候需要查询知识库，例如：这是你的知识库，当你xx时，可以使用这个知识库'
                className='!resize-none !min-h-40px !line-height-22px mt-12px'
                autoSize={{ minRows: 3, maxRows: 6 }}
                autoFocus
                maxLength={300}
              />
            </FormItem>
          </AgentSearchMethodWrapper>
          <div className='mb-20px flex flex-col gap-8px'>
            <div className='flex flex-1 items-center'>
              <div>展示知识原文下载链接</div>
              <Tooltip title='开启后，Agent 的对话者可以通过日志中的链接下载原文件。建议保持关闭状态，以避免知识泄漏风险。'>
                <IconFont
                  name='jieshishuimeng'
                  style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                  className='cursor-pointer ml-5px mr-8px'
                />
              </Tooltip>
              <Form.Item noStyle name='show_knowledge_url'>
                <Switch size='small' />
              </Form.Item>
            </div>
            {show_knowledge_url && (
              <ShowKnowledgeReferenceComponent business='agent' />
            )}
          </div>
        </Form>
      </div>
    </Modal>
  )
}
