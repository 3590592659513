import type { EditorState, Transaction } from 'prosemirror-state'

export function backspace(
  state: EditorState,
  dispatch?: (tr: Transaction) => void,
) {
  if (!dispatch) return false

  const from = state.selection.from
  const to = state.selection.to

  if (from !== to) return false

  const { parent: node } = state.doc.resolve(from)

  // 占位框不主动删除
  if (
    ['pe-input', 'pe-select'].includes(node?.type.name) &&
    (node?.nodeSize ?? 0) < 4
  ) {
    if (node?.nodeSize === 3) {
      dispatch(state.tr.replaceWith(from - 1, from, '' as any))
    }
    return true
  }

  return false
}
