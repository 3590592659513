import { rgba } from 'polished'

interface RoleOptionCardProps {
  useColor?: boolean
  bgColor?: string
  label: string
  description: string
}

export function RoleOptionCard(props: RoleOptionCardProps) {
  const { useColor = true, bgColor, label, description } = props

  return (
    <div
      className='w-224px p-l-10 p-t-5 p-r-6 p-b-8 b-rd-4px bg-op-8'
      style={
        useColor && bgColor ? { backgroundColor: rgba(bgColor!, 0.08) } : {}
      }
    >
      <div className='m-b-4 line-height-22px font-500 c-#17171d'>{label}</div>
      <div className='line-height-20px text-12px c-#8d8d99 overflow-hidden whitespace-normal'>
        {description}
      </div>
    </div>
  )
}
