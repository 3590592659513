import React from 'react'
import { Radio } from 'antd'
import { CardRadioGroup } from '@/components'

export const RadioGroup: React.FC<any> = props => {
  switch (props.optionType) {
    case 'card':
      return (
        <CardRadioGroup
          options={props.options}
          onChange={!props.disabled && props.onChange}
          value={props.value}
          size={props.size}
        />
      )
    default:
      return (
        <Radio.Group
          options={props.options}
          onChange={props.onChange}
          value={props.value}
        />
      )
  }
}
