import { useEffect, useMemo, useRef } from 'react'
import cn from 'classnames'
import { isArray } from 'lodash-es'
import { Select } from '@/components'
import { useNodeMetaStore } from '@/store/nodeMeta'

interface IFieldsFileTypeSelectProps {
  value?: unknown[]
  onChange?: (value: string[]) => void
}
export function FieldsFileTypeSelect(props: IFieldsFileTypeSelectProps) {
  const { value, onChange } = props
  const containerRef = useRef<HTMLDivElement | null>(null)
  const supportFileConfigList = useNodeMetaStore(
    state => state.supportFileConfigList,
  )
  const val = useMemo(() => {
    if (!value) {
      return [supportFileConfigList[0].type]
    } else {
      return isArray(value) ? value : [value]
    }
  }, [value])

  useEffect(() => {
    if (!value) {
      onChange?.([supportFileConfigList[0].type] as any)
    }
  }, [value])

  const supportFileOptions = useMemo(() => {
    return (
      supportFileConfigList.map(item => {
        return {
          label: item.name,
          value: item.type,
        }
      }) ?? []
    )
  }, [supportFileConfigList])
  return (
    <div ref={containerRef} className='nodrag nopan nowheel'>
      <Select
        mode='multiple'
        virtual={false}
        value={val}
        size='small'
        getPopupContainer={() => containerRef?.current!}
        onChange={value => {
          onChange?.(value as any)
        }}
        className={cn([
          '[&_.ant-select-selection-item]:h-24px! w-full',
          '[&_.ant-select-selector]:h-auto! [&_.ant-select-selector]:p-3!',
          '[&_.ant-select-selection-overflow]:gap-y-4px',
          'nodrag nopan text-[12px]',
        ])}
        options={supportFileOptions}
      ></Select>
    </div>
  )
}
