import { useState } from 'react'
import { message } from 'antd'
import { HitTestOperationPanel } from '@/pages/datastores/components/HitTestOperationPanel.tsx'
import { DocumentStatus } from '@/apis/datastore/model.ts'
import { SimilarityConfig } from '@/pages/datastores/components/SimilarityConfig.tsx'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

interface HitTestProps {
  file_id: number
  status: DocumentStatus
  hitLoading: boolean
  onSearch: (
    text: string,
    matchThreshold: number,
    tags?: string[],
    params?: Record<string, any>,
  ) => Promise<any>
}

export function HitTest(props: HitTestProps) {
  const { file_id, status, hitLoading } = props
  const [searchText, setSearchText] = useState('')
  const [topK, setTopK] = useState(6)

  const onSearch = async ({
    _topK,
    ...params
  }: { _topK?: number } & Record<string, any>) => {
    if (!searchText.trim()) {
      message.warning('请输入查询内容')
      return
    }
    await props.onSearch(searchText, _topK ?? topK, undefined, params)
  }

  const { scrollRef } = useScrollBar()

  return (
    <div ref={scrollRef} className='flex-1 overflow-y-auto px-24 py-22'>
      <HitTestOperationPanel
        className='flex flex-col'
        file_id={file_id}
        hitTestLoading={hitLoading}
        disabled={
          status !== DocumentStatus.Done &&
          status !== DocumentStatus.Warning &&
          status !== DocumentStatus.AIProcess
        }
        searchText={searchText}
        showSearchForm={true}
        onSearchTextChange={setSearchText}
        onSearch={e => onSearch(e || {})}
        headerActionSlot={
          <SimilarityConfig
            defaultValue={topK}
            onChange={value => {
              setTopK(value)
              if (searchText.trim() && value !== topK) {
                onSearch({ _topK: value })
              }
            }}
          />
        }
      />
    </div>
  )
}
