import { useRequest, useUpdateEffect } from 'ahooks'
import dayjs from 'dayjs'
import { omit } from 'lodash-es'
import { useEffect, useState } from 'react'
import type { AppMonitorType, ListResponse } from '@/apis/monitor'
import { StaticTimeTypes } from '@/apis/monitor'
import type { MonitorDrawerProps } from '@/components/MonitorDrawer'
import { TIME_FORMAT } from '../constants'

export function getDefaultDatesParams() {
  return {
    start_date: dayjs().subtract(1, 'week').toISOString(),
    end_date: dayjs().toISOString(),
  }
}

interface ReqParams {
  flow_id?: string
  flow_type?: AppMonitorType
  run_type?: string[]
  statistic_time: StaticTimeTypes
  start_date?: string
  end_date?: string
  sort_field?: string
  sort_order?: 'desc' | 'asc'
  page: number
  page_size: number
  search_content?: string
  [k: string]: any
}

interface Options<T, R> {
  open: boolean
  api: (params: T) => Promise<ListResponse<R>>
  outerTimeType: StaticTimeTypes
  sortFieldsMap?: Record<string, string>
  dataKey?: string
  totalKey?: string
  pageKey?: string
  pageSizeKey?: string
  startDateKey?: string
  endDateKey?: string
  transformStatisticTime?: (value: StaticTimeTypes) => any
  transformDateString?: (dateStr: string) => string
  defaultParams: any
}

export function useMonitorDetailReq<T extends ReqParams, R>(
  options: Options<T, R>,
) {
  const {
    open,
    api,
    defaultParams,
    outerTimeType,
    transformStatisticTime,
    pageKey,
    pageSizeKey,
    startDateKey,
    endDateKey,
    sortFieldsMap,
    transformDateString,
  } = options

  const [timeType, setTimeType] = useState(outerTimeType)
  const [dateParams, setDateParams] = useState(() => ({
    start_date: defaultParams.start_date,
    end_date: defaultParams.end_date,
  }))

  useEffect(() => {
    if (defaultParams?.start_date && defaultParams?.end_date) {
      setDateParams({
        start_date: defaultParams.start_date,
        end_date: defaultParams.end_date,
      })
    }
  }, [defaultParams])

  const { runAsync: fetchList, data } = useRequest<ListResponse<R>, [T]>(
    params => {
      let newParams = {
        ...params,
        statistic_time: params.statistic_time || timeType,
      }
      if ((params.statistic_time || timeType) !== StaticTimeTypes.CUSTOM) {
        newParams = {
          ...(omit(newParams, ['start_date', 'end_date']) as T),
        }
      }
      if (transformStatisticTime) {
        newParams = {
          ...omit(newParams, 'statistic_time'),
          ...transformStatisticTime(params.statistic_time),
        }
      }
      if (pageKey) {
        newParams = {
          ...(omit(newParams, 'page') as any),
          [pageKey]: newParams.page,
        }
      }
      if (pageSizeKey) {
        newParams = {
          ...(omit(newParams, 'page_size') as any),
          [pageSizeKey]: newParams.page_size,
        }
      }
      if (
        startDateKey &&
        newParams.start_date &&
        timeType === StaticTimeTypes.CUSTOM
      ) {
        newParams = {
          ...(omit(newParams, 'start_date') as any),
          [startDateKey]: transformDateString
            ? transformDateString(
                dayjs(newParams.start_date).startOf('d').format(TIME_FORMAT),
              )
            : dayjs(newParams.start_date).startOf('d').format(TIME_FORMAT),
        }
      }
      if (
        endDateKey &&
        newParams.end_date &&
        timeType === StaticTimeTypes.CUSTOM
      ) {
        newParams = {
          ...(omit(newParams, 'end_date') as any),
          [endDateKey]: transformDateString
            ? transformDateString(
                dayjs(newParams.end_date).endOf('d').format(TIME_FORMAT),
              )
            : dayjs(newParams.end_date).endOf('d').format(TIME_FORMAT),
        }
      }
      console.log('newParams', newParams)
      return api(newParams)
    },
    {
      manual: true,
      defaultParams,
    },
  )

  useEffect(() => {
    setTimeType(outerTimeType)
  }, [outerTimeType])

  useUpdateEffect(() => {
    if (open) {
      if (timeType !== StaticTimeTypes.CUSTOM) {
        fetchList({
          ...defaultParams,
          start_date: undefined,
          end_date: undefined,
          statistic_time: timeType,
        })
      } else if (dateParams.start_date && dateParams.end_date) {
        fetchList({
          ...defaultParams,
          ...dateParams,
          statistic_time: timeType,
        })
      }
    }
  }, [timeType, dateParams])

  const onTableChange: MonitorDrawerProps['onTableChange'] = async (
    pagination,
    _filters,
    sorter: any,
  ) => {
    const params: T = {
      ...defaultParams,
      ...dateParams,
      statistic_time: timeType,
    }
    if (sorter.field) {
      params.sort_field = sortFieldsMap
        ? sortFieldsMap[sorter.field]
        : sorter.field
      params.sort_order = sorter.order === 'ascend' ? 'asc' : 'desc'
    }
    if (pagination.current) {
      params.page = pagination.current
    }
    if (pagination.pageSize) {
      params.page_size = pagination.pageSize
    }
    await fetchList(params)
  }

  const onFilterChange: MonitorDrawerProps['onFilterChange'] = async value => {
    setTimeType(value as any)
  }

  const onRangePickerChange: MonitorDrawerProps['onRangePickerChange'] =
    async value => {
      if (value) {
        setDateParams({
          start_date: dayjs(value[0]).toISOString(),
          end_date: dayjs(value[1]).toISOString(),
        })
        await fetchList({
          ...defaultParams,
          start_date: dayjs(value[0]).toISOString(),
          end_date: dayjs(value[1]).toISOString(),
        })
      }
    }

  const onSearchChange: MonitorDrawerProps['onSearchChange'] = async key => {
    await fetchList({
      ...defaultParams,
      ...dateParams,
      search_content: key,
    })
  }

  return {
    fetchList,
    dataSource: (data as any)?.[options.dataKey || 'rows'] || [],
    total: (data as any)?.[options.totalKey || 'totals'] || 0,
    onTableChange,
    onFilterChange,
    onRangePickerChange,
    onSearchChange,
  }
}
