import { Dropdown, type MenuProps } from 'antd'
import type { ReactNode } from 'react'
import React from 'react'
import dayjs from 'dayjs'
import { Button, IconFont } from '@/components'

export interface AgentGoConnectTestProps {
  agentAvatar?: string
  serviceAvatar?: string
  serviceName?: string
  connectState: 'off' | 'testing' | 'fail' | 'success'
  connectTime?: string
  actionSlot?: ReactNode
  operator?: string
  authAppEnable?: boolean
  errorMessage?: string
  onTest: VoidFunction
  onReset: VoidFunction
  onUnbind: VoidFunction
}

function InternalAgentGoConnectTest({
  agentAvatar,
  serviceAvatar,
  serviceName,
  connectState,
  connectTime,
  operator,
  errorMessage,
  onTest,
  onReset,
  onUnbind,
  actionSlot,
  authAppEnable,
}: AgentGoConnectTestProps) {
  const menu: { items: MenuProps['items']; onClick: MenuProps['onClick'] } = {
    items: [
      {
        label: (
          <span className='pl-12px text-14px/32px text-font'>重新配置</span>
        ),
        key: 'reset',
      },
      {
        label: (
          <span className='pl-12px text-14px/32px text-error'>解绑账号</span>
        ),
        key: 'unbind',
      },
    ],
    onClick: ({ key }) => {
      if (key === 'reset') {
        onReset()
        return
      }
      if (key === 'unbind') {
        onUnbind()
      }
    },
  }

  let stateElement: React.ReactNode
  switch (connectState) {
    case 'off':
      stateElement = (
        <span className='inline-block ml-8px text-center w-52px h-20px text-12px/20px font-medium text-font_1 rounded-4px bg-[rgba(98,105,153,.1216)]'>
          未启用
        </span>
      )
      break
    case 'testing':
      stateElement = (
        <span className='inline-block ml-8px text-center w-52px h-20px text-12px/20px font-medium text-tips rounded-4px bg-[rgba(56,166,255,.1216)]'>
          测试中
        </span>
      )
      break
    case 'fail':
      stateElement = (
        <span
          title={`连接失败：${errorMessage}`}
          className='inline-block ml-8px text-center truncate max-w-130 px-4 h-20px text-12px/20px font-medium text-error rounded-4px bg-[rgba(255,82,25,.1216)]'
        >
          连接失败：{errorMessage}
        </span>
      )
      break
    case 'success':
      stateElement = (
        <span className='inline-block ml-8px text-center w-52px h-20px text-12px/20px font-medium text-#2CB969 rounded-4px bg-[rgba(44,185,105,.12)]'>
          正常
        </span>
      )
      break
    default:
      break
  }

  return (
    <div className='p-20px rounded-8px bg-white border border-solid border-[rgba(225,225,229,0.4)] inline-block mr-24px min-w-[320px] w-100%'>
      <div className='flex'>
        <div className='flex-1 overflow-hidden'>
          <div className='flex items-center'>
            <img
              className='w-64px h-64px rounded-full object-contain'
              src={serviceAvatar}
            />
            <IconFont
              className='text-[rgba(98,105,153,0.6)] text-16px mx-4px'
              name='link'
            />
            <img
              className='w-64px h-64px rounded-full object-contain'
              src={agentAvatar}
            />
          </div>
        </div>
        <div className='shrink-0 ml-auto inline-flex'>
          {authAppEnable && (
            <>
              {actionSlot}
              <Button
                className='w-76px !px-0 text-font align-top !border-[rgba(225,225,229,0.8)]'
                size='small'
                onClick={onTest}
              >
                连接测试
              </Button>
            </>
          )}
          <Dropdown
            overlayClassName='[&>.ant-dropdown-menu-root]:rounded-8px [&>.ant-dropdown-menu-root]:w-156px [&>.ant-dropdown-menu-root]:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.12)]'
            menu={menu}
            placement='bottomRight'
          >
            <a
              className='inline-flex justify-center items-center ml-8px w-32px h-32px text-16px text-[rgba(98,105,153,0.6)] rounded-8px cursor-pointer hover:text-[rgba(23,23,29,0.6)] hover:bg-[rgba(98,105,153,0.08)] transition-colors duration-200 ease-[cubic-bezier(0.645,0.045,0.355,1)]'
              onClick={event => event.preventDefault()}
            >
              <IconFont name='gengduo' />
            </a>
          </Dropdown>
        </div>
      </div>
      <span className='flex items-center block mt-12px text-14px/16px font-medium text-font'>
        <div className='truncate max-w-[40%]'>{serviceName}</div>
        {stateElement}
      </span>
      <p className='text-12px/16px text-font_1 mt-8px'>
        接入时间：{dayjs(connectTime).format('YYYY-MM-DD HH:mm')} | 最后操作人：
        {operator}
      </p>
    </div>
  )
}

export const AgentGoConnectTest = React.memo(InternalAgentGoConnectTest)
