import { Form } from 'antd'
import { useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import { LLMChannels } from '@/apis/llm/model'
import { useLLMStore } from '@/store/llm'
import type { NodeComponent } from '../base'
import { NodeOperationTypes, NodeType } from '../base'
import type { JsonFormConfig } from '../components'
import { JsonForm } from '../components'
import { useModelChange } from '../hooks/useModelChange'
import { LLMPanelOptions } from '../llm/LLMNode/components/LLMPanelOptions'
import {
  isLLMBatchNodeFormValues,
  transformLLMNodeData2LLMNodeFormData,
} from '../llm/LLMNodePanel/utils'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { BatchDataVariable } from './components/BatchDataVariable'

const LLM_PACKAGE_NAME = 'integration.llm.LLMAction'

export interface LLMBatchNodeData {
  name: string
  inputs: {
    model: string // 模型类型
    channel: LLMChannels // LLM Server 需要区分渠道
    temperature: number // 创造性
    stream: boolean // 是否开启stream
    maxToken: number
    splitName: string // 需要分割的数据变量
    system_content: string
    summarize?: string
  }
  packageName: string
}

export type LLMBatchNodeDataForm = Omit<LLMBatchNodeData, 'inputs'> & {
  inputs: {
    model: string
    channel: LLMChannels
    temperature: number
    top_p?: number
    presence_penalty?: number
    frequency_penalty?: number
    stream: boolean
    maxToken: number
    splitName: string
    system_content: string
    summarize?: string
    // 额外添加的配置字段，提供给 from 使用
    modelSetting: {
      model: string
      channel: LLMChannels
      temperature: number
      top_p?: number
      presence_penalty?: number
      frequency_penalty?: number
      outputType: string
    }
  }
}

export const LLMBatchNode: NodeComponent<LLMBatchNodeData> = props => {
  const { data, nodeElement, variables } = props

  const [form] = Form.useForm<LLMBatchNodeDataForm>()

  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const { beforeChange } = useModelChange(
    form as any,
    props.data.inputs.channel,
  )
  const { llmModelList } = useLLMStore()

  const streamingEnabled =
    form.getFieldValue(['inputs', 'modelSetting'])?.channel ===
    LLMChannels.XunFei

  const beforeJsonChange = useMemoizedFn((values: unknown) => {
    if (!isLLMBatchNodeFormValues(values)) {
      console.error('数据格式错误')
      return values
    }

    const { modelSetting, stream } = values.inputs
    const inputsData = beforeChange({
      modelSetting,
      stream,
    })
    const newInput = { ...values.inputs, ...inputsData }
    delete (newInput as any).modelSetting
    const newValues = { ...values, inputs: newInput }

    const maxToken = llmModelList.find(
      item => item.model === newValues.inputs.model,
    )?.maxTokens
    if (maxToken) {
      newValues.inputs.maxToken = maxToken
    }
    return newValues
  })

  const list = useMemo<JsonFormConfig[]>(
    () => [
      {
        required: true,
        name: ['inputs'],
        render: () => (
          <LLMPanelOptions
            streamingEnabled={streamingEnabled}
            nodeElement={nodeElement}
            asyncSupport
          />
        ),
      },
      {
        required: true,
        label: '数据变量',
        name: ['inputs', 'splitName'],
        rules: [{ required: true, message: '数据变量不能为空' }],
        render: () => {
          return (
            <BatchDataVariable
              variables={variables}
              variableTipsContainer={nodeElement}
              disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
            />
          )
        },
      },
      {
        required: true,
        label: '提示词',
        name: ['inputs', 'system_content'],
        type: 'TextEditor',
        tooltip: '提供给AI的提示，以确保AI按照您的意愿做出响应',
        rules: [{ required: true, message: 'System Prompt不能为空' }],
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        componentProps: {
          placeholder: '输入内容，如：“获取{{message}}中任务的出生日期”',
          variables,
          variableTipsContainer: nodeElement,
          // mode: 'text',
          // setOptions: {
          //   maxLines: 24,
          // },
          // className: 'min-h-64px $',
        },
      },
      {
        label: '输出总结',
        name: ['inputs', 'summarize'],
        tooltip: '可以将结果的处理进行总结',
        type: 'TextEditor',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        componentProps: {
          placeholder: '输入对总结的要求和描述',
          variableTipsContainer: nodeElement,
          variables,
          // className: 'min-h-64px $',
          // mode: 'text',
          // setOptions: {
          //   maxLines: 10,
          // },
        },
      },
    ],
    [streamingEnabled, nodeElement, variables],
  )

  return useMemo(
    () => (
      <div className='p-16 pb-0 w-420px'>
        <JsonForm
          form={form}
          list={list}
          disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          initialValues={transformLLMNodeData2LLMNodeFormData(data as any)}
          beforeChange={beforeJsonChange}
        />
      </div>
    ),
    [form, list, beforeJsonChange],
  )
}

LLMBatchNode.meta = {
  type: NodeType.LLM_BATCH,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'LLM批量处理',
  actionType: 'ASYNC_LLM',
  icon: 'llm',
  description: '支持数据分段并批量处理数据',
  backgroundColor: '#00BD68',
  initialData: {
    name: 'llm_batch_1',
    packageName: LLM_PACKAGE_NAME,
    inputs: {
      model: 'gpt-3.5-turbo',
      channel: LLMChannels.GPT,
      stream: true,
      temperature: 0.7,
      maxToken: 4096,
      splitName: '',
      system_content: '',
    },
  },
}
