import { LEGAL_REQUIRED_TEXT } from '@/constants/common'

interface PropsType {
  text?: string
}

export function GeneratedByAIText(props: PropsType) {
  const { text = LEGAL_REQUIRED_TEXT } = props

  return (
    <span className='scale-75 text-12px text-font_1 text-op-48'>{text}</span>
  )
}
