import { useState, useMemo, useRef, useContext, useEffect } from 'react'
import { Spin } from 'antd'
import type { FormInstance } from 'antd'
import { get, assign, isNil, isEmpty } from 'lodash-es'
import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm } from '@/features/nodes/components'
import { DynamicForm } from '../base/components/DynamicForm'
import { useDynamicValues } from '../hooks/useDynamicValues'
import type { InnerNodePanelProps } from '@/features/nodes/base'
import { Button } from '@/components'
import { DebugResultPanelContext } from '../base/DebugResultPanel'
import useStartFileNodeVariableKeyList from '../hooks/useStartFileNodeVariableKeyList'

export function TextNodePanel(props: InnerNodePanelProps<any>) {
  const {
    data,
    activatedNodeId,
    variables,
    nodeElement,
    startNodeFormItemType = [],
  } = props

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType!,
    variables,
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<FormInstance>(null)

  const [loading] = useState<boolean>(false)

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: formRef.current?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message = [{ content: get(value, 'inputs.textarea_value') }]
      return message
    },
  })

  const { usedKeyListByNodeId } = val

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await formRef.current?.validateFields()
    run(data)
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables])

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })
    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: '内容',
        required: true,
        rules: [{ required: true, message: '文本内容不能为空' }],
        name: ['inputs', 'textarea_value'],
        type: 'TextEditor',
        tooltip: '文本内容会作为当前节点的输出',
        componentProps: {
          minHeight: 302,
          placeholder: '输入文本内容，作为本节点的输出信息',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
    ]
  }, [nodeElement, variables, containerRef.current])

  return (
    <div className='p-16 w-full' onWheel={e => e.stopPropagation()}>
      <Spin spinning={loading} delay={1000}>
        <JsonForm ref={formRef} list={list}></JsonForm>
        <div>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
      </Spin>
    </div>
  )
}
