import React from 'react'
import { useMemoizedFn } from 'ahooks'
import { KnowledgeDatasetList } from '@/features/agent/KnowledgeConfig/KnowledgeDatasetList'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'

function InternalAgentKnowledgeList() {
  const { applicationInfo, ruleRef } = useAgentEdit()

  const handleUpdate = useMemoizedFn(() => {
    ruleRef.current?.refreshRuleConfig()
  })

  if (!applicationInfo) {
    return
  }

  return (
    <KnowledgeDatasetList flowInfo={applicationInfo} onUpdate={handleUpdate} />
  )
}

export const AgentKnowledgeList = React.memo(InternalAgentKnowledgeList)
