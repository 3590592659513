import { Popover } from 'antd'
import { useMemo, useRef } from 'react'
import classNames from 'classnames'
import { CodeBlock } from '@/components/CodeBlock'
import { CodeEditor } from '@/features/editor'
import type { TagsTooltipProps } from '@/features/editor/components/TagsTooltip'
import type { LLMMessageStructType } from '@/features/nodes/utils/llm.ts'
import { useLLMContextExample } from '../../hooks/useLLMContextExample'

export interface JSONFieldProps {
  value?: string
  onChange?: (v: string) => void
  disabled?: boolean
  anchor?: TagsTooltipProps['anchor']
  completions?: any[]
  modelMessageStructType?: LLMMessageStructType
  extraCls?: string
  getPopupContainer?: () => HTMLElement
}

export function JSONField(props: JSONFieldProps) {
  const {
    value = '',
    onChange,
    disabled,
    completions,
    anchor,
    modelMessageStructType,
    getPopupContainer,
  } = props

  const wrapperRef = useRef<HTMLElement>(null)

  const onValueChange = (v: string) => {
    let jsonData = v
    try {
      jsonData = JSON.parse(v)
    } catch {}
    onChange?.(jsonData)
  }

  const acceptValue = useMemo(
    () => (typeof value !== 'string' ? JSON.stringify(value, null, 2) : value),
    [value],
  )

  const { llmContextExample } = useLLMContextExample({
    modelMessageStructType,
  })

  return (
    <div className='relative'>
      <CodeEditor
        className={classNames('llm-node-code-editor mb-0px!', {
          'llm-node-code-editor-disabled': disabled,
        })}
        readOnly={disabled}
        mode='json'
        placeholder='填写或输入上下文的JSON变量'
        width='100%'
        setOptions={{
          readOnly: disabled,
          fontSize: 14,
          showGutter: false,
          showLineNumbers: false,
          maxLines: 12,
          printMargin: 0,
        }}
        anchor={anchor}
        variables={disabled ? undefined : completions}
        value={acceptValue}
        onChange={onValueChange}
      />

      <Popover
        arrow={false}
        trigger='click'
        placement='bottom'
        getPopupContainer={getPopupContainer}
        content={
          <div>
            <div className='mb-12px line-height-16px text-12px font-500 text-#17171d'>
              上下文内容的JSON格式请参照示例
            </div>
            <CodeBlock
              title='示例'
              lang='json'
              str={llmContextExample}
              className='bg-white b-1 b-#e1e1e5 b-op-60'
              codeFont='12px'
              titleFont='12px'
            />
          </div>
        }
      >
        <span
          ref={wrapperRef}
          className='absolute top-11px right-10 text-14px text-primary cursor-pointer'
        >
          示例
        </span>
      </Popover>
    </div>
  )
}
