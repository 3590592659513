import { useRequest } from 'ahooks'
import { useState } from 'react'
import { Spin, message } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import {
  StepsWrapper,
  downloadOssFile,
  DraggerWrapper,
  uploadFile,
} from '@/pages/application/components/appDetail/utils'
import theme from '@/constants/theme'
import { Button, IconFont } from '@/components'
import { getDatasetSTSToken } from '@/apis/oss'
import { useWorkspaceStore } from '@/store'

function StepIcon({ step }: { step: number }) {
  return (
    <div
      className='text-[16px] font-extrabold h-[24px] w-[24px] line-height-24px'
      style={{
        background: 'rgba(123, 97, 255, 0.2)',
        color: theme.colors.primary,
        borderRadius: '50%',
        transform: 'translate(3px, 6px)',
      }}
    >
      {step}
    </div>
  )
}

export function useStepUploadFile({
  generateExcel,
  name,
  descriptionChildren,
  needOssDownLoad = true,
  customUploadFn,
}: {
  generateExcel: () => Promise<any>
  name: string
  descriptionChildren?: React.ReactNode
  needOssDownLoad?: boolean
  customUploadFn?: (e: UploadRequestOption<any>) => void
}) {
  const [currentSteps, setCurrentSteps] = useState(1) // 当前步骤
  const [file, setFile] = useState<{
    url?: string
    name: string
    file?: File
  } | null>() // 上传文件结果
  const { runAsync: getSTSTokenApi, loading: getOssTokenLoading } = useRequest(
    getDatasetSTSToken,
    { manual: true },
  )
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { run: getExcelOssUrl, loading: getExcelLoading } = useRequest(
    generateExcel,
    {
      manual: true,
      onSuccess: e => needOssDownLoad && downloadOssFile(name, e),
    },
  )

  const { runAsync: runUpload, loading: uploadLoading } = useRequest(
    async e => {
      try {
        const data = await getSTSTokenApi()
        if (data) {
          const upload = await uploadFile(data, e.file, currentWorkspaceId)
          setFile({
            url: `/${upload.name}` || '',
            name: (e.file as File)?.name || '',
          })
          setCurrentSteps(2)
          e.onSuccess?.(upload as any)
          message.success('上传成功！')
        } else {
          message.error('上传失败！')
          e.onError?.('上传失败！' as any)
        }
      } catch (err) {
        message.error('上传失败！')
        e.onError?.(err as any)
      }
    },
    { manual: true },
  )

  return {
    file,
    currentSteps,
    setFile,
    setCurrentSteps,
    uploadLoading,
    StepUploadFile: (
      <StepsWrapper
        direction='vertical'
        current={currentSteps}
        items={[
          {
            title: '下载模版并填写',
            icon: <StepIcon step={1} />,
            description: (
              <div className='adapt:h-90px'>
                <Button
                  size='large'
                  style={{ height: '36px' }}
                  loading={getExcelLoading || getOssTokenLoading}
                  className='adapt:mt-16'
                  onClick={getExcelOssUrl}
                  icon={<IconFont name='xiazai' className='text-16px' />}
                >
                  下载模板
                </Button>
              </div>
            ),
          },
          {
            title: '上传Excel文件',
            icon: <StepIcon step={2} />,
            description: (
              <DraggerWrapper
                name='file'
                multiple={false}
                showUploadList={false}
                accept='.xlsx'
                style={{ marginTop: 16 }}
                customRequest={customUploadFn || runUpload}
              >
                <Spin spinning={uploadLoading}>
                  {file ? (
                    <div className='flex flex-items-center flex-justify-between px-28px'>
                      <div className='flex flex-items-center gap-8px'>
                        <IconFont name='xlsx' className='text-20px' />
                        <div className='truncate block'>{file.name}</div>
                      </div>
                      <div style={{ color: theme.colors.primary }}>
                        重新上传
                      </div>
                    </div>
                  ) : (
                    descriptionChildren
                  )}
                </Spin>
              </DraggerWrapper>
            ),
          },
        ]}
      />
    ),
  }
}
