import styled from '@emotion/styled'

export const ColorfulBg = styled.div`
  background: linear-gradient(
    105deg,
    rgba(54, 205, 255, 0.12) 0%,
    rgba(107, 78, 255, 0.12) 62%,
    rgba(150, 58, 255, 0.12) 99%
  );
`

export const ColorfulText = styled.div`
  font-weight: 500;
  background: linear-gradient(100deg, #2cb5ff 0%, #6b4eff 62%, #963aff 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
