import { Tooltip } from 'antd'
import { IconFont } from '@/components'

export default function ({
  num = 0,
  title,
  int,
}: {
  num?: number
  title: string
  int: string
}) {
  const getStatisticNumber = (value: number): string => {
    const valueStrArr = value.toString().split('')
    const res = [valueStrArr.splice(0, valueStrArr.length % 3).join('')]
    while (valueStrArr.length > 0) {
      res.push(valueStrArr.splice(0, 3).join(''))
    }
    if (res[0] === '') {
      res.shift()
    }
    return res.join(',')
  }
  return (
    <div className='w-100% py-12px flex flex-col flex-items-center'>
      <div className='mb-12px text-48px font-800'>
        {getStatisticNumber(num)}
      </div>
      <div className='text-14px font-500 c-font flex flex-items-center gap-4px'>
        {title}
        <Tooltip title={int}>
          <IconFont
            name='jieshishuimeng'
            className='text-12px cursor-pointer'
            style={{ color: 'rgba(141, 141, 153, 0.4)' }}
          />
        </Tooltip>
      </div>
    </div>
  )
}
