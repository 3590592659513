import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { FLOW_DISPLAYNAME } from '@/constants/common'

export default (): { typeName: typeof FLOW_DISPLAYNAME | 'Agent' | '应用' } => {
  const location = useLocation()
  const typeName = useMemo(() => {
    if (location.pathname.startsWith('/application')) {
      return FLOW_DISPLAYNAME
    }
    if (location.pathname.startsWith('/agent')) {
      return 'Agent'
    }
    return '应用'
  }, [location])
  return { typeName }
}
