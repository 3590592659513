import type { PropsWithChildren } from 'react'

interface GradientRoundedBoxProps {
  classNames?: string
  size?: number
  borderWidth?: number
  gradient: string
}

export function GradientRoundedBox(
  props: PropsWithChildren<GradientRoundedBoxProps>,
) {
  const { classNames, size = 88, borderWidth = 1, gradient, children } = props

  return (
    <div
      className={`b-transparent b-rd-50% bg-origin-border ${classNames}`}
      style={{
        width: size,
        height: size,
        borderWidth,
        backgroundClip: 'content-box, border-box',
        backgroundImage: `linear-gradient(#fff, #fff), ${gradient}`,
      }}
    >
      {children}
    </div>
  )
}
