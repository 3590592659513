import { createAuthBrowserRouter } from '@bty/react-auth'
import { Spin } from 'antd'
import { Navigate, Outlet } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'
import { agentRoutes } from '@/router/agent.tsx'
import { defaultRouterPath, grayscaleMap } from '@/constants/common.ts'
import Login, { loader as loginLoader } from '@/pages/login'
import type { AuthRoute } from '@/auth'
import auth from '@/auth'
import ApplicationPublicPage from '@/pages/application/public'
import { NotAllowed } from '@/pages/notAllowed'
import { NotFound } from '@/pages/404'
import Pay from '@/pages/pay'
import { ShareAgent } from '@/pages/agent/shareAgent'
import Chat from '@/pages/agent/chat'
import AgentGo from '@/pages/agentGo'
import HomeLayout from '@/pages/home/HomeLayout.tsx'
import { Explore } from '@/pages/explore/Explore'
import Plugins from '@/pages/plugins'
import PluginInfo from '@/pages/plugins/pluginInfo'
import { AgentList } from '@/pages/agent/list/list'
import { RoleCode } from '@/apis/authority'
import WorkSpaceSettings from '@/pages/workspace/settings'
import Overview from '@/pages/workspace/settings/Overview'
import Users from '@/pages/workspace/settings/Users'
import Orders from '@/pages/workspace/settings/Orders'
import AppList from '@/pages/application/list'
import AppDetail from '@/pages/application/detail'
import DatastorePage from '@/pages/datastores/Page'
import DatastoresDetailLayout from '@/pages/datastores/DetailLayout'
import Documents from '@/pages/datastores/Documents'
import HitTest from '@/pages/datastores/HitTest'
import DataStoreAssociatedApp from '@/pages/datastores/AssociatedApp'
import DocumentDetailLayout from '@/pages/datastores/document-detail/Layout.tsx'
import ChatBotTemplates from '@/pages/chatbot/Templates'
import ChatBot, { loader as chatbotLoader } from '@/pages/chatbot'
import ChatBotNotFound from '@/pages/chatbot/NotFound'
import { SynonymLibrary } from '@/pages/datastores/SynonymLibrary'
import { Statistics } from '@/pages/statistics'
import { PushNotificationProvider } from '@/providers/PushNotificationProvider'
import { ShareChannel } from '@/pages/agent/shareChannel'
import { dbRoutes, dbRoutesNoLayout } from '@/router/db.tsx'
import Data from '@/pages/workspace/settings/Data'
import { UsageStatistics } from '@/pages/statistics/UsageStatistics'
import { Discovery } from '@/pages/chatbot/Discovery'
import { Alarm } from '@/pages/workspace/settings/alarm'
import { applicationRoutes } from './application'
import { templatesRoutes } from './templates'
import { chromePluginRoutes } from './chromePlugin'
import { selectWorkSpaceRoutes } from './selectWorkspace'
import { pluginsRoutes } from './plugin'

export function isNotAuthRoute() {
  return ['/shareAgent/', '/chat/', '/shareChannel/'].find(item =>
    location.pathname.includes(item),
  )
}

function LoadComponent() {
  return (
    <div className='absolute top-0 right-0 bottom-0 left-0 flex-center '>
      <Spin />
    </div>
  )
}

const router = createAuthBrowserRouter(
  [
    {
      path: '/',
      element: (
        <PushNotificationProvider>
          <Outlet />
        </PushNotificationProvider>
      ),
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          errorElement: <Navigate to='/404' />,
          element: <Navigate to={defaultRouterPath} />,
        },
        {
          path: '/login',
          element: <Login />,
          loader: loginLoader,
        },
        {
          path: '/chat/quickLink?/:botId/:conversationId?',
          element: <Chat />,
          loader: chatbotLoader,
          redirect: '/notAllowed',
        },
        {
          path: '/shareChannel/:botId/:conversationId?',
          element: <ShareChannel />,
          loader: chatbotLoader,
          redirect: '/notAllowed',
        },
        { path: '/public/app/:id/:tag?', element: <ApplicationPublicPage /> },
        { path: '/shareAgent/:id/:robot_id', element: <ShareAgent /> },
        {
          path: '',
          element: <HomeLayout />,
          children: [
            {
              path: '/explore',
              auth: 'auth',
              element: <Explore />,
            },
            {
              path: '/chatbot',
              auth(s) {
                return s.auth && s.grayscale(grayscaleMap.chat)
              },
              element: <Navigate to='templates' />,
            },
            {
              path: '/chatbot/templates',
              element: <ChatBotTemplates />,
            },
            {
              path: '/chatbot/discovery',
              auth(s) {
                return s.auth && s.grayscale(grayscaleMap.chat)
              },
              element: <Discovery />,
            },
            {
              path: '/chatbot/:botId',
              auth(s) {
                return s.auth && s.grayscale(grayscaleMap.chat)
              },
              loader: chatbotLoader,
              element: <ChatBot showTask />,
              errorElement: <ChatBotNotFound />,
            },
            {
              path: '/agent',
              auth(s) {
                return s.auth && s.grayscale(grayscaleMap.agent)
              },
              redirect: '/application', // agent现在作为首页如果没有灰度的话那么就重定向到flow列表
              element: <AgentList />,
            },
            {
              path: '/plugin',
              auth: 'auth',
              element: <Plugins />,
            },
            {
              path: '/plugin/:pluginId',
              auth: 'auth',
              element: <PluginInfo />,
            },
            {
              path: '/application',
              auth: 'auth',
              element: <AppList />,
            },
            {
              path: '/application/:id/:tag?',
              auth: 'auth',
              element: <AppDetail />,
            },
            {
              path: '/datastores',
              auth: 'auth',
              element: <DatastorePage />,
            },
            {
              path: '/datastores/:workspaceId/:id',
              auth(s) {
                return s.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])
              },
              redirect: '/notAllowed',
              element: <DatastoresDetailLayout />,
              children: [
                {
                  path: 'documents',
                  element: <Documents />,
                },
                {
                  path: 'hitTest',
                  element: <HitTest />,
                },
                {
                  path: 'synonymLibrary',
                  element: <SynonymLibrary />,
                },
                {
                  path: 'applications',
                  element: <DataStoreAssociatedApp />,
                },
              ],
            },
            {
              path: '/datastores/:workspaceId/:id/:documentId/:route',
              auth: 'auth',
              element: <DocumentDetailLayout />,
            },
            {
              path: '/agentgo',
              auth: 'auth',
              element: <AgentGo />,
            },
            {
              path: '/statistics',
              auth(s) {
                return s.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])
              },
              redirect: '/notAllowed',
              element: <Statistics />,
              children: [
                {
                  path: 'usageStatistics',
                  element: <UsageStatistics />,
                },
                {
                  path: 'resourceStatistics',
                  element: <Data />,
                },
              ],
            },
            {
              path: '/workspace/:id/settings',
              auth(s) {
                return s.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])
              },
              redirect: '/notAllowed',
              element: <WorkSpaceSettings />,
              children: [
                {
                  path: 'overview',
                  element: <Overview />,
                },
                // {
                //   path: 'base',
                //   element: <Base />,
                // },
                {
                  path: 'users',
                  element: <Users />,
                },
                {
                  path: 'orders',
                  element: <Orders />,
                },
                {
                  path: 'alarm',
                  element: <Alarm />,
                },
              ],
            },
            ...dbRoutes,
          ] as AuthRoute[],
        },
        { path: '/notAllowed', element: <NotAllowed /> },
        { path: '/pay', auth: 'auth', element: <Pay /> },
        { path: '/404', element: <NotFound /> },
        ...applicationRoutes,
        ...templatesRoutes,
        ...agentRoutes,
        ...chromePluginRoutes,
        ...selectWorkSpaceRoutes,
        ...dbRoutesNoLayout,
        ...pluginsRoutes,
      ],
    },
  ],
  {
    auth,
    defaultRedirectPath: '/login',
    loadComponent: LoadComponent,
    provider: NiceModal.Provider,
  },
)

export default router
