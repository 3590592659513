import type { FC } from 'react'
import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { Tag, message } from 'antd'
import classNames from 'classnames'
import { isString, uniq } from 'lodash-es'
import { TagDropdownSelect } from '@/features/datastore/components/TagDropdownSelect.tsx'
import { IconFont } from '@/components'
import type { CodeEditorInstance } from '@/features/editor'
import { CodeEditor } from '@/features/editor'
import { VariableRegex } from '@/constants/common.ts'

interface MemoryTagSelectProps {
  value?: string[]
  onChange?: (value?: unknown[]) => void
  tags: Array<{ label: string; value: string }>
  error?: string[]
  variables: any[]
  variableTipsContainer?: HTMLElement
  placeholder: string
  multiple?: boolean
}

function TagVariableEditor(props: {
  variables: any[]
  variableTipsContainer?: HTMLElement
  onChange?: (value: string) => void
  placeholder?: string
}) {
  const [input, setInput] = useState('')
  const codeEditorRef = useRef<CodeEditorInstance | null>(null)

  const onConfirm = useCallback(() => {
    if (input.trim()) {
      props.onChange?.(input)
      setInput('')
    }
  }, [props.onChange, input])

  useEffect(() => {
    const codeEditor = codeEditorRef.current?.editor
    if (codeEditor) {
      codeEditor.commands.removeCommand('onEnter')
      codeEditor.commands.addCommand({
        name: 'onEnter',
        bindKey: { win: 'Enter', mac: 'Enter' },
        exec: onConfirm,
      })
    }
  }, [onConfirm])

  return (
    <div className='min-w-[60px] flex-1 -ml-[8px]'>
      <CodeEditor
        {...props}
        ref={codeEditorRef}
        className='w-full border-none shrink-0 !bg-transparent'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={input}
        onChange={v => {
          setInput(v)
        }}
        onBlur={onConfirm}
        singleLine
        wrapEnabled={false}
        variables={props.variables}
        placeholder={props.placeholder}
        setOptions={{
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
      />
    </div>
  )
}

export const CommonSelectEditor: FC<MemoryTagSelectProps> = props => {
  const { tags, error, placeholder, multiple = false, ...remaining } = props
  const tagsOptions = tags
  const boxRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)

  const formatValue = useMemo(() => {
    if (Array.isArray(remaining.value)) {
      return remaining.value
    } else if (isString(remaining.value)) {
      return [remaining.value]
    } else {
      return remaining.value
    }
  }, [remaining.value])
  const onVariableEditorChange = useCallback(
    (v: string) => {
      // 如果是变量，或者是已有的标签，直接添加
      if (
        VariableRegex.test(v) ||
        tags.findIndex(item => item.value === v) !== -1
      ) {
        // 如果是变量，直接添加

        const newValue = multiple ? uniq([...(formatValue ?? []), v]) : [v]
        props.onChange?.(newValue)
      } else {
        message.warning('该标签不存在')
      }
      setOpen(false)
    },
    [tags, formatValue, props.onChange, multiple],
  )

  return (
    <div ref={boxRef}>
      <TagDropdownSelect
        open={open}
        onOpenChange={setOpen}
        getPopupContainer={() => boxRef.current!}
        options={tagsOptions}
        {...remaining}
        value={formatValue}
        onChange={item => {
          if (multiple) {
            remaining.onChange?.(item as any)
          } else {
            if (item.length) {
              remaining.onChange?.([item[item.length - 1]] as any)
            } else {
              remaining.onChange?.()
            }
          }
        }}
        triggerRender={(_, ref) => {
          return (
            <>
              <div
                className={classNames(
                  'relative px-8 py-4 b-1 rounded-6px bg-bg_3 bg-op-6 flex-center-between gap-8',
                  {
                    '!b-error': !!error?.length,
                    'b-primary': open,
                    'hover:b-primary b-bg_3 b-op-0': !open,
                  },
                )}
                ref={ref}
              >
                <div className='flex gap-8 flex-wrap w-full'>
                  {(formatValue || []).map((tag, index) => {
                    const isVariable = VariableRegex.test(tag)
                    return (
                      <Tag
                        key={tag + index}
                        closable
                        closeIcon={
                          <IconFont
                            name='guanbi'
                            className={classNames(
                              'c-font_1 text-12px c-op-60 relative top-1px right--2px',
                              {
                                'c-tips c-op-80': isVariable,
                              },
                            )}
                          />
                        }
                        className={classNames(
                          'rounded-4px !m-0 flex items-center',
                          {
                            'bg-tips bg-op-12 c-tips b-tips': isVariable,
                          },
                        )}
                        onClose={() =>
                          remaining.onChange?.(
                            formatValue?.filter(i => i !== tag),
                          )
                        }
                      >
                        {tag}
                      </Tag>
                    )
                  })}
                  <TagVariableEditor
                    variables={props.variables}
                    variableTipsContainer={props.variableTipsContainer}
                    onChange={onVariableEditorChange}
                    placeholder={formatValue?.length ? '' : placeholder}
                  />
                </div>
                <div className={classNames('w-16px h-16px flex-center')}>
                  <IconFont name='arrow' className='text-7px c-font_1' />
                </div>
              </div>
              {!!error?.length && (
                <div className='mb-12 text-12px c-error'>{error[0]}</div>
              )}
            </>
          )
        }}
      />
    </div>
  )
}
