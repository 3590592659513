import './index.css'

import { useMemoizedFn } from 'ahooks'
import type { RefObject, MouseEvent, ForwardedRef } from 'react'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { useMouseDrag } from './hooks'

interface Props {
  horizontal?: boolean
  scrollRef: RefObject<HTMLElement>
}

export interface BarRef {
  refresh: () => void
}

export const Scrollbar = forwardRef(
  (props: Props, ref: ForwardedRef<BarRef>) => {
    const { horizontal, scrollRef } = props

    const scrollbarRef = useRef<HTMLDivElement>(null)
    const thumbRef = useRef<HTMLDivElement>(null)
    const topRef = useRef(0)
    const leftRef = useRef(0)
    const [show, setShow] = useState(false)

    const refresh = useMemoizedFn(() => {
      if (!thumbRef.current || !scrollRef.current) return
      const content = scrollRef.current
      const thumb = thumbRef.current

      if (!horizontal) {
        thumb.style.height = `${
          (content.offsetHeight / content.scrollHeight) * 100
        }%`
        thumb.style.top = `${(content.scrollTop / content.scrollHeight) * 100}%`
      } else {
        thumb.style.width = `${
          (content.offsetWidth / content.scrollWidth) * 100
        }%`
        thumb.style.left = `${
          (content.scrollLeft / content.scrollWidth) * 100
        }%`
      }
    })

    const handleScrollbarClick = useMemoizedFn((e: MouseEvent) => {
      if (!scrollbarRef.current || !thumbRef.current || !scrollRef.current) {
        return
      }

      const content = scrollRef.current
      const scrollbarRect = scrollbarRef.current.getBoundingClientRect()
      const thumbRect = thumbRef.current.getBoundingClientRect()

      if (!horizontal) {
        const top = e.clientY - scrollbarRect.y - thumbRect.height / 2
        const percent = top / scrollbarRect.height
        scrollRef.current.scrollTo({
          top: percent * content.scrollHeight,
          behavior: 'smooth',
        })
      } else {
        const left = e.clientX - scrollbarRect.x - thumbRect.width / 2
        const percent = left / scrollbarRect.width
        scrollRef.current.scrollTo({
          left: percent * content.scrollWidth,
          behavior: 'smooth',
        })
      }
    })

    const handleMoveStart = useMemoizedFn(() => {
      setShow(true)
      if (!thumbRef.current) return
      topRef.current = thumbRef.current.offsetTop
      leftRef.current = thumbRef.current.offsetLeft
    })

    const handleMove = useMemoizedFn(([diffX, diffY]) => {
      if (!thumbRef.current || !scrollbarRef.current || !scrollRef.current) {
        return
      }

      const content = scrollRef.current
      const scrollbarRect = scrollbarRef.current.getBoundingClientRect()

      if (!horizontal) {
        const top = topRef.current + diffY
        const percent = top / scrollbarRect.height
        scrollRef.current.scrollTo({
          top: percent * content.scrollHeight,
        })
      } else {
        const left = leftRef.current + diffX
        const percent = left / scrollbarRect.width
        scrollRef.current.scrollTo({
          left: percent * content.scrollWidth,
        })
      }
    })

    const handleMoveEnd = useMemoizedFn(() => {
      setShow(false)
    })

    const handleMouseDown = useMouseDrag({
      onDragStart: handleMoveStart,
      onDragChange: handleMove,
      onDragEnd: handleMoveEnd,
    })

    useEffect(() => {
      if (!thumbRef.current) return
      const content = thumbRef.current
      const handle = (event: any) => {
        event.stopPropagation()
        handleMouseDown(event)
      }
      content.addEventListener('mousedown', handle)

      return () => {
        content.removeEventListener('mousedown', handle)
      }
    }, [scrollRef])

    useImperativeHandle(ref, () => ({ refresh }))

    useEffect(() => {
      refresh()
    }, [])

    return (
      <div
        ref={scrollbarRef}
        className={classNames('custom-scrollbar', {
          'custom-scrollbar-horizontal': horizontal,
          'custom-scrollbar-show': show,
        })}
        onClick={handleScrollbarClick}
      >
        <div ref={thumbRef} className='custom-scrollbar-thumb' />
      </div>
    )
  },
)
