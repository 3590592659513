import { Col, Form, Row } from 'antd'
import type { AgentPluginProperties } from '@bty/chat-types'
import { Select } from '@/components'

export interface PluginConfOutputsProps {
  conf: NonNullable<AgentPluginProperties['outputs']>
}

export function PluginConfOutputs({ conf }: PluginConfOutputsProps) {
  return (
    <>
      <p className='mb-12 text-14px font-medium'>输出配置</p>
      <Row gutter={[12, 20]}>
        {Object.entries(conf).map(([key, value]) => (
          <Col key={key} span={12}>
            <Form.Item name={key} noStyle>
              <Select<string, { key: string; description: string }>
                className='w-full h-auto'
                options={value.optional}
                fieldNames={{ label: 'description', value: 'key' }}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </>
  )
}
