import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Error } from '@/components/error'
import PlaceholderPNG from '@/assets/app/rocket.png'
import { useBotStore } from '@/store'

export default function () {
  const navigate = useNavigate()
  const fetchBotList = useBotStore(state => state.fetchBotList)

  const toDefaultBot = async () => {
    try {
      const botList = await fetchBotList()
      if (botList.length) {
        navigate(`/chatbot/${botList[0].robot_id}`)
      }
    } catch (e) {
      console.log('fetch bot list error')
    }
  }

  useEffect(() => {
    // bot 不存在默认返回对话页，选中第一个可以选择的Bot
    toDefaultBot()
  }, [])

  return (
    <div className='h-full'>
      <div className='h-full bg-white rounded-12px'>
        <Error
          image={PlaceholderPNG}
          content={
            <div className='flex flex-col items-center'>
              <div className='line-height-20px'>暂无对话</div>
              <div className='line-height-20px mt-10'>
                请将Agent发布到「对话」
              </div>
            </div>
          }
        />
      </div>
    </div>
  )
}
