import { Dropdown, Switch } from 'antd'
import classNames from 'classnames'
import type { AgentFlowSkill, AgentListSystemSkillItem } from '@bty/chat-types'
import { Skill } from '@/features/agent/components/Skill.tsx'
import { IconFont, useConfirmModal, usePageModal } from '@/components'
import type { SkillError } from '@/features/agent/hooks/useSkillConfig.ts'
import { useWorkspaceStore } from '@/store'
import type { SkillBaseInfo, SkillType } from '../types'

export type SkillValue =
  | (SkillBaseInfo & {
      meta: AgentFlowSkill
      error?: SkillError | undefined
    })
  | (SkillBaseInfo & {
      meta: AgentListSystemSkillItem
    })

export interface SkillListItemProps {
  type: SkillType
  skill: SkillValue
  forcedInvoke?: boolean
  onEdit?: (skill: SkillValue) => void
  onDelete?: (id: SkillValue['id']) => void
  onEnableChange?: (checked: boolean, skill: SkillValue) => void
  showRemovalNotice?: boolean
}

export function SkillListItem({
  type,
  skill,
  forcedInvoke,
  onEdit,
  onEnableChange,
  onDelete,
  showRemovalNotice,
}: SkillListItemProps) {
  const [deleteModal] = useConfirmModal()

  const pageModal = usePageModal()

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  let disabled = false
  let titleSuffix = null
  if (type === 'Flow') {
    const flow = skill as SkillBaseInfo & {
      meta: AgentFlowSkill
      error?: SkillError | undefined
    }
    disabled = flow.error?.type === 'error'
    titleSuffix = (
      <div className='flex'>
        {flow.error && (
          <div
            className={classNames(
              'ml-8 text-12px px-4 py-2 flex-center rounded-4px mr-12px',
              {
                'bg-error bg-op-12 c-error': flow.error?.type === 'error',
                'bg-warning bg-op-12 c-warning': flow.error?.type === 'warning',
              },
            )}
          >
            <IconFont name='shuimeng' className='text-14px mr-4' />
            <span>{flow.error?.message}</span>
          </div>
        )}
        {flow.meta?.app_id && flow.meta?.agent_rel_flow_status !== 'DELETED' ? (
          <span
            className='text-16px ml-8 cursor-pointer c-#626999 c-op-60 hidden group-hover:flex-center pr-20px'
            onClick={e => {
              e.stopPropagation()
              pageModal.show({
                url: `/application/${currentWorkspaceId}/${flow.meta.app_id}/flow/${flow.meta.flow_id}`,
              })
            }}
          >
            <IconFont name='super-link' />
          </span>
        ) : undefined}
      </div>
    )
  }

  if (type === 'Plugin') {
    titleSuffix = (
      <span
        className='text-16px ml-8 cursor-pointer c-#626999 c-op-60 hidden group-hover:flex-center pr-20px'
        onClick={e => {
          e.stopPropagation()
          pageModal.show({
            url: `/plugin/info/${skill.id}?pageType=infoOnly`,
          })
        }}
      >
        <IconFont name='super-link' />
      </span>
    )
  }

  const menu = {
    items: [
      {
        key: 'delete',
        label: (
          <span className='block rounded-4px font-400 cursor-pointer hover:bg-bg_3 hover:bg-op-8 w-88px px-12 py-9 c-font_red'>
            移除
          </span>
        ),
      },
    ],
    onClick: () => {
      deleteModal.open({
        title: <span className='font-medium'>是否确认删除该技能?</span>,
        okText: '删除',
        onOk: () => {
          onDelete?.(skill.id)
          deleteModal.close()
        },
      })
    },
  }

  return (
    <Skill
      showRemovalNotice={showRemovalNotice}
      className='group'
      value={skill}
      titleSuffix={null}
      suffix={
        <div className='flex items-center' onClick={e => e.stopPropagation()}>
          {titleSuffix}
          {!disabled && (
            <span
              className='w-28px h-28px mr-24 text-16px text-[rgba(98,105,153,0.6)] flex-center'
              onClick={() => onEdit?.(skill)}
            >
              <IconFont
                className='group-hover:text-primary'
                name='kongjianshezhi'
              />
            </span>
          )}
          <Switch
            size='small'
            disabled={disabled}
            defaultChecked={skill?.meta?.is_enable}
            onChange={checked => {
              onEnableChange?.(checked, skill)
            }}
          />
          <Dropdown
            overlayClassName='[&>.ant-dropdown-menu]:rounded-8px'
            menu={menu}
            trigger={['click']}
            placement='bottomRight'
          >
            <span className='ml-12 flex-center w-32px h-32px text-16px text-[rgba(98,105,153,0.6)] rounded-8px cursor-pointer hover:text-[rgba(23,23,29,0.6)] hover:bg-[rgba(98,105,153,0.08)] transition-colors duration-200 ease-[cubic-bezier(0.645,0.045,0.355,1)]'>
              <IconFont name='gengduo' />
            </span>
          </Dropdown>
        </div>
      }
      forcedInvoke={forcedInvoke}
      disabled={disabled}
      onClick={() => onEdit?.(skill)}
    />
  )
}
