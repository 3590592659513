import type { ColumnType } from '@bty/smartsheet'
import { useRequest } from 'ahooks'
import { Form, message } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  getTableColumnsHash,
  tableFieldUpdateBulk,
} from '@/apis/database/api.ts'
import type { TableFieldUpdateBulkRequest } from '@/apis/database/types.ts'
import { Button, FormItem, IconFont, TextArea } from '@/components'
import { useTableDescGeneral } from '@/features/database/hooks/useTableDescGeneral.ts'
import { useTable } from '@/features/database/provider/TableProvider.tsx'
import type { MoveField } from '@/features/database/types/column.ts'
import {
  compareCols,
  compareColumns,
  fixColumn,
} from '@/features/database/utils/column.ts'
import { checkTableHasDescription } from '../utils/table'
import { DescriptionAutoGen } from './DescriptionAutoGen'
import { FormListColumns } from './FormListColumns'

interface TableDescEditPanelProps {
  open?: boolean
  onClose?: () => void
}

export default function TableDescEditPanel({
  open,
  onClose,
}: TableDescEditPanelProps) {
  return (
    <div
      className={classNames(
        'bg-white max-w-640px transition-width-200 overflow-hidden',
        open ? 'w-600px' : 'w-0',
      )}
    >
      {open && <TableDescEditContent onClose={onClose} />}
    </div>
  )
}

function TableDescEditContent({ onClose }: { onClose?: () => void }) {
  const [form] = Form.useForm()

  const scrollRef = useRef<HTMLDivElement | null>(null)

  const { currentTable, reloadCurrentTable } = useTable()

  const { onTableDescriptionGeneral, loading: generalDescLoading } =
    useTableDescGeneral(form)

  const moveColumns = useRef<MoveField[]>([])

  const columns = useMemo(
    () => currentTable?.columns?.filter(column => !column.system) ?? [],
    [currentTable],
  )

  useEffect(() => {
    form.setFieldsValue({
      columns,
      title: currentTable?.title,
      description: currentTable?.description ?? '',
    })
  }, [currentTable])

  const { data: hashRes } = useRequest(getTableColumnsHash, {
    defaultParams: [currentTable!.id],
    ready: !!currentTable,
    refreshDeps: [currentTable?.id],
  })

  const onSave = async () => {
    try {
      if (currentTable && hashRes?.hash) {
        const value = await form.validateFields()
        const oldColumns =
          currentTable.columns?.filter(column => !column.system) ?? []
        const diff = compareColumns(oldColumns, value.columns, ['description'])
        const viewId = currentTable.views?.[0].id ?? ''
        let hasChange = false
        const reqValue: Required<TableFieldUpdateBulkRequest> = {
          base_id: currentTable?.base_id,
          table: {},
          column_bulk: {
            hash: hashRes.hash,
            ops: [],
          },
        }
        if (currentTable.description !== value.description) {
          hasChange = true
          reqValue.table = {
            ...reqValue.table,
            description: value.description,
          }
        }
        if (
          diff.add.length ||
          diff.update.length ||
          diff.delete.length ||
          moveColumns.current.length
        ) {
          hasChange = true
          reqValue.column_bulk.ops = [
            ...diff.add.map((item: ColumnType) => ({
              op: 'add',
              column: fixColumn(item),
            })),
            ...diff.delete.map((item: ColumnType) => ({
              op: 'delete',
              column: item,
            })),
            ...diff.update.map((item: ColumnType) => ({
              op: 'update',
              column: fixColumn(item),
            })),
          ]

          const recordMap: Record<string, true> = {}
          for (const moveColumn of moveColumns.current) {
            const op = reqValue.column_bulk.ops.find(op =>
              compareCols(op.column, moveColumn),
            )
            if (op) {
              recordMap[moveColumn.id || moveColumn.temp_id || 'unknow'] = true
              if (op.op === 'add' || op.op === 'update') {
                op.column.column_order = {
                  order: moveColumn.order,
                  view_id: viewId,
                }
              }
            }
          }
          const restMoveColumnOps: Required<TableFieldUpdateBulkRequest>['column_bulk']['ops'] =
            moveColumns.current
              .filter(column => {
                if (column.id || column.temp_id) {
                  return !recordMap[(column.id ?? column.temp_id) as string]
                }
                return false
              })
              .map(item => {
                return {
                  op: 'update',
                  column: fixColumn({
                    ...value.columns[item.index],
                    column_order: {
                      order: item.order,
                      view_id: viewId,
                    },
                  }),
                }
              })

          reqValue.column_bulk.ops =
            reqValue.column_bulk.ops.concat(restMoveColumnOps)
        }
        if (hasChange) {
          await tableFieldUpdateBulk(currentTable.id, reqValue)
          reloadCurrentTable()
          message.success('修改成功')
        }
        onClose?.()
      }
      onClose?.()
    } catch (e) {
      console.error('save fields description', e)
    }
  }

  const showConfirmIfNeed = useCallback(() => {
    const formData = form.getFieldsValue()
    return checkTableHasDescription(formData)
  }, [form])

  return (
    <div className='flex flex-col h-full overflow-hidden'>
      <div className='header b-b b-line b-op-60 flex-center-between py-7 px-16'>
        <span className='line-height-16px font-600'>数据表调用配置</span>
        <div className='flex items-center'>
          <Button onClick={onSave} type='primary' size='small'>
            保存
          </Button>
          <span
            className='w-32px rd-6px h-32px b-1 b-line b-op-80 flex-center cursor-pointer ml-8'
            onClick={onClose}
          >
            <IconFont name='guanbi' />
          </span>
        </div>
      </div>
      <div className='flex-1 p-16 overflow-y-auto' ref={scrollRef}>
        <DescriptionAutoGen
          className={classNames(
            'w-full h-32px mt-8 mb-24 bg-#f5f3ff rounded-8px',
            generalDescLoading ? 'bg-op-60' : 'hover:bg-op-60',
          )}
          text='优化描述和字段'
          loading={generalDescLoading}
          showConfirmIfNeed={showConfirmIfNeed}
          onAutoGen={onTableDescriptionGeneral}
        />
        <Form form={form} layout='vertical' requiredMark={false}>
          <Form.Item name='title' hidden />
          <FormItem
            name='description'
            label='数据表描述'
            rules={[{ required: true, message: '数据表描述不能为空' }]}
            required
          >
            <TextArea
              showCount
              autoSize={{
                minRows: 2,
              }}
              maxLength={200}
              placeholder='请输入数据表描述，这段文字将作为 Agent 的使用引导'
            />
          </FormItem>
          <FormListColumns namePath='columns' />
        </Form>
      </div>
    </div>
  )
}
