import { DatePicker, TimePicker } from 'antd'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useMemoizedFn } from 'ahooks'
import { IconFont } from '@/components'

interface DateSelectProps extends Record<string, any> {
  value?: number
  onChange?: (value?: number) => void
}

export const DateSelect = memo((props: DateSelectProps) => {
  const { value, onChange, ...rest } = props
  const timeHasMod = useRef(false)

  const dateValue = useMemo(() => {
    if (!value) return undefined
    return dayjs(value * 1000)
  }, [value])

  const handelDateChange = useMemoizedFn((date: Dayjs | null) => {
    if (!date) {
      onChange?.(undefined)
      return
    }

    let sureDate = date.clone()
    const nowDate = dayjs()
    if (dateValue) {
      sureDate = sureDate.hour(dateValue.hour())
      sureDate = sureDate.minute(dateValue.minute())
    } else {
      sureDate = sureDate.hour(nowDate.hour())
      const nowMin = nowDate.minute()
      if (nowMin <= 30) {
        sureDate = sureDate.minute(30)
      } else {
        sureDate = sureDate.minute(60)
      }
    }

    let timestamp = sureDate.valueOf()
    const second = timestamp % 60000
    timestamp = timestamp - second

    onChange?.(timestamp / 1000)
  })

  const handleTimeChange = useMemoizedFn((time: Dayjs | null) => {
    if (!time) {
      onChange?.(undefined)
      return
    }

    timeHasMod.current = true

    let timestamp = time.valueOf()
    const second = timestamp % 60000
    timestamp = timestamp - second

    onChange?.(timestamp / 1000)
  })

  const disabledDate = useCallback((checkDate: Dayjs) => {
    return checkDate.isBefore(dayjs().subtract(1, 'day'))
  }, [])

  // const disabledTime = useCallback(() => {
  //   return {
  //     disabledHours: () => {
  //       return range(0, 24).filter(hour => {
  //         if (!dateValue || dateValue.isAfter(dayjs())) return false
  //         const hourDate = dateValue.clone().hour(hour)
  //         return hourDate.isBefore(dateValue)
  //       })
  //     },
  //     disabledMinutes: () => {
  //       return range(0, 60).filter(minute => {
  //         if (!dateValue || dateValue.isAfter(dayjs())) return false
  //         const hourDate = dateValue.clone().minute(minute)
  //         return hourDate.isBefore(dateValue)
  //       })
  //     },
  //   }
  // }, [dateValue])

  useEffect(() => {
    if (dateValue) return

    let sureDate = dayjs()
    const nowDate = dayjs()
    sureDate = sureDate.hour(nowDate.hour())
    const nowMin = nowDate.minute()
    if (nowMin <= 30) {
      sureDate = sureDate.minute(30)
    } else {
      sureDate = sureDate.minute(60)
    }

    let timestamp = sureDate.valueOf()
    const second = timestamp % 60000
    timestamp = timestamp - second

    onChange?.(timestamp / 1000)
  }, [])

  return (
    <div>
      <DatePicker
        {...rest}
        className='mr-8px h-36px bg-bg_3 bg-op-6 border-transparent hover:border-primary [&.ant-picker-disabled]:border-transparent [&.ant-picker-disabled]:bg-[rgba(98,105,153,0.06)]'
        allowClear={false}
        showToday={false}
        suffixIcon={
          <IconFont name='arrow' className='text-7px text-font_1 mr-2px' />
        }
        disabledDate={disabledDate}
        value={dateValue}
        onChange={handelDateChange}
      />

      <TimePicker
        {...rest}
        className='h-36px bg-bg_3 bg-op-6 border-transparent hover:border-primary [&.ant-picker-disabled]:border-transparent [&.ant-picker-disabled]:bg-[rgba(98,105,153,0.06)]'
        format='HH:mm'
        showNow={false}
        allowClear={false}
        suffixIcon={
          <IconFont name='arrow' className='text-7px text-font_1 mr-2px' />
        }
        needConfirm={false}
        // disabledTime={disabledTime}
        value={dateValue}
        onChange={handleTimeChange}
      />
    </div>
  )
})
