export function createSocketUrl(path: string, params: Record<string, any>) {
  // 本地测试走/ws的代理配置
  const baseUrl =
    import.meta.env.MODE === 'development'
      ? `ws://${location.host}/ws`
      : `${import.meta.env.VITE_AI_WS_BASE_URL}`

  const buildUrl = baseUrl + path
  const url = new URL(buildUrl)

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      url.searchParams.set(
        key,
        Array.isArray(value) ? value.join(',') : value.toString(),
      )
    }
  }

  return url.toString()
}
