import classNames from 'classnames'
import { useRef } from 'react'
import { ModelSelect, NodeFormItem } from '@/features/nodes/components'
import type { ModalSettingValue } from '../../components/type'
import { MiniSetting } from './MiniModalSetting'

export function LLMPanelOptions(props: any) {
  const { value, onChange, readOnly = false, asyncSupport = false } = props

  const onValueChange = (newValue: ModalSettingValue) => {
    if (readOnly) {
      return
    }
    onChange({ ...value, modelSetting: { ...value.modelSetting, ...newValue } })
  }

  const onModelChange = (newModel: any) => {
    if (readOnly) {
      return
    }
    onChange({ ...value, modelSetting: { ...value.modelSetting, ...newModel } })
  }

  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={wrapperRef}
      className='w-full flex justify-between items-center flex-1'
      style={{
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <NodeFormItem required className='flex-1 important:mb-0px'>
        <ModelSelect
          onChange={onModelChange}
          value={{
            model: value.modelSetting.model,
            channel: value.modelSetting.channel,
          }}
          popupMatchSelectWidth={false}
          getPopupContainer={() => props.nodeElement as HTMLElement}
          popupWrapper={wrapperRef.current}
          asyncSupport={asyncSupport}
        />
      </NodeFormItem>
      <NodeFormItem className={classNames('ml-12px important:mb-0px w-64px')}>
        <MiniSetting value={value.modelSetting} onChange={onValueChange} />
      </NodeFormItem>
    </div>
  )
}
