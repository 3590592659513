import type { OnMove, OnMoveEnd, OnMoveStart } from 'reactflow'
import { useFlowInteractionStore, useMovableOptionStore } from '@/store'

interface Options {
  startCallback?: () => void
  endCallback?: () => void
}

export function useFlowDraftMove(options: Options) {
  const { startCallback, endCallback } = options
  const { isAddMode } = useFlowInteractionStore()
  const { show, hide, setPosition, visible } = useMovableOptionStore()

  const onMoveStart: OnMoveStart = _e => {
    startCallback?.()
  }

  const onMove: OnMove = e => {
    if (isAddMode && visible && e) {
      hide()
    }
  }

  const onMoveEnd: OnMoveEnd = e => {
    if (e instanceof MouseEvent) {
      const { x, y } = e
      setPosition({
        x,
        y,
      })
      show()
    }
    endCallback?.()
  }

  return {
    onMoveStart,
    onMoveEnd,
    onMove,
  }
}
