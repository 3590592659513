import { useMemo } from 'react'
import { groupBy, map } from 'lodash-es'
import type { ITemplateItem } from '@/apis/flow'
import { TEMPLATE_DISPLAYNAME } from '@/constants/common'
import { TemplateItemCard } from './TemplateItemCard'

interface TemplateListProps {
  data: ITemplateItem[]
  activeKey: string
  onSelect: (id: string) => void
}

export function TemplateList(props: TemplateListProps) {
  const { data, activeKey, onSelect } = props

  const groupedData = useMemo(() => {
    const res = groupBy(data, each => each.category)
    return res
  }, [data])

  return (
    <>
      <div className='pt-12px pb-24px text-16px font-500 c-#17171d'>
        {TEMPLATE_DISPLAYNAME}
      </div>
      {data.length ? (
        map(groupedData, (list, key) => {
          return (
            <div key={key}>
              <div className='pb-12px font-500 text-#8d8d99'>{key}</div>
              {list.map(
                ({
                  id,
                  name,
                  icon,
                  color,
                  description,
                  thumbnail,
                  thumbnail_width,
                  related_blog,
                }) => (
                  <TemplateItemCard
                    key={id}
                    id={id}
                    name={name}
                    icon={icon}
                    iconColor={color}
                    description={description}
                    image={thumbnail}
                    imageWidth={thumbnail_width}
                    helpLink={related_blog}
                    selected={id === activeKey}
                    onClick={onSelect}
                  />
                ),
              )}
            </div>
          )
        })
      ) : (
        <div className='mb-10px line-height-18px text-#8d8d99 text-op-60'>
          暂无可用模板
        </div>
      )}
    </>
  )
}
