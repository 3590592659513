export function getTopLevelDomain() {
  const domainParts = new URL(location.href).hostname.split('.')

  // 检查是否为双重顶级域名（如 .co.uk）
  const topLevelDomains = ['co.uk', 'com.au', 'gov.uk', 'org.uk']
  if (domainParts.length > 2) {
    const potentialTLD = domainParts.slice(-2).join('.')
    if (topLevelDomains.includes(potentialTLD)) {
      return domainParts.slice(-3).join('.')
    }
  }

  return domainParts.slice(-2).join('.')
}
