import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'
import { useState } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { IconFont } from '@/components'
import type { TableEditModalProps } from '@/features/database/components/TableEditModal'
import TableEditModal from '@/features/database/components/TableEditModal'
import type { TableImportModalProps } from '@/features/database/components/TableImportModal'
import TableImportModal from '@/features/database/components/TableImportModal'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

interface TableCreateDropdownProps {
  useInAgent?: {
    flow_id: string
    version_id: string
  }
  database?: TableEditModalProps['database']
  onOk?: TableEditModalProps['onOk'] | TableImportModalProps['onOk']
  children: React.ReactNode | ((open?: boolean) => React.ReactNode)
}
export default function TableCreateDropdown({
  useInAgent,
  database,
  children,
  onOk,
}: TableCreateDropdownProps) {
  const [open, setOpen] = useState(false)

  const tableCreateModal = useModal(TableEditModal)
  const tableImportModal = useModal(TableImportModal)

  const menus: MenuProps['items'] = [
    {
      key: 'create',
      label: (
        <LimitedAccess limitedType>
          <div
            onClick={() => {
              if (!database) {
                return
              }
              tableCreateModal.show({
                database,
                onOk: onOk as TableEditModalProps['onOk'],
              })
            }}
            className='flex-center gap-4 hover:bg-#7F69FF hover:bg-op-8 px-8 py-11 cursor-pointer rd-6px'
          >
            <IconFont name='add' />
            <span>创建数据表</span>
          </div>
        </LimitedAccess>
      ),
    },
    {
      key: 'import',
      label: (
        <LimitedAccess limitedType>
          <div
            onClick={() => {
              if (!database) {
                return
              }
              tableImportModal.show({
                useInAgent,
                database,
                onOk: onOk as TableImportModalProps['onOk'],
              })
            }}
            className='flex-center gap-4 hover:bg-#7F69FF hover:bg-op-8 px-8 py-11 cursor-pointer rd-6px'
          >
            <IconFont name='import' />
            <span>导入数据表</span>
          </div>
        </LimitedAccess>
      ),
    },
  ]

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      trigger={['hover']}
      menu={{
        items: menus,
        style: { padding: 4, width: 138 },
      }}
    >
      {typeof children === 'function' ? children(open) : children}
    </Dropdown>
  )
}
