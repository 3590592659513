import {
  TextSelection,
  type EditorState,
  type Transaction,
} from 'prosemirror-state'

export function arrowRight(
  state: EditorState,
  dispatch?: (tr: Transaction) => void,
) {
  if (!dispatch) return false

  const from = state.selection.from

  const { parent: fromNode } = state.doc.resolve(from)

  const { parent: afterNode } = state.doc.resolve(from + 1)

  if (
    ['pe-input', 'pe-select'].includes(fromNode?.type.name) &&
    afterNode?.type.name === 'paragraph'
  ) {
    dispatch(state.tr.setSelection(TextSelection.create(state.doc, from + 1)))
    return true
  }

  if (
    fromNode?.type.name === 'paragraph' &&
    ['pe-input', 'pe-select'].includes(afterNode?.type.name)
  ) {
    dispatch(state.tr.setSelection(TextSelection.create(state.doc, from + 1)))
    return true
  }

  return false
}
