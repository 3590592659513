import styled from '@emotion/styled'

const Content = styled.div<{ occupiedHeight: string }>`
  height: ${({ occupiedHeight }) => `calc(100% - ${occupiedHeight}px)`};
  transition: height 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
  will-change: height;
  overflow-y: auto;
  scroll-behavior: smooth;
`

interface OverflowContentProps {
  // 被占用的高度， 比如整个容器的高度是 100%, 如果有一个固定高度的 header 占用了 48px, 那么这个值就是 48
  occupiedHeight: string
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export function OverflowContent(props: OverflowContentProps) {
  const { occupiedHeight, children, className, style } = props
  return (
    <Content
      className={className}
      occupiedHeight={occupiedHeight}
      style={style}
    >
      {children}
    </Content>
  )
}
