import type {
  AuthAppBase,
  AuthAppDingTalk,
  AuthAppWeChat,
  PUBLISH_MODE,
} from '@bty/chat-types'
import type { FlowData } from './flow'
import { request } from './lib'

export interface AuthAppDingTalkWeb extends AuthAppBase {
  auth_app_source: typeof PUBLISH_MODE.dingtalk_web
  auth_app_config: {
    AppKey: string
    AppSecret: string
  }
}

export interface AuthAppWebChatOpenApp extends AuthAppBase {
  auth_app_source: typeof PUBLISH_MODE.wechat_open_app
  auth_app_config: {
    AppID: string
    AppSecret: string
    Token: string
    EncodingAESKey: string
    OriginId: string
  }
}

export interface AuthAppEnterpriseWebChat extends AuthAppBase {
  auth_app_source: typeof PUBLISH_MODE.wechat_enterprise
  auth_app_config: {
    Token: string
    CorpID: string
    Secret: string
    AgentId: string
    EncodingAESKey: string
  }
}
export interface AuthAppFlyBook extends AuthAppBase {
  auth_app_source: typeof PUBLISH_MODE.feishu
  bot_info?: {
    avatar_url?: string
    app_name: string
  }
  auth_app_config: {
    AppID: string
    AppSecret: string
    EncryptKey: string
    VerificationToken: string
  }
}

export type AuthAppSource =
  | AuthAppWeChat['auth_app_source']
  | AuthAppDingTalk['auth_app_source']
  | AuthAppDingTalkWeb['auth_app_source']
  | AuthAppWebChatOpenApp['auth_app_source']
  | AuthAppEnterpriseWebChat['auth_app_source']
  | AuthAppFlyBook['auth_app_source']

export type AuthApp =
  | AuthAppDingTalk
  | AuthAppDingTalkWeb
  | AuthAppWeChat
  | AuthAppWebChatOpenApp
  | AuthAppEnterpriseWebChat
  | AuthAppFlyBook

export function queryApp(
  auth_app_source: AuthAppWeChat['auth_app_source'],
  agent_id: string,
): Promise<AuthAppWeChat>
export function queryApp(
  auth_app_source: AuthAppDingTalk['auth_app_source'],
  agent_id: string,
): Promise<AuthAppDingTalk>
export function queryApp(
  auth_app_source: AuthAppDingTalkWeb['auth_app_source'],
  agent_id: string,
): Promise<AuthAppDingTalkWeb>
export function queryApp(
  auth_app_source: AuthAppWebChatOpenApp['auth_app_source'],
  agent_id: string,
): Promise<AuthAppWebChatOpenApp>
export function queryApp(
  auth_app_source: AuthAppEnterpriseWebChat['auth_app_source'],
  agent_id: string,
): Promise<AuthAppEnterpriseWebChat>
export function queryApp(
  auth_app_source: AuthAppFlyBook['auth_app_source'],
  agent_id: string,
): Promise<AuthAppFlyBook>
export function queryApp(
  auth_app_source: AuthAppSource,
  agent_id: string,
): Promise<AuthApp> {
  return request.get(`/v1/agent/auth_app/${auth_app_source}/${agent_id}`)
}

export function queryWeChatAppConfig(
  corpId: string,
): Promise<AuthAppWeChat['auth_app_config']> {
  return request.get(`/v1/agent/weixin_kf/find_by_corp_id?corp_id=${corpId}`)
}

interface CreateAppPayload {
  agent_id: string
  auth_app_name: string
  auth_app_source: AuthAppSource
  auth_app_config: Record<string, unknown>
}
export function createApp(data: CreateAppPayload): Promise<AuthApp> {
  return request.post('/v1/agent/auth_app/new', data)
}

interface UpdateAppPayload {
  auth_app_id: string
  auth_app_name: string
  auth_app_config: Record<string, unknown>
}
export function updateApp(data: UpdateAppPayload): Promise<unknown> {
  return request.post<AuthApp>('/v1/agent/auth_app/edit', data)
}

export function appConnectTest(
  auth_app_id: string,
): Promise<AuthAppBase['conn_status']> {
  return request.get(`/v1/agent/auth_app/conn_test/${auth_app_id}`)
}

export function unbindApp(auth_app_id: string) {
  return request.delete(`/v1/agent/auth_app/${auth_app_id}`)
}

export function enableApp(auth_app_id: string, enabled = true) {
  return request.post('/v1/agent/auth_app/enable', { auth_app_id, enabled })
}

export function getBindAuthAppList(agent_id: string) {
  return request.get<Array<AuthAppDingTalk | AuthAppWeChat>>(
    `/v1/agent/auth_app/find_by_agent_id?agent_id=${agent_id}`,
  )
}

export type GetFlowsWithFormResponse = (FlowData & {
  dingtalk_card_template_id?: string
})[]
export function getFlowsWithForm(
  agent_id: string,
): Promise<GetFlowsWithFormResponse> {
  return request.get(`/v1/flow/query_form_flow?agent_pk=${agent_id}`)
}

export function updateFormTemplateForFlows(
  templates: { flow_id: string; template_id: string }[],
) {
  return request.post('/v1/flow/update/template_id', templates)
}
