import './index.css'
import 'prosemirror-view/style/prosemirror.css'

import type { MutableRefObject } from 'react'
import {
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { EditorState, Plugin } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import type { Node } from 'prosemirror-model'
import { Schema } from 'prosemirror-model'
import { history } from 'prosemirror-history'
import classNames from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { createPortal } from 'react-dom'
import {
  getProsemirrorJsonFromNode,
  getNodeFromText,
  findNodeStartEnd,
  getTextFromDoc,
} from './util'
import { peNodes } from './nodes'
import { peMarks } from './marks'
import { peCommand } from './command'

export interface IERef {
  view?: EditorView
  setTemplate: (template: string) => void
  setOptionMap: (optionMap: Record<string, string[]>) => void
  focus: () => void
}

interface PlaceholderEditorProps {
  template?: string
  eRef?: MutableRefObject<IERef | undefined>
  optionMap?: Record<string, string[]>
  getContainer?: () => HTMLElement
  onChange?: (value: string) => void
}

export const PlaceholderEditor = memo((props: PlaceholderEditorProps) => {
  const {
    template,
    eRef,
    optionMap,

    getContainer,
    onChange,
  } = props

  const ref = useRef<HTMLDivElement>(null)
  const optionMapRef = useRef(optionMap)
  const editorView = useRef<EditorView>()
  const [focus, setFocus] = useState(false)
  const [nowEditorNode, setNowEditorNode] = useState<Node>()
  const [nodeRange, setNodeRange] = useState<{ start: number; end: number }>({
    start: -1,
    end: -1,
  })
  const [nowNodeOption, setNowNodeOption] = useState<string[]>()

  const host = useMemo(() => {
    return getContainer?.() ?? document.body
  }, [getContainer])

  const selectNodePlugin = useMemo(() => {
    return new Plugin({
      view() {
        return {
          update(view) {
            const $from = view.state.selection.$from
            const focusNode = $from.parent

            if (focusNode.attrs.key) {
              setNowEditorNode(focusNode)
              setNodeRange(findNodeStartEnd(focusNode, view.state.doc))
              setNowNodeOption(optionMapRef.current?.[focusNode.attrs.key])
            } else {
              setNowEditorNode(undefined)
            }
          },
        }
      },
    })
  }, [])

  const valuePlugin = useMemo(() => {
    return new Plugin({
      view() {
        return {
          update(view, prevState) {
            if (view.state.doc === prevState.doc || !onChange) return
            onChange(getTextFromDoc(view.state.doc))
          },
        }
      },
    })
  }, [])

  const popPosition = useMemo(() => {
    if (!editorView.current || nodeRange.start === -1)
      return { top: -1, left: -1 }
    const coords = editorView.current.coordsAtPos(nodeRange.start)
    const editorRect = host.getBoundingClientRect()
    return {
      top: coords.top - editorRect.top + 26,
      left: coords.left - editorRect.left + 4,
    }
  }, [nodeRange])

  const mySchema = useMemo(() => {
    return new Schema({
      nodes: peNodes,
      marks: peMarks,
    })
  }, [])

  const initFromTemplate = useMemoizedFn((template?: string) => {
    editorView.current?.destroy()
    setNowEditorNode(undefined)
    setNodeRange({ start: -1, end: -1 })

    const nodes = getNodeFromText(template ?? '')
    const view = new EditorView(ref.current, {
      state: EditorState.create({
        schema: mySchema,
        doc: mySchema.nodeFromJSON(getProsemirrorJsonFromNode(nodes)),
        plugins: [history(), peCommand(), selectNodePlugin, valuePlugin],
      }),
    })

    if (onChange) {
      onChange(getTextFromDoc(view.state.doc))
    }

    view.focus()

    editorView.current = view

    return view
  })

  useEffect(() => {
    initFromTemplate(template)
    return () => {
      editorView.current?.destroy()
    }
  }, [])

  useImperativeHandle(eRef, () => ({
    view: editorView.current,
    setTemplate: initFromTemplate,
    setOptionMap: optionMap => (optionMapRef.current = optionMap),
    focus: () => editorView.current?.focus(),
  }))

  const replaceNodeContent = useMemoizedFn((text: string) => {
    if (!editorView.current || !nowEditorNode) return
    const view = editorView.current
    const state = editorView.current?.state
    const textNode = state.schema.text(text)
    const tr = state.tr.replaceWith(
      nodeRange.start + 1,
      nodeRange.start + nowEditorNode.nodeSize - 1,
      textNode,
    )

    view.dispatch(tr)
    view.focus()
  })

  return (
    <div className='placeholder-editor'>
      <div
        ref={ref}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {createPortal(
        <div
          className={classNames(
            'placeholder-editor-pop',
            nowEditorNode && focus && nowNodeOption?.length
              ? 'placeholder-editor-pop-show'
              : 'placeholder-editor-pop-hidden',
          )}
          style={popPosition}
          onMouseDown={event => {
            event.preventDefault()
            event.stopPropagation()
          }}
        >
          {nowNodeOption?.map((each, index) => (
            <div
              key={index}
              className={classNames('placeholder-editor-pop-item', {
                'placeholder-editor-pop-item-active':
                  nowEditorNode?.textContent === each,
              })}
              onMouseDown={() => {
                replaceNodeContent(each)
              }}
            >
              {each}
            </div>
          ))}
        </div>,
        host,
      )}
    </div>
  )
})
