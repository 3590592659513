import { useContext, useEffect, useMemo } from 'react'
import { Form } from 'antd'
import { assign, get, isEmpty, isNil } from 'lodash-es'
import type {
  NodePanelComponent,
  InnerNodePanelProps,
} from '@/features/nodes/base'
import type { InsertMemoryNodeData } from '../InsertMemoryNode'

import { JsonForm, type JsonFormConfig } from '@/features/nodes/components'
import { DocumentSelect } from '@/features/nodes/insert-memory/DocumentSelect.tsx'

import { Button } from '@/components'
import { useDynamicValues } from '../../hooks/useDynamicValues'
import { DynamicForm } from '../../base/components/DynamicForm'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import useStartFileNodeVariableKeyList from '../../hooks/useStartFileNodeVariableKeyList'

export const InsertMemoryNodePanel: NodePanelComponent<InsertMemoryNodeData> = (
  props: InnerNodePanelProps<any>,
) => {
  const {
    data,
    activatedNodeId,
    nodeElement,
    variables,
    startNodeFormItemType = [],
  } = props

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType,
    variables,
  )

  const [form] = Form.useForm()

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: form?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message = [{ content: get(value, 'inputs.content') }]
      return message
    },
  })

  const { usedKeyListByNodeId } = val

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await form?.validateFields()
    run(data)
  }

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })

    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const selected_partition_id = form.getFieldValue(['inputs', 'partition_id'])
  const list = useMemo<JsonFormConfig[]>(
    () => [
      {
        label: '知识库',
        name: ['inputs', 'partition_id'],
        required: true,
        rules: [{ required: true, message: '请选择知识库' }],
        type: 'DatasetSelect',
      },
      {
        label: '数据文件',
        name: ['inputs', 'file_id'],
        required: true,
        hidden: !form.getFieldValue(['inputs', 'partition_id']),
        rules: [{ required: true, message: '请选择数据文件' }],
        render: () => {
          return <DocumentSelect memoryId={selected_partition_id} />
        },
      },
      {
        label: '数据内容',
        name: ['inputs', 'content'],
        required: true,
        type: 'TextEditor',
        rules: [{ required: true, message: '插入内容不能为空' }],
        tooltip: '目前仅支持纯文本数据',
        className: 'important:mb-0',
        componentProps: {
          placeholder:
            '输入上下文的变量或纯文本数据，并将变量的数据或者纯文本数据导入知识库中',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
    ],
    [nodeElement, variables, selected_partition_id],
  ) // formItem 中配置dependencies无效，因为list被memo缓存了，先这样解决
  const onBeforeChange = (values: any) => {
    // 知识库变化之后，之前选择的文档就得清空
    if (values.inputs?.partition_id !== selected_partition_id) {
      form.setFieldValue(['inputs', 'file_id'], undefined)
      delete values.inputs.file_id
    }
    return values
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables])

  return (
    <div>
      <div className='p-16px' onWheel={e => e.stopPropagation()}>
        <JsonForm
          list={list}
          form={form}
          beforeChange={onBeforeChange}
        ></JsonForm>
        <div className='mt-16px'>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
      </div>
    </div>
  )
}
