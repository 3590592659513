import { StaticTimeTypes } from '@/apis/monitor'
import { FLOW_DISPLAYNAME } from '@/constants/common'

function toMap(data: typeof AgentRunTypeGroup | typeof FlowRunTypeGroup) {
  return data
    .map(el => el.children)
    .flat()
    .reduce<Record<string, string>>((m, el) => {
      m[el.value] = el.label
      return m
    }, {})
}

export const AgentRunTypeGroup = [
  {
    title: '调试',
    key: 'test',
    value: 'test',
    children: [
      {
        label: '调试',
        value: 'AGENT_TESTING',
      },
      {
        label: '多模型对比',
        value: 'MULTIPLE_MODELS',
      },
    ],
  },
  {
    title: '线上',
    key: 'prod',
    value: 'prod',
    children: [
      {
        label: 'Chatbot',
        value: 'CHATBOT',
      },
      {
        label: 'Agent',
        value: 'AGENT',
      },
      {
        label: 'API',
        value: 'AGENT_OAPI',
      },
      {
        label: 'copilot',
        value: 'COPILOT',
      },
      {
        label: '客户端',
        value: 'CHAT_CLIENT',
      },
      {
        label: '钉钉 stream 机器人',
        value: 'DINGTALK_STREAM_ROBOT',
      },
      {
        label: '钉钉机器人',
        value: 'DINGTALK_ORGAPP_ROBOT',
      },
      {
        label: '微信客服',
        value: 'WEIXIN_KF',
      },
      {
        label: '微信公众号',
        value: 'WEIXIN_MP',
      },
      {
        label: '企业微信',
        value: 'WEIXIN_WORK_APP',
      },
      {
        label: '飞书机器人',
        value: 'FEISHU_APP_BOT',
      },
    ],
  },
]

export const AgentRunTypeMap = toMap(AgentRunTypeGroup)

export const FlowRunTypeGroup = [
  {
    title: '调试',
    key: 'test',
    value: 'test',
    children: [
      {
        label: '调试',
        value: 'TESTING',
      },
      {
        label: '批量调试',
        value: 'BATCH_TEST',
      },
      {
        label: '单节点运行',
        value: 'STEP_RUN',
      },
    ],
  },
  {
    title: '线上',
    key: 'prod',
    value: 'prod',
    children: [
      {
        label: '单次',
        value: 'ONCE_RUN',
      },
      {
        label: '批量',
        value: 'BATCH_RUN',
      },
      {
        label: '定时',
        value: 'CORN_RUN',
      },
      {
        label: 'API',
        value: 'API',
      },
      {
        label: 'Webhook',
        value: 'WEBHOOK',
      },
      {
        label: 'Chatbot',
        value: 'CHATBOT',
      },
      {
        label: 'Agent',
        value: 'AGENT',
      },
      {
        label: `子${FLOW_DISPLAYNAME}`,
        value: 'SUB_FLOW',
      },
    ],
  },
]

export const FlowRunTypeMap = toMap(FlowRunTypeGroup)

export const changeToolTipMap: Partial<Record<StaticTimeTypes, string>> = {
  [StaticTimeTypes.DAILY]: '较昨天',
  [StaticTimeTypes.WEEKLY]: '较上周',
  [StaticTimeTypes.MONTHLY]: '较上月',
  [StaticTimeTypes.QUARTERLY]: '较上季度',
  [StaticTimeTypes.YEARLY]: '较去年',
}

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
