import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import type { NodeType } from '@/features/nodes/base'

interface TobeInsertedNodeData {
  type: NodeType
  data?: any
}

interface NodeInsertStore {
  node: TobeInsertedNodeData | null
  saveNode: (node: TobeInsertedNodeData) => void
}

export const useNodeInsertStore = create<NodeInsertStore>(set => ({
  node: null,
  saveNode: node => {
    set({
      node,
    })
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('nodeInsertStore', useNodeInsertStore)
}
