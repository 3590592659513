import { Select as AntdSelect } from 'antd'
import { useMemo } from 'react'
import classNames from 'classnames'
import { Select } from './Select'

interface DefaultOptionType {
  /**
   * 用于分组
   */
  channel?: string
  label: string
  value: string
  description?: string
  tips?: string
  icon?: string
  extra?: string
}

interface GroupSelectProps<T> {
  className?: string
  value?: string
  options?: T[]
  size?: 'small' | 'middle' | 'large'
  getPopupContainer?: () => HTMLElement
  onChange?: (value: string) => void
}

export function GroupSelect<T extends DefaultOptionType = DefaultOptionType>({
  className,
  value,
  options,
  size,
  getPopupContainer,
  onChange,
}: GroupSelectProps<T>) {
  const innerSize = size || 'middle'

  const groupedOptions = useMemo(() => {
    const grouped: Record<string, { label: string; options: T[] }> = {}

    options?.forEach(option => {
      const channel = option.channel || '其他'
      if (!grouped[channel]) {
        grouped[channel] = {
          label: channel,
          options: [],
        }
      }
      grouped[channel].options.push(option)
    })

    return Object.values(grouped)
  }, [options])

  const children = useMemo(
    () =>
      groupedOptions?.map((group, index) => (
        <AntdSelect.OptGroup key={index} label={group.label}>
          {group.options.map(item => (
            <AntdSelect.Option
              key={item.value}
              className='flex items-center'
              value={item.value}
              label={item.label}
            >
              {item.icon ? (
                <img
                  className='w-16px h-16px object-contain inline-block'
                  src={item.icon}
                />
              ) : undefined}
              <span
                className={classNames(
                  'leading-16px text-font font-medium ml-6 mr-auto',
                  {
                    'text-12px': innerSize === 'small',
                    'text-14px': innerSize === 'middle',
                    'text-16px': innerSize === 'large',
                  },
                )}
              >
                {item.label}
                {item.description ? (
                  <span className='text-font_1 font-normal ml-6'>
                    ({item.description})
                  </span>
                ) : undefined}
              </span>
              <span
                className={classNames('text-font_1', {
                  'text-12px': innerSize === 'small',
                  'text-14px': innerSize === 'middle',
                  'text-16px': innerSize === 'large',
                })}
              >
                {item.tips}
              </span>
            </AntdSelect.Option>
          ))}
        </AntdSelect.OptGroup>
      )),
    [groupedOptions],
  )

  return (
    <Select
      className={classNames(
        '[&>.ant-select-selector]:!h-32px [&_.ant-select-selection-item]:inline-flex [&_.ant-select-selection-item]:items-center w-full nodrag nopan nowheel',
        className,
      )}
      value={value}
      virtual={false}
      popupClassName='nodrag nopan nowheel'
      getPopupContainer={getPopupContainer}
      onChange={onChange}
    >
      {children}
    </Select>
  )
}
