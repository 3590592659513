import { useKeyPress } from 'ahooks'
import { Button, IconFont } from '@/components'
import { useFlowInteractionStore } from '@/store'

export function EditModeTipBar() {
  const { isAddMode, exitAddMode } = useFlowInteractionStore()

  useKeyPress('esc', () => {
    if (isAddMode) {
      exitAddMode()
    }
  })

  return (
    <div
      className='absolute top--60px flex-center w-100vw h-60px b-b-1 b-#e1e1e5 b-op-60'
      style={{
        background: 'linear-gradient(270deg, #EAE6FF 0%, #E6F4FF 100%)',
      }}
    >
      <div className='mr-8px flex-center w-16px h-16px b-rd-50% bg-#7F69FF'>
        <IconFont name='add' className='text-12px text-#fff' />
      </div>
      <div className='mr-8px font-500 text-#17171d'>
        进入添加节点模式，按【esc】退出或
      </div>
      <Button
        type='link'
        className='px-0! hover:text-#7b61ff!'
        onClick={exitAddMode}
      >
        点此退出
      </Button>
    </div>
  )
}
