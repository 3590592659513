import styled from '@emotion/styled'
import { useState } from 'react'
import SuccessPanel from '@/pages/agentGo/components/SuccessPanel.tsx'
import ResearchForm from '@/pages/agentGo/components/ResearchForm.tsx'
import HeaderText from '@/assets/agent-go/header-text.png'
import PicImage from '@/assets/agent-go/agentgopic.png'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

const Container = styled.div`
  background: linear-gradient(
      169deg,
      rgba(0, 216, 216, 0.08) 6%,
      rgba(61, 141, 255, 0.064) 42%,
      rgba(109, 80, 255, 0) 73%
    ),
    #f7f7fa;
`

export default function AgentGo() {
  const [view, setView] = useState<'form' | 'success'>('form')
  const { scrollRef } = useScrollBar()

  return (
    <Container className='w-full h-full overflow-hidden flex flex-col'>
      <div className='font-600 text-20px  adapt:px-64 adapt:pt-40 shrink-0'>
        <div className='b-b b-line b-op-40 pb-24 line-height-36px'>
          Agent Go
        </div>
      </div>
      <div ref={scrollRef} className='w-full h-full pb-80 overflow-auto flex-1'>
        <div className='flex items-center flex-col'>
          <div className='flex items-center flex-col w-1076px'>
            <div className='container-header flex items-center w-full'>
              <div className='flex-1'>
                <div className='flex c-#3D3D3D items-center text-50px font-600'>
                  <span>发布使用</span>
                  <img src={HeaderText} alt='' className='w-268px' />
                </div>
                <div className='text-18px font-400 c-font c-op-40 mt-13'>
                  只需要两步告诉我们您的信息，其他的交给我们
                </div>
              </div>
              <div className='h-200px w-470px mr-40'>
                <img src={PicImage} alt='' className='w-full h-219px' />
              </div>
            </div>
            <div className='container-body w-full'>
              {view === 'form' && (
                <ResearchForm onSubmitFinish={() => setView('success')} />
              )}
              {view === 'success' && (
                <SuccessPanel onBack={() => setView('form')} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
