import { useRef } from 'react'
import { intersection, difference, uniq } from 'lodash-es'

export function useFileProcessWatcher() {
  const filesRef = useRef<number[]>([])

  const add = async (_files?: number[]) => {
    if (_files) {
      filesRef.current = uniq(filesRef.current.concat(_files))
    }
  }

  const process = async (
    _files: number[],
    callback: (needProcessFileIds: number[]) => any | Promise<any>,
  ) => {
    const intersectionArr = intersection(filesRef.current, _files)
    await callback(intersectionArr)
    filesRef.current = difference(filesRef.current, _files)
  }
  const clean = () => {
    filesRef.current = []
  }
  return {
    add,
    process,
    clean,
  }
}
