import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useRequest } from 'ahooks'
import type { PublishModeValue } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'
import AgentGoSDK from '@/features/agent/AgentGo/AgentGoSDK'
import { WorkSpaceKeysProvider } from '@/features/agent/provider/WorkspaceKeysProvieder.tsx'
import AgentGoDingTalk from '@/features/agent/AgentGo/AgentGoDingTalk'
import { getBindAuthAppList } from '@/apis/agentgo.ts'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import AgentGoWeChatOpenApp from '@/features/agent/AgentGo/AgentGoWeChatOpenApp.tsx'
import AgentGoEnterpriseWeChat from '@/features/agent/AgentGo/AgentGoEnterpriseWeChat.tsx'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { useVersion } from '@/hooks/useVersion'
import { AgentGoPublishMode } from './AgentGoPublishMode'
import AgentGoWeChat from './AgentGoWeChat'
import APIAccess from './AgentGoAPI'
import AgentGoChat from './AgentGoChat'
import AgentGoWebLink from './AgentGoWebLink'
import AgentGoFlyBook from './AgentGoFlyBook'
import { PUBLISH_LIST } from './common'
import AgentGoDingTalkWeb from './AgentGoDingTalkWeb'

export function AgentGo({ className }: { className?: string }) {
  const [publishMode, setPublishMode] = useState<PublishModeValue>(
    PUBLISH_MODE.link,
  )

  const publishListItem = PUBLISH_LIST.flatMap(el => el.items).find(
    el => el.key === publishMode,
  )

  const { isTeam } = useVersion()

  const { applicationInfo } = useAgentEdit()
  const { scrollRef } = useScrollBar()

  const { data: bindAuthApps = [], runAsync: getBindAuthAppListApi } =
    useRequest(getBindAuthAppList, {
      manual: true,
    })
  const getBindAuthApp = () => {
    if (applicationInfo) {
      getBindAuthAppListApi(applicationInfo.flowId)
    }
  }
  useEffect(() => {
    getBindAuthApp()
  }, [applicationInfo?.flowId])

  let rightContent
  switch (publishMode) {
    case PUBLISH_MODE.chat:
      rightContent = <AgentGoChat />
      break
    case PUBLISH_MODE.link:
      rightContent = <AgentGoWebLink />
      break
    case PUBLISH_MODE.api:
      rightContent = <APIAccess />
      break
    case PUBLISH_MODE.sdk:
      rightContent = <AgentGoSDK />
      break
    case PUBLISH_MODE.wechat_kf:
      rightContent = (
        <AgentGoWeChat
          onBindSuccess={getBindAuthApp}
          onUnBindSuccess={getBindAuthApp}
          mode={publishListItem?.label}
          modeIcon={publishListItem?.image}
        />
      )
      break
    case PUBLISH_MODE.dingtalk:
      rightContent = (
        <AgentGoDingTalk
          onBindSuccess={getBindAuthApp}
          onUnBindSuccess={getBindAuthApp}
          mode={publishListItem?.label ?? ''}
          modeIcon={publishListItem?.image ?? ''}
        />
      )
      break
    case PUBLISH_MODE.dingtalk_web:
      rightContent = (
        <AgentGoDingTalkWeb
          onBindSuccess={getBindAuthApp}
          onUnBindSuccess={getBindAuthApp}
          mode={publishListItem?.label ?? ''}
          modeIcon={publishListItem?.image ?? ''}
        />
      )
      break
    case PUBLISH_MODE.wechat_open_app:
      rightContent = (
        <AgentGoWeChatOpenApp
          onBindSuccess={getBindAuthApp}
          onUnBindSuccess={getBindAuthApp}
          mode={publishListItem?.label ?? ''}
          modeIcon={publishListItem?.image ?? ''}
        />
      )
      break
    case PUBLISH_MODE.wechat_enterprise:
      rightContent = (
        <AgentGoEnterpriseWeChat
          onBindSuccess={getBindAuthApp}
          onUnBindSuccess={getBindAuthApp}
          mode={publishListItem?.label ?? ''}
          modeIcon={publishListItem?.image ?? ''}
        />
      )
      break
    case PUBLISH_MODE.feishu:
      rightContent = (
        <AgentGoFlyBook
          onBindSuccess={getBindAuthApp}
          onUnBindSuccess={getBindAuthApp}
          mode={publishListItem?.label ?? ''}
          modeIcon={publishListItem?.image ?? ''}
        />
      )
      break
    default:
      break
  }

  return (
    <WorkSpaceKeysProvider>
      <div
        className={classNames('flex flex-1 w-full overflow-hidden', className)}
      >
        <div className='w-1/4 min-w-360px max-w-480px bg-white shrink-0 h-full'>
          <ul ref={scrollRef} className='overflow-y-scroll h-full p-24px'>
            {PUBLISH_LIST.map((el, index) => (
              <li key={index.toString()} className='mt-40px first:mt-0'>
                <ul>
                  <li className='text-16px font-semibold text-font'>
                    {el.group}
                  </li>
                  {el.items.map(item => (
                    <AgentGoPublishMode
                      needUpgradeToEnterprise={
                        item.needUpgradeToEnterprise && isTeam
                      }
                      key={item.key}
                      name={item.label}
                      image={item.image}
                      description={item.description}
                      isBind={
                        bindAuthApps.findIndex(
                          ({ auth_app_source }) =>
                            auth_app_source === item.key ||
                            item.key.includes(auth_app_source),
                        ) !== -1
                      }
                      selected={publishMode === item.key}
                      onClick={() => setPublishMode(item.key)}
                    />
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <div className='flex-1 bg-bg w-3/4 max-w-[calc(100%-360px)] min-w-[calc(100%-480px)]'>
          {rightContent}
        </div>
      </div>
    </WorkSpaceKeysProvider>
  )
}
