import styled from '@emotion/styled'
import { Collapse } from 'antd'
import { TextArea } from '@/components'

export const CollapseWrapper = styled(Collapse)`
  & {
    .ant-collapse-item {
      .ant-collapse-header {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 8px;

        .ant-collapse-expand-icon {
          width: 16px;
          height: 16px;
          display: flex;
          padding: 0px;
          align-items: center;
          justify-content: center;
          margin-right: 4px;
        }

        .ant-collapse-header-text {
          font-size: 12px;
          color: #8d8d99;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0 12px 12px;
        }
      }
    }
  }
`

export const TextAreaWrapper = styled(TextArea)`
  & {
    textarea.ant-input {
      padding: 0;
      min-height: 22px;
    }

    .ant-input-suffix {
      position: unset;
      align-items: end;

      .ant-input-data-count {
        position: unset;
        line-height: 22px;
      }
    }
  }
`
