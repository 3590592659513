import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import { Global, css } from '@emotion/react'
import { IconFont, Progress } from '@/components'

interface messageType {
  duration: number
  title: ReactNode
  content: ReactNode
  onClose?: () => void
}

function GLobalSelectStyle() {
  return (
    <Global
      styles={css`
        .warningMessage {
          padding: 0 !important;
          .ant-progress-bg {
            transition: none !important;
          }
        }
        .ant-message .ant-message-notice .ant-message-notice-content {
          padding: 0;
          overflow: hidden;
        }
      `}
    />
  )
}

function WarningMessage({ duration, title, content, onClose }: messageType) {
  const [percent, setPercent] = useState(10000)
  const interval = useRef<any>()
  const setTime = () =>
    (interval.current = setInterval(() => setPercent(percent - 30), 30))
  useEffect(() => {
    if ((percent / (duration * 1000)) * 100 <= 0) {
      clearInterval(interval.current)
      message.destroy()
      return
    }
    setTime()
    return () => {
      clearInterval(interval.current)
    }
  }, [percent])
  useEffect(() => {
    return () => onClose?.()
  }, [])
  return (
    <div
      onMouseEnter={() => clearInterval(interval.current)}
      onMouseLeave={setTime}
    >
      <GLobalSelectStyle />
      <div className='flex p-24px pb-0px w-640px justify-between mb-36px'>
        <div className='flex'>
          <IconFont
            name='shuimeng'
            style={{ marginRight: 12, color: '#FE9700', fontSize: 24 }}
          />
          <div className='flex flex-col flex-items-start line-height-24px text-16px font-500'>
            {title}
            <div
              className='mt-12px text-14px font-normal'
              style={{ color: 'rgba(23, 23, 29, 0.85)' }}
            >
              {content}
            </div>
          </div>
        </div>
        <IconFont
          name='guanbi'
          className='text-16px cursor-pointer'
          onClick={() => message.destroy()}
        />
      </div>
      <Progress
        className='mb-0px h-0px'
        style={{ transform: 'translate(0px, 2px)' }}
        showInfo={false}
        percent={(percent / (duration * 1000)) * 100}
        strokeLinecap='butt'
        strokeColor='#FE9700'
      />
    </div>
  )
}

export function useWarningMessage() {
  const open = (props: Omit<messageType, 'permissionWarning'>) => {
    message.destroy()
    message.open({
      className: 'warningMessage',
      content: <WarningMessage {...props} />,
      type: 'warning',
      icon: <></>,
      duration: 0,
    })
  }
  return { open }
}
