import { useCallback, useState, useRef, useMemo } from 'react'
import styled from '@emotion/styled'
import { Select as AntdSelect, Divider, Spin } from 'antd'
import classNames from 'classnames'
import { useRequest } from 'ahooks'
import { useWorkspaceStore } from '@/store'
import { RoleCode } from '@/apis/authority.ts'
import { Button, Modal, IconFont, Select } from '@/components'
import { getVersionInfoByWorkspaceId } from '@/apis/version'
import { VersionStatus } from '@/features/pay'
import { useModal } from '@/hooks/useModal.tsx'
import RocketImage2 from '@/assets/app/rocket2.png'
import ShowConnectContent from './ShowConnectContent'

interface WorkSpaceSelectProps {
  onConfirm: (workspaceId: string, selectedConnect?: any) => void
  defaultWorkspaceId?: string
  showConnectComponent?: boolean
  checkLimit?: 'app'
  appId?: string
  partition_id?: number | string
  loading?: boolean
}

export enum SelectType {
  DATASTORE = 'datastore',
  DATABASE = 'database',
  FLOW = 'flow',
  AGENT = 'agent',
}
const ContainSelect = styled.div`
  .ant-select-selection-item {
    .currentTag {
      visibility: hidden;
    }
  }
`

function WorkSpaceSelect(props: WorkSpaceSelectProps) {
  const workspaceList = useWorkspaceStore(state => state.workspaceList)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const ref = useRef<HTMLDivElement>(null)
  const [selectedConnect, setSelectConnect] = useState(true)
  const workspaceOptions = workspaceList
    .filter(item => item.roleCode !== RoleCode.VIEWER)
    .map(k => {
      return {
        label: k.name,
        value: k.id,
      }
    })
  const [workspaceId, setWorkspaceId] = useState(
    props.defaultWorkspaceId || workspaceOptions[0]?.value,
  )
  const [focus, setFocus] = useState(false)

  const { data: targetWorkspaceVersionInfo, loading: getVersionInfoLoading } =
    useRequest(() => getVersionInfoByWorkspaceId(workspaceId), {
      refreshDeps: [workspaceId],
    })

  const { showLimitedInfo = false, limitedInfo } = useMemo(() => {
    /**
     * 所有选择后的目标空间都会判断是否过期，因为目前所有使用该组件的场景都是复制应用｜知识库｜数据库
     * ⚠️：如果后期有其他的场景，而不需要判断空间过期的，可以自定义 checkLimit 参数进行判断
     */
    if (
      targetWorkspaceVersionInfo &&
      targetWorkspaceVersionInfo?.status === VersionStatus.LoseEffect
    ) {
      return {
        showLimitedInfo: true,
        limitedInfo: ` 「
            ${workspaceOptions?.find(item => item.value === workspaceId)?.label}
            」应用空间已过期，无法复制`,
      }
    }
    if (
      props?.checkLimit === 'app' &&
      targetWorkspaceVersionInfo &&
      targetWorkspaceVersionInfo?.app_consume_num >=
        targetWorkspaceVersionInfo?.app_count_limit
    ) {
      return {
        showLimitedInfo: true,
        limitedInfo: ` 「
            ${workspaceOptions?.find(item => item.value === workspaceId)?.label}
            」应用空间已满，无法复制`,
      }
    }
    return {
      showLimitedInfo: false,
      limitedInfo: null,
    }
  }, [
    targetWorkspaceVersionInfo,
    props.checkLimit,
    workspaceOptions,
    workspaceId,
  ])

  function handleSelect(value: string) {
    setWorkspaceId(value)
  }

  const toCreateWorkspace = () => {
    window.open('/login?type=workspace')
  }
  const notFoundContent = (
    <div className='flex flex-col items-center py-24px'>
      <img src={RocketImage2} alt='empty' className='w-100px h-100px' />
      <span className='text-12px/12px text-font_2'>暂无内容</span>
    </div>
  )

  return (
    <div className='px-16px py-8px relative'>
      <Spin spinning={getVersionInfoLoading}>
        <h3 className='text-14px/14px font-500'>选择工作空间</h3>
        <ContainSelect className='mt-12px' ref={ref}>
          <Select
            className='w-full'
            placeholder='请选择'
            showSearch
            notFoundContent={notFoundContent}
            getPopupContainer={() => ref.current!}
            suffixIcon={
              focus ? (
                <IconFont
                  name='search'
                  className='text-20px/20px text-font_1 pt-3px'
                />
              ) : (
                <IconFont
                  name='arrow'
                  className='text-12px text-font_1 pt-3px pr-3px'
                />
              )
            }
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            dropdownRender={menu => {
              return (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '0 0 3px 0',
                    }}
                  />
                  <div
                    className='flex-center py-12px cursor-pointer hover:bg-#62699914 rounded-4px'
                    onClick={toCreateWorkspace}
                  >
                    <IconFont
                      name='tianjiahuihua'
                      className='c-primary text-14px'
                    />
                    <span className='c-primary text-14px pl-8px'>创建空间</span>
                  </div>
                </>
              )
            }}
            value={workspaceId}
            onSelect={handleSelect}
            optionFilterProp='children'
            filterOption={(input, option) => {
              const optionContent = option?.tag
              return (
                optionContent?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              )
            }}
          >
            {workspaceOptions.map(item => (
              <AntdSelect.Option
                value={item.value}
                key={item.value}
                tag={item.label}
              >
                <div className='flex items-center'>
                  {item.label}
                  {item.value === currentWorkspaceId && (
                    <div
                      className={classNames(
                        'border-1 ml-8px w-48px h-16px rounded-4px text-10px color-#FE9700 flex-center',
                        { currentTag: focus },
                      )}
                      style={{ borderColor: 'rgba(254, 151, 0, 0.48)' }}
                    >
                      当前空间
                    </div>
                  )}
                </div>
              </AntdSelect.Option>
            ))}
          </Select>
        </ContainSelect>
        {showLimitedInfo && (
          <div className='flex mt-16px color-#FF5219 items-center'>
            <IconFont name='yunxingyibai' className='text-16px' />
            <span className='text-14px/14px ml-4px'>{limitedInfo}</span>
          </div>
        )}
        <div className='flex justify-between items-center mt-20px'>
          <div>
            {props.showConnectComponent && (
              <ShowConnectContent
                selectedConnect={selectedConnect}
                appId={props.appId}
                isDisabled={currentWorkspaceId !== workspaceId}
                setSelectConnect={setSelectConnect}
              />
            )}
          </div>
          <Button
            disabled={showLimitedInfo}
            type='primary'
            className='mt-12px'
            loading={props?.loading}
            onClick={() => {
              if (workspaceId) {
                props?.partition_id
                  ? props.onConfirm(workspaceId, props?.partition_id)
                  : props.onConfirm(workspaceId, selectedConnect)
              }
            }}
          >
            确定复制
          </Button>
        </div>
      </Spin>
    </div>
  )
}

export default WorkSpaceSelect
export function useWorkspaceSelectModal(
  options: {
    copyType?: string
  } & WorkSpaceSelectProps,
) {
  const WorkspaceModal = useCallback(
    (props: any) => {
      return (
        <Modal {...props} footer={null} title={options?.copyType}>
          <WorkSpaceSelect
            onConfirm={options.onConfirm}
            defaultWorkspaceId={options.defaultWorkspaceId}
            showConnectComponent={options.showConnectComponent}
            appId={options.appId}
            partition_id={props?.partition_id}
            loading={options.loading}
            checkLimit={options?.checkLimit}
          />
        </Modal>
      )
    },
    [
      options.onConfirm,
      options.defaultWorkspaceId,
      options.loading,
      options?.partition_id,
    ],
  )
  return useModal(WorkspaceModal, {
    loading: options.loading,
    partition_id: options.partition_id,
  })
}
