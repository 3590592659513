import './index.css'

import { schema } from 'prosemirror-schema-basic'
import { inputNodeSpec } from './input'
import { selectNodeSpec } from './select'

export const peNodes = {
  doc: schema.spec.nodes.get('doc')!,
  paragraph: schema.spec.nodes.get('paragraph')!,
  text: schema.spec.nodes.get('text')!,
  'pe-input': inputNodeSpec,
  'pe-select': selectNodeSpec,
}
