import type { ReactNode } from 'react'
import { memo, useEffect, useState } from 'react'
import { Form, Modal, Popover } from 'antd'
import { useMemoizedFn, useSize } from 'ahooks'
import { hasIn } from 'lodash-es'
import type { AgentVariableConfig } from '@bty/chat-types'
import { Input, Button, IconFont } from '@/components'

import { useAgentDiffStore } from '@/store/agentDiffStore'

interface AgentVariableProps {
  alwaysActive?: boolean
  close: boolean
  varList: AgentVariableConfig[]
  onClose?: () => void
  onConfirm?: (value: Record<string, string>) => void
}

export const AgentVariable = memo((props: AgentVariableProps) => {
  const { alwaysActive, close, varList, onClose, onConfirm } = props

  const variableValue = useAgentDiffStore(s => s.variableValue)

  const [hasError, setHasError] = useState(varList.some(e => e.required))
  const [hasChange, setHasChange] = useState(false)

  const [form] = Form.useForm()

  const handleConfirm = useMemoizedFn(async () => {
    try {
      await form.validateFields()
      onConfirm?.(form.getFieldsValue())
    } catch (err) {}
  })

  const handleChange = useMemoizedFn(async () => {
    setHasChange(true)
    try {
      await form.validateFields()
      setHasError(false)
    } catch (err) {
      setHasError(true)
    }
  })

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(variableValue)
    const hasError = varList
      .filter(e => e.required)
      .some(e => !hasIn(variableValue, e.variable_name))
    setHasError(hasError)
  }, [variableValue])

  return (
    <div>
      <div
        className='h-48px text-16px font-medium px-20px flex items-center'
        style={{
          borderBottom: '1px solid rgba(225, 225, 229, 0.6)',
        }}
      >
        <span>变量输入</span>

        {close && (
          <IconFont
            className='ml-auto text-16px cursor-pointer'
            name='guanbi'
            onClick={onClose}
          />
        )}
      </div>

      <div className='p-20px'>
        <Form
          form={form}
          layout='vertical'
          requiredMark={false}
          onChange={handleChange}
        >
          {varList.map(each => {
            return (
              <Form.Item
                className='mb-20px! [&_.ant-form-item-explain]:mt-4px'
                key={each.variable_name}
                name={each.variable_name}
                label={
                  <span>
                    {each.label}{' '}
                    {each.required && <span className='text-#FF512B'>*</span>}
                  </span>
                }
                rules={[
                  {
                    required: each.required,
                    message: `${each.label} 不能为空`,
                  },
                ]}
              >
                <Input placeholder={each.placeholder} />
              </Form.Item>
            )
          })}
        </Form>

        <div className='flex'>
          <Button
            className='ml-auto'
            type='primary'
            disabled={hasError || (!alwaysActive && !hasChange)}
            onClick={handleConfirm}
          >
            保存，开始会话
          </Button>
        </div>
      </div>
    </div>
  )
})

interface AgentVariableModalProps extends AgentVariableProps {
  open: boolean
  onOpen?: () => void
  getContainer: () => HTMLElement
}

export const AgentVariableModal = memo((props: AgentVariableModalProps) => {
  const { open, getContainer, varList, onConfirm } = props
  const setVariableValue = useAgentDiffStore(s => s.setVariableValue)

  const handleConfirm = useMemoizedFn((newValue: Record<string, string>) => {
    setVariableValue(newValue, true)
    onConfirm?.(newValue)
  })

  const { width } = useSize(document.body) ?? {}

  return (
    <Modal
      wrapClassName='absolute! flex flex-center'
      open={open}
      centered
      footer={null}
      closable={false}
      mousePosition={{ x: width! - 100, y: 79 }}
      getContainer={getContainer}
      styles={{
        content: { padding: 0 },
        mask: {
          position: 'absolute',
          backdropFilter: 'none',
        },
      }}
      destroyOnClose
    >
      <AgentVariable
        alwaysActive
        close={false}
        varList={varList ?? []}
        onConfirm={handleConfirm}
      />
    </Modal>
  )
})

interface AgentVariablePopProps extends AgentVariableProps {
  onOpen?: () => void
  children: ReactNode
}

export const AgentVariablePop = memo((props: AgentVariablePopProps) => {
  const { varList, onOpen, onConfirm, children } = props
  const setVariableValue = useAgentDiffStore(s => s.setVariableValue)
  const [open, setOpen] = useState(false)

  const handleConfirm = useMemoizedFn((newValue: Record<string, string>) => {
    setVariableValue(newValue, true)
    onConfirm?.(newValue)
    setOpen(false)
  })

  const handleOpenChange = useMemoizedFn((newOpen: boolean) => {
    setOpen(newOpen)
    if (newOpen) {
      onOpen?.()
    }
  })

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      open={open}
      onOpenChange={handleOpenChange}
      arrow={false}
      overlayInnerStyle={{ padding: 0, borderRadius: 8, width: 560 }}
      content={
        <AgentVariable
          alwaysActive={false}
          close
          varList={varList ?? []}
          onClose={() => setOpen(false)}
          onConfirm={handleConfirm}
        />
      }
      destroyTooltipOnHide
    >
      {children}
    </Popover>
  )
})
