import {
  Navigate,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import type { ModalType } from '@/features/login'
import { defaultRouterPath, LOGO } from '@/constants/common.ts'
import { checkToken } from '@/apis/user'
import { LoginOrRegister } from '@/features/login/LoginOrRegister'
import { CreateWorkspace } from '@/features/login/CreateWorkspace'
import { Invite } from '@/features/login/Invite'
import { tokenStorage } from '@/utils/storage.ts'

const ANONYMOUS_USER_ID = -1000

type Authenticated = boolean

export async function loader(): Promise<Authenticated> {
  const token = tokenStorage.get()
  if (!token) {
    return false
  }
  try {
    const user = await checkToken(token)
    return user.userId !== ANONYMOUS_USER_ID
  } catch (_) {
    return false
  }
}

function LoginPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const redirect = searchParams.get('redirect')

  const innerRedirect =
    !redirect || redirect === '/' ? defaultRouterPath : redirect

  const type = searchParams.get('type') as ModalType | null
  const channel = searchParams.get('channel')
  const link_id = searchParams.get('link_id')

  const authenticated = useLoaderData() as Authenticated

  if (authenticated && (!type || type === 'login')) {
    return <Navigate to={innerRedirect} />
  }

  if (!authenticated && type === 'login' && channel) {
    return <Navigate to='/login?type=register' />
  }

  return (
    <div className='relative w-100vw h-100vh flex'>
      <img className='absolute top-24px left-32px w-130px' src={LOGO} alt='' />
      <div
        className='hidden ssm:flex ssm:w-1/2 lg:w-1/2 flex-center flex-col'
        style={{
          background:
            'linear-gradient(318deg, #F2E6FF 0%, #EAE4FF 24%, #E3E4FF 53%, #F4FBFF 100%), linear-gradient(139deg, #64E5FF 0%, #3EAFFF 14%, #6B4EFF 49%, #9F32FF 94%)',
        }}
      >
        <div className='flex flex-col mx-54px'>
          {/* <div className='mb-8px font-700 text-36px/48px md:text-48px/60px'>
            AGI For Organization
          </div> */}
          <div className='mb font-700 md:text-36px/48px text-28px/40px'>
            灵活、易用的
          </div>
          <div className='mb-100px font-700 md:text-36px/48px text-28px/40px text-center'>
            企业级AI Agent开发平台
          </div>
        </div>
        {/* <div className='w-80%'>
          <img className='max-w-full h-auto scale-116' src={LoginImg} alt='' />
        </div> */}
      </div>
      <div className='w-full ssm:w-1/2 lg:w-1/2 flex-center bg-#fff'>
        {(!type || type === 'login') && <LoginOrRegister />}
        {type === 'invite' && <Invite link_id={link_id || ''} />}
        {type === 'workspace' && (
          <CreateWorkspace
            inModal={false}
            onSuccess={() => {
              const redirect = searchParams.get('redirect')
              const innerRedirect =
                !redirect || redirect === '/' ? defaultRouterPath : redirect
              navigate(innerRedirect)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default LoginPage
