import { memo } from 'react'
import cn from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { Segmented, Tooltip } from 'antd'
import { IconFont } from '@/components'
import { PromptType } from '@/apis/prompt.ts'

interface EditorTypeSelectorProps {
  value?: PromptType
  onChange?: (value: PromptType) => void
  className?: string
}

export const EditorTypeSelector = memo<EditorTypeSelectorProps>(props => {
  const { onChange, className } = props
  const value = props.value || PromptType.RAW

  const handleSelect = useMemoizedFn((mode: PromptType) => {
    onChange?.(mode)
  })

  return (
    <div className={cn('w-62px', className)}>
      <Segmented
        value={value}
        onChange={handleSelect}
        block
        className='bg-bg_3/8 rounded-8px! [&_.ant-segmented-item-label]:p-0px! [&_.ant-segmented-item]:rounded-6px! [&_.ant-segmented-thumb]:rounded-6px!'
        size='small'
        options={[
          {
            label: (
              <Tooltip title='结构化模式'>
                <span className='flex flex-center h-20px'>
                  <IconFont
                    className='text-16px'
                    name='promptjiegouhuabianji'
                  />
                </span>
              </Tooltip>
            ),
            value: PromptType.STRUCT,
          },
          {
            label: (
              <Tooltip title='文本模式'>
                <span className='flex flex-center h-20px'>
                  <IconFont className='text-16px' name='promptwenbenbianji' />
                </span>
              </Tooltip>
            ),
            value: PromptType.RAW,
          },
        ]}
      />
    </div>
  )
})

EditorTypeSelector.displayName = 'EditorTypeSelector'
