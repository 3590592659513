import type { FormItemProps } from 'antd'
import { Form, Tooltip } from 'antd'
import type { FC, ReactNode } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'
import { IconFont } from '..'

export function RequiredMark({ className }: { className?: string }) {
  return (
    <span
      className={cn(
        'color-error ml-5 text-18px line-height-14px position-relative top-3px',
        className,
      )}
    >
      *
    </span>
  )
}

const StyledFormItem = styled(Form.Item)<{ padding?: number }>`
  .ant-form-item-required {
    width: 100%;
  }
  .ant-form-item-label {
    padding: 0 0 ${({ padding }) => padding}px;
  }
  .ant-form-item-explain {
    margin-top: 0;
  }
  .ant-form-item-explain-error {
    margin-left: 0 !important;
    margin-top: 8px;
    line-height: 16px;
  }
`

export const FormItem: FC<
  FormItemProps & {
    padding?: number
    rightContent?: ReactNode
    children: FormItemProps['children']
  }
> = props => {
  const { rightContent, ...rest } = props

  const label = (
    <div className='keyu-form-item-label flex items-center font-400 w-full'>
      <span>{props.label}</span>
      {props.required && <RequiredMark />}
      {props.tooltip &&
        (typeof props.tooltip === 'string' ||
          React.isValidElement(props.tooltip)) && (
          <Tooltip
            title={props.tooltip as string | React.ReactNode}
            {...(React.isValidElement(props.tooltip)
              ? {
                  arrow: false,
                  color: '#fff',
                  placement: 'right',
                  overlayInnerStyle: { width: 'fit-content' },
                }
              : { placement: 'top' })}
          >
            <IconFont
              className='keyu-form-item-tooltip-icon text-font_1 text-14px text-opacity-40 ml-5 cursor-pointer'
              name='jieshishuimeng'
            />
          </Tooltip>
        )}
      {rightContent && (
        <div className='right-content ml-auto font-normal mr-[-14px]'>
          {rightContent}
        </div>
      )}
    </div>
  )
  return (
    <StyledFormItem
      {...rest}
      padding={props.padding}
      tooltip={
        typeof props.tooltip === 'string' || React.isValidElement(props.tooltip)
          ? undefined
          : props.tooltip
      }
      label={
        props.label && typeof props.label === 'string' ? label : props.label
      }
    >
      {props.children}
    </StyledFormItem>
  )
}
