import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import * as styles from 'react-syntax-highlighter/dist/esm/styles/prism'
import { useMemo } from 'react'

interface MarkdownProps {
  text: string
  onHrefTagClick?: (url: string) => void
}

export function Markdown({ text, onHrefTagClick }: MarkdownProps) {
  return (
    <ReactMarkdown
      className='editor'
      remarkPlugins={[remarkGfm]}
      components={useMemo(() => {
        return {
          code({ inline, className, children, ...props }): JSX.Element {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                {...props}
                style={styles.coldarkCold}
                language={match[1]}
                PreTag='div'
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            )
          },
          a({ href, children }): JSX.Element {
            return (
              <a
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (onHrefTagClick && href) {
                    onHrefTagClick(href)
                  } else {
                    window.open(href)
                  }
                }}
              >
                {children}
              </a>
            )
          },
          td(data): JSX.Element {
            return (
              <td title={data.children?.join('\n')} {...data}>
                {data.children?.map((item, index) =>
                  item === '<br>' ? <br key={index}></br> : item,
                )}
              </td>
            )
          },
          table(data): JSX.Element {
            return (
              <div className='tableBorder'>
                <table {...data} />
              </div>
            )
          },
        }
      }, [text])}
    >
      {text}
    </ReactMarkdown>
  )
}
