import { Outlet, useSearchParams } from 'react-router-dom'
import { LayoutSideBar } from './components/LayoutSideBar'

function HomeLayout() {
  const [searchParams] = useSearchParams()
  const noLayout = searchParams.get('independent') === '1'

  return noLayout ? (
    <Outlet />
  ) : (
    <div
      className='flex h-100vh !bg-#edeef5 overflow-hidden'
      style={{
        background:
          'linear-gradient(-320deg, rgba(170, 85, 255, 0.12) 1%, rgba(76, 105, 255, 0.1) 13%, rgba(104, 192, 255, 0.05) 25%, rgba(168, 252, 255, 0) 44%)',
      }}
    >
      <LayoutSideBar />
      <main
        id='main-container'
        className='flex-1 bg-#f7f7fa my-8px mr-8px b-rd-12px overflow-hidden'
      >
        <Outlet />
      </main>
    </div>
  )
}

export default HomeLayout
