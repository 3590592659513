import { useMemo } from 'react'
import type { AppMonitorType, UsageTrendType } from '@/apis/monitor'
import { queryUsageTrend } from '@/apis/monitor'
import { ChartDashboardPane } from '@/features/agent/AgentAnalysis/ChartDashboardPane'

interface UsageTrendPaneProps {
  flowId: string
  flowType: AppMonitorType
  runType?: string[]
  onLogJump: (date: string) => void
}

export function UsageTrendPane({
  flowId,
  flowType,
  runType,
  onLogJump,
}: UsageTrendPaneProps) {
  const params = useMemo(
    () => ({
      flow_id: flowId,
      flow_type: flowType,
      statistic_time: 'weekly',
      display_time: 'daily',
      run_type: runType,
    }),
    [flowId, flowType, runType],
  )

  return (
    <>
      <ChartDashboardPane<UsageTrendType>
        className='mt-24'
        type='area'
        xField='display_time'
        yField='value'
        title='用量趋势'
        color={['#7B61FF', '#5784FD', '#2FBDF6']}
        service={{
          call: queryUsageTrend,
          params,
        }}
        filter
        showTooltip
        onDetailClick={xFieldValue => {
          if (xFieldValue) {
            onLogJump(xFieldValue)
          }
        }}
      />
    </>
  )
}
