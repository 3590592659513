import { request } from '@/apis/lib'
import { workspaceStorage } from '@/utils/storage.ts'
import type { LLMModalItem, LLMModelLimitedItem } from '@/apis/llm/type.ts'

export function getLLMModelList(generate_type: 'textGenerate') {
  return request.get<LLMModalItem[]>('/v1/llm/active_channels', {
    params: {
      generate_type,
    },
  })
}

export function getLLMModelListLimited() {
  const workspaceId = workspaceStorage.get()
  return request.get<LLMModelLimitedItem[]>(
    `/v1/workspace/model_limited?workspace_id=${workspaceId}`,
  )
}

export function getAsyncSupportLLMModelListLimited() {
  const workspaceId = workspaceStorage.get()
  return request.get<LLMModelLimitedItem[]>(
    `/v1/async_batch/workspace/model_limited?workspace_id=${workspaceId}`,
  )
}

export function getLLMModelContextExample() {
  return request.get<Record<string, any>>('/v1/flow/llm/context_example')
}
