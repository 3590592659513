import dayjs from 'dayjs'
import { StaticTimeTypes } from '@/apis/monitor'
import type { StabilityTimeParams } from '../UsageStatistics/components/StabilityDetails'

export function useTimeRangeParams({
  timeParams,
  filterParams,
}: {
  timeParams: StabilityTimeParams | undefined
  filterParams: string | undefined
}) {
  let start_time, end_time

  switch (filterParams) {
    case StaticTimeTypes.WEEKLY: {
      start_time = dayjs().startOf('week').format('YYYY-MM-DD 00:00:00')
      end_time = dayjs().endOf('week').format('YYYY-MM-DD 23:59:59')
      break
    }
    case StaticTimeTypes.MONTHLY: {
      start_time = dayjs().startOf('month').format('YYYY-MM-DD 00:00:00')
      end_time = dayjs().endOf('month').format('YYYY-MM-DD 23:59:59')
      break
    }
    case StaticTimeTypes.QUARTERLY: {
      const currentMonth = dayjs().month()
      const currentQuarter = Math.floor(currentMonth / 3)
      start_time = dayjs()
        .month(currentQuarter * 3)
        .startOf('month')
        .format('YYYY-MM-DD 00:00:00')
      end_time = dayjs()
        .month(currentQuarter * 3 + 2)
        .endOf('month')
        .format('YYYY-MM-DD 23:59:59')
      break
    }
    case StaticTimeTypes.YEARLY: {
      start_time = dayjs().startOf('year').format('YYYY-MM-DD 00:00:00')
      end_time = dayjs().endOf('year').format('YYYY-MM-DD 23:59:59')
      break
    }
    default: {
      start_time = timeParams?.start_date
      end_time = timeParams?.end_date
    }
  }

  return {
    start_time,
    end_time,
  }
}
