import { useCallback, useEffect, useMemo, useRef } from 'react'
import type { FormInstance } from 'antd'
import type { JsonFormConfig } from '../components'
import { JsonForm } from '../components'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { useNodeBranchRemoveConfirm } from '../hooks/useNodeBranchRemoveConfirm'
import { BranchTypes, ConditionForm, type ConditionItem } from './ConditionForm'

export interface ConditionNodeData {
  name: string
  conditions: ConditionItem[]
  relation: {
    conditionResultId: string
    branchId?: string
    [o: string]: any
  }
  [k: string]: any
}

export const ConditionNode: NodeComponent<ConditionNodeData> = props => {
  const {
    data: { conditions },
  } = props

  const formRef = useRef<FormInstance>()
  const addBranch = useFlowDraftStore(s => s.addBranch)
  const removeBranch = useFlowDraftStore(s => s.removeBranch)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const { beforeRemove, cancel } = useNodeBranchRemoveConfirm(props.id)

  const onAdd = useCallback(() => {
    const insertIndex = conditions.findIndex(
      item => item.type === BranchTypes.ELSE,
    )
    if (insertIndex > -1) {
      addBranch({
        id: props.id,
        conditionIndex: insertIndex,
        condition: {
          type: BranchTypes.ELSEIF,
          statement: '',
        },
      })
    }
  }, [conditions, props.id])

  const onDelete = useCallback(
    (id: string) => {
      removeBranch({
        id: props.id,
        conditionId: id,
      })
    },
    [props.id],
  )

  const List = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        name: 'conditions',
        rules: [
          {
            required: true,
            validator: (_, value: ConditionItem[]) => {
              const hasInvalidStatement = value
                .filter(v => v.type !== BranchTypes.ELSE)
                .some(item => item.statement === '')
              if (hasInvalidStatement) {
                return Promise.reject(new Error('请检查条件分支，不能为空'))
              }
              return Promise.resolve()
            },
          },
        ],
        render: () => {
          return (
            <ConditionForm
              variables={props.variables}
              variableTipsContainer={props.nodeElement}
              onAdd={onAdd}
              onDelete={onDelete}
              onBeforeRemove={beforeRemove}
              onCancel={cancel}
              disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
            />
          )
        },
      },
    ]
  }, [props.nodeElement, props.variables, onAdd, onDelete, lockStatus])

  useEffect(() => {
    if (formRef.current) {
      const fieldValue = formRef.current.getFieldValue('conditions')

      // 当删除或增减的时候立即更新表单值
      if (fieldValue?.length !== props.data.conditions.length) {
        formRef.current.setFieldValue('conditions', props.data.conditions)
      }
    }
  }, [props.data.conditions, formRef.current])

  return useMemo(
    () => (
      <div className='w-420px p-16'>
        <JsonForm
          ref={formRef}
          list={List}
          disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
        />
      </div>
    ),
    [formRef, List, lockStatus],
  )
}

ConditionNode.meta = {
  type: NodeType.CONDITION,
  operationType: NodeOperationTypes.MULTI_BRANCH_NODE,
  actionType: 'CONDITION',
  typeName: '逻辑分支',
  icon: 'condition',
  description: '条件分支',
  backgroundColor: '#6f59df',
  customSourceHandle: true,
  initialData: {
    name: 'condition_1',
    relation: {
      conditionResultId: '',
    },
    conditions: [
      {
        type: BranchTypes.IF,
        statement: '',
      },
      {
        type: BranchTypes.ELSE,
        statement: '',
      },
    ],
  },
  classNames: ['overflow-visible'],
}
