// components/Uploader.tsx
import { Upload, message, Tooltip } from 'antd'
import type { UploadProps } from 'antd'
import type OSS from 'ali-oss'
import {
  VIDEO_EXTENSIONS,
  IMAGE_EXTENSIONS,
} from '@bty/chat-ui/src/components/message/type/Markdown/MarkdownAnchor'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { IconFont } from '@/components'
import { useOSSClient } from '@/hooks/useOSSClient'
import { checkFileSize } from '@/features/datastore/utils'

const VIDEO_MAX_SIZE = 50
const IMAGE_MAX_SIZE = 20

function _getSupportMimetype(arr: string[]) {
  const formattedExtensions = arr.map(ext => `.${ext}`)
  const extensionsString = formattedExtensions.join(',')
  return extensionsString
}

export const SupportFileType = {
  video: VIDEO_EXTENSIONS.map(item => `.${item}`),
  image: IMAGE_EXTENSIONS.map(item => `.${item}`),
}

export interface DatastoreMediaUploadProps {
  file_id: number
  className?: string
  uploadOptions: {
    uploadDir: string
    onSuccess: (options: { url: string; file: File }) => void
    onFail?: (error: any) => void
    onProcess?: (options: { file: File; percent: number }) => void
    onStart?: (options: any) => void
  }
}

export interface DatastoreMediaUploadRef {
  triggerUpload: () => void
}

const DatastoreMediaUpload = forwardRef<
  DatastoreMediaUploadRef,
  DatastoreMediaUploadProps
>(({ className, uploadOptions, file_id }, ref) => {
  const { ossClient } = useOSSClient({ business: 'dataset' })
  const uploadRef = useRef<any>()

  const onFileUpload = async (
    file: File,
    options?: OSS.MultipartUploadOptions,
  ) => {
    if (ossClient) {
      const filePath = `i/${uploadOptions?.uploadDir}/${file_id}/${+new Date()}/${file.name}`

      ossClient.useBucket(import.meta.env.VITE_AI_DATASTORE_RESOURCE_BUCKET)
      const res = await ossClient.multipartUpload(filePath, file, options || {})
      const url = (res.res as any)?.requestUrls?.[0]?.split('?')?.[0]
      return url
    }
    throw new Error('oss client is not init')
  }

  const handleRequest = async (options: any) => {
    const { file } = options
    try {
      uploadOptions?.onStart?.(options)
      const url = await onFileUpload(file, {
        progress: percent => {
          const p = Math.floor(percent * 100)
          uploadOptions?.onProcess?.({ file, percent: p })
        },
      })
      uploadOptions?.onSuccess?.({ url, file })
    } catch (error) {
      uploadOptions?.onFail?.(error)
    }
  }

  const beforeUpload = (file: File) => {
    // 定义视频文件扩展名列表
    // 获取文件扩展名
    const extension = `${file?.name?.split?.('.')?.pop()?.toLowerCase()}`
    // 根据文件类型设置大小限制
    const maxSize = VIDEO_EXTENSIONS.includes(extension)
      ? VIDEO_MAX_SIZE
      : IMAGE_MAX_SIZE // 单位MB

    if (!checkFileSize(file.size, maxSize)) {
      message.warning(`文件大小不能超过${maxSize}MB, 请修改后重新上传`)
      return Upload.LIST_IGNORE
    }
    return true
  }

  const uploadProps: UploadProps = {
    customRequest: handleRequest,
    beforeUpload,
    showUploadList: false,
    accept: `${_getSupportMimetype(IMAGE_EXTENSIONS)},${_getSupportMimetype(VIDEO_EXTENSIONS)}`,
    maxCount: 1,
  }

  const triggerUpload = () => {
    uploadRef?.current?.upload?.uploader?.fileInput?.click?.()
  }

  useImperativeHandle(
    ref,
    () => ({
      triggerUpload,
    }),
    [triggerUpload, ref],
  )

  return (
    <Tooltip title='上传图片/视频'>
      <Upload ref={uploadRef} {...uploadProps} className={className}>
        <IconFont name='shangzhuantupian' className='c-#B9B9CD' />
      </Upload>
    </Tooltip>
  )
})

export default DatastoreMediaUpload
