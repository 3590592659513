import { memo, useEffect, useRef, useState } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { getTaskList, type TaskListResult } from '@/apis/task'
import { TaskList } from './TaskList'
import { TaskLog } from './TaskLog'
import { TaskDetail } from './TaskDetail'
import { TaskProvider } from './TaskContext'

interface TaskInfoProps {
  agentId: string
  versionId: string
  showTaskId?: string
  taskRecordId?: string
  getConversationId: () => string
  onBack?: () => void
  onClose: () => void
}

export const TaskInfo = memo((props: TaskInfoProps) => {
  const {
    agentId,
    versionId,
    showTaskId,
    taskRecordId,
    getConversationId,
    onBack,
    onClose,
  } = props

  const {
    data: taskList,
    run: refreshList,
    runAsync: getList,
    loading: taskListLoading,
    mutate,
  } = useRequest(() => getTaskList(agentId, getConversationId()))

  const historyList = useRef<string[]>([])
  const [tab, setTab] = useState(showTaskId ? 'log' : 'list')
  const [showTask, setShowTask] = useState<TaskListResult>()
  const [isDelete, setIsDelete] = useState(false)

  const handleBack = useMemoizedFn(() => {
    onBack?.()
    setTab(historyList.current.pop() ?? 'list')
  })

  const handleToEdit = useMemoizedFn((task?: TaskListResult) => {
    setTab('edit')
    historyList.current.push(tab)
    setShowTask(task)
  })

  const handleToLog = useMemoizedFn((task: TaskListResult, saveNow = true) => {
    setTab('log')
    if (saveNow) {
      historyList.current.push(tab)
    }
    setShowTask(task)
  })

  const handleUpdateList = useMemoizedFn(
    (refresh?: boolean, task?: TaskListResult) => {
      if (refresh) {
        refreshList()
        return
      }

      if (task?.task_id === showTask?.task_id) {
        setShowTask(task)
      }

      if (task) {
        mutate(prev => {
          if (!prev) return []
          return prev.map(item => {
            if (item.task_id === task.task_id) {
              return task
            }
            return item
          })
        })
      }
    },
  )

  const handleInitTaskId = useMemoizedFn(async () => {
    let list = taskList
    if (!list?.length) {
      list = await getList()
    }

    setTab('log')
    const _showTask = list?.find(item => item.task_id === showTaskId)
    setShowTask(_showTask)
    if (!_showTask) {
      setIsDelete(true)
    }
  })

  useEffect(() => {
    if (!showTaskId) {
      setTab('list')
      return
    }
    handleInitTaskId()
  }, [showTaskId])

  useEffect(() => {
    const handle = () => {
      handleUpdateList(true)
    }

    window.addEventListener('chatMessageUpdate', handle)
    return () => {
      window.removeEventListener('chatMessageUpdate', handle)
    }
  }, [])

  return (
    <div className='h-full w-full min-w-480px'>
      <TaskList
        list={taskList ?? []}
        agentId={agentId}
        getConversationId={getConversationId}
        toEdit={handleToEdit}
        toLog={handleToLog}
        loading={taskListLoading}
        onClose={onClose}
        onUpdateList={handleUpdateList}
        className={tab !== 'list' ? 'hidden' : ''}
      />
      {tab === 'log' && (
        <TaskLog
          agentId={agentId}
          isDelete={isDelete}
          taskRecordId={taskRecordId}
          taskId={showTask?.task_id!}
          getConversationId={getConversationId}
          onUpdateList={handleUpdateList}
          toEdit={handleToEdit}
          onBack={handleBack}
          onClose={onClose}
        />
      )}
      {tab === 'edit' && (
        <TaskProvider>
          <TaskDetail
            agentId={agentId}
            versionId={versionId}
            task={showTask}
            getConversationId={getConversationId}
            onUpdateList={handleUpdateList}
            onUpdate={task => handleToLog(task, false)}
            onBack={handleBack}
            onClose={onClose}
          />
        </TaskProvider>
      )}
    </div>
  )
})
