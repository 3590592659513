import { InputNumber } from 'antd'
import { useRef } from 'react'
import styled from '@emotion/styled'
import { useMemoizedFn } from 'ahooks'
import { IconFont } from '@/components'
import { ModelSetting } from '../../components/ModelSetting'
import type { ModalSettingValue } from '../../components/type'

const StyledInputNumber = styled(InputNumber, {
  shouldForwardProp: prop => prop !== 'nodeElement',
})`
  padding: 0 8px;
  border-color: transparent;
  background-color: rgba(98, 105, 153, 0.06);
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 14px;

  &.ant-input-number-focused {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  & .ant-input-number-input {
    text-align: center;
    height: 28px;
    line-height: 28px;
    padding: 0;
    font-size: 12px;
  }
`

interface MiniSettingProps {
  value: ModalSettingValue
  onChange: (value: ModalSettingValue) => void
}

export function MiniSetting(props: MiniSettingProps) {
  const { value, onChange } = props

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleTemperatureChange = useMemoizedFn((newNumber: number) => {
    onChange({ ...value, temperature: newNumber })
  })

  return (
    <ModelSetting
      title='参数调整'
      width={500}
      value={value}
      onChange={onChange}
      onlyModelConfig
      getPopupContainer={() => wrapperRef.current!}
    >
      <div ref={wrapperRef}>
        <StyledInputNumber
          className='w-full'
          value={value.temperature}
          onChange={handleTemperatureChange as any}
          changeOnWheel={false}
          prefix={
            <IconFont
              name='chuangcaoxing'
              className='text-16px text-#626999 text-op-60'
            />
          }
          controls={false}
        />
      </div>
    </ModelSetting>
  )
}
