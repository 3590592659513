import { IconFont } from '@/components'
import { TextEditor } from '@/features/editor/TextEditor'
import type { Variable } from '@/features/editor'

interface BatchDataVariableProps {
  value?: string
  onChange?: (val: any) => void
  variables: Variable[]
  disabled?: boolean
  variableTipsContainer?: HTMLElement | null
}

export function BatchDataVariable(props: BatchDataVariableProps) {
  const { value, variables, variableTipsContainer, onChange, disabled } = props

  return (
    <>
      <TextEditor
        className='text-12px'
        placeholder='填写或选择需要处理的数据变量'
        rows={1}
        variables={variables}
        variableTipsContainer={variableTipsContainer}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <div className='flex flex-items-center mt-8px h-32px px-12px bg-#38a6ff bg-op-8 b-rd-6px'>
        <IconFont className='mr-8px text-#38a6ff' name='info' />
        <span className='text-12px text-#17171d'>
          数据将自动分段，并按提示词的描述对数据进行批量处理
        </span>
      </div>
    </>
  )
}
