import type { MouseEvent } from 'react'
import type { Edge } from 'reactflow'
import { useNodeDataInsert } from '@/hooks/useNodeDataInsert'
import { useFlowInteractionStore } from '@/store'

export function useEdgeClick() {
  const { isAddMode } = useFlowInteractionStore()
  const { insert } = useNodeDataInsert()

  const onEdgeClick = (_e: MouseEvent, edge: Edge) => {
    if (isAddMode) {
      const { source, target, sourceHandle } = edge
      insert({
        source,
        target,
        sourceHandleId: sourceHandle,
      })
    }
  }

  return {
    onEdgeClick,
  }
}
