import { Form, Popover } from 'antd'
import { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { sample } from 'lodash-es'
import type { Rule } from 'antd/es/form'
import {
  Input,
  TextArea,
  Modal,
  IconFont,
  FormItem,
  AppLogo,
  Button,
} from '@/components'
import type { AppLogoProps } from '@/components'

import { ColorSelect } from '@/components/createAppModal/ColorSelect.tsx'
import { colors } from '@/constants/theme.ts'
import { IconSelect } from '@/components/createAppModal/IconSelect.tsx'
import { iconList, fontIcons, iconColors } from '@/constants/icon.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { AvatarSelect } from '@/components/createAppModal/ImageSelect.tsx'

type FieldKeys = 'name' | 'description' | 'icon'

type FieldConfig = {
  [key in FieldKeys]?: {
    placeholder?: string
    className?: string
    rules?: Rule[]
  }
}

interface AppBaseInfo {
  name?: string
  description?: string
  icon?: string
  color?: string
}

interface CreateModalProps {
  open?: boolean
  onCancel?: () => void
  title: string
  fieldConfig?: FieldConfig
  logoType: AppLogoProps['type']
  defaultValue?: AppBaseInfo
  iconColors?: string[]
  onFinish?: (data: Required<AppBaseInfo>) => void
}

const StyledFormItem = styled(FormItem)`
  .keyu-form-item-label {
    font-weight: 500;
  }
`

export function CreateModal(props: CreateModalProps) {
  const {
    open,
    title,
    logoType,
    fieldConfig,
    onCancel,
    iconColors: _iconColors,
  } = props
  const [logoPopoverOpen, setLogoPopoverOpen] = useState(false)
  const [form] = Form.useForm()

  const resultIcons = useMemo(() => {
    if (logoType === 'icon') return fontIcons
    if (logoType === 'emoji') return iconList
    return []
  }, [logoType])

  const resultColors = useMemo(() => {
    if (logoType === 'icon') return colors
    if (logoType === 'emoji') return _iconColors || iconColors
    return []
  }, [logoType])

  const defaultValue = useMemo(() => {
    return Object.assign(
      {},
      {
        color: sample(resultColors),
        icon: sample(resultIcons),
      },
      props.defaultValue,
    )
  }, [resultIcons, resultColors])

  const onFinish = () => {
    form.validateFields().then(res => {
      // 不知道怎么了，初始化antd没有把initValues的值获取到，暂时手动合并一下
      const data = Object.assign({}, defaultValue, res)
      props.onFinish?.(data)
    })
  }

  const { scrollRef } = useScrollBar()

  const logoEditContent = (
    <div
      ref={scrollRef}
      className='overflow-y-auto overflow-x-hidden max-h-221px'
      style={{ padding: logoType === 'image' ? 24 : 12 }}
    >
      <FormItem noStyle hidden={logoType === 'image'}>
        <div className='mb-10'>
          <p className='font-500 lh-20px mb-5'>背景</p>
          <FormItem name='color' noStyle>
            <ColorSelect colors={resultColors} />
          </FormItem>
        </div>
        <div>
          <p className='font-500 lh-20px mb-5'>图标</p>
          <FormItem name='icon' noStyle>
            <IconSelect iconType={logoType} icons={resultIcons} />
          </FormItem>
        </div>
      </FormItem>
      <FormItem name='icon' hidden={logoType !== 'image'} noStyle>
        <AvatarSelect />
      </FormItem>
    </div>
  )

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      width={600}
      footer={null}
      closeIcon={<IconFont name='guanbi' className='color-#17171D text-16px' />}
    >
      <Form
        form={form}
        initialValues={defaultValue}
        layout='vertical'
        requiredMark={false}
        className='mt-2 px-16'
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) =>
            prev.icon !== curr.icon || prev.color !== curr.color
          }
        >
          {({ getFieldValue }) => {
            const icon = getFieldValue('icon')
            const color = getFieldValue('color')
            return (
              <div className='flex w-full mb-24 justify-center'>
                <Popover
                  trigger='click'
                  placement='bottom'
                  onOpenChange={setLogoPopoverOpen}
                  open={logoPopoverOpen}
                  arrow={false}
                  overlayInnerStyle={{
                    padding: 0,
                    width: logoType === 'image' ? 465 : 421,
                  }}
                  content={logoEditContent}
                >
                  <div
                    className='w-80px h-80px overflow-hidden cursor-pointer relative'
                    style={{ borderRadius: logoType === 'image' ? '100%' : 8 }}
                  >
                    <div className='absolute z-1 w-full h-full top-0 bg-black bg-op-40 left-0 absolute flex-center op-0 hover:!op-100'>
                      <IconFont name='bianji' className='text-16px c-#fff' />
                    </div>
                    <AppLogo
                      value={icon}
                      type={logoType}
                      color={color}
                      size={80}
                      fillSize={36}
                    />
                  </div>
                </Popover>
              </div>
            )
          }}
        </Form.Item>
        <StyledFormItem
          label='名称'
          name='name'
          required
          rules={[
            { required: true, message: '请输入名称' },
            ...(fieldConfig?.name?.rules ?? []),
          ]}
        >
          <Input
            placeholder={fieldConfig?.name?.placeholder}
            showCount
            maxLength={50}
          />
        </StyledFormItem>
        <StyledFormItem label='描述' name='description'>
          <TextArea
            rows={4}
            placeholder={fieldConfig?.description?.placeholder}
            showCount
            maxLength={200}
          />
        </StyledFormItem>
        <div className='flex items-center justify-end gap-12 mb-8'>
          <Button onClick={onCancel}>取消</Button>
          <Button type='primary' onClick={onFinish}>
            完成
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
