import styled from '@emotion/styled'
import { IconFont } from '@/components'

interface AddPluginButtonProps {
  onClick?: () => void
  label?: string
}
const AddPluginButtonWrapper = styled.div``

export function AddPluginButton({
  onClick,
  label = '在新Agent使用',
}: AddPluginButtonProps) {
  return (
    <AddPluginButtonWrapper
      className='cursor-pointer p-15 b b-line b-op-60 rounded-8px c-primary flex items-center justify-between hover:bg-#F3F3F7'
      onClick={onClick}
    >
      <div className='flex items-center'>
        <span className='rounded-8px bg-primary bg-op-7.84 w-40px h-40px flex-center text-16px'>
          <IconFont name='add' />
        </span>
        <div className='ml-12px'>{label}</div>
      </div>
    </AddPluginButtonWrapper>
  )
}
