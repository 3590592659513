import { Tooltip, message } from 'antd'
import { useImagePreview } from '@bty/ui-components'
import { Modal, IconFont } from '@/components'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

interface ImagePreviewModalProps {
  open?: boolean
  onCancel?: () => void
  url: string
  width?: number
  height?: number
}
export function ImagePreviewModal(props: ImagePreviewModalProps) {
  const { open, url, onCancel } = props

  const { scrollRef } = useScrollBar()

  const { onShrink, onRestore, onEnlarge, imageStyle } = useImagePreview(url, {
    onImageDownloadFail: () => {
      message.error('下载失败')
    },
    onImageLoadFail: () => {
      message.warning('图片信息加载失败')
    },
  })

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={props.width ?? 1120}
      footer={null}
      styles={{
        body: {
          height: props.height ?? 720,
          overflow: 'hidden',
          padding: 0,
        },
      }}
      title={
        <div className='bg-white rounded-tl-8px rounded-tr-8px b-font_1 b-b b-op-8 h-48px flex items-center justify-center'>
          <div className='flex items-center'>
            <Tooltip placement='bottom' title='放大'>
              <span
                className='w-32px h-32px flex-center rounded-6px hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
                onClick={onEnlarge}
              >
                <IconFont name='fangtaitupian' />
              </span>
            </Tooltip>
            <Tooltip placement='bottom' title='缩小'>
              <span
                className='w-32px h-32px flex-center rounded-6px hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
                onClick={onShrink}
              >
                <IconFont name='suoxiaotupian' />
              </span>
            </Tooltip>
            <Tooltip placement='bottom' title='还原'>
              <span
                className='w-32px h-32px flex-center rounded-6px hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
                onClick={onRestore}
              >
                <IconFont name='yuanshichecun' />
              </span>
            </Tooltip>
          </div>
        </div>
      }
    >
      <div ref={scrollRef} className='w-full h-full overflow-auto p-12'>
        <img
          src={url}
          alt=''
          style={{
            width: imageStyle.width,
            height: imageStyle.height,
            maxWidth: imageStyle.width,
            maxHeight: imageStyle.height,
          }}
          className='select-none shrink-0'
        />
      </div>
    </Modal>
  )
}
