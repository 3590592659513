import { memo } from 'react'
import { FormItem } from '@/components'
import { WithQuickFillTextArea } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'

export const SkillForm = memo<{
  placeholderMap: Record<string, string>
}>(({ placeholderMap }) => {
  return (
    <>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='技能描述'
        name='skillInfo'
      >
        <WithQuickFillTextArea
          rows={3}
          className='resize-none!'
          placeholder={placeholderMap.skillInfo}
        />
      </FormItem>
    </>
  )
})

SkillForm.displayName = 'SkillForm'
