import styled from '@emotion/styled'
import { useEventListener, useRequest } from 'ahooks'
import { sample } from 'lodash-es'
import { useState, type FC, type ReactNode, useRef, useMemo } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { type useAuth } from '@bty/react-auth'
import type { ApplicationBodyType } from '@/apis/application.ts'
import {
  createApplication,
  fetchTemplateList,
  forkApplication,
  getTemplateLabels,
} from '@/apis/application.ts'
import { Button, IconFont, Modal } from '@/components'
import { avatars } from '@/features/agent/constant/base.ts'
import {
  AgentTemplateCard,
  TemplateAbility,
} from '@/pages/agent/list/AgentCardItem.tsx'
import { getName } from '@/pages/application/utils.tsx'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { PromptType } from '@/apis/prompt.ts'
import { getDefaultStructPrompt } from '@/features/prompt/constant/structPrompt.ts'

interface AgentCreateModalProps {
  access: ReturnType<typeof useAuth>['access']
  open?: boolean
  onCancel?: () => void
  agentList?: ApplicationBodyType[]
  groupId?: string
  navigate?: NavigateFunction
}

const LeftBarItem = styled.div<{ selected?: boolean }>`
  padding: 10px 12px 12px 12px;
  margin-bottom: 3px;
  font-size: 14px;
  transition: background-color 0.2s ease;
  background: ${props =>
    props.selected ? 'rgba(123, 97, 255, 0.08)' : 'transparent'};
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: rgba(98, 105, 153, 0.08);
  }
`

const agentCardClassName =
  '!w-[calc((100%_/_3)_-_14px)] !at-md:w-[calc((100%_/_3)_-_14px)] !at-sm:w-[calc(50%_-_10px)] !lt-sm:w-100%'

function renderAgentInfo(title: ReactNode, desc: string) {
  return (
    <>
      <div
        className='color-#17171D text-14px font-600 py-8px'
        title={typeof title === 'string' ? title : ''}
      >
        {title}
      </div>
      <p className='text-12px c-font_1 line-height-20px line-clamp-2 font-300'>
        {desc}
      </p>
    </>
  )
}

function TemplateBlock(props: {
  title: string
  children?: React.ReactNode
  id: string
}): JSX.Element {
  const { id, children, title } = props
  return (
    <div id={id} style={{ scrollMarginTop: '16px' }} className='template-block'>
      <h2 className='mb-[16px] font-medium color-[#17171D] text-[16px]'>
        {title}
      </h2>
      <div className='w-[58vw] max-w-[1120px] flex items-center flex-wrap gap-16 mb-[24px]'>
        {children}
      </div>
    </div>
  )
}

export const AgentCreateModal: FC<AgentCreateModalProps> = props => {
  const { access, agentList, navigate, groupId, ...remainingProps } = props
  const [selectedLabel, setSelectedLabel] = useState<string>('快速上手')
  const templateContentRef = useRef<HTMLDivElement>(null)
  const isScrollingRef = useRef(false)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { fetchVersionByWorkspaceId } = useVersionStore()
  const labelRef = useRef<string[]>([])
  const { data: tempalteLabels = [] } = useRequest(getTemplateLabels)
  // 如果 labels 中有快速上手的话， 去掉快速上手
  const labels = useMemo(() => {
    const filteredLabels = tempalteLabels.filter(label => label !== '快速上手')
    labelRef.current = filteredLabels
    return filteredLabels
  }, [tempalteLabels])
  const { data: templateList } = useRequest(fetchTemplateList)
  const { runAsync: createEmptyAgent, loading: createEmptyAgentLoading } =
    useRequest(e => createApplication(e, currentWorkspaceId), {
      manual: true,
    })
  const { runAsync: createAgentByTemplate, loading: createByTemplateLoading } =
    useRequest(forkApplication, { manual: true })
  const getEmptyAgent = async () => {
    const res = await createEmptyAgent({
      icon: sample(avatars),
      description: '暂无描述',
      name: getName(agentList || [], 'agent'),
      applicationType: 'AGENT',
      appGroupId: groupId,
      config: JSON.stringify({
        rule: {
          channel: 'GPT',
          model: 'gpt-4o',
          related_questions_enabled: false,
          related_questions_obj: { related_questions_type: 'AI' },
          enforce_execution: { type: 'CLOSE', tool_id: '' },
          prompt_plugins: {
            length_prompt: 5,
            time_prompt: true,
          },
          stream: true,
          temperature: 0.7,
          messages: [{ role: 'system', content: '' }],
          welcome_enabled: false,
          welcome: '',
          rule_id: uuidv4(),
          promptType: PromptType.STRUCT,
          structPrompt: getDefaultStructPrompt('Agent'),
        },
        flows: [],
        dataset: [],
        utility: [],
      }),
    })
    return res
  }

  const onCreateEmptyAgent = async () => {
    const res = await getEmptyAgent()
    // 更新套餐用量
    fetchVersionByWorkspaceId(currentWorkspaceId)
    navigate?.(`/agent/${res.id}`)
    remainingProps.onCancel?.()
  }

  const onCreateByTemplate = async (
    templateId: string,
    templateWorkspaceId: string,
  ) => {
    const res = await createAgentByTemplate({
      source_appid: templateId,
      source_workspace_id: templateWorkspaceId,
      target_workspace_id: currentWorkspaceId,
      app_group_id: groupId,
    })
    // 更新套餐用量
    fetchVersionByWorkspaceId(currentWorkspaceId)
    // window.open(`/agent/${res.application_id}?forkTaskId=${res.task_id}`, '_blank')
    window.location.href = `/agent/${res.application_id}?forkTaskId=${res.task_id}`
    remainingProps.onCancel?.()
  }

  const handleScroll = () => {
    if (!templateContentRef.current || isScrollingRef.current) return
    const scrollDom = templateContentRef.current

    if (scrollDom.scrollTop === 0) {
      setSelectedLabel('快速上手')
      return
    }

    // 滚动到最底部时，选中最后一个 label
    if (
      scrollDom.clientHeight + scrollDom.scrollTop ===
      scrollDom.scrollHeight
    ) {
      if (labelRef.current[labelRef.current.length - 1]) {
        setSelectedLabel(labelRef.current[labelRef.current.length - 1])
      }
      return
    }

    const sections = document.querySelectorAll('.template-block')
    const scrollPosition = scrollDom.scrollTop + scrollDom.offsetTop

    sections.forEach((section: Element) => {
      // 这里断言 section 为 HTMLElement，以访问 offsetTop 和 offsetHeight
      const top = (section as HTMLElement).offsetTop
      const bottom = top + (section as HTMLElement).offsetHeight
      if (scrollPosition >= top && scrollPosition <= bottom) {
        if (section.id) {
          const label = section.id.split('-')[0]
          setSelectedLabel(label)
        }
      }
    })
  }

  useEventListener('scroll', handleScroll, {
    target: templateContentRef.current,
  })

  const handleClickLabel = (label: string) => {
    isScrollingRef.current = true
    setSelectedLabel(label)
    const templateElement = document.getElementById(
      `${label}-agent-template-left-bar-item`,
    )
    if (templateElement) {
      templateElement.scrollIntoView()
      let lastPos: number | null = null
      requestAnimationFrame(checkPos)
      function checkPos() {
        const newPos: number | null =
          templateElement?.getBoundingClientRect().top ?? null
        if (newPos === lastPos) {
          // 滚动完成
          isScrollingRef.current = false
        } else {
          lastPos = newPos
          requestAnimationFrame(checkPos)
        }
      }
    }
  }

  return (
    <Modal
      {...remainingProps}
      title='创建Agent'
      width='fit-content'
      styles={{
        body: {
          maxHeight: 600,
          background: '#F7F7FA',
          padding: '0',
          borderRadius: '0 0 10px 10px',
        },
      }}
      footer={null}
    >
      <div className='flex relative'>
        <div
          className='w-[162px] h-[600px] bg-[#fff] p-[16px] b-r-1 b-[#E1E1E5] b-op-80'
          style={{ borderBottomLeftRadius: '10px' }}
        >
          <LeftBarItem
            selected={selectedLabel === '快速上手'}
            key='快速上手'
            onClick={() => handleClickLabel('快速上手')}
          >
            快速上手
          </LeftBarItem>
          {labels.map(label => (
            <LeftBarItem
              key={label}
              selected={selectedLabel === label}
              onClick={() => handleClickLabel(label)}
            >
              {label}
            </LeftBarItem>
          ))}
        </div>
        <div
          className='flex-1 p-[16px] h-[600px] overflow-y-auto transform-gpu'
          ref={templateContentRef}
        >
          <TemplateBlock
            title='快速上手'
            id='快速上手-agent-template-left-bar-item'
          >
            <div
              className={`${agentCardClassName}  bg-#fff b-rd-12px relative p-10px cursor-pointer  overflow-hidden hover:shadow-[0px_12px_20px_0px_rgba(23,23,29,0.12)]`}
              onClick={onCreateEmptyAgent}
              style={createEmptyAgentLoading ? { pointerEvents: 'none' } : {}}
            >
              <div className='text-right mb-[3px]'>
                <span
                  className='bg-op-20 px-[4px] py-[2px] b-rd-[100px] font-size-[12px] font-medium color-[#7B67EE]'
                  style={{
                    background:
                      'linear-gradient(290deg, rgba(226, 29, 111, 0.2) -2%, rgba(210, 72, 182, 0.2) 4%, rgba(109, 60, 255, 0.2) 39%, rgba(123, 97, 255, 0.2) 59%, rgba(69, 152, 255, 0.2) 80%)',
                  }}
                >
                  AI助手
                </span>
              </div>
              <div>
                <div className='flex justify-center mb-[12px]'>
                  <IconFont className='font-size-[30px]' name='tianjiahuihua' />
                </div>
                <div className='font-size-[14px] font-semibold color-[#17171D] text-center mb-[8px]'>
                  空白Agent
                </div>
                <div className='font-size-[12px] color-[#8D8D99] text-center line-height-[18px] mb-[16px] font-light'>
                  输入你的需求AI助手帮你生成Agent
                </div>
              </div>
            </div>
            {templateList
              ?.filter(template => template.labels?.includes('快速上手'))
              .map(template => {
                return (
                  <AgentTemplateCard
                    className={agentCardClassName}
                    key={template.id}
                    icon={template.icon}
                    messageWrapper={renderAgentInfo(
                      template.name,
                      template.description,
                    )}
                    onClick={() =>
                      onCreateByTemplate(template.id, template.workspaceId)
                    }
                    buttonWrapper={() => (
                      <Button
                        className='w-full'
                        type='primary'
                        size='small'
                        icon={<IconFont name='edit-app' />}
                        loading={createByTemplateLoading}
                      >
                        使用此模版创建
                      </Button>
                    )}
                    footerWrapper={() => {
                      return (
                        <div className='flex gap-16px mt-10px'>
                          <TemplateAbility
                            icon='zhishi'
                            num={template.skillStatistics?.knowledge_files || 0}
                          />
                          <TemplateAbility
                            icon='zhajian-1'
                            num={template.skillStatistics?.plugins || 0}
                          />
                          <TemplateAbility
                            icon='flow-1'
                            num={template.skillStatistics?.flows || 0}
                          />
                          <TemplateAbility
                            icon='data-1'
                            num={template.skillStatistics?.database_tables || 0}
                          />
                        </div>
                      )
                    }}
                  />
                )
              })}
          </TemplateBlock>
          {labels.map(label => {
            return (
              <TemplateBlock
                key={label}
                title={label}
                id={`${label}-agent-template-left-bar-item`}
              >
                {templateList
                  ?.filter(template => template.labels?.includes(label))
                  .map(template => {
                    return (
                      <AgentTemplateCard
                        className={agentCardClassName}
                        key={template.id}
                        icon={template.icon}
                        messageWrapper={renderAgentInfo(
                          template.name,
                          template.description,
                        )}
                        onClick={() =>
                          onCreateByTemplate(template.id, template.workspaceId)
                        }
                        buttonWrapper={() => (
                          <Button
                            className='w-full'
                            type='primary'
                            size='small'
                            icon={<IconFont name='edit-app' />}
                            loading={createByTemplateLoading}
                          >
                            使用此模版创建
                          </Button>
                        )}
                        footerWrapper={() => {
                          return (
                            <div className='flex gap-16px mt-10px'>
                              <TemplateAbility
                                icon='zhishi'
                                num={
                                  template.skillStatistics?.knowledge_files || 0
                                }
                              />
                              <TemplateAbility
                                icon='zhajian-1'
                                num={template.skillStatistics?.plugins || 0}
                              />
                              <TemplateAbility
                                icon='flow-1'
                                num={template.skillStatistics?.flows || 0}
                              />
                              <TemplateAbility
                                icon='data-1'
                                num={
                                  template.skillStatistics?.database_tables || 0
                                }
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  })}
              </TemplateBlock>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
