import { IconFont } from '@/components'

interface IconProps {
  type: 'success' | 'failed' | 'wait'
  content?: string | React.ReactNode
}

const iconMap: Record<IconProps['type'], string> = {
  success: 'pay-success',
  wait: 'pay-wait',
  failed: 'pay-error',
}

export function PayIcon({ type, content }: IconProps) {
  return (
    <div className='flex-center flex-col'>
      <IconFont className='text-64px' name={iconMap[type]}></IconFont>
      {content && <div className='text-16px mt-24 font-600'>{content}</div>}
    </div>
  )
}
