export const avatars = [
  ...Array.from({ length: 32 })
    .fill(null)
    .map((_, index) => {
      return `https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-${
        index + 35
      }.png`
    }),
  ...Array.from({ length: 34 })
    .fill(null)
    .map((_, index) => {
      return `https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-${
        index + 1
      }.png`
    }),
]

export const CODE_KEY_PLACEHOLDER = {
  AGENT_ID: '{YOUR_AGENT_ID}',
  WORKSPACE_ID: '{YOUR_WORKSPACE_ID}',
  API_KEY: '{YOUR_API_KEY}',
}

export const templateRuleFormData = {
  flow: {
    name: '{user}的个人助理',
    description: '你的个人助理',
  },
  rule: {
    messages: [
      {
        content: '你是我的个人助理，可以成为各个领域的专家，并且用中文回答',
      },
    ],
    welcome: '{user}你好，有什么可以帮你？',
    model: 'GPT-3.5-Turbo',
    temperature: 0.7,
    question_guide: [
      '你是从事膳食计划的营养专家，请为我制定三天的高蛋白膳食计划',
      '你是一个汇报专家，给我一份半年总结的结构',
    ],
  },
}

export const AGENT_DESIGN_ROLE_FULLSCREEN = 'AGENT_DESIGN_ROLE_FULLSCREEN'

export const AGENT_FROM = '__AGENT_FROM__'

export const AGENT_KNOWLEDGE_ROUTER_MAP = {
  DATASTORE: 'DATASTORE',
  DOCUMENT: 'DOCUMENT',
  DETAIL: 'DETAIL',
}

export const SYSTEM_PROMPT_NAME_PATH = ['messages', 0, 'content']
