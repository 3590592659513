import { userCenterRequest, request } from './lib/axios'

export interface SendSMSRequest {
  setting: {
    verificationType: number
    verificationLength: number
    expirationTime: number
  }
  params: {
    phoneNumbers: string
    signName: string
    templateCode: string
    templateParam: string
  }
}
export function SendSMS(data: SendSMSRequest) {
  return request.post<{ randomCode: string }>('/v1/sms/sendSms', data)
}

export function sendVerifyCode(data: { phone: string }) {
  const formData = new FormData()
  formData.append('phone', data.phone)
  return userCenterRequest.post(
    '/user-center/auth/sendVerifyCode',
    formData,
    // {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    // }
  )
}
