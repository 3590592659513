import { useCallback } from 'react'
import styled from '@emotion/styled'
import { message } from 'antd'
import { useBoolean } from 'ahooks'
import { LoadingOutlined } from '@ant-design/icons'
import { PanelHeader, useEditName } from '@/features/nodes/base'
import { useFlowDraftStore } from '@/store'
import { Input, IconFont } from '@/components'

const StyledInput = styled(Input)`
  width: 220px;
  height: 24px;
  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  &:disabled {
    color: #fff;
  }
`

export function CodeNodePanelHeader(props: any) {
  const { data, triggerNode, onClosePanel, activatedNodeId, onSaveChange } =
    props
  const [isEditTitle, { toggle: toggleTitleEdit }] = useBoolean(false)
  const { isSaving, nodes } = useFlowDraftStore()

  const { name, setName, validateName } = useEditName(data?.name as string)

  const onSaveTitle = useCallback(() => {
    const { pass, validateMessage } = validateName(name)
    if (!pass || !name) {
      message.error(validateMessage)
      return
    }
    if (
      nodes
        .filter(v => v.id !== activatedNodeId)
        .some(v => v.data.name === name)
    ) {
      message.error('该节点名已存在')
      setName(data?.name as string)
      toggleTitleEdit()
      return
    }
    onSaveChange({ name } as any, true, {
      newVariableName: name,
      oldVariableName: data.name,
    })
    toggleTitleEdit()
  }, [name, data, onSaveChange, activatedNodeId])

  return (
    <PanelHeader
      className='flex justify-between items-center text-14px h-48px'
      style={{ background: 'rgba(250, 250, 252)' }}
    >
      <div>
        <div className='flex items-center'>
          <div className='bg py-6 b-rd-4px'>
            <div
              className='flex items-center justify-center w-32px h-32px b-rd-6px bg-#fff'
              style={{
                border: '1px solid rgba(225, 225, 229, 0.8)',
              }}
            >
              <IconFont
                className='text-white line-height-16px text-16px'
                name={
                  data?.inputs?.language === 'javascript'
                    ? 'JS'
                    : 'python' || ''
                }
              />
            </div>
          </div>
          <div>
            {isEditTitle ? (
              <StyledInput
                value={name}
                maxLength={16}
                onDoubleClick={e => {
                  e && e.stopPropagation()
                }}
                onChange={e => setName(e.target.value)}
                onPressEnter={onSaveTitle}
                autoFocus
                onBlur={onSaveTitle}
                style={{
                  borderColor: '#7b61ff',
                  height: '28px',
                  fontSize: '16px',
                }}
                className='ml-8 font-600 nodrag flex items-center text-#17171D px-4 py-6  b-1px b-rd-4px hover:b-transparent bg-#fff'
              />
            ) : (
              <div
                onClick={() => {
                  toggleTitleEdit()
                }}
                style={{
                  borderColor: 'rgba(225, 225, 229, 0.8)',
                }}
                className='cursor-pointer font-600 ml-8 nodrag text-16px flex items-center text-#17171D px-4 py-6 b-rd-4px bg-opacity-6 hover:bg-opacity-0 hover:bg-opacity-8 hover:bg-#626999  hover:b-transparent'
              >
                {data?.name as string}
              </div>
            )}
          </div>
          <div className='flex px-4px py-2px bg-#626999 bg-op-08 b-rd-4px ml-4px font-size-12px color-#17171D color-op-80'>
            Beta
          </div>
          <div className='flex flex-items-center m-l-16'>
            {isSaving ? (
              <LoadingOutlined className='text-16px m-r-6' />
            ) : (
              <IconFont name='chenggongbaocun' className='text-16px m-r-6' />
            )}
            <span className='text-12px font-500 c-#a9aab8'>
              {isSaving ? '正在保存' : '已保存'}
            </span>
          </div>
        </div>
      </div>
      <div className='flex items-center'>
        {triggerNode}
        <div className='flex flex-items-center'>
          <div
            onClick={onClosePanel}
            className=' b-rd-4px m-l-8px w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
          >
            <IconFont name='guanbi' className='text-16px' />
          </div>
        </div>
      </div>
    </PanelHeader>
  )
}
