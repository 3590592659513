import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import type { AuthRoute } from '@/auth.ts'
import {
  AgentEdit,
  AgentDesign,
  AgentDatabase,
  AgentLog,
  AgentCopilot,
  AgentAnalysis,
} from '@/pages/agent'
import { ErrorElement } from '@/pages/error.tsx'
import { RoleCode } from '@/apis/authority.ts'
import { KNOWLEDGE_ROUTER_PATH, grayscaleMap } from '@/constants/common'
import { AgentGo } from '@/features/agent/AgentGo/AgentGo'
import { AgentFlow } from '@/pages/agent/components/AgentFlow'
import { AgentPlugin } from '@/pages/agent/components/AgentPlugin'
import { AgentKnowledge } from '@/pages/agent/components/AgentKnowledge'
import { AgentEditProvider } from '@/features/agent/provider/AgentEditProvider'
import { withVersionGuard } from '@/features/pay/withVersionGuard'
import { Version } from '@/features/pay'
import { AgentKnowledgeDetail } from '@/pages/agent/components/AgentKnowledgeDetail'
import { AgentKnowledgeList } from '@/pages/agent/components/AgentKnowledgeList'

function NavigateWithSearch(props: { to: string; replace?: boolean }) {
  const { to, replace } = props

  const navigate = useNavigate()

  useEffect(() => {
    navigate(
      {
        pathname: to,
        search: location.search,
      },
      {
        replace,
      },
    )
  }, [])

  return null
}

export const agentRoutes: AuthRoute[] = [
  {
    path: '/agent/:id',
    element: (
      <AgentEditProvider>
        <AgentEdit />
      </AgentEditProvider>
    ),
    auth(s) {
      const data = new URLSearchParams(window.location.search)
      const noRedirect = data.get('noRedirect')
      if (noRedirect || localStorage.getItem('noRedirect') === 'true') {
        localStorage.setItem('noRedirect', 'true')
        return true
      }
      return (
        s.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER]) &&
        s.grayscale(grayscaleMap.agent)
      )
    },
    redirect: '/notAllowed',
    errorElement: <ErrorElement />,
    children: [
      {
        path: '',
        element: <NavigateWithSearch replace to='design' />,
      },
      {
        path: 'design',
        element: <AgentDesign />,
        errorElement: <ErrorElement />,
        children: [
          {
            path: 'copilot',
            element: <AgentCopilot />,
          },
          {
            path: 'rule',
            element: <div className='hidden'></div>,
          },
          {
            path: KNOWLEDGE_ROUTER_PATH,
            children: [
              {
                path: '',
                element: <AgentKnowledgeList />,
              },
              {
                path: 'datastores/:datastoreId',
                element: <AgentKnowledge />,
              },
              {
                path: 'datastores/:datastoreId/:documentId',
                element: <AgentKnowledgeDetail />,
              },
            ],
          },
          {
            path: 'plugin',
            element: <AgentPlugin />,
          },
          {
            path: 'flow',
            element: <AgentFlow />,
          },
          {
            path: 'database',
            element: withVersionGuard({
              comp: <AgentDatabase />,
              allowVersions: [Version.ENTERPRISE, Version.FREE],
            }),
          },
        ],
      },
      {
        path: 'log',
        element: <AgentLog />,
      },
      {
        path: 'analysis',
        element: <AgentAnalysis />,
      },
      {
        path: 'agentgo',
        element: <AgentGo />,
      },
    ],
  },
]
