import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import OSS from 'ali-oss'
import type { TimeRangePickerProps } from 'antd'
import { Drawer, Dropdown, Modal, Steps, Switch, Upload } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { type ReactNode } from 'react'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { getSTSToken } from '@/apis/oss'
import type { scheduledType } from '@/apis/run'
import { log_state_enum, state_enum } from '@/apis/run'
import empty from '@/assets/empty.png'
import loading from '@/assets/loading.png'
import { Button, IconFont } from '@/components'
import theme from '@/constants/theme'
import { FLOW_DISPLAYNAME } from '@/constants/common'

const { Dragger } = Upload
const { confirm } = Modal

export interface AppDetailInstance {
  refreshAppInfo: () => void
  appInfo?: ApplicationBodyType
}

export function TitleWarpper({
  text,
  fontSize = 16,
  marginBottom = 12,
}: {
  text: string
  fontSize?: number
  marginBottom?: number
}) {
  return (
    <div
      className={classNames({
        'font-500': true,
        [`text-${fontSize}px m-b-${marginBottom}px`]: true,
      })}
    >
      {text}
    </div>
  )
}

export const ProcessWarpper = styled.div<{ process: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 17px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  &:before {
    width: ${props => props.process || 0}%;
    height: 100%;
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(123, 97, 255, 0.4);
  }
`

export function getOssUrl(stsTokenData: any, name: string, data: string) {
  const ossClient = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: stsTokenData!.access_key_id,
    accessKeySecret: stsTokenData!.access_key_secret,
    stsToken: stsTokenData!.security_token,
    bucket: import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET,
  })
  const response = {
    'content-disposition': `attachment; filename=${encodeURIComponent(name)}`,
  }
  const url = ossClient.signatureUrl(decodeURIComponent(data), { response })
  return url
}

export function downloadOssFile(name: string, url: string) {
  return getSTSToken().then((res: any) => {
    const a = document.createElement('a')
    a.href = getOssUrl(res || {}, name, url)
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  })
}

export async function uploadFile(
  stsTokenData: any,
  file: File,
  workspace_id: string,
) {
  const ossClient = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: stsTokenData!.access_key_id,
    accessKeySecret: stsTokenData!.access_key_secret,
    stsToken: stsTokenData!.security_token,
    bucket: import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET,
  })
  return await ossClient.multipartUpload(
    `ai/${workspace_id}/execute_result/${new Date().getTime()}${file.name}`,
    file,
    {},
  )
}

export const DivWrapper = styled.div`
  padding: 6px 0;
  color: rgba(23, 23, 29, 0.8);
`

export const rangePresets: TimeRangePickerProps['presets'] = [
  {
    label: <DivWrapper>今天</DivWrapper>,
    value: [dayjs().startOf('d'), dayjs().endOf('day')],
  },
  {
    label: <DivWrapper>过去7天</DivWrapper>,
    value: [dayjs().add(-7, 'd').startOf('d'), dayjs().endOf('day')],
  },
  {
    label: <DivWrapper>过去1个月</DivWrapper>,
    value: [dayjs().add(-1, 'M').startOf('d'), dayjs().endOf('day')],
  },
  {
    label: <DivWrapper>过去3个月</DivWrapper>,
    value: [dayjs().add(-3, 'M').startOf('d'), dayjs().endOf('day')],
  },
  {
    label: <DivWrapper>过去1年</DivWrapper>,
    value: [dayjs().add(-1, 'y').startOf('d'), dayjs().endOf('day')],
  },
]

export function GLobalSelectStyle() {
  return (
    <Global
      styles={css`
        .ant-picker-dropdown
          .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
          .ant-picker-cell-inner,
        :where(.css-dev-only-do-not-override-cdw58m).ant-picker-dropdown
          .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
            .ant-picker-cell-range-start
          ):not(.ant-picker-cell-range-end):not(
            .ant-picker-cell-range-hover-start
          ):not(.ant-picker-cell-range-hover-end)
          .ant-picker-cell-inner {
          background: rgba(123, 97, 255, 0.2);
        }
        .ant-picker-dropdown
          .ant-picker-time-panel-column
          > li.ant-picker-time-panel-cell-selected
          .ant-picker-time-panel-cell-inner {
          background: rgba(123, 97, 255, 0.12);
        }
        .ant-picker-dropdown
          .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
          background: rgba(123, 97, 255, 0.12);
        }
        .ant-select-dropdown {
          .rc-virtual-list {
            .rc-virtual-list-holder {
              .rc-virtual-list-holder-inner {
                .ant-select-item {
                  color: rgba(23, 23, 29, 0.8);
                }
                .ant-select-item-option-active {
                  background: rgba(98, 105, 153, 0.08);
                }
                .ant-select-item-option-selected {
                  color: #17171d !important;
                  background: rgba(123, 97, 255, 0.08) !important;
                  font-size: 14px !important;
                  font-weight: 600 !important;
                }
              }
            }
          }
        }
      `}
    />
  )
}

export const statusSelectOption = [
  { label: '全部状态', value: -1 },
  { label: '成功', value: 'SUCCEEDED' },
  { label: '失败', value: 'FAILED' },
]

export const typeSelectOption = [
  { label: '全部任务类型', value: -1 },
  { label: '调试', value: 'TESTING' },
  { label: '批量调试', value: 'BATCH_TEST' },
  { label: '单次', value: 'ONCE_RUN' },
  { label: '批量', value: 'BATCH_RUN' },
  { label: '定时', value: 'CORN_RUN' },
  { label: 'API', value: 'API' },
  { label: 'Webhook', value: 'WEBHOOK' },
  { label: 'Chatbot', value: 'CHATBOT' },
  { label: 'Agent', value: 'AGENT' },
  { label: `子${FLOW_DISPLAYNAME}`, value: 'SUB_FLOW' },
  { label: '单节点运行', value: 'STEP_RUN' },
]

export const typeSearchOption = [
  { value: 'user_name', label: '用户' },
  { value: 'input_word', label: '输入' },
  { value: 'output_word', label: '输出' },
]
export const agentSearchOption = [
  { value: 'user_name', label: '用户' },
  { value: 'title', label: '标题' },
  { value: 'input_word', label: '输入' },
  { value: 'output_word', label: '输出' },
]

export const feedbackSearchOptions = [
  { value: 'content', label: '问题' },
  { value: 'response', label: '回答' },
  { value: 'user_opinion', label: '输入意见' },
]

export const statusUI = [
  {
    background: 'rgba(44, 185, 105, 0.12)',
    color: '#2CB969',
    text: '成功',
  },
  {
    background: 'rgba(255, 82, 25, 0.12)',
    color: '#FF5219',
    text: '失败',
  },
]

export const statusList: Record<string, { text?: string; icon?: ReactNode }> = {
  start: {
    text: '惊喜即将发生',
    icon: <img className='w-124px' src={empty} />,
  },
  loading: {
    text: '生成中...',
    icon: <img className='w-124px' src={loading} />,
  },
  complete: {},
}

export const DivWarpper = styled.div<{ status: state_enum }>`
  color: #8d8d99;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  padding-left: 15px;
  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    content: ' ';
    background: ${({ status }) => (status === 1 ? '#31CC74' : '#FE9700')};
    height: 8px;
    width: 8px;
    border-radius: 50%;
    transform: translate(0, -50%);
    box-shadow: ${({ status }) =>
      status === 1
        ? '0px 0px 6px 0px rgba(49, 204, 116, 0.4)'
        : '0px 0px 6px 0px rgba(254, 151, 0, 0.4)'};
  }
`

export const DrawerWawrpper = styled(Drawer)`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .ant-drawer-close {
      font-size: 14px;
      color: #000000;
      margin-inline-end: 0;
    }
  }
`

export const timingStatusList: Record<
  log_state_enum,
  { background?: string; color?: string; text?: string }
> = {
  [log_state_enum.SUCCEEDED]: {
    background: 'rgba(44, 185, 105, 0.12)',
    color: '#2CB969',
    text: '成功',
  },
  [log_state_enum.TO_BE_RUN]: {
    background: 'rgba(56, 166, 255, 0.12)',
    color: '#38A6FF',
    text: '等待中',
  },
  [log_state_enum.FAILED]: {
    background: 'rgba(255, 82, 25, 0.12)',
    color: '#FF5219',
    text: '失败',
  },
}

export function StatusWarpper({
  status = log_state_enum.TO_BE_RUN,
}: {
  status?: log_state_enum
}) {
  return (
    <div
      className='border-rd-4px w-52px text-12px font-500 line-height-20px text-center ws-nowrap'
      style={{
        background: timingStatusList[status]?.background,
        color: timingStatusList[status]?.color,
      }}
    >
      {timingStatusList[status]?.text}
    </div>
  )
}

export interface RenderItemType {
  status: number
  text: string
  time: string
}

const MoreButton = styled(Button)`
  border: none;
  box-shadow: none !important;
  background: none !important;
  &:hover {
    background: rgba(98, 105, 153, 0.08) !important;
  }
`

export const SearchSelectContainer = styled.div`
  display: flex;
  .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-affix-wrapper {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid rgba(225, 225, 229, 0.6);
  }
`

const SpanWrapper = styled.span`
  width: 100%;
  line-height: 36px;
  display: inline-block;
  &:hover {
    color: ${() => theme.colors.primary};
  }
`

export function useTimingData({
  deleteTask,
  changeState,
  handleEdit,
  clickName,
  selectedRowKey,
}: {
  deleteTask: (e: string) => void
  changeState: (e: number, taskId: string) => void
  handleEdit: (e: scheduledType) => void
  clickName: (e: scheduledType) => void
  selectedRowKey: React.Key
}) {
  return {
    columns: [
      {
        title: '任务名称',
        key: 'task_name',
        width: 300,
        dataIndex: 'task_name',
        render: (_: any, record: scheduledType) => (
          <SpanWrapper
            className='cursor-pointer text-14px'
            style={
              selectedRowKey === record.key
                ? { color: theme.colors.primary }
                : {}
            }
            onClick={() => {
              clickName(record)
            }}
          >
            {record.task_name}
          </SpanWrapper>
        ),
      },
      {
        title: '最近结果',
        key: 'state',
        dataIndex: 'state',
        width: 100,
        render: (_: any, record: scheduledType) => (
          <StatusWarpper status={record.log_state} />
        ),
      },
      {
        title: '操作',
        key: 'action',
        width: 80,
        render: (_: any, record: scheduledType) => (
          <div className='flex flex-items-center'>
            <Switch
              size='small'
              checked={record.state === state_enum.RUNNING}
              onChange={e => changeState(e ? 1 : 2, record.task_id)}
              className='m-r-16px'
            />
            <Dropdown
              menu={{
                items: [
                  {
                    label: '编辑',
                    key: '1',
                    style: { width: 78, height: 28, padding: '0 12px' },
                    onClick: () => handleEdit(record),
                  },
                  {
                    label: '删除',
                    key: '2',
                    style: {
                      color: 'red',
                      width: 78,
                      height: 28,
                      padding: '0 12px',
                    },
                    onClick: () =>
                      confirm({
                        icon: <></>,
                        content: '请确认是否要删除Flow？',
                        cancelText: '取消',
                        okText: '确定',
                        okButtonProps: { danger: true },
                        onOk: () => deleteTask(record.task_id),
                      }),
                  },
                ],
              }}
              trigger={['click']}
            >
              <MoreButton
                icon={<IconFont name='gengduo' style={{ color: '#8D8D99' }} />}
              ></MoreButton>
            </Dropdown>
          </div>
        ),
      },
    ],
  }
}

export const DraggerWrapper = styled(Dragger)`
  .ant-upload-drag {
    background: rgba(98, 105, 153, 0.06) !important;
    border: 1px dashed rgba(225, 225, 229, 0.8) !important;
    border-radius: 8px !important;
  }
  .ant-upload-drag .ant-upload {
    padding: 40px 0 !important;
  }
`

export const StepsWrapper = styled(Steps)`
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    opacity: 0.24 !important;
  }
`
export function processObject<T extends object>(
  obj: T,
  excludeKeys?: string[],
): T {
  const result: any = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (excludeKeys?.includes(key)) {
        result[key] = obj[key]
      } else {
        const value = obj[key]
        if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean'
        ) {
          result[key] = value
        } else if (Array.isArray(value)) {
          result[key] = value
        } else {
          try {
            result[key] = JSON.stringify(value)
          } catch (error) {
            console.error(`Error serializing ${key}: ${error}`)
            result[key] = ''
          }
        }
      }
    }
  }
  return result as T
}
