import { memo } from 'react'
import type { IHead } from '../type'
import { Cell, CheckCell } from './head-cell'

interface HeadRowProps {
  heads: IHead[]
  flex: string[]
}

export const HeadRow = memo(({ heads, flex }: HeadRowProps) => {
  return (
    <div className='resize-table-row'>
      {heads.map((each, index) => {
        if (each.isRowSelect) {
          return <CheckCell key={index} head={each} flex={flex[index]} />
        }

        return (
          <Cell
            key={index}
            head={each}
            flex={flex[index]}
            last={index === heads.length - 1}
          />
        )
      })}
    </div>
  )
})
