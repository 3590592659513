import styled from '@emotion/styled'
import { memo } from 'react'

const EllipsisSpan = styled.span<{ width: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => props.width}px;
`

function TitleShowSearchValueContent(props: {
  title: string
  searchValue: string
  width: number
}) {
  const { title, searchValue, width } = props
  const upperTitle = title.toLocaleUpperCase()
  const upperSearchValue = searchValue.toLocaleUpperCase()
  if (searchValue && upperTitle.includes(upperSearchValue)) {
    const index = upperTitle.indexOf(upperSearchValue)
    if (index > -1) {
      return (
        <EllipsisSpan width={width - 108}>
          {title.slice(0, index)}
          <span style={{ color: 'var(--primary)' }}>
            {title.slice(index, index + searchValue.length)}
          </span>
          {title.slice(index + searchValue.length)}
        </EllipsisSpan>
      )
    }
  }
  return <EllipsisSpan width={width - 108}>{title}</EllipsisSpan>
}

export const TitleShowSearchValue = memo(TitleShowSearchValueContent)
