import type { ColumnType } from '@bty/smartsheet'
import { UITypes, PgUi, uiTypes } from '@bty/smartsheet'
import type { MoveField } from '@/features/database/types/column.ts'
import { setColumnToPrimary } from '@/apis/database/api.ts'
import type { SheetColumnType } from '@/features/database/types/tableImport.ts'

export function compareColumns(
  oldColumns: ColumnType[],
  newColumns: ColumnType[],
  fields: (keyof ColumnType)[] = ['column_name', 'description', 'uidt'],
) {
  const compare: any = { delete: [], add: [], update: [] }

  // 创建一个基于id的映射，以方便查找和比较
  const oldMap = new Map(oldColumns.map(item => [item.id, item]))
  const newMap = new Map(newColumns.map(item => [item.id, item]))

  // 检测删除和更新
  oldColumns.forEach(column => {
    if (!newMap.has(column.id)) {
      compare.delete.push({ id: column.id })
    } else {
      const newColumn = newMap.get(column.id)!
      if (fields.some(field => column[field] !== newColumn[field])) {
        compare.update.push({
          ...column,
          ...newColumn,
        })
      }
    }
  })

  // 检测添加
  newColumns.forEach(column => {
    if (!column.id || !oldMap.has(column.id)) {
      compare.add.push({ ...column })
    }
  })

  return compare
}

export async function setFirstUserColumnToPrimary(columns: ColumnType[]) {
  const firstUserColumn = columns.find(column => !column.system)
  const id = firstUserColumn ? firstUserColumn.id : columns[0].id
  if (id) {
    return setColumnToPrimary(id)
  }
}

export function fixColumn(column: ColumnType | SheetColumnType): ColumnType {
  const name = column.column_name ?? column.title
  return {
    ...column,
    title: name,
  } as ColumnType
}

export function compareCols(a?: any, b?: any) {
  if (a?.id && b?.id) {
    return a.id === b.id
  } else if (a?.temp_id && b?.temp_id) {
    return a.temp_id === b.temp_id
  }
  return false
}

function getFieldOrder(
  field: ColumnType,
  moveFields: MoveField[],
  fieldsOrderMap: Record<string, number>,
) {
  if (!field) return -1
  const mop = moveFields?.find(moveField => compareCols(moveField, field))
  if (mop) {
    return mop.order
  } else if (field.id) {
    const viewFieldOrder = fieldsOrderMap?.[field.id]
    return viewFieldOrder ?? -1
  }
  return -1
}

export function getNewColumn(): ColumnType {
  return {
    column_name: '',
    title: '',
    dt: 'character varying',
    dtx: 'specificType',
    ct: 'varchar(45)',
    nrqd: true,
    rqd: false,
    ck: false,
    pk: false,
    un: false,
    ai: false,
    cdf: null,
    clen: 45,
    np: null,
    ns: null,
    dtxp: '45',
    dtxs: '',
    altered: 1,
    uidt: UITypes.SingleLineText,
    uip: '',
    uicn: '',
  } as unknown as ColumnType
}

export function getDataTypeForUiType(uidt: ColumnType['uidt']) {
  switch (uidt) {
    case 'SingleLineText':
    case 'URL':
      return 'character varying'
    case 'Checkbox':
      return 'bool'
    case 'LongText':
    case 'Attachment':
    case 'MultiSelect':
    case 'SingleSelect':
      return 'text'
    case 'Date':
      return 'date'
    case 'Year':
      return 'int'
    case 'Time':
      return 'time'
    case 'Number':
      return 'bigint'
    case 'Decimal':
      return 'decimal'
    case 'DateTime':
      return 'timestamp'
    case 'JSON':
      return 'json'
    default:
      return 'character varying'
  }
}

export function calculateOrderForIndex(
  index: number,
  meta: {
    fields: ColumnType[]
    moveFields: MoveField[]
  },
  fromAbove = false,
) {
  const { fields, moveFields } = meta
  const fieldsOrderMap: Record<string, number> = {}
  fields.forEach(item => {
    if (item.id && item.order) {
      fieldsOrderMap[item.id] = item.order
    }
  })

  if (index >= fields.length - 1) {
    const fieldOrders = fields.map(f =>
      getFieldOrder(f, moveFields, fieldsOrderMap),
    )
    return Math.max(...fieldOrders) + 1
  }

  let orderBefore = -1
  let orderAfter = -1

  const fieldBefore = fields[index + (fromAbove ? -1 : 0)]
  const fieldAfter = fields[index + (fromAbove ? 0 : 1)]

  if (fieldBefore) {
    orderBefore = getFieldOrder(fieldBefore, moveFields, fieldsOrderMap)
  }

  if (fieldAfter) {
    orderAfter = getFieldOrder(fieldAfter, moveFields, fieldsOrderMap)
    if (orderAfter === -1) {
      orderAfter = orderBefore + 1
    }
  }

  return (orderBefore + orderAfter) / 2
}

export function injectSystemColumn(columns: ColumnType[]): ColumnType[] {
  const systemColumns = PgUi.getNewTableColumns() as any as ColumnType[]

  return [...columns, ...systemColumns]
}

export const uiTypeMap = uiTypes.reduce(
  (map, item) => {
    map[item.name] = item.label
    return map
  },
  {} as Record<UITypes, string>,
)
