import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { useNavigate } from 'react-router-dom'
import { EmotionProvider } from '@/providers/themeProvider'
import { AntdProvider } from '@/providers/antdProvider'

export function useModal<T>(Modal: FC<T>, componentProps?: Partial<T>) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null)
  const modalRootRef = useRef<ReactDOM.Root | null>(null)
  const [open, setOpen] = useState(false)
  const [currentProps, setCurrentProps] = useState<Omit<T, 'open'> | null>(null)
  const umountedRef = useRef(true)

  const navigate = useNavigate()

  const closeModal = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      if (modalRootRef.current) {
        modalRootRef.current.unmount()
        modalRootRef.current = null
      }

      if (modalContainerRef.current) {
        document.body.removeChild(modalContainerRef.current)
        modalContainerRef.current = null
      }
    }, 200)
    umountedRef.current = true
  }, [])

  useEffect(() => {
    try {
      let isMounted = true

      if (modalContainerRef.current && isMounted) {
        if (!modalRootRef.current && umountedRef.current) {
          modalRootRef.current = ReactDOM.createRoot(modalContainerRef.current)
          umountedRef.current = false
        }
        modalRootRef?.current?.render?.(
          <AntdProvider>
            <EmotionProvider>
              <Modal
                {...((componentProps || {}) as any)}
                {...((currentProps || {}) as any)}
                open={open}
                navigate={navigate}
                onCancel={() => {
                  // @ts-expect-error ts(2531)
                  currentProps?.onCancel?.()
                  closeModal()
                }}
              />
            </EmotionProvider>
          </AntdProvider>,
        )
      }

      // 清理函数
      return () => {
        isMounted = false
      }
    } catch (error) {
      console.warn(error)
    }
  }, [open, currentProps, componentProps, navigate, closeModal])

  const openModal = useCallback((props?: Omit<T, 'open'>) => {
    if (!modalContainerRef.current) {
      modalContainerRef.current = document.createElement('div')
      document.body.appendChild(modalContainerRef.current)
    }
    props && setCurrentProps(props)
    setOpen(true)
  }, [])

  return [
    {
      open: openModal,
      close: closeModal,
      toggle: open ? closeModal : openModal,
    },
  ]
}
