import type { InputRef } from 'antd'
import { Form, Modal } from 'antd'
import { useEffect, useRef } from 'react'
import { Button, Input } from '@/components'

export function NewGroupModal({
  open,
  onCancel,
  onOk,
  title,
  initValue,
}: {
  open: boolean
  onCancel: () => void
  onOk: (e: string) => void
  initValue?: any
  title?: string
}) {
  const [form] = Form.useForm()

  const InputRefDiv = useRef<InputRef>(null)

  useEffect(() => {
    setTimeout(() => {
      open && InputRefDiv.current?.focus()
    }, 100)
  }, [open])

  const handleOk = () => {
    form.validateFields().then(({ groupName }) => {
      onOk(groupName)
    })
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      destroyOnClose
      onOk={handleOk}
      closeIcon={<></>}
      footer={
        <>
          <Button onClick={onCancel}>取消</Button>
          <Button onClick={handleOk} type='primary'>
            确定
          </Button>
        </>
      }
      title={title}
    >
      <Form form={form} initialValues={initValue} className='mt-20px'>
        <Form.Item
          name='groupName'
          preserve={false}
          rules={[{ required: true, message: '分组名不能为空' }]}
        >
          <Input
            onKeyDown={e => e.keyCode === 13 && handleOk()}
            ref={InputRefDiv}
            maxLength={20}
            showCount
            placeholder='输入分组名称'
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
