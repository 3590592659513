import { Form, message } from 'antd'
import NiceModal from '@ebay/nice-modal-react'
import type { ColumnType } from '@bty/smartsheet'
import { UITypes } from '@bty/smartsheet'
import { useDebounceFn } from 'ahooks'
import { useEffect, useRef } from 'react'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { v4 as uuidv4 } from 'uuid'
import { Button, Modal } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import useNiceModal from '@/features/database/hooks/useNiceModal'
import TableEditFormItems from '@/features/database/components/TableEditFormItems'
import type {
  CreateTableRequest,
  Database,
  TableFieldUpdateBulkRequest,
} from '@/apis/database/types'
import { getTableDetail } from '@/apis/database/api'
import {
  compareColumns,
  fixColumn,
  getDataTypeForUiType,
  injectSystemColumn,
} from '../utils/column'
import { useTableDescGeneral } from '@/features/database/hooks/useTableDescGeneral.ts'

export interface TableEditModalProps {
  actionType?: 'create' | 'edit'
  initialId?: string
  database: Database | (() => Promise<Database>)
  onOk?: (
    databaseId: string,
    sourceId: string,
    tableMeta: CreateTableRequest | TableFieldUpdateBulkRequest,
  ) => Promise<void>
}

const FORM_INITIAL_VALUES = {
  columns: [
    {
      id: uuidv4(),
      column_name: '',
      description: '',
      uidt: UITypes.SingleLineText,
      rqd: false,
    },
  ],
}

function TableEditModal({
  actionType,
  initialId,
  database,
  onOk,
}: TableEditModalProps) {
  const [form] = Form.useForm()

  const oldTableName = useRef<string>()

  const oldColumns = useRef<ColumnType[]>()

  useEffect(() => {
    if (!initialId) {
      return
    }
    const query = async () => {
      const response = await getTableDetail(initialId)
      const columns = response.columns?.filter(el => !el.system)
      form.setFieldsValue({
        title: response.title,
        description: response.description,
        columns,
      })
      oldTableName.current = response.title
      oldColumns.current = columns
    }
    query()
  }, [initialId, form])

  const { handleClose, modal } = useNiceModal()

  const { onTableDescriptionGeneral, loading: generalDescLoading } =
    useTableDescGeneral(form)

  const { run: handleSave } = useDebounceFn(
    async () => {
      let _database
      if (typeof database === 'function') {
        try {
          _database = await database()
        } catch (_) {
          message.error((_ as Error).message)
          return
        }
      } else {
        _database = database
      }

      if (!_database?.sources?.length) {
        return
      }

      try {
        const values = await form.validateFields()
        if (actionType === 'edit') {
          const diff = compareColumns(oldColumns.current || [], values.columns)
          let ops
          if (diff.add.length || diff.update.length || diff.delete.length) {
            ops = [
              ...diff.add.map((item: ColumnType) => ({
                op: 'add',
                column: fixColumn(item),
              })),
              ...diff.delete.map((item: ColumnType) => ({
                op: 'delete',
                column: item,
              })),
              ...diff.update.map((item: ColumnType) => ({
                op: 'update',
                column: fixColumn(item),
              })),
            ]
          }
          const table = Object.assign(
            { description: values.description },
            values.title !== oldTableName.current
              ? { title: values.title, table_name: values.title }
              : undefined,
          )
          await onOk?.(_database.id, _database.sources[0].id, {
            base_id: _database.id,
            table,
            column_bulk: ops
              ? {
                  hash: '',
                  ops,
                }
              : undefined,
          })

          handleClose()
          message.success('保存成功')

          return
        }

        values.table_name = values.title
        values.columns = values.columns.map((col: ColumnType) => ({
          ...col,
          dt: getDataTypeForUiType(col.uidt),
        }))
        values.columns = injectSystemColumn(values.columns)

        await onOk?.(_database.id, _database.sources[0].id, values)

        handleClose()
        message.success('创建成功')
      } catch (e) {}
    },
    {
      leading: true,
      trailing: false,
    },
  )

  const Footer = (
    <div className='flex items-center justify-end gap-12 pt-12 px-32 pb-24 b-t b-font_1 b-op-8'>
      <div className='flex-center gap-12'>
        <Button onClick={handleClose} className='ml-8'>
          取消
        </Button>
        <Button onClick={handleSave} type='primary'>
          保存
        </Button>
      </div>
    </div>
  )

  const title = (
    <div className='px-16px py-6px border-b border-solid border-font_1 border-opacity-8 leading-36px'>
      {actionType === 'edit' ? '数据表调用配置' : '创建数据表'}
    </div>
  )

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  return (
    <Modal
      className='min-w-800px !w-60%'
      title={title}
      open={modal.visible}
      styles={{
        body: { padding: 0 },
      }}
      maskClosable={false}
      okText='保存'
      footer={Footer}
      onOk={handleSave}
      onCancel={handleClose}
    >
      <OverlayScrollbarsComponent
        ref={scrollRef}
        className='max-h-634px py-24'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        <Form
          className='px-32'
          form={form}
          layout='vertical'
          requiredMark={false}
          initialValues={FORM_INITIAL_VALUES}
        >
          <TableEditFormItems
            actionType={actionType}
            allowAutoGen={form => !!form.getFieldValue('title')}
            autoGenLoading={generalDescLoading}
            onAutoGen={onTableDescriptionGeneral}
            onColumnAdd={() => {
              setTimeout(() => {
                const scroller = scrollRef.current?.osInstance()?.elements()
                  .viewport
                if (scroller) {
                  scroller?.scroll({
                    top: scroller.scrollHeight + 200,
                    behavior: 'smooth',
                  })
                }
              })
            }}
          />
        </Form>
      </OverlayScrollbarsComponent>
    </Modal>
  )
}

export default NiceModal.create(TableEditModal)
