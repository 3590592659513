import type { ChatPCProps } from '@bty/chat-renderer-pc'
import { ChatPC } from '@bty/chat-renderer-pc'
import { useEffect, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import type { MessageItem } from '@bty/chat-ui'
import { ChatbotRunType } from '@bty/chat-types'
import { useUserStore, useWorkspaceStore } from '@/store'
import { TaskLogModal } from '@/features/chat'
import type { TransformKey } from '@/features/agent/provider/AgentEditProvider'
import {
  TransformKeyList,
  useAgentEdit,
} from '@/features/agent/provider/AgentEditProvider'
import { tokenStorage } from '@/utils/storage'
import { createAgentCopilotConversation } from '@/apis/copilot'
import { FormMessageComponentMap } from '@/features/agent/components/FormMessage.tsx'
import { BlurBG } from './BlurBG'

export interface CopilotChatProps {
  robotId?: string
  // conversationId: string
  // meta?: Record<string, any>
}

const REFRESH_PREVIEW_SKILLS = ['角色设定变更', '名称变更', '开场白变更']

export function CopilotChat() {
  const user = useUserStore(state => state.user)
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const {
    applicationInfo,
    refreshApplicationInfo,
    updateTransformMap,
    chatRef,
    uploadFileConfig,
  } = useAgentEdit()

  const chatUser = useMemo<ChatPCProps['user']>(() => {
    return {
      name: user?.username || '',
      id: user?.username || '',
    }
  }, [user])

  const meta = useMemo(() => {
    return {
      workspaceId,
      agentId: applicationInfo?.flowId,
      versionId: applicationInfo?.versionId,
      token: tokenStorage.get(),
    }
  }, [applicationInfo])

  const header = useMemo(() => {
    return (
      <div className='h-24px flex flex-center-between text-16px text-black font-500 pl-24px mt-24px mb-20px'>
        编排助手
      </div>
    )
  }, [])

  const onMessage = useMemoizedFn((message: MessageItem) => {
    const transformList = message?.tools?.filter(each => {
      return TransformKeyList.includes(each.title as TransformKey)
    })

    const newMap: Record<string, string> = {}
    transformList?.forEach(each => {
      newMap[each.title] = each.status
    })

    updateTransformMap(newMap)
  })

  const onChatEnd = useMemoizedFn(async (message: MessageItem) => {
    updateTransformMap({}, 'replace')

    const needRefresh = message?.tools
      ?.filter(v => v.status === 'success')
      .some(v => REFRESH_PREVIEW_SKILLS.includes(v.title))

    if (needRefresh) {
      setTimeout(() => {
        chatRef.current?.refresh?.()
      }, 500)
    }
  })

  useEffect(() => {
    if (!applicationInfo?.copilot_conversation_id && applicationInfo?.flowId) {
      createAgentCopilotConversation(
        applicationInfo?.id,
        applicationInfo?.flowId,
      ).then(() => refreshApplicationInfo())
    }
  }, [applicationInfo?.copilot_conversation_id])

  return (
    <div className='w-full h-full flex justify-center relative'>
      <div className='w-full h-full min-w-[300px] flex  flex-col relative '>
        {applicationInfo?.copilot_agent_id && (
          <ChatPC
            user={chatUser}
            actionType='CONVERSATION'
            autoSwitchConversation={false}
            loggerComponent={TaskLogModal}
            runType={ChatbotRunType.CHATBOT}
            onMessage={onMessage}
            onChatEnd={onChatEnd}
            env={__DEFINE_ENV__}
            conversationId={applicationInfo?.copilot_conversation_id}
            botMessageProps={{ avatarInfo: null }}
            userMessageProps={{ avatarInfo: null }}
            robotId={applicationInfo?.copilot_agent_id}
            workspaceId={workspaceId}
            title={header}
            metadata={meta}
            // paddingX={24}
            inputTips={['enter', 'shift + enter']}
            formMessageComponentMap={FormMessageComponentMap}
            uploadFileConfig={uploadFileConfig}
            authorization={tokenStorage.get()}
            // hideHeader
          />
        )}
        {/* </div> */}
        <div className='bottom-30'>
          <BlurBG position='bottom' />
        </div>
      </div>
    </div>
  )
}
