import { useRequest } from 'ahooks'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { fetchAlarmRules } from '@/apis/alarm'
import { Button } from '@/components'
import norulesImg from '@/assets/alarm/norules2@2x.png'
import { useWorkspaceStore } from '@/store'
import { AlarmRecords } from './Records'
import { AlarmRules } from './Rules'
import { StyledTabs } from './styledComponents'

export function Alarm() {
  const [searchParams, setSearchParams] = useSearchParams()
  const tabKey = searchParams.get('tab') ?? 'records'
  const [noRules, setNoRules] = useState(true)

  const { currentWorkspaceId } = useWorkspaceStore()

  const { data: totalRules } = useRequest(() => fetchAlarmRules({}), {
    refreshDeps: [currentWorkspaceId],
  })

  useEffect(() => {
    const queryTab = searchParams.get('tab')
    setNoRules((!queryTab || queryTab === 'rules') && !totalRules?.data.length)
  }, [totalRules])

  return (
    <div className='h-full pb-20px adapt:px-64'>
      <div className='h-full bg-#fff b-rd-12px'>
        {noRules ? (
          <div className='h-full flex-center flex-col'>
            <img className='w-400px h-326px' src={norulesImg} alt='' />
            <div className='mt-24px text-16px text-#17171d font-500'>
              平台运行异常时，将第一时间通过钉钉群机器人、短信、紧急电话等方式通知开发者
            </div>
            <Button
              type='primary'
              className='mt-24px'
              onClick={() => {
                setNoRules(false)
                setSearchParams({
                  tab: 'rules',
                })
              }}
            >
              配置告警规则
            </Button>
          </div>
        ) : (
          <div className='w-full h-full'>
            <StyledTabs
              className='h-full'
              items={[
                {
                  label: '告警记录',
                  key: 'records',
                  children: <AlarmRecords />,
                },
                {
                  label: '告警规则',
                  key: 'rules',
                  children: <AlarmRules />,
                },
              ]}
              activeKey={tabKey}
              onChange={k => {
                setSearchParams({
                  tab: k,
                })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
