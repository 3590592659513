import { RoleCode, RoleName } from '@/apis/authority'

export const MemberTypes = [
  {
    value: 'all',
    label: '全部角色',
  },
  {
    value: RoleCode.ADMINISTRATOR,
    label: RoleName[RoleCode.ADMINISTRATOR],
    description: '可以升级空间、编辑和使用应用、邀请其他用户加入工作空间等。',
    allowChangedRoleOptions: [
      RoleCode.ADMINISTRATOR,
      RoleCode.DEVELOPER,
      RoleCode.VIEWER,
    ],
  },
  {
    value: RoleCode.DEVELOPER,
    label: RoleName[RoleCode.DEVELOPER],
    description: '可以编辑和使用应用、邀请其他用户加入工作空间等。',
    allowChangedRoleOptions: [RoleCode.DEVELOPER, RoleCode.VIEWER],
  },
  {
    value: RoleCode.VIEWER,
    label: RoleName[RoleCode.VIEWER],
    description: '可以使用授权范围内的应用。',
  },
]
