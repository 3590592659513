import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { useUnmount } from 'ahooks'
import { IconFont, Segmented } from '@/components'
import { Version } from '@/features/pay/const'
import { useNodePanelStore, type IItemInfoChangeParams } from '@/store'
import { useVersionStore } from '@/store/version'
import { transformData } from '../../utils'
import { DeletePopover } from '@/features/nodes/components'
import { ExecuteBtn } from '../StyleComponent'
import Record from './components/Records'

const StyledSegmented = styled(Segmented)`
  display: flex;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding: 2px;
  border-radius: 6px;

  .ant-steps-item {
    &:hover {
      background: rgba(98, 105, 153, 0.08) !important;
    }
  }

  .ant-segmented-item {
    border-radius: 4px;
    padding: 8px 6px;
    flex: 1;
  }
`
interface IPromptHistoryProps {
  flowId: string
  selectedId: string
  activatedNodeId: string
  onUse: () => void
  onShow: (history_id: string, extra?: Record<string, any>) => void
  onClose: () => void
}

export function PromptHistory(props: IPromptHistoryProps) {
  const { flowId, selectedId, activatedNodeId, onUse, onShow, onClose } = props
  const [selectedTab, setSelectedTab] = useState<string>('record')

  const {
    setCollectItemStatus,
    allRecordList,
    fetchAllRecordList,
    initRecordList,
    collectList,
    fetchCollectedList,
    fetchLastedCollected,
    initCollectedList,
    collectListCount,
    recordListCount,
  } = useNodePanelStore()
  const versionInfo = useVersionStore(state => state.versionInfo)

  const formattedAllRecordList = useMemo(() => {
    return transformData(allRecordList)
  }, [allRecordList])

  const formattedCollectList = useMemo(() => {
    return transformData(collectList)
  }, [collectList])

  const fetchAllRecordListData = async () => {
    await fetchAllRecordList(flowId, activatedNodeId)
  }

  const fetchCollectedListData = async () => {
    await fetchCollectedList(flowId, activatedNodeId)
  }

  const initRecordListData = async () => {
    await initRecordList()
  }

  const initCollectedListData = async () => {
    await initCollectedList()
  }

  const changeItemInfo = async (params: IItemInfoChangeParams) => {
    await setCollectItemStatus(params)
    await fetchLastedCollected(flowId, activatedNodeId)
  }

  const tabsMap = useMemo(() => {
    return {
      record:
        versionInfo?.product_code !== Version.FREE ? '近30天记录' : '近5天记录',
      collect: '我的收藏',
    }
  }, [versionInfo])

  const recordListHasMore = useMemo(() => {
    return recordListCount > allRecordList.length
  }, [allRecordList.length, recordListCount])

  const collectedListHasMore = useMemo(() => {
    return collectListCount > collectList.length
  }, [collectList.length, collectListCount])

  const list = useMemo(() => {
    return selectedTab === 'record'
      ? formattedAllRecordList
      : formattedCollectList
  }, [selectedTab, formattedAllRecordList, formattedCollectList])

  const hasMore = useMemo(() => {
    return selectedTab === 'record' ? recordListHasMore : collectedListHasMore
  }, [selectedTab, recordListHasMore, collectedListHasMore])

  useUnmount(() => {
    initRecordList()
    initCollectedList()
  })

  return (
    <div className='h-full flex flex-col relative'>
      <div className='pt-16px pl-16px pr-16px pb-12px flex-none'>
        <IconFont
          onClick={() => {
            onClose?.()
          }}
          className='ml-4px text-16px cursor-pointer absolute top-16px right-16px'
          name='guanbi'
        />
        <div className='color-#17171D text-12px line-height-16px pb-12px font-500'>
          历史记录
        </div>
        <StyledSegmented
          size='middle'
          value={selectedTab}
          options={Object.entries(tabsMap).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          onChange={async value => {
            setSelectedTab(value as string)
          }}
        />
      </div>

      <div className='w-100% relative flex-auto overflow-hidden'>
        <Record
          list={list}
          hasMore={hasMore}
          selected={selectedId}
          onItemClick={onShow}
          onItemInfoChange={changeItemInfo}
          loadMore={
            selectedTab === 'record'
              ? fetchAllRecordListData
              : fetchCollectedListData
          }
          resetData={
            selectedTab === 'record'
              ? initRecordListData
              : initCollectedListData
          }
        />
      </div>

      {!!selectedId && (
        <div>
          <DeletePopover
            className='w-full px-20px pt-12px pb-24px'
            title='请确认是否覆盖内容？'
            deleteText='确定'
            closeText='取消'
            onDelete={onUse}
          >
            <ExecuteBtn
              type='primary'
              icon={<IconFont name='huifulishi' />}
              className='run-btn w-100% c-#f7f7ff'
            >
              恢复此记录
            </ExecuteBtn>
          </DeletePopover>
        </div>
      )}
    </div>
  )
}

export default PromptHistory
