import { memo, useMemo, useState } from 'react'
import type { FormInstance, FormListFieldData } from 'antd'
import { Form, Image, Tooltip } from 'antd'
import { useBoolean, useMemoizedFn } from 'ahooks'
import { useModal } from '@ebay/nice-modal-react'
import { shortcutsFormat } from '@bty/chat-logic'
import { useNavigate } from 'react-router-dom'
import type {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import type { StoreValue } from 'antd/es/form/interface'
import type { AgentConfigShortcut, Bot } from '@bty/chat-types'
import { AppLogo, IconFont } from '@/components'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { ShortcutsModal } from './ShortcutModal'

interface FormQuickInputListProps {
  flowId: string
  versionId: string
  form: FormInstance
  onAdd?: (values?: Record<string, any>) => void
  onEdit?: (values?: Record<string, any>) => void
  onSort?: (shortcuts?: AgentConfigShortcut[]) => void
}

const Label = memo(
  ({ value, icon, color }: { value?: string; icon: string; color: string }) => {
    return (
      <div className='flex items-center gap-6'>
        <AppLogo
          size={16}
          value={icon}
          color={color}
          type='emoji'
          fillSize={12}
          imgStyle={{ borderRadius: 4 }}
          className='rounded-4px'
        />
        <div>{value}</div>
      </div>
    )
  },
)

export const FormQuickInputList = memo(
  ({ flowId, versionId, form, onEdit, onSort }: FormQuickInputListProps) => {
    const modal = useModal(ShortcutsModal)
    const { skillConfigContext } = useAgentEdit()

    const [activeIndex, setActiveIndex] = useState<number | null>(null)
    const [isDragging, { setTrue: setIsDragging, setFalse: setNoDragging }] =
      useBoolean(false)
    const navigate = useNavigate()

    const onToFlow = useMemoizedFn(() => {
      navigate('flow')
    })

    const onToPlugin = useMemoizedFn(() => {
      navigate('plugin')
    })

    const handleAdd = useMemoizedFn(
      (add: (task: any) => void, _remove: (index: number) => void) => {
        modal.show({
          flowId,
          versionId,
          toAddFlow: onToFlow,
          toAddPlugin: onToPlugin,
          onSave(values) {
            add({ ...values })
            modal.remove()
          },
        })
      },
    )

    const handleEdit = useMemoizedFn(
      (
        _add: (task: any) => void,
        _remove: (index: number) => void,
        index: number,
      ) => {
        modal.show({
          flowId,
          versionId,
          initialData: form.getFieldValue(['shortcuts', index]),
          toAddFlow: onToFlow,
          toAddPlugin: onToPlugin,
          onSave(values) {
            form.setFieldValue(['shortcuts', index], values)
            onEdit?.(values)
            modal.remove()
          },
        })
      },
    )

    const shortcuts = Form.useWatch('shortcuts', form)

    const iconList = useMemo(() => {
      return shortcutsFormat({
        config: {
          flows: skillConfigContext?.flowList.map(v => v.meta),
          utility: skillConfigContext?.toolList.map(v => v.meta),
        },
        shortcuts,
      } as unknown as Bot)
    }, [shortcuts, skillConfigContext])

    const onDragEnd = (result: DropResult) => {
      setNoDragging()
      if (!result.destination) return
      const { source, destination } = result
      const shortcuts = form.getFieldValue('shortcuts')

      const newValue = [...shortcuts]
      const [reorderedItem] = newValue.splice(source.index, 1)
      newValue.splice(destination.index, 0, reorderedItem)

      form.setFieldsValue({ shortcuts: newValue })

      onSort?.(newValue)
    }

    const renderDropClone = (
      provided: DraggableProvided,
      _: DraggableStateSnapshot,
      rubric: DraggableRubric,
    ) => {
      const { icon, color, title } = iconList?.[rubric.source.index] || {}

      return (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            className='h-36px bg-white border-1px border-op-80 rounded-6px flex flex-center px-10px pr-6px'
            {...provided.dragHandleProps}
          >
            <Form.Item className='shrink-0 mb-0'>
              <Label icon={icon} color={color} value={title} />
            </Form.Item>

            <div className='ml-auto flex flex-center text-[rgba(98,105,153,0.6)]'>
              <button
                className='flex justify-center items-center w-24px h-24px mr-6px text-16px rounded-6px hover:bg-#E4E5ED'
                type='button'
              >
                <IconFont name='zaicishuru' />
              </button>
              <button
                className='flex justify-center items-center w-24px h-24px text-16px rounded-6px hover:bg-#E4E5ED'
                type='button'
              >
                <IconFont name='shanshu2' />
              </button>
            </div>
          </div>
        </div>
      )
    }

    const renderDropBox = (
      fields: FormListFieldData[],
      add: (defaultValue?: StoreValue, insertIndex?: number) => void,
      remove: (index: number | number[]) => void,
    ) => {
      return (
        provided: DroppableProvided,
        snapshot: DroppableStateSnapshot,
      ) => {
        return (
          <div
            style={{
              marginBottom: snapshot.isDraggingOver ? 16 : 0,
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {fields.map(({ key, name }, index) => {
              return (
                <Draggable key={key} draggableId={`${name}`} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      onMouseEnter={() => setActiveIndex(index)}
                      onMouseLeave={() => setActiveIndex(null)}
                    >
                      <div
                        key={key}
                        className='mb-8 flex w-full flex-items-center'
                        {...provided.draggableProps}
                      >
                        <span
                          className={classNames(
                            'flex items-center justify-center w-[16px] h-[16px] cursor-move',
                            {
                              'opacity-0': activeIndex !== index || isDragging,
                            },
                          )}
                        >
                          <IconFont
                            name='drag'
                            className='text-font_1 text-[15px] text-op-30'
                          />
                        </span>
                        <div className='h-36px bg-white border-1px border-op-80 rounded-6px flex flex-center px-10px pr-6px flex-1'>
                          <Form.Item
                            name={[name, 'short_name']}
                            className='shrink-0 mb-0'
                          >
                            <Label
                              icon={iconList?.[index]?.icon}
                              color={iconList?.[index]?.color}
                            />
                          </Form.Item>

                          <div className='ml-auto flex flex-center text-[rgba(98,105,153,0.6)]'>
                            <button
                              className='flex justify-center items-center w-24px h-24px mr-6px text-16px rounded-6px hover:bg-#E4E5ED'
                              type='button'
                            >
                              <IconFont
                                name='zaicishuru'
                                onClick={() => handleEdit(add, remove, index)}
                              />
                            </button>
                            <button
                              className='flex justify-center items-center w-24px h-24px text-16px rounded-6px hover:bg-#E4E5ED'
                              type='button'
                            >
                              <IconFont
                                name='shanshu2'
                                onClick={() => remove(index)}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              )
            })}

            {provided.placeholder}
          </div>
        )
      }
    }

    return (
      <Form.List name='shortcuts'>
        {(fields, { add, remove }) => (
          <div className={classNames({ 'pb-8px': shortcuts?.length })}>
            <div className='flex items-center h-48px'>
              <span className='text-14px text-font font-medium pl-16'>
                快捷入口
              </span>
              <Tooltip
                placement='top'
                title={
                  <div className='flex flex-col flex-center px-4 py-2'>
                    <Image
                      width={226}
                      height={180}
                      src='https://resource.bantouyan.com/betteryeah/agent/shortcuts/shortcut-demo.png'
                      alt='快捷入口'
                    />
                    <div className='mt-8 mb-6'>快捷入口</div>
                  </div>
                }
              >
                <IconFont
                  className='text-font_1 text-14px text-opacity-40 ml-5 cursor-pointer'
                  name='jieshishuimeng'
                />
              </Tooltip>
              <button
                className='flex justify-center items-center w-24px h-24px ml-auto text-16px rounded-6px hover:bg-#E4E5ED'
                type='button'
                onClick={event => {
                  event.preventDefault()
                  handleAdd(add, remove)
                }}
              >
                <IconFont name='fangtai' />
              </button>
            </div>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={setIsDragging}>
              <Droppable
                renderClone={renderDropClone}
                droppableId='shortcutsdroppable'
              >
                {renderDropBox(fields, add, remove)}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </Form.List>
    )
  },
)
