import type { SegmentedValue } from 'antd/es/segmented'
import { useEffect, useState } from 'react'
import { Segmented } from '@/components'
import type { ChatProps as LLMChatProps } from '../../LLMNodePanel/components/FormChat'
import { Chat } from '../../LLMNodePanel/components/FormChat'
import { LLMContextType, contextOptions } from '../../const'
import type { LLMContextJSONFieldProps } from './LLMContextJSONField'
import { LLMContextJSONField } from './LLMContextJSONField'

type LLMContextFormFieldProps = (
  | (LLMChatProps & { contextType: LLMContextType.MSG_LIST })
  | (LLMContextJSONFieldProps & { contextType: LLMContextType.JSON_VARIABLE })
) & {
  onTypeChange: (type: SegmentedValue) => void
}

export function LLMContextFormField(props: LLMContextFormFieldProps) {
  const { contextType, onTypeChange, value, onChange } = props
  // ui实时切换
  const [type, setType] = useState(contextType)

  useEffect(() => {
    setType(contextType)
  }, [contextType])

  return (
    <div>
      <Segmented
        block
        size='small'
        className='mb-12px'
        disabled={props.readOnly || props.disabled}
        options={contextOptions}
        value={type}
        onChange={t => {
          setType(t as LLMContextType)
          onTypeChange(t)
        }}
      />
      {contextType === LLMContextType.MSG_LIST && (
        <Chat
          value={value}
          onChange={onChange}
          readOnly={props.readOnly}
          anchor={props.anchor}
          modelMessageStructType={props.modelMessageStructType}
          completions={props.completions}
          variableTipsContainer={props.variableTipsContainer}
          setOptions={props.setOptions}
          extraCls={props.extraCls}
        />
      )}
      {contextType === LLMContextType.JSON_VARIABLE && (
        <LLMContextJSONField
          value={value}
          readOnly={props.readOnly}
          onChange={onChange}
          anchor={props.anchor}
          completions={props.completions}
          modelMessageStructType={props.modelMessageStructType}
          model={props?.model}
          variableTipsContainer={props.variableTipsContainer}
          extraCls={props.extraCls}
        />
      )}
    </div>
  )
}
