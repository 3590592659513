import React, { useState } from 'react'
import cn from 'classnames'
import JsonView from 'react-json-view'
import copy from 'copy-to-clipboard'
import { message } from 'antd'
import { IconFont, Segmented } from '@/components'
import { FlowStatus } from '@/apis/flow'

interface ExecuteResultProps {
  open: boolean
  value: any
  status?: FlowStatus
  error?: string
  onOpenChange: (open: boolean) => void
}

function InternalExecuteResult({
  open,
  value,
  status,
  error,
  onOpenChange,
}: ExecuteResultProps) {
  const columns = Array.isArray(value)
    ? value[0]
      ? Object.keys(value[0])
      : null
    : null

  const rows = columns ? (value as object[]) : null

  const [displayType, setDisplayType] = useState<'Table' | 'JSON'>('Table')

  return (
    <div
      className={cn(
        'absolute z-10 left-0 bottom-0 w-full h-48px flex flex-col px-16 pb-16 border-top after:border-[rgba(225,225,229,0.6)] bg-white transition-height',
        {
          '!h-430px': open,
        },
      )}
    >
      <div className='py-16 flex justify-between items-center shrink-0'>
        <span className='text-14px/16px font-medium'>运行结果</span>
        <button
          onClick={event => {
            event.stopPropagation()
            onOpenChange(!open)
          }}
        >
          <IconFont
            name={open ? 'mianbanxiangxiashouqi' : 'mianbanxiangshangzhankai'}
          />
        </button>
      </div>
      {status === FlowStatus.FAIL ? (
        <div className='flex flex-items-center flex-justify-center rounded-8px bg-#ff5219 bg-op-8 px-12 py-8'>
          <IconFont name='cuowu' className='text-16px m-r-8 shrink-0' />
          <span className='text-#99310f'>{error}</span>
        </div>
      ) : (
        <>
          <Segmented
            className='shrink-0 mb-12 [&_.ant-segmented-item]:!py-8'
            options={['Table', 'JSON']}
            size='small'
            block
            onChange={value => {
              setDisplayType(value as 'Table' | 'JSON')
            }}
          />
          <div className='relative overflow-hidden flex-1'>
            <div className='h-full overflow-auto rounded-6px border border-solid border-[rgba(225,225,229,0.6)] [&>.react-json-view]:p-8 [&>.react-json-view_.string-value]:whitespace-pre-line'>
              {displayType === 'Table' ? (
                <table className='table-auto w-full'>
                  <colgroup>
                    {columns?.map((_, index) => (
                      <col
                        key={`schema_col_${index}`}
                        className='min-w-120px'
                      />
                    ))}
                  </colgroup>
                  <thead>
                    <tr>
                      {columns?.map((el, index) => (
                        <th
                          key={`schema_col_${index}`}
                          className='border-r border-b border-solid border-[rgba(225,225,229,0.6)] p-10 text-12px text-font_1 font-normal text-left'
                        >
                          {el}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows?.map((r, rIndex) => (
                      <tr key={`schema_row_${rIndex}`}>
                        {Object.values(r).map((value, index) => (
                          <td
                            key={`schema_row_value_${index}`}
                            className='border-r border-b border-solid border-[rgba(225,225,229,0.6)] p-10 text-12px text-font font-medium'
                          >
                            <span className='line-clamp-2' title={value}>
                              {value}
                            </span>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : displayType === 'JSON' ? (
                <JsonView
                  src={value}
                  name={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  displayArrayKey={false}
                />
              ) : null}
            </div>
            {rows ? (
              <button
                className='absolute top-8 right-8 text-16px text-bg_3 text-op-60 p-4 rounded-6px bg-white shadow-[0px_0px_4px_0px_rgba(0,0,0,0.1)] flex justify-center items-center'
                onClick={() => {
                  copy(JSON.stringify(value, undefined, 2))
                  message.success('复制成功')
                }}
              >
                <IconFont name='copy' />
              </button>
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}

export const ExecuteResult = React.memo(InternalExecuteResult)
