import type { InputRef } from 'antd'
import { Input } from 'antd'
import type { ChangeEvent, KeyboardEvent } from 'react'
import { memo, useEffect, useMemo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import type { CellRenderProps } from './type'

export const InputRender = memo((props: CellRenderProps<string>) => {
  const { className, value = '', cell, head, onChange } = props

  const wrapRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const handleChange = useMemoizedFn(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const sureValue = (event.target.value || '').replaceAll('\n', ' ')
      onChange(sureValue, [cell.key, head.key])
    },
  )

  const handleClick = useMemoizedFn(() => {
    inputRef.current?.focus()
  })

  const handleKeyDown = useMemoizedFn((event: KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.preventDefault()
    }
  })

  useEffect(() => {
    if (!head.autoFocus || !cell.autoFocus || value) return
    wrapRef.current?.click()
    inputRef.current?.focus()
    delete cell.autoFocus
  }, [head.autoFocus, cell.autoFocus, head.key, cell.key])

  const hasError = useMemo(() => {
    if (!head.validate || !head.require) return false
    return !value
  }, [head.validate, value, head.key])

  return (
    <div
      ref={wrapRef}
      className={classNames(className, 'test-table-cell cursor-text', {
        'test-table-cell-error': hasError,
      })}
      onClick={handleClick}
    >
      <Input.TextArea
        ref={inputRef}
        style={{ height: '100%', wordBreak: 'break-all' }}
        className='test-table-input'
        variant='borderless'
        value={value}
        placeholder={head.placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={head.disabled}
      />
    </div>
  )
})
