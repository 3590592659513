import type { PropsWithChildren } from 'react'
import cn from 'classnames'
import RocketImage from '@/assets/app/rocket2.png'

interface EmptyProps {
  className?: string
  desc: string
}

export default ({
  className,
  desc,
  children,
}: PropsWithChildren<EmptyProps>) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-16px mt-106 items-center text-14px w-100% h-100%',
        className,
      )}
      style={{ color: 'rgba(98, 105, 153, 0.48)' }}
    >
      <img className='w-110px' src={RocketImage} alt='not-assess-image' />
      <div>{desc}</div>
      {children}
    </div>
  )
}
