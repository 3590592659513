import { FLOW_DISPLAYNAME, PLUGIN_DISPLAYNAME } from '@/constants/common'
import type { AgentSkillType } from '@/features/agent/constant'

export interface SkillBaseInfo {
  id: string
  name: string
  description: string
  icon?: string
  color?: string
  type: AgentSkillType
  disabled?: boolean
  is_new?: boolean
}

export interface ApplicationBaseInfo {
  icon: string
  color: string
  name: string
  id: string
  flowId: string
}

export type SkillType = 'Plugin' | 'Flow'

export const activeSkillTypeDisplayMap = {
  Plugin: PLUGIN_DISPLAYNAME,
  Flow: FLOW_DISPLAYNAME,
}

export const PluginListRouteAction = {
  OpenAddPluginModal: 'OpenAddPluginModal',
  ShowTipsData: 'ShowTipsData',
}
