import type { InputRef } from 'antd'
import { Input } from 'antd'
import type { ChangeEvent } from 'react'
import { memo, useEffect, useMemo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import type { CellRenderProps } from './type'

export const AreaRender = memo((props: CellRenderProps<string>) => {
  const { className, value = '', cell, head, onChange } = props

  const wrapRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const handleChange = useMemoizedFn(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value, [cell.key, head.key])
    },
  )

  const handleClick = useMemoizedFn(() => {
    inputRef.current?.focus()
  })

  useEffect(() => {
    if (!head.autoFocus || !cell.autoFocus || value) return
    wrapRef.current?.click()
    inputRef.current?.focus()
    delete cell.autoFocus
  }, [head.autoFocus, cell.autoFocus, head.key, cell.key])

  const hasError = useMemo(() => {
    if (!head.validate || !head.require) return false
    return !value
  }, [head.validate, value, head.key])

  const sureValue = useMemo(() => {
    if (typeof value !== 'object' || isNil(value)) return value
    return JSON.stringify(value)
  }, [value, head.key])

  return (
    <div
      ref={wrapRef}
      className={classNames(className, 'test-table-cell cursor-text', {
        'test-table-cell-error': hasError,
      })}
      onClick={handleClick}
    >
      <Input.TextArea
        ref={inputRef}
        className='test-table-area'
        variant='borderless'
        autoSize={false}
        value={sureValue}
        placeholder={head.placeholder}
        onChange={handleChange}
        readOnly={head.disabled}
      />
    </div>
  )
})
