import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
} from 'react'
import { useUserStore, useWorkspaceStore } from '@/store'
import { DataTrack } from './init'
import type { TrackMetaData } from './type'
import { sendTrack } from './service'

export interface TrackProps {
  event: string
  properties?: Record<string, any>
  children: React.ReactNode
}
export function Track(props: TrackProps) {
  const { children, event, properties } = props
  const user = useUserStore(state => state.user)
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const data = useMemo<TrackMetaData>(() => {
    return {
      uid: user?.username,
      event_id: event,
      event_name: DataTrack.trackList.find(v => v.event === event)?.eventName,
      workspace_id: workspaceId,
      custom_attributes: properties,
    }
  }, [user, workspaceId, event, properties])

  useEffect(() => {
    if (!DataTrack.trackList.find(v => v.event === event)) {
      console.warn(`未注册${event}事件， 请在track.config.ts中配置`)
    }
  }, [event])

  const Element = useMemo(() => {
    return Children.map(children, child => {
      // 确保child是一个有效的React元素
      if (isValidElement(child)) {
        // 获取原有的onClick函数
        const originalOnClick = child.props.onClick

        // 克隆child，修改onClick函数，同时保留原有的props
        return cloneElement(child, {
          ...child.props, // 保留原有的props
          onClick: (e: any) => {
            const config = DataTrack.trackList.find(v => v.event === event)
            if (config && config.trigger?.includes('click')) {
              sendTrack({ data, trigger: 'click' })
            }
            originalOnClick?.(e) // 调用原有的onClick函数
          },
        })
      }
      return child
    })
  }, [children, event, data])

  return <>{Element}</>
}

export function useTrack() {
  const user = useUserStore(state => state.user)
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const doTrack = (event: string, properties?: Record<string, any>) => {
    const data = {
      uid: user?.username,
      event_id: event,
      event_name: DataTrack.trackList.find(v => v.event === event)?.eventName,
      workspace_id: workspaceId,
      custom_attributes: properties,
    }
    const config = DataTrack.trackList.find(v => v.event === event)
    if (config && config.trigger?.includes('click')) {
      sendTrack({ data, trigger: 'click' })
    }
  }

  return {
    doTrack,
  }
}
