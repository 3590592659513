import { useState } from 'react'
import { RunTypeGroupSelect } from '@/features/analyze/components/RunTypeGroupSelect'
import { FlowRunTypeGroup } from '@/features/analyze/constants'
import { MonitorOverviewPane } from '@/features/analyze/components/MonitorOverviewPane'
import { UsageTrendPane } from '@/features/analyze/components/UsageTrendPane'
import { UsageRankPane } from '@/features/analyze/components/UsageRankPane'
import { AppStabilityPane } from '@/features/analyze/components/AppStabilityPane'
import { usePageModal } from '@/components'

interface FlowAnalyzeProps {
  flowId: string
  flowJumpId: string
}

export function FlowAnalyze(props: FlowAnalyzeProps) {
  const { flowId, flowJumpId } = props
  console.log(8899, flowId, flowJumpId)
  const [runType, setRunType] = useState<string[]>([])

  const logPageModal = usePageModal()

  const openLogPage = (value: any) => {
    console.log(value)
    let url = `/application/${flowJumpId}?tab=log&run_types=${runType.join(',')}`
    if (value.date) {
      url += `&start_time=${value.date}&end_time=${value.date}`
    }
    if (value.user_name) {
      url += `&user_name=${value.user_name}`
    }
    logPageModal.show({
      url,
    })
  }

  return (
    <div className='h-full overflow-y-auto'>
      <div className='mb-24px flex items-center'>
        <RunTypeGroupSelect
          data={FlowRunTypeGroup}
          onTypeChange={value => {
            setRunType(value)
          }}
        />
        <div className='ml-12px text-#9e9e9e'>
          除「今日用量」外，其他统计指标均不包含当日的用量数据。
        </div>
      </div>
      <MonitorOverviewPane
        flowId={flowId}
        flowType='FLOW'
        runType={runType}
        onLogJump={date => {
          openLogPage({ date })
        }}
      />
      <UsageTrendPane
        flowId={flowId}
        flowType='FLOW'
        runType={runType}
        onLogJump={date => {
          openLogPage({ date })
        }}
      />
      <UsageRankPane
        flowId={flowId}
        flowType='FLOW'
        runType={runType}
        onLogJump={user_name => {
          openLogPage({ user_name })
        }}
      />
      <AppStabilityPane
        flowId={flowId}
        flowType='FLOW'
        runType={runType}
        onLogJump={date => {
          openLogPage({ date })
        }}
      />
    </div>
  )
}
