import { useBoolean, useFocusWithin, useSize } from 'ahooks'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import type { InputRef } from 'antd'
import { Input } from 'antd'

interface NoteCommentProps {
  content?: string
  className?: string
  onSave?: (content: string) => void
}

export function NoteComment(props: NoteCommentProps) {
  const { className, content, onSave } = props

  const commentNodeRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const isFocusWithin = useFocusWithin(commentNodeRef)
  const size = useSize(commentNodeRef)

  const [
    isEdit,
    { toggle: toggleEdit, setFalse: setNoEdit, setTrue: setEdit },
  ] = useBoolean(false)

  const [comment, setComment] = useState(content)

  const [hasHover, setHover] = useState(false)

  useEffect(() => {
    if (!comment) {
      setEdit()
    }
  }, [])

  const showComment = useMemo(() => {
    return comment ? !isEdit : false
  }, [isFocusWithin, isEdit, comment])

  const wrapperStyle = useMemo(() => {
    return size?.height ? { top: -size?.height - 16 } : { opacity: 0 }
  }, [size])

  useEffect(() => {
    setTimeout(() => {
      setHover(!!size?.height)
    }, 100)
  }, [size])

  const handleSave = (value: string) => {
    setNoEdit()
    onSave?.(value)
  }

  useEffect(() => {
    if (!showComment) {
      inputRef.current?.focus({
        cursor: content ? 'end' : 'start',
      })
    }
  }, [content, showComment])

  return (
    <div
      ref={commentNodeRef}
      style={wrapperStyle}
      className={classNames(
        'nodrag nopan absolute w-full z-10 b-1 b-line b-opacity-60 p-4px bg-#EFF8FF rd-6px text-font_1 shadow-[0px_8px_16px_0px_rgba(0,0,0,0.04)]',
        className,
      )}
    >
      {showComment ? (
        <div
          className={classNames(
            'px-6px py-4px break-words rd-6px leading-20px font-400 whitespace-pre-wrap break-words',
            { 'hover:bg-#626999 hover:bg-opacity-8': hasHover },
          )}
          onClick={toggleEdit}
        >
          {comment}
        </div>
      ) : (
        <Input.TextArea
          ref={inputRef}
          className='rd-6px'
          value={comment}
          autoSize={{ minRows: 1, maxRows: 100 }}
          onChange={e => setComment(e.target.value)}
          maxLength={500}
          placeholder='请输入节点注释'
          onBlur={e => handleSave(e.target.value)}
        />
      )}
    </div>
  )
}
