interface NodeOptionProps {
  name: string
  description: string
  icon: string
  onSelect: (id: string) => void
  id: string
}

export default function PluginOption(props: NodeOptionProps) {
  const { name, description, onSelect, icon, id } = props

  return (
    <div
      className='flex h-54px m-b-4 p-8 b-rd-6px hover-bg-#626999 hover-bg-op-8 nodrag nopan'
      onClick={() => onSelect(id)}
    >
      <img
        className='m-r-8 w-24px h-24px flex flex-items-center flex-justify-center b-rd-4px'
        src={icon}
        alt=''
      />
      <div>
        <div className='m-b-4px line-height-18px font-500 c-#17171d'>
          {name}
        </div>
        <div className='line-height-16px text-12px c-font_1 font-400 truncate max-w-340px'>
          {description}
        </div>
      </div>
    </div>
  )
}
