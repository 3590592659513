import { ResponsiveCardGrid } from '@/components/list/ResponsiveCardGrid.tsx'
import { Card, type ICardItem } from './Card'

interface IGroupCardProps {
  title: string
  list: ICardItem[]
}

export function GroupCard(props: IGroupCardProps) {
  const { title, list } = props

  return (
    <div className='flex flex-col py-20px'>
      <div className='flex items-center'>
        <p className='text-16px font-600 c-#17171D mr-14px'>{title}</p>
      </div>
      <div className='mt-20px'>
        <ResponsiveCardGrid className='flex flex-wrap gap-24px'>
          {(list ?? []).map((item: ICardItem, idx: number) => {
            return (
              <Card
                checkInfo={item.checkInfo}
                action={item.action}
                key={idx}
                title={item.title}
                description={item.description}
                avatar={item.avatar}
              />
            )
          })}
        </ResponsiveCardGrid>
      </div>
    </div>
  )
}
