import { useMemo, type FC, type PropsWithChildren } from 'react'
import { useAuth, Access } from '@bty/react-auth'
import { useNavigate } from 'react-router-dom'
import RocketImage from '@/assets/app/rocket.png'
import { RoleCode } from '@/apis/authority.ts'
import { Button } from '@/components'
import { useWorkspaceStore } from '@/store'
import { FLOW_DISPLAYNAME } from '@/constants/common'

interface AppRunAccessGuardProps {
  isPublished: boolean
  isEnable: boolean
  appId: string
  flowId: string
}

const AppRunAccessGuard: FC<
  PropsWithChildren<AppRunAccessGuardProps>
> = props => {
  const { isPublished, isEnable, appId, flowId } = props
  const navigate = useNavigate()
  const allowAccess = isPublished && isEnable
  const { access, state: roleInfo } = useAuth()

  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  // const reasonText = !isPublished ? '发布' : !isEnable ? '启用' : ''
  // const actionText = !isPublished ? `${reasonText}应用后` : !isEnable ? `${reasonText}应用后` : ''
  // const prefixText = roleInfo?.role === RoleCode.VIEWER ? `应用尚未${reasonText}，请联系管理员` : `应用尚未${reasonText}，`

  const text = useMemo(() => {
    if (!isPublished && roleInfo?.role === RoleCode.VIEWER) {
      return `${FLOW_DISPLAYNAME}尚未发布，请联系管理员发布后使用`
    }
    if (!isPublished && roleInfo?.role !== RoleCode.VIEWER) {
      return `${FLOW_DISPLAYNAME}尚未发布，请在发布后使用`
    }
    if (!isEnable && roleInfo?.role === RoleCode.VIEWER) {
      return 'Flow已停用，请联系管理员开启后使用'
    }
    if (!isEnable && roleInfo?.role !== RoleCode.VIEWER) {
      return 'Flow已停用，请开启后使用'
    }
    return ''
  }, [isPublished, isEnable, roleInfo?.role])

  if (allowAccess) {
    return <>{props.children}</>
  } else {
    return (
      <div className='flex-1 flex-col flex-center rounded-12px bg-white h-100%'>
        <img className='w-124px' src={RocketImage} alt='not-assess-image' />
        {/* <p className="c-bg_3 mt-12 c-op-40">{prefixText}{actionText}使用</p> */}
        <p className='c-bg_3 mt-12 c-op-40'>{text}</p>
        {!isPublished && (
          <Access
            hide
            access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
          >
            <Button
              size='large'
              type='primary'
              className='mt-20 important:px-35'
              onClick={() =>
                navigate(`/application/${workspaceId}/${appId}/flow/${flowId}`)
              }
            >
              去发布
            </Button>
          </Access>
        )}
      </div>
    )
  }
}

export default AppRunAccessGuard
