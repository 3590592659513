import { useMemo } from 'react'
import type { EdgeProps } from 'reactflow'
import { BaseEdge, Position, getSmoothStepPath } from 'reactflow'
import { useFlowInteractionStore } from '@/store'

export interface HelpEdgeData {
  bottomPosY: number
  nodeHeight: number
  nodeWidth: number
}

export function HelpEdge(props: EdgeProps<HelpEdgeData>) {
  // interactionWidth默认置为0用于禁止虚线交互
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
    interactionWidth = 0,
  } = props
  const { dangerStateEdgeIds } = useFlowInteractionStore()
  const isDanger = useMemo(
    () => dangerStateEdgeIds.includes(id),
    [id, dangerStateEdgeIds],
  )

  const offset = useMemo(() => {
    return typeof data?.bottomPosY === 'number' ? data.bottomPosY - sourceY : 40
  }, [data?.bottomPosY, sourceY])
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition: Position.Bottom,
    targetX: targetX + 9 + 4 + (data?.nodeWidth || 0) / 2,
    targetY: targetY + 2 + (data?.nodeHeight || 0),
    targetPosition: Position.Bottom,
    offset,
  })

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          strokeDasharray: '3,3',
          stroke: isDanger ? '#ff5219' : undefined,
          pointerEvents: 'none',
        }}
        interactionWidth={interactionWidth}
      />
    </>
  )
}
