import { Form } from 'antd'
import { useBoolean, useRequest, useThrottleFn } from 'ahooks'
import { useState } from 'react'
import styled from '@emotion/styled'
import { InputPassword, IconFont, Input } from '@/components'
import { doLogin } from '@/apis/user'
import { tokenStorage, useIdStorage } from '@/utils/storage'
import { useUserStore, useWorkspaceStore } from '@/store'
import { PRIVACY_URL, SERVICE_URL } from '@/constants/url'
import { PageLink } from '@/components/pageModal/PageLink'
import { PRODUCT_BASE_NAME } from '@/constants/common.ts'
import { mobileRules } from './Register'
import { LoginButton, StyledCheckbox } from './components/LoginButton'
import type { ModalType } from './LoginModal'

const StyledInputPassword = styled(InputPassword)`
  .ant-input-password-icon {
    color: ${props => props.theme.colors.font_1};
  }
`

// const styled
export interface LoginProps {
  onLoginSuccess?: () => void
  onChangType?: (type: ModalType) => void
}

export function Login(props: LoginProps) {
  const { onLoginSuccess, onChangType } = props

  const [checked, { toggle: toggleChecked }] = useBoolean(false)
  const [fieldValues, setFieldValues] = useState({ phone: '', password: '' })

  const { fetchWorkspaceList } = useWorkspaceStore()
  const fetchUser = useUserStore(state => state.fetchUser)
  const [form] = Form.useForm()

  const { runAsync: doLoginApi, loading } = useRequest(doLogin, {
    manual: true,
  })

  const { run: handleLogin } = useThrottleFn(
    async () => {
      const values = await form.validateFields()
      const res = await doLoginApi(values)

      if (res?.accessToken) {
        tokenStorage.set(res.accessToken)
        useIdStorage.set(String(res.userId))

        const [workspaceList] = await Promise.all([
          fetchWorkspaceList(),
          fetchUser(),
        ])

        if (!workspaceList.length) {
          onChangType?.('workspace')
        } else {
          onLoginSuccess?.()
        }
      }
    },
    { wait: 500 },
  )

  const handleChangeUserName = (e: any) => {
    // 禁止空格
    form.setFieldValue('phone', e.target.value.replace(/\s/g, ''))
  }

  return (
    <div className='w-446px relative overflow-hidden px-48 py-60'>
      <div className='w-208px h-132px absolute left-0px top--30px bg-#00FFDD bg-opacity-20 opacity-60 rounded-104px/66px blur-80px'></div>
      <div className='w-259px h-170px absolute right--30px top--50px bg-#A200FF bg-opacity-20 opacity-60 rounded-104px/66px blur-100px'></div>
      <h3 className='text-font font-800 text-24px'>
        欢迎登录{PRODUCT_BASE_NAME}账号<span className='text-26px'> 👏</span>
      </h3>
      <p className='text-font_1 text-14px mt-16'>
        还没有账号？
        <span
          className='text-primary cursor-pointer'
          onClick={() => onChangType?.('register')}
        >
          立即注册
        </span>
      </p>

      <div className='mt-60'>
        <Form
          initialValues={fieldValues}
          form={form}
          onValuesChange={(_, values) => setFieldValues(values)}
        >
          <Form.Item name='phone' rules={mobileRules}>
            <Input
              size='large'
              type='text'
              placeholder='输入账号'
              maxLength={11}
              onChange={handleChangeUserName}
            ></Input>
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <StyledInputPassword
              size='large'
              type='password'
              placeholder='输入密码'
              iconRender={visible =>
                visible ? (
                  <IconFont className='text-font_1' name='eye-show' />
                ) : (
                  <IconFont className='text-font_1' name='eye-hide' />
                )
              }
            ></StyledInputPassword>
          </Form.Item>
        </Form>

        <div className='mb-10 mt-60'>
          <StyledCheckbox checked={checked} onChange={toggleChecked}>
            <p className='text-font_1 text-12px'>
              我已阅读并同意{' '}
              <PageLink href={SERVICE_URL} className='text-primary'>
                服务协议
              </PageLink>{' '}
              和{' '}
              <PageLink href={PRIVACY_URL} className='text-primary'>
                用户隐私协议
              </PageLink>
            </p>
          </StyledCheckbox>
        </div>
        <LoginButton
          type='primary'
          block
          size='large'
          disabled={!checked}
          loading={loading}
          onClick={handleLogin}
        >
          登录
        </LoginButton>
      </div>
    </div>
  )
}
