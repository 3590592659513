import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import type { LogItem } from '@/apis/run'

interface FlowLogStore {
  currentFlowId: string | null
  currentLog: LogItem | null
  setCurrentLog: (logIten: LogItem) => void
  setCurrentFlowId: (id: string) => void
  currentTaskId: string | null
  setCurrentTaskId: (id: string) => void
}

export const useFlowLogStore = create<FlowLogStore>(set => ({
  currentFlowId: null,
  currentLog: null,
  setCurrentLog: log => {
    set({
      currentLog: log,
    })
  },
  setCurrentFlowId: id => {
    console.log('setCurrentFlowId', id)
    set({
      currentFlowId: id,
    })
  },
  currentTaskId: null,
  setCurrentTaskId: id => {
    set({
      currentTaskId: id,
    })
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('flowLogStore', useFlowLogStore)
}
