import { useMemo, useRef, useEffect, useState } from 'react'
import { Switch, Form, Tooltip, Alert } from 'antd'
import { useBoolean, useRequest } from 'ahooks'
import classNames from 'classnames'
import { pick } from 'lodash-es'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'
import type { ActionTypesForNode } from '@/apis/flow'
import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm, ShowMore } from '@/features/nodes/components'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { MemoryTagSelect } from '@/features/nodes/memory/MemoryTagSelect.tsx'
import { MemoryOutputTypeSelect } from '@/features/nodes/memory/MemoryOutputTypeSelect.tsx'
import { OutputTypes } from '@/apis/datastore/model.ts'
import { VariableRegex } from '@/constants/common.ts'
import { IconFont, SliderSingle, Segmented } from '@/components'
import { DocumentSelect } from '../insert-memory/DocumentSelect'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { ShowKnowledgeReferenceComponent } from '@/features/agent/components/ShowKnowledgeReferenceComponent'
import { TextEditor } from '@/features/editor/TextEditor'
import { KnowledgeContentExample } from '../components/KnowledageContentExample'
import { PartitionCategoryType } from '@/apis/datastore/types'
import { CodeEditor } from '@/features/editor/CodeEditor'
import { DatasetFileSelect } from '../components/DatasetSelect/DatasetFileSelect'
import { SearchContentSelect } from './components/SearchContentSelect'
import { SearchRangeSelect } from './components/SearchRangeSelect'

export const KnowledgePackageName = 'integration.knowledge.KnowledgeAction'

let time: any = null

export interface KnowledgeNodeData {
  name: string
  packageName: string
  isSelectAllTags?: boolean
  inputs?: KnowledgeDataValue
  actionType?: ActionTypesForNode.INTEGRATION
}

export enum searchScopeType {
  All = 'All',
  Tags = 'Tags',
  Ai = 'Ai',
  specifyFileIds = 'specifyFileIds',
}

export interface KnowledgeDataValue {
  maxResultNum?: number
  searchContent?: string
  memory?: number
  minSimilar?: number
  tags?: string[]
  outputType: (typeof OutputTypes)[keyof typeof OutputTypes]
  memoryType: 'insertMemory' | 'searchMemory'
  content: string
  hitStrategy: number
  rankingStrategy: number
  file_id?: string
  file_name?: string
  show_knowledge_url?: boolean
  similarity?: number
  searchScope?: searchScopeType
  fileIds?: string[]
  ai_prompt?: string
  max_files_num: number
  group_files_num: number
}

export const KnowledgeNode: NodeComponent<KnowledgeNodeData> = props => {
  const datasetList = useNodeMetaStore(state => state.datasetList)
  const datasetTagsMap = useNodeMetaStore(state => state.datasetTagsMap)
  const getDatasetTags = useNodeMetaStore(state => state.getDatasetTags)
  const selectPopupRef = useRef<HTMLDivElement>(null)
  const [form] = Form.useForm()
  const lockStatus = useFlowDraftStore(s => s.lockStatus)
  const [dataState, setDataState] = useState(props.data)

  // 兼容历史逻辑～骚操作先处理一下
  useEffect(() => {
    setDataState({ ...dataState, name: props.data.name })
  }, [props.data?.name])

  const [operationType, setOperationType] = useState<'create' | 'update'>(
    dataState.inputs?.file_name ? 'create' : 'update',
  )

  const [expanded, { toggle: toggleExpanded }] = useBoolean(false)

  const fetchDatasetTags = async () => {
    if (dataState.inputs?.memory) {
      if (!VariableRegex.test(String(dataState.inputs?.memory))) {
        getDatasetTags(dataState.inputs.memory, true)
      }
    }
    return true
  }

  useRequest(fetchDatasetTags, { refreshOnWindowFocus: true })

  const handleFormChange = (params: Record<string, any>) => {
    const formData = form.getFieldsValue()
    const data: KnowledgeNodeData = {
      ...formData,
      ...dataState,
      inputs: {
        ...formData.inputs,
        ...dataState.inputs,
        ...params,
      },
    }
    setDataState(data)
    if (time) clearTimeout(time)
    time = setTimeout(() => {
      const inputs = data.inputs as KnowledgeDataValue
      if (inputs.memoryType === 'insertMemory') {
        data.inputs = (
          operationType === 'create'
            ? pick(inputs, ['memoryType', 'memory', 'file_name', 'content'])
            : pick(inputs, ['memoryType', 'memory', 'file_id', 'content'])
        ) as KnowledgeDataValue
      } else {
        data.inputs = pick(inputs, [
          'memoryType',
          'memory',
          'tags',
          'hitStrategy',
          'searchContent',
          'maxResultNum',
          'rankingStrategy',
          'searchScope',
          'similarity',
          'show_knowledge_url',
          'outputType',
          'max_files_num',
          'group_files_num',
          'fileIds',
          'ai_prompt',
        ]) as KnowledgeDataValue
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      data.inputs.partition_id = data.inputs.memory
      props.onSaveChange(data)
    }, 500)
  }

  const handleFilterTags = (
    tags?: Array<{ tag: string; file_num: number }>,
  ) => {
    const inputs = dataState.inputs
    if (
      inputs?.memory &&
      inputs.tags?.length &&
      inputs?.searchScope === searchScopeType.Tags
    ) {
      const datasetTags = tags || datasetTagsMap?.[inputs.memory] || []
      const newTags = inputs.tags.filter(
        tag =>
          VariableRegex.test(tag) ||
          datasetTags.findIndex(i => i.tag === tag) !== -1,
      )
      return newTags
    }
  }

  useEffect(() => {
    const inputs = dataState.inputs
    if (
      inputs?.memory &&
      inputs.tags?.length &&
      datasetTagsMap?.[inputs.memory]
    ) {
      const newTags = handleFilterTags()
      setDataState({
        ...dataState,
        inputs: {
          ...dataState.inputs,
          tags: newTags,
        } as KnowledgeDataValue,
      })
    }
  }, [datasetTagsMap])

  useEffect(() => {
    return () => time && clearTimeout(time)
  }, [])

  const isSelectedQADataSet = useMemo(() => {
    if (
      dataState.inputs?.memoryType === 'insertMemory' &&
      dataState.inputs?.memory
    ) {
      const dataset = datasetList.find(
        item => item.partition_id === dataState.inputs?.memory,
      )
      if (dataset?.partition_category === PartitionCategoryType.QA) {
        return true
      }
    }
    return false
  }, [datasetList, dataState])

  const operationTypeFormItem = {
    render: () => (
      <Segmented
        value={operationType}
        options={[
          {
            label: (
              <Tooltip
                title='将数据内容更新在某个文件中'
                getPopupContainer={() =>
                  selectPopupRef.current ?? document.body
                }
                destroyTooltipOnHide
              >
                更新文件
              </Tooltip>
            ),
            value: 'update',
          },
          {
            label: (
              <Tooltip
                title='根据文件名、数据内容新建文件'
                getPopupContainer={() =>
                  selectPopupRef.current ?? document.body
                }
                destroyTooltipOnHide
              >
                新建文件
              </Tooltip>
            ),
            value: 'create',
          },
        ]}
        size='small'
        onChange={value => {
          setOperationType(value as 'create' | 'update')
          let formValue
          if (value === 'create') {
            formValue = { file_id: null }
          } else {
            formValue = { file_name: null }
          }
          handleFormChange(formValue)
        }}
      />
    ),
  }

  const inputFileNameFormItem = {
    label: '文件名称',
    name: ['inputs', 'file_name'],
    required: true,
    rules: [{ required: true, message: '请输入文件名称' }],
    render: () => (
      <CodeEditor
        className='ace-gray h-32px flex-1'
        wrapEnabled={false}
        width='100%'
        setOptions={{ maxLines: 1 }}
        variableTipsContainer={props.nodeElement}
        variables={props.variables}
        placeholder='输入新增文件的名称，可通过变量设置'
        singleLine
        onChange={value => handleFormChange({ file_name: value })}
      />
    ),
  }

  const selectFileNameFormItem = {
    label: '数据文件',
    name: ['inputs', 'file_id'],
    required: true,
    hidden: !form.getFieldValue(['inputs', 'memory']),
    rules: [{ required: true, message: '请选择数据文件' }],
    render: () => (
      <DocumentSelect
        memoryId={dataState.inputs?.memory}
        onChange={e => handleFormChange({ file_id: e })}
      />
    ),
  }

  const fileContentFormItem = {
    label: '数据内容',
    name: ['inputs', 'content'],
    required: true,
    type: 'TextEditor' as const,
    rules: [{ required: true, message: '内容不能为空' }],
    tooltip: '文档库仅支持纯文本数据；问答库仅支持 JSON 格式',
    className: '!mb-0',
    componentProps: {
      placeholder: isSelectedQADataSet
        ? '填写或用变量输入 QA 问答的 JSON 数据'
        : '输入上下文的变量或纯文本数据，并将变量的数据或者纯文本数据导入知识库中',
      variables: props.variables,
      variableTipsContainer: props.nodeElement,
      onChange: (e: string) => {
        handleFormChange({ content: e })
      },
      innerTooltipContent: isSelectedQADataSet && <KnowledgeContentExample />,
    },
  }

  const searchListForm = [
    {
      noStyle: true,
      render: () => {
        return (
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.inputs.searchContent !== curr.inputs.searchContent ||
              prev.inputs.hitStrategy !== curr.inputs.hitStrategy
            }
          >
            {({ getFieldError }) => {
              const errorContent = getFieldError(['inputs', 'searchContent'])
              return (
                <>
                  <div className='flex justify-between items-center mb-8px'>
                    <div className='font-500 text-12px flex items-center'>
                      查询内容
                      <span className='c-#ff5219 text-18px ml-5px block h-12px'>
                        *
                      </span>
                    </div>
                    <SearchContentSelect
                      suffixIcon={
                        <IconFont
                          name='arrow'
                          className='text-7px !text-font_1'
                        />
                      }
                      getPopupContainer={triggerNode =>
                        triggerNode.parentNode as HTMLElement
                      }
                      virtual={false}
                      dropdownStyle={{ minWidth: '102px' }}
                      popupMatchSelectWidth={false}
                      variant='borderless'
                      size='small'
                      className='nodrag nopan'
                      value={dataState.inputs?.hitStrategy || 1}
                      onChange={value =>
                        handleFormChange({ hitStrategy: value })
                      }
                      disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                      options={[
                        {
                          label: (
                            <div className='flex gap-4px text-12px items-center justify-center line-height-16px'>
                              混合查询
                              <span className='c-#FF8056 py-2px text-9px font-500 px-4px bg-#FFECE6 border-rd-100px'>
                                推荐
                              </span>
                            </div>
                          ),
                          value: 1,
                        },
                        {
                          label: (
                            <div className='text-12px line-height-16px'>
                              语义查询
                            </div>
                          ),
                          value: 3,
                        },
                        {
                          label: (
                            <div className='text-12px line-height-16px'>
                              关键词查询
                            </div>
                          ),
                          value: 2,
                        },
                      ]}
                    />
                  </div>
                  <TextEditor
                    placeholder={
                      [
                        '',
                        `输入变量或文本，从知识库中通过语义和关键词进行查询，并综合排序
，效果最好
`,
                        `输入变量或文本，基于关键词进行查询，并综合排序，推荐在查询特定
名词、缩写词、短语、ID时使用
`,
                        `输入变量或文本，基于语义相关度进行查询，并综合排序，推荐在需要
理解语义相关度或跨语言查询时使用
`,
                      ]?.[dataState.inputs?.hitStrategy || 1]
                    }
                    focusScroll
                    minHeight={120}
                    maxHeight={480}
                    aria-invalid={errorContent.length > 0 ? 'true' : 'false'}
                    className='text-12px'
                    disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                    variables={props.variables}
                    variableTipsContainer={props.nodeElement}
                    value={dataState.inputs?.searchContent}
                    onChange={e => handleFormChange({ searchContent: e })}
                  />
                  <Form.Item
                    className='[&_.ant-form-item-control-input]:h-0 [&_.ant-form-item-control-input]:min-h-0'
                    name={['inputs', 'searchContent']}
                    style={{ marginBottom: 16, paddingBottom: 5 }}
                    rules={[{ required: true, message: '查询内容不能为空' }]}
                  />
                </>
              )
            }}
          </Form.Item>
        )
      },
    },
    {
      label: (
        <div className='font-500 text-12px flex items-center'>
          查询范围
          <span
            className='text-8px rounded-8px color-#fff ml-4px flex items-center justify-center h-14px w-26px'
            style={{
              background: 'linear-gradient(270deg, #FF3A5A 0%, #FF69BB 100%)',
            }}
          >
            new
          </span>
        </div>
      ),
      render: () => {
        const searchScopeValue =
          dataState.inputs?.searchScope ||
          (dataState.inputs?.tags && dataState.inputs?.tags?.length > 0
            ? searchScopeType.Tags
            : searchScopeType.All)
        return (
          <SearchRangeSelect
            value={searchScopeValue}
            onChange={value => {
              handleFormChange({ searchScope: value })
            }}
            memoryId={dataState.inputs?.memory}
            handleFormChange={handleFormChange}
            popupContainerRef={selectPopupRef}
            aiPromptValue={dataState.inputs?.ai_prompt || ''}
          />
        )
      },
    },
    {
      noStyle: true,
      hidden: !(
        dataState.inputs?.memory &&
        dataState.inputs?.searchScope === searchScopeType.Tags
      ),
      render: () => (
        <>
          <div className='flex items-center text-12px font-500 mb-12'>
            <div className='flex items-center'>选择标签</div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.inputs.memory !== curr.inputs.memory
            }
          >
            {({ getFieldValue }) => {
              const memory = getFieldValue(['inputs', 'memory'])
              const tags = datasetTagsMap[memory] ?? []
              return (
                <div className='mb-16px'>
                  <MemoryTagSelect
                    tags={tags}
                    allEnable={true}
                    disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                    onChange={e => {
                      const res = e
                      form.setFieldValue(
                        ['inputs', 'tags'],
                        !e?.length ? null : e,
                      )
                      handleFormChange({ tags: res })
                    }}
                    value={dataState.inputs?.tags || []}
                    variables={props.variables}
                    variableTipsContainer={props.nodeElement as HTMLElement}
                  />
                </div>
              )
            }}
          </Form.Item>
        </>
      ),
    },
    {
      label: '选择文件',
      name: ['inputs', 'fileIds'],
      required: true,
      hidden: !(
        dataState.inputs?.memory &&
        dataState.inputs?.searchScope === searchScopeType.specifyFileIds
      ),
      rules:
        dataState.inputs?.memory &&
        dataState.inputs?.searchScope === searchScopeType.specifyFileIds
          ? [{ required: true, message: '请选择数据文件' }]
          : [],
      render: () => {
        return (
          <DatasetFileSelect
            disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
            memoryId={dataState.inputs?.memory}
            onChange={e => handleFormChange({ fileIds: e })}
            variables={props.variables}
            variableTipsContainer={props.nodeElement as HTMLElement}
          />
        )
      },
    },
    {
      label: '最大结果数',
      name: ['inputs', 'maxResultNum'],
      tooltip:
        '从知识库中查询 X 条结果（ X 等于你设置的数字，X 的最大值为30） 建议 X 值不超过 10，避免超出大模型支持的最大 Token 数量',
      render: () => (
        <div className='flex flex-col justify-between'>
          <SliderSingle
            min={1}
            max={30}
            size='small'
            step={1}
            controls={false}
            value={dataState.inputs?.maxResultNum}
            onChange={e => handleFormChange({ maxResultNum: Number(e) })}
          />
          {!!dataState?.inputs?.maxResultNum &&
            dataState?.inputs?.maxResultNum > 10 && (
              <Alert
                className='mt-8px b-0px text-12px'
                showIcon
                message={
                  <p className='font-400 text-12px/16px c-#17171D'>
                    建议最大结果数不超过10，避免超出大模型支持的最大Token数
                  </p>
                }
                type='warning'
              />
            )}
        </div>
      ),
    },
    {
      noStyle: true,
      hidden: !expanded,
      render: () => (
        <div className='flex items-center mb-16px'>
          <div className='font-500 text-12px'>结果重排</div>
          <Tooltip title='可将查询结果精准排序，提高匹配率，但耗时较长'>
            <IconFont
              className='ml-4px mr-8px'
              style={{ color: 'rgba(141, 141, 153, 0.4)' }}
              name='jieshishuimeng'
            />
          </Tooltip>
          <Switch
            size='small'
            checked={dataState.inputs?.rankingStrategy === 1}
            onChange={e => handleFormChange({ rankingStrategy: e ? 1 : 2 })}
          />
        </div>
      ),
    },
    {
      label: '最低相似度',
      hidden: !(dataState.inputs?.rankingStrategy === 1) || !expanded,
      name: ['inputs', 'similarity'],
      tooltip:
        '设定的一个阈值，过滤掉相似度低于此值的匹配结果，一般0.4属于适中，0.5以上属于精确。',
      render: () => (
        <div className='flex flex-col justify-between'>
          <SliderSingle
            size='small'
            min={0}
            max={1}
            step={0.01}
            maxLength={4}
            controls={false}
            value={
              // 如果老数据中没有similarity字段，则默认值为0.4
              dataState.inputs &&
              !Object.prototype.hasOwnProperty.call(
                dataState.inputs,
                'similarity',
              )
                ? 0.4
                : dataState.inputs?.similarity
            }
            onChange={e => handleFormChange({ similarity: Number(e) })}
          />
        </div>
      ),
    },
    {
      noStyle: true,
      hidden: !expanded,
      render: () => (
        <div className='flex flex-col mb-16px'>
          <div className='flex flex-1 items-center'>
            <div className='font-500 text-12px'>展示知识原文下载链接</div>
            <Tooltip title='开启后，Flow 的使用者可以通过日志中的链接下载原文件。建议保持关闭状态，以避免知识泄漏风险。'>
              <IconFont
                className='ml-[4px] mr-8px'
                style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                name='jieshishuimeng'
              />
            </Tooltip>
            <Switch
              size='small'
              checked={dataState.inputs?.show_knowledge_url}
              onChange={e => handleFormChange({ show_knowledge_url: e })}
            />
          </div>
          {!!dataState.inputs?.show_knowledge_url && (
            <ShowKnowledgeReferenceComponent business='flow' />
          )}
        </div>
      ),
    },
    {
      label: '输出设置',
      required: true,
      hidden: !expanded,
      className: 'important:mb-0',
      name: ['inputs', 'outputType'],
      render: () => (
        <MemoryOutputTypeSelect
          onChange={e => handleFormChange({ outputType: e })}
        />
      ),
    },
  ]

  const list = useMemo<JsonFormConfig[]>(() => {
    const returnValue = [
      {
        noStyle: true,
        render: () => (
          <>
            <div className='font-500 text-12px mb-8px'>类型</div>
            <Segmented
              block
              size='small'
              style={{ padding: 2 }}
              disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
              options={[
                { label: '查询知识库', value: 'searchMemory' },
                { label: '插入知识库', value: 'insertMemory' },
              ]}
              className='mb-16px'
              value={dataState.inputs?.memoryType}
              onChange={e => {
                handleFormChange({
                  ...KnowledgeNode.meta.initialData?.inputs,
                  memoryType: e,
                  memory: undefined,
                  file_id: undefined,
                  fileIds: [],
                  tags: [],
                })
                form.setFields([
                  { name: ['inputs', 'memory'], value: undefined },
                  { name: ['inputs', 'file_id'], value: undefined },
                  { name: ['inputs', 'fileIds'], value: [] },
                  { name: ['inputs', 'tags'], value: [] },
                ])
              }}
            />
            <div
              className='h-1px w-[calc(100%+32px)] mb-16px'
              style={{
                transform: 'translate(-16px, 0)',
                background: 'rgba(225, 225, 229, 0.6)',
              }}
            />
          </>
        ),
      },
      {
        label: '知识库',
        name: ['inputs', 'memory'],
        required: true,
        rules: [
          {
            required: true,
            message:
              dataState.inputs?.memoryType === 'insertMemory'
                ? '请选择知识库'
                : '请选择知识库或输入表示知识库ID的变量',
          },
        ],
        type: 'DatasetSelect',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        componentProps: {
          variables:
            dataState.inputs?.memoryType === 'insertMemory'
              ? []
              : props.variables,
          variableTipsContainer: props.nodeElement,
          onChange: (val: number) => {
            if (val && !VariableRegex.test(String(val))) {
              getDatasetTags(val, true).then(tags => {
                const newTags = handleFilterTags(tags)
                form.setFields([
                  { name: ['inputs', 'fileIds'], value: [] },
                  { name: ['inputs', 'file_id'], value: undefined },
                  { name: ['inputs', 'tags'], value: newTags },
                ])
                // 切换知识库时，清空所有的选择文件
                handleFormChange({
                  file_id: undefined,
                  fileIds: [],
                  memory: val,
                  tags: newTags,
                })
              })
            } else {
              handleFormChange({ memory: val })
            }
          },
        },
      },
    ] as JsonFormConfig[]

    if (dataState.inputs?.memoryType !== 'insertMemory') {
      returnValue.push(...searchListForm)
      return returnValue
    }

    if (dataState.inputs?.memory) {
      returnValue.push(operationTypeFormItem)
      if (operationType === 'create') {
        returnValue.push(inputFileNameFormItem, fileContentFormItem)
      } else {
        returnValue.push(selectFileNameFormItem, fileContentFormItem)
      }
    }

    return returnValue
  }, [
    datasetList,
    props.variables,
    props.nodeElement,
    dataState,
    operationType,
  ])

  const isInsert = dataState.inputs?.memoryType === 'insertMemory'

  const isSearch = dataState.inputs?.memoryType === 'searchMemory'

  return useMemo(
    () => (
      <>
        <div
          ref={selectPopupRef}
          className={classNames(
            'relative pt-16px px-16px w-420px expandable-content',
            { 'pb-16px': isInsert },
          )}
          onWheel={e => e.stopPropagation()}
        >
          <JsonForm
            form={form}
            list={list}
            disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          />
        </div>
        {isSearch && (
          <ShowMore
            text='展开更多配置'
            expandedText='收起更多配置'
            expanded={expanded}
            onClick={toggleExpanded}
          />
        )}
      </>
    ),
    [form, list, lockStatus, isInsert, isSearch, expanded],
  )
}

KnowledgeNode.meta = {
  type: NodeType.KNOWLEDGE,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'INTEGRATION',
  typeName: '知识库',
  icon: 'memory',
  description: '向知识库，查询或插入数据',
  backgroundColor: '#3898FF',
  canDelete: true,
  initialData: {
    isSelectAllTags: true,
    inputs: {
      memory: undefined,
      tags: [],
      maxResultNum: 6,
      searchContent: '',
      outputType: OutputTypes.TEXT,
      memoryType: 'searchMemory',
      content: '',
      hitStrategy: 1,
      rankingStrategy: 1,
      file_id: '',
      show_knowledge_url: false,
      similarity: 0.4,
      searchScope: searchScopeType.All,
      ai_prompt: '',
      fileIds: [],
      max_files_num: 200,
      group_files_num: 50,
    },
    name: 'knowledge_1',
    packageName: KnowledgePackageName,
  },
}
