// import { useMouse } from "ahooks";
import { useEffect, useRef } from 'react'

export function useMousePosition(initialPosition: { x: number; y: number }) {
  const { x, y } = initialPosition
  const mouseX = useRef(x || 0)
  const mouseY = useRef(y || 0)

  // const mouse = useMouse()

  // useEffect(() => {
  //   mouseX.current = mouse.clientX
  //   mouseY.current = mouse.clientY
  // }, [mouse])

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      mouseX.current = event.clientX
      mouseY.current = event.clientY
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return {
    mouseX,
    mouseY,
  }
}
