import { SkillFlowViewMode } from '@/apis/run.ts'
import SkillFormViewImage from '@/assets/agent/skill-form-inputs-view.png'

export enum AgentSkillType {
  FLOW = 'flow',
  TOOL = 'tool',
}

export const skillFlowViewSelectOptions = [
  {
    label: '直接调用',
    value: SkillFlowViewMode.RAW,
  },
  {
    label: '表单形式',
    value: SkillFlowViewMode.FORM,
    viewImage: SkillFormViewImage,
  },
]
