import { useNavigate } from 'react-router-dom'
import { useLatest } from 'ahooks'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { storageRouterMap } from '@/pages/home/contants'
import { useContactCustomerServiceModal } from '../../ContactCustomerServiceModal'
import { useLimitedAccessModal } from '../../LimitedAccess'
import { useLimitedModal } from '../../LimitedPanel'
import { getWorkspaceChangeRoutePath } from '../../../../pages/home/components/WorkspaceList'
import { useAlertInfo } from './useAlertInfo'

interface generateMarkdownLimitedInfoProps {
  prefix: string
}

export function useGenerateMarkdownLimitedInfo({
  prefix = '',
}: generateMarkdownLimitedInfoProps): [string, (url: string) => void] {
  const [levelupModal] = useLimitedModal()
  const [contactCustomerServiceModal] = useContactCustomerServiceModal()
  const navigate = useNavigate()
  const { alertInfo, botAlertInfo, currentWorkspaceName, botWorkspaceId } =
    useAlertInfo()
  const setCurrentWorkspace = useWorkspaceStore(
    state => state.setCurrentWorkspace,
  )
  const { setBotWorkspaceItem, fetchVersionByWorkspaceId, botWorkspaceItem } =
    useVersionStore(state => ({
      setBotWorkspaceItem: state.setBotWorkspaceItem,
      fetchVersionByWorkspaceId: state.fetchVersionByWorkspaceId,
      botWorkspaceItem: state.botWorkspaceItem,
    }))
  const latestBotWorkspaceItem = useLatest(botWorkspaceItem)
  // 不是同一个工作空间的 chatbot 的购买弹窗
  const [limitedAccessModal] = useLimitedAccessModal({
    title: `是否切换到空间「${botAlertInfo?.workspaceName}」并购买升级`,
    content: `你当前所处的空间是「${currentWorkspaceName}」，和要购买的空间不一致`,
    cancelBtnText: '暂不升级',
    confirmBtnText: '购买升级',
    onConfirm: () => {
      if (botWorkspaceId) {
        // 清空当前的 botworkspaceItem 信息
        setBotWorkspaceItem(undefined)
        // 切换到对应的工作空间
        setCurrentWorkspace(botWorkspaceId || '')
        fetchVersionByWorkspaceId(botWorkspaceId || '')
        Object.values(storageRouterMap).forEach(key =>
          localStorage.removeItem(key),
        )
        navigate(
          getWorkspaceChangeRoutePath(window.location.pathname, botWorkspaceId),
        )
      }
      setTimeout(() => {
        // 打开购买弹窗
        levelupModal.open()
      }, 300)
    },
  })
  const realAlertInfo =
    (latestBotWorkspaceItem.current && botAlertInfo) || alertInfo
  if (!realAlertInfo) {
    return ['Yeah积分已达上限,请联系管理员升级版本或购买加量包后使用', () => {}]
  }
  const limitedMarkdownInfo = `${prefix}空间「${
    realAlertInfo?.workspaceName || ''
  }」${
    realAlertInfo?.type === 'expired'
      ? `已经过期，请${
          realAlertInfo?.isAdmin
            ? `购买以继续使用。[购买](${
                botAlertInfo && latestBotWorkspaceItem.current
                  ? `http://show-bty-limited-action?type=levelupModal&botAlertWorkspace=${botAlertInfo.workspaceName}`
                  : 'http://show-bty-limited-action?type=levelupModal'
              })`
            : `联系管理员「${realAlertInfo?.adminName}」续费`
        }`
      : `积分已耗尽，请${
          realAlertInfo?.isAdmin
            ? '[联系客服](http://show-bty-limited-action?type=CustomerService)'
            : `请联系管理员「${realAlertInfo?.adminName}」购买积分`
        }`
  }`
  const handleLimitedModalClick = (url: string) => {
    const parsedUrl = new URL(url)
    const params = new URLSearchParams(parsedUrl.search)
    const type = params.get('type')
    const botAlertWorkspace = params.get('botAlertWorkspace')
    if (type === 'CustomerService') {
      contactCustomerServiceModal.open()
    } else if (type === 'levelupModal') {
      // 使用 lastedtBotworkspaceItem 是为了判断，切换空间之后的打开弹窗
      if (botAlertWorkspace && latestBotWorkspaceItem.current) {
        limitedAccessModal.open()
        return
      }
      levelupModal.open()
    }
  }
  return [limitedMarkdownInfo, handleLimitedModalClick]
}
