import { Radio } from 'antd'
import type { AuthAppDingTalk } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'

const [http, stream] = PUBLISH_MODE.dingtalk.split('|')

const modes = [
  {
    value: http,
    label: '非流式输出（HTTP 模式）',
    description: '首字回复等待时间稍长，但可以节省「钉钉开发资源」用量',
    gif: 'https://resource.bantouyan.com/betteryeah/agent/agentgo/dingtalk-gif-http.gif',
  },
  {
    value: stream,
    label: '流式输出（Stream 模式）',
    description: '首字回复等待时间更短，但会增加「钉钉开发资源」用量',
    gif: 'https://resource.bantouyan.com/betteryeah/agent/agentgo/dingtalk-gif-stream.gif',
  },
]

interface DingTalkMessageReceivingModeProps {
  value?: AuthAppDingTalk['auth_app_source']
  onChange?: (value: AuthAppDingTalk['auth_app_source']) => void
}

export function DingTalkMessageReceivingMode({
  value: inputValue,
  onChange,
}: DingTalkMessageReceivingModeProps) {
  return (
    <div className='flex flex-nowrap gap-8'>
      {modes.map(({ value, label, description, gif }) => (
        <div key={value} className='bg-bg_3 bg-op-6 rounded-8px flex-1'>
          <Radio
            className='flex-row-reverse pl-4 pr-12 mr-0 [&>.ant-radio]:self-start [&>.ant-radio]:mt-14'
            value={value}
            checked={inputValue === value}
            onChange={event => {
              onChange?.(event.target.value)
            }}
          >
            <span className='inline-block text-font text-14px/20px mt-12'>
              {label}
            </span>
            <span className='inline-block text-font_2 text-12px/16px mt-4'>
              {description}
            </span>
          </Radio>
          <span
            className='relative mt-16 mx-auto w-[calc(100%-48px)] pt-[calc(94%-45px)] block bg-contain bg-no-repeat'
            style={{
              backgroundImage:
                'url(https://resource.bantouyan.com/betteryeah/agent/agentgo/dingtalk-gif-bg.png)',
            }}
          >
            <img
              className='absolute top-4% left-5% w-90% h-96% object-cover rounded-t-5%'
              src={gif}
            />
          </span>
        </div>
      ))}
    </div>
  )
}
