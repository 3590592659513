import type { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { Tooltip } from 'antd'
import { Button, IconFont } from '@/components'

interface SubpageWrapProps {
  width: string
  isOpen: boolean
  title: string
  className?: string
  onBack: () => void
  onDownload?: () => void
}

export function Subpage(props: PropsWithChildren<SubpageWrapProps>) {
  const [needSetMinWidth, setNeedSetMinWidth] = useState(false)

  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => setNeedSetMinWidth(true), 200)
    } else {
      setNeedSetMinWidth(false)
    }
  }, [props.isOpen])

  return (
    <div
      className={classNames(
        'transition-all-200 bg-white ease-in-out z-2 w-0 flex flex-col overflow-hidden',
        {
          'min-w-500px': needSetMinWidth,
        },
        props.className,
      )}
      style={{ width: props.isOpen ? props.width : '0px' }}
    >
      <div className='flex flex-center-between pt-20 pb-18 px-24 relative b-b b-line b-op-60'>
        <span className='text-18px line-height-18px font-600 whitespace-nowrap'>
          {props.title}
        </span>
        <div className='absolute right-24px flex items-center'>
          {props.onDownload && (
            <Tooltip title='下载源文件'>
              <Button
                size='small'
                className='!p-0px !rounded-6px mr-20px w-32px h-32px flex justify-between items-center'
                onClick={props?.onDownload}
              >
                <IconFont name='xiazairizhi' className='text-16px flex-1' />
              </Button>
            </Tooltip>
          )}

          <span
            onClick={props.onBack}
            className='cursor-pointer flex-center rounded-4px text-16px w-24px h-24px hover:bg-bg_3 hover:bg-op-12'
          >
            <IconFont name='guanbi' />
          </span>
        </div>
      </div>
      {props.children}
    </div>
  )
}
