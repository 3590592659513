import { useRequest } from 'ahooks'
import { IconFont } from '@/components'
import { useDatabase } from '@/features/database/provider/DatabaseProvider.tsx'
import DatabaseInfo from '@/features/database/components/DatabaseInfo.tsx'
import TableTabs from '@/features/database/components/TableTabs/Tabs.tsx'
import { bulkGetDBAppIds } from '@/apis/database/api.ts'
import { getAppListByIds } from '@/apis/application.ts'

export default function Header() {
  const { onBackList, id } = useDatabase()

  const { data: relatedApplications = [] } = useRequest(
    async (id: string) => {
      const res = await bulkGetDBAppIds([id])
      const ids = res[0]?.applications ?? []
      if (ids.length) {
        return getAppListByIds(ids)
      }
      return []
    },
    {
      defaultParams: [id],
    },
  )

  return (
    <div className='h-50px w-full flex px-16 items-end shrink-0 overflow-hidden relative'>
      <div className='line b-b b-line b-op-60 absolute bottom-0 left-0 h-1px w-full z-1'></div>
      <span
        onClick={onBackList}
        className='mr-12 mb-7 shrink-0 c-op-60 w-32px h-32px hover:bg-bg_3 hover:bg-op-8 rounded-6px flex-center cursor-pointer'
      >
        <IconFont name='fanhui' className='text-16px' />
      </span>
      <DatabaseInfo relatedApplications={relatedApplications} />
      <TableTabs />
    </div>
  )
}
