import { Space } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import { Button } from '@/components'
import { Modal } from './BaseModal'

export interface ConfirmModalProps {
  open: boolean
  title?: string | ReactNode
  okText?: string
  cancelText?: string
  okButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  onOk?: () => void
  onCancel?: () => void
  afterClose?: () => void
}
export const ConfirmModal: FC<PropsWithChildren<ConfirmModalProps>> = props => {
  const {
    open,
    onOk,
    onCancel,
    afterClose,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
  } = props

  const Footer = (
    <div className='p-16 m-t-0'>
      <Space>
        <Button onClick={onCancel} {...cancelButtonProps}>
          {cancelText || '取消'}
        </Button>
        <Button onClick={onOk} danger type='primary' {...okButtonProps}>
          {okText || '确定'}
        </Button>
      </Space>
    </div>
  )

  return (
    <Modal
      closable={false}
      wrapClassName='border-r-8px'
      styles={{
        body: { padding: '24px 24px 0px' },
      }}
      open={open}
      title={null}
      footer={Footer}
      afterClose={afterClose}
    >
      {props.title || props.children}
    </Modal>
  )
}
