import './animate.css'

import { Dropdown, Modal, Tooltip, message } from 'antd'
import classNames from 'classnames'
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { RefObject } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { IconFont, AppLogo, Button, useConfirmModal } from '@/components'
import { InvitePopover } from '@/pages/workspace/settings/components/InvitePopover.tsx'
import { getRoleOptions } from '@/pages/workspace/settings/util.tsx'
import type { ApplicationBodyType } from '@/apis/application.ts'
import {
  deleteApplication,
  forkApplication,
  editApplication,
} from '@/apis/application.ts'
import { useWorkspaceSelectModal } from '@/pages/application/components/WorkSpaceSelect.tsx'
import type { RuleConfigInstance } from '@/features/agent/Rule'
import {
  TransformKey,
  useAgentEdit,
} from '@/features/agent/provider/AgentEditProvider.tsx'
import { useModal } from '@/hooks/useModal.tsx'
import { CreateModal } from '@/components/createAppModal'
import { AGENT_FROM } from '@/features/agent/constant/base'

const tabItems = [
  {
    key: 'design',
    label: '编排',
  },
  {
    key: 'log',
    label: '日志',
  },
  {
    key: 'analysis',
    label: '分析',
  },
  {
    key: 'agentgo',
    label: '发布',
  },
]

interface ProgressTextProps {
  text: string
  loading: boolean
}

function ProgressText(props: ProgressTextProps) {
  const { text, loading } = props

  const show = useRef(false)
  const interval = useRef<any>(null)

  const [innerText, setText] = useState(text)
  const [gen, setGen] = useState(false)

  const start = useMemoizedFn((toText: string) => {
    let nowText = innerText
    if (nowText === toText) return
    show.current = toText.startsWith(nowText)
    clearInterval(interval.current)
    setGen(true)
    interval.current = setInterval(() => {
      if (toText === nowText) {
        clearInterval(interval.current)
        setGen(false)
        return
      }

      if (!show.current) {
        nowText = nowText.substring(0, nowText.length - 1)
        setText(nowText)
        if (nowText.length === 0) show.current = true
        return
      }

      nowText = toText.substring(0, nowText.length + 1)
      setText(nowText)
    }, 100)
  })

  useEffect(() => {
    start(text)
  }, [text])

  return (
    <span className='relative inline-block min-h-1em'>
      {innerText}
      {(gen || loading) && (
        <span
          className='absolute top-0 bottom-0 w-2px rounded-4px right-[-4px] cursor-animate-blink'
          style={{
            backgroundImage:
              'linear-gradient(354deg, #E21D6F 18%, #D248B6 25%, #6D3CFF 59%, #7B61FF 79%, #4598FF 99%)',
            transform: 'scaleY(0.9)',
          }}
        />
      )}
    </span>
  )
}

interface Props {
  locked?: boolean
  transformMap: Record<string, string>
  ruleConfigRef: RefObject<RuleConfigInstance | null>
  applicationInfo: ApplicationBodyType
  onAgentInfoEdited: () => void
  refreshApplicationInfo: () => void
}

export function Header(props: Props) {
  const {
    locked,
    transformMap,
    ruleConfigRef,
    applicationInfo,
    onAgentInfoEdited,
    refreshApplicationInfo,
  } = props
  const navigate = useNavigate()
  const routerMatch = useMatch({ path: '/agent/:id/:tab', end: false })
  const { setOpenInvitePopover, openInvitePopover } = useAgentEdit()
  const [searchParams] = useSearchParams()
  const [confirmModal] = useConfirmModal()

  const tab = useMemo(
    () => routerMatch?.params?.tab,
    [routerMatch?.params?.tab],
  )

  const handleFork = useCallback(
    async (workspaceId: string) => {
      const res = await forkApplication({
        source_appid: applicationInfo.id,
        source_workspace_id: applicationInfo?.workspaceId || '',
        target_workspace_id: workspaceId,
      })
      window.open(
        `/agent/${res.application_id}?forkTaskId=${res.task_id}`,
        '_blank',
      )
      onForkSuccess()
    },
    [applicationInfo.workspaceId, applicationInfo.id],
  )
  const [workspaceSelectModal] = useWorkspaceSelectModal({
    onConfirm: handleFork,
    defaultWorkspaceId: applicationInfo?.workspaceId,
    showConnectComponent: true,
    copyType: '复制Agent',
    appId: applicationInfo?.id,
    checkLimit: 'app',
  })

  function onForkSuccess() {
    workspaceSelectModal.close()
  }
  const location = useLocation()

  const goBack = () => {
    const source = searchParams.get('source')
    if (location?.state?.[AGENT_FROM]) {
      navigate(-1)
    } else {
      navigate(source ?? '/agent')
    }
  }

  const onBackDetail = () => {
    if (ruleConfigRef.current?.hasSaved === false) {
      confirmModal.open({
        title: '规则尚未保存，是否需要保存？',
        cancelText: '取消并离开',
        okText: '保存并离开',
        okButtonProps: {
          danger: false,
        },
        onOk: async () => {
          const result = await ruleConfigRef.current?.onSave()
          if (result) {
            goBack()
          }
          confirmModal.close()
        },
        onCancel: () => {
          goBack()
        },
      })
    } else {
      goBack()
    }
  }

  const [agentInfoModal] = useModal(CreateModal)

  const { runAsync: updateFlowApi } = useRequest(editApplication, {
    manual: true,
  })

  const onAgentInfoEdit = useMemoizedFn(() => {
    agentInfoModal.open({
      title: '编辑Agent信息',
      fieldConfig: {
        name: { placeholder: '请输入名称' },
        description: {
          placeholder: '请输入...',
        },
      },
      logoType: 'image',
      defaultValue: applicationInfo,
      onFinish: async formData => {
        await updateFlowApi({
          id: applicationInfo.id,
          applicationType: 'AGENT',
          ...formData,
        })
        refreshApplicationInfo()
        onAgentInfoEdited()
        agentInfoModal.close()
      },
    })
  })

  const editContent = useMemo(() => {
    const temp = (
      <div
        className={classNames('flex items-center cursor-pointer duration-150', {
          'opacity-80': locked,
        })}
        onClick={!locked ? onAgentInfoEdit : undefined}
      >
        <div className='relative'>
          <div
            className='absolute rounded-full w-full h-full transition-300'
            style={{
              opacity: transformMap[TransformKey.avatar] ? 1 : 0,
              background:
                'linear-gradient(314deg, rgba(226, 29, 111, 0.6) 7%, rgba(210, 72, 182, 0.6) 13%, rgba(109, 60, 255, 0.6) 48%, rgba(123, 97, 255, 0.6) 68%, rgba(69, 152, 255, 0.6) 88%)',
            }}
          >
            <IconFont
              className='absolute text-white text-14px top-12px left-12px star-animate-blink'
              name='xingxing5'
            />
            <IconFont
              className='absolute text-white text-8px top-22px left-23px star-animate-blink-later'
              name='xingxing5'
            />
          </div>
          <AppLogo
            size={40}
            fillSize={20}
            value={applicationInfo.icon}
            color={applicationInfo.color}
            type='image'
          />
        </div>
        <span className='ml-8 text-20px font-600'>
          <ProgressText
            loading={!!transformMap[TransformKey.name]}
            text={transformMap[TransformKey.name] ? '' : applicationInfo.name}
          />
        </span>
        <IconFont
          name='bianji'
          style={{
            color: 'rgba(98, 105, 153, 0.6)',
            borderRadius: 4,
            boxSizing: 'content-box',
          }}
          className='text-16px ml-8px cursor-pointer p-4px hover:bg-#62696D hover:bg-op-8'
        />
      </div>
    )

    return (
      <Tooltip
        open={locked ? undefined : false}
        title={locked ? '助手正在自动编排，请稍等' : ''}
        placement='bottom'
      >
        {temp}
      </Tooltip>
    )
  }, [transformMap, locked, applicationInfo])

  return (
    <>
      <div className='h-60px b-b b-line-op-60 flex items-center pl-10 shrink-0'>
        <IconFont
          onClick={onBackDetail}
          name='fanhui'
          style={{
            boxSizing: 'content-box',
            borderRadius: 4,
          }}
          className='text-16px mr-12px ml-4px cursor-pointer p-8px hover:bg-#62696D hover:bg-op-8'
        />
        {editContent}
        <div className='flex ml-40px flex-1'>
          {tabItems.map(item => {
            return (
              <span
                className={classNames(
                  'px-19 py-7 relative text-16px c-font c-op-80 cursor-pointer font-500 rounded-8px hover:c-primary',
                  {
                    '!c-primary c-op-100': tab === item.key,
                  },
                )}
                style={{
                  background:
                    tab === item.key ? 'rgba(123, 97, 255, 0.08)' : 'none',
                }}
                key={item.key}
                onClick={() => {
                  if (tab !== item.key) {
                    const source = searchParams.get('source')
                    navigate(
                      `/agent/${applicationInfo.id}/${item.key}${
                        source ? `?source=${source}` : ''
                      }`,
                    )
                  }
                }}
              >
                <span>{item.label}</span>
              </span>
            )
          })}
        </div>
        <div className='mr-24px flex gap-12px'>
          <InvitePopover
            isAgent
            title='分享'
            applicationId={applicationInfo.id}
            flowId={applicationInfo.flowId}
            workspaceId={applicationInfo.workspaceId}
            roleOptions={getRoleOptions(false)}
            isBot={applicationInfo.isBot}
            isPublic={applicationInfo.isPublic}
            refreshAppInfo={refreshApplicationInfo}
            onClose={refreshApplicationInfo}
            setOpenInvitePopover={setOpenInvitePopover}
            openInvitePopover={openInvitePopover}
          >
            <Button
              type='primary'
              icon={
                <span role='img' className='anticon anticon-edit'>
                  <IconFont name='fenxiang' />
                </span>
              }
            >
              分享
            </Button>
          </InvitePopover>
          <Dropdown
            overlayClassName='w-180px'
            trigger={['click']}
            menu={{
              items: [
                {
                  label: '复制',
                  style: { height: 32, padding: '0 28px 0 10px' },
                  key: '3',
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation()
                    workspaceSelectModal.open()
                  },
                },
                {
                  label: '删除',
                  key: '2',
                  style: {
                    color: '#FF5219',
                    height: 32,
                    padding: '0 28px 0 10px',
                  },
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation()
                    Modal.confirm({
                      icon: <></>,
                      content: '请确认是否要删除应用？',
                      cancelText: '取消',
                      okText: '确定',
                      okButtonProps: { danger: true },
                      onOk: () =>
                        deleteApplication(applicationInfo.id).then(() => {
                          message.success('删除成功！')
                          navigate('/agent')
                        }),
                    })
                  },
                },
              ],
            }}
          >
            <Button
              style={{ height: 36 }}
              onClick={e => e.stopPropagation()}
              className='p-0! w-36px!'
              icon={<IconFont name='gengduo' className='c-font' />}
            ></Button>
          </Dropdown>
        </div>
      </div>
    </>
  )
}
