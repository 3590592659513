import { useState } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { LoadingOutlined } from '@ant-design/icons'
import { message, Spin } from 'antd'
import { Input, IconFont } from '@/components'
import {
  getFileDisplayName,
  getFileIconByFileTypeOrMimeType,
} from '@/features/datastore/utils'
import type { DocumentType } from '@/apis/datastore/model'
import type { DocumentUpdateRequest } from '@/apis/datastore/types'

interface DocumentNameEditProps {
  file_id: number
  file_name: string
  mimetype: string
  file_type: DocumentType
  onSave: (params: DocumentUpdateRequest) => void
}

const NameBox = styled.div`
  transition: all 0.15s ease-in-out;
  .edit-icon {
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    .edit-icon {
      opacity: 1;
      width: 16px !important;
      margin-left: 8px;
    }
  }
`
export function DocumentNameEdit(props: DocumentNameEditProps) {
  const { file_name, file_id, mimetype, file_type, onSave } = props
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState(file_name)
  const [loading, setLoading] = useState(false)

  const onConfirm = async () => {
    if (value.trim()) {
      try {
        setLoading(true)
        await onSave({
          file_id,
          file_name: value,
        })
        setIsEdit(false)
        message.success('保存成功')
      } finally {
        setLoading(false)
      }
    }
  }

  if (isEdit) {
    return (
      <Input
        prefix={
          loading ? (
            <Spin indicator={<LoadingOutlined className='!text-16px' />} />
          ) : null
        }
        size='small'
        autoFocus
        value={value}
        maxLength={255}
        showCount
        className={classNames('flex-1 max-w-600px', {
          '!b-error': !value.trim(),
        })}
        onBlur={onConfirm}
        onPressEnter={onConfirm}
        placeholder='请输入标题'
        onChange={e => setValue(e.target.value)}
      />
    )
  }
  return (
    <NameBox
      className='flex items-center hover:bg-bg_3 hover:bg-op-12 rounded-4px hover:px-12 py-6'
      title={value}
      onClick={() => setIsEdit(true)}
    >
      <img
        className='w-20px h-24px shrink-0 mr-12'
        src={getFileIconByFileTypeOrMimeType(mimetype, file_type)}
        alt=''
      />
      <span className='cursor-pointer text-20px font-600 truncate max-w-40vw'>
        {getFileDisplayName(value, file_type)}
      </span>
      <IconFont
        name='bianji'
        className='c-bg_3 !w-0 edit-icon op-0 h-fit shrink-0 c-op-60 text-14px relative mt-2'
      />
    </NameBox>
  )
}
