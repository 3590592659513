import type { PopoverProps } from 'antd'
import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'
import { useViewport } from 'reactflow'

export function usePanelPlacement(containerRef: RefObject<HTMLElement>) {
  const placementRef = useRef<PopoverProps['placement']>('right')

  const viewport = useViewport()

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      const flowDom = document.getElementById('flow')
      if (flowDom) {
        const viewportWidth = flowDom.clientWidth
        const viewportHeight = flowDom.clientHeight
        const diff = {
          top: rect.top,
          right: viewportWidth - rect.right,
          bottom: viewportHeight - rect.bottom,
          left: rect.left,
        }
        const directX = diff.right < 300 ? 'left' : 'right'
        const directY = diff.bottom < 610 ? 'Top' : 'Bottom'
        placementRef.current = `${directX}${directY}`
      }
    }
  }, [viewport])

  return placementRef
}
