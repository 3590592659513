import { request } from './lib'

interface CreateAgentCopilotConversationRes {
  copilot_conversation_id: string
  copilot_session_id: string
}

export function createAgentCopilotConversation(appId: string, agentId: string) {
  return request.put<CreateAgentCopilotConversationRes>(
    `/v1/copilot/insert_conversation_info?agent_id=${agentId}`,
    {},
    {
      headers: {
        'Application-Id': appId,
      },
    },
  )
}
