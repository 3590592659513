import { Upload } from 'antd'
import type { MouseEvent } from 'react'
import { memo, useMemo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import type { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { isEmpty } from 'lodash-es'
import { IconFont } from '@/components'
import { getFileIconByMimetype } from '@/features/datastore/utils'
import { UN_KNOW_ICON } from '@/features/datastore/constant'
import type { CellRenderProps, IFile } from './type'

export const UploadRender = memo((props: CellRenderProps<IFile>) => {
  const { className, value, cell, head, onChange } = props

  const ref = useRef<HTMLDivElement>(null)

  const handleChange = useMemoizedFn(
    async (event: UploadChangeParam<UploadFile>) => {
      const { upload_file_id } = await head.extra.uploadFile({
        file: event.file,
      })

      const fileUrl = `${
        import.meta.env.VITE_AI_API_BASE_URL
      }/v1/chat/file/${upload_file_id}`

      const fileInfo = {
        name: event.file.name,
        mimetype: event.file.type,
        url: fileUrl,
      }

      onChange(fileInfo, [cell.key, head.key])
    },
  )

  const handleClick = useMemoizedFn((event: MouseEvent) => {
    if (head.disabled) return
    event.preventDefault()
    ref.current?.click()
  })

  const hasError = useMemo(() => {
    if (!head.validate || !head.require) return false
    return !value
  }, [head.validate, value, head.key])

  const showContent = useMemo(() => {
    if (isEmpty(value) || !value.url) {
      return (
        <div className='h-22px leading-22px truncate color-[rgba(0,0,0,.25)]'>
          {head.placeholder}
        </div>
      )
    }

    return (
      <div className='flex items-center h-24px overflow-hidden'>
        <img
          className='flex-0'
          src={getFileIconByMimetype(value.mimetype!) ?? UN_KNOW_ICON}
        />
        <span className='ml-4px leading-1.5em truncate'>
          {value.name || value.url}
        </span>
      </div>
    )
  }, [value])

  return (
    <div
      className={classNames(className, 'test-table-cell select-none', {
        'test-table-cell-error': hasError,
      })}
      onClick={handleClick}
    >
      <Upload
        className='test-table-upload'
        showUploadList={false}
        onChange={handleChange}
        accept={(head.fileTypes || []).join(',')}
        beforeUpload={() => false}
      >
        <span className={value ? 'hidden' : ''} ref={ref}>
          <IconFont name='add' />
        </span>
      </Upload>

      {showContent}
    </div>
  )
})
