import { type Edge, type Node } from 'reactflow'
import type { LoopNodeData } from '@/features/nodes/loop'
import type { NestNodeAutoLayoutFn } from '../types'
import { getDomByNodeId, isNestableNode } from '@/features/flow/utils'
import { getNodeBoundary } from '../../boundary'
import { SPACE_BETWEEN_NODES } from '../../constant'
import { getNextNode } from '..'
import { NodeType } from '@/features/nodes/base'

const DEFAULT_HEIGHT = 30
const DEFAULT_WIDTH = 340

export function LoopNodeAutoLayout(
  node: Node<LoopNodeData>,
  nodes: Node[],
  edges: Edge[],
  nestNodeAutoLayoutFn: NestNodeAutoLayoutFn,
) {
  const nodeEle = getDomByNodeId(node.id)
  const nodeWidth = nodeEle?.clientWidth || 0
  const nodeHeight = nodeEle?.clientHeight || 0
  const { relation } = node.data
  const resultNode = nodes.find(n => n.id === relation.loopResultId)
  if (!resultNode) {
    console.error('loop节点数据出错: 缺失result节点')
    return
  }

  const loopBoundaryEdge = edges.find(
    e => e.sourceHandle === `loopBoundary_${node.id}`,
  )
  const loopStartEdge = edges.find(
    e => e.sourceHandle === `loopStart_${node.id}`,
  )
  const firstLoopChildNode = nodes.find(n => n.id === loopStartEdge?.target)
  const hasLoopChild =
    firstLoopChildNode && firstLoopChildNode.id !== resultNode.id
  // 循环体内有子节点
  if (hasLoopChild) {
    firstLoopChildNode.position.x =
      node.position.x + (nodeWidth || 0) + SPACE_BETWEEN_NODES
    let current: Node | null = null
    let next: Node | null = firstLoopChildNode
    let resultPosX = 0
    while (next) {
      if (isNestableNode(next)) {
        nestNodeAutoLayoutFn(next, nodes, edges)
      }
      if (current && next) {
        const currentNodeBoundary = getNodeBoundary(current, nodes, edges)
        const nextNodeBoundary = getNodeBoundary(next, nodes, edges)
        const gap = nextNodeBoundary.left - currentNodeBoundary.right
        const isGapProper = gap >= SPACE_BETWEEN_NODES - 2 && gap <= 100
        if (!isGapProper) {
          next.position.x = currentNodeBoundary.right + SPACE_BETWEEN_NODES
          nestNodeAutoLayoutFn(next, nodes, edges)
        }
      }
      resultPosX =
        getNodeBoundary(next, nodes, edges).right + SPACE_BETWEEN_NODES
      current = next
      next = getNextNode(nodes, edges, current, NodeType.LOOP_RESULT)
    }
    resultNode.position.x = resultPosX

    const loopChildren = nodes.filter(
      n => n.data?.relation?.loopNodeId === node.id,
    )
    const loopChildrenBoundaries = loopChildren.map(item =>
      getNodeBoundary(item, nodes, edges),
    )
    const bottomY =
      Math.max(
        node.position.y + nodeHeight,
        ...loopChildrenBoundaries.map(item => item.bottom),
      ) + 40

    if (loopBoundaryEdge) {
      loopBoundaryEdge.data = {
        ...loopBoundaryEdge.data,
        bottomPosY: bottomY,
        nodeHeight: resultNode?.height || DEFAULT_HEIGHT,
        nodeWidth: resultNode?.width || DEFAULT_WIDTH,
      }
    }
  } else {
    resultNode.position.x =
      node.position.x +
      (nodeWidth || 0) +
      SPACE_BETWEEN_NODES +
      (nodeWidth || 0) / 2
    if (loopBoundaryEdge) {
      loopBoundaryEdge.data = {
        ...loopBoundaryEdge.data,
        bottomPosY: node.position.y + nodeHeight + 18 + 40,
        nodeHeight: resultNode?.height || DEFAULT_HEIGHT,
        nodeWidth: resultNode?.width || DEFAULT_WIDTH,
      }
    }
  }
  // 兼容老数据问题，老数据默认添加了13的偏移量
  const offsetY = nodeHeight > 10 ? 0 : 13
  resultNode.position.y = node.position.y + offsetY
}
