import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import type { UploadProps } from 'antd'
import { Button, Tooltip, Upload } from 'antd'
import styled from '@emotion/styled'
import type { RcFile, UploadRequestOption } from 'rc-upload/lib/interface'
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { IconFont } from '@/components'
import { useUploadFileToOss } from '@/hooks/useOssUpload'

const allFileType = [
  '.docx',
  '.doc',
  '.xlsx',
  '.xls',
  '.ppt',
  '.pptx',
  '.pdf',
  '.md',
  '.txt',
  '.jpg',
  '.png',
]

export interface FileInfo {
  id: string
  name: string
  size?: number
  type?: string
}

interface CopilotUploadProps extends Omit<UploadProps, 'onChange'> {
  disabled?: boolean
  loading: boolean
  onLoadingChange: (loading: boolean) => void
  value?: FileInfo
  onChange?: (value?: FileInfo) => void
}

export const CopilotUpload = memo(
  ({
    disabled,
    loading,
    onLoadingChange,
    value,
    onChange,
  }: CopilotUploadProps) => {
    const { uploadFileToOssApi } = useUploadFileToOss()
    const uploadFileToOss = useMemoizedFn(
      async (options: UploadRequestOption) => {
        onLoadingChange(true)
        const file = options.file as RcFile
        const formData = new FormData()
        formData.append('file', file)
        const res = await uploadFileToOssApi(formData)

        try {
          onChange?.({
            name: file.name,
            id: res.upload_file_id,
            size: file.size,
          })
        } catch (err) {}
        onLoadingChange(false)
      },
    )

    const button = useMemo(() => {
      const temp = (
        <Button
          className='p-4px'
          disabled={disabled || !!value?.id || loading}
          icon={loading ? <LoadingOutlined /> : <PlusCircleOutlined />}
          type='text'
        >
          上传文件
        </Button>
      )

      if (!value?.id) return temp

      return (
        <Tooltip
          title='最多上传一个文件'
          placement='bottom'
          align={{ offset: [-4] }}
        >
          {temp}
        </Tooltip>
      )
    }, [disabled, loading, value])

    return (
      <Upload
        showUploadList={false}
        customRequest={uploadFileToOss}
        accept={allFileType.join(',')}
      >
        {button}
      </Upload>
    )
  },
)

export const FileTypeIcon = memo(({ name }: { name: string }) => {
  const nameType = useMemo(() => {
    if (!name) return 'txt'
    if (name.includes('.')) return name.split('.').pop()
    return 'txt'
  }, [name])

  const nameTypeIcon = useMemo(() => {
    switch (nameType) {
      case 'doc':
      case 'docx':
        return 'doc-1'
      case 'xls':
      case 'xlsx':
        return 'xlsx'
      case 'ppt':
      case 'pptx':
        return 'ppt'
      case 'pdf':
        return 'pdf'
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'webp':
      case 'svg':
        return 'tupianwenjian'
      case 'html':
        return 'wangyeshuju'
      default:
        return ''
    }
  }, [nameType])

  return <IconFont name={nameTypeIcon || 'txt'} />
})

interface CopilotFileInfoProps {
  fileInfo?: FileInfo
  onClear: () => void
}

export const CopilotFileInfo = memo(
  ({ fileInfo, onClear }: CopilotFileInfoProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const time = useRef(400)
    const [innerFile, setFile] = useState(fileInfo)

    useLayoutEffect(() => {
      const dom = ref.current
      if (!dom) return
      if (innerFile) return
      dom.style.overflow = 'hidden'
      dom.style.transition = `${time.current}ms`
      dom.style.height = '0px'

      requestAnimationFrame(() => {
        dom.style.height = '44px'
      })
      setFile(fileInfo)
    }, [fileInfo])

    useLayoutEffect(() => {
      const dom = ref.current
      if (!dom) return
      if (fileInfo) return
      dom.style.transition = `${time.current}ms`
      dom.style.height = '44px'

      requestAnimationFrame(() => {
        dom.style.height = '0px'
      })

      setTimeout(() => {
        setFile(fileInfo)
      }, time.current)
    }, [fileInfo])

    const file = useMemo(() => fileInfo || innerFile, [fileInfo, innerFile])

    const sizeInfo = useMemo(() => {
      if (!file?.size) return
      const kb = file.size / 1024
      if (kb > 100) return `${(kb / 1024).toFixed(2)}MB`
      return `${kb.toFixed(2)}KB`
    }, [file])

    if (!file) return null

    return (
      <div className='pr-6px' ref={ref}>
        <div
          className='flex flex-center h-36px rounded-8px px-12px mt-8px relative w-fit max-w-full'
          style={{ background: 'rgba(98, 105, 153, 0.14)' }}
        >
          <span className='mr-6px text-20px'>
            <FileTypeIcon name={file.name} />
          </span>
          <span className='flex-1 text-ellipsis overflow-hidden'>
            {file.name}
          </span>
          {file.size && (
            <span className='text-#8D8D99 ml-12px'>{sizeInfo}</span>
          )}
          <span className='text-20px absolute top-[-6px] right-[-6px]'>
            <IconFont name='qingshubujian' onClick={onClear} />
          </span>
        </div>
      </div>
    )
  },
)

const { Dragger } = Upload

const DraggerWrapper = styled(Dragger)`
  .ant-upload-drag {
    background: rgba(98, 105, 153, 0.08) !important;
    border: 1px dashed rgba(225, 225, 229, 0.8);
    border-radius: 8px !important;
  }
`

interface CopilotDraggerProps extends Omit<UploadProps, 'onChange'> {
  value?: FileInfo
  onChange?: (value?: FileInfo) => void
}

export const CopilotDragger = memo(
  ({ value, onChange, ...rest }: CopilotDraggerProps) => {
    const [state, setState] = useState(value ? 'success' : 'init')
    const [file, setFile] = useState<File>()
    const { uploadFileToOssApi } = useUploadFileToOss()

    const uploadFileTo = useMemoizedFn(async (options: UploadRequestOption) => {
      setState('loading')

      const file = options.file as RcFile
      setFile(file)
      const formData = new FormData()
      formData.append('file', file)
      const res = await uploadFileToOssApi(formData)

      try {
        onChange?.({ name: file.name, id: res.upload_file_id, size: file.size })
        setState('success')
      } catch (err) {
        setState('error')
      }
    })

    const handleCancel = useMemoizedFn(event => {
      event.preventDefault()
      event.stopPropagation()
      setState('init')
      onChange?.(undefined)
    })

    return (
      <DraggerWrapper
        className='h-116px block'
        showUploadList={false}
        customRequest={uploadFileTo}
        accept={allFileType.join(',')}
        {...rest}
      >
        <div className='flex flex-center'>
          {state === 'init' && (
            <IconFont name='wenjianshangzhuan' className='text-24px' />
          )}
          {state === 'loading' && (
            <LoadingOutlined className='text-24px c-primary' />
          )}
          {state === 'success' && (
            <IconFont name='pay-success' className='text-24px' />
          )}
          {state === 'error' && (
            <IconFont name='shangzhuanbujian' className='text-24px' />
          )}
        </div>
        {['init', 'error'].includes(state) && (
          <p className='mt-12px'>
            拖拽文件到这里或 <span className='c-primary'>选择文件</span>
            以上传
          </p>
        )}
        {state === 'loading' && (
          <p className='mt-12px'>正在上传 {file?.name}</p>
        )}
        {state === 'success' && (
          <p className='mt-12px'>上传完成 {value?.name}</p>
        )}

        {['init', 'error'].includes(state) && (
          <p
            className='mt-6px text-12px'
            style={{ color: 'rgba(141, 141, 153, 0.6)' }}
          >
            支持格式{allFileType.join('、')}
          </p>
        )}
        {state === 'loading' && (
          <p className='mt-6px mt-12px'>
            <span className='c-primary' onClick={handleCancel}>
              取消
            </span>
          </p>
        )}
        {state === 'success' && (
          <p className='mt-6px mt-12px'>
            <span className='c-primary'>更换文件</span>
          </p>
        )}
      </DraggerWrapper>
    )
  },
)
