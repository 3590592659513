import { memo, useRef, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { useAuth } from '@bty/react-auth'
import { useNavigate } from 'react-router-dom'
import { CopilotState } from '@/features/agent/provider/AgentEditProvider'
import CopilotIcon from '@/assets/agent/copilot-progress-icon.svg?url'
import { wait } from '@/utils/wait'
import { CopilotInitConfig } from './CopilotInitConfig'
import { CopilotProgress } from './CopilotProgress'
import { BlurBG } from './BlurBG'

interface CopilotInitProps {
  workspaceId: string
  appId: string
  flowId: string
  state: CopilotState
  onChangeState: (state: CopilotState) => void
  onFinish: () => void
}

export const CopilotInit = memo(
  ({
    workspaceId,
    appId,
    flowId,
    state,
    onChangeState,
    onFinish,
  }: CopilotInitProps) => {
    const navigate = useNavigate()
    const { access } = useAuth()
    const time = useRef(800)
    const parentRef = useRef<HTMLDivElement>(null)
    const [start, setStart] = useState(false)

    const handleLeave = useMemoizedFn(async () => {
      if (!parentRef.current) return
      const parent = parentRef.current
      parent.style.transition = `${time.current}ms`
      parent.style.opacity = '1'

      await wait(10)
      parent.style.opacity = '0'

      await wait(time.current)
      parent.style.display = 'none'
    })

    const handleConFinish = useMemoizedFn(async () => {
      onChangeState(CopilotState.generate)
    })

    const handleGenFinish = useMemoizedFn(async () => {
      onFinish()
      await handleLeave()
      onChangeState(CopilotState.finish)
    })

    const handleGenError = useMemoizedFn(async () => {
      onChangeState(CopilotState.init)
    })

    const handleSkip = useMemoizedFn(async () => {
      navigate({ pathname: 'rule' })
    })

    if (!access.grayscale('COPILOT_GRAYSCALE')) return null

    if (![CopilotState.init, CopilotState.generate].includes(state)) return null

    return (
      <div
        ref={parentRef}
        className='absolute top-0 left-0 w-full h-full bg-white/90 z-10 flex justify-center backdrop-blur-10px overflow-auto'
      >
        <div
          className={classNames(
            'flex flex-col items-center relative h-fit min-h-full w-700px pb-4vh duration-300',
            state === CopilotState.init ? 'pt-14vh' : 'pt-14vh',
          )}
        >
          <img className='h-90px' src={CopilotIcon} />

          {state === CopilotState.init && (
            <CopilotInitConfig
              appId={appId}
              onStart={setStart}
              onFinish={handleConFinish}
            />
          )}

          {state === CopilotState.generate && (
            <CopilotProgress
              workspaceId={workspaceId}
              flowId={flowId}
              appId={appId}
              onFinish={handleGenFinish}
              onError={handleGenError}
            />
          )}

          {!start && state === CopilotState.init && (
            <div
              className='c-primary cursor-pointer hover:opacity-60 duration-150 mt-24'
              onClick={handleSkip}
            >
              跳过，进入手动编排
            </div>
          )}

          <BlurBG
            className='absolute'
            padding={-88}
            position='top'
            style={{ zIndex: -1 }}
          />
        </div>
      </div>
    )
  },
)
