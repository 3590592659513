import { IconFont } from '@/components'
import type { NodeType } from '@/features/nodes/base'
import { BaseNodeOptions } from '@/features/nodes/base'
import { useNodeMetaStore } from '@/store/nodeMeta'

interface NodeOption {
  type: NodeType
  name: string
  icon: string
  iconColor: string
  description: string
}

const baseNodeList = BaseNodeOptions.reduce<NodeOption[]>((pre, cur) => {
  return pre.concat(cur.data)
}, [])

export function useGenMovableOptionData() {
  const { templateList, subFlowList, pluginList } = useNodeMetaStore()

  const generate = (key: any) => {
    const baseNode = baseNodeList.find(item => item.type === key)
    if (baseNode) {
      const { name, description, icon, iconColor } = baseNode
      return {
        name,
        description,
        icon: (
          <div
            className='flex-center mr-8px w-24px h-24px b-rd-4px'
            style={{ backgroundColor: iconColor }}
          >
            <IconFont name={icon} />
          </div>
        ),
      }
    }

    const template = templateList.find(s => s.id === key)
    if (template) {
      const { name, description, icon } = template
      return {
        name,
        description,
        icon: <img className='w-24px h-24px mr-8px' src={icon} alt='' />,
      }
    }

    const plugin = pluginList.find(p => p.function_id === key)
    if (plugin) {
      const { display_name, description, icon } = plugin
      return {
        name: display_name,
        description,
        icon: <img className='w-24px h-24px mr-8px' src={icon} alt='' />,
      }
    }

    const flow = subFlowList.find(s => s.flow_id === key)
    if (flow) {
      const { flow_name, description, icon, color } = flow
      return {
        name: flow_name,
        description,
        icon: (
          <div
            className='flex-center mr-8px w-24px h-24px b-rd-4px'
            style={{ backgroundColor: color }}
          >
            {icon}
          </div>
        ),
      }
    }
    return null
  }

  return {
    generate,
  }
}
