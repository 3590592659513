import { Avatar } from '@bty/chat-ui'
import type { Bot, ConversationData } from '@bty/chat-types'
import { getAvatarFromBot } from '../utils/getAvatar'

export interface ChatAvatarProps {
  bot: Bot
  currentConversation?: Partial<ConversationData>
}

export function ChatAvatar({ currentConversation, bot }: ChatAvatarProps) {
  const hasTitle = !!currentConversation?.title

  return (
    <div className='mx-auto flex gap-8'>
      <Avatar size={40} icon={getAvatarFromBot(bot)} color={bot.color} />
      <div className='flex flex-col justify-center'>
        <span
          className={
            hasTitle
              ? 'text-12px/16px text-font_1'
              : 'text-16px/20px text-font font-semibold'
          }
        >
          {bot.name}
        </span>
        {hasTitle && (
          <span className='text-16px/20px text-font font-semibold'>
            {currentConversation?.title}
          </span>
        )}
      </div>
    </div>
  )
}
