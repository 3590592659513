import { useRequest } from 'ahooks'
import { Spin, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { forkApplication } from '@/apis/application.ts'
import { DetailComponent as AppDetail, Button, IconFont } from '@/components'
import { useWorkspaceSelectModal } from '@/pages/application/components/WorkSpaceSelect.tsx'
import type { AppDetailInstance } from '@/pages/application/components/appDetail/utils'
import { useUserStore, useWorkspaceStore } from '@/store'
import { AgentNotAllowed } from '../agent/components/AgentNotAllowed'
import useShareAgentAuthentic from '@/hooks/useShareAgentAuthentic'
import type { Bot } from '@/apis/chatbot'

export type TemplatePageSource = 'template' | 'app'
export default function PublicPage() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userStore = useUserStore()
  const fetchWorkspaceList = useWorkspaceStore(
    state => state.fetchWorkspaceList,
  )
  const [loading, setLoading] = useState(false)
  const source = searchParams.get('source') as TemplatePageSource
  const isShare = searchParams.get('share') === 'true'
  const appDetailRef = useRef<AppDetailInstance>(null)

  const init = async () => {
    if (source === 'template') {
      // 从模板页进入需要判断用户是否登录，所以先获取用户信息
      setLoading(true)
      await userStore.fetchUser()
      setLoading(false)
    } else if (isShare) {
      // 如果带了share标识，则用户需要ForkApp，需要判断用户是否登录并且获取WorkSpace List
      setLoading(true)
      try {
        await Promise.all([userStore.fetchUser(), fetchWorkspaceList()])
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    init()
  }, [])

  const checkUserLogin = () => {
    if (!userStore.user || userStore.user?.isAnonymous) {
      // 模板应用需要登录后使用
      const redirectParams = `redirect=${encodeURIComponent(
        window.location.pathname + window.location.search,
      )}`
      navigate(
        `/login${
          window.location.search
            ? `${window.location.search}&${redirectParams}`
            : `?${redirectParams}`
        }`,
      )
      return false
    }
    return true
  }

  const beforeRun = () => {
    if (source === 'template') {
      return checkUserLogin()
    }
    return true
  }

  const [workspaceSelectModal] = useWorkspaceSelectModal({
    onConfirm: handleFork,
    defaultWorkspaceId: appDetailRef.current?.appInfo?.workspaceId,
  })
  const onForkApp = () => {
    if (checkUserLogin()) {
      workspaceSelectModal.open()
    }
  }

  const { runAsync: forkApplicationApi } = useRequest(forkApplication, {
    manual: true,
  })

  async function handleFork(workspaceId: string) {
    if (appDetailRef.current && appDetailRef.current.appInfo) {
      const res = await forkApplicationApi({
        source_appid: appDetailRef.current.appInfo.id,
        source_workspace_id: appDetailRef.current.appInfo.workspaceId,
        target_workspace_id: workspaceId,
      })
      window.open(
        `/application/${res.application_id}?forkTaskId=${res.task_id}`,
        '_blank',
      )
      workspaceSelectModal.close()
    } else {
      message.error('当前App信息获取失败，请刷新页面重试！')
    }
  }

  const {
    loading: authenticLoading,
    isEnable,
    isPublic,
    isLogin,
    authentic,
    roleCode,
  } = useShareAgentAuthentic({ bot: { app_id: id } as Bot, manual: false })

  const Dom = (
    <div className='bg-bg_2 py-40 px-60 h-100vh flex flex-col'>
      <AppDetail
        id={id!}
        ref={appDetailRef}
        tabs={['singleRun']}
        beforeRun={beforeRun}
      >
        {isShare && (
          <Button size='large' type='primary' onClick={onForkApp}>
            <IconFont name='edit-app' />
            <span className='ml-8'>复制应用</span>
          </Button>
        )}
      </AppDetail>
    </div>
  )

  if (loading || authenticLoading) {
    return (
      <div className='w-100vw h-100vh absolute top-0 left-0 z-1 flex-center bg-bg_3 bg-op-30'>
        <Spin />
      </div>
    )
  }

  if (isPublic) {
    return Dom
  }

  if (isEnable === false) {
    return (
      <div className='c-bg_3 text-20px font-500 c-op-40 w-100% h-100vh flex flex-items-center justify-center'>
        agent未启用
      </div>
    )
  }

  if (!isLogin) {
    // 没有登录，等待登录接口返回
    navigate(
      `/login?redirect=${encodeURIComponent(window.location.pathname)}&channel=ai-share`,
    )
    return
  }

  // 已登录，无权限
  if (authentic === false) {
    return <AgentNotAllowed agentId={id} roleCode={roleCode} />
  }

  if (authentic === null) {
    return
  }

  return Dom
}
