import { useUnmount } from 'ahooks'
import { useFlowInteractionStore } from '@/store'

// 处理edge销毁后原先所在的前后handle依然处于激活状态的问题
export function useEdgeUnMount({
  source,
  target,
}: {
  source: string
  target: string
}) {
  const { activeHandles, activeEdgeIds, setActiveStates } =
    useFlowInteractionStore()

  useUnmount(() => {
    const newHandles = activeHandles.filter(
      item => !item.startsWith(source) && !item.startsWith(target),
    )
    setActiveStates({
      handles: newHandles,
      edgeIds: activeEdgeIds,
    })
  })
}
