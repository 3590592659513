export const iconList = [
  '😃',
  '🤩',
  '😎',
  '🧑‍🎓',
  '👩‍🏫',
  '👨‍💼',
  '👩‍💼',
  '🧑‍💻',
  '👨‍⚖️',
  '👩‍🎨',
  '🙋',
  '👾',
  '🤖',
  '👽',
  '🗣',
  '👤',
  '🤘',
  '👍',
  '🤝',
  '💪',
  '✍️',
  '🐵',
  '🐱',
  '🦊',
  '🐰',
  '🐯',
  '🐨',
  '🦁',
  '🐶',
  '🐴',
  '🦄',
  '🐮',
  '🐼',
  '🐣',
  '🐧',
  '🐠',
  '🐳',
  '🐙',
  '🌸',
  '🌼',
  '🌵',
  '🌎',
  '🪐',
  '🚀',
  '⏰',
  '🎮',
  '💡',
  '🏖',
  '⚙️',
  '💰',
  '🛠',
  '🔫',
  '🧬',
  '🪩',
  '💻',
  '📱',
  '📸',
  '🎯',
  '📨',
  '📬',
  '📊',
  '🗒',
  '📋',
  '📔',
  '🗂️',
  '📒',
  '📆',
  '📇',
  '📝',
  '🔍',
  '📢',
  '🔔',
  '💬',
  '🗑️',
]

export const iconColors = [
  '#BFE2FF',
  '#ADE9CE',
  '#D4CCFF',
  '#FECFDA',
  '#FFE1AD',
  '#EDD3BB',
  'linear-gradient(314deg, rgba(226, 29, 111, 0.2) 7%, rgba(210, 72, 182, 0.2) 13%, rgba(109, 60, 255, 0.2) 48%, rgba(123, 97, 255, 0.2) 68%, rgba(69, 152, 255, 0.2) 88%)',
]

export const fontIcons = Array(17)
  .fill(null)
  .map((_, index) => `icon-logo-${index + 1}`)
