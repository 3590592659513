import type { ColumnType } from '@bty/smartsheet'
import { UITypes } from '@bty/smartsheet/src/nocodb-sdk/ui-types'
import { isNaN, sampleSize } from 'lodash-es'
import type {
  Sheet,
  SheetColumnType,
} from '@/features/database/types/tableImport.ts'
import {
  extractFileName,
  extractMultiOrSingleSelectProps,
  fixName,
  getDateFormat,
  getSafeValueByUidtType,
  isCheckboxType,
  isEmailType,
  isMultiLineTextType,
  isUrlType,
  maxRowsToParse,
} from '@/features/database/utils/parseHelpers.ts'

const excelTypeToUidt: Record<string, UITypes> = {
  d: UITypes.DateTime,
  b: UITypes.Checkbox,
  // n: UITypes.Number,
  n: UITypes.Decimal,
  s: UITypes.SingleLineText,
}

export function extractExcelData(
  file: File,
  sheetLimit?: number,
): Promise<Sheet[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = async function (e) {
      const data = e.target?.result
      const xlsx = await import('xlsx')

      const workbook = xlsx.read(data, {
        type: 'binary',
        cellDates: true,
        cellText: true,
      })
      const fileName = fixName(extractFileName(file.name), 'table_')

      const sheets = sheetLimit
        ? workbook.SheetNames.slice(0, sheetLimit)
        : workbook.SheetNames

      const result: (Sheet | null)[] = sheets.map(sheetName => {
        const columnNamePrefixRef: Record<string, any> = {}
        const sheet = workbook.Sheets[sheetName]

        if (!sheet || !sheet['!ref']) {
          return null
        }

        const columns: Array<SheetColumnType & { index: number }> = []

        const range = xlsx.utils.decode_range(sheet['!ref'])

        // fix precision bug & timezone offset issues introduced by xlsx
        const basedate = new Date(1899, 11, 30, 0, 0, 0)
        // number of milliseconds since base date
        const dnthresh =
          basedate.getTime() +
          (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) *
            60000
        // number of milliseconds in a day
        const day_ms = 24 * 60 * 60 * 1000
        // handle date1904 property
        const fixImportedDate = (date: Date) => {
          const parsed = xlsx.SSF.parse_date_code(
            (date.getTime() - dnthresh) / day_ms,
            {
              date1904: workbook.Workbook?.WBProps?.date1904,
            },
          )
          return new Date(
            parsed.y,
            parsed.m,
            parsed.d,
            parsed.H,
            parsed.M,
            parsed.S,
          )
        }

        let rows: any = xlsx.utils.sheet_to_json(sheet, {
          // header has to be 1 disregarding this.config.firstRowAsHeaders
          // so that it generates an array of arrays
          header: 1,
          blankrows: false,
          defval: null,
        })

        rows = rows.map((rowData: any) =>
          rowData.map((value: any) =>
            value instanceof Date ? fixImportedDate(value) : value,
          ),
        )

        for (let col = 0; col < rows[0].length; col++) {
          const fieldName =
            rows[0] && rows[0][col] && rows[0][col].toString().trim()

          if (!fieldName) continue

          const title = fixName(fieldName, 'column_')

          let column_name = title

          while (column_name in columnNamePrefixRef) {
            column_name = `${column_name}${++columnNamePrefixRef[column_name]}`
          }
          columnNamePrefixRef[column_name] = 0

          const column: SheetColumnType & { index: number } = {
            index: col,
            title,
            column_name,
            ref_column_name: column_name,
            meta: {},
            uidt: UITypes.SingleLineText,
            ref_uidt: UITypes.SingleLineText,
          }

          // 推断列类型
          const useToParsedUidtRows = sampleSize(rows.slice(1), maxRowsToParse)

          const cellId = xlsx.utils.encode_cell({
            c: range.s.c + col,
            r: 1,
          })
          const cellProps = sheet[cellId] || {}
          column.uidt = (excelTypeToUidt[cellProps.t] ||
            UITypes.SingleLineText) as ColumnType['uidt']

          if (column.uidt === UITypes.SingleLineText) {
            // check for long text
            if (isMultiLineTextType(rows, col)) {
              column.uidt = UITypes.LongText
            } else if (isEmailType(rows, col)) {
              // column.uidt = UITypes.Email
              column.uidt = UITypes.SingleLineText
            } else if (isUrlType(rows, col)) {
              column.uidt = UITypes.URL
            } else {
              const vals = rows
                .slice(1)
                .map((r: any) => r[col])
                .filter(
                  (v: any) =>
                    v !== null && v !== undefined && v.toString().trim() !== '',
                )

              if (isCheckboxType(vals)) {
                // column.uidt = UITypes.Checkbox
                column.uidt = UITypes.SingleLineText
              } else {
                // Single Select / Multi Select
                Object.assign(column, extractMultiOrSingleSelectProps(vals))
              }
            }
          } else if (column.uidt === UITypes.Number) {
            if (
              useToParsedUidtRows.some((v: any) => {
                return v && v[col] && Number.parseInt(v[col]) !== +v[col]
              })
            ) {
              column.uidt = UITypes.Decimal
            }
            if (
              useToParsedUidtRows.some((_v: any, i: any) => {
                const cellId = xlsx.utils.encode_cell({
                  c: range.s.c + col,
                  r: i + 1,
                })

                const cellObj = sheet[cellId]
                return (
                  !cellObj ||
                  (cellObj.w &&
                    !(
                      !isNaN(Number(cellObj.w)) &&
                      !isNaN(Number.parseFloat(cellObj.w))
                    ))
                )
              })
            ) {
              // fallback to SingleLineText
              column.uidt = UITypes.SingleLineText
            }
          } else if (column.uidt === UITypes.DateTime) {
            // TODO(import): centralise
            // hold the possible date format found in the date
            const dateFormat: Record<string, number> = {}
            if (
              useToParsedUidtRows.every((_v: any, i: any) => {
                const cellId = xlsx.utils.encode_cell({
                  c: range.s.c + col,
                  r: i + 1,
                })

                const cellObj = sheet[cellId]
                const isDate =
                  !cellObj || (cellObj.w && cellObj.w.split(' ').length === 1)
                if (isDate && cellObj) {
                  dateFormat[getDateFormat(cellObj.w)] =
                    (dateFormat[getDateFormat(cellObj.w)] || 0) + 1
                }
                return isDate
              })
            ) {
              column.uidt = UITypes.DateTime
              // take the date format with the max occurrence
              column.meta.date_format =
                Object.keys(dateFormat).reduce((x, y) =>
                  dateFormat[x] > dateFormat[y] ? x : y,
                ) || 'YYYY-MM-DD HH:mm:ss'
            }
          }

          column.ref_uidt = column.uidt as any
          columns.push(column)
        }

        // 解析数据
        const data: any[] = rows.slice(1).map((row: any, rowIndex: number) => {
          const rowData: Record<string, any> = {}
          for (let i = 0; i < columns.length; i++) {
            const rowValue = row[columns[i].index]
            const value =
              typeof rowValue === 'string' ? rowValue.trim() : rowValue
            rowData[columns[i].column_name] = getSafeValueByUidtType(
              value,
              columns[i].uidt as UITypes,
            )
            if (columns[i].uidt === UITypes.Date) {
              const cellId = xlsx.utils.encode_cell({
                c: range.s.c + i,
                r: rowIndex + 1,
              })
              const cellObj = sheet[cellId]
              rowData[columns[i].column_name] = (cellObj && cellObj.w) || value
            }
          }

          return rowData
        })

        return {
          title:
            workbook.SheetNames.length > 1
              ? `${fileName}_${sheetName}`
              : fileName,
          description: '',
          sheetName,
          columns,
          data,
        }
      })

      resolve(result.filter(d => d !== null) as Sheet[])
    }

    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}
