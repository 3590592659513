import { useBoolean } from 'ahooks'
import { generateFlowSchema } from '@/apis/flow'

export function useAutoFlowDesc({ isDraft = false }: { isDraft: boolean }) {
  const [generating, { setTrue: startGenerating, setFalse: stopGenerating }] =
    useBoolean(false)

  const [
    genPopConfirmVisible,
    {
      setTrue: showGenPopConfirm,
      setFalse: hideGenPopConfirm,
      set: setGenPopConfirm,
    },
  ] = useBoolean(false)

  const generateFlowDesc = async (flowId: string) => {
    startGenerating()
    const flowSchemaResult = await generateFlowSchema(flowId, isDraft).finally(
      () => {
        stopGenerating()
        hideGenPopConfirm()
      },
    )

    return flowSchemaResult
  }

  return {
    generating,
    genPopConfirmVisible,
    generateFlowDesc,
    showGenPopConfirm,
    hideGenPopConfirm,
    setGenPopConfirm,
  }
}
