import { useReactFlow } from 'reactflow'
import { NodeType } from '@/features/nodes/base'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { generateNodeName } from '@/features/nodes/utils'
import { TemplateNode } from '@/features/nodes/template'

export function useGenInsertTemplateNode() {
  const { templateList } = useNodeMetaStore()
  const { getNodes } = useReactFlow()

  const generateToBeInsertedTemplate = async (id: string) => {
    const currentTemplate = templateList.find(f => f.id === id)
    if (!currentTemplate) {
      console.error('找不到当前模板')
      return
    }

    const {
      id: templateId,
      name,
      icon,
      color,
      source_id,
      related_blog,
    } = currentTemplate
    const { meta } = TemplateNode

    return {
      type: NodeType.TEMPLATE,
      data: {
        ...meta.initialData,
        icon,
        iconColor: color ?? 'rgba(98,105,153,0.6)',
        displayName: name,
        name: generateNodeName(getNodes(), meta.type),
        helpLink: related_blog,
        template_id: templateId,
        source_id,
      },
    }
  }

  return {
    generateToBeInsertedTemplate,
  }
}
