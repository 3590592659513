import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useRequest } from 'ahooks'
import type { ItemType } from 'antd/es/menu/interface'
import { Divider, Tooltip } from 'antd'
import { AppLogo, IconFont, ResponsiveCard } from '@/components'
import { AppDropDown } from '../agent/components/AppDropDown'
import {
  PartitionCategoryType,
  type DataStoreItem,
} from '@/apis/datastore/types'
import { colors } from '@/constants/theme'
import { SelectType } from '../application/components/WorkSpaceSelect'
import { getAssociatedApplication } from '@/apis/datastore/api'

interface DatastoreListItemProps {
  data: DataStoreItem
  dragging?: boolean
  embeddingModels?: Record<string, { name: string; icon: string }>
  moveToGroup?: (app: DataStoreItem) => ItemType
  onDragStart?: (args: {
    item: DataStoreItem
    DragType: 'join'
    e: React.DragEvent<HTMLDivElement>
  }) => void
  onClick?: (
    partition_id: number,
    partition_category?: PartitionCategoryType,
  ) => void
  onFork: (data: DataStoreItem) => void
  onDelete?: VoidFunction
}

function InternalDatastoreListItem({
  data,
  dragging,
  embeddingModels,
  moveToGroup,
  onDragStart,
  onClick,
  onFork,
  onDelete,
}: DatastoreListItemProps) {
  const { data: relateAppData, runAsync: fetchAssociatedApp } = useRequest(
    getAssociatedApplication,
    {
      manual: true,
    },
  )

  const modelInfo = useMemo(() => {
    if (!embeddingModels || !data.partition_model) return null
    return embeddingModels[data.partition_model]
  }, [embeddingModels, data.partition_model])

  return (
    <ResponsiveCard
      className={classNames(
        'group rounded-12px bg-white cursor-pointer h-170px justify-between hover:shadow-[0px_12px_20px_0px_rgba(23,23,29,0.12)]',
        dragging ? '!op-40' : '!op-100',
      )}
      draggable
      onDragStart={e => {
        onDragStart?.({ item: data, DragType: 'join', e })
      }}
      onClick={() => onClick?.(data.partition_id, data.partition_category)}
    >
      <div className='flex flex-1 p-20px pb-16px'>
        <AppLogo
          type='icon'
          color={data.partition_icon_color ?? colors[0]}
          value={data.partition_icon ?? 'app'}
        />

        <div className='ml-16 flex-1 min-w-0 flex flex-col justify-between'>
          <p className='truncate font-500 text-16px/20px'>
            {data.partition_name}
          </p>

          <div className='text-12px'>
            <span className='inline-block px-3px py-2px bg-bg_3/4 rounded-4px border-solid border-1px border-#E1E1E5'>
              {data.partition_category === PartitionCategoryType.QA
                ? '问答'
                : '文档'}
            </span>
          </div>
        </div>
      </div>

      <div className='px-20px mb-16px h-18px truncate text-12px/18px text-font_1'>
        {data.partition_describe}
      </div>

      <div className='p-20 h-52px flex justify-between items-center'>
        <div className='flex items-center'>
          <div className='text-12px text-font_1 flex items-center'>
            <IconFont name='doc' />
            <span className='mx-4'>{data.file_num}</span>
            <span>文档</span>
          </div>

          <div className='ml-16px text-12px text-font_1 flex items-center'>
            <IconFont name='app' />
            <span className='mx-4'>{data.application.length}</span>
            <span>个关联应用</span>
          </div>

          {modelInfo && (
            <Divider className='mx-8px h-12px top-0' type='vertical' />
          )}

          {modelInfo && (
            <Tooltip title={modelInfo.name}>
              <span>
                <img className='h-12px' src={modelInfo.icon} alt='' />
              </span>
            </Tooltip>
          )}
        </div>

        <div onMouseEnter={() => fetchAssociatedApp(data.partition_id)}>
          <AppDropDown
            className='m-[-8px] border-none !text-20px invisible [&_.icon]:!text-font group-hover:visible [&.ant-dropdown-open]:visible'
            app={data}
            selectType={SelectType.DATASTORE}
            isRelateApp={relateAppData && relateAppData?.length > 0}
            removeToGroup={moveToGroup}
            handleFork={() => onFork(data)}
            handleDelete={onDelete}
          />
        </div>
      </div>
    </ResponsiveCard>
  )
}

export const DatastoreListItem = React.memo(InternalDatastoreListItem)
