import { useRequest } from 'ahooks'
import { Dropdown, message, type MenuProps } from 'antd'
import copy from 'copy-to-clipboard'
import { useEffect, useRef, useState } from 'react'
import { changeApplicationAccess } from '@/apis/application'
import { RoleCode } from '@/apis/authority'
import { Button, IconFont } from '@/components'
import useTypeName from '@/hooks/useTypeName'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

interface PopoverShareAppProps {
  isAgent?: boolean
  applicationId: string
  workspaceId: string
  isPublic?: boolean
  flowId?: string
  role?: string
  onCopy: (url: string) => void
}

enum AccessType {
  PUBLICACCESS = '公开访问·任何人都可使用',
  AUTHORIZEDACCESS = '授权访问·你同意才能使用',
}

export function PopoverShareApp(props: PopoverShareAppProps) {
  const {
    applicationId,
    workspaceId,
    isPublic = true,
    isAgent = false,
    onCopy,
    flowId,
    role,
  } = props
  const { typeName } = useTypeName()
  const [checked, setChecked] = useState(isPublic)
  const { runAsync: changeAccess } = useRequest(changeApplicationAccess, {
    manual: true,
  })
  const popoverShareRef = useRef(null)

  const onSelectChange = async (checked: boolean) => {
    const res = await changeAccess({
      applicationId,
      publicAccess: checked,
    })
    if (res) {
      message.success('修改成功')
      setChecked(checked)
    }
  }

  const menu: { items: MenuProps['items']; onClick: MenuProps['onClick'] } = {
    items: [
      // {
      //   label: <div className='my-11px ml-8px'>{AccessType.PUBLICACCESS}</div>,
      //   key: 'publicAccess',
      // },
      {
        label: (
          <div className='my-11px ml-8px'>{AccessType.AUTHORIZEDACCESS}</div>
        ),
        key: 'authorizedAccess',
      },
    ],
    onClick: ({ key }) => {
      onSelectChange(key === 'publicAccess')
    },
  }
  useEffect(() => {
    changeAccess({
      applicationId,
      publicAccess: isPublic,
    }).then(() => {
      setChecked(isPublic)
    })
  }, [isPublic])

  return (
    <div
      className='flex flex-items-center justify-between'
      ref={popoverShareRef}
    >
      <div>
        <div className='c-#17171D text-14px/14px mb-8px font-500'>
          分享{typeName}链接
        </div>
        {role === RoleCode.VIEWER && (
          <Dropdown
            menu={menu}
            placement='bottom'
            trigger={['click']}
            overlayClassName='selectAccessDropdown'
          >
            <div
              className='flex c-#8D8D99 cursor-pointer'
              onClick={event => event.preventDefault()}
            >
              {checked ? AccessType.PUBLICACCESS : AccessType.AUTHORIZEDACCESS}
              <IconFont name='arrow-1' />
            </div>
          </Dropdown>
        )}
        {role === RoleCode.DEVELOPER && (
          <div className='c-#8D8D99'>{AccessType.AUTHORIZEDACCESS}</div>
        )}
      </div>
      <LimitedAccess
        limitedType={
          role === RoleCode.DEVELOPER || !checked ? 'inviteUser' : false
        }
        limitedAccessModalContainer={popoverShareRef.current!}
      >
        <Button
          type='primary'
          onClick={() => {
            const urls = {
              isAgent: {
                [RoleCode.VIEWER]: `${
                  import.meta.env.VITE_AGENT_LINKS_URL
                }/chat/quickLink/${flowId}`,
                [RoleCode.DEVELOPER]: `${location.origin}/agent/${applicationId}?noRedirect=true`,
              },
              isFlow: {
                [RoleCode.VIEWER]: `${location.origin}/public/app/${applicationId}?workspaceId=${workspaceId}`,
                [RoleCode.DEVELOPER]: `${location.origin}/application/${flowId}?noRedirect=true`,
              },
            }
            const url =
              urls[isAgent ? 'isAgent' : 'isFlow'][
                role === RoleCode.VIEWER ? RoleCode.VIEWER : RoleCode.DEVELOPER
              ]
            copy(url)
            onCopy(url)
          }}
        >
          复制链接
        </Button>
      </LimitedAccess>
    </div>
  )
}
