import { useState, useMemo, useRef, useContext, useEffect } from 'react'
import { Form, Spin } from 'antd'

import styled from '@emotion/styled'
import { assign, find, get, isArray, isEmpty, isNil } from 'lodash-es'
import { useBoolean, useLatest } from 'ahooks'
import classNames from 'classnames'
import { DynamicForm } from '../../base/components/DynamicForm'
import {
  SUB_FLOW_STATUS,
  type InnerNodePanelProps,
} from '@/features/nodes/base'
import { JsonForm, NodeFormItem } from '@/features/nodes/components'
import type { JsonFormConfig } from '@/features/nodes/components'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { Button } from '@/components'

import { useNodeMetaStore } from '@/store/nodeMeta'
import { FlowSelect } from '../components/FlowSelect'
import { useDynamicValues } from '../../hooks/useDynamicValues'
import { componentMap as compMap } from '../components/componentMap'
import { WarningTips } from '../components/WarningTips'
import { OutputExample } from '../../components/OutputExample'
import useStartFileNodeVariableKeyList from '../../hooks/useStartFileNodeVariableKeyList'

const CodeNodePanelWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`

export function SubFlowPanelNode(props: InnerNodePanelProps<any>) {
  const { variables, data, activatedNodeId, startNodeFormItemType = [] } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const subFlowList = useNodeMetaStore(state => state.subFlowList)
  const [form] = Form.useForm()
  const latestFlowId = useLatest(data.flow_id)

  const [expanded] = useBoolean(false)

  const [loading] = useState<boolean>(false)

  const getSubFlowInfo = (id: string) => {
    let currentFlow: any = {}
    if (id) {
      currentFlow = find(subFlowList, item => item.flow_id === id)
    }
    return currentFlow
  }

  const subFlowFormConfig = useMemo(() => {
    let formConfig: any = []
    if (data.flow_id && data.flow_status === SUB_FLOW_STATUS.ACTIVE) {
      const subFlowInfo = getSubFlowInfo(data.flow_id)
      formConfig = subFlowInfo?.form_config || []
    }
    return formConfig
  }, [subFlowList, data.flow_id, data.flow_status])

  const onBeforeChange = (value: any) => {
    if (latestFlowId.current !== value.flow_id) {
      latestFlowId.current = value.flow_id
      value.inputs = {}
      const subFlowInfo = getSubFlowInfo(value.flow_id)
      const config = subFlowInfo?.form_config || []
      config.forEach((item: any) => {
        value.inputs[item.variableName] = ''
      })
      value.flow_name = subFlowInfo.flow_name
      value.flow_status = SUB_FLOW_STATUS.ACTIVE
      form.setFieldsValue({
        inputs: value.inputs,
        flow_name: value.flow_name,
        flow_status: value.flow_status,
      })
    }
    return { ...value }
  }

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: form?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message = subFlowFormConfig?.map((item: any) => {
        const currentValue = get(value, `inputs.${item.variableName}`)
        const flag = !!value && isArray(currentValue)
        const formatValue = flag ? (currentValue || []).join(',') : currentValue
        return {
          content: formatValue,
        }
      })
      return message
    },
  })

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType,
    variables,
  )

  const { usedKeyListByNodeId } = val

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await form?.validateFields()
    run(data)
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables])

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })
    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const list = useMemo<JsonFormConfig[]>(() => {
    const formConfig = subFlowFormConfig || []

    const divider = []
    if (formConfig?.length) {
      divider.push({
        className: '!h-1px',
        render: () => (
          <div className='b-b-1 b-solid b-[rgba(225,225,229,0.6)] w-100% flex-1' />
        ),
      })
    }
    const items = formConfig.map((item: any, index: any) => {
      const componentMap = compMap as any
      const Component = componentMap[item.type]
      return {
        className: classNames('w-100% px-16px  !mb-0px', { 'pt-16px': !index }),
        required: item.required,
        render: () => {
          return (
            <NodeFormItem
              {...item}
              shouldUpdate
              name={['inputs', item.variableName]}
              index={index}
              key={`${item.variableName}_${data.flow_id}`}
              rules={[{ required: item.required, message: item.placeholder }]}
            >
              {Component({
                ...item,
                variableTipsContainer: props?.nodeElement,
                variables: props.variables,
              })}
            </NodeFormItem>
          )
        },
      }
    })
    return [
      {
        label: '选择Flow',
        required: true,
        // className: 'px-16px !mb-0px',
        className: classNames('px-16px', { '!mb-0px': items.length }),
        render: () => {
          return (
            <>
              <div className='flex items-start flex-col flex-1'>
                <NodeFormItem
                  className='px-16px'
                  noStyle
                  name={['flow_id']}
                  rules={[{ required: true, message: '请选择Flow' }]}
                >
                  <FlowSelect
                    className='w-100% nodrag nopan'
                    lastSelectedInfo={{
                      name: data.flow_name,
                      status: data.flow_status!,
                    }}
                  />
                </NodeFormItem>
              </div>
            </>
          )
        },
      },
      ...divider,
      ...items,
    ]
  }, [
    props.nodeElement,
    props.variables,
    containerRef.current,
    expanded,
    props.data,
    subFlowFormConfig,
    form,
  ])

  const warningTips = useMemo(() => {
    if (data.flow_status !== SUB_FLOW_STATUS.ACTIVE && data.flow_id) {
      return <WarningTips flowStatus={data.flow_status!} />
    }
    return null
  }, [data, activatedNodeId])

  const output = useMemo(() => {
    return getSubFlowInfo(data.flow_id!)?.output || ''
  }, [data.flow_id!, subFlowList, getSubFlowInfo])

  const jsonForm = useMemo(() => {
    return <JsonForm list={list} form={form} beforeChange={onBeforeChange} />
  }, [list, form, data, onBeforeChange, subFlowList, activatedNodeId])

  return (
    <Spin spinning={loading} delay={1000}>
      <CodeNodePanelWrapper>
        {warningTips}
        {jsonForm}
        <div className='px-20px'>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
        <div className='b-b-1 b-solid b-[rgba(225,225,229,0.6)] w-100% flex-1 mb-16px mt-8px' />
        <OutputExample
          className='px-16px'
          name={data.name}
          value={output}
          showExpanded={true}
          tooltip='节点运行完成后，这些变量将被赋值为返回的内容， 下游节点可以引用这些变量。'
        />
      </CodeNodePanelWrapper>
    </Spin>
  )
}
