import copy from 'copy-to-clipboard'
import { Tooltip, message } from 'antd'
import type { CSSProperties } from 'react'
import { IconFont, usePageModal } from '@/components'
import dingTalkLogo from '@/assets/nodes/dingtalk-logo.svg?url'
import { useFlowDraftStore, useWorkspaceStore } from '@/store'

export interface WebhookProps {
  style?: CSSProperties
}

export function Webhook(props: WebhookProps) {
  const { style } = props
  const { flowId } = useFlowDraftStore()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const pageModal = usePageModal()
  const webhookUrl = `${
    import.meta.env.VITE_AI_API_BASE_URL
  }/v1/public_api/webhook/${flowId}/execute_flow?Access-Key=YOUR_API_KEY&Workspace-Id=${workspaceId}`
  return (
    <div className='wrap p-16' style={style}>
      <div>
        <p className='title text-12px line-height-16px font-500 flex-center-between'>
          <span>Webhook URL</span>
          <span
            className='text-primary cursor-pointer font-400'
            onClick={() =>
              pageModal.show({
                url: `/workspace/${workspaceId}/settings/overview`,
              })
            }
          >
            获取API KEY
          </span>
        </p>
        <Tooltip placement='top' title={webhookUrl}>
          <div className='mt-8 flex w-full items-center justify-between pl-12 pr-5 py-4 rounded-6px bg-bg_3 bg-opacity-8'>
            <p className='text-12px line-height-18px max-w-[calc(100%_-_30px)] text-nowrap overflow-hidden text-ellipsis'>
              {webhookUrl}
            </p>
            <div
              onClick={() => {
                copy(webhookUrl)
                message.success('Webhook URL 已复制到剪切板')
              }}
              className='cursor-pointer flex items-center justify-center cursor-pointer w-24px h-24px rounded-4px hover:bg-#626299 hover:bg-opacity-12 overflow-hidden'
            >
              <IconFont name='copy' className='text-font_1 text-16px' />
            </div>
          </div>
        </Tooltip>
      </div>
      <div className='mt-16 items-start flex px-13 pt-15 pb-12 rounded-6px bg-tips bg-opacity-8'>
        <IconFont name='info' className='text-tips text-18px' />
        <div className='ml-8 mt--0.6'>
          <p className='text-12px line-height-20px font-400'>
            外部系统可以通过以上URL触发流程的执行，通过start变量获取Webhook的入参
          </p>
          <div className='mt-12 flex items-center'>
            <div
              className='flex items-center mr-16 rounded-6px bg-tips bg-opacity-16 hover:bg-white p-4 w-96px'
              onClick={() =>
                pageModal.show({
                  url: 'https://alidocs.dingtalk.com/i/nodes/NZQYprEoWo5Oep9lTg2rYDdnJ1waOeDk?doc_type=wiki_doc#',
                })
              }
            >
              <img src={dingTalkLogo} alt='' />
              <span className='ml-8 text-12px line-height-16px'>钉钉</span>
              <IconFont
                name='left-arrow'
                className='text-7px text-font_1 text-opacity-60 ml-22'
              />
            </div>
            {/* <div className="flex items-center mr-16 rounded-6px bg-tips bg-opacity-16 hover:bg-white p-4 w-96px"> */}
            {/*  <div className="w-24px h-24px bg-white rounded-4px"> */}
            {/*    <img src={feishuLogo} alt="" /> */}
            {/*  </div> */}
            {/*  <span className="ml-8 text-12px line-height-16px">飞书</span> */}
            {/*  <IconFont name="left-arrow" className="text-7px text-font_1 text-opacity-60 ml-22" /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
