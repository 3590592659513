import type { FC } from 'react'
import { useRef } from 'react'
import { QRCode } from 'antd'
import { Button } from '@/components'
import { ShareQRCodeImage } from '@/features/agent/AgentGo/shareQRCode/ShareQRCodeImage.tsx'
import QRCodeBg from '@/assets/agent/qrcode-bg.png'
import {
  drawShareImageBg,
  drawShareImageAvatar,
  drawAgentName,
  drawQRCode,
  drawQRCodeTips,
} from '@/features/agent/AgentGo/shareQRCode/utils.ts'

interface AgentGoWeChatKFShareQRCodeProps {
  authAppName: string
  authAppKFId: string
  avatar: string
}

export const AgentGoWeChatKFShareQRCode: FC<
  AgentGoWeChatKFShareQRCodeProps
> = props => {
  const { authAppName, avatar, authAppKFId } = props

  const canvasEl = useRef<HTMLCanvasElement>(null)
  const qrCodeUrl = `https://work.weixin.qq.com/kfid/${authAppKFId}`

  const popoverMainContent = (
    <div
      className='h-250px rounded-8px relative'
      style={{
        background:
          'radial-gradient(111% 111% at 0% 0%, #5029FF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(49% 25% at 100% 12%, rgba(123, 97, 255, 0.39) 0%, #61C2FF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(175deg, rgba(0, 43, 216, 0.08) 6%, rgba(61, 141, 255, 0.064) 49%, rgba(109, 80, 255, 0) 85%), #FAFAFC',
      }}
    >
      <img
        src={QRCodeBg}
        alt=''
        className='w-172px absolute bottom-8 left-0 right-0 m-auto'
      />
      <div className='w-full absolute z-1 h-full top-0 left-0 flex flex-col items-center'>
        <img src={avatar} alt='' className='rounded-full w-40px h-40px mt-22' />
        <span className='text-12px mt-10 font-600'>{authAppName}</span>
        <QRCode
          size={140}
          bordered={false}
          className='mt--4px'
          value={qrCodeUrl}
        />
        <span className='text-12px mt--4px font-400 scale-75'>
          微信扫一扫 或 长按识别二维码
        </span>
      </div>
    </div>
  )

  const drawShareImage = async (qrCodeUrl: string) => {
    if (canvasEl.current) {
      const ctx = canvasEl.current.getContext('2d')
      if (ctx) {
        const canvasWidth = canvasEl.current.width
        const canvasHeight = canvasEl.current.height
        await drawShareImageBg(ctx, canvasWidth, canvasHeight)
        await drawShareImageAvatar(ctx, avatar, canvasWidth)
        drawAgentName(ctx, authAppName, canvasWidth, 141)
        await drawQRCode(ctx, qrCodeUrl, canvasWidth, 240, 240, 181)
        drawQRCodeTips(ctx, canvasWidth, 450)
      }
    }
  }

  return (
    <ShareQRCodeImage
      canvasRef={canvasEl}
      qrCodeUrl={qrCodeUrl}
      drawShareImage={canvas => drawShareImage(canvas.toDataURL())}
      popoverMainContent={popoverMainContent}
    >
      <Button size='small' type='primary' className='vertical-top mr-8'>
        去使用
      </Button>
    </ShareQRCodeImage>
  )
}
