import { mountStoreDevtool } from 'simple-zustand-devtools'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { useFlowDraftStore, useMovableOptionStore } from '.'

interface FlowInteractionStore {
  // 删除元素前画布标记的ui状态
  dangerStateHandles: string[]
  dangerStateEdgeIds: string[]
  dangerStateNodeIds: string[]
  // 鼠标hover某条连结edge时画布标记的ui状态
  activeEdgeIds: string[]
  activeHandles: string[]
  setDangerStates: (data: {
    handles: string[]
    edgeIds: string[]
    nodeIds: string[]
  }) => void
  clearDangerStates: () => void
  setActiveStates: (data: { handles: string[]; edgeIds: string[] }) => void
  clearActiveStates: () => void
  isAddMode: boolean
  enterAddMode: () => void
  exitAddMode: () => void
  insertActiveEdgeId: string
  setInsertActiveEdgeId: (id: string) => void
}

export const useFlowInteractionStore =
  createWithEqualityFn<FlowInteractionStore>(
    (set, get) => ({
      dangerStateHandles: [],
      dangerStateEdgeIds: [],
      dangerStateNodeIds: [],
      activeEdgeIds: [],
      activeHandles: [],
      setDangerStates: ({ handles, edgeIds, nodeIds }) => {
        set({
          dangerStateEdgeIds: edgeIds,
          dangerStateHandles: handles,
          dangerStateNodeIds: nodeIds,
        })
      },
      clearDangerStates: () => {
        set({
          dangerStateEdgeIds: [],
          dangerStateHandles: [],
          dangerStateNodeIds: [],
        })
      },
      setActiveStates: ({ handles, edgeIds }) => {
        set({
          activeEdgeIds: edgeIds,
          activeHandles: handles,
        })
      },
      clearActiveStates: () => {
        set({
          activeEdgeIds: [],
          activeHandles: [],
        })
      },
      isAddMode: false,
      enterAddMode: () => {
        set({
          isAddMode: true,
        })
        // 进入添加模式时取消所有选中节点
        const { nodes, setNodes } = useFlowDraftStore.getState()
        setNodes(
          nodes.map(n => ({
            ...n,
            selected: false,
          })),
        )
      },
      exitAddMode: () => {
        set({
          isAddMode: false,
        })
        get().clearActiveStates()
        useMovableOptionStore.getState().hide()
      },
      insertActiveEdgeId: '',
      setInsertActiveEdgeId: id => {
        set({
          insertActiveEdgeId: id,
        })
      },
    }),
    shallow,
  )

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('flowInteractionStore', useFlowInteractionStore)
}
