import type { InputProps, InputRef } from 'antd'
import { useRef, useState } from 'react'
import type { TextAreaProps } from 'antd/es/input'
import { Input, TextArea } from '@/components'
import { Size } from '../constant'

export interface ExpandableInputProps extends InputProps {
  maxRows?: number
}

export function ExpandableInput({
  size,
  maxRows,
  onFocus,
  ...restProps
}: ExpandableInputProps) {
  const inputRef = useRef<InputRef>(null)

  const [expanded, setExpanded] = useState(false)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const input = inputRef.current?.input
    if (input && input.scrollWidth > input.clientWidth) {
      setExpanded(true)
    }
    onFocus?.(e)
  }

  const handleBlur = () => {
    setExpanded(false)
  }

  return expanded ? (
    <div
      className='w-full relative z-1'
      style={{ height: Size[size || 'middle'] }}
    >
      <TextArea
        className='absolute top-0 left-0 w-full bg-white'
        {...(restProps as TextAreaProps)}
        autoSize={{ maxRows: maxRows || 3 }}
        autoFocus
        onBlur={handleBlur}
      />
    </div>
  ) : (
    <Input ref={inputRef} {...restProps} size={size} onFocus={handleFocus} />
  )
}
