import { memo, useState } from 'react'
import { Spin, Tooltip } from 'antd'
import { useRequest } from 'ahooks'
import { Card } from '../../components/Card'
import { IconFont } from '@/components'
import { useWorkspaceStore } from '@/store'
import { getWorkspaceUsageOverview, StaticTimeTypes } from '@/apis/monitor'
import { AnalyzeTimeFilter } from '@/features/analyze/components/AnalyzeTimeFilter'

function mapTypeToPointKey(type: string) {
  return {
    pointsKey: `${type}_consumed_points`,
    changeKey: `${type}_consumed_points_change`,
  }
}

function WorkspacePointCardContent() {
  const workspace_id = useWorkspaceStore(state => state.currentWorkspaceId)

  const [overViewTimeParams, setOverviewTimeParams] = useState<{
    statistic_time: StaticTimeTypes
    start_date?: string
    end_date?: string
  }>({
    statistic_time: StaticTimeTypes.YEARLY,
  })

  const { data: overviewData, loading: getWorkspaceOverviewLoading } =
    useRequest(() => getWorkspaceUsageOverview(overViewTimeParams as any), {
      refreshDeps: [workspace_id, overViewTimeParams],
    })

  return (
    <Card>
      <div className='text-16px/16px c-font font-600 flex items-center mb-16px py-7px'>
        平台积分用量
        <Tooltip
          title={
            <div>
              <p className='leading-20px'>
                Agent与工作流在运行过程中所消耗的积分量
              </p>
              <p className='leading-20px'>（不含插件积分用量）</p>
              <p className='leading-20px'>
                *除「今日消耗」外，其他统计项均不包含当日用量数据
              </p>
            </div>
          }
          placement='bottom'
          overlayInnerStyle={{ width: '320px' }}
        >
          <IconFont
            name='jieshishuimeng'
            className='text-14px cursor-pointer ml-5px'
            style={{ color: 'rgba(141, 141, 153, 0.4)' }}
          />
        </Tooltip>
      </div>
      <Spin spinning={getWorkspaceOverviewLoading}>
        <div className='mt-24 flex flex-col'>
          <div className='mb-36'>
            <div className='text-14px c-#17171D mb-7'>今日消耗</div>
            <div className='flex items-end'>
              <span className='text-24px'>
                {(overviewData?.daily_consumed_points || 0).toLocaleString()}
              </span>
            </div>
          </div>
          <div className='mb-36'>
            <div className='text-14px text-font mb-7'>本周消耗</div>
            <div className='flex items-end'>
              <span className='text-24px'>
                {(overviewData?.weekly_consumed_points || 0).toLocaleString()}
              </span>
            </div>
          </div>
          <>
            <div className='text-14px text-font mb-7'>
              <AnalyzeTimeFilter
                suffixText='消耗'
                titleClassName='text-14px! font-normal'
                dafaultType={StaticTimeTypes.YEARLY}
                onTypeChange={data => {
                  if (
                    data.type === StaticTimeTypes.CUSTOM &&
                    !(data?.startDate && data?.endDate)
                  ) {
                    return
                  }
                  setOverviewTimeParams({
                    start_date: data.startDate,
                    end_date: data.endDate,
                    statistic_time: data.type as StaticTimeTypes,
                  })
                }}
              />
            </div>
            <div className='flex items-end'>
              <span className='text-24px'>
                {(
                  overviewData?.[
                    mapTypeToPointKey(overViewTimeParams?.statistic_time)
                      .pointsKey
                  ] || 0
                ).toLocaleString()}
              </span>
            </div>
          </>
        </div>
      </Spin>
    </Card>
  )
}

export const WorkspacePointCard = memo(WorkspacePointCardContent)
