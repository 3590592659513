import { useMemo, type FC, type ReactNode } from 'react'
import cn from 'classnames'
import { Button, IconFont } from '@/components'

interface EmptyProps {
  text: string | ReactNode
  btnText?: string
  btnClassName?: string
  image: string
  button?: ReactNode
  bottom?: ReactNode
  onCreate?: () => void
}

export const Empty: FC<EmptyProps> = props => {
  const {
    image,
    text,
    btnText,
    onCreate,
    btnClassName = '',
    button,
    bottom,
  } = props

  const showButton = useMemo(() => {
    if (button) return button

    return (
      <Button
        className={cn('mt-24px', btnClassName)}
        type='primary'
        icon={<IconFont name='add' />}
        size='large'
        onClick={onCreate}
      >
        {btnText}
      </Button>
    )
  }, [button, btnClassName, btnText, onCreate])

  return (
    <div className='w-full h-full flex-center'>
      <div className='flex flex-col items-center justify-center relative'>
        <img className='shrink-0 w-128px h-128px' src={image} alt='' />
        <div className='mt-20 c-font_1 font-400'>{text}</div>
        {showButton}
        {bottom && (
          <div className='absolute top-100% flex-center'>{bottom}</div>
        )}
      </div>
    </div>
  )
}
