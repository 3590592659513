import { memo, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useRequest } from 'ahooks'
import MonitorDrawer from '@/components/MonitorDrawer'
import { getTimeRange, SearchType } from '@/pages/workspace/settings/Data'
import {
  getWorkspaceOverview,
  type workspaceOverview,
} from '@/apis/workspaceCombo'
import { useWorkspaceStore } from '@/store'
import { usePageModal } from '@/components'

const options = [
  {
    value: SearchType.TODAY,
    label: '今日',
  },
  {
    value: SearchType.WEEK,
    label: '本周',
  },
  {
    value: SearchType.MONTH,
    label: '本月',
  },
  {
    value: SearchType.YEAR,
    label: '本年',
  },
  {
    value: SearchType.CUSTOM,
    label: '自定义',
  },
]

interface PointUsageModalProps {
  onClose: () => void
  currentDate: string
}

function InternalPointUsageModal(props: PointUsageModalProps) {
  const pageModal = usePageModal()

  const [rangeTime, setRangeTime] = useState<any>(() => {
    if (props.currentDate) {
      return [
        dayjs(props.currentDate).startOf('d'),
        dayjs(props.currentDate).endOf('d'),
      ]
    }
    return [dayjs().startOf('w'), dayjs()]
  })
  const workspace_id = useWorkspaceStore(state => state.currentWorkspaceId)
  const { data, loading, run } = useRequest(
    () =>
      getWorkspaceOverview({
        workspace_id,
        start_time: rangeTime?.[0]
          ?.startOf('day')
          ?.format('YYYY-MM-DD HH:mm:ss'),
        end_time: rangeTime?.[1]?.endOf('day')?.format('YYYY-MM-DD HH:mm:ss'),
      }),
    { manual: true },
  )

  const { dataSource, total } = useMemo(() => {
    const dataSource = (data?.appDimensionBaseInfo || [])?.map(
      (item: any, index) =>
        ({
          key: index,
          ...item,
        }) || [],
    ) as workspaceOverview['appDimensionBaseInfo']
    return {
      dataSource,
      total: dataSource.length || 0,
    }
  }, [data])

  const onFilterChange = (value: string) => {
    if (value === SearchType.CUSTOM) {
      setRangeTime([null, null])
      return
    }
    const [startTime, endTime] = getTimeRange(value as SearchType)
    setRangeTime([dayjs(startTime), dayjs(endTime)])
  }

  useEffect(() => {
    rangeTime && run()
  }, [rangeTime])

  return (
    <>
      <MonitorDrawer
        loading={loading}
        title='平台积分用量统计'
        onClose={props.onClose}
        columns={[
          {
            title: '应用名称',
            dataIndex: 'appName',
            ellipsis: true,
          },
          {
            title: '应用类型',
            dataIndex: 'application_type',
            ellipsis: true,
            render: value => {
              return value === 'AGENT' ? 'Agent' : '工作流'
            },
          },
          {
            title: '消耗积分',
            dataIndex: 'consume_points',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.consume_points - b.consume_points,
          },
          {
            title: '操作',
            key: 'operation',
            sorter: true,
            render: (_, { app_id, application_type }) => {
              return (
                <div
                  className='c-#7b61ff cursor-pointer'
                  onClick={() => {
                    pageModal.show({
                      url:
                        application_type === 'AGENT'
                          ? `/agent/${app_id}/log`
                          : `/application/${app_id}/日志?tab=log`,
                    })
                  }}
                >
                  查看日志
                </div>
              )
            },
          },
        ]}
        dataSource={dataSource}
        onFilterChange={onFilterChange}
        onRangePickerChange={setRangeTime}
        filter={{
          defaultValue: props?.currentDate
            ? SearchType.CUSTOM
            : SearchType.WEEK,
          options,
          defaultSubValue: props?.currentDate
            ? [
                dayjs(props.currentDate).startOf('d'),
                dayjs(props.currentDate).endOf('d'),
              ]
            : undefined,
          customFilterValue: SearchType.CUSTOM,
        }}
        total={total}
      />
    </>
  )
}

export const PointUsageModal = memo(InternalPointUsageModal)
