import classNames from 'classnames'
import type { CSSProperties } from 'react'
import { IconFont } from '@/components'

interface CollapseButtonProps {
  collapsed: boolean
  className: string
  style: CSSProperties
  onToggle: () => void
}

export function CollapseButton(props: CollapseButtonProps) {
  const { collapsed, className, style, onToggle } = props

  return (
    <div
      className={classNames(
        className,
        'flex-center w-22px h-22px b-rd-4px b-1 b-#e1e1e5 b-op-60 z-3 cursor-pointer',
      )}
      style={style}
      onClick={onToggle}
    >
      <IconFont
        name={collapsed ? 'zhankaichouti' : 'shouqichouti'}
        className='text-#626999 text-op-60'
      />
    </div>
  )
}
