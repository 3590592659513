import { useBoolean, useRequest } from 'ahooks'
import { Popover } from 'antd'
import { forkingResourcesDetail } from '@/apis/application'
import { AppLogo, Checkbox } from '@/components'
import RocketImage2 from '@/assets/app/rocket2.png'

interface FlowDataProps {
  color: string
  icon: string
  flow_name: string
}
interface DataSetsProps {
  partition_name: string
  partition_icon: string
  partition_icon_color: string
  file_count: number
}

interface DataBaseProps {
  icon_color: string
  icon: string
  title: string
  table_count: number
}
interface ConnectContentProps {
  selectedConnect: boolean
  appId?: string
  isDisabled: boolean
  setSelectConnect: (value: boolean) => void
}

interface ResourceItemProps {
  type: 'database' | 'dataset' | 'flow'
  icon: string
  color: string
  title: string
  count?: number
}

export enum SelectType {
  DATABASE = 'database',
  DATASET = 'dataset',
  FLOW = 'flow',
  AGENT = 'Agent',
}
export enum AccessType {
  DATASET = '知识库',
  DATABASE = '数据库',
  FLOW = '工作流',
}
function Empty() {
  return (
    <div className='flex flex-col items-center py-40px'>
      <img src={RocketImage2} alt='empty' className='w-100px h-100px' />
      <span className='text-12px/12px text-font_2'>暂无内容</span>
    </div>
  )
}
const ResourceItem: React.FC<ResourceItemProps> = ({
  type,
  icon,
  color,
  title,
  count,
}) => {
  // 根据资源类型获取对应的展示标签
  const getAccessTypeName = (type: string) => {
    switch (type) {
      case SelectType.DATASET:
        return AccessType.DATASET
      case SelectType.DATABASE:
        return AccessType.DATABASE
      case SelectType.FLOW:
        return AccessType.FLOW
      default:
        return ''
    }
  }
  return (
    <div className='flex items-center justify-between p-8px'>
      <div className='flex items-center'>
        <AppLogo
          type={
            type === SelectType.DATASET
              ? 'icon'
              : icon?.startsWith('http')
                ? 'image'
                : 'emoji'
          }
          color={color}
          value={icon}
          size={24}
          fillSize={14}
          className='!rounded-4px mr-8px'
        />
        <span>{title}</span>
        <div
          className='border-1 ml-12px w-48px h-16px rounded-4px text-12px color-#8D8D99 flex-center'
          style={{ borderColor: 'rgba(225, 225, 229, 0.6)' }}
        >
          {getAccessTypeName(type)}
        </div>
      </div>
      <div className='flex items-center'>
        {count && (
          <span className='c-#8D8D99 text-12px/12px'>
            {count} {type === SelectType.DATASET ? ' 文档' : ' 数据表'}
          </span>
        )}
      </div>
    </div>
  )
}

export default function ShowConnectContent(props: ConnectContentProps) {
  const { selectedConnect, appId, setSelectConnect, isDisabled } = props
  const [open, { set: setOpen }] = useBoolean(false)
  const { data: segmentedData, runAsync: fetchSegmentedData } = useRequest(
    forkingResourcesDetail,
    {
      manual: true,
    },
  )
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
    if (newOpen) {
      fetchSegmentedData(appId ?? '')
    }
  }

  const isEmpty = [
    segmentedData?.database_resources,
    segmentedData?.datasets_resources,
    segmentedData?.flow_resources,
  ].every(resource => resource?.length === 0)
  const Assets = () => {
    return (
      <div className='px-4px w-420px'>
        <div className='text-14px/16px my-8px ml-10px  font-500'>关联资源</div>
        <div className='h-200px overflow-y-auto'>
          {isEmpty ? (
            <Empty />
          ) : (
            <>
              {segmentedData?.datasets_resources?.map(
                (item: DataSetsProps, index: number) => (
                  <ResourceItem
                    type={SelectType.DATASET}
                    icon={item.partition_icon}
                    color={item.partition_icon_color}
                    title={item.partition_name}
                    count={item.file_count}
                    key={index.toString()}
                  />
                ),
              )}
              {segmentedData?.database_resources?.map(
                (item: DataBaseProps, index: number) => (
                  <ResourceItem
                    type={SelectType.DATABASE}
                    icon={item.icon}
                    color={item.icon_color}
                    title={item.title}
                    count={item.table_count}
                    key={index.toString()}
                  />
                ),
              )}
              {segmentedData?.flow_resources?.map(
                (item: FlowDataProps, index: number) => (
                  <ResourceItem
                    type={SelectType.FLOW}
                    icon={item.icon}
                    color={item.color}
                    title={item.flow_name}
                    key={index.toString()}
                  />
                ),
              )}
            </>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className='flex items-center mt-12px text-12px/16px'>
      <Checkbox
        className='!mb-0px mr-8px'
        checked={selectedConnect}
        disabled={isDisabled}
        onChange={e => setSelectConnect(e.target.checked)}
      />
      <span className='c-#8D8D99'>同步复制&nbsp;</span>
      <Popover
        trigger='click'
        open={open}
        arrow={false}
        content={Assets}
        placement='bottom'
        overlayStyle={{ left: '-15px', top: '140px' }}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        onOpenChange={handleOpenChange}
      >
        <p className='c-#7B61FF cursor-pointer relative'>关联的资源</p>
      </Popover>
    </div>
  )
}
