import type { ReactNode } from 'react'
import AceEditor from 'react-ace'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { isNil, isObject, isString } from 'lodash-es'
import copy from 'copy-to-clipboard'
import { Button, Collapse, message } from 'antd'
import classNames from 'classnames'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useMemoizedFn } from 'ahooks'
import { isEmptyString, isMarkdown } from '../util'
import { GeneratedByAIText, IconFont } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar.ts'
import {
  StringContent,
  SwitchOutputDisplay,
} from '@/features/logger/components/StringContent'

export interface OutputProps {
  knowledgeBucket?: string
  scrollToEnd?: boolean
  result?: string | null | any
  remarks?: string
  title: ReactNode
  operations?: Array<'diff' | 'copy'>
  aiTextMark?: boolean
  className?: string
  diff?: boolean
  onDiff?: () => void
}

export const Output: React.FC<OutputProps> = memo(props => {
  const {
    knowledgeBucket,
    scrollToEnd,
    result,
    remarks,
    title,
    operations = [],
    aiTextMark = false,
    className,
    onDiff,
    diff,
  } = props

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const scrollToBottom = useMemoizedFn(() => {
    const viewport = scrollRef.current?.osInstance()?.elements().viewport
    viewport?.scroll({
      top: viewport.scrollHeight + 200,
    })
  })

  const ref = useRef(null)
  const [showCard, setShowCard] = useState(!!knowledgeBucket)

  const isEmpty = useMemo(
    () => isNil(result) || isEmptyString(result),
    [result],
  )

  const isMarkdownType = useMemo(
    () => !isEmpty && isString(result) && isMarkdown(result),
    [result, isEmpty],
  )

  const isCodeType = useMemo(
    () => !isMarkdownType && !isString(result),
    [isMarkdownType, result],
  )

  const items = useMemo(() => {
    return [
      {
        key: '1',
        label: <div className='text-#AA915A text-12px ml-[-4px]'>备注</div>,
        children: (
          <p className='text-#AA915A text-12px leading-18px'>{remarks}</p>
        ),
      },
    ]
  }, [remarks])

  const onCopy = () => {
    const text = isObject(result) ? JSON.stringify(result, null, 2) : result
    copy(text)
    message.success('复制成功')
  }

  useEffect(() => {
    setShowCard(!!knowledgeBucket)
  }, [knowledgeBucket])

  useEffect(() => {
    if (!scrollToEnd) return
    scrollToBottom()
  }, [scrollToEnd, result])

  return (
    <div
      ref={ref}
      className={classNames(
        'b-op-60 h-full relative w-full flex flex-col [&:hover_.output-button]:opacity-100',
        className,
      )}
    >
      {title && (
        <div className='flex items-center mb-12px h-24px flex-none px-20px'>
          <div className='text-14px text-font flex items-center text-#8D8D99 italic'>
            {title}
          </div>

          <div className='ml-auto flex'>
            {operations.includes('diff') && (
              <Button
                type='text'
                size='small'
                onClick={onDiff}
                className='output-button opacity-0 flex-center text-12px! text-#17171D ml-12px !px-4px ![&_.ant-btn-icon]:mr-4px'
                icon={<IconFont name='duibi' className='text-14px' />}
              >
                {diff ? '关闭对比' : '打开对比'}
              </Button>
            )}

            {operations.includes('copy') && (
              <Button
                type='text'
                size='small'
                className='output-button opacity-0 flex-center text-12px! text-#17171D ml-12px !px-4px ![&_.ant-btn-icon]:mr-4px'
                icon={<IconFont name='fuzhi2' className='text-14px' />}
                onClick={onCopy}
              >
                复制
              </Button>
            )}

            {isMarkdownType && knowledgeBucket && (
              <SwitchOutputDisplay
                className='ml-12px'
                value={showCard}
                onChange={setShowCard}
              />
            )}
          </div>
        </div>
      )}

      {remarks && (
        <div className='px-20px mb-12px'>
          <Collapse
            className='bg-#FFF5DF rounded-6px [&_.ant-collapse-header]:items-center! [&_.ant-collapse-content-box]:pt-0px!'
            items={items}
            bordered={false}
            size={'small'}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => (
              <IconFont
                name='left-arrow'
                className={classNames('text-8px! text-#AA915A! duration-300', {
                  'rotate-90': isActive,
                })}
              />
            )}
          />
        </div>
      )}

      {isEmpty && (
        <div className='h-full flex-center c-bg_3 c-op-40 px-20px'>
          emmm, 流程貌似没有输出内容
        </div>
      )}

      {isMarkdownType && !isEmpty && (
        <OverlayScrollbarsComponent
          ref={scrollRef}
          className='overflow-auto rounded-8px flex-1 px-20px'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <StringContent
            closeCopy
            content={result}
            bucketId={knowledgeBucket}
            showCard={showCard}
          />
        </OverlayScrollbarsComponent>
      )}

      {isCodeType && !isMarkdownType && (
        <div className='flex-auto rounded-8px px-20px'>
          <AceEditor
            value={JSON.stringify(result, null, 2)}
            theme='tomorrow'
            highlightActiveLine={false}
            readOnly
            height='100%'
            width='100%'
            mode='json'
            setOptions={{ wrap: true }}
          />
        </div>
      )}

      {aiTextMark && (
        <div className='flex items-center mt-8px'>
          <GeneratedByAIText />
        </div>
      )}
    </div>
  )
})
