import { message } from 'antd'
import { useMemo, useRef, useState } from 'react'
import copy from 'copy-to-clipboard'
import { cloneDeep, isString } from 'lodash-es'
import styled from '@emotion/styled'
import type {
  nodeConfigType,
  singleExecuteParams,
  singleExecuteType,
} from '@/apis/run'
import { isMarkdown } from '@/pages/flowPage/util'
import {
  Button,
  GeneratedByAIText,
  IconFont,
  Markdown,
  NodeForm,
} from '@/components'
import { FlowStatus } from '@/apis/flow'
import type { ApplicationBodyType } from '@/apis/application'
import { CodeEditor } from '@/features/editor'
import {
  useStreamingExecuteFlow,
  StreamMessageType,
} from '@/hooks/useStreamingExecuteFlow'
import { statusList } from '@/pages/application/components/appDetail/utils'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { API_CODE_CODE } from '@/constants/request'
import { useGenerateMarkdownLimitedInfo } from '@/features/pay/LimitedAlert'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils.ts'

interface propsType {
  text: string
  handleLimitedModalClick: (url: string) => void
}

const CodeEditorWrapper = styled(CodeEditor)`
  .ace_editor {
    width: 100% !important;
  }
`

function RenderItem(props: propsType) {
  const { scrollRef } = useScrollBar()

  return (
    <div className='h-full flex flex-col'>
      <div
        className='flex-1 bg-#fff border-rd-8px px-20px py-16px overflow-hidden flex flex-col'
        style={{ border: '1px solid rgba(225, 225, 229, 0.6)' }}
      >
        <div
          ref={scrollRef}
          className='line-height-24px m-b-12px text-14px color-#17171D overflow-y-auto'
        >
          <div>
            {props?.text && isString(props?.text) && isMarkdown(props?.text) ? (
              <div className='b-rd-8px b-#e1e1e5 b-op-60'>
                <Markdown
                  text={props.text}
                  onHrefTagClick={url => {
                    // TODO 后续优化代码 暂时使用写死方法
                    if (url.includes('show-bty-limited-action')) {
                      props.handleLimitedModalClick(url)
                    } else {
                      window.open(url)
                    }
                  }}
                />
              </div>
            ) : (
              props?.text &&
              !isString(props?.text) && (
                <CodeEditorWrapper
                  readOnly
                  value={JSON.stringify(props?.text, null, 2)}
                  mode='json'
                />
              )
            )}
          </div>
        </div>
      </div>
      <div className='mt-12px flex flex-items-center justify-between'>
        <GeneratedByAIText />
        <Button
          style={{
            height: 28,
            fontWeight: 400,
            borderRadius: 6,
            boxShadow: 'none',
          }}
          className='text-12px flex flex-items-center'
          icon={<IconFont name='copy' className='text-16px color-#8d8d99' />}
          onClick={() => {
            copy(props.text)
            message.success('复制成功！')
          }}
        >
          复制
        </Button>
      </div>
    </div>
  )
}

interface Props {
  data?: Partial<ApplicationBodyType>
  firstNodeConfig: nodeConfigType | null
  beforeRun?: () => boolean | Promise<boolean>
}

export default ({ data, firstNodeConfig, beforeRun }: Props) => {
  const [status, setStatus] = useState<'start' | 'complete' | 'loading'>(
    'start',
  )
  const [result, setResult] = useState<Partial<singleExecuteType> | undefined>()
  const currentScrollHeight = useRef<any>()
  const scrollDiv = useRef<HTMLDivElement>(null)
  const [form] = NodeForm.useForm()
  const messageType = useRef<StreamMessageType>(StreamMessageType.PIECEWISE)
  const getParams = async (): Promise<singleExecuteParams> => {
    const formData = await form.validateFields()
    return {
      flowId: data?.flowId || '',
      env: 'ONCE_RUN',
      versionId: firstNodeConfig?.version_id || '',
      inputData: formData,
    }
  }

  const [limitedMarkdownInfo, handleLimitedModalClick] =
    useGenerateMarkdownLimitedInfo({
      prefix: '该工作流所在的',
    })

  const { startExecute } = useStreamingExecuteFlow({
    socketUrl: '/v1/flow/ws_execute_flow',
    flowId: data?.flowId,
    onStepStart: ({ message_type }) => {
      messageType.current = message_type
      setResult(result ? { ...result, run_result: '' } : undefined)
    },
    onMessage: message => {
      const newMessage =
        messageType.current === StreamMessageType.PIECEWISE
          ? `${result?.run_result || ''}${message}`
          : message
      setResult({ status: FlowStatus.SUCCEEDED, run_result: newMessage })
      if (scrollDiv.current?.scrollHeight !== currentScrollHeight.current) {
        scrollDiv.current?.scrollTo(0, scrollDiv.current.scrollHeight)
      }
      currentScrollHeight.current = scrollDiv.current?.scrollHeight
    },
    onFinish: result => {
      const cloneResult = cloneDeep(result)
      if (result?.code === API_CODE_CODE.COMBO_NOT_ENOUGH) {
        cloneResult.message = limitedMarkdownInfo
      }
      setResult(cloneResult)
      setStatus('complete')
    },
  })

  const onRun = async () => {
    const handleRun = async () => {
      const params = await getParams()
      setStatus('loading')
      setResult(undefined)
      return startExecute(params)
    }
    if (beforeRun) {
      const allowRun = await beforeRun()
      allowRun && handleRun()
    } else {
      handleRun()
    }
  }

  const formConfig = useMemo(() => {
    if (firstNodeConfig?.form_config) {
      return firstNodeConfig?.form_config.map(item => ({
        ...item,
        className: item.type === 'textarea' ? 'resize-y!' : '',
      }))
    }
    return []
  }, [firstNodeConfig])

  return (
    <div className='h-full flex flex-justify-between flex-1 adapt:gap-20 overflow-hidden'>
      <div className='flex-2 shrink-0 border-rd-12px bg-#fff flex-justify-between overflow-hidden flex flex-col'>
        <div className='overflow-y-auto flex-1 adapt:pt-24 adapt:px-20'>
          <NodeForm
            form={form}
            schema={transformConfig2SchemaConfig(formConfig)}
          />
        </div>
        <div className='flex gap-12px adapt:px-20 adapt:pb-24'>
          <Button
            disabled={status === 'loading'}
            className='w-80px'
            style={{ height: 36 }}
            size='large'
            onClick={() => form.resetFields()}
          >
            重置
          </Button>
          <Button
            style={{ height: 36 }}
            loading={status === 'loading'}
            className='flex-1'
            type='primary'
            size='large'
            onClick={onRun}
          >
            运行
          </Button>
        </div>
      </div>
      <div className='max-w-[calc(60%-12px)] flex-3 border-rd-12px adapt:px-20 adapt:py-24 bg-#fff flex flex-col'>
        <div className='color-#17171D text-16px font-500 adapt:mb-20'>
          生成结果
        </div>
        <div ref={scrollDiv} className='overflow-hidden flex-1 flex flex-col'>
          {status === 'complete' ||
          (status === 'loading' && result?.run_result) ? (
            <RenderItem
              handleLimitedModalClick={handleLimitedModalClick}
              text={
                (result?.status === FlowStatus.FAIL
                  ? result.message?.step_err_info
                  : result?.run_result) ||
                result?.message ||
                ''
              }
            />
          ) : (
            <div
              style={{ color: 'rgba(98, 105, 153, 0.4)' }}
              className='h-full flex flex-justify-center flex-items-center flex-col text-14px'
            >
              {statusList[status].icon || ''}
              {statusList[status].text}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
