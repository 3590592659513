import styled from '@emotion/styled'
import { FormItem } from '@/components'

export const NodeFormItem = styled(FormItem)`
  margin-bottom: 16px;
  .keyu-form-item-label {
    font-size: 12px;
    font-weight: 500;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 12px;
  }
` as typeof FormItem
