import type { IHead } from '@/components/resize-table/type'

export const checkHeads: IHead[] = [
  {
    key: 'batch-test-row-checkbox',
    isRowSelect: true,
    title: '',
    width: 50,
    sticky: true,
  },
]

export const allPagesize = [
  { label: '10 条/页', value: 10 },
  { label: '20 条/页', value: 20 },
  { label: '50 条/页', value: 50 },
  { label: '100 条/页', value: 100 },
]

export const widthMap: Record<string, [number, number]> = {
  textarea: [450, 450],
  json: [450, 450],
  output: [450, 450],
  input: [200, 200],
  file: [200, 200],
  select: [120, 120],
  multiSelect: [120, 120],
  similarity: [120, 120],
  remarks: [200, 200],
}

export const placeholderMap: Record<string, string> = {
  correct_result: '填写后可以和运行结果比对，非必填',
  output: '运行后自动生成',
}

export const DEFAULT_PAGESIZE = 100
export const DEFAULT_LIMIT_SIZE = 50
