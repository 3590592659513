import { useBoolean, useClickAway } from 'ahooks'
import { message } from 'antd'
import type { RefObject } from 'react'
import { useState } from 'react'
import { useReactFlow } from 'reactflow'

interface EditNameOptions {
  id: string
  saveCallback?: (name: string) => void
  target: RefObject<HTMLElement>
  initialIsEdit?: boolean
}

const validateMessage = '名称必须是英文字符、数字、下划线且不包含特殊字符'

export function useEditName(initName: string, options: EditNameOptions) {
  const { id, saveCallback, target, initialIsEdit } = options
  const [isEdit, { toggle }] = useBoolean(initialIsEdit || false)
  const { getNodes } = useReactFlow()

  const [_name, setName] = useState(initName)
  const validateName = (text: string) => {
    const isValidate = /^[A-Za-z0-9_]+$/.test(text)

    return {
      pass: isValidate,
      validateMessage: !isValidate ? validateMessage : '',
    }
  }

  const onSaveTitle = () => {
    if (!_name?.trim()) {
      setName(initName)
      toggle()
      return
    }
    const { pass, validateMessage } = validateName(_name)
    if (!pass) {
      message.error(validateMessage)
      setName(initName)
      toggle()
      return
    }

    const nodes = getNodes()
    if (nodes.filter(v => v.id !== id).some(v => v.data.name === _name)) {
      message.error('该节点名已存在')
      setName(initName)
      toggle()
      return
    }

    saveCallback?.(_name)
    toggle()
  }

  useClickAway(onSaveTitle, target)

  return {
    name: _name,
    setName,
    isEdit,
    onSaveTitle,
    toggle,
  }
}
