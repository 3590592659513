import { useMemoizedFn } from 'ahooks'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNodeStore } from '@/store'
import {
  DEBUG_TASK_ID_MAP,
  DEBUG_TASK_MSG_MAP,
  DEBUG_TEST_ID_MAP,
} from '@/features/logger/constants'
import { useSessionValue } from '@/features/logger/components/loggerModal/hooks/useSessionValue'
import { useFlowDraftValidate } from './useFlowDraftValidate'
import { useLastFlowTaskId } from './useLastFlowTaskId'

export function useFlowDraftRunDebug() {
  const { id } = useParams()

  const { validate } = useFlowDraftValidate()
  const { activatedNodeId } = useNodeStore()
  const getLastFlowTaskId = useLastFlowTaskId(id!)

  const [taskId, setTaskId] = useSessionValue(DEBUG_TASK_ID_MAP, id!)
  const [testDataId, setTestDataId] = useSessionValue(DEBUG_TEST_ID_MAP, id!)
  const [, setTaskMsg] = useSessionValue(DEBUG_TASK_MSG_MAP, id!)

  const [debugOpen, setDebugOpen] = useState(!!taskId)
  const [logsOpen, setLogOpen] = useState(false)

  const openDebugModal = useMemoizedFn(async () => {
    setDebugOpen(true)
    setLogOpen(false)
  })

  const openLogsModal = useMemoizedFn(() => {
    setDebugOpen(false)
    setLogOpen(true)
  })

  const onClose = useMemoizedFn(() => {
    setDebugOpen(false)
    setLogOpen(false)
    setTaskId(null)
    setTaskMsg(null)
  })

  const startDebug = useMemoizedFn(async () => {
    const pass = await validate()
    if (!pass) return

    if (!taskId && !testDataId) {
      const lastTaskId = await getLastFlowTaskId()
      setTaskId(lastTaskId)
      setTaskMsg(null)
      openDebugModal()
    } else {
      openDebugModal()
    }
  })

  const debugByTaskId = useMemoizedFn(
    async (taskId: string, extra?: Record<string, any>) => {
      setTaskId(taskId)
      setTaskMsg(extra ?? null)
      setTestDataId(null)
      openDebugModal()
    },
  )

  useEffect(() => {
    if (activatedNodeId) {
      onClose()
    }
  }, [activatedNodeId])

  return {
    flowId: id!,
    debugOpen,
    logsOpen,
    startDebug,
    showLogs: openLogsModal,
    onClose,
    debugByTaskId,
  }
}
