import { useRequest, useThrottleFn } from 'ahooks'
import { useMemo } from 'react'
import type { BatchTestProgressType } from '@/apis/batchTest'
import { getBatchTestCount, getBatchTestProgress } from '@/apis/batchTest'

export interface UseBatchTestProgressOptions {
  flowId: string
  onSuccess?: (data: BatchTestProgressType) => void
}

export function useBatchTestProgress(options: UseBatchTestProgressOptions) {
  const { flowId, onSuccess } = options
  const {
    data,
    refresh: refreshBatchTestProgress,
    cancel,
  } = useRequest(() => getBatchTestProgress(flowId), {
    refreshDeps: [flowId],
    ready: !!flowId,
    manual: true,
    onSuccess,
  })
  const { run: refreshBatchTestProgressTR } = useThrottleFn(
    refreshBatchTestProgress,
    {
      wait: 2000,
      leading: false,
    },
  )
  const { data: testCount, refresh: updateTestCount } = useRequest(
    () => getBatchTestCount(flowId),
    {
      refreshDeps: [flowId],
      ready: !!flowId,
    },
  )

  const runningDoneSteps = useMemo(() => {
    return (
      (data?.ERROR || 0) +
      (data?.SUCCEEDED || 0) +
      (data?.TIMEOUT || 0) +
      (data?.FAILED || 0)
    )
  }, [data])

  return {
    progress: data,
    runningDoneSteps,
    totalSteps: data?.total || 0,
    refresh: refreshBatchTestProgressTR,
    cancel,
    testCount: testCount?.total || 0,
    updateTestCount,
  }
}
