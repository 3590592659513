import { useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useMemoizedFn } from 'ahooks'
import { type InnerNodePanelProps } from '@/features/nodes/base'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { Button, IconFont } from '@/components'
import {
  PUB_FLOW_SIGNAL,
  RUN_FLOW_SIGNAL,
  UPDATE_FLOW_SIGNAL,
} from '@/pages/flowPage/const'
import { pubSignal } from '@/hooks/useSignal'

export function TemplateNodePanel(props: InnerNodePanelProps<any>) {
  const { data } = props

  const { registerTriggerNode, setExtraRightButton } = useContext(
    DebugResultPanelContext,
  )
  const { workspaceId } = useParams()
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const flowSrc = useMemo(() => {
    if (!data) return ''
    return `/application/${workspaceId}/${data.flow_id}/flow/${data.flow_id}?hiddenHeader=true&isTemplateFlow=true`
  }, [data, workspaceId])

  const startRun = useMemoizedFn(async () => {
    if (!iframeRef.current?.contentWindow) return
    pubSignal(RUN_FLOW_SIGNAL, iframeRef.current?.contentWindow)
  })

  const pubFlow = useMemoizedFn(async () => {
    if (!iframeRef.current?.contentWindow) return
    pubSignal(PUB_FLOW_SIGNAL, iframeRef.current?.contentWindow)
    pubSignal(UPDATE_FLOW_SIGNAL, window)
  })

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button className='ml-8px !p-0 w-66px !h-32px text-12px text-primary !hover:text-primary gap-4px hover:bg-#626999 hover:bg-op-12'>
          <IconFont name='yunxing' />
          运行
        </Button>
      ),
      () => startRun(),
    )
  }, [data])

  useEffect(() => {
    setExtraRightButton(
      <Button
        type='primary'
        className='!h-32px w-66px text-12px !p-0 bg-op-60 flex items-center'
        onClick={pubFlow}
      >
        <IconFont name='fabu' />
        发布
      </Button>,
    )
  }, [data])

  return (
    <div className='w-full h-full'>
      <iframe
        className='b-none w-full h-full rounded-6px'
        src={flowSrc}
        ref={iframeRef}
      />
    </div>
  )
}
