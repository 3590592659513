import type { FC } from 'react'
import { useMemo, useRef, useState } from 'react'
import { Form, message } from 'antd'
import { useRequest } from 'ahooks'
import classNames from 'classnames'
import styled from '@emotion/styled'

import type { DatastoreItemEditorRef } from '@/pages/datastores/components/DatastoreItemEditor'
import { DatastoreItemEditor } from '@/pages/datastores/components/DatastoreItemEditor'

import { Modal, Button } from '@/components'
import type { ParagraphItem } from '../ParagraphListItem'
import { addSplitContent, updateSplitContent } from '@/apis/datastore/api'
import type { Paragraph } from '@/pages/datastores/types/paragraph'
import KeywordsEditor from '@/pages/datastores/components/KeywordsEditor.tsx'
import { ParagraphExtraInfoStatus } from '@/apis/datastore/model'

export function RequireIcon() {
  return (
    <span className='color-error ml-5 text-[18px] line-height-14px position-relative top-[3px]'>
      *
    </span>
  )
}

export interface NormalParagraphEditorModalProps {
  title?: string | React.ReactElement
  defaultValue?: string[]
  open?: boolean
  onCancel?: () => void
  onFinish?: () => void
  paragraph: Omit<ParagraphItem, 'key'> & {
    key?: ParagraphItem['key']
  }
  file_id: number
  chunk_id?: number
  insert_above_chunk_id?: number
  callback: (value: Paragraph) => void
  matched_keywords?: string[]
  operationType: 'update' | 'add'
}

const FormWrapper = styled(Form)`
  .ant-form-item-required {
    ::before {
      display: none !important;
    }
  }
`

const MODAL_HEIGHT = '67vh'

export const NormalParagraphEditorModal: FC<
  NormalParagraphEditorModalProps
> = props => {
  const {
    open,
    onCancel: onModalClose,
    title = '编辑段落',
    paragraph,
    file_id,
    chunk_id,
    callback,
    matched_keywords = [],
    operationType,
    insert_above_chunk_id,
  } = props

  const datastoreItemEditorRef = useRef<DatastoreItemEditorRef>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [form] = Form.useForm()
  const [keywords, setKeyWords] = useState(paragraph.keywords ?? [])

  const { runAsync, loading } = useRequest(updateSplitContent, { manual: true })
  const { runAsync: runAddSplitItem, loading: addLoading } = useRequest(
    addSplitContent,
    { manual: true, debounceWait: 300 },
  )

  const onOk = async () => {
    await form.validateFields()
    const validateInfo = await datastoreItemEditorRef?.current?.validate?.()
    if (validateInfo?.success === false) {
      message.error(validateInfo?.msg)
      return
    }
    const pre_chunk_status = paragraph?.extra_info?.chunk_status
    const baseParams = {
      file_id,
      ...form.getFieldsValue(),
      keywords,
      extra_info: {
        ...paragraph.extra_info,
        chunk_status: ParagraphExtraInfoStatus.Done,
      },
    }

    if (operationType === 'update') {
      const updateParams = {
        chunk_id,
        enable:
          pre_chunk_status === ParagraphExtraInfoStatus.Error
            ? true
            : paragraph.enable,
      }
      await runAsync({ ...baseParams, ...updateParams })
      callback?.({ ...baseParams, ...updateParams })
    } else {
      const addParams = {
        enable: true,
        insert_above_chunk_id,
      }
      const res = await runAddSplitItem({ ...baseParams, ...addParams })
      callback?.({ ...res, key: res.chunk_id })
    }
    message.success(operationType === 'update' ? '修改已保存' : '添加成功')
    onModalClose?.()
  }

  const customStyle = useMemo(() => {
    return {
      height: `calc(${MODAL_HEIGHT} - 240px)`, // 当前常量为除textarea外的其他元素高度,
    }
  }, [])

  return (
    <Modal
      title={title}
      open={open}
      maskClosable={false}
      onCancel={onModalClose}
      footer={null}
      width={1020}
      styles={{
        body: {
          minHeight: '420px',
          height: MODAL_HEIGHT,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          padding: '24px 32px',
        },
      }}
    >
      <div
        className={classNames('flex flex-col flex-1', {
          '!pt-0': operationType !== 'update',
        })}
      >
        {
          <div className='flex flex-wrap gap-8'>
            <KeywordsEditor
              defaultValue={keywords}
              readOnly={false}
              highlightKeywords={matched_keywords}
              onChange={setKeyWords}
            />
          </div>
        }
        <div className='pt-[24px] pb-[24px] flex-1' ref={wrapperRef}>
          <FormWrapper
            form={form}
            layout={'vertical'}
            initialValues={{
              content: paragraph?.content || '',
              extra_info: paragraph?.extra_info,
            }}
          >
            <Form.Item
              name='content'
              rules={[{ required: true, message: '请输入内容' }]}
            >
              <DatastoreItemEditor
                file_id={file_id}
                customStyle={customStyle}
                setOptions={{
                  count: true,
                  customizeToken: true,
                  uploadOptions: {
                    uploadDir: 'datastore_normal',
                  },
                }}
                ref={datastoreItemEditorRef}
              />
            </Form.Item>
          </FormWrapper>
        </div>
      </div>
      <div className='px-32 pb-24 flex items-center justify-end gap-12 absolute bottom-0 w-full left-0 bg-white rounded-[0_0_8px_8px] z-100'>
        <Button
          onClick={() => {
            form.resetFields()
            onModalClose?.()
          }}
        >
          取消
        </Button>
        <Button
          loading={operationType === 'update' ? loading : addLoading}
          type='primary'
          onClick={onOk}
        >
          保存
        </Button>
      </div>
    </Modal>
  )
}
