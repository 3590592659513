import { Form, Switch, Tooltip } from 'antd'
import React from 'react'
import { IconFont, Input } from '@/components'

interface FormListVariableProps {
  afterAdd: VoidFunction
}

function InternalFormListVariable({ afterAdd }: FormListVariableProps) {
  return (
    <Form.List name='agent_variables'>
      {(fields, { add, remove }) => (
        <>
          <div className='flex items-center h-48px'>
            <span className='text-14px text-font font-medium'>变量</span>
            <Tooltip
              placement='top'
              title='定义一些变量，角色设定中可以使用变量做一些动态设定'
            >
              <IconFont
                className='text-font_1 text-14px text-opacity-40 ml-5 cursor-pointer'
                name='jieshishuimeng'
              />
            </Tooltip>
            <button
              className='flex justify-center items-center w-24px h-24px ml-auto text-16px rounded-6px hover:bg-#E4E5ED'
              type='button'
              onClick={event => {
                event.preventDefault()
                add({ required: true })
                afterAdd()
              }}
            >
              <IconFont name='fangtai' />
            </button>
          </div>
          {fields.map(({ key, name }) => (
            <div className='mb-8 flex items-stretch last:mb-16' key={key}>
              <Form.Item
                className='shrink-0 mb-0'
                name={[name, 'variable_name']}
                rules={[{ required: true, message: '变量名不能为空' }]}
              >
                <Input
                  className='w-184px !bg-white hover:!border-primary hover:!border-op-100 not-focus:!border-line not-focus:!border-op-80'
                  placeholder='变量名'
                />
              </Form.Item>
              <Form.Item
                className='flex-1 mx-12 mb-0'
                name={[name, 'label']}
                rules={[{ required: true, message: '字段名不能为空' }]}
              >
                <Input
                  className='!bg-white hover:!border-primary hover:!border-op-100 not-focus:!border-line not-focus:!border-op-80'
                  placeholder='字段名（显示给用户看）'
                />
              </Form.Item>
              <span className='text-14px/36px mr-2'>必填</span>
              <Form.Item
                className='mb-0 [&_.ant-form-item-control-input]:min-h-36px'
                name={[name, 'required']}
                valuePropName='checked'
              >
                <Switch size='small' />
              </Form.Item>
              <span
                className='ml-14 w-16px h-36px text-bg_3 text-16px text-op-60 hover:text-primary cursor-pointer flex-center'
                onClick={() => remove(name)}
              >
                <IconFont name='shanshu' />
              </span>
            </div>
          ))}
        </>
      )}
    </Form.List>
  )
}

export const FormListVariable = React.memo(InternalFormListVariable)
