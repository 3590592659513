import type { FC, PropsWithChildren, ReactNode } from 'react'
import classnames from 'classnames'
import { IconFont } from '@/components'

export interface StepItemProps {
  stepNum: number
  title: string
  isActive?: boolean
  isFinish?: boolean
  isLastStep: boolean
}
export const StepItem: FC<PropsWithChildren<StepItemProps>> = props => {
  const content = (
    <div
      className={classnames('pl-10 pt-8', {
        'pb-16': !props.isLastStep,
      })}
    >
      <div
        className={`${
          !props.isLastStep
            ? 'border-l border-solid border-primary border-opacity-24'
            : ''
        } pl-30 pt-16`}
      >
        {props.children}
      </div>
    </div>
  )

  const line = (
    <div className='ml-10 mb-4 mt-4 h-8px border-l border-solid border-font border-opacity-8'></div>
  )

  const ArrowIcon = <IconFont name='success' className='text-9px' />

  return (
    <div>
      <div className='flex flex-items-center'>
        <div
          className={classnames(
            'text-center line-height-24px rounded-full w-24px h-24px flex items-center justify-center',
            {
              'bg-primary text-white': props.isFinish || props.isActive,
              'bg-font_1 bg-opacity-24 text-font_1':
                !props.isFinish && !props.isActive,
            },
          )}
        >
          {props.isFinish ? ArrowIcon : props.stepNum}
        </div>
        <span
          className={classnames('ml-16 text-16px', {
            'text-font_1': !props.isFinish && !props.isActive,
          })}
        >
          {props.title}
        </span>
      </div>
      {props.isActive ? content : props.isLastStep ? undefined : line}
    </div>
  )
}

export interface IStepItem {
  title: string
  content: ReactNode
  stepNum: number
}
export interface StepProps {
  currentStep: number
  steps: IStepItem[]
}
export const Step: FC<PropsWithChildren<StepProps>> = props => {
  const { currentStep } = props
  return (
    <>
      {props.steps.map((item, index) => (
        <StepItem
          key={item.stepNum}
          stepNum={item.stepNum}
          title={item.title}
          isActive={currentStep === item.stepNum}
          isFinish={currentStep > item.stepNum}
          isLastStep={index === props.steps.length - 1}
        >
          {item.content}
        </StepItem>
      ))}
    </>
  )
}
