import type { IHead } from './type'

export function getMinWidth(
  heads: IHead[] = [],
  widthMap: Map<string, number> = new Map(),
) {
  const base = heads.reduce((pre, cur) => {
    if (widthMap.has(cur.key)) {
      return pre + widthMap.get(cur.key)!
    }
    return pre + cur.width
  }, 0)

  if (!base) return '100%'

  return `${base + heads.length - 1}px`
}

export function getWidth(
  heads: IHead[] = [],
  widthMap: Map<string, number> = new Map(),
) {
  const result: Record<string, string> = {}
  let hasGrow = false
  heads.forEach((each, index) => {
    let grow = each.grow && !widthMap?.has(each.key)
    // 确保有一列会占用剩余空间，如果没有的话就是最后一列
    if (grow) {
      hasGrow = true
    }
    if (index === heads.length - 1 && !hasGrow) {
      grow = true
    }

    const width = (widthMap && widthMap.get(each.key)) ?? each.width
    const value = `${grow ? 1 : 0} 0 ${width}px`
    result[`--rt-row-${each.key}`] = value
  })

  return result
}
