import { Tooltip, message } from 'antd'
import copy from 'copy-to-clipboard'
import { memo, useState } from 'react'
import styled from '@emotion/styled'
import { IconFont } from '@/components'
import { StyledPopover } from '../StyledComponent'
import type { ISubFlowItem } from '@/apis/flow.ts'
import { TitleShowSearchValue } from './TitleShowSearchValue'
import { PopoverContent } from './PopoverContent'

export interface dataStoreValueSturcture {
  tags: string[]
  fileList: Array<{ name: string; value: string }>
  tableList: Array<{ name: string; value: string }>
  params: ISubFlowItem['form_config']
}

export interface TreeTitleRenderProps extends dataStoreValueSturcture {
  title: string
  copyable?: boolean
  code?: ''
  type?: 'EXAMPLE' | 'DATASTORE'
  searchValue: string
  value: string | number
  language: 'javascript' | 'python'
  trackMetaData: object
  leftBarWidth?: number
  level: number
}

const TreeNodeMask = styled.div<{ popOverOpen: boolean }>`
  background-color: ${props =>
    props.popOverOpen ? 'rgba(98, 105, 153, 0.08)' : 'transparent'};
  &:hover {
    background-color: rgba(98, 105, 153, 0.08);
  }
`

function TreeTitleRenderContent(props: TreeTitleRenderProps) {
  const {
    title,
    copyable = false,
    code = '',
    type,
    searchValue,
    tags,
    fileList,
    value,
    language,
    tableList,
    trackMetaData,
    leftBarWidth,
    params,
    level,
  } = props

  const [popOverOpen, setPopOverOpen] = useState(false)

  const handlePopoverOpenChange = async (visible: boolean) => {
    setPopOverOpen(visible)
  }

  return (
    // 设置 title = '' 去除默认 hover 显示的 title
    <span title=''>
      <span className={`${type === 'EXAMPLE' ? 'ml-4px' : ''} inline-flex`}>
        <TitleShowSearchValue
          title={title}
          searchValue={searchValue}
          width={leftBarWidth!}
        />
      </span>
      <StyledPopover
        getPopupContainer={() =>
          document.querySelector('#code_node_panel_don_target')!
        }
        arrow={false}
        overlayInnerStyle={{
          padding: '16px',
          maxHeight: 'calc(100vh * 0.7 - 64px)',
          overflowY: 'hidden',
        }}
        destroyTooltipOnHide
        onOpenChange={handlePopoverOpenChange}
        title={['EXAMPLE', 'DATASTORE'].includes(type ?? '') && title}
        align={{
          offset: [8],
        }}
        content={
          ['EXAMPLE', 'DATASTORE'].includes(type ?? '') && (
            <PopoverContent
              title={title}
              key={value}
              tags={tags}
              params={params}
              searchValue={searchValue}
              value={value}
              fileList={fileList}
              type={type}
              code={code}
              language={language}
              tableList={tableList}
              trackMetaData={trackMetaData}
              level={level}
            />
          )
        }
        placement='right'
      >
        <TreeNodeMask
          className='ant-treenode-mask flex group'
          popOverOpen={popOverOpen}
        >
          {type === 'EXAMPLE' && (
            <span className='top-10 absolute' style={{ left: 8 + 24 * level }}>
              <IconFont
                className='text-16px text-#626999 text-op-60'
                name='daimazhili'
              />
            </span>
          )}
          {copyable && (
            <span className='right-6 top-6 absolute hidden group-hover:block'>
              <Tooltip title='复制' destroyTooltipOnHide>
                <button
                  onClick={event => {
                    event.stopPropagation()
                    copy(String(code || value))
                    message.success('复制成功')
                  }}
                >
                  <div className='cursor-pointer p-5px rounded-4px hover:bg-[rgba(98,105,109,0.08)]'>
                    <IconFont
                      className='text-14px text-#626999 text-op-60'
                      name='copy'
                    />
                  </div>
                </button>
              </Tooltip>
            </span>
          )}
          {type === 'EXAMPLE' && (
            <span className='right-14 top-15 absolute hidden group-hover:block'>
              <IconFont
                name='left-arrow'
                style={{
                  color: 'rgba(98, 105, 153, 0.6)',
                  fontSize: '7px',
                }}
              />
            </span>
          )}
        </TreeNodeMask>
      </StyledPopover>
    </span>
  )
}

export const TreeTitleRender = memo(TreeTitleRenderContent)
