import { useCountDown, useRequest } from 'ahooks'
import { useCallback, useState } from 'react'
import { Tooltip, message } from 'antd'
import { padZero } from '../../util'
import { Button } from '@/components'
import { uploadPayCert, type OrderDetail } from '@/apis/order'
import exampleImg from '@/assets/order/bank-cert.png'
import { PayIcon } from './PayIcon'
import type { FileItem } from './ImageUpload'
import { ImageUpload } from './ImageUpload'

export interface WaitPayToBProps {
  order: OrderDetail
  onCancelOrder: (orderId: string) => void
  cancelLoading?: boolean
  onPay?: () => void
  submitCallback?: () => void
}

export function WaitPayToB({
  order,
  onCancelOrder,
  cancelLoading,
  submitCallback,
}: WaitPayToBProps) {
  const [_countdown, formattedRes] = useCountDown({
    targetDate: order?.orderExpireTime,
  })

  const [imageList, setImageList] = useState<string[]>([])

  const { runAsync: uploadPayCertApi, loading } = useRequest(uploadPayCert, {
    manual: true,
  })

  const { days, hours, minutes, seconds } = formattedRes

  const handleChange = (list: FileItem[]) => {
    setImageList(
      list.filter(v => v.file_status === 'done').map(v => v.oss_path),
    )
  }

  const handleUpload = useCallback(async () => {
    if (imageList.length === 0) {
      message.error('请上传付款凭证')
      return
    }
    await uploadPayCertApi({
      order_no: order?.orderNo,
      trade_cert_url: imageList,
    })
    message.success('凭证上传成功，请等待审核')
    setTimeout(() => {
      submitCallback?.()
    }, 300)
  }, [imageList, order])
  return (
    <div className='flex flex-col items-center'>
      <PayIcon
        type='wait'
        content={
          <div>
            待提交付款凭证{' '}
            <span className='text-#E54A17'>{`${days}天 ${padZero(
              hours,
            )}:${padZero(minutes)}:${padZero(seconds)}`}</span>
          </div>
        }
      ></PayIcon>

      <div className='w-456px flex justify-between mt-60 mb-12px'>
        <div className='text-16px font-500 flex'>
          付款凭证 <div className='text-#FF5219 text-18px ml-2px'>*</div>
        </div>
        <Tooltip
          color='#fff'
          arrow={false}
          placement='topRight'
          overlayInnerStyle={{ width: '700px' }}
          title={<img className='w-700px' src={exampleImg}></img>}
        >
          <div className='text-14px font-500 text-primary cursor-pointer'>
            示例图
          </div>
        </Tooltip>
      </div>
      <ImageUpload
        hashPath={`cert/${order?.orderNo}`}
        onChange={handleChange}
      ></ImageUpload>
      <div className='mt-24'>
        <Button
          size='large'
          className='mr-12 w-120px'
          loading={cancelLoading}
          onClick={() => onCancelOrder?.(order?.orderNo)}
        >
          取消订单
        </Button>
        <Button
          size='large'
          type='primary'
          className='w-120px'
          loading={loading}
          disabled={!imageList.length}
          onClick={handleUpload}
        >
          提交
        </Button>
      </div>
    </div>
  )
}
