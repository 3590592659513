import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { memo } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { IconFont } from '@/components'
import type { RenderProps } from './type'

export const SelectRender = memo(
  (props: RenderProps<string> & Omit<SelectProps, 'onChange'>) => {
    const {
      name,
      disabled,
      placeholder,
      value = '',
      options,
      onChange,
      supportFileTypes,

      ...rest
    } = props

    const handleChange = useMemoizedFn((newValue: string) => {
      onChange(newValue, name)
    })

    return (
      <Select
        showSearch={false}
        suffixIcon={<IconFont name='arrow' className='text-7px text-font_1' />}
        {...rest}
        className={classNames(rest.className, 'llm-node-single-select h-36px')}
        value={value || undefined}
        options={options ?? []}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
      />
    )
  },
)
