import type { RoleCode } from '@/apis/authority.ts'
import { request } from './lib'

export const roles = ['企业管理者', '产品', '技术', '运营']
export const roleSelectOptions = [
  { value: '企业管理者', label: '企业管理者' },
  { value: '产品', label: '产品' },
  { value: '技术', label: '技术' },
  { value: '运营', label: '运营' },
  { value: '其他', label: '其他' },
]

export const NO_FREE_WORKSPACE_CODE = 6003
export interface WorkspaceItem {
  id: string
  name: string
  roleCode: RoleCode
  creator?: number
  creatorName: string
}

export interface CreateWorkspaceRes {
  id: string
  name: string
  extension: Record<string, any>
}
export function getWorkspaceList(name?: string) {
  return request.get<WorkspaceItem[]>('/v1/workspaces/list', {
    params: {
      name,
    },
    ignoreError: true,
    noRedirect: true,
  })
}
export function deleteWorkSpace(workspaceId: string) {
  return request.delete(`/v1/workspaces/${workspaceId}`)
}

export function createWorkspace({
  name,
  extension,
}: {
  name: string
  extension: { demand: string; source: 'AI' }
}) {
  return request.post<CreateWorkspaceRes>(
    '/v1/workspaces',
    { name, extension },
    { ignoreError: true },
  )
}

export function getWorkspaceApiKey(workspaceId: string) {
  return request.get(`/v1/workspaces/getKey/${workspaceId}`)
}

export function generateWorkspaceApiKey(workspaceId: string) {
  return request.put(`/v1/workspaces/generateKey/${workspaceId}`)
}

export function updateWorkspace(body: any) {
  return request.put(`/v1/workspaces/${body.id}`, body)
}

export function queryWorkspaceFree() {
  return request.post('/v1/workspace/query_workspace_free')
}
