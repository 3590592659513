import { useRequest } from 'ahooks'
import { message } from 'antd'
import type { PropsWithChildren } from 'react'
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAgentRel } from '@/apis/agent'
import type { ApplicationBodyType } from '@/apis/application'
import { editApplication, getApplicationById } from '@/apis/application'
import type { nodeConfigType } from '@/apis/run'
import { getFirstNodeConfig } from '@/apis/run'
import { CreateModal } from '@/components/createAppModal'
import { useFlowLogStore, useWorkspaceStore } from '@/store'
import { type tabsType } from '../../constant'
import { AppLogo, DetailHeader, DetailTabs } from '@/components'
import { useModal } from '@/hooks/useModal.tsx'
import AssociatedAgentPopover from '@/features/application/AssociatedAgentPopover.tsx'
import type { AppDetailInstance } from './utils'
import { useFlowDetailTabs } from './hooks/useFlowDetailTabs'

export const DetailComponent = forwardRef<
  AppDetailInstance,
  PropsWithChildren<{
    tabs: tabsType[]
    id: string
    canEditInfo?: boolean // TODO 是否可以编辑应用详情，暂时这样处理，后续要抽到上层
    getAppDataOnSuccess?: (e: {
      app: ApplicationBodyType
      firstNodeConfig: nodeConfigType
    }) => void
    beforeRun?: () => boolean | Promise<boolean>
  }>
>((props, ref) => {
  const navigate = useNavigate()
  const { children, tabs, id, getAppDataOnSuccess, beforeRun } = props
  const [firstNodeConfig, setFirstNodeConfig] = useState<nodeConfigType | null>(
    null,
  )
  const { setCurrentFlowId } = useFlowLogStore()
  const setCurrentWorkspace = useWorkspaceStore(
    state => state.setCurrentWorkspace,
  )

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const { data, loading, run } = useRequest(
    () => getApplicationById(id || ''),
    {
      manual: false,
      refreshDeps: [id],
      onSuccess: async e => {
        setCurrentFlowId(e.flowId)
        setCurrentWorkspace(e.workspaceId)
        getFirstNodeConfig({
          flow_id: e.flowId,
          workspace_id: e.workspaceId,
        }).then(res => {
          setFirstNodeConfig(res)
          getAppDataOnSuccess?.({ app: e, firstNodeConfig: res })
        })
      },
    },
  )
  const { data: AgentRelData } = useRequest(
    () =>
      getAgentRel({
        flow_id: data?.flowId || '',
        workspace_id: data?.workspaceId || '',
      }),
    { ready: !!data?.flowId && !!data?.workspaceId },
  )

  const handleBeforeRun = useCallback(async () => {
    if (!data?.isEnable) {
      message.error('Flow未启用！')
      return false
    }
    if (data?.versionStatus !== 'PUBLISHED') {
      message.error('Flow未发布！')
      return false
    }
    const allowRun = await beforeRun?.()
    return allowRun ?? true
  }, [data?.isEnable, data?.versionStatus])

  const { displayTabs, defaultKey, onTabChange } = useFlowDetailTabs({
    tabs,
    data,
    firstNodeConfig,
    handleBeforeRun,
  })
  console.log(3355, displayTabs)

  const refreshAppInfo = () => {
    if (data) {
      // 没有数据的时候不存在刷新应用信息
      run()
    }
  }

  useImperativeHandle(ref, () => {
    return {
      refreshAppInfo,
      appInfo: data,
      fetchAppInfoLoading: loading,
      associatedCount: AgentRelData?.length,
    }
  }, [data, run, loading, AgentRelData])

  const [appBaseInfoModal] = useModal(CreateModal)
  const onAppInfoEdit = () => {
    if (props.canEditInfo) {
      appBaseInfoModal.open({
        title: '编辑Flow信息',
        fieldConfig: {
          name: { placeholder: '请输入Flow名称' },
          description: {
            placeholder: '请输入Flow描述，这段文字将作为Flow的使用引导',
          },
        },
        logoType: 'emoji',
        defaultValue: data,
        onFinish: async formData => {
          if (data) {
            await editApplication({
              id: data.id,
              applicationType: 'AI',
              ...formData,
            })
            run()
            appBaseInfoModal.close()
          }
        },
      })
    }
  }

  return (
    <>
      <DetailHeader
        onBack={() => {
          navigate('/application')
        }}
        mainInfo={
          data &&
          AgentRelData && (
            <div className='flex items-center gap-8px'>
              <AppLogo
                value={data?.icon}
                onClick={onAppInfoEdit}
                type='emoji'
                color={data?.color}
                size={40}
                className='cursor-pointer'
                imgStyle={{ borderRadius: '10px' }}
              />
              {/* <div
                onClick={onAppInfoEdit}
                className='w-40px h-40px cursor-pointer border-rd-10px flex flex-justify-center flex-items-center text-26px'
                style={{ background: data?.color, flex: '0 0 40px' }}
              >
                {data?.icon?.startsWith('http')}
                <span>{data?.icon}</span>
              </div> */}
              <span
                title={data?.name}
                className='cursor-pointer text-20px font-500 truncate'
                onClick={onAppInfoEdit}
              >
                {data?.name}
              </span>
              <AssociatedAgentPopover
                data={(AgentRelData || [])?.map((item: any) => ({
                  ...item,
                  name: item.agent_name,
                }))}
                onClick={data => {
                  const url =
                    data.application_type === 'AGENT'
                      ? `/agent/${data.app_id}`
                      : `/application/${currentWorkspaceId}/${data.app_id}/flow/${data.flow_id}`
                  window.open(url, '_blank')
                }}
              />
            </div>
          )
        }
        actions={children}
      />
      <div className='h-[calc(100%-72px)] flex-1 adapt:p-24px'>
        <DetailTabs
          tabs={displayTabs}
          defaultKey={defaultKey}
          onChange={onTabChange}
        />
      </div>
    </>
  )
})
