import { Select } from 'antd'
import type { MouseEvent } from 'react'
import { memo, useMemo, useRef, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { IconFont } from '@/components'
import type { CellRenderProps } from './type'

export const MultipleSelectRender = memo((props: CellRenderProps<string[]>) => {
  const { className, value, cell, head, onChange } = props

  const wrapRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleChange = useMemoizedFn((newValue: string[]) => {
    onChange(newValue, [cell.key, head.key])
  })

  const handleClick = useMemoizedFn((event: MouseEvent) => {
    wrapRef.current?.focus()
    if (wrapRef.current !== event.target) return
    if (head.disabled) return
    setOpen(true)
  })

  const handleOpenChange = useMemoizedFn((newOpen: boolean) => {
    if (head.disabled && newOpen) return
    setOpen(newOpen)
  })

  const hasError = useMemo(() => {
    if (!head.validate || !head.require) return false
    return !value || value.length === 0
  }, [head.validate, value, head.key])

  const sureValue = useMemo(() => {
    if (typeof value === 'string') return [value]
    if (Array.isArray(value)) return value
    return []
  }, [value])

  return (
    <div
      ref={wrapRef}
      tabIndex={0}
      className={classNames(className, 'test-table-cell select-none', {
        'test-table-cell-error': hasError,
      })}
      onClick={handleClick}
    >
      <Select<string[]>
        className='test-table-select test-table-select-multiple'
        variant='borderless'
        value={sureValue}
        options={head.options ?? []}
        placeholder={head.placeholder}
        onChange={handleChange}
        open={open}
        onDropdownVisibleChange={handleOpenChange}
        suffixIcon={<IconFont name='arrow-1' className='color-#626999' />}
        mode='tags'
        showSearch={false}
      />
    </div>
  )
})
