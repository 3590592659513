import { useRequest, useTitle } from 'ahooks'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getApplicationById } from '@/apis/application'
import { PRODUCT_TITLE } from '@/constants/common'
import { useApplicationStore } from '@/store'

export function useApplicationInit() {
  const { appId } = useParams()

  const { setCurrentApplication } = useApplicationStore()

  const { data: applicationInfo, refreshAsync: fetchAppInfo } = useRequest(
    () => getApplicationById(appId as string),
    {
      refreshDeps: [appId],
      ready: !!appId,
    },
  )

  const onRefreshApp = async () => {
    await fetchAppInfo()
  }

  useTitle(applicationInfo?.name ? `${applicationInfo?.name}` : PRODUCT_TITLE, {
    restoreOnUnmount: true,
  })

  useEffect(() => {
    if (appId) {
      setCurrentApplication(appId)
    }
  }, [appId])

  return {
    applicationInfo,
    onRefreshApp,
  }
}
