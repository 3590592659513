import { Dropdown, type MenuProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import { Avatar, IconFont } from '@/components'
import { useUserStore } from '@/store'
import { sideBarCollapseStorage, tokenStorage } from '@/utils/storage'
import { UserSettingsModal } from './UserSettingsModal'

const MenuKey = {
  User: 'user',
  Exit: 'exit',
} as const

const items: MenuProps['items'] = [
  {
    label: (
      <div className='flex items-center p-8px truncate'>
        <IconFont
          name='user'
          className='mr-8 text-16px text-#626999 text-op-60'
        />{' '}
        用户设置
      </div>
    ),
    key: MenuKey.User,
  },
  {
    label: (
      <div className='flex items-center p-8px truncate'>
        <IconFont
          name='exit'
          className='mr-8 text-16px text-#626999 text-op-60'
        />{' '}
        退出登录
      </div>
    ),
    key: MenuKey.Exit,
  },
]
export function User(props: { collapsed?: boolean }) {
  const { collapsed = false } = props
  const user = useUserStore(state => state.user)

  const updateUser = useUserStore(state => state.updateUser)

  const navigate = useNavigate()

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === MenuKey.Exit) {
      tokenStorage.clear()
      updateUser(undefined)
      sideBarCollapseStorage.clear()
      location.href = '/login'
      return
    }
    if (key === MenuKey.User && user !== undefined) {
      NiceModal.show(UserSettingsModal, {
        user,
        onProfileUpdated: user => {
          updateUser(user)
        },
        onPasswordUpdated: () => {
          setTimeout(() => {
            tokenStorage.clear()
            updateUser(undefined)
            navigate('/login')
          }, 200)
        },
      })
    }
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick,
      }}
      trigger={['click']}
      overlayClassName='helpPopover w-114px'
      placement='topLeft'
    >
      <div
        title={user?.username}
        className={classNames(
          'flex flex-center h-32px rounded-8px text-font_1 text-14px cursor-pointer hover:bg-#fff hover:bg-op-60',
          collapsed ? 'px-4' : 'px-8',
        )}
      >
        <Avatar name={user?.username} src={user?.avatar} size='small' />
        {collapsed ? null : (
          <div className='ml-4px text-font max-w-140px truncate'>
            {user?.username}
          </div>
        )}
      </div>
    </Dropdown>
  )
}
