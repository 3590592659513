import axios from 'axios'
import type {
  ChatMessageItem,
  ParametersWithInput,
  ParametersWithProperty,
} from '@bty/chat-types'
import { request } from './lib'

interface AgentListParameters {
  function_id: string | undefined
  workspace_id?: string
}
interface PushMessage {
  conversation_id: string | undefined
  content: string
  tips: any[]
  to_context: boolean
}

interface ConsumeInfo {
  consume_mode: string
  consume?: string
  model_list?: Array<{ model: string; consume: string }>
}

export interface Parameters {
  type: string
  description: string
  label: string
  default: any
  example: any
}

export interface Metadata {
  type: 'string' | 'int' | 'bool'
  display_name: string
  component: string
  required: boolean
  description: string
  value: any
  options?: Array<{ label: string; value: any }>
}

export interface PluginInfoResponse {
  function_id: string
  name: string
  // type:
  description: string
  display_name: string
  label: string
  is_new: boolean
  parameters: ParametersWithInput | ParametersWithProperty
  metadata_config: {
    icon: string
    properties: {
      inputs: Record<string, Metadata>
      outputs: unknown
    }
  }
  consume_info: ConsumeInfo
  usage_demo: {
    context: ChatMessageItem[]
  }
  resp_sample: any
}

export const keyuXcAuth =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxOTEwIiwiY2xpZW50Ijoibm9jb19hcGkiLCJleHAiOjUzMDQyNDczNDV9.eepR4YIDPc6EvJJYG21vh32_xvES0F8upqhjLmRDy7M'

export function getSkillList({ function_id }: AgentListParameters) {
  return request.get(`/v1/agent/utility/${function_id}/detail`)
}
export function getAddPluginToAgent(body: any) {
  return request.post('/v1/agent/utility/function/create', body)
}
export function getAgentList({
  function_id,
  workspace_id,
}: AgentListParameters) {
  return request.get(`/v1/agent/function/rel?function_id=${function_id}`, {
    headers: {
      workspace_id,
    },
  })
}
export function getPluginPageConfig() {
  return request.get('/v1/agent/plugin/template')
}
export function getPushMsg(body: PushMessage) {
  return request.post('/v1/chat/push_msg', body)
}

export function getPluginInfo(
  function_id: string,
): Promise<PluginInfoResponse> {
  return request.get(`/v1/completion_function/${function_id}/detail`)
}

export enum PluginDataType {
  add = 'add',
  view = 'view',
}

export enum PluginDataFrom {
  agent = 'agent',
  plugin = 'plugin',
  flow = 'flow',
}

// 插件数据统计，包括：查看详情页数据 & 添加数据
export function setPluginData(
  type: PluginDataType,
  plugin_id: string,
  from?: PluginDataFrom,
) {
  return axios.post(
    `https://salyut2.bantouyan.com/api/v1/db/data/v1/1iuk5af6830x1fqrq37xyihcfqevmja2wu5f/${type === PluginDataType.add ? 'add_plugin_data' : 'view_info_data'}`,
    { plugin_id, from, env: import.meta.env.MODE },
    {
      headers: {
        'xc-auth': keyuXcAuth,
      },
    },
  )
}
