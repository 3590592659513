import { monitor } from '@bty/monitor'
import { useEffect } from 'react'
import { Link, useRouteError } from 'react-router-dom'

export function ErrorElement(props: {
  content?: string
  redirect?: string
  buttonText?: string
  image?: string
}) {
  const error = useRouteError()

  const {
    content = '抱歉！系统出了一点小问题，请稍后再试',
    redirect = '/',
    buttonText = '回到首页',
    image = 'https://resource.bantouyan.com/battleyeah-ai/ai-client/404.png',
  } = props

  useEffect(() => {
    if (!error) return
    monitor.reportError((error as any).error as Error)
  }, [error])

  return (
    <div className='h-100vh flex-center flex-col text-14px c-bg_3 c-op-48'>
      <img className='w-200px' src={image} alt='' />
      <p>{content}</p>
      <Link to={redirect}>
        {' '}
        <div className='mt-10'>{buttonText}</div>
      </Link>
    </div>
  )
}
