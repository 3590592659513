import styled from '@emotion/styled'
import { Form, Input } from 'antd'

export const DescWrapper = styled(Input.TextArea)`
  border-color: #fff;
  textarea {
    background: rgba(98, 105, 153, 0.06);
  }
  .ant-input-suffix {
    .ant-input-data-count {
      bottom: 8px;
      right: 8px;
    }
  }
`

export const RequiredFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-weight: 500;
  }
  .ant-form-item-label {
    padding-bottom: 12px;
  }
  .ant-form-item-required {
    &:before {
      position: absolute;
      right: -15px;
    }
  }
`

export const SpanWarpper = styled.span`
  &:hover {
    background: rgba(98, 105, 153, 0.08);
  }
`
