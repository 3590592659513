import type { Edge, Node } from 'reactflow'
import type { ConditionNodeData } from '@/features/nodes/condition'
import type { NestNodeBoundaryFn, NodeBoundary } from '../types'
import { getNormalNodeBoundary } from '../util'
import { isNestableNode } from '@/features/flow/utils'

export function getConditionNodeBoundary(
  node: Node<ConditionNodeData>,
  nodes: Node[],
  edges: Edge[],
  findNestNodeBoundaryFn: NestNodeBoundaryFn,
): NodeBoundary {
  const boundary = {
    top: node.position.y,
    left: node.position.x,
    right: node.position.x + (node.width || 0),
    bottom: node.position.y + (node.height || 0),
  }
  const { conditions, relation } = node.data
  const resultNode = nodes.find(n => n.id === relation.conditionResultId)

  if (conditions.length && resultNode) {
    // 以result节点为右边界
    boundary.right = getNormalNodeBoundary(resultNode).right
    const firstConditionId = conditions[0].id
    const lastConditionId = conditions.slice(-1)[0].id
    const firstBranchEdge = edges.find(e => e.sourceHandle === firstConditionId)
    const lastBranchEdge = edges.find(e => e.sourceHandle === lastConditionId)
    const firstBranchNodes = nodes.filter(
      n => n.data.relation?.branchId === firstConditionId,
    )
    const lastBranchNodes = nodes.filter(
      n => n.data.relation?.branchId === lastConditionId,
    )
    // 获取第一条分支和最后一条分支上所有节点的边界
    const firstBranchBoundaries = [...firstBranchNodes].map(n => {
      if (isNestableNode(n)) {
        return findNestNodeBoundaryFn(n, nodes, edges)
      }
      return getNormalNodeBoundary(n)
    })
    const lastBranchBoundaries = [...lastBranchNodes].map(n => {
      if (isNestableNode(n)) {
        return findNestNodeBoundaryFn(n, nodes, edges)
      }
      return getNormalNodeBoundary(n)
    })

    // 没有节点时应该计算第一条edge和node.position.y的最小值
    boundary.top = firstBranchBoundaries.length
      ? Math.min.apply(
          null,
          firstBranchBoundaries.map(item => item.top),
        )
      : Math.min.apply(null, [
          node.position.y,
          firstBranchEdge?.data?.customPathOptions?.points?.[0]?.y || 0,
        ])

    // 同上
    boundary.bottom = lastBranchBoundaries.length
      ? Math.max.apply(
          null,
          lastBranchBoundaries.map(item => item.bottom),
        )
      : Math.max.apply(null, [
          node.position.y + (node.height || 0),
          lastBranchEdge?.data?.customPathOptions?.points?.[0]?.y || 0,
        ])
  }
  return boundary
}
