import { memo, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useBoolean, useMemoizedFn } from 'ahooks'
import copy from 'copy-to-clipboard'
import { message, Popover } from 'antd'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { isEmpty, isString, map } from 'lodash-es'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import type { TaskLogFunctionCallLog, TaskLogItem } from '@/apis/task'
import { AppLogo, Button, IconFont, Markdown } from '@/components'
import { tryParseToJsonObject } from '@/utils/string'
import { TaskLogModal } from '@/features/chat'

export const LogTag = memo((props: { status: string }) => {
  const { status } = props

  if (status === 'SUCCEEDED') {
    return (
      <div
        className='flex flex-center w-52px h-20px rounded-4px text-#2CB969 text-12px'
        style={{
          background: 'rgba(44, 185, 105, 0.1216)',
        }}
      >
        成功
      </div>
    )
  }

  if (status === 'RUNNING') {
    return (
      <div
        className='flex flex-center w-52px h-20px rounded-4px text-#2CB969 text-12px'
        style={{
          background: 'rgba(44, 185, 105, 0.1216)',
        }}
      >
        <span className='w-6px h-6px bg-#31CC74 rounded-6px mr-4px'></span>
        执行中
      </div>
    )
  }

  if (status === 'FAILED') {
    return (
      <div
        className='flex flex-center w-52px h-20px rounded-4px text-#FF5219 text-12px'
        style={{
          background: 'rgba(255, 82, 25, 0.1216)',
        }}
      >
        失败
      </div>
    )
  }

  return (
    <div
      className='flex flex-center w-52px h-20px rounded-4px text-#38A6FF text-12px'
      style={{
        background: 'rgba(56, 166, 255, 0.1216)',
      }}
    >
      等待中
    </div>
  )
})

function getTaskOutput(log: TaskLogItem) {
  if (log.task_status === 'SUCCEEDED') {
    const tasks = log.output.tasks ?? []
    const last = tasks[tasks.length - 1]
    return last?.type === 'TEXT' ? last.content : ''
  }

  if (log.task_status === 'FAILED') {
    if (typeof log.output?.message === 'string') {
      return log.output?.message
    }

    return log.output?.message?.message?.step_err_info
  }

  return ''
}

interface LogItemProps {
  log: TaskLogItem
  logModalPosition?: { top?: number; right?: number; bottom?: number }
  initialExpanded?: boolean
}

export const LogItem = memo((props: LogItemProps) => {
  const { log, logModalPosition, initialExpanded = false } = props

  const [callLog, setCallLog] = useState<TaskLogFunctionCallLog>()
  const [callLogOpen, setCallLogOpen] = useState(false)

  const [panelExpanded, { setFalse: closePanel, setTrue: showPanel }] =
    useBoolean(initialExpanded)

  const outputText = useMemo(() => {
    return getTaskOutput(log)
  }, [log])

  const functionCalls = useMemo(() => {
    const tasks = log.output?.tasks ?? []
    return tasks.filter(e => e.type === 'FUNCTION' && e.status !== 'STARTED')
  }, [log])

  const runParam = useMemo(() => {
    return log.run_param
  }, [log])

  const showTaskLog = useMemoizedFn((taskLog: TaskLogFunctionCallLog) => {
    setCallLogOpen(true)
    setCallLog(taskLog)
  })

  const onCopy = useMemoizedFn(() => {
    copy(outputText)
    message.success('已复制到剪切板')
  })

  const popContent = useMemo(
    () => (
      <OverlayScrollbarsComponent
        className='w-250px max-h-360px overflow-auto mr-[-8px] pr-8px'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {map(runParam, (each, key) => {
          const sureValue = tryParseToJsonObject(each)

          return (
            <div key={key} className='text-12px mb-8px'>
              <p className='leading-18px'>{key}</p>
              {isString(sureValue) ? (
                <p className='leading-18px text-#8D8D99 break-all'>
                  {isEmpty(sureValue) ? '-' : sureValue}
                </p>
              ) : (
                <pre className='leading-16px text-#8D8D99 break-all'>
                  {JSON.stringify(sureValue, null, 2)}
                </pre>
              )}
            </div>
          )
        })}
      </OverlayScrollbarsComponent>
    ),
    [runParam],
  )

  const callLogDetail = useMemo(() => {
    return {
      data: { ...callLog, message: callLog?.message?.message?.step_err_info },
      status: callLog?.status === 'SUCCEEDED' ? 'success' : 'fail',
      name: callLog?.name,
    }
  }, [callLog])

  return (
    <div
      className={classNames('p-16px border-1px rounded-8px mb-10px', {
        'cursor-pointer': !panelExpanded,
        'bg-#F6F6F9': panelExpanded,
      })}
      onClick={!panelExpanded ? showPanel : undefined}
    >
      <div
        className='flex flex-center cursor-pointer'
        onClick={panelExpanded ? closePanel : undefined}
      >
        <LogTag status={log.task_status} />
        <div className='mr-auto ml-8'>
          {dayjs(log.execute_time).format('YYYY-MM-DD HH:mm')}
        </div>
        <IconFont
          onClick={closePanel}
          name='yongqi2111'
          className={classNames(
            'text-font_1 text-14px rotate-90 cursor-pointer',
            {
              'rotate-180': panelExpanded,
            },
          )}
        />
      </div>
      {!panelExpanded && outputText && (
        <div className='mt-12 text-font_1 truncate'>{outputText}</div>
      )}
      {panelExpanded && (
        <>
          {functionCalls.length > 0 && (
            <div>
              {functionCalls.map((each, index) => {
                return (
                  <div
                    key={index}
                    className='h-40px border-1px mt-12px rounded-8px flex flex-center px-12px cursor-pointer bg-white'
                    onClick={() => showTaskLog(each)}
                  >
                    <AppLogo
                      className='rounded-4px mr-8px'
                      value={each.metadata.icon}
                      type={
                        each.metadata.icon?.startsWith('http')
                          ? 'image'
                          : 'emoji'
                      }
                      color={each.metadata.color}
                      imageClassName='rounded-4px!'
                      size={24}
                      fillSize={14}
                    />
                    <span className='mr-auto'>{each.name}</span>
                    <span>
                      {each.status === 'SUCCEEDED' && (
                        <IconFont
                          name='chenggongbaocun'
                          className='font-16px'
                        />
                      )}
                      {each.status === 'FAILED' && (
                        <IconFont name='cuowu' className='font-16px' />
                      )}
                    </span>
                  </div>
                )
              })}
            </div>
          )}

          {outputText && (
            <div className='leading-20px mt-12px mb-12px text-font_1'>
              <Markdown text={outputText} />
            </div>
          )}

          <div>
            {!isEmpty(runParam) && (
              <Popover
                content={popContent}
                placement='left'
                title='工作流输入'
                destroyTooltipOnHide
                zIndex={1050}
              >
                <Button className='inline-flex flex-center px-4px! h-22px! rounded-4px! text-#8D8D99 font-normal! hover:text-#8D8D99! mr-8px gap-0'>
                  <IconFont className='mr-4px' name='zhushi' />
                  <span className='text-12px'>运行参数</span>
                </Button>
              </Popover>
            )}

            {outputText && (
              <Button
                className='inline-flex flex-center px-4px! h-22px! rounded-4px! text-#8D8D99 font-normal! hover:text-#8D8D99! gap-0'
                onClick={onCopy}
              >
                <IconFont className='mr-4px' name='copy' />
                <span className='text-12px'>复制</span>
              </Button>
            )}
          </div>
        </>
      )}
      {callLogOpen &&
        createPortal(
          <TaskLogModal
            zIndex={1100}
            top={logModalPosition?.top ?? 110}
            right={logModalPosition?.right ?? 20}
            bottom={logModalPosition?.bottom ?? 20}
            task={callLogDetail}
            title={callLogDetail.name}
            status={callLogDetail.status as any}
            onClose={() => setCallLogOpen(false)}
          />,
          document.body,
        )}
    </div>
  )
})
