import { useMemo } from 'react'
import { Switch, message } from 'antd'
import { useRequest } from 'ahooks'
import { Button } from '@/components'
import { useAgentEdit } from '../provider/AgentEditProvider'
import AgentGoPublishImg from '@/assets/agent-go/AgentGoPublishImg.png'
import { shareToChatBot } from '@/apis/flow.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

// const AppItem = styled.div<{ type?: string }>`
//   .qrCode {
//     display: none;
//   }
//   ${({ type }) =>
//     type === 'qrCode'
//       ? `cursor: pointer;
//       &:hover {
//     .qrCode {
//       display: flex;
//     }
//     .normal {
//         display: none;
//       }
//   }`
//       : ''}
// `

export default () => {
  const { applicationInfo, refreshApplicationInfo, setOpenInvitePopover } =
    useAgentEdit()
  const text = useMemo(() => {
    return `/chatbot/${applicationInfo?.flowId}`
  }, [applicationInfo])
  const { scrollRef } = useScrollBar()

  const {
    runAsync: toggleAgentToChatEnable,
    loading: toggleToChatEnableLoading,
  } = useRequest(shareToChatBot, {
    manual: true,
  })

  const handleToChatEnableToggle = async (isBot: boolean) => {
    if (applicationInfo?.flowId) {
      await toggleAgentToChatEnable({
        flowId: applicationInfo.flowId,
        isBot,
      })
      await refreshApplicationInfo()
      message.success(isBot ? '发布成功' : '停用成功')
    }
  }

  // const appList = [
  //   {
  //     title: '网页端',
  //     img: Pc,
  //     enable: true,
  //     buttonText: '使用',
  //     onclick: () => window.open(text),
  //   },
  //   {
  //     title: 'windows',
  //     img: Window,
  //     enable: true,
  //     buttonText: '下载',
  //     onclick: () =>
  //       window.open(
  //         'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/temp/BetterYeah%20Bot-1.0.0%20Setup.exe',
  //       ),
  //   },
  //   {
  //     title: 'Mac',
  //     img: Mac,
  //     enable: true,
  //     buttonText: '下载',
  //     onclick: () =>
  //       window.open(
  //         'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/temp/BetterYeah%20Bot-1.0.0-universal.dmg',
  //       ),
  //   },
  //   {
  //     title: 'Android',
  //     img: Android,
  //     enable: true,
  //     buttonText: '下载',
  //     type: 'qrCode',
  //     qrCodeImg: AndroidQR,
  //   },
  //   {
  //     title: 'ios',
  //     img: iOS,
  //     enable: false,
  //     buttonText: '即将推出',
  //     onclick: () => window.open(text),
  //   },
  // ]
  //
  return (
    <div
      ref={scrollRef}
      className='p-24px flex-1 w-full h-full overflow-y-auto relative'
    >
      <div className='adapt:mb-24 font-500 text-20px line-height-32px'>
        发布到【对话】
        <Switch
          className='ml-8px'
          checked={applicationInfo?.isBot}
          loading={toggleToChatEnableLoading}
          onChange={handleToChatEnableToggle}
        />
      </div>
      <div className='rounded-8px bg-#fff p-24px'>
        <div className='text-14px font-500'>样式示例</div>
        <div className='py-12px c-font_1 text-14px'>
          在首页的【对话】频道，使用 Agent
        </div>
        <img src={AgentGoPublishImg} className='w-320px mb-24px' />
        <div className='text-14px font-500 mb-12px'>可用范围</div>
        <div
          className='mb-24px flex items-center'
          style={{ color: 'rgba(23, 23, 29, 0.8)' }}
        >
          仅授权用户可用
          <Button
            type='link'
            size='small'
            disabled={!applicationInfo?.isBot}
            className='!text-14px !h-26px flex items-center !px-8px'
            onClick={() => setOpenInvitePopover?.(true)}
          >
            邀请用户
          </Button>
        </div>
        <Button
          type='primary'
          size='middle'
          disabled={!applicationInfo?.isBot}
          onClick={() => window.open(text, '_blank')}
        >
          去使用
        </Button>
      </div>
      {/* <div className='font-600 text-16px mb-12px mt-30px'> */}
      {/*  在单独的【对话】客户端中，更沉浸的使用Agent */}
      {/* </div> */}
      {/* <div className='flex items-center gap-24px w-full flex-wrap'> */}
      {/*  {appList.map((item, index) => ( */}
      {/*    <AppItem */}
      {/*      key={index} */}
      {/*      type={item.type} */}
      {/*      style={{ */}
      {/*        pointerEvents: item.enable ? 'auto' : 'none', */}
      {/*        opacity: item.enable ? 1 : 0.5, */}
      {/*      }} */}
      {/*      className='w-260px shrink-0 bg-white p-40px rounded-8px' */}
      {/*    > */}
      {/*      <div className='normal flex flex-col justify-between items-center gap-12px flex-nowrap'> */}
      {/*        <img src={item.img} className='mb-12px w-60px' /> */}
      {/*        <div className='font-600 text-14px'>{item.title}</div> */}
      {/*        <Button */}
      {/*          size='middle' */}
      {/*          className='c-primary w-94px' */}
      {/*          onClick={() => item.onclick?.()} */}
      {/*        > */}
      {/*          {item.buttonText} */}
      {/*        </Button> */}
      {/*      </div> */}
      {/*      <div className='qrCode flex flex-col justify-between items-center gap-12px flex-nowrap'> */}
      {/*        <img src={item.qrCodeImg} className='w-117px h-117px' /> */}
      {/*        <div className='text-12px text-center truncate'> */}
      {/*          扫码下载Android客户端 */}
      {/*        </div> */}
      {/*      </div> */}
      {/*    </AppItem> */}
      {/*  ))} */}
      {/* </div> */}
    </div>
  )
}
