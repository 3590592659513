import { Radio } from 'antd'
import classNames from 'classnames'
import { useScrollBar } from '@/hooks/useScrollBar'

interface OptionType {
  label: string
  value: string
  description?: string
  extra?: string
}

export interface CardRadioGroupProps {
  options?: OptionType[]
  value?: OptionType['value']
  size?: 'small' | 'middle' | 'large'
  compact?: boolean
  onChange?: (value: OptionType['value']) => void
}

export function CardRadioGroup({
  value,
  options,
  size,
  compact,
  onChange,
}: CardRadioGroupProps) {
  const { scrollRef } = useScrollBar()

  const innerSize = size || 'middle'

  return (
    <div className='w-full flex-1'>
      <Radio.Group
        className='flex justify-around overflow-x-auto'
        value={value}
        onChange={event => {
          onChange?.(event.target.value as string)
        }}
      >
        <div
          ref={scrollRef}
          className='flex justify-around gap-12 nodrag nopan nowheel flex-1 [&>.scroll-content]:flex [&>.scroll-content]:gap-12'
        >
          {options?.map(item => (
            <Radio
              key={item.value}
              className={classNames(
                'mr-0 flex-1 flex-row-reverse justify-between items-center px-12 py-8 b-1 b-solid b-b-[rgba(225,225,229,0.6)] hover:bg-[rgba(98,105,153,0.08)] after:content-[""] [&>.ant-radio+span]:p-0 [&>.ant-radio+span]:flex-1 [&_.ant-radio-inner]:w-14px [&_.ant-radio-inner]:h-14px',
                { '[&>.ant-radio]:self-start': item.extra && !compact },
                {
                  'py-9 [&>.ant-radio+span]:inline-flex [&>.ant-radio+span]:items-center':
                    compact,
                },
                { '!b-primary': item.value === value },
                innerSize === 'small'
                  ? 'min-h-32px rounded-6px'
                  : 'min-h-36px rounded-8px',
              )}
              value={item.value}
            >
              <span
                className={classNames('leading-16px', {
                  'text-12px': innerSize === 'small',
                  'text-14px': innerSize === 'middle',
                  'text-16px': innerSize === 'large',
                })}
              >
                {item.label}
              </span>
              {item.description && (
                <span
                  className={classNames(
                    'text-font_1 leading-16px text-nowrap',
                    {
                      'text-12px': innerSize === 'small',
                      'text-14px': innerSize === 'middle',
                      'text-16px': innerSize === 'large',
                    },
                  )}
                >
                  {item.description}
                </span>
              )}
              {!!item.extra && (
                <span
                  className={classNames(
                    'text-font_1',
                    compact ? 'inline ml-auto mr-12' : 'block mt-8',
                    {
                      'text-12px': innerSize === 'small',
                      'text-14px': innerSize === 'middle',
                      'text-16px': innerSize === 'large',
                    },
                  )}
                >
                  {item.extra}
                </span>
              )}
            </Radio>
          ))}
        </div>
      </Radio.Group>
    </div>
  )
}
