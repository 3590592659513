import { NodeType } from '@/features/nodes/base'
import { useFlowDraftStore, useNodeInsertStore } from '@/store'
import { useTrack } from '@/features/track/Track'

export function useNodeDataInsert() {
  const { node, saveNode } = useNodeInsertStore()
  const { insertNode } = useFlowDraftStore()
  const { doTrack } = useTrack()

  const insert = (options: {
    target: string
    source: string
    sourceHandleId?: string | null
  }) => {
    if (node) {
      insertNode({
        ...node,
        ...options,
      })
      if (node.type === NodeType.PLUGIN) {
        doTrack('flow_plugin_add', {
          pluginName: node?.data?.pluginName,
          pluginDisplayName: node?.data?.displayName,
          actionType: 'insert',
        })
      }
    }
  }

  return {
    save: saveNode,
    insert,
  }
}
