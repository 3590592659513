import ace from 'ace-builds'
import copyTo from 'copy-to-clipboard'
import type { IAceEditorProps } from 'react-ace'

import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-sql'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-one_dark'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/theme-twilight'

import {
  useBoolean,
  useEventListener,
  useFocusWithin,
  useKeyPress,
} from 'ahooks'
import classNames from 'classnames'
import { isEqualWith, isObject, merge, unionBy } from 'lodash-es'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import AceEditor from 'react-ace'
import type { IAceEditor, ICommand } from 'react-ace/lib/types'
import { Tooltip } from 'antd'
import { IconFont } from '@/components'
import { TagsTooltip, type TagsTooltipProps } from '../components/TagsTooltip'
import { Delete } from './Delete'
import { Role } from './Role'
import {
  defaultTriggerCommand,
  disableFindCommand,
  singleLineCommand,
  textModelTriggerCommand,
} from './commands'
import './global.style'
import { TextMode } from './mode/highlight'
import './theme/ace-gray'

ace.config.set(
  'basePath',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.24.0/src-noconflict/',
)

function isMac() {
  return /macintosh|mac os x/i.test(navigator.userAgent.toLowerCase())
}

const FULLSCREEN_KEY = isMac() ? 'meta.k' : 'ctrl.k'

const COPY_KEY = isMac() ? 'meta.c' : 'ctrl.c'

export interface Variable {
  label: string
  type?: string
  hideQuickSelect?: boolean
  property?: Omit<Variable, 'hideQuickSelect'>[]
}

export interface CodeEditorProps extends IAceEditorProps {
  variables?: Variable[]
  defaultVariables?: Variable[]
  containerClassName?: string
  variableTipsContainer?: HTMLElement | null // 变量提示锚定位置  默认为编辑器本身
  viewport?: {
    // 缩放比例，node节点中使用，避免由于缩放导致的光标位置不对
    zoom: number
    x?: number
    y?: number
  }
  role?: string
  singleLine?: boolean
  showDelete?: boolean // 是否显示删除按钮
  showFullscreen?: boolean
  fullscreen?: boolean
  copy?: boolean
  copyPosition?: 'top' | 'bottom'
  noBorder?: boolean
  maxHeight?: string
  minHeight?: string
  'aria-invalid'?: string
  anchor?: TagsTooltipProps['anchor']
  disabled?: boolean
  onRoleClick?: (role: string) => void
  onDelete?: () => void
  onCopy?: (text: string) => void
  onFullscreenChange?: (isFullscreen: boolean) => void
}

function customize(
  arr1?: CodeEditorProps['variables'],
  arr2?: CodeEditorProps['variables'],
) {
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    if (arr1.length !== arr2.length) {
      return false
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].label !== arr2[i].label || arr1[i].type !== arr2[i].type) {
        return false
      }
    }
    return true
  } else {
    if (!arr1 && !arr2) {
      return true
    } else if ((!arr1 && arr2) || (!arr2 && arr1)) {
      return false
    } else {
      return false
    }
  }
}

const highlightMap: Record<string, any> = {
  // json: JsonMode,
  text: TextMode,
  // javascript: JavascriptMode,
}

const defaultEditorProps: CodeEditorProps = {
  fontSize: 12,
  wrapEnabled: true,
  showGutter: false,
  singleLine: false,
  highlightActiveLine: false,
  showPrintMargin: false,
  copy: false,
  copyPosition: 'bottom',
  mode: 'text',
  editorProps: {
    $blockScrolling: true,
  },
  anchor: 'left',
  setOptions: {
    hasCssTransforms: true,
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    tooltipFollowsMouse: true,
    enableSnippets: false,
    showLineNumbers: false,
    indentedSoftWrap: false,
    tabSize: 2,
    maxLines: Number.POSITIVE_INFINITY,
  },
}

const modeDefaultEditorPropsMap: Record<string, CodeEditorProps> = {
  sql: {
    theme: 'tomorrow',
    showGutter: true,
    setOptions: {
      tooltipFollowsMouse: true,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      useWorker: false,
    },
    editorProps: {
      $blockScrolling: false,
    },
  },
  json: {
    theme: 'tomorrow',
    showGutter: true,
    setOptions: {
      showLineNumbers: true,
      useWorker: false,
      tooltipFollowsMouse: true,
    },
    editorProps: {
      $blockScrolling: false,
    },
  },
  javascript: {
    theme: 'tomorrow',
    showGutter: true,
    setOptions: {
      tooltipFollowsMouse: true,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      useWorker: false,
    },
    editorProps: {
      // $blockScrolling: false,
    },
  },
  python: {
    theme: 'tomorrow',
    showGutter: true,
    setOptions: {
      tooltipFollowsMouse: true,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      useWorker: false,
    },
    editorProps: {
      $blockScrolling: false,
    },
  },
}

const bracketSnippetsModes = ['text', 'json']

function transformValue(value: string) {
  return value.replaceAll('{{{{', '{{').replaceAll('{{{', '{{')
}

export interface CodeEditorInstance {
  editor?: IAceEditor
  getHeight: () => number | undefined
  getLineHeight: () => number | undefined
}

export const AceCodeEditor = memo(
  forwardRef<CodeEditorInstance, CodeEditorProps>((props, ref) => {
    const aceEditorRef = useRef<AceEditor>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const keywordCompleterRef = useRef<any>(null)

    const editor = useMemo(() => {
      return aceEditorRef.current?.editor
    }, [aceEditorRef.current])

    const mergedProps = useMemo(
      () =>
        merge(
          {},
          defaultEditorProps,
          modeDefaultEditorPropsMap[props.mode as string] || {},
          props,
        ),
      [props],
    )

    useImperativeHandle(
      ref,
      () => {
        return {
          editor,
          getHeight: () => containerRef.current?.clientHeight,
          getLineHeight: () => editor?.renderer.lineHeight,
        }
      },
      [editor],
    )

    const {
      anchor,
      containerClassName,
      variables: _variables = [],
      defaultVariables = [],
      variableTipsContainer,
      // viewport,
      role,
      showDelete,
      singleLine,
      showFullscreen,
      fullscreen,
      copy,
      copyPosition,
      noBorder,
      maxHeight,
      minHeight,
      onRoleClick,
      onDelete,
      onCopy,
      disabled,
      onFullscreenChange,
      ...aceProps
    } = mergedProps

    const { mode, onChange } = aceProps

    const variables = useMemo(() => {
      return unionBy(_variables.concat(defaultVariables), 'label')
    }, [_variables, defaultVariables])

    const [variablesState, setVariablesState] = useState<
      CodeEditorProps['variables']
    >([])

    // TODO 后续需要继续优化CODE_EDITOR，目前暂时使用该方法规避问题。
    useEffect(() => {
      if (!isEqualWith(variables, variablesState, customize)) {
        setVariablesState(variables)
      }
    }, [variables])

    const isFocusWithin = useFocusWithin(containerRef)

    useEffect(() => {
      if (!isFocusWithin) {
        editor?.selection.clearSelection()
      }
    }, [isFocusWithin])

    const [
      variableTipsVisible,
      { setTrue: showVariableTips, setFalse: hideVariableTips },
    ] = useBoolean(false)

    const variablesWithSnippets = useMemo(() => {
      return (
        variablesState?.map(v => {
          const snippet = bracketSnippetsModes.includes(mode as string)
            ? `{{${v.label}}}`
            : v.label
          return { ...v, snippet }
        }) || []
      )
    }, [variablesState, mode])
    useEffect(() => {
      if (editor != null && highlightMap[mode as string]) {
        const Mode = highlightMap[mode as string]
        const editorMode = new Mode(variablesState || [])
        editor.session.setMode(editorMode)
      }
    }, [editor, mode, variablesState])

    const showPlaceHolder = useRef(true)

    useEffect(() => {
      if (editor != null) {
        editor.onCompositionEnd = function () {
          showPlaceHolder.current = true
        }
        editor.onCompositionStart = function () {
          showPlaceHolder.current = false
        }
      }
    }, [editor])

    useEffect(() => {
      if (isFocusWithin && mode && editor) {
        // 自定义对象补全项目
        const snippets: Record<string, any> = {}
        const flattenObjectVariables = (variables: Variable[], prefix = '') => {
          variables.forEach(v => {
            if (v.property?.length) {
              flattenObjectVariables(v.property, `${prefix}${v.label}.`)
              snippets[`${prefix}${v.label}.`] = v.property.map(_v => {
                return {
                  caption: `${prefix}${v.label}.${_v.label}`,
                  meta: _v.type,
                  value: `${prefix}${v.label}.${_v.label}`,
                }
              })
            }
          })
        }
        flattenObjectVariables(
          variablesWithSnippets?.filter(v => !!v.property?.length) || [],
        )
        const customObjectCompleter = {
          id: 'customObjectCompleter',
          identifierRegexps: [/[a-zA-Z_0-9.\-\u00A2-\uFFFF]/],
          getCompletions(
            _editor: any,
            _session: any,
            _pos: any,
            _prefix: string,
            callback: any,
          ) {
            if (!Object.keys(snippets).length) {
              callback(null, [])
            }
            // 获取当前编辑器的内容
            if (_prefix[_prefix.length - 1] === '.') {
              callback(null, snippets[_prefix] || [])
              console.log(editor.completer.popup)
            }
          },
        }
        // 自定义自动补全项
        const customCompleter = {
          id: 'customCompleter',
          getCompletions(
            _editor: any,
            _session: any,
            _pos: any,
            _prefix: any,
            callback: any,
          ) {
            const completions = (variablesWithSnippets || [])?.map(v => {
              return {
                value: `${v.label}`,
                snippet: v.snippet,
                score: v.type === 'input' ? 1000 : 999,
                meta: v.type,
                className: `complete_${v.type} `,
                completer: {
                  insertMatch(editor: IAceEditor, data: any) {
                    editor.completer.insertMatch({ snippet: data.snippet })

                    // '/' 触发命令，补全后删除 '/' 符号
                    const currentPosition = editor.getCursorPosition()
                    const currentLine = editor.session.getLine(
                      currentPosition.row,
                    )
                    const charColumn =
                      currentPosition.column - data.snippet.length - 1
                    const char = currentLine.charAt(
                      currentPosition.column - data.snippet.length - 1,
                    )

                    if (char === '/') {
                      editor.session.replace(
                        // @ts-expect-error 删除符号
                        {
                          start: {
                            row: currentPosition.row,
                            column: charColumn,
                          },
                          end: {
                            row: currentPosition.row,
                            column: charColumn + 1,
                          },
                        },
                        '',
                      )
                    }
                    _editor.completers = keywordCompleterRef.current
                      ? [
                          keywordCompleterRef.current,
                          customCompleter,
                          customObjectCompleter,
                        ]
                      : [customCompleter, customObjectCompleter]
                  },
                },
              }
            })

            callback(null, [...completions])
          },
        }

        if (!keywordCompleterRef.current) {
          keywordCompleterRef.current = editor.completers?.find(
            v => v.id === 'keywordCompleter',
          ) as any
        }

        editor.completers = keywordCompleterRef.current
          ? [
              keywordCompleterRef.current,
              customCompleter,
              customObjectCompleter,
            ]
          : [customCompleter, customObjectCompleter]
      }
    }, [variablesWithSnippets, mode, isFocusWithin, editor])

    const commands = useMemo(() => {
      const _commands: ICommand[] = [defaultTriggerCommand]

      const isTextModel = mode === 'text'

      isTextModel && _commands.push(textModelTriggerCommand)

      if (singleLine) {
        _commands.push(singleLineCommand)
        _commands.push(disableFindCommand)
      }

      return _commands
    }, [singleLine, mode])

    const containerStyle = useMemo(() => {
      const isDark = aceProps.className?.includes('ace-gray')

      return {
        background: isDark ? '#F3F3F7' : 'white',
        maxHeight,
        minHeight,
        height: aceProps.height || '100%',
      }
    }, [
      isFocusWithin,
      aceProps.className,
      aceProps.readOnly,
      maxHeight,
      minHeight,
      aceProps.height,
      aceProps['aria-invalid'],
    ])

    const onTagClick = useCallback(
      (_tag: { label: string; type?: string }) => {
        const snippet = variablesWithSnippets.find(v => v.label === _tag.label)
          ?.snippet
        snippet && editor?.insert(snippet)
        editor?.focus()
        hideVariableTips()
      },
      [editor, variablesWithSnippets],
    )

    const handleCopy = useCallback(() => {
      const text = editor?.getValue()

      onCopy?.(text || '')
    }, [editor, onCopy])

    useKeyPress(
      [COPY_KEY],
      _e => {
        setTimeout(() => {
          const selectedText = editor?.session.getTextRange(
            editor?.getSelectionRange(),
          )
          copyTo(selectedText || '')
        }, 200)
      },
      { target: containerRef },
    )

    useKeyPress(
      FULLSCREEN_KEY,
      event => {
        event.preventDefault()
        onFullscreenChange?.(!fullscreen)
      },
      { useCapture: true },
    )

    const [isScrolling, setIsScrolling] = useState(false)
    // const isScrollingRef = useRef(false)
    const scrollTimer = useRef<any>(null)

    const handleScroll: IAceEditorProps['onScroll'] = () => {
      setIsScrolling(true)
      // isScrollingRef.current = true
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current)
      }
      // editor.session
      scrollTimer.current = setTimeout(() => {
        setIsScrolling(false)
        // isScrollingRef.current = false
      }, 600)
    }

    const innerContainerClassName = useMemo(() => {
      const { className } = aceProps

      const isDark = className?.includes('ace-gray')

      const isError = aceProps['aria-invalid'] === 'true'

      return noBorder
        ? 'relative'
        : classNames([
            'flex nodrag nopan items-start justify-between bg-opacity-8 rounded-6px transition-all-200 b-1px relative overflow-auto',
            className,
            containerClassName,
            {
              nowheel: isScrolling,
              'py-7 pl-4 pr-6': isDark,
              'hover:b-primary': !isError && !aceProps.readOnly && !disabled,
              'b-error': isError,
              'b-primary':
                isFocusWithin && !aceProps.readOnly && !isError && !disabled,
              'b-transparent':
                !isFocusWithin && !aceProps.readOnly && isDark && !isError,
              'py-4 pr-5': !isDark,
            },
          ])
    }, [
      aceProps.className,
      aceProps.theme,
      noBorder,
      aceProps['aria-invalid'],
      isFocusWithin,
      aceProps.readOnly,
      containerClassName,
      isScrolling,
      disabled,
    ])

    const blockAdapterStyle = useMemo(() => {
      if (aceEditorRef.current) {
        const el = aceEditorRef.current.refEditor
        const gutterEl = el.querySelector('.ace_gutter')

        if (!gutterEl)
          return {
            display: 'none',
          }

        const { width, display } = window.getComputedStyle(gutterEl) || {}
        return {
          width,
          display,
        }
      }
      return {}
    }, [aceEditorRef.current])

    const handleChange = useCallback(
      (value: string) => {
        onChange?.(transformValue(value))
      },
      [onChange],
    )

    useEventListener(
      'wheel',
      e => {
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // // @ts-expect-error
        // const scrollHeight = editor?.renderer.layerConfig.maxHeight
        // const scrollTop = editor?.session.getScrollTop() ?? 0
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // // @ts-expect-error
        // const clientHeight = editor?.renderer.$size.height
        // if (scrollHeight - scrollTop <= clientHeight) {
        //   e.preventDefault()
        // }
        variableTipsContainer && e.preventDefault()
      },
      { target: containerRef },
    )

    // 处理异常场景
    const formatValue = useMemo(() => {
      return aceProps.value && isObject(aceProps.value)
        ? JSON.stringify(aceProps.value)
        : aceProps.value
    }, [aceProps.value])

    return (
      <>
        {(isFocusWithin || variableTipsVisible) && !!variablesState?.length && (
          <div onMouseEnter={showVariableTips} onMouseLeave={hideVariableTips}>
            <TagsTooltip
              rootClassName='z-1'
              variables={variablesState.filter(item => !item.hideQuickSelect)}
              anchor={anchor}
              topEl={containerRef.current}
              leftEl={variableTipsContainer || containerRef.current}
              onTagClick={onTagClick}
            />
          </div>
        )}
        <div
          ref={containerRef}
          className={innerContainerClassName}
          style={containerStyle}
        >
          {role && (
            <div className='mt--2px'>
              <Role
                readOnly={aceProps.readOnly}
                role={role}
                onClick={() => onRoleClick?.(role)}
              />
            </div>
          )}
          {!noBorder && (
            <div
              className='bg-#f6f6f6 absolute left-0 top-0 b-rd-l-6px'
              style={blockAdapterStyle}
            />
          )}
          <AceEditor
            ref={aceEditorRef}
            onScroll={handleScroll}
            {...aceProps}
            value={formatValue}
            placeholder={showPlaceHolder.current ? props.placeholder : ''}
            height='100%'
            onChange={handleChange}
            className={classNames([props.className], 'pt-[2px]', {
              'opacity-60': disabled,
              '!b-0': disabled,
            })}
            commands={props.commands || commands}
            readOnly={disabled}
          />
          {showDelete && (
            <div className='m-t--4px w-20px'>
              <Delete onClick={onDelete} />
            </div>
          )}
          {copy && (
            <div
              className={classNames(
                'absolute right-8 z-10 flex items-center justify-center cursor-pointer w-24px h-24px rounded-4px hover:bg-#626299 hover:bg-opacity-12',
                {
                  'bottom-8': copyPosition === 'bottom',
                  'top-8': copyPosition === 'top',
                },
              )}
            >
              <IconFont
                name='copy'
                className='text-font_1 text-16px'
                onClick={handleCopy}
              />
            </div>
          )}
          {showFullscreen && (
            <Tooltip title='⌘+K' destroyTooltipOnHide>
              <button
                className='text-font_1 text-16px absolute right-16 top-8 z-10 flex items-center justify-center cursor-pointer w-24px h-24px rounded-4px hover:text-primary hover:bg-#626299 hover:bg-opacity-12'
                type='button'
                onClick={event => {
                  event.stopPropagation()
                  onFullscreenChange?.(!fullscreen)
                }}
              >
                <IconFont
                  name={fullscreen ? 'tuichuquanping1x' : 'quanping1x'}
                />
              </button>
            </Tooltip>
          )}
        </div>
      </>
    )
  }),
)
