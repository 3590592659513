import { useState } from 'react'
import { Divider, Spin, message } from 'antd'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useRequest } from 'ahooks'
import styled from '@emotion/styled'
import {
  IconFont,
  Button,
  ResponsiveCardGrid,
  ResponsiveCard,
  AppLogo,
} from '@/components'
import { useBackBefore, useScrollBar } from '@/hooks/useScrollBar.ts'
import Empty from '@/features/database/components/Empty.tsx'
import { useModal } from '@/hooks/useModal.tsx'
import { CreateModal } from '@/components/createAppModal/CreateModal.tsx'
import { useWorkspaceStore } from '@/store'
import {
  getDBList,
  createDatabase,
  bulkGetDBAppIds,
  bulkGetDBTableCount,
} from '@/apis/database/api.ts'
import { normalAppInfo2DatabaseInfo } from '@/features/database/utils/database.ts'
import { TableNameValidateRule } from '@/features/database/constant/table.ts'
import { iconColors } from '@/constants/icon'
import { AppDropDown } from '../agent/components/AppDropDown'
import {
  SelectType,
  useWorkspaceSelectModal,
} from '../application/components/WorkSpaceSelect'

// import type { DataStoreType } from '@/apis/application'
import { forkDataBase } from '@/apis/application'
import { useCache } from '@/hooks/useCache'
import { useVersion } from '@/hooks/useVersion'
import { DB_LIST_SCROLL_TOP, DB_LIST_SEARCH } from '@/constants/storage'
import { LimitedAlertBar } from '@/features/pay/LimitedAlert'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { Search } from './components/Search'

const DataStoreCard = styled(ResponsiveCard)`
  .ant-btn {
    border: none;
    font-size: 20px !important;
    visibility: hidden;
    .c-font_1 {
      color: #17171d !important;
    }
  }
  &:hover {
    .ant-btn {
      visibility: visible;
    }
  }
`
export function DatabaseList() {
  const [searchName, setSearchName] = useCache(DB_LIST_SEARCH, '')
  const [tableCountMap, setTableCountMap] = useState<Record<string, number>>({})
  const [appCountMap, setAppCountMap] = useState<Record<string, number>>({})
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const navigate = useNavigate()
  const { runAsync: fetchForkDataBase, loading: copyLoading } = useRequest(
    forkDataBase,
    { manual: true },
  )
  const { isTeam } = useVersion()

  const {
    data: dbListRes,
    loading,
    runAsync: fetchDBList,
  } = useRequest(getDBList, {
    refreshDeps: [workspaceId],
    onSuccess: async data => {
      const ids = data.list?.map(item => item.id) ?? []
      if (ids.length) {
        bulkGetDBAppIds(ids).then(res => {
          const newAppCountMap: Record<string, number> = {}
          res.forEach(item => {
            newAppCountMap[item.database] = item.applications?.length ?? 0
          })
          setAppCountMap(newAppCountMap)
        })
        bulkGetDBTableCount(ids).then(res => {
          setTableCountMap(res)
        })
      }
    },
  })
  const dbList = dbListRes?.list ?? []

  const { scrollRef, scrollBar } = useScrollBar()
  useBackBefore(scrollBar, DB_LIST_SCROLL_TOP, [!!dbList.length])

  const [createModal] = useModal(CreateModal)

  const handleDatabaseCopy = async (
    target_workspace_id: string,
    source_database_id: string,
  ) => {
    await fetchForkDataBase({
      source_database_id,
      source_workspace_id: workspaceId,
      target_workspace_id,
    })
    message.success('复制成功')
    fetchDBList()
    onCopySuccess()
  }
  const [copyModal] = useWorkspaceSelectModal({
    onConfirm: handleDatabaseCopy,
    defaultWorkspaceId: workspaceId,
    copyType: '复制数据库',
    loading: copyLoading,
  })

  function onCopySuccess() {
    copyModal.close()
  }

  const onCreate = () => {
    createModal.open({
      title: '新建数据库',
      logoType: 'emoji',
      iconColors: iconColors.filter(v => v.startsWith('#')),
      fieldConfig: {
        name: {
          placeholder: '请输入数据库名称，例如：商品库',
          rules: [TableNameValidateRule],
        },
        description: {
          placeholder:
            '请描述数据库的用途或内容，让Agent更加理解此数据库的功能',
        },
      },
      onFinish: async data => {
        const res = await createDatabase(normalAppInfo2DatabaseInfo(data))
        createModal.close()
        navigate(`/db/${workspaceId}/${res.id}`)
      },
    })
  }

  return (
    <div className='w-full h-full flex'>
      <div className='adapt:pt-40 bg-bg rounded-12px flex-1 overflow-hidden w-full h-full flex flex-col'>
        <div className='adapt:px-64'>
          <div className='flex flex-item-center justify-between pb-24 b-b-line b-op-40 border-b-1px'>
            <div className='flex items-center h-36px flex-wrap'>
              <span className='line-height-36px font-500 text-20px nowrap'>
                数据库
              </span>
            </div>
            <div className='flex items-center'>
              <Search onSearch={setSearchName} />
              <Divider type='vertical' className='mx-20 h-26px' />
              <LimitedAccess limitedType>
                <Button
                  type='primary'
                  icon={<IconFont name='fangtai' />}
                  onClick={onCreate}
                >
                  新建数据库
                </Button>
              </LimitedAccess>
            </div>
          </div>
        </div>
        <div className='py-20 flex-1 overflow-hidden relative'>
          <div className='adapt:px-64'>
            <LimitedAlertBar className='mb-20px' />
          </div>
          <Loading loading={loading} />
          {!loading && !dbList?.length && !isTeam && (
            <Empty desc='暂无数据库'>
              <LimitedAccess limitedType>
                <Button
                  onClick={onCreate}
                  type='primary'
                  icon={<IconFont name='fangtai' />}
                >
                  新建数据库
                </Button>
              </LimitedAccess>
            </Empty>
          )}
          <div
            ref={scrollRef}
            className='w-full h-full overflow-y-auto adapt:px-64'
          >
            <ResponsiveCardGrid className='content-start w-full h-full'>
              {(searchName
                ? dbList.filter(el => el.title.includes(searchName))
                : dbList
              ).map(item => {
                return (
                  <DataStoreCard
                    className='flex-col flex justify-between rounded-12px bg-white cursor-pointer h-160px hover:shadow-[0_8px_24px_0px_rgba(23,23,29,0.12)]'
                    key={item.id}
                    onClick={() => navigate(`/db/${workspaceId}/${item.id}`)}
                  >
                    <div>
                      <div className='flex px-20 py-20 w-full overflow-hidden'>
                        <AppLogo
                          type='emoji'
                          color={item.icon_color}
                          value={item.icon}
                        />
                        <div className='ml-16 mt-2 w-full overflow-hidden'>
                          <p className='font-500 text-16px line-clamp-2'>
                            {item.title}
                          </p>
                          <p className='text-12px line-height-18px mt-8px text-font_1 max-h-66px overflow-hidden line-clamp-2 break-all'>
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='b-t b-line b-op-40 flex justify-between items-center px-20px h-52px'>
                      <div className='flex gap-x-24'>
                        <div className='text-12px c-font_1 line-height-12px flex items-center font-400'>
                          <IconFont name='doc' className='text-10px c-font_1' />
                          <span className='mx-4'>
                            {tableCountMap[item.id] ?? 0}
                          </span>
                          <span>数据表</span>
                        </div>
                        <div className='text-12px c-font_1 line-height-12px flex items-center font-400'>
                          <IconFont name='app' className='text-10px c-font_1' />
                          <span className='mx-4'>
                            {appCountMap[item.id] ?? 0}
                          </span>
                          <span>个关联应用</span>
                        </div>
                      </div>
                      <AppDropDown
                        app={item}
                        selectType={SelectType.DATABASE}
                        handleDelete={() => {
                          fetchDBList()
                        }}
                        isRelateApp={appCountMap[item.id] > 0}
                        handleFork={() => {
                          copyModal.open({ partition_id: item.id })
                        }}
                      />
                    </div>
                  </DataStoreCard>
                )
              })}
            </ResponsiveCardGrid>
          </div>
        </div>
      </div>
    </div>
  )
}

function Loading(props: { loading: boolean }) {
  return (
    <div
      className={classNames('absolute top-0 left-0 w-full h-full flex-center', {
        'pointer-events-none': !props.loading,
      })}
    >
      <Spin spinning={props.loading} />
    </div>
  )
}
