import { useEffect, useMemo, useRef, useState } from 'react'
import type { InputRef } from 'antd'
import { isUndefined } from 'lodash-es'
import { useInViewport } from 'ahooks'
import { IconFont, Input, LabelTabs } from '@/components'
import type { NodeType } from '@/features/nodes/base'
import { BaseNodeOptions, PluginOrderMap } from '@/features/nodes/base'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { transformPluginData } from '@/features/nodes/plugin/util'
import { useNodeOptionSelect } from '../hooks/useNodeOptionSelect'
import EmptyImg from '@/assets/flow/empty.png'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { BaseNodeList } from './BaseNodeList'
import { PluginList } from './PluginList'
import { FlowList } from './FlowList'
import { TemplateList } from './TemplateList'

type ToolBarTabKey = 'base' | 'plugins' | 'flow' | 'template'

const TABS: { label: string; key: ToolBarTabKey }[] = [
  {
    label: '基础能力',
    key: 'base',
  },
  {
    label: '模板',
    key: 'template',
  },
  {
    label: '插件',
    key: 'plugins',
  },
  {
    label: FLOW_DISPLAYNAME,
    key: 'flow',
  },
]

interface InsertNodePanelProps {
  onSelect: (data: { type: NodeType; data?: any }) => void
}

export function InsertNodePanel(props: InsertNodePanelProps) {
  const { onSelect } = props

  const [activeTab, setActiveTab] = useState<ToolBarTabKey>('base')
  const [searchValue, setSearchValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const baseNodeListRef = useRef<HTMLDivElement | null>(null)
  const templateListRef = useRef<HTMLDivElement | null>(null)
  const pluginListRef = useRef<HTMLDivElement | null>(null)
  const flowListRef = useRef<HTMLDivElement | null>(null)

  const [baseInViewport] = useInViewport(baseNodeListRef, {
    threshold: 0.5,
  })
  const [templateInViewport] = useInViewport(templateListRef, {
    threshold: 0.5,
  })
  const [pluginInViewport] = useInViewport(pluginListRef, {
    threshold: 0.3,
  })
  const [flowInViewport] = useInViewport(flowListRef, {
    threshold: 0.1,
  })

  useEffect(() => {
    if (baseInViewport) {
      setActiveTab('base')
      return
    }
    if (templateInViewport) {
      setActiveTab('template')
      return
    }
    if (pluginInViewport) {
      setActiveTab('plugins')
      return
    }
    if (flowInViewport) {
      setActiveTab('flow')
    }
  }, [baseInViewport, pluginInViewport, flowInViewport])

  const { templateList, pluginList, subFlowList } = useNodeMetaStore()

  const onTabChange = (key: string) => {
    setActiveTab(key as ToolBarTabKey)
    // if (key === 'base') {
    //   baseNodeListRef.current?.scrollIntoView({ behavior: 'smooth' })
    //   return
    // }
    // if (key === 'plugins') {
    //   pluginListRef.current?.scrollIntoView({ behavior: 'smooth' })
    //   return
    // }
    // if (key === 'flow') {
    //   flowListRef.current?.scrollIntoView({ behavior: 'smooth' })
    // }
    if (containerRef.current) {
      const { scrollTop } = containerRef.current
      let h = 0
      if (key === 'base' && baseNodeListRef.current) {
        h = baseNodeListRef.current.offsetTop
      }
      if (key === 'template' && templateListRef.current) {
        h = templateListRef.current.offsetTop
      }
      if (key === 'plugins' && pluginListRef.current) {
        h = pluginListRef.current.offsetTop
      }
      if (key === 'flow' && flowListRef.current) {
        h = flowListRef.current.offsetTop
      }
      containerRef.current.scrollBy({
        top: h - 114 - scrollTop,
        behavior: 'smooth',
      })
    }
  }

  const nodeOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return BaseNodeOptions
    }
    return BaseNodeOptions.map(item => ({
      ...item,
      data: item.data.filter(n =>
        n.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    })).filter(item => item.data.length > 0)
  }, [BaseNodeOptions, searchValue])

  const templateOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return templateList
    }
    return templateList.filter(item =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    )
  }, [templateList, searchValue])

  const pluginOptions = useMemo(() => {
    let list = pluginList
    if (searchValue.trim()) {
      list = list.filter(item =>
        item.display_name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    }
    return transformPluginData(list).sort((a, b) => {
      const orderA = isUndefined(PluginOrderMap[a.label])
        ? 999
        : PluginOrderMap[a.label]
      const orderB = isUndefined(PluginOrderMap[b.label])
        ? 999
        : PluginOrderMap[b.label]
      return orderA - orderB
    })
  }, [pluginList, searchValue])

  const subFlowOptions = useMemo(() => {
    if (!searchValue.trim()) {
      return subFlowList
    }
    return subFlowList.filter(item =>
      item.flow_name.toLowerCase().includes(searchValue.toLowerCase()),
    )
  }, [subFlowList, searchValue])

  const showEmpty = useMemo(() => {
    const count =
      nodeOptions.length +
      pluginOptions.length +
      subFlowOptions.length +
      templateOptions.length
    return !!searchValue.trim() && count <= 0
  }, [searchValue, nodeOptions, subFlowOptions, pluginOptions])

  const {
    onBaseNodeSelect,
    onTemplateSelect,
    onPluginSelect,
    onSubflowSelect,
  } = useNodeOptionSelect(onSelect)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div
      id='insertNodePanel'
      className='nodrag nopan nowheel flex flex-col max-h-610px w-340px bg-#fff rounded-8px b-1 b-#e1e1e5 b-op-60 shadow-[0_8px_24px_0px_rgba(0,0,0,0.12)]'
      onClick={e => e.stopPropagation()}
    >
      <div className='p-12px b-b-1 b-#e1e1e5 b-op-60'>
        <Input
          ref={inputRef}
          className='mb-12px'
          prefix={<IconFont name='search' className='text-16px' />}
          allowClear
          placeholder='搜索节点'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
        <LabelTabs activeKey={activeTab} labels={TABS} onChange={onTabChange} />
      </div>
      {showEmpty ? (
        <div className='flex-center flex-col h-190px'>
          <img className='w-80px h-80px' src={EmptyImg} alt='' />
          <div className='text-12px text-font_2'>无搜索结果</div>
        </div>
      ) : (
        <div
          ref={containerRef}
          className='flex-1 px-12px pb-10px pt-4px overflow-auto scrollbar scrollbar-w-0'
        >
          <div ref={baseNodeListRef}>
            <BaseNodeList data={nodeOptions} onSelect={onBaseNodeSelect} />
          </div>
          <div ref={templateListRef}>
            <TemplateList data={templateOptions} onSelect={onTemplateSelect} />
          </div>
          <div ref={pluginListRef}>
            <PluginList data={pluginOptions} onSelect={onPluginSelect} />
          </div>
          <div ref={flowListRef}>
            <FlowList data={subFlowOptions} onSelect={onSubflowSelect} />
          </div>
        </div>
      )}
    </div>
  )
}
