import { memo } from 'react'
import { Slider } from 'antd'
import { FormItem } from '@/components'
import { FlatViewSelect } from '@/features/prompt/components/PromptStructEditor/FlatViewSelect.tsx'
import {
  WithQuickFillInput,
  WithQuickFillTextArea,
} from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'

export const RoleForm = memo<{
  placeholderMap: Record<string, string>
}>(({ placeholderMap }) => {
  return (
    <>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='角色信息'
        name='role'
      >
        <WithQuickFillInput
          autoFocus
          className='py-6'
          placeholder={placeholderMap.role}
        />
      </FormItem>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='使用场景'
        name='scene'
      >
        <WithQuickFillInput
          className='py-6'
          placeholder={placeholderMap.scene}
        />
      </FormItem>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='工作任务'
        name='task'
      >
        <WithQuickFillTextArea
          rows={3}
          className='resize-none!'
          placeholder={placeholderMap.task}
        />
      </FormItem>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='人设性格'
        name='personality'
      >
        <FlatViewSelect
          options={['阳光', '开朗', '幽默', '专业', '热情', '认真']}
        />
      </FormItem>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='人设语气'
        name='tone'
      >
        <FlatViewSelect
          options={['正式', '友善', '友好', '规范', '专业', '搞笑']}
        />
      </FormItem>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='回答长度'
        name='outputLength'
      >
        <Slider
          dots
          min={1}
          max={3}
          marks={{
            1: <div className='mt-5'>短</div>,
            2: <div className='mt-5'>适中</div>,
            3: <div className='mt-5'>长</div>,
          }}
        />
      </FormItem>
    </>
  )
})

RoleForm.displayName = 'RoleForm'
