import { rgba } from 'polished'
import { useEffect, useMemo, useState } from 'react'
import {
  Divider,
  Radio,
  Popover,
  type RadioChangeEvent,
  Spin,
  message,
} from 'antd'
import classNames from 'classnames'
import { useBoolean, useCountDown, useRequest } from 'ahooks'
import { InputNumber, Button, IconFont } from '@/components'
import { HAS_REPEATED_ORDER_CODE, OrderType, Version } from '../const'
import { padZero } from '../util'
import theme from '@/constants/theme'
import { useVersionStore, useWorkspaceStore } from '@/store'
import {
  getOrderCalculatedPrice,
  type CalculatePriceInfo,
  createOrder,
} from '@/apis/order'
import type { VersionConfig } from '@/apis/version'
import { RepeatOrderModal } from './components/RepeatOrderModal'

interface VersionItemProps {
  name: string
  price: number
  active?: boolean
  unit: string
  className?: string
  disabled?: boolean
  onClick?: () => void
}

const DISCOUNT_LEFT_TIME = 30 * 1000 * 60
function VersionItem(props: VersionItemProps) {
  const { name, price, unit, active, className, disabled, onClick } = props
  const style = useMemo(() => {
    return active
      ? {
          border: `2px solid ${rgba(theme.colors.primary, 1)}`,
          boxShadow: '0px 4px 12px 0px rgba(123, 97, 255, 0.24)',
        }
      : {}
  }, [active])

  const clsName = classNames(
    'w-430px flex items-center justify-between p-24px b-1 b-line b-opacity-60 rd-12px cursor-pointer',
    className ?? '',
    {
      'bg-bg_3 bg-opacity-6 cursor-not-allowed text-font_1 text-opacity-60':
        disabled,
    },
  )
  return (
    <div
      style={style}
      className={clsName}
      onClick={() => !disabled && onClick?.()}
    >
      <div className='text-20px font-800'>{name}</div>
      <div className='text-20px font-800'>
        {price}
        <span className='font-500 text-14px'> / {unit}</span>
      </div>
    </div>
  )
}

function Price(
  props: CalculatePriceInfo & { version: Version; orderType: OrderType },
) {
  const {
    real_pay_amount,
    task_recovery_amount,
    discount_amount,
    total_amount,
    version,
    orderType,
  } = props

  const discountAmount = (discount_amount + task_recovery_amount)?.toFixed(2)
  const [_, formattedRes] = useCountDown({
    leftTime: DISCOUNT_LEFT_TIME,
  })
  const { minutes, seconds } = formattedRes
  return (
    <div className='flex flex-col items-end'>
      <div className='flex items-end'>
        <div className='text-16px font-600'>费用合计</div>
        <div className='text-#E54A17 text-24px font-800 ml-8'>
          <span className='text-16px'>¥</span>
          {real_pay_amount}
        </div>
      </div>
      {discountAmount !== '0.00' && (
        <div className='flex mt-14px text-12px'>
          <IconFont name='youhui' />
          <div className='ml-2'>
            <>
              共省{' '}
              <span className='text-#E54A17'>
                ¥{(discount_amount + task_recovery_amount)?.toFixed(2)}
              </span>
              ，优惠保留时间{' '}
              <span className='text-#E54A17'>
                {padZero(minutes)}:{padZero(seconds)}
              </span>
            </>
          </div>
          <Popover
            title='优惠明细'
            content={
              <div className='w-248px'>
                <div className='flex justify-between mt-12 text-12px'>
                  <div>{version}版本订购</div> <div>¥{total_amount}</div>
                </div>
                {discount_amount !== 0 && (
                  <div className='flex justify-between mt-12 text-12px'>
                    <div>{version}版本订购折扣</div>{' '}
                    <div className='text-#E54A17'>-{discount_amount}</div>
                  </div>
                )}
                {orderType === OrderType.SUB &&
                  version === Version.TEAM &&
                  task_recovery_amount !== 0 && (
                    <div className='flex justify-between mt-12 text-12px'>
                      <div>个人版剩余用量冲抵费用</div>{' '}
                      <div className='text-#E54A17'>
                        -{task_recovery_amount}
                      </div>
                    </div>
                  )}
              </div>
            }
          >
            <IconFont
              name='jieshishuimeng'
              className='text-font_1 opacity-40 ml-3 cursor-pointer'
            />
          </Popover>
        </div>
      )}
    </div>
  )
}

export interface VersionSelectProps {
  onOrderCallback?: (orderId: string) => void
  version?: Version
  orderType: OrderType
  duration?: number
}

const OTHER_DURATION_TAG = -1
export function VersionSelect(props: VersionSelectProps) {
  const {
    onOrderCallback,
    duration: initialDuration = 12,
    version = Version.PERSONAL,
    orderType = OrderType.SUB,
  } = props
  const [duration, setDuration] = useState(initialDuration)
  const [currVersion, setCurrVersion] = useState(version)
  const [currOrderType] = useState(orderType)

  const [otherDuration, setOtherDuration] = useState(12)

  const [repeatOrderModalVisible, { toggle: toggleModal }] = useBoolean(false)

  const { versionConfigList, versionInfo } = useVersionStore()

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const {
    runAsync: reCalculatePriceApi,
    data: priceInfo,
    loading: priceLoading,
  } = useRequest(getOrderCalculatedPrice, { manual: true })

  const { runAsync: createOrderApi, loading: createOrderLoading } = useRequest(
    createOrder,
    { manual: true },
  )

  const filteredVersionList = useMemo(() => {
    return versionConfigList?.filter(item => {
      return ![Version.FREE, Version.ENTERPRISE].includes(item.service_code)
    })
  }, [versionConfigList])

  const currVersionId = useMemo(
    () => versionConfigList.find(v => v.service_code === currVersion)?.id,
    [versionConfigList, currVersion],
  )

  const onChange = (e: RadioChangeEvent) => setDuration(e.target.value)

  const actualDuration = useMemo(() => {
    return duration === OTHER_DURATION_TAG ? otherDuration || 0 : duration
  }, [duration, otherDuration])

  const handleDoOrder = async () => {
    if (currVersionId) {
      const res = await createOrderApi({
        workspace_id: currentWorkspaceId,
        product_service_id: currVersionId,
        duration_of_service: actualDuration,
        order_type: currOrderType,
      }).catch(err => {
        if (err.business && err.code === HAS_REPEATED_ORDER_CODE) {
          toggleModal()
        } else {
          message.warning(err.message)
        }
      })
      res && onOrderCallback?.(res)
    }
  }

  useEffect(() => {
    currVersionId &&
      reCalculatePriceApi({
        workspace_id: currentWorkspaceId,
        product_service_id: currVersionId,
        duration_of_service: actualDuration,
        order_type: currOrderType,
      })
  }, [
    currVersion,
    currOrderType,
    currVersionId,
    currentWorkspaceId,
    actualDuration,
  ])

  const isDisableItem = (item: VersionConfig) => {
    if (
      versionInfo?.product_code === Version.FREE &&
      orderType === OrderType.SUB
    ) {
      return false
    }

    return (
      (version !== item.service_code && orderType === OrderType.RENEW) ||
      (version === Version.TEAM && item.service_code === Version.PERSONAL)
    )
  }

  return (
    <div className='p-60 bg-white rd-12px'>
      <h3 className='text-16px font-600'>选择购买版本</h3>
      <div className='flex mt-20'>
        {filteredVersionList?.map((item, index) => {
          return (
            <VersionItem
              key={item.service_code}
              active={currVersion === item.service_code}
              className={index === 0 ? '' : 'ml-20'}
              name={item.service_name}
              disabled={isDisableItem(item)}
              price={item.price}
              onClick={() => setCurrVersion(item.service_code as Version)}
              unit='月'
            />
          )
        })}
      </div>
      <h3 className='text-16px font-600 mt-40'>购买时长</h3>
      <div className='mt-20 h-40px flex items-center'>
        <Radio.Group onChange={onChange} value={duration}>
          <Radio value={1}>1个月</Radio>
          <Radio value={6}>6个月</Radio>
          <Radio value={12}>
            <div className='flex items-center'>
              <span>12个月</span>
              <div className='bg-#FC6B8D text-white text-10px rd-21px px-6 py-2 ml-4'>
                {versionConfigList?.[1]?.year_discount * 10}折
              </div>
            </div>
          </Radio>
          <Radio value={-1}>
            <div className='flex items-center'>
              <span>其他</span>
              {duration === OTHER_DURATION_TAG && (
                <>
                  <div className='w-48px ml-12 mr-8'>
                    <InputNumber
                      value={otherDuration}
                      onChange={val => setOtherDuration(val as number)}
                      min={1}
                      max={60}
                      size='small'
                    />
                  </div>
                  <span>个月</span>
                </>
              )}
            </div>
          </Radio>
        </Radio.Group>
      </div>
      <Divider className='mt-30 mb-40' />
      <div className='flex justify-end'>
        <Spin spinning={priceLoading}>
          <Price
            {...(priceInfo || ({} as CalculatePriceInfo))}
            version={version}
            orderType={orderType}
          />
        </Spin>
      </div>
      <div className='flex justify-end mt-20'>
        <Button
          type='primary'
          onClick={handleDoOrder}
          disabled={
            !actualDuration ||
            Boolean(
              priceInfo?.real_pay_amount && priceInfo?.real_pay_amount <= 0,
            )
          }
          loading={createOrderLoading}
          className='w-120px'
        >
          立即下单
        </Button>
      </div>
      <RepeatOrderModal
        repeatOrderModalVisible={repeatOrderModalVisible}
        toggleModal={toggleModal}
      />
    </div>
  )
}
