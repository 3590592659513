import styled from '@emotion/styled'
import type { FormInstance } from 'antd'
import { Checkbox, Form, Radio } from 'antd'
import dayjs from 'dayjs'
import { Input, Select, usePageModal } from '@/components'
import theme from '@/constants/theme'

const timeOptions = [
  { label: '每分', value: 1 },
  { label: '每时', value: 2 },
  { label: '每天', value: 3 },
  { label: '每周', value: 4 },
  { label: '月份', value: 5 },
  { label: '高级', value: 6 },
]

const weekOptions = [
  { label: '周一', value: '1' },
  { label: '周二', value: '2' },
  { label: '周三', value: '3' },
  { label: '周四', value: '4' },
  { label: '周五', value: '5' },
  { label: '周六', value: '6' },
  { label: '周日', value: '0' },
]

const minute = Array.from({ length: 59 }).map((_, index) => ({
  label: String(index + 1),
  value: String(index + 1),
}))

const hour = Array.from({ length: 23 }).map((_, index) => ({
  label: String(index + 1),
  value: String(index + 1),
}))

function day() {
  const days = Array.from({ length: dayjs().daysInMonth() })
  return days.map((_, index) => ({
    // label: days.length - 2 === index ? '本月最后一天' : String(index + 1),
    // value: days.length - 2 === index ? 'L' : String(index + 1),
    label: String(index + 1),
    value: String(index + 1),
  }))
}

const SelectWarpper = styled.div`
  width: calc(50% - 10px) !important;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

interface resultType {
  minute: number
  hour: number
  day: number
  week: string
  format: string
}

function SelectCom({
  title,
  list,
  name,
}: {
  title: string
  field: keyof resultType
  list: { label: number | string; value: number | string }[]
  name: string
}) {
  return (
    <SelectWarpper>
      {title}
      <Form.Item noStyle name={name}>
        <Select options={list} />
      </Form.Item>
    </SelectWarpper>
  )
}

export const formInitValues = {
  type: 1,
  min: '10',
  hour: '1',
  week: ['1'],
  day: '1',
}

export default ({ form }: { form: FormInstance }) => {
  const formMin = Form.useWatch('min', form)
  const formHour = Form.useWatch('hour', form)
  const formWeek = Form.useWatch('week', form)
  const formDay = Form.useWatch('day', form)
  const type = Form.useWatch('type', form)

  const pageModal = usePageModal()
  return (
    <div className='w-100%'>
      <Form.Item noStyle name='type'>
        <Radio.Group options={timeOptions} className='m-b-24px' />
      </Form.Item>
      {type === 5 && (
        <div className='m-b-12px'>
          <SelectCom title='天' name='day' field='day' list={day()} />
        </div>
      )}
      {type === 4 && (
        <Form.Item noStyle name='week'>
          <Checkbox.Group options={weekOptions} className='m-b-24px' />
        </Form.Item>
      )}
      {type === 1 && (
        <div>
          <SelectCom
            name='min'
            title='分钟'
            field='minute'
            list={minute.slice(type === 1 ? 9 : 0, minute.length)}
          />
          <div className='m-t-24px'>每隔{formMin}分钟运行</div>
        </div>
      )}
      {(type === 2 || type === 3 || type === 4 || type === 5) && (
        <>
          <div className='flex gap-20px'>
            <SelectCom name='hour' title='小时' field='hour' list={hour} />
            <SelectCom name='min' title='分钟' field='minute' list={minute} />
          </div>
          <div className='m-t-24px'>
            {type === 2 && `每间隔${formHour}小时，在第${formMin}分钟执行`}
            {type === 3 && `每天的${formHour}时${formMin}分执行`}
            {type === 4 && `每周${formWeek}的${formHour}时${formMin}分执行`}
            {type === 5 && `每月第${formDay}天的${formHour}时${formMin}分执行`}
          </div>
        </>
      )}
      {type === 6 && (
        <div className='flex flex-col gap-12px m-t-24px'>
          时间表达式
          <Form.Item noStyle name='cron'>
            <Input className='w-456px' placeholder='请输入时间表达式' />
          </Form.Item>
          <a
            style={{ color: theme.colors.primary }}
            onClick={() =>
              pageModal.show({
                url: 'https://help.aliyun.com/document_detail/133509.html',
              })
            }
          >
            常用表达式
          </a>
        </div>
      )}
    </div>
  )
}

export function getTimeFormValue(e = '') {
  return e ? (e === '*' ? '' : e) : ''
}
