import type { MemoExoticComponent } from 'react'
import type { Node } from 'reactflow'
import type { NodeValidateFunction } from '@/store'
import type { APINodeData } from '..//api'
import type { ApplicationNodeData } from '../application'
import type { CodeNodeData } from '../code'
import type { EndNodeData } from '../end'
import type { LLMNodeData } from '../llm'
import type { MemoryNodeData } from '../memory'
import type { IStartNodeFormItemType, StartNodeData } from '../start'

export enum NodeType {
  START = 'START',
  END = 'END',
  LLM = 'LLM',
  TEXT = 'TEXT',
  CODE = 'CODE',
  MEMORY = 'MEMORY',
  KNOWLEDGE = 'KNOWLEDGE',
  DATABASE = 'DATABASE',
  INSERT_MEMORY = 'INSERT_DATA',
  API = 'API',
  EMPTY = 'EMPTY',
  APP = 'APP',
  CONDITION = 'CONDITION',
  CONDITION_RESULT = 'CONDITION_RESULT',

  INTENT = 'INTENT',
  INTENT_RESULT = 'INTENT_RESULT',

  FLOW = 'SUB_FLOW',
  PLUGIN = 'PLUGIN',
  LOOP = 'LOOP',
  LOOP_RESULT = 'LOOP_RESULT',
  LLM_BATCH = 'LLM_BATCH',
  PYTHON = 'PYTHON',
  JAVASCRIPT = 'JAVASCRIPT',
  TEMPLATE = 'TEMPLATE',
}

export type ActionType =
  | 'TRIGGER'
  | 'CODE'
  | 'INTEGRATION'
  | 'LOOP'
  | 'LOOP_RESULT'
  | 'BRANCH'
  | 'END'
  | 'MISSING'
  | 'EMPTY'
  | 'CONDITION'
  | 'CONDITION_RESULT'
  | 'INTENT'
  | 'INTENT_RESULT'
  | 'SUB_FLOW'
  | 'PLUGIN'
  | 'ASYNC_LLM'
  | 'TEXT'
  | 'TEMPLATE'

export enum VariableType {
  Input = 'input',
  Node = 'node',
  LoopScope = 'loopscope',
}

export enum SUB_FLOW_STATUS {
  ACTIVE = 'ACTIVE',
  UNABLE = 'UNABLE',
  DELETED = 'DELETED',
}

export enum PLUGIN_STATUS {
  ACTIVE = 'ACTIVE',
  UNABLE = 'UNABLE',
  DELETED = 'DELETED',
}

export interface InnerNodePanelProps<T = Record<string, unknown>> {
  data: T
  onSaveChange: any
  snapshotData?: T
  onSnapshotChange?: any
  onClosePanel: (data: T) => void
  variables: any[]
  activatedNodeId: string | undefined
  nodeElement: HTMLElement | null
  nodeSize?: {
    width: number
    height: number
  }
  startNodeFormItemType?: IStartNodeFormItemType[]
}

export interface NodePanelComponent<T = any> {
  (props: InnerNodePanelProps<T>): JSX.Element
}

export interface InnerNodeHeaderProps<T = Record<string, unknown>> {
  data: T
  onClosePanel: () => void
  activatedNodeId: string | undefined
  onSaveChange: any
  triggerNode: any
}

export interface NodePanelHeader<T = Record<string, unknown>> {
  (props?: InnerNodeHeaderProps<T>): JSX.Element | null
}

export interface PatchVariableChangeOptions {
  newVariableName: string
  oldVariableName: string
}

export enum NodeOperationTypes {
  SINGLE_NODE = 'SINGLE_NODE',
  MULTI_BRANCH_NODE = 'MULTI_BRANCH_NODE',
  LOOP_NODE = 'LOOP_NODE',
  TEMPLATE_NODE = 'TEMPLATE_NODE',
}

// 节点元数据，用于显示节点基础信息
export interface NodeMeta<T = any> {
  type: NodeType
  actionType?: ActionType
  typeName: string
  icon?: string
  description?: string
  backgroundColor?: string
  borderColor?: string
  canDelete?: boolean
  isStart?: boolean
  isEnd?: boolean
  isEmpty?: boolean
  customSourceHandle?: boolean
  customTargetHandle?: boolean
  resizeable?: boolean
  resizeConfig?: {
    maxWidth?: number
    maxHeight?: number
    minWidth?: number
    minHeight?: number
  }
  initialData?: T & { comment?: string }
  isToolNode?: boolean // 是否为工具型节点，在flow中无实际业务含义
  classNames?: string[]
  operationType: NodeOperationTypes
}

export interface InnerNodeProps<T = Record<string, unknown>> {
  id: string
  data: T
  nodeElement: HTMLElement | null
  isOpenedPanel: boolean
  isResizing: boolean
  focused: boolean
  nodeSize: {
    width: number
    height: number
  }
  variables: Array<{ label: string; type: VariableType }> // 可用的变量列表
  onSaveChange: (
    data: Partial<T>,
    isMergeUpdate?: boolean,
    patchVariableChangeOptions?: PatchVariableChangeOptions,
  ) => void
  onOpenPanel: () => void
  onDelete: () => void
  onClosePanel: (data: T) => void
  validateNode: () => void
  registerValidateFunction: (validateFunc: NodeValidateFunction) => void // 注册校验函数，重复校验，后面的注册会覆盖前面的校验
  removeValidateFunction?: () => void
}

export interface NodeComponent<T = any> {
  (props: InnerNodeProps<T>): JSX.Element
  meta: NodeMeta<T>
}
export type NodeData =
  | StartNodeData
  | LLMNodeData
  | MemoryNodeData
  | APINodeData
  | CodeNodeData
  | ApplicationNodeData
  | EndNodeData
export interface NodeConfig {
  node: MemoExoticComponent<(props: Node<NodeData>) => JSX.Element>
  meta: NodeMeta
  panel?: NodePanelComponent<any>
  panelHeader?: NodePanelHeader
}

export interface GeneralNodeData {
  name: string
  packageName: string
  actionType: ActionType
  isEnable: boolean
  inputs: Record<string, unknown>
  relation: Record<string, string> | null
  comment?: string
  helpLink?: string
  handleState?: any
  [k: string]: unknown
}
