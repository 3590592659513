export const PluginNodeFieldTypes = {
  Input: 'input',
  Slider: 'slider',
  Select: 'select',
  Switch: 'switch',
  MultiSelect: 'multiSelect',
  Textarea: 'textarea',
  Json: 'json',
  Radio: 'radio',
  MultiInput: 'multiInput',
  ModelSelect: 'modelSelect',
} as const

export type PluginNodeFiledType =
  (typeof PluginNodeFieldTypes)[keyof typeof PluginNodeFieldTypes]
