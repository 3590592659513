import styled from '@emotion/styled'
import type { TextAreaProps } from 'antd/es/input'
import { TextArea, Button } from '@/components'

const StyledTextArea = styled(TextArea)`
  padding: 12px 16px;
  padding-bottom: 52px !important;
  resize: none !important;
  transition-duration: 0s !important;
`

type Props = TextAreaProps & {
  onTest?: () => void
  disabled?: boolean
  loading?: boolean
}

export function HitTestTextArea(props: Props) {
  const { onTest, loading, disabled, ...restProps } = props

  return (
    <div className='relative'>
      <StyledTextArea {...restProps} />
      <div className='absolute p-16px bottom-1 left-1 rounded-8px flex items-end justify-between w-100%'>
        <span className='text-12px scale-90 c-[rgba(141,141,153,0.6)]'>
          {(restProps.value as string).length} / {restProps.maxLength}
        </span>
        <Button
          type='primary'
          size='small'
          className='!text-14px'
          disabled={disabled}
          loading={loading}
          onClick={onTest}
        >
          测试
        </Button>
      </div>
    </div>
  )
}
