import {
  type FieldItem,
  FieldTypes,
  type FiledType,
} from '@/features/nodes/start/constant'
import { CodeEditor } from '@/features/editor/CodeEditor'
import { FormList } from '../../components/Forms/FormList'
import { CommonSelectEditor } from '../../components/CommonSelectEditor'
import { FlowSingleSelectEditor } from '../../components/FlowSingleSelectEditor'

export type ComponentMap = {
  [key in FiledType]?: (
    item: FieldItem & {
      value: any
      variableTipsContainer?: HTMLElement
      variables?: Array<{ label: string; type?: string }>
    },
  ) => React.ReactNode
}

export const componentMap: ComponentMap = {
  [FieldTypes.Input]: item => (
    <CodeEditor
      className='ace-gray w-100%'
      wrapEnabled={false}
      singleLine
      height='32px'
      value={item.value}
      width='100%'
      setOptions={{ maxLines: 1 }}
      variableTipsContainer={item.variableTipsContainer}
      variables={item.variables}
      placeholder={item.placeholder}
      disabled={item.disabled}
    />
  ),
  [FieldTypes.Select]: item => (
    <FlowSingleSelectEditor
      placeholder={item.placeholder || ''}
      variables={item.variables || []}
      variableTipsContainer={item.variableTipsContainer}
      tags={item.options!}
    />
  ),
  [FieldTypes.MultiSelect]: item => (
    <CommonSelectEditor
      placeholder={item.placeholder || ''}
      multiple
      variables={item.variables || []}
      variableTipsContainer={item.variableTipsContainer}
      tags={item.options!}
    />
  ),
  [FieldTypes.Textarea]: item => (
    <CodeEditor
      className='ace-gray w-100%'
      value={item.value}
      width='100%'
      setOptions={{ maxLines: 6, minLines: 3 }}
      variableTipsContainer={item.variableTipsContainer}
      variables={item.variables}
      placeholder={item.placeholder}
    />
  ),
  [FieldTypes.Json]: item => (
    <FormList
      type='JSON'
      variableTipsContainer={item.variableTipsContainer}
      variables={item.variables}
    />
  ),
  [FieldTypes.File]: item => (
    <CodeEditor
      className='ace-gray w-100%'
      wrapEnabled={false}
      singleLine
      height='32px'
      value={item.value}
      width='100%'
      setOptions={{ maxLines: 1 }}
      variableTipsContainer={item.variableTipsContainer}
      variables={item.variables}
      placeholder={item.placeholder}
    />
  ),
}
