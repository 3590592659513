import { useOSSClient } from '@/hooks/useOSSClient'

export function useReportFileUpload() {
  const { ossClient } = useOSSClient()

  const upload = async (name: string, file: File | Blob) => {
    if (ossClient) {
      ossClient.useBucket(import.meta.env.VITE_AI_DATASTORE_RESOURCE_BUCKET)
      const { res, url } = await ossClient.put(
        `/issueReport/${Date.now()}/${name}`,
        file,
      )
      if (res.status === 200) {
        return url
      }
      return null
    }
    return null
  }

  return {
    upload,
  }
}
