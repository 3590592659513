import type { InputRef } from 'antd'
import { Input } from 'antd'
import type { ChangeEvent, KeyboardEvent } from 'react'
import { memo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import type { TextAreaProps } from 'antd/es/input'
import classNames from 'classnames'
import type { RenderProps } from './type'

export const InputRender = memo(
  (props: RenderProps<string> & Omit<TextAreaProps, 'onChange'>) => {
    const {
      className,
      name,
      disabled,
      placeholder,
      value = '',
      onChange,
      supportFileTypes,

      ...rest
    } = props

    const inputRef = useRef<InputRef>(null)

    const handleChange = useMemoizedFn(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        const sureValue = (event.target.value || '').replaceAll('\n', ' ')
        onChange(sureValue, name)
      },
    )

    const handleKeyDown = useMemoizedFn((event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        event.preventDefault()
      }
    })

    return (
      <Input.TextArea
        className={classNames(className, 'bg-#fff! text-#17171D!')}
        {...rest}
        ref={inputRef}
        style={{
          padding: '6px 10px',
          minHeight: '36px',
          wordBreak: 'break-all',
          resize: 'none',
          ...rest.style,
        }}
        autoSize={{ minRows: 1, maxRows: 4 }}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
    )
  },
)
