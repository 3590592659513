import dayjs from 'dayjs'

export function dateFormate(date: string, format = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(date).format(format)
}

export function isWithinValidityPeriod(expiryDate: Date | string | number) {
  const now = dayjs()
  const expiry = dayjs(expiryDate)
  return now.isBefore(expiry)
}

/**
 * 禁用一年前的日期
 * @param current
 * @returns
 */
export function disabledOneYearDate(current: dayjs.Dayjs): boolean {
  const oneYearAgo = dayjs().subtract(1, 'year')
  return current && current < oneYearAgo.startOf('day')
}
