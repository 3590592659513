import styled from '@emotion/styled'
import { useSize } from 'ahooks'
import classNames from 'classnames'
import type { CSSProperties } from 'react'
import { memo, useMemo, useRef, useLayoutEffect } from 'react'

const ColorDiv = styled.div`
  top: 0;
  width: 51.7777%;
  padding: 6.5% 0;
  pointer-events: none;
  border-radius: 50%;
  filter: blur(var(--blur-size, 133px));
`

interface BottomColorProps {
  className?: string
  style?: CSSProperties
  padding?: number
  position: 'bottom' | 'top'
}

export const BlurBG = memo(
  ({ className, style, padding, position }: BottomColorProps) => {
    const container = useRef<HTMLDivElement>(null)
    const size = useSize(container)
    const sureClassName = useMemo(() => {
      if (className?.includes('absolute')) {
        return classNames('left-0', className)
      }

      return classNames('relative left-0', className)
    }, [className])

    useLayoutEffect(() => {
      if (!container.current) return
      const wrapper = container.current
      wrapper.style.setProperty('--blur-size', `${wrapper.offsetWidth * 0.1}px`)
      wrapper.style[position] = `-${wrapper.offsetWidth * 0.03}px`
    }, [size?.width])

    return (
      <div
        className={sureClassName}
        style={{
          left: padding ?? 0,
          right: padding ?? 0,
          pointerEvents: 'none',
          height: 0,
          ...style,
        }}
        ref={container}
      >
        <div style={{ padding: '6.5% 0' }}></div>
        <ColorDiv
          className='absolute'
          style={{
            left: '1.5%',
            background: '#68DEFF',
          }}
        ></ColorDiv>

        <ColorDiv
          className='absolute'
          style={{
            right: '1.5%',
            background: '#7368FF',
          }}
        ></ColorDiv>
      </div>
    )
  },
)
