import { Form, Radio } from 'antd'
import { Modal, FormItem, Input, Button } from '@/components'

interface CopyTableModalProps {
  sourceTableName?: string
  open?: boolean
  onCancel?: () => void
  loading?: boolean
  onFinish?: (table_name: string, excludeData: boolean) => void
}

export default function CopyTableModal(props: CopyTableModalProps) {
  const { open, onCancel, onFinish, loading, sourceTableName } = props
  const [form] = Form.useForm<{ table_name: string; excludeData: boolean }>()

  const onConfirm = () => {
    form.validateFields().then(res => {
      onFinish?.(res.table_name, res.excludeData)
    })
  }

  return (
    <Modal
      title='复制数据表'
      open={open}
      styles={{
        body: { padding: '24px 32px' },
      }}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        requiredMark={false}
        form={form}
        layout='vertical'
        initialValues={{
          excludeData: true,
          table_name: sourceTableName ? `${sourceTableName}_副本` : '',
        }}
      >
        <FormItem
          required
          label='数据表名称'
          name='table_name'
          rules={[{ required: true, message: '数据表名称不能为空' }]}
        >
          <Input placeholder='请输入数据表名称' showCount maxLength={20} />
        </FormItem>
        <FormItem label='复制范围' name='excludeData'>
          <Radio.Group>
            <Radio
              value={true}
              className='w-full flex items-center line-height-36px'
            >
              仅复制表格结构
            </Radio>
            <Radio
              value={false}
              className='w-full flex items-center line-height-36px'
            >
              复制表格结构和所有记录
            </Radio>
          </Radio.Group>
        </FormItem>
        <div className='flex items-center justify-end gap-12 mt-6 mt-10'>
          <Button onClick={onCancel}>取消</Button>
          <Button type='primary' onClick={onConfirm} loading={loading}>
            完成
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
