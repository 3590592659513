import classNames from 'classnames'
import type { CSSProperties } from 'react'
import { memo, useMemo, useState } from 'react'
import { isEqual } from 'lodash-es'
import type { EdgeProps, XYPosition } from 'reactflow'
import { BaseEdge, EdgeLabelRenderer } from 'reactflow'
import { NodeType } from '@/features/nodes/base'
import { useSelectNode } from '@/features/nodes/base/hooks'
import {
  useFlowDraftStore,
  useFlowInteractionStore,
  useMovableOptionStore,
} from '@/store'
import { useInsertEdgePath } from '../hooks/useInsertEdgePath'
import { useEdgeUnMount } from '../hooks/useEdgeUnMount'
import { useNodeDataInsert } from '@/hooks/useNodeDataInsert'
import { InsertButton } from '../components/InsertButton'
import { AddButton } from '../components/AddButton'
import { PasteButton } from '../components/PasteButton'
import { useTrack } from '@/features/track/Track'

export enum CustomEdgePathType {
  BRANCH = 'BRANCH',
  BRANCH_END = 'BRANCH_END',
  BRANCH_START = 'BRANCH_START',
}

export interface CustomEdgePathOptions {
  pathType: CustomEdgePathType
  points: XYPosition[]
}

export interface InsertEdgeData {
  customPathOptions?: CustomEdgePathOptions
  [k: string]: any
}

const InsertEdge = memo((props: EdgeProps<InsertEdgeData>) => {
  // console.log('edgeProps: ', props)
  const {
    id,
    source,
    target,
    sourceHandleId,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    sourcePosition,
    targetPosition,
    data = {},
  } = props
  const { doTrack } = useTrack()

  const { edgePath, labelX, labelY } = useInsertEdgePath({
    edgeInfo: {
      id,
      source,
      target,
      sourceHandleId,
    },
    layout: {
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    },
    customPathOptions: data.customPathOptions,
  })
  const { pasteStatus } = useFlowDraftStore(
    s => ({
      pasteStatus: s.pasteStatus,
    }),
    isEqual,
  )
  const insertNode = useFlowDraftStore(s => s.insertNode)
  const pasteNodes = useFlowDraftStore(s => s.pasteNodes)
  const { dangerStateEdgeIds, activeEdgeIds, isAddMode, insertActiveEdgeId } =
    useFlowInteractionStore()
  const { nextEdges } = useSelectNode()

  const isDanger = useMemo(
    () => dangerStateEdgeIds.includes(id),
    [id, dangerStateEdgeIds],
  )
  const isActive = useMemo(
    () => activeEdgeIds.includes(id),
    [activeEdgeIds, id],
  )

  const { insert } = useNodeDataInsert()
  const { hide } = useMovableOptionStore()

  const [insertPanelVisible, setInsertPanelVisible] = useState(false)

  const onInsertPanelShow = (show: boolean) => {
    setInsertPanelVisible(show)
  }

  const onAdd = (nodeData: { type: NodeType; data?: any }) => {
    insertNode({
      ...nodeData,
      target,
      source,
      sourceHandleId,
    })
    if (nodeData.type === NodeType.PLUGIN) {
      doTrack('flow_plugin_add', {
        pluginName: nodeData?.data?.pluginName,
        pluginDisplayName: nodeData?.data?.displayName,
        actionType: 'add',
      })
    }
  }

  const onPaste = () => {
    if (!pasteStatus) return
    // const sourceNode = useFlowDraftStore
    //   .getState()
    //   .nodes.find(item => item.id === source)
    pasteNodes({
      target,
      source,
      sourceHandleId: sourceHandleId || undefined,
      // sourceHandleId: sourceHandleId || sourceNode?.data?.relation?.branchId,
    })
  }

  const onInsert = () => {
    if (!isAddMode) return
    hide()
    insert({
      target,
      source,
      sourceHandleId,
    })
  }

  const showIcon = useMemo(() => {
    return (
      isActive ||
      insertPanelVisible ||
      (nextEdges?.id === props.id && pasteStatus)
    )
  }, [isActive, insertPanelVisible, nextEdges, pasteStatus])

  const displayStyle = useMemo(() => {
    const strokeWidth = isAddMode
      ? isActive && insertActiveEdgeId === id
        ? 2
        : style?.strokeWidth || 1
      : isActive
        ? 2
        : style?.strokeWidth || 1
    return {
      ...style,
      pointerEvents: 'visibleStroke' as CSSProperties['pointerEvents'],
      stroke: isActive ? '#7b61ff' : isDanger ? '#ff5219' : style?.stroke,
      strokeWidth,
    }
  }, [style, isActive, isDanger, isAddMode, insertActiveEdgeId, id])

  useEdgeUnMount({ source, target })

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={displayStyle} />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
          }}
          className={classNames(
            'absolute z-3000 pointer-events-auto cursor-pointer',
            {
              'w-0 h-0 overflow-hidden': !showIcon,
            },
          )}
        >
          {pasteStatus ? (
            <PasteButton onClick={onPaste} />
          ) : isAddMode ? (
            <InsertButton
              active={insertActiveEdgeId === id}
              onClick={onInsert}
            />
          ) : (
            <AddButton
              onPanelVisibleToggle={onInsertPanelShow}
              onInsert={onAdd}
            />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}, isEqual)

export default InsertEdge
