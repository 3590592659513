import type { Edge, Node } from 'reactflow'
import { v4 as uuidv4 } from 'uuid'
import { omit } from 'lodash-es'
import { EMPTY_NODE_HEIGHT, NODE_WIDTH, SPACE_BETWEEN_NODES } from '../layout'
import { getNodeMetaByType } from '@/features/nodes'
import { NodeType } from '@/features/nodes/base'
import type {
  ConditionItem,
  ConditionNodeData,
} from '@/features/nodes/condition'
import type { LoopNodeData } from '@/features/nodes/loop'
import type { IntentItem, IntentNodeData } from '@/features/nodes/intent'

export function generateEdgeFromNodes({
  target,
  source,
  sourceHandle,
  data,
  type,
}: {
  target: Node | string
  source: Node | string
  sourceHandle?: string | null
  data?: any
  type?: string
}): Edge {
  const sourceId = typeof source === 'string' ? source : source.id
  const targetId = typeof target === 'string' ? target : target.id
  const edge: Edge = {
    id: `${sourceHandle || sourceId}-${targetId}`,
    type: type || 'insert',
    source: sourceId,
    target: targetId,
    style: {
      stroke: '#bbbbc2',
      strokeWidth: 1,
    },
    interactionWidth: 80,
    data,
    ...(sourceHandle ? { sourceHandle } : {}),
  }
  return edge
}

export function generateNextNodeByPreviousPosition(
  position: { x: number; y: number } | null,
  node: Partial<Node>,
): Node {
  const newNode = {
    ...node,
    id: uuidv4(),
    position: position
      ? { x: position.x + NODE_WIDTH + SPACE_BETWEEN_NODES, y: position.y }
      : { x: 0, y: 0 },
    data: node.data || { value: 'normal node value' },
  }
  return newNode
}

export function initNodesAndEdgesForFlow() {
  const initialNodes = ['START', 'LLM', 'END'].reduce<Node[]>(
    (pre, type, index) => {
      const metaData = getNodeMetaByType(type as NodeType)
      const data = metaData
        ? {
            ...metaData.initialData,
            actionType: metaData.actionType,
          }
        : {}
      return pre.concat(
        generateNextNodeByPreviousPosition(
          index === 0 ? null : pre.slice(-1)[0].position,
          { type, data },
        ),
      )
    },
    [],
  )

  const initialEdges = []
  let previousNode = null
  let currentNode = null
  for (const node of initialNodes) {
    previousNode = currentNode
    currentNode = node
    if (previousNode && currentNode) {
      initialEdges.push(
        generateEdgeFromNodes({
          source: previousNode,
          target: currentNode,
        }),
      )
    }
  }

  return {
    nodes: initialNodes,
    edges: initialEdges,
  }
}

export function isConditionNode(node: Node): node is Node<ConditionNodeData> {
  return node.type === NodeType.CONDITION
}

export function isIntentNode(node: Node): node is Node<IntentNodeData> {
  return node.type === NodeType.INTENT
}

export function isLoopNode(node: Node): node is Node<LoopNodeData> {
  return node.type === NodeType.LOOP
}

export function isNestableNode(
  node: Node,
): node is Node<ConditionNodeData | LoopNodeData> {
  return [NodeType.CONDITION, NodeType.LOOP, NodeType.INTENT].includes(
    node.type as NodeType,
  )
}

/**
 *
 * @param node
 * @param nodes
 * @returns
 * @description 获取指定节点上可能存在的条件分支id,若存在证明该节点在条件分支上
 */
export function getBranchIdFromNode(
  node: Node,
  nodes: Node[],
): string | undefined {
  if (!node) return
  if (
    [
      NodeType.CONDITION_RESULT,
      NodeType.LOOP_RESULT,
      NodeType.INTENT_RESULT,
    ].includes(node.type as NodeType)
  ) {
    const parentNode = findParentNodeByResultNode(node, nodes) as Node
    return parentNode?.data?.relation?.branchId
  }
  return node.data.relation?.branchId
}

export function getConditionNodeIdFromNode(
  node: Node,
  nodes: Node[],
): string | undefined {
  if (!node) return
  if (
    [
      NodeType.CONDITION_RESULT,
      NodeType.LOOP_RESULT,
      NodeType.INTENT_RESULT,
    ].includes(node.type as NodeType)
  ) {
    const parentNode = findParentNodeByResultNode(node, nodes) as Node
    return parentNode?.data?.relation?.conditionNodeId
  }
  return node.data.relation?.conditionNodeId
}

export function getLoopNodeIdFromNode(node: Node, nodes: Node[]) {
  if (!node) return
  if (
    [
      NodeType.CONDITION_RESULT,
      NodeType.LOOP_RESULT,
      NodeType.INTENT_RESULT,
    ].includes(node.type as NodeType)
  ) {
    const parentNode = findParentNodeByResultNode(node, nodes) as Node
    return parentNode?.data?.relation?.loopNodeId
  }
  return node.data.relation?.loopNodeId
}

// 生成要插入节点的关系对象
export function generateNodeRelation(
  nodes: Node[],
  _edges: Edge[],
  sourceNode: Node,
  sourceHandleId?: string | null,
) {
  // loop节点循环体入口
  if (sourceHandleId && sourceHandleId.startsWith('loopStart')) {
    return {
      loopNodeId: sourceNode.id,
    }
  }
  // condition节点分支入口
  if (sourceHandleId && sourceNode.type === NodeType.CONDITION) {
    return {
      branchId: sourceHandleId,
      conditionNodeId: sourceNode.id,
    }
  }

  // condition节点分支入口
  if (sourceHandleId && sourceNode.type === NodeType.INTENT) {
    return {
      branchId: sourceHandleId,
      conditionNodeId: sourceNode.id,
    }
  }

  const loopNodeId = getLoopNodeIdFromNode(sourceNode, nodes)
  // loop节点循环体内
  if (loopNodeId) {
    return {
      loopNodeId,
    }
  }

  const branchId = getBranchIdFromNode(sourceNode, nodes)
  const conditionNodeId = getConditionNodeIdFromNode(sourceNode, nodes)
  // condition节点分支上
  if (branchId && conditionNodeId) {
    return {
      branchId,
      conditionNodeId,
    }
  }

  return null
}

// 拷贝嵌套节点时去除外部节点间关系
export function removeOuterRelation(relation?: Record<string, any>) {
  if (!relation) return {}
  return omit(relation, 'branchId', 'conditionNodeId', 'loopNodeId')
}

export function getRelateBydTargetId(current: Node, nodes: Node[]) {
  if (
    current?.type === NodeType.CONDITION ||
    current?.type === NodeType.INTENT
  ) {
    return nodes.find(n => n.id === current?.data?.relation.conditionResultId)!
  }
  if (current?.type === NodeType.LOOP) {
    return nodes.find(n => n.id === current?.data?.relation.loopResultId)!
  }
  return current
}

function findParentNodeByResultNode(node: Node, nodes: Node[]) {
  if (node.type === NodeType.CONDITION_RESULT) {
    const conditionId = node.data.relation?.conditionId as string
    const conditionNode = nodes.find(n => n.id === conditionId)
    return conditionNode
  }

  if (node.type === NodeType.INTENT_RESULT) {
    const intentId = node.data.relation?.conditionId as string
    const intentIdNode = nodes.find(n => n.id === intentId)
    return intentIdNode
  }

  if (node.type === NodeType.LOOP_RESULT) {
    const loopNodeId = node.data.relation.loopNodeId as string
    const loopNode = nodes.find(n => n.id === loopNodeId)
    return loopNode
  }
  return undefined
}

/**
 *
 * @param node 条件节点
 * @param nodes
 * @returns
 * @description 获取指定条件节点下所有在条件分支上的子节点集合(包含节点本身及对应的result节点)，如果该子节点为条件节点，递归获取其分支上的子节点集合
 */
export function getAllBranchNodesFromHasConditionNode(
  node: Node<ConditionNodeData | IntentNodeData>,
  nodes: Node[],
): Node[] {
  const { relation, conditions } = node.data
  const resultNode = nodes.find(n => n.id === relation?.conditionResultId)!
  const conditionIds = conditions.map(c => c.id)
  const childNodesOnBranch = nodes.filter(n =>
    conditionIds.includes(n.data?.relation?.branchId),
  )
  const conditionChildNodes = childNodesOnBranch.filter(
    n => n.type === NodeType.CONDITION || n.type === NodeType.INTENT,
  )
  const normalChildNodes = childNodesOnBranch.filter(
    n => n.type !== NodeType.CONDITION && n.type !== NodeType.INTENT,
  )
  let branchNodes: Node[] = []
  if (conditionChildNodes.length) {
    branchNodes = conditionChildNodes.reduce<Node[]>((pre, cur) => {
      return pre.concat(getAllBranchNodesFromHasConditionNode(cur, nodes))
    }, [])
  }
  return [node, resultNode, ...branchNodes, ...normalChildNodes]
}

export function getDomByNodeId(id: string) {
  return document.querySelector(`[data-id="${id}"]`)
}

export function getMaxNodeHeightFromSingleBranch(
  nodes: Node[],
  branchId: string,
) {
  const branchNodes = nodes.filter(n => n.data.relation?.branchId === branchId)
  if (branchNodes.length) {
    console.log('branchNodes', branchNodes)
    return Math.max.apply(
      null,
      branchNodes.map(n => {
        if (n.height) {
          return n.height
        }
        const dom = getDomByNodeId(n.id)
        console.log('dom', dom)
        if (dom) {
          return dom.clientHeight
        } else {
          return EMPTY_NODE_HEIGHT
        }
      }),
    )
  }
  return 0
}

export function getDistanceFromConditionTopToCurrentBranch(
  nodes: Node[],
  branchId: string,
  conditionId: string,
) {
  let distance = 0
  const conditionNode = nodes.find(
    n =>
      n.id === conditionId &&
      (n.type === NodeType.CONDITION || n.type === NodeType.INTENT),
  )
  if (conditionNode) {
    const conditions = conditionNode.data.conditions as
      | ConditionItem[]
      | IntentItem[]
    const currentBranchIndex = conditions.findIndex(
      item => item.id === branchId,
    )
    if (currentBranchIndex > 0) {
      const restConditions = conditions.slice(0, currentBranchIndex)
      distance = restConditions.reduce((acc, cur) => {
        const prevY = getMaxNodeHeightFromSingleBranch(nodes, cur.id!)
        console.log('prevY', prevY)
        return acc + (prevY === 0 ? 40 : prevY + 200)
      }, 0)
    }
  }
  return distance
}

export function getOriginBranchPosition(
  nodes: Node[],
  branchId: string,
  conditionId: string,
) {
  const position = { x: 0, y: 0 }
  const conditionNode = nodes.find(
    n =>
      n.id === conditionId &&
      (n.type === NodeType.CONDITION || n.type === NodeType.INTENT),
  )
  if (conditionNode) {
    const conditions = conditionNode.data.conditions as ConditionItem[]
    const currentBranchIndex = conditions.findIndex(
      item => item.id === branchId,
    )
    position.x = conditionNode.position.x
    position.y = 40 + 16 + 62 * currentBranchIndex
  }
  return position
}
