import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import type { Schema } from '@/apis/database/types'
import { getSchemas } from '@/apis/database/api'
import { IconFont } from '@/components'

interface SchemaCollapseProps {
  identifier: string
  title: string
  classNames?: {
    root?: string
    refreshButton?: string
    closeButton?: string
  }
  // sb
  showClose?: boolean
  onClose?: VoidFunction
}

function InternalSchemaCollapse({
  classNames,
  identifier,
  title,
  showClose,
  onClose,
}: SchemaCollapseProps) {
  const [schemas, setSchemas] = useState<Schema[]>()

  const loadSchemas = async (identifier: string) => {
    const response = await getSchemas(identifier)
    setSchemas(response.table_list)
  }

  useEffect(() => {
    loadSchemas(identifier)
  }, [identifier])

  return (
    <>
      <div className={cn('flex items-center shrink-0', classNames?.root)}>
        <span className='text-14px/20px text-font font-semibold mr-auto'>
          {title}
        </span>
        <span
          className={cn(
            'p-4 rounded-6px hover:bg-#f6f6f9 text-bg_3 text-op-60 text-14px',
            classNames?.refreshButton,
          )}
          onClick={event => {
            event.stopPropagation()
            loadSchemas(identifier)
          }}
        >
          <IconFont name='tongxinshengcheng' />
        </span>
        {showClose && (
          <span
            className={cn(
              'p-4 rounded-6px hover:bg-#f6f6f9 text-bg_3 text-op-60 text-14px',
              classNames?.closeButton,
            )}
            onClick={event => {
              event.stopPropagation()
              onClose?.()
            }}
          >
            <IconFont name='guanbi' />
          </span>
        )}
      </div>
      <div className='flex-1 overflow-x-hidden overflow-y-auto'>
        {schemas?.map(el => (
          <details key={el.id} className='mt-12 group/schema'>
            <summary className='list-none'>
              <span className='inline-flex items-center text-font hover:!text-op-60'>
                <span className='inline-flex justify-center items-center w-16px h-16px mr-4 group-[open]/schema:rotate-90'>
                  <IconFont className='text-10px' name='yunxing' />
                </span>
                <span className='text-12px/20px font-semibold'>
                  {el.title}
                  {!!el.columns?.length && (
                    <span className='ml-4 font-normal text-bg_3 leading-inherit'>
                      ({el.columns.length})
                    </span>
                  )}
                </span>
              </span>
            </summary>
            {!!el.columns?.length && (
              <ul className='text-12px text-font_1 mt-2 ml-20px'>
                {el.columns.map(el => (
                  <li key={el.id} className='mt-8 flex w-full overflow-hidden'>
                    <span className='flex-1'>{el.column_name}</span>
                    <span className='flex-1'>{el.column_type}</span>
                  </li>
                ))}
              </ul>
            )}
          </details>
        ))}
      </div>
    </>
  )
}

export const SchemaCollapse = React.memo(InternalSchemaCollapse)
