import { useAuth } from '@bty/react-auth'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoleCode } from '@/apis/authority'
import type { VersionInfo } from '@/apis/version'
import { Button, IconFont } from '@/components'
import {
  POINTS_CONSUME_DESC,
  POINTS_CONSUME_NAME,
} from '@/constants/commercialization'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { VersionStatus, useLimitedModal } from '@/features/pay'
import { useWorkspaceStore } from '@/store'
import { useContactCustomerServiceModal } from '@/features/pay/ContactCustomerServiceModal'
import { ComboProcessCard } from './ComboProcessCard'

export default ({ versionInfo }: { versionInfo: VersionInfo }) => {
  const navigate = useNavigate()
  const { state } = useAuth()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const [modal] = useLimitedModal()
  const [contactCustomerServiceModal] = useContactCustomerServiceModal()
  const isExpire = useMemo(() => {
    return versionInfo?.status === VersionStatus.LoseEffect
  }, [versionInfo])
  const comboProcessCardStyle: React.CSSProperties = {
    // flex: '0 0 calc(33.33% - 16px)',
    opacity: isExpire ? '0.4' : '1',
    cursor: isExpire ? 'not-allowed' : 'default',
  }
  const developCardStyle: React.CSSProperties = {
    // flex: '0 0 calc(33.33% - 16px)',
    opacity: isExpire ? '0.4' : '1',
    cursor: isExpire ? 'not-allowed' : 'pointer',
  }
  const {
    currentPointsNum,
    totalPointsNum,
    totalAppNum,
    totalMemoryNum,
    totalUserNum,
  } = useMemo(() => {
    return {
      currentPointsNum:
        versionInfo.dosage_package_points_consume_num +
        versionInfo.points_consume_num,
      totalPointsNum:
        versionInfo.dosage_package_points_count_total +
        versionInfo.points_count_limit,
      totalAppNum:
        versionInfo.dosage_package_app_count_total +
        versionInfo.app_count_limit,
      totalMemoryNum:
        versionInfo.dosage_package_memory_total +
        versionInfo.memory_count_limit,
      totalUserNum:
        versionInfo.dosage_package_user_count_total +
        versionInfo.total_user_count_limit,
    }
  }, [versionInfo])

  return (
    <div className='border-rd-12px bg-#fff p-24px pt-20px'>
      <div className='flex mb-20px flex-items-center justify-between'>
        <div className='flex flex-items-center gap-12px'>
          <div className='c-font text-20px font-800'>
            {versionInfo.product_name}
          </div>
          <PrivateDeployGuard>
            {isExpire ? (
              <span
                className='border-rd-4px px-8px py-4px c-error'
                style={{ background: 'rgba(255, 82, 25, 0.12)' }}
              >
                已过期
              </span>
            ) : (
              versionInfo.lose_effect_time && (
                <div
                  className='border-rd-4px text-12px font-500 px-8px py-4px'
                  style={{
                    background: 'rgba(98, 105, 153, 0.12)',
                    color: 'rgba(23, 23, 29, 0.8)',
                  }}
                >
                  {dayjs(versionInfo.lose_effect_time).format('YYYY-MM-DD')}
                  到期
                  {versionInfo?.days_remaining &&
                    versionInfo.days_remaining <= 30 && (
                      <>
                        ，仅剩
                        <span className='color-#7B67EE'>
                          {versionInfo.days_remaining}
                        </span>
                        天
                      </>
                    )}
                </div>
              )
            )}
          </PrivateDeployGuard>
        </div>
        <PrivateDeployGuard>
          {state.role === RoleCode.ADMINISTRATOR && (
            <div className='flex gap-12px'>
              <Button onClick={() => contactCustomerServiceModal.open()}>
                联系客服
              </Button>
              <Button
                onClick={() => {
                  modal.open({ role: state.role })
                }}
                type='primary'
              >
                版本权益
              </Button>
            </div>
          )}
        </PrivateDeployGuard>
      </div>
      <div className='flex gap-24px w-100%'>
        <PrivateDeployGuard>
          <ComboProcessCard
            tabComp={null}
            currentNum={currentPointsNum}
            totalNum={totalPointsNum}
            name={
              <div className='flex items-end gap-4px items-center'>
                {POINTS_CONSUME_NAME}
                <Tooltip title={POINTS_CONSUME_DESC}>
                  <IconFont
                    name='jieshishuimeng'
                    className='text-12px cursor-pointer'
                    style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                  />
                </Tooltip>
              </div>
            }
            unit='分'
            processLeftColor='#7B61FF'
            processRightColor='#678AFF'
            icon='yeahjifen'
            iconColor='#7B61FF'
            int={<div className='text-12px c-font_1 pt-25px pb-2px'></div>}
            buttonComp={
              versionInfo.points_consume_num +
                (versionInfo.dosage_package_points_consume_num || 0) >=
                versionInfo.points_count_limit +
                  (versionInfo.dosage_package_points_count_total || 0) && (
                <span className='pb-6px color-#FF5219 inline-flex items-center'>
                  <IconFont
                    name='yunxingyibai'
                    className='font-size-12px mr-4px'
                  />
                  积分已耗尽
                </span>
              )
            }
            style={comboProcessCardStyle}
          />
        </PrivateDeployGuard>
        <ComboProcessCard
          currentNum={versionInfo.app_consume_num}
          totalNum={totalAppNum}
          name={
            <div className='flex items-end gap-4px items-center'>
              应用数量
              <Tooltip title='Agent和工作流的总应用数'>
                <IconFont
                  name='jieshishuimeng'
                  className='text-12px cursor-pointer'
                  style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                />
              </Tooltip>
            </div>
          }
          unit='个'
          processLeftColor='#ff63a9'
          processRightColor='#ff8eec'
          icon='ai'
          iconColor='#ff63a9'
          style={comboProcessCardStyle}
        />
        <ComboProcessCard
          currentNum={versionInfo.memory_consume_num}
          totalNum={totalMemoryNum}
          name='知识库容量'
          unit='MB'
          processLeftColor='#38A6FF'
          processRightColor='#6be7fa'
          icon='shujujiyongliang'
          iconColor='#38A6FF'
          style={comboProcessCardStyle}
        />
        <ComboProcessCard
          currentNum={versionInfo.exist_user_count}
          totalNum={totalUserNum}
          name='用户数量'
          unit='人'
          processLeftColor='#ffa200'
          processRightColor='#ffd549'
          icon='kaifazhe'
          iconColor='#ffa200'
          style={developCardStyle}
          buttonComp={
            <div className='pb-6px c-#7B61FF font-500 text-14px/14px hover:opacity-60'>
              管理
            </div>
          }
          onClick={() => {
            navigate(`/workspace/${currentWorkspaceId}/settings/users`)
          }}
        />
      </div>
    </div>
  )
}
