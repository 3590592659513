import { sample } from 'lodash-es'
import { PromptStructType } from '@/apis/prompt.ts'

export type PlaceholderMap = Record<
  PromptStructType,
  { placeholder: string; generateForm: Record<string, string> }
> & {
  [PromptStructType.ROLE]: {
    generateForm: {
      role: string
      scene: string
      task: string
    }
  }
  [PromptStructType.BACKGROUND]: {
    generateForm: {
      businessDescription: string
      customerProfile: string
    }
  }
  [PromptStructType.TASK]: {
    generateForm: {
      taggingDesc: string
      intentDesc: string
      writeTarget: string
      customMethod: string
    }
  }
  [PromptStructType.SKILL]: {
    generateForm: {
      skillInfo: string
    }
  }
  [PromptStructType.CUSTOM]: {
    generateForm: {
      customTopicDesc: string
    }
  }
  [PromptStructType.OUTPUT]: {
    generateForm: {
      outputExample: string
    }
  }
  [PromptStructType.REQUIREMENTS]: {
    generateForm: {
      customContentRequirements: string
    }
  }
}

const placeholdersList: PlaceholderMap[] = [
  {
    [PromptStructType.ROLE]: {
      placeholder: '你是一名资深的AI产品售后客服专员。',
      generateForm: {
        role: '客服专员',
        scene: '金融证券',
        task: '查询知识库，解决用户疑问',
      },
    },
    [PromptStructType.BACKGROUND]: {
      placeholder: '你服务于一家AI公司，主要面向企业开发AI应用。',
      generateForm: {
        businessDescription: '负责解决客户关于AI产品的咨询问题',
        customerProfile: '主要面向企业客户',
      },
    },
    [PromptStructType.SKILL]: {
      placeholder: '-查询知识库：当用户咨询问题时，必须使用此技能。',
      generateForm: {
        skillInfo: '**查询知识库**: 当用户咨询问题时，可以使用此技能',
      },
    },
    [PromptStructType.TASK]: {
      placeholder: '从知识库中寻找最佳答案，解答客户问题。',
      generateForm: {
        customHandleNoContent: '直接告诉我：“没有查询到内容”；',
        customResolveUncertainInput: '直接输出全部内容；',
        taggingDesc:
          '输入标签名称及说明，如：**维修**：仅描述具体某品类维修，例如：“冰箱维修”',
        intentDesc:
          '输入意图名称及说明，如：**寒暄**：当用户与你闲聊或打招呼时，属于此意图',
        customMethod:
          '填写方法名称、说明和举例，比如：\n' +
          '数字情感法：用区别于形容目标对象的数字来强化更为强烈的情感色彩。\n' +
          '1 . 写失眠：在凌晨的00点01分，我数到了第934只羊。',
        writeTarget:
          '分析产品属性、用户特点，编写调动用户情绪的广告文案，让用户感受到"你懂我!"',
      },
    },
    [PromptStructType.REQUIREMENTS]: {
      placeholder: '一步一步思考；认真检查你的回答是否可以解决用户的问题；',
      generateForm: {
        customContentRequirements: '用中文回答',
      },
    },
    [PromptStructType.OUTPUT]: {
      placeholder: '使用段落清晰、易读的格式',
      generateForm: {
        outputExample:
          '按照下面格式输出：\n' +
          '## 一级标题\n' +
          '具体内容\n' +
          '### 二级标题\n' +
          '具体内容',
      },
    },
    [PromptStructType.CUSTOM]: {
      placeholder: '请输入自定义主题内容',
      generateForm: {
        customTopicDesc: '生成一些【自定义主题】的内容',
      },
    },
  },
]

export function getRandomPlaceholderMap() {
  return sample<PlaceholderMap>(placeholdersList)!
}
