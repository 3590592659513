import { useBoolean } from 'ahooks'
import { useCallback } from 'react'

export function useVersionVisible() {
  const [isShowVersion, { set: setShowVersion }] = useBoolean(false)

  const onOpenVersion = useCallback(() => setShowVersion(true), [])
  const onCloseVersion = useCallback(() => setShowVersion(false), [])

  return {
    isShowVersion,
    onOpenVersion,
    onCloseVersion,
  }
}
