import type { FC } from 'react'
import { useRef, useState, useCallback, useEffect } from 'react'
import { Tag, message } from 'antd'
import classNames from 'classnames'
import { uniq } from 'lodash-es'
import { TagDropdownSelect } from '@/features/datastore/components/TagDropdownSelect.tsx'
import { IconFont } from '@/components'
import type { CodeEditorInstance } from '@/features/editor'
import { CodeEditor } from '@/features/editor'
import { VariableRegex } from '@/constants/common.ts'

interface MemoryTagSelectProps {
  value?: string[]
  disabled?: boolean
  onChange?: (value?: unknown[]) => void
  tags: Array<{ tag: string; file_num: number }>
  error?: string[]
  variables: any[]
  variableTipsContainer?: HTMLElement
  allEnable?: boolean
}

function TagVariableEditor(props: {
  variables: any[]
  disabled?: boolean
  variableTipsContainer?: HTMLElement
  onChange?: (value: string) => void
  onDelete?: () => void
  placeholder?: string
}) {
  const [input, setInput] = useState('')
  const codeEditorRef = useRef<CodeEditorInstance | null>(null)

  const onConfirm = useCallback(() => {
    if (input.trim()) {
      props.onChange?.(input)
      setInput('')
    }
  }, [props.onChange, input])

  useEffect(() => {
    const codeEditor = codeEditorRef.current?.editor
    if (codeEditor) {
      codeEditor.commands.removeCommand('onEnter')
      codeEditor.commands.addCommand({
        name: 'onEnter',
        bindKey: { win: 'Enter', mac: 'Enter' },
        exec: onConfirm,
      })
    }
  }, [onConfirm])

  const onDeleteWord = useCallback(() => {
    if (!input.trim()) {
      props.onDelete?.()
    } else {
      setInput(input.slice(0, input.length - 1))
    }
  }, [props.onChange, input])

  useEffect(() => {
    const codeEditor = codeEditorRef.current?.editor
    if (codeEditor) {
      codeEditor.commands.removeCommand('onDeleteWord')
      codeEditor.commands.addCommand({
        name: 'onDeleteWord',
        bindKey: { win: 'Backspace', mac: 'Backspace' },
        exec: onDeleteWord,
      })
    }
  }, [onDeleteWord])

  return (
    <div className='min-w-60px flex-1 ml--8px'>
      <CodeEditor
        {...props}
        ref={codeEditorRef}
        disabled={props.disabled}
        className='w-full border-none shrink-0 !bg-transparent'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={input}
        onChange={v => {
          setInput(v)
        }}
        onBlur={onConfirm}
        singleLine
        wrapEnabled={false}
        variables={props.variables}
        placeholder={props.placeholder}
        setOptions={{
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
      />
    </div>
  )
}

export const MemoryTagSelect: FC<MemoryTagSelectProps> = props => {
  const { tags, disabled, error, ...remaining } = props
  const tagsOptions = tags.map(item => ({
    label: item.tag,
    value: item.tag,
    count: item.file_num,
  }))
  const boxRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)

  const onVariableEditorChange = useCallback(
    (v: string) => {
      // 如果是变量，或者是已有的标签，直接添加
      if (
        VariableRegex.test(v) ||
        tags.findIndex(item => item.tag === v) !== -1
      ) {
        // 如果是变量，直接添加
        const newValue = uniq([...(props.value ?? []), v])
        props.onChange?.(newValue)
      } else {
        message.warning('错误标签，非当前知识库标签')
      }
      setOpen(false)
    },
    [tags, props.value, props.onChange],
  )

  return (
    <div ref={boxRef}>
      <TagDropdownSelect
        open={open}
        onOpenChange={setOpen}
        getPopupContainer={() => boxRef.current!}
        options={tagsOptions}
        disabled={disabled}
        {...remaining}
        triggerRender={(_, ref) => {
          return (
            <>
              <div
                className={classNames(
                  'relative mb-8 px-8 py-3 b-1 rounded-6px bg-bg_3 bg-op-6 flex-center-between gap-8',
                  {
                    '!b-error': !!error?.length,
                    'b-primary': open,
                    'hover:b-primary b-bg_3 b-op-0': !open,
                  },
                )}
                ref={ref}
              >
                <div className='flex gap-8 flex-wrap w-full'>
                  {remaining.value?.map((tag, index) => {
                    const isVariable = VariableRegex.test(tag)
                    return (
                      <Tag
                        key={tag + index}
                        closable
                        closeIcon={
                          <IconFont
                            name='guanbi'
                            className={classNames(
                              'c-font_1 text-12px c-op-60 relative top-1px right--2px hover:c-#17171D',
                              {
                                'c-tips c-op-80': isVariable,
                              },
                            )}
                          />
                        }
                        className={classNames(
                          'rounded-4px !m-0 flex items-center',
                          {
                            'bg-tips bg-op-12 c-tips b-tips': isVariable,
                          },
                        )}
                        onClose={() =>
                          remaining.onChange?.(
                            remaining.value?.filter(i => i !== tag),
                          )
                        }
                      >
                        {tag}
                      </Tag>
                    )
                  })}
                  <TagVariableEditor
                    variables={props.variables}
                    disabled={disabled}
                    onDelete={() =>
                      props.onChange?.(
                        remaining.value?.splice(0, remaining.value.length - 1),
                      )
                    }
                    variableTipsContainer={props.variableTipsContainer}
                    onChange={onVariableEditorChange}
                    placeholder={props.value?.length ? '' : '选择或者输入标签'}
                  />
                </div>
                <div className={classNames('w-16px h-16px flex-center')}>
                  <IconFont name='arrow' className='text-7px c-font_1' />
                </div>
              </div>
              {!!error?.length && (
                <div className='mb-12 text-12px c-error'>{error[0]}</div>
              )}
            </>
          )
        }}
      />
    </div>
  )
}
