import type { MessageItem } from '@bty/chat-ui'
import { uniqueId } from 'lodash-es'
import { LEFT_COLOR, RIGHT_COLOR } from '../const'

export function generateBotMessage(message: Partial<MessageItem>): MessageItem {
  return {
    id: uniqueId(),
    messageType: 'markdown',
    type: 'message',
    position: 'left',
    backgroundColor: LEFT_COLOR,
    ...message,
  }
}

export function generateSendMessage(
  message: Partial<MessageItem>,
): MessageItem {
  return {
    id: uniqueId(),
    messageType: 'text',
    type: 'message',
    position: 'right',
    backgroundColor: RIGHT_COLOR,
    ...message,
  }
}

export function generateBotLoadingMessage(
  message: Partial<MessageItem>,
): MessageItem {
  return {
    id: uniqueId(),
    messageType: 'fetching',
    type: 'message',
    position: 'left',
    backgroundColor: LEFT_COLOR,
    ...message,
  }
}

export function generateSeparator(content: string): MessageItem {
  return {
    id: uniqueId(),
    type: 'separator',
    messageType: 'text',
    content,
  }
}
