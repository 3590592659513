import cn from 'classnames'
import defaultAvatarImg from '@/assets/user/default-avatar.png'

interface AvatarProps {
  className?: string
  name?: string
  src?: string
  size?: 'small' | 'medium' | 'large'
}

export function Avatar({ className, name, src, size }: AvatarProps) {
  const innerSize = size || 'medium'

  const sizeClassNames = {
    'w-80px h-80px': innerSize === 'large',
    'w-40px h-40px': innerSize === 'medium',
    'w-24px h-24px': innerSize === 'small',
  }

  const innerSrc = src && src.startsWith('http') ? src : undefined

  if (innerSrc) {
    return (
      <img
        className={cn('rounded-full object-cover', sizeClassNames, className)}
        src={innerSrc}
      />
    )
  }

  if (name) {
    return (
      <div
        className={cn(
          'text-center bg-#EAF3FD rounded-full',
          sizeClassNames,
          {
            'text-34px/80px': innerSize === 'large',
            'text-24px/40px': innerSize === 'medium',
            'text-12px/24px': innerSize === 'small',
          },
          className,
        )}
      >
        {name?.[0]}
      </div>
    )
  }

  return (
    <img
      className={cn('rounded-full', sizeClassNames, className)}
      src={defaultAvatarImg}
    />
  )
}
