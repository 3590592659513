import type { SliderSingleProps, InputNumberProps } from 'antd'
import { Slider, InputNumber, Tooltip } from 'antd'
import styled from '@emotion/styled'
import classNames from 'classnames'
import type { ReactNode } from 'react'

const StyledInputNumber = styled(InputNumber)`
  border-color: transparent;
  width: 42px;
  height: 26px;
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 14px;

  &.ant-input-number-focused {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  & .ant-input-number-input {
    text-align: center;
    height: 26px;
    line-height: 26px;
    padding: 0;
  }
`

type SliderProps = SliderSingleProps &
  InputNumberProps & {
    noStyle?: boolean
    tooltip?: string
    prefixIcon?: ReactNode
  }

export function SliderSingle({
  noStyle,
  tooltip,
  prefixIcon,
  ...props
}: SliderProps) {
  const content = (
    <div
      className={classNames(
        'w-full flex items-center',
        {
          'bg-bg_3 bg-opacity-6 rounded-6px h-32px pl-12px pr-4px border-transparent border border-solid transition-all-200':
            noStyle !== true,
          'hover:border-primary': !props.disabled,
        },
        props.className,
      )}
    >
      {prefixIcon}
      <Slider {...props} className='flex-1 mx-0 nodrag nopan' />
      <StyledInputNumber
        {...props}
        className='shrink-0 ml-12px flex items-center justify-center text-12px!'
        changeOnWheel={false}
        controls={false}
        onChange={v => props.onChange?.(v as number)}
      />
    </div>
  )

  return tooltip ? (
    <Tooltip trigger={['hover']} title={tooltip}>
      {content}
    </Tooltip>
  ) : (
    content
  )
}
