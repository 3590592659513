import type { AppStatusEnum } from '@/pages/workspace/settings/util'
import type { RoleCode } from './authority'
import { request } from './lib'

export interface workspaceOverview {
  appDimensionBaseInfo: {
    appName: string
    created_at: string
    developer_count: number
    run_count: number
    icon: string
    color: string
    updated_at: string
    last_run_time: string
    use_users_count: number
    version_status: AppStatusEnum
    app_id: string
    application_type: 'AI' | 'AGENT'
    consume_points: number
  }[]
  workspaceDimensionBaseInfo: {
    admin_count: number
    developer_count: number
    draft_app_count: number
    published_app_count: number
    use_users_count: number
  }
}

interface appRunData {}

// 工作空间app维度数据
export function getWorkspaceOverview(body: {
  workspace_id: string
  version_status?: string | null
  start_time?: string
  end_time?: string
}) {
  return request.post<workspaceOverview>('/v1/workspace/overview', body)
}

// 校验工作空间是否可以邀请
export function workspaceCheckMember(body: {
  sourceRoleCode?: RoleCode
  targetRoleCode: RoleCode
  phone?: string[]
}) {
  return request.post<any>('/v1/workspace/check_member', body)
}

// 根据workspaceId查询应用运行数据
export function getAppRunDataByWorkspaceId(workspace_id: string) {
  return request.get<appRunData>(`/v1/product_service/data/${workspace_id}`)
}

// 根据workspaceId查询应用运行数据
export function updateMemberInfo(body: {
  workspace_id: string
  target_role_code: string
  source_role_code?: string
  deleted?: boolean
}) {
  return request.post<any>('/v1/workspace/update_member_info', {
    ...body,
    deleted: !!body.deleted,
  })
}
