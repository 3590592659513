import { useRequest } from 'ahooks'
import { message } from 'antd'
import download from 'downloadjs'

// const XLS_MIMETYPE = 'application/vnd.ms-excel'
const XLSX_FILETYPE = 'xlsx'
const XLSX_MIMETYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export function useLogsExport<T = any>({
  exportApi,
  fileMimeType = XLSX_MIMETYPE,
}: {
  exportApi: (data: T) => Promise<string | null>
  fileMimeType?: string
}) {
  const { runAsync: runExportRequest, loading } = useRequest(exportApi, {
    manual: true,
  })

  const exportLogs = async (data: T, fileName: string) => {
    try {
      const fileUrl = await runExportRequest(data)
      if (fileUrl) {
        const res = await fetch(fileUrl)
        const contentType = res.headers.get('Content-Type')
        if (contentType !== XLSX_MIMETYPE) {
          message.error('导出文件格式错误')
          return
        }
        const blob = await res.blob()
        download(blob, `${fileName}.${XLSX_FILETYPE}`, fileMimeType)
      } else {
        message.error('日志为空')
      }
    } catch (error) {
      console.error('日志文件导出失败', error)
      message.error('日志文件导出失败')
    }
  }

  return { exportLogs, loading }
}
