export function isMobileDevice() {
  const userAgent = navigator.userAgent

  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent,
  )
}

export function isMacOS() {
  return /macintosh|mac os x/i.test(navigator.userAgent.toLowerCase())
}
