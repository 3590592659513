export const SPACE_BETWEEN_NODES = 88 + 18 * 2
export const NODE_WIDTH = 420
export const EMPTY_NODE_HEIGHT = 344
export const CONDITION_START_EDGE_GAP = 40

// export const SPACE_BETWEEN_NODES = 88 + 18 * 2
// export const NODE_WIDTH = 420
// export const EMPTY_NODE_HEIGHT = 820
// // export const CONDITION_START_EDGE_GAP = 110
// export const CONDITION_START_EDGE_GAP = 110
