import type { OrderStatusTagType } from '../util'

export default function ({
  status,
  color,
  background,
}: Partial<OrderStatusTagType>) {
  return status ? (
    <span
      style={{ color, background }}
      className='px-8px py-4px font-500 text-12px border-rd-4px'
    >
      {status}
    </span>
  ) : (
    <>-</>
  )
}
