import { Popover } from 'antd'
import { useRef } from 'react'
import { useHover } from 'ahooks'
import type { IPluginItem } from '@/apis/flow'
import { IconFont, usePageModal } from '@/components'
import { useMovableOptionStore } from '@/store'

interface PluginItemCardProps {
  id: string
  iconURL: string
  // iconColor: string
  name: string
  description: string
  fields: IPluginItem['form_config'][number][]
  selected: boolean
  onClick: (id: string) => void
}

export function PluginItemCard(props: PluginItemCardProps) {
  const { id, iconURL, name, description, fields, selected, onClick } = props
  const pageModal = usePageModal()
  const { init } = useMovableOptionStore()
  const containerRef = useRef(null)
  const isHover = useHover(containerRef)

  return (
    <div
      ref={containerRef}
      className='mb-12px p-12px b-rd-8px b-1 b-#e1e1e5 b-op-60 hover:bg-#626999 hover:bg-op-8 cursor-pointer'
      onClick={e => {
        const { clientX, clientY } = e
        init({
          x: clientX,
          y: clientY,
        })
        onClick(id)
      }}
    >
      <div className='flex items-center mb-4px'>
        <img className='w-24px h-24px mr-8px' src={iconURL} alt='' />
        <div className='flex-1 font-500 text-#17171d flex items-center'>
          {name}
          <span
            onClick={e => {
              pageModal.show({
                url: `/plugin/info/${id}?pageType=infoOnly`,
              })
              e.stopPropagation()
            }}
            className={`${
              isHover ? 'block' : 'hidden'
            } text-14px ml-8 cursor-pointer c-#626999 c-op-60`}
          >
            <IconFont name='super-link' />
          </span>
        </div>
        <div className='flex-center w-24px h-24px b-rd-4px'>
          <IconFont
            name={selected ? 'jiediansixuan' : 'add'}
            className='text-16px text-#7861ff'
          />
        </div>
      </div>
      <div className='mb-12px pl-32px line-height-18px text-12px text-#8D8D99'>
        {description}
      </div>
      <Popover
        arrow={false}
        placement='right'
        overlayInnerStyle={{ padding: 0, minWidth: 210 }}
        content={
          <div className='py-20px px-16px'>
            {fields.map(
              ({
                label,
                variableName,
                variableType,
                required,
                placeholder,
              }) => (
                <div key={variableName} className='mb-16px last:mb-0'>
                  <div className='mb-8px flex items-center'>
                    <span className='mr-8px text-12px font-500 text-#17171d whitespace-nowrap'>
                      {label}
                    </span>
                    <span className='mr-8px text-12px text-#8D8D99'>
                      {variableType}
                    </span>
                    {required && (
                      <span className='flex-center w-28px h-16px rounded-4px b-1 b-#ff5219 b-op-24'>
                        <div className='whitespace-nowrap text-12px text-#ff5219 text-op-80 scale-83.33'>
                          必填
                        </div>
                      </span>
                    )}
                  </div>
                  <div className='line-height-18px text-12px text-#8d8d99'>
                    {placeholder}
                  </div>
                </div>
              ),
            )}
          </div>
        }
      >
        <div className='ml-32px flex flex-wrap items-center'>
          {fields.map(item => (
            <div
              key={item.variableName}
              className='mr-8px mb-4px flex-center h-20px px-6px b-rd-4px bg-#626999 bg-op-8 text-#8d8d99 text-12px whitespace-nowrap'
            >
              {item.label}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  )
}
