import styled from '@emotion/styled'

const defaultGap = 24

export const ResponsiveCardGrid = styled.div<{ gap?: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.gap ?? defaultGap}px;
`

export const ResponsiveCard = styled.div<{ gap?: number }>`
  /* 屏幕宽度大于 1920px 显示5个 */
  @media (min-width: 1921px) {
    width: ${props => {
      const gap = props.gap ?? defaultGap
      return `calc((100% - ${4 * gap}px) / 5);`
    }};
  }

  /* 屏幕宽度在 1440px 到 1750px 之间 显示4个 */
  @media (min-width: 1440px) and (max-width: 1920px) {
    width: ${props => {
      const gap = props.gap ?? defaultGap
      return `calc((100% - ${3 * gap}px) / 4);`
    }};
  }

  /* 屏幕宽度在 1080px 到 1440px 之间 显示3个 */
  @media (min-width: 1080px) and (max-width: 1439px) {
    width: ${props => {
      const gap = props.gap ?? defaultGap
      return `calc((100% - ${2 * gap}px) / 3);`
    }};
  }

  /* 屏幕宽度在 576px 到 1080px 之间，显示2个 */
  @media (min-width: 720px) and (max-width: 1079px) {
    width: ${props => {
      const gap = props.gap ?? defaultGap
      return `calc((100% - ${gap}px) / 2);`
    }};
  }

  /* 屏幕宽度小于 576px 显示1个 */
  @media (max-width: 719px) {
    width: 100%;
  }
`
