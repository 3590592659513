import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { Node } from 'reactflow'
import { useReactFlow } from 'reactflow'
import { useSessionStorageState, useTimeout } from 'ahooks'
import { useFlowDraftStore } from '@/store'
import { NodeType } from '@/features/nodes/base'
import { API_CODE_CODE } from '@/constants/request'
import { FLOW_ERROR_LOG } from '@/features/logger/constants'

export function useAutoLocateNode() {
  const [flowErrorLog, setFlowErrorLog] =
    useSessionStorageState<any>(FLOW_ERROR_LOG)
  const reactFlowInstance = useReactFlow()
  const { viewPortNodeId, isDraftInitialized, setNodeErrorStatusMap } =
    useFlowDraftStore()
  const searchParams = useSearchParams()

  const setViewPort = (targetViewPortNode: Node) => {
    reactFlowInstance.setViewport({
      x:
        document.body.clientWidth / 2 -
        (targetViewPortNode.width || 0) / 2 -
        targetViewPortNode.position.x,
      y: (targetViewPortNode.height || 0) / 2 - targetViewPortNode.position.y,
      zoom: 1,
    })
  }

  useEffect(() => {
    const targetViewPortNode = reactFlowInstance
      .getNodes()
      .find(n => n.id === viewPortNodeId)
    if (targetViewPortNode) {
      setViewPort(targetViewPortNode)
    }
  }, [viewPortNodeId])

  useEffect(() => {
    if (isDraftInitialized && flowErrorLog?.errorNodeName) {
      const target = reactFlowInstance
        .getNodes()
        .find(n => n.data.name === flowErrorLog.errorNodeName)
      if (target) {
        setViewPort(target)
        setNodeErrorStatusMap({
          [target.id]: true,
        })
      }
    }
  }, [flowErrorLog, isDraftInitialized])

  useTimeout(() => {
    setFlowErrorLog(false)
  }, 2000)

  useEffect(() => {
    // 大模型次数不足定位到对应的节点
    const errorCode = searchParams[0].get('errorCode')
    if (errorCode && isDraftInitialized) {
      const LLMNoTimesEnough = reactFlowInstance.getNodes().find(item => {
        if (Number(errorCode) === API_CODE_CODE.COMBO_NOT_GPT4_TIME_ENOUGH) {
          return (
            item.type === NodeType.LLM && item.data.inputs?.model === 'gpt-4'
          )
        } else if (
          Number(errorCode) === API_CODE_CODE.COMBO_NOT_QWENPLUS_TIME_ENOUGH
        ) {
          return (
            item.type === NodeType.LLM &&
            item.data.inputs?.model === 'qwen_plus'
          )
        } else {
          return null
        }
      })
      LLMNoTimesEnough && setViewPort(LLMNoTimesEnough)
    }
  }, [searchParams, isDraftInitialized])
}
