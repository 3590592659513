import { memo, useState, useEffect } from 'react'
import type { ProgressProps } from 'antd'
import { Progress } from 'antd'

interface AutoProgressProps extends Omit<ProgressProps, 'percent'> {
  /**
   * @desc 预期进度条会多少秒完成，组件会根据这个时间控制步长增长的频率
   * 单位: ms
   */
  expectationTime?: number
  /**
   * @desc 是否完成，如果为true，进度条会马上完成，如果为false，进度条按照
   * expectationTime 控制的频率增长，到 95%暂停，直到finish为true
   */
  finish: boolean
  className?: string
}

const AutoProgress = memo<AutoProgressProps>(props => {
  const { finish, expectationTime = 3000, ...antProgressProps } = props
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    if (finish) {
      setPercent(100)
      return
    }

    const intervalTime = expectationTime / 95 // 每次增加1%所需时间
    let currentPercent = percent

    const increaseProgress = () => {
      if (currentPercent < 95) {
        currentPercent += 1
        setPercent(currentPercent)
        setTimeout(increaseProgress, intervalTime)
      }
    }

    increaseProgress() // 开始进度增长

    return () => {} // 清除 setTimeout 不需要手动清除
  }, [finish, expectationTime])

  return <Progress {...antProgressProps} percent={percent} />
})

export default AutoProgress
