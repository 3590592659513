import type { Edge, Node } from 'reactflow'
import { isNestableNode } from '../utils'
import { findAllChildrenInNestedNode } from '../operation/util'

export function toggleNodeEnableOnFlow(
  nodes: Node[],
  edges: Edge[],
  node: Node,
  enable: boolean,
): { nodes: Node[]; edges: Edge[] } {
  const config = {
    nodes,
    edges,
  }
  if (isNestableNode(node)) {
    const allNodes = findAllChildrenInNestedNode(node, nodes)
    const nodeIds = allNodes.map(n => n.id)
    config.nodes = config.nodes.map(n =>
      nodeIds.includes(n.id)
        ? { ...n, data: { ...n.data, isEnable: enable } }
        : n,
    )
  } else {
    config.nodes = config.nodes.map(n =>
      n.id === node.id ? { ...n, data: { ...n.data, isEnable: enable } } : n,
    )
  }
  return config
}
