import { memo, useMemo, useRef, useState, type ReactNode } from 'react'
import { Popover, Spin } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { isEmpty, isString, map } from 'lodash-es'
import { InfoCircleOutlined } from '@ant-design/icons'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import classNames from 'classnames'
import { IconFont } from '@/components'
import { InfiniteScroll } from '@/components/InfiniteScrollComponent'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { tryParseToJsonObject } from '@/utils/string'

export interface InfiniteSelectOption extends Record<string, any> {
  label: ReactNode
  value: string
}

export interface InfiniteSelectProps {
  placeholder?: string
  value?: InfiniteSelectOption
  options?: InfiniteSelectOption[]
  disabled?: boolean
  hasMore?: boolean
  loadMore?: () => void
  onChange?: (value: string, option: InfiniteSelectOption) => void
  getContainer?: () => HTMLElement
}

export const InfiniteSelect = memo((props: InfiniteSelectProps) => {
  const {
    options,
    hasMore,
    value,
    placeholder,
    disabled,

    loadMore,
    onChange,
    getContainer,
  } = props

  const ref = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const handleSelect = useMemoizedFn((option: InfiniteSelectOption) => {
    onChange?.(option.value, option)
    setOpen(false)
  })

  const renderOption = useMemoizedFn(
    (option: InfiniteSelectOption, index: number) => {
      const itemContent = (
        <div
          key={index}
          className={classNames(
            'flex w-full h-34px px-12px flex items-center cursor-pointer rounded-6px hover:bg-[rgba(98,105,153,0.08)]',
            {
              'bg-[rgba(123,97,255,0.08)]!': value?.value === option.value,
            },
          )}
          onClick={() => handleSelect(option)}
        >
          {option.label}
          {!isEmpty(option.variable) && (
            <InfoCircleOutlined
              className='ml-auto'
              style={{ color: 'rgba(141, 141, 153, 0.4)' }}
            />
          )}
        </div>
      )

      if (isEmpty(option.variable)) {
        return itemContent
      }

      const popContent = (
        <OverlayScrollbarsComponent
          className='w-250px max-h-360px overflow-auto mr-[-8px] pr-8px'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          {map(option.showVariable || option.variable, (each, key) => {
            const sureValue = tryParseToJsonObject(each)

            return (
              <div key={key} className='text-12px mb-8px'>
                <p className='leading-18px'>{key}</p>
                {isString(sureValue) ? (
                  <p className='leading-18px text-#8D8D99 break-all'>
                    {isEmpty(sureValue) ? '-' : sureValue}
                  </p>
                ) : (
                  <pre className='leading-16px text-#8D8D99 break-all'>
                    {JSON.stringify(sureValue, null, 2)}
                  </pre>
                )}
              </div>
            )
          })}
        </OverlayScrollbarsComponent>
      )

      return (
        <Popover
          key={index}
          overlayClassName='[&_.ant-popover-arrow]:top-36px'
          content={popContent}
          placement='right'
          title={option.runType === 'STEP_RUN' ? '单节点输入' : '工作流输入'}
          destroyTooltipOnHide
          zIndex={1050}
        >
          {itemContent}
        </Popover>
      )
    },
  )

  const content = useMemo(() => {
    if (disabled) return null

    return (
      <div ref={ref}>
        <OverlayScrollbarsComponent
          className='w-330px max-h-270px overflow-auto'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <InfiniteScroll
            load={loadMore!}
            targetRoot={ref}
            hasMore={hasMore || false}
            loader={
              <div className='w-100% h-28px flex items-center flex-center'>
                <Spin />
              </div>
            }
          >
            {(options ?? []).map(renderOption)}
          </InfiniteScroll>
        </OverlayScrollbarsComponent>
      </div>
    )
  }, [disabled, hasMore, options, value])

  // useEffect(() => {
  //   if (open && options?.length === 1) {
  //     loadMore?.()
  //   }
  // }, [open])

  return (
    <Popover
      placement='bottomRight'
      trigger='click'
      content={content}
      arrow={false}
      overlayClassName='[&_.ant-popover-inner]:p-4px!'
      open={open}
      onOpenChange={setOpen}
      getPopupContainer={getContainer}
      getTooltipContainer={getContainer}
    >
      <div
        className={classNames(
          'text-#17171D px-8px py-5px rounded-6px flex flex-center hover:bg-#E4E5ED [&.ant-popover-open]:bg-#E4E5ED',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
      >
        <span className='mr-8px'>{value?.label || placeholder}</span>
        <IconFont name='arrow' className='text-7px text-font_1' />
      </div>
    </Popover>
  )
})
