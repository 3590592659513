import { useRequest } from 'ahooks'
import { message } from 'antd'
import copy from 'copy-to-clipboard'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkspaceStore } from '@/store'
import { AceCodeEditor as CodeEditor } from '@/features/editor'
import { Button, IconFont, Table } from '@/components'
import { APIdetail } from '@/apis/run'
import ContentURL from '../components/ContentURL'
import { TitleWarpper } from '../utils'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

export default ({ flowId = '' }: { flowId?: string }) => {
  const { data } = useRequest(() => APIdetail(flowId))
  const navigate = useNavigate()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const [url] = useState(import.meta.env.VITE_AI_API_BASE_URL)

  const curlTemplate = `
curl ${url}/v1/public_api/rest_api/${flowId}/execute_flow \\
-X POST \\
-H 'Content-Type: application/json' \\
-H 'Access-Key: Your Access-Key' \\
-H 'Workspace-Id: ${workspaceId}' \\
-d '{
  "inputs": {
    ${data
      ?.map(item => {
        return `"${item.variableName}": ${
          {
            input: '"XXXX"',
            textarea: '"XXXXX"',
            multiSelect: '"[XXX,XXX]"',
            select: '"XXX"',
            json: '"{}"',
            file: '"XXX"',
            modelSelect: '',
            multiInput: '[]',
            radio: '',
            slider: '',
            switch: false,
          }[item.type] || ''
        }`
      })
      ?.join(',\n\t\t')}
  }
}'
`
  const responseExample = `{
  "code": 200,
  "success": true,
  "message": "SUCCESS",
  "data": {
    "task_id": "cb50d8ee-4e40-4d19-96d1-9527ede2c979",
    "status": "SUCCEEDED",
    "run_result": "XXXXXXX",
    "message": "流程执行成功"
  },
  "now_time": 1691580087
}`

  const { scrollRef } = useScrollBar()

  return (
    <div
      ref={scrollRef}
      className='adapt:p-20 rounded-12px bg-#fff flex-1 w-full h-full overflow-y-auto'
    >
      <div className='adapt:mb-24 flex flex-items-center justify-between'>
        <TitleWarpper text='运行应用' marginBottom={0} />
        <Button
          type='default'
          size='small'
          className='important:text-14px'
          onClick={() =>
            navigate(`/workspace/${workspaceId}/settings/overview`)
          }
          icon={<IconFont name='key' className='text-12px' />}
        >
          获取API key
        </Button>
      </div>
      <TitleWarpper text='默认版本链接' fontSize={14} />
      <ContentURL
        url={`${url}/v1/public_api/rest_api/${flowId}/execute_flow`}
        method='POST'
        className='adapt:mb-24'
      />
      <TitleWarpper text='请求体' fontSize={14} />
      <Table
        dataSource={data || []}
        columns={[
          { title: '名称', dataIndex: 'variableName', key: 'variableName' },
          {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            render: (_, record: any) => {
              const typeArr: Record<string, string> = {
                input: 'String',
                textarea: 'String',
                multiSelect: 'Array',
                select: 'String',
              }
              return typeArr[record.type]
            },
          },
          {
            title: '必填',
            key: 'required',
            render: (_, record: any) => (record.required ? 'true' : 'false'),
          },
          { title: '备注', dataIndex: 'placeholder', key: 'placeholder' },
        ]}
        className='m-b-24px'
        pagination={false}
      />
      <TitleWarpper text='请求示例' fontSize={14} />
      <div className='m-b-24px'>
        <CodeEditor
          theme='one_dark'
          width='100%'
          noBorder
          readOnly
          height='200px'
          className='m-b-24px rd-12px'
          copy
          onCopy={text => {
            copy(text)
            message.success('请求示例 已复制到剪切板')
          }}
          value={curlTemplate}
          mode='python'
          placeholder='请求头'
        />
      </div>
      <TitleWarpper text='返回示例' fontSize={14} />
      <CodeEditor
        noBorder
        width='100%'
        height='192px'
        className='rd-12px'
        value={responseExample}
        mode='json'
        copy
        onCopy={text => {
          copy(text)
          message.success('返回示例 已复制到剪切板')
        }}
        readOnly
        theme='one_dark'
        placeholder='请求头'
      />
    </div>
  )
}
