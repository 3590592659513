import styled from '@emotion/styled'
import { Tabs } from 'antd'
import { Table } from '@/components'

export const StyledTable = styled(Table)`
  .ant-table-cell {
    background-color: #fff !important;
  }
`

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-left: 24px;
    margin-bottom: 0;
  }
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
`
