import { Button } from '@/components'

interface DatabaseSelectItemProps {
  icon?: string
  iconColor?: string
  name: string
  description: string
  onClick: VoidFunction
}

export function DatabaseSelectItem({
  name,
  description,
  icon,
  iconColor,
  onClick,
}: DatabaseSelectItemProps) {
  const iconEle = icon?.startsWith('http') ? (
    <img className='w-40px h-40px object-contain rounded-8px' src={icon} />
  ) : (
    <span
      className='w-40px h-40px rounded-8px text-20px/40px text-center'
      style={{ backgroundColor: iconColor }}
    >
      {icon}
    </span>
  )

  return (
    <div
      className='flex items-center h-72px cursor-pointer border-bottom after:border-line after:border-op-40 group'
      onClick={onClick}
    >
      {iconEle}
      <div className='ml-12 max-w-80%'>
        <div className='text-14px/16px font-medium text-font truncate'>
          {name}
        </div>
        <p className='text-12px/16px text-font_1 mt-6 truncate'>
          {description}
        </p>
      </div>
      <Button className='ml-auto hidden group-hover:block' type='primary'>
        添加
      </Button>
    </div>
  )
}
