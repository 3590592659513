import { useEffect, useRef } from 'react'
import { IconFont } from '@/components'
import { useMousePosition } from '../../hooks/useMousePosition'
import { useMovableOptionStore } from '@/store'

export function MovableOption(props: { showHeader?: boolean }) {
  const { showHeader } = props
  const { data, position: initialPosition } = useMovableOptionStore()
  const ref = useRef<HTMLDivElement>(null)
  const { mouseX, mouseY } = useMousePosition(initialPosition)

  useEffect(() => {
    const animate = () => {
      requestAnimationFrame(animate)
      if (ref.current) {
        ref.current.style.left = `${mouseX.current}px`
        ref.current.style.top = `${mouseY.current - (showHeader ? 30 : -30)}px`
      }
    }
    animate()
  }, [])

  if (!data) return null
  const { icon, name, description } = data

  return (
    <div
      ref={ref}
      className='absolute z-200 w-304px p-12px b-rd-8px bg-#fff b-1 b-#7b61ff shadow-[0_4px_16px_0px_rgba(0,0,0,0.1)]'
    >
      <div className='flex items-center mb-8px'>
        {icon}
        <div className='flex-1 font-500 text-#17171d'>{name}</div>
        <IconFont name='jiediansixuan' />
      </div>
      <div className='pl-32px line-height-18px text-12px text-#8D8D99'>
        {description}
      </div>
    </div>
  )
}
