import { useMemoizedFn, useTitle } from 'ahooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Resizable } from 'react-resizable'
import classNames from 'classnames'
import type { AgentRuleConfig } from '@bty/chat-types'
import {
  Button,
  ForkTaskLoading,
  IconFont,
  Modal,
  usePageModal,
} from '@/components'
import {
  FLOW_DISPLAYNAME,
  KNOWLEDGE_ROUTER_PATH,
  PLUGIN_DISPLAYNAME,
  PRODUCT_TITLE,
} from '@/constants/common'
import { ApplyType } from '@/apis/notification'
import {
  CopilotState,
  useAgentEdit,
} from '@/features/agent/provider/AgentEditProvider.tsx'
import { useForkTaskCheck } from '@/hooks/useForkTaskCheck.tsx'
import { Header } from '@/pages/agent/components/Header.tsx'
import { DatabasePane } from '@/features/agent/DatabasePane'
import { useResizeX } from '@/features/agent/hooks/useResizeX'
import CopilotIcon from '@/assets/agent/copilot-icon.svg?url'
import { AGENT_DESIGN_ROLE_FULLSCREEN } from '@/features/agent/constant/base'
import useShareAgentAuthentic from '@/hooks/useShareAgentAuthentic'
import { RoleCode } from '@/apis/authority'
import { useVersion } from '@/hooks/useVersion'
import { RunTypeGroupSelect } from '@/features/analyze/components/RunTypeGroupSelect'
import { AgentRunTypeGroup } from '@/features/analyze/constants'
import { MonitorOverviewPane } from '@/features/analyze/components/MonitorOverviewPane'
import { UsageRankPane } from '@/features/analyze/components/UsageRankPane'
import { AppStabilityPane } from '@/features/analyze/components/AppStabilityPane'
import { UsageTrendPane } from '@/features/analyze/components/UsageTrendPane'
import { useApplicationStore } from '@/store'
import { useTask } from '@/features/agent/Task/hooks/useTask'
import { TaskInfo } from '@/features/agent/Task/TaskInfo'
import { TaskAgentIcon } from '@/features/agent/Task/TaskChatIcon'
import { useAgentReady } from '@/pages/agent/hooks/useAgentReady.ts'
import AgentChat from './components/AgentChat'
import { Logs } from './components/Logs'
import { CopilotChat } from './components/CopilotChat'
import { AgentRule } from './components/AgentRule'
import type { DesignRouteItem } from './components/DesignNavigation'
import { DesignNavigation } from './components/DesignNavigation'
import { CopilotInit } from './components/CopilotInit'
import {
  AgentVariableModal,
  AgentVariablePop,
} from './components/AgentVariable'
import { useGlobalVariable } from './hooks/useGlobalVariable'
import { useAgentDiff } from './hooks/useAgentDiff'
import { AgentDiff } from './components/AgentDiff'
import { AgentNotAllowed } from './components/AgentNotAllowed'

function AgentHeader() {
  const {
    chatRef,
    ruleRef,
    copilotState,
    transformMap,
    applicationInfo,
    refreshApplicationInfo,
  } = useAgentEdit()

  const locked = useMemo(() => {
    return [CopilotState.generate, CopilotState.transform].includes(
      copilotState,
    )
  }, [copilotState])

  useTitle(applicationInfo?.name ? `${applicationInfo?.name}` : PRODUCT_TITLE, {
    restoreOnUnmount: true,
  })

  const handleAgentInfoEdited = useCallback(() => {
    chatRef?.current?.refetch()
  }, [])

  if (!applicationInfo) return null

  return (
    <Header
      locked={locked}
      transformMap={transformMap}
      ruleConfigRef={ruleRef}
      applicationInfo={applicationInfo}
      onAgentInfoEdited={handleAgentInfoEdited}
      refreshApplicationInfo={refreshApplicationInfo}
    />
  )
}

export function AgentEdit() {
  const forkTaskLoading = useForkTaskCheck('forkTaskId')
  const { applicationInfo } = useAgentEdit()
  const { setCurrentApplication } = useApplicationStore()

  const { hasSystemPrompt } = useAgentReady(
    (applicationInfo?.config as any)?.rule,
  )

  const { authentic } = useShareAgentAuthentic({
    bot: { app_id: applicationInfo?.id },
  })

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (authentic === false) {
      setOpen(true)
    } else if (authentic === true) {
      localStorage.setItem('noRedirect', 'false')
    }
  }, [authentic])

  useMemo(() => {
    if (!applicationInfo?.id) return

    const store = JSON.parse(localStorage.getItem('AGENT_DESIGN_ROUTE') || '{}')
    const lastRoute = store[applicationInfo.id]

    if (lastRoute && lastRoute !== 'copilot') {
      localStorage.setItem(
        'AGENT_DESIGN_ROUTE',
        JSON.stringify({
          ...store,
          [applicationInfo.id]: 'rule',
        }),
      )
    }

    if (!lastRoute) {
      localStorage.setItem(
        'AGENT_DESIGN_ROUTE',
        JSON.stringify({
          ...store,
          [applicationInfo.id]: hasSystemPrompt ? 'rule' : 'copilot',
        }),
      )
    }
  }, [])

  useEffect(() => {
    applicationInfo && setCurrentApplication(applicationInfo?.id)
    return () => {
      localStorage.removeItem(AGENT_DESIGN_ROLE_FULLSCREEN)
    }
  }, [])

  return (
    <div className='w-full h-100vh bg-white flex overflow-hidden flex-col'>
      <AgentHeader />
      <div className='flex flex-1 overflow-hidden relative justify-center'>
        {forkTaskLoading && <ForkTaskLoading text='Agent资源拷贝中，请稍等' />}
        <Outlet />
      </div>
      <Modal open={open} closable={false} footer={null} width={600}>
        <div className='py-16px'>
          <AgentNotAllowed
            showLogo={false}
            type={ApplyType.DEVELOPER}
            agentId={applicationInfo?.id}
            roleCode={RoleCode.DEVELOPER}
          />
        </div>
      </Modal>
    </div>
  )
}

export function AgentLog() {
  const { applicationInfo } = useAgentEdit()

  if (!applicationInfo) return null

  return (
    <Logs
      agentName={applicationInfo.name}
      agentId={applicationInfo.flowId}
      appId={applicationInfo.id}
      avatarInfo={{
        icon: applicationInfo.icon,
        name: applicationInfo.name,
        color: applicationInfo.color,
      }}
    />
  )
}

export function AgentAnalysis() {
  const { applicationInfo } = useAgentEdit()

  const [runType, setRunType] = useState<string[]>([])

  const logPageModal = usePageModal()

  const openLogPage = (value: any) => {
    let url = `/agent/${applicationInfo?.id}/log?run_types=${runType.join(',')}`
    if (value.date) {
      url += `&start_time=${value.date}&end_time=${value.date}`
    }
    if (value.user_name) {
      url += `&user_name=${value.user_name}`
    }
    logPageModal.show({
      url,
    })
  }

  if (!applicationInfo) {
    return null
  }

  return (
    <div className='w-full h-full overflow-y-auto px-24 pb-24 bg-bg'>
      <div className='pt-16 pb-24 flex items-center'>
        <RunTypeGroupSelect
          data={AgentRunTypeGroup}
          onTypeChange={value => {
            setRunType(value)
          }}
        />
        <div className='ml-12px text-#9e9e9e'>
          除「今日用量」外，其他统计指标均不包含当日的用量数据。
        </div>
      </div>
      <MonitorOverviewPane
        flowId={applicationInfo.flowId}
        flowType='AGENT'
        runType={runType}
        onLogJump={date => {
          openLogPage({ date })
        }}
      />
      <UsageTrendPane
        flowId={applicationInfo.flowId}
        flowType='AGENT'
        runType={runType}
        onLogJump={date => {
          openLogPage({ date })
        }}
      />
      <UsageRankPane
        flowId={applicationInfo.flowId}
        flowType='AGENT'
        runType={runType}
        onLogJump={user_name => {
          openLogPage({ user_name })
        }}
      />
      <AppStabilityPane
        flowId={applicationInfo.flowId}
        flowType='AGENT'
        runType={runType}
        onLogJump={date => {
          openLogPage({ date })
        }}
      />
    </div>
  )
}

const CHAT_PANE_MIN_WIDTH = 424

const AGENT_DESIGN_NAVIGATOR_WIDTH = 60

const ResizableHandle = (
  <div className='absolute top-0 -right-4 w-8px px-3 h-full !pointer-events-auto hover:bg-primary hover:!cursor-col-resize bg-clip-content z-1' />
)

export function AgentDesign() {
  const chatDomRef = useRef<HTMLDivElement>(null)

  const [searchParams] = useSearchParams()
  const routerMatch = useMatch({ path: '/agent/:id/design/:tab', end: false })
  const knowledgeRouterMatch = useMatch({
    path: `/agent/:id/design/${KNOWLEDGE_ROUTER_PATH}/*`,
  })

  const navigate = useNavigate()
  const {
    ruleRef,
    chatRef,
    applicationInfo,
    skillConfigContext,
    copilotState,
    setCopilotState,
    refreshApplicationInfo,
    statistics,
    updateStatistics,
    uploadFileConfig,
    refreshAgentUploadLoadSupportFileTypes,
  } = useAgentEdit()
  const { hasError } = skillConfigContext

  const [routerMemoryMap, setRouterMemoryMap] = useState<
    Record<NonNullable<DesignRouteItem['path']>, any>
  >({})

  const { hasSystemPrompt } = useAgentReady(
    (applicationInfo?.config as any)?.rule,
  )

  const {
    taskOpen,
    showTaskId,
    taskRecordId,
    handleTaskOpen,
    handleTaskClose,
    handleTaskBack,
    handleOpenTask,
  } = useTask()

  const isTaskEnable = useMemo(() => {
    const rule = (applicationInfo?.config as any)?.rule as AgentRuleConfig
    return !!rule?.preset_tasks_enabled
  }, [applicationInfo?.config])

  const {
    variableModalOpen,
    variableConfigs,

    closeVarModal,
    initVariablesValue,
  } = useGlobalVariable(applicationInfo!)

  const {
    diffHasOpened,
    diffOpen,
    diffConfigs,

    handleDelete,
    handleReNew,
    handleDiffUse,
    handleDiffOpen,
    handleDiffClose,
    handleAddDiffConfig,
  } = useAgentDiff(applicationInfo!, ruleRef, chatRef, handleTaskClose)

  const { isTeam } = useVersion()

  useEffect(() => {
    if (knowledgeRouterMatch) {
      const path = `${knowledgeRouterMatch.pathname}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
      setRouterMemoryMap({
        [KNOWLEDGE_ROUTER_PATH]: path,
      })
    }
  }, [knowledgeRouterMatch?.pathname, searchParams])

  const locked = useMemo(() => {
    return [CopilotState.generate, CopilotState.transform].includes(
      copilotState,
    )
  }, [copilotState])

  const tabItems = useMemo<DesignRouteItem[]>(() => {
    return [
      {
        type: 'route',
        path: 'copilot',
        text: '助手',
        icon: <img className='h-20px' src={CopilotIcon} />,
        top: 0,
      },
      { type: 'divider' },
      {
        type: 'route',
        path: 'rule',
        text: '规则',
        icon: <IconFont name='guize' className='text-20px' />,
        // count: statistics?.rule,
        top: 56 + 17,
      },
      {
        type: 'route',
        path: KNOWLEDGE_ROUTER_PATH,
        fullPath: routerMemoryMap[KNOWLEDGE_ROUTER_PATH],
        text: '知识',
        icon: <IconFont name='zhishi' className='text-20px' />,
        count: statistics?.knowledge,
        top: 56 * 2 + 17,
      },
      {
        type: 'route',
        path: 'plugin',
        text: PLUGIN_DISPLAYNAME,
        icon: <IconFont name='zhajian-1' className='text-20px' />,
        count: statistics?.plugin,
        top: 56 * 3 + 17,
      },
      {
        type: 'route',
        path: 'flow',
        text: FLOW_DISPLAYNAME,
        icon: <IconFont name='flow-1' className='text-20px' />,
        top: 56 * 4 + 17,
        count: statistics?.flow,
        errorIcon: hasError && (
          <IconFont
            name='shuimeng'
            className='c-error absolute top-0px right-0px'
          />
        ),
      },
      {
        type: 'route',
        path: 'database',
        text: '数据',
        icon: <IconFont name='data-1' className='text-20px' />,
        count: statistics?.database,
        top: 56 * 5 + 17,
        errorIcon: isTeam && (
          <IconFont
            name='suoding'
            className='text-16px absolute top-2px right-8px bg-#fff p-2px'
          />
        ),
      },
    ]
  }, [hasError, statistics, routerMemoryMap])

  const tab = useMemo(() => {
    if (!applicationInfo?.id) return 'rule'

    const store = JSON.parse(localStorage.getItem('AGENT_DESIGN_ROUTE') || '{}')
    const temp = routerMatch?.params?.tab || store[applicationInfo.id]
    localStorage.setItem(
      'AGENT_DESIGN_ROUTE',
      JSON.stringify({
        ...store,
        [applicationInfo.id]: temp,
      }),
    )
    return temp!
  }, [routerMatch?.params?.tab])

  const handleCopilotInitFinish = useMemoizedFn(() => {
    refreshApplicationInfo()
    refreshAgentUploadLoadSupportFileTypes()
    ruleRef.current?.refreshRuleConfig()
    skillConfigContext.refresh()
    updateStatistics()
  })

  const getConversationId = useMemoizedFn(() => {
    return chatRef.current?.conversationId!
  })

  // 跟产品确认，原本这段逻辑是为了HACK，添加插件后，Agent还处于「请配置规则后访问」的限制问题
  // 现在去掉了强制配置的限制，这段逻辑移除
  // const prevTab = usePrevious(tab)
  // useEffect(() => {
  //   chatRef.current?.chat?.list?.scrollToBottom()
  //
  //   if (tab && prevTab === 'rule' && tab !== 'rule' && !hasSystemPrompt) {
  //     ruleRef.current?.onSave()
  //   }
  // }, [tab])

  const containerRef = useRef<HTMLDivElement | null>(null)

  const { min, max, width, onStart, onResize, onStop } = useResizeX(
    containerRef,
    {
      offset: AGENT_DESIGN_NAVIGATOR_WIDTH,
      max: outerWidth => outerWidth - CHAT_PANE_MIN_WIDTH,
      afterResize: () => {
        chatRef.current?.chat?.list?.scrollToBottom()
      },
    },
  )

  useEffect(() => {
    if (routerMatch?.params?.tab || !applicationInfo?.id) return
    const store = JSON.parse(localStorage.getItem('AGENT_DESIGN_ROUTE') || '{}')
    let to = store[applicationInfo.id]
    if (searchParams.get('forkTaskId')) {
      to = 'rule'
    }

    navigate(
      {
        pathname: to,
      },
      {
        replace: true,
      },
    )
  }, [])

  if (!applicationInfo) return null

  return (
    <div className='flex flex-1 overflow-hidden relative'>
      <DesignNavigation items={tabItems} disabled={locked} value={tab} />

      <div ref={containerRef} className='flex flex-1 relative overflow-hidden'>
        {tab === 'copilot' &&
          (!hasSystemPrompt || copilotState === CopilotState.generate) && (
            <CopilotInit
              workspaceId={applicationInfo.workspaceId}
              appId={applicationInfo.id}
              flowId={applicationInfo.flowId}
              state={copilotState}
              onChangeState={setCopilotState}
              onFinish={handleCopilotInitFinish}
            />
          )}

        <Resizable
          className='z-1'
          axis='x'
          width={width}
          minConstraints={min}
          maxConstraints={max}
          handle={!diffOpen ? ResizableHandle : <></>}
          onResizeStart={onStart}
          onResize={onResize}
          onResizeStop={onStop}
        >
          <div
            className={classNames('h-full b-r b-line b-op-60', {
              'basis-0 grow-9 min-w-550px': diffOpen,
            })}
            style={{ width: !diffOpen ? `${width}px` : '' }}
          >
            <AgentRule className={tab !== 'rule' ? '!hidden' : ''} />
            <Outlet />
          </div>
        </Resizable>

        <div
          className='flex-1 h-full overflow-hidden bg-bg flex flex-col relative'
          style={{
            flexGrow: diffOpen ? diffConfigs.length * 10 : '',
            backgroundColor: 'rgba(247, 247, 250, 0.6)',
          }}
          ref={chatDomRef}
        >
          <header className='flex-none flex items-center h-36px px-24 mt-12px mb-20px'>
            <h1 className='font-medium text-16px text-font mr-auto'>
              效果预览
            </h1>

            {/* {!diffOpen && hasSystemPrompt && isTaskEnable && ( */}
            {!diffOpen && isTaskEnable && (
              <TaskAgentIcon
                agentId={applicationInfo.flowId}
                getConversationId={getConversationId}
                onClick={handleTaskOpen}
              />
            )}

            {variableConfigs && !!variableConfigs.length && (
              <AgentVariablePop
                alwaysActive
                close
                varList={variableConfigs}
                onConfirm={closeVarModal}
                onOpen={handleTaskClose}
              >
                <span className='flex flex-center text-14px text-#7B61FF! mr-8px cursor-pointer rounded-6px p-4px hover:bg-[rgba(98,105,153,0.08)] [&.ant-popover-open]:bg-[rgba(98,105,153,0.08)]'>
                  <IconFont name='bianliang' className='mr-4px' />
                  <span>变量</span>
                </span>
              </AgentVariablePop>
            )}

            {/* {hasSystemPrompt && !diffOpen && ( */}
            {!diffOpen && (
              <span
                className='flex flex-center text-14px text-#7B61FF! cursor-pointer rounded-6px p-4px hover:bg-[rgba(98,105,153,0.08)]'
                onClick={handleDiffOpen}
              >
                <IconFont name='muxing' className='mr-4px' />
                <span>多模型对比</span>
              </span>
            )}

            {diffOpen && (
              <Button
                disabled={diffConfigs.length >= 3}
                className='flex flex-center px-12px mr-8px font-normal!'
                onClick={handleAddDiffConfig}
              >
                <IconFont name='tianjiahuihua' className='mr-4px' />
                添加模型{diffConfigs.length}/3
              </Button>
            )}

            {diffOpen && (
              <Button
                className='flex flex-center px-12px font-normal!'
                onClick={handleDiffClose}
              >
                <IconFont name='daochu-1' className='mr-4px' />
                退出多模型
              </Button>
            )}
          </header>

          <div
            className={classNames('flex-1 overflow-hidden', {
              hidden: diffOpen,
            })}
          >
            <AgentChat
              ref={chatRef}
              chatVisible
              applicationInfo={applicationInfo}
              // chatVisible={hasSystemPrompt}
              initVariablesValue={initVariablesValue}
              onMessageTaskClick={handleOpenTask}
              uploadFileConfig={uploadFileConfig}
            />
          </div>

          {(diffOpen || diffHasOpened.current) && (
            <div
              className={classNames('flex-1 overflow-hidden', {
                hidden: !diffOpen,
              })}
            >
              <AgentDiff
                applicationInfo={applicationInfo}
                onUse={handleDiffUse}
                onDelete={handleDelete}
                onReNew={handleReNew}
                uploadFileConfig={uploadFileConfig}
              />
            </div>
          )}

          <AgentVariableModal
            open={variableModalOpen}
            getContainer={() => chatDomRef.current!}
            close={false}
            varList={variableConfigs ?? []}
            onConfirm={closeVarModal}
          />

          <Modal
            centered={false}
            closable={false}
            footer={null}
            mask={false}
            wrapClassName='static!'
            className='absolute top-110px! bottom-20px right-20px p-0px [&_.ant-modal-content]:h-full z-10'
            width={480}
            styles={{
              body: { padding: 0, height: '100%' },
              content: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              },
            }}
            open={taskOpen}
            destroyOnClose
          >
            <TaskInfo
              agentId={applicationInfo.flowId}
              taskRecordId={taskRecordId}
              versionId={applicationInfo.versionId}
              showTaskId={showTaskId}
              getConversationId={getConversationId}
              onBack={handleTaskBack}
              onClose={handleTaskClose}
            />
          </Modal>
        </div>
      </div>
    </div>
  )
}

export function AgentCopilot() {
  return <CopilotChat />
}

export function AgentDatabase() {
  const { applicationInfo, ruleRef } = useAgentEdit()

  const handleUpdate = useMemoizedFn(() => {
    ruleRef.current?.refreshRuleConfig()
  })

  if (!applicationInfo) return null

  return (
    <DatabasePane
      agentId={applicationInfo.flowId}
      versionId={applicationInfo.versionId}
      onUpdate={handleUpdate}
    />
  )
}
