import { useMemoizedFn } from 'ahooks'
import type { MutableRefObject } from 'react'
import React, { useRef, useEffect } from 'react'

interface InfiniteScrollProps {
  loading?: boolean
  disabled?: boolean
  hasPrev?: boolean
  hasMore: boolean
  load: (position?: 'prev' | 'next', scroll?: HTMLElement) => void
  loader: React.ReactNode
  children?: React.ReactNode
  endMessage?: React.ReactNode
  targetRoot: HTMLElement | MutableRefObject<HTMLElement | null>
}

function getDom(
  targetRoot: HTMLElement | MutableRefObject<HTMLElement | null>,
) {
  if (targetRoot instanceof HTMLElement) {
    return targetRoot
  }
  return targetRoot?.current
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  loading,
  disabled,
  hasPrev,
  hasMore,
  load,
  loader,
  children,
  endMessage,
  targetRoot,
}) => {
  const prevSentinelRef = useRef<HTMLDivElement>(null)
  const nextSentinelRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const innerLoading = useRef<boolean>(false)

  const handleIntersect = useMemoizedFn(
    async (
      entries: IntersectionObserverEntry[],
      _observer: IntersectionObserver,
    ) => {
      const isPrev = entries.find(e => e.target === prevSentinelRef.current)
      const isNext = entries.find(e => e.target === nextSentinelRef.current)

      if (loading || disabled || innerLoading.current) return

      innerLoading.current = true
      if (isPrev) {
        if (hasPrev && isPrev.intersectionRatio > 0) {
          await load('prev', getDom(targetRoot)!)
        }
      }

      if (isNext) {
        if (hasMore && isNext.intersectionRatio > 0) {
          await load('next', getDom(targetRoot)!)
        }
      }
      innerLoading.current = false
    },
  )

  useEffect(() => {
    if (!getDom(targetRoot)) return

    // Create a new IntersectionObserver when the component mounts
    observerRef.current = new IntersectionObserver(handleIntersect, {
      root: getDom(targetRoot) || null,
      rootMargin: '16px',
      threshold: 0.75,
    })

    if (prevSentinelRef.current) {
      observerRef.current.observe(prevSentinelRef.current)
    }

    // Attach the observer to the sentinel element
    if (nextSentinelRef.current) {
      observerRef.current.observe(nextSentinelRef.current)
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [getDom(targetRoot)])

  useEffect(() => {
    if (!observerRef.current) return

    if (prevSentinelRef.current) {
      observerRef.current.observe(prevSentinelRef.current)
    }
  }, [hasPrev])

  useEffect(() => {
    if (!observerRef.current) return

    if (nextSentinelRef.current) {
      observerRef.current.observe(nextSentinelRef.current)
    }
  }, [hasMore])

  return (
    <div>
      {hasPrev && <div ref={prevSentinelRef}>{!disabled && loader}</div>}
      {children}
      {hasMore && <div ref={nextSentinelRef}>{!disabled && loader}</div>}
      {!hasMore && endMessage}
    </div>
  )
}
