import './index.css'

import { memo, useEffect } from 'react'
import { Tooltip } from 'antd'
import { useBoolean, useMemoizedFn } from 'ahooks'
import { useMatch } from 'react-router-dom'
import { ReactComponent as AIIcon } from '@/assets/agent/ai-tip-icon.svg'
import { Modal } from '@/components'
import type { PromptStructItem } from '@/apis/prompt'
import { Track } from '@/features/track'
import { useTrack } from '@/features/track/Track.tsx'
import { CreateModal } from './CreateModal'

interface PromptAICreateModalProps {
  className?: string
  getPrompt: () => string
  onConfirm: (struct: PromptStructItem[]) => void
  templateType?: 'Agent' | 'Flow'
}

export const PromptAICreateModal = memo((props: PromptAICreateModalProps) => {
  const { className, getPrompt, onConfirm, templateType = 'Agent' } = props

  const match = useMatch('/agent/:agentId/design/rule')
  const [open, { setTrue, setFalse }] = useBoolean(false)
  const { doTrack } = useTrack()

  const handleConfirm = useMemoizedFn((struct: PromptStructItem[]) => {
    onConfirm(struct)
    setFalse()
    doTrack('agent_prompt_generate_use')
  })

  const handleKeyDown = useMemoizedFn((event: KeyboardEvent) => {
    const eventPath = event.composedPath()

    // 仅响应从 #root 中冒泡的事件，避免多个全局弹框出现
    if (
      eventPath.every(e => (e as HTMLElement).id !== 'root') &&
      eventPath[0] !== document.body
    )
      return

    if (open || !match) return

    if (event.shiftKey && event.key === 'Tab') {
      setTrue()
    }
  })

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className={className}>
      <Tooltip title='「Shift+Tab」唤起AI，一键生成提示词'>
        <span className='prompt-ai-text-bg rounded-6px h-24px inline-flex'>
          <Track event='agent_prompt_generate'>
            <span
              className='prompt-ai-text inline-flex flex-center cursor-pointer rounded-6px h-24px gap-4px px-8px hover:bg-bg_3/8'
              onClick={setTrue}
            >
              <AIIcon width={14} height={14} />
              <span>AI 生成</span>
            </span>
          </Track>
        </span>
      </Tooltip>
      <Modal
        maskClosable={false}
        open={open}
        title='AI生成提示词'
        width={960}
        footer={null}
        styles={{ body: { padding: 0 } }}
        onCancel={setFalse}
        onClose={setFalse}
        destroyOnClose
      >
        <CreateModal
          templateType={templateType}
          getPrompt={getPrompt}
          onConfirm={handleConfirm}
        />
      </Modal>
    </div>
  )
})
