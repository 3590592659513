import { Popover } from 'antd'
import type {
  CSSProperties,
  ClipboardEventHandler,
  PropsWithChildren,
} from 'react'
import { memo, useEffect, useRef, useState } from 'react'
import { useRequest } from 'ahooks'
import classNames from 'classnames'
import { useMatch } from 'react-router-dom'
import type { TextAreaRef } from 'antd/es/input/TextArea'
import { useScreenShot } from '@/hooks/useScreenShot'
import { TextArea } from '../input'
import { Button } from '../button'
import { IconFont } from '../icon'
import { useApplicationStore, useFlowLogStore } from '@/store'
import { reportIssue } from '@/apis/report'
import { FileList } from './FileList'

export enum SceneTypes {
  AGENT_EDIT = 1,
  AGENT_USE = 2,
  FLOW = 3,
  KNOWLEDGE = 4,
}

export interface IssueReportProps extends PropsWithChildren {
  sceneType: SceneTypes
  defaultDesc?: string
  className?: string
  extraData?: any
  // upload: ReturnType<typeof useReportFileUpload>
  upload: (name: string, file: File | Blob) => Promise<string | null>
  getPopupContainer?: () => HTMLElement
  reportCallback?: (info: { status: 'success' | 'error'; e?: unknown }) => void
}

export async function batchReport(req: {
  description: string
  attachments: string[]
  location_type: SceneTypes
  scene_info: Record<string, any> | Record<string, any>[]
}) {
  return await reportIssue(req)
}

export const IssueReport = memo((props: IssueReportProps) => {
  const {
    sceneType,
    defaultDesc = '',
    className,
    extraData,
    children,
    upload,
    getPopupContainer,
    reportCallback,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<TextAreaRef>()
  const hasScreenShotRef = useRef(false)
  const [submited, setSubmited] = useState(false)
  const [wrapperStyle, setWrapperStyle] = useState<CSSProperties>({})
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState(defaultDesc)
  const [files, setFiles] = useState<string[]>([])
  const { blob, takeScreenShot } = useScreenShot()
  const { currentTaskId, currentLog } = useFlowLogStore()
  const { currentApplicationId } = useApplicationStore()
  const match = useMatch('/application/:workspaceId/:appId/flow/:id')
  const toolLogMatch = useMatch('/agent/:id/design/:tab')
  const { runAsync: report, loading } = useRequest(reportIssue, {
    manual: true,
  })

  const onPaste: ClipboardEventHandler = async e => {
    const { items } = e.clipboardData
    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile()
        if (file) {
          const url = await upload(`image_${files.length + 1}`, file)
          if (url) {
            setFiles(l => l.concat([url]))
          }
        }
      }
    }
  }

  const onFileRemove = (url: string) => {
    setFiles(l => l.filter(f => f !== url))
  }

  const onSubmit = async () => {
    if (loading) return
    const reportData = {
      description,
      attachments: files,
      location_type: sceneType,
    }
    let reportInfo
    if (
      sceneType === SceneTypes.AGENT_EDIT ||
      sceneType === SceneTypes.AGENT_USE
    ) {
      reportInfo = {
        agent_id: extraData.agentId,
        agent_url: window.location.href,
        agent_log: defaultDesc,
        agent_log_id: extraData.conversationId,
        record_id: extraData.record_id,
        trace_id: extraData.traceId,
      }
    } else if (sceneType === SceneTypes.FLOW) {
      reportInfo = {
        flow_log_id:
          match?.params || toolLogMatch?.params
            ? currentTaskId
            : currentLog?.task_id,
        flow_log: defaultDesc,
        flow_url: window.location.href,
        flow_id: currentApplicationId,
      }
    } else if (sceneType === SceneTypes.KNOWLEDGE) {
      reportInfo = {
        dataset_url: window.location.href,
        dataset_id: extraData.dataset_id,
        dataset_file_name: extraData.dataset_file_name,
        dataset_file_url: extraData.dataset_file_url,
        dataset_file_id: extraData.dataset_file_id,
        dataset_failed_reason: extraData.dataset_failed_reason,
      }
    }
    try {
      const req = {
        ...reportData,
        scene_info: reportInfo,
      }
      await report(req)

      setSubmited(true)
      setDescription('')
      setFiles([])
      hasScreenShotRef.current = false
      reportCallback?.({
        status: 'success',
        e: req,
      })
    } catch (error) {
      reportCallback?.({
        status: 'error',
        e: error,
      })
      // err
    }
  }

  const onShow = () => {
    setOpen(true)
    setDescription(defaultDesc)
    if (!hasScreenShotRef.current) {
      takeScreenShot()
    }
  }

  const onClose = () => {
    setSubmited(false)
    setOpen(false)
  }

  const onEditFocus = () => {
    setWrapperStyle({
      borderColor: '#7b61ff',
    })
    textareaRef.current?.focus({
      cursor: 'end',
    })
  }

  useEffect(() => {
    if (blob) {
      hasScreenShotRef.current = true
      upload('screenshot', blob).then(url => {
        if (url) {
          setFiles(l => [url, ...l])
        }
      })
    }
  }, [blob])

  return (
    <Popover
      open={open}
      zIndex={990}
      // getPopupContainer={() => (children ? document.body : ref.current!)}
      getPopupContainer={getPopupContainer || (() => ref.current!)}
      destroyTooltipOnHide
      content={
        <div className='relative flex flex-col min-h-454px p-24px'>
          <div
            className='absolute top-24px right-24px flex-center w-20px h-20px b-rd-4px cursor-pointer hover:bg-#626999 hover:bg-op-12'
            onClick={onClose}
          >
            <IconFont name='guanbi' onClick={() => setOpen(false)} />
          </div>
          <div className='mb-24px text-18px font-500 text-#17171d'>
            问题上报
          </div>
          {submited ? (
            <div className='flex-col flex-center flex-1'>
              <div className='flex-center w-40px h-40px b-rd-50% bg-#00b042'>
                <IconFont name='success' className='text-20px text-#fff' />
              </div>
              <div className='mt-24px line-height-26px font-600'>上报成功</div>
              <div className='line-height-26px'>我们会尽快处理你的问题</div>
            </div>
          ) : (
            <>
              <div
                className='relative b-1 b-transparent b-rd-8px bg-#626999 bg-op-6'
                style={wrapperStyle}
              >
                <TextArea
                  ref={textareaRef}
                  className='b-none bg-transparent! resize-none! hover:bg-inherit hover:b-color-inherit focus:shadow-none'
                  rows={9}
                  placeholder='请输入问题描述'
                  value={description}
                  onChange={e => setDescription(e.target.value.trim())}
                  onFocus={() => {
                    setWrapperStyle({
                      borderColor: '#7b61ff',
                    })
                  }}
                  onBlur={() => {
                    setWrapperStyle({
                      borderColor: 'transparent',
                    })
                  }}
                  onPaste={onPaste}
                ></TextArea>
                <div className='w-100%'>
                  <FileList
                    files={files}
                    onItemRemove={onFileRemove}
                    onFileAdd={url => {
                      setFiles(l => l.concat([url]))
                    }}
                    onFocus={onEditFocus}
                  />
                </div>
              </div>
              <div className='flex justify-end mt-24px'>
                <Button type='primary' loading={loading} onClick={onSubmit}>
                  提交
                </Button>
              </div>
            </>
          )}
        </div>
      }
      overlayInnerStyle={{
        padding: 0,
        width: 480,
      }}
      overlayClassName='issue-report-popover'
    >
      <div ref={ref} onClick={onShow}>
        {children || (
          <Button className={classNames('text-12px', className)} type='link'>
            问题上报
          </Button>
        )}
      </div>
    </Popover>
  )
})
