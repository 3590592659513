import type { Options } from 'ahooks/lib/useWebSocket'
import useWebSocket, { ReadyState } from 'ahooks/lib/useWebSocket'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useApplicationStore,
  useVersionStore,
  useWorkspaceStore,
} from '@/store'
import { tokenStorage } from '@/utils/storage'
import { API_CODE_CODE } from '@/constants/request'
import { createSocketUrl } from '@/utils/socket.ts'
import { useComboRequest } from './useComboRequest'

interface StreamingExecuteOptions {
  socketUrl: string
  flowId?: string
  defaultParams?: Record<string, any>
  websocketOptions?: Partial<Options>
  onStepStart?: (data: {
    id: string
    name: string
    message_type: StreamMessageType
  }) => void
  onStepEnd?: (data: { id: string; name: string }) => void
  onMessage: (data: string) => void
  onFinish: (res: any, hasError?: boolean) => void
}

export enum StreamMessageType {
  PIECEWISE = 'piecewise', // 逐个字符返回，大部分场景下使用， "斑" "头" "雁"
  AGGREGATE = 'aggregate', // 拼接返回，极少数模型，比如阿里的 "斑" "斑头" "斑头雁"
}

export function useStreamingExecuteFlow(options: StreamingExecuteOptions) {
  const {
    socketUrl,
    defaultParams = {},
    websocketOptions = {},
    onStepStart,
    onStepEnd,
    onFinish,
    onMessage,
  } = options
  const { id, appId } = useParams()
  const other = useSearchParams()
  const [executeParams, setExecuteParams] = useState(defaultParams)
  const workspace_id = useWorkspaceStore(state => state.currentWorkspaceId)
  const { fetchVersionByWorkspaceId } = useVersionStore()
  const token = tokenStorage.get()
  const { currentApplicationId: applicationId } = useApplicationStore()
  const { handleNotEnoughCombo, errorMessage } = useComboRequest(
    () => Promise.resolve(),
    { manual: true },
    undefined,
    { flowId: options?.flowId, applicationId: appId || id },
  )

  const { readyState, latestMessage, connect, disconnect, sendMessage } =
    useWebSocket(
      createSocketUrl(socketUrl, {
        'Workspace-Id': other[0].get('workspaceId') || workspace_id,
        'Application-Id': id || applicationId,
        Authorization: token,
      }),
      {
        ...websocketOptions,
        manual: true,
        reconnectLimit: 0,
      },
    )

  const startExecute = (params?: StreamingExecuteOptions['defaultParams']) => {
    params && setExecuteParams(params)
    connect()
  }

  useEffect(() => {
    if (readyState === ReadyState.Open) {
      sendMessage(JSON.stringify(executeParams))
    }
  }, [readyState])

  useEffect(() => {
    if (latestMessage) {
      let data: any = null
      try {
        data = JSON.parse(latestMessage.data)
        data =
          data.message?.code === API_CODE_CODE.COMBO_NOT_ENOUGH
            ? {
                ...data,
                message: {
                  ...data.message,
                  message: errorMessage(data.message?.message),
                },
              }
            : data
      } catch (error) {
        // error
      }
      if (data?.type === 'result') {
        disconnect()
        const { code, data: result } = data.message
        if (code === 200 && result) {
          fetchVersionByWorkspaceId(workspace_id)
          onFinish(result)
        } else {
          onFinish(data.message, true)
        }
        handleNotEnoughCombo(
          data.message,
          () => onFinish(data.message || {}),
          false,
        )
      } else if (data?.type === 'start') {
        onStepStart?.(data)
      } else if (data?.type === 'end') {
        onStepEnd?.(data)
      } else if (!data) {
        onMessage(latestMessage.data)
      }
    }
  }, [latestMessage])

  return {
    startExecute,
  }
}
