import { useCallback } from 'react'
import type { NodeValidateFunction } from '@/store'
import { useFlowDraftStore } from '@/store'

export function useNodeValidate(id: string) {
  const registerNodeValidateFunction = useFlowDraftStore(
    s => s.registerNodeValidateFunction,
  )
  const validateFlow = useFlowDraftStore(s => s.validateFlow)
  const removeNodeValidateFunction = useFlowDraftStore(
    s => s.removeNodeValidateFunction,
  )

  const registerValidateFunction = useCallback(
    (validateFunc: NodeValidateFunction) => {
      registerNodeValidateFunction(id, validateFunc)
    },
    [id, registerNodeValidateFunction],
  )

  const removeValidateFunction = useCallback(() => {
    removeNodeValidateFunction(id)
  }, [id, removeNodeValidateFunction])

  const validateNode = useCallback(async () => {
    return await validateFlow(id)
  }, [id, validateFlow])

  return {
    validateNode,
    registerValidateFunction,
    removeValidateFunction,
  }
}
