import type {
  OrderServiceStatus,
  OrderStatus,
  OrderType,
  PayType,
} from '@/features/pay'
import { request } from './lib'

export interface orderType {
  orderNo: string
  orderStatus: OrderStatus
  placeOrderTime: string
  productServiceName: string
  realPayAmount: number
  userName: string
  orderPayType: PayType
  payingTime: string
  orderType: OrderType
  yeah_points: number
}

// 查询workspace下的所有订单
export function getorderList(workspace_id: string) {
  return request.get<orderType[]>(`/v1/order/list?workspace_id=${workspace_id}`)
}

export interface CalculatePriceParams {
  workspace_id: string
  product_service_id: string | number
  order_type: OrderType
  duration_of_service: number
}

export interface CalculatePriceInfo {
  real_pay_amount: number
  total_amount: number
  discount_amount: number
  task_recovery_amount: number
}

export function getOrderCalculatedPrice(data: CalculatePriceParams) {
  return request.post<CalculatePriceInfo>('/v1/order/charging', data)
}

export interface CreateOrderData {
  workspace_id: string
  duration_of_service: number
  product_service_id: string | number
  order_type: number
}

export interface OrderDetail {
  orderNo: string
  userId: number
  userPhone: string
  userName: string
  workspaceId: string
  orderPayType: null
  orderSource: string
  totalAmount: string
  discountAmount: string
  realPayAmount: string
  taskRecoveryAmount: string
  placeOrderTime: string
  payingTime: null
  effectiveStartTime: string
  effectiveEndTime: string
  durationOfService: number
  orderType: OrderType
  orderServiceStatus: OrderServiceStatus
  orderStatus: OrderStatus
  tradeCertUrl: null
  orderDesc: null
  productServiceName: string
  productServiceDesc: string
  price: string
  orderExpireTime: string
}

export function createOrder(data: CreateOrderData) {
  return request.post<string>('/v1/order', data, { ignoreError: true })
}

export function getOrderDetailById(orderId: string) {
  return request.get<OrderDetail>(`/v1/order/detail?order_no=${orderId}`)
}

export interface DoPayParams {
  order_no: string
  pay_type: string
}

export function doToBPay(data: DoPayParams) {
  return request.post<string>('/v1/pay/send_tob', data)
}

export function cancelPay(orderId: string) {
  return request.put<string>(`/v1/order/cancel?order_no=${orderId}`)
}

interface OrderPayCertParams {
  order_no: string
  trade_cert_url: string[]
}

export function uploadPayCert(data: OrderPayCertParams) {
  return request.post<string>('/v1/order/trade_cert', data)
}
