import { memo, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import type { InputRef } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { Input } from '@/components'

interface PromptStructTitleProps {
  title: string
  onChange: (newTitle: string) => void
  disabled?: boolean
  mini?: boolean
}

export const PromptStructTitle = memo<PromptStructTitleProps>(props => {
  const { title, disabled, onChange, mini = false } = props
  const [isEdit, setIsEdit] = useState(disabled ? false : !title)

  const inputRef = useRef<InputRef>(null)
  const [inputValue, setInputValue] = useState(title)

  const handleConfirm = useMemoizedFn(() => {
    if (inputValue.trim()) {
      onChange(inputValue)
      setIsEdit(false)
    }
  })

  useEffect(() => {
    // 一坨💩: 这里mini是在flow渲染时传入的，flow为了配合外部JsonForm的渲染模式，选择失焦完成表单渲染更新
    if (isEdit && !mini) {
      inputRef.current?.focus()
    }
  }, [isEdit])

  useEffect(() => {
    if (title) {
      setInputValue(title)
    }
  }, [title])

  return (
    <div>
      {isEdit ? (
        <Input
          ref={inputRef}
          value={inputValue}
          className={cn('h-22px! p-4 rd-4px!', {
            'text-12px!': mini,
            'text-14px!': !mini,
          })}
          size='small'
          placeholder='未命名主题'
          onChange={e => setInputValue(e.target.value)}
          onBlur={handleConfirm}
          onPressEnter={handleConfirm}
        />
      ) : (
        <span
          className={cn('font-500 text-14px p-4px ml--4px', {
            'cursor-pointer hover:bg-bg_3/8 rd-4px': !disabled,
            'text-12px!': mini,
          })}
          onClick={() => {
            if (!disabled) {
              setIsEdit(true)
            }
          }}
        >
          {title}
        </span>
      )}
    </div>
  )
})

PromptStructTitle.displayName = 'PromptStructTitle'
