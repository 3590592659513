import { useBoolean, useEventListener, useMemoizedFn } from 'ahooks'
import { Spin } from 'antd'
import classNames from 'classnames'
import type { CSSProperties } from 'react'
import { memo, useEffect, useRef } from 'react'

type SupportLanguage = 'python' | 'javascript' | 'typescript'
interface LSPApis {
  javascript?: string
  typescript?: string
  python?: string
}
interface GlobalVariable {
  node?: string
  label: string
  variableType?: 'input' | 'node' | 'loopscope'
  description?: string
  fileType?: string
}

interface CodeEditorIframeProps {
  defaultValue?: string
  onChange?: (code: string) => void
  language: SupportLanguage
  className?: string
  style?: CSSProperties
  lspApis?: LSPApis
  globalVariables?: GlobalVariable[]
  tempPath?: string
}

const CODE_CHANGE_EVENT = 'CODE_CHANGE'
const CODE_DATA_POST_EVENT = 'CODE_DATA_POST'
const CODE_EDITOR_RENDERED = 'CODE_EDITOR_RENDERED'

export const CodeEditorIframe = memo((props: CodeEditorIframeProps) => {
  const {
    className,
    style,
    language,
    lspApis,
    globalVariables,
    tempPath,
    defaultValue,
    onChange,
  } = props
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [iframeLoaded, { setTrue: setLoaded }] = useBoolean(false)

  const [iframeAppRendered, { setTrue: setIframeAppRendered }] =
    useBoolean(false)

  const loaded = iframeLoaded && iframeAppRendered

  const postDataToIframe = useMemoizedFn(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        {
          type: CODE_DATA_POST_EVENT,
          data: {
            language,
            lspApis,
            globalVariables,
            tempPath,
            defaultValue,
          },
        },
        '*',
      )
    }
  })
  useEventListener(
    'message',
    (event: MessageEvent) => {
      if (event.data.type === CODE_CHANGE_EVENT) {
        onChange?.(event.data.code)
      }
      if (event.data.type === CODE_EDITOR_RENDERED) {
        setLoaded()
        setIframeAppRendered()
        postDataToIframe()
      }
    },
    {
      target: window,
    },
  )

  useEffect(() => {
    postDataToIframe()
  }, [
    language,
    lspApis,
    globalVariables,
    tempPath,
    defaultValue,
    iframeRef.current,
  ])

  return (
    <div className='w-full h-full relative'>
      <iframe
        ref={iframeRef}
        src={import.meta.env.VITE_WEB_IDE_URL}
        className={classNames(className, {
          'opacity-0': !loaded,
        })}
        // allow='allow-scripts'
        style={style}
      />
      <div
        className={classNames(
          'w-full h-full bg-white flex-center rounded-8px absolute top-0 left-0 ',
          !loaded ? 'flex' : 'hidden',
        )}
      >
        <Spin />
      </div>
    </div>
  )
})
