import { memo, useMemo } from 'react'
import { Collapse } from 'antd'
import { uniqueId } from 'lodash-es'
import JsonView from 'react-json-view'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import type { LogEntry } from '..'
import { IconFont } from '@/components'

interface LogItemProps {
  logEntry: LogEntry
  key?: string
  itemWidth: number
}

const StyledCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    .ant-collapse-expand-icon {
      height: 16px;
    }
  }
`

const LevelStyleMap = {
  log: {
    icon: 'yonghuzidingyi',
    iconColor: 'rgba(98, 105, 153, 0.6)',
    backgroundColor: 'trasparent',
    labelColor: '#17171D',
  },
  warn: {
    icon: 'jinggaorizhi',
    iconColor: 'rgba(254, 151, 0, 1)',
    backgroundColor: '#FFF7EB',
    labelColor: '#E58800',
  },
  error: {
    icon: 'cuowurizhi',
    iconColor: 'rgb(236,95,50)',
    backgroundColor: '#FFF1ED',
    labelColor: '#E54A16',
  },
}

function willTriggerEllipsis(
  text: string,
  width: number,
  fontSize: string = '14px',
  fontFamily: string = 'system-ui',
): boolean {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    return false
  }
  context.font = `${fontSize} ${fontFamily}`
  const textWidth = context.measureText(text).width
  return textWidth > width
}

function MessageTitle(props: {
  message: string
  color: string
  style?: React.CSSProperties
  className?: string
  maxWidth: number
}) {
  return (
    <div
      style={{
        color: props.color,
        maxWidth: props.maxWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...props.style,
      }}
      className={props.className}
    >
      {props.message}
    </div>
  )
}

function LogitemContent(props: LogItemProps) {
  const { logEntry, itemWidth } = props

  const isEllipsisTitle = useMemo(() => {
    if (!logEntry?.collapseObject) {
      return willTriggerEllipsis(logEntry.message, itemWidth)
    }
    return false
  }, [logEntry.message])

  return (
    <div
      className='flex items-start py-12px px-16px b-b-1 b-#E1E1E5 b-op-32 text-14px'
      style={{
        backgroundColor: LevelStyleMap[logEntry.level].backgroundColor,
      }}
    >
      <span>
        <IconFont
          name={LevelStyleMap[logEntry.level].icon}
          color={LevelStyleMap[logEntry.level].iconColor}
          className='text-16px mr-4px'
        />
      </span>
      <span className='ml-8px color-#8D8D99'>
        {dayjs(logEntry.timestamp).format('HH:mm:ss')}
      </span>
      {logEntry?.collapseObject ? (
        <StyledCollapse
          className='ml-12px'
          ghost
          expandIcon={params => {
            const { isActive } = params
            return (
              <IconFont
                name='left-arrow'
                style={{
                  color: 'rgba(98, 105, 153, 0.6)',
                  fontSize: '7px',
                  transition: 'all .2s',
                  transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            )
          }}
          items={[
            {
              key: uniqueId(),
              label: (
                <MessageTitle
                  maxWidth={itemWidth}
                  message={logEntry?.message?.replace(/[\n\r]/g, '')}
                  color={LevelStyleMap[logEntry.level].labelColor}
                />
              ),
              children: (
                <JsonView
                  src={logEntry.collapseObject as object}
                  displayDataTypes={false}
                  enableClipboard={false}
                  displayObjectSize={false}
                  name={false}
                  style={{
                    fontSize: '11px',
                    fontWeight: '400',
                    letterSpacing: '-0.195px',
                    lineHeight: '13px',
                  }}
                />
              ),
            },
          ]}
        />
      ) : isEllipsisTitle ? (
        <StyledCollapse
          className='ml-12px'
          ghost
          expandIcon={params => {
            const { isActive } = params
            return (
              <IconFont
                name='left-arrow'
                style={{
                  color: 'rgba(98, 105, 153, 0.6)',
                  fontSize: '7px',
                  transition: 'all .2s',
                  transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            )
          }}
          items={[
            {
              key: uniqueId(),
              label: (
                <MessageTitle
                  maxWidth={itemWidth}
                  message={logEntry?.message}
                  color={LevelStyleMap[logEntry.level].labelColor}
                />
              ),
              children: (
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    lineHeight: '18px',
                    color: LevelStyleMap[logEntry.level].labelColor,
                    wordBreak: 'break-all',
                    marginLeft: '3px',
                  }}
                >
                  {logEntry?.message}
                </div>
              ),
            },
          ]}
        />
      ) : (
        <MessageTitle
          maxWidth={itemWidth}
          message={logEntry?.message}
          color={LevelStyleMap[logEntry.level].labelColor}
          className='ml-28px'
        />
      )}
    </div>
  )
}

export const LogItem = memo(LogitemContent)
