import React, { useEffect, useMemo } from 'react'
import type { Dayjs } from 'dayjs'
import { Spin, type TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { useRequest } from 'ahooks'
import styled from '@emotion/styled'
import type { SegmentedProps } from 'antd/lib'
import {
  Select,
  RangePickerWrapper,
  Table,
  AppLogo,
  Segmented,
} from '@/components'
import type { workspaceOverview } from '@/apis/workspaceCombo'
import { getWorkspaceOverview } from '@/apis/workspaceCombo'
import { useWorkspaceStore } from '@/store'
import type { VersionInfo } from '@/apis/version'
import {
  DivWrapper,
  rangePresets,
} from '@/pages/application/components/appDetail/utils'
import { POINTS_CONSUME_NAME } from '@/constants/commercialization'
import { isPrivateVersion } from '@/constants/common.ts'
import { AppStatusSetting } from './util'
import StatusTag from './components/StatusTag'
import StatisticWrapper from './components/ComboStatistic'

const format = 'YYYY/MM/DD HH:mm'

const format1 = 'YYYY-MM-DD HH:mm:ss'

// @ts-expect-error 333fg
type RangeValue<T = any> = any

const StyledSegmented = styled(Segmented)`
  font-size: 14px;
  .ant-segmented-item {
    padding: 8px 16px;
  }
`

export enum SearchType {
  TODAY = 'TODAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  CUSTOM = 'CUSTOM',
}

export function getTimeRange(value: SearchType): [string, string] {
  const now = dayjs()
  let startTime: string
  let endTime: string

  switch (value) {
    case SearchType.TODAY:
      startTime = now.startOf('day').toISOString()
      endTime = now.endOf('day').toISOString()
      break
    case SearchType.WEEK:
      startTime = now.startOf('week').toISOString()
      endTime = now.toISOString()
      break
    case SearchType.MONTH:
      startTime = now.startOf('month').toISOString()
      endTime = now.toISOString()
      break
    case SearchType.YEAR:
      startTime = now.startOf('year').toISOString()
      endTime = now.toISOString()
      break
    case SearchType.CUSTOM:
      // 自定义时间段需要额外的参数，这里仅返回一个默认值
      startTime = now.toISOString()
      endTime = now.toISOString()
      break
    default:
      throw new Error('Invalid search type')
  }

  return [startTime, endTime]
}

// 测试
const options: SegmentedProps['options'] = [
  {
    value: SearchType.TODAY,
    label: '今日',
  },
  {
    value: SearchType.WEEK,
    label: '本周',
  },
  {
    value: SearchType.MONTH,
    label: '本月',
  },
  {
    value: SearchType.YEAR,
    label: '本年',
  },
  {
    value: SearchType.CUSTOM,
    label: '自定义',
  },
]
const columns: TableColumnsType<workspaceOverview['appDimensionBaseInfo'][0]> =
  [
    {
      title: '应用名称',
      key: 'appName',
      ellipsis: true,
      width: 280,
      render: (_, record) => {
        return (
          <div className='flex items-center'>
            <AppLogo
              value={record.icon}
              color={record.color}
              type='emoji'
              square
              size={32}
              fillSize={18}
            />
            <div className='ml-12px'>{record.appName}</div>
          </div>
        )
      },
    },
    {
      width: 230,
      title: `消耗${POINTS_CONSUME_NAME}`,
      key: 'consume_points',
      dataIndex: 'consume_points',
      defaultSortOrder: 'descend',
      hidden: isPrivateVersion,
      sorter: (a, b) => a.consume_points - b.consume_points,
    },
    {
      width: 150,
      title: '应用运行次数',
      key: 'run_count',
      ellipsis: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.run_count - b.run_count,
      render: (_, record) => record.run_count,
    },
    {
      title: '应用状态',
      key: 'state',
      ellipsis: true,
      render: (_, record) => (
        <StatusTag {...(AppStatusSetting[record.version_status] || {})} />
      ),
    },
    {
      title: '开发者',
      key: 'developer_count',
      ellipsis: true,
      sorter: (a, b) => a.developer_count - b.developer_count,
      render: (_, record) => record.developer_count,
    },
    {
      title: '使用者',
      key: 'use_users_count',
      ellipsis: true,
      sorter: (a, b) => a.use_users_count - b.use_users_count,
      render: (_, record) => record.use_users_count,
    },
    {
      title: '最后更新时间',
      key: 'updated_at',
      width: 220,
      ellipsis: true,
      sorter: (a, b) => {
        const unixA = a.updated_at
          ? dayjs(a.updated_at).unix()
          : Number.NEGATIVE_INFINITY
        const unixB = b.updated_at
          ? dayjs(b.updated_at).unix()
          : Number.NEGATIVE_INFINITY

        return Number.isNaN(unixA - unixB) ? 0 : unixA - unixB
      },
      render: (_, record) =>
        record.updated_at ? dayjs(record.updated_at).format(format) : '-',
    },
    {
      width: 220,
      title: '最后运行时间',
      key: 'last_run_time',
      ellipsis: true,
      sorter: (a, b) => {
        const lastA = a.last_run_time
          ? dayjs(a.last_run_time).unix()
          : Number.NEGATIVE_INFINITY
        const lastB = b.last_run_time
          ? dayjs(b.last_run_time).unix()
          : Number.NEGATIVE_INFINITY
        return Number.isNaN(lastA - lastB) ? 0 : lastA - lastB
      },
      render: (_, record) =>
        record.last_run_time ? dayjs(record.last_run_time).format(format) : '-',
    },
    {
      title: '日志',
      key: 'operation',
      align: 'end',
      render: (_, { app_id, application_type }) => {
        return (
          <div
            className='c-#7b61ff cursor-pointer'
            onClick={() => {
              window.open(
                application_type === 'AGENT'
                  ? `/agent/${app_id}/log`
                  : `/application/${app_id}/日志?tab=log`,
              )
            }}
          >
            查看
          </div>
        )
      },
    },
  ]

// const times = getTimeRange(SearchType.MONTH)
export default ({ versionInfo }: { versionInfo?: VersionInfo }) => {
  const [appState, setAppState] = React.useState('-1')
  const [rangeTime, setRangeTime] = React.useState<RangeValue<Dayjs>>([
    dayjs().startOf('M'),
    dayjs(),
  ])
  const workspace_id = useWorkspaceStore(state => state.currentWorkspaceId)
  const initRangeTime = useMemo<RangeValue<Dayjs> | undefined>(() => {
    if (!versionInfo) {
      return undefined
    }
    const value: RangeValue<Dayjs> = [
      dayjs(versionInfo?.next_refresh_time).add(-1, 'M').startOf('d'),
      dayjs(versionInfo?.next_refresh_time).endOf('d'),
    ]
    return value
  }, [versionInfo])

  const { data, loading, run } = useRequest(
    () =>
      getWorkspaceOverview({
        workspace_id,
        start_time: rangeTime?.[0]?.startOf('day')?.format(format1),
        end_time: rangeTime?.[1]?.endOf('day')?.format(format1),
        ...(appState === '-1' ? {} : { version_status: appState }),
      }),
    { manual: true },
  )

  const onChange = (value: SearchType) => {
    if (value === SearchType.CUSTOM) {
      setRangeTime([null, null])
      return
    }
    const [startTime, endTime] = getTimeRange(value)
    setRangeTime([dayjs(startTime), dayjs(endTime)])
  }

  useEffect(() => {
    rangeTime && run()
  }, [appState, rangeTime, workspace_id])

  return (
    <div className='adapt:px-64 h-full overflow-scroll'>
      <Spin spinning={loading}>
        <div className='border-rd-12px bg-#fff p-24px'>
          <div className='flex flex-nowrap flex-items-center w-100% justify-around mb-32px'>
            {/* <StatisticWrapper num={data?.workspaceDimensionBaseInfo.admin_count} title="管理员" int="角色为管理员的账号数量" />
        <span className="w-1px h-40px bg-line"></span> */}
            <StatisticWrapper
              num={data?.workspaceDimensionBaseInfo.developer_count}
              title='开发者'
              int='角色为开发者的账号数量'
            />
            <span className='w-1px h-40px bg-line'></span>
            <StatisticWrapper
              num={data?.workspaceDimensionBaseInfo.use_users_count}
              title='使用者'
              int='角色为使用者的账号数量'
            />
            <span className='w-1px h-40px bg-line'></span>
            <StatisticWrapper
              num={data?.workspaceDimensionBaseInfo.published_app_count}
              title='已发布应用'
              int='正式发布过的应用数量'
            />
            <span className='w-1px h-40px bg-line'></span>
            <StatisticWrapper
              num={data?.workspaceDimensionBaseInfo.draft_app_count}
              title='未发布应用'
              int='未正式发布过的应用数量'
            />
          </div>
          <div className='flex justify-between'>
            <div className='flex flex-nowrap gap-12px flex-items-center'>
              <StyledSegmented
                options={options}
                defaultValue={SearchType.MONTH}
                onChange={value => onChange(value as SearchType)}
              />
              <RangePickerWrapper
                showTime={false}
                size='large'
                value={rangeTime}
                format='YYYY-MM-DD'
                className='h-40px border-rd-8px'
                onChange={setRangeTime}
                presets={rangePresets?.concat([
                  {
                    label: <DivWrapper>当前周期</DivWrapper>,
                    value: initRangeTime!,
                  },
                  {
                    label: (
                      <DivWrapper onClick={() => setRangeTime([null, null])}>
                        全部
                      </DivWrapper>
                    ),
                    value: [null, null],
                  },
                ])}
              />
            </div>
            <Select
              size='large'
              style={{ width: 130 }}
              value={appState}
              onChange={setAppState}
              placeholder='应用状态'
              options={[
                { label: '全部应用状态', value: '-1' },
                { label: '发布', value: 'PUBLISHED' },
                { label: '未发布', value: 'DRAFT' },
              ]}
            />
          </div>
          <Table
            className='mt-20px border-rd-8px border-b-none overflow-hidden'
            style={{ border: '1px solid rgba(225, 225, 229, 0.4)' }}
            pagination={false}
            columns={columns}
            scroll={{ x: 1500 }}
            dataSource={
              (data?.appDimensionBaseInfo || [])?.map((item: any, index) => ({
                key: index,
                ...item,
              })) as workspaceOverview['appDimensionBaseInfo']
            }
          />
        </div>
      </Spin>
    </div>
  )
}
