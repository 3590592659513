import { create } from 'zustand'

interface PublicStore {
  isPublic: boolean
  setIsPublic: (isPublic: boolean) => void
}

export const usePublicStore = create<PublicStore>(set => ({
  isPublic: false,
  setIsPublic: (isPublic: boolean) => {
    set({ isPublic })
  },
}))
