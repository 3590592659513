import { injectGlobal } from '@emotion/css'
import { rgba } from 'polished'
import { INPUT_VARIABLE_COLOR, NODE_VARIABLE_COLOR } from '../const'
import theme from '@/constants/theme'

injectGlobal`
  .ace_editor.ace-gray {
    color: ${theme.colors.font};
    background: transparent;
    /* margin-top: 2px; */
  }
  .ace_editor.ace-white {
    color: ${theme.colors.font};
    background: #fff;
  }
  .ace_placeholder {
      color: ${rgba(theme.colors.font_1, 0.6)}!important;
      transform: scale(1)!important;
      opacity: 1!important;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif!important;
      
    }
  .ace-gray {
    .ace_placeholder {
      color: ${rgba(theme.colors.font_1, 0.6)}!important;;
      padding: 0!important;
      top:0px!important;
      left: 4px!important;
      font-size: 12px!important;
      transform: translate(0,0);
      opacity: 1!important;
      
    }
  }
  .ace_context.ace_var_input {
    color: ${INPUT_VARIABLE_COLOR};
  }
  .ace_context.ace_var_node {
    color: ${NODE_VARIABLE_COLOR};
  }
  .ace_hidden-cursors .ace_cursor {
    opacity: 0;
  }
  

`
