import { useEffect, useMemo } from 'react'
import { useKeyPress } from 'reactflow'
import { useConfirmModal } from '@/components'
import { useFlowDraftStore } from '@/store'

interface NodeRemoveOptions {
  canRemove: boolean
}

export function useNodeRemoveShortcut(options: NodeRemoveOptions) {
  const { canRemove } = options
  const nodes = useFlowDraftStore(s => s.nodes)
  const deleteNodes = useFlowDraftStore(s => s.deleteNodes)
  const backspacePressed = useKeyPress('Backspace')
  const [modal] = useConfirmModal()

  const selectedNodes = useMemo(
    () => nodes.filter(item => item.selected),
    [nodes],
  )

  useEffect(() => {
    const ids = selectedNodes
      .filter(node => node.type !== 'START' && node.type !== 'END')
      .map(node => node.id)
    if (backspacePressed && canRemove && ids.length) {
      modal.open({
        title: '请确认是否要删除节点',
        onOk: () => {
          deleteNodes(ids)
          modal.close()
        },
        onCancel: () => {},
      })
    }
  }, [backspacePressed, selectedNodes, canRemove])
}
