import emptyImg from '@/assets/dataset/empty@2x.png'

export function EmptyContent(props: { emptyText?: string }) {
  const emptyText = props.emptyText || '暂无内容'
  return (
    <div className='w-100% h-100% min-h-200px flex flex-col flex-items-center flex-justify-center'>
      <img className='w-124px' src={emptyImg} alt='' />
      <span className='c-#626999 c-op-40'>{emptyText}</span>
    </div>
  )
}
