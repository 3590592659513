import { useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useRequest } from 'ahooks'
import styled from '@emotion/styled'
import { message } from 'antd'
import { HAS_REPEATED_ORDER_CODE, Version, OrderType } from '../const'
import { useVersionStore, useWorkspaceStore } from '@/store'
import payModalStar from '@/assets/order/payModalStar.png'
import { useScrollBar } from '@/hooks/useScrollBar'
import { createOrder } from '@/apis/order'
import { RepeatOrderModal } from '../PayPanel/components/RepeatOrderModal'
import { ListRow, LitmitedTitle, SubTitleRow } from './LimitedList'
import {
  advancedConfig,
  commonConfig,
  convertConfig,
  limitedTitleConfig,
  supportConfig,
} from './limitedConfig'

const columnIndexMap = {
  [Version.FREE]: 0,
  [Version.TEAM]: 1,
  [Version.ENTERPRISE]: 2,
  [Version.PERSONAL]: 4,
}

const ColumnHover = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    z-index: -1;
  }
`

export function LimitedPanel(props: {
  closeModal?: () => void
  showVersions?: string[]
}) {
  const panelRef = useRef(null)
  const [repeatOrderModalVisible, { toggle: toggleModal }] = useBoolean(false)

  const [currentHoveringColumn, setCurrentHoveringColumn] = useState<Version>()

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { versionInfo, versionConfigList, fetchVersionConfigList } =
    useVersionStore(state => ({
      versionInfo: state.versionInfo,
      versionConfigList: state.versionConfigList,
      fetchVersionConfigList: state.fetchVersionConfigList,
    }))

  const { runAsync: createOrderApi, loading: createOrderLoading } = useRequest(
    createOrder,
    { manual: true },
  )

  useEffect(() => {
    if (versionConfigList?.length === 0) {
      fetchVersionConfigList()
    }
  }, [versionConfigList])

  const columnHoveringOffset = useMemo(() => {
    if (currentHoveringColumn) {
      return `translate(${
        232 + columnIndexMap[currentHoveringColumn] * 272
      }px, 0px)`
    }
  }, [currentHoveringColumn])

  const { scrollRef } = useScrollBar()

  const {
    titleConfigList,
    commonConfigList,
    advancedConfigList,
    supportConfigList,
  } = useMemo(() => {
    const titleConfigList = limitedTitleConfig.map(titleConfig => {
      const config = versionConfigList.find(
        i => i.service_code === titleConfig.version,
      )
      return {
        ...titleConfig,
        title: config?.service_name || titleConfig.title,
        price: config?.price.toString() || titleConfig?.price,
      }
    })
    const commonConfigList = convertConfig(commonConfig, versionConfigList)
    const advancedConfigList = convertConfig(advancedConfig, versionConfigList)
    const supportConfigList = convertConfig(supportConfig, versionConfigList)

    return {
      titleConfigList,
      commonConfigList,
      advancedConfigList,
      supportConfigList,
    }
  }, [versionConfigList])

  const handleOrderCallback = async (orderId: string) => {
    setTimeout(() => {
      window.open(`/pay?orderId=${orderId}`)
    }, 200)
  }

  const handlePay = async (versionCode: Version, orderType: OrderType) => {
    // 之前的逻辑，先注释，商业化逻辑修改后，可能要重新启用
    // setTimeout(() => {
    //   window.open(
    //     `/pay?versionCode=${encodeURIComponent(
    //       versionCode,
    //     )}&orderType=${orderType}`,
    //   )
    // }, 200)
    const currVersionId = versionConfigList.find(
      v => v.service_code === versionCode,
    )?.id
    if (currVersionId) {
      // 2024-5-20 改版逻辑，直接订购 12 个月份的团队版
      const res = await createOrderApi({
        workspace_id: currentWorkspaceId,
        product_service_id: currVersionId,
        duration_of_service: 12,
        order_type: orderType,
      }).catch(err => {
        if (err.business && err.code === HAS_REPEATED_ORDER_CODE) {
          toggleModal()
        } else {
          message.warning(err.message)
        }
      })
      if (res) {
        props.closeModal?.()
        handleOrderCallback?.(res)
      }
    }
  }

  return (
    <div
      className='w-1120px bg-contain bg-center bg-no-repeat p-36px pr-6px relative h-100%'
      ref={panelRef}
    >
      <div className='h-100% relative'>
        {currentHoveringColumn && (
          <div
            style={{
              width: '272px',
              transform: columnHoveringOffset,
              height: '100%',
              position: 'absolute',
              top: 0,
              bottom: 0,
              pointerEvents: 'none',
            }}
          >
            <ColumnHover />
          </div>
        )}
        <div className='h-100% overflow-y-auto pr-30px' ref={scrollRef}>
          <div className='flex flex-wrap sticky top-0px z-1'>
            <div className='w-232px p-24px relative'>
              <img
                src={payModalStar}
                className='w-40px absolute left-30px top-24px'
              />
              <div className='font-size-24px line-height-36px font-600 mt-64px'>
                BetterYeah <br />
                版本选择
              </div>
            </div>
            {titleConfigList.map(config => (
              <LitmitedTitle
                createOrderLoading={createOrderLoading}
                versionInfo={versionInfo}
                key={config.title}
                setHoveringColumn={setCurrentHoveringColumn}
                config={config}
                onButtonClick={() =>
                  handlePay(
                    // TODO: item.service_code as Version
                    config.version,
                    config.version === versionInfo?.product_code
                      ? OrderType.RENEW
                      : OrderType.SUB,
                  )
                }
              />
            ))}
          </div>
          <SubTitleRow
            setHoveringColumn={setCurrentHoveringColumn}
            subTitle='核心能力'
          />
          {commonConfigList.map(config => (
            <ListRow
              setHoveringColumn={setCurrentHoveringColumn}
              key={config.title}
              config={config}
            />
          ))}
          <SubTitleRow
            setHoveringColumn={setCurrentHoveringColumn}
            subTitle='外部应用能力'
          />
          {advancedConfigList.map(config => (
            <ListRow
              setHoveringColumn={setCurrentHoveringColumn}
              key={config.title}
              config={config}
            />
          ))}
          <SubTitleRow
            setHoveringColumn={setCurrentHoveringColumn}
            subTitle='服务支持'
          />
          {supportConfigList.map(config => (
            <ListRow
              setHoveringColumn={setCurrentHoveringColumn}
              key={config.title}
              config={config}
            />
          ))}
        </div>
      </div>
      <RepeatOrderModal
        repeatOrderModalVisible={repeatOrderModalVisible}
        toggleModal={toggleModal}
        handleToOrderListCallback={props.closeModal}
      />
    </div>
  )
}
