export const DOC_URL = import.meta.env.VITE_DOC_URL

export const WEBSITE_URL = import.meta.env.VITE_HOME_WEBSITE_URL

// 隐私政策
export const PRIVACY_URL =
  'https://ai-docs.betteryeah.com/%E5%8D%8F%E8%AE%AE/%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.html'

// 服务协议
export const SERVICE_URL =
  'https://ai-docs.betteryeah.com/%E5%8D%8F%E8%AE%AE/%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.html'
