import { useRef, type FC, useEffect } from 'react'
import { Tooltip, Upload, message } from 'antd'
import type { RcFile, UploadFile } from 'antd/es/upload'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import { random } from 'lodash-es'
import OSS from 'ali-oss'
import classNames from 'classnames'
import { useRequest } from 'ahooks'
import type { UploadFileStatus } from 'antd/es/upload/interface'
import styled from '@emotion/styled'
import { rgba } from 'polished'

// import { checkFileSize, getFileSuffix } from '../utils'
// import { FileIconMap } from '../constant'
import { IconFont } from '@/components'
import type { STSTokenResponse } from '@/apis/oss'
import { getSTSToken } from '@/apis/oss'
import { useWorkspaceStore, useUserStore } from '@/store'

export interface FileItem {
  oss_path: string
  mimetype: string
  file_name: string
  file_size: number
  file_status: UploadFileStatus
}
interface ImageUploadProps {
  hashPath: string
  onChange?: (list: FileItem[]) => void
}
const StyledUpload = styled(Upload.Dragger)`
  .ant-upload-drag {
    background: ${({ theme }) => rgba(theme.colors.bg_3, 0.06)};
  }
`

function checkFileSize(fileSize: number, limit: number) {
  return fileSize / 1024 / 1024 < limit
}

export const ImageUpload: FC<ImageUploadProps> = ({ onChange, hashPath }) => {
  const getSTSTokenPromise = useRef<Promise<STSTokenResponse>>()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const user = useUserStore(state => state.user)
  const beforeUpload = (file: RcFile) => {
    if (!checkFileSize(file.size, 10)) {
      message.warning('文件大小不能超过10MB, 请修改后重新上传')
      return Upload.LIST_IGNORE
    }
    return true
  }

  const { runAsync: getSTSTokenApi } = useRequest(getSTSToken, { manual: true })
  useEffect(() => {
    getSTSTokenPromise.current = getSTSTokenApi()
  }, [])

  const uploadFileToOss = async (options: UploadRequestOption) => {
    const stsTokenData = await getSTSTokenPromise.current

    if (!stsTokenData) {
      message.error('获取上传凭证失败，请稍后重试')
      return
    }

    const { file, onSuccess, onError } = options
    return new Promise((resolve, reject) => {
      const filePath = `/ai/${currentWorkspaceId}/pay/${hashPath}/__${
        user?.userId ?? '-1000'
      }_${random(1, 9999)}__${(file as RcFile).name}`
      const mimeType = (file as RcFile).type

      const ossClient = new OSS({
        region: 'oss-cn-hangzhou',
        accessKeyId: stsTokenData!.access_key_id,
        accessKeySecret: stsTokenData!.access_key_secret,
        stsToken: stsTokenData!.security_token,
        bucket: import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET,
      })

      // 随机步长更新进度，模拟上传进度，上限95, 带两位小数
      let percent = 0
      const timer = setInterval(() => {
        percent = percent + random(1, 5, true)
        if (percent >= 95) {
          clearInterval(timer)
        }
        // onProgress?.({ percent })
      }, 500)

      ossClient
        .put(filePath, file)
        .then(() => {
          const response = {
            mimeType,
            fileName: (file as RcFile).name,
            filePath,
          }
          onSuccess?.(response)
          resolve(response)
        })
        .catch(err => {
          onError?.(err)
          reject(err)
        })
        .finally(() => {
          clearInterval(timer)
        })
    })
  }

  const renderFileStatus = (file: UploadFile, remove: () => void) => {
    switch (file.status) {
      case 'error':
      case 'done':
        return (
          <IconFont
            name='shanshu'
            onClick={remove}
            className='text-font_1 hover:text-error cursor-pointer'
          />
        )
      case 'uploading':
        // return (
        //   <span className="flex items-center">
        //     <span className="font-400 text-font_1">{formatFileSize(file.size ?? 0)}</span>
        //     <span className="font-500 ml-8">{(file.percent ?? 0 / 100).toFixed(2)}%</span>
        //   </span>
        // )
        return '上传中...'
      default:
        return null
    }
  }

  return (
    <div>
      <StyledUpload
        multiple
        accept='.jpg,.jpeg,.png'
        beforeUpload={beforeUpload}
        customRequest={uploadFileToOss}
        onChange={list => {
          const fileList = list.fileList.map(item => {
            const file_name = item.response?.fileName ?? ''
            return {
              oss_path: item.response?.filePath as string,
              mimetype: item.response?.mimeType as string,
              file_name:
                file_name.length > 50 ? file_name.slice(0, 50) : file_name,
              file_size: item.size ?? 0,
              file_status: item.status as UploadFileStatus,
            }
          })
          onChange?.(fileList)
        }}
        itemRender={(_originNode, file, _fileList, actions) => {
          return (
            <div
              title={file.name}
              className={classNames(
                'overflow-hidden relative mt-8px bg-white border border-solid border-line rounded-8px',
                {
                  'hover:bg-bg_3 hover:bg-op-8 cursor-pointer':
                    file.status === 'done',
                },
              )}
            >
              {/* {file.percent !== 100 && <div className="bg-primary bg-opacity-40 h-full position-absolute" style={{ width: `${file.percent}%`, transition: 'all 0.25s ease-in-out' }}></div>} */}
              <div className='py-10 px-16 flex items-center justify-between'>
                <span className='flex items-center w-230px'>
                  {file.status === 'done' && (
                    <IconFont name='chenggongbaocun'></IconFont>
                  )}
                  {file.status === 'error' ? (
                    <Tooltip title='文件上传失败'>
                      <span className='text-error ml-8 text-ellipsis overflow-hidden inline-block text-nowrap'>
                        {file.name}
                      </span>
                    </Tooltip>
                  ) : (
                    <span className='ml-8 text-ellipsis overflow-hidden inline-block text-nowrap'>
                      {file.name}
                    </span>
                  )}
                </span>
                {renderFileStatus(file, actions.remove)}
              </div>
            </div>
          )
        }}
      >
        <div className='py-46px w-456px'>
          <p className='text-14px line-height-14px'>
            拖拽凭证图片到这里，或者{' '}
            <span className='text-primary'>选择文件</span>
          </p>
          <p className='text-12px text-font_1 mt-8'>
            请上传付款凭证截图或图片，支持JPG、JPEG、PNG
          </p>
          <p className='text-12px text-font_1 mt-8'>
            最多上传10张图片，每张图片不超过5M。
          </p>
        </div>
      </StyledUpload>
    </div>
  )
}
