import type { ReactNode } from 'react'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty, isNil, isString } from 'lodash-es'
import type { FormInstance, MenuProps } from 'antd'
import { Dropdown, Form, Popover, message } from 'antd'
import copy from 'copy-to-clipboard'
import { useMemoizedFn, useUnmount } from 'ahooks'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Button, IconFont, Markdown } from '@/components'
import { isEmptyString } from '@/pages/flowPage/util'
import { CodeEditor } from '@/features/editor'
import empty from '@/assets/empty.png'
import loading from '@/assets/loading.png'

import { useApplicationStore, useWorkspaceStore } from '@/store'
import useEventSource from '@/hooks/useEventSource'
import { FlowStatus } from '@/apis/flow'
import type { TaskResult } from '../type'
import { LLMNodePanelContext, TaskStatus } from '../type'
import {
  LLMMessageStructType,
  checkLLMMessage,
  getModelMessageStructTypeByChannel,
} from '@/features/nodes/utils/llm'
import { BaiduContextExampleValue, LLMContextType } from '../../const'
import { CodeBlock } from '@/components/CodeBlock'
import { ModelSetting } from '../../components/ModelSetting'
import type { ModalSettingValue } from '../../components/type'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { tokenStorage } from '@/utils/storage.ts'
import { ActionButton } from './StyleComponent'

export const statusList: Record<string, { text?: string; icon?: ReactNode }> = {
  start: {
    text: '点击运行，惊喜即将发生',
    icon: <img className='w-124px' src={empty} />,
  },
  loading: {
    text: '生成中...',
    icon: <img className='w-124px' src={loading} />,
  },
  complete: {},
  abort: {},
  error: {},
}

const Empty = memo((props: { status: string }) => {
  const { status } = props

  return (
    <div
      style={{ color: 'rgba(98, 105, 153, 0.4)' }}
      className='h-full flex flex-justify-center flex-items-center flex-col text-14px'
    >
      {statusList[status].icon || ''}
      {statusList[status].text}
    </div>
  )
})

interface OutputProps {
  id: string
  header?: ReactNode
  durations?: number
  text?: string
  status: string
  errorMsg?: string
  readOnly?: boolean
  onCopy: () => void
}

export const Output = memo((props: OutputProps) => {
  const { id, header, durations, text, status, errorMsg, onCopy } = props

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const scrollToBottom = useMemoizedFn(() => {
    const viewport = scrollRef.current?.osInstance()?.elements().viewport
    viewport?.scroll({
      top: viewport.scrollHeight + 200,
    })
  })

  const isEmptyType = useMemo(() => {
    return isNil(text) || isEmptyString(text)
  }, [text])

  const isCodeType = useMemo(() => {
    try {
      JSON.parse(text ?? '')
      return true
    } catch {}
    return false
  }, [text])

  const isMarkdownType = useMemo(() => {
    return !isCodeType && isString(text)
  }, [isEmptyType, text])

  const showContent = useMemo(() => {
    if (errorMsg) {
      return (
        <div className='p-20px'>
          <div className='p-20px flex flex-items-center flex-justify-center b-rd-8px bg-#ff5219 bg-op-8'>
            <IconFont name='cuowu' className='text-16px m-r-8 flex-none' />
            <span className='c-#99310f'>{errorMsg}</span>
          </div>
        </div>
      )
    }

    if (isEmptyType) {
      if (status === 'abort') {
        return <p className='px-20px color-#8D8D99'>运行已终止...</p>
      }

      return (
        <div className='text-#8D8D99 h-80% flex flex-center'>
          <Empty status={status} />
        </div>
      )
    }

    if (isMarkdownType) {
      return (
        <OverlayScrollbarsComponent
          ref={scrollRef}
          className='p-20px h-full overflow-auto'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <Markdown text={text ?? ''} />

          {status === 'abort' && (
            <p className='mt-8px color-#8D8D99'>运行已终止...</p>
          )}
        </OverlayScrollbarsComponent>
      )
    }

    if (isCodeType) {
      return (
        <div className='p-20px'>
          <CodeEditor readOnly value={props?.text} mode='json' />
        </div>
      )
    }

    return null
  }, [status, errorMsg, isMarkdownType, isCodeType, isEmptyType, text])

  useEffect(() => {
    scrollToBottom()
  }, [text])

  return (
    <div
      className='grow-1 basis-400px min-w-400px border-rd-8px h-full flex flex-col bg-#fff relative overflow-hidden [&:hover_.operator-button]:opacity-100'
      style={{
        border: '1px solid rgba(225, 225, 229, 0.6)',
      }}
    >
      {id === 'current' && (
        <div className='absolute w-64px h-40px bg-#7B61FF text-#fff right-[-24px] top-[-12px] flex items-end justify-center rotate-45'>
          <span className='pb-5px text-12px'>当前</span>
        </div>
      )}

      {header}

      <div className='text-14px h-full flex-auto overflow-auto'>
        {showContent}
      </div>

      <div className='h-50px px-20px pb-20px flex'>
        {durations && (
          <span className='mt-auto text-#8D8D99'>
            {Number(durations).toFixed(3)}s
          </span>
        )}
        {!isEmptyType && (
          <ActionButton
            className='ml-auto operator-button opacity-0 flex flex-center text-12px rounded-6px px-11px h-30px'
            onClick={onCopy}
          >
            <IconFont name='copy' className='text-14px mr-4px' />
            复制
          </ActionButton>
        )}
      </div>
    </div>
  )
})

const ExecutePanelMenu: MenuProps['items'] = [
  {
    key: 'use',
    label: <div className='px-12px h-36px flex items-center'>单一模型调试</div>,
  },
  {
    key: 'remove',
    label: <div className='px-12px h-36px flex items-center'>移除</div>,
  },
]

interface ExecuteOutputProps {
  form?: FormInstance
  id: string
  flowId: string
  activatedNodeId?: string
  showConfig?: boolean
  config?: ModalSettingValue
  variableValue: Record<string, any>
  resultInfo?: Record<string, any>
  getInputs: () => Record<string, any>
  onEnd: () => void
  onUse?: (id: string) => void
  onRemove?: (id: string) => void
  onChange: (value: ModalSettingValue, id: string) => void
}

export const ExecuteOutput = memo((props: ExecuteOutputProps) => {
  const {
    form,
    id,
    flowId,
    activatedNodeId,
    showConfig,
    config,
    variableValue,
    resultInfo,

    getInputs,
    onEnd,
    onUse,
    onRemove,
    onChange,
  } = props

  const {
    onUseInfo,
    clearInfo,
    subscribeRun,
    getTaskResult,
    getTaskStatus,
    setTaskStatus,
    setTaskResult,
  } = useContext(LLMNodePanelContext)

  const workspaceId = useWorkspaceStore(s => s.currentWorkspaceId)
  const applicationId = useApplicationStore(s => s.currentApplicationId)

  const [status, setStatus] = useState<TaskStatus>(TaskStatus.start)
  const [result, setResult] = useState<TaskResult>({})

  const handleStatusChange = useMemoizedFn((newStatus: TaskStatus) => {
    setStatus(newStatus)
    setTaskStatus(id, newStatus)
  })

  const handleResultChange = useMemoizedFn((newResult: TaskResult) => {
    setResult(newResult)
    setTaskResult(id, newResult)
  })

  const { run: fetchResult, cancel: cancelResultFetch } = useEventSource<any>(
    '/v1/common/llm/completions',
    {
      Authorization: tokenStorage.get() || '',
      'Workspace-Id': workspaceId,
      'Application-Id': applicationId || '',
    },
    {
      onConnecting: () => {
        handleStatusChange(TaskStatus.loading)
        handleResultChange({
          ...result,
          result: '',
          errorMsg: '',
        })
      },
      onMessage: (_, content) => {
        if (content.message_type === 'error') {
          handleResultChange({
            ...result,
            duration: content.duration_time,
            errorMsg: content.message,
          })
        } else {
          handleResultChange({
            ...result,
            result: content.message,
            duration: content.duration_time,
            errorMsg: '',
          })
        }
      },
      onClose: () => {
        handleStatusChange(TaskStatus.complete)
        handleResultChange({
          ...result,
        })
        onEnd()
      },
      onError: (_, error) => {
        handleStatusChange(TaskStatus.error)
        onEnd()
        error?.message && message.error(error.message)
        handleResultChange({
          result: undefined,
          errorMsg: error?.message || '运行失败',
        })
      },
    },
  )

  const mergeConfig = useMemoizedFn(() => {
    if (id === 'current') return getInputs()

    const executeConfig: Record<string, any> = {
      ...getInputs(),
      ...config,
    }

    try {
      executeConfig.plugin = {
        json_mode: executeConfig.outputType === 'json',
      }
      delete executeConfig.outputType
    } catch {}

    return executeConfig
  })

  const onCheck = useMemoizedFn(() => {
    if (id !== 'current' && !config?.model) {
      message.error('请选择模型后运行')
    }
  })

  const onRun = useMemoizedFn(async () => {
    if (status === 'loading') {
      handleStatusChange(TaskStatus.abort)
      onEnd()
      cancelResultFetch()
      return
    }

    const temp = mergeConfig()

    try {
      temp.stream = true
      await fetchResult({
        ...temp,
        node_id: activatedNodeId,
        variable: variableValue,
        flow_id: flowId,
      })
    } catch (err) {
      console.error(err)
    }
  })

  const handleCopy = useMemoizedFn(() => {
    copy(result.result || '')
    message.success('复制成功！')
  })

  const handleChange = useMemoizedFn((newValue: ModalSettingValue) => {
    onChange(newValue, id)
  })

  const handleMenuClick = useMemoizedFn((menu: any) => {
    if (menu.key === 'remove') {
      clearInfo(id)
      onRemove?.(id)
      return
    }

    if (menu.key === 'use') {
      if (isEmpty(config)) {
        message.error('请先选择模型')
        return
      }
      onUseInfo(id)
      onUse?.(id)
    }
  })

  const contextType = Form.useWatch(['inputs', 'context_type'], form)
  const messages = Form.useWatch(['inputs', 'messages'], form)
  const jsonMessages = Form.useWatch(['inputs', 'pre_defined_messages'], form)

  const usedMessage = useMemo(() => {
    if (contextType === LLMContextType.JSON_VARIABLE) {
      return jsonMessages
    }
    return messages
  }, [contextType, messages, jsonMessages])

  const hasMessages = useMemo(() => {
    return !isEmpty(usedMessage)
  }, [contextType, messages, jsonMessages])

  const diffWaring = useMemo(() => {
    if (!config || !config.model) return
    const channelType = getModelMessageStructTypeByChannel(config.channel)

    if (channelType === LLMMessageStructType.NO_CONTEXT && hasMessages) {
      return (
        <div className='flex justify-center w-320px'>
          <IconFont
            name='jingzhi'
            className='flex-none text-14px mr-6px mt-2px'
          />
          <div className='leading-18px'>
            此模型没有「上下文」字段，你的输入不会对此模型生效
          </div>
        </div>
      )
    }

    if (typeof usedMessage === 'string') {
      return
    }

    if (channelType === LLMMessageStructType.LIKE_BAIDU && hasMessages) {
      if (checkLLMMessage(usedMessage)) return

      return (
        <div>
          <div className='flex justify-center w-320px'>
            <IconFont
              name='jingzhi'
              className='flex-none text-14px mr-6px mt-2px'
            />
            <div className='leading-18px'>
              此模型「上下文」字段要求，和你的输入不一致
            </div>
          </div>
          <CodeBlock title='示例' lang='json' str={BaiduContextExampleValue} />
        </div>
      )
    }
  }, [usedMessage, hasMessages, config])

  useEffect(() => {
    return subscribeRun(id, onCheck, onRun)
  }, [])

  useEffect(() => {
    if (!isEmpty(resultInfo)) {
      setResult({ result: resultInfo.response })
      setStatus(TaskStatus.complete)
    } else {
      setResult(getTaskResult(id) ?? {})
      setStatus(getTaskStatus(id) ?? TaskStatus.start)
    }
  }, [resultInfo, showConfig])

  useUnmount(() => {
    cancelResultFetch()
  })

  const extraHeader = useMemo(() => {
    if (!showConfig) return null
    return (
      <div className='h-32px m-20px mb-0px flex shrink-0'>
        <ModelSetting
          value={config}
          onChange={handleChange}
          placement='bottomLeft'
        />

        {diffWaring && (
          <Popover content={diffWaring} placement='bottom'>
            <div className='w-32px h-32px ml-4px rounded-6px cursor-pointer flex flex-center hover:bg-[rgba(98,105,153,0.08)]'>
              <IconFont name='jingzhi' />
            </div>
          </Popover>
        )}

        <Dropdown
          disabled={id === 'current'}
          overlayClassName='[&_.ant-dropdown-menu]:p-4px!'
          menu={{ items: ExecutePanelMenu, onClick: handleMenuClick }}
          placement='bottomRight'
        >
          <Button
            disabled={id === 'current'}
            type='text'
            className='h-32px! px-8px! ml-auto'
          >
            <IconFont name='gengduo' />
          </Button>
        </Dropdown>
      </div>
    )
  }, [id, config, showConfig, diffWaring])

  const durations = useMemo(() => {
    if (status === 'loading') return
    return result.duration
  }, [result])

  return (
    <Output
      id={showConfig ? id : ''}
      header={extraHeader}
      durations={durations}
      text={result.status === FlowStatus.FAIL ? '运行失败' : result.result}
      status={status}
      errorMsg={result.errorMsg}
      onCopy={handleCopy}
    />
  )
})
