import classNames from 'classnames'
import { IconFont } from '@/components'

interface KeywordsProps {
  value: string[]
  deleteEnable?: boolean
  highlightValue?: string[]
  onValueRemove?: (keyword: string) => void
  className?: string
}

export default function Keywords(props: KeywordsProps) {
  const {
    value = [],
    highlightValue = [],
    deleteEnable,
    onValueRemove,
    className,
  } = props

  return value.map(item => {
    return (
      <div
        key={item}
        style={{
          background:
            'linear-gradient(0deg, rgba(98, 105, 153, 0.04), rgba(98, 105, 153, 0.04)), #FFFFFF',
        }}
        className={classNames(
          'flex items-center b-1 rd-4px px-8 py-5 shrink-0',
          highlightValue.includes(item)
            ? 'b-#00C853 c-#00C853 b-op-20'
            : 'b-line',
          className,
        )}
      >
        <span className='line-height-14px! text-14px'>{item}</span>
        {deleteEnable && (
          <IconFont
            className='c-font_1 c-op-60 ml-8 cursor-pointer hover:c-font'
            name='guanbi'
            onClick={() => onValueRemove?.(item)}
          />
        )}
      </div>
    )
  })
}
