import { useLockFn, useRequest } from 'ahooks'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Dropdown, Spin, message } from 'antd'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { findIndex } from 'lodash-es'
import { AppLogo, Button, IconFont, Input, usePageModal } from '@/components'
import type { AgentDataset } from '@/apis/agent.ts'
import {
  createAgentDataset,
  createDatasetByTemplate,
  fetchAgentDatasetList,
  unbindAgentDataset,
  updateAgentDataset,
} from '@/apis/agent.ts'
import type { ApplicationBodyType } from '@/apis/application.ts'
import {
  fetchEmbeddingModelList,
  getDataStoreList,
} from '@/apis/datastore/api.ts'
import { colors } from '@/constants/theme'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'

import KnowledgeDatasetSelectModal from '../KnowledgeDatasetSelectModal'
import { useModal } from '@/hooks/useModal'
import { getPartitionTypeName } from '../../util'
import Empty from '@/features/database/components/Empty.tsx'
import { CreateDataStoreCard } from '@/pages/datastores/CreateDataStoreCard'
import { PartitionCategoryType } from '@/apis/datastore/types'
import {
  CreateDataStoreDatasetModal,
  getPartitionAvatarInfo,
} from '@/features/datastore/CreateDataStoreModal/DatasetModal'
import { CreateDataStoreDocumentsModal } from '@/features/datastore/CreateDataStoreModal/DocumentsModal'
import { useDataStoreCreate } from '@/features/datastore/hooks/useDataStoreCreate'
import KnowledgeDatasetSettingModal from '../KnowledgeDatasetSettingModal'
import KnowledgeDocumentSettingModal from '../KnowledgeDocumentSettingModal'
import { useAgentEdit } from '../../provider/AgentEditProvider'
import { DEFAULT_AGENT_DATASET_CONFIG } from '@/features/datastore/constant'
import { useWorkspaceStore } from '@/store'
import { AGENT_FROM, AGENT_KNOWLEDGE_ROUTER_MAP } from '../../constant/base'

export interface KnowledgeListProps {
  flowInfo: ApplicationBodyType
  onUpdate?: () => void
}

export function KnowledgeDatasetList(props: KnowledgeListProps) {
  const { flowInfo, onUpdate } = props

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const pageModal = usePageModal()

  const navigate = useNavigate()

  const { onCreate: onDataStoreCreateApi, loading: datastoreCreateLoading } =
    useDataStoreCreate()

  const [createDataStoreDocumentsModal] = useModal(
    CreateDataStoreDocumentsModal,
    {
      okButtonProps: {
        loading: datastoreCreateLoading,
      },
    },
  )

  const [knowledgeDocumentSettingModal] = useModal(
    KnowledgeDocumentSettingModal,
  )

  const { data: embeddingModelList } = useRequest(fetchEmbeddingModelList)

  const [createDatastoreDatasetModal] = useModal(CreateDataStoreDatasetModal, {
    okButtonProps: { loading: datastoreCreateLoading },
    embeddingModelList,
  })

  const [knowledgeDatasetSelectModal] = useModal(KnowledgeDatasetSelectModal)

  const [knowledgeDatasetSettingModal] = useModal(KnowledgeDatasetSettingModal)

  const [isInited, setIsInited] = useState(false)
  const [searchInfo, setSearchInfo] = useState({
    partition_name: '',
  })

  const onSearchInfoChange = (params: Record<string, any>) => {
    setSearchInfo({
      ...searchInfo,
      ...params,
    })
  }

  const [agentDatasetList, setAgentDatasetList] = useState<AgentDataset[]>([])

  const { id: agentId } = useParams()

  const {
    data: datastoreList,
    refresh: refreshDatastoreList,
    runAsync: fetchAsyncDatastoreList,
  } = useRequest(getDataStoreList)

  const { runAsync: createAgentDatasetApi } = useRequest(createAgentDataset, {
    manual: true,
  })
  const { runAsync: updateAgentDatasetApi } = useRequest(updateAgentDataset, {
    manual: true,
  })

  const { runAsync: unbindAgentDatasetApi } = useRequest(unbindAgentDataset, {
    manual: true,
  })

  const { runAsync: createAgentDatasetByTemplate } = useRequest(
    createDatasetByTemplate,
    { manual: true },
  )

  const { updateStatistics, ruleRef, refreshApplicationInfo } = useAgentEdit()

  const agentDatasetListId = useMemo(() => {
    return agentDatasetList.map(item => item.partition_id)
  }, [agentDatasetList])

  const { refresh: refreshAgentDatasetList, loading: datasetLoading } =
    useRequest(fetchAgentDatasetList, {
      defaultParams: [flowInfo.flowId, flowInfo.versionId],
      onSuccess: res => {
        setAgentDatasetList(res)
      },
      onFinally: () => {
        setIsInited(true)
      },
    })

  const dataSource = useMemo(() => {
    const { partition_name } = searchInfo
    if (partition_name) {
      return agentDatasetList.filter(item => {
        return item?.partition_name?.includes(partition_name)
      })
    }
    return agentDatasetList
  }, [agentDatasetList, searchInfo, datastoreList])

  const handleDatasetChange = async (
    action: 'add' | 'update' | 'delete',
    params: any & { partition_id?: number },
  ) => {
    if (action === 'add') {
      await createAgentDatasetApi({
        flow_id: flowInfo.flowId,
        version_id: flowInfo.versionId,
        description: DEFAULT_AGENT_DATASET_CONFIG.description,
        is_enable: true,
        num: DEFAULT_AGENT_DATASET_CONFIG.num,
        threshold: DEFAULT_AGENT_DATASET_CONFIG.threshold,
        hit_strategy: 1,
        ranking_strategy: 1,
        show_knowledge_url: false,
        ...params,
        ...(params.partition_category === PartitionCategoryType.QA && {
          random_result: false,
        }),
      })
      message.success('添加成功')
    } else if (action === 'update') {
      const req = {
        flow_id: flowInfo.flowId,
        version_id: flowInfo.versionId,
        ...params,
      }
      await updateAgentDatasetApi(req)
      message.success('更新成功')
    } else if (action === 'delete') {
      await unbindAgentDatasetApi({
        flow_id: flowInfo.flowId,
        version_id: flowInfo.versionId,
        partition_id: params.partition_id,
      })
      message.success('移除成功')
    }
    onUpdate?.()
    refreshAgentDatasetList()
    refreshApplicationInfo()
    ruleRef.current?.refreshRuleConfig()
    updateStatistics()
  }

  const addAgentDataStore = useLockFn(async () => {
    const res = await fetchAsyncDatastoreList()
    knowledgeDatasetSelectModal.open({
      onCreate: actionType => {
        const name = actionType === PartitionCategoryType.QA ? '问答' : '文档'
        const title = `新建${name}知识库`
        createDatastoreDatasetModal.open({
          title,
          defaultValue: {
            partition_name: `未命名${name}知识库${res!.partitions?.length + 1}`,
            partition_category: actionType ?? PartitionCategoryType.Document,
          },
          onFinish: async value => {
            const res = await onDataStoreCreateApi(value)
            createDatastoreDatasetModal.close()
            knowledgeDatasetSelectModal.close()
            await handleDatasetChange('add', {
              partition_id: res.partition_id,
              partition_category: actionType,
            })
          },
        })
      },
      datasetList: res!.partitions,
      currentDataset: agentDatasetListId,
      onChange: async (type, params) => {
        if (type === 'delete') {
          await handleDatasetChange('delete', params)
        } else {
          await handleDatasetChange('add', params)
        }
      },
    })
  })

  const goDataset = (partition_id: number) => {
    const url = `/agent/${agentId}/design/knowledge/datastores/${partition_id}`
    navigate(url, {
      state: {
        [AGENT_FROM]: AGENT_KNOWLEDGE_ROUTER_MAP.DATASTORE,
      },
      replace: true,
    })
  }

  const createDocumentsFinish = useLockFn(async (value, type) => {
    const name = type === PartitionCategoryType.QA ? '问答' : '文档'

    const partitionName = `${flowInfo.name || '未命名'}${name}知识库`
    if (!value) {
      await createAgentDatasetByTemplate({
        flow_id: flowInfo.flowId,
        version_id: flowInfo.versionId,
        type: 1,
      })
      refreshDatastoreList()
      refreshAgentDatasetList()
      createDataStoreDocumentsModal.close()
    } else {
      const res = await onDataStoreCreateApi({
        ...value,
        partition: {
          ...value?.partition,
          partition_name: partitionName,
          partition_category: type,
          ...getPartitionAvatarInfo(type),
        },
      })
      await handleDatasetChange('add', {
        partition_id: res.partition_id,
        partition_category: type,
      })
      createDataStoreDocumentsModal.close()
      goDataset(res.partition_id)
    }
  })

  const handleCreateDocuments = (type?: PartitionCategoryType) => {
    const title = '添加文档'
    createDataStoreDocumentsModal.open({
      title,
      datastoreType: type,
      hasDataset: !!datastoreList?.partitions?.length,
      onFinish: value => createDocumentsFinish(value, type),
    })
  }

  const menu = useCallback(
    (id: number) => {
      return {
        items: [
          {
            key: 'delete',
            label: (
              <span className='block rounded-4px font-400 cursor-pointer hover:bg-bg_3 hover:bg-op-8 w-88px px-12 py-9 c-font_red'>
                移除
              </span>
            ),
          },
        ],
        onClick: () => {
          handleDatasetChange('delete', { partition_id: id })
        },
      }
    },
    [handleDatasetChange],
  )

  if (!agentDatasetList?.length && isInited) {
    return (
      <div className='mt-12px mx-24px h-full flex flex-col'>
        <h2 className='flex items-center h-36px text-16px font-medium text-font shrink-0'>
          知识
        </h2>
        <div className='flex-grow-1 px-24p flex flex-col justify-center items-center'>
          <div className='flex-grow-1 px-24p flex flex-col justify-center items-center'>
            <div>
              <CreateDataStoreCard
                cardClassName='bg-#FAFAFC! flex-1'
                create={handleCreateDocuments}
              />
              {!!datastoreList?.partitions.length && (
                <span
                  className='c-primary cursor-pointer flex-center mt-24'
                  onClick={() => {
                    addAgentDataStore()
                  }}
                >
                  <IconFont name='action-link' className='mr-3' />
                  <span>选择已有知识库</span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='mt-12px mx-24px flex flex-col relative h-100%'>
      <h2 className='flex items-center h-36px text-16px font-medium text-font shrink-0'>
        知识
        {isInited && (
          <>
            <Input
              className='rounded-8px ml-auto w-140px'
              placeholder='搜索'
              prefix={<IconFont className='text-16px' name='search' />}
              onChange={event => {
                const searchKey = event.target.value
                onSearchInfoChange({ partition_name: searchKey })
              }}
            />
            <span className='mx-12 px-4 h-16px border-right after:right-4 after:border-line' />
            <Button
              className='!px-10px'
              type='primary'
              onClick={addAgentDataStore}
            >
              <IconFont name='add' />
              <span className='ml-4'>添加知识库</span>
            </Button>
            <Button
              className='ml-12 !px-9 rounded-8px shrink-0'
              onClick={() => {
                knowledgeDatasetSettingModal.open({
                  datasetConfig:
                    ((flowInfo?.config as any).rule
                      ?.dataset_global_config as any) || {},
                  onOk: async values => {
                    await handleDatasetChange('update', {
                      ...values,
                    })
                    knowledgeDatasetSettingModal.close()
                  },
                })
              }}
            >
              设置
            </Button>
          </>
        )}
      </h2>
      <OverlayScrollbarsComponent
        className='flex-1 mt-20 pb-36px'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        <Spin
          spinning={datasetLoading}
          style={{
            minHeight: '400px',
          }}
        >
          {dataSource.length ? (
            dataSource.map(item => {
              const partitionTypeName = getPartitionTypeName(
                item.partition_category,
              )
              return (
                <div
                  key={item.partition_id}
                  className='flex overflow-hidden px-16px py-18px hover:bg-#F2F2F7 w-100% cursor-pointer rounded-4px group'
                  onClick={() => {
                    goDataset(item.partition_id)
                  }}
                >
                  <div className='flex-1 flex overflow-hidden'>
                    <AppLogo
                      className='!w-40px !h-40px'
                      type='icon'
                      color={item?.partition_icon_color ?? colors[0]}
                      value={item?.partition_icon ?? 'app'}
                    />
                    <div className='ml-12px flex flex-col flex-1 justify-center'>
                      <p className=' overflow-hidden font-500 flex items-center flex-1 w-25vw'>
                        <span className='truncate c-#17171D text-16px/18px mr-8px'>
                          {item?.partition_name}
                        </span>
                        <span className='b-1  b-solid b-#E1E1E5 rounded-4px text-12px/14px px-4px py-2px c-font_1 text-nowrap'>
                          {partitionTypeName}
                        </span>
                      </p>
                      <p className='mt-6px text-font_1'>
                        {item?.partition_file_count ?? '-'} 文档
                      </p>
                    </div>
                  </div>
                  <div
                    className='flex items-center'
                    onClick={e => e?.stopPropagation()}
                  >
                    <div
                      className='group-hover:op-100 op-0 text-16px ml-8 mr-18px op-0 cursor-pointer rounded-4px w-24px h-24px hover:bg-bg_3 hover:bg-op-12 c-#626999 c-op-60 flex-center'
                      onClick={() =>
                        pageModal.show({
                          url: `/datastores/${currentWorkspaceId}/${item.partition_id}/documents?independent=1`,
                        })
                      }
                    >
                      <IconFont name='super-link' />
                    </div>

                    <div
                      className='w-28px h-28px text-16px text-[rgba(98,105,153,0.6)] flex-center'
                      onClick={async () => {
                        const idx = findIndex(
                          agentDatasetList,
                          o => o.partition_id === item.partition_id,
                        )
                        if (idx === -1) {
                          message.error('系统错误，请联系管理员')
                        }
                        const config = agentDatasetList[idx]
                        knowledgeDocumentSettingModal.open({
                          currentDataset: config,
                          onOk: values => {
                            handleDatasetChange('update', {
                              ...values,
                              partition_id: item.partition_id,
                              dataset_id: item.id,
                            })
                            knowledgeDocumentSettingModal.close()
                          },
                        })
                      }}
                    >
                      <IconFont
                        className='group-hover:text-primary'
                        name='kongjianshezhi'
                      />
                    </div>
                    <Dropdown
                      overlayClassName='[&>.ant-dropdown-menu]:rounded-8px'
                      menu={menu(item.partition_id)}
                      trigger={['click']}
                      placement='bottomRight'
                    >
                      <span className='ml-12 flex-center w-32px h-32px text-16px text-[rgba(98,105,153,0.6)] rounded-8px cursor-pointer hover:text-[rgba(23,23,29,0.6)] hover:bg-[rgba(98,105,153,0.08)] transition-colors duration-200 ease-[cubic-bezier(0.645,0.045,0.355,1)]'>
                        <IconFont name='gengduo' />
                      </span>
                    </Dropdown>
                  </div>
                </div>
              )
            })
          ) : (
            <>{isInited && <Empty desc='搜索结果为空' />}</>
          )}
        </Spin>
      </OverlayScrollbarsComponent>
    </div>
  )
}
