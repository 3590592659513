import type { Position } from 'reactflow'
import { getSmoothStepPath } from 'reactflow'
import { getConditionBranchPath } from '../utils/edgePath'
import type { CustomEdgePathOptions } from '../edges/InsertEdge'

export interface InsertEdgePathParams {
  edgeInfo: {
    id: string
    source: string
    target: string
    sourceHandleId?: string | null
  }
  layout: {
    sourceX: number
    sourceY: number
    targetX: number
    targetY: number
    sourcePosition: Position
    targetPosition: Position
  }
  customPathOptions?: CustomEdgePathOptions
}

export function useInsertEdgePath(params: InsertEdgePathParams): {
  edgePath: string
  labelX: number
  labelY: number
} {
  const { layout, customPathOptions } = params
  // const { id, source, target, sourceHandleId } = edgeInfo
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition } =
    layout
  let edgePath = ''
  let labelX = 0
  let labelY = 0
  if (customPathOptions) {
    // console.log('自定义path', customPathOptions);
    ;[edgePath, labelX, labelY] = getConditionBranchPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      options: customPathOptions,
    })
  } else {
    ;[edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    })
    // labelX = sourceX
    // labelY = sourceY - 5
  }
  return {
    edgePath,
    labelX,
    labelY,
  }
}
