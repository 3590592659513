import { RequiredMark } from '@/components'

export default function renderLabel(label: string, required = true) {
  return (
    <span className='text-font font-medium leading-16px'>
      {label}
      {required && <RequiredMark />}
    </span>
  )
}
