import FormData from 'form-data'
import { LOGIN_AUTHORIZATION } from '@/constants/request'
import { userCenterRequest, request } from './lib'
import type { AppJoined } from './authority'

interface Role {
  name: string
  new: boolean
}

export enum FeatureFlag {
  WORKSPACE_CREATION = 'WORKSPACE_CREATION',
  AI_CHAT = 'AI_CHAT',
}

export interface User {
  userId: number
  username: string
  avatar?: string
  activatedStatus: AppJoined
  integrationAttr: any
  isSystemUser: boolean | null
  phone: string
  enterpriseId: number
  roles: Role[]
  isAnonymous?: boolean
}

export function getUser() {
  return request.get<User>('/v1/users/me')
}

export function updateUser(data: Pick<User, 'username' | 'avatar'>) {
  return request.put('/v1/users/me', data)
}

export interface LoginResData {
  accessToken: string
  client: string
  expired: number
  refreshToken: string
  userId: number
  userName: string
  isNewUser: boolean
}

export function doLogin({
  phone,
  password,
}: {
  phone: string
  password: string
}) {
  const data = new FormData()

  data.append('phone', phone)
  data.append('password', password)
  return userCenterRequest.post<LoginResData>('/user-center/login', data, {
    headers: {
      Authorization: LOGIN_AUTHORIZATION,
    },
  })
}

interface SignUpParams {
  username: string
  phone: string
  password: string
  userProfile?: {
    source: string // 注册渠道来源
  }
}

export interface LoginOrRegisterParams {
  phone: string
  password?: string
  verifyCode?: string
}

export function loginOrRegister(data: LoginOrRegisterParams) {
  const formData = new FormData()
  formData.append('phone', data.phone)
  data.password && formData.append('password', data.password)
  data.verifyCode && formData.append('verifyCode', data.verifyCode)
  return userCenterRequest.post<LoginResData>('/user-center/login', formData, {
    headers: {
      Authorization: LOGIN_AUTHORIZATION,
    },
  })
}

export function signUp(data: SignUpParams) {
  return userCenterRequest.post<{ userId: string; phone: string }>(
    '/user-center/user/signUp',
    data,
  )
}

export function authorizeChatbot(userId: string) {
  return request.put(`/v1/users/feature?feature=CHATBOT&userId=${userId}`)
}

export interface UserByPhoneRes {
  phone: string
  userId: number
  username: string
  avatar?: string
  isSystem: boolean
  activatedStatus: AppJoined
  enterpriseId: number
}

export function getUserByPhone(phone: string) {
  return userCenterRequest.get<UserByPhoneRes>(
    `/user-center/user/getUserByPhone?phone=${phone}`,
    {
      headers: {
        innerToken: 'c9ff2c02-82be-44fe-9e3b-2bbf6354525a',
      },
      ignoreError: true,
    },
  )
}

interface UpdatePasswordParams {
  password: string
  phone: string
  verifyCode: string
}

export function updatePasswordWithVerifyCode(data: UpdatePasswordParams) {
  const formData = new FormData()
  formData.append('password', data.password)
  formData.append('phone', data.phone)
  formData.append('verifyCode', data.verifyCode)
  return userCenterRequest.put(
    '/user-center/user/updatePasswordWithVerifyCode',
    formData,
  )
}

export interface UserProfile {
  role?: string
  enterpriseName?: string
  source?: string
  profile?: any
  utm_term?: string
  utm_sou?: string
}

interface UserProfileResponse {
  id: number
  userId: number
  source: null
  profile: string
  createTime: string
  updateTime: string
  profileJSON: UserProfile
}

export function getUserProfile(userId: number) {
  return userCenterRequest.get<UserProfileResponse>(
    `/user-center/user/getUserProfileByUserId?userId=${userId}`,
  )
}

export function saveOrUpdateUserProfile(
  userId: number,
  profile: UserProfile,
  source?: string,
) {
  return userCenterRequest.post(
    '/user-center/user/saveOrUpdateProfile',
    {
      userId,
      source,
      profile,
    },
    { customErrorMessage: '系统开小差了，请刷新重试' },
  )
}

export function checkToken(token: string | null): Promise<UserProfileResponse> {
  const formData = new FormData()
  formData.append('token', token)

  return userCenterRequest.post('/user-center/auth/checkToken', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    ignoreError: true,
  })
}
