import { useState } from 'react'
import cn from 'classnames'
import { IconFont } from '@/components'
import { SchemaCollapse } from '../SchemaCollapse'

export function SchemaFormItem({
  title,
  identifier,
}: {
  title?: string
  identifier?: string
}) {
  const [collapsed, setCollapsed] = useState(false)

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div className='px-16 pt-8 pb-16'>
      <div className='text-12px/16px text-font font-medium mb-8 flex justify-between items-center'>
        Schema
        <span
          className={cn(
            'w-16px h-16px flex justify-center items-center text-8px text-[rgba(141,141,153,0.6)]',
            {
              'rotate-180': collapsed,
            },
          )}
          onClick={handleCollapse}
        >
          <IconFont name='arrow' />
        </span>
      </div>
      {!collapsed && (
        <div className='h-300px p-12 rounded-8px bg-[rgba(98,105,153,0.06)] overflow-x-hidden overflow-y-auto'>
          {title && identifier ? (
            <SchemaCollapse
              classNames={{
                refreshButton:
                  '-mt-1 -mr-4 bg-white shadow-[0px_0px_4px_0px_rgba(0,0,0,0.1)]',
              }}
              title={title}
              identifier={identifier}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}
