import { Popover, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { useMemoizedFn } from 'ahooks'
import {
  CheckCircleOutlined,
  LoadingOutlined,
  MinusCircleFilled,
} from '@ant-design/icons'
import { Button, IconFont } from '@/components'
import { TextEditorPure } from '@/features/editor/TextEditor/editor'
import { tokenStorage } from '@/utils/storage'
import {
  State,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket.ts'

interface SystemPromptOptimizeProps {
  className?: string
  disabled?: boolean
  getPrompt: () => string
  onChange: (value: string) => void
}

function InternalSystemPromptOptimize(props: SystemPromptOptimizeProps) {
  const { disabled, getPrompt, onChange, className } = props

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const token = tokenStorage.get()

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const {
    state,
    content,

    handleSendMessage,
    handleStop,
    disconnect,
  } = useGeneratePromptSocket(socketUrl)

  const optimizePrompt = useMemoizedFn(() => {
    setLoading(true)
    handleSendMessage(
      JSON.stringify({
        action: 'optimize_prompt_text',
        params: {
          detailDemand: getPrompt(),
        },
      }),
    )
  })

  const handleToggle = useMemoizedFn(() => {
    if (content) return
    optimizePrompt()
  })

  const handleClose = useMemoizedFn(() => {
    if (!open) return
    setOpen(false)
    disconnect()
  })

  const handleConfirm = useMemoizedFn(() => {
    setOpen(false)
    onChange(content)
  })

  const handleOpenChange = useMemoizedFn((newOpen: boolean) => {
    if (open === newOpen) return
    setOpen(newOpen)
    if (!newOpen) {
      disconnect()
    }
  })

  useEffect(() => {
    if ([State.none, State.result].includes(state)) {
      setLoading(false)
    }
  }, [state])

  const popContent = (
    <div className='w-480px p-14px'>
      <div className='flex items-center mb-24'>
        <span className='text-18px font-medium text-font mr-6'>提示词优化</span>
        {loading && <LoadingOutlined className='text-16px c-primary' />}
        {!loading && (
          <CheckCircleOutlined className='text-16px color-#2CB969' />
        )}
        <span
          className='ml-auto text-20px cursor-pointer'
          onClick={handleClose}
        >
          <IconFont name='guanbi' />
        </span>
      </div>
      <div className='h-390px relative rounded-8px p-12 bg-[rgba(98,105,153,0.08)]'>
        <TextEditorPure
          className='z-10'
          value={content || '优化中，请稍后...'}
          placeholder='请输入提示词'
          readonly
          updateToBottom
          lastCursor={loading || !content}
        />
        {loading && (
          <div
            className='inline-flex flex-center h-34px w-94px z-20 absolute bottom-12px left-0 right-0 mx-auto rounded-8px bg-white color-#8D8D99 cursor-pointer shadow-[0_0_8px_0_rgba(0,0,0,0.06)]'
            onClick={handleStop}
          >
            <MinusCircleFilled className='mr-4px' />
            停止生成
          </div>
        )}
      </div>
      <div className='mt-24 flex justify-end'>
        <Button disabled={loading} onClick={optimizePrompt}>
          重新生成
        </Button>
        <Button
          className='ml-12'
          type='primary'
          disabled={loading}
          onClick={handleConfirm}
        >
          使用
        </Button>
      </div>
    </div>
  )

  return (
    <Popover
      rootClassName='[&_.ant-popover-inner]:border [&_.ant-popover-inner]:border-[rgba(141,141,153,0.08)] [&_.ant-popover-inner]:shadow-[0px_12px_32px_0px_rgba(153,162,176,0.24)]'
      trigger={'click'}
      open={open}
      onOpenChange={handleOpenChange}
      content={popContent}
      placement='bottomLeft'
      arrow={false}
      destroyTooltipOnHide
    >
      <Tooltip title='基于你输入的内容自动优化'>
        <button
          className={cn(
            'h-24px w-24px flex-center px-4 rounded-4px text-14px',
            className,
            {
              'hover:bg-bg_3/8 data-[open=true]:bg-bg_3/8': !disabled,
              'cursor-not-allowed opacity-50': disabled,
            },
          )}
          data-open={open}
          disabled={disabled}
          type='button'
          onClick={handleToggle}
        >
          <IconFont className='text-16px c-bg_3/60' name='auto' />
        </button>
      </Tooltip>
    </Popover>
  )
}

export const SystemPromptOptimize = React.memo(InternalSystemPromptOptimize)
