import { memo, useRef, useState } from 'react'
import cn from 'classnames'
import { useMemoizedFn } from 'ahooks'
import type { FormInstance } from 'antd'
import { Form, Tooltip } from 'antd'
import type { Variable } from '@/features/editor/TextEditor'
import { IconFont } from '@/components'
import { PromptStructType } from '@/apis/prompt.ts'
import { WeightEditor } from '@/features/prompt/components/PromptStructEditor/WeightEditor.tsx'
import { useModal } from '@/hooks/useModal.tsx'
import { PromptGenerateModal } from '@/features/prompt/components/PromptStructEditor/PromptGenerateModal.tsx'
import { FormListName } from '@/features/prompt/components/PromptStructEditor/PromptStructEditor.tsx'
import { PromptStructTitle } from '@/features/prompt/components/PromptStructEditor/PromptStructTitle.tsx'
import type { PlaceholderMap } from '@/features/prompt/utils/placeholder.ts'
import { WithQuickFillTextEditor } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'
import { isMacOS } from '@/utils/device.ts'
import { SkillSync } from '@/features/prompt/components/PromptStructEditor/SkillSync.tsx'

interface PromptStructItemProps {
  form: FormInstance
  index: number // Form List Item Name
  variables?: Variable[]
  variableTipsContainer?: HTMLElement | null
  anchor?: 'left' | 'right'
  onRemove: () => void
  fullscreen: boolean
  toggleFullScreen: () => void
  onSkillSync: (index: number) => void
  placeholderMap: PlaceholderMap
  skillSyncEnable?: boolean
  skills: { name: string; description: string }[]
  onValueChange: (index: number, key: string, value: any) => void
  className?: string
  showTools?: boolean
  disabled?: boolean
  textareaClassName?: string
  mini?: boolean
}

const TEXT_EDITOR_VARIABLE_TOOLTIP_OFFSET: [number, number] = [0, -36]

export const PromptStructItem = memo<PromptStructItemProps>(props => {
  const {
    variables,
    variableTipsContainer,
    anchor = 'right',
    index,
    form,
    fullscreen,
    skills,
    className,
    toggleFullScreen,
    onRemove,
    onSkillSync,
    onValueChange,
    skillSyncEnable,
    placeholderMap,
    showTools = true,
    disabled = false,
    textareaClassName,
    mini = false,
  } = props
  const container = useRef<HTMLDivElement>(null)
  const [focus, setFocus] = useState(false)
  const [promptGenerateModal] = useModal(PromptGenerateModal)

  const type = form.getFieldValue([
    FormListName,
    index,
    'type',
  ]) as PromptStructType

  const handleFocus = useMemoizedFn(() => {
    setFocus(true)
  })

  const handleBlur = useMemoizedFn(() => {
    setFocus(false)
  })

  const handleGenerateModalOpen = useMemoizedFn(() => {
    const prefixName = [FormListName, index]
    const title = form.getFieldValue([FormListName, index, 'title'])
    const currentPrompt = form.getFieldValue([...prefixName, 'content'])
    const formValue = form.getFieldValue([...prefixName, 'form_submit'])
    const onFormValueChange = (value: Record<string, any>) => {
      onValueChange(index, 'form_submit', value)
    }
    const onPromptReplace = (prompt: string) => {
      onValueChange(index, 'content', prompt)
    }

    promptGenerateModal.open({
      type,
      title,
      value: formValue,
      listForm: form,
      skills,
      currentPrompt,
      placeholderMap: placeholderMap[type].generateForm,
      onChange: onFormValueChange,
      onPromptReplace,
    })
  })

  const handleTitleChange = useMemoizedFn((newTitle: string) => {
    onValueChange(index, 'title', newTitle)
  })

  return (
    <div
      ref={container}
      className={cn(
        'w-full transition-all-200 ease-in-out bg-#fff b-1 rd-6px of-hidden',
        {
          'b-line/80': !focus,
          'b-primary': focus,
          'shadow-[0_0px_4px_4px_rgba(123,97,255,0.07)]': focus && !mini,
          'b-transparent!': !focus && mini,
          'bg-#626999! bg-op-8!': mini,
        },
        className,
      )}
    >
      {/* Header */}
      <div className='flex-center-between w-full h-36px pl-12 pr-8'>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => {
            return (
              prev[FormListName]?.[index]?.title !==
              next[FormListName]?.[index]?.title
            )
          }}
        >
          {form => {
            const title = form.getFieldValue([FormListName, index, 'title'])
            return (
              <PromptStructTitle
                mini={mini}
                title={title}
                onChange={handleTitleChange}
                disabled={type !== PromptStructType.CUSTOM}
              />
            )
          }}
        </Form.Item>
        <div className='flex items-center gap-8'>
          {showTools && (
            <>
              {skillSyncEnable && type === PromptStructType.SKILL && (
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) =>
                    prev[FormListName][index].content !==
                    next[FormListName][index].content
                  }
                >
                  {form => {
                    const content =
                      form.getFieldValue([FormListName, index, 'content']) ?? ''
                    return (
                      <SkillSync
                        disabledConfirm={!content.trim()}
                        onSync={() => onSkillSync(index)}
                      />
                    )
                  }}
                </Form.Item>
              )}
              <Form.Item noStyle name={[index, 'weight']}>
                <WeightEditor />
              </Form.Item>
              <Tooltip
                placement='top'
                trigger={['hover']}
                title={`${isMacOS() ? '⌘' : 'Win'}+I`}
              >
                <span
                  className='w-24px h-24px rd-4px cursor-pointer flex-center hover:bg-bg_3/8'
                  onClick={handleGenerateModalOpen}
                >
                  <IconFont name='auto' className='text-16px c-bg_3/60' />
                </span>
              </Tooltip>
              <span
                className='w-24px h-24px rd-4px cursor-pointer flex-center hover:bg-bg_3/8'
                onClick={toggleFullScreen}
              >
                <IconFont
                  name={fullscreen ? 'suoxiaocongkou' : 'fangtai-1'}
                  className='text-16px c-bg_3/60'
                />
              </span>
            </>
          )}

          <span
            className={cn(
              'w-24px h-24px rd-4px cursor-pointer c-bg_3/60 flex-center hover:bg-error/8 hover:c-error',
              {
                hidden: type !== PromptStructType.CUSTOM,
              },
            )}
            onClick={onRemove}
          >
            <IconFont name='shanshu' className='text-16px' />
          </span>
        </div>
      </div>
      {/* Editor */}
      <Form.Item name={[index, 'content']} noStyle>
        <WithQuickFillTextEditor
          mini={mini}
          className={cn(
            'px-12! py-0! pb-4px! [&_textarea]:h-full! text-14px rd-0 b-none! bg-#fff! [&_.text-editor-content]:line-height-[1.5]! [&_textarea]:line-height-[1.5]!',
            textareaClassName,
          )}
          height={fullscreen ? 'calc(100vh - 335px)' : '100%'}
          minHeight={28}
          maxHeight={fullscreen ? undefined : 124}
          placeholder={placeholderMap[type].placeholder}
          variables={variables}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={e => {
            // 快捷唤起AI辅助浮层
            if (e.code === 'KeyI' && e.metaKey) {
              handleGenerateModalOpen()
            }
          }}
          anchor={anchor}
          variableTipsOffset={TEXT_EDITOR_VARIABLE_TOOLTIP_OFFSET}
          variableTipsContainer={variableTipsContainer || container.current}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  )
})

PromptStructItem.displayName = 'PromptStructItem'
