import { cloneElement } from 'react'
import type { FC, PropsWithChildren, ReactElement } from 'react'
import type { FieldControl, FormSchema, MetaState } from '@bty/ui-components'
import cn from 'classnames'
import copy from 'copy-to-clipboard'
import { message } from 'antd'
import type { ChatPCProps } from '@bty/chat-renderer-pc'
import { FlowFileUpload } from '@/features/nodes/components/FieldsUpload'
import {
  Input,
  JsonEditor,
  RequiredMark,
  Select,
  TagViewSelect,
  TextArea,
} from '@/components'
import { FieldTypes, SelectViewMode } from '@/features/nodes/start'

const FormItem: FC<
  PropsWithChildren<{
    control: FieldControl
    schema: FormSchema
    meta: MetaState
  }>
> = props => {
  const { children, schema, control, meta } = props

  const hasError = meta.errors.length > 0

  const ClonedChildren = cloneElement(children as ReactElement, {
    ...control,
    placeholder: schema.placeholder,
    disabled: meta.disabled,
    status: hasError ? 'error' : '',
    className: cn((children as ReactElement).props.className, schema.className),
    'aria-invalid': hasError ? 'true' : undefined,
  })

  return (
    <div className='mb-16px w-full box-border'>
      <div className='pb-8 flex items-center'>
        <span className='font-400 c-font_1'>{schema.label}</span>
        {schema.type === FieldTypes.MultiSelect && (
          <span className='c-font_1 mr--7px font-400'>（可多选）</span>
        )}
        {schema.required && <RequiredMark className='!text-16px top-0' />}
      </div>
      <div className='w-full'>{ClonedChildren}</div>
      <div
        className={cn(
          'mt-2 flex flex-col op-0 h-0 transition-all-200 ease-in-out',
          {
            'h-auto! op-100!': hasError,
          },
        )}
      >
        {meta.errors.map((error, index) => (
          <div className='c-error text-12px pt-4px pb-12' key={error + index}>
            {error}
          </div>
        ))}
      </div>
      {hasError && <div className='mb--16px'></div>}
    </div>
  )
}

export const FormMessageComponentMap: ChatPCProps['formMessageComponentMap'] = {
  input: (schema, control, meta) => {
    return (
      <FormItem schema={schema} meta={meta} control={control}>
        <Input className='important:text-14px py-8px h-34px!' />
      </FormItem>
    )
  },
  textarea: (schema, control, meta) => (
    <FormItem schema={schema} meta={meta} control={control}>
      <TextArea autoSize={{ minRows: 3 }} />
    </FormItem>
  ),
  select: (schema, control, meta) => (
    <FormItem schema={schema} meta={meta} control={control}>
      {schema.viewMode === SelectViewMode.Flat ? (
        <TagViewSelect type='single' options={schema.options} />
      ) : (
        <Select
          options={schema.options}
          className='w-full [&_.ant-select-selector]:h-34px!'
        />
      )}
    </FormItem>
  ),
  multiSelect: (schema, control, meta) => (
    <FormItem schema={schema} meta={meta} control={control}>
      {schema.viewMode === SelectViewMode.Flat ? (
        <TagViewSelect type='multi' options={schema.options} />
      ) : (
        <Select
          className={cn([
            '[&_.ant-select-selection-item]:h-24px! w-full',
            '[&_.ant-select-selector]:h-auto! [&_.ant-select-selector]:p-3!',
            '[&_.ant-select-selection-overflow]:gap-y-4px',
          ])}
          mode='multiple'
          options={schema.options}
        />
      )}
    </FormItem>
  ),
  json: (schema, control, meta) => (
    <FormItem schema={schema} meta={meta} control={control}>
      <JsonEditor
        className='min-h-128px'
        onCopy={text => {
          copy(text)
          message.success('复制成功')
        }}
      />
    </FormItem>
  ),
  file: (schema, control, meta) => (
    <FormItem schema={schema} meta={meta} control={control}>
      <FlowFileUpload
        hasError={!!meta.errors.length}
        supportFileConfig={schema.supportFileConfig}
        supportFileTypes={schema?.supportFileTypes}
      />
    </FormItem>
  ),
}
