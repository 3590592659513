import { useMemo, useRef, type ReactNode } from 'react'
import { useHover } from 'ahooks'
import { Tooltip } from 'antd'
import { useAuth } from '@bty/react-auth'
import { IconFont, usePageModal } from '@/components'
import { type Version } from '@/features/pay'
import { useVersionStore } from '@/store'
import { useLimitedAccessModal } from '@/features/pay/LimitedAccess'
import { RoleCode } from '@/apis/authority'

interface NodeOptionItemProps {
  type?: 'plugin'
  id: any
  icon: ReactNode
  name: string
  description?: string
  allowVersion?: Version[]
  onClick: (data: any) => void
}

export function NodeOptionItem(props: NodeOptionItemProps) {
  const { type, id, icon, name, description, onClick, allowVersion } = props
  const ref = useRef<HTMLDivElement>(null)
  const pageModal = usePageModal()
  const isHover = useHover(ref)
  const versionInfo = useVersionStore(state => state.versionInfo)
  const { state } = useAuth()

  const [limitedAccessModal] = useLimitedAccessModal({
    hideShowUpdateModal: !(state.role === RoleCode.ADMINISTRATOR),
  })

  const needUpdateVersion = useMemo(() => {
    if (
      Array.isArray(allowVersion) &&
      allowVersion?.length > 0 &&
      versionInfo
    ) {
      return !allowVersion.includes(versionInfo?.product_code)
    }
    return false
  }, [allowVersion, versionInfo])

  const content = (
    <div
      ref={ref}
      className='relative flex items-center h-40px p-8px b-rd-6px hover:bg-#626999 hover:bg-op-8 cursor-pointer group'
      onClick={() => {
        if (needUpdateVersion) {
          // 现在只有团队版的限制
          limitedAccessModal.open({
            title: `团队版无法使用${name}功能，请升级到企业版使用。`,
          })
          return
        }
        onClick(id)
      }}
    >
      {icon}
      <div className='flex items-center flex-1 text-#17171d font-500'>
        {name}
        {type === 'plugin' && isHover && (
          <span
            onClick={e => {
              pageModal.show({
                url: `/plugin/info/${id}?pageType=infoOnly`,
              })
              e.stopPropagation()
            }}
            className='cursor-pointer text-14px ml-8 cursor-pointer c-#626999 c-op-60'
          >
            <IconFont name='super-link' />
          </span>
        )}
      </div>
      {needUpdateVersion ? (
        <div className='flex-center w-24px h-24px'>
          <IconFont name='suoding' className='text-16px text-#626999/60' />
        </div>
      ) : (
        <div className='group-hover:flex-center hidden w-24px h-24px'>
          <IconFont name='add' className='text-16px text-#7b61ff' />
        </div>
      )}
    </div>
  )

  return description ? (
    <Tooltip
      title={description}
      placement='left'
      // getPopupContainer={() => ref.current!}
    >
      {content}
    </Tooltip>
  ) : (
    content
  )
}
