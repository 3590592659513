import { useMemoizedFn, useRequest } from 'ahooks'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useMemo } from 'react'
import { sample } from 'lodash-es'
import { Button, IconFont, usePageModal } from '@/components'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'
import { createAgentSkillFunction } from '@/apis/agent'
import { PluginListRouteAction } from '@/features/agent/types'

export function usePluginActionOnAgent(disabledAction?: boolean) {
  const navigate = useNavigate()
  const pageModal = usePageModal()

  const { skillConfigContext, applicationInfo, updateStatistics } =
    useAgentEdit()

  const { toolList = [], refresh } = skillConfigContext

  const existedPLuginIds = useMemo(() => {
    return (toolList || []).map(item => item.id)
  }, [toolList])

  const { runAsync: createAgentSystemSkill } = useRequest(
    createAgentSkillFunction,
    { manual: true },
  )

  const handleAddPlugin = useMemoizedFn(async (id: string) => {
    const res = await createAgentSystemSkill(
      [id],
      applicationInfo?.flowId!,
      applicationInfo?.versionId!,
    )
    const tipsData = res?.map(
      (item: { display_name: string; tips: string[] }) => ({
        label: item?.display_name,
        content: [sample(item?.tips)],
      }),
    )
    navigate('plugin', {
      state: {
        action: PluginListRouteAction.ShowTipsData,
        tipsData,
      },
    })
    refresh()
    updateStatistics()
  })

  const pluginItemOnMessageRender = useMemoizedFn(
    (args: { avatar: string; id: string; title: string }) => {
      const { avatar, id, title } = args
      return (
        <div
          className='flex bg-#fff rounded-8px w-330px p-8px mt-8px cursor-pointer items-center group'
          onClick={e => {
            e.stopPropagation()
            pageModal.show({
              url: `/plugin/info/${id}?pageType=infoOnly`,
            })
          }}
        >
          <img
            className={classNames(
              'w-24px h-24px rounded-5.33px object-contain shrink-0',
            )}
            src={avatar}
          />
          <span className='text-14px/16px ml-8px'>{title}</span>
          <span className='ml-auto flex items-center'>
            <span className='text-16px mr-12px cursor-pointer c-#626999 c-op-60 hidden group-hover:block'>
              <IconFont name='super-link' />
            </span>
            {!disabledAction ? (
              <Button
                type='primary'
                className={`w-60px h-24px! text-12px! ${
                  existedPLuginIds.includes(id)
                    ? 'bg-[rgba(98,105,153,0.08)]! c-[#8D8D99]! '
                    : 'bg-primary'
                }`}
                disabled={existedPLuginIds.includes(id)}
                onClick={e => {
                  e.stopPropagation()
                  handleAddPlugin(id)
                }}
              >
                {existedPLuginIds.includes(id) ? '已添加' : '添加'}
              </Button>
            ) : null}
          </span>
        </div>
      )
    },
  )

  const addFileProcessingPlugin = useMemoizedFn(() => {
    navigate('plugin', {
      state: {
        action: PluginListRouteAction.OpenAddPluginModal,
      },
    })
  })

  return {
    pluginItemOnMessageRender,
    addFileProcessingPlugin,
  }
}
