import { useSubSignal } from '@/hooks/useSignal'
import { useApplicationInit } from '../hooks/useApplicationInit'
import { useFlowExtraDataInit } from '../hooks/useFlowExtraDataInit'
import { useFlowPreviewInit } from '../hooks/useFlowPreviewInit'
import { UPDATE_FLOW_SIGNAL } from '../const'
import { FlowPreview } from './flow'

export default function FlowPagePreview() {
  const { fetchDraftFlow } = useFlowPreviewInit()
  useFlowExtraDataInit()
  useApplicationInit()
  useSubSignal(UPDATE_FLOW_SIGNAL, fetchDraftFlow)

  return <FlowPreview />
}
