import type { IPluginItem } from '@/apis/flow'

export function transformPluginData(data: IPluginItem[]) {
  return data.reduce<{ label: string; data: IPluginItem[] }[]>((pre, cur) => {
    const labelItem = pre.find(item => item.label === cur.plugin_label)
    if (labelItem) {
      labelItem.data.push(cur)
    } else {
      pre.push({
        label: cur.plugin_label,
        data: [cur],
      })
    }
    return pre
  }, [])
}
