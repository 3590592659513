import type { Edge, Node } from 'reactflow'
import { NodeType } from '@/features/nodes/base'
import type { ConditionNodeData } from '@/features/nodes/condition'
import type { MultiBranchNodeOperation } from './MultiBranchNodeOperation'
import type { LoopNodeOperation } from './LoopNodeOperation'
import { getOperationByNodeType } from '.'

/**
 * @description 根据节点id获取其所属父节点的禁用状态以继承，用于节点添加时指定节点启用状态
 * @param id
 * @param nodes
 */
export function getInheritedNodeEnableById(id: string, nodes: Node[]) {
  let nodeEnable = true
  const currentNode = nodes.find(n => n.id === id)
  if (currentNode) {
    const { relation } = currentNode.data
    // 如果添加在条件节点的分支上或者循环体中，获取对应容器节点的禁用状态
    const parentNodeId = relation?.conditionNodeId || relation?.loopNodeId
    if (parentNodeId) {
      const parentNode = nodes.find(n => n.id === parentNodeId)
      nodeEnable = parentNode?.data.isEnable
    }
  }
  return nodeEnable
}

export function findAllChildrenInNestedNode(
  node: Node,
  nodes: Node[],
  includeSelfAndResult = true,
) {
  if (node.type === NodeType.CONDITION) {
    const { relation, conditions } = node.data as ConditionNodeData
    const result = []
    if (includeSelfAndResult) {
      const resultNode = nodes.find(
        n => n.id === relation?.conditionResultId,
      ) as Node
      result.push(node, resultNode)
    }
    const conditionIds = conditions.map(c => c.id)
    const childNodesOnBranch = nodes.filter(n =>
      conditionIds.includes(n.data?.relation?.branchId),
    )
    childNodesOnBranch.forEach(item => {
      if (
        ![NodeType.CONDITION, NodeType.INTENT, NodeType.LOOP].includes(
          item.type as NodeType,
        )
      ) {
        result.push(item)
      }
      result.push(...findAllChildrenInNestedNode(item, nodes, true))
    })
    return result
  }
  if (node.type === NodeType.INTENT) {
    const { relation, conditions } = node.data as ConditionNodeData
    const result = []
    if (includeSelfAndResult) {
      const resultNode = nodes.find(
        n => n.id === relation?.conditionResultId,
      ) as Node
      result.push(node, resultNode)
    }
    const conditionIds = conditions.map(c => c.id)
    const childNodesOnBranch = nodes.filter(n =>
      conditionIds.includes(n.data?.relation?.branchId),
    )
    childNodesOnBranch.forEach(item => {
      if (
        ![NodeType.CONDITION, NodeType.INTENT, NodeType.LOOP].includes(
          item.type as NodeType,
        )
      ) {
        result.push(item)
      }
      result.push(...findAllChildrenInNestedNode(item, nodes, true))
    })
    return result
  }
  if (node.type === NodeType.LOOP) {
    const { relation } = node.data
    const result = []
    const resultNode = nodes.find(n => n.id === relation?.loopResultId) as Node
    if (includeSelfAndResult) {
      result.push(node, resultNode)
    }
    const childNodesOnLoop = nodes.filter(
      n => n.data?.relation?.loopNodeId === node.id && n.id !== resultNode.id,
    )
    childNodesOnLoop.forEach(item => {
      if (
        ![NodeType.CONDITION, NodeType.INTENT, NodeType.LOOP].includes(
          item.type as NodeType,
        )
      ) {
        result.push(item)
      }
      result.push(...findAllChildrenInNestedNode(item, nodes, true))
    })
    return result
  }
  return []
}

export function copyNestableNode(
  node: Node,
  nodes: Node[],
  edges: Edge[],
  nodesMap: Record<string, Node>,
) {
  const operation = getOperationByNodeType(node.type as NodeType) as
    | MultiBranchNodeOperation
    | LoopNodeOperation
  return operation.copy(node, nodes, edges, nodesMap, false)
}
