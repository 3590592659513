import { Switch } from 'antd'

interface WelcomeSwitchControlProps {
  value?: boolean
  onChange?: (value: boolean) => void
}

export function WelcomeSwitchControl({
  value,
  onChange,
}: WelcomeSwitchControlProps) {
  return (
    <div className='flex items-center h-48px'>
      <span className='text-14px text-font font-medium mr-auto'>开场白</span>
      <Switch value={value} size='small' onChange={onChange} />
    </div>
  )
}
