import { useRef, useContext, useMemo } from 'react'
import classNames from 'classnames'

import { get, isObject } from 'lodash-es'
import { Radio } from 'antd'
import ReactDiffViewer, { DiffMethod } from '../ReactDiffViewer'

import { ENUM_ROLE_TEXT } from '../FormChat'

import type { Message } from '../../../LLMNode'

import { NodeFormItem } from '@/features/nodes/components'
import { checkedJsonModel } from '@/features/nodes/utils/llm'
import { Segmented } from '@/components'
import { LLMContextType, contextOptions } from '../../../const'
import { PromptDiffModalContext } from '.'

const customStyles = {
  variables: {
    light: {
      emptyLineBackground: 'rgb(244,243,247)',
    },
  },
  line: {
    wordBreak: 'break-word',
    background: 'rgb(244,243,247)',
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  contentText: {
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  diffAdded: {
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  diffRemoved: {
    fontSize: '12px',
    lineHeight: '20px !important',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  diffContainer: {
    width: '100%',
    background: 'rgb(244,243,247)',
    fontFamily: 'monospace !important',
    borderSpacing: '0px',
  },
  lineNumber: {
    fontSize: '12px !important',
    lineHeight: '20px !important',
  },
}

export function DiffPromptNode(props: any) {
  const { readOnly = false, show = 'current' } = props
  const { oldData, data } = useContext(PromptDiffModalContext)

  const diffData = useMemo(() => {
    return {
      newValue: data?.inputs?.system_content,
      oldValue: oldData?.inputs?.system_content,
    }
  }, [oldData, data, show])

  const wrapperRef = useRef<HTMLDivElement>(null)

  const component = useMemo(() => {
    return (
      <ReactDiffViewer
        show={show}
        oldValue={diffData.oldValue}
        newValue={diffData.newValue}
        compareMethod={DiffMethod.WORDS}
        splitView={true}
        showDiffOnly={false}
        useDarkTheme={false}
        disableWordDiff={false}
        hideLineNumbers={false}
        styles={customStyles}
      />
    )
  }, [diffData.newValue, diffData.oldValue])

  return (
    <div
      ref={wrapperRef}
      className='flex justify-between items-center flex-1'
      style={{
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <NodeFormItem required className='flex-1 important:mb-0px relative'>
        <div
          className={classNames('opacity-100 relative rd-6px overflow-hidden')}
        >
          {component}
        </div>
      </NodeFormItem>
    </div>
  )
}

export function DiffLLMContextFormField({
  show = 'current',
}: {
  show?: 'current' | 'old'
}) {
  const { data, oldData } = useContext(PromptDiffModalContext)

  const context_type = useMemo(
    () =>
      show === 'current'
        ? data?.inputs?.context_type
        : oldData?.inputs?.context_type,
    [data?.inputs?.context_type, oldData?.inputs?.context_type, show],
  )

  const processValue = (inputData: any) => {
    const type = inputData?.inputs?.context_type
    if (type === LLMContextType.JSON_VARIABLE) {
      const val = get(inputData, 'inputs.pre_defined_messages') || ''
      return isObject(val) ? JSON.stringify(val, null, 2) : String(val)
    } else {
      const val = get(inputData, 'inputs.messages') || []
      return val.reduce((total: string, current: Message, index: number) => {
        const separator = index === val.length - 1 ? '' : '\\n\\n'
        return `${total}[${ENUM_ROLE_TEXT[current.role]}]: ${current.content || ''}${separator}`
      }, '')
    }
  }

  const newValue = useMemo(() => processValue(data), [data])
  const oldValue = useMemo(() => processValue(oldData), [oldData])

  return (
    <div className='opacity-100 relative rd-6px overflow-hidden'>
      <Segmented
        block
        size='small'
        className='mb-12px'
        disabled
        options={contextOptions}
        value={context_type}
      />
      <ReactDiffViewer
        show={show}
        oldValue={oldValue}
        newValue={newValue}
        compareMethod={DiffMethod.WORDS}
        splitView={true}
        showDiffOnly={false}
        showMinHeight={true}
        useDarkTheme={false}
        disableWordDiff={false}
        hideLineNumbers={false}
        styles={customStyles}
      />
    </div>
  )
}

export function DiffLLMOutputMethod(props: any) {
  const { show = 'current' } = props
  const { data, oldData } = useContext(PromptDiffModalContext)

  const newValue = useMemo(() => {
    return data?.inputs?.plugin?.json_mode
  }, [data])
  const oldValue = useMemo(() => {
    return oldData?.inputs?.plugin?.json_mode
  }, [oldData])

  const val = show === 'current' ? newValue : oldValue

  const model =
    show === 'current' ? data?.inputs?.model : oldData?.inputs?.model

  const support = checkedJsonModel(model)
  return (
    <div>
      <Radio.Group disabled value={val}>
        <Radio value={false}>文本</Radio>
        <Radio value={true}>JSON</Radio>
      </Radio.Group>
      {!support && (
        <span className='text-12px c-[rgba(0,0,0,0.25)] line-height-16px'>
          当前模型不支持该功能
        </span>
      )}
    </div>
  )
}

export function DiffChatNode(props: any) {
  const { readOnly = false, show = 'current' } = props
  const { oldData, data } = useContext(PromptDiffModalContext)
  const currentChat = (data?.inputs?.messages || []).slice(1)
  const oldChat = (oldData?.inputs?.messages || []).slice(1)

  const newValue = currentChat.reduce(
    (total: any, current: Message, index: number) => {
      const separator = currentChat.length - 1 === index ? '' : '\n\n'
      total += `[${ENUM_ROLE_TEXT[current.role]}]: ${
        current.content || ''
      }${separator}`
      return total
    },
    '',
  )

  const oldValue = oldChat.reduce(
    (total: any, current: Message, index: number) => {
      const separator = oldChat.length - 1 === index ? '' : '\n\n'
      total += `[${ENUM_ROLE_TEXT[current.role]}]: ${
        current.content || ''
      }${separator}`
      return total
    },
    '',
  )
  const diffData = useMemo(() => {
    return {
      newValue,
      oldValue,
    }
  }, [oldValue, newValue, show])

  const wrapperRef = useRef<HTMLDivElement>(null)

  const component = useMemo(() => {
    return (
      <ReactDiffViewer
        show={show}
        oldValue={diffData.oldValue}
        newValue={diffData.newValue}
        compareMethod={DiffMethod.WORDS}
        splitView={true}
        showDiffOnly={false}
        showMinHeight={true}
        useDarkTheme={false}
        disableWordDiff={false}
        hideLineNumbers={false}
        styles={customStyles}
      />
    )
  }, [diffData.newValue, diffData.oldValue])

  return (
    <div
      ref={wrapperRef}
      className='flex justify-between items-center flex-1'
      style={{
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <NodeFormItem required className='flex-1 important:mb-0px relative'>
        <div
          className={classNames('opacity-100 relative rd-6px overflow-hidden')}
        >
          {component}
        </div>
      </NodeFormItem>
    </div>
  )
}

export function DiffVariableNode(props: any) {
  const { readOnly = false, show = 'current', current = {}, old = {} } = props

  const newValue = Object.keys(current).reduce(
    (total: any, item: any, index: number) => {
      const separator = Object.keys(current).length - 1 === index ? '' : '\n\n'
      const currentItem = isObject(current[item])
        ? JSON.stringify(current[item])
        : current[item]
      total += `[${item}]: ${currentItem || ''}${separator}`
      return total
    },
    '',
  )

  const oldValue = Object.keys(old).reduce(
    (total: any, item: any, index: number) => {
      const separator = Object.keys(old).length - 1 === index ? '' : '\n\n'
      const oldItem = isObject(old[item])
        ? JSON.stringify(old[item])
        : old[item]
      total += `[${item}]: ${oldItem || ''}${separator}`
      return total
    },
    '',
  )

  const diffData = useMemo(() => {
    return {
      newValue,
      oldValue,
    }
  }, [current, old, show])

  const wrapperRef = useRef<HTMLDivElement>(null)

  const component = useMemo(() => {
    return (
      <ReactDiffViewer
        show={show}
        oldValue={diffData.oldValue}
        newValue={diffData.newValue}
        compareMethod={DiffMethod.WORDS}
        splitView={true}
        showMinHeight={true}
        showDiffOnly={false}
        useDarkTheme={false}
        disableWordDiff={false}
        hideLineNumbers={false}
        styles={customStyles}
      />
    )
  }, [diffData.newValue, diffData.oldValue])

  return (
    <div
      ref={wrapperRef}
      className='flex justify-between items-center flex-1'
      style={{
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <NodeFormItem required className='flex-1 important:mb-0px relative'>
        <div
          className={classNames('opacity-100 relative rd-6px overflow-hidden')}
        >
          {component}
        </div>
      </NodeFormItem>
    </div>
  )
}
