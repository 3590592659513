import { Access } from '@bty/react-auth'
import React, { useCallback, useMemo } from 'react'
import { Button, IconFont, Modal } from '@/components'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { useLimitedModal } from '../LimitedPanel'
import { useModal } from '@/hooks/useModal'
import { Version, VersionStatus } from '../const'
import { RoleCode } from '@/apis/authority'
import { useVersion } from '@/hooks/useVersion'
import { useContactCustomerServiceModal } from '../ContactCustomerServiceModal'

type limitedType =
  | 'agent'
  | 'inviteUser'
  | 'dataStore'
  | 'flow'
  | 'shouldCheckTeamVersion'
  | boolean

interface LimitedAccessProps {
  children: React.ReactNode
  limitedType: limitedType
  alwaysAllowAccess?: boolean
  limitedAccessModalContainer?: HTMLElement
  limitFeatureName?: string
  hideShowUpdateModal?: boolean
}

interface useLimitedAccessModalOptions {
  title?: string | React.ReactNode
  content?: string | React.ReactNode
  onConfirm?: () => void
  cancelBtnText?: string
  confirmBtnText?: string
  hideShowUpdateModal?: boolean
  modalContainer?: HTMLElement
}

export function useLimitedAccessModal(options: useLimitedAccessModalOptions) {
  const [upgradeModal] = useLimitedModal()
  const [contactCustomerServiceModal] = useContactCustomerServiceModal()
  const { isEnterprise } = useVersion()

  const LimitedAccessModal = useCallback(
    (props: any) => {
      return (
        <Modal
          {...props}
          footer={false}
          title='操作提示'
          width={480}
          styles={{
            body: {
              padding: '20px',
            },
          }}
          // 解决在 antd popover 上打开被遮挡的问题，popover default zIndex 为 1030
          zIndex={1031}
          getContainer={
            options?.modalContainer
              ? () => options.modalContainer
              : document.body
          }
        >
          <div className='flex'>
            <div className='pt-3px'>
              <IconFont className='text-32px mr-12px' name='yunxingyibai' />
            </div>
            <div className='flex-1 mb-38px'>
              <div
                className={`font-500 text-16px mb-8px ${
                  options?.content ? '' : 'mt-10px'
                }`}
              >
                {props?.title || options.title}
              </div>
              <div className='text-14px color-#8D8D99 line-height-18px'>
                {options.content}
              </div>
            </div>
          </div>
          <div className='text-right'>
            <Button onClick={props.onCancel}>
              {options?.cancelBtnText || '我知道了'}
            </Button>
            {!options.hideShowUpdateModal && (
              <Button
                onClick={() => {
                  if (options?.onConfirm) {
                    options.onConfirm()
                    props.onCancel()
                  } else {
                    if (isEnterprise) {
                      contactCustomerServiceModal.open()
                      return
                    }
                    upgradeModal.open()
                  }
                }}
                className='ml-8px'
                type='primary'
              >
                {options?.confirmBtnText ||
                  (isEnterprise ? '联系客服' : '购买升级')}
              </Button>
            )}
          </div>
        </Modal>
      )
    },
    [
      options.content,
      options.title,
      options.modalContainer,
      upgradeModal,
      contactCustomerServiceModal,
      isEnterprise,
    ],
  )
  return useModal(LimitedAccessModal)
}

export interface LimitedAccessInfo extends useLimitedAccessModalOptions {
  access: boolean
}

export function useLimitedAccessModalInfo(
  limitedType: limitedType,
  limitFeatureName?: string,
): LimitedAccessInfo {
  const { currentWorkspaceItem } = useWorkspaceStore(state => ({
    currentWorkspaceItem: state.currentWorkspaceItem,
    workspaceList: state.workspaceList,
  }))

  const versionInfo = useVersionStore(state => state.versionInfo)

  return useMemo(() => {
    const workspaceName = currentWorkspaceItem?.name
    const role = currentWorkspaceItem?.roleCode
    const isAdministrator = role === RoleCode.ADMINISTRATOR
    if (
      limitedType &&
      limitedType !== 'shouldCheckTeamVersion' &&
      versionInfo &&
      versionInfo?.status === VersionStatus.LoseEffect
    ) {
      let title = ''
      let confirmBtnText = ''
      switch (versionInfo.product_code) {
        case Version.FREE:
          title = `「${workspaceName}」空间已过期，请升级版本后使用。`
          break
        case Version.TEAM:
          title = `「${workspaceName}」空间已过期，请续费团队版使用。`
          confirmBtnText = '续费升级'
          break
        case Version.ENTERPRISE:
          title = `「${workspaceName}」空间已过期${
            isAdministrator ? '，请联系客服续费' : ''
          }。`
          break
      }
      return {
        access: false,
        title: title || '空间已过期',
        content: '',
        hideShowUpdateModal: !isAdministrator,
        confirmBtnText,
      }
    }
    if (versionInfo && limitedType) {
      switch (limitedType) {
        case 'agent': {
          const app_total_num =
            versionInfo.app_count_limit +
            versionInfo.dosage_package_app_count_total
          const access = app_total_num > versionInfo.app_consume_num
          return {
            access,
            title: '应用数量已至上限，无法继续创建 Agent',
            content: (
              <span>
                「{workspaceName}」可发布应用数量{' '}
                <span className='color-#FF5219'>
                  {versionInfo.app_consume_num}
                </span>
                /{app_total_num} 个
              </span>
            ),
            hideShowUpdateModal: !isAdministrator,
          }
        }
        case 'flow': {
          const app_total_num =
            versionInfo.app_count_limit +
            versionInfo.dosage_package_app_count_total
          const access = app_total_num > versionInfo.app_consume_num
          return {
            access,
            title: '应用数量已至上限，无法继续创建工作流',
            content: (
              <span>
                「{workspaceName}」可发布应用数量{' '}
                <span className='color-#FF5219'>
                  {versionInfo.app_consume_num}
                </span>
                /{app_total_num} 个
              </span>
            ),
            hideShowUpdateModal: !isAdministrator,
          }
        }
        case 'inviteUser': {
          const user_total_num =
            versionInfo.total_user_count_limit +
            versionInfo.dosage_package_user_count_total
          const access = user_total_num > versionInfo.exist_user_count
          return {
            access,
            title: '用户数量已至上限，无法继续邀请用户',
            content: (
              <span>
                「{workspaceName}」用户数量{' '}
                <span className='color-#FF5219'>
                  {versionInfo.exist_user_count}
                </span>
                /{user_total_num} 个
              </span>
            ),
            hideShowUpdateModal: !isAdministrator,
          }
        }
        case 'dataStore': {
          const memory_total_num =
            versionInfo.memory_count_limit +
            versionInfo.dosage_package_memory_total
          const access = memory_total_num > versionInfo.memory_consume_num
          return {
            access,
            title: '知识库容量已至上限，无法继续添加文档',
            content: (
              <span>
                「{workspaceName}」知识库容量
                <span className='color-#FF5219'>
                  {versionInfo.memory_consume_num}
                </span>
                /{memory_total_num} MB
              </span>
            ),
            hideShowUpdateModal: !isAdministrator,
          }
        }
        case 'shouldCheckTeamVersion': {
          const access = versionInfo.product_code !== Version.TEAM
          return {
            access,
            title: `团队版本无法使用${limitFeatureName}, 请升级到企业版使用`,
            content: '',
            hideShowUpdateModal: !isAdministrator,
          }
        }
      }
    }
    return {
      access: true,
      title: '',
      content: '',
      hideShowUpdateModal: false,
    }
  }, [limitedType, versionInfo])
}

/**
 * 如果 limitedType 为 true，则表示只校验空间是否过期
 * @param props
 * @returns
 */
export function LimitedAccess(props: LimitedAccessProps) {
  const { children, limitedType, limitFeatureName = '' } = props

  const {
    access,
    title,
    content,
    hideShowUpdateModal = false,
    confirmBtnText,
  } = useLimitedAccessModalInfo(limitedType, limitFeatureName)

  const [LimitedAccessModal] = useLimitedAccessModal({
    title,
    content,
    modalContainer: props?.limitedAccessModalContainer,
    hideShowUpdateModal,
    confirmBtnText,
  })

  return (
    <Access
      access={props?.alwaysAllowAccess ? true : access}
      callback={() => {
        LimitedAccessModal.open()
      }}
    >
      {children}
    </Access>
  )
}
