import { Tooltip } from 'antd'
import { ResponsiveCard } from '@/components/list/ResponsiveCardGrid.tsx'
import { CardDataLabel } from './CardDataLabel'
import { GradientBorderAvatar } from './GradientBorderAvatar'

export interface TemplateCardProps {
  id: string
  image: string
  title: string
  desc: string
  skillCount: number
  docCount: number
  heat: number
  onClick: (id: string) => void
}

export function TemplateCard(props: TemplateCardProps) {
  const { id, image, title, desc, skillCount, docCount, heat, onClick } = props

  return (
    <ResponsiveCard
      className='flex flex-col justify-end shrink-0 relative h-212px mb-28px cursor-pointer'
      onClick={() => onClick(id)}
    >
      <div className='absolute top-0 left-50% translate-x--50% w-88px h-88px'>
        <GradientBorderAvatar image={image} />
      </div>
      <div className='flex flex-col flex-items-center h-168px p-20px b-rd-12px bg-#fff hover:shadow-[0_8px_24px_0px_rgba(23,23,29,0.12)]'>
        <div className='mt-44px mb-12px text-16px font-600 text-#17171d'>
          {title}
        </div>
        <Tooltip title={desc}>
          <div className='w-full line-height-20px text-12px text-#8d8d99 text-op-60 text-center truncate'>
            {desc}
          </div>
        </Tooltip>
        <div className='w-full flex flex-justify-between mt-24px'>
          <div className='flex gap-16px'>
            <CardDataLabel iconName='jitai' count={skillCount} title='技能' />
            <CardDataLabel iconName='jiyi' count={docCount} title='文档' />
          </div>
          <CardDataLabel className='ml-16px' iconName='remen' count={heat} />
        </div>
      </div>
    </ResponsiveCard>
  )
}
