export const CodeEditorAssistantIdMap = (function () {
  switch (__DEFINE_ENV__) {
    case 'pre':
      // https://pre-ai.betteryeah.com/agent/ed5536f1505444dfb91b94879f994c7d/design/rule
      return {
        python: 'ed5536f1505444dfb91b94879f994c7d',
        javascript: 'ed5536f1505444dfb91b94879f994c7d',
      }
    case 'production':
      return {
        // https://ai.betteryeah.com/agent/8b2ce6b3fb87423fad96c96801ce6703/design/rule
        python: '8b2ce6b3fb87423fad96c96801ce6703',
        // https://ai.betteryeah.com/agent/889e8b74405b455f9aa158f8c2f575df/design/rule
        javascript: '889e8b74405b455f9aa158f8c2f575df',
      }
    default:
      // http://dev-ai.betteryeah.com/agent/34e2c5de193b4c189b704f5fe86b053b/design/rule
      return {
        python: '34e2c5de193b4c189b704f5fe86b053b',
        javascript: '34e2c5de193b4c189b704f5fe86b053b',
      }
  }
})()
