import { type FC } from 'react'
import { Tooltip, Upload, message } from 'antd'
import type { RcFile, UploadFile } from 'antd/es/upload'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import { random } from 'lodash-es'
import classNames from 'classnames'
import type { UploadFileStatus } from 'antd/es/upload/interface'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import dayjs from 'dayjs'
import { checkFileSize, getFileSuffix, getFileIconByMimetype } from '../utils'
import {
  FileIconMap,
  SUPPORTED_FILE_TYPE_TEXT,
  fileTypeMimeMap,
} from '../constant'

import { IconFont } from '@/components'
import { useWorkspaceStore, useUserStore } from '@/store'
import type { IOSSClientProps } from '@/hooks/useOSSClient'
import { useOSSClient } from '@/hooks/useOSSClient'

interface FileItem {
  oss_path: string
  signature_url?: string
  mimetype: string
  file_name: string
  file_size: number
  file_status: UploadFileStatus
}
export interface DocumentUploadProps {
  hashPath?: string
  namespace: string
  business?: IOSSClientProps['business']
  onChange?: (list: FileItem[]) => void
  placeholder?: string
  getFileStatus?: (e?: UploadFileStatus) => void
  fileAccept?: string[]
  maxCount?: number
}
const StyledUpload = styled(Upload.Dragger)`
  .ant-upload-drag {
    background: ${({ theme }) => rgba(theme.colors.bg_3, 0.06)};
  }
`

export const defaultVideoFileType = [
  '.mp4',
  '.webm',
  '.mov',
  '.mkv',
  '.flv',
  '.avi',
  '.wmv',
]

export const defaultNormaleFileType = [
  '.docx',
  '.doc',
  '.pdf',
  '.xlsx',
  '.md',
  '.txt',
  '.jpg',
  '.png',
  '.ppt',
  '.pptx',
  '.msg',
]
export const defaultAllowFileType = [
  ...defaultNormaleFileType,
  ...defaultVideoFileType,
]

export const DocumentUpload: FC<DocumentUploadProps> = ({
  onChange,
  getFileStatus,
  hashPath,
  namespace,
  placeholder,
  fileAccept,
  business = 'main',
  maxCount = undefined,
}) => {
  // const getSTSTokenPromise = useRef<Promise<STSTokenResponse>>()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const user = useUserStore(state => state.user)

  // const { runAsync: getSTSTokenApi } = useRequest(getDatasetSTSToken, {
  //   manual: true,
  // })
  // useEffect(() => {
  //   getSTSTokenPromise.current = getSTSTokenApi()
  // }, [])

  const { ossClient } = useOSSClient({
    business,
  })

  const uploadFileToOss = async (options: UploadRequestOption) => {
    // const stsTokenData = await getSTSTokenPromise.current

    // if (!stsTokenData) {
    //   message.error('获取上传凭证失败，请稍后重试')
    //   return
    // }

    const { file, onSuccess, onError, onProgress } = options
    return new Promise((resolve, reject) => {
      const filePath = `ai/${currentWorkspaceId}/${namespace}/${
        hashPath || Date.now()
      }/__${user?.userId ?? '-1000'}_${dayjs().valueOf()}_${random(1, 9999)}__${
        (file as RcFile).name
      }`
      const suffix = getFileSuffix((file as RcFile).name)
      const mimeType =
        (file as RcFile).type ||
        (fileTypeMimeMap as any)[suffix] ||
        'text/plain'

      // const ossClient = new OSS({
      //   region: 'oss-cn-hangzhou',
      //   accessKeyId: stsTokenData!.access_key_id,
      //   accessKeySecret: stsTokenData!.access_key_secret,
      //   stsToken: stsTokenData!.security_token,
      //   bucket: import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET, // 后续dataset服务的bucket也需要修改 具体与后端确定
      //   timeout: 3 * 60 * 1000,
      // })
      ossClient?.useBucket(import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET)
      ossClient
        ?.multipartUpload(filePath, file, {
          progress(p) {
            onProgress?.({ percent: p * 100 })
          },
        })
        .then(() => {
          onProgress?.({ percent: 100 })
          const response = {
            mimeType,
            fileName: (file as RcFile).name,
            filePath,
          }
          onSuccess?.(response)
          resolve(response)
        })
        .catch(err => {
          onError?.(err)
          reject(err)
        })
        .finally(() => {
          // clearInterval(timer)
        })
    })
  }

  const renderFileStatus = (file: UploadFile, remove: () => void) => {
    switch (file.status) {
      case 'error':
      case 'done':
        return (
          <IconFont
            name='shanshu'
            onClick={remove}
            className='text-font_1 hover:text-error cursor-pointer'
          />
        )
      case 'uploading':
        // return (
        //   <span className="flex items-center">
        //     <span className="font-400 text-font_1">{formatFileSize(file.size ?? 0)}</span>
        //     <span className="font-500 ml-8">{(file.percent ?? 0 / 100).toFixed(2)}%</span>
        //   </span>
        // )
        return '上传中...'
      default:
        return null
    }
  }

  const beforeUpload = (file: File): any => {
    if (!checkFileSize(file.size, 150)) {
      message.warning('文件大小不能超过150MB, 请修改后重新上传')
      return Upload.LIST_IGNORE
    }
    return true
  }

  return (
    <div>
      <StyledUpload
        multiple
        maxCount={maxCount}
        accept={fileAccept?.join(',') || defaultAllowFileType.join(',')}
        customRequest={uploadFileToOss}
        beforeUpload={beforeUpload}
        onChange={list => {
          const fileList = list.fileList.map(item => {
            const file_name = item.response?.fileName ?? ''
            return {
              oss_path: item.response?.filePath as string,
              mimetype: item.response?.mimeType as string,
              file_name:
                file_name.length > 50 ? file_name.slice(0, 50) : file_name,
              file_size: item.size ?? 0,
              file_status: item.status as UploadFileStatus,
            }
          })
          onChange?.(fileList)
        }}
        itemRender={(_originNode, file, _fileList, actions) => {
          getFileStatus?.(file.status)
          return (
            <div
              title={file.name}
              className={classNames(
                'overflow-hidden relative mt-[8px] bg-white border border-solid border-line rounded-[8px]',
                {
                  'hover:bg-bg_3 hover:bg-op-8 cursor-pointer':
                    file.status === 'done',
                },
              )}
            >
              <div
                className='bg-[rgba(123,97,255,0.2)] h-full position-absolute'
                style={{
                  width: `${file.percent}%`,
                  opacity: file.percent !== 100 ? 1 : 0,
                  zIndex: file.percent !== 100 ? 'inherit' : -1,
                  transition: 'all 0.25s ease-in-out',
                }}
              ></div>
              <div className='py-10 px-16 flex items-center justify-between'>
                <span className='flex items-center w-[230px]'>
                  <img
                    className='w-[16px] h-[20px]'
                    src={
                      file.type
                        ? getFileIconByMimetype(file.type!)
                        : FileIconMap[getFileSuffix(file.name)]
                    }
                    alt=''
                  />
                  {file.status === 'error' ? (
                    <Tooltip title='文件上传失败'>
                      <span className='text-error ml-8 text-ellipsis overflow-hidden inline-block text-nowrap'>
                        {file.name}
                      </span>
                    </Tooltip>
                  ) : (
                    <span className='ml-8 text-ellipsis overflow-hidden inline-block text-nowrap'>
                      {file.name}
                    </span>
                  )}
                </span>
                {renderFileStatus(file, actions.remove)}
              </div>
            </div>
          )
        }}
      >
        <div className='py-[46px]'>
          <p className='text-[14px] line-height-14px'>
            拖拽文件到这里，或者 <span className='text-primary'>选择文件</span>
          </p>

          <p className='text-[12px] text-font_1 mt-8 line-height-20px'>
            {placeholder || `支持格式：${SUPPORTED_FILE_TYPE_TEXT}`}
          </p>
        </div>
      </StyledUpload>
    </div>
  )
}
