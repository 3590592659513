import QrCodeContainerBg from '@/assets/agent/qrcode-share-container-bg.png'

export const PixelMultiplier = 2

export async function drawShareImageBg(
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  canvasHeight: number,
) {
  return new Promise<void>((resolve, reject) => {
    const bgImg = new Image()
    bgImg.src = QrCodeContainerBg
    bgImg.onload = () => {
      ctx.drawImage(bgImg, 0, 0, canvasWidth, canvasHeight)
      resolve()
    }
    bgImg.onerror = e => {
      reject(e)
    }
  })
}

export async function drawShareImageAvatar(
  ctx: CanvasRenderingContext2D,
  url: string,
  canvasWidth: number,
) {
  return new Promise<void>((resolve, reject) => {
    const avatarImage = new Image()
    avatarImage.src = url
    avatarImage.crossOrigin = '*'

    avatarImage.onload = () => {
      ctx.save()

      const avatarX = canvasWidth / 2 - (83 * PixelMultiplier) / 2
      const avatarY = 37 * PixelMultiplier
      const avatarSize = 83 * PixelMultiplier

      ctx.beginPath()
      ctx.arc(
        avatarX + avatarSize / 2,
        avatarY + avatarSize / 2,
        avatarSize / 2,
        0,
        Math.PI * 2,
        true,
      )
      ctx.closePath()
      ctx.clip()

      ctx.drawImage(avatarImage, avatarX, avatarY, avatarSize, avatarSize)
      ctx.restore()
      resolve()
    }

    avatarImage.onerror = e => {
      reject(e)
    }
  })
}

export function drawAgentName(
  ctx: CanvasRenderingContext2D,
  name: string,
  canvasWidth: number,
  y: number,
) {
  ctx.font = `600 ${PixelMultiplier * 18}px Arial`
  ctx.fillStyle = '#3F3F44'
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'

  ctx.fillText(name, canvasWidth / 2, y * PixelMultiplier)
}

export async function drawQRCode(
  ctx: CanvasRenderingContext2D,
  url: string,
  canvasWidth: number,
  width: number,
  height: number,
  y: number,
) {
  return new Promise<void>((resolve, reject) => {
    const qrCodeImage = new Image()
    qrCodeImage.src = url
    qrCodeImage.crossOrigin = 'anonymous'

    qrCodeImage.onload = () => {
      const x = canvasWidth / 2 - (width / 2) * PixelMultiplier

      ctx.drawImage(
        qrCodeImage,
        x,
        y * PixelMultiplier,
        width * PixelMultiplier,
        height * PixelMultiplier,
      )
      resolve()
    }

    qrCodeImage.onerror = e => {
      reject(e)
    }
  })
}

export function drawQRCodeTips(
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  y: number,
) {
  ctx.font = `400 ${14 * PixelMultiplier}px Arial`
  ctx.fillText(
    '微信扫一扫 或 长按识别二维码',
    canvasWidth / 2,
    y * PixelMultiplier,
  )
}
