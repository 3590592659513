import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { workspaceStorage } from '@/utils/storage'
import { getWorkspaceList, type WorkspaceItem } from '@/apis/workspace'

interface WorkspaceStore {
  currentWorkspaceId: string
  workspaceList: WorkspaceItem[]
  currentWorkspaceItem?: WorkspaceItem
  setCurrentWorkspace: (workspaceId: string) => void
  setWorkspaceList: (workspaceList: WorkspaceItem[]) => void
  fetchWorkspaceList: (name?: string) => Promise<WorkspaceItem[]>
}

export const useWorkspaceStore = create<WorkspaceStore>((set, get) => ({
  currentWorkspaceId: workspaceStorage.get() || '',
  currentWorkspaceItem: undefined,
  workspaceList: [],
  setCurrentWorkspace: workspaceId => {
    workspaceStorage.set(workspaceId)
    set({
      currentWorkspaceId: workspaceId,
      currentWorkspaceItem: workspaceId
        ? get().workspaceList.find(item => item.id === workspaceId)
        : undefined,
    })
  },
  setWorkspaceList: workspaceList => set({ workspaceList }),
  fetchWorkspaceList: async name => {
    const res = await getWorkspaceList(name)
    if (res?.length) {
      set({ workspaceList: res })
    } else {
      set({ workspaceList: [] })
      workspaceStorage.clear()
    }
    // res?.length ? set({ workspaceList: res }) : workspaceStorage.clear()
    return res || []
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('workspaceStore', useWorkspaceStore)
}
