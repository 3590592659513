import type {
  ConversationData,
  GenerateTitleParams,
  MessageHistoryParams,
  Bot as BotType,
  UnreadMessageCountMap,
  PaginatedMessageHistory,
  ChatbotRunType,
  ChatActionType,
} from '@bty/chat-types'
import type { AgentRunStatus, AgentRunType } from './agent'
import { request } from './lib'

export const CHAT_API_PREFIX = '/v1/chat'

export function getBotById(robotId: string) {
  return request.get(`${CHAT_API_PREFIX}/robot/${robotId}`)
}

export function getDefaultBot() {
  return request.get(`${CHAT_API_PREFIX}/robot/default`)
}

export function getBotList() {
  return request.get<BotType[]>(
    `${CHAT_API_PREFIX}/robot/list?include_system=false`,
  )
}

export function uploadFile2Bot(uploadData: {
  conversation: string
  file_type: string
}) {
  return request.post(`${CHAT_API_PREFIX}/upload`, uploadData)
}

export interface ChatMessage {
  conversation_id: string
  stream?: boolean
  content: string
  filename?: string
  file_type?: string
  action?: 'regenerate' | 'continue'
}

// export interface AgentTask {
//   duration_time: number
//   tool_type: string
//   message: string
//   name: string
//   status: string
//   timestamp: number
//   type: string
// }

export interface ChatResponseStream {
  message: { code: number; success: boolean; message: string; now_time: number }
  type: 'result'
}

export interface SendMessagePayload {
  conversation_id: number | string
  session_id?: string
  /**
   * prompt
   */
  content: string
  filename?: string
  /**
   * 文件类型
   */
  file_type?: string
  /**
   * 是否重新生成
   */
  action?: 'regenerate' | 'continue'

  upload_file_id?: string
  /**
   * 文件传递类型，后端吃该逻辑
   */
  file_action?: 'FILE_URL' | 'FILE_DATA' | undefined
}

export function getMessageHistory(params: MessageHistoryParams) {
  const query = new URLSearchParams(params as Record<string, any>)
  return request.get<PaginatedMessageHistory>(
    `${CHAT_API_PREFIX}/history?${query}`,
  )
}

export function refreshSession(
  conversation_id: string,
  action_type?: ChatActionType,
) {
  return request.get(
    `${CHAT_API_PREFIX}/session/refresh?conversation_id=${conversation_id}&action_type=${action_type}`,
  )
}

export function getConversationList(
  robot_id?: string,
  run_type?: ChatbotRunType,
) {
  return request.get<ConversationData[]>(
    `${CHAT_API_PREFIX}/conversation/list?robot_id=${robot_id || ''}&run_type=${
      run_type || ''
    }`,
  )
}

export function createConversation(data: {
  robot_id: string
  title: string
  run_type: ChatbotRunType
}) {
  return request.post<{ conversation_id: string; session_id: string }>(
    `${CHAT_API_PREFIX}/conversation/create`,
    data,
  )
}

export function deleteConversation(conversation_id: string) {
  return request.delete(
    `${CHAT_API_PREFIX}/conversation?conversation_id=${conversation_id}`,
  )
}

export function updateConversation(conversation_id: string, title: string) {
  return request.put(`${CHAT_API_PREFIX}/conversation`, {
    conversation_id,
    title,
  })
}

export function getCurrentSession(conversationId: string) {
  return request.get<string>(
    `${CHAT_API_PREFIX}/session/current?conversation_id=${conversationId}`,
  )
}

export function getAgentBot(robot_id: string) {
  return request.get(
    ` ${CHAT_API_PREFIX}/conversation/agent_testing/${robot_id}`,
  )
}

export interface Bot {
  id: string
  app_id: string
  color: string
  icon: string
  name: string
  welcome_message: string
  description: string
}
interface AgentConversationData {
  conversation_id: string
  uid: number
  title: string
  robot_id: string
  update_time: number
  bot: Bot
}

export function getAgentTestConversation(robotId: string) {
  return request.get<AgentConversationData>(
    `${CHAT_API_PREFIX}/conversation/agent_testing/${robotId}`,
  )
}

export function generateTitleFromConversation(params: GenerateTitleParams) {
  return request.post<{ title: string }>(`${CHAT_API_PREFIX}/get_title`, params)
}

export function generateTitleFromDiffConversation(params: GenerateTitleParams) {
  return request.post<{ title: string }>(
    `${CHAT_API_PREFIX}/get_title_for_multiple_models`,
    params,
  )
}

export function getAgentGlobalUnreadMessageCount() {
  return request.get<UnreadMessageCountMap>(
    `${CHAT_API_PREFIX}/record/count_unread_msgs`,
    {
      noRedirect: true,
      ignoreError: true,
    },
  )
}

export function getAgentGlobalUnreadMessageCountForAgentTest() {
  return request.get<UnreadMessageCountMap>(
    `${CHAT_API_PREFIX}/record/count_agent_testing_unread_msgs`,
    {
      noRedirect: true,
      ignoreError: true,
    },
  )
}

export interface ExportAgentLogsParams {
  agent_id: string
  user_name?: string
  run_types?: AgentRunType[]
  run_status?: AgentRunStatus
  start_time?: string
  end_time?: string
}

export function exportAgentLogs(body: ExportAgentLogsParams) {
  return request.post<string | null>(
    `${CHAT_API_PREFIX}/conversation/agent_log_export`,
    body,
  )
}
