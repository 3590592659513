import { Button } from '@/components'
import { PayIcon } from './PayIcon'

export interface PayFailedProps {
  reason: string
  title: string | React.ReactNode
  loading?: boolean
  onCancel?: () => any
  onRetry?: () => any
  cancelText?: string
  retryText?: string
  hideCancelBtn?: boolean
  hideRetryBtn?: boolean
}

export function PayFailed(props: PayFailedProps) {
  const {
    reason,
    title,
    onCancel,
    onRetry,
    loading = false,
    cancelText = '取消订单',
    retryText = '重新支付',
    hideCancelBtn = false,
    hideRetryBtn = false,
  } = props
  return (
    <div className='flex flex-col items-center'>
      <PayIcon type='failed' content={title}></PayIcon>
      <div className='mt-48 w-300px text-center'>{reason}</div>
      <div className='mt-48'>
        {!hideCancelBtn && (
          <Button
            className='mr-12 w-120px'
            onClick={onCancel}
            loading={loading}
          >
            {cancelText}
          </Button>
        )}
        {!hideRetryBtn && (
          <Button type='primary' className='w-120px' onClick={onRetry}>
            {retryText}
          </Button>
        )}
      </div>
    </div>
  )
}
