import type { ReactNode } from 'react'
import { useMemo } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

interface DesignRouteProps {
  active?: boolean
  disabled?: boolean
  path: string
  icon: ReactNode
  text: string
  extra?: ReactNode
}

export function DesignRoute({
  active,
  disabled,
  path,
  icon,
  text,
  extra,
}: DesignRouteProps) {
  const className = useMemo(() => {
    return classNames(
      'flex flex-col flex-center w-52px h-52px relative mb-4px duration-100',
      active && '!c-primary',
    )
  }, [active])

  if (disabled) {
    return (
      <Tooltip
        title='助手正在自动编排，请稍等'
        placement='right'
        align={{
          offset: [0, 0],
        }}
      >
        <span
          className={className}
          style={{
            color: 'rgba(98, 105, 153, 0.8)',
            opacity: '0.5',
            cursor: 'not-allowed',
          }}
        >
          {icon}
          <span className='text-12px mt-6px leading-none'>{text}</span>
        </span>
      </Tooltip>
    )
  }

  return (
    <Link
      to={path}
      replace
      className={className}
      style={{
        color: 'rgba(98, 105, 153, 0.8)',
      }}
    >
      {icon}
      <span className='text-12px mt-6px leading-none'>{text}</span>
      {extra}
    </Link>
  )
}
