import { Form, Tooltip } from 'antd'
import { IconFont, Input } from '@/components'

function InternalFormListQuestionGuide() {
  return (
    <Form.Item
      shouldUpdate={(prevValue, nextValue) =>
        prevValue.welcome_enabled !== nextValue.welcome_enabled
      }
      noStyle
    >
      {form =>
        form.getFieldValue('welcome_enabled') ? (
          <Form.List name='question_guide'>
            {(fields, { add, remove }) => (
              <>
                <div className='flex items-center h-48px'>
                  <span className='text-14px text-font font-medium'>
                    开场提问引导
                  </span>
                  <Tooltip
                    placement='top'
                    title='提前预设一些问题，让使用者更快的熟悉 Agent 的能力'
                    destroyTooltipOnHide
                  >
                    <IconFont
                      className='text-font_1 text-14px text-opacity-40 ml-5 cursor-pointer'
                      name='jieshishuimeng'
                    />
                  </Tooltip>
                  <button
                    className='flex justify-center items-center w-24px h-24px ml-auto text-16px rounded-6px hover:bg-#E4E5ED'
                    type='button'
                    onClick={event => {
                      event.preventDefault()
                      add('')
                    }}
                  >
                    <IconFont name='fangtai' />
                  </button>
                </div>
                {fields.map(({ key, name }) => (
                  <Form.Item key={key} name={name} noStyle>
                    <Input
                      className='!bg-white mb-8 last:mb-16 hover:!border-primary hover:!border-op-100 not-focus-within:!border-line not-focus-within:!border-op-80'
                      size='middle'
                      suffix={
                        <span
                          className='w-16px h-16px text-bg_3 text-16px text-op-60 hover:text-primary cursor-pointer flex-center'
                          onClick={() => remove(name)}
                        >
                          <IconFont name='shanshu' />
                        </span>
                      }
                      placeholder='帮我撰写一份小红书文案'
                    />
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
        ) : null
      }
    </Form.Item>
  )
}

export const FormListQuestionGuide = InternalFormListQuestionGuide
