import { useModal } from '@ebay/nice-modal-react'

export default function useNiceModal() {
  const modal = useModal()

  const handleClose = () => {
    modal.hide()

    setTimeout(() => {
      modal.remove()
    }, 200)
  }

  return {
    modal,
    handleClose,
  }
}
