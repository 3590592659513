import styled from '@emotion/styled'
import { useBoolean, useClickAway } from 'ahooks'
import { useEffect, useMemo, useRef, useState } from 'react'

import type { ActionTypesForNode } from '@/apis/flow'
import { Select } from '@/components'
import { CodeEditor } from '@/features/editor/CodeEditor'
import {
  NodeOperationTypes,
  NodeType,
  type NodeComponent,
} from '@/features/nodes/base'
import type { JsonFormConfig } from '../components'
import { JsonForm, ShowMore } from '../components'
import { FormList } from '../components/Forms/FormList'
import { NodeFormItem } from '../components/Forms/NodeFormItem'
import { useFlowDraftStore, FLOW_DRAFT_LOCK_STATUS } from '@/store'

export interface MemoryNodeData {
  name: string
}

export interface APINodeData {
  name: string
  packageName: string
  comment?: string
  inputs?: {
    url?: string
    bodyType: 'JSON' | 'FormData'
    methodType?: string
    description?: string
    headers?: string
    body?: string
    requestTimeout?: number
    retryCount?: number
  }
  actionType?: ActionTypesForNode.INTEGRATION
}

const FormItemStyle = styled(NodeFormItem)`
  .ant-form-item-explain {
    .ant-form-item-explain-error {
      margin-bottom: 0;
      margin-top: 0;
      position: absolute;
    }
  }
`

const METHOD_OPTIONS = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PUT', value: 'PUT' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'DELETE', value: 'DELETE' },
]

const BODY_TYPE_OPTIONS = [
  { label: 'JSON', value: 'JSON' },
  { label: 'FormData', value: 'FormData' },
]

const NO_BODY_METHODS = ['GET', 'DELETE']

interface URLEditorProps {
  variables: any[]
  variableTipsContainer?: HTMLElement
  value?: string
  disabled?: boolean
  onChange?: (value: string) => void
}

function URLEditor(props: URLEditorProps) {
  const [
    wrapEnabled,
    { setTrue: setWrapEnabledTrue, setFalse: setWrapEnabledFalse },
  ] = useBoolean(false)
  const ref = useRef<HTMLDivElement>(null)

  useClickAway(() => {
    setWrapEnabledFalse()
  }, ref)

  return (
    <div
      ref={ref}
      className='absolute top-0 w-100% z-10'
      onClick={setWrapEnabledTrue}
    >
      <CodeEditor
        {...props}
        className='ace-gray'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={props.value}
        onChange={props.onChange}
        wrapEnabled={wrapEnabled}
        disabled={props.disabled}
        // onFocus={setWrapEnabledTrue}
        // onBlur={setWrapEnabledFalse}
        singleLine
        variables={props.variables}
        setOptions={{
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
        placeholder='http://example.com'
      />
    </div>
  )
}

export const APINode: NodeComponent<APINodeData> = props => {
  const [methods] = useState(METHOD_OPTIONS)
  const [expanded, { toggle: toggleExpanded }] = useBoolean(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const { data } = props

  useEffect(() => {
    // 兼容旧数据
    if (data.inputs?.description && !data.comment) {
      props.onSaveChange({ comment: data.inputs?.description } as any, true)
    }
  }, [])

  const onBeforeChange = (value: any) => {
    if (!value?.inputs?.bodyType) {
      value.inputs && (value.inputs.bodyType = 'JSON')
    }
    return value
  }

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: 'URL',
        className: '!mb-0',
        render: () => {
          return (
            <div className='flex items-start gap-4px'>
              <NodeFormItem name={['inputs', 'methodType']} required>
                <Select
                  className='nodrag nopan'
                  size='small'
                  placeholder='请选择'
                  options={methods}
                  style={{ width: 96 }}
                  disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                />
              </NodeFormItem>
              <FormItemStyle
                name={['inputs', 'url']}
                className='flex-1'
                required
                rules={[{ required: true, message: 'URL不能为空' }]}
              >
                <URLEditor
                  variables={props.variables}
                  disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                  variableTipsContainer={props.nodeElement as HTMLElement}
                />
              </FormItemStyle>
            </div>
          )
        },
      },
      // {
      //   label: 'API描述',
      //   name: ['inputs', 'description'],
      //   type: 'Input',
      //   componentProps: {
      //     placeholder: '添加描述，方便在流程中直观看到本API的作用',
      //   },
      // },
      {
        label: '请求头',
        name: ['inputs', 'headers'],
        render: () => {
          return (
            <FormList
              type='FormData'
              disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
              variableTipsContainer={props.nodeElement}
              variables={props.variables}
            />
          )
        },
      },
      {
        label: '请求体',
        hidden: NO_BODY_METHODS.includes(
          props.data.inputs?.methodType as string,
        ),
        render: () => {
          return (
            <>
              <NodeFormItem noStyle name={['inputs', 'bodyType']}>
                <Select
                  className='mb-12 nodrag nopan'
                  size='small'
                  // defaultValue='JSON'
                  options={BODY_TYPE_OPTIONS}
                  getPopupContainer={() => containerRef.current as HTMLElement}
                />
              </NodeFormItem>
              <NodeFormItem noStyle name={['inputs', 'body']}>
                <FormList
                  type={props.data.inputs?.bodyType}
                  variableTipsContainer={props.nodeElement}
                  variables={props.variables}
                />
              </NodeFormItem>
            </>
          )
        },
      },
      {
        label: '重试次数',
        name: ['inputs', 'retryCount'],
        hidden: !expanded,
        type: 'InputNumber',
        componentProps: {
          className: 'nodrag nopan',
          tooltip: '当API出错时，重新请求的次数。最大值为5次。',
          size: 'small',
          placeholder: '重试次数',
          max: 5,
          min: 0,
          step: 1,
        },
      },
      {
        label: '超时时间（秒）',
        name: ['inputs', 'requestTimeout'],
        hidden: !expanded,
        type: 'InputNumber',
        componentProps: {
          className: 'nodrag nopan',
          tooltip: '请求超时时间，单位为秒。默认值为10秒。',
          placeholder: '请求超时时间，单位为秒。',
          max: 300,
          min: 1,
          step: 1,
        },
      },
    ]
  }, [
    props.nodeElement,
    props.variables,
    methods,
    containerRef.current,
    expanded,
    props.data,
  ])

  return (
    <>
      <div ref={containerRef} className='p-16px w-420px pb-0'>
        <JsonForm
          list={list}
          disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          initialValues={{
            inputs: data.inputs,
          }}
          beforeChange={onBeforeChange}
        />
      </div>
      <ShowMore
        text='更多配置'
        expandedText='收起更多配置'
        expanded={expanded}
        onClick={toggleExpanded}
      />
    </>
  )
}

APINode.meta = {
  type: NodeType.API,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'API',
  actionType: 'INTEGRATION',
  icon: 'api',
  description: '调用外部API，发送或者接收数据',
  backgroundColor: '#FF63A9',
  canDelete: true,
  initialData: {
    inputs: {
      methodType: 'GET',
      bodyType: 'JSON',
      url: '',
      description: '',
      requestTimeout: 10,
      headers: '',
      body: '',
      retryCount: 3,
    },
    name: 'api_1',
    packageName: 'integration.api.ApiAction',
  },
}
