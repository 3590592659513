import { Form, Popover, Switch, Tooltip } from 'antd'
import type { ReactNode } from 'react'
import React, { memo, useMemo, useState } from 'react'
import cn from 'classnames'
import { QuestionCircleOutlined } from '@ant-design/icons'
import type { AgentRuleConfig } from '@bty/chat-types'
import { IconFont, Select, SliderSingle } from '@/components'
import { ModelSelect } from '@/features/nodes/components'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard'
import { useLLMStore } from '@/store/llm'
import { DefaultModelIcon } from '@/constants/common.ts'

const Tip = memo(({ tip }: { tip: ReactNode }) => {
  return (
    <Tooltip title={tip}>
      <QuestionCircleOutlined className='cursor-pointer ml-4px text-12px text-[rgba(141,141,153,0.4)]' />
    </Tooltip>
  )
})

interface ModelValue {
  model: AgentRuleConfig['model']
  channel: AgentRuleConfig['channel']
}

type FormInstanceValue = null | {
  model: ModelValue
  temperature: AgentRuleConfig['temperature']
  prompt_plugins: AgentRuleConfig['prompt_plugins']
}

function InternalModelSettings({ disabled }: { disabled?: boolean }) {
  const form = Form.useFormInstance<FormInstanceValue>()

  const [showSettings, setShowSettings] = useState(false)

  const models = useLLMStore(state => state.llmModelLimitedList)
  const modelsDetail = useLLMStore(state => state.llmModelList)

  const model = Form.useWatch('model', { form, preserve: true }) as
    | ModelValue
    | undefined

  const points = models.find(
    item => item.llm_code === model?.model,
  )?.llm_consume_points

  const nowModel = useMemo(() => {
    return modelsDetail.find(item => item.model === model?.model)
  }, [model?.model, modelsDetail])

  const memoryRoundOptions = [
    { round: 1, multiplier: 1 },
    { round: 5, multiplier: 1 },
    { round: 10, multiplier: 2 },
  ].map(({ round, multiplier }) => ({
    text: `${round}轮对话`,
    label: (
      <>
        <span>{round}轮对话</span>
        <PrivateDeployGuard>
          <span className='text-14px text-font_1 font-normal ml-auto'>
            {typeof points === 'number'
              ? `Agent 消耗 ${points * multiplier} Yeah 积分`
              : ''}
          </span>
        </PrivateDeployGuard>
      </>
    ),
    value: round,
  }))

  const content = (
    <div style={{ width: 566 }}>
      <div
        className='h-46px px-14px text-16px flex flex-center'
        style={{
          borderBottom: '1px solid rgba(225, 225, 229, 0.6)',
        }}
      >
        <span className='mr-auto'>模型设置</span>
        <IconFont
          className='rounded-4px p-4px m-[-4px] box-content hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
          name='guanbi'
          onClick={() => {
            setShowSettings(false)
          }}
        />
      </div>

      <div className='p-20px flex flex-col gap-20px'>
        <div className='flex flex-center h-36px'>
          <span className='w-120px flex-none'>模型</span>
          <div className='flex-1'>
            <Form.Item name='model' noStyle>
              <ModelSelect
                className='h-36px'
                size='middle'
                filterByMethodSupport={['tools', 'functions']}
                placeholder='请选择'
                showModelDescription={false}
              />
            </Form.Item>
          </div>
        </div>

        <div className='flex flex-center h-36px'>
          <span className='w-120px flex-none'>创造性</span>
          <div className='flex-1'>
            <Form.Item name='temperature' noStyle>
              <SliderSingle
                className='h-36px!'
                min={0}
                max={1}
                step={0.1}
                maxLength={3}
              />
            </Form.Item>
          </div>
        </div>

        <div className='flex flex-center h-36px'>
          <span className='w-120px flex-none'>
            对话记忆轮数
            <Tip tip='和 Agent 一次问答记为一轮对话' />
          </span>
          <div className='flex-1'>
            <Form.Item name={['prompt_plugins', 'length_prompt']} noStyle>
              <Select
                className='w-full h-36px'
                popupClassName='[&_.ant-select-item-option.ant-select-item.ant-select-item-option-selected]:!font-medium'
                options={memoryRoundOptions}
                optionLabelProp='text'
              />
            </Form.Item>
          </div>
        </div>

        <div className='flex flex-center h-36px'>
          <span className='w-120px flex-none'>
            知道当前时间
            <Tip
              tip={
                <div>
                  <p className='leading-20px'>
                    开启后，Agent 能够知道准确时间日期
                  </p>
                  <p className='leading-20px'>
                    例如：“2小时后”、“明天”、 “下周三”
                  </p>
                  <p className='leading-20px'>
                    在请假、日程相关的场景，请开启使用
                  </p>
                </div>
              }
            />
          </span>

          <div className='flex-1'>
            <Form.Item
              name={['prompt_plugins', 'time_prompt']}
              valuePropName='checked'
              noStyle
            >
              <Switch size='small' />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Popover
      overlayClassName='p-0px'
      overlayInnerStyle={{
        padding: 0,
        border: '1px solid rgba(141, 141, 153, 0.08)',
      }}
      trigger='click'
      placement='bottomRight'
      content={content}
      arrow={false}
      destroyTooltipOnHide
      open={showSettings}
      onOpenChange={setShowSettings}
    >
      <div
        className={cn(
          'min-w-190px h-36px flex items-center px-10 bg-#f6f6f9 border border-line border-op-80 rounded-6px select-none cursor-pointer hover:border-primary',
          {
            'data-[open=true]:border-primary': showSettings,
          },
        )}
        data-open={showSettings}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? '.6' : '1',
        }}
      >
        {nowModel?.feature?.icon ? (
          <img
            src={nowModel?.feature?.icon || DefaultModelIcon}
            alt=''
            className='w-16px h-16px mr-8px'
          />
        ) : (
          <IconFont
            name='muxing'
            className='mr-8px text-16px text-#626999 text-op-60'
          />
        )}
        <span className='text-12px text-font font-normal mr-8'>
          {model?.model}
        </span>
        <IconFont className='text-16px ml-auto text-font' name='muxingpeizhi' />
      </div>
    </Popover>
  )
}

export const ModelSettings = React.memo(InternalModelSettings)
