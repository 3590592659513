import { useMemo, useRef } from 'react'

import { find, omit } from 'lodash-es'
import { Form, Tooltip, message } from 'antd'
import classNames from 'classnames'
import { useBoolean, useMemoizedFn } from 'ahooks'
import copy from 'copy-to-clipboard'
import type { NodeComponent } from '@/features/nodes/base'
import {
  NodeType,
  NodeOperationTypes,
  PLUGIN_STATUS,
} from '@/features/nodes/base'
import type { ActionTypesForNode, IPluginItem } from '@/apis/flow'
import type { JsonFormConfig } from '../components'
import { JsonForm, NodeFormItem, ShowMore } from '../components'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { IconFont, RequiredMark } from '@/components'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { OutputExample } from '../components/OutputExample'
import { WarningTips } from './components/WarningTips'
import { componentMap as compMap } from './components/componentMap'
import { PluginNodeFieldTypes } from './constants'

const PLUGIN_PACKAGE_NAME = 'integration.plugin.pluginAction'

export interface PluginNodeData {
  name: string
  packageName: string
  comment?: string
  plugin_id?: string
  plugin_name: string
  plugin_status?: PLUGIN_STATUS
  output?: Record<string, any>
  display_name?: string
  inputs?: {
    formConfig?: Record<string, any>
  }
  actionType?: ActionTypesForNode.INTEGRATION
}

function formatValue(value: any) {
  if (typeof value === 'string') {
    return value
  } else {
    return JSON.stringify(value, null, 2)
  }
}

export const PluginNode: NodeComponent<PluginNodeData> = props => {
  const containerRef = useRef<HTMLDivElement>(null)
  const pluginList = useNodeMetaStore(state => state.pluginList)
  const [expanded, { toggle: toggleExpanded }] = useBoolean(false)

  const [form] = Form.useForm()
  const { data } = props
  const getPlugInfo = (id: string) => {
    let currentPlugin: any = {}
    if (id) {
      currentPlugin = find(
        pluginList,
        item => item.function_id === id,
      )! as IPluginItem
    }
    return currentPlugin
  }

  const onBeforeChange = useMemoizedFn((value: any) => {
    return value
  })
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const pluginFormConfig = useMemo(() => {
    let formConfig: Record<string, any> = []
    if (data.plugin_id && data.plugin_status === PLUGIN_STATUS.ACTIVE) {
      const pluginInfo = getPlugInfo(data.plugin_id)
      formConfig = pluginInfo?.form_config || []
    }
    return formConfig as IPluginItem['form_config']
  }, [pluginList, data.plugin_id, data.plugin_status, data.actionType])

  const output = useMemo(() => {
    return getPlugInfo(data.plugin_id!)?.output || ''
  }, [data.plugin_id!, pluginList, getPlugInfo])

  const hasExpandItem = useMemo(() => {
    const hasExpandList = pluginFormConfig.filter(item => item.hasExpand)
    return !!hasExpandList.length
  }, [pluginFormConfig])

  const list = useMemo<JsonFormConfig[]>(() => {
    const formConfig = pluginFormConfig || []
    const items = formConfig.map((item, index) => {
      const componentMap = compMap as any
      const type =
        item.type === 'select' && item.llm_model
          ? PluginNodeFieldTypes.ModelSelect
          : item.type
      const Component = componentMap[type]

      return {
        className: classNames('w-100% px-16px !mb-0px'),
        required: item.required,
        hidden: !!item.hasExpand && !expanded,
        render: () => {
          const isInline = item?.layout === 'inline'
          const com = (
            <NodeFormItem
              {...omit(item, 'variableName', 'variableType')}
              layout={item.layout === 'inline' ? 'horizontal' : 'vertical'}
              tooltip={
                item.description?.length > 0 && item.example ? (
                  <div className='py-[8px] px-[8px] w-fit'>
                    <h1 className='c-[#17171D] leading-[16px] font-medium text-[12px]'>
                      {item.description}
                    </h1>
                    <div className='w-[288px] mt-[12px] border-1 rounded-[6px] overflow-hidden border-[rgba(225, 225, 229, 0.6)]'>
                      <div className='bg-[#626999] bg-opacity-6 h-[32px] flex justify-between items-center px-[12px]'>
                        <span className='text-[12px] leading-[16px] c-[#17171D]'>
                          示例
                        </span>
                        <IconFont
                          onClick={e => {
                            copy(formatValue(item.example))
                            message.success('复制成功')
                            e.stopPropagation()
                          }}
                          className='cursor-pointer'
                          fontSize={16}
                          name='fuzhi2'
                          color='#9da1bf'
                        />
                      </div>
                      <div className='c-[#17171D] px-[12px] py-[8px]'>
                        {formatValue(item.example)}
                      </div>
                    </div>
                  </div>
                ) : (
                  item.description
                )
              }
              shouldUpdate
              className={classNames({
                '!mb-0px': index === formConfig.length - 1 && !expanded,
              })}
              name={['inputs', item.variableName]}
              key={`${item.variableName}_${index}`}
              rules={[
                {
                  required: item.required,
                  message: item.placeholder,
                },
              ]}
              noStyle={isInline}
            >
              {Component({
                ...item,
                variables: props.variables,
                disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
                variableTipsContainer: containerRef?.current,
                ...(type === PluginNodeFieldTypes.Radio
                  ? {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      options: item.options?.map(el => ({
                        ...el,
                        extra: el.consume_points,
                      })),
                    }
                  : undefined),
                ...(type === PluginNodeFieldTypes.ModelSelect
                  ? {
                      getPopupContainer: () => props.nodeElement as HTMLElement,
                    }
                  : undefined),
              })}
            </NodeFormItem>
          )
          if (isInline) {
            return (
              <NodeFormItem noStyle>
                <div className='flex justify-start mb-16px'>
                  <span className='font-medium text-font text-12px/16px'>
                    {item.label}
                  </span>
                  {!!item.required && <RequiredMark />}
                  {!!item.tooltip && (
                    <Tooltip title={item.tooltip || ''}>
                      <IconFont
                        name='jieshishuimeng'
                        className='cursor-pointer mr-8px mt-1px c-font_1 c-op-40 text-14px/16px ml-4px'
                      />
                    </Tooltip>
                  )}
                  {com}
                </div>
              </NodeFormItem>
            )
          }
          return com
        },
      }
    })
    return items
  }, [
    props.nodeElement,
    props.variables,
    containerRef.current,
    props.data,
    pluginFormConfig,
    form,
  ])

  const jsonForm = useMemo(() => {
    return (
      <JsonForm
        list={list}
        form={form}
        beforeChange={onBeforeChange}
        disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
      />
    )
  }, [list, form, data, onBeforeChange, pluginList])

  const warningTips = useMemo(() => {
    if (data.plugin_status !== PLUGIN_STATUS.ACTIVE && data.plugin_id) {
      return <WarningTips status={data.plugin_status!} />
    }
    return null
  }, [data])

  return (
    <div
      ref={containerRef}
      className={classNames('w-[420px] pt-[16px]', {
        'pb-[16px]': !hasExpandItem,
        'pb-0': hasExpandItem,
      })}
    >
      <div className='px-[16px]'>{warningTips}</div>
      {jsonForm}
      {hasExpandItem ? (
        <ShowMore
          text='更多配置'
          expandedText='收起更多配置'
          expanded={expanded}
          onClick={toggleExpanded}
        />
      ) : (
        <>
          <div className='b-b-1 b-solid border-[rgba(225,225,229,0.6)] border-solid w-[100%] flex-1 my-[16px]' />
          <OutputExample
            className='px-[16px]'
            name={data.name}
            value={output}
            tooltip='节点运行完成后，这些变量将被赋值为返回的内容， 下游节点可以引用这些变量。'
          />
        </>
      )}
    </div>
  )
}

PluginNode.meta = {
  type: NodeType.PLUGIN,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'Plugin',
  actionType: NodeType.PLUGIN,
  icon: '',
  description: '',
  backgroundColor: '#fff',
  borderColor: '#E8E9ED',
  canDelete: true,
  initialData: {
    plugin_id: undefined,
    inputs: {},
    name: 'plugin',
    packageName: PLUGIN_PACKAGE_NAME,
    plugin_name: '',
    output: {},
    display_name: '',
  },
}
