import type { UITypes } from '@bty/smartsheet'
import { uiTypes } from '@bty/smartsheet'
import { Form, Switch, Tooltip } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'
import { getNewColumn, uiTypeMap } from '../utils/column'
import { ExpandableInput, IconFont, Select } from '@/components'
import { TableNameValidateRule } from '../constant/table'

interface FormItemColumnNameProps {
  editable?: boolean
  value?: string
}

function FormItemColumnName({
  editable,
  ...restProps
}: FormItemColumnNameProps) {
  return editable ? (
    <ExpandableInput
      {...restProps}
      className='!text-14px'
      placeholder='请输入字段名'
      size='small'
    />
  ) : (
    <div className='ml-12 w-100px self-center shrink-0 truncate'>
      {restProps.value}
    </div>
  )
}

interface FormItemDataTypeProps {
  editable?: boolean
  value?: UITypes
  onChange?: (value: UITypes) => void
}

function FormItemDataType({ editable, ...restProps }: FormItemDataTypeProps) {
  return editable ? (
    <Select
      className='[&>.ant-select-selector]:!h-32px'
      options={uiTypes}
      fieldNames={{
        label: 'label',
        value: 'name',
      }}
      placeholder='请选择'
      {...restProps}
    />
  ) : (
    <div className='ml-16 w-60px self-center shrink-0'>
      {restProps.value && uiTypeMap[restProps.value]}
    </div>
  )
}

interface FormItemRqdProps {
  editable?: boolean
  value?: boolean
}

function FormItemRqd({ editable, ...restProps }: FormItemRqdProps) {
  return editable ? (
    <Switch size='small' {...restProps} />
  ) : (
    <div className='ml-16 w-32px self-center shrink-0'>
      {restProps.value === true ? '必填' : '/'}
    </div>
  )
}

export interface FormListColumnsProps {
  namePath: string | (string | number)[]
  fullyEditable?: boolean
  afterAdd?: VoidFunction
}

export function FormListColumns({
  namePath,
  fullyEditable,
  afterAdd,
}: FormListColumnsProps) {
  return (
    <>
      <div className='mb-6 flex items-center h-38px bg-#f6f6f9 text-14px font-semibold text-font_1'>
        <span
          className='ml-12'
          style={{ width: `${fullyEditable ? 110 : 100}px` }}
        >
          字段名
        </span>
        <span
          className='ml-16'
          style={{ width: `${fullyEditable ? 100 : 60}px` }}
        >
          类型
        </span>
        <span className='ml-16 flex-1 inline-flex items-center'>
          字段描述
          <Tooltip placement='top' title='字段的用途或简介'>
            <IconFont
              className='cursor-pointer text-font_1 text-12px text-opacity-40 ml-5'
              name='jieshishuimeng'
            />
          </Tooltip>
        </span>
        <span className='ml-16 w-32px'>必填</span>
        <span className='ml-16 w-32px' />
      </div>
      <Form.List name={namePath}>
        {(fields, { add, remove, move }) => {
          const draggable = fullyEditable && fields.length > 1

          const deleteDisabled = !(fullyEditable && fields.length > 1)

          return (
            <>
              <DragDropContext
                onDragEnd={result => {
                  if (!result.destination) {
                    return
                  }
                  move(result.source.index, result.destination?.index)
                }}
              >
                <Droppable droppableId='database-columns-droppable'>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map((field, index) => (
                        <Draggable
                          key={field.key}
                          index={index}
                          draggableId={`${field.key}`}
                          isDragDisabled={!draggable}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={cn('flex py-6', {
                                'shadow-[0px_2px_15px_0px_rgba(0,0,0,0.15)]':
                                  snapshot.isDragging,
                              })}
                            >
                              {draggable && (
                                <span
                                  {...provided.dragHandleProps}
                                  className={cn(
                                    'flex-center w-24px h-24px cursor-move mt-4 -ml-24 text-bg_3 text-op-30 text-16px shrink-0',
                                    {
                                      'op-0 pointer-events-none': !draggable,
                                    },
                                  )}
                                >
                                  <IconFont name='drag' />
                                </span>
                              )}
                              <Form.Item
                                name={[field.name, 'column_name']}
                                className='mb-0 ml-12 shrink-0 [&_.ant-form-item-explain]:my-1'
                                style={{
                                  width: `${fullyEditable ? 110 : 100}px`,
                                }}
                                rules={
                                  fullyEditable
                                    ? [
                                        {
                                          required: true,
                                          message: '请输入字段名',
                                        },
                                        TableNameValidateRule,
                                      ]
                                    : undefined
                                }
                                noStyle={!fullyEditable}
                              >
                                <FormItemColumnName editable={fullyEditable} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'uidt']}
                                className='mb-0 ml-16 shrink-0 [&_.ant-form-item-explain]:my-1'
                                style={{
                                  width: `${fullyEditable ? 100 : 60}px`,
                                }}
                                noStyle={!fullyEditable}
                              >
                                <FormItemDataType editable={fullyEditable} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'description']}
                                className='mb-0 ml-16 flex-1 [&_.ant-form-item-explain]:my-1'
                                rules={[
                                  { required: true, message: '请输入字段描述' },
                                ]}
                              >
                                <ExpandableInput
                                  className='!text-14px'
                                  placeholder='请输入字段描述'
                                  size='small'
                                />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'rqd']}
                                className='mb-0 ml-16 w-32px shrink-0 [&_.ant-form-item-explain]:my-1'
                                noStyle={!fullyEditable}
                              >
                                <FormItemRqd editable={fullyEditable} />
                              </Form.Item>
                              <button
                                className={cn(
                                  'mt-8 ml-16 w-32px self-start shrink-0 text-16px text-bg_3 text-op-60 hover:text-error',
                                  {
                                    'op-0 pointer-events-none': deleteDisabled,
                                  },
                                )}
                                disabled={deleteDisabled}
                                onClick={() => remove(index)}
                              >
                                <IconFont name='shanshu' />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {fullyEditable && (
                <button
                  className='mt-6 flex-center text-12px/16px cursor-pointer w-200px p-10 b-1 b-line b-op-80 hover:bg-bg_3 hover:bg-op-12 rd-8px'
                  type='button'
                  onClick={event => {
                    event.preventDefault()
                    const defaultValue = getNewColumn()
                    defaultValue.id = uuidv4()
                    add(defaultValue)
                    afterAdd?.()
                  }}
                >
                  <IconFont name='add' />
                  <span className='ml-4'>添加</span>
                </button>
              )}
            </>
          )
        }}
      </Form.List>
    </>
  )
}
