import type { Edge } from 'reactflow'
import type { FieldItem, StartNodeTypes } from '@/features/nodes/start'
import type { FlowInputSchema, FlowNode, FlowStatus } from './flow'
import type { ApiResponse } from './lib'
import { request } from './lib/axios'

export interface executeDetailres {
  batch_result: {
    fail: number
    total: number
    execute: number
    success: number
  }
  created: string
  deleted: boolean
  download_file_url: string
  flow_id: string
  id: number
  state: number
  task_ids: string[]
  updated: string
  upload_file_url: string
  user_id: number
}

export interface LogItem {
  consume_points: number
  created_at: string
  updated_at: string
  flow_id: string
  durationTime: number
  show_name: string
  run_type: string
  run_result: {
    status: string
    actionOutputs: Record<string, any>
    durationTime: number
    message: {
      step_err_info: string
      step_name: string
    }
  }
  input?: string
  run_status: FlowStatus
  start_time: string
  user_name: string
  finish_time: string
  task_id: string
  test_data_id: string
  test_result_id: string
  version_id: string
  user_id: string
  first_node_type?: StartNodeTypes
  flow_node_type: StartNodeTypes
  form_config?: FieldItem[]
}

export interface logType {
  total: number
  page_no: number
  page_size: number
  data: LogItem[]
}

export interface timRuning {
  name: string
  cron: string
  type: number
  min: string
  hour: string
  week: string
  day: string
  flow_id: string
  run_param: Record<string, any>
}

export enum state_enum {
  RUNNING = 1,
  STOP = 2,
}

export enum log_state_enum {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TO_BE_RUN = 'TO_BE_RUN',
}

export interface scheduledType {
  key: number
  log_state: log_state_enum
  state: state_enum
  task_id: string
  task_name: string
}

type envtype = 'TESTING' | 'ONCE_RUN' | 'BATCH_RUN' | 'CORN_RUN'

export enum SkillFlowViewMode {
  RAW = 'raw',
  FORM = 'form',
}

export interface nodeConfigType {
  version_id: string
  version_no: number
  first_node_type: StartNodeTypes
  description: string
  form_config: FieldItem[]
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  inputs_properties?: {
    inputsViewMode: SkillFlowViewMode
    formWelcome: string
  }
  skill_desc?: string
  desc_schema?: {
    description: string
    inputs: FlowInputSchema[]
  }
}

export interface singleExecuteType {
  flow_id: string
  task_id: string
  run_result: string
  durationTime: number
  message: string & {
    step_err_info?: string
    step_name?: string
  }
  run_type: string
  status: FlowStatus
  code?: number
}

export interface ScheduledTaskOptionsType {
  config: {
    day: string
    hour: string
    min: string
    week: string
  }
  created: string
  cron: string
  deleted: boolean
  flow_id: string
  id: number
  job_id: string
  name: string
  run_param: Record<string, any>
  state: number
  task_id: string
  type: number
  updated: string
  user_id: number
}

export interface scheduledTaskLog {
  finish_time: string
  id: number
  output: string
  run_status: string
  task_id: string
}

export interface scheduledTaskDetailType {
  next_execution_time: string
  run_param: Record<string, any>
  run_record: scheduledTaskLog[]
  run_rule: string
  task_name: string
  state: state_enum
}

export interface singleExecuteParams {
  flowId: string
  env: envtype
  versionId: string
  inputData: Record<string, any>
}

// 下载模版
export function generateExcel(flow_id: string) {
  return request.get<string>(`/v1/execute/batch/generate_excel/${flow_id}`)
}

// 批量运行
export function batchExecute(body: { flow_id: string; file_url: string }) {
  return request.post<ApiResponse<any>>(
    `/v1/execute/batch/${body.flow_id}`,
    { ...body },
    {
      timeout: 1000 * 60 * 60 * 24,
      ignoreError: true,
      ignoreShowLimitedModal: true,
    },
  )
}

// 批量运行结果
export function executeDetail(flow_id: string) {
  return request.get<executeDetailres>(`/v1/execute/batch/detail/${flow_id}`)
}

// 停止运行
export function executeCancel(body: { flow_id: string }) {
  return request.post<ApiResponse<any>>(
    `/v1/execute/batch/cancel/${body.flow_id}`,
  )
}

// 重置批量任务
export function batchResetting(body: { flow_id: string }) {
  return request.post<ApiResponse<any>>(
    `/v1/execute/batch/resetting/${body.flow_id}`,
  )
}

// API运行详情
export function APIdetail(flow_id: string) {
  return request.get<nodeConfigType['form_config']>(
    `/v1/execute/rest-api/detail/${flow_id}`,
  )
}

// 单次运行
export function singleExecute(body: singleExecuteParams, workspaceId?: string) {
  return request.post<singleExecuteType>('/v1/flow/execute', body, {
    headers: workspaceId ? { 'workspace-id': workspaceId } : {},
  })
}

// 获取首节点formConfig配置
export function getFirstNodeConfig({
  version_id,
  flow_id,
  isDraft,
  workspace_id,
}: {
  flow_id: string
  version_id?: string
  isDraft?: boolean
  workspace_id?: string
}) {
  return version_id
    ? getFirstNodeConfigByVersion({
        flow_id,
        version_id,
        isDraft,
      })
    : request.get<nodeConfigType>(
        `/v1/flow/query_form_config/${flow_id}?${
          isDraft ? 'is_draft=true' : ''
        }`,
        {
          headers: workspace_id ? { 'workspace-id': workspace_id } : {},
        },
      )
}

export function getFirstNodeConfigByVersion({
  version_id,
  flow_id,
}: {
  flow_id: string
  version_id?: string
  isDraft?: boolean
}) {
  return request.get<nodeConfigType>(
    `/v1/flow/version_form_config?flow_id=${flow_id}${`&version_id=${version_id}`}`,
  )
}

export interface FLowFormConfigType {
  flow_id: string
  skill_desc: string
  inputs?: Array<{
    key: string
    description: string
    default_value?: string
    required: boolean
    field_type: string
  }>
  inputs_properties?: {
    inputsViewMode: SkillFlowViewMode
    formWelcome: string
  }
}

export function saveFlowFormConfig(values: FLowFormConfigType) {
  return request.post('/v1/flow/save_skill_desc', values)
}

// 获取日志
export function getLog(body: {
  flowId: string
  pageNo: number
  pageSize: number
  runStatus?: string
  runTypes?: string[]
  startTime?: string | null
  endTime?: string | null
  userName?: string
}) {
  return request.post<logType>('/v1/flow/query_all_execute_log', body)
}

// 启停应用
export function enableFlow(flowId: string, enable: boolean) {
  return request.put<logType>(`/v1/flow/${flowId}/enable?enable=${enable}`)
}

// 定时任务运行详情
export function scheduledTaskDetail(task_id: string, flow_id: string) {
  return request.get<scheduledTaskDetailType>(
    `/v1/execute/scheduled/task/result/${task_id}?flow_id=${flow_id}`,
  )
}

// 保存定时任务
export function saveScheduledTask(body: timRuning) {
  return request.post<any>('/v1/execute/scheduled/task', body)
}

// 获取定时任务配置查询
export function getScheduledTaskOptions(task_id: string) {
  return request.get<ScheduledTaskOptionsType>(
    `/v1/execute/scheduled/task/${task_id}`,
  )
}

// 编辑定时任务
export function setScheduledTaskOptions(body: timRuning, task_id: string) {
  return request.put<any>(`/v1/execute/scheduled/task/${task_id}`, body)
}

// 删除定时任务
export function deleteScheduledTask(task_id: string) {
  return request.delete<any>(`/v1/execute/scheduled/task/${task_id}`)
}

// 停止或启用定时任务
export function openOrStopScheduledTask(state: any, task_id: string) {
  return request.put<any>(
    `/v1/execute/scheduled/task/stop/${task_id}?state=${state}`,
  )
}

// 分页查询定时任务
export function getScheduledTaskList(flow_id: string, name = '') {
  return request.get<scheduledType[]>(
    `/v1/execute/scheduled/task/page/${flow_id}?name=${name}`,
  )
}

// 分页查询定时日志
export function getScheduledTaskLogList(
  task_id: string,
  flow_id: string,
  page_no: number,
) {
  return request.get<{
    page_no: number
    total: number
    result_log: scheduledTaskLog[]
  }>(`/v1/execute/scheduled/task/result_log/${task_id}`, {
    params: { flow_id, page_no, page_size: 20 },
  })
}
