import { useMemo } from 'react'
import { useWebSocket } from 'ahooks'
import { useWorkspaceStore } from '@/store'
import { createSocketUrl } from '@/utils/socket.ts'
import { tokenStorage } from '@/utils/storage.ts'
import { useChatStore } from '@/store/chat.ts'

interface UseUnreadMessageListenerOptions {
  agentTest?: boolean
}

export function useUnreadMessageListener(
  options?: UseUnreadMessageListenerOptions,
) {
  const fetchUnreadMessageCount = useChatStore(
    state => state.fetchUnreadMessageCount,
  )
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const token = tokenStorage.get()

  const socketUrl = useMemo(() => {
    const url = '/v1/chat/record/count_unread_msgs/user'

    return createSocketUrl(url, {
      'Workspace-Id': workspaceId,
      Authorization: token,
    })
  }, [workspaceId, token])

  useWebSocket(socketUrl, {
    onMessage: () => {
      fetchUnreadMessageCount(options?.agentTest)
    },
  })
}
