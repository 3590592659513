import styled from '@emotion/styled'
import { Button, ConfigProvider } from 'antd'
import { rgba } from 'polished'
import classNames from 'classnames'
import { IconFont } from '@/components'
import theme from '@/constants/theme'

const DefaultButton = styled(Button)`
  &.ant-btn-default {
    box-shadow: none;
    background: #fff;
  }
  &.ant-btn-default:not(:disabled):hover {
    background: ${props => rgba(props.theme.colors.bg_3, 0.08)};
  }
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${props => props.theme.colors.font};
    border: 1px solid rgba(225, 225, 229, 0.8);
  }
`

interface AddButtonProps {
  className?: string
  onClick?: () => void
  children?: React.ReactNode
  disabled?: boolean
}

export function AddButton(props: AddButtonProps) {
  const { className, children, onClick, disabled = false } = props
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: theme.colors.line,
          borderRadius: 6,
          fontSize: 12,
          boxShadow: 'none',
        },
      }}
    >
      <DefaultButton
        disabled={disabled}
        className={classNames(className, 'flex justify-center items-center')}
        block
        onClick={onClick}
        icon={<IconFont name='add' className='text-14px' />}
      >
        {children}
      </DefaultButton>
    </ConfigProvider>
  )
}
