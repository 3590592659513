import cn from 'classnames'

interface TagViewSingleSelect {
  type: 'single'
  value?: string
  onChange?: (value: string) => void
}

interface TagViewMultiSelect {
  type: 'multi'
  value?: string[]
  onChange?: (value: string[]) => void
}

type TagViewSelectProps = (TagViewMultiSelect | TagViewSingleSelect) & {
  disabled?: boolean
  options: Array<{ label: string; value: string }>
}

export function TagViewSelect(props: TagViewSelectProps) {
  const { type, options, value, onChange, disabled } = props
  const isMulti = type === 'multi'

  const onSelect = (selectValue: string) => {
    if (disabled) return
    if (isMulti) {
      const newValue = [...(value ?? [])]
      const selectIndex = newValue.findIndex(i => i === selectValue)
      if (selectIndex !== -1) {
        newValue.splice(selectIndex, 1)
      } else {
        newValue.push(selectValue)
      }
      onChange?.(newValue)
    } else {
      onChange?.(selectValue)
    }
  }

  return (
    <div className='flex flex-wrap gap-8'>
      {options.map(option => {
        const isSelect = isMulti
          ? value?.includes(option.value)
          : value === option.value
        return (
          <div
            key={option.value}
            className={cn(
              'px-10 py-7 rd-8px max-w-100% line-height-20px bg-bg_3/12  b-1 b-transparent cursor-pointer',
              {
                'cursor-not-allowed!': disabled,
                'hover:b-primary': !isSelect && !disabled,
                'bg-primary/16 font-500': isSelect,
              },
            )}
            onClick={() => onSelect(option.value)}
          >
            {option.label}
          </div>
        )
      })}
    </div>
  )
}
