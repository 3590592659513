import type { ReactNode } from 'react'
import React, { useMemo } from 'react'
import cn from 'classnames'
import { DesignRoute } from './DesignRoute'

export interface DesignRouteItem {
  type: 'route' | 'divider'
  path?: string
  fullPath?: string
  icon?: ReactNode
  text?: string
  top?: number
  count?: number
  errorIcon?: ReactNode
}

interface DesignNavigationProps {
  items: DesignRouteItem[]
  disabled?: boolean
  /**
   * 当前激活的 route
   */
  value?: string
}

function InternalDesignNavigation({
  items,
  disabled,
  value,
}: DesignNavigationProps) {
  const barTop = useMemo(() => {
    const activeRoute = items.find(each => each.path === value)
    return activeRoute?.top || 0
  }, [value])

  return (
    <div className='flex flex-col items-center flex-none shrink-0 w-60px b-r b-line b-op-60 pt-12'>
      <div
        className='absolute left-0 top-12px w-4px h-52px bg-primary rounded-r-4px scale-y-70 duration-300'
        style={{ transform: `translateY(${barTop}px) scaleY(0.7)` }}
      ></div>

      {items.map((each, index) =>
        each.type === 'route' ? (
          <DesignRoute
            key={each.path}
            active={value === each.path}
            disabled={each.path !== 'copilot' && disabled}
            path={each?.fullPath || each.path!}
            icon={each.icon}
            text={each.text!}
            extra={
              each.errorIcon ? (
                each.errorIcon
              ) : each.count ? (
                <span
                  className={cn(
                    'block min-w-12px h-12px bg-white rounded-full text-12px text-op-80 font-medium text-center absolute top-4 right-12',
                    value === each.path ? 'text-primary' : 'text-bg_3',
                  )}
                >
                  {each.count}
                </span>
              ) : null
            }
          />
        ) : (
          <hr key={index} className='w-36px mt-4 mb-12' />
        ),
      )}
    </div>
  )
}

export const DesignNavigation = React.memo(InternalDesignNavigation)
