import { memo, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import { Divider } from 'antd'
import { useLogger } from '../context'
import { FlowStatus } from '@/apis/flow'
import type { LoggerTab } from '../type'
import { RunningLoading } from '@/components'
import { parseCardData } from '@/features/logger/utils/parse'
import { ErrorResult, LoggerResult } from './Result'

interface LoggerOutputProps {
  onChangeTab: (tab: LoggerTab) => void
}

export const LoggerOutput = memo((props: LoggerOutputProps) => {
  const { onChangeTab } = props

  const {
    loading,
    output,
    logs,
    correctResult,
    remarks,

    diff,
    setDiff,
  } = useLogger()

  const handleShowError = useMemoizedFn(() => {
    onChangeTab('log')
  })

  const handleToggleDiff = useMemoizedFn(() => {
    setDiff(prev => !prev)
  })

  const knowledgeBucket = useMemo(() => {
    if (!logs || !output || !output.result) return false
    const outNode = logs[logs.length - 2]

    if (!outNode || outNode.input?.memoryType !== 'searchMemory') return false

    // 这里根据 mimetype 判断是否是不合理，但上下文环境中只能获取到这个
    const cardList = parseCardData(output.result)
    if (cardList.some(each => !each.mimetype)) return false

    return outNode.input.memory
  }, [logs])

  if (loading && !output?.result && !output?.errorMsg) {
    return (
      <div className='flex-center flex-col h-100%'>
        <RunningLoading loading={loading} />
      </div>
    )
  }

  if (output?.status === FlowStatus.FAIL) {
    return (
      <div className='px-16px'>
        <ErrorResult output={output} onShowError={handleShowError} />
      </div>
    )
  }

  return (
    <div className='px-16px h-full'>
      <div className='flex h-full py-20px border-1px rounded-8px'>
        {diff && (
          <LoggerResult
            title='正确答案'
            result={correctResult}
            knowledgeBucket={knowledgeBucket}
            remarks={remarks}
            diff={true}
            operations={['copy']}
          />
        )}
        {diff && <Divider type='vertical' className='h-auto' />}
        <LoggerResult
          knowledgeBucket={knowledgeBucket}
          result={output.result ?? ''}
          operations={correctResult ? ['diff', 'copy'] : ['copy']}
          diff={diff}
          scrollEnd={loading}
          onDiff={handleToggleDiff}
        />
      </div>
    </div>
  )
})
