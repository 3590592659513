import ReactJson from 'react-json-view'
import { Tooltip } from 'antd'
import { useBoolean } from 'ahooks'
import classNames from 'classnames'
import { isString } from 'lodash-es'
import { useMemo } from 'react'
import { IconFont } from '@/components'

interface IOutputExample {
  value: any
  tooltip?: string
  name?: string
  showExpanded?: boolean
  className?: string
  onToggle?: (expanded: boolean) => void
}
export function OutputExample(props: IOutputExample) {
  const {
    value,
    tooltip,
    name = null,
    showExpanded = false,
    onToggle,
    className = '',
  } = props
  const [expanded, { toggle: toggleExpanded }] = useBoolean(false)
  const isStr = useMemo(() => {
    return isString(value)
  }, [value])
  return (
    <div className={classNames(className)}>
      <div
        className='header flex items-center justify-between'
        onClick={() => {
          toggleExpanded()
          onToggle?.(!expanded)
        }}
      >
        <div className='flex items-center'>
          <span className='font-500 text-12px line-height-16px'>输出</span>
          <Tooltip title={tooltip} placement='top'>
            <IconFont
              name='jieshishuimeng'
              className='cursor-pointer mr-12px c-font_1 c-op-40 text-14px ml-4px'
            />
          </Tooltip>
        </div>
        {!showExpanded && (
          <div className='flex-1 justify-end flex'>
            <IconFont
              name='arrow'
              className='text-8px ml-10 c-[rgba(141,141,153,0.6)]'
              style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
            />
          </div>
        )}
      </div>
      {(expanded || showExpanded) && (
        <div className='mt-8px'>
          {isStr ? (
            <p className='bg-[rgba(98,105,153,0.06)] text-12px/16px p-16px rounded-8px c-[rgb(221,105,0)]'>
              {value}
            </p>
          ) : (
            <ReactJson
              name={name}
              src={value}
              style={{
                backgroundColor: 'rgba(98,105,153,0.06)',
                fontSize: '12px',
                lineHeight: '16px',
                padding: '16px',
                borderRadius: '8px',
              }}
              enableClipboard={false}
              theme={'summerfruit:inverted'}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          )}
        </div>
      )}
    </div>
  )
}
