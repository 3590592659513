import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useWorkspaceStore } from '@/store'
import { storageRouterMap } from '../contants'

export function useRouterPathMemory() {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentWorkspaceId } = useWorkspaceStore()

  useEffect(() => {
    const storageRouter =
      storageRouterMap[location?.pathname?.split('/')?.[1] || ''] || ''
    if (storageRouter) {
      localStorage.setItem(storageRouter, location?.pathname + location?.search)
    }
  }, [location])

  useEffect(() => {
    if (currentWorkspaceId) {
      Object.values(storageRouterMap).map(item => localStorage.removeItem(item))
    }
  }, [currentWorkspaceId])

  const navigateWithMemory = (key: string) => {
    navigate(localStorage.getItem(storageRouterMap?.[key] || '') || `/${key}`)
  }

  return {
    navigateWithMemory,
  }
}
