import type { ReactNode } from 'react'

export function ErrorContent({
  content,
  extra,
  report,
}: {
  content: string
  extra: ReactNode
  report: ReactNode
}) {
  return (
    <div className='b-1 b-#ff5219 b-op-12 bg-#fff1ed py-12px px-16px rounded-8px text-#e54a16 mb--6px'>
      {/* <h3 className="font-500 mb-12">Error:</h3> */}
      <p className='whitespace-pre-line text-14px leading-22px'>{content}</p>
      {extra}
      {report}
    </div>
  )
}
