import { memo, useState } from 'react'
import { Form, message, Tooltip } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Modal, Button, IconFont } from '@/components'
import type { ParagraphItem } from '../ParagraphListItem'
import type { Paragraph } from '@/pages/datastores/types/paragraph'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { saveQAGroup } from '@/apis/datastore/api'
import type { IQAQuestionItem, QAAnswer } from '@/apis/datastore/types'
import KeywordsEditor from '@/pages/datastores/components/KeywordsEditor'
import { QuestionForm } from './components/QuestionEditor'
import { AnswerForm } from './components/AnswerEditor'

export function RequireIcon() {
  return (
    <span className='color-error ml-5 text-[18px] line-height-14px position-relative top-[3px]'>
      *
    </span>
  )
}

export function answerListToMap(answers: QAAnswer[]) {
  return (answers ?? []).reduce(
    (acc, e) => {
      acc[e.title] = e.value
      return acc
    },
    {} as Record<string, string>,
  )
}

export interface QAItemEditorProps {
  open?: boolean
  title?: string | React.ReactElement
  file_id: number
  chunk_id?: number
  insert_above_chunk_id?: number
  paragraph: OptionalKey<ParagraphItem, 'key'>
  matched_keywords?: string[]
  operationType: 'update' | 'add'
  isSingle?: boolean
  callback: (value: Paragraph) => void
  onCancel?: () => void
}

export const QAParagraphItemEditorModal = memo((props: QAItemEditorProps) => {
  const {
    open,
    title = '编辑',
    file_id,
    insert_above_chunk_id,
    paragraph,
    matched_keywords = [],
    operationType,
    isSingle,
    callback,
    onCancel,
  } = props

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const firstQuestion = Form.useWatch(['questions', 0], form)

  const onOk = useMemoizedFn(async () => {
    await form.validateFields()
    const newInfo: ParagraphItem['qa_info'] = form.getFieldsValue()

    const oldQuestionIds = (paragraph.qa_info?.questions ?? []).map(e => e.id)
    const newQuestionIds = (newInfo?.questions ?? []).map(e => e.id)
    const deleteQuestionIds = oldQuestionIds.filter(
      e => !newQuestionIds.includes(e),
    )

    setLoading(true)
    try {
      const groupItem = await saveQAGroup({
        file_id,
        answer_group_id: paragraph.chunk_id,
        insert_above_answer_group_id: insert_above_chunk_id,
        chunks_to_delete: deleteQuestionIds as number[],
        contents: (newInfo?.questions ?? []).map(e => {
          const res = {} as IQAQuestionItem
          if (!e.new) {
            res.chunk_id = e.id
          }
          res.content = e.value
          res.keywords = e.keywords
          res.enable = true

          return res as IQAQuestionItem
        }),
        answers: answerListToMap(newInfo?.answers ?? []),
      })

      const newQAInfo = groupItem.qa_info ?? newInfo

      const newParagraph = {
        ...paragraph,
        ...groupItem,
        key: paragraph.chunk_id! ?? groupItem.chunk_id,
        keywords: newQAInfo?.questions?.[0].keywords || paragraph.keywords,
        qa_info: newQAInfo,
      }

      callback(newParagraph)
      message.success(operationType === 'update' ? '修改已保存' : '添加成功')
      onCancel?.()
    } catch (e) {
      console.error(e)
      message.error(operationType === 'update' ? '保存失败' : '添加失败')
    }

    setLoading(false)
  })

  return (
    <Modal
      title={title}
      open={open}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
      width={1020}
      styles={{
        body: {
          minHeight: '420px',
          height: '67vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          padding: 0,
        },
      }}
    >
      <OverlayScrollbarsComponent
        className='flex-1 px-32 py-24 overflow-auto'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        <Form
          className='[&_.ant-form-item-required]-before:hidden!'
          form={form}
          layout={'vertical'}
          initialValues={paragraph?.qa_info ?? {}}
        >
          {operationType === 'update' && (
            <div className='flex items-center mb-8px'>
              <span className='font-medium'>关键词</span>
              <Tooltip title='关键词根据主问题自动生成'>
                <IconFont
                  className='ml-4px cursor-pointer c-#8D8D99/40'
                  name='jieshishuimeng'
                />
              </Tooltip>
              <span className='ml-8px c-#8D8D99'>
                ({(firstQuestion?.keywords ?? []).length}/20)
              </span>
            </div>
          )}

          {operationType === 'update' && (
            <Form.Item noStyle name={['questions', '0', 'keywords']}>
              <KeywordsEditor
                className='mt-8px mb-24px'
                layout='horizontal'
                showHeader={false}
                readOnly={false}
                highlightKeywords={matched_keywords}
              />
            </Form.Item>
          )}

          <div className='flex items-center mb-8px'>
            <span className='bg-#7B61FF/12 c-#7B61FF text-12px rounded-4px p-4px mr-8px'>
              问
            </span>
            <span className='font-medium'>问题</span>
          </div>

          <Form.List name='questions'>
            {(fields, { add, remove }) => (
              <QuestionForm
                className='mb-24px'
                showKeywords={false}
                matchedKeywords={matched_keywords}
                fields={fields}
                onAdd={add}
                onRemove={remove}
                isSingle={isSingle}
              />
            )}
          </Form.List>

          <Form.List name='answers'>
            {(fields, { add, remove }) => (
              <AnswerForm
                className='mb-24px'
                file_id={file_id}
                fields={fields}
                onAdd={add}
                onRemove={remove}
              />
            )}
          </Form.List>
        </Form>
      </OverlayScrollbarsComponent>

      <div className='p-16px flex items-center justify-end gap-12 border-t-1px border-t-#E1E1E5/60'>
        <Button
          onClick={() => {
            form.resetFields()
            onCancel?.()
          }}
        >
          取消
        </Button>
        <Button loading={loading} type='primary' onClick={onOk}>
          保存
        </Button>
      </div>
    </Modal>
  )
})
