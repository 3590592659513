import { Tooltip } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import classNames from 'classnames'
import React, {
  type Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import dayjs from 'dayjs'
import { IconFont, Input, RangePickerWrapper, Select } from '@/components'
import type { StaticTimeTypes } from '@/apis/monitor'
import { AreaChart } from './AreaChart'
import { BarChart } from './BarChart'
import { AbnormalFirstResponseTimeStats } from './AbnormalFirstResponseTimeStats'

type ChartType = 'area' | 'bar' | 'abnormal-stats'

interface FilterOption {
  label: string
  value: string
  subOptions?: FilterOption[]
}

interface FilterType {
  options?: FilterOption[]
  ignoreSubOptions?: boolean
  onChange: (value: {
    type: StaticTimeTypes
    start_date?: string
    end_date?: string
  }) => void
}

interface SearchType {
  placeholder?: string
}

interface ChartService<T> {
  call: (...args: any[]) => Promise<T[]>
  params?: Record<string, unknown>
  transform?: (data: T[]) => T[] | void
}

interface ChartDashboardPaneProps<
  T extends { title: string; value: unknown[] },
> {
  className?: string
  chartClassName?: string
  type: ChartType | ChartType[]
  charts?: T[]
  /**
   * 每个图表是否有明细入口
   */
  detail?:
    | false
    | {
        onClick: (index: number) => void
      }
  color?: string | string[]
  xField: string
  yField: string
  showTooltip?: boolean
  title: string
  /**
   * 给 title 用
   */
  tooltip?: string
  /**
   * 跟随在 title 和 tooltip 之后
   */
  addonAfterTitle?: React.ReactNode
  tooltipTitle?: string
  filter?: boolean | FilterType
  searcher?: false | SearchType
  /**
   * 使用 service 时 charts 失效
   */
  service?: ChartService<T>
  /**
   * 点击明细时触发，可能是图表中单条数据明细，也可能是图表明细
   */
  onDetailClick?: (
    xFieldValue: string,
    flowId?: string,
    chartId?: string | number,
  ) => void
}

export interface ChartDashboardPaneRef {
  refresh: () => void
}

const CHART_MAP = {
  area: AreaChart,
  bar: BarChart,
  'abnormal-stats': AbnormalFirstResponseTimeStats,
}

const DEFAULT_FILTER_OPTIONS: FilterType['options'] = [
  {
    label: '本周',
    value: 'weekly',
  },
  {
    label: '本月',
    value: 'monthly',
    subOptions: [
      {
        label: '按天',
        value: 'daily',
      },
      {
        label: '按周',
        value: 'weekly',
      },
    ],
  },
  {
    label: '本季度',
    value: 'quarterly',
    subOptions: [
      {
        label: '按天',
        value: 'daily',
      },
      {
        label: '按周',
        value: 'weekly',
      },
      {
        label: '按月',
        value: 'monthly',
      },
    ],
  },
  {
    label: '本年',
    value: 'yearly',
    subOptions: [
      {
        label: '按天',
        value: 'daily',
      },
      {
        label: '按周',
        value: 'weekly',
      },
      {
        label: '按月',
        value: 'monthly',
      },
      {
        label: '按季度',
        value: 'quarterly',
      },
    ],
  },
  {
    label: '自定义',
    value: 'custom',
  },
]

export const DEFAULT_CUSTOM_DATE: NonNullable<RangePickerProps['value']> = [
  dayjs().subtract(8, 'day'),
  dayjs().subtract(1, 'day'),
]

function InternalChartDashboardPane<
  T extends { title: string; value: unknown[] },
>(
  {
    className,
    chartClassName,
    type,
    charts,
    detail,
    color,
    xField,
    yField,
    showTooltip,
    title,
    tooltip,
    addonAfterTitle,
    filter,
    searcher,
    service,
    tooltipTitle,
    onDetailClick,
  }: ChartDashboardPaneProps<T>,
  ref: Ref<ChartDashboardPaneRef>,
) {
  const [serviceData, setServiceData] = useState<T[]>()

  const isObjectFilter = typeof filter === 'object'

  const hasFilter = filter === true || isObjectFilter

  const filterOptions = isObjectFilter
    ? filter.options || DEFAULT_FILTER_OPTIONS
    : DEFAULT_FILTER_OPTIONS

  const [filterValue, setFilterValue] = useState<string | undefined>(
    filterOptions ? 'custom' : undefined,
  )

  const [subFilterValue, setSubFilterValue] = useState<string | undefined>(
    () => {
      if (isObjectFilter && filter.ignoreSubOptions) {
        return
      }
      const value = filterOptions?.find(el => el.value === filterValue)
      return value?.subOptions?.[0].value
    },
  )

  const [customDate, setCustomDate] =
    useState<RangePickerProps['value']>(DEFAULT_CUSTOM_DATE)

  const innerParams = useMemo(() => {
    const params = { ...service?.params }
    params.statistic_time = filterValue
    if (subFilterValue) {
      params.display_time = subFilterValue
    }
    if (filterValue === 'custom') {
      params.start_date = customDate?.[0]
        ?.startOf('d')
        ?.format('YYYY-MM-DD HH:mm:ss')
      params.end_date = customDate?.[1]
        ?.endOf('d')
        ?.format('YYYY-MM-DD HH:mm:ss')
    }
    return params
  }, [service?.params, filterValue, subFilterValue, customDate])

  const loadDataRef = useRef(async (params: ChartService<T>['params']) => {
    if (!service) {
      return
    }
    const response = await service.call(params)
    service.transform?.(response)
    setServiceData(response)
  })

  useEffect(() => {
    loadDataRef.current(innerParams)
  }, [innerParams])

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => {
        loadDataRef.current(innerParams)
      },
    }),
    [innerParams],
  )

  const innerCharts = service ? serviceData : charts

  return (
    <div className={classNames('w-full p-24 bg-white rounded-12px', className)}>
      <div className='flex items-center'>
        <span className='inline-flex items-center text-16px text-font font-semibold mr-12'>
          {title}
          {tooltip ? (
            <Tooltip title={tooltip} placement='bottom' destroyTooltipOnHide>
              <span className='ml-4 text-14px text-font_1 text-op-40'>
                <IconFont name='jieshishuimeng' />
              </span>
            </Tooltip>
          ) : null}
        </span>
        {addonAfterTitle}
        {hasFilter && (
          <>
            <Select
              className='w-140px h-36px'
              popupClassName='[&_.rc-virtual-list-holder-inner]:gap-4'
              value={filterValue}
              options={filterOptions}
              onChange={(value, option) => {
                setFilterValue(value)
                if (filter === true || !filter.ignoreSubOptions) {
                  const subValue = (option as FilterOption).subOptions?.[0]
                    .value
                  setSubFilterValue(subValue)
                }
                const isCustom = value === 'custom'
                if (isCustom && customDate !== DEFAULT_CUSTOM_DATE) {
                  setCustomDate(DEFAULT_CUSTOM_DATE)
                }
                if (isObjectFilter) {
                  filter.onChange({
                    type: value as StaticTimeTypes,
                    start_date: isCustom
                      ? DEFAULT_CUSTOM_DATE[0]!.format('YYYY-MM-DD HH:mm:ss')
                      : undefined,
                    end_date: isCustom
                      ? DEFAULT_CUSTOM_DATE[1]!.format('YYYY-MM-DD HH:mm:ss')
                      : undefined,
                  })
                }
              }}
            />
            {subFilterValue && (
              <Select
                className='w-140px h-36px ml-12'
                popupClassName='[&_.rc-virtual-list-holder-inner]:gap-4'
                value={subFilterValue}
                options={
                  filterOptions?.find(el => el.value === filterValue)
                    ?.subOptions
                }
                onChange={value => setSubFilterValue(value)}
              />
            )}
            {filterValue === 'custom' && (
              <RangePickerWrapper
                className='h-36px ml-12'
                value={customDate}
                allowClear={false}
                onChange={values => {
                  setCustomDate(values)
                  if (isObjectFilter) {
                    filter.onChange({
                      type: 'custom' as StaticTimeTypes,
                      start_date: values?.[0]?.format('YYYY-MM-DD HH:mm:ss'),
                      end_date: values?.[1]?.format('YYYY-MM-DD HH:mm:ss'),
                    })
                  }
                }}
              />
            )}
          </>
        )}
        {typeof searcher === 'object' && (
          <Input
            className='w-200px ml-auto'
            prefix={<IconFont className='text-16px' name='search' />}
            placeholder={searcher.placeholder}
          />
        )}
      </div>
      <div className='max-w-full flex mt-16 overflow-x-auto overflow-y-hidden [&>.vchart+.vchart]:ml-72'>
        {innerCharts?.map(({ title, value }, index) => {
          const chartType = typeof type === 'string' ? type : type[index]
          const Chart = CHART_MAP[chartType]
          const showDetail = detail && value.length
          const chartColor = Array.isArray(color) ? color[index] : color

          return (
            <Chart
              key={index}
              id={index}
              className={classNames(
                'vchart flex-1 max-w-560px min-w-420px',
                chartClassName,
              )}
              title={title}
              addonAfterTitle={
                showDetail ? (
                  <span
                    className='text-14px text-#9e9e9e ml-12 cursor-pointer'
                    onClick={() => detail.onClick(index)}
                  >
                    明细
                  </span>
                ) : undefined
              }
              values={value}
              xField={xField}
              yField={yField}
              color={chartColor}
              showTooltip={showTooltip}
              tooltipTitle={tooltipTitle}
              yFieldLabel={title}
              onXFieldDetailClick={onDetailClick}
              onDetailClick={() => detail && detail.onClick(index)}
            />
          )
        })}
      </div>
    </div>
  )
}

export const ChartDashboardPane = React.forwardRef(
  InternalChartDashboardPane,
) as unknown as <T extends { title: string; value: unknown[] }>(
  props: ChartDashboardPaneProps<T> & React.RefAttributes<unknown>,
) => React.ReactElement
