import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect } from 'react'
import { noop } from 'lodash-es'
import { useRequest } from 'ahooks'
import { getWorkspaceApiKey } from '@/apis/workspace.ts'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'

export interface IWorkspaceKeysContext {
  apiKey?: string
  fetchApiKey: () => Promise<string>
}

export const WorkspaceKeysContext = createContext<IWorkspaceKeysContext>({
  fetchApiKey: noop as any,
})

export const WorkSpaceKeysProvider: FC<PropsWithChildren> = ({ children }) => {
  const { applicationInfo } = useAgentEdit()

  const { data: apiKey, refreshAsync: fetchApiKey } = useRequest(
    getWorkspaceApiKey,
    {
      ready: !!applicationInfo?.workspaceId,
      defaultParams: [applicationInfo!.workspaceId],
    },
  )

  useEffect(() => {
    const refreshApiKey = () => {
      if (!apiKey) {
        fetchApiKey()
      }
    }
    window.addEventListener('focus', refreshApiKey)
    return () => window.removeEventListener('focus', refreshApiKey)
  }, [apiKey])

  return (
    <WorkspaceKeysContext.Provider
      value={{
        apiKey,
        fetchApiKey,
      }}
    >
      {applicationInfo && children}
    </WorkspaceKeysContext.Provider>
  )
}

export function useWorkspaceKeys() {
  return useContext(WorkspaceKeysContext)
}
