import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useLocalStorageState } from 'ahooks'

const TOOL_BAR_COLLAPSE_KEY = 'toolbar-collapse'
export function useToolbarCollapse() {
  const [collapsed, setCollapsed] = useLocalStorageState<boolean>(
    TOOL_BAR_COLLAPSE_KEY,
    {
      defaultValue: true,
    },
  )

  const toolbarClassnames = useMemo(() => {
    return classNames('m-0', {
      'top-12px! left-16px!': collapsed,
      'h-full': !collapsed,
    })
  }, [collapsed])

  const onToolBarOpen = useCallback(() => setCollapsed(false), [])

  const onToolBarClose = useCallback(() => setCollapsed(true), [])

  return {
    collapsed,
    toolbarClassnames,
    onToolBarOpen,
    onToolBarClose,
  }
}
