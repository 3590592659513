import { memo, useEffect } from 'react'
import { isUndefined } from 'lodash-es'
import type { SchemaFormProps } from '@bty/ui-components'
import {
  Button,
  IconFont,
  JsonEditor,
  NodeForm,
  TagViewSelect,
} from '@/components'
import {
  type FieldExtraItem,
  type FieldItem,
  SelectViewMode,
} from '@/features/nodes/start'
import { Track } from '@/features/track'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils.ts'
import { OverflowContent } from './OverflowContent'
import {
  CodeDebugFlowFileUpload,
  CodeDebugInput,
  CodeDebugSelect,
  CodeDebugTextArea,
  FieldFormItem,
} from './StyledComponent'

interface DebugPanelContentProps {
  trackMetaData: object
  rightBarHeight: number
  toggleRightBar: (action: 'open' | 'close') => void
  debuggerVariableList: debuggerVariableType[]
  handleValuesChansge: any
  runBySingleStep: any
  btnLoading: boolean
  debugVariables: any
}

export type debuggerVariableType = FieldItem & FieldExtraItem

function CustomJSONEditor(props: any) {
  // 延迟更新的效果， 因为一开始的 value 是 undefined, JsonEditor 性能原因用的是 defaulutValue
  return (
    <>
      {!isUndefined(props?.value) && (
        <JsonEditor {...props} value={props.value} />
      )}
    </>
  )
}

const componentMap: SchemaFormProps['fieldComponentsMap'] = {
  json: (schema, control, meta) => {
    return (
      <FieldFormItem schema={schema} control={control} meta={meta}>
        <CustomJSONEditor />
      </FieldFormItem>
    )
  },
  input: (schema, control, meta) => (
    <FieldFormItem schema={schema} control={control} meta={meta}>
      <CodeDebugInput size='large' className='important:text-14px' />
    </FieldFormItem>
  ),
  textarea: (schema, control, meta) => (
    <FieldFormItem schema={schema} control={control} meta={meta}>
      <CodeDebugTextArea autoSize={{ minRows: 3, maxRows: 6 }} />
    </FieldFormItem>
  ),
  select: (schema, control, meta) => (
    <FieldFormItem schema={schema} control={control} meta={meta}>
      {schema.viewMode === SelectViewMode.Flat ? (
        <TagViewSelect type='single' options={schema.options} />
      ) : (
        <CodeDebugSelect size='large' options={schema.options} />
      )}
    </FieldFormItem>
  ),
  multiSelect: (schema, control, meta) => (
    <FieldFormItem schema={schema} control={control} meta={meta}>
      {schema.viewMode === SelectViewMode.Flat ? (
        <TagViewSelect type='multi' options={schema.options} />
      ) : (
        <CodeDebugSelect
          size='large'
          mode='multiple'
          options={schema.options}
        />
      )}
    </FieldFormItem>
  ),
  file: (schema, control, meta) => (
    <FieldFormItem schema={schema} control={control} meta={meta}>
      <CodeDebugFlowFileUpload
        supportFileConfig={schema.supportFileConfig}
        supportFileTypes={schema?.supportFileTypes}
      />
    </FieldFormItem>
  ),
}

function DebugPanelContent(props: DebugPanelContentProps) {
  const {
    rightBarHeight,
    toggleRightBar,
    debuggerVariableList,
    handleValuesChansge,
    runBySingleStep,
    btnLoading,
    debugVariables,
    trackMetaData,
  } = props

  const [form] = NodeForm.useForm()

  useEffect(() => {
    if (debuggerVariableList.length > 0) {
      // 特地处理 json 类型的变量， 初始化值为 '', 规避 undefined 的情况
      const jsonFieldValue: { [key: string]: string } = {}
      debuggerVariableList.forEach((item: FieldItem) => {
        if (item.type === 'json' && !debugVariables[item.variableName]) {
          jsonFieldValue[item.variableName] = ''
        }
        form.setFieldsValue({
          ...debugVariables,
          ...jsonFieldValue,
        })
      })
    }
  }, [debugVariables, form, debuggerVariableList])

  return (
    <>
      <div
        style={{
          height: `calc(100vh - ${rightBarHeight}px - 48px)`,
        }}
      >
        <div
          className='px-16px py-8px flex flex-justify-between flex-items-center'
          style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
        >
          <div className='font-size-16px font-medium'>运行调试</div>
          <div
            onClick={() => toggleRightBar('close')}
            className='w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
          >
            <IconFont name='guanbi' className='text-16px' />
          </div>
        </div>
        <OverflowContent occupiedHeight='48' className='p-16px pb-0px'>
          <div className='text-16px color-#17171D font-500 mb-24px'>输入</div>
          {debuggerVariableList?.length > 0 ? (
            <NodeForm
              form={form}
              showEmpty={false}
              schema={transformConfig2SchemaConfig(debuggerVariableList)}
              onValuesChange={handleValuesChansge}
              fieldComponentsMap={componentMap}
              className='!h-auto'
            />
          ) : (
            <div className='mb-16px h-64px flex py-14px bg-#626999 bg-op-08 b-rd-8px items-center justify-center color-#8D8D99 color-op-60'>
              暂无输入
            </div>
          )}
          <div className='sticky bottom-0px pb-16px bg-#FAFAFC z-100'>
            <Track event='flow_code_node_run' properties={trackMetaData}>
              <Button
                type='primary'
                block
                onClick={runBySingleStep}
                loading={btnLoading}
              >
                开始运行
              </Button>
            </Track>
          </div>
        </OverflowContent>
      </div>
    </>
  )
}

export const DebugPanel = memo(DebugPanelContent)
