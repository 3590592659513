import type { Node } from 'prosemirror-model'
import type { EditorNode } from './type'
import { EditorNodeType } from './type'

export function getNodeFromText(text: string) {
  const pattern = /{{{.*?}}}/g
  const textArray = text.split(pattern).map(each => ({
    type: EditorNodeType.text,
    text: each,
  }))
  const matches = text.match(pattern)

  const resultArray: EditorNode[] = []
  if (matches) {
    for (const match of matches) {
      const [key, type, value] = match.slice(3, -3).split(':')
      resultArray.push({
        type: type as EditorNodeType,
        text: value,
        key,
      })
    }
  }

  let index = 0
  const result: EditorNode[] = []
  while (index < textArray.length || index < resultArray.length) {
    if (textArray[index]) {
      result.push(textArray[index])
    }
    if (resultArray[index]) {
      result.push(resultArray[index])
    }
    index += 1
  }

  return result.filter(
    each => !(each.type === EditorNodeType.text && each.text === ''),
  )
}

export function getProsemirrorJsonFromNode(nodes: EditorNode[]) {
  const nodeList: any[] = []

  nodes.forEach(each => {
    if (each.type === EditorNodeType.text) {
      nodeList.push({
        type: 'text',
        text: each.text,
      })
    } else if (each.type === EditorNodeType.select) {
      nodeList.push({
        type: 'pe-select',
        attrs: {
          key: each.key,
          placeholder: each.text,
          text: each.text,
        },
      })
    } else {
      nodeList.push({
        type: 'pe-input',
        attrs: {
          key: each.key,
          placeholder: each.text,
          text: each.text,
        },
      })
    }
  })

  return {
    type: 'doc',
    content: [{ type: 'paragraph', content: nodeList }],
  }
}

export function findNodeStartEnd(node: Node, doc: Node) {
  let start = -1
  let end = -1

  doc.descendants((child, pos) => {
    if (child === node) {
      start = pos
      end = pos + child.nodeSize
      return false
    }
    return true
  })

  return { start, end }
}

export function getTextFromDoc(doc: Node) {
  let fullText = ''

  doc.descendants(node => {
    if (['pe-input', 'pe-select'].includes(node?.type.name)) {
      fullText += node.textContent ? '' : node.attrs.placeholder
    } else if (node.isText) {
      fullText += node.text
    } else if (!node.isTextblock) {
      fullText += '\n'
    }
  })

  return fullText
}
