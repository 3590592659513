import classNames from 'classnames'
import { Dropdown } from 'antd'
import type { FC } from 'react'
import type { RoleCode } from '@/apis/authority.ts'
import { RoleName } from '@/apis/authority.ts'
import { IconFont } from '@/components'
import { useAuthRoleOptions } from '@/components/userSearchTable/useAuthRoleOptions.tsx'

interface UserRoleSelectProps {
  disabled?: boolean
  selectedKey?: RoleCode
  onChange?: (roleCode: RoleCode) => void
  className?: string
}

export const UserRoleSelect: FC<UserRoleSelectProps> = props => {
  const { selectedKey, disabled, className, onChange } = props
  const roleOptions = useAuthRoleOptions(true)
  const isDisabled = disabled || !roleOptions.length

  return (
    <Dropdown
      overlayStyle={{ minWidth: 240 }}
      disabled={isDisabled}
      menu={{
        items: roleOptions,
        selectedKeys: selectedKey ? [selectedKey] : [],
        onClick: ({ key }) => {
          onChange?.(key as RoleCode)
        },
      }}
      trigger={['click']}
    >
      <div className='flex cursor-pointer'>
        <span
          className={classNames('m-r-4 c-font', className, {
            'c-op-40': isDisabled,
            'c-op-80': !isDisabled,
          })}
        >
          {selectedKey ? RoleName[selectedKey] : '请选择'}
        </span>
        <div className='flex flex-justify-center flex-items-center w-[16px] h-[16px] b-rd-[4px] hover-bg-bg_3 hover-bg-op-8'>
          <IconFont
            className={classNames('text-6px c-font_1', {
              'c-op-40': isDisabled,
            })}
            name='desc'
          />
        </div>
      </div>
    </Dropdown>
  )
}
