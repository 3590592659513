import { Col, Form, Row } from 'antd'
import { useMemo } from 'react'
import type {
  AgentPluginInputComponent,
  AgentPluginProperties,
} from '@bty/chat-types'
import * as PluginConfFormItem from './PluginConfFormItem'

export interface PluginConfFixedProps {
  conf: NonNullable<AgentPluginProperties['inputs']>
}

export function PluginConfFixed({ conf }: PluginConfFixedProps) {
  const parseWebStatus = Form.useWatch('parse_web_status')

  const sortedConf = useMemo(
    () =>
      Object.entries(conf).sort((one, another) =>
        one[0].localeCompare(another[0]) > 0 ? -1 : 1,
      ),
    [conf],
  )

  const renderComponent = (key: string, value: AgentPluginInputComponent) => {
    if (value.component === 'card') {
      return (
        <PluginConfFormItem.CardRadioGroup
          name={key}
          required={value.required}
          displayName={value.display_name}
          options={value.options.map(el => ({
            ...el,
            extra: el.consume_points,
          }))}
          compact
        />
      )
    }

    if (value.component === 'switch') {
      return (
        <PluginConfFormItem.Switch
          name={key}
          displayName={value.display_name}
          required={value.required}
          extra={value.description}
        />
      )
    }

    if (value.component === 'select') {
      return value.llm_model ? (
        <PluginConfFormItem.LLM
          name={key}
          displayName={value.display_name}
          required={value.required}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          options={value.options}
        />
      ) : (
        <></>
      )
    }

    if (value.component === 'slider') {
      return (
        <PluginConfFormItem.Slider
          name={key}
          displayName={value.display_name}
          required={value.required}
          min={value.limit.min}
          max={value.limit.max}
          disabled={key === 'parse_web_count' && !parseWebStatus}
        />
      )
    }

    if (value.component === 'input') {
      return (
        <PluginConfFormItem.Input
          name={key}
          displayName={value.display_name}
          required={value.required}
          placeholder={value.placeholder}
        />
      )
    }
  }

  return (
    <>
      <p className='mb-20 text-14px font-medium'>固定配置</p>
      <Row className='mb-32' gutter={[12, 20]}>
        {sortedConf.map(([key, value]) => (
          <Col key={key} span={value.component === 'switch' ? 24 : 12}>
            {renderComponent(key, value)}
          </Col>
        ))}
      </Row>
    </>
  )
}
