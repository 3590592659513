import type { TableType } from '@bty/smartsheet'
import type {
  AgentDetailResponse,
  AgentFlowSkill,
  AgentListSystemSkillItem,
  AgentPluginProperties,
  AgentPluginSkill,
  AgentRuleConfig,
  AgentSkillBaseRequest,
  UploadFileSupportTypes,
} from '@bty/chat-types'
import { workspaceStorage } from '@/utils/storage'
import { request } from './lib'
import type { PartitionCategoryType } from './datastore/types'

export type AgentId = string

export type AgentRunStatus = 'SUCCEEDED' | 'FAILED'
export type AgentRunType =
  | 'AGENT_TESTING'
  | 'MULTIPLE_MODELS'
  | 'CHATBOT'
  | 'AGENT'
  | 'AGENT_OAPI'
  | 'COPILOT'
  | 'DINGTALK_STREAM_ROBOT'
  | 'DINGTALK_ORGAPP_ROBOT'
  | 'WEIXIN_KF'
  | 'WEIXIN_MP'
  | 'WEIXIN_WORK_APP'
  | 'FEISHU_APP_BOT'

export interface AgentLogParams {
  agent_id: string
  user_name?: string
  run_status?: AgentRunStatus
  run_type?: AgentRunType
  run_types?: AgentRunType[]
  page_no: number
  page_size: number
  start_time?: string
  end_time?: string
  application_id?: string
}

export interface AgentChatParams {
  robot_id: string
  user_name?: string
  record_status?: AgentRunStatus
  run_type?: string[]
  page_no: number
  page_size: number
  start_time?: string
  end_time?: string
  application_id?: string
}

export interface AgentLogItem {
  conversation_id: string
  agent_id: string
  title: string
  latest_create_time: string
  total_records: number
  run_status: AgentRunStatus
  run_type: AgentRunType
  user_name: string | null
  user_id: number
  total_upgrade_consume: number
  client_type: 'PC' | 'MOBILE'
}

export interface AgentLogsResponse {
  data: AgentLogItem[]
  total: number
  page_no: number
  page_size: number
}

export interface AgentLogDetailParams {
  conversation_id: string
  agent_id: string
  page_no: number
  page_size: number
  application_id?: string
}

export interface AgentLogDetailItem {
  create_time: string
  file_name: string
  file_type: string
  record_status: AgentRunStatus
  response: string | null
  response_ext: any
  session_id: string
  user_content: string
}

export interface AgentLogDetailResponse {
  data_list: AgentLogDetailItem[]
  page_no: number
  page_size: number
  total_count: number
  total_pages: number
}

export interface AgentDataset {
  description: string
  user_description?: string
  id: string
  dataset_id: string
  num: number
  threshold: number
  is_enable: boolean
  partition_id: number
  partition_icon_color: string
  partition_icon: string
  partition_name: string
  partition_category: PartitionCategoryType
  partition_file_count: number
  file_num: number
  hit_strategy: number
  ranking_strategy: number
  show_knowledge_url: boolean
  query_mode?: {
    mode: 'AI' | 'FORCE'
    desc?: string
    tag: boolean
  }
  similarity: number
  random_result: boolean
}

export interface AgentDatasetRequest {
  flow_id: string
  version_id: string
  partition_id: number
  user_description?: string
  description: string
  is_enable: boolean
  num: number
  threshold: number
  hit_strategy: number
  ranking_strategy: number
  show_knowledge_url: boolean
}

export enum AgentFeedbackStatusEn {
  Processed = '已处理',
  Pending = '待处理',
  Ignored = '不处理',
}

export interface AgentChatFeedbackParams {
  app_id: string
  status?: AgentFeedbackStatusEn
  start_time: string
  end_time: string
  content?: string
  response?: string
  user_opinion?: string
  page_no?: number
  page_size?: number
}

export interface AgentChatFeedbackItem {
  content: string
  created_at: string
  created_by: number
  creator_name: string
  feedback_id: string
  handle_notes?: string
  reason_type: string[]
  record_id: string
  response: string
  run_type: string
  status: AgentFeedbackStatusEn
  updated_at: string
  updated_by: number
  updater_name: string
  user_opinion: string
}

export function fetchAgentLogs(params: AgentLogParams) {
  return request.post<AgentLogsResponse>(
    '/v1/chat/conversation/agent_log',
    params,
    {
      headers: { 'Application-Id': params.application_id },
    },
  )
}

// 查询Agent聊天记录
export function getAgentChatHistory(params: AgentChatParams) {
  return request.post('/v1/chat/agent_log', params, {
    headers: { 'Application-Id': params.application_id },
  })
}

export function fetchAgentLogDetail(params: AgentLogDetailParams) {
  return request.get('/v1/chat/agent/log/detail', {
    headers: { 'Application-Id': params.application_id },
    params,
  })
}

// 查询 Agent 点踩记录
export function getAgentChatFeedBackList(params: AgentChatFeedbackParams) {
  return request.post('/v1/chat/feedback/list', params)
}

// Agent 点踩记录导出
export function exportAgentChatFeedBackList(params: AgentChatFeedbackParams) {
  return request.post('/v1/chat/feedback/export', params)
}

// 查询 Agent 点踩记录详情
export function getAgentChatFeedBackDetail(feedback_id: string) {
  return request.get('/v1/chat/feedback/detail', {
    params: { feedback_id },
  })
}

// Agent 点踩状态变更
export function fetchHandleFeedback(payload: {
  feedback_id: string
  status: string
  handle_notes?: string
}) {
  return request.post<AgentDetailResponse>('/v1/chat/feedback/handle', payload)
}
/**
 * @description chat 页获取 bot 详情用
 * @param botId 其实是 flow_id
 */
export function fetchAgentDetail(botId: string) {
  return request.post<AgentDetailResponse>('/v1/agent/detail', {
    flow_id: botId,
  })
}

/**
 * @description 获取Agent规则
 */
export function fetchAgentRule(flow_id: string, version_id?: string) {
  return request.post<AgentRuleConfig>('/v1/agent/rule/detail', {
    flow_id,
    version_id,
  })
}

export function getRelatedQuestionPrompt(flow_id: string, version_id: string) {
  return request.get<string>('/v1/agent/relate_question', {
    params: { flow_id, version_id },
  })
}

/**
 * @description 更新Agent规则
 */
export function updateAgentRule(
  rule: AgentRuleConfig,
  flow: { flow_id: string; version_id: string },
) {
  return request.post('/v1/agent/rule/update', Object.assign(rule, flow))
}

/**
 * @description 获取Agent知识列表
 */
export function fetchAgentDatasetList(flow_id: string, version_id: string) {
  return request.post<AgentDataset[]>('/v1/agent/dataset/list', {
    flow_id,
    version_id,
  })
}

/**
 * @description 创建Agent知识
 */
export function createAgentDataset(data: AgentDatasetRequest) {
  return request.post('/v1/agent/dataset/create', data)
}

export function updateAgentDataset(
  data: Pick<
    AgentDatasetRequest,
    'flow_id' | 'version_id' | 'show_knowledge_url' | 'description'
  >,
) {
  return request.post('/v1/agent/dataset/update', data)
}

export function switchAgentDataset(
  data: AgentDatasetRequest & { dataset_id: string },
) {
  return request.post('/v1/agent/dataset/switch', data)
}

/**
 * @description 解绑agent知识库
 */
export function unbindAgentDataset(data: {
  flow_id: string
  version_id: string
  partition_id: number
}) {
  return request.post('/v1/agent/dataset/untie', data)
}

/**
 * @description 获取Agent技能列表
 */
export function fetchAgentSkillList(
  flow_id: string,
  version_id: string,
  workspace_id: string,
) {
  return request.post<Required<Pick<AgentDetailResponse, 'utility' | 'flows'>>>(
    '/v1/agent/skill/list',
    {
      flow_id,
      version_id,
      workspace_id,
    },
  )
}

/**
 * @description 获取Agent系统工具列表
 */
export function fetchAgentFunctionList() {
  return request.post<AgentPluginSkill[]>('/v1/agent/utility/function/list')
}

export function queryAgentPlugin(
  function_id: string,
): Promise<AgentPluginSkill> {
  return request.get(`/v1/agent/utility/${function_id}/detail`)
}

export function updateAgentPlugin(
  function_id: string,
  values: {
    agent_id: string
    description: string
    properties: AgentPluginProperties
    parameters: Record<string, any>
  },
) {
  return request.post(`/v1/agent/utility/${function_id}/update`, {
    ...values,
  })
}

export function createAgentSkill(data: AgentSkillBaseRequest) {
  return request.post('/v1/agent/skill/create', data)
}

export function updateAgentSkill(
  data: AgentSkillBaseRequest & { id?: string },
) {
  return request.post('/v1/agent/skill/update', data)
}

export function deleteAgentSkill(
  id: string,
  flow_id: string,
  version_id: string,
) {
  return request.post('/v1/agent/skill/delete', { id, flow_id, version_id })
}

export function enableAgentSkill(data: {
  id: string
  flow_id: string
  version_id: string
  enabled: boolean
}) {
  return request.post('/v1/agent/skill/enabled', data)
}

export function createAgentSkillFunction(
  function_ids: string[],
  flow_id: string,
  version_id: string,
) {
  return request.post('/v1/agent/utility/function/create', {
    function_ids,
    flow_id,
    version_id,
  })
}

export function deleteAgentSkillFunction(
  function_id: string,
  flow_id: string,
  version_id: string,
) {
  return request.post('/v1/agent/utility/function/delete', {
    function_id,
    flow_id,
    version_id,
  })
}

export function enableAgentSkillFunction(data: {
  flow_id: string
  version_id: string
  function_id: string
  enabled: boolean
}) {
  return request.post('/v1/agent/utility/function/enabled', data)
}

export function getAgentRel(data: { flow_id: string; workspace_id: string }) {
  return request.post('/v1/agent/flow/rel', data)
}

// robot对话记录分享
export function shareAgentContent(conversation_id: string) {
  return request.get(
    `/v1/agent/share/content?conversation_id=${conversation_id}`,
  )
}

// robot对话快照记录分享
export function getConversationListBySnapshotId(snapshot_id: string) {
  return request.get(`/v1/agent/share/conversation/list/${snapshot_id}`)
}

// robot对话记录分享
export function getConversationListByConversationId(
  conversation_id: string,
  robot_id: string,
) {
  return request.get(
    `/v1/chat/records?conversation_id=${conversation_id}&page_number=1&robot_id=${robot_id}`,
  )
}

// robot对话记录取消分享
export function deleteShareAgentContent(snapshot_id: string) {
  return request.get(
    `/v1/agent/delete/share/conversation?snapshot_id=${snapshot_id}`,
  )
}

// 继续此会话
export function continueAgentConversation(snapshot_id: string) {
  return request.get(`/v1/agent/this/conversation?snapshot_id=${snapshot_id}`)
}

// 获取单个会话
export function getConversationById(conversation_id: string) {
  return request.get(`/v1/chat/conversation?conversation_id=${conversation_id}`)
}

// 根据会话id获取快照
export function getSnapshotIdByConversationId(conversation_id: string) {
  return request.get(`/v1/agent/query/snapshotId/${conversation_id}`)
}

export function createDatasetByTemplate(data: {
  flow_id: string
  version_id: string
  type: number
}) {
  return request.post<string>('/v1/agent/dataset/template', data)
}

export function toggleIsRelatedQuestionGenerate(data: {
  conversation_id: string
  session_id: string
  session_related_questions_enabled: boolean
}) {
  return request.post('/v1/agent/session_related_questions', data)
}

interface BindTableForAgentPayload {
  flow_id: AgentId | null
  version_id: string | null
  database_id: string
  table_id: string
  source_id: string
}
export function bindTableForAgent(
  payload: BindTableForAgentPayload,
): Promise<TableType> {
  return request.post('/v1/agent/database/create', payload)
}

export function unbindDatabase(
  flow_id: string,
  version_id: string,
  appId: string,
) {
  return request.post(
    '/v1/agent/database/untie',
    {
      flow_id,
      version_id,
    },
    {
      headers: {
        'Application-Id': appId,
      },
    },
  )
}

interface BindDatabasePayload {
  flow_id: string
  version_id: string
  database_id: string
  source_id: string
}
export function bindDatabase(data: BindDatabasePayload) {
  return request.post('/v1/agent/database/add_existing_database', {
    ...data,
    db_params: null,
  })
}

interface EnableDatabasePayload {
  flow_id: string
  version_id: string
  tool_id: string
  enabled: boolean
}
export function enableTableForAgent(data: EnableDatabasePayload) {
  return request.post('/v1/agent/database/enabled', data)
}

interface DeleteTableForAgentPayload {
  database_id: string
  table_id: string
}
export function deleteTableForAgent(data: DeleteTableForAgentPayload) {
  return request.post('/v1/agent/database/delete', data)
}

interface GetAgentConfigByTypePayload {
  robot_id: string
  run_type: string
}

interface GetAgentConfigByTypeRes {
  conversation_id: string
  config: {
    index: number
    title_suffix: string
    model_config: {
      model: string
      channel: string
      temperature?: number
      top_p?: number
      presence_penalty?: number
      frequency_penalty?: number
      prompt_plugins?: { time_prompt?: boolean; length_prompt?: number }
    }
  }
}

export function getAgentConfigByType(params: GetAgentConfigByTypePayload) {
  return request.get<GetAgentConfigByTypeRes[]>('/v1/chat/conversation/list', {
    params,
  })
}

export function deleteAgentConversation(id: string) {
  return request.delete('/v1/chat/conversation', {
    params: {
      conversation_id: id,
    },
  })
}

export function getAgentShortcutsList(flow_id: string, version_id: string) {
  return request.post<{
    flows?: AgentFlowSkill[]
    utility?: Array<AgentListSystemSkillItem>
  }>('/v1/agent/shortcuts/list', {
    flow_id,
    version_id,
    workspace_id: workspaceStorage.get(),
  })
}

export function getAgentUploadFileSupportFileTypes(agent_id: string) {
  return request.post<UploadFileSupportTypes[]>('/v1/chat/agent/file-support', {
    agent_id,
  })
}
