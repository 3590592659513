import classNames from 'classnames'

interface INewFeatureBadgeProps {
  className?: string
  style?: Record<string, any>
}
export default (props: React.PropsWithChildren<INewFeatureBadgeProps>) => {
  const { children, className, style } = props
  return (
    <div
      style={{
        background: 'linear-gradient(270deg, #FF3A5A 0%, #FF69BB 100%)',
        ...style,
      }}
      className={classNames(
        'inline-flex c-#fff h-16px rounded-[8px_8px_8px_0] text-10px line-height-16px px-6px',
        className,
      )}
    >
      {children}
    </div>
  )
}
