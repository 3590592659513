import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import classNames from 'classnames'

import { useDebounceFn, useMount } from 'ahooks'
import { IconFont } from '@/components'
import { fetchTokenSizeByWord } from '@/apis/datastore/api'

export function RequireIcon() {
  return (
    <span className='color-error ml-5 text-[18px] line-height-14px position-relative top-[3px]'>
      *
    </span>
  )
}

interface CustomizeTokenProps {
  maxCount: number
  value: string
  file_id: number
}

export const CustomizeToken = forwardRef(
  ({ maxCount, value, file_id }: CustomizeTokenProps, _ref) => {
    const [wordTokenInfo, setWordTokenInfo] = useState({
      usedToken: 0,
      maxToken: 0,
    })

    const { run: getWorkSize, flush: getWorkSizeImmediately } = useDebounceFn(
      async (showToast = false) => {
        const data = await fetchTokenSizeByWord({ file_id, content: value })
        setWordTokenInfo({
          usedToken: data.token_consumption,
          maxToken: data.max_token,
        })
        if (data.token_consumption > data.max_token && showToast) {
          setWordTokenInfo({
            usedToken: data.token_consumption,
            maxToken: data.max_token,
          })
          throw new Error('字符超出限制')
        }
      },
      { wait: 300 },
    )

    useEffect(() => {
      getWorkSize()
    }, [value])
    useMount(() => {
      getWorkSizeImmediately()
    })

    useImperativeHandle(_ref, () => {
      return {
        validate: async () => {
          return {
            success: !(wordTokenInfo.usedToken > wordTokenInfo.maxToken),
            msg: '字符超出限制',
          }
        },
      }
    }, [wordTokenInfo])

    return (
      <div className='mt-12px text-12px/12px c-#8D8D99 flex items-center'>
        <IconFont name='zifushu' />
        <p className='ml-4px'>
          <span
            className={classNames({
              'c-error': wordTokenInfo.usedToken > wordTokenInfo.maxToken,
            })}
          >
            {wordTokenInfo.usedToken}
          </span>
          /{maxCount}
        </p>
      </div>
    )
  },
)
