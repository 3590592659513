import React from 'react'
import { Tooltip } from 'antd'
import { Version } from '../const'
import { IconFont } from '@/components'
import type { VersionInfo } from '@/apis/version'
import type { litmitedTitleConfig, limitedVersionConfig } from './limitedConfig'
import { ActionButton } from './ActionButton'

type setHoveringColumn = (version: Version | undefined) => void

interface ListItemProps {
  children?: React.ReactNode
  version: Version
  setHoveringColumn: setHoveringColumn
}

interface LimitedTitleProps {
  children?: React.ReactNode
  setHoveringColumn: setHoveringColumn
  config: litmitedTitleConfig
  versionInfo: VersionInfo | undefined
  onButtonClick: () => void
  createOrderLoading: boolean
}

export function LitmitedTitle(props: LimitedTitleProps) {
  const {
    config,
    setHoveringColumn,
    versionInfo,
    onButtonClick,
    createOrderLoading,
  } = props
  const handleMouseEnter = () => {
    setHoveringColumn(config.version)
  }
  const handleMouseLeave = () => {
    setHoveringColumn(undefined)
  }
  return (
    <div
      className='w-272px pt-24px pb-12px px-30px cursor-pointer'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='font-size-20px font-800 mb-8px flex'>{config.title}</div>
      <div className='font-size-14px line-height-20px font-400 color-#8D8D99 mb-12px'>
        {config.desc}
      </div>
      <div className={`mb-16px ${config?.showTextInPrice ? 'mt-24px' : ''}`}>
        {config?.showTextInPrice ? (
          <span className='font-size-24px font-800'>
            {config?.showTextInPrice}
          </span>
        ) : (
          <>
            <span className='font-size-24px line-height-24px font-800 mr-4px'>
              ¥
            </span>
            <span className='font-size-30px line-height-36px tracking-[-1px] pr-2px'>
              {config.price}
            </span>
            <span className='font-size-14px line-height-16px'>
              /{config.billingCycle}
            </span>
            {config.discount && (
              <span className='ml-4px font-size-14px line-height-16px color-#8D8D99 line-through'>
                ¥{config.discount}
              </span>
            )}
          </>
        )}
      </div>
      <ActionButton
        product_code={versionInfo?.product_code}
        config={config}
        onButtonClick={onButtonClick}
        createOrderLoading={createOrderLoading}
      />
    </div>
  )
}

function ListItem(props: ListItemProps) {
  const { setHoveringColumn } = props
  const handleMouseEnter = () => {
    setHoveringColumn(props.version)
  }
  const handleMouseLeave = () => {
    setHoveringColumn(undefined)
  }
  return (
    <div
      className='w-272px flex justify-center py-12px items-center'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
    </div>
  )
}

interface SubTitleRowProps {
  setHoveringColumn: setHoveringColumn
  subTitle: string
}

export function SubTitleRow(props: SubTitleRowProps) {
  const { setHoveringColumn, subTitle } = props
  return (
    <div className='flex flex-wrap cursor-pointer'>
      <div className='w-232px pl-24px pt-12px pb-6px flex items-center'>
        <span className='ml-10px font-size-12px color-#8D8D99 color-op-60'>
          {subTitle}
        </span>
      </div>
      <ListItem version={Version.FREE} setHoveringColumn={setHoveringColumn} />
      <ListItem version={Version.TEAM} setHoveringColumn={setHoveringColumn} />
      <ListItem
        version={Version.ENTERPRISE}
        setHoveringColumn={setHoveringColumn}
      />
    </div>
  )
}

interface ListRowProps {
  setHoveringColumn: setHoveringColumn
  config: limitedVersionConfig
}

function getBooleanIcon(props: boolean) {
  return props ? (
    <IconFont name='xiaogou' />
  ) : (
    <IconFont className='color-#8D8D99 color-op-40' name='guanbi' />
  )
}

export function ListRow(props: ListRowProps) {
  const { setHoveringColumn, config } = props
  return (
    <div className='flex flex-wrap hover:bg-#626999 hover:bg-op-12 b-rd-8px cursor-pointer'>
      <div className='w-232px pl-24px py-12px flex items-center pr-16px'>
        <span className='ml-10px'>{config.title} </span>
        {config?.tooltip && (
          <Tooltip title={config.tooltip} placement='bottom'>
            <IconFont
              name='jieshishuimeng'
              className='color-#8D8D99 color-op-40 ml-4px'
            />
          </Tooltip>
        )}
      </div>
      <ListItem version={Version.FREE} setHoveringColumn={setHoveringColumn}>
        <span className='relative'>
          {config?.type === 'boolean'
            ? getBooleanIcon(config.Free as boolean)
            : config.Free}
          {config?.limit && (
            <span className='absolute left-24px color-#8D8D99 w-100px top-0px'>
              限{config.limit}次
            </span>
          )}
        </span>
      </ListItem>
      <ListItem version={Version.TEAM} setHoveringColumn={setHoveringColumn}>
        {config?.type === 'boolean'
          ? getBooleanIcon(config.Team as boolean)
          : config.Team}
      </ListItem>
      <ListItem
        version={Version.ENTERPRISE}
        setHoveringColumn={setHoveringColumn}
      >
        {config?.type === 'boolean'
          ? getBooleanIcon(config.Enterprise as boolean)
          : config.Enterprise}
      </ListItem>
    </div>
  )
}
