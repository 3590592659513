import type { FC } from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Tooltip, message } from 'antd'
import classNames from 'classnames'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { AppLogo, IconFont, usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import { colors } from '@/constants/theme'
import { VariableRegex } from '@/constants/common'
import { PartitionCategoryType } from '@/apis/datastore/types'
import { SelectComponent } from './components/SelectComponent'
import { TagVariableEditor } from './components/TagVariableEditor'

export const PartitionCategory = {
  [PartitionCategoryType.Document]: '文档',
  [PartitionCategoryType.QA]: '问答',
}

interface DatasetSelectProps {
  disabled?: boolean
  value?: string | number
  onChange?: (value: string | number) => void
  variables: any[]
  variableTipsContainer?: HTMLElement
}

export const DatasetSelect: FC<DatasetSelectProps> = props => {
  const datasetList = useNodeMetaStore(state => state.datasetList)
  const ref = useRef<HTMLDivElement>(null)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { value } = props
  const [open, setOpen] = useState(false)

  const list = useMemo(() => {
    return datasetList.map(item => {
      return {
        label: item.partition_name,
        value: item.partition_id,
        category: item.partition_category,
        icon: item.partition_icon,
        iconColor: item.partition_icon_color,
      }
    })
  }, [datasetList])

  const onVariableEditorChange = useCallback(
    (v: string) => {
      // 如果是变量，或者是已有的标签，直接添加
      if (
        VariableRegex.test(v) ||
        list.findIndex(item => String(item.value) === v) !== -1
      ) {
        // 如果是变量，直接添加
        const newValue = v
        props.onChange?.(newValue)
      } else {
        message.warning('该知识库不存在')
      }
      setOpen(false)
    },
    [list, props.value, props.onChange],
  )

  const pageModal = usePageModal()
  const showPageModal = (
    disabled: boolean | undefined,
    partition_id: number,
  ) => {
    if (disabled) {
      return
    }
    pageModal.show({
      url: `/datastores/${currentWorkspaceId}/${partition_id}/documents`,
    })
  }

  const getKnowledgeItem = useCallback(
    (val: number | string) => {
      const item = list.filter(item => item.value === val)
      return item?.[0] || {}
    },
    [list],
  )

  return (
    <div ref={ref}>
      <SelectComponent
        getPopupContainer={() => ref.current!}
        options={list}
        open={open}
        onOpenChange={setOpen}
        value={Array.isArray(value) ? value : [value]}
        onChange={val => {
          props.onChange?.(val as any)
          setOpen(false)
        }}
        handlePreviewClick={targetVal => {
          showPageModal(props.disabled, targetVal!)
        }}
        extraRender={
          <div
            className={classNames({
              'b-t-1 b-t-line b-t-op-60 mt-4 pt-4': list.length,
            })}
          >
            <div
              className='w-100% h-32px cursor-pointer flex-center text-12px hover:bg-bg_3 hover:bg-op-8 rounded-6px'
              onClick={e => {
                e.stopPropagation()
                pageModal.show({ url: '/datastores' })
              }}
            >
              <IconFont name='add' className='text-16px' />
              创建知识库
            </div>
          </div>
        }
        triggerRender={(_, ref) => {
          const knowledgeItem = getKnowledgeItem(value as any)
          return (
            <>
              <div
                className={classNames(
                  'relative px-8 py-4 b-1 rounded-6px bg-bg_3 bg-op-6 flex-center-between gap-8',
                  {
                    'b-primary': open,
                    'hover:b-primary b-bg_3 b-op-0': !open,
                  },
                )}
                ref={ref}
              >
                <div className='flex gap-8 flex-wrap w-full'>
                  {(Array.isArray(value) ? value : value ? [value] : []).map(
                    (value, index) => {
                      const isVariable = VariableRegex.test(value)
                      return (
                        <div
                          key={value + index}
                          className={classNames(
                            'rounded-4px !m-0 flex items-center text-12px/16px pr-7px',
                            {
                              'bg-tips bg-op-12 c-tips b-tips': isVariable,
                            },
                          )}
                        >
                          {isVariable ? (
                            value
                          ) : (
                            <>
                              <AppLogo
                                type='icon'
                                size={24}
                                fillSize={12}
                                color={knowledgeItem.iconColor ?? colors[0]}
                                value={knowledgeItem.icon ?? 'app'}
                                className='rounded-4px mr-8px'
                              />
                              {knowledgeItem?.label}
                              {knowledgeItem?.category && (
                                <span className='rounded-4px box-border b-1px b-color-#E1E1E5 b-op-60 ml-8px color-#8D8D99 px-4px py-2px  text-11px/12px'>
                                  {PartitionCategory[knowledgeItem.category]}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      )
                    },
                  )}
                  <TagVariableEditor
                    variables={props.variables}
                    variableTipsContainer={props.variableTipsContainer}
                    onChange={onVariableEditorChange}
                    placeholder={
                      props.value
                        ? ''
                        : props?.variables?.length
                          ? '请选择知识库或输入表示知识库ID的变量'
                          : '请选择知识库'
                    }
                  />
                </div>
                {!!props.value && !VariableRegex.test(props.value as any) && (
                  <div
                    className='w-16px h-16px cursor-pointer flex-center text-12px hover:bg-bg_3 hover:bg-op-8 rounded-6px'
                    onClick={e => {
                      e.stopPropagation()
                      showPageModal(props.disabled, Number(props.value!))
                    }}
                  >
                    <Tooltip placement='top' title='查看知识库详情'>
                      <IconFont
                        name='super-link'
                        className='c-#626999 c-op-60 text-16px'
                      />
                    </Tooltip>
                  </div>
                )}

                <div className={classNames('w-16px h-16px flex-center')}>
                  <IconFont name='arrow' className='text-7px c-font_1' />
                </div>
              </div>
            </>
          )
        }}
      />
    </div>
  )
}
