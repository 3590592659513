import { useMemoizedFn } from 'ahooks'
import type { RefObject, MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useMouseDrag } from '../hooks/use-mouse-drag'

interface Props {
  scrollRef: RefObject<HTMLElement>
}

export function Scrollbar({ scrollRef }: Props) {
  const scrollbarRef = useRef<HTMLDivElement>(null)
  const scrollbarThumbRef = useRef<HTMLDivElement>(null)
  const topRef = useRef(0)
  const [has, setHas] = useState(false)
  const [show, setShow] = useState(false)

  const handleScroll = useMemoizedFn(() => {
    if (!scrollbarThumbRef.current || !scrollRef.current) return
    const content = scrollRef.current
    const thumb = scrollbarThumbRef.current
    if (content.offsetHeight >= content.scrollHeight) {
      setHas(false)
      return
    }
    setHas(true)
    thumb.style.height = `${
      (content.offsetHeight / content.scrollHeight) * 100
    }%`
    thumb.style.top = `${(content.scrollTop / content.scrollHeight) * 100}%`
  })

  const handleScrollbarClick = useMemoizedFn((e: MouseEvent) => {
    if (
      !scrollbarRef.current ||
      !scrollbarThumbRef.current ||
      !scrollRef.current
    ) {
      return
    }

    const content = scrollRef.current
    const scrollbarRect = scrollbarRef.current.getBoundingClientRect()
    const thumbRect = scrollbarThumbRef.current.getBoundingClientRect()
    const top = e.clientY - scrollbarRect.y - thumbRect.height / 2
    const percent = top / scrollbarRect.height
    scrollRef.current.scrollTo({
      top: percent * content.scrollHeight,
      behavior: 'smooth',
    })
  })

  const handleMoveStart = useMemoizedFn(() => {
    setShow(true)
    if (!scrollbarThumbRef.current) return
    topRef.current = scrollbarThumbRef.current.offsetTop
  })

  const handleMove = useMemoizedFn(diffY => {
    if (
      !scrollbarThumbRef.current ||
      !scrollbarRef.current ||
      !scrollRef.current
    ) {
      return
    }

    const content = scrollRef.current
    const scrollbarRect = scrollbarRef.current.getBoundingClientRect()
    const top = topRef.current + diffY
    const percent = top / scrollbarRect.height
    scrollRef.current.scrollTo({
      top: percent * content.scrollHeight,
    })
  })

  const handleMoveEnd = useMemoizedFn(() => {
    setShow(false)
  })

  const handleMouseDown = useMouseDrag({
    onDragStart: handleMoveStart,
    onDragChange: handleMove,
    onDragEnd: handleMoveEnd,
  })

  useEffect(() => {
    if (!scrollRef.current) return
    const content = scrollRef.current
    handleScroll()
    content.addEventListener('scroll', handleScroll)

    return () => {
      content.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (!scrollbarThumbRef.current) return
    const content = scrollbarThumbRef.current

    const handle = (event: any) => {
      event.preventDefault()
      event.stopPropagation()
      handleMouseDown(event)
    }

    content.addEventListener('mousedown', handle)

    return () => {
      content.removeEventListener('mousedown', handle)
    }
  }, [scrollRef])

  return (
    <div
      ref={scrollbarRef}
      className={classNames('text-editor-scrollbar', {
        'text-editor-scrollbar-hidden': !has,
        'text-editor-scrollbar-show': show,
      })}
      onClick={handleScrollbarClick}
    >
      <div ref={scrollbarThumbRef} className='text-editor-scrollbar-thumb' />
    </div>
  )
}
