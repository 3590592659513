import {
  databaseInfo2NormalAppInfo,
  normalAppInfo2DatabaseInfo,
} from '@/features/database/utils/database.ts'
import { updateDatabase } from '@/apis/database/api.ts'
import { useDatabase } from '@/features/database/provider/DatabaseProvider.tsx'
import { useModal } from '@/hooks/useModal.tsx'
import { CreateModal } from '@/components/createAppModal'

export default function useAppInfoEdit() {
  const { baseInfo, refresh } = useDatabase()
  const [editModal] = useModal(CreateModal)

  const onEdit = () => {
    if (baseInfo) {
      editModal.open({
        title: '编辑数据库',
        logoType: 'emoji',
        defaultValue: databaseInfo2NormalAppInfo(baseInfo),
        fieldConfig: {
          name: { placeholder: '请输入数据库名称，例如：商品库' },
          description: {
            placeholder:
              '请描述数据库的用途或内容，让Agent更加理解此数据库的功能',
          },
        },
        onFinish: async data => {
          await updateDatabase(baseInfo.id, normalAppInfo2DatabaseInfo(data))
          refresh()
          editModal.close()
        },
      })
    }
  }

  return { onEdit }
}
