import { Popover, Button } from 'antd'
import { useState } from 'react'
import classNames from 'classnames'
import { useRequest } from 'ahooks'
import { IconFont } from '@/components'
import { fetchPermissionNotifications } from '@/apis/notification'
import emptyImage from '@/assets/app/rocket2.png'
import { usePushNotification } from '@/hooks/usePushNotification'
import { PushNotificationListItem } from './PushNotificationListItem'

interface PushNotificationProps {
  className?: string
}

export function PushNotification({ className }: PushNotificationProps) {
  const [open, setOpen] = useState(false)

  const { data: notifications, runAsync: fetchNotifications } = useRequest(
    fetchPermissionNotifications,
    {
      manual: true,
    },
  )

  const { hasUnreadNotification, clearUnreadNotification } =
    usePushNotification()

  const content = (
    <div className='w-400px max-h-500px flex flex-col'>
      <div className='shrink-0 pl-12 py-12 border-b border-[rgba(225,225,229,0.6)] text-16px font-medium text-font'>
        消息通知
      </div>
      {notifications?.length ? (
        <ul className='flex-1 overflow-x-hidden overflow-y-auto'>
          {notifications?.map((el, index) => (
            <PushNotificationListItem
              key={el.id}
              className={
                index < notifications.length - 1
                  ? 'border-bottom after:border-[rgba(225,225,229,0.6)] after:left-12 after:right-12 after:w-auto'
                  : ''
              }
              notificationId={el.id}
              role={el.role}
              application_id={el.application_id}
              applyUserName={el.apply_user_name}
              approvedStatus={el.approved_status}
              approvedUserName={el.approved_user_name}
              workspaceName={el.workspace_name}
              flowId={el.flow_id}
              flowName={el.flow_name}
              createAt={el.create_at}
              type={el.type}
              refresh={fetchNotifications}
            />
          ))}
        </ul>
      ) : (
        <div className='pt-40 pb-60 text-center'>
          <img className='w-140px object-contain mx-auto' src={emptyImage} />
          <span className='text-14px/16px text-bg_3 text-op-48'>
            还没有消息
          </span>
        </div>
      )}
    </div>
  )

  return (
    <Popover
      rootClassName='[&_.ant-popover-inner]:p-0 [&_.ant-popover-inner]:rounded-8px [&_.ant-popover-inner]:backdrop-blur-16px [&_.ant-popover-inner]:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.12)]'
      open={open}
      content={content}
      placement='topLeft'
      trigger='click'
      title={null}
      arrow={false}
      destroyTooltipOnHide
      onOpenChange={open => {
        setOpen(open)
        if (open) {
          fetchNotifications()
          if (hasUnreadNotification) {
            clearUnreadNotification()
          }
        }
      }}
    >
      <Button
        className={classNames(
          'data-[open=true]:bg-white data-[open=true]:bg-opacity-60 hover:!bg-white hover:!bg-opacity-60 text-0',
          {
            'relative after:absolute after:content-empty after:w-12px after:h-12px after:top-3 after:right-2 after:bg-#ff5219 after:rounded-full after:border after:border-solid after:border-#e3e0f6 hover-after:border-#F4F2FB data-[open=true]-after:border-#F4F2FB':
              hasUnreadNotification,
          },
          className,
        )}
        type='text'
        icon={
          <IconFont
            className='text-18px text-#626999 text-op-60'
            name='xiaoxi'
          />
        }
        data-open={open}
      />
    </Popover>
  )
}
