import { memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { Checkbox } from 'antd'
import type { IHead, IRow } from '../type'
import { useResizeTable } from './context'

interface CheckCellProps {
  no: number
  select: boolean
  row: IRow
  flex: string
}

export const CheckCell = memo(({ no, select, row, flex }: CheckCellProps) => {
  const { onRowSelect } = useResizeTable()

  const id = useMemo(() => row.key, [row.key])

  const handleRowSelect = useMemoizedFn(() => {
    onRowSelect(id)
  })

  const className = useMemo(
    () => classNames('resize-table-cell', 'resize-table-cell-sticky'),
    [],
  )

  return (
    <div className={className} style={{ flex }} onClick={handleRowSelect}>
      <div className='resize-table-cell-content '>
        <div
          className={classNames('resize-table-cell-checkbox-content', {
            'resize-table-cell-checkbox-select': select,
          })}
        >
          <div className='resize-table-row-no'>{(row.index ?? no) + 1}</div>
          <Checkbox className='resize-table-cell-checkbox' checked={select} />
        </div>
      </div>
    </div>
  )
})

interface CellProps {
  head: IHead
  row: IRow
  flex: string
}

export const Cell = memo(({ head, row, flex }: CellProps) => {
  const { onCellSelect, observeCell } = useResizeTable()

  const [cSelect, setCSelect] = useState(false)

  const id = useMemo(() => `${row.key}-${head.key}`, [])

  const handleCellClick = useMemoizedFn(() => {
    onCellSelect(id)
    head?.onClick?.(row, head)
  })

  useEffect(() => {
    return observeCell(id, setCSelect)
  }, [id])

  const content = useMemo(() => {
    return head.render ? head.render(row[head.key], row, head) : row[head.key]
  }, [head, row])

  const className = useMemo(
    () =>
      classNames(head.className, 'resize-table-cell', {
        'resize-table-cell-select': cSelect,
      }),
    [head.className, head.sticky, cSelect],
  )

  return (
    <div
      data-id={id}
      className={className}
      style={{ flex }}
      onClick={handleCellClick}
    >
      <div className='resize-table-cell-content'>{content}</div>
    </div>
  )
})
