import { Button } from 'antd'

export interface RegisterSuccessProps {
  onButtonClick?: () => void
}

export function RegisterSuccess(props: RegisterSuccessProps) {
  return (
    <div className='w-446px relative overflow-hidden px-48 py-60'>
      <div className='w-208px h-132px absolute left-0px top--30px bg-#00FFDD bg-opacity-20 opacity-60 rounded-104px/66px blur-80px'></div>
      <div className='w-259px h-170px absolute right--30px top--50px bg-#A200FF bg-opacity-20 opacity-60 rounded-104px/66px blur-100px'></div>
      <div className='flex flex-col justify-center items-center'>
        <div className='text-80px'> 🎉 </div>
        <div className='text-font font-800 text-24px mt-66'>
          {' '}
          恭喜你注册成功{' '}
        </div>
        <div className='text-font_1 text-14px mt-16'>
          已为你提供 <span className='text-primary'>10</span>{' '}
          次免费使用应用的机会
        </div>
        <Button
          className='mt-48'
          type='primary'
          block
          onClick={props?.onButtonClick}
        >
          马上体验
        </Button>
      </div>
    </div>
  )
}
