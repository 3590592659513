import cn from 'classnames'
import type { ReactNode } from 'react'
import React from 'react'
import classNames from 'classnames'
import { IconFont } from '@/components'
import { useModal } from '@/hooks/useModal.tsx'
import { ImagePreviewModal } from '@/components/modal/ImagePreviewModal.tsx'

export interface AgentGoPublishGuideProps {
  className?: string
  item: { paragraph: string | ReactNode; diagram: string }[]
}

function InternalAgentGoPublishGuide({
  className,
  item,
}: AgentGoPublishGuideProps) {
  const [imagePreviewModal] = useModal(ImagePreviewModal)

  return (
    <div className={cn('p-24px', className)}>
      <h3 className='text-font text-16px font-medium mb-22px flex items-center'>
        <IconFont className='text-18px mr-4px' name='youhuajianyi' />
        帮助说明
      </h3>
      {item.map(({ paragraph, diagram }, index) => (
        <div key={index}>
          {!!paragraph && (
            <p
              className={classNames(
                'text-font text-14px/20px font-medium mb-14px',
                {
                  'mt-30': index !== 0,
                },
              )}
            >
              {paragraph}
            </p>
          )}
          <img
            className='w-full object-contain align-top mb-12px cursor-zoom-in'
            src={diagram}
            onClick={() => {
              imagePreviewModal.open({ url: diagram, height: 800 })
            }}
            alt=''
          />
        </div>
      ))}
    </div>
  )
}

export const AgentGoPublishGuide = React.memo(InternalAgentGoPublishGuide)
