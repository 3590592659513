import { memo, useMemo } from 'react'
import type { FormListFieldData } from 'antd'
import { Form, Tooltip } from 'antd'
import { isString } from 'markdown-it/lib/common/utils'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { Button, IconFont } from '@/components'

import { DatastoreItemEditor } from '@/pages/datastores/components/DatastoreItemEditor'
import { EditableText } from './EditableText'

const MAX_ANSWER_COUNT = 20
const MAX_ANSWER_CONTENT_COUNT = 4000

interface IAnswerItem {
  id: string | number
  new?: boolean
  title: string
  value: string | string[]
}

interface AnswerItemProps {
  className?: string
  file_id: number
  showRemove: boolean

  index: number
  value?: IAnswerItem
  onChange?: (val: IAnswerItem, index: number) => void
  onRemove: (index: number) => void
}

const AnswerItem = memo((props: AnswerItemProps) => {
  const {
    className,
    file_id,
    showRemove,

    index,
    value: outValue,
    onChange,
    onRemove,
  } = props

  const value = useMemo(
    () => outValue ?? { id: Date.now(), value: '', title: '' },
    [outValue],
  )

  const textareaVal = useMemo(() => {
    return isString(value.value) ? value.value : value.value?.join('\n')
  }, [value.value])

  const setOptions = useMemo(() => {
    return {
      autoSize: { minRows: 4, maxRows: 10 },
      count: true,
      uploadOptions: {
        uploadDir: 'datastore_qa',
      },
    }
  }, [])

  const handleChange = useMemoizedFn((newValue: string) => {
    onChange?.({ ...value, value: newValue }, index)
  })

  const handleTitleChange = useMemoizedFn((newTitle: string) => {
    onChange?.({ ...value, title: newTitle }, index)
  })

  const handleRemove = useMemoizedFn(() => {
    if (!showRemove) return
    onRemove(index)
  })

  return (
    <div className={classNames('bg-white cursor-pointer', className)}>
      <div className='mb-4px flex items-center w-100%'>
        <span className='h-20px bg-#38A6FF/12 c-#38A6FF text-12px rounded-4px p-4px mr-8px'>
          答
        </span>

        <EditableText
          index={index}
          value={value.title}
          onChange={handleTitleChange}
        />

        <Tooltip open={!showRemove ? false : undefined} title='删除答案'>
          <span
            className={classNames(
              'ml-auto p-4px rounded-8px c-#626999/60 text-16px',
              {
                'hover:bg-#FF5219/12 hover:c-error cursor-pointer': showRemove,
                'opacity-60 cursor-not-allowed': !showRemove,
              },
            )}
            onClick={handleRemove}
          >
            <IconFont name='shanshu' />
          </span>
        </Tooltip>
      </div>

      <div className='relative'>
        <DatastoreItemEditor
          className={classNames(
            'py-8px! px-12px! rounded-8px! b-transparent [.ant-form-item-has-error_&]:b-error! [.ant-form-item-has-error_&]:focus-within:b-primary!',
          )}
          file_id={file_id}
          noBorder={true}
          setOptions={setOptions}
          value={textareaVal}
          onChange={handleChange}
        />
      </div>
    </div>
  )
})

interface AnswerFormProps {
  className?: string
  file_id: number
  fields: FormListFieldData[]
  onAdd: (newItem: IAnswerItem) => void
  onRemove: (index: number) => void
}

export function AnswerForm(props: AnswerFormProps) {
  const { className, file_id, fields, onAdd, onRemove } = props
  const form = Form.useFormInstance()

  const handleAdd = useMemoizedFn(() => {
    const answers: IAnswerItem[] = form.getFieldValue('answers')

    let questionTitle = `可选答案${answers.length + 1}`
    // 检查名字是否重复，如果重复则尝试新的名字
    while (answers.find((item: any) => item.name === questionTitle)) {
      questionTitle = `${questionTitle}~`
    }
    if (answers.length >= MAX_ANSWER_COUNT) {
      return
    }

    answers.forEach(o => {
      if (questionTitle === o.title) {
        questionTitle = `${questionTitle}~`
      }
    })

    onAdd({
      id: Date.now(),
      new: true,
      value: '',
      title: questionTitle,
    })
  })

  const showRemove = useMemo(() => fields.length > 1, [fields])

  return (
    <div className={className}>
      {fields.map((item, index) => {
        return (
          <div
            className='w-full flex items-center mb-12px last:mb-0px'
            key={index}
          >
            <Form.Item
              key={index}
              className='flex-1 mb-0px!'
              name={item.name}
              rules={[
                {
                  validator(_rule, value) {
                    if (!value.value) {
                      return Promise.reject(new Error('请输入答案'))
                    }
                    if (value.value.length > MAX_ANSWER_CONTENT_COUNT) {
                      return Promise.reject(
                        new Error(
                          `输入错误，最多${MAX_ANSWER_CONTENT_COUNT}个字符`,
                        ),
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <AnswerItem
                index={index}
                file_id={file_id}
                showRemove={showRemove}
                onRemove={onRemove}
              />
            </Form.Item>
          </div>
        )
      })}

      <Button
        icon={<IconFont name='tianjiahuihua' className='text-14px' />}
        disabled={fields?.length >= MAX_ANSWER_COUNT}
        onClick={handleAdd}
      >
        添加答案
      </Button>
    </div>
  )
}
