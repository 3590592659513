import { type FormInstance } from 'antd'
import type { SegmentedValue } from 'antd/es/segmented'
import { useEffect, useMemo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import {
  NodeOperationTypes,
  NodeType,
  type NodeComponent,
} from '@/features/nodes/base'
import type { JsonFormConfig } from '../components/JsonForm'
import { JsonForm } from '../components/JsonForm'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { JAVASCRIPT_DEFAULT_CODE, PYTHON_DEFAULT_CODE } from './const'

export interface CodeNodeData {
  name: string
  comment?: string
  inputs: {
    language: 'javascript' | 'python'
    desc: string
    code: string
    version: number
  }
}

// 切换tab时，设置默认代码
function setPlaceholderCodeValue(form: FormInstance, value: SegmentedValue) {
  const formData = form.getFieldsValue()
  const codeVal = form.getFieldValue(['inputs', 'code'])
  if (value === 'python' && codeVal === JAVASCRIPT_DEFAULT_CODE) {
    form.setFieldValue(['inputs', 'code'], PYTHON_DEFAULT_CODE)
    formData.inputs.code = PYTHON_DEFAULT_CODE
  }
  if (value === 'javascript' && codeVal === PYTHON_DEFAULT_CODE) {
    form.setFieldValue(['inputs', 'code'], JAVASCRIPT_DEFAULT_CODE)
    formData.inputs.code = JAVASCRIPT_DEFAULT_CODE
  }
  formData.inputs.language = value
  return formData
}

export const CodeNode: NodeComponent<CodeNodeData> = props => {
  const formRef = useRef<FormInstance>(null)
  const { locked } = useFlowDraftStore(s => ({
    locked: s.lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
  }))

  const { variables, nodeElement, data } = props

  const containerStyle = useMemo(() => {
    return {
      width: props.nodeSize.width,
      cursor: locked ? 'not-allowed' : 'pointer',
    }
  }, [props.nodeSize, locked])

  const maxLines = useMemo(() => {
    return props.nodeSize.height
      ? Math.floor((props.nodeSize.height - 238) / 16) - 1
      : 20
  }, [props.nodeSize])

  const beforeChange = useMemoizedFn(async (values: any) => {
    const language = values?.inputs?.language
    if (language && formRef.current) {
      const newValues = setPlaceholderCodeValue(formRef.current, language)
      return newValues
    }
    return values
  })

  useEffect(() => {
    // 兼容旧数据
    if (data.inputs?.desc && !data.comment) {
      props.onSaveChange({ comment: data.inputs?.desc } as any, true)
    }
  }, [])

  const formList = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: '代码内容',
        type: 'CodeBlock',
        name: ['inputs', 'code'],
        required: true,
        disabled: locked,
        tooltip: '代码的return结果会作为当前节点的输出',
        rules: [{ required: true, message: '代码内容不能为空' }],
        componentProps: {
          lang: props.data.inputs.language || 'javascript',
          str: props.data.inputs.code,
          inFlow: true,
          hideHeader: true,
          className: 'nowheel',
          height: props.nodeSize.height
            ? `${props.nodeSize.height - 238}px`
            : '340px',
        },
      },
    ]
  }, [
    props.data.inputs.language,
    props.nodeSize,
    maxLines,
    variables,
    nodeElement,
    // handleLanguageChange,
  ])

  return useMemo(
    () => (
      <div
        className='pt-16 px-16px nodrag h-100% relative group'
        style={containerStyle}
        onClick={() => {
          if (!locked) {
            props.onOpenPanel()
          }
        }}
      >
        <JsonForm ref={formRef} list={formList} beforeChange={beforeChange} />
        <div
          className='absolute pos-right-24 pos-bottom-8 group-hover:block hidden'
          style={{ background: 'rgb(245, 245, 248)' }}
        >
          <div
            style={{
              color: 'rgba(123, 97, 255, 1)',
              background: 'rgba(123, 97, 255, 0.08)',
            }}
            className='px-8px py-4px font-500 text-12px border-rd-4px'
          >
            进入IDE编写
          </div>
        </div>
      </div>
    ),
    [containerStyle, formRef, formList, beforeChange],
  )
}

CodeNode.meta = {
  type: NodeType.CODE,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'CODE',
  typeName: 'CODE',
  icon: 'code',
  canDelete: true,
  resizeable: true,
  resizeConfig: {
    minWidth: 420,
    minHeight: 340,
    maxWidth: 1200,
    maxHeight: 960,
  },
  initialData: {
    name: 'code_1',
    inputs: {
      code: JAVASCRIPT_DEFAULT_CODE,
      language: 'javascript',
      desc: '',
      version: 1,
    },
  },
  backgroundColor: '#FFA100',
  description: '执行JavaScript和Python代码',
}

export const PythonCodeNode: NodeComponent<CodeNodeData> = props => {
  return <CodeNode {...props} />
}
PythonCodeNode.meta = {
  ...CodeNode.meta,
  type: NodeType.PYTHON,
  typeName: 'Python',
  initialData: {
    name: 'code_1',
    inputs: {
      code: PYTHON_DEFAULT_CODE,
      language: 'python',
      desc: '',
      version: 1,
    },
  },
  description: '执行Python代码',
}

export const JavaScriptCodeNode: NodeComponent<CodeNodeData> = props => {
  return <CodeNode {...props} />
}
JavaScriptCodeNode.meta = {
  ...CodeNode.meta,
  type: NodeType.JAVASCRIPT,
  typeName: 'JavaScript',
  initialData: {
    name: 'code_1',
    inputs: {
      code: JAVASCRIPT_DEFAULT_CODE,
      language: 'javascript',
      desc: '',
      version: 1,
    },
  },
  description: '执行JavaScript代码',
}
