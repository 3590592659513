import { useMemoizedFn } from 'ahooks'
import { useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty, isEqual } from 'lodash-es'
import type { AgentVariableConfig } from '@bty/chat-types'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import type { ApplicationBodyType } from '@/apis/application'

const cacheMap = new Map<string, boolean>()

export function useGlobalVariable(applicationInfo: ApplicationBodyType) {
  const scope = applicationInfo.id!

  const hasInit = useRef<boolean>(false)
  const variableConfigs = useMemo(() => {
    const config = applicationInfo?.config as any
    return (config?.rule?.agent_variables as AgentVariableConfig[]) ?? []
  }, [applicationInfo?.config])

  const [variableModalOpen, setVariableModalOpen] = useState(false)

  const setVariableValue = useAgentDiffStore(s => s.setVariableValue)
  const checkVariableValue = useAgentDiffStore(s => s.checkVariableValue)

  const closeVarModal = useMemoizedFn(() => {
    setVariableModalOpen(false)
  })

  const initVariablesValue = useMemoizedFn((value: Record<string, any>) => {
    if (
      !isEmpty(value) &&
      isEqual(useAgentDiffStore.getState().variableValue, value)
    ) {
      return
    }

    const checkPass = checkVariableValue(
      variableConfigs.filter(v => v.required).map(v => v.variable_name),
      value,
    )

    if (!checkPass) {
      setVariableModalOpen(true)
    } else if (!isEmpty(value)) {
      setVariableValue(value, false)
    }
  })

  useEffect(() => {
    if (!hasInit.current) {
      hasInit.current = !!variableConfigs
      return
    }

    if (!variableConfigs || !variableConfigs.length) {
      closeVarModal()
      return
    }

    if (
      !checkVariableValue(
        variableConfigs.filter(v => v.required).map(v => v.variable_name),
        useAgentDiffStore.getState().variableValue,
      )
    ) {
      setVariableModalOpen(true)
    }
  }, [variableConfigs])

  const initFromCache = useMemoizedFn(() => {
    const cache = cacheMap.get(scope)
    setVariableModalOpen(cache ?? false)
  })

  const saveToCache = useMemoizedFn(() => {
    cacheMap.set(scope, variableModalOpen)
  })

  useEffect(() => {
    initFromCache()
    return saveToCache
  }, [])

  return {
    variableModalOpen,
    variableConfigs,
    closeVarModal,
    initVariablesValue,
  }
}
