import { useSessionStorageState } from 'ahooks'
import {
  useApplicationStore,
  useFlowLogStore,
  useWorkspaceStore,
} from '@/store'
import {
  DEBUG_TASK_ID_MAP,
  DEBUG_TEST_ID_MAP,
  FLOW_ERROR_LOG,
} from '@/features/logger/constants'
import type { LogItem } from '@/apis/run'
import { useSetSessionValue } from '@/features/logger/components/loggerModal/hooks/useSessionValue'

export function useGoDraftPageWithInput() {
  const { currentFlowId } = useFlowLogStore()
  const { currentApplicationId } = useApplicationStore()
  const { currentWorkspaceId } = useWorkspaceStore()
  const [, setFLowErrorLog] = useSessionStorageState(FLOW_ERROR_LOG, undefined)
  const setDebugTaskId = useSetSessionValue(DEBUG_TASK_ID_MAP)
  const setTestDataId = useSetSessionValue(DEBUG_TEST_ID_MAP)

  const goDraftPage = (log: LogItem) => {
    if (currentFlowId) {
      setDebugTaskId(log?.task_id, currentFlowId)
      setTestDataId(null, currentFlowId)
    }

    setFLowErrorLog({
      run_type: log?.run_type,
    })

    window.open(
      `/application/${currentWorkspaceId}/${currentApplicationId}/flow/${currentFlowId}`,
    )
  }

  return {
    goDraftPage,
  }
}
