import { memo, useEffect, useMemo, useState } from 'react'
import { message, Popover, Tooltip } from 'antd'
import { useBoolean, useMemoizedFn } from 'ahooks'
import cn from 'classnames'
import { Button, IconFont, Mermaid } from '@/components'
import ErrorPng from '@/assets/dataset/error.png'
import AutoProgress from '@/components/progress/AutoProgress.tsx'
import { tokenStorage } from '@/utils/storage'
import {
  State,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket'
import { tryGetObject } from '@/features/logger/components/loggerModal/util'
import { Track } from '@/features/track'
import { useTrack } from '@/features/track/Track'

interface PromptMermaidProps {
  disabled?: boolean
  prompt: string
  getContainer?: () => HTMLElement
}

interface PromptGraphResponse {
  description: string
  graph: string
}

class CacheStore {
  private cachePrompt = ''
  public cacheData: PromptGraphResponse | undefined

  get prompt() {
    return this.cachePrompt
  }

  set prompt(prompt: string) {
    this.cachePrompt = prompt
  }

  setCacheData(data: PromptGraphResponse) {
    this.cacheData = data
  }
}

const cacheStore = new CacheStore()

export const PromptMermaid = memo<PromptMermaidProps>(props => {
  const { prompt, disabled, getContainer } = props
  const [open, setOpen] = useState(false)
  const [genTip, { setTrue: openTip, setFalse: closeTip }] = useBoolean(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PromptGraphResponse>()
  const token = tokenStorage.get()
  const { doTrack } = useTrack()

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const {
    state,
    content,

    handleSendMessage,
  } = useGeneratePromptSocket(socketUrl)

  const getPromptAnalyzeResult = async (prompt: string) => {
    setLoading(true)
    cacheStore.prompt = prompt
    handleSendMessage(
      JSON.stringify({
        action: 'gen_prompt_graph',
        params: {
          message: prompt,
        },
      }),
    )
  }

  const handleRetryAnalyze = () => {
    if (!prompt.trim()) {
      message.warning('未找到 Prompt，请编写后重试')
      return
    }
    getPromptAnalyzeResult(prompt)
  }

  useEffect(() => {
    if (open && !!prompt.trim()) {
      if (prompt !== cacheStore.prompt) {
        getPromptAnalyzeResult(prompt)
      } else if (cacheStore.cacheData) {
        setData(cacheStore.cacheData)
      }
    }
  }, [open, prompt])

  useEffect(() => {
    if ([State.end, State.result].includes(state)) {
      const data = tryGetObject(content)
      setData(data)
      cacheStore.setCacheData(data)
      setLoading(false)
    }
  }, [state])

  const render = useMemoizedFn(() => {
    const maxTime = 10 * 1000
    const promptExpectationTime = prompt.length * 300 // 2~3个字1s
    if (loading) {
      return (
        <div className='w-full flex flex-col h-300px flex-center'>
          {/* <img */}
          {/*  src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/chat/generating.gif' */}
          {/*  alt='loading' */}
          {/*  className='w-36px h-36px' */}
          {/* /> */}
          <AutoProgress
            className='w-100px'
            strokeColor={{
              '0%': '#7F55FF',
              '50%': '#7DD2FF',
              '100%': '#FF9BFD',
            }}
            expectationTime={
              promptExpectationTime > maxTime ? maxTime : promptExpectationTime
            }
            showInfo={false}
            finish={!loading}
          />
          <div className='font-400 line-height-16px c-font_1 mt-8px'>
            分析中
          </div>
        </div>
      )
    } else if (!data || typeof data !== 'object' || !data.graph?.trim()) {
      // AI 返回出错
      return (
        <div className='w-full flex flex-col h-454px flex-center'>
          <img src={ErrorPng} alt='error' className='w-117px' />
          <div className='font-400 line-height-16px c-font_1 mt-8px'>
            AI开小差了，试试重新分析
          </div>
        </div>
      )
    } else {
      // 渲染analyze结果
      return (
        <div className='max-h-300px'>
          <div className='p-12 w-full line-height-22px font-400 whitespace-pre-line'>
            {data.description}
          </div>
          <div className='p-16'>
            <Mermaid value={data.graph ?? ''} />
          </div>
        </div>
      )
    }
  })

  const Content = (
    <div>
      {/* Header */}
      <div className='flex-center-between w-full'>
        <span className='font-500 text-18px'>角色设定透视图</span>
        <span
          onClick={() => {
            if (loading) {
              openTip()
            }
            setOpen(false)
          }}
          className='w-24px h-24px flex-center hover:bg-bg_3/8 rd-4px cursor-pointer'
        >
          <IconFont name='guanbi' className='text-20px' />
        </span>
      </div>
      {/* Content */}
      <div className='w-full max-h-65vh rd-8px b-line/80 b-1 mt-20 of-y-auto'>
        {render()}
      </div>
      {/* Footer */}
      <div className='flex-center-between mt-20'>
        <Track event='agent_prompt_graph_regenerate'>
          <Button
            onClick={handleRetryAnalyze}
            className='ml-auto'
            disabled={loading}
          >
            重新分析
          </Button>
        </Track>
      </div>
    </div>
  )

  return (
    <Popover
      open={open}
      trigger={['click']}
      content={Content}
      placement='bottom'
      rootClassName='left-0px! w-full!'
      overlayInnerStyle={{
        padding: '24px 24px 20px',
      }}
      arrow={false}
      getPopupContainer={getContainer}
    >
      <Tooltip title='角色设定透视图'>
        <span
          className={cn(
            'w-24px relative h-24px rd-4px cursor-pointer flex-center',
            {
              'hover:bg-bg_3/8': !open,
              'bg-bg_3/8': open,
            },
          )}
          onClick={() => {
            if (disabled) {
              message.warning('未找到可分析的角色设定，请填写后重试')
              return
            }
            doTrack('agent_prompt_graph')
            closeTip()
            setOpen(true)
          }}
        >
          {genTip && (
            <div className='w-6px h-6px rd-full bg-error absolute top-0 right-0 z-2'></div>
          )}
          <IconFont name='shengchengtuzhi' className='text-16px' />
        </span>
      </Tooltip>
    </Popover>
  )
})
