import { memo, useEffect, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useDebounceFn, useMemoizedFn } from 'ahooks'

import type { FormInstance } from 'antd'
import { Button, IconFont } from '@/components'
import { useNodePanelStore } from '@/store'
import { useCache } from '@/hooks/useCache'

import type { ModalSettingValue } from '../../components/type'
import { ExecuteOutput } from './OutputPanel'

export const DEFAULT_OVERLAY_SCROLLBAR_OPTIONS = {
  overflow: {
    x: 'scroll',
    y: 'hidden',
  },
  scrollbars: {
    autoHide: 'leave',
    autoHideDelay: 1000,
  },
} as const

interface ResultPanelProps {
  form: FormInstance
  flowId: string
  activatedNodeId?: string
  variableValue: Record<string, any>
  resultInfo: Record<string, any>
  compare: boolean
  disableCompare: boolean
  getBaseInputs: () => Record<string, any>
  onShowCompare: (show: boolean) => void
  onChange: (value: ModalSettingValue) => void
}

export const ResultPanel = memo((props: ResultPanelProps) => {
  const {
    form,
    flowId,
    activatedNodeId,
    variableValue,
    resultInfo,
    compare,
    disableCompare,

    getBaseInputs,
    onShowCompare,
    onChange,
  } = props

  const { fetchLastedRecord } = useNodePanelStore()

  const getInitInput = useMemoizedFn(() => {
    const baseInput = getBaseInputs()
    return {
      channel: baseInput.channel,
      model: baseInput.model,
      temperature: baseInput.temperature,
      top_p: baseInput.top_p,
      presence_penalty: baseInput.presence_penalty,
      frequency_penalty: baseInput.frequency_penalty,
      outputType: baseInput.plugin?.json_mode ? 'json' : 'text',
    } as ModalSettingValue
  })

  const [currentConfig, setCurrentConf] = useState<ModalSettingValue>()
  const [diffConf, setDiffConf] = useCache<any[]>(
    `FLOW_DIFF_CONFIG_${flowId}_${activatedNodeId}`,
    [],
  )

  const { run: handleUpdateHistory } = useDebounceFn(
    () => {
      fetchLastedRecord(flowId, activatedNodeId!)
    },
    { wait: 800 },
  )

  const handleAddDiff = useMemoizedFn(() => {
    if (diffConf.length >= 2) return
    // setDiffConf([...diffConf, getInitInput()])
    setDiffConf([...diffConf, {}])
  })

  const handleOpenDiff = useMemoizedFn(() => {
    if (diffConf.length === 0) {
      setDiffConf([{}])
    }
    onShowCompare(true)
  })

  const handleCloseDiff = useMemoizedFn(() => {
    onShowCompare(false)
  })

  const handleChange = useMemoizedFn(
    (newValue: ModalSettingValue, id: string) => {
      if (!id) return
      if (id === 'current') {
        setCurrentConf(newValue)
        onChange(newValue)
      } else {
        setDiffConf(prev => {
          const index = Number(id)
          const temp = [...prev]
          temp[index] = {
            ...temp[index],
            ...newValue,
          }
          return temp
        })
      }
    },
  )

  const handleUse = useMemoizedFn((id: string) => {
    const index = Number(id)
    const usedConfig = diffConf[index]
    setDiffConf(prev => prev.filter((_, i) => i !== index))
    setCurrentConf(usedConfig)
    onChange(usedConfig)
    handleCloseDiff()
  })

  const handleRemove = useMemoizedFn((id: string) => {
    const index = Number(id)
    setDiffConf(prev => prev.filter((_, i) => i !== index))
  })

  useEffect(() => {
    if (!compare) return
    setCurrentConf(getInitInput())
  }, [compare])

  return (
    <div
      className='basis-0 b-l-1px b-solid flex flex-col h-full overflow-hidden'
      style={{
        flexGrow: (compare ? diffConf.length * 10 : 0) + 10,
        borderColor: 'rgba(225, 225, 229, 0.6)',
        backgroundColor: 'rgba(247, 247, 250, 0.6)',
      }}
    >
      <div
        className='h-60px px-24px flex items-center'
        style={{ flex: '0 0 60px' }}
      >
        <div className='mr-auto text-16px text-#17171D font-medium'>
          预览调试
        </div>
        {!compare && (
          <Button
            className='text-primary hover:text-primary! flex flex-center font-normal!'
            style={{
              pointerEvents: disableCompare ? 'none' : 'auto',
            }}
            type='text'
            onClick={handleOpenDiff}
            disabled={disableCompare}
          >
            多模型调试
            <IconFont name='qianwang' className='ml-4px' />
          </Button>
        )}

        {compare && (
          <Button
            disabled={diffConf.length >= 2}
            className='flex flex-center px-12px mr-8px font-normal!'
            onClick={handleAddDiff}
          >
            <IconFont name='tianjiahuihua' className='mr-4px' />
            添加模型{diffConf.length + 1}/3
          </Button>
        )}

        {compare && (
          <Button
            className='flex flex-center px-12px font-normal!'
            onClick={handleCloseDiff}
          >
            <IconFont name='daochu-1' className='mr-4px' />
            退出多模型
          </Button>
        )}
      </div>

      <div className='flex-1 overflow-hidden'>
        <OverlayScrollbarsComponent
          className='overflow-scroll h-full w-full'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <div className='h-full flex gap-12px px-24px pb-24px w-fit min-w-100% max-w-1272px'>
            <ExecuteOutput
              id='current'
              flowId={flowId}
              activatedNodeId={activatedNodeId}
              showConfig={compare}
              config={currentConfig}
              variableValue={variableValue}
              resultInfo={resultInfo}
              getInputs={getBaseInputs}
              onEnd={handleUpdateHistory}
              onChange={handleChange}
            />

            {compare &&
              diffConf.map((each, index) => (
                <ExecuteOutput
                  form={form}
                  key={index}
                  id={String(index)}
                  flowId={flowId}
                  activatedNodeId={activatedNodeId}
                  showConfig={compare}
                  config={each}
                  variableValue={variableValue}
                  getInputs={getBaseInputs}
                  onEnd={handleUpdateHistory}
                  onUse={handleUse}
                  onRemove={handleRemove}
                  onChange={handleChange}
                />
              ))}
          </div>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  )
})
