import { useReactFlow } from 'reactflow'

// 获取当前节点所处循环深度
export function useLoopDepth(id: string) {
  const { getNodes } = useReactFlow()
  const nodes = getNodes()
  let current = nodes.find(n => n.id === id)
  let depth = 0

  while (current) {
    const { relation } = current.data
    if (relation?.loopNodeId) {
      depth++
      current = nodes.find(n => n.id === relation.loopNodeId)
      continue
    }
    if (relation?.conditionNodeId) {
      current = nodes.find(n => n.id === relation.conditionNodeId)
      continue
    }
    break
  }

  return depth
}
