import { useState } from 'react'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import { AppLogo, IconFont, useConfirmModal, usePageModal } from '@/components'
import AssociatedAgentPopover from '@/features/application/AssociatedAgentPopover.tsx'
import { useDatabase } from '@/features/database/provider/DatabaseProvider.tsx'
import { useWorkspaceStore } from '@/store'

import useAppInfoEdit from '@/features/database/hooks/useAppInfoEdit.ts'
import { deleteDatabase } from '@/apis/database/api.ts'

export default function DatabaseInfo(props: { relatedApplications: any[] }) {
  const pageModal = usePageModal()
  const { baseInfo, onBackList } = useDatabase()
  const [deleteModal] = useConfirmModal()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { onEdit } = useAppInfoEdit()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const hasRelateApp = !!props.relatedApplications.length

  const onDelete = () => {
    setDropdownOpen(false)
    if (baseInfo) {
      deleteModal.open({
        title: `确认要删除数据库「${baseInfo.title}」？`,
        okText: '删除',
        onOk: async () => {
          await deleteDatabase(baseInfo.id)
          deleteModal.close()
          setTimeout(onBackList, 200)
        },
      })
    }
  }

  return (
    <div className='flex items-center pb-10 shrink-0'>
      <div className='flex items-center cursor-pointer'>
        <AppLogo
          value={baseInfo?.icon ?? ''}
          type='emoji'
          color={baseInfo?.icon_color ?? ''}
          fillSize={14}
          size={24}
          className='rd-4px'
        />
        <Dropdown
          open={dropdownOpen}
          overlayClassName='w-156px'
          trigger={['hover']}
          onOpenChange={setDropdownOpen}
          menu={{
            style: { padding: 4, width: 130 },
            items: [
              {
                label: (
                  <div
                    onClick={() => {
                      setDropdownOpen(false)
                      onEdit()
                    }}
                    className='hover:bg-#7F69FF hover:bg-op-8 px-8 py-11 cursor-pointer rd-6px'
                  >
                    编辑信息
                  </div>
                ),
                key: 'edit',
              },
              {
                label: (
                  <div
                    onClick={onDelete}
                    className={classNames(
                      'w-full px-8 py-11 rounded-6px',
                      hasRelateApp
                        ? 'cursor-not-allowed text-[rgba(0,0,0,0.25)]'
                        : 'cursor-pointer text-error hover:bg-#7F69FF hover:bg-op-8',
                    )}
                  >
                    删除
                    {hasRelateApp ? (
                      <p className='text-10px mt-4'>有关联应用不可删除</p>
                    ) : null}
                  </div>
                ),
                key: 'delete',
                disabled: hasRelateApp,
              },
            ],
          }}
        >
          <div
            className={classNames(
              'mx-5 overflow-hidden flex items-center p-3px rd-4px hover:bg-bg_3 hover:bg-op-12',
              {
                'bg-bg_3 bg-op-12': dropdownOpen,
              },
            )}
          >
            <span className='font-600 text-18px/18px max-w-230px truncate'>
              {baseInfo?.title}
            </span>
            <IconFont name='arrow' className='text-10px mx-4' />
          </div>
        </Dropdown>
      </div>
      <AssociatedAgentPopover
        trigger='hover'
        data={props.relatedApplications.map(item => ({
          ...item,
          name: item.flow_name,
        }))}
        onClick={(data: any) => {
          const url =
            data.application_type === 'AGENT'
              ? `/agent/${data.app_id}`
              : `/application/${currentWorkspaceId}/${data.app_id}/flow/${data.flow_id}`
          pageModal.show({ url })
        }}
      />
    </div>
  )
}
