import type { Edge, Node } from 'reactflow'
import { useMemo } from 'react'
import { FieldTypes } from '../start'
import type { FieldItem, StartNodeData } from '../start'
import { NodeType, VariableType } from '@/features/nodes/base'
import type { LoopNodeData } from '../loop'
import { getNodeMetaByType } from '..'

// const AI_VISION_IMG_PLUGIN_NAME = 'vision_image'
function getPreNodes(currNodeId: string, nodes: Node[], edges: Edge[]): Node[] {
  // 创建一个 Map，用于快速通过 ID 查找节点
  const nodesMap = new Map<string, Node>()
  nodes.forEach(node => nodesMap.set(node.id, node))

  // 创建一个 Set，用于存储找到的前置节点 ID，避免重复
  const preNodeIdsSet = new Set<string>()

  // 递归函数，用于查找当前节点的所有前置节点 ID
  function findPreNodeIds(currNodeId: string) {
    edges.forEach(edge => {
      if (edge.target === currNodeId) {
        preNodeIdsSet.add(edge.source)
        findPreNodeIds(edge.source) // 递归查找
      }
    })
  }

  // 开始递归查找
  findPreNodeIds(currNodeId)

  // 根据前置节点 ID 查找并返回前置节点数组
  return Array.from(preNodeIdsSet)
    .map(id => nodesMap.get(id)!)
    .filter(Boolean)
}

function getInputVariables(formConfig: FieldItem[]) {
  return formConfig.map(item => {
    return {
      label: item.variableName,
      type: VariableType.Input,
    }
  })
}

function getParentLoopVariables(nodeId: string, nodes: Node[]) {
  const currentNode = nodes.find(n => n.id === nodeId) as Node
  const loopNodeId = currentNode.data?.relation?.loopNodeId
  if (!loopNodeId) {
    return []
  }
  const parentLoopNode = nodes.find(
    n => n.id === loopNodeId,
  ) as Node<LoopNodeData>
  const { name } = parentLoopNode.data

  const isPythonMode =
    currentNode.type === NodeType.PYTHON &&
    currentNode.data?.inputs?.language === 'python'
  return isPythonMode
    ? [
        {
          label: `${name}["item"]`,
          type: VariableType.LoopScope,
        },
        {
          label: `${name}["index"]`,
          type: VariableType.LoopScope,
        },
      ]
    : [
        {
          label: `${name}.item`,
          type: VariableType.LoopScope,
        },
        {
          label: `${name}.index`,
          type: VariableType.LoopScope,
        },
      ]
}

export function useVariableDeps(
  currNodeId: string,
  nodes: Node[],
  edges: Edge[],
) {
  const preNodes = useMemo(
    () => getPreNodes(currNodeId, nodes, edges),
    [currNodeId, nodes, edges],
  )

  const inputVariables = useMemo(() => {
    const formConfig: FieldItem[] =
      preNodes.find(node => node.type === 'START')?.data?.formConfig || []
    return getInputVariables(formConfig)
  }, [preNodes])

  const nodeVariables = useMemo(() => {
    return preNodes
      .filter(v => v.type !== NodeType.CONDITION)
      .filter(v => v.type !== NodeType.CONDITION_RESULT)
      .map(node => {
        return {
          label: node.data?.name,
          type: VariableType.Node,
        }
      })
      .filter(item => !!item.label)
  }, [preNodes])

  // console.log('nodeVariables', currNodeId, preNodes)

  const loopVariables = useMemo(
    () => getParentLoopVariables(currNodeId, nodes),
    [currNodeId, nodes],
  )

  const needRemoveFileVariableKeys = useMemo(() => {
    // 如果当前节点是插件类型，直接返回空数组，避免不必要的计算
    // const currNode = nodes.find(item => item.id === currNodeId)
    // const { pluginName = '' } = currNode?.data
    // 只有为plugin类型并且是ai生图plugin
    // if (
    //   currNode?.type === NodeType.PLUGIN &&
    //   pluginName === AI_VISION_IMG_PLUGIN_NAME
    // ) {
    //   //
    //   return []
    // }

    return []

    // 找到开始节点并直接从中提取表单配置
    const startNodeFormConfig: StartNodeData['formConfig'] = nodes.find(
      item => item.type === NodeType.START,
    )?.data?.formConfig

    // 提取文件类型字段的key
    return (
      startNodeFormConfig
        ?.filter(item => item.type === FieldTypes.File)
        .map(item => item.variableName) ?? []
    )
  }, [nodes, currNodeId])

  const variables = useMemo(() => {
    return [...loopVariables, ...inputVariables, ...nodeVariables].filter(
      item => {
        return !needRemoveFileVariableKeys?.includes(item.label)
      },
    )
  }, [inputVariables, nodeVariables, needRemoveFileVariableKeys])

  return {
    preNodes,
    variables,
  }
}

type ValueOf<T> = T[keyof T]
type FieldTypesValues = ValueOf<typeof FieldTypes>

function createFormConfig(node: Node, fieldType?: FieldTypesValues) {
  const meta = getNodeMetaByType(node.type as NodeType)
  return {
    label: meta?.typeName,
    variableName: node.data?.name,
    type: fieldType || FieldTypes.Json,
  }
}

function handleStartNode(node: Node): any[] | undefined {
  return node.data?.formConfig?.map((item: FieldItem) => {
    const config: any = {
      label: item.variableName,
      fieldType: item.type,
      node: node.type,
      formConfig: item,
    }
    if (item.placeholder) {
      config.description = item.placeholder
    }
    if (item.options && item.options?.length > 0) {
      config.options = item.options.map(option => option.value)
    }
    return config
  })
}

function handleLLMNode(node: Node): any {
  const config: any = {
    label: node.data?.name,
    node: node.type,
  }
  const jsonMode = node.data?.inputs?.plugin?.json_mode
  config.output = jsonMode ? 'JSON' : '文本'
  config.formConfig = createFormConfig(
    node,
    jsonMode ? FieldTypes.Json : FieldTypes.Input,
  )
  return config
}

function handleKnowledgeNode(node: Node): any {
  const config: any = {
    label: node.data?.name,
    node: node.type,
    memoryType: node.data?.inputs?.memoryType,
  }
  if (node.data?.inputs?.memoryType === 'searchMemory') {
    config.output = node.data?.inputs?.outputType
  }
  config.formConfig = createFormConfig(node)
  return config
}

// Main function to get de        tailed input variables
function getDetailedInputVariables(node: Node) {
  switch (node.type) {
    case NodeType.START:
      return handleStartNode(node)
    case NodeType.LLM:
      return handleLLMNode(node)
    case NodeType.KNOWLEDGE:
      return handleKnowledgeNode(node)
    default:
      return {}
  }
}

export function useEnhancedCodeVariableDeps(
  currNodeId: string,
  nodes: Node[],
  edges: Edge[],
) {
  const preNodes = useMemo(
    () => getPreNodes(currNodeId, nodes, edges),
    [currNodeId, nodes, edges],
  )
  const { inputVariables, nodeVariables } = useMemo(() => {
    const startNode = preNodes.find(node => node.type === 'START')
    let inputVars = []
    let nodeVars = []
    if (startNode) {
      inputVars = getDetailedInputVariables(startNode)
    }
    nodeVars = preNodes
      .filter(
        node =>
          ![NodeType.CONDITION, NodeType.CONDITION_RESULT].includes(
            node.type as NodeType,
          ),
      )
      .map(node => {
        if (node.type === NodeType.START) {
          return {
            label: node.data?.name,
            node: node.type,
            type: node.data?.type,
            formConfig: createFormConfig(node),
          }
        }
        if (
          [NodeType.LLM, NodeType.KNOWLEDGE].includes(node.type as NodeType)
        ) {
          return getDetailedInputVariables(node)
        }
        return {
          label: node.data?.name,
          node: node.type,
          formConfig: createFormConfig(node),
        }
      })
      .filter(item => !!item.label)
    return { inputVariables: inputVars, nodeVariables: nodeVars }
  }, [preNodes])

  const loopVariables = useMemo(
    () => getParentLoopVariables(currNodeId, nodes),
    [currNodeId, nodes],
  )

  const [
    variablesForCodeNode,
    variablesForCodeNodeFormConfig,
    variablesForCodeNodeKeys,
  ] = useMemo(() => {
    const variables = [...inputVariables, ...loopVariables, ...nodeVariables]
    const formConfigList = variables
      .map(item => ({ ...item.formConfig }))
      .filter(item => !!item.label)
    const formConfigNameList = formConfigList.map(item => item.variableName)
    return [variables, formConfigList, formConfigNameList]
  }, [inputVariables, loopVariables, nodeVariables])

  return {
    variablesForCodeNode,
    variablesForCodeNodeFormConfig,
    variablesForCodeNodeKeys,
  }
}
