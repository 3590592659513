import ReactDOM from 'react-dom/client'

import { ErrorBoundary, mapToMonitorEnvironment, monitor } from '@bty/monitor'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import App from '@/app'
import 'react-resizable/css/styles.css'
import '@bty/chat-ui/dist/index.css'
import 'virtual:uno.css'
import './styles/index.css'
import { DataTrack } from './features/track'
import { trackList } from './track.config'
import { tokenStorage, useIdStorage } from './utils/storage'
import { isPrivateVersion } from './constants/common'

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.locale('zh-cn')

if (process.env.NODE_ENV !== 'local' && !isPrivateVersion) {
  DataTrack.init({ trackList })
  monitor.init({
    env: mapToMonitorEnvironment(process.env.NODE_ENV!),
    version: __DEFINE_RELEASE_VERSION__,
    user: {
      name: useIdStorage.get() ?? tokenStorage.get() ?? undefined,
    },
  })
  useIdStorage.listen(useId => monitor.setUser(useId))
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
)
