interface ILabel {
  label_id: string
  title: string
}

interface IProps {
  labels: Array<ILabel>
  selectedCategory: string
  handleChangeSelected: (label_id: string) => void
}

function Categories(props: IProps) {
  const { labels, selectedCategory, handleChangeSelected } = props

  return (
    <div
      style={{ scrollbarWidth: 'none' }}
      className='flex pb-24px overflow-x-scroll'
    >
      {labels.map((label: ILabel) => (
        <div
          key={label.label_id}
          className={`px-20 whitespace-nowrap py-10 rounded-lg cursor-pointer c-[rgba(23,23,29,0.8)] hover:color-primary ${
            label.label_id === selectedCategory
              ? 'color-primary! bg-[--primary-light-8]'
              : ''
          }`}
          onClick={() => handleChangeSelected(label.label_id)}
        >
          <span className='text-[14px] leading-[16px] font-medium'>
            {label.title}
          </span>
        </div>
      ))}
    </div>
  )
}

export default Categories
