import ace from 'ace-builds'

function initVariableHighlightRules(
  tokens: { label: string; type?: string }[],
  noDoubleInterpolation = false,
) {
  const variablesHighlightRules = [
    {
      token: 'context.var_node',
      regex: !noDoubleInterpolation
        ? new RegExp(
            `{{(${tokens
              .filter(v => v.type === 'node')
              .map(v => v.label)
              .join('|')})(\\..+)?}}`,
          )
        : new RegExp(
            `(${tokens
              .filter(v => v.type === 'node')
              .map(v => v.label)
              .join('|')})`,
          ),
    },
    {
      token: 'context.var_input',
      regex: !noDoubleInterpolation
        ? new RegExp(
            `{{(${tokens
              .filter(v => v.type === 'input')
              .map(v => v.label)
              .join('|')})(\\..+)?}}`,
          )
        : new RegExp(
            `(${tokens
              .filter(v => v.type === 'input')
              .map(v => v.label)
              .join('|')})`,
          ),
    },
  ]
  const errorLingHighlightRules = [
    {
      token: 'context.wavy-underline',
      // eslint-disable-next-line prefer-regex-literals
      regex: new RegExp('{{([a-zA-Z0-9_\u4E00-\u9FFF]+?)(\\..+)?}}'),
    },
  ]
  return [
    ...variablesHighlightRules,
    !noDoubleInterpolation ? [...errorLingHighlightRules] : [],
  ].flat()
}

export class TextMode extends ace.require('ace/mode/text').Mode {
  HighlightRules?: any
  constructor(tokens: { label: string; type?: string }[]) {
    class CustomHighlightRules extends ace.require(
      'ace/mode/text_highlight_rules',
    ).TextHighlightRules {
      $rules?: any
      constructor() {
        super()

        this.$rules = {
          start: initVariableHighlightRules(tokens),
        }
      }
    }
    super()
    this.HighlightRules = CustomHighlightRules
  }
}

export class JsonMode extends ace.require('ace/mode/json').Mode {
  HighlightRules?: any
  constructor(tokens: { label: string; type?: string }[]) {
    const JsonHighlightRules = ace.require(
      'ace/mode/json_highlight_rules',
    ).JsonHighlightRules

    class CustomHighlightRules extends JsonHighlightRules {
      $rules?: any
      constructor() {
        super()
        const rules = new JsonHighlightRules().getRules()

        const customRules = initVariableHighlightRules(tokens)

        // rules.custom = customRules

        // console.log(rules, '2333334')
        this.$rules = rules

        this.addRules(
          {
            start: customRules,
          },
          'new-',
        )
        console.log(rules, this, '2333334')
      }
    }
    super()
    this.HighlightRules = CustomHighlightRules
  }
}

export class JavascriptMode extends ace.require('ace/mode/javascript').Mode {
  HighlightRules?: any
  constructor(tokens: { label: string; type?: string }[]) {
    const JavaScriptHighlightRules = ace.require(
      'ace/mode/javascript_highlight_rules',
    ).JavaScriptHighlightRules
    class CustomHighlightRules extends JavaScriptHighlightRules {
      $rules?: any
      constructor() {
        super()

        const rules = new JavaScriptHighlightRules().getRules()

        const customRules = initVariableHighlightRules(tokens, true)

        this.$rules = rules

        this.addRules(
          {
            start: customRules,
          },
          'new-',
        )
        console.log(rules, this, '233333')
      }
    }
    super()

    this.HighlightRules = CustomHighlightRules
  }
}
