import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import classNames from 'classnames'
import { useAuth } from '@bty/react-auth'

// import { uniqWith, map } from 'lodash-es'
import type { UserItem } from '@/apis/authority'
import { AppJoined, RoleCode, RoleName, RoleWeight } from '@/apis/authority'
import { UserRoleSelect } from '@/components/userSearchTable/UserRoleSelect.tsx'
import { Button, IconFont } from '..'

interface UserColumnsParams {
  isInApplication: boolean
  appOptions: { label: string; value: string; applicationType: string }[]
  onRoleChange: (params: any) => void
  onApplicationsChange: (params: any) => void
  onRemoveConfirm: (params: any) => void
  onInviteAgain: (params: any) => void
  isInviting: boolean
  authOpenRowId?: number
  onAuthAppPopoverOpenChange: (value: number | undefined) => void
  userRoleCode: RoleCode
  onAuthModalOpen: (userItem: UserItem) => void
}

export function Actions(data: UserItem & Partial<UserColumnsParams>) {
  const {
    permissionGroupId,
    userId,
    roleCode,
    applications,
    activatedStatus,
    phone,
    isInviting,
    onInviteAgain,
    isInApplication,
    onRemoveConfirm,
  } = data
  const {
    state: { role: workspaceRole },
  } = useAuth()
  const classifyUserType =
    RoleWeight[workspaceRole] < RoleWeight[roleCode] ||
    (workspaceRole === RoleCode.DEVELOPER && roleCode === RoleCode.DEVELOPER)
  return (
    <div className='flex flex-items-center flex-justify-end'>
      {activatedStatus === AppJoined.NOT_JOINED && (
        <Button
          className='m-r-12 important-h-32px c-#7b61ff'
          icon={<IconFont name='add' className='c-#7b61ff' />}
          loading={isInviting}
          onClick={() => {
            onInviteAgain?.({
              phones: [phone],
              applicationIds: applications?.map(item => item.id) ?? [],
              role: roleCode,
              applicationType: 'AI',
            })
          }}
        >
          再次邀请
        </Button>
      )}
      {!(
        isInApplication &&
        [RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER].includes(roleCode)
      ) && (
        <Tooltip title={classifyUserType ? '非管理员不能删除' : ''}>
          <IconFont
            className={classNames(
              'text-16px cursor-pointer c-#8d8d99 hover:c-#ff5219',
              {
                'cursor-not-allowed !hover:c-#8d8d99': classifyUserType,
              },
            )}
            name='shanshu'
            onClick={() => {
              if (classifyUserType) {
                return
              }
              onRemoveConfirm?.({
                permissionGroupId,
                sourceRoleCode: roleCode,
                userId,
              })
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}

export function UserSelect(data: UserItem & Partial<UserColumnsParams>) {
  const {
    roleCode,
    isInApplication,
    activatedStatus,
    userRoleCode,
    userId,
    phone,
    onRoleChange,
  } = data
  const roleName = RoleName[roleCode]
  if (isInApplication) {
    return roleName
  }
  return (
    <UserRoleSelect
      selectedKey={roleCode}
      disabled={
        activatedStatus === AppJoined.NOT_JOINED ||
        RoleWeight[roleCode] > RoleWeight[userRoleCode || RoleCode.VIEWER]
      }
      onChange={code => {
        const params = {
          userId,
          roleCode: code,
          applicationType: 'AI',
          sourceRoleCode: roleCode,
          phone,
        }
        onRoleChange?.(params)
      }}
    />
  )
}

export function UserList(data: { dataSource: UserItem[] } & UserColumnsParams) {
  const {
    dataSource,
    isInApplication = false,
    onRoleChange,
    onRemoveConfirm,
    onInviteAgain,
    isInviting,
    userRoleCode,
  } = data
  return (
    <div className='h-300px overflow-y-scroll flex flex-col gap-24px pr-10px'>
      {dataSource?.map((item, index) => (
        <div className='flex justify-between items-center' key={index}>
          <div className='flex flex-col justify-between'>
            <div className='flex flex-items-center'>
              <span className='font-500 c-#17171d'>{item.username}</span>
              {item.activatedStatus === AppJoined.NOT_JOINED && (
                <span className='flex flex-center m-l-8 h-20px p-l-8 p-r-8 b-rd-4px bg-#ff5219 bg-op-12 b-1 b-#ff5219 b-op-48 text-12px font-500 c-#ff5219 break-keep'>
                  待加入
                </span>
              )}
            </div>
            <div className='c-font_1 text-12px mt-8px'>{item.phone}</div>
          </div>
          <div className='flex gap-24px items-center'>
            <div>
              <Actions
                {...item}
                onRemoveConfirm={onRemoveConfirm}
                onInviteAgain={onInviteAgain}
                isInviting={isInviting}
                isInApplication={isInApplication}
              />
            </div>
            <div>
              <UserSelect
                {...item}
                userRoleCode={userRoleCode}
                onRoleChange={onRoleChange}
                isInApplication={isInApplication}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export function getUserColumns({
  isInApplication = false,
  appOptions,
  onRoleChange,
  onRemoveConfirm,
  onInviteAgain,
  isInviting,
  userRoleCode,
  onAuthModalOpen,
}: UserColumnsParams): ColumnsType<UserItem> {
  return [
    {
      title: '用户名',
      key: 'username',
      dataIndex: 'username',
      render: (_, { username, activatedStatus }) => {
        return (
          <div className='flex flex-items-center'>
            <span className='font-500 c-#17171d'>{username}</span>
            {activatedStatus === AppJoined.NOT_JOINED && (
              <span className='flex flex-center m-l-8 h-20px p-l-8 p-r-8 b-rd-4px bg-#ff5219 bg-op-12 b-1 b-#ff5219 b-op-48 text-12px font-500 c-#ff5219 break-keep'>
                待加入
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: '账号(手机号)',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: '角色',
      key: 'role',
      dataIndex: 'roleCode',
      render: (_, data) => (
        <UserSelect
          {...data}
          userRoleCode={userRoleCode}
          onRoleChange={onRoleChange}
          isInApplication={isInApplication}
        />
      ),
    },
    ...(isInApplication
      ? []
      : [
          {
            title: '授权应用范围',
            key: 'applications',
            dataIndex: 'applications',
            render: (
              _: any,
              {
                applications,
                roleCode,
                userId,
                isAuthAllApp,
                ...rest
              }: UserItem,
            ) => {
              // const options = isAuthAllApp ? appOptions : applications
              // const dep = isAuthAllApp ? 'value' : 'id'
              // const agentCheckList = map(
              //   uniqWith(
              //     options?.filter(
              //       (item: { id: string; applicationType: string }) =>
              //         item.applicationType === 'AGENT',
              //     ),
              //     (obj1, obj2) => obj1[dep] === obj2[dep],
              //   ),
              //   dep,
              // )
              // const FlowCheckList = map(
              //   uniqWith(
              //     options?.filter(
              //       (item: { id: string; applicationType: string }) =>
              //         item.applicationType === 'AI',
              //     ),
              //     (obj1, obj2) => obj1[dep] === obj2[dep],
              //   ),
              //   dep,
              // )
              const handleSelectValue = () => {
                if (
                  isAuthAllApp ||
                  appOptions.length === applications?.length
                ) {
                  return '全部应用'
                }
                return applications && applications.length > 0
                  ? `${applications.length}个应用`
                  : '无授权应用'
              }
              return roleCode === 'Administrator' ? (
                '全部应用'
              ) : (
                <div
                  className='flex cursor-pointer'
                  onClick={() => {
                    onAuthModalOpen({
                      applications,
                      roleCode,
                      userId,
                      isAuthAllApp,
                      ...rest,
                    })
                  }}
                >
                  <span>{handleSelectValue()}</span>
                  <div className='flex flex-justify-center flex-items-center w-16px h-16px b-rd-4px hover-bg-#626999 hover-bg-op-8'>
                    <IconFont className='text-6px c-#8d8d99' name='desc' />
                  </div>
                </div>
              )
            },
          },
        ]),
    {
      title: '操作',
      key: 'action',
      align: 'right',
      // className: 'flex-center',
      render: (_, item) => (
        <Actions
          {...item}
          onRemoveConfirm={onRemoveConfirm}
          onInviteAgain={onInviteAgain}
          isInviting={isInviting}
          isInApplication={isInApplication}
        />
      ),
    },
  ]
}
