export const GPTContextExampleValue = `[
  {
    "role": "user",
    "content": "你好"
  },
  {
    "role": "assistant",
    "content": "你好，有什么可以帮助您？"
  },
  {
    "role": "user",
    "content": "北京奥运会时间"
  }
]`

export const BaiduContextExampleValue = `[
  {
    "role": "assistant",
    "content": "你好，有什么可以帮助您？"
  },
  {
    "role": "user",
    "content": "北京奥运会时间"
  },
  {
    "role": "assistant",
    "content": "2008年"
  },
  {
    "role": "user",
    "content": "建国时间？"
  }
]`

export enum LLMContextType {
  JSON_VARIABLE = 'jsonVariable',
  MSG_LIST = 'messageList',
}

export const contextOptions = [
  {
    label: '通过变量',
    value: LLMContextType.JSON_VARIABLE,
  },
  {
    label: '手动输入',
    value: LLMContextType.MSG_LIST,
  },
]

export const outputOptions = [
  {
    label: '文本',
    value: 'text',
  },
  {
    label: 'JSON',
    value: 'json',
  },
]

export const LLM_NODE_PROMPT_FULLSCREEN = 'LLM_NODE_PROMPT_FULLSCREEN'
