import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'

export default function ParagraphEditorStyle() {
  return (
    <Global
      styles={css`
        .paragraph-editor {
          outline: 0;
          min-height: 22px;
          padding: 0 20px;

          *:not(li) {
            margin-bottom: 0 !important;
          }

          .paragraph-editor-image-upload-loading-box {
            width: 400px;
            height: 200px;
            background: rgba(98, 105, 153, 0.06);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.disabled {
            .paragraph-split-mark {
              opacity: 0;
              pointer-events: none;
            }
          }

          .paragraph-split-mark {
            min-height: auto;
            margin-bottom: 0;
            width: 0;
            &:hover {
              width: 100%;
              .line {
                border-color: #7b61ff;
              }
            }
          }
        }
      `}
    />
  )
}

export const CommonParagraphItemWrap = styled.div<{
  disabled: boolean
  isEdited?: boolean
}>`
  .paragraph-editor,
  .paragraph-editor > *:not(.paragraph-split-mark) {
    ${props => props.disabled && 'color: rgba(141, 141, 153, 0.6);'}
  }

  .edited-mark:before {
    content: '';
    width: 24px;
    height: 24px;
    background: #38a6ff;
    opacity: 0.4;
    position: absolute;
    top: -12px;
    left: -12px;
    pointer-events: none;
    display: ${props => (props.isEdited ? 'block' : 'none')};
    transform: rotate(45deg);
  }

  table {
    width: 100%;
    border-collapse: collapse;
    line-height: 22px;
    font-size: 14px;
  }

  caption {
    color: blue;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 6px;
    border: 1px solid #d9d9d9;
  }

  th {
    color: #fff;
    background-color: blue;
  }
  td {
    padding: 4px 8px;
    border: 1px solid #e1e1e5;
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  img {
    padding: 12px 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 400px;
    max-height: 400px;
  }

  video {
    padding: 12px 0;
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 200px;
  }

  @keyframes show-paragraph-active {
    0% {
      background-color: hsla(240deg, 0%, 100%, 1);
    }

    20% {
      background-color: hsla(240deg, 7%, 90%, 1);
    }

    100% {
      background-color: hsla(240deg, 0%, 100%, 1);
    }
  }

  .paragraph-blink {
    animation: show-paragraph-active linear 1s 2;
  }
`
