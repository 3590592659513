import type { FC } from 'react'
import styled from '@emotion/styled'
import { useRequest, useMemoizedFn } from 'ahooks'
import { Spin } from 'antd'
import { useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CloseCircleFilled } from '@ant-design/icons'
import { AppLogo, IconFont, usePageModal, Button, Input } from '@/components'
import type { DocumentsInstance } from '@/features/datastore/Documents.tsx'
import Documents from '@/features/datastore/Documents.tsx'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { PartitionCategoryType } from '@/apis/datastore/types.ts'
import { getDataStoreDetail } from '@/apis/datastore/api.ts'
import { useWorkspaceStore } from '@/store'
import NewFeatureBadge from '@/features/datastore/components/NewFeatureBadge'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { AGENT_KNOWLEDGE_ROUTER_MAP, AGENT_FROM } from '../constant/base'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

// import { ActionBtn } from './ActionBtn'

export interface KnowledgeConfigProps {
  flowInfo: ApplicationBodyType
  onUpdate?: () => void
}

const StyledDocuments = styled(Documents)`
  .file_name .text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
export const KnowledgeDocumentConfig: FC<KnowledgeConfigProps> = () => {
  // const { flowInfo, onUpdate } = props
  const navigate = useNavigate()
  const reactParams = useParams()
  const { datastoreId, id: agentId } = reactParams

  const { data: datastoreInfo, loading: datastoreLoading } = useRequest(
    getDataStoreDetail,
    {
      defaultParams: [Number(datastoreId)],
    },
  )
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const { updateStatistics } = useAgentEdit()

  const handleShowDetail = useMemoizedFn((id: number) => {
    navigate(`${String(id)}`, {
      state: {
        [AGENT_FROM]: AGENT_KNOWLEDGE_ROUTER_MAP.DOCUMENT,
      },
    })
    return true
  })

  const pageModal = usePageModal()

  const [searchKey, setSearchKey] = useState<string>()

  const datastoreHashPath = datastoreInfo?.partition.oss_path ?? ''

  const documentsRef = useRef<DocumentsInstance>(null)

  const isSearched = useRef(false)

  const handleDocumentSearch = async (file_name: string) => {
    if (!isSearched.current) {
      await documentsRef.current?.onSearch({ file_name })
      isSearched.current = true
    }
  }

  const addText = useMemo(() => {
    return datastoreInfo?.partition?.partition_category ===
      PartitionCategoryType.QA
      ? '问答'
      : '文档'
  }, [datastoreInfo?.partition?.partition_category])

  const header = useMemo(() => {
    return (
      <div className='flex items-center h-36px text-16px/36px font-medium text-font mt-24px mx-24px group'>
        <div className='flex items-center grow-1 shrink-1 pr-12  overflow-hidden'>
          <span
            className='text-16px font-500 truncate color-#8D8D99 cursor-pointer'
            onClick={() => {
              navigate(`/agent/${agentId}/design/knowledge`, {
                replace: true,
                state: {
                  KNOWLEDGE_ROUTE: {
                    form: AGENT_KNOWLEDGE_ROUTER_MAP.DOCUMENT,
                  },
                },
              })
            }}
          >
            知识
          </span>
          <IconFont
            className='mx-12 color-#8D8D99 text-10px'
            name='left-arrow'
          />

          <AppLogo
            size={36}
            fillSize={18}
            value={datastoreInfo?.partition?.partition_icon!}
            color={datastoreInfo?.partition?.partition_icon_color}
            type='icon'
          />
          <span className='ml-12px text-16px font-500 truncate c-font'>
            {datastoreInfo?.partition.partition_name}
          </span>
          <span
            className='shrink-0 text-16px ml-4 cursor-pointer c-font_1 c-op-60 group-hover:op-100 hover:bg-bg_3 hover:bg-op-12 w-24px h-24px rounded-4px flex-center'
            onClick={() => {
              pageModal.show({
                url: `/datastores/${currentWorkspaceId}/${datastoreInfo?.partition.partition_id}/documents?independent=1`,
              })
            }}
          >
            <IconFont name='super-link' />
          </span>
        </div>
        <div className='flex items-center flex-nowrap'>
          <Input
            className='rounded-8px ml-24 w-140px shrink-0'
            placeholder={`搜索${addText}名`}
            prefix={<IconFont className='text-16px' name='search' />}
            onChange={event => {
              const searchKey = event.target.value
              isSearched.current = false
              setSearchKey(searchKey)
            }}
            allowClear={{
              clearIcon: (
                <CloseCircleFilled
                  onClick={() => {
                    handleDocumentSearch('')
                  }}
                />
              ),
            }}
            onBlur={() => {
              handleDocumentSearch(searchKey!)
            }}
            onPressEnter={() => {
              handleDocumentSearch(searchKey!)
            }}
          />
          <span className='mx-12 px-4 h-16px shrink-0 border-right after:right-4 after:border-line' />
          <LimitedAccess limitedType='dataStore'>
            <Button
              className='mr-12 !p-0 w-96px shrink-0'
              type='primary'
              onClick={() => documentsRef.current?.create(updateStatistics)}
            >
              <IconFont name='add' />
              <span className='ml-4'>
                添加
                {addText}
              </span>
              {datastoreInfo?.partition?.partition_category !==
                PartitionCategoryType.QA && (
                <NewFeatureBadge className='right-0 -top-8 z-10 absolute'>
                  新增邮件
                </NewFeatureBadge>
              )}
            </Button>
          </LimitedAccess>
        </div>
      </div>
    )
  }, [datastoreInfo, handleDocumentSearch, documentsRef])
  if (datastoreLoading) {
    return (
      <>
        {datastoreInfo?.partition && header}
        <div className='w-full h-full flex-center'>
          <Spin />
        </div>
      </>
    )
  }
  if (datastoreInfo?.partition) {
    return (
      <div className='flex flex-col h-full'>
        {header}
        <div className='p-24 pt-0px pb-0px flex flex-col overflow-hidden flex-grow-1'>
          <StyledDocuments
            ref={documentsRef}
            className='mt-7 !h-auto overflow-hidden cursor-pointer'
            id={datastoreInfo?.partition.partition_id}
            openDocumentInNewWindow={true}
            dateFormat='MM-DD HH:mm'
            datastoreHashPath={datastoreHashPath}
            widthMap={{
              file_name: 175,
              file_size: 100,
              update_time: 120,
              extracted_text_length: 80,
              status: 120,
              enable: 80,
            }}
            fields={[
              'file_name',
              'file_size',
              'tags',
              'segment_count',
              'update_time',
              'status',
              'enable',
            ]}
            hideHeader
            datastoreName={datastoreInfo?.partition.partition_name}
            datastoreType={datastoreInfo?.partition?.partition_category}
            onRowClick={handleShowDetail}
            onShowDetail={handleShowDetail}
          />
        </div>
      </div>
    )
  }
}
