import { isFunction } from 'lodash-es'
import type { RefObject } from 'react'

export function moreRef<T>(
  ...refs: (
    | RefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined
  )[]
) {
  return (dom: T) => {
    refs.forEach((each: any) => {
      if (!each) return
      if (isFunction(each)) {
        each(dom)
      } else {
        each.current = dom
      }
    })
  }
}
