import { memo, useEffect, useMemo, useRef, useState } from 'react'
import AceEditor from 'react-ace'
import { isNil, isObject, isString } from 'lodash-es'
import copy from 'copy-to-clipboard'
import { Button, Collapse, message } from 'antd'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import classNames from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { IconFont } from '@/components'
import { isEmptyString, isMarkdown } from '@/pages/flowPage/util'
import { StringContent, SwitchOutputDisplay } from '../../StringContent'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import type { FormattedOutput } from '../type'
import { LIMITED_ERROR_COMBO_NOT_ENOUGH } from '@/constants/pay'
import { LimitedAlertContent } from '@/features/pay/LimitedAlert'

interface LoggerResultProps {
  title?: string
  result: string
  operations: Array<'diff' | 'copy'>
  knowledgeBucket?: string
  remarks?: string
  diff?: boolean
  scrollEnd?: boolean
  onDiff?: () => void
}

export const LoggerResult = memo((props: LoggerResultProps) => {
  const {
    knowledgeBucket,
    title,
    remarks,
    result,
    operations,
    diff,
    scrollEnd,

    onDiff,
  } = props

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const isEmpty = useMemo(
    () => isNil(result) || isEmptyString(result),
    [result],
  )

  const isMarkdownType = useMemo(
    () => !isEmpty && isString(result) && isMarkdown(result),
    [result, isEmpty],
  )

  const isCodeType = useMemo(
    () => !isMarkdownType && !isString(result),
    [isMarkdownType, result],
  )

  const [showCard, setShowCard] = useState(true)

  const onCopy = useMemoizedFn(() => {
    const text = isObject(result) ? JSON.stringify(result, null, 2) : result
    copy(text)
    message.success('复制成功')
  })

  const scrollToBottom = useMemoizedFn(() => {
    if (!scrollEnd || !scrollRef.current) return
    setTimeout(() => {
      const viewport = scrollRef.current?.osInstance()?.elements().viewport
      viewport?.scroll({
        top: viewport.scrollHeight,
        behavior: 'smooth',
      })
    })
  })

  const items = useMemo(() => {
    return [
      {
        key: '1',
        label: <div className='text-#AA915A text-12px ml-[-4px]'>备注</div>,
        children: (
          <p className='text-#AA915A text-12px leading-18px'>{remarks}</p>
        ),
      },
    ]
  }, [remarks])

  useEffect(() => {
    if (!scrollEnd) return
    scrollToBottom()
  }, [result])

  return (
    <div className='b-op-60 h-full relative w-full flex flex-col [&:hover_.output-button]:opacity-100'>
      <div className='flex items-center mb-12px h-24px flex-none px-20px'>
        <div className='text-14px text-font flex items-center text-#8D8D99 italic'>
          {title ?? '运行结果'}
        </div>

        <div className='ml-auto flex'>
          {operations.includes('diff') && (
            <Button
              type='text'
              size='small'
              onClick={onDiff}
              className='output-button opacity-0 flex-center text-12px! text-#17171D ml-12px !px-4px ![&_.ant-btn-icon]:mr-4px'
              icon={<IconFont name='duibi' className='text-14px' />}
            >
              {diff ? '关闭对比' : '打开对比'}
            </Button>
          )}

          {operations.includes('copy') && (
            <Button
              type='text'
              size='small'
              className='output-button opacity-0 flex-center text-12px! text-#17171D ml-12px !px-4px ![&_.ant-btn-icon]:mr-4px'
              icon={<IconFont name='fuzhi2' className='text-14px' />}
              onClick={onCopy}
            >
              复制
            </Button>
          )}

          {isMarkdownType && knowledgeBucket && (
            <SwitchOutputDisplay
              className='ml-12px'
              value={showCard}
              onChange={setShowCard}
            />
          )}
        </div>
      </div>

      {remarks && (
        <div className='px-20px mb-12px'>
          <Collapse
            className='bg-#FFF5DF rounded-6px [&_.ant-collapse-header]:items-center! [&_.ant-collapse-content-box]:pt-0px!'
            items={items}
            bordered={false}
            size={'small'}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => (
              <IconFont
                name='left-arrow'
                className={classNames('text-8px! text-#AA915A! duration-300', {
                  'rotate-90': isActive,
                })}
              />
            )}
          />
        </div>
      )}

      {isEmpty && (
        <div className='h-full flex-center c-bg_3 c-op-40 px-20px'>
          emmm, 流程貌似没有输出内容
        </div>
      )}

      {isMarkdownType && !isEmpty && (
        <OverlayScrollbarsComponent
          ref={scrollRef}
          className='overflow-auto rounded-8px flex-1 px-20px'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <StringContent
            closeCopy
            content={result}
            bucketId={knowledgeBucket}
            showCard={showCard}
          />
        </OverlayScrollbarsComponent>
      )}

      {isCodeType && !isMarkdownType && (
        <div className='flex-auto rounded-8px px-20px'>
          <AceEditor
            value={JSON.stringify(result, null, 2)}
            theme='tomorrow'
            highlightActiveLine={false}
            readOnly
            height='100%'
            width='100%'
            mode='json'
            setOptions={{ wrap: true }}
          />
        </div>
      )}
    </div>
  )
})

interface ErrorResultProps {
  output: FormattedOutput
  onShowError: () => void
}

export const ErrorResult = memo((props: ErrorResultProps) => {
  const { output, onShowError } = props

  return (
    <div className='min-h-66px flex flex-items-center b-rd-8px b-1 b-#ff5219 b-op-12 bg-#fff1ed px-16px py-8px'>
      <IconFont name='cuowu' className='text-16px m-r-8' />
      <div className='c-#e54a16 text-wrap line-height-20px'>
        {output?.errorMsg ===
        // 根据 errorMessage 判断是否要显示超期/耗尽的错误信息
        LIMITED_ERROR_COMBO_NOT_ENOUGH ? (
          <LimitedAlertContent prefix='该工作流所在的空间' />
        ) : (
          output?.errorMsg
        )}
      </div>
      {output?.errorStepName && (
        <Button
          className='p-0! ml-8px font-normal!'
          type='link'
          onClick={onShowError}
        >
          查看日志
        </Button>
      )}
    </div>
  )
})
