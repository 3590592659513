import { memo } from 'react'
import { typeSelectOption } from '@/pages/application/components/appDetail/utils'
import { FlowStatus } from '@/apis/flow'
import { useLogger } from '../context'
import { createWrapComponent } from './wrapper'

const Title = createWrapComponent({
  className: 'color-#17171D text-14px m-b-16px',
})

const Content = createWrapComponent({
  className: 'color-#8D8D99 text-14px m-b-40px',
})

export const LoggerDetail = memo(() => {
  const { flowLog } = useLogger()

  return (
    <div className='px-16px'>
      <Title>运行状态</Title>
      <Content>
        {flowLog?.run_status === FlowStatus.SUCCEEDED ? '成功' : '失败'}
      </Content>

      <Title>运行开始时间</Title>
      <Content>{flowLog?.start_time || ''}</Content>

      <Title>用户</Title>
      <Content>{flowLog?.user_name || ''}</Content>

      <Title>运行方式</Title>
      <Content>
        {typeSelectOption.find(item => item.value === flowLog?.run_type)
          ?.label || ''}
      </Content>

      <Title>运行时长</Title>
      <Content>{`${flowLog?.run_result?.durationTime}s`}</Content>
    </div>
  )
})
