import { memo } from 'react'
import { Popconfirm, Tooltip } from 'antd'
import { IconFont } from '@/components'

interface SkillSyncProps {
  disabledConfirm: boolean
  onSync: () => void
}

export const SkillSync = memo<SkillSyncProps>(({ disabledConfirm, onSync }) => {
  return (
    <Tooltip placement='top' title='同步已有技能名称和描述'>
      <Popconfirm
        title={null}
        description={
          <div className='w-240px line-height-22px font-400'>
            同步会覆盖现有内容，确定同步？
          </div>
        }
        icon={null}
        trigger={['click']}
        okText='确认'
        cancelText='取消'
        onConfirm={onSync}
        okButtonProps={{
          size: 'small',
          className: 'font-400 rd-4px!',
        }}
        cancelButtonProps={{
          size: 'small',
          className: 'font-400 rd-4px!',
        }}
        disabled={disabledConfirm}
      >
        <span
          className='h-24px w-82px rd-4px cursor-pointer flex-center hover:bg-bg_3/8'
          onClick={() => {
            if (disabledConfirm) {
              onSync()
            }
          }}
        >
          <span className='font-400 text-12px c-primary'>一键同步</span>
          <IconFont
            name='jieshishuimeng'
            className='font-12px ml-5 c-font_1/40'
          />
        </span>
      </Popconfirm>
    </Tooltip>
  )
})

SkillSync.displayName = 'SkillSync'
