import { memo, useEffect, useRef, useState } from 'react'
import { Checkbox } from 'antd'
import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import type { IHead } from '../type'
import { useMouseDrag } from '@/components/scrollbar/hooks'
import { useResizeTable } from './context'

interface HeadCellProps {
  select?: boolean
  head: IHead
  flex: string
}

export const CheckCell = memo(({ flex }: HeadCellProps) => {
  const { onRowSelectAll, observeAllRow } = useResizeTable()

  const [type, setType] = useState<boolean | 'half'>(false)

  const handleClick = useMemoizedFn(() => {
    onRowSelectAll(type === 'half' ? true : !type)
  })

  useEffect(() => {
    return observeAllRow(setType)
  }, [])

  return (
    <div
      className='resize-table-cell resize-table-cell-sticky'
      style={{ flex }}
      onClick={handleClick}
    >
      <div className='resize-table-cell-content'>
        <Checkbox checked={type === true} indeterminate={type === 'half'} />
      </div>
    </div>
  )
})

interface CellProps {
  head: IHead
  flex: string
  last: boolean
}

export const Cell = memo(({ head, flex, last }: CellProps) => {
  const { onWidthChange } = useResizeTable()

  const cellRef = useRef<HTMLDivElement>(null)
  const nowWidth = useRef<number>()
  const [resize, setResize] = useState(false)

  const handleDragStart = useMemoizedFn(() => {
    if (!cellRef.current) return
    nowWidth.current = cellRef.current.offsetWidth
  })

  const handleDragChange = useMemoizedFn(([diffX]) => {
    document.body.classList.add('in-cell-resize')
    setResize(true)
    onWidthChange(
      head.key,
      Math.max(nowWidth.current + diffX, head.minWidth ?? 24),
    )
  })

  const handleDragEnd = useMemoizedFn(() => {
    document.body.classList.remove('in-cell-resize')
    setResize(false)
    nowWidth.current = undefined
  })

  const handleMouseDown = useMouseDrag({
    onDragStart: handleDragStart,
    onDragChange: handleDragChange,
    onDragEnd: handleDragEnd,
  })

  const handleDoubleClick = useMemoizedFn(() => {
    onWidthChange(head.key)
  })

  return (
    <div
      ref={cellRef}
      className='resize-table-cell'
      style={{ '---rt-cell-flex': flex } as any}
    >
      <div className='resize-table-cell-content'>{head.title}</div>
      {head.resize !== false && !last && (
        <div
          className={classNames('resize-table-cell-bar', {
            'resize-table-cell-bar-show': resize,
          })}
          onMouseDown={handleMouseDown}
          onDoubleClick={handleDoubleClick}
        />
      )}
    </div>
  )
})
