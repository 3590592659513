import { useState } from 'react'
import { useMemoizedFn, useWebSocket } from 'ahooks'
import { wait } from '@/utils/wait.ts'

export enum State {
  none = 'none',
  pending = 'pending',
  start = 'start',
  end = 'end',
  result = 'result',
}

export function useGeneratePromptSocket(socketUrl: string) {
  const [state, setState] = useState<State>(State.none)
  const [content, setContent] = useState('')

  const handleMessage = useMemoizedFn(message => {
    const data = message.data

    try {
      const jsonData = JSON.parse(data)
      if (jsonData.type) {
        setState(jsonData.type)

        if (jsonData.type === 'result') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          disconnect()
        }
        return
      }
    } catch (err) {}

    if (state === 'start') {
      setContent(prev => prev + data)
    }
  })

  const { connect, disconnect, sendMessage } = useWebSocket(socketUrl, {
    manual: true,
    onMessage: handleMessage,
  })

  const handleSendMessage = useMemoizedFn(async (message: string) => {
    setContent('')
    setState(State.pending)
    connect()
    await wait(500)
    sendMessage(message)
  })

  const handleStop = useMemoizedFn(() => {
    disconnect()
    setState(State.end)
  })

  return {
    state,
    content,
    disconnect,
    handleSendMessage,
    handleStop,
  }
}
