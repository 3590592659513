import { Panel } from 'reactflow'
import { IconFont } from '@/components'

interface FlowMessageType {
  content: string
  width?: string
  // setShowTips: (isShowTips: boolean) => void
}

export function FlowMessage({ content, width }: FlowMessageType) {
  return (
    <Panel position='top-center' className='w-full m-12px'>
      <div
        className='h-56px ml-52px rounded-8px border-1 border-#fe970080 py-20px px-24px flex justify-between'
        style={{
          background:
            'linear-gradient(0deg, rgba(254, 151, 0, 0.08), rgba(254, 151, 0, 0.08)), #FFFFFF',
          width: width ?? '100%',
        }}
      >
        <div className='flex'>
          <IconFont name='jingzhi' className='text-#FE9700' />
          <span className='ml-12px'>{content}</span>
        </div>
        {/* <IconFont
          name='guanbi'
          className='text-#62699999 cursor-pointer'
          onClick={() => setShowTips(false)}
        /> */}
      </div>
    </Panel>
  )
}
