import { useNavigate } from 'react-router-dom'
import { Button, Modal } from '@/components'
import { useWorkspaceStore } from '@/store'

interface RepeatOrderModalProps {
  repeatOrderModalVisible: boolean
  toggleModal: () => void
  handleToOrderListCallback?: () => void
}

export function RepeatOrderModal(props: RepeatOrderModalProps) {
  const { repeatOrderModalVisible, toggleModal } = props

  const navigate = useNavigate()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  return (
    <Modal
      open={repeatOrderModalVisible}
      closable={false}
      footer={false}
      styles={{ body: { padding: '24px 20px' } }}
    >
      <h4 className='text-16px font-600'>温馨提示</h4>
      <p className='mt-20px text-14px'>
        当前工作空间存在待完成订单，请完成付款，或取消订单后重新下单。
      </p>
      <div className='mt-48px flex justify-end'>
        <Button className='mr-12px' onClick={toggleModal}>
          取消
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (props?.handleToOrderListCallback) {
              toggleModal()
              props.handleToOrderListCallback?.()
            }
            navigate(`/workspace/${currentWorkspaceId}/settings/orders`)
          }}
        >
          查看订单
        </Button>
      </div>
    </Modal>
  )
}
