import React from 'react'
import { useMemoizedFn } from 'ahooks'
import { KnowledgeDocumentConfig } from '@/features/agent/KnowledgeConfig/KnowledgeDocumentConfig'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'

function InternalAgentKnowledge() {
  const { applicationInfo, ruleRef } = useAgentEdit()

  const handleUpdate = useMemoizedFn(() => {
    ruleRef.current?.refreshRuleConfig()
  })

  if (!applicationInfo) {
    return
  }

  return (
    <KnowledgeDocumentConfig
      flowInfo={applicationInfo}
      onUpdate={handleUpdate}
    />
  )
}

export const AgentKnowledge = React.memo(InternalAgentKnowledge)
