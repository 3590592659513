import type { FormInstance } from 'antd'
import { Form, Switch, Tooltip } from 'antd'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Select, InputNumber, IconFont } from '@/components'

const StyledInputNumber = styled(InputNumber)<{ background: string }>`
  width: 52px;
  height: 28px;
  ${({ background }) =>
    background === 'rgba(98, 105, 153, 0.06)' ? 'background: #fff' : ''};
  & .ant-input-number-input-wrap,
  & .ant-input-number-input {
    height: 28px !important;
  }
`
const StyleSelect = styled(Select)<{ background: string }>`
  width: 110px;
  &.ant-select-single {
    height: 28px !important;
  }
  .ant-select-selector,
  .ant-select {
    height: 28px !important;
  }
  .ant-select-selector {
    ${({ background }) =>
      background === 'rgba(98, 105, 153, 0.06)'
        ? 'background: #fff!important'
        : ''};
  }
`
function GlobalStyled() {
  return (
    <Global
      styles={css`
        .synonymsPopupClassName {
          width: 150px !important;
          .rc-virtual-list-holder-inner {
            gap: 4px;
          }
        }
      `}
    />
  )
}
export function SearchForm({
  form,
  showResultNumber = true,
  background = 'rgba(98, 105, 153, 0.06)',
  onChange,
}: {
  form: FormInstance
  showResultNumber?: boolean
  background?: string
  onChange?: (e: any) => void
}) {
  return (
    <Form
      form={form}
      onFieldsChange={onChange}
      initialValues={{
        hit_strategy: 1,
        ranking_strategy: true,
        ...(showResultNumber ? { top_k: 6 } : {}),
      }}
    >
      <GlobalStyled />
      <div
        style={{
          background,
          borderRadius: '6px 6px 0 0',
        }}
      >
        <div
          style={{
            background,
            borderRadius: '6px 6px 0 0',
          }}
          className='flex justify-between items-center w-100% py-8px px-12px'
        >
          <div
            className='flex gap-8px items-center'
            style={{ fontSize: !showResultNumber ? '14px' : '12px' }}
          >
            <span className='text-nowrap'>查询方法</span>
            <Form.Item noStyle name='hit_strategy'>
              <StyleSelect
                background={background}
                optionLabelProp='title'
                size='small'
                options={[
                  {
                    title: '混合查询',
                    label: (
                      <Tooltip
                        placement='right'
                        title='使用语义和关键词进行查询，并综合排序，效果最好'
                      >
                        <div className='flex gap-8px items-center h-100%'>
                          混合查询
                          <span className='px-4px py-2px c-#FF8056 text-9px font-500 bg-#FFECE6 border-rd-100px'>
                            推荐
                          </span>
                        </div>
                      </Tooltip>
                    ),
                    value: 1,
                  },
                  {
                    title: '关键词查询',
                    label: (
                      <Tooltip
                        placement='right'
                        title='基于关键词查询，适合通过人名、地名等查询具体内容'
                      >
                        关键词查询
                      </Tooltip>
                    ),
                    value: 2,
                  },
                  {
                    title: '语义查询',
                    label: (
                      <Tooltip
                        placement='right'
                        title='语义相似查询，适合通过描述、问题语义查询相似答案'
                      >
                        语义查询
                      </Tooltip>
                    ),
                    value: 3,
                  },
                ]}
                popupClassName='synonymsPopupClassName'
              ></StyleSelect>
            </Form.Item>
          </div>
          <div
            className='flex gap-8px items-center mx-16px'
            style={{ fontSize: !showResultNumber ? '14px' : '12px' }}
          >
            <span className='text-nowrap'>结果重排</span>
            <Tooltip title='根据查询内容的语义，对查询的结果按相关度再次精确排序，查询匹配更准确，但耗时较长'>
              <IconFont
                name='jieshishuimeng'
                style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                className='ml--4px cursor-pointer'
              />
            </Tooltip>
            <Form.Item noStyle name='ranking_strategy'>
              <Switch size='small' />
            </Form.Item>
          </div>
          {showResultNumber && (
            <div className='flex gap-8px items-center text-12px'>
              <div className='text-nowrap'>最大结果数</div>
              <Tooltip title='从知识库中查询 X 条结果（ X 等于你设置的数字，X 的最大值为30）'>
                <IconFont
                  name='jieshishuimeng'
                  style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                  className='ml--4px cursor-pointer'
                />
              </Tooltip>
              <Form.Item noStyle name='top_k'>
                <StyledInputNumber
                  max={30}
                  min={1}
                  background={background}
                  size='small'
                />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}
