export enum Version {
  // FREE = 'Free',
  // PRO = 'Pro',
  // PRO_PLUS = 'Pro+',
  FREE = 'Free',
  PERSONAL = 'Personal',
  TEAM = 'Team',
  ENTERPRISE = 'Enterprise',
}

export enum PayStep {
  VersionSelect = 0,
  OrderConfirm = 1,
  PayResult = 2,
}

export enum DurationType {
  MONTH = 1,
  YEAR = 2,
}

export enum PayType {
  ALI_PAY = 'alipay',
  TO_B = 'tob',
}

export enum OrderType {
  // 订购
  SUB = 1,
  // 续费
  RENEW = 2,
  // 加量包积分类型
  DOSAGE_PACKAGE_YEAH = 3,
  // 加量包agent加量类型
  DOSAGE_PACKAGE_AGENT_COUNT = 4,
}

export enum VersionStatus {
  UnUsed = 1, // 未使用
  Used = 2, // 使用中
  LoseEffect = 3, // 已过期
}
export const DurationTypeText = {
  [DurationType.MONTH]: '月',
  [DurationType.YEAR]: '年',
}

export enum OrderStatus {
  WAIT_PAY = 'WAIT_PAY',
  PLACE_ORDER = 'PLACE_ORDER',
  COMPLETED = 'COMPLETED',
  WAIT_AUDIT = 'WAIT_AUDIT',
  AUDIT_DISMISS = 'AUDIT_DISMISS',
  WAIT_UPLOAD_CERT = 'WAIT_UPLOAD_CERT',
  CLOSED = 'CLOSED',
}

export enum OrderServiceStatus {
  EFFECTING = 'EFFECTING', // 生效中
  EXPIRED = 'EXPIRED', // 已过期
  WAITING_EFFECT = 'WAITING_EFFECT', // 待生效
  LOSE_EFFECT = 'LOSE_EFFECT', // 已失效
}

export const HAS_REPEATED_ORDER_CODE = 20006

export const VersionSuitableText = {
  [Version.FREE]: '面向新用户免费试用 14 天',
  [Version.PERSONAL]: '适合个人/个人创业者',
  [Version.TEAM]: '适合团队/独立工作室',
  [Version.ENTERPRISE]: '适合中大型企业/组织',
}

export const VersionShowText = {
  [Version.FREE]: '试用版',
  [Version.TEAM]: '团队版',
  [Version.ENTERPRISE]: '企业版',
}

/**
 * 倒计时天数，剩余多少天开始提醒
 */
export const ReminderRemainingDays = 15
