import { Segmented as AntdSegmented } from 'antd'
import styled from '@emotion/styled'
import { rgba } from 'polished'

export const Segmented = styled(AntdSegmented)`
  padding: ${props => (props.size === 'small' ? '2px' : '4px')};
  background-color: ${props => rgba(props.theme.colors.bg_3, 0.08)};
  color: ${props => props.theme.colors.font_1};
  font-size: 12px;
  border-radius: 10px;
  &.ant-segmented-sm {
    border-radius: 8px;
    .ant-segmented-thumb,
    .ant-segmented-item {
      border-radius: 6px;
    }
  }
  .ant-segmented-item {
    padding: ${props => (props.size === 'small' ? '6px' : '12px')} 16px;
    border-radius: 6px;
    cursor: pointer;
    &::after {
      display: none;
    }
    &:hover &:active {
      &:not(.ant-segmented-item-selected) &:not(.ant-segmented-item-disabled) {
        background-color: ${props => rgba(props.theme.colors.bg_3, 0.08)};
      }
    }
    .ant-segmented-item-label {
      min-height: 16px;
      line-height: 16px;
    }
  }
` as typeof AntdSegmented
