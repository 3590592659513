import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Popover } from 'antd'
import { usePrevious } from 'ahooks'
import { Button } from '@/components'

interface DeletePopoverProps {
  className?: string
  title: string
  onDelete: () => void
  onOpen?: () => void
  onClose?: () => void
  children: ReactNode
  deleteText?: string
  closeText?: string
}

export function DeletePopover(props: DeletePopoverProps) {
  const {
    className,
    title,
    onDelete,
    onClose,
    onOpen,
    deleteText = '删除',
    closeText = '取消',
  } = props
  const [open, setOpen] = useState(false)
  const previousOpen = usePrevious(open)
  const ref = useRef<HTMLDivElement | null>(null)

  const onDeleteClick = () => {
    setOpen(false)
    onDelete()
  }

  useEffect(() => {
    if (!!open !== !!previousOpen) {
      open ? onOpen?.() : onClose?.()
    }
  }, [open, previousOpen])

  return (
    <div className={className} ref={ref}>
      <Popover
        getPopupContainer={() => ref.current!}
        open={open}
        onOpenChange={(v: boolean) => setOpen(v)}
        overlayInnerStyle={{ padding: 0 }}
        trigger='click'
        zIndex={2300}
        content={
          <div className='flex flex-col w-240px h-120px p-16px'>
            <div className='flex-1 line-height-20px font-500 text-12px c-#17171d'>
              {title}
            </div>
            <div className='flex justify-end'>
              <Button
                size='small'
                className='!h-28px'
                onClick={() => {
                  setOpen(false)
                  onClose?.()
                }}
              >
                {closeText}
              </Button>
              <Button
                size='small'
                className='!h-28px m-l-8px'
                danger
                type='primary'
                onClick={onDeleteClick}
              >
                {deleteText}
              </Button>
            </div>
          </div>
        }
      >
        {props.children}
      </Popover>
    </div>
  )
}
