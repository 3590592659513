import type { FormSchema } from '@bty/ui-components'
import type { FieldItem } from '@/features/nodes/start/constant.ts'

export function transformConfig2SchemaConfig(
  config: FieldItem[],
  extra?: Record<string, any>,
): FormSchema[] {
  return config.map(configItem => ({
    ...configItem,
    ...extra,
    name: configItem.variableName,
  }))
}
