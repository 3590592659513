import classNames from 'classnames'
import type { CSSProperties } from 'react'

export function BetaTag(props: {
  className?: string
  style?: CSSProperties
  text?: string
}) {
  return (
    <span
      className={classNames(
        'py-2 px-4 rd-2px bg-bg_3 bg-op-12 c-#3F3F44 text-12px/12px font-400',
        props.className,
      )}
      style={props.style}
    >
      {props.text ?? 'Beta'}
    </span>
  )
}
