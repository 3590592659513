import { memo, useEffect, useState, useMemo } from 'react'
import { Form, message, Popover, Tooltip } from 'antd'
import { useBoolean, useRequest } from 'ahooks'
import { Select, IconFont, Button, usePageModal } from '@/components'
import ai_select_file from '@/assets/dataset/ai_select_file.png'
import { searchScopeType } from '../KnowledgeNode'
import { TextEditor } from '@/features/editor/TextEditor'
import { getUniversalPrompt } from '@/apis/prompt'
import { useWorkspaceStore } from '@/store'
import { VariableRegex } from '@/constants/common'

const showcaseUrl =
  'https://ai-docs.betteryeah.com/%E7%9F%A5%E8%AF%86%E5%BA%93/%E7%9F%A5%E8%AF%86%E5%BA%93%E8%8A%82%E7%82%B9-%E4%BB%85%E6%9F%A5%E8%AF%A2%E6%8C%87%E5%AE%9A%E6%96%87%E4%BB%B6.html'

const usageUrl =
  'https://ai-docs.betteryeah.com/%E7%9F%A5%E8%AF%86%E5%BA%93/%E7%9F%A5%E8%AF%86%E5%BA%93%E8%8A%82%E7%82%B9-%E4%BB%85%E6%9F%A5%E8%AF%A2%E6%8C%87%E5%AE%9A%E6%96%87%E4%BB%B6.html#%E8%83%BD%E5%8A%9B%E8%BF%9B%E9%98%B6-%E8%B0%83%E6%95%B4-ai%E8%87%AA%E5%8A%A8%E9%80%89%E6%8B%A9%E6%96%87%E4%BB%B6-%E7%9A%84%E8%A7%84%E5%88%99'

interface SearchRangeProps {
  value?: string
  aiPromptValue: string | undefined
  onChange: (value: string) => void
  popupContainerRef: React.RefObject<HTMLDivElement>
  handleFormChange: (params: Record<string, any>) => void
  inNodePanel?: boolean
  memoryId?: number | undefined
}

function SearchRange({
  popupContainerRef,
  value,
  aiPromptValue = '',
  handleFormChange,
  inNodePanel = false,
  memoryId,
}: SearchRangeProps) {
  const [
    promptEditorPopupVisible,
    { setTrue: openPromptEditor, setFalse: closePromptEditor },
  ] = useBoolean(false)
  const pageModal = usePageModal()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const [promptValue, setPromptValue] = useState(() => aiPromptValue)

  const datastoreUrl = useMemo(() => {
    if (memoryId && !VariableRegex.test(memoryId?.toString())) {
      return `/datastores/${workspaceId}/${memoryId}/documents`
    } else {
      return '/datastores'
    }
  }, [workspaceId])

  const { data: defaultAiPrompt } = useRequest(getUniversalPrompt, {
    cacheKey: 'getUniversalPrompt_knwoledgeNode_aiPrompt',
    defaultParams: [
      {
        module: 'dataset',
        prompt_type: 'ai_search_dataset',
      },
    ],
  })

  useEffect(() => {
    // 如果第一次选择AI自动选择文件，且没有输入提示词，则默认使用AI提示词，且触发保存请求
    if (value === searchScopeType.Ai && !promptValue && defaultAiPrompt) {
      setPromptValue(defaultAiPrompt)
      handleFormChange({ ai_prompt: defaultAiPrompt })
    }
  }, [aiPromptValue, defaultAiPrompt, value])

  useEffect(() => {
    if (!promptEditorPopupVisible && aiPromptValue) {
      setPromptValue(aiPromptValue)
    }
  }, [promptEditorPopupVisible, aiPromptValue])

  return (
    <>
      <Select
        className='nodrag nopan'
        options={[
          { label: '全部文件', value: searchScopeType.All },
          { label: '按标签查询', value: searchScopeType.Tags },
          {
            label: '仅查询指定文件 - 手动选择文件',
            value: searchScopeType.specifyFileIds,
          },
          {
            label: '仅查询指定文件 - AI自动选择文件',
            value: searchScopeType.Ai,
          },
        ]}
        value={value}
        onChange={value => {
          if (
            [searchScopeType.Tags, searchScopeType.specifyFileIds].includes(
              value as searchScopeType,
            ) &&
            !memoryId
          ) {
            message.warning('请先选择知识库')
          }
          handleFormChange({ searchScope: value })
        }}
        size='small'
        getPopupContainer={() => popupContainerRef.current!}
      />
      {value === searchScopeType.Ai && (
        <>
          <Popover
            placement='leftTop'
            overlayClassName='nodrag nopan w-242px'
            content={
              <div className='p-8px'>
                <img src={ai_select_file} alt='' className='w-202px h-184px' />
                <div className='text-12px color-#8D8D99 text-center mt-15.5px'>
                  AI自动选择文件
                </div>
              </div>
            }
            arrow={false}
            getPopupContainer={
              inNodePanel ? undefined : () => popupContainerRef.current!
            }
            align={
              inNodePanel
                ? {
                    offset: [-24, 0],
                    useCssTransform: true,
                  }
                : {
                    useCssTransform: true,
                    targetOffset: ['6.18%', 0],
                  }
            }
          >
            <div className='cursor-pointer mt-16px rounded-6px bg-#626999 bg-op-06 py-8px px-12px relative'>
              <div className='font-500 text-12px/20px mb-4px'>
                AI自动选择文件
              </div>
              <div className='w-220px text-12px/20px color-#8D8D99'>
                第一步：首次使用，请先阅读{' '}
                <a
                  className='color-primary'
                  onClick={() => pageModal.show({ url: showcaseUrl })}
                >
                  使用案例
                </a>{' '}
                第二步：前往
                <a
                  className='color-primary'
                  onClick={() =>
                    pageModal.show({
                      url: datastoreUrl,
                    })
                  }
                >
                  知识库
                </a>
                ，修改知识文件名称
              </div>
            </div>
          </Popover>
          <Popover
            open={promptEditorPopupVisible}
            arrow={false}
            placement='bottomRight'
            overlayClassName='nodrag nopan min-w-480px'
            getPopupContainer={() => popupContainerRef.current!}
            content={
              <div className='p-4px'>
                <div className='text-14px/16px font-500 mb-16px'>
                  修改AI规则
                </div>
                <div className='flex justify-between'>
                  <div className='font-500 text-12px flex items-center'>
                    修改提示词
                    <span className='c-#ff5219 text-18px ml-5px block h-12px'>
                      *
                    </span>
                  </div>
                  <div className='text-12px/18px color-#7B61FF flex'>
                    <span
                      className='inline-flex px-4px py-2px mr-12px cursor-pointer'
                      onClick={() => setPromptValue(defaultAiPrompt || '')}
                    >
                      <IconFont name='tongshi' className='mr-4px' />
                      重置
                    </span>
                    <span
                      className='inline-flex px-4px py-2px cursor-pointer'
                      onClick={() =>
                        pageModal.show({
                          url: usageUrl,
                        })
                      }
                    >
                      <IconFont name='jieshishuimeng' className='mr-4px' />
                      如何修改
                    </span>
                  </div>
                </div>
                <Form.Item
                  validateStatus={!promptValue ? 'error' : 'success'}
                  help={!promptValue ? '请输入提示词' : ''}
                >
                  <TextEditor
                    className='mt-10px bg-#626999 bg-op-06 rounded-6px w-448px h-232px py-8px px-12px text-12px/18px'
                    value={promptValue}
                    onChange={setPromptValue}
                    aria-invalid={!promptValue ? 'true' : undefined}
                  />
                </Form.Item>
                <div className='text-right mt-16px'>
                  <Button onClick={closePromptEditor}>取消</Button>
                  <Button
                    type='primary'
                    className='ml-12px'
                    onClick={() => {
                      if (!promptValue) {
                        return
                      }
                      handleFormChange({ ai_prompt: promptValue })
                      closePromptEditor()
                    }}
                  >
                    保存
                  </Button>
                </div>
              </div>
            }
          >
            <Tooltip
              title='AI选择文件效果不理想？点击修改AI规则'
              placement='top'
              getPopupContainer={() => popupContainerRef.current!}
              zIndex={10}
            >
              <div
                className='p-4px inline-block absolute top-54px right-6px hover:bg-#626999 hover:bg-op-08 rounded-4px cursor-pointer'
                onClick={openPromptEditor}
              >
                <IconFont
                  name='shezhi'
                  className='text-14px color-#626999 color-op-60'
                />
              </div>
            </Tooltip>
          </Popover>
        </>
      )}
    </>
  )
}

export const SearchRangeSelect = memo(SearchRange)
