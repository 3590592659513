import type { Node } from 'reactflow'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import type { NodeMeta, NodeType } from '@/features/nodes/base'

interface NodeStore {
  activatedNodeMeta?: NodeMeta // 激活节点
  activatedNodeId?: string
  activatedNodeType?: NodeType | undefined
  activatedNodeData?: Record<string, unknown>
  activateNode: (
    nodeId: string,
    nodeMeta: NodeMeta,
    data: Record<string, unknown>,
  ) => void
  deactivateNode: () => void
  commentVisibleMap: Record<string, boolean>
  setCommentVisibleMap: (
    map: Record<string, boolean>,
    isOptional?: boolean,
  ) => void
  showAllComment: (nodes: Node[]) => void
  hideAllComment: () => void
  isAllCommentHide: () => boolean
}

export const useNodeStore = createWithEqualityFn<NodeStore>(
  (set, get) => ({
    activatedNodeMeta: undefined,
    activatedNodeId: undefined,
    activatedNodeData: undefined,
    commentVisibleMap: {},
    activatedNodeType: undefined,
    activateNode: (nodeId, nodeMeta, data) =>
      set({
        activatedNodeId: nodeId,
        activatedNodeMeta: nodeMeta,
        activatedNodeData: data,
        activatedNodeType: nodeMeta?.type,
      }),
    deactivateNode: () =>
      set({
        activatedNodeId: undefined,
        activatedNodeMeta: undefined,
        activatedNodeData: undefined,
        activatedNodeType: undefined,
      }),
    setCommentVisibleMap: (map, isOptional = true) => {
      const commentVisibleMap = get().commentVisibleMap
      const newMap = isOptional ? { ...commentVisibleMap, ...map } : map
      set({ commentVisibleMap: newMap })
    },
    hideAllComment: () => {
      const commentVisibleMap = get().commentVisibleMap

      get().setCommentVisibleMap(
        Object.keys(commentVisibleMap).reduce<Record<string, boolean>>(
          (acc, cur) => {
            acc[cur] = false
            return acc
          },
          {},
        ),
      )
    },
    showAllComment: (nodes: Node[]) => {
      const commentNodes = nodes.filter(n => n.data.comment)
      const newMap = commentNodes.reduce<Record<string, boolean>>(
        (acc, cur) => {
          acc[cur.id] = true
          return acc
        },
        {},
      )
      set({
        commentVisibleMap: newMap,
      })
    },
    isAllCommentHide: () => {
      const commentVisibleMap = get().commentVisibleMap

      return Object.values(commentVisibleMap).every(key => !key)
    },
  }),
  shallow,
)

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('nodesStore', useNodeStore)
}
