import { cloneDeep } from 'lodash-es'
import { type UploadFileSupportTypes } from '@bty/chat-types'
import { PartitionCategoryType } from '@/apis/datastore/types'
import { type IAgentEditContext } from './provider/AgentEditProvider'
import type { templateRuleFormData } from './constant/base'

type RuleFormData = typeof templateRuleFormData

function replace(obj: any, placeholder: string, target: string) {
  Object.entries(obj).forEach(([k, v]) => {
    if (typeof v === 'string') {
      obj[k] = v.replace(placeholder, target)
    } else {
      replace(v, placeholder, target)
    }
  })
}

export function getRuleFormDataByTemplate(
  template: RuleFormData,
  userName = '',
): RuleFormData {
  const templateData = cloneDeep(template)
  replace(templateData, '{user}', userName)
  return templateData
}

export function getPartitionTypeName(type?: PartitionCategoryType) {
  return type === PartitionCategoryType.QA ? '问答' : '文档'
}

export function transformFileSupportTypesRawData(
  raw: UploadFileSupportTypes[],
): IAgentEditContext['uploadFileConfig'] {
  if (!raw || raw?.length === 0) {
    return {
      fileAccepts: [],
      allowFileTypeNameList: [],
    }
  }
  const fileAccepts = new Set<string>()
  const allowFileTypeNameList = new Set<string>()

  raw.forEach(item => {
    allowFileTypeNameList.add(item.name)
    item.support.forEach(type => fileAccepts.add(type))
    item.extension.forEach(type => fileAccepts.add(type))
  })

  return {
    fileAccepts: Array.from(fileAccepts),
    allowFileTypeNameList: Array.from(allowFileTypeNameList),
  }
}
