import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { noop } from 'lodash-es'
import { useNavigate, useParams } from 'react-router-dom'
import { useRequest } from 'ahooks'
import { getDataBaseInfo } from '@/apis/database/api.ts'
import type { Database } from '@/apis/database/types'

export const DatabaseContext = createContext<{
  id: string
  baseInfo?: Database
  refresh: VoidFunction
  onBackList: VoidFunction
}>({
  id: '',
  refresh: noop,
  onBackList: noop,
})

export const DatabaseProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, refreshAsync } = useRequest(getDataBaseInfo, {
    defaultParams: [id!],
  })

  const onBackList = () => {
    navigate('/db')
  }

  return (
    <DatabaseContext.Provider
      value={{
        id: id!,
        baseInfo: data,
        refresh: refreshAsync,
        onBackList,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  )
}

export function useDatabase() {
  const context = useContext(DatabaseContext)
  if (context === null) {
    throw new Error('useDatabase 必须在 DatabaseProvider 中使用')
  }

  return context
}
