import { Form } from 'antd'
import { useMemo } from 'react'
import {
  NodeOperationTypes,
  NodeType,
  type NodeComponent,
} from '@/features/nodes/base'
import type { JsonFormConfig } from '../components/JsonForm'
import { JsonForm } from '../components/JsonForm'
import { RequiredMark } from '@/components'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { DatabaseSelect } from './DatabaseSelect'
import { SchemaPopover } from './SchemaPopover'

export interface DatabaseNodeProps {
  name: string
  inputs: {
    baseId?: string
    database?: { id: string; title: string }
    executableSQL?: string
  }
  packageName: 'integration.database.DatabaseAction'
}

export const DatabaseNode: NodeComponent<DatabaseNodeProps> = ({
  variables,
  nodeElement,
  nodeSize,
}) => {
  const [form] = Form.useForm()
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const maxLines = nodeSize.height
    ? Math.floor((nodeSize.height - 238) / 16) - 1
    : 20

  const formList = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        className: 'px-16',
        label: '选择数据库',
        name: ['inputs', 'database'],
        required: true,
        rules: [{ required: true, message: '数据库不能为空' }],
        render: props => (
          <DatabaseSelect
            {...props}
            disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          />
        ),
      },
      {
        noStyle: true,
        render: () => (
          <div className='h-1px w-full bg-[rgba(225,225,229,0.6)] mb-10' />
        ),
      },
      {
        label: (
          <>
            <span className='text-12px/16px font-500'>SQL</span>
            <RequiredMark />
            <SchemaPopover />
          </>
        ),
        className:
          'px-16 [&_.ant-form-item-required]:w-full [&_.ant-form-item-required]-after:hidden [&_.ant-form-item-label]:overflow-visible [&_.ace_editor]:!w-50%',
        type: 'CodeEditor',
        name: ['inputs', 'executableSQL'],
        required: true,
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        rules: [{ required: true, message: 'SQL 不能为空' }],
        componentProps: {
          height: nodeSize.height ? `${nodeSize.height - 238}px` : '340px',
          mode: 'sql',
          variables,
          setOptions: {
            minLines: maxLines,
            maxLines,
          },
          variableTipsContainer: nodeElement,
        },
      },
    ]
  }, [nodeSize, maxLines, variables, nodeElement])

  return useMemo(
    () => (
      <div
        className='pt-16 nodrag h-full'
        style={{ width: nodeSize.width }}
        onWheel={e => e.stopPropagation()}
      >
        <JsonForm
          form={form}
          list={formList}
          disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          beforeChange={values => {
            const _values = values as { inputs: DatabaseNodeProps['inputs'] }
            return {
              inputs: {
                database: { ..._values.inputs.database },
                baseId: _values.inputs.database?.id,
                executableSQL: _values.inputs.executableSQL,
              },
            }
          }}
        />
      </div>
    ),
    [nodeSize.width, form, formList],
  )
}

DatabaseNode.meta = {
  type: NodeType.DATABASE,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'INTEGRATION',
  typeName: '数据库',
  icon: 'shujukujiedian',
  canDelete: true,
  resizeable: true,
  resizeConfig: {
    minWidth: 420,
    minHeight: 340,
    maxWidth: 1200,
    maxHeight: 960,
  },
  initialData: {
    name: 'database_1',
    inputs: {},
    packageName: 'integration.database.DatabaseAction',
  },
  backgroundColor: '#FF6161',
  description: '向数据库，查询或者导入数据',
}
