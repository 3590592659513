import { useState } from 'react'
import { Avatar, message } from 'antd'
import { useRequest } from 'ahooks'
import { map } from 'lodash-es'
import type { AgentDetailResponse } from '@bty/chat-types'
import { fetchAgentDetail } from '@/apis/agent'
import { Button, IconFont } from '@/components'
import logo from '@/assets/newlogo.svg?url'
import {
  ApplyType,
  ResultOfApply,
  applyForPermission,
  queryResultOfApply,
} from '@/apis/notification'
import { RoleCode, getAdminMembers } from '@/apis/authority'
import theme from '@/constants/theme'

export function AgentNotAllowed({
  agentId,
  roleCode,
  showLogo = true,
  type = ApplyType.VIEWER,
}: {
  agentId?: string
  roleCode?: RoleCode
  showLogo?: boolean
  type?: ApplyType
}) {
  const [applied, setApplied] = useState(false)

  const [agent, setAgent] = useState<AgentDetailResponse | null>(null)
  // console.log("agent", agent)

  useRequest(() => fetchAgentDetail(agentId as string), {
    refreshDeps: [agentId],
    ready: !!agentId,
    onSuccess: e => setAgent(e),
  })

  const applicationId = agent?.application?.id

  const workspaceId = agent?.workspace_id ?? ''

  const { data: adminList } = useRequest(
    () =>
      getAdminMembers({
        workspaceId,
        applicationId,
      }),
    {
      refreshDeps: [applicationId, workspaceId],
      ready: !!applicationId && !!workspaceId,
    },
  )

  const { data: resultOfApply, runAsync: getResultOfApply } = useRequest(
    () => queryResultOfApply(applicationId as string, workspaceId as string),
    {
      refreshDeps: [applicationId, workspaceId],
      ready: !!applicationId && !!workspaceId,
    },
  )

  const _applied = applied || resultOfApply?.status === ResultOfApply.APPLY

  const handleApply = async () => {
    if (!applicationId || !workspaceId) {
      return
    }
    if (_applied) {
      window.location.reload()
      return
    }
    try {
      const data = await applyForPermission(applicationId, workspaceId, type)
      if (data?.success === false) {
        message.warning(data?.message)
      }
      await getResultOfApply()
      setApplied(true)
    } catch (err) {
      message.error('出错了，请稍后再试')
    }
  }

  const reject = resultOfApply?.status === ResultOfApply.REJECTED

  return (
    <div className='flex flex-col justify-between h-full'>
      {showLogo && (
        <img
          className='absolute object-contain left-0 ml-20 mt-20 h-32px'
          src={logo}
        />
      )}
      <div className='flex-1 flex flex-col items-center relative justify-center'>
        {agent?.application?.icon?.includes('http') ? (
          <Avatar
            src={agent?.application?.icon}
            alt={agent?.application?.icon}
            size={120}
          />
        ) : (
          <div
            className='h-120px w-120px border-rd-50% text-40px flex justify-center items-center'
            style={{ background: agent?.application?.color }}
          >
            {agent?.application?.icon}
          </div>
        )}
        <h1 className='text-font mt-20 text-20px font-semibold'>
          {agent?.application?.name}
        </h1>
        <p className='text-font_1 mt-12 text-center text-14px/22px max-w-400px'>
          {agent?.application?.description}
        </p>
        <p className='text-font_1 mt-12 text-center text-14px'>
          开发者：
          {resultOfApply?.approve_user_name || agent?.application?.createdBy}
          <span className='mx-8'>│</span>
          空间：{agent?.workspace_name}
        </p>
        <span className='my-30 w-400px border-0 border-t border-solid border-[rgba(225,225,229,0.6)]' />
        {reject && <IconFont name='pay-error' className='mb-8px text-30px' />}
        <p className='text-font text-center text-14px/22px font-medium'>
          {reject
            ? `无法访问，${resultOfApply.approve_user_name} 拒绝了你的申请`
            : _applied
              ? `已向“${
                  resultOfApply?.approve_user_name ||
                  map(adminList, 'username')?.join('、')
                }”发送申请消息，请耐心等待`
              : `没有${
                  roleCode === RoleCode.DEVELOPER ? '开发' : '使用'
                }权限，可以向“${
                  resultOfApply?.approve_user_name ||
                  map(adminList, 'username')?.join('、')
                }”申请`}
        </p>
        {resultOfApply === undefined ? null : (
          <Button
            className='mt-16 w-204px disabled:!bg-[rgba(123,97,255,0.4)] disabled:!text-white'
            type='primary'
            style={
              _applied
                ? {
                    color: theme.colors.primary,
                    background: 'none',
                    border: `1px solid ${theme.colors.primary}`,
                  }
                : {}
            }
            onClick={handleApply}
          >
            {reject
              ? '重新申请'
              : _applied
                ? '刷新页面'
                : roleCode === RoleCode.DEVELOPER
                  ? '申请开发权限'
                  : '申请使用'}
          </Button>
        )}
      </div>
    </div>
  )
}
