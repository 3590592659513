import '@bty/smartsheet/dist/index.css'
import type { SmartsheetInstance } from '@bty/smartsheet'
import { Smartsheet } from '@bty/smartsheet'
import { useEffect, useRef, useState } from 'react'
import { Spin } from 'antd'
import NiceModal from '@ebay/nice-modal-react'
import { useTable } from '@/features/database/provider/TableProvider'
import Empty from '@/features/database/components/Empty'
import { Button, IconFont } from '@/components'
import TableCreateDropdown from '@/features/database/components/TableCreateDropdown'
import TableDescEditPanel from '@/features/database/components/TableDescEditPanel'
import { createTable } from '@/apis/database/api'
import { setFirstUserColumnToPrimary } from '@/features/database/utils/column.ts'
import { bindTableForAgent } from '@/apis/agent'
import type { Database } from '@/apis/database/types'
import { useDatabase } from './provider/DatabaseProvider'
import TableImportModal from './components/TableImportModal'

export default function TablePanel() {
  const {
    reloadDataEvent,
    currentTable: table,
    tables,
    tablesLoading,
    reloadCurrentTable,
    refreshTables,
    setCurrentTableId,
  } = useTable()

  const { baseInfo: base } = useDatabase()

  const [tableEditPanelVisible, setTableEditPanelVisible] = useState(false)

  const smartsheetRef = useRef<SmartsheetInstance | null>(null)

  reloadDataEvent?.useSubscription(() => {
    if (smartsheetRef.current) {
      smartsheetRef.current.reloadData()
    }
  })

  useEffect(
    () => () => {
      smartsheetRef.current?.unmount()
    },
    [],
  )

  useEffect(() => {
    if (!base || !table) {
      return
    }

    if (smartsheetRef.current) {
      smartsheetRef.current.update({
        base,
        table,
      })
      return
    }

    smartsheetRef.current = Smartsheet({
      dom: '#smartsheet',
      base,
      table,
      onTableMetaUpdate: () => {
        setTableEditPanelVisible(true)
      },
      onTableMetaUpdated: reloadCurrentTable,
      onImportRow: (tableId, databaseId, sourceId, afterImport) => {
        NiceModal.show(TableImportModal, {
          delta: true,
          tableId,
          database: { id: databaseId, sources: [{ id: sourceId }] } as Database,
          onOk: async () => {
            afterImport?.()
          },
        })
      },
    })
  }, [base, table])

  if (tablesLoading) {
    smartsheetRef.current = null

    return (
      <div className='w-full h-full flex-center'>
        <Spin />
      </div>
    )
  }

  if (!tables.length) {
    return (
      <Empty desc='还没有数据表'>
        <TableCreateDropdown
          database={base}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onOk={async (...args) => {
            if (args.length === 3) {
              const [database_id, source_id, db_params] = args
              const table = await createTable(database_id, source_id, db_params)
              await setFirstUserColumnToPrimary(table.columns ?? [])
              await refreshTables()
              setCurrentTableId(table.id)
              bindTableForAgent({
                database_id,
                table_id: table.id,
                source_id,
                flow_id: null,
                version_id: null,
              })
              return
            }
            await refreshTables()
            setCurrentTableId(args[0])
          }}
        >
          <Button
            size='large'
            className='px-23!'
            type='primary'
            icon={<IconFont name='add' />}
          >
            添加数据表
          </Button>
        </TableCreateDropdown>
      </Empty>
    )
  }

  return (
    <div className='flex w-full h-full overflow-hidden'>
      <div
        id='smartsheet'
        className='body p-16 pt-0 flex-1 bg-bg w-full overflow-hidden'
      />
      <TableDescEditPanel
        open={tableEditPanelVisible}
        onClose={() => setTableEditPanelVisible(false)}
      />
    </div>
  )
}
