import { IconFont } from '@/components'

export function Delete({ onClick }: { onClick?: () => void }) {
  return (
    <div className='flex items-center justify-center w-24px h-24px text-opacity-60  text-font_1 rounded-4px hover:bg-error hover:bg-opacity-12 hover:text-error'>
      <IconFont
        name='shanshu'
        className='text-16px cursor-pointer '
        onClick={onClick}
      />
    </div>
  )
}
