import styled from '@emotion/styled'
import { memo, useMemo, useRef } from 'react'
import classNames from 'classnames'
import type { InputRef } from 'antd'
import { Input } from 'antd'
import { useEditName } from './useEditName'

const StyledInput = styled(Input)`
  width: 220px;
  height: 24px;
  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  &:disabled {
    color: #fff;
  }
`

export enum EDIT_TITLE_THEME {
  BLACK = 'black',
  WHITE = 'white',
}

export interface EditTitleProps {
  initialIsEdit?: boolean
  value: string
  nodeId: string
  onSave?: (value: string) => void
  disabled?: boolean
  theme?: 'black' | 'white'
  onFocus?: () => void
  onBlur?: () => void
}

export const EditTitle = memo((props: EditTitleProps) => {
  const {
    initialIsEdit,
    value,
    disabled,
    nodeId,
    onSave,
    theme = EDIT_TITLE_THEME.WHITE,
    onFocus,
    onBlur,
  } = props

  const nameRef = useRef(null)
  const inputRef = useRef<InputRef>(null)

  const {
    name,
    isEdit,
    toggle: toggleTitleEdit,
    setName,
    onSaveTitle,
  } = useEditName(value, {
    id: nodeId,
    target: nameRef,
    initialIsEdit,
    saveCallback(title) {
      onSave?.(title)
    },
  })

  const onEdit = () => {
    if (!disabled) {
      toggleTitleEdit()
      setTimeout(() => {
        inputRef.current?.focus({
          cursor: 'all',
        })
      }, 100)
    }
  }

  const styleMap = useMemo(() => {
    if (theme === EDIT_TITLE_THEME.WHITE) {
      return {
        input:
          'nodrag nopan text-white  text-12px flex items-center p-4 b-1px b-rd-4px b-white b-opacity-12 bg-white bg-opacity-6 hover:bg-opacity-0 hover:bg-opacity-12 hover:bg-font hover:b-transparent focus:bg-opacity-0 focus:bg-opacity-12 focus:bg-font',
        div: 'nodrag text-12px flex items-center h-20px p-4 b-1px b-rd-4px b-white b-opacity-12 bg-white bg-opacity-6 hover:bg-opacity-0 hover:bg-opacity-12 hover:bg-font hover:b-transparent',
      }
    } else {
      return {
        input:
          'nodrag nopan text-12px flex items-center text-#8D8D99 p-4 b-1px  b-rd-4px b-opacity-12 bg-opacity-6  hover:bg-opacity-8 hover:bg-#626999 hover:b-transparent !focus:b-[rgb(123,97,255)] !w-140px',
        div: 'nodrag text-[rgba(141,141,153)] text-12px flex items-center h-20px p-4 b-1px b-rd-4px b-#E1E1E5 b-opacity-80 bg-white hover:bg-[#626999] hover:bg-opacity-8 hover:b-[rgba(225,225,229,0.8)]',
      }
    }
  }, [theme])

  return (
    <div>
      {isEdit ? (
        <div ref={nameRef}>
          <StyledInput
            size='small'
            disabled={disabled}
            value={name}
            maxLength={16}
            onDoubleClick={e => {
              e && e.stopPropagation()
            }}
            ref={inputRef}
            onPressEnter={onSaveTitle}
            onChange={e => setName?.(e.target.value)}
            className={styleMap.input}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      ) : (
        <div
          onClick={onEdit}
          className={classNames(styleMap.div, {
            'cursor-pointer': !disabled,
            'cursor-not-allowed': disabled,
          })}
        >
          {value}
        </div>
      )}
    </div>
  )
})
