import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useWorkspaceStore } from '@/store'
import { Button, Select } from '@/components'

interface WorkSpaceListProps {
  id: string
  creatorName: string
  name: string
  roleCode: string
  creator: number
}

const BgContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    343deg,
    rgba(170, 85, 255, 0.28) 5%,
    rgba(76, 105, 255, 0.2) 20%,
    rgba(104, 192, 255, 0.12) 47%,
    rgba(168, 252, 255, 0) 75%
  );
  .div_shadow {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
  }
`
export default function () {
  const navigate = useNavigate()
  const location = useLocation()
  const { setCurrentWorkspace, setWorkspaceList } = useWorkspaceStore()
  const handleWorkSpace = (data: WorkSpaceListProps[]) => {
    return data?.map((item: WorkSpaceListProps) => ({
      label: item.name,
      value: item.id,
    }))
  }
  const [selectedValue, setSelectedValue] = useState(
    handleWorkSpace(location?.state?.workSpaceList)?.[0]?.value,
  )
  const changeWorkspace = () => {
    setCurrentWorkspace(selectedValue)
    setWorkspaceList(location?.state?.workSpaceList)
    navigate('/')
  }
  const immediateCreate = () => {
    navigate('/login?type=workspace')
  }
  return (
    <BgContainer>
      <div className='w-448px h-348px bg-#FFFFFF rounded-12px div_shadow'>
        <div className='mx-48px my-60px'>
          <div className='text-24px/24px font-800 c-#17171D'>
            选择进入工作空间
          </div>
          <p className='text-14px/14px mt-16px'>
            <span className='c-#17171D'>创建自己的工作空间？</span>
            <span
              className='c-#7B61FF cursor-pointer'
              onClick={immediateCreate}
            >
              立即创建
            </span>
          </p>
          <Select
            className='w-full mt-44px'
            value={selectedValue}
            onChange={value => setSelectedValue(value)}
            options={handleWorkSpace(location?.state?.workSpaceList)}
            defaultValue={
              handleWorkSpace(location?.state?.workSpaceList)?.[0]?.value
            }
          ></Select>
          <Button
            type='primary'
            className='mt-50px w-full'
            onClick={changeWorkspace}
          >
            确定
          </Button>
        </div>
      </div>
    </BgContainer>
  )
}
