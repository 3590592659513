import { type Edge, type Node } from 'reactflow'
import type { NodeType } from '../nodes/base'
import type { ReplaceNodeData } from '@/store'
import { pasteNodesFromLocalData } from './actions'
import { getOperationByNodeType } from './operation'
import type {
  AddConditionData,
  RemoveConditionData,
} from './operation/MultiBranchNodeOperation'
import { MultiBranchNodeOperation } from './operation/MultiBranchNodeOperation'

export class AIWorkFlow {
  private nodesMap: Record<string, Node> = {}

  constructor(
    private nodes: Node[],
    private edges: Edge[],
  ) {
    this.init()
  }

  init() {
    this.nodes.forEach(n => {
      if (!this.nodesMap[n.id]) {
        this.nodesMap[n.id] = n
      }
    })
  }

  update(nodes: Node[], edges: Edge[]) {
    this.nodes = nodes
    this.edges = edges
    this.nodesMap = {}
    this.init()
  }

  getNodes() {
    return this.nodes
  }

  getEdges() {
    return this.edges
  }

  insertNode(options: {
    type: NodeType
    data?: any
    target: string
    source: string
    sourceHandleId?: string | null
  }) {
    const { type } = options
    const operation = getOperationByNodeType(type)
    if (!operation) {
      console.error('找不到对应节点的操作类实例')
      return
    }
    let config
    try {
      config = operation.insert({
        ...options,
        nodes: this.nodes,
        edges: this.edges,
        nodesMap: this.nodesMap,
      })
    } catch (error) {
      // err
      console.error(error)
    }
    return config
  }

  replaceEmpty(id: string, node: ReplaceNodeData, sourceHandleId?: string) {
    const currentNode = this.nodesMap[id]
    if (!currentNode) {
      console.error('找不到替换的空节点')
      return
    }
    const operation = getOperationByNodeType(node.type)
    if (!operation) {
      console.error('找不到对应节点的操作类实例')
      return
    }
    let config
    try {
      config = operation.replaceEmpty({
        id,
        node,
        sourceHandleId,
        nodes: this.nodes,
        edges: this.edges,
        nodesMap: this.nodesMap,
      })
    } catch (error) {
      // err
    }
    return config
  }

  removeNodeById(id: string) {
    const removingNode = this.nodesMap[id]
    if (!removingNode) {
      console.error('找不到删除目标节点')
      return
    }
    const operation = getOperationByNodeType(removingNode.type as NodeType)
    if (!operation) {
      console.error('找不到对应节点的操作类实例')
      return
    }
    let config
    try {
      config = operation.remove(id, this.nodes, this.edges, this.nodesMap)
    } catch (error) {
      // err
    }
    return config
  }

  removeNodes(ids: string[]) {
    let currentData
    let i = 0
    try {
      while (i < ids.length) {
        currentData = this.removeNodeById(ids[i])
        if (currentData) {
          this.nodes = currentData.nodes
          this.edges = currentData.edges
          this.init()
        }
        i++
      }
    } catch (error) {
      // err
    }
    return currentData
  }

  getCorrectBranchNodeOperation(id: string) {
    const currentNode = this.nodesMap[id]
    if (!currentNode) {
      console.error('节点不存在')
      return
    }
    const operation = getOperationByNodeType(currentNode.type as NodeType)
    if (!operation) {
      console.error('找不到对应节点的操作类实例')
      return
    }
    if (!(operation instanceof MultiBranchNodeOperation)) {
      console.error('操作错误的节点类型')
      return
    }
    return operation
  }

  addBranch(data: AddConditionData) {
    const { id } = data
    const operation = this.getCorrectBranchNodeOperation(id)
    if (!operation) {
      return
    }
    let config
    try {
      config = operation.addBranch(data, this.nodes, this.edges, this.nodesMap)
    } catch (error) {
      // err
    }
    return config
  }

  removeBranch(data: RemoveConditionData) {
    const { id } = data
    const operation = this.getCorrectBranchNodeOperation(id)
    if (!operation) {
      return
    }
    let config
    try {
      config = operation.removeBranch(
        data,
        this.nodes,
        this.edges,
        this.nodesMap,
      )
    } catch (error) {
      // err
    }
    return config
  }

  copyNodes(originNodes: Node[]) {
    const result = []
    try {
      for (const n of originNodes) {
        const operation = getOperationByNodeType(n.type as NodeType)
        if (!operation) {
          console.error('节点复制失败')
          break
        }
        const cloneItem = operation.copy(
          n,
          this.nodes,
          this.edges,
          this.nodesMap,
        )
        result.push(cloneItem)
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }

  pasteNodesOnEdge(data: {
    source: string
    target: string
    sourceHandleId?: string
    nodes?: Node[]
    edges?: Edge[]
  }) {
    const result = {
      nodes: this.nodes,
      edges: this.edges,
    }
    try {
      const { source, target, sourceHandleId } = data
      const { resNodes, resEdges } = pasteNodesFromLocalData({
        source,
        target,
        sourceHandleId,
        nodes: this.nodes,
        edges: this.edges,
      })
      result.nodes = resNodes
      result.edges = resEdges
    } catch (error) {
      console.error(error)
    }
    return result
  }
}
