import { useMemoizedFn } from 'ahooks'
import { useEffect } from 'react'

export function useSubSignal(
  single: string,
  handle: (e: MessageEvent) => void,
) {
  const handleMessage = useMemoizedFn((e: MessageEvent) => {
    try {
      const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data
      if (data.single === single) {
        handle(e)
      }
    } catch {}
  })

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])
}

export function pubSignal(
  single: string,
  targetWindow?: Window | null,
  target = location.origin,
) {
  if (!targetWindow) return

  targetWindow.postMessage(JSON.stringify({ single }), {
    targetOrigin: target,
  })
}
