import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import { range } from 'lodash-es'
import type { ReactNode } from 'react'
import { memo, useMemo } from 'react'
import { WeekMap } from './const'

interface ItemProps {
  value: number
  selected: boolean
  onSelect: (value: number) => void
  children: ReactNode
}

function Item({ value, selected, onSelect, children }: ItemProps) {
  const handleSelect = useMemoizedFn(() => {
    onSelect(value)
  })

  return (
    <div
      className={classNames(
        'w-36px h-36px flex flex-center bg-bg_3 bg-op-6 rounded-8px cursor-pointer hover:border-primary hover:border-1px',
        {
          'bg-primary bg-op-100 text-#fff': selected,
        },
      )}
      onClick={handleSelect}
    >
      {children}
    </div>
  )
}

interface WeekSelectProps {
  value?: number[]
  onChange?: (value: number[]) => void
}

export const WeekSelect = memo((props: WeekSelectProps) => {
  const { value, onChange } = props

  const handleSelect = useMemoizedFn((item: number) => {
    if (value?.includes(item)) {
      onChange?.(value.filter(e => e !== item))
      return
    }
    onChange?.([...(value ?? []), item])
  })

  return (
    <div className='flex items-center gap-8px'>
      {range(1, 8).map(index => (
        <Item
          key={index}
          value={index % 7}
          selected={!!value?.includes(index % 7)}
          onSelect={handleSelect}
        >
          {WeekMap[index]}
        </Item>
      ))}
    </div>
  )
})

interface WeekSelectStringProps {
  value?: string
  onChange?: (value: string) => void
}

export const WeekSelectString = memo((props: WeekSelectStringProps) => {
  const { value, onChange } = props

  const arrayValue = useMemo(() => {
    if (!value) return []
    return value.split(',').map(e => Number(e))
  }, [value])

  const handleChange = useMemoizedFn((value: number[]) => {
    onChange?.(value.join(','))
  })

  return <WeekSelect value={arrayValue} onChange={handleChange} />
})
