import type { Edge, Node } from 'reactflow'
import type { AIWorkFlow } from '../AIWorkFlow'
import type { ReplaceNodeData } from '@/store'
import type { NodeType } from '@/features/nodes/base'

export interface FlowConfigData {
  nodes: Node[]
  edges: Edge[]
}

export interface InsertConfigData {
  nodes: Node[]
  edges: Edge[]
  newNode?: Node | null
}

export abstract class BaseNodeOperation {
  abstract remove(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    nodesMap: AIWorkFlow['nodesMap'],
  ): FlowConfigData
  abstract copy(
    originNode: Node,
    nodes: Node[],
    edges: Edge[],
    nodesMap: AIWorkFlow['nodesMap'],
  ): FlowConfigData
  abstract insert(options: {
    type: NodeType
    data?: any
    source: string
    target: string
    sourceHandleId?: string | null
    nodes: Node[]
    edges: Edge[]
    nodesMap: AIWorkFlow['nodesMap']
  }): InsertConfigData | Promise<InsertConfigData>
  abstract replaceEmpty(data: {
    id: string
    node: ReplaceNodeData
    sourceHandleId?: string
    nodes: Node[]
    edges: Edge[]
    nodesMap: AIWorkFlow['nodesMap']
  }): FlowConfigData
}
