import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useDebounceFn, useMemoizedFn, useWebSocket } from 'ahooks'
import { ReadyState } from 'ahooks/lib/useWebSocket'
import { useWorkspaceStore } from '@/store'
import { tokenStorage } from '@/utils/storage'
import { createSocketUrl } from '@/utils/socket'
import { wait } from '@/utils/wait'
import { tryParseToObject } from '@/utils/string'
import { useSubscribe } from './useSubscribe'

const HEALTH_TRIGGER_TIME = 30 * 1000
const HEALTH_RECONNECT_TIME = 30 * 1000

export function useSocketSubscribe(
  url: string,
  params: Record<string, string>,
) {
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const token = tokenStorage.get()
  const [subscribe, publish] = useSubscribe()
  const healthCheckRef = useRef(Date.now())

  const socketUrl = useMemo(() => {
    return createSocketUrl(url, {
      'Workspace-Id': workspaceId,
      Authorization: token,
      ...params,
    })
  }, [url, workspaceId, token, params])

  const { readyState, connect, disconnect, sendMessage } = useWebSocket(
    socketUrl,
    {
      manual: true,
      onOpen: () => {
        // 心跳检测
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        checkHealthDB()
      },
      onMessage: message => {
        const res = tryParseToObject(message.data)
        if (res.type === 'health') {
          healthCheckRef.current = Date.now()
        } else if (res.type) {
          publish(res)
        }

        // 心跳检测
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        checkHealthDB()
      },
    },
  )

  const checkHealth = useMemoizedFn(async () => {
    if (healthCheckRef.current === -1) return

    const now = Date.now()
    healthCheckRef.current = now
    sendMessage(JSON.stringify({ type: 'health' }))

    // 触发心跳后 30s 无响应，则关掉重连，主动关闭不重连
    await wait(HEALTH_RECONNECT_TIME)
    if (healthCheckRef.current !== -1 && now === healthCheckRef.current) {
      disconnect()
      connect()
    }
  })

  // 有消息后 30s 后触发心跳
  const { run: checkHealthDB } = useDebounceFn(checkHealth, {
    wait: HEALTH_TRIGGER_TIME,
  })

  const send = useMemoizedFn(
    async (message: string | ArrayBufferLike | Blob | ArrayBufferView) => {
      if ([ReadyState.Closed, ReadyState.Closing].includes(readyState)) {
        connect()
        await wait(300)
      }
      sendMessage(message)
    },
  )

  const close = useMemoizedFn(() => {
    healthCheckRef.current = -1
    disconnect()
  })

  useEffect(() => {
    return () => {
      close()
    }
  }, [])

  return {
    subscribe,
    send,
    open: connect,
    close,
  }
}

const SocketContext = createContext<ReturnType<typeof useSocketSubscribe>>({
  subscribe: () => () => {},
  send: async () => {},
  open: () => {},
  close: () => {},
})

interface SocketProviderProps {
  url: string
  params: Record<string, string>
  children: React.ReactNode
}

export const SocketProvider = memo((props: SocketProviderProps) => {
  const { url, params, children } = props

  const memoParams = useMemo(() => params, [params])

  const value = useSocketSubscribe(url, memoParams)

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  )
})

export function useSocket() {
  return useContext(SocketContext)
}
