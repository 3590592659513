import type { EditorState, Transaction } from 'prosemirror-state'

export function enter(
  state: EditorState,
  dispatch?: (tr: Transaction) => void,
) {
  if (!dispatch) return false

  const from = state.selection.from
  const to = state.selection.to

  const { parent: fromNode } = state.doc.resolve(from)
  const { parent: toNode } = state.doc.resolve(to)

  if (fromNode?.type.name === 'pe-input' && toNode?.type.name === 'pe-input') {
    return true
  }

  return false
}
