import type {
  BarProps,
  BarChartProps as VBarChartProps,
} from '@visactor/react-vchart'
import { BarChart as VBarChart, Bar, Tooltip } from '@visactor/react-vchart'
import React from 'react'
import EmptyImage from '@/assets/app/rocket2.png'
import type { ChartBoxProps } from './ChartBox'
import { ChartBox } from './ChartBox'
import { TOOLTIP_STYLE, TooltipContent } from './TooltipContent'

interface BarChartProps extends Omit<ChartBoxProps, 'children'> {
  values?: any[]
  xField: string
  yField: string
  showTooltip?: boolean
  yFieldLabel?: string
  onXFieldDetailClick?: (xFieldValue: string) => void
}

const axes: VBarChartProps['axes'] = [
  {
    type: 'band',
    orient: 'left',
    label: {
      style: {
        fontSize: 14,
        fontWeight: 400,
        fill: '#8d8d99',
        fillOpacity: 0.6,
      },
      formatMethod: value => {
        if (value.length > 4) {
          return `${value.slice(0, 4)}...`
        }
        return value
      },
    },
  },
  {
    type: 'linear',
    orient: 'bottom',
    visible: false,
    expand: {
      max: 0.1,
    },
  },
]

const getBar: (color?: string) => BarProps['bar'] = color => ({
  style: {
    fill: color,
  },
})

function InternalBarChart({
  className,
  title,
  values,
  xField,
  yField,
  color,
  showTooltip,
  modal,
  yFieldLabel,
  onXFieldDetailClick,
}: BarChartProps) {
  const bar = getBar(color)

  return (
    <ChartBox className={className} title={title} color={color} modal={modal}>
      {values?.length ? (
        <VBarChart
          className='[&>canvas]:!w-full'
          type='bar'
          data={[{ values }]}
          direction='horizontal'
          tooltip={{
            visible: showTooltip ?? false,
          }}
          label={{
            visible: true,
            overlap: {
              strategy: [{ type: 'position' }],
            },
            style: {
              type: 'text',
              fill: '#8d8d99',
              fillOpacity: 0.6,
            },
          }}
          axes={axes}
          padding={0}
          // width={560}
          height={228}
          autoFit
        >
          {showTooltip && (
            <Tooltip
              style={TOOLTIP_STYLE}
              enterable
              parentElement={
                document.querySelector('#main-container') as HTMLElement
              }
            >
              <TooltipContent
                xField={yField}
                yField={xField}
                yFieldLabel={yFieldLabel}
                detail={{ onClick: onXFieldDetailClick }}
              />
            </Tooltip>
          )}
          <Bar xField={xField} yField={yField} barWidth={14} bar={bar} />
        </VBarChart>
      ) : (
        <div className='w-full h-228px text-14px text-font_1 flex flex-col justify-center items-center'>
          <img className='w-70px object-contain' src={EmptyImage} />
          暂无数据
        </div>
      )}
    </ChartBox>
  )
}

export const BarChart = React.memo(InternalBarChart)
