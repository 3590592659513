import type { ReactNode, FC, Key, MutableRefObject } from 'react'
import { useState, useRef } from 'react'
import type { PopoverProps, TooltipProps } from 'antd'
import { Popover, Empty, Tooltip } from 'antd'

interface DropdownSelectProps extends PopoverProps {
  options?: Array<{
    label: string
    count?: number
    value: unknown
    description?: string
    tips?: string
  }>
  defaultValue?: string[]
  value?: unknown[]
  onChange?: (value: unknown[]) => void
  width?: number
  disabled?: boolean
  allEnable?: boolean
  placement?: TooltipProps['placement']
  onOpenChange?: (visible: boolean) => void
  triggerRender: (
    value: unknown,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => ReactNode
}

export const DropdownSelect: FC<DropdownSelectProps> = props => {
  const {
    defaultValue,
    triggerRender,
    options = [],
    onChange,
    value: propsValue,
    disabled,
    placement = 'bottomRight',

    ...remainingProps
  } = props
  const [__value, setValue] = useState<unknown[]>(defaultValue ?? [])
  const value = propsValue ?? __value
  const triggerRef = useRef<HTMLDivElement | null>(null)
  const popoverWidth = triggerRef.current?.offsetWidth || props.width || 320

  const onSelect = (_value?: unknown) => {
    let newValue = [...value]
    if (newValue.includes(_value)) {
      newValue = newValue.filter(item => item !== _value)
    } else {
      newValue.push(_value)
    }
    setValue(newValue)
    onChange?.(newValue)
  }
  return (
    <Popover
      arrow={false}
      trigger='click'
      overlayInnerStyle={{ padding: 0, borderRadius: 6, width: popoverWidth }}
      placement={placement}
      onOpenChange={vis => {
        props?.onOpenChange?.(vis)
      }}
      content={
        disabled ? null : (
          <>
            <div className='p-4 nodrag nopan nowheel'>
              {options.map(option => {
                return (
                  <Option
                    key={option.value as Key}
                    label={option.label}
                    value={option.value}
                    description={option.description}
                    count={option.count}
                    checked={value.includes(option.value)}
                    onClick={() => onSelect(option.value)}
                    tips={option.tips}
                  />
                )
              })}
              {!options.length && (
                <div className='flex items-center w-full'>
                  <Empty
                    className='flex-center flex-col w-full'
                    imageStyle={{ width: 100, height: 100 }}
                    description={null}
                  >
                    <span className='c-font_1 c-op-60 relative top--20px'>
                      暂无可选标签
                    </span>
                  </Empty>
                </div>
              )}
            </div>
          </>
        )
      }
      {...remainingProps}
    >
      {triggerRender(value, triggerRef)}
    </Popover>
  )
}

function Option(props: {
  label: string
  value: unknown
  description?: any
  count?: number
  checked: boolean
  indeterminate?: boolean
  tips?: string
  onClick: () => void
}) {
  const optionRef = useRef<HTMLDivElement>(null)
  return (
    <div ref={optionRef}>
      <Tooltip
        placement='right'
        title={props.tips}
        arrow={false}
        getPopupContainer={() => optionRef.current || document.body}
      >
        <div
          key={props.value as Key}
          className='px-12 rounded-6px cursor-pointer hover:bg-bg_3 hover:bg-op-8 flex items-center'
          title={props.label}
          onClick={props.onClick}
        >
          <div className='h-32px text-12px flex-1 flex items-center overflow-hidden'>
            <span className='flex-1 truncate leading-14px'>{props.label}</span>
            {!!props.description && (
              <p className='text-right max-w-70% truncate ml-48px text-font_1 text-opacity-60 font-normal'>
                <span className=''>{props.description}</span>
              </p>
            )}
            {!!props.count && (
              <span className='ml-12 c-font_1'>{props.count}文档</span>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  )
}
