import { tokenStorage } from '@/utils/storage'

const { VITE_DATASET_API_BASE_URL } = import.meta.env
const version = 'v1'

export function downloadTemplate(
  urlPath: string,
  name: string,
  headers?: Record<string, any>,
) {
  return fetch(
    `${VITE_DATASET_API_BASE_URL}/mebsuta/api/${version}/${urlPath}`,
    {
      headers: { ...headers, Authorization: tokenStorage.get() || '' },
    },
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${name}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    })
}
