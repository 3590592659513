import { fetchEventSource } from './event-source'

export const ErrorCode = {
  // sse 连接错误，包括客户端错误和服务端错误
  SSE_CLIENT_ERROR: 'SSE_CLIENT_ERROR',
  SSE_UNAUTHORIZED: 'SSE_UNAUTHORIZED',
  SSE_SERVER_ERROR: 'SSE_SERVER_ERROR',
  // sse stream 错误
  SSE_STREAM_DATA_ERROR: 'SSE_STREAM_DATA_ERROR',
  SSE_STREAM_SERVER_ERROR: 'SSE_STREAM_SERVER_ERROR',
  // sse 未知错误
  SSE_UNKNOWN_ERROR: 'SSE_UNKNOWN_ERROR',
  // sse 连接超时
  SSE_TIMEOUT: 'SSE_TIMEOUT',
  // SSE_TOKEN_LIMIT_ERROR: "SSE_TOKEN_LIMIT_ERROR",

  UNAUTHORIZED: 'UNAUTHORIZED',
  SERVER_ERROR: 'SERVER_ERROR',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
} as const

export type ErrorType = (typeof ErrorCode)[keyof typeof ErrorCode]

export class IError extends Error {
  readonly code: ErrorType

  constructor(code: ErrorType, message?: string) {
    super(message)
    this.code = code
  }
}

const TIMEOUT = 30000

const SSE_DEFAULT_ERROR_MESSAGE = '发生了未知错误，请重试或联系支持团队'

export interface EventSourceRequestHeaders {
  Authorization?: string
  'Application-Id'?: string
  'Workspace-Id'?: string
}

export interface EventSourceRequestCallbacks<
  T = {
    message_type: 'piecewise' | 'aggregate' | 'error' | undefined
    message: string
  },
> {
  /**
   * 连接建立成功
   */
  onOpen: VoidFunction
  /**
   * GPT 返回信息时的回调，会多次执行
   * @param content GPT 响应
   * @returns
   */
  onMessage: (data: T) => void
  /**
   * GPT 流结束时的回调
   */
  onFinish: VoidFunction
  /**
   * GPT 流出错时的回调，包括建立链接出错，数据解析出错
   * @param err
   * @returns
   */
  onError: (err?: IError) => void
}

export type AbortEventHandler = VoidFunction

export default function sse<
  TPayload,
  T = {
    duration_time?: number
    message_type: 'piecewise' | 'aggregate' | 'error' | undefined
    message: string
  },
>(
  url: string,
  payload: TPayload,
  headers: EventSourceRequestHeaders,
  { onOpen, onMessage, onFinish, onError }: EventSourceRequestCallbacks<T>,
): AbortEventHandler {
  const abort = new AbortController()

  const dispose = window.setTimeout(() => {
    abort.abort()
  }, TIMEOUT)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const body = JSON.stringify(((payload.stream = true), payload))
  const baseurl =
    import.meta.env.MODE === 'development'
      ? '/api'
      : import.meta.env.VITE_AI_API_BASE_URL
  fetchEventSource(baseurl + url, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream,application/json',
      ...headers,
    },
    signal: abort.signal,
    openWhenHidden: true,
    onopen: async response => {
      window.clearTimeout(dispose)
      switch (true) {
        case response.status >= 200 && response.status < 300: {
          const contentType = response.headers.get('Content-Type')
          if (contentType === 'application/json') {
            const json = (await response.json()) as {
              message: string
              code: number
            }
            if (json.code !== 200) {
              throw new IError(
                json.code >= 500
                  ? ErrorCode.SSE_SERVER_ERROR
                  : ErrorCode.SSE_CLIENT_ERROR,
                json.message,
              )
            }
          }
          onOpen()
          return
        }
        case response.status === 401:
          // token 失效
          throw new IError(
            ErrorCode.SSE_UNAUTHORIZED,
            '您的登录状态已过期，请重新登录',
          )
        default: {
          // 默认 400 是 token 超限
          // 默认 404 是没有使用权限
          const json = (await response.json()) as { message: string }
          const code =
            response.status >= 500
              ? ErrorCode.SSE_SERVER_ERROR
              : ErrorCode.SSE_CLIENT_ERROR

          throw new IError(code, json.message || SSE_DEFAULT_ERROR_MESSAGE)
        }
      }
    },
    onmessage: (msg: { data: string }) => {
      if (!msg.data) {
        // 目前不清楚为啥存在空的数据
        return
      }
      // 后端返回都是jsonstr
      const newData = JSON.parse(msg.data)
      onMessage(newData)
    },
    onclose: () => {
      console.log('====== sse close')
      onFinish()
    },
    onerror: _ => {
      console.log('====== _', _)
      // onerror 的时候不会触发 onclose
      const error =
        _ instanceof IError
          ? _
          : new IError(ErrorCode.SSE_UNKNOWN_ERROR, SSE_DEFAULT_ERROR_MESSAGE)

      onError(error)

      throw new Error('stop retry')
    },
  })

  const abortHandler: AbortEventHandler = () => {
    if (abort.signal.aborted) {
      return
    }
    abort.abort()
  }

  return abortHandler
}
