export const REQUEST_TIMEOUT = 60 * 1000
export const FLOW_EXECUTE_TIMEOUT = 300 * 1000

export enum API_STATUS_CODES {
  REQUEST_NOT_AUTH = 401,
  REQUEST_ERROR = 400,
  APPLICATION_NOT_AUTH = 403,
  RESOURCE_NOT_FOUND = 404,
  SERVER_ERROR = 502,
  SERVER_ERROR_2 = 500,
  SERVER_UNAVAILABLE = 503,
}

export enum KEYU_API_BUSSINESS_CODE {
  POINTS_NOT_ENOUGH = 93003,
}

export enum API_CODE_CODE {
  COMBO_NOT_ENOUGH = 60001, // 套餐余额不足
  COMBO_NOT_GPT4_TIME_ENOUGH = 60002, // GPT4次数不足
  COMBO_NOT_QWENPLUS_TIME_ENOUGH = 60003, // qwen plus次数不足

  /**
   * 工作空间成员数量已达上限
   */
  WORKSPACE_MEMBER_LIMIT_REACHED = 99019,
  /**
   * 此用户已存在，请勿重复邀请
   */
  USER_ALREADY_EXISTS = 91007,
}

export const not_enough_llm = {
  [API_CODE_CODE.COMBO_NOT_GPT4_TIME_ENOUGH]: 'GPT-4',
  [API_CODE_CODE.COMBO_NOT_QWENPLUS_TIME_ENOUGH]: 'qwen-plus',
}

export const NO_AUTH_API_LIST = ['/user-center/login']

export const LOGIN_AUTHORIZATION = 'Basic YWlfZmxvdzphaV9mbG93X3Bhc3M='

export const NO_APPLICATION_ID_API_LIST: RegExp[] = [
  /\/v1\/flow\/is_bot/,
  /\/v1\/agent\/auth_app/,
  /\/v1\/app\_monitor/,
]
