import type { KeyboardEvent } from 'react'
import { useMemo, useState } from 'react'
import { message } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import Keywords from '@/pages/datastores/components/Keywords.tsx'
import { Input } from '@/components'

interface KeywordsEditorProps {
  className?: string
  defaultValue?: string[]
  onChange?: (value: string[]) => void
  highlightKeywords?: string[]
  readOnly?: boolean
  showHeader?: boolean
  value?: string[]
  maxKeywordsCount?: number
  maxValueLength?: number
  layout?: 'vertical' | 'horizontal'
  placeholder?: string
}

const MAX_KEYWORDS_COUNT = 20
const MAX_VALUE_LENGTH = 10

const Wrapper = styled.div``

export default function KeywordsEditor(props: KeywordsEditorProps) {
  const {
    className,
    defaultValue,
    highlightKeywords,
    onChange,
    readOnly = false,
    showHeader = true,
    value: controlValue,
    maxKeywordsCount = MAX_KEYWORDS_COUNT,
    maxValueLength = MAX_VALUE_LENGTH,
    layout = 'vertical',
    placeholder = '输入关键词，使用回车、逗号填加',
  } = props
  const [localValue, setValue] = useState<string[]>(defaultValue ?? [])
  const [inputValue, setInputValue] = useState('')

  const onSetValue = (val: string[]) => {
    if (controlValue) {
      onChange?.(val)
    } else {
      setValue(val)
      onChange?.(val)
    }
  }

  const value = useMemo(() => {
    return controlValue || localValue
  }, [localValue, controlValue])

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ',' || e.key === 'Enter') {
      e.preventDefault()
      if (value.includes(inputValue)) {
        message.warning('标签已存在')
      }
      if (
        !value.includes(inputValue) &&
        inputValue.trim() &&
        value.length < maxKeywordsCount &&
        inputValue?.trim()
      ) {
        const newValue = [...value, inputValue]
        onSetValue(newValue)
      }
      setInputValue('')
    }
    if (e.key === 'Backspace' && inputValue === '' && value.length > 0) {
      const newValue = value.slice(0, value.length - 1)
      onSetValue(newValue)
    }
  }

  const onBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    e.preventDefault()
    if (
      !value.includes(inputValue) &&
      inputValue.trim() &&
      value.length < maxKeywordsCount &&
      inputValue?.trim()
    ) {
      const newValue = [...value, inputValue]
      onSetValue(newValue)
    }
    setInputValue('')
  }

  const onValueRemove = (valueStr: string) => {
    const newValue = value.filter(item => item !== valueStr)
    setValue(newValue)
    onChange?.(newValue)
  }

  const moreThanMaxSize = useMemo(() => {
    return value.length > maxKeywordsCount
  }, [value, maxKeywordsCount])
  return (
    <Wrapper
      className={classNames(className, {
        'flex flex-col': layout === 'vertical',
        'flex items-start gap-8px': layout === 'horizontal',
      })}
    >
      {showHeader && (
        <span
          className={classNames(
            'font-400 c-font_1 line-height-26px text-12px h-26px box-border text-nowrap',
            { 'c-#FE9700': moreThanMaxSize },
          )}
        >
          关键词({value.length}/{maxKeywordsCount})
        </span>
      )}
      <div
        className={classNames('flex items-center gap-8 flex-wrap', {
          ' mt-8': layout === 'vertical',
        })}
      >
        <Keywords
          value={value}
          deleteEnable={!readOnly}
          highlightValue={highlightKeywords}
          onValueRemove={onValueRemove}
          className={moreThanMaxSize ? '!b-1 !b-#FE9700' : ''}
        />
        {value.length < maxKeywordsCount && !readOnly && (
          <Input
            value={inputValue}
            onChange={e => {
              const value = (e.target as HTMLInputElement).value
              setInputValue(value)
            }}
            onBlur={onBlur}
            maxLength={maxValueLength}
            variant='borderless'
            status='warning'
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            className='px-0! placeholder:c-font_1 border-none! text-14px/26px !h-26px c-font! placeholder:c-op-60 bg-transparent! block rd-0! outline-none! flex-1 !min-w-220px box-border'
          />
        )}
      </div>
    </Wrapper>
  )
}
