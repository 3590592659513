import { Spin } from 'antd'
import { Modal } from '@/components'
import { useSelectAgent } from '../hooks/usePluginsPageConfig'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { AddPluginButton } from '../components/AddPluginButton'
import { SelectAgentContent } from '../components/SelectAgentContent'
import { EmptyAgentList } from '../components/EmptyAgentList'

function SelectAgentModal(props: {
  open: boolean
  handleCancel: () => void
  functionId: string | undefined
}) {
  const { open, handleCancel, functionId } = props
  const {
    loading,
    agentList,
    handleCreatePlugin,
    handleAddPlugin,
    addPluginSuccess,
  } = useSelectAgent(functionId)

  const renderContent = () => {
    if (loading)
      return (
        <div className='w-[100%] mt-[250px] flex items-center justify-center'>
          <Spin />
        </div>
      )

    if (agentList.length > 0)
      return (
        <>
          <LimitedAccess limitedType='agent'>
            <AddPluginButton onClick={handleCreatePlugin} />
          </LimitedAccess>
          <SelectAgentContent
            agentList={agentList}
            handleAddPlugin={handleAddPlugin}
            addPluginSuccess={addPluginSuccess}
          />
        </>
      )
    return <EmptyAgentList handleCreatePlugin={handleCreatePlugin} />
  }

  return (
    <Modal
      title='选择Agent'
      open={open}
      onCancel={handleCancel}
      footer={null}
      width={1120}
      bodyStyle={{
        minHeight: 671,
        maxHeight: '70vh',
        overflow: 'auto',
        padding: '24px',
      }}
    >
      {renderContent()}
    </Modal>
  )
}

export default SelectAgentModal
