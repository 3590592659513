import type { ColumnsType } from 'antd/es/table'
import { useRequest } from 'ahooks'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { IconFont, Table, usePageModal } from '@/components'
import { getAssociatedApplication } from '@/apis/datastore/api.ts'
import { useWorkspaceStore } from '@/store'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

const StyledTable = styled(Table)`
  .ant-table-column-sorters {
    justify-content: flex-start !important;
  }
  .ant-table-column-title {
    flex: initial;
  }
`
function AssociatedApp() {
  const { id } = useParams()
  const { data, loading } = useRequest(getAssociatedApplication, {
    defaultParams: [Number(id)],
  })
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const pageModal = usePageModal()
  const list = data || []
  const { scrollRef } = useScrollBar()

  const columns: ColumnsType<object> = [
    {
      title: '应用名称',
      key: 'flow_name',
      dataIndex: 'flow_name',
    },
    {
      title: '更新时间',
      key: 'updated_at',
      dataIndex: 'updated_at',
      width: 160,
      sortIcon: ({ sortOrder }) => {
        return (
          <div className='ml-8'>
            <IconFont
              name='asc'
              className={classNames('text-6px text-font_1', {
                'text-opacity-40': sortOrder !== 'ascend',
              })}
            />
            <IconFont
              name='desc'
              className={classNames('text-6px text-font_1', {
                'text-opacity-40': sortOrder !== 'descend',
              })}
            />
          </div>
        )
      },
      sorter: (a: any, b: any) =>
        dayjs(a.update_time).unix() - dayjs(b.update_time).unix(),
      render: (updateTime: string) =>
        dayjs(updateTime).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: record => {
        return (
          <a
            className='text-14px text-primary cursor-pointer'
            onClick={() => {
              const url =
                record.application_type === 'AGENT'
                  ? `/agent/${record.app_id}`
                  : `/application/${currentWorkspaceId}/${record.app_id}/flow/${record.flow_id}`
              pageModal.show({ url })
            }}
            target='blank'
          >
            打开应用
          </a>
        )
      },
    },
  ]
  return (
    <div className='rounded-8px bg-white flex-1 flex flex-col overflow-hidden'>
      <div ref={scrollRef} className='flex-1 overflow-y-auto relative'>
        <StyledTable
          rowKey='id'
          loading={loading}
          columns={columns}
          dataSource={list}
          sticky
          pagination={false}
        />
      </div>
      {/* <div className="p-16 bg-#fff w-full flex justify-end border-t border-solid border-font_1 border-op-12">
        <Pagination
          total={list.length}
          showSizeChanger
          showQuickJumper
          showTotal={(total: number) => `共计 ${total} 条数据`}
        />
      </div> */}
    </div>
  )
}

export default AssociatedApp
