import type { VersionConfig } from '@/apis/version'
import { Version, VersionShowText, VersionSuitableText } from '../const'

export interface litmitedTitleConfig {
  title: string
  desc: string
  price?: string
  discount?: string
  billingCycle?: string
  showTextInPrice?: string
  version: Version
}

export const limitedTitleConfig: litmitedTitleConfig[] = [
  {
    title: VersionShowText[Version.FREE],
    desc: VersionSuitableText[Version.FREE],
    price: '0',
    billingCycle: '14天',
    version: Version.FREE,
  },
  {
    title: VersionShowText[Version.TEAM],
    desc: VersionSuitableText[Version.TEAM],
    price: '12999',
    billingCycle: '年',
    discount: '15588',
    version: Version.TEAM,
  },
  {
    title: VersionShowText[Version.ENTERPRISE],
    desc: VersionSuitableText[Version.ENTERPRISE],
    version: Version.ENTERPRISE,
    showTextInPrice: '详情请咨询',
  },
]

export interface limitedVersionConfig {
  title: string
  Free: any
  Team: any
  Enterprise: any
  tooltip?: string
  backendKey?: string
  type?: string
  limit?: number
}

export const commonConfig: limitedVersionConfig[] = [
  {
    title: 'Yeah积分',
    tooltip: '平台所有应用运行模型时消耗积分，不同模型消耗不一致',
    [Version.FREE]: '1000积分',
    [Version.TEAM]: '50000积分',
    [Version.ENTERPRISE]: '按需',
    type: '积分',
  },
  {
    title: '可用模型',
    tooltip: '20多个国内外知名大模型',
    Free: '全部',
    Team: '全部',
    Enterprise: '全部',
  },
  {
    title: '可邀请用户数',
    tooltip: '同时可协作的角色成员数量',
    Free: '5 个',
    Team: '20 个',
    Enterprise: '按需',
  },
  {
    title: '应用数量',
    tooltip: '同时拥有正式发布的 Agent 和工作流 数量',
    Free: '30 个',
    Team: '60 个',
    Enterprise: '无限',
  },
  {
    title: '知识库容量',
    tooltip: '矢量数据库文件存储大小',
    Free: '50 MB',
    Team: '500 MB',
    Enterprise: '无限',
  },
  {
    title: '日志储存',
    Free: '-',
    Team: '近365天',
    Enterprise: '近3年',
  },
  {
    title: 'Agent精品模板库',
    Free: true,
    Team: true,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '通用插件库',
    tooltip: '平台内置插件工具，且不断更新',
    Free: true,
    Team: true,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '数据库',
    tooltip: '关系型数据库，存储结构化数据',
    Free: true,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '线上文档抓取',
    tooltip: '通过扩展插件一键抓取网页或线上文档资料数据',
    Free: true,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
]

export const advancedConfig: limitedVersionConfig[] = [
  {
    title: '网页链接使用',
    limit: 50,
    Free: true,
    Team: true,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '接入外部平台',
    tooltip:
      'Agent可接入微信客服、微信公众号、企业微信、钉钉机器人、飞书机器人',
    limit: 50,
    Free: true,
    Team: true,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '专用 API',
    limit: 50,
    Free: true,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '个性化 SDK',
    limit: 50,
    Free: true,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
]

export const supportConfig: limitedVersionConfig[] = [
  {
    title: '社区讨论社群',
    Free: true,
    Team: true,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '企业服务支持群',
    Free: false,
    Team: true,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '培训服务',
    Free: false,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: 'AI 训练调优服务',
    Free: false,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '接入企业自有模型',
    Free: false,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
  {
    title: '私有化部署',
    Free: false,
    Team: false,
    Enterprise: true,
    type: 'boolean',
  },
]

export function convertConfig(
  configList: limitedVersionConfig[],
  versionConfigList: VersionConfig[],
) {
  const [freeConfig, teamConfig, enterpriseConfig] = versionConfigList
  return configList.map(config => {
    return {
      ...config,
      [Version.FREE]:
        freeConfig?.[config.backendKey as keyof VersionConfig] ||
        config[Version.FREE],
      [Version.TEAM]:
        teamConfig?.[config.backendKey as keyof VersionConfig] ||
        config[Version.TEAM],
      [Version.ENTERPRISE]:
        enterpriseConfig?.[config.backendKey as keyof VersionConfig] ||
        config[Version.ENTERPRISE],
    }
  })
}
