import emptyImg from '@/assets/dataset/empty@2x.png'

export function EmptyTemplates() {
  return (
    <div className='flex-col flex-center min-h-240px'>
      <img className='w-110px h-110px' src={emptyImg} alt='' />
      <p className='text-#8d8d99'>没找到模板</p>
    </div>
  )
}
