import { Tooltip } from 'antd'
import { useMemo } from 'react'
import type { ReactNode, FC } from 'react'
import type {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import type {
  FieldControl,
  FieldItemComponentsMap,
  FormSchema,
  MetaState,
} from '@bty/ui-components'
import { noop } from 'lodash-es'
import { Input, TextArea, NodeForm, Select } from '@/components'
import { type FieldItem, FieldTypes } from '../constant'
import { AddButton } from '@/features/nodes/components'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils.ts'
import { FieldFormItemWithDraggableAndAction, FormItem } from './FieldFormItem'

interface FieldsConfigProps {
  value: FieldItem[]
  disabled: boolean
  onFieldAdd: () => void
  onFieldEdit: (index: number) => void
  onFieldDelete: (index: number) => void
  onSort: (fields: FieldItem[]) => void
}

// 车祸现场,疑似循环引用
// import { NodeType } from '@/features/nodes/base'
// const START_NODE_CLASS = `.react-flow__node-${NodeType.START}`
const START_NODE_CLASS = '.react-flow__node-START'

function getTooltipContainer() {
  return document.querySelector(START_NODE_CLASS) as HTMLElement
}

type RenderFunc = (schema: FormSchema) => ReactNode

function getComponentMap(
  onDelete: (index: number) => void,
  onEdit: (index: number) => void,
): FieldItemComponentsMap {
  const CompWithFormItem = (children: RenderFunc) => {
    return (schema: FormSchema, control: FieldControl, meta: MetaState) => {
      return (
        <FieldFormItemWithDraggableAndAction
          onDelete={onDelete}
          onEdit={onEdit}
          schema={schema}
          control={control}
          meta={meta}
        >
          {typeof children === 'function' ? children(schema) : children}
        </FieldFormItemWithDraggableAndAction>
      )
    }
  }

  return {
    [FieldTypes.Input]: CompWithFormItem(schame => (
      <Tooltip
        title='请在「运行调试」中输入'
        arrow={false}
        getPopupContainer={getTooltipContainer}
      >
        <div>
          <Input
            size='small'
            disabled
            placeholder={schame.placeholder}
            className='px-12'
          />
        </div>
      </Tooltip>
    )),
    [FieldTypes.Select]: CompWithFormItem(schema => (
      <Tooltip
        title='请在「运行调试」中输入'
        arrow={false}
        getPopupContainer={getTooltipContainer}
      >
        <div>
          <Select
            size='small'
            disabled
            className='nodrag w-full'
            placeholder={schema.placeholder}
            getPopupContainer={() =>
              document.getElementById('keyu-start-node-field-config') as any
            }
            options={schema.options}
            popupClassName='pointer-events-none'
          />
        </div>
      </Tooltip>
    )),
    [FieldTypes.MultiSelect]: CompWithFormItem(schema => (
      <Tooltip
        title='请在「运行调试」中输入'
        arrow={false}
        getPopupContainer={getTooltipContainer}
      >
        <div>
          <Select
            disabled
            size='small'
            showSearch={false}
            placeholder={schema.placeholder}
            className='nodrag w-full'
            getPopupContainer={() =>
              document.getElementById('keyu-start-node-field-config') as any
            }
            options={schema.options}
            popupClassName='pointer-events-none'
          />
        </div>
      </Tooltip>
    )),
    [FieldTypes.Textarea]: CompWithFormItem(schema => (
      <Tooltip
        title='请在「运行调试」中输入'
        arrow={false}
        getPopupContainer={getTooltipContainer}
      >
        <div>
          <TextArea
            rows={3}
            disabled
            size='small'
            placeholder={schema.placeholder}
            className='important:resize-none py-8 px-12'
          />
        </div>
      </Tooltip>
    )),
    [FieldTypes.Json]: CompWithFormItem(schema => (
      <Tooltip
        title='请在「运行调试」中输入'
        arrow={false}
        getPopupContainer={getTooltipContainer}
      >
        <div>
          <TextArea
            rows={3}
            disabled
            size='small'
            placeholder={schema.placeholder}
            className='important:resize-none py-8 px-12'
          />
        </div>
      </Tooltip>
    )),
    [FieldTypes.File]: CompWithFormItem(schema => (
      <Tooltip
        title='请在「运行调试」中输入'
        arrow={false}
        getPopupContainer={getTooltipContainer}
      >
        <div>
          <Input
            size='small'
            placeholder={schema.placeholder}
            disabled
            className='px-12'
          />
        </div>
      </Tooltip>
    )),
  }
}

export const FieldsConfig: FC<FieldsConfigProps> = props => {
  const { onFieldAdd, onFieldEdit, onFieldDelete, onSort, value, disabled } =
    props
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const { source, destination } = result
    const newValue = Array.from(value)
    const [reorderedItem] = newValue.splice(source.index, 1)
    newValue.splice(destination.index, 0, reorderedItem)
    onSort(newValue)
  }

  const componentMap = useMemo(
    () => getComponentMap(onFieldDelete, onFieldEdit),
    [onFieldDelete, onFieldEdit],
  )

  const renderDropClone = (
    provided: DraggableProvided,
    _: DraggableStateSnapshot,
    rubric: DraggableRubric,
  ) => {
    const item = value[rubric.source.index]
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <FormItem
          label={item.label}
          required={item.required}
          index={rubric.source.index}
          variableName={item.variableName}
          onEdit={noop}
          onDelete={noop}
        >
          {componentMap[item.type](
            { ...item, name: item.variableName },
            {
              value: '',
              onChange: noop,
            },
            {
              index: 0,
              touched: false,
              validating: false,
              errors: [],
              warnings: [],
              name: [],
              validated: false,
            },
          )}
        </FormItem>
      </div>
    )
  }

  const renderDropBox = (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot,
  ) => {
    return (
      <div
        style={{
          marginBottom: snapshot.isDraggingOver ? 16 : 0,
        }}
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        <NodeForm
          showEmpty={false}
          className='pl-16'
          fieldComponentsMap={componentMap}
          schema={transformConfig2SchemaConfig(value)}
        />
        {provided.placeholder}
      </div>
    )
  }

  return (
    <div className='p-16  nodrag nopan nopan' id='keyu-start-node-field-config'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          renderClone={renderDropClone}
          droppableId='startNodeFormConfigDroppable'
        >
          {renderDropBox}
        </Droppable>
      </DragDropContext>

      <AddButton onClick={onFieldAdd} disabled={disabled}>
        添加字段
      </AddButton>
    </div>
  )
}
