import { useRequest } from 'ahooks'
import { useWorkspaceStore } from '@/store'
import type { ApplicationBodyType } from '@/apis/application.ts'
import { createApplication } from '@/apis/application.ts'
import { getName } from '@/pages/application/utils.tsx'
import { convertRenderNodesToFlowNodes } from '@/pages/flowPage/util.ts'
import { initNodesAndEdgesForFlow } from '@/features/flow/utils'
import { iconList, iconColors } from '@/constants/icon.ts'

export function useCreateFlow(
  list: ApplicationBodyType[],
  onCreateSuccess?: (data: ApplicationBodyType) => void,
) {
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { runAsync, loading } = useRequest(
    e => createApplication(e, currentWorkspaceId),
    {
      manual: true,
      onSuccess: e => {
        onCreateSuccess?.(e)
      },
    },
  )

  const create = (groupId?: string) => {
    const { nodes, edges } = initNodesAndEdgesForFlow()

    return runAsync({
      icon: iconList[Math.min(Math.floor(Math.random() * 100), 39)],
      color: iconColors[Math.min(Math.floor(Math.random() * 10), 5)],
      description: '暂无描述',
      name: getName(list || []),
      applicationType: 'AI',
      appGroupId: groupId,
      config: JSON.stringify({
        nodes: convertRenderNodesToFlowNodes(nodes),
        edges,
      }),
    })
  }

  return {
    create,
    loading,
  }
}
