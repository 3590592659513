import type { ForwardedRef } from 'react'
import { forwardRef, useMemo, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'

import { isNil } from 'lodash-es'
import { useMemoizedFn } from 'ahooks'
import { MAX_SPLIT_SIZE } from '@/features/datastore/constant'
import type { DatastoreBaseEditorRef } from '../DatastoreBaseEditor'
import DatastoreBaseEditor, {
  createDataBaseEditorFeatures,
  DatastoreBaseEditorFeatures,
} from '../DatastoreBaseEditor'
import { IconFont } from '@/components'
import { CustomizeToken } from './CustomizeToken'

const DEFAULT_MAX_TOKEN = MAX_SPLIT_SIZE

export function RequireIcon() {
  return (
    <span className='color-error ml-5 text-[18px] line-height-14px position-relative top-[3px]'>
      *
    </span>
  )
}

interface InternalEditorProps {
  value?: any
  file_id: number
  onChange?: (value: string) => void
  onBlur?: () => void
  className?: string
  customStyle?: Record<string, any>
  readOnly?: boolean
  noBorder?: boolean
  setOptions?: {
    count?: boolean
    autoSize?: { minRows: number; maxRows: number }
    maxCount?: number
    customizeToken?: boolean
    uploadOptions?: {
      uploadDir: string
    }
  }
}

const InternalEditor = forwardRef(
  (
    {
      value,
      file_id,
      onChange,
      onBlur,
      className,
      readOnly,
      noBorder,
      setOptions,
      customStyle = {},
    }: InternalEditorProps,
    _ref: ForwardedRef<DatastoreItemEditorRef>,
  ) => {
    const editorRef =
      useRef<
        DatastoreBaseEditorRef<
          [
            DatastoreBaseEditorFeatures.Upload,
            DatastoreBaseEditorFeatures.Highlight,
          ]
        >
      >(null)

    const [focus, setFocus] = useState(false)

    const maxCount = useMemo(
      () => setOptions?.maxCount || DEFAULT_MAX_TOKEN,
      [setOptions?.maxCount],
    )

    const moreThanMaxSize = useMemo(() => {
      if (setOptions?.customizeToken) {
        return false
      } else {
        return value?.length > maxCount
      }
    }, [setOptions?.customizeToken, maxCount, value, readOnly])

    const handleUpload = () => {
      editorRef?.current?.triggerUpload()
    }

    const handleClickFocus = useMemoizedFn(() => {
      editorRef.current?.focus()
    })

    const wrapperStyle = useMemo(() => {
      const style = { ...customStyle }
      if (!isNil(setOptions?.autoSize?.minRows)) {
        style.minHeight = setOptions?.autoSize?.minRows * 22
      }
      if (!isNil(setOptions?.autoSize?.maxRows)) {
        style.maxHeight = setOptions?.autoSize?.maxRows * 22
      }
      return style
    }, [setOptions?.autoSize?.maxRows, setOptions?.autoSize?.minRows])

    const features = useMemo(() => {
      return createDataBaseEditorFeatures([
        DatastoreBaseEditorFeatures.Upload,
        DatastoreBaseEditorFeatures.Highlight,
      ])
    }, [])

    const uploadOpt = useMemo(() => {
      return {
        uploadDir: setOptions?.uploadOptions?.uploadDir!,
        file_id,
      }
    }, [setOptions?.uploadOptions?.uploadDir!, file_id])

    return (
      <div
        className='relative'
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(false)
          onBlur?.()
        }}
      >
        <div
          style={wrapperStyle}
          className={classNames(
            className,
            'w-full overflow-auto b-1 rounded-4px py-10px px-20px',
            {
              '!b-0 !hover:b-0 !shadow-none': false,
              '!bg-[rgba(98,105,153,0.06)]': !readOnly,
              '!pl-0px': readOnly,
              '!b-[rgba(225,225,229,0.8)]': !noBorder && !moreThanMaxSize,
              '!b-error': moreThanMaxSize,
              '!focus:b-error': moreThanMaxSize,
              '!b-primary': !moreThanMaxSize && focus,
              '!hover:b-primary': !readOnly && !moreThanMaxSize,
              '!hover:b-error': !readOnly && moreThanMaxSize,
              '!pb-32px': !!setOptions?.uploadOptions,
            },
          )}
          onClick={handleClickFocus}
        >
          <DatastoreBaseEditor
            enable
            features={features}
            ref={editorRef}
            value={value}
            onChange={val => onChange?.(val)}
            uploadOptions={uploadOpt}
            placeholder='输入内容'
          />
        </div>
        {setOptions?.uploadOptions && (
          <div
            className={classNames(
              'absolute text-20px w-24px h-24px left-8px z-100 cursor-pointer hover:bg-#62696D hover:bg-op-8 rounded-4px flex items-center justify-center',
              {
                'bottom-[8px]': setOptions?.count && !setOptions.customizeToken,
                'bottom-[28px]': setOptions?.count && setOptions.customizeToken,
              },
            )}
          >
            <Tooltip title='上传图片/视频'>
              <IconFont
                name='shangzhuantupian'
                className='c-#B9B9CD'
                onClick={handleUpload}
              />
            </Tooltip>
          </div>
        )}
        {setOptions?.count && (
          <>
            {setOptions.customizeToken ? (
              <CustomizeToken
                value={value}
                file_id={file_id}
                maxCount={maxCount}
                ref={_ref}
              />
            ) : (
              <div
                className={classNames(
                  'absolute right-12px bottom-[12px] flex items-center info-box z-1',
                  { '!op-0': readOnly },
                )}
              >
                <span
                  className={classNames('c-font_1 text-12px font-400', {
                    'c-error': value?.length > maxCount,
                  })}
                >
                  <span
                    className={classNames({
                      'c-#8D8D99': value?.length <= maxCount,
                      '!c-error': value?.length > maxCount,
                    })}
                  >
                    {value?.length}
                  </span>
                  {!readOnly && <span>/ {maxCount}</span>}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    )
  },
)

export interface DatastoreItemEditorRef {
  validate: () => {
    success: boolean
    msg: string
  }
}
export const DatastoreItemEditor = forwardRef(
  (
    {
      className,
      value,
      onChange,
      file_id,
      setOptions,
      readOnly,
      noBorder,
      customStyle,
    }: InternalEditorProps,
    _ref: ForwardedRef<DatastoreItemEditorRef>,
  ) => (
    <InternalEditor
      className={className}
      value={value}
      onChange={onChange}
      setOptions={setOptions}
      file_id={file_id}
      readOnly={readOnly}
      noBorder={noBorder}
      customStyle={customStyle}
      ref={_ref}
    />
  ),
)
