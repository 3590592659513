import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Switch } from 'antd'
import { concat } from 'lodash-es'
import { Button, CheckboxGroup } from '@/components'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { FLOW_DISPLAYNAME } from '@/constants/common'

interface CheckboxListProps {
  defaultCheckedList: { agentCheckList: string[]; FlowCheckList: string[] }
  autoSave?: boolean
  options: { value: string; label: string; applicationType: string }[]
  onListChange?: (value: string[], checkAll?: boolean) => void
  onClose?: () => void
}

export function AppCheckboxList(props: CheckboxListProps) {
  const {
    defaultCheckedList = [],
    autoSave = true,
    options,
    onListChange,
    onClose,
  } = props
  const [checkAll, setCheckAll] = useState(false)
  const [checkedAgentList, setCheckedAgentList] = useState<string[]>(
    'agentCheckList' in defaultCheckedList
      ? defaultCheckedList.agentCheckList
      : [],
  )

  const [checkedFlowList, setCheckedFlowList] = useState<string[]>(
    'FlowCheckList' in defaultCheckedList
      ? defaultCheckedList.FlowCheckList
      : [],
  )

  const onCheckAllChange = (checked: boolean) => {
    setCheckedAgentList(
      checked
        ? options
            ?.filter(item => item.applicationType === 'AGENT')
            ?.map(item => item.value)
        : [],
    )
    setCheckedFlowList(
      checked
        ? options
            ?.filter(item => item.applicationType === 'AI')
            ?.map(item => item.value)
        : [],
    )
    setCheckAll(checked)
  }

  const onAgentChange = (list: string[]) => {
    setCheckedAgentList(list)
  }
  const onFlowChange = (list: string[]) => {
    setCheckedFlowList(list)
  }

  const onSave = () => {
    onListChange &&
      onListChange(concat(checkedAgentList, checkedFlowList ?? []), checkAll)
  }
  useEffect(() => {
    if (concat(checkedAgentList, checkedFlowList)?.length >= options.length) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }
  }, [checkedAgentList, checkedFlowList])

  const { scrollRef } = useScrollBar()

  return (
    <div className='relative'>
      <div className={classNames('overflow-y-hidden h-302px')}>
        <div
          className={classNames(
            'p-16 min-w-300px max-h-250px overflow-y-scroll p-b-0',
          )}
          ref={scrollRef}
        >
          <div className='text-12px/12px c-#8D8D99 mb-17px'>Agent</div>
          <CheckboxGroup
            value={checkedAgentList}
            options={options
              ?.filter(item => item.applicationType === 'AGENT')
              ?.map(item => ({
                ...item,
                style: { width: '100%', marginBottom: 16 },
              }))}
            style={{ flexDirection: 'column', width: '100%' }}
            onChange={onAgentChange}
          />
          <div className='text-12px/12px c-#8D8D99 mb-17px'>
            {FLOW_DISPLAYNAME}
          </div>
          <CheckboxGroup
            value={checkedFlowList}
            options={options
              ?.filter(item => item.applicationType === 'AI')
              ?.map(item => ({
                ...item,
                style: { width: '100%', marginBottom: 16 },
              }))}
            style={{ flexDirection: 'column', width: '100%' }}
            onChange={onFlowChange}
          />
        </div>
      </div>
      {!autoSave && (
        <div className='absolute bottom-0 left-0 w-100% h-52px flex-center-between px-10px b-t-1 b-t-#e1e1e5 bg-white rounded-b-8px'>
          <div>
            <Switch
              checked={checkAll}
              size='small'
              onChange={onCheckAllChange}
            />
            <span className='ml-8px'>授权全部应用</span>
          </div>
          <div>
            <Button size='small' onClick={onClose}>
              取消
            </Button>
            <Button
              size='small'
              className='ml-12'
              type='primary'
              onClick={onSave}
            >
              保存
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
