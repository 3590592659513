import { AppLogo, IconFont } from '@/components'
import { useMovableOptionStore } from '@/store'

interface FlowItemCardProps {
  id: string
  icon: string
  iconColor: string
  name: string
  description: string
  selected: boolean
  onClick: (id: string) => void
}

export function FlowItemCard(props: FlowItemCardProps) {
  const { id, icon, iconColor, name, description, selected, onClick } = props

  const { init } = useMovableOptionStore()

  return (
    <div
      className='mb-12px p-12px b-rd-8px b-1 b-#e1e1e5 b-op-60 hover:bg-#626999 hover:bg-op-8 cursor-pointer'
      onClick={e => {
        const { clientX, clientY } = e
        init({
          x: clientX,
          y: clientY,
        })
        onClick(id)
      }}
    >
      <div className='flex items-center mb-4px'>
        <AppLogo
          value={icon}
          type='emoji'
          className='mr-8px'
          color={iconColor}
          size={24}
          style={{ borderRadius: 4 }}
          imageClassName='b-rd-4px!'
          fillSize={16}
        />
        <div className='flex-1 font-500 text-#17171d'>{name}</div>
        <div className='flex-center w-24px h-24px b-rd-4px'>
          <IconFont
            name={selected ? 'jiediansixuan' : 'add'}
            className='text-16px text-#7861ff'
          />
        </div>
      </div>
      <div className='pl-32px line-height-18px text-12px text-#8D8D99'>
        {description}
      </div>
    </div>
  )
}
