import cn from 'classnames'
import { IconFont } from '@/components'
import type { NodeMeta } from '@/features/nodes/base'

interface NodeOptionProps {
  meta: NodeMeta
  onSelect: (meta: NodeMeta) => void
  extra?: React.ReactNode
  className?: string
}

export default function NodeOption(props: NodeOptionProps) {
  const { meta, onSelect, extra, className } = props
  const { typeName, description, icon, backgroundColor } = meta
  return (
    <div
      className={cn(
        'flex flex-1 h-54px m-b-4 p-8 b-rd-6px hover-bg-#626999 hover-bg-op-8',
        className,
      )}
      onClick={() => onSelect(meta)}
    >
      <div
        className={
          'm-r-8 w-24px h-24px flex flex-items-center flex-justify-center b-rd-4px'
        }
        style={{
          backgroundColor,
        }}
      >
        <IconFont name={icon!} className='text-16px' />
      </div>
      <div>
        <div className='m-b-4px line-height-18px font-500 c-#17171d'>
          {typeName}
        </div>
        <div className='line-height-16px text-12px c-font_1 font-400'>
          {description}
        </div>
      </div>
      {extra}
    </div>
  )
}
