import { useRequest } from 'ahooks'
import { Select, Tooltip } from 'antd'
import cn from 'classnames'
import type { AgentRuleConfig } from '@bty/chat-types'
import { IconFont, usePageModal } from '@/components'
import { fetchAgentSkillList } from '@/apis/agent'
import { DropdownControl } from './DropdownControl'

const menus = [
  {
    label: '关闭',
    key: 'CLOSE',
  },
  {
    label: '知识库',
    key: 'DATASET',
  },
  {
    label: '工作流',
    key: 'FLOW',
  },
] as const

type ForcedInvokeValue = AgentRuleConfig['enforce_execution']

interface ForcedInvokeControlProps {
  agent: {
    flowId: string
    versionId: string
    workspaceId: string
  }
  filterMenuOption?: (menu: (typeof menus)[number]['key']) => boolean
  value?: ForcedInvokeValue
  onChange?: (value: ForcedInvokeValue) => void
}

export function ForcedInvokeControl({
  agent,
  filterMenuOption,
  value,
  onChange,
}: ForcedInvokeControlProps) {
  const pageModal = usePageModal()

  const filteredMenu = filterMenuOption
    ? menus.filter(el => filterMenuOption(el.key))
    : menus

  const { data: skills, refreshAsync: reloadSkills } = useRequest(
    fetchAgentSkillList,
    {
      defaultParams: [agent.flowId, agent.versionId, agent.workspaceId],
    },
  )

  const flows = skills?.flows?.filter(
    el => el.agent_rel_flow_status !== 'DELETED' && el.is_enable,
  )

  const isFlow = value?.type === 'FLOW'

  return (
    <DropdownControl
      menu={filteredMenu}
      value={value?.type}
      label='强制调用'
      tooltip='每次对话，都强制调用知识库或一个工作流，一个 Agent 只能设置一个强制调用对象'
      onChange={type => {
        onChange?.({
          type,
          tool_id: type === 'FLOW' ? value?.tool_id : '',
        })
      }}
    >
      {isFlow && (
        <Select<string>
          className='w-full mb-16 !h-36px [&>.ant-select-selector]:not-hover:not-focus-within:!border-line [&>.ant-select-selector]:not-hover:not-focus-within:!border-op-80'
          value={value?.tool_id}
          placeholder='请指定要调用的工作流'
          suffixIcon={
            <IconFont name='arrow' className='text-7px text-font_1' />
          }
          optionFilterProp='name'
          showSearch
          onChange={value => {
            onChange?.({ type: 'FLOW', tool_id: value })
          }}
          onDropdownVisibleChange={visible => {
            if (visible) {
              reloadSkills()
            }
          }}
        >
          {flows?.map(el => (
            <Select.Option key={el.id} value={el.id} name={el.name}>
              <div className='pl-5 w-full flex items-center overflow-hidden group/flow'>
                {el?.icon?.startsWith('http') ? (
                  <img
                    className='w-24px h-24px rounded-4px shrink-0'
                    src={el.icon}
                  />
                ) : (
                  <div
                    className='flex-center w-24px h-24px rounded-4px text-16px'
                    style={{ backgroundColor: el.color }}
                  >
                    {el.icon}
                  </div>
                )}
                <div className='ml-8 flex-1 overflow-hidden'>
                  <span className='text-font truncate block'>{el.name}</span>
                </div>
                <div className='w-24px h-24px invisible shrink-0 group-hover/flow:visible'>
                  <Tooltip title='查看工作流详情' destroyTooltipOnHide>
                    <button
                      className='flex justify-center items-center w-full h-full'
                      type='button'
                      onClick={() => {
                        pageModal.show({
                          url: `/application/${agent.workspaceId}/${el.app_id}/flow/${el.flow_id}`,
                        })
                      }}
                      onMouseDown={event => {
                        event.stopPropagation()
                      }}
                    >
                      <IconFont
                        name='super-link'
                        className={cn('text-bg_3 text-op-60 text-16px')}
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      )}
    </DropdownControl>
  )
}
