import type { Node, Edge } from 'reactflow'
import { cloneDeep } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import { getNextNode, getPrevNode } from '../utils/nodes'
import {
  generateEdgeFromNodes,
  generateNodeRelation,
  isConditionNode,
  isIntentNode,
  isLoopNode,
} from '../utils'
import type { ReplaceNodeData } from '@/store'
import type { NodeType } from '@/features/nodes/base'
import { TO_BE_COPIED_ID } from '../constants'
import type { InsertConfigData } from './BaseNodeOperation'
import { BaseNodeOperation } from './BaseNodeOperation'
import { getInheritedNodeEnableById } from './util'

export class SingleNodeOperation extends BaseNodeOperation {
  insert(options: {
    type: NodeType
    data?: any
    source: string
    target: string
    sourceHandleId?: string | null
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }): InsertConfigData | Promise<InsertConfigData> {
    const {
      type,
      data,
      source,
      target,
      sourceHandleId,
      nodes,
      edges,
      nodesMap,
    } = options
    const config: InsertConfigData = {
      nodes,
      edges,
      newNode: null,
    }
    const sourceNode = nodesMap[source]
    const targetNode = nodesMap[target]

    // 合法性校验
    if (!sourceNode || !targetNode) {
      throw new Error('添加节点失败')
    }

    // 生成插入节点
    const relation = generateNodeRelation(
      nodes,
      edges,
      sourceNode,
      sourceHandleId,
    )

    const newNode: Node = {
      id: uuidv4(),
      type,
      position: {
        x: sourceNode.position.x,
        y: sourceNode.position.y,
      },
      selected: true,
      data: {
        ...data,
        relation: relation || data?.relation,
        ...(sourceHandleId ? { sourceHandleId } : {}),
      },
    }

    // 处理画布数据
    config.edges = config.edges.filter(
      e =>
        e.id !==
        `${
          isConditionNode(sourceNode) || isIntentNode(sourceNode)
            ? newNode.data.relation?.branchId
            : isLoopNode(sourceNode)
              ? `loopStart_${source}`
              : source
        }-${target}`,
    )
    config.edges = config.edges.concat([
      generateEdgeFromNodes({
        source: sourceNode,
        target: newNode,
        sourceHandle: sourceHandleId,
      }),
      generateEdgeFromNodes({
        source: newNode,
        target: targetNode,
      }),
    ])
    config.nodes = nodes.concat(newNode)
    config.newNode = newNode
    return config
  }

  replaceEmpty(data: {
    id: string
    node: ReplaceNodeData<any>
    sourceHandleId?: string
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }) {
    const { id, node, nodes, edges } = data
    const nodeEnable = getInheritedNodeEnableById(id, nodes)
    return {
      nodes: nodes.map(n =>
        n.id === id
          ? { ...n, ...node, data: { ...node.data, isEnable: nodeEnable } }
          : n,
      ),
      edges,
    }
  }

  remove(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
  ) {
    const removingNode = nodesMap[nodeId] as Node
    const prevNode = getPrevNode(removingNode, nodes, edges)
    const nextNode = getNextNode(removingNode, nodes, edges)
    const sourceEdge = edges.filter(e => e.target === nodeId)?.[0]
    if (!prevNode || !nextNode || !sourceEdge) {
      throw new Error('节点数据异常')
    }
    const newEdge = generateEdgeFromNodes({
      source: prevNode.id,
      target: nextNode.id,
      sourceHandle: sourceEdge.sourceHandle, // 防止连接删除节点的前一条边存在出口id（条件节点分支id）
    })
    const newEdges = edges
      .filter(e => e.source !== nodeId && e.target !== nodeId)
      .concat(newEdge)
    const newNodes = nodes.filter(n => n.id !== nodeId)
    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }

  copy(
    originNode: Node,
    // nodes: Node[],
    // edges: Edge[],
    // nodesMap?: Record<string, Node>,
  ) {
    const { id, ...rest } = originNode
    const copiedNode = cloneDeep({
      ...rest,
      id: `${TO_BE_COPIED_ID}_${id}`,
      data: {
        ...rest.data,
        relation: null, // 清除所有的节点依赖关系
      },
    })
    return {
      nodes: [copiedNode],
      edges: [],
    }
  }
}
