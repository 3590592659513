import type { ReactNode } from 'react'
import {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Progress, Tooltip, message } from 'antd'
import CountUp from 'react-countup'
import {
  useCountDown,
  useMemoizedFn,
  usePrevious,
  useRequest,
  useWebSocket,
} from 'ahooks'
import classNames from 'classnames'
import { random } from 'lodash-es'
import { tokenStorage } from '@/utils/storage'
import { getAgentCopilotProgress } from '@/apis/authority'
import { AppLogo, IconFont } from '@/components'
import { useScrollBar } from '@/hooks/useScrollBar'
import { iconColors, iconList } from '@/constants/icon'
import { wait } from '@/utils/wait'

interface CopilotInitProps {
  workspaceId: string
  appId: string
  flowId: string
  onFinish: () => Promise<void> | undefined
  onError: () => Promise<void> | undefined
}

enum ProgressStatus {
  success = 'success',
  progress = 'progress',
  waiting = 'waiting',
  fail = 'fail',
}

interface ProgressItemType {
  id: string
  icon: ReactNode
  text: string
  progress: number
  status: ProgressStatus
  errorMessage: string
}

const mapToStatus: Record<string, ProgressStatus> = {
  FAILED: ProgressStatus.fail,
  TIMEOUT: ProgressStatus.fail,
  SUCCEEDED: ProgressStatus.success,
  0: ProgressStatus.waiting,
  100: ProgressStatus.success,
}

const DEFAULT_EMOJI_MAP: Record<string, string> = {
  // 文件解析
  file_parsing: '📜',
  // 意图分析
  intentional_analysis: '🕵️‍♀️',
  // 名字
  agent_name: '📝',
  // 描述
  agent_description: '✍️',
  // 头像
  agent_icon: '📸',
  // 角色设定
  agent_rule: '👩‍🎨',
  // 开场白
  agent_welcome: '🙋',
  // 生成提问引导
  agent_question_guide: '💬',
}

const MAIN_PROGRESS = ['INTENTIONAL_REFINEMENT', 'intentional_analysis']

const ProgressItem = memo(
  ({ icon, text, progress, status, errorMessage }: ProgressItemType) => {
    const [finish, setFinish] = useState(true)

    const handleChange = useMemoizedFn(async () => {
      if (progress < 100 && finish) {
        setFinish(false)
      } else if (progress === 100) {
        await wait(100)
        setFinish(true)
      }
    })

    useEffect(() => {
      handleChange()
    }, [progress])

    const stateContent = useMemo(() => {
      if (status === ProgressStatus.success && finish) {
        return <IconFont className='text-16px' name='pay-success' />
      }

      if (status === ProgressStatus.fail) {
        return (
          <Tooltip title={errorMessage}>
            <IconFont className='text-16px' name='pay-error' />
          </Tooltip>
        )
      }

      if (status === ProgressStatus.success && finish) {
        return <IconFont className='text-16px' name='pay-success' />
      }

      if (status === ProgressStatus.waiting) {
        return <IconFont className='text-16px' name='dengdai' />
      }

      return (
        <Progress
          style={{ transform: 'rotate(-90deg)' }}
          type='circle'
          percent={progress}
          size={18}
          strokeColor='#00B042'
        />
      )
    }, [progress, status, finish])

    return (
      <div
        className='flex flex-center h-60px w-480px bg-white mb-12px last:mb-0 p-12px rounded-8px'
        style={{
          boxShadow:
            status !== ProgressStatus.waiting
              ? '0px 0px 12px 0px rgba(0, 0, 0, 0.07)'
              : undefined,
          backgroundColor:
            status === ProgressStatus.waiting
              ? 'rgba(98, 105, 153, 0.08)'
              : undefined,
        }}
      >
        <div className='flex flex-center'>{icon}</div>
        <div className='flex-1 text-ellipsis overflow-hidden text-nowrap'>
          {text}
        </div>
        <div>{stateContent}</div>
      </div>
    )
  },
)

export const CopilotProgress = memo(
  ({ workspaceId, appId, flowId, onFinish, onError }: CopilotInitProps) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const listRef = useRef<HTMLDivElement>(null)
    const { scrollRef } = useScrollBar()
    const token = tokenStorage.get()
    const time = useRef(200)
    const [isFinish, setIsFinish] = useState(false)
    const [count, setCount] = useState(0)
    const [progress, setProgress] = useState(0)
    const prevProgress = usePrevious(progress)
    const [list, setList] = useState<ProgressItemType[]>([])
    const { runAsync: getProgress } = useRequest(getAgentCopilotProgress, {
      manual: true,
    })

    const [countdown] = useCountDown({
      leftTime: count,
      onEnd: onFinish,
    })

    const handleRefreshList = useMemoizedFn(async () => {
      try {
        const data = await getProgress(flowId)

        const newList: ProgressItemType[] = data.map((each: any) => {
          const lastItem = list.find(item => item.id === each.key)
          let state = mapToStatus[each.progress] ?? ProgressStatus.progress
          if (each.progress === 100) {
            state = mapToStatus[each.status]
          }

          const isImage = each.icon?.startsWith('http')

          return {
            id: each.key,
            icon: lastItem?.icon ?? (
              <AppLogo
                type={isImage ? 'image' : 'emoji'}
                value={
                  isImage
                    ? each.icon
                    : DEFAULT_EMOJI_MAP[each.key] ||
                      each.icon ||
                      iconList[random(iconList.length - 1)]
                }
                color={iconColors[Math.min(Math.floor(Math.random() * 10), 5)]}
                size={24}
                fillSize={14}
                className='m-r-8 rounded-6px'
                style={{
                  background: !isImage
                    ? 'linear-gradient(314deg, rgba(226, 29, 111, 0.2) 7%, rgba(210, 72, 182, 0.2) 13%, rgba(109, 60, 255, 0.2) 48%, rgba(123, 97, 255, 0.2) 68%, rgba(69, 152, 255, 0.2) 88%)'
                    : '',
                }}
              />
            ),
            text: each.name,
            progress: each.progress,
            status: state,
            errorMessage: each.message,
          }
        })
        if (!newList.length) return

        // 避免只有一个意图分析时，进度显示 100%
        const all = (newList.length < 3 ? 5 : newList.length) * 100
        const now = newList.reduce((acc: number, cur: any) => {
          const add = cur.progress ?? 0
          return add + acc
        }, 0)
        setProgress(Math.floor((now / all) * 100))
        setList(newList)

        // 意图分析失败需重新开始
        const errorItem = newList.find(
          item =>
            MAIN_PROGRESS.includes(item.id) &&
            item.status === ProgressStatus.fail,
        )
        if (errorItem) {
          message.warning(errorItem?.errorMessage)
          onError()
          return
        }

        const isAllSuccess = newList.every(item => item.progress === 100)
        if (isAllSuccess) {
          setIsFinish(true)
          setCount(5000)
        }
      } catch (err) {
        console.log(err)
      }
    })

    const socketUrl = useMemo(() => {
      return `${
        import.meta.env.VITE_AI_WS_BASE_URL
      }/v1/copilot/agent_connect?Workspace-Id=${workspaceId}&Application-Id=${appId}&Authorization=${token}&agent_id=${flowId}`
    }, [workspaceId, appId, token])

    useWebSocket(socketUrl, {
      onMessage: handleRefreshList,
      onOpen: handleRefreshList,
    })

    const handleEnter = useMemoizedFn(async () => {
      if (!parentRef.current) return
      const children = parentRef.current?.children

      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement
        child.style.transition = '.2s ease-in'
        child.style.opacity = '0'
      }

      await wait(time.current)
      for (const each of children) {
        const child = each as HTMLElement
        child.style.opacity = '1'
      }
    })

    const handleListChange = useMemoizedFn(async () => {
      if (!list || !list.length || !listRef.current) return
      const children = listRef.current?.children

      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement

        if (child.style.opacity !== '1') {
          child.style.transition = `.2s ${time.current * i}ms ease-in`
          child.style.opacity = '0'
        }
      }

      await wait(time.current)
      for (const each of children) {
        const child = each as HTMLElement
        child.style.opacity = '1'
      }
    })

    useEffect(() => {
      handleRefreshList()
    }, [])

    useLayoutEffect(() => {
      handleEnter()
    }, [])

    useLayoutEffect(() => {
      handleListChange()
    }, [list.length])

    return (
      <div ref={parentRef} className='flex flex-col w-480px flex-center'>
        {!isFinish && (
          <div className='c-primary text-center text-18px mt-20 mb-8 font-bold'>
            编排中
            <span className='inline-flex mx-4px'>
              <CountUp start={prevProgress ?? 0} end={progress} />%
            </span>
            <span className='inline-flex'>
              <span className='animate-bounce'>.</span>
              <span
                className='animate-bounce'
                style={{ animationDelay: '200ms' }}
              >
                .
              </span>
              <span
                className='animate-bounce'
                style={{ animationDelay: '400ms' }}
              >
                .
              </span>
            </span>
          </div>
        )}

        {isFinish && (
          <div className='c-primary text-center text-18px mt-20 mb-8 font-bold'>
            编排完成
          </div>
        )}

        <div className='max-h-50vh' ref={scrollRef}>
          <div className='p-14px' ref={listRef}>
            {list.map(each => (
              <ProgressItem key={each.id} {...each} />
            ))}
          </div>
        </div>

        {isFinish && (
          <Button
            type='primary'
            block
            className={classNames('mt-36px h-44px !w-284px', {})}
            onClick={onFinish}
          >
            马上进入预览调试({Math.round(countdown / 1000)}s)
          </Button>
        )}
      </div>
    )
  },
)
