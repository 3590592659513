import type { ChangeEvent, KeyboardEvent } from 'react'
import { memo, useMemo } from 'react'
import { Form, Tooltip } from 'antd'
import { type FormListFieldData, type CollapseProps } from 'antd'
import classNames from 'classnames'
import { useMemoizedFn } from 'ahooks'
import { CaretRightOutlined } from '@ant-design/icons'
import { Button, IconFont } from '@/components'
import KeywordsEditor from '@/pages/datastores/components/KeywordsEditor'
import { CollapseWrapper, TextAreaWrapper } from './wrapper'

const MAX_QUESTION_COUNT = 9
const MAX_QUESTION_CONTENT_COUNT = 255

interface IQuestionItem {
  id: string | number
  new?: boolean
  keywords: string[]
  value: string
}

interface QuestionItemProps {
  matchedKeywords?: string[]
  showKeywords?: boolean
  showRemove?: boolean

  index: number
  value?: IQuestionItem
  onChange?: (value: IQuestionItem, index: number) => void
  onRemove: (index: number) => void
}

export const QuestionItem = memo((props: QuestionItemProps) => {
  const {
    matchedKeywords,
    showKeywords,
    showRemove,

    index,
    value,
    onChange,
    onRemove,
  } = props

  const keywords = useMemo(() => value?.keywords ?? [], [value?.keywords])

  const handleChangeQuestion = useMemoizedFn((event: ChangeEvent) => {
    const newValue = (event.target as HTMLInputElement).value
    onChange?.({ ...(value || {}), value: newValue } as IQuestionItem, index)
  })

  const handleChangeKeywords = useMemoizedFn((newKeywords: string[]) => {
    onChange?.(
      { ...(value || {}), keywords: newKeywords } as IQuestionItem,
      index,
    )
  })

  const handleKeyDown = useMemoizedFn((event: KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.preventDefault()
    }
  })

  const handleRemove = useMemoizedFn(() => {
    if (!showRemove) return
    onRemove(index)
  })

  const items: CollapseProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: (
          <span className='text-12px/12px text-#8D8D99'>
            关键词（{keywords.length}/20）
          </span>
        ),
        children: (
          <KeywordsEditor
            layout='horizontal'
            showHeader={false}
            readOnly={false}
            highlightKeywords={matchedKeywords}
            defaultValue={keywords}
            onChange={handleChangeKeywords}
          />
        ),
      },
    ],
    [keywords, matchedKeywords],
  )

  return (
    <div className='flex items-center'>
      <div className='flex-1'>
        <TextAreaWrapper
          autoSize
          autoFocus={!value?.value}
          className={classNames(
            'text-14px px-12px! py-6px! rounded-8px! bg-bg_3/6 hover:bg-bg_3/6 focus-within:bg-bg_3/6',
            {
              'rounded-b-0px! b-b-#626999/6': showKeywords,
            },
          )}
          placeholder='请输入问题'
          count={{
            show: true,
            max: MAX_QUESTION_CONTENT_COUNT,
          }}
          value={value?.value}
          onChange={handleChangeQuestion}
          onKeyDown={handleKeyDown}
        />
        {showKeywords && (
          <CollapseWrapper
            className='bg-bg_3/6 rounded-8px rounded-t-0px!'
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                className={classNames('text-10px! text-#8D8D99! duration-300', {
                  'rotate-90': isActive,
                })}
              />
            )}
            items={items}
          />
        )}
      </div>
      <Tooltip open={!showRemove ? false : undefined} title='删除问题'>
        <span
          className={classNames(
            'p-4px rounded-8px ml-8px c-#626999/60 text-16px',
            {
              'hover:bg-#FF5219/12 hover:c-error cursor-pointer': showRemove,
              'opacity-60 cursor-not-allowed': !showRemove,
            },
          )}
          onClick={handleRemove}
        >
          <IconFont name='shanshu' />
        </span>
      </Tooltip>
    </div>
  )
})

interface QuestionFormProps {
  className?: string
  showKeywords?: boolean
  matchedKeywords?: string[]
  fields: FormListFieldData[]
  isSingle?: boolean
  onAdd: (newItem: IQuestionItem) => void
  onRemove: (index: number) => void
}

export const QuestionForm = memo((props: QuestionFormProps) => {
  const {
    className,
    showKeywords,
    matchedKeywords,
    fields,
    isSingle,

    onAdd,
    onRemove,
  } = props

  const handleAdd = useMemoizedFn(() => {
    onAdd?.({ id: Date.now(), new: true, value: '', keywords: [] })
  })

  const showRemove = useMemo(() => fields.length > 1, [fields])

  return (
    <div className={className}>
      {fields.map((item, index) => (
        <div className='w-full items-center mb-12px last:mb-0px' key={index}>
          <Form.Item
            key={index}
            className='mb-0px!'
            name={item.name}
            rules={[
              {
                validator(_rule, value) {
                  if (!value.value) {
                    return Promise.reject(new Error('请输入问题'))
                  }
                  if (value.value.length > MAX_QUESTION_CONTENT_COUNT) {
                    return Promise.reject(
                      new Error(
                        `输入错误，最多${MAX_QUESTION_CONTENT_COUNT}个字符`,
                      ),
                    )
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <QuestionItem
              showKeywords={showKeywords}
              matchedKeywords={matchedKeywords}
              showRemove={showRemove}
              index={index}
              onRemove={onRemove}
            />
          </Form.Item>
        </div>
      ))}

      {!isSingle && (
        <Button
          icon={<IconFont name='tianjiahuihua' className='text-14px' />}
          disabled={fields.length >= MAX_QUESTION_COUNT}
          onClick={handleAdd}
        >
          添加相似问题
        </Button>
      )}
    </div>
  )
})
