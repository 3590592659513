import type { NodeType } from '@/features/nodes/base'
import { NodeOperationTypes } from '@/features/nodes/base'
import { getNodeMetaByType } from '@/features/nodes'
import { SingleNodeOperation } from './SingleNodeOperation'
import { MultiBranchNodeOperation } from './MultiBranchNodeOperation'
import { LoopNodeOperation } from './LoopNodeOperation'
import { TemplateNodeOperation } from './TemplateNodeOperation'

export const nodeOperationMap = {
  [NodeOperationTypes.SINGLE_NODE]: new SingleNodeOperation(),
  [NodeOperationTypes.TEMPLATE_NODE]: new TemplateNodeOperation(),
  [NodeOperationTypes.MULTI_BRANCH_NODE]: new MultiBranchNodeOperation(),
  [NodeOperationTypes.LOOP_NODE]: new LoopNodeOperation(),
}

export function getOperationByNodeType(type: NodeType) {
  const meta = getNodeMetaByType(type)
  if (!meta) {
    console.error('目标节点无对应元数据')
    return null
  }
  return nodeOperationMap[meta.operationType]
}
