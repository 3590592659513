import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useRequest, useTitle } from 'ahooks'
import { Button, Spin } from 'antd'
import { ChatbotRunType, type Bot } from '@bty/chat-types'
import { Text } from '../agent/chat'
import { createConversation, getBotById } from '@/apis/chatbot'
import { ChatAvatar } from '@/features/chat/components/ChatAvatar'
import logo from '@/assets/logo-square.svg?url'
import { ShareMessageList } from '@/features/chat/components/ShareChat'
import { continueAgentConversation } from '@/apis/agent'
import useShareAgentAuthentic from '@/hooks/useShareAgentAuthentic'
import { PRODUCT_NAME } from '@/constants/common'
import { isMobileDevice } from '@/utils/device'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'

export function ShareAgent() {
  const { robot_id, id } = useParams()

  const searchParams = useSearchParams()

  const { data: bot } = useRequest(() => getBotById(robot_id || ''))

  const { runAsync, loading: loaded } = useRequest(
    () => continueAgentConversation(id || ''),
    { manual: true },
  )

  const { loading, isEnable, isPublic, authentic, getUser, user } =
    useShareAgentAuthentic({ bot, manual: true })

  const navigate = useNavigate()

  const title = searchParams[0].get('title')
  useTitle(title || PRODUCT_NAME)

  if (isMobileDevice()) {
    window.location.href = `${
      import.meta.env.VITE_CHATBOT_MOBILE_URL
    }/shareAgent/${robot_id}/${id}?title=${title}`
    return
  }

  if (loading || loaded) {
    return <Spin />
  }

  if (isEnable === false) {
    // agent未启用
    return <Text text='agent未启用' />
  }

  const handleContinue = () => {
    if (isPublic) {
      id &&
        runAsync().then(res =>
          window.open(`/chat/${res?.robot_id}/${res.conversation_id}`),
        )
      return
    }
    getUser().then(loginStatus => {
      setTimeout(() => {
        if (isPublic === false && !loginStatus && user !== undefined) {
          // 非公开、没有登陆
          navigate('/notAllowed')
          return
        }
        if (isPublic === false && authentic === false && loginStatus) {
          // 非公开、没有权限、登陆
          navigate('/notAllowed')
          return
        }
        id &&
          runAsync().then(res =>
            window.open(`/chat/${res?.robot_id}/${res.conversation_id}`),
          )
      }, 100)
    })
  }

  const handleCreate = async () => {
    if (robot_id) {
      await createConversation({
        robot_id,
        title: '新会话',
        run_type: ChatbotRunType.CHATBOT,
      })
      window.open(`/chat/${robot_id}`)
    }
  }

  return (
    <div className='w-100vw h-100vh box-border'>
      <div className='bg-#fff h-100% flex flex-col overflow-hidden justify-between border-rd-12px'>
        <div className='flex items-center h-72px border-b border-[rgba(225,225,229,0.6)]'>
          <ChatAvatar
            bot={(bot || {}) as Bot}
            currentConversation={{ title: searchParams[0].get('title') || '' }}
          />
        </div>
        <div className='py-40px flex-1'>
          <ShareMessageList bot={bot} conversation_id={id} />
        </div>
        <div className='py-20px border-t border-[rgba(225,225,229,0.6)]'>
          <div className='text-center'>
            <Button
              className='w-230px text-font font-medium'
              loading={loading}
              size='large'
              onClick={handleCreate}
            >
              发起新会话
            </Button>
            <Button
              className='w-230px font-medium ml-12'
              loading={loading}
              size='large'
              type='primary'
              onClick={handleContinue}
            >
              继续此会话
            </Button>
          </div>
          <PrivateDeployGuard>
            <p
              className='text-font_1 text-14px cursor-pointer flex justify-center items-center mt-12'
              onClick={() => window.open('https://www.betteryeah.com/')}
            >
              <img
                className='h-18px w-18px inline-block mr-4'
                src={logo}
                alt=''
              />
              Powered by BetterYeah
            </p>
          </PrivateDeployGuard>
        </div>
      </div>
    </div>
  )
}
