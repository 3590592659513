import { useUnmount } from 'ahooks'
import { useLayoutEffect, useRef, useState } from 'react'

const PAGINATION_HEIGHT = 69
const TABLE_HEADER_HEIGHT = 41

export function useTableBodyScroll() {
  const [tableBodyHeight, setTableBodyHeight] = useState(400)
  const tableRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<ResizeObserver | null>(null)

  useLayoutEffect(() => {
    const node = tableRef.current
    if (node) {
      observerRef.current = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target === node) {
            const { height } = node.getBoundingClientRect()
            const paginationEle = node.querySelector('.ant-table-pagination')
            const tableHeaderEle = node.querySelector('.ant-table-header')
            const pH = paginationEle
              ? paginationEle.getBoundingClientRect().height
              : PAGINATION_HEIGHT
            const hH = tableHeaderEle
              ? tableHeaderEle.getBoundingClientRect().height
              : TABLE_HEADER_HEIGHT
            console.log(99112, height, pH, hH)
            setTableBodyHeight(height - pH - hH)
          }
        }
      })
      observerRef.current.observe(node)
    }
  }, [tableRef])

  useUnmount(() => {
    if (observerRef.current && tableRef.current) {
      observerRef.current.unobserve(tableRef.current)
    }
  })

  return {
    tableBodyHeight,
    tableRef,
  }
}
