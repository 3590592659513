import { useEffect, useMemo } from 'react'
import { Form } from 'antd'
import { isNil } from 'lodash-es'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'

import {
  FLOW_DRAFT_LOCK_STATUS,
  useFlowDraftStore,
  useFlowInteractionStore,
} from '@/store'
import {
  HandleStatus,
  statusStyles,
} from '@/features/flow/components/DefaultHandle'
import { JsonForm } from '../components'
import { SliderSingle } from '@/components'
import { LoopTypes, type LoopNodeData } from './LoopNode'
import { LoopConditionNode } from './LoopResultFormItemNode'

export const LoopResultNode: NodeComponent<any> = props => {
  const { id, data, variables, nodeElement } = props
  const [form] = Form.useForm()

  const { relation } = data
  const { dangerStateNodeIds, activeHandles } = useFlowInteractionStore()
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const { nodes } = useFlowDraftStore()

  const targetNodeLoopType = useMemo(() => {
    const target = nodes.find(node => node.id === relation.loopNodeId)
      ?.data as LoopNodeData
    return target?.inputs?.loop_type
  }, [relation.loopNodeId, nodes])

  const style = useMemo(() => {
    const isDanger = dangerStateNodeIds.includes(id)
    if (isDanger) {
      return statusStyles[HandleStatus.TO_BE_REMOVED]
    }
    return {}
  }, [id, dangerStateNodeIds, activeHandles])

  const list = useMemo(() => {
    return [
      {
        label: '最大循环次数',
        name: ['inputs', 'iterations'],
        type: 'TextEditor',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        render: () => (
          <SliderSingle
            min={1}
            max={10}
            size='small'
            step={1}
            controls={false}
          />
        ),
      },
      {
        label: '循环终止条件',
        name: ['inputs', 'statement'],
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        render: () => {
          return (
            <LoopConditionNode
              nodeElement={nodeElement}
              variables={variables}
            />
          )
        },
      },
    ]
  }, [targetNodeLoopType, variables, nodeElement]) as any

  // [兼容老数据]
  useEffect(() => {
    const inputs = data.inputs
    if (
      isNil(inputs?.iterations) &&
      targetNodeLoopType === LoopTypes.CONDITION
    ) {
      form.setFieldValue(['inputs', 'iterations'], 3)
    }
  }, [targetNodeLoopType, data?.inputs?.iterations])

  const onBeforeChange = (values: any) => {
    if (!values.inputs?.iterations) {
      form.setFieldValue(['inputs', 'iterations'], 3)
    }
    return values
  }

  return (
    <div className='flex-center w-100% relative'>
      {targetNodeLoopType === LoopTypes.CONDITION ? (
        <div className='relative p-16 pb-0 w-420px'>
          <div className='c-font text-12px/16px font-500 mb-16px'>
            设置跳出规则
          </div>
          <JsonForm
            form={form}
            list={list}
            initialValues={{ ...data }}
            disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
            beforeChange={onBeforeChange}
          />
        </div>
      ) : (
        <div className='c-font_1 py-22px'>循环结束</div>
      )}
      <div></div>
      <div
        className='absolute bottom-[-10px] w-10px h-10px b-rd-50 b-#c5c5cc b-1 shadow-[0_0_0_2px_#f4f4f6]'
        style={{
          transform: 'translate(-50%, 100%)',
          ...style,
        }}
      ></div>
    </div>
  )
}

export const customLoopPackageName = 'loop_end'

LoopResultNode.meta = {
  type: NodeType.LOOP_RESULT,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'LOOP_RESULT',
  typeName: '终止循环',
  icon: 'xunhuanjiedian',
  backgroundColor: '#387dff',
  classNames: ['overflow-visible'],
  canDelete: false,
  initialData: {
    name: `${customLoopPackageName}_1`,
    inputs: {
      iterations: 3,
      statement: '',
    },
  },
}
