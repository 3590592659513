import { SearchOutlined } from '@ant-design/icons'
import { useAuth } from '@bty/react-auth'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import type { InputRef } from 'antd'
import { Select } from 'antd'
import classNames from 'classnames'
import { rgba } from 'polished'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash-es'
import { useWorkspaceStore } from '@/store'
import { useWarningMessage } from '@/hooks/useWarningMessage'
import { useLoginModal } from '@/features/login'
import { defaultRouterPath } from '@/constants/common.ts'
import { IconFont, Input } from '@/components'
import type { WorkspaceItem } from '@/apis/workspace'
import { queryWorkspaceFree } from '@/apis/workspace'
import { RoleCode } from '@/apis/authority'
import { storageRouterMap } from '../contants'
import { useLimitedAccessModal } from '@/features/pay/LimitedAccess'

const Container = styled.div`
  /* .ant-select-item-option:last-child {
    padding-bottom: 30px;
  } */
`
const StyledSelect = styled(Select, {
  shouldForwardProp: prop => prop !== 'collapsed',
})<{ collapsed: boolean }>`
  &.ant-select {
    max-width: 100%;
    .ant-select-item {
      margin-bottom: 4px;
    }
    .ant-select-selector {
      padding: 0 9px;
    }
    .ant-select-selection-item {
      padding-inline-end: ${props => (props.collapsed ? 0 : '18px')};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .ant-select-selection-item {
    font-weight: 500;
    padding-inline-end: 16px;
  }
  &.ant-select-single.ant-select-open .ant-select-selection-item {
  }
  && {
    .ant-select-arrow {
      svg {
        /* font-size: 6px; */
        color: ${props => rgba(props.theme.colors.bg_3, 0.6)};
      }
    }
  }
`

function GlobalSelectStyle() {
  return (
    <Global
      styles={css`
        .dropdown-light.ant-select-dropdown {
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
          .ant-select-item {
            margin-bottom: 4px;
          }
        }
        .globalStyledSelect {
          .rc-virtual-list-holder {
            border-radius: 6px;
          }
          .ant-select-item-option:last-child {
            box-sizing: content-box;
          }
        }
      `}
    />
  )
}

// const SHOW_SEARCH_COUNT = 10

export function getWorkspaceChangeRoutePath(
  pathName: string,
  currWorkspaceId: string,
) {
  const pathNameArr = pathName.split('/')

  if (pathNameArr?.[1] === 'chatbot') return pathName

  const isWorkspacePath =
    pathNameArr.length > 2 && pathNameArr?.[1] === 'workspace'

  if (isWorkspacePath) {
    pathNameArr[2] = currWorkspaceId
    return pathNameArr.join('/')
  }

  const isStatisticsPath =
    pathNameArr.length > 2 && pathNameArr?.[1] === 'statistics'

  if (isStatisticsPath) {
    pathNameArr[2] = 'usageStatistics'
    return pathNameArr.join('/')
  }

  return pathNameArr.length > 1 ? `/${pathNameArr?.[1]}` : defaultRouterPath
}

export function WorkspaceList(props: { collapsed: boolean }) {
  const { collapsed } = props

  const {
    setCurrentWorkspace,
    currentWorkspaceId,
    workspaceList,
    fetchWorkspaceList,
    // currentWorkspaceItem,
  } = useWorkspaceStore()

  const [menuOpen, setMenuOpen] = useState(false)
  const inputRef = useRef<InputRef>(null)

  const {
    state: { role, user },
  } = useAuth()

  const [limitedAccessModal] = useLimitedAccessModal({
    title: '平台空间数量已达上限，无法继续新建空间',
    content: '',
  })

  const [modal] = useLoginModal()

  const navigate = useNavigate()

  const containerRef = useRef<HTMLDivElement>(null)

  const { open } = useWarningMessage()

  const [filterValue, _setFilterValue] = useState('')

  const onSearch = debounce(async (val: string) => {
    const text = val.trim()
    if (!text) {
      await fetchWorkspaceList()
      return
    }
    // 改为服务端搜索，用于支持系统超级管理员搜索工作空间；为缓解扫描压力，剔除3个纯数字以下的搜索内容
    const isInvalid = /^\d{1,2}$/.test(text)
    if (isInvalid) return
    await fetchWorkspaceList(text)
  }, 300)

  const options = useMemo(() => {
    const filteredWorkspaceList = workspaceList.filter(v =>
      v.name.toLowerCase().includes(filterValue.toLowerCase()),
    )
    const renderOptionLabel = (v: WorkspaceItem, collapsed: boolean) => {
      return (
        <div className='h-full flex flex-items-center gap-8px c-font'>
          <div
            style={{ flex: '0 0 28px' }}
            className='c-#fff h-28px w-28px flex items-center justify-center bg-primary border-rd-50%'
          >
            {v?.name?.[0]}
          </div>
          {!collapsed && (
            <>
              {v.creator === user?.userId ? (
                <div
                  className='px-4px py-2px text-12px'
                  style={{
                    border: '1px solid rgba(56, 166, 255, 0.48)',
                    borderRadius: '4px',
                    color: '#38A6FF',
                  }}
                >
                  自建
                </div>
              ) : (
                ''
              )}
              <div className='max-w-154px truncate'>{v.name}</div>
            </>
          )}
        </div>
      )
    }
    return filteredWorkspaceList
      ?.map(v => ({
        selectLabel: renderOptionLabel(v, collapsed),
        label: renderOptionLabel(v, false),
        value: v.id,
        sort: v.creator === user?.userId ? 1 : 0,
      }))
      .sort((a, b) => b.sort - a.sort)
  }, [workspaceList, filterValue, collapsed, user?.userId])

  const handleChange = (value: any) => {
    setCurrentWorkspace(value)
    Object.values(storageRouterMap).forEach(key => localStorage.removeItem(key))
    navigate(getWorkspaceChangeRoutePath(window.location.pathname, value))
  }

  const handleCreateWorkspace = async () => {
    const canCreate = await queryWorkspaceFree()
    if (role === RoleCode.ADMINISTRATOR && !canCreate) {
      // 已创建免费空间不能继续在创建
      limitedAccessModal.open()
      return
    }
    if (role !== RoleCode.ADMINISTRATOR && !canCreate) {
      // 已经创建免费空间被邀请到付费空间（非管理员不能继续创建）引导到自建空间升级
      open({
        duration: 10,
        title: '无空间创建权限',
        content: '请联系所在空间管理员，或切换至您「自建」的空间操作',
      })
      return
    }
    modal.open({
      type: 'workspace',
      closable: true,
      onSuccess: async res => {
        await fetchWorkspaceList()
        res.id && setCurrentWorkspace(res.id)
        modal.close()
        navigate(workspaceList.length ? defaultRouterPath : '/chatbot')
      },
    })
  }

  useEffect(() => {
    if (menuOpen && inputRef.current) {
      // inputRef.current.focus()
      setTimeout(() => {
        inputRef.current?.focus()
      }, 10)
    }
  }, [menuOpen])

  return (
    <Container
      className={classNames(
        'max-w-224px my-12px h-48px flex items-center b-rd-8px overflow-hidden shrink-0 hover:bg-[rgba(255,255,255,0.6)]',
        {
          'bg-#fff': menuOpen,
          'w-min': collapsed,
        },
      )}
      ref={containerRef}
    >
      <GlobalSelectStyle />
      <StyledSelect
        virtual={false} // 上面样式中使用了margin-bottom:4px;影响了虚拟列高度的计算，所以导致最后一个元素看不见，暂时关闭虚拟滚动
        collapsed={collapsed}
        // value={workspaceList.length ? currentWorkspaceId : ''}
        value={currentWorkspaceId}
        className=' text-font_1  text-14px flex-1'
        listHeight={320}
        // getPopupContainer={() => containerRef.current as HTMLElement}
        variant='borderless'
        popupClassName='dropdown-light max-w-auto! globalStyledSelect'
        optionLabelProp='selectLabel'
        placement='bottomLeft'
        dropdownRender={menu => {
          return (
            <>
              <div>
                {/* {workspaceList.length > SHOW_SEARCH_COUNT && (
                  <Input
                    ref={inputRef}
                    className='mb-10 mt-4 rounded-4px'
                    autoFocus
                    placeholder='搜索工作空间'
                    onChange={e => setFilterValue(e.target.value)}
                    onChange={e => {
                      if (e.target.value.trim()) {
                        onSearch(e.target.value)
                      }
                    }}
                    prefix={<SearchOutlined className='c-font_1 c-op-60' />}
                  ></Input>
                )} */}
                <Input
                  ref={inputRef}
                  className='mb-10 mt-4 rounded-4px'
                  autoFocus
                  placeholder='搜索工作空间'
                  onChange={e => {
                    onSearch(e.target.value)
                  }}
                  prefix={<SearchOutlined className='c-font_1 c-op-60' />}
                ></Input>
              </div>
              {menu}
              <div className='border-t-1 b-#e1e1e5 b-op-60'>
                <div
                  onClick={handleCreateWorkspace}
                  className='relative h-40px mt-8px px-8px rounded-6px flex flex-items-center text-primary bg-opacity-24 items-center  cursor-pointer hover:bg-#626999 hover:bg-op-8'
                >
                  <IconFont name='add' />
                  <span className='ml-6'>创建空间</span>
                </div>
              </div>
            </>
          )
        }}
        suffixIcon={
          collapsed ? null : (
            <div className='flex flex-col'>
              <IconFont
                name='yongqi2111'
                className='text-12px text-font_1'
              ></IconFont>
              <IconFont
                name='yongqi211'
                className='text-12px text-font_1'
              ></IconFont>
            </div>
          )
        }
        dropdownStyle={{
          maxWidth: '224px',
          minWidth: '224px',
          overflowY: 'auto',
        }}
        options={options}
        onChange={handleChange}
        onDropdownVisibleChange={open => {
          setMenuOpen(open)
        }}
      ></StyledSelect>
    </Container>
  )
}
