import { Button } from '@/components'

interface EmptyAgentListProps {
  handleCreatePlugin?: () => void
}
export function EmptyAgentList(props: EmptyAgentListProps) {
  const { handleCreatePlugin } = props
  return (
    <div className='mt-108px flex items-center flex-col'>
      <img
        src='https://resource.bantouyan.com/battleyeah-ai/ai-client/empty_plugin_page.png'
        alt='empty'
        className='w-140px h-140px'
      />
      <span className='block mt-10px text-14px/16px color-#6269997A'>
        创建Agent，在Agent中使用Plugin
      </span>
      <Button type='primary' className='mt-24px' onClick={handleCreatePlugin}>
        创建Agent
      </Button>
    </div>
  )
}
