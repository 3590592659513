import dayjs from 'dayjs'
import { isNil, isObject, omit } from 'lodash-es'
import type {
  LLMNodeData,
  LLMNodeDataForm,
  LLMNodeFormValues,
} from '@/features/nodes/llm'
import type { LLMBatchNodeDataForm } from '../../lllm-batch'
import { LLMContextType } from '../const'

export function extractVars(
  s: string,
  scope: string[] | undefined = undefined,
) {
  // 确保输入是字符串
  if (typeof s !== 'string') {
    return []
  }

  // 修改正则表达式以匹配包括方括号及其内容的变量名
  const matches = s.match(/\{\{([\w.\[\]0-9]+)\}\}/g)
  // 如果没有找到匹配项，返回空数组
  if (!matches) return []

  const result = matches.map(match => match.slice(2, -2))

  if (scope) {
    const filteredResult = new Set<string>()
    result.forEach(item => {
      const match = scope.some(variable => {
        return (
          item === variable ||
          item.startsWith(`${variable}.`) ||
          item.match(new RegExp(`^${variable}\\[[0-9]+\\]`))
        )
      })
      if (match) {
        filteredResult.add(item)
      }
    })
    return Array.from(filteredResult)
  }

  return result
}

export function fillDynamicValues(ctx: string, variables: Record<string, any>) {
  const regex = /{{(?!text)[^{}]+}}/g
  const result = ctx.replace(regex, match => {
    return !isNil(variables[match.slice(2, -2).trim()])
      ? variables[match.slice(2, -2).trim()] || ''
      : match
  })
  return result
}

export function transformLLMNodeData2LLMNodeFormData(
  data: LLMNodeData,
): LLMNodeDataForm {
  // 兼容旧字段
  const {
    system_content,
    pre_defined_system_content,
    messages,
    context_type,
    plugin,
    channel,
    model,
    temperature,
    top_p,
    presence_penalty,
    frequency_penalty,
  } = data.inputs

  const systemContent =
    system_content ||
    (context_type === LLMContextType.JSON_VARIABLE
      ? pre_defined_system_content
      : messages?.find(item => item.role === 'system')?.content) ||
    ''

  return {
    ...data,
    inputs: {
      ...data.inputs,
      system_content: systemContent,
      modelSetting: {
        channel,
        model,
        temperature,
        top_p,
        presence_penalty,
        frequency_penalty,
        outputType: plugin?.json_mode ? 'json' : 'text',
      },
    },
  }
}

//  与transformLLMNodeData2LLMNodeFormData的差别 只处理input相关数据
export function transformInputDataAdapter(
  inputs: LLMNodeData['inputs'],
): LLMNodeData['inputs'] {
  const {
    system_content,
    pre_defined_system_content,
    pre_defined_messages,
    messages,
    context_type,
  } = inputs
  const systemContent =
    system_content ||
    (context_type === LLMContextType.JSON_VARIABLE
      ? pre_defined_system_content
      : messages?.find((item: any) => item.role === 'system')?.content) ||
    ''
  return {
    ...inputs,
    system_content: systemContent,
    pre_defined_system_content,
    pre_defined_messages,
  }
}

export function formatCurrent(data: any) {
  const { inputs } = data
  return {
    messages: [...(inputs.messages || [])],
    params: omit(inputs, ['messages']),
    date: dayjs(),
    history_id: 'current',
    isLocal: true,
  }
}

export function transformData(data: any[]) {
  const defaultGroup: Record<string, any> = {}
  const groupByDate = data.reduce((groups: any, item: any) => {
    // 使用dayjs进行日期转换
    item.date = dayjs(item.created_time).valueOf()
    const key = dayjs(dayjs(item.created_time).format('YYYY-MM-DD')).valueOf()
    // 按日期进行分组
    if (!groups[key]) {
      groups[key] = []
    }
    // 这时我们将整个item对象，而非仅仅是content，推入数组
    groups[key].push(item)

    return groups
  }, defaultGroup)

  const keys = Object.keys(groupByDate)
    .map(Number)
    .sort((a, b) => b - a)

  // 将对象转换为所需的数组格式
  const result = keys.map(key => ({
    date: key,
    list: groupByDate[key.toString()],
  }))
  return result
}

export function isMac() {
  return /macintosh|mac os x/i.test(navigator.userAgent.toLowerCase())
}

export function isLLMLikeFormValues(value: unknown): value is {
  inputs: {
    modelSetting: LLMNodeDataForm['inputs']['modelSetting']
    stream: boolean
    [k: string]: any
  }
} {
  if (!isObject(value)) return false
  if (!('inputs' in value)) return false
  if (!isObject(value.inputs)) return false
  let flag = true
  const props = ['modelSetting']
  const keys = Object.keys(value.inputs)
  for (const p of props) {
    if (!keys.includes(p)) {
      flag = false
      break
    }
  }
  return flag
}

export function isLLMNodeFormValues(
  value: unknown,
): value is LLMNodeFormValues {
  if (!isLLMLikeFormValues(value)) return false
  return (
    'context_type' in value.inputs ||
    'pre_defined_messages' in value.inputs ||
    'messages' in value.inputs
  )
}

export function isLLMBatchNodeFormValues(
  value: unknown,
): value is LLMBatchNodeDataForm {
  if (!isLLMLikeFormValues(value)) return false
  return 'splitName' in value.inputs
}
