import { Popover, Tooltip } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Input, Select, IconFont } from '@/components'
import { AddButton } from '../../components'
import { Track } from '@/features/track'

const MAX_COUNT = 10
export interface IntentExampleItemProps {
  value?: Array<{ query: string; intent: string }>
  onChange?: (value: Array<{ query: string; intent: string }>) => void
  options?: Array<{ label: string; value: string }>
}
export function IntentExampleItem(props: IntentExampleItemProps) {
  const { value = [], onChange, options = [] } = props
  const [open, setOpen] = useState(false)
  const wrapper = useRef<HTMLDivElement>(null)
  const [innerValue, setInnerValue] = useState(props.value || [])

  useEffect(() => {
    if (props.value && props.value?.length) {
      setInnerValue(props.value)
    }
  }, [props.value])

  const handleAdd = () => {
    const newItem = { query: '', intent: '' }
    const newValue = [...innerValue, newItem]
    setInnerValue(newValue)
    onChange?.(newValue)
  }

  const handleValChange = (
    idx: number,
    val: string,
    field: 'query' | 'intent',
  ) => {
    const updatedItems = innerValue.map((item, index) => {
      if (index === idx) {
        return { ...item, [field]: val }
      }
      return item
    })
    setInnerValue(updatedItems)
    onChange?.(updatedItems) // 此处触发onChange
  }

  const handleDel = (idx: number) => {
    const newValue = innerValue.filter((_item, index) => index !== idx)
    setInnerValue(newValue)
    onChange?.(newValue) // 此处触发onChange
  }

  const disabled = useMemo(() => {
    return value?.length >= MAX_COUNT
  }, [value])

  const renderPopoverTitle = useMemo(() => {
    return (
      <div className='w-100% flex items-center justify-around'>
        <span>添加样本数据</span>
        <span
          className='ml-auto text-16px w-16px h-16px cursor-pointer'
          onClick={() => {
            setOpen(false)
          }}
        >
          <IconFont name='guanbi' />
        </span>
      </div>
    )
  }, [])

  const renderPopoverContent = useMemo(() => {
    return (
      <div className='w-100%'>
        <p className='text-12px/20px c-font_1 text-op-60'>
          识别不准时可添加样本训练模型，提高准确率，易错意图推荐添加2-4个样本数据
        </p>
        {!!innerValue?.length && (
          <div className='mt-16px'>
            <div className='flex-1'>
              <div className='flex items-center c-font text-12px/16px gap-8px'>
                <p className='w-216px'>用户问题</p>
                <p className='w-136px'>所属分类</p>
              </div>
              {innerValue.map((item: any, idx: number) => {
                const key = `${item.intent}_${idx}`
                return (
                  <div
                    key={key}
                    className='mt-8px flex items-center c-font gap-8px'
                  >
                    <Tooltip
                      title={item?.query?.length > 14 ? item?.query : undefined}
                    >
                      <Input
                        value={item.query}
                        size='small'
                        onChange={e => {
                          const val = e.target.value
                          handleValChange(idx, val, 'query')
                        }}
                        placeholder='输入用户问题'
                        className='w-216px'
                      />
                    </Tooltip>
                    <Select
                      className='nodrag nopan'
                      getPopupContainer={() => wrapper?.current!}
                      placeholder='请选择'
                      size='small'
                      options={options}
                      value={item.intent}
                      style={{ width: 136 }}
                      onChange={(val: string) => {
                        handleValChange(idx, val, 'intent')
                      }}
                    />
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        handleDel(idx)
                      }}
                      className={classNames(
                        'flex items-center justify-center w-24px h-24px text-opacity-60  text-font_1 rounded-4px hover:bg-error hover:bg-opacity-12 hover:text-error',
                      )}
                    >
                      <IconFont
                        name='shanshu'
                        className={classNames(
                          'text-16px text-op-60  cursor-pointer',
                        )}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <Track
          event='intent_sample_data_add'
          properties={{
            actionType: 'intent_sample_data_add',
            name: '意图节点添加样本数据',
          }}
        >
          <AddButton
            disabled={disabled}
            className='mt-8px'
            onClick={() => {
              handleAdd()
            }}
          >
            添加样本数据
          </AddButton>
        </Track>
      </div>
    )
  }, [value, handleAdd, handleDel])

  return (
    <div ref={wrapper}>
      <span className='flex items-center text-12px/16px h-20px rounded-4px px-4px c-primary hover:bg-[rgba(98,105,153,0.08)] cursor-pointer'>
        <Popover
          arrow={false}
          open={open}
          overlayClassName='nodrag nopan w-420px z-100 [&_.ant-popover-inner]:p-16px !rounded-8px'
          placement='bottomRight'
          getPopupContainer={() => wrapper?.current!}
          title={renderPopoverTitle}
          content={renderPopoverContent}
        >
          <span
            onClick={() => {
              setOpen(true)
            }}
          >
            样本数据
          </span>
        </Popover>
      </span>
    </div>
  )
}
