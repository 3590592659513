import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import type { TableColumnsType } from 'antd'
import { Spin } from 'antd'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Table } from '@/components'
import type { orderType } from '@/apis/order'
import { getorderList } from '@/apis/order'
import { useWorkspaceStore } from '@/store'
import { OrderStatus } from '@/features/pay'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import StatusTag from './components/StatusTag'
import { OrderStatusSetting, orderPayName } from './util'
import InvoicingModal from './components/InvoicingModal'

const TableWrapper = styled(Table)`
  .ant-table-thead .ant-table-cell {
    padding: 16px;
  }
  .ant-table-tbody .ant-table-row .ant-table-cell {
    padding: 10px 16px;
    background: #fff;
  }
`

const columns: ({
  history,
}: {
  history: NavigateFunction
}) => TableColumnsType<orderType> = () => [
  {
    title: '版本名称',
    key: 'productServiceName',
    fixed: 'left',
    ellipsis: true,
    width: 120,
    render: (_, record) => record.productServiceName,
  },
  {
    title: 'Yeah积分',
    key: 'yeah_points',
    ellipsis: true,
    width: 200,
    render: (_, record) => record.yeah_points,
  },
  {
    title: '订单金额',
    key: 'realPayAmount',
    ellipsis: true,
    width: 200,
    render: (_, record) => `¥${record.realPayAmount}`,
  },
  {
    title: '支付时间',
    ellipsis: true,
    key: 'payingTime',
    width: 200,
    render: (_, record) =>
      record.payingTime
        ? dayjs(record.payingTime).format('YYYY/MM/DD HH:mm')
        : '-',
  },
  {
    title: '支付方式',
    ellipsis: true,
    key: 'orderPayType',
    width: 200,
    render: (_, record) => orderPayName[record.orderPayType] ?? '-',
  },
  {
    title: '订单编号',
    key: 'orderNo',
    ellipsis: true,
    width: 200,
    render: (_, record) => record.orderNo,
  },
  {
    title: '状态',
    key: 'status',
    ellipsis: true,
    fixed: 'right',
    width: 110,
    render: (_, record) => (
      <StatusTag {...(OrderStatusSetting[record.orderStatus] || {})} />
    ),
  },
  {
    title: '操作',
    key: 'actions',
    fixed: 'right',
    ellipsis: true,
    width: 110,
    render: (_, record) => (
      <>
        {record.orderStatus === OrderStatus.COMPLETED && (
          <InvoicingModal>
            <a className='c-primary'>申请开票</a>
          </InvoicingModal>
        )}
        {[
          OrderStatus.WAIT_AUDIT,
          OrderStatus.WAIT_PAY,
          OrderStatus.PLACE_ORDER,
          OrderStatus.AUDIT_DISMISS,
          OrderStatus.WAIT_UPLOAD_CERT,
        ].includes(record.orderStatus) && (
          <a
            className='c-primary'
            onClick={() => window.open(`/pay?orderId=${record.orderNo}`)}
          >
            查看详情
          </a>
        )}
      </>
    ),
  },
]

export default function Orders() {
  const history = useNavigate()
  const workspace_id = useWorkspaceStore(state => state.currentWorkspaceId)
  const { data, loading } = useRequest(() => getorderList(workspace_id))
  const { scrollRef } = useScrollBar()
  return (
    <div ref={scrollRef} className='adapt:px-64 h-full overflow-scroll'>
      <Spin spinning={loading} />
      <TableWrapper
        loading={loading}
        pagination={false}
        scroll={{ x: 1500 }}
        columns={columns({ history })}
        dataSource={
          // 兼容后端数据返回 data 整体是对象的情况，通常是内部 api 调用报错
          (Array.isArray(data) ? data : [])?.map((item, key) => ({
            ...item,
            key,
          })) as orderType[]
        }
      />
    </div>
  )
}
