import { useState, useMemo, useRef, useContext, useEffect } from 'react'
import { Spin } from 'antd'

import type { FormInstance } from 'antd'
import styled from '@emotion/styled'
import { useBoolean } from 'ahooks'
import { get, assign, isNil, isEmpty } from 'lodash-es'
import { DynamicForm } from '../../base/components/DynamicForm'
import { NodeFormItem, JsonForm } from '../../components'
import { FormList } from '../../components/Forms/FormList'
import { useDynamicValues } from '../../hooks/useDynamicValues'
import type { InnerNodePanelProps } from '@/features/nodes/base'
import type { JsonFormConfig } from '@/features/nodes/components'
import { Select, Button } from '@/components'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { CodeEditor } from '@/features/editor'
import useStartFileNodeVariableKeyList from '../../hooks/useStartFileNodeVariableKeyList'

const CodeNodePanelWrapper = styled.div`
  // width: calc(100vw - 400px);
  width: 100%;

  padding: 20px;
`

const FormItemStyle = styled(NodeFormItem)`
  .ant-form-item-explain {
    .ant-form-item-explain-error {
      margin-bottom: 0;
      margin-top: 0;
      position: absolute;
    }
  }
`

const METHOD_OPTIONS = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PUT', value: 'PUT' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'DELETE', value: 'DELETE' },
]

const BODY_TYPE_OPTIONS = [
  { label: 'JSON', value: 'JSON' },
  { label: 'FormData', value: 'FormData' },
]

const NO_BODY_METHODS = ['GET', 'DELETE']

interface URLEditorProps {
  variables: any[]
  variableTipsContainer?: HTMLElement
  value?: string
  onChange?: (value: string) => void
}

function URLEditor(props: URLEditorProps) {
  const [
    wrapEnabled,
    { setTrue: setWrapEnabledTrue, setFalse: setWrapEnabledFalse },
  ] = useBoolean(false)

  return (
    <div className='absolute top-0 w-100% z-10'>
      <CodeEditor
        {...props}
        className='ace-gray important:w-100%'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={props.value}
        onChange={props.onChange}
        wrapEnabled={wrapEnabled}
        onFocus={setWrapEnabledTrue}
        onBlur={setWrapEnabledFalse}
        singleLine
        variables={props.variables}
        setOptions={{
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
        placeholder='http://example.com'
      />
    </div>
  )
}

export function ApiNodePanel(props: InnerNodePanelProps<any>) {
  const { data, activatedNodeId, variables, startNodeFormItemType = [] } = props

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType!,
    variables,
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<FormInstance>(null)
  const [methods] = useState(METHOD_OPTIONS)

  const [loading] = useState<boolean>(false)

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: formRef.current?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message = [
        { content: get(value, 'inputs.body') },
        { content: get(value, 'inputs.headers') },
        { content: get(value, 'inputs.url') },
      ]
      return message
    },
  })

  const { usedKeyListByNodeId } = val

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await formRef.current?.validateFields()
    run(data)
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables])

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })
    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: 'URL',
        className: 'important:m-b-0',
        render: () => {
          return (
            <div className='flex items-start gap-4px'>
              <NodeFormItem name={['inputs', 'methodType']} required>
                <Select
                  className='nodrag nopan'
                  size='small'
                  placeholder='请选择'
                  options={methods}
                  style={{ width: 96 }}
                />
              </NodeFormItem>
              <FormItemStyle
                name={['inputs', 'url']}
                className='flex-1'
                required
                rules={[{ required: true, message: 'URL不能为空' }]}
              >
                <URLEditor
                  variables={props.variables}
                  variableTipsContainer={props.nodeElement as HTMLElement}
                />
              </FormItemStyle>
            </div>
          )
        },
      },
      // {
      //   label: 'API描述',
      //   name: ['inputs', 'description'],
      //   type: 'Input',
      //   componentProps: {
      //     placeholder: '添加描述，方便在流程中直观看到本API的作用',
      //   },
      // },
      {
        label: '请求头',
        name: ['inputs', 'headers'],
        render: () => {
          return (
            <FormList
              type='FormData'
              variableTipsContainer={props.nodeElement}
              variables={props.variables}
            />
          )
        },
      },
      {
        label: '请求体',
        hidden: NO_BODY_METHODS.includes(
          props.data.inputs?.methodType as string,
        ),
        render: () => {
          return (
            <>
              <NodeFormItem noStyle name={['inputs', 'bodyType']}>
                <Select
                  className='mb-12 nodrag nopan'
                  size='small'
                  options={BODY_TYPE_OPTIONS}
                  getPopupContainer={() => containerRef.current as HTMLElement}
                />
              </NodeFormItem>
              <NodeFormItem noStyle name={['inputs', 'body']}>
                <FormList
                  type={props.data.inputs?.bodyType}
                  variableTipsContainer={props.nodeElement}
                  variables={props.variables}
                />
              </NodeFormItem>
            </>
          )
        },
      },
      {
        label: '重试次数',
        name: ['inputs', 'retryCount'],
        hidden: false,
        type: 'InputNumber',
        componentProps: {
          className: 'nodrag nopan',
          tooltip: '当API出错时，重新请求的次数。最大值为5次。',
          size: 'small',
          placeholder: '重试次数',
          max: 5,
          min: 0,
          step: 1,
        },
      },
      {
        label: '超时时间（秒）',
        name: ['inputs', 'requestTimeout'],
        hidden: false,
        type: 'InputNumber',
        componentProps: {
          className: 'nodrag nopan',
          tooltip: '请求超时时间，单位为秒。默认值为10秒。',
          placeholder: '请求超时时间，单位为秒。',
          max: 100,
          min: 1,
          step: 1,
        },
      },
    ]
  }, [
    props.nodeElement,
    props.variables,
    methods,
    containerRef.current,
    props.data,
  ])

  return (
    <Spin spinning={loading} delay={1000}>
      <CodeNodePanelWrapper>
        <JsonForm ref={formRef} list={list}></JsonForm>
        <div>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
      </CodeNodePanelWrapper>
    </Spin>
  )
}
