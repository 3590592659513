import classNames from 'classnames'
import { CloseCircleFilled } from '@ant-design/icons'
import { useRef, useState } from 'react'
import { IconFont, Input, Select } from '@/components'

import { TagDropdownSelect } from './components/TagDropdownSelect'

interface IDocumentsSearch {
  showExtraSearch: boolean
  selectOptions: { label: string; value: number }[]
  tagOptions: any[]
  searchInfo: {
    file_name: string
    file_status: number
    tags: string[]
  }
  placeholder?: string
  setSearchInfo: (params: {
    file_name: string
    file_status: number
    tags: string[]
  }) => void
  onSearchInfoChange: (params: Record<string, any>) => void
  onSearch: (arg: any) => void
}

export function DocumentsSearch(props: IDocumentsSearch) {
  const {
    showExtraSearch,
    selectOptions,
    tagOptions,
    onSearch,
    searchInfo,
    placeholder,
    setSearchInfo,
    onSearchInfoChange,
  } = props
  const isSearched = useRef(false)
  const [tagSelectOpen, setTagSelectOpen] = useState(false)

  const handleSearch = async (params: Record<string, any>) => {
    if (!isSearched.current) {
      await onSearch?.(params)
      isSearched.current = true
    }
  }

  return (
    <div className='flex flex-items-center flex-justify-between'>
      <div className='flex items-center'>
        <Input
          prefix={<IconFont name='search' className='text-15px' />}
          placeholder={placeholder ?? '搜索文档名称'}
          className={classNames('w-240px bg-[rgba(98,105,153,0.06)]', {
            'w-240px': showExtraSearch,
            'w-400px': !showExtraSearch,
          })}
          value={searchInfo.file_name}
          allowClear={{
            clearIcon: (
              <CloseCircleFilled
                onClick={() => {
                  isSearched.current = false
                  handleSearch({
                    ...searchInfo,
                    file_name: '',
                  })
                }}
              />
            ),
          }}
          onChange={e => {
            isSearched.current = false
            setSearchInfo({ ...searchInfo, file_name: e.target.value })
          }}
          onPressEnter={() => {
            handleSearch({ ...searchInfo })
          }}
          onBlur={() => {
            handleSearch({ ...searchInfo })
          }}
        />
        {showExtraSearch && (
          <>
            <TagDropdownSelect
              className='ml-12px min-w-140px max-w-240px h-36px rounded-6px text-14px'
              contentClassName='max-h-35vh overflow-y-auto'
              options={tagOptions}
              value={searchInfo.tags}
              allEnable
              onOpenChange={vis => {
                setTagSelectOpen(vis)
              }}
              placement='bottomLeft'
              triggerRender={() => {
                return (
                  <div className='max-w-full flex items-center overflow-hidden'>
                    <div
                      className={classNames(
                        'h-36px overflow-hidden w-auto min-w-140px max-w-240px text-14px line-height-36px flex items-center bg-[rgba(98,105,153,0.06)] rounded-6px py-8 px-11 font-400 max-w-100% w-fit cursor-pointer b-1px hover:b-primary b-transparent focus:b-primary',
                        {
                          '!b-primary': tagSelectOpen,
                        },
                      )}
                    >
                      {searchInfo?.tags.length === 0 && (
                        <span className='flex-1'>未选择标签</span>
                      )}
                      {searchInfo.tags.length !== 0 &&
                        searchInfo.tags.length === tagOptions.length && (
                          <span className='flex-1'>全部标签</span>
                        )}
                      {searchInfo.tags.length !== 0 &&
                        searchInfo.tags.length !== tagOptions.length && (
                          <span
                            className='truncate flex-1'
                            title={searchInfo.tags.join('，')}
                          >
                            标签：{searchInfo.tags.join('，')}
                          </span>
                        )}
                      <IconFont
                        name='arrow'
                        className='ml-5 c-font_1 text-8px'
                      />
                    </div>
                  </div>
                )
              }}
              onChange={e => {
                isSearched.current = false
                onSearchInfoChange({ tags: e })
              }}
            >
              <Select />
            </TagDropdownSelect>
            <Select
              placeholder='请选择或输入文档标签'
              className='ml-12px text-14px w-140px h-36px  rounded-6px'
              options={selectOptions}
              value={searchInfo.file_status}
              onChange={e => {
                isSearched.current = false
                onSearchInfoChange({ file_status: e })
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}
