import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import Axios from 'axios'
import { message as antdMessage } from 'antd'
import {
  API_STATUS_CODES,
  NO_APPLICATION_ID_API_LIST,
  NO_AUTH_API_LIST,
  REQUEST_TIMEOUT
} from '@/constants/request'
import { tokenStorage } from '@/utils/storage'
import { useApplicationStore, useWorkspaceStore } from '@/store'
import { BusinessError, handleGeneralBussinessError } from './error'

function logOnDev(message: string) {
  if (import.meta.env.MODE === 'development') console.log(message)
}

interface CustomRequestConfig {
  customErrorMessage: string
  ignoreError: boolean
  noRedirect?: boolean
  ignoreShowLimitedModal?: boolean
}
type RequestConfig = AxiosRequestConfig & CustomRequestConfig

function onRequest(config: InternalAxiosRequestConfig) {
  const { currentApplicationId } = useApplicationStore.getState()
  const { currentWorkspaceId } = useWorkspaceStore.getState()
  const token = tokenStorage.get()
  const workspaceId = currentWorkspaceId
  const applicationId = currentApplicationId

  if (token && !NO_AUTH_API_LIST.some(api => config.url?.includes(api))) {
    config.headers.Authorization = `${token}`
  }

  config.headers.Accept = 'application/json'
  config.headers['Workspace-Id'] = config.headers['Workspace-Id'] || workspaceId // ai

  const isIgnoreUrl = NO_APPLICATION_ID_API_LIST.findIndex(item => {
    return item.test(config.url || '')
  }) !== -1

  if (applicationId && !isIgnoreUrl) {
    config.headers['Application-Id'] =
      config.headers['Application-Id'] || applicationId
  }
  return config
}

const commonWarningMessage = '系统开小差了，请稍后再试'

function onResponse(response: AxiosResponse): AxiosResponse {
  const { method, url, customErrorMessage, ignoreError, ignoreShowLimitedModal} =
    response.config as RequestConfig

  const { status, headers } = response
  const { success, message, data, code } = response.data

  if (!success && !ignoreError) {
    antdMessage.warning(customErrorMessage || message || commonWarningMessage)
  }

  const isMockRes = !!headers['mock-response']
  logOnDev(
    `🚀 [API] ${
      isMockRes ? '[MOCK]' : ''
    } ${method?.toUpperCase()} ${url} | Response ${status}`,
  )

  if (!success) {
    if (!ignoreShowLimitedModal) {
      handleGeneralBussinessError(code)
    }
    throw new BusinessError(message || commonWarningMessage, code, status)
  }

  return data as any
}

function onErrorResponse(error: AxiosError): Promise<AxiosError> {
  const { message } = error
  const {
    method,
    url,
    customErrorMessage,
    ignoreError,
    noRedirect = false,
  } = error.config as RequestConfig
  const { status } = (error.response as AxiosResponse) ?? {}

  const handleNoRedirect = decodeURIComponent(window.location.search)?.includes('noRedirect=true')
  if(handleNoRedirect){
    localStorage.setItem('noRedirect', 'true')
  }
  const storageNoRedirect = localStorage.getItem('noRedirect') // 邀请开发者时候不需要直接跳转到没权限的页面而是弹出申请权限的弹窗
  logOnDev(
    `🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`,
  )

  const { message: resMessage } = (error.response?.data || {} as any)

  !ignoreError && storageNoRedirect !== 'true' &&
    antdMessage.warning(
      resMessage || message || customErrorMessage || commonWarningMessage,
    )

  if (
    status === API_STATUS_CODES.REQUEST_NOT_AUTH &&
    !NO_AUTH_API_LIST.some(api => url?.includes(api)) &&
    !noRedirect
  ) {
    tokenStorage.clear()
    const redirectParams = `redirect=${encodeURIComponent(
      window.location.pathname + window.location.search,
    )}`
    window.location.replace(
      `/login${
        window.location.search
          ? `${window.location.search}&${redirectParams}`
          : `?${redirectParams}`
      }`,
    )
  } else if (status === API_STATUS_CODES.APPLICATION_NOT_AUTH && !noRedirect) {
    if (storageNoRedirect !== 'true') {
      window.location.replace('/notAllowed')
    }
  }

  return Promise.reject(error)
}

function setupInterceptors(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.request.use(onRequest, onErrorResponse)
  instance.interceptors.response.use(onResponse, onErrorResponse)

  return instance
}

// AI服务
export const request = Axios.create({
  baseURL:
    import.meta.env.MODE === 'development'
      ? '/api/'
      : import.meta.env.VITE_AI_API_BASE_URL,
  timeout: REQUEST_TIMEOUT,
})

export const userCenterRequest = Axios.create({
  baseURL:
    import.meta.env.MODE === 'development'
      ? '/user-center/'
      : import.meta.env.VITE_USER_CENTER_BASE_URL,
  timeout: REQUEST_TIMEOUT,
})

export const dbRequest = Axios.create({
  baseURL: import.meta.env.MODE === 'development' ? '/db-server/' : import.meta.env.VITE_DB_API_BASE_URL,
  timeout: REQUEST_TIMEOUT * 5,
})

// 数据集服务
export const datasetRequest = Axios.create({
  baseURL: import.meta.env.MODE === 'development' ? '/mebsuta/api/' : `${import.meta.env.VITE_DATASET_API_BASE_URL}/mebsuta/api/`,
  timeout: REQUEST_TIMEOUT * 5,
})

setupInterceptors(request)
setupInterceptors(userCenterRequest)
setupInterceptors(dbRequest)
setupInterceptors(datasetRequest)

