import { type FC } from 'react'
import { Form, Switch } from 'antd'
import type { FieldExtraItem, FieldItem } from '..'
import { FieldTypes, SelectViewMode } from '..'
import { type PatchVariableChangeOptions } from '@/features/nodes/base'
import { Button, IconFont, Input } from '@/components'
import { NodeFormItem } from '@/features/nodes/components'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { SelectViewModeConfig } from '@/features/nodes/start/components/SelectViewModeConfig.tsx'
import { OptionsConfig } from './OptionsConfig'
import { FieldTypeSelect } from './FieldTypeSelect'
import { FieldsFileTypeSelect } from './FieldsFileTypeSelect'

export interface FieldEditProps {
  isEdit: boolean
  showError?: boolean
  editItem?: FieldItem & FieldExtraItem
  onCancel: () => void
  onConfirm: (
    item: FieldItem & FieldExtraItem,
    patchVariableChangeOptions?: PatchVariableChangeOptions,
  ) => void
  variables: string[]
}

export const FieldEdit: FC<FieldEditProps> = props => {
  const { variables, showError } = props
  const supportFileConfigList = useNodeMetaStore(
    state => state.supportFileConfigList,
  )

  const getInitialValue = () => {
    if (props.isEdit) {
      if (props.editItem) {
        const isSelectFlag = [
          FieldTypes.Select,
          FieldTypes.MultiSelect,
        ].includes(props.editItem.type as any)

        const placeholderParams =
          props.editItem?.type === FieldTypes.File
            ? {
                file_placeholder: props.editItem?.placeholder,
                placeholder: '',
              }
            : {
                placeholder: props.editItem?.placeholder,
              }
        return {
          ...props.editItem,
          ...placeholderParams,
          viewMode: isSelectFlag
            ? (props.editItem.viewMode ?? SelectViewMode.SELECT)
            : undefined,
        }
      }
    }

    return {
      type: FieldTypes.Input,
      placeholder: '',
      viewMode: SelectViewMode.SELECT,
    }
  }

  const [form] = Form.useForm<FieldItem & { file_placeholder?: string }>()

  const onSave = () => {
    form.validateFields().then(value => {
      if (
        value.type === FieldTypes.Select ||
        value.type === FieldTypes.MultiSelect
      ) {
        value.options =
          value.options?.map(opt => ({ label: opt.label, value: opt.label })) ??
          []
      }

      const isPatch =
        props.isEdit &&
        props.editItem?.variableName !== value.variableName &&
        Boolean(props.editItem?.variableName)
      const patchVariableOptions = isPatch
        ? {
            newVariableName: value.variableName,
            oldVariableName: props.editItem?.variableName as string,
          }
        : undefined

      const placeholder =
        value.type === FieldTypes.File
          ? value.file_placeholder
          : value.placeholder
      const supportFileConfig = form.getFieldValue('supportFileConfig') || {}
      const extraConfig =
        value.type === FieldTypes.File ? { supportFileConfig } : {}

      const params = { ...value, ...extraConfig, placeholder }
      delete params.file_placeholder

      props.onConfirm(params, patchVariableOptions)
      if (props.isEdit && props.editItem?.variableName !== value.variableName) {
        // 更换变量名称
      }
    })
  }

  const onFieldTypeChange = (type: string) => {
    if (type === FieldTypes.Select || type === FieldTypes.MultiSelect) {
      const viewMode = form.getFieldValue('viewMode')
      if (!viewMode) {
        form.setFieldValue('viewMode', SelectViewMode.SELECT)
      }
    }
  }

  const onFieldFileTypeChange = (fileTypes: string[]) => {
    const currentFileTypeConfig = supportFileConfigList.filter(i =>
      fileTypes.includes(i.type),
    )
    form.setFieldValue('supportFileTypes', fileTypes) // 保持原先结构
    form.setFieldValue(
      'file_placeholder',
      `请上传${currentFileTypeConfig?.[0]?.name || ''}文件`,
    )
    form.setFieldValue('supportFileConfig', currentFileTypeConfig)
  }
  return (
    <div>
      {showError && (
        <div className='bg-error bg-op-12 flex-center c-error'>
          <div className='py-10 text-12px flex'>
            <IconFont name='shuimeng' />
            <span className='ml-6'>请保存信息</span>
          </div>
        </div>
      )}
      <div className='p-16'>
        <div className='flex items-center justify-between'>
          <Button
            onClick={props.onCancel}
            className='w-56px important:h-28px important:rounded-6px important:py-0 text-12px important:px-0'
          >
            上一步
          </Button>
          <Button
            className='w-56px important:h-28px important:rounded-6px important:py-0 text-12px important:px-0'
            type='primary'
            onClick={onSave}
          >
            保存
          </Button>
        </div>
        <Form
          form={form}
          initialValues={getInitialValue()}
          layout='vertical'
          requiredMark={false}
          className='mt-16 nopan'
        >
          <NodeFormItem name='type' label='字段类型'>
            <FieldTypeSelect onChange={onFieldTypeChange} />
          </NodeFormItem>

          <NodeFormItem
            name='label'
            label='字段名称'
            required
            rules={[{ required: true, message: '字段名称不能为空' }]}
          >
            <Input
              size='small'
              showCount
              maxLength={20}
              placeholder='请输入字段名称'
            />
          </NodeFormItem>

          <NodeFormItem
            name='variableName'
            tooltip='定义字段的唯一标识，后续节点通过该名称访问该字段的内容'
            label='变量名称'
            required
            rules={[
              { required: true, message: '变量名称不能为空' },
              {
                pattern: /^[a-zA-Z_$][a-zA-Z0-9_$]*$/,
                message: '变量名称不能包含特殊字符',
              },
              {
                validator: (_, value) =>
                  variables.includes(value)
                    ? Promise.reject(new Error('变量名称已存在'))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input
              size='small'
              placeholder='请输入变量名称，仅限英文字符'
              showCount
              maxLength={20}
            />
          </NodeFormItem>

          <Form.Item
            noStyle
            shouldUpdate={(prevValue, nextValue) =>
              prevValue.type !== nextValue.type
            }
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('type')
              if (
                type === FieldTypes.Select ||
                type === FieldTypes.MultiSelect
              ) {
                return (
                  <NodeFormItem label='选项'>
                    <OptionsConfig />
                  </NodeFormItem>
                )
              }
              return null
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValue, nextValue) =>
              prevValue.type !== nextValue.type
            }
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('type')
              return (
                <>
                  {type === FieldTypes.File && (
                    <NodeFormItem
                      name='supportFileTypes'
                      label='支持格式'
                      required
                    >
                      <FieldsFileTypeSelect onChange={onFieldFileTypeChange} />
                    </NodeFormItem>
                  )}
                  {[FieldTypes.Select, FieldTypes.MultiSelect].includes(
                    type,
                  ) && (
                    <NodeFormItem name='viewMode' label='展示形式' required>
                      <SelectViewModeConfig />
                    </NodeFormItem>
                  )}
                </>
              )
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValue, nextValue) =>
              prevValue.type !== nextValue.type
            }
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('type')
              return (
                <>
                  {type === FieldTypes.File ? (
                    <NodeFormItem
                      // 中间状态， 为了处理file的固定placeholder以及其他type的placeholder
                      name='file_placeholder'
                      label='占位符'
                      dependencies={['type']}
                    >
                      <Input
                        size='small'
                        placeholder='请输入占位符'
                        showCount
                        maxLength={20}
                      />
                    </NodeFormItem>
                  ) : (
                    <NodeFormItem
                      name='placeholder'
                      label='占位符'
                      dependencies={['type']}
                    >
                      <Input
                        size='small'
                        placeholder='请输入占位符'
                        showCount
                        maxLength={20}
                      />
                    </NodeFormItem>
                  )}
                </>
              )
            }}
          </Form.Item>
          <NodeFormItem className='mb-0!' name='required'>
            <FormSwitchItem />
          </NodeFormItem>
        </Form>
      </div>
    </div>
  )
}

function FormSwitchItem(props: {
  value?: boolean
  onChange?: (value: boolean) => void
}) {
  return (
    <div className='flex items-center'>
      <span className='text-12px font-500'>是否必填</span>
      <Switch
        size='small'
        checked={props.value}
        onChange={props.onChange}
        className='ml-12'
      />
    </div>
  )
}
