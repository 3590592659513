import CryptoJS from 'crypto-js'

const DES_SECRET = 'keyu_qweasdzxc'

// DES解密
export function decryptDes(message: string, key = DES_SECRET) {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const decrypted = CryptoJS.DES.decrypt(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    {
      ciphertext: CryptoJS.enc.Base64.parse(message),
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  )

  return decrypted.toString(CryptoJS.enc.Utf8)
}
