import type { AgentConfigShortcut, Shortcut } from '@bty/chat-types'
import type { SelectProps } from 'antd'

export const TYPE_OPTIONS: SelectProps['options'] = [
  {
    label: '插件',
    value: 'utility',
  },
  {
    label: '工作流',
    value: 'flows',
  },
  {
    label: '指令',
    value: 'prompt',
  },
]

export const DEFAULT_SHORTCUTS: AgentConfigShortcut = {
  type: 'utility',
  function_id: '',
  short_name: '',
  hidden_parameters: [],
}

export const DEMO_SHORTCUT: Shortcut = {
  title: '快捷按钮',
  type: 'flow',
  color: '#FFA500',
  icon: 'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/agent/plugins/web.png',
  formConfig: [],
  meta: {
    type: 'flows',
    function_id: '',
    short_name: '快捷按钮',
    hidden_parameters: [],
  },
}

export const PROMPT_SHORTCUT: Shortcut = {
  title: '',
  type: 'prompt',
  color: '#FFA500',
  icon: '📝',
  formConfig: [],
  meta: {
    type: 'prompt',
    function_id: '',
    short_name: '',
    hidden_parameters: [],
  },
}
