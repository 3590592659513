import { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row, Tabs } from 'antd'
import styled from '@emotion/styled'
import { usePageModal } from '@/components'
import { RunTypeGroupSelect } from '@/features/analyze/components/RunTypeGroupSelect'
import {
  FlowRunTypeGroup,
  AgentRunTypeGroup,
} from '@/features/analyze/constants'
import { useApplicationStore, useWorkspaceStore } from '@/store'
import { getFormalApplicationId, queryUsageTrend } from '@/apis/monitor'
import type { ChartDashboardPaneRef } from '../../../features/agent/AgentAnalysis/ChartDashboardPane'
import { ChartDashboardPane } from '@/features/agent/AgentAnalysis/ChartDashboardPane'
import { MonitorOverviewPane } from '@/features/analyze/components/MonitorOverviewPane'
import { WorkspacePointTrend } from './components/WorkspacePointTrend'
import { WorkspacePointCard } from './components/WorkspacePointCard'
import { UsageWorkspaceUsageRankPane } from './components/UsageRankPane'
import { WorkspaceStabilityPane } from './components/WorkspaceStabilityPane'

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    border: transparent;
  }
`
export enum StatisticsFlowType {
  AGENT = 'AGENT',
  FLOW = 'FLOW',
}

export function UsageStatistics() {
  const [flowType, setFlowType] = useState<StatisticsFlowType>(
    StatisticsFlowType.AGENT,
  )
  const [runType, setRunType] = useState<string[]>([])
  const { setCurrentApplication } = useApplicationStore()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const logPageModal = usePageModal()
  const openLogPage = async (flowId: string) => {
    const { app_id } = await getFormalApplicationId(flowId)
    if (app_id) {
      const url =
        flowType === StatisticsFlowType.AGENT
          ? `/agent/${app_id}/log?run_types=${runType.join(',')}`
          : `/application/${app_id}?tab=log`
      logPageModal.show({
        url,
      })
    }
  }

  const trendingTableRef = useRef<ChartDashboardPaneRef>(null)
  const errorTableRef = useRef<ChartDashboardPaneRef>(null)

  useEffect(() => {
    trendingTableRef.current?.refresh()
    errorTableRef.current?.refresh()
  }, [currentWorkspaceId])

  useEffect(() => {
    setCurrentApplication('')
  }, [])

  const trendTableParams = useMemo(
    () => ({
      flow_type: flowType,
      statistic_time: 'weekly',
      display_time: 'daily',
      run_type: runType,
    }),
    [flowType, runType],
  )

  return (
    <div className='adapt:px-64 h-full overflow-scroll'>
      <Row
        gutter={24}
        className='overflow-auto flex items-stretch'
        wrap={false}
      >
        <Col span={8} style={{ minWidth: 360 }}>
          <WorkspacePointCard />
        </Col>
        <Col span={16} style={{ minWidth: 720 }}>
          <WorkspacePointTrend />
        </Col>
      </Row>
      <div className='my-24px'>
        <StyledTabs
          className='mr-16px'
          items={[
            {
              label: 'Agent',
              key: StatisticsFlowType.AGENT,
            },
            {
              label: '工作流',
              key: StatisticsFlowType.FLOW,
            },
          ]}
          activeKey={flowType}
          size='middle'
          onChange={value => {
            setFlowType(value as StatisticsFlowType)
            setRunType([])
          }}
        ></StyledTabs>
        <RunTypeGroupSelect
          data={
            flowType === StatisticsFlowType.AGENT
              ? AgentRunTypeGroup
              : FlowRunTypeGroup
          }
          onTypeChange={value => {
            setRunType(value)
          }}
        />
        <span className='text-14px/14px c-#9E9E9E ml-12px'>
          除「今日用量」外，其他统计指标均不包含当日的用量数据。
        </span>
      </div>
      <div className='flex items-center gap-24px w-full'>
        <MonitorOverviewPane
          flowType={flowType}
          runType={runType}
          onLogJump={false}
        />
      </div>
      <div>
        <ChartDashboardPane
          className='mt-24'
          ref={trendingTableRef}
          type='area'
          xField='display_time'
          yField='value'
          title='用量趋势'
          color={['#7B61FF', '#5784FD', '#2FBDF6']}
          filter
          showTooltip
          service={{
            call: queryUsageTrend,
            params: trendTableParams,
          }}
        />
        <UsageWorkspaceUsageRankPane
          runType={runType}
          flowType={flowType}
          openLogPage={openLogPage}
        />
        <WorkspaceStabilityPane
          runType={runType}
          flowType={flowType}
          openLogPage={() => {}}
        />
      </div>
    </div>
  )
}
