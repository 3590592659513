import { useCallback } from 'react'
import { useNodeStore } from '@/store'
import { NodeType, type NodeMeta } from '@/features/nodes/base'

export function useNodePanel(
  nodeId: string,
  meta: NodeMeta,
  data: Record<string, unknown>,
) {
  const activateNode = useNodeStore(state => state.activateNode)
  const activatedNodeId = useNodeStore(state => state.activatedNodeId)

  const deactivateNode = useNodeStore(state => state.deactivateNode)

  const onClosePanel = () => deactivateNode()

  const onOpenPanel = useCallback(() => {
    if ([NodeType.LLM_BATCH, NodeType.LOOP].includes(meta.type)) return
    activateNode(nodeId, meta, data)
  }, [data, nodeId, activateNode, activatedNodeId, onClosePanel])

  return {
    onClosePanel,
    onOpenPanel,
  }
}
