import { useState } from 'react'
import { Modal } from '@/components'
import customerServiceQRCode from '@/assets/customerServiceQRCode.png'

export default ({ children }: { children: React.ReactNode }) => {
  const [showInvoicingModal, setShowInvoicingModal] = useState(false)
  return (
    <>
      <div onClick={() => setShowInvoicingModal(true)}>{children}</div>
      <Modal
        title='申请开票'
        width={400}
        open={showInvoicingModal}
        footer={<></>}
        onCancel={() => setShowInvoicingModal(false)}
      >
        <div className='flex flex-col flex-items-center text-14px c-font'>
          <div>如需开票，请扫码联系客服</div>
          <img
            className='h-126px w-126px mt-32px mb-12px'
            src={customerServiceQRCode}
          />
          <div className='mb-30px'>微信扫一扫</div>
        </div>
      </Modal>
    </>
  )
}
