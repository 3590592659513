import { usePageModal } from '.'

export interface IframeLinkProps {
  href: string
  children?: React.ReactNode
  className?: string
}

export function PageLink({ href, children, className }: IframeLinkProps) {
  const url = href.startsWith('http') ? href : `${location.origin}${href}`
  const modal = usePageModal()

  const handleOpen = () => {
    modal.show({
      url,
    })
  }
  return (
    <a onClick={handleOpen} className={className}>
      {children}
    </a>
  )
}
