import { NodeType } from './types'

export const controlStyle = {
  background: 'transparent',
  border: 'none',
  left: 'auto',
  top: 'auto',
  right: '10px',
  bottom: '10px',
}

export const fullscreenNodePanelType = [
  NodeType.LLM,
  NodeType.PYTHON,
  NodeType.JAVASCRIPT,
  NodeType.TEMPLATE,
]
