import type { TreeSelectProps } from 'antd'
import { TreeSelect } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useControllableValue } from 'ahooks'
import styled from '@emotion/styled'
import { IconFont } from '@/components'

interface RunTypeGroupSelectProps {
  data: TreeSelectProps['treeData']
  onTypeChange?: (value: string[]) => void
  onChange?: (value: string[]) => void
  value?: string[]
}

const StyledTreeSelect = styled(TreeSelect)`
  .ant-select-selector {
    padding: 4px 24px 4px 4px !important;
    background: rgba(98, 105, 153, 0.06) !important;
    box-shadow: none !important;
    border: none !important;
    gap: 4px;
  }

  .ant-select-selection-overflow-item,
  .ant-select-selection-item {
    margin: 0 !important;
  }
  .ant-select-selection-item {
    height: 28px !important;
    .ant-select-selection-item-content {
      line-height: 28px;
    }
    margin-right: 4px !important;
  }
`

function getFirstOptionKey(data: TreeSelectProps['treeData']) {
  if (!data) return null
  const firstGroup = data[0]
  return firstGroup.children?.length
    ? firstGroup.children[0].value
    : firstGroup.value
}

function getAllKeys(data: NonNullable<TreeSelectProps['treeData']>) {
  return data.reduce((prev, cur) => {
    return prev.concat(cur.children ? cur.children.map(item => item.value) : [])
  }, [])
}

export function RunTypeGroupSelect(props: RunTypeGroupSelectProps) {
  const { data, onTypeChange } = props
  const [value, setValue] = useControllableValue<string[]>(props)
  const [allSelected, setAllSelected] = useState(false)

  const optionsLength = useMemo(() => {
    if (!data) return 0
    return data.reduce(
      (pre, cur) => pre + (cur.children ? cur.children.length : 0),
      0,
    )
  }, [data])

  useEffect(() => {
    if (data) {
      const allKeys = getAllKeys(data)
      setValue(allKeys as string[])
      setAllSelected(true)
    }
  }, [data])

  return (
    <StyledTreeSelect
      className='min-w-140px h-36px'
      popupClassName='[&_.ant-select-tree-switcher-icon>svg]:align-baseline [&_.ant-select-tree-checkbox]:rd-4px! [&_.ant-select-tree-checkbox-inner]:rd-4px!'
      treeData={data}
      value={value}
      maxTagPlaceholder={props => {
        return `+${props.length}`
      }}
      tagRender={tagProps => {
        if (allSelected) {
          return tagProps.value === getFirstOptionKey(data) ? (
            <span className='ant-select-selection-item'>
              <span className='ant-select-selection-item-content'>
                全部运行方式
              </span>
            </span>
          ) : (
            <></>
          )
        }
        return (
          <div className='ant-select-selection-item'>
            <div
              className='ant-select-selection-item-content'
              title={
                typeof tagProps.label === 'string' ? tagProps.label : undefined
              }
            >
              {tagProps.label}
            </div>
          </div>
        )
      }}
      maxTagCount={allSelected ? undefined : 4}
      maxTagTextLength={5}
      suffixIcon={
        <IconFont name='arrow' className='text-7px text-font_1 mr-2px' />
      }
      popupMatchSelectWidth={250}
      showSearch={false}
      treeDefaultExpandAll
      treeCheckable
      onChange={(value: any) => {
        setAllSelected(value.length === optionsLength)
        if (value.length === 0 && data) {
          const allKeys = getAllKeys(data)
          setValue(allKeys as string[])
          setAllSelected(true)
          onTypeChange?.(value)
          return
        }
        setValue(value)
        onTypeChange?.(value)
      }}
    />
  )
}
