import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import type { useGroup } from '@/hooks/useGroup'
import { DragDiv } from '@/hooks/useGroup'
import { IconFont } from '@/components'
import type { GroupType } from '@/apis/application'
import { PartitionCategoryType } from '@/apis/datastore/types'
import type { CreateType } from '@/features/datastore/constant'
import { NewGroupModal } from '@/features/home/NewGroupModal'

interface DatastoreListGroupHeaderProps {
  data: GroupType
  collapsed: boolean
  draggable: boolean
  showDragHandle: boolean
  showDragOverlay: boolean
  showMoreActions: boolean
  dropdownComponent: ReturnType<typeof useGroup>['DropdownMenu']
  onDragTypeChange: (type: 'sort' | 'join' | null) => void
  onDragStart: (args: {
    DragType: 'sort' | 'join' | null
    item: GroupType
    e: React.DragEvent<any>
  }) => void
  onDragEnd: VoidFunction
  onClick: (groupId: string) => void
  onCreate: (
    type: PartitionCategoryType,
    createType?: CreateType,
    groupId?: string,
  ) => void
  onRename: (args: { id: string; AppGroup: { name: string } }) => Promise<void>
}

function InternalDatastoreListGroupHeader({
  data,
  collapsed,
  draggable,
  showDragHandle,
  showDragOverlay,
  showMoreActions,
  dropdownComponent: Dropdown,
  onDragTypeChange,
  onDragStart,
  onDragEnd,
  onClick,
  onCreate,
  onRename,
}: DatastoreListGroupHeaderProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [groupModalOpen, setGroupModalOpen] = useState(false)

  const initialValues = useMemo(
    () => ({
      groupName: data.name,
    }),
    [data.name],
  )

  return (
    <>
      <DragDiv
        className='group-item-header cursor-pointer flex gap-8 items-center relative py-20'
        open={dropdownOpen}
        onClick={() => onClick(data.id)}
      >
        {showDragHandle && (
          <span
            className='dragBtn cursor-grab absolute -left-24px pr-8'
            draggable={draggable}
            onMouseDown={() => onDragTypeChange('join')}
            onMouseUp={() => onDragTypeChange(null)}
            onDragStart={e => onDragStart({ item: data, DragType: 'sort', e })}
            onDragEnd={onDragEnd}
          >
            {showDragOverlay ? (
              <span className='inline-block bg-primary px-24 py-12 text-14px font-semibold rounded-6px text-white'>
                移动一个分组
              </span>
            ) : (
              <IconFont
                className='text-16px text-bg_3 text-op-60'
                name='drag'
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            )}
          </span>
        )}
        {!showDragOverlay && (
          <div className='cursor-pointer flex items-center gap-4'>
            <IconFont
              className={classNames('transition-all duration-200', {
                'rotate-270': collapsed,
              })}
              name='arrow-1'
            />
            <span className='text-16px font-semibold'>{data.name}</span>
          </div>
        )}
        {showMoreActions && (
          <Dropdown
            open={dropdownOpen}
            item={data}
            title='知识库'
            subMenu={[
              {
                key: PartitionCategoryType.Document,
                label: '新建文档知识库',
                className: 'h-32px !px-10',
              },
              {
                key: PartitionCategoryType.QA,
                label: '新建 QA 知识库',
                className: 'h-32px !px-10',
              },
            ]}
            openChange={val => setDropdownOpen(!!val)}
            createAppToAgent={key => {
              if (key) {
                onCreate(key as PartitionCategoryType, undefined, data.id)
              }
            }}
            handleReName={() => {
              setGroupModalOpen(true)
            }}
          />
        )}
      </DragDiv>
      {showMoreActions && (
        <NewGroupModal
          open={groupModalOpen}
          initValue={initialValues}
          title='分组重命名'
          onOk={async name => {
            await onRename({
              id: data.id,
              AppGroup: { name },
            })
            setGroupModalOpen(false)
          }}
          onCancel={() => {
            setGroupModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export const DatastoreListGroupHeader = React.memo(
  InternalDatastoreListGroupHeader,
)
