import type { Bot } from '@bty/chat-types'

const DEFAULT_ROBOT_AVATAR =
  'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-1.png'

export function getAvatarFromBot(bot: Bot) {
  if (bot.type === 'AI-FLOW') {
    return bot.icon
  }
  return bot?.icon?.startsWith('http') ? bot?.icon : DEFAULT_ROBOT_AVATAR
}
