import type { FC, PropsWithChildren, ReactElement } from 'react'
import { cloneElement } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import type { FieldControl, FormSchema, MetaState } from '@bty/ui-components'
import { IconFont } from '@/components'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'

interface WithAction {
  onDelete: (index: number) => void
  onEdit: (index: number) => void
}

export interface FormItemBaseProps {
  schema: FormSchema
  meta: MetaState
  control: FieldControl
}

type IFormItem = FC<
  PropsWithChildren<
    {
      label: string
      required: boolean
      variableName: string
      index: number
    } & WithAction & {
        dragHandleProps?: DraggableProvidedDragHandleProps | null
      }
  >
>
export const FormItem: IFormItem = props => {
  const { label, required, index, variableName } = props
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const locked = lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK

  return (
    <div className='flex mb-[16px] flex-col items-center justify-between w-full'>
      <div className='flex pb-[8px] items-center justify-between w-full'>
        <div className='flex items-center ml-[-16px] overflow-inherit'>
          <span
            {...props.dragHandleProps}
            className='flex items-center justify-center w-[16px] h-[16px] cursor-move'
          >
            <IconFont name='drag' className='text-font_1 text-[15px]' />
          </span>
          <span className='text-[12px] font-medium'>{label}</span>
          {required && (
            <span className='color-error ml-5 text-16px relative top-3px'>
              *
            </span>
          )}
          <span className='text-[12px] px-4 py-2 rounded-[4px] bg-primary bg-opacity-6 border border-solid border-opacity-24 text-primary ml-5'>
            {variableName}
          </span>
        </div>
        <div className='flex items-center'>
          <span
            onClick={() => !locked && props.onEdit(index)}
            className={classNames(
              'cursor-pointer flex items-center justify-center w-24px text-[#626999] text-opacity-60 h-24px inline-block rounded-4px hover:bg-bg_3 hover:bg-opacity-12',
              { 'cursor-not-allowed!': locked },
            )}
          >
            <IconFont name='shezhi' className='text-[16px]' />
          </span>
          <span
            onClick={() => !locked && props.onDelete(index)}
            className={classNames(
              'ml-4 cursor-pointer flex items-center justify-center w-24px text-[#626999] text-opacity-60 h-24px inline-block rounded-4px hover:bg-error hover:bg-opacity-12 hover:text-error',
              { 'cursor-not-allowed!': locked },
            )}
          >
            <IconFont name='shanshu' className='text-[16px]' />
          </span>
        </div>
      </div>
      <div className='w-full'>{props.children}</div>
    </div>
  )
}

export type IFieldFormItemWithAction = FC<
  PropsWithChildren<FormItemBaseProps & WithAction>
>

export const FieldFormItemWithDraggableAndAction: IFieldFormItemWithAction =
  props => {
    const ClonedChildren = cloneElement(props.children as ReactElement, {
      ...props.control,
      disabled: props.meta.disabled,
    })

    return (
      <Draggable
        key={props.schema.name}
        draggableId={props.schema.name}
        index={props.meta.index}
      >
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <FormItem
              onEdit={props.onEdit}
              onDelete={props.onDelete}
              required={!!props.schema.required}
              label={props.schema.label}
              variableName={props.schema.variableName}
              index={props.meta.index}
              dragHandleProps={provided.dragHandleProps}
            >
              {ClonedChildren}
            </FormItem>
          </div>
        )}
      </Draggable>
    )
  }
