import classNames from 'classnames'
import { IconFont } from '@/components'
import type { SidebarMenuItemData } from '../contants'

export function transformMenuData(
  data: SidebarMenuItemData[],
  pathname: string,
) {
  const allMenus = data.map(({ path, title, icon, iconColor, group }) => ({
    path,
    label: title,
    icon: (
      <IconFont
        name={icon}
        className={classNames('text-16px text-#626999', {
          'text-op-60': path !== pathname,
        })}
        style={path === pathname ? { color: iconColor } : {}}
      />
    ),
    group,
  }))
  const topMenus = allMenus.filter(item => item.group === 'top')
  const bottomMenus = allMenus.filter(item => item.group === 'bottom')
  return {
    topMenus,
    bottomMenus,
  }
}
