import {
  InsertMemoryNode,
  InsertMemoryNodePanel,
} from '@/features/nodes/insert-memory'
import type {
  NodeComponent,
  NodePanelComponent,
  NodePanelHeader,
  NodeType,
} from './base'
import { withConnectFlowNode } from './base'

import { APINode, ApiNodePanel } from './api'
import { ApplicationNode } from './application'
import {
  CodeNodePanel,
  JavaScriptCodeNode,
  PythonCodeNode,
  CodeNodePanelHeader,
} from './code'
import { EmptyNode } from './empty'
import { EndNode } from './end'
import { LLMNode, LLMNodePanel } from './llm'
import { TextNode, TextNodePanel } from './text'
import { MemoryNode, MemoryNodePanel } from './memory'
import { PluginNode, PluginPanelNode } from './plugin'
import { StartNode } from './start'
import { SubFlowNode, SubFlowPanelNode } from './subflow'

import { ConditionNode } from './condition'
import { ConditionResultNode } from './condition/ConditionResultNode'

import { IntentNode, IntentPanelNode } from './intent'
import { IntentResultNode } from './intent/IntentResultNode'

import { KnowledgeNode, KnowledgeNodePanel } from './knowledge'
import { LoopNode, LoopResultNode } from './loop'
import { LLMBatchNode } from './lllm-batch'
import { DatabaseNode, DatabaseNodePanel } from './database'
import { TemplateNode, TemplateNodePanel } from './template'

// 有循环依赖的问题，这里先改成函数延迟执行
const originNodeList: () => [
  NodeComponent,
  NodePanelComponent?,
  NodePanelHeader?,
][] = () => [
  [StartNode],
  [LLMNode, LLMNodePanel],
  [TextNode, TextNodePanel],
  [MemoryNode, MemoryNodePanel],
  [InsertMemoryNode, InsertMemoryNodePanel],
  [PythonCodeNode, CodeNodePanel, CodeNodePanelHeader],
  [JavaScriptCodeNode, CodeNodePanel, CodeNodePanelHeader],
  [APINode, ApiNodePanel],
  [EndNode],
  [ApplicationNode],
  [SubFlowNode, SubFlowPanelNode],
  [EmptyNode],
  [ConditionNode],
  [ConditionResultNode],

  [IntentNode, IntentPanelNode],
  [IntentResultNode],

  [PluginNode, PluginPanelNode],
  [KnowledgeNode, KnowledgeNodePanel],
  [LoopNode],
  [LoopResultNode],
  [DatabaseNode, DatabaseNodePanel],
  [LLMBatchNode],
  [TemplateNode, TemplateNodePanel],
]

export function getNodeMetaByType(type: NodeType) {
  return originNodeList().find(([node]) => node.meta.type === type)?.[0].meta
}

export function initFlowNodes() {
  return originNodeList().map(([node, panel, panelHeader]) => ({
    node: withConnectFlowNode(node),
    meta: node.meta,
    panel,
    panelHeader,
  }))
}
