import { memo } from 'react'
import classNames from 'classnames'
import { IconFont } from '@/components'
import theme from '@/constants/theme'
import { useLimitedModal } from '../LimitedPanel'
import { useVersion } from '@/hooks/useVersion'
import { useContactCustomerServiceModal } from '../ContactCustomerServiceModal'
import { Version } from '../const'
import { useAlertInfo } from './hooks/useAlertInfo'

interface LimitedAlertBarProps {
  className?: string
}

interface LimitedAlertContentProps {
  prefix: string
  /**
   * 是否需要检查是否是团队版
   */
  shouldCheckTeamVersion?: boolean
}

export function LimitedAlertContent({
  prefix = '',
  shouldCheckTeamVersion = false,
}: LimitedAlertContentProps) {
  const { alertInfo } = useAlertInfo()
  const { isTeam } = useVersion()
  const [modal] = useLimitedModal()
  const [contactCustomerServiceModal] = useContactCustomerServiceModal()

  if (shouldCheckTeamVersion) {
    return isTeam ? (
      <span>
        {`${prefix}，请升级到企业版 `}
        <a
          className='text-primary cursor-pointer underline'
          onClick={e => {
            e.stopPropagation()
            modal.open()
          }}
        >
          购买
        </a>
      </span>
    ) : null
  }

  return (
    <>
      {alertInfo?.type === 'expired' &&
        (alertInfo.isAdmin ? (
          <span>
            {prefix}
            {`「${alertInfo.workspaceName}」已经过期，请购买以继续使用。`}
            <a
              style={{
                color: theme.colors.primary,
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation()
                modal.open()
              }}
            >
              购买
            </a>
          </span>
        ) : (
          `${prefix}「${alertInfo.workspaceName}」已经过期，请联系管理员「${alertInfo.adminName}」续费`
        ))}
      {alertInfo?.type === 'exhausted' &&
        (alertInfo.isAdmin ? (
          <span>
            {prefix}
            {`「${alertInfo.workspaceName}」积分已耗尽，请`}
            <a
              style={{
                color: theme.colors.primary,
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation()
                contactCustomerServiceModal.open()
              }}
            >
              联系客服
            </a>
          </span>
        ) : (
          `${prefix}「${alertInfo.workspaceName}」积分已经消耗尽，请联系管理员「${alertInfo.adminName}」续费`
        ))}
    </>
  )
}

function LimitedAlertBarContent(props: LimitedAlertBarProps) {
  const { className } = props

  const [modal] = useLimitedModal()
  const [contactCustomerServiceModal] = useContactCustomerServiceModal()

  const { alertInfo, role } = useAlertInfo()

  return (
    <div style={{ display: alertInfo ? 'block' : 'none' }}>
      <div
        className={classNames(
          'py-19px px-24px b-1 b-#FF5219 b-op-48 b-rd-8px w-100% box-border flex items-center',
          className,
        )}
        style={{
          background:
            'linear-gradient(0deg, rgba(255, 82, 25, 0.08), rgba(255, 82, 25, 0.08)), #FFFFFF',
        }}
      >
        <IconFont name='yunxingyibai' className='font-size-16px mr-12px' />
        {alertInfo?.type === 'expired' &&
          (alertInfo.isAdmin ? (
            <span>
              {`「${alertInfo.workspaceName}」已经过期，请购买以继续使用。`}
              <a
                className='text-primary cursor-pointer underline'
                onClick={e => {
                  e.stopPropagation()
                  if (alertInfo?.product_code === Version.ENTERPRISE) {
                    contactCustomerServiceModal.open()
                  } else {
                    modal.open({ role })
                  }
                }}
              >
                购买
              </a>
            </span>
          ) : (
            `「${alertInfo.workspaceName}」已经过期，请联系管理员「${alertInfo.adminName}」续费`
          ))}
        {alertInfo?.type === 'exhausted' &&
          (alertInfo.isAdmin ? (
            <span>
              {`「${alertInfo.workspaceName}」积分已耗尽，请`}
              <a
                className='text-primary cursor-pointer underline'
                onClick={e => {
                  e.stopPropagation()
                  contactCustomerServiceModal.open()
                }}
              >
                联系客服
              </a>
            </span>
          ) : (
            `「${alertInfo.workspaceName}」积分已经消耗尽，请联系管理员「${alertInfo.adminName}」续费`
          ))}
      </div>
    </div>
  )
}

export const LimitedAlertBar = memo(LimitedAlertBarContent)
