import { useMemo } from 'react'
import { FieldTypes, type IStartNodeFormItemType } from '../start'

export default (
  startNodeFormItemType: IStartNodeFormItemType[],
  variables: any[],
) => {
  const fileVariableKeyList = useMemo(() => {
    const fileVariables = startNodeFormItemType
      ?.filter(item => item.type === FieldTypes.File)
      .map(item => item)
    return fileVariables
  }, [startNodeFormItemType, variables])

  return {
    fileVariableKeyList,
  }
}
