import type { IAceEditorProps } from 'react-ace'
import AceEditor from 'react-ace'
import { memo, useMemo, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import { isNil } from 'lodash-es'
import type ReactAce from 'react-ace/lib/ace'
import classNames from 'classnames'
import type { RenderProps } from './type'

export const JSONRender = memo(
  (props: RenderProps<string> & IAceEditorProps) => {
    const {
      className,
      name,
      readOnly,
      disabled,
      placeholder,
      value = '',
      onChange,
      supportFileTypes,

      ...rest
    } = props

    const aceRef = useRef<ReactAce>(null)

    const handleChange = useMemoizedFn((newValue: string) => {
      onChange(newValue, name)
    })

    const sureValue = useMemo(() => {
      if (typeof value !== 'object' || isNil(value)) return value
      return JSON.stringify(value, null, 2)
    }, [value])

    return (
      <div
        className={classNames(className, 'llm-node-json-input', {
          'llm-node-json-input-normal': !disabled,
          'llm-node-json-input-disabled': disabled,
        })}
      >
        <AceEditor
          theme='tomorrow'
          height='100%'
          width='100%'
          tabSize={2}
          fontSize={14}
          highlightActiveLine={false}
          showPrintMargin={false}
          setOptions={{
            useWorker: false,
            enableSnippets: false,
            minLines: 4,
            maxLines: 16,
          }}
          {...rest}
          mode='json'
          ref={aceRef}
          value={sureValue}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={disabled}
        />
      </div>
    )
  },
)
