import { Position } from 'reactflow'
import { useCallback, useContext, useRef, useState } from 'react'
import classNames from 'classnames'
import { useBoolean, useClickAway } from 'ahooks'
import { AddButton, DeletePopover } from '../components'
import { CodeEditor } from '@/features/editor'
import { IconFont } from '@/components'
import { DefaultHandle } from '@/features/flow/components/DefaultHandle'
import { NodeContext } from '../base/context'

export enum BranchTypes {
  IF = 'if',
  ELSEIF = 'elseif',
  ELSE = 'else',
}

const branchTypesInfo = {
  [BranchTypes.IF]: {
    text: 'If',
  },
  [BranchTypes.ELSEIF]: {
    text: 'Else If',
  },
  [BranchTypes.ELSE]: {
    text: 'Else',
  },
}

export interface ConditionItem {
  id?: string
  type: BranchTypes
  statement: string
}

export interface ConditionFormProps {
  value?: ConditionItem[]
  disabled?: boolean
  onChange?: (value: ConditionItem[]) => void
  variables?: Array<{ label: string; type?: string }>
  variableTipsContainer?: HTMLElement | null // 变量提示锚定位置  默认为编辑器本身
  onDelete?: (id: string) => void
  onAdd?: () => void
  onBeforeRemove: (conditionId: string) => void
  onCancel: () => void
}

interface ConditionCodeProps {
  variables?: Array<{ label: string; type?: string }>
  variableTipsContainer?: HTMLElement | null
  disabled?: boolean
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
  placeholder?: string
}

function ConditionCodeEditor(props: ConditionCodeProps) {
  const [
    wrapEnabled,
    { setTrue: setWrapEnabledTrue, setFalse: setWrapEnabledFalse },
  ] = useBoolean(false)
  const ref = useRef<HTMLDivElement>(null)

  useClickAway(() => {
    setWrapEnabledFalse()
  }, ref)

  return (
    <div
      ref={ref}
      className='absolute top-0 w-100% z-10'
      onClick={setWrapEnabledTrue}
    >
      <CodeEditor
        {...props}
        className='ace-gray'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={props.value}
        onChange={props.onChange}
        wrapEnabled={wrapEnabled}
        mode='javascript'
        defaultValue={props.defaultValue}
        // onFocus={setWrapEnabledTrue}
        // onBlur={setWrapEnabledFalse}
        singleLine
        variables={props.variables}
        setOptions={{
          showGutter: false,
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export function ConditionForm(props: ConditionFormProps) {
  const {
    value: conditionList = [],
    disabled,
    onDelete,
    onAdd,
    onChange,
    onBeforeRemove,
    onCancel,
  } = props
  const [dangerIds, setDangerIds] = useState<string[]>([])
  const { id: nodeId } = useContext(NodeContext)

  const onStatementChange = useCallback(
    (value: string, conditionId: string) => {
      const newConditions = conditionList.map(item =>
        item.id === conditionId ? { ...item, statement: value } : item,
      )
      onChange?.(newConditions)
    },
    [conditionList, onChange],
  )

  const onPopoverOpen = (id: string) => {
    setDangerIds([id])
    onBeforeRemove(id)
  }

  const onPopoverClose = () => {
    setDangerIds([])
    onCancel()
  }

  return (
    <div>
      {conditionList.length &&
        conditionList.map(({ id, type, statement }, i) => (
          <div
            key={id || i}
            className={classNames(
              'relative m-b-8 p-8 bg-white b-rd-6px b-1 b-op-60',
              [dangerIds.includes(id as string) ? 'b-#ff5219' : 'b-#e1e1e5'],
            )}
          >
            {!(conditionList.length <= 2 && i <= 1) && i > 0 && (
              <DeletePopover
                title='该分支的所有节点，都会被删除，是否确认删除？'
                onOpen={() => onPopoverOpen(id!)}
                onClose={() => onPopoverClose()}
                onDelete={() => onDelete?.(id!)}
              >
                <div className='parent absolute top-4 right-4 flex-center w-24px h-24px b-rd-4px cursor-pointer hover:bg-#ff5219 hover:bg-op-12'>
                  <IconFont
                    name='shanshu'
                    className='text-16px c-#8d8d99 c-op-60 parent-hover:c-#ff5219'
                  />
                </div>
              </DeletePopover>
            )}
            <div className='line-height-16px text-12px font-500 c-#17171d'>
              {branchTypesInfo[type].text}
            </div>
            {type !== BranchTypes.ELSE && (
              <div className='mt-8 relative h-32px'>
                <ConditionCodeEditor
                  disabled={disabled}
                  placeholder='请输入javascript表达式'
                  variableTipsContainer={props.variableTipsContainer}
                  variables={props.variables}
                  value={statement}
                  onChange={value => onStatementChange(value, id!)}
                />
              </div>
            )}
            <DefaultHandle
              id={id}
              nodeId={nodeId as string}
              type='source'
              position={Position.Right}
              style={{
                top: 13,
                right: -34,
              }}
            />
          </div>
        ))}
      <AddButton disabled={disabled} onClick={onAdd}>
        添加分支
      </AddButton>
    </div>
  )
}
