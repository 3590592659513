import { useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useAuth } from '@bty/react-auth'
import { useWorkspaceStore } from '@/store'
import { RoleCode } from '@/apis/authority.ts'
import { LabelTabs } from '@/components'
import { isPrivateVersion } from '@/constants/common.ts'

const allTabs = [
  {
    label: '概览',
    key: 'overview',
  },
  // {
  //   label: '基本信息',
  //   key: 'base',
  // },
  {
    label: '告警管理',
    key: 'alarm',
  },
  {
    label: '用户列表',
    key: 'users',
  },
  {
    label: '订单管理',
    key: 'orders',
  },
]

type TabKey = (typeof allTabs)[number]['key']

export default function WorkSpaceSettings() {
  // const { access } = useAuth()
  const {
    state: { role },
  } = useAuth()
  const { id } = useParams()
  const match = useMatch('/workspace/:id/settings/:tab')
  const tab = match?.params.tab as TabKey
  const [activeKey, setActiveKey] = useState<TabKey>(tab)
  const navigate = useNavigate()
  const { currentWorkspaceItem, workspaceList } = useWorkspaceStore(state => ({
    currentWorkspaceItem: state.currentWorkspaceItem,
    workspaceList: state.workspaceList,
  }))

  const tabs = allTabs.filter(item => {
    if (isPrivateVersion) {
      return item.key !== 'orders'
    }
    return true
  })

  const workspaceNotFound = useMemo(
    () => !workspaceList.find(item => item.id === id),
    [workspaceList, id],
  )

  // const displayTabs = useMemo(() => {
  //   const isViewer = access.role([RoleCode.VIEWER])
  //   if (isViewer) {
  //     return tabs.filter(item => item.key === 'base')
  //   }
  //   return tabs
  // }, [tabs, access])

  const displayTabs = useMemo(() => {
    if (role === RoleCode.VIEWER) {
      return tabs.filter(item => item.key === 'overview' || item.key === 'data')
    } else if (role === RoleCode.DEVELOPER) {
      return tabs.filter(
        item =>
          item.key === 'base' ||
          item.key === 'overview' ||
          item.key === 'data' ||
          item.key === 'users' ||
          item.key === 'alarm',
      )
    } else if (role === RoleCode.ADMINISTRATOR) {
      return tabs
    } else {
      return tabs.filter(item => item.key === 'overview' || item.key === 'data')
    }
  }, [tabs, role])

  const handleTabChange = (key: TabKey) => {
    setActiveKey(key)
  }

  useEffect(() => {
    handleTabChange(tab)
  }, [tab])

  useEffect(() => {
    if (!workspaceNotFound) {
      navigate(`${activeKey}${location.search}`)
    }
  }, [activeKey])

  if (workspaceNotFound) {
    return <Navigate to='/404' />
  }

  return (
    <div className='h-full'>
      <div className='h-100% adapt:pt-40 bg-bg b-rd-12px flex flex-col'>
        <div className='adapt:px-64 '>
          <div className='line-height-36px text-20px font-600 pb-24'>
            {currentWorkspaceItem?.name || '我的工作空间名称'}
          </div>
        </div>
        <div className='flex adapt:mb-20 adapt:px-64 '>
          <LabelTabs
            labels={displayTabs}
            activeKey={activeKey}
            onChange={key => handleTabChange(key)}
          />
        </div>
        <div className='flex-1 overflow-hidden'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
