import { memo } from 'react'
import { message } from 'antd'
import copy from 'copy-to-clipboard'
import { useMemoizedFn } from 'ahooks'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { CodeEditor } from '@/features/editor'
import { NodeForm } from '@/components'
import { Webhook } from '@/features/nodes/start/components/Webhook'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils'
import { useLogger } from '../context'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'

export const LoggerInputReadonly = memo(() => {
  const { form, webhookJson, startType, startConfig } = useLogger()

  const onCopy = useMemoizedFn((text: string) => {
    copy(text)
    message.success('复制成功')
  })

  return (
    <OverlayScrollbarsComponent
      className='w-full h-full px-16px'
      element='div'
      options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
      defer
    >
      {startType === 'form' && (
        <NodeForm
          form={form}
          disabled={true}
          schema={transformConfig2SchemaConfig(startConfig)}
        />
      )}

      {startType === 'webhook' && (
        <div className='flex flex-col h-full'>
          <Webhook style={{ padding: 0, marginBottom: 20 }} />
          <div className='flex-1'>
            <CodeEditor
              width='100%'
              theme='tomorrow'
              mode='json'
              disabled={true}
              setOptions={{
                maxLines: 31,
                hasCssTransforms: false,
              }}
              placeholder='填写JSON参数，模拟Webhook调用'
              value={webhookJson}
              copy
              onCopy={onCopy}
            />
          </div>
        </div>
      )}
    </OverlayScrollbarsComponent>
  )
})
