import type { MessageItem } from '@bty/chat-ui'
import {
  useBoolean,
  useRequest,
  useUnmount,
  useUpdateEffect,
  useDebounceEffect,
  useAntdTable,
  useDebounceFn,
} from 'ahooks'
import type { ColumnsType } from 'antd/es/table'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import isBetween from 'dayjs/plugin/isBetween'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Pagination, Tabs, Form } from 'antd'
import type { TabsProps } from 'antd'
import { isEqual } from 'lodash-es'
import {
  Input,
  Button,
  IconFont,
  RangePickerWrapper,
  Select,
  Table,
} from '@/components'
import {
  rangePresets,
  SearchSelectContainer,
  agentSearchOption,
  feedbackSearchOptions,
} from '@/pages/application/components/appDetail/utils'
import { POINTS_CONSUME_NAME } from '@/constants/commercialization'

import { LimitedAlertContent } from '@/features/pay/LimitedAlert'
import { useVersion } from '@/hooks/useVersion'
import {
  AgentFeedbackStatusEn,
  exportAgentChatFeedBackList,
  fetchAgentLogs,
  fetchHandleFeedback,
  getAgentChatFeedBackList,
} from '@/apis/agent'
import type {
  AgentChatFeedbackItem,
  AgentChatFeedbackParams,
  AgentLogItem,
  AgentRunStatus,
  AgentRunType,
} from '@/apis/agent'
import { disabledOneYearDate } from '@/utils/date'
import { exportAgentLogs } from '@/apis/chatbot'
import { useLogsExport } from '@/hooks/useLogsExport'
import { RunTypeGroupSelect } from '@/features/analyze/components/RunTypeGroupSelect'
import { AgentRunTypeGroup } from '@/features/analyze/constants'
import { LogModal } from './LogModal'
import { FeedbackDetailModal } from './FeedbackDetailModal'
import { FeedbackStatusSelect } from './FeedbackStatusSelect'

dayjs.extend(isBetween)

const PAGE_SIZE = 10
const PAGINATION_HEIGHT = 69
const TABLE_HEADER_HEIGHT = 41
const SEARCH_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const StyledTabs = styled(Tabs)`
  &.ant-tabs-top > .ant-tabs-nav::before {
    border: none !important;
  }
  &.ant-tabs .ant-tabs-tab {
    font-size: 16px;
    font-weight: 500;
    padding: 0px 0px 12px 0px;
  }
  &.ant-tabs .ant-tabs-ink-bar {
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
  }
`

export const agentRunTypesMap: Record<
  AgentRunType | string,
  { label: string }
> = {
  AGENT_TESTING: {
    label: '调试',
  },
  MULTIPLE_MODELS: {
    label: '多模型对比',
  },
  CHATBOT: {
    label: 'chatBot',
  },
  CHAT_CLIENT: {
    label: 'copilot',
  },
  AGENT: {
    label: '客户端',
  },
  AGENT_OAPI: {
    label: 'API',
  },
  COPILOT: {
    label: 'copilot',
  },
  DINGTALK_STREAM_ROBOT: {
    label: '钉钉 stream 机器人',
  },
  DINGTALK_ORGAPP_ROBOT: {
    label: '钉钉机器人',
  },
  WEIXIN_KF: {
    label: '微信客服',
  },
  WEIXIN_MP: {
    label: '微信公众号',
  },
  WEIXIN_WORK_APP: {
    label: '企业微信',
  },
  FEISHU_APP_BOT: {
    label: '飞书机器人',
  },
}

const agentRunStatusOptions: {
  label: string
  value: AgentRunStatus | 'all'
}[] = [
  {
    value: 'all',
    label: '全部状态',
  },
  {
    value: 'SUCCEEDED',
    label: '成功',
  },
  {
    value: 'FAILED',
    label: '失败',
  },
]

type LogTabType = 'logs' | 'feedback'

export function AgentRunLogs(props: {
  agentId: string
  appId: string
  agentName: string
  avatarInfo: MessageItem['avatarInfo']
}) {
  const { agentId, appId, avatarInfo, agentName } = props
  const [searchParams] = useSearchParams()
  const ref = useRef<HTMLDivElement>(null)
  const observerRef = useRef<ResizeObserver | null>(null)
  const [tableBodyHeight, setTableBodyHeight] = useState(400)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [userName, setUserName] = useState<string>(
    () => searchParams.get('user_name') || '',
  )
  const [runStatus, setRunStatus] =
    useState<(typeof agentRunStatusOptions)[number]['value']>('all')
  const [runType, setRunType] = useState<AgentRunType[] | ['all']>(() => {
    const runType = searchParams.get('run_types')
    if (runType) {
      return runType.split(',') as AgentRunType[]
    }
    return []
  })

  const [searchType, setSearchType] = useState(agentSearchOption[0].value)
  const searchPlaceHolder = useMemo(() => {
    return agentSearchOption?.find(item => searchType === item.value)?.label
  }, [searchType])

  const [timeRange, setTimeRange] = useState<[Dayjs | null, Dayjs | null]>(
    () => {
      const startTime = searchParams.get('start_time')
      const endTime = searchParams.get('end_time')
      if (startTime && endTime) {
        return [dayjs(startTime).startOf('d'), dayjs(endTime).endOf('d')]
      }
      return [dayjs().add(-7, 'd').startOf('d'), dayjs().endOf('day')]
    },
  )

  const { isTeam } = useVersion()

  const { exportLogs, loading: exporting } = useLogsExport({
    exportApi: exportAgentLogs,
  })
  const [logModalVisible, { toggle: toggleLogModal }] = useBoolean(false)
  const {
    data: logsData,
    loading,
    refreshAsync: refreshLogs,
    runAsync: fetchLogs,
  } = useRequest(
    () =>
      fetchAgentLogs({
        agent_id: agentId,
        page_no: page,
        page_size: pageSize,
        [searchType]: userName,
        run_status: runStatus !== 'all' ? runStatus : undefined,
        run_types: !(isEqual(runType, ['all']) || runType?.length === 0)
          ? (runType as AgentRunType[])
          : undefined,
        start_time: timeRange[0]?.startOf('day').format(SEARCH_DATE_FORMAT),
        end_time: timeRange[1]?.endOf('day').format(SEARCH_DATE_FORMAT),
        application_id: appId,
      }),
    {
      refreshDeps: [agentId, pageSize, searchType],
      ready: !!agentId,
    },
  )
  const logs = useMemo(() => {
    return logsData?.data || []
  }, [logsData])

  const [userAvatarInfo, setUserAvatarInfo] = useState({
    color: 'rgba(20, 171, 241, 0.12)',
    icon: '',
  })
  const [currConversationId, setCurrConversationId] = useState('')

  const openConversationModal = async (conversationId: string) => {
    setCurrConversationId(conversationId)
    const log = logs.find(v => v.conversation_id === conversationId)
    setUserAvatarInfo({
      ...userAvatarInfo,
      icon: log?.user_name?.substring(0, 1) || '',
    })
    toggleLogModal()
  }

  const handleExport = () => {
    exportLogs(
      {
        agent_id: agentId,
        [searchType]: userName,
        run_types: !(isEqual(runType, ['all']) || runType?.length === 0)
          ? (runType as AgentRunType[])
          : undefined,
        run_status: runStatus !== 'all' ? runStatus : undefined,
        start_time: timeRange[0]?.format(SEARCH_DATE_FORMAT),
        end_time: timeRange[1]?.format(SEARCH_DATE_FORMAT),
      },
      `${agentName}-运行日志-${dayjs().format('YYYYMMDDHHmm')}`,
    )
  }
  const handleSelectChange = (value: string) => {
    setSearchType(value)
  }

  const columns: ColumnsType<AgentLogItem> = [
    {
      title: '最新消息时间',
      key: 'latest_create_time',
      width: 200,
      ellipsis: true,
      render: (_, { latest_create_time }) => {
        const t = dayjs(latest_create_time)
        const now = dayjs()
        const yesterdayStart = now.subtract(1, 'day').startOf('day')
        const todayStart = now.startOf('day')
        const todayEnd = now.endOf('day')
        if (t.isBetween(todayStart, todayEnd)) {
          return `今天 ${t.format('HH:mm:ss')}`
        } else if (t.isBetween(yesterdayStart, todayStart)) {
          return `昨天 ${t.format('HH:mm:ss')}`
        }
        return t.format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '状态',
      key: 'run_status',
      render: (_, { run_status }) => {
        return (
          <div
            className={classNames(
              'flex-center w-min h-20px px-14px b-rd-4px bg-op-12 text-12px font-500 whitespace-nowrap',
              {
                'bg-#2cb969 c-#2cb969': run_status === 'SUCCEEDED',
                'bg-#ff5219 c-#ff5219': run_status === 'FAILED',
              },
            )}
          >
            {run_status === 'SUCCEEDED' ? '成功' : '失败'}
          </div>
        )
      },
    },
    {
      title: '标题',
      key: 'title',
      ellipsis: true,
      dataIndex: 'title',
      width: 300,
    },
    {
      title: '消息条数',
      key: 'total_records',
      dataIndex: 'total_records',
      ellipsis: true,
    },
    {
      title: `消耗${POINTS_CONSUME_NAME}`,
      key: 'total_upgrade_consume',
      dataIndex: 'total_upgrade_consume',
      ellipsis: true,
    },
    {
      title: '用户',
      key: 'user_name',
      dataIndex: 'user_name',
      render: (value, record) => {
        if (record.run_type === 'WEIXIN_MP') {
          return `粉丝${value.slice(-6)}`
        }
        return value
      },
    },
    {
      title: '运行方式',
      key: 'run_type',
      ellipsis: true,
      render: (_, { run_type }) => agentRunTypesMap[run_type]?.label,
    },
    {
      title: '客户端类型',
      key: 'client_type',
      dataIndex: 'client_type',
    },
    {
      title: '操作',
      render: (_, { conversation_id }) => {
        return (
          <div
            className='c-#7b61ff cursor-pointer'
            onClick={e => {
              e.stopPropagation()
              openConversationModal(conversation_id)
            }}
          >
            查看详情
          </div>
        )
      },
    },
  ]

  useLayoutEffect(() => {
    const node = ref.current
    if (node) {
      observerRef.current = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target === node) {
            const { height } = node.getBoundingClientRect()
            const paginationEle = node.querySelector('#agent_logs_pagination')
            const tableHeaderEle = node.querySelector('.ant-table-header')
            const pH = paginationEle
              ? paginationEle.getBoundingClientRect().height
              : PAGINATION_HEIGHT
            const hH = tableHeaderEle
              ? tableHeaderEle.getBoundingClientRect().height
              : TABLE_HEADER_HEIGHT
            setTableBodyHeight(height - pH - hH)
          }
        }
      })
      observerRef.current.observe(node)
    }
  }, [ref])

  useUnmount(() => {
    if (observerRef.current && ref.current) {
      observerRef.current.unobserve(ref.current)
    }
  })

  useUpdateEffect(() => {
    // 筛选项变化切换到第一页请求
    if (page !== 1) {
      setPage(1)
    } else {
      refreshLogs()
    }
  }, [timeRange, runStatus, runType])
  useUpdateEffect(() => {
    refreshLogs()
  }, [page])

  useDebounceEffect(
    () => {
      if (page !== 1) {
        setPage(1)
      }
      fetchLogs()
    },
    [userName],
    {
      wait: 500,
    },
  )

  return (
    <div className='flex flex-col h-full bg-#fff b-rd-12px'>
      <div className='flex-center-between gap-12px m-b-20px'>
        <SearchSelectContainer>
          <Select
            options={agentSearchOption}
            style={{ width: 72 }}
            value={searchType}
            onChange={handleSelectChange}
          />
          <Input
            className='w-328px bg-op-6'
            prefix={<IconFont className='text-16px' name='search' />}
            placeholder={`搜索${searchPlaceHolder}`}
            size='middle'
            value={userName}
            onChange={e => {
              setUserName(e.target.value)
            }}
          />
        </SearchSelectContainer>
        <div className='flex-center-between gap-12px'>
          <Select
            className='min-w-140px'
            size='middle'
            placeholder='全部状态'
            options={agentRunStatusOptions}
            value={runStatus}
            onChange={v => setRunStatus(v)}
          />
          <RunTypeGroupSelect
            data={AgentRunTypeGroup}
            onTypeChange={v => setRunType(v as AgentRunType[])}
          />
          <RangePickerWrapper
            disabledDate={isTeam ? disabledOneYearDate : undefined}
            showTime={false}
            presets={rangePresets}
            format='YYYY-MM-DD'
            className='h-36px border-rd-8px'
            value={timeRange}
            onChange={dates => {
              dates
                ? setTimeRange([dates[0], dates[1]])
                : setTimeRange([null, null])
            }}
          />
          <Button loading={exporting} onClick={handleExport}>
            导出
          </Button>
        </div>
      </div>
      <div className='flex-1 overflow-hidden' ref={ref}>
        <Table
          rowKey='conversation_id'
          columns={columns}
          dataSource={logs}
          loading={loading}
          scroll={{
            y: `${tableBodyHeight}px`,
          }}
          rowClassName='cursor-pointer'
          onRow={record => ({
            onClick: () => {
              openConversationModal(record.conversation_id)
            },
          })}
          pagination={false}
        />
        <div
          className='flex flex-1 pt-20 items-center justify-between border-t border-solid border-font_1 border-op-16 '
          id='agent_logs_pagination'
        >
          <span>
            <LimitedAlertContent
              prefix='团队版仅可查看近1年的日志'
              shouldCheckTeamVersion
            />
          </span>
          <Pagination
            {...{
              current: page,
              total: logsData?.total || 0,
              pageSize,
              onChange: (page, size) => {
                setPage(page)
                setPageSize(size)
              },
            }}
          />
        </div>
      </div>
      {logModalVisible && (
        <LogModal
          agentId={agentId}
          appId={appId}
          conversationId={currConversationId}
          onClose={toggleLogModal}
          robotAvatarInfo={avatarInfo}
          userAvatarInfo={userAvatarInfo}
        />
      )}
    </div>
  )
}

function getAgentChatFeedBackListFormatted(params: AgentChatFeedbackParams) {
  return getAgentChatFeedBackList(params).then(res => {
    return {
      list: res.data || [],
      total: res.total || 0,
    }
  })
}

const feedbackStatusOptions: {
  label: string
  value: AgentFeedbackStatusEn | 'all'
}[] = [
  {
    value: 'all',
    label: '全部状态',
  },
  {
    value: AgentFeedbackStatusEn.Processed,
    label: '已处理',
  },
  {
    value: AgentFeedbackStatusEn.Pending,
    label: '待处理',
  },
  {
    value: AgentFeedbackStatusEn.Ignored,
    label: '不处理',
  },
]

function Feedback({
  appId,
  agentName,
  avatarInfo,
}: {
  appId: string
  agentName: string
  avatarInfo: MessageItem['avatarInfo']
}) {
  const ref = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [tableBodyHeight, setTableBodyHeight] = useState(400)
  const [feedbackModalVisible, { toggle: toggleFeedbackModal }] =
    useBoolean(false)
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<string | null>(
    null,
  )
  const [form] = Form.useForm()
  const searchType = Form.useWatch('searchType', form)
  const searchPlaceHolder = useMemo(() => {
    return feedbackSearchOptions?.find(item => searchType === item.value)?.label
  }, [searchType])
  const {
    tableProps,
    search,
    refresh,
    params: tableParams,
  } = useAntdTable(
    (
      { current, pageSize },
      formData: {
        searchType: 'content' | 'response' | 'user_opinion'
        keyword?: string
        content?: string
        response?: string
        user_opinion?: string
        status: AgentFeedbackStatusEn | 'all' | undefined
        timeRange: [dayjs.Dayjs, dayjs.Dayjs]
      },
    ) => {
      const searchParams: {
        app_id: string
        page_no: number
        page_size: number
        status?: AgentFeedbackStatusEn
        start_time: string
        end_time: string
        content?: string
        response?: string
        user_opinion?: string
      } = {
        app_id: appId,
        page_no: current,
        page_size: pageSize,
        status: formData?.status === 'all' ? undefined : formData?.status,
        start_time: formData.timeRange[0]
          ?.startOf('day')
          .format(SEARCH_DATE_FORMAT),
        end_time: formData.timeRange[1]
          ?.endOf('day')
          .format(SEARCH_DATE_FORMAT),
      }
      if (formData.keyword) {
        searchParams[formData.searchType] = formData.keyword
      }
      return getAgentChatFeedBackListFormatted(searchParams)
    },
    {
      form,
      defaultType: 'simple',
      defaultParams: [
        { current: 1, pageSize: PAGE_SIZE },
        {
          searchType: 'content',
          status: 'all',
          timeRange: [dayjs().add(-7, 'd').startOf('d'), dayjs().endOf('day')],
        },
      ],
    },
  )

  const { submit } = search

  const { run: handleFeedback } = useRequest(fetchHandleFeedback, {
    manual: true,
    onSuccess: () => {
      refresh()
    },
  })

  const { exportLogs: exportFeedbackList, loading: exporting } = useLogsExport({
    exportApi: exportAgentChatFeedBackList,
  })

  const handleExport = () => {
    const searchValue = tableParams[1]!
    exportFeedbackList(
      {
        app_id: appId,
        status: searchValue?.status === 'all' ? undefined : searchValue?.status,
        start_time: searchValue?.timeRange[0]?.format(SEARCH_DATE_FORMAT),
        end_time: searchValue?.timeRange[1]?.format(SEARCH_DATE_FORMAT),
      },
      `${agentName}-点踩反馈-${dayjs().format('YYYYMMDDHHmm')}`,
    )
  }

  const handleStatusChange = (
    status: string,
    record: AgentChatFeedbackItem,
  ) => {
    handleFeedback({
      feedback_id: record.feedback_id,
      status,
    })
  }

  // 定义反馈表格的列
  const columns: ColumnsType<AgentChatFeedbackItem> = [
    {
      title: '问题',
      dataIndex: 'content',
      width: 320,
      ellipsis: true,
    },
    {
      title: '回答',
      dataIndex: 'response',
      ellipsis: true,
      width: 320,
    },
    {
      title: '类型',
      dataIndex: 'reason_type',
      render: reason_type => {
        return reason_type?.length ? reason_type.join(',') : '-'
      },
    },
    {
      title: '输入意见',
      dataIndex: 'user_opinion',
      ellipsis: true,
      render: user_opinion => {
        return user_opinion || '-'
      },
    },
    {
      title: '反馈人',
      dataIndex: 'creator_name',
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
      width: 180,
      render: updated_at => {
        return dayjs(updated_at).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '操作',
      dataIndex: 'status',
      width: 100,
      render: (status, record) => {
        return (
          <div onClick={e => e.stopPropagation()}>
            <FeedbackStatusSelect
              value={status}
              onChange={(value: AgentFeedbackStatusEn) =>
                handleStatusChange(value, record)
              }
            />
          </div>
        )
      },
    },
  ]

  useLayoutEffect(() => {
    const node = ref.current
    if (!node) return

    const updateTableBodyHeight = () => {
      const { height } = node.getBoundingClientRect()
      const paginationEle = paginationRef.current
      const tableHeaderEle = node.querySelector('.ant-table-header')
      const paginationHeight =
        paginationEle?.getBoundingClientRect().height || PAGINATION_HEIGHT
      const tableHeaderHeight =
        tableHeaderEle?.getBoundingClientRect().height || TABLE_HEADER_HEIGHT
      setTableBodyHeight(height - paginationHeight - tableHeaderHeight)
    }

    const resizeObserver = new ResizeObserver(updateTableBodyHeight)
    resizeObserver.observe(node)

    updateTableBodyHeight()

    return () => resizeObserver.disconnect()
  }, [ref])

  const { run: inputSubmit } = useDebounceFn(submit, { wait: 500 })

  const openFeedbackModal = (feedbackId: string) => {
    setSelectedFeedbackId(feedbackId)
    toggleFeedbackModal()
  }

  return (
    <div className='flex flex-col h-full'>
      <Form form={form} className='flex-center-between gap-12px'>
        <SearchSelectContainer>
          <Form.Item name='searchType'>
            <Select
              options={feedbackSearchOptions}
              style={{ width: 100 }}
              onChange={submit}
              dropdownStyle={{ width: 90 }}
            />
          </Form.Item>
          <Form.Item name='keyword'>
            <Input
              className='w-328px bg-op-6'
              prefix={<IconFont className='text-16px' name='search' />}
              placeholder={`搜索${searchPlaceHolder}`}
              size='middle'
              onChange={inputSubmit}
            />
          </Form.Item>
        </SearchSelectContainer>
        <div className='flex-center-between gap-12px'>
          <Form.Item name='status'>
            <Select
              className='min-w-140px'
              size='middle'
              placeholder='全部状态'
              options={feedbackStatusOptions}
              onChange={submit}
            />
          </Form.Item>
          <Form.Item name='timeRange'>
            <RangePickerWrapper
              showTime={false}
              presets={rangePresets}
              format='YYYY-MM-DD'
              className='h-36px border-rd-8px'
              onChange={submit}
            />
          </Form.Item>
          <Form.Item>
            <Button loading={exporting} onClick={handleExport}>
              导出
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className='flex-1 overflow-hidden' ref={ref}>
        <Table
          rowKey='feedback_id'
          columns={columns}
          rowClassName='cursor-pointer'
          scroll={{
            y: `${tableBodyHeight}px`,
          }}
          onRow={record => ({
            onClick: () => {
              openFeedbackModal(record.feedback_id)
            },
          })}
          {...tableProps}
          pagination={false}
        />
        <div
          className='flex flex-1 pt-20 items-center justify-between border-t border-solid border-font_1 border-op-16'
          ref={paginationRef}
        >
          <span>{/* 如果需要，可以添加类似的提示信息 */}</span>
          <Pagination
            current={tableProps.pagination.current}
            total={tableProps.pagination.total || 0}
            pageSize={tableProps.pagination.pageSize}
            onChange={(page, pageSize) => {
              tableProps.onChange?.({
                current: page,
                pageSize,
              })
            }}
          />
        </div>
      </div>
      {feedbackModalVisible && selectedFeedbackId && (
        <FeedbackDetailModal
          avatarInfo={avatarInfo}
          onClose={toggleFeedbackModal}
          feedbackId={selectedFeedbackId}
          refresh={refresh}
        />
      )}
    </div>
  )
}

export function Logs(props: {
  agentId: string
  appId: string
  agentName: string
  avatarInfo: MessageItem['avatarInfo']
}) {
  const { agentId, appId, agentName, avatarInfo } = props
  const [activeTab, setActiveTab] = useState<LogTabType>('logs')

  const items: TabsProps['items'] = [
    {
      key: 'logs',
      label: '日志',
    },
    {
      key: 'feedback',
      label: '反馈',
    },
  ]

  return (
    <div className='flex flex-col h-full p-20px bg-#fff b-rd-12px'>
      <StyledTabs
        activeKey={activeTab}
        items={items}
        onChange={(key: string) => setActiveTab(key as LogTabType)}
      />
      {activeTab === 'logs' && (
        <AgentRunLogs
          agentId={agentId}
          appId={appId}
          agentName={agentName}
          avatarInfo={avatarInfo}
        />
      )}
      {activeTab === 'feedback' && (
        <Feedback appId={appId} agentName={agentName} avatarInfo={avatarInfo} />
      )}
    </div>
  )
}
