import { useReactFlow } from 'reactflow'
import { NodeType, SUB_FLOW_STATUS } from '@/features/nodes/base'
import { SubFlowNode } from '@/features/nodes/subflow'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { generateNodeName } from '@/features/nodes/utils'

export function useGenInsertSubflowNode() {
  const { subFlowList } = useNodeMetaStore()
  const { getNodes } = useReactFlow()

  const generateToBeInsertedSubflow = (id: string) => {
    const currentSubflow = subFlowList.find(f => f.flow_id === id)
    if (!currentSubflow) {
      console.error('找不到当前Subflow')
      return
    }
    const { flow_id, flow_name } = currentSubflow
    const { meta } = SubFlowNode

    return {
      type: NodeType.FLOW,
      data: {
        ...meta.initialData,
        flow_id,
        flow_name,
        flow_status: SUB_FLOW_STATUS.ACTIVE,
        name: generateNodeName(getNodes(), meta.type),
      },
    }
  }

  return {
    generateToBeInsertedSubflow,
  }
}
