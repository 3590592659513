import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'

type Call<T> = (value?: T) => void

export function useSubscribe<T>() {
  const valueRef = useRef<T>()
  const callMapRef = useRef<Set<Call<T>>>(new Set())

  const subscribe = useMemoizedFn((call: Call<T>) => {
    callMapRef.current.add(call)

    return () => {
      callMapRef.current.delete(call)
    }
  })

  const publish = useMemoizedFn((value?: T) => {
    valueRef.current = value
    callMapRef.current.forEach(call => {
      call(value)
    })
  })

  return [subscribe, publish, valueRef] as const
}
