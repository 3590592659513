import { memo, useMemo } from 'react'
import type { PromptStructItem } from '@/apis/prompt.ts'
import { PromptType } from '@/apis/prompt.ts'
import { getPromptFromStructPrompt } from '@/features/prompt/utils/prompt.ts'
import { PromptMermaid } from '@/features/prompt/components/PromptMermaid.tsx'

interface PromptMermaidWithFormItemProps {
  getContainer?: () => HTMLElement
  promptType?: PromptType
  struct?: PromptStructItem[]
  structText?: string
}

export const PromptMermaidWithFormItem = memo(
  (props: PromptMermaidWithFormItemProps) => {
    const { getContainer, promptType, struct, structText } = props

    const prompt = useMemo(() => {
      return promptType === PromptType.STRUCT
        ? getPromptFromStructPrompt(struct ?? [])
        : structText
    }, [promptType, struct, structText])
    console.log(66331, promptType, prompt)

    return (
      <PromptMermaid
        disabled={!prompt?.trim()}
        prompt={prompt || ''}
        getContainer={getContainer}
      />
    )

    // return (
    //   <Form.Item<RuleFormValues>
    //     noStyle
    //     shouldUpdate={(prev, next) => {
    //       if (prev.promptType !== next.promptType) {
    //         return true
    //       } else {
    //         if (next.promptType === PromptType.STRUCT) {
    //           return (
    //             getPromptFromStructPrompt(prev.structPrompt ?? []) !==
    //             getPromptFromStructPrompt(next.structPrompt ?? [])
    //           )
    //         } else {
    //           return (
    //             get(prev, ['inputs', 'system_content']) !==
    //             get(next, ['inputs', 'system_content'])
    //           )
    //         }
    //       }
    //     }}
    //   >
    //     {form => {
    //       const promptType = form.getFieldValue(['inputs', 'promptType'])
    //       const prompt =
    //         promptType === PromptType.STRUCT
    //           ? getPromptFromStructPrompt(
    //               form.getFieldValue(['inputs', 'structPrompt']) ?? [],
    //             )
    //           : (form.getFieldValue(['inputs', 'system_content']) ?? '')

    //       const disabled = !prompt.trim()
    //       console.log(6633, promptType, prompt)
    //       return (
    //         <PromptMermaid
    //           disabled={disabled}
    //           prompt={prompt}
    //           getContainer={getContainer}
    //         />
    //       )
    //     }}
    //   </Form.Item>
    // )
  },
)
