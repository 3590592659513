import styled from '@emotion/styled'
import { Select } from 'antd'

export const SearchContentSelect = styled(Select)`
  .ant-select-selector {
    padding: 0px 4px !important;
    &:hover {
      background-color: rgba(98, 105, 153, 0.08);
    }
    .ant-select-selection-item {
      padding-inline-end: 16px !important;
    }
  }
  .ant-select-arrow {
    right: 7px !important;
    opacity: 1 !important;
  }
`
