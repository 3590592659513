import { Popover, Tooltip } from 'antd'
import { AppLogo, IconFont, usePageModal } from '@/components'
import { useMovableOptionStore } from '@/store'

interface TemplateItemCardProps {
  id: string
  icon: string
  iconColor: string
  name: string
  description: string
  image: string
  imageWidth: number
  helpLink?: string
  selected: boolean
  onClick: (id: string) => void
}

export function TemplateItemCard(props: TemplateItemCardProps) {
  const {
    id,
    icon,
    iconColor,
    name,
    description,
    image,
    imageWidth,
    helpLink,

    selected,
    onClick,
  } = props

  const { init } = useMovableOptionStore()
  const pageModal = usePageModal()

  return (
    <Popover
      key={id}
      rootClassName='[&_.ant-popover-inner]:p-16px'
      content={
        <div>
          <div className='font-500 text-#17171d mb-16px'>{name}</div>
          <img src={image} alt={name} width={imageWidth} />
        </div>
      }
      trigger='hover'
      placement='right'
    >
      <div
        className='mb-12px p-12px b-rd-8px b-1 b-#e1e1e5 b-op-60 hover:bg-#626999 hover:bg-op-8 cursor-pointer'
        onClick={e => {
          const { clientX, clientY } = e
          init({
            x: clientX,
            y: clientY,
          })
          onClick(id)
        }}
      >
        <div className='flex items-center mb-4px'>
          <AppLogo
            value={icon}
            type='emoji'
            className='mr-8px'
            color={iconColor}
            size={24}
            style={{ borderRadius: 4 }}
            imageClassName='b-rd-4px!'
            fillSize={16}
          />
          <div className='flex-1 flex items-center font-500 text-#17171d min-w-0px'>
            <span className='truncate grow-0 shrink'>{name}</span>

            {helpLink && (
              <Tooltip title='帮助文档'>
                <span
                  className='flex flex-center h-24px px-4px ml-4px b-white rounded-4px cursor-pointer !hover:bg-#626999 !hover:bg-op-8 hover:bg-#17171D hover:bg-op-12'
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    pageModal.show({
                      url: helpLink,
                    })
                  }}
                >
                  <IconFont
                    name='shiyongshuimeng'
                    className='text-14px text-bg_3/60'
                  />
                  <span className='text-12px ml-2px text-#626999/60'>帮助</span>
                </span>
              </Tooltip>
            )}
          </div>
          <div className='flex-center w-24px h-24px b-rd-4px'>
            <IconFont
              name={selected ? 'jiediansixuan' : 'add'}
              className='text-16px text-#7861ff'
            />
          </div>
        </div>
        <div className='pl-32px line-height-18px text-12px text-#8D8D99'>
          {description}
        </div>
      </div>
    </Popover>
  )
}
