import axios from 'axios'

export interface TemplateApp {
  id: string
  color: string
  icon: string
  name: string
  description: string
  type: string
  sub_type: string
  isTemplate: true
}

export interface TemplateAppGroup {
  label: string
  value: string
}

export interface TemplateAppTypeMap {
  [key: string]: {
    name: string
    icon: string
    value: string
  }
}

export interface TemplateAppData {
  apps: TemplateApp[]
  app_groups: TemplateAppGroup[]
  types: TemplateAppTypeMap
}

export async function getTemplateAppData(): Promise<TemplateAppData> {
  const res = await axios.get(
    `${import.meta.env.VITE_AI_OSS_APP_URL}/template_app.json`,
    {
      params: {
        t: +new Date(),
      },
    },
  )
  return res.data
}
