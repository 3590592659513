import classNames from 'classnames'
import { Tooltip, Popconfirm } from 'antd'
import { useMemo } from 'react'
import { IconFont } from '@/components'

export const defaultGifImage =
  'https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/chat/generating.gif'

export enum OperationActionType {
  AI = 'ai',
  EDIT = 'edit',
  ADD = 'add',
  DELETE = 'delete',
}
export interface OperationActionProps {
  [key: string]: any
  onActionClick: (actionType: OperationActionType) => void
  actionList: OperationActionType[]
}
export function OperationAction(props: OperationActionProps) {
  const {
    deleteConfirmOpen,
    setDeleteConfirmOpen,
    onActionClick,
    actionList = Object.values(OperationActionType),
  } = props

  const renderActionList = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    const list = actionList?.map(type => {
      switch (type) {
        case OperationActionType.AI:
          return (
            <div
              key={type}
              className='cursor-pointer w-24px h-24px flex-center hover:bg-[rgba(98,105,153,0.08)] rounded-4px text-16px/16px'
              onClick={() => {
                onActionClick(OperationActionType.AI)
              }}
            >
              <Tooltip title='AI增强处理'>
                <IconFont name='AIcengjiangchuli' />
              </Tooltip>
            </div>
          )
        case OperationActionType.EDIT:
          return (
            <div
              key={type}
              className='c-[rgba(164,165,191)] cursor-pointer w-24px h-24px flex-center hover:bg-[rgba(98,105,153,0.08)] rounded-4px text-16px/16px'
              onClick={() => {
                onActionClick(OperationActionType.EDIT)
              }}
            >
              <Tooltip title='编辑'>
                <IconFont name='zaicishuru' className='' />
              </Tooltip>
            </div>
          )
        case OperationActionType.ADD:
          return (
            <div
              key={type}
              className='c-[rgba(164,165,191)] cursor-pointer w-24px h-24px flex-center hover:bg-[rgba(98,105,153,0.08)] rounded-4px text-16px/16px'
              onClick={() => {
                onActionClick(OperationActionType.ADD)
              }}
            >
              <Tooltip title='向上添加'>
                <IconFont name='xiangshangjiayiduan' />
              </Tooltip>
            </div>
          )
        case OperationActionType.DELETE:
          return (
            <div key={type}>
              <Tooltip title='删除分段'>
                <Popconfirm
                  icon={null}
                  title={<div className='p-10'>确定删除该分段吗？</div>}
                  open={deleteConfirmOpen}
                  onOpenChange={setDeleteConfirmOpen}
                  placement='bottom'
                  onConfirm={() => {
                    onActionClick(OperationActionType.DELETE)
                  }}
                  okButtonProps={{
                    danger: true,
                  }}
                  okText='确定'
                  cancelText='取消'
                >
                  <span
                    className={classNames(
                      'rounded-4px w-24px h-24px hover:bg-error hover:bg-op-8 cursor-pointer  c-[rgba(164,165,191)] hover:c-error flex-center text-16px/16px',
                      {
                        'bg-error bg-op-8 c-error': deleteConfirmOpen,
                      },
                    )}
                    onClick={e => e.stopPropagation()}
                  >
                    <IconFont name='shanshu2' />
                  </span>
                </Popconfirm>
              </Tooltip>
            </div>
          )
      }
    })
    return list
  }, [actionList, onActionClick, deleteConfirmOpen])

  return <div className='flex items-center gap-8px'>{renderActionList}</div>
}
