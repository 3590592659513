import type { FC } from 'react'
import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { message } from 'antd'
import classNames from 'classnames'
import { uniq } from 'lodash-es'
import { IconFont } from '@/components'
import type { CodeEditorInstance } from '@/features/editor'
import { CodeEditor } from '@/features/editor'
import { VariableRegex } from '@/constants/common.ts'
import { DropdownSelect } from './DropdownSelect'

interface TagSelectProps {
  value?: string[]
  onChange?: (value?: unknown) => void
  tags: Array<{ label: string; value: string }>
  error?: string[]
  variables: any[]
  variableTipsContainer?: HTMLElement
  placeholder: string
}

function TagVariableEditor(props: {
  variables: any[]
  variableTipsContainer?: HTMLElement
  onChange?: (value: string) => void
  placeholder?: string
}) {
  const [input, setInput] = useState('')
  const codeEditorRef = useRef<CodeEditorInstance | null>(null)

  const onConfirm = useCallback(() => {
    if (input.trim()) {
      props.onChange?.(input)
      setInput('')
    }
  }, [props.onChange, input])

  useEffect(() => {
    const codeEditor = codeEditorRef.current?.editor
    if (codeEditor) {
      codeEditor.commands.removeCommand('onEnter')
      codeEditor.commands.addCommand({
        name: 'onEnter',
        bindKey: { win: 'Enter', mac: 'Enter' },
        exec: onConfirm,
      })
    }
  }, [onConfirm])

  return (
    <div className='min-w-[60px] flex-1 -ml-[8px]'>
      <CodeEditor
        {...props}
        ref={codeEditorRef}
        className='w-full border-none shrink-0 !bg-transparent'
        width='100%'
        variableTipsContainer={props.variableTipsContainer}
        value={input}
        onChange={v => {
          setInput(v)
        }}
        onBlur={onConfirm}
        singleLine
        wrapEnabled={false}
        variables={props.variables}
        placeholder={props.placeholder}
        setOptions={{
          printMargin: false,
          maxLines: Number.POSITIVE_INFINITY,
        }}
      />
    </div>
  )
}

export const FlowSingleSelectEditor: FC<TagSelectProps> = props => {
  const { tags, error, placeholder, ...remaining } = props

  const valueTypeIsArr = useMemo(() => {
    return !!Array.isArray(remaining?.value)
  }, [remaining?.value])

  const tagsOptions = tags
  const boxRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)

  const onVariableEditorChange = useCallback(
    (v: string) => {
      // 如果是变量，或者是已有的标签，直接添加
      if (
        VariableRegex.test(v) ||
        tags.findIndex(item => item.value === v) !== -1
      ) {
        // 如果是变量，直接添加

        const newValue = valueTypeIsArr ? uniq([...(props.value ?? []), v]) : v
        props.onChange?.(newValue)
      } else {
        message.warning('该标签不存在')
      }
      setOpen(false)
    },
    [tags, props.value, props.onChange, valueTypeIsArr],
  )

  const getLabel = (value?: string) => {
    if (!value) return value
    const match = tagsOptions?.find?.(item => item.value === value)
    return match?.label ?? value
  }
  return (
    <div ref={boxRef}>
      <DropdownSelect
        open={open}
        onOpenChange={setOpen}
        getPopupContainer={() => boxRef.current!}
        options={tagsOptions}
        {...remaining}
        onChange={item => {
          if (item.length) {
            remaining.onChange?.(
              valueTypeIsArr ? [item[item.length - 1]] : item[item.length - 1],
            )
          } else {
            remaining.onChange?.()
          }
          setOpen(false)
        }}
        triggerRender={(_, ref) => {
          return (
            <>
              <div
                className={classNames(
                  'relative px-8 py-4 b-1 rounded-6px bg-bg_3 bg-op-6 flex-center-between gap-8',
                  {
                    '!b-error': !!error?.length,
                    'b-primary': open,
                    'hover:b-primary b-bg_3 b-op-0': !open,
                  },
                )}
                ref={ref}
              >
                <div className='flex gap-8 flex-wrap w-full'>
                  {(Array.isArray(remaining?.value)
                    ? remaining?.value
                    : remaining?.value
                    ? [remaining?.value]
                    : []
                  ).map((value, index) => {
                    const isVariable = VariableRegex.test(value)
                    const label = getLabel(value)
                    return (
                      <div
                        key={value + index}
                        className={classNames(
                          'rounded-4px !m-0 flex items-center text-12px/16px',
                          {
                            'bg-tips bg-op-12 c-tips b-tips': isVariable,
                          },
                        )}
                      >
                        {label || value}
                      </div>
                    )
                  })}
                  <TagVariableEditor
                    variables={props.variables}
                    variableTipsContainer={props.variableTipsContainer}
                    onChange={onVariableEditorChange}
                    placeholder={props.value?.length ? '' : placeholder}
                  />
                </div>
                <div className={classNames('w-16px h-16px flex-center')}>
                  <IconFont name='arrow' className='text-7px c-font_1' />
                </div>
              </div>
              {!!error?.length && (
                <div className='mb-12 text-12px c-error'>{error[0]}</div>
              )}
            </>
          )
        }}
      />
    </div>
  )
}
