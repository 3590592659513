import { Position } from 'reactflow'
import { useCallback, useContext, useRef, useState } from 'react'
import classNames from 'classnames'
import { Alert } from 'antd'
import type { ModelSelectProps } from '../../components'
import {
  AddButton,
  DeletePopover,
  ModelSelect,
  NodeFormItem,
} from '../../components'

// import { CodeEditor } from '@/features/editor'
import { TextEditor } from '@/features/editor/TextEditor'

import { IconFont } from '@/components'
import { DefaultHandle } from '@/features/flow/components/DefaultHandle'
import { NodeContext } from '../../base/context'

export enum BranchTypes {
  IF = 'if',
  ELSEIF = 'elseif',
  ELSE = 'else',
}

export interface IntentItem {
  id?: string
  type: BranchTypes
  statement: string
}

export interface IntentFormProps {
  value?: IntentItem[]
  disabled?: boolean
  onChange?: (value: IntentItem[]) => void
  variables?: Array<{ label: string; type?: string }>
  variableTipsContainer?: HTMLElement | null // 变量提示锚定位置  默认为编辑器本身
  onDelete?: (id: string) => void
  onAdd?: () => void
  onBeforeRemove: (conditionId: string) => void
  onCancel: () => void
  showHandle?: boolean
}

interface IntentCodeEditorProps {
  variables?: Array<{ label: string; type?: string }>
  variableTipsContainer?: HTMLElement | null
  disabled?: boolean
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
  placeholder?: string
  className?: string
}

function IntentCodeEditor(props: IntentCodeEditorProps) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div ref={ref} className='w-100% h-100% z-10 overflow-hidden'>
      <TextEditor
        {...props}
        variableTipsContainer={props.variableTipsContainer}
        placeholder={props.placeholder}
        variables={props.variables}
        value={props.value}
        rows={1}
        height={20}
        onChange={props.onChange}
      />
    </div>
  )
}

interface LLMModelSelectProps {
  value?: ModelSelectProps['value']
  onChange?: ModelSelectProps['onChange']
  readOnly?: boolean
  asyncSupport?: boolean
  nodeElement?: HTMLElement | null
}

export function LLMModelSelect(props: LLMModelSelectProps) {
  const { value, onChange, readOnly = false, asyncSupport = false } = props

  const onModelChange = (newModel: any) => {
    if (readOnly) {
      return
    }
    onChange?.({
      ...value!,
      modelSetting: { ...value?.modelSetting, ...newModel },
    })
  }

  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={wrapperRef}
      className='w-full flex justify-between items-center flex-1'
      style={{
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <NodeFormItem required className='flex-1 important:mb-0px'>
        <ModelSelect
          onChange={onModelChange}
          value={{
            model: value?.modelSetting?.model!,
            channel: value?.modelSetting?.channel!,
          }}
          popupMatchSelectWidth={false}
          getPopupContainer={() => props.nodeElement as HTMLElement}
          popupWrapper={wrapperRef.current}
          asyncSupport={asyncSupport}
        />
      </NodeFormItem>
    </div>
  )
}

export const INTENT_MAX_CONTENT_SIZE = 1000

export function IntentForm(props: IntentFormProps) {
  const {
    value: conditionList = [],
    disabled,
    onDelete,
    onAdd,
    onChange,
    onBeforeRemove,
    onCancel,
    showHandle = true,
  } = props
  const [dangerIds, setDangerIds] = useState<string[]>([])
  const { id: nodeId } = useContext(NodeContext)
  const onStatementChange = useCallback(
    (value: string, conditionId: string) => {
      const newConditions = conditionList.map(item =>
        item.id === conditionId ? { ...item, statement: value } : item,
      )
      onChange?.(newConditions)
    },
    [conditionList, onChange],
  )

  const onPopoverOpen = (id: string) => {
    setDangerIds([id])
    onBeforeRemove(id)
  }

  const onPopoverClose = () => {
    setDangerIds([])
    onCancel()
  }

  return (
    <div>
      {conditionList.length &&
        conditionList.map(({ id, type, statement }, i) => (
          <div
            key={id || i}
            className={classNames(
              'relative m-b-8 p-8 bg-white b-rd-6px b-1 b-op-60',
              [dangerIds.includes(id as string) ? 'b-#ff5219' : 'b-#e1e1e5'],
            )}
          >
            {(conditionList.length > 2 || i > 1) &&
              type !== BranchTypes.ELSE && (
                <DeletePopover
                  title='删除该分类将会一并删除分支上的所有节点，请确认是否删除？'
                  onOpen={() => onPopoverOpen(id!)}
                  onClose={() => onPopoverClose()}
                  onDelete={() => onDelete?.(id!)}
                >
                  <div className='parent absolute top-4 right-4 flex-center w-24px h-24px b-rd-4px cursor-pointer hover:bg-#ff5219 hover:bg-op-12'>
                    <IconFont
                      name='shanshu'
                      className='text-16px c-#8d8d99 c-op-60 parent-hover:c-#ff5219'
                    />
                  </div>
                </DeletePopover>
              )}
            <div
              className={classNames(
                'line-height-16px text-12px font-500 c-#17171d',
              )}
            >
              {conditionList.length === i + 1 ? '其他意图' : `分类${i + 1}`}
            </div>
            {type !== BranchTypes.ELSE && (
              <div className='mt-8 relative w-100%'>
                <IntentCodeEditor
                  disabled={disabled}
                  placeholder='输入分类名称，如：打招呼'
                  variableTipsContainer={props.variableTipsContainer}
                  variables={props.variables}
                  value={statement}
                  onChange={value => onStatementChange(value, id!)}
                />
              </div>
            )}
            <DefaultHandle
              id={id}
              nodeId={nodeId as string}
              type='source'
              position={Position.Right}
              style={{
                top: 13,
                right: -34,
                display: showHandle ? 'inherit' : 'none',
              }}
            />
          </div>
        ))}
      <AddButton onClick={onAdd}>添加意图分类</AddButton>
    </div>
  )
}

export const INTENT_ALERT_NODE_DISPLAY_COUNT = 7

export function showIntentAlertNode(count: number) {
  return count > INTENT_ALERT_NODE_DISPLAY_COUNT
}

export function IntentAlertNode() {
  return (
    <Alert
      className='mt--4px b-0px text-12px bg-[rgba(254,151,0,0.08)] rounded-6px'
      showIcon
      icon={
        <IconFont
          name='jingzhi'
          className='c-[rgba(240,156,56)]! text-14px/20px mr-4px'
        />
      }
      message={
        <p className='font-400 text-12px/16px c-#17171D'>
          意图过多会导致分类不准，建议整合不超过
          {INTENT_ALERT_NODE_DISPLAY_COUNT}条
        </p>
      }
      type='warning'
    />
  )
}
