import { memo, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import { isEmpty } from 'lodash-es'
import { FlowLogger } from '@/features/logger'
import { useLogger } from '../context'
import { FlowStatus, getFlowExecuteLog } from '@/apis/flow'
import { transformDataToValidLogs } from '@/features/logger/utils'
import type { FlowOutput } from '@/features/logger/types'
import { addChildrenToFlowOutput } from '@/features/logger/utils/logItem'

export const LoggerLog = memo(() => {
  const { logs, setLogs } = useLogger()

  const onChange = useMemoizedFn(async (item: FlowOutput, open: boolean) => {
    const key = item.value || ''

    if (item.type === 'SUB_FLOW' && open && isEmpty(item.children)) {
      const flowId = item?.attrs?.flow_id
      const taskId = item?.attrs?.task_id

      const res = await getFlowExecuteLog({ flowId, taskId })

      const changedLogs = addChildrenToFlowOutput(
        logs,
        key,
        transformDataToValidLogs(res.run_result.actionOutputs, {
          isSingleStepRun: (res.run_type as string) === 'STEP_RUN',
          taskId: res.task_id,
        }),
      )

      setLogs(changedLogs)
    }
  })

  const expandLogKeys = useMemo(() => {
    const errorLog = logs.find(log => log.status === FlowStatus.FAIL)
    return !errorLog || errorLog?.type === 'SUB_FLOW'
      ? ['output']
      : [errorLog.name]
  }, [logs])

  return (
    <div className='overflow-auto h-full px-16px'>
      <FlowLogger
        outputs={logs}
        defaultActiveKey={expandLogKeys}
        onChange={onChange}
      />
    </div>
  )
})
