import { Popover, Switch, Tooltip } from 'antd'
import type { ReactNode } from 'react'
import { memo, useMemo, useState } from 'react'
import cn from 'classnames'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useMemoizedFn } from 'ahooks'
import { IconFont, Select, SliderSingle } from '@/components'
import { ModelSelect } from '@/features/nodes/components'
import { useLLMStore } from '@/store/llm'
import type { LLMChannels } from '@/apis/llm/model'
import type { AgentDiffModel } from '@/store/agentDiffStore'
import { DefaultModelIcon } from '@/constants/common.ts'

const Tip = memo(({ tip }: { tip: ReactNode }) => {
  return (
    <Tooltip title={tip}>
      <QuestionCircleOutlined className='cursor-pointer ml-4px text-12px text-[rgba(141,141,153,0.4)]' />
    </Tooltip>
  )
})

const DEFAULT_CONFIG = {
  temperature: 0.7,
  length_prompt: 5,
  time_prompt: true,
} as AgentDiffModel

interface DiffConfigProps {
  error?: boolean
  value?: AgentDiffModel
  onChange: (value: AgentDiffModel) => void
}

export const DiffConfig = memo((props: DiffConfigProps) => {
  const { error, value, onChange } = props

  const [open, setOpen] = useState(false)

  const modelLimiteds = useLLMStore(state => state.llmModelLimitedList)
  const models = useLLMStore(state => state.llmModelList)

  const nowModelLimited = useMemo(() => {
    return modelLimiteds.find(item => item.llm_code === value?.model)
  }, [modelLimiteds, value?.model])

  const nowModel = useMemo(() => {
    return models.find(item => item.model === value?.model)
  }, [models, value?.model])

  const memoryRoundOptions = useMemo(() => {
    const points = nowModelLimited?.llm_consume_points

    return [
      { round: 1, multiplier: 1 },
      { round: 5, multiplier: 1 },
      { round: 10, multiplier: 2 },
    ].map(({ round, multiplier }) => ({
      text: `${round}轮对话`,
      label: (
        <>
          <span>{round}轮对话</span>
          <span className='text-14px text-font_1 font-normal ml-auto'>
            <span className='text-14px text-font_1 font-normal ml-auto'>
              {typeof points === 'number'
                ? `Agent 消耗 ${points * multiplier} Yeah 积分`
                : ''}
            </span>
          </span>
        </>
      ),
      value: round,
    }))
  }, [nowModelLimited, value?.model])

  const handleChange = useMemoizedFn(
    (newValue: any, key: keyof AgentDiffModel) => {
      onChange?.({
        ...(value ?? DEFAULT_CONFIG),
        [key]: newValue,
      })
    },
  )

  const handleChangeModal = useMemoizedFn(
    (newValue: { channel: LLMChannels; model: string }) => {
      const newConfig = {
        ...(value ?? DEFAULT_CONFIG),
        ...newValue,
      }

      onChange?.(newConfig)
    },
  )

  const content = (
    <div style={{ width: 566 }}>
      <div
        className='h-46px px-14px text-16px flex flex-center'
        style={{
          borderBottom: '1px solid rgba(225, 225, 229, 0.6)',
        }}
      >
        <span className='mr-auto'>模型设置</span>
        <IconFont
          className='rounded-4px p-4px m-[-4px] box-content hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
          name='guanbi'
          onClick={() => {
            setOpen(false)
          }}
        />
      </div>

      <div className='p-20px flex flex-col gap-20px'>
        <div className='flex flex-center h-36px'>
          <span className='w-120px flex-none'>模型</span>
          <div className='flex-1'>
            <ModelSelect
              className='h-36px! [&_.ant-select-selector]:h-36px! [&_.ant-select-selection-placeholder]:text-#17171D [&_.ant-select-selection-placeholder]:text-14px! [&_.ant-select-selection-item]:text-14px!'
              filterByMethodSupport={['tools', 'functions']}
              showModelDescription={false}
              value={
                value?.model
                  ? {
                      channel: value?.channel as LLMChannels,
                      model: value?.model || '',
                    }
                  : undefined
              }
              placeholder='选择模型'
              onChange={handleChangeModal}
            />
          </div>
        </div>

        {value?.model && (
          <div className='flex flex-center h-36px'>
            <span className='w-120px flex-none'>创造性</span>
            <SliderSingle
              value={value?.temperature ?? 0.7}
              onChange={val => handleChange(val, 'temperature')}
              className='flex-1 h-36px!'
              min={0}
              max={1}
              step={0.1}
              maxLength={3}
            />
          </div>
        )}

        {value?.model && (
          <div className='flex flex-center h-36px'>
            <span className='w-120px flex-none'>
              对话记忆轮数
              <Tip tip='和 Agent 一次问答记为一轮对话' />
            </span>
            <Select
              value={value?.length_prompt ?? 5}
              onChange={val => handleChange(val, 'length_prompt')}
              className='flex-1 h-36px'
              popupClassName='[&_.ant-select-item-option.ant-select-item.ant-select-item-option-selected]:!font-medium'
              options={memoryRoundOptions}
              optionLabelProp='text'
            />
          </div>
        )}

        {value?.model && (
          <div className='flex flex-center h-36px'>
            <span className='w-120px flex-none'>
              知道当前时间
              <Tip
                tip={
                  <div>
                    <p className='leading-20px'>
                      开启后，Agent 能够知道准确时间日期
                    </p>
                    <p className='leading-20px'>
                      例如：“2小时后”、“明天”、 “下周三”
                    </p>
                    <p className='leading-20px'>
                      在请假、日程相关的场景，请开启使用
                    </p>
                  </div>
                }
              />
            </span>

            <div className='flex-1'>
              <Switch
                checked={value?.time_prompt ?? true}
                onChange={val => handleChange(val, 'time_prompt')}
                size='small'
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <Popover
      overlayClassName='p-0px'
      overlayInnerStyle={{
        padding: 0,
        border: '1px solid rgba(141, 141, 153, 0.08)',
      }}
      trigger='click'
      placement='bottomLeft'
      content={content}
      arrow={false}
      destroyTooltipOnHide
      open={open}
      onOpenChange={setOpen}
    >
      <div
        className={cn(
          'min-w-190px h-32px flex items-center px-10 bg-#f6f6f9 border border-line border-op-80 rounded-6px select-none cursor-pointer hover:border-primary',
          {
            'data-[open=true]:border-primary': open,
            'b-#FF5219': error,
          },
        )}
        data-open={open}
      >
        {nowModel?.feature?.icon ? (
          <img
            src={nowModel?.feature?.icon || DefaultModelIcon}
            alt=''
            className='w-16px h-16px mr-8px'
          />
        ) : (
          <IconFont name='muxing' className='mr-8px text-16px text-op-60' />
        )}
        <span className='text-12px text-font font-normal mr-8'>
          {value?.model || '选择模型'}
        </span>
        <IconFont className='text-16px ml-auto text-font' name='muxingpeizhi' />
      </div>
    </Popover>
  )
})
