import emptyImg from '@/assets/empty.png'
import loadingImg from '@/assets/loading.png'

interface RunningProps {
  loading: boolean
  emptyText?: string
  loadingText?: string
}

export function RunningLoading(props: RunningProps) {
  const { loading, emptyText = '惊喜即将发生', loadingText = '生成中' } = props

  return (
    <div className='w-100% h-100% min-h-200px flex flex-col flex-items-center flex-justify-center'>
      <img className='w-124px' src={loading ? loadingImg : emptyImg} alt='' />
      <span className='c-#626999 c-op-40'>
        {loading ? loadingText : emptyText}
      </span>
    </div>
  )
}
