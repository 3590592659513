import styled from '@emotion/styled'
import { Segmented } from '@/components'

export const StyledSegmented = styled(Segmented)`
  font-size: 14px;
  .ant-segmented-item {
    width: 184px;
    padding: 8px 16px;
  }
`
