import NormalParagraphListItem from './NormalParagraphListItem'

import QAparagraphListItem from './QAParagraphListItem'

export * from './types'

export default {
  NormalParagraphListItem,
  QAparagraphListItem,
}
