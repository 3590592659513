import './index.css'

import { Form, Spin } from 'antd'
import { get, merge } from 'lodash-es'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useMemoizedFn } from 'ahooks'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import type { InnerNodePanelProps } from '@/features/nodes/base'
import { getLLMNodeSnapshotPromptByHistoryId } from '@/apis/prompt'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { useDynamicValues } from '../../hooks/useDynamicValues'
import { useFlowDraftStore } from '@/store'
import { Button, IconFont } from '@/components'
import { useCache } from '@/hooks/useCache'
import { useModelChange } from '../../hooks/useModelChange'
import { LLMContextType } from '../const'
import type { ModalSettingValue } from '../components/type'
import { checkedJsonModel } from '../../utils/llm'
import { PromptPanel } from './components/PromptPanel'
import { ResultPanel } from './components/ResultPanel'
import {
  transformInputDataAdapter,
  transformLLMNodeData2LLMNodeFormData,
} from './utils'
import { HistoryPanel } from './components/HistoryPanel'
import { LLMNodePanelContext, type TaskResult, type TaskStatus } from './type'

export const InnerLLMNodePanel = memo((props: InnerNodePanelProps<any>) => {
  const {
    data,
    variables,
    activatedNodeId = '',
    startNodeFormItemType = [],
    onSaveChange,
  } = props

  const { flowId } = useFlowDraftStore()
  const {
    setExtraLeftButton,
    variables: variablesValue,
    setVariables: setVariablesValue,
  } = useContext(DebugResultPanelContext)
  const ref = useRef<HTMLDivElement>(null)
  const promptScrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const [form] = Form.useForm()

  const { beforeChange } = useModelChange(form, props.data.inputs.channel)

  const [loading, setLoading] = useState<boolean>(false)
  const [showHistory, setShowHistory] = useState(false)
  const [historyData, setHistoryData] = useState<any>(data)
  const [historyExtraData, setHistoryExtraData] = useState<any>({
    historyId: '',
    resultInfo: null,
    variables: {},
  })
  const [compare, setCompare] = useCache(
    `FLOW_DIFF_OPEN_${activatedNodeId}`,
    false,
  )

  const panelData = useMemo(() => {
    return historyExtraData.historyId ? historyData : data
  }, [historyExtraData, historyData, data])

  const variableValues = useMemo(() => {
    return historyExtraData.historyId
      ? historyExtraData.variables
      : variablesValue
  }, [historyExtraData.historyId, historyExtraData.variables, variablesValue])

  const resultInfo = useMemo(() => {
    return historyExtraData.historyId ? historyExtraData.resultInfo : null
  }, [historyExtraData.resultInfo, data])

  const changeModalSetting = useMemoizedFn((newValue: ModalSettingValue) => {
    const oldValue = form.getFieldsValue()

    form.setFieldsValue({
      ...oldValue,
      inputs: {
        modelSetting: newValue,
      },
    })
  })

  const changeFormData = useMemoizedFn((newData: any) => {
    const temp = transformLLMNodeData2LLMNodeFormData(newData)
    form.setFieldsValue(temp)
  })

  const getFormData = useMemoizedFn(() => {
    const base = form.getFieldsValue()

    const {
      modelSetting = {},
      stream,
      messages,
      context_type,
    } = base.inputs || {}

    const inputsData = beforeChange({
      modelSetting,
      stream,
      messages: context_type === LLMContextType.MSG_LIST ? messages : undefined,
    })

    // 旧数据兼容
    inputsData.pre_defined_system_content = undefined
    inputsData.messages = inputsData.messages?.filter(
      item => item.role !== 'system',
    )

    if (!checkedJsonModel(inputsData.model)) {
      inputsData.plugin = {}
    }
    const newInput = { ...base.inputs, ...inputsData }
    delete (newInput as any).modelSetting

    const newData = {
      ...data,
      inputs: { ...data.inputs, ...newInput },
    }

    return newData
  })

  const { usedKeyListByNodeId } = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    variables: variables?.map(item => item.label),
    values: panelData,
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      return [
        ...(get(value, 'inputs.messages') || []),
        {
          content: get(value, 'inputs.pre_defined_messages'),
        },
        { content: get(value, 'inputs.system_content') },
      ]
    },
  })

  const onChange = useMemoizedFn(() => {
    if (historyExtraData.historyId) return
    onSaveChange(getFormData(), true)
  })

  const handleUseHistory = useMemoizedFn(async () => {
    setLoading(true)
    const { inputs, variables } = await getLLMNodeSnapshotPromptByHistoryId(
      historyExtraData.historyId!,
    )

    setHistoryExtraData({
      historyId: '',
      resultInfo: {},
      variables: {},
    })
    setVariablesValue(variables, false)

    await onSaveChange({
      inputs: transformInputDataAdapter(inputs),
    })
    setLoading(false)
  })

  const handleShowHistory = useMemoizedFn(async (historyId: string) => {
    if (!historyId) {
      setHistoryExtraData({
        historyId: '',
        resultInfo: {},
        variables: {},
      })
      changeFormData(data)
      setHistoryData({})
      return
    }

    setLoading(true)
    const { inputs, resultInfo, variables } =
      await getLLMNodeSnapshotPromptByHistoryId(historyId)
    const newInputs = transformInputDataAdapter(inputs)

    setHistoryExtraData({
      historyId,
      resultInfo,
      variables,
    })

    const newData = { inputs: newInputs }
    changeFormData(newData)
    setHistoryData(newData)
    setLoading(false)
  })

  const handleCloseHistory = useMemoizedFn(() => {
    setHistoryExtraData({
      historyId: '',
      resultInfo: {},
      variables: {},
    })
    changeFormData(data)
    setHistoryData({})
    setShowHistory(false)
  })

  const handleShowHistoryChange = useMemoizedFn((newShow: boolean) => {
    if (!newShow) {
      handleCloseHistory()
    } else {
      setCompare(false)
      setShowHistory(newShow)
    }
  })

  const handleShowCompareChange = useMemoizedFn((newShow: boolean) => {
    if (newShow) {
      handleCloseHistory()
    }
    setCompare(newShow)
  })

  const handleChangeByDiff = useMemoizedFn((newValue: ModalSettingValue) => {
    changeModalSetting(newValue)
    onChange()
    const viewport = promptScrollRef.current?.osInstance()?.elements().viewport
    viewport?.scroll({
      top: 0,
      behavior: 'smooth',
    })
  })

  const getBaseInputs = useMemoizedFn(() => {
    return merge({}, getFormData().inputs)
  })

  useMemo(() => {
    // 同步执行确保优先级
    changeFormData(data)
  }, [])

  useEffect(() => {
    setExtraLeftButton(
      <Button
        className='text-12px px-12px! ml-12px bg-op-60 !h-32px flex flex-center'
        disabled={compare}
        onClick={() => handleShowHistoryChange(!showHistory)}
      >
        <IconFont name='lishijilu' className='text-14px mr-6px' />
        {showHistory ? '退出历史记录' : '历史'}
      </Button>,
    )
  }, [showHistory, compare])

  return (
    <Spin spinning={loading} delay={1000}>
      <div
        className='llm_node_panel_dom_target bg-#fff flex'
        style={{ height: 'calc(-48px + 100vh)' }}
        ref={ref}
      >
        {showHistory && (
          <HistoryPanel
            flowId={flowId}
            data={panelData}
            readOnly={!!historyExtraData.historyId}
            variables={variableValues}
            resultInfo={resultInfo}
            activatedNodeId={activatedNodeId}
            onUse={handleUseHistory}
            onChange={handleShowHistory}
            onClose={handleCloseHistory}
          />
        )}

        <PromptPanel
          scrollRef={promptScrollRef}
          form={form}
          flowId={flowId}
          activatedNodeId={activatedNodeId}
          readOnly={!!historyExtraData.historyId}
          onCompare={compare}
          usedKeyList={usedKeyListByNodeId}
          variables={variables}
          variableValues={variableValues}
          startNodeFormItemType={startNodeFormItemType}
          onChange={onChange}
          setVariableValues={setVariablesValue}
          getPopupContainer={() => ref.current!}
        />

        <ResultPanel
          form={form}
          flowId={flowId}
          activatedNodeId={activatedNodeId}
          resultInfo={resultInfo}
          variableValue={variablesValue}
          compare={compare}
          disableCompare={showHistory}
          onShowCompare={handleShowCompareChange}
          getBaseInputs={getBaseInputs}
          onChange={handleChangeByDiff}
        />
      </div>
    </Spin>
  )
})

export function LLMNodePanel(props: InnerNodePanelProps<any>) {
  const [statusMap] = useCache(
    `FLOW_RUN_STATUS_${props.activatedNodeId}`,
    new Map<string, TaskStatus>(),
  )
  const [resultMap] = useCache(
    `FLOW_RUN_RESULT_${props.activatedNodeId}`,
    new Map<string, TaskResult>(),
  )
  const runCallSet = useRef(new Set<() => void>())
  const checkCallSet = useRef(new Set<() => void>())
  const statusCallSet = useRef(
    new Set<(map: Map<string, TaskStatus>) => void>(),
  )
  const resultCallSet = useRef(
    new Set<(map: Map<string, TaskResult>) => void>(),
  )

  const onRun = useMemoizedFn(() => {
    checkCallSet.current.forEach(call => call())
    runCallSet.current.forEach(call => call())
  })

  const subscribeRun = useMemoizedFn(
    (_id: string, checkCall: () => void, call: () => void) => {
      runCallSet.current.add(call)
      checkCallSet.current.add(checkCall)

      return () => {
        runCallSet.current.delete(call)
        checkCallSet.current.delete(checkCall)
      }
    },
  )

  const getTaskStatus = useMemoizedFn((id: string) => {
    return statusMap.get(id)
  })

  const setTaskStatus = useMemoizedFn((id: string, state: TaskStatus) => {
    statusMap.set(id, state)
    statusCallSet.current.forEach(call => call(statusMap))
  })

  const subscribeState = useMemoizedFn(
    (call: (map: Map<string, TaskStatus>) => void) => {
      statusCallSet.current.add(call)

      return () => {
        statusCallSet.current.delete(call)
      }
    },
  )

  const getTaskResult = useMemoizedFn((id: string) => {
    return resultMap.get(id)
  })

  const setTaskResult = useMemoizedFn((id: string, result: TaskResult) => {
    resultMap.set(id, result)
    resultCallSet.current.forEach(call => call(resultMap))
  })

  const subscribeResult = useMemoizedFn(
    (call: (map: Map<string, TaskResult>) => void) => {
      resultCallSet.current.add(call)

      return () => {
        resultCallSet.current.delete(call)
      }
    },
  )

  const clearInfo = useMemoizedFn((id: string) => {
    resultMap.delete(id)
    statusMap.delete(id)
  })

  const onUseInfo = useMemoizedFn((id: string) => {
    resultMap.set('current', resultMap.get(id)!)
    statusMap.set('current', statusMap.get(id)!)
    clearInfo(id)
  })

  const value = useMemo(() => {
    return {
      onUseInfo,
      clearInfo,
      onRun,
      subscribeRun,
      getTaskStatus,
      setTaskStatus,
      subscribeState,
      getTaskResult,
      setTaskResult,
      subscribeResult,
    }
  }, [])

  return (
    <LLMNodePanelContext.Provider value={value}>
      <InnerLLMNodePanel {...props} />
    </LLMNodePanelContext.Provider>
  )
}
