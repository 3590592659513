import { useState } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Form } from 'antd'
import { Modal } from '@/components'
import useNiceModal from '@/features/database/hooks/useNiceModal.ts'
import type { Sheet } from '@/features/database/types/tableImport.ts'
import type { FileParsedProps } from '@/features/database/components/TableImportModalContent'
import {
  DeltaFileParsed,
  FileParsed,
  FileUpload,
} from '@/features/database/components/TableImportModalContent'

export type TableImportModalProps = Pick<
  FileParsedProps,
  'useInAgent' | 'database' | 'onOk' | 'onError'
> &
  (
    | { delta?: undefined; tableId?: undefined }
    | {
        /**
         * 是否增量导入数据
         */
        delta: true
        /**
         * 增量导入时需要
         */
        tableId: string
      }
  )

function TableImportModal({
  useInAgent,
  database,
  delta,
  tableId,
  onOk,
  onError,
}: TableImportModalProps) {
  const [form] = Form.useForm<{ sheets: Sheet[] }>()
  const { modal, handleClose } = useNiceModal()
  const [isUploaded, setIsUploaded] = useState(false)
  const [sheets, setSheets] = useState<Sheet[]>([])

  const ModalTitle = isUploaded ? (
    delta ? (
      '字段映射设置'
    ) : (
      <div className='px-16px py-6 b-b b-font_1 b-op-8 leading-36px'>
        <span>编辑数据表</span>
        {sheets.length > 1 && (
          <span className='p-4 c-tips bg-tips bg-op-12 text-12px rd-4px ml-12'>
            {sheets.length}个可用 Sheet
          </span>
        )}
      </div>
    )
  ) : (
    '导入数据表'
  )

  const onFileUploadAndParse = (data: Sheet[]) => {
    setSheets(data)
    setIsUploaded(true)
  }

  return (
    <Modal
      className='min-w-800px !w-60%'
      styles={{ body: { padding: 0 } }}
      title={ModalTitle}
      open={modal.visible}
      maskClosable={false}
      footer={null}
      onCancel={handleClose}
    >
      {isUploaded ? (
        delta ? (
          <DeltaFileParsed
            sheet={{
              columns: sheets[0].columns,
              data: sheets[0].data as unknown as Record<string, unknown>[],
            }}
            tableId={tableId}
            onOk={tableId => {
              onOk?.(tableId)
              handleClose()
            }}
            onCancel={handleClose}
          />
        ) : (
          <FileParsed
            form={form}
            useInAgent={useInAgent}
            database={database}
            sheets={sheets}
            onOk={async id => {
              await onOk?.(id)
              handleClose()
            }}
            onError={onError}
            onCancel={handleClose}
          />
        )
      ) : (
        <FileUpload
          sheetLimit={delta ? 1 : undefined}
          onSuccess={onFileUploadAndParse}
        />
      )}
    </Modal>
  )
}

export default NiceModal.create(TableImportModal)
