import classNames from 'classnames'

export function Role({
  role,
  onClick,
  readOnly = false,
}: {
  role: string
  onClick?: (role: string) => void
  readOnly?: boolean
}) {
  return (
    <div
      className={classNames(
        'bg-white b-rd-4px b-1px b-#E1E1E5 text-font px-5 py-2 text-12px',
        {
          'important:c-[rgba(141,141,153,0.4)]': readOnly,
          'cursor-pointer': !readOnly,
        },
      )}
      onClick={() => onClick?.(role)}
    >
      {role}
    </div>
  )
}
