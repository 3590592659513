import axios from 'axios'
import { REQUEST_TIMEOUT } from '@/constants/request.ts'

const KEYU_DB_BASE_URL = 'https://salyut2.bantouyan.com'
const REQUEST_URL = `${KEYU_DB_BASE_URL}/api/v1/db/data/v1/${
  import.meta.env.VITE_KEYU_DB_WAIT_LIST_PROJECT_ID
}`
const request = axios.create({
  baseURL: REQUEST_URL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    'xc-auth':
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDc5IiwiY2xpZW50Ijoibm9jb19hcGkiLCJleHAiOjUyOTQ1MDM1MTJ9.cRwJsxHKy8s8U0qmJmsvCBNeYUpx80xs1CEIEjmmH0M',
  },
})

export interface AccessRecord {
  userId: number
  username: string
  demand: string
  otherOccupation: string
  phone: string
  company: string
  occupation: string
}
export function getAccessInfo(userId: number) {
  return request.get('/wait-list/find-one', {
    params: {
      where: `(userId, eq, ${userId})`,
    },
  })
}

export function applyAccess(req: AccessRecord) {
  return request.post('/wait-list', req)
}

interface AgentGoRequest {
  username: string
  userId: number
  platforms: string
  role: string
  scene: string
}
export function addAgentGoRecord(req: AgentGoRequest) {
  return request.post('/agent-go', req)
}

export interface NewPackageForm {
  name: string
  version: string
  language: 'python' | 'javascript'
  url?: string
  scene?: string
  userId?: number
}

export function addCodeEditorPackage(req: NewPackageForm) {
  return request.post('/code-package-submission', req)
}
