import classNames from 'classnames'
import { omit } from 'lodash-es'
import { useEffect, useMemo, useRef } from 'react'
import { useParams, matchPath } from 'react-router-dom'
import { Form } from 'antd'
import type { FormSchema } from '@bty/ui-components'
import { useMemoizedFn, useRequest } from 'ahooks'
import type { JsonFormConfig } from '../../components'
import { JsonForm, NodeFormItem } from '../../components'
import { componentMap } from '../../subflow/components/componentMap'
import type { NodeComponent } from '@/features/nodes/base'
import {
  NodeOperationTypes,
  NodeType,
  SUB_FLOW_STATUS,
} from '@/features/nodes/base'
import { UPDATE_FLOW_SIGNAL } from '@/pages/flowPage/const'
import { useFlowDraftStore } from '@/store/flowDraft'
import { pubSignal, useSubSignal } from '@/hooks/useSignal'
import { getFlowById } from '@/apis/flow'
import { IconFont } from '@/components'

const FLOW_PACKAGE_NAME = 'integration.flow.flowAction'

export interface TemplateNodeData {
  name: string
  packageName: string
  comment?: string
  template_id?: string
  helpLink?: string
  source_id?: string
  flow_id?: string
  flow_status?: SUB_FLOW_STATUS
  inputs?: {
    formConfig?: Record<string, any>
  }
  output?: Record<string, any>
}

export const TemplateNode: NodeComponent<TemplateNodeData> = props => {
  const { id, focused, data, variables, isOpenedPanel, onOpenPanel } = props

  const {
    loading: flowInfoLoading,
    data: flowInfo,
    refresh: refreshFlowInfo,
  } = useRequest(
    () =>
      getFlowById(data.flow_id!, {
        headers: { 'Application-Id': data.flow_id! },
        noRedirect: true,
        ignoreError: true,
      }),
    {
      ready: !!data.flow_id && data.flow_status !== SUB_FLOW_STATUS.DELETED,
    },
  )

  const isDelete = useMemo(
    () =>
      data.flow_status === SUB_FLOW_STATUS.DELETED ||
      (!flowInfoLoading && !flowInfo),
    [flowInfoLoading, flowInfo, data.flow_status],
  )

  const { workspaceId } = useParams()
  const containerRef = useRef<HTMLDivElement>(null)
  const [form] = Form.useForm()
  const needReload = useRef(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const nodeError = useFlowDraftStore(s => s.nodeErrorStatusMap[id])

  const handleRefresh = useMemoizedFn(async () => {
    pubSignal(UPDATE_FLOW_SIGNAL, iframeRef.current?.contentWindow)
    if (data.flow_id) {
      refreshFlowInfo()
    }
    needReload.current = false
  })

  const flowIsInit = useMemo(() => {
    if (!flowInfo) return false
    return (
      flowInfo?.created_at === flowInfo?.updated_at && !flowInfo.is_published
    )
  }, [flowInfo])

  const previewFlowSrc = useMemo(() => {
    if (!flowInfo) return ''
    const previewMatch = matchPath(
      '/application/:workspaceId/:flowId/flow/:flowId/preview',
      location.pathname,
    )
    if (previewMatch) return ''
    if (!data) return ''
    return `/application/${workspaceId}/${data.flow_id}/flow/${data.flow_id}/preview?autowidth`
  }, [data, flowInfo, workspaceId])

  const templateFormConfig = useMemo(() => {
    return (flowInfo?.form_config ?? []) as FormSchema[]
  }, [flowInfo])

  const list = useMemo<JsonFormConfig[]>(() => {
    const formConfig = templateFormConfig || []

    const items = formConfig.map(item => {
      const Component = (componentMap as any)[item.type]

      return {
        className: classNames('w-100% !mb-0px pb-16px'),
        required: item.required,
        render: () => {
          return (
            <NodeFormItem
              {...omit(item, 'variableName')}
              shouldUpdate
              className='!mb-0px'
              name={['inputs', item.variableName]}
              key={`${item.variableName}_${data.flow_id}`}
              rules={[{ required: item.required, message: item.placeholder }]}
            >
              {Component({
                ...item,
                variables,
                variableTipsContainer: containerRef?.current,
              })}
            </NodeFormItem>
          )
        },
      }
    })

    return items
  }, [variables, containerRef.current, data, templateFormConfig, form])

  const jsonForm = useMemo(() => {
    if (isDelete) return
    return <JsonForm list={list} form={form} />
  }, [isDelete, list, form, data])

  useSubSignal(UPDATE_FLOW_SIGNAL, () => {
    needReload.current = true
  })

  useEffect(() => {
    if (needReload.current && !isOpenedPanel) {
      handleRefresh()
    }
  }, [isOpenedPanel])

  return (
    <div ref={containerRef} className='pt-16 px-16px h-100% relative'>
      <div className='mb-16px'>
        {flowIsInit && (
          <div className='flex items-center h-32px bg-warning/8 rounded-6px mb-8px px-12px text-12px'>
            <IconFont name='jingzhi' className='mr-8px' />
            <span>请进入调整发布模板流程，否则无法运行</span>
          </div>
        )}

        {isDelete && (
          <div className='flex items-center h-32px bg-#ff5219/8 rounded-6px mb-8px px-12px text-12px'>
            <IconFont name='cuowu' className='text-12px m-r-8' />
            <span className='c-#99310f'>模板已删除，无法使用</span>
          </div>
        )}

        {!isDelete && (
          <div
            className={classNames(
              'relative rounded-6px overflow-hidden [&:hover_.template-tip]:opacity-100 hover:outline-1 hover:outline-solid hover:outline-primary h-220px',
              {
                'outline-1 outline-solid !outline-error': nodeError,
              },
            )}
          >
            {previewFlowSrc && (
              <iframe
                className={classNames('b-none w-full h-220px', {
                  'pointer-events-none': !focused,
                })}
                src={previewFlowSrc}
                ref={iframeRef}
              />
            )}
            <div
              className='template-mask absolute inset-0'
              onClick={onOpenPanel}
            ></div>
            <div
              style={{
                color: 'rgba(123, 97, 255, 1)',
                background: 'rgba(123, 97, 255, 0.08)',
              }}
              className='template-tip absolute opacity-0 duration-300 right-8px bottom-8px px-8px py-4px font-500 text-12px border-rd-4px'
              onClick={onOpenPanel}
            >
              编辑模版工作流
            </div>
          </div>
        )}

        {nodeError && (
          <div className='mt-8px text-error text-12px'>
            运行失败，请进入工作流检查
          </div>
        )}
      </div>

      {jsonForm}
    </div>
  )
}

TemplateNode.meta = {
  type: NodeType.TEMPLATE,
  operationType: NodeOperationTypes.TEMPLATE_NODE,
  // 执行逻辑为 subFlow
  actionType: NodeType.FLOW,
  typeName: 'Template',
  icon: 'flowjiedian-1',
  backgroundColor: '#FFF',
  description: '调用已发布的Flow',
  canDelete: true,
  initialData: {
    name: 'template',
    packageName: FLOW_PACKAGE_NAME,
    comment: '',
    template_id: undefined,
    source_id: '',
    flow_id: '',
    inputs: {},
    output: {},
  },
}
