import { useMemoizedFn } from 'ahooks'
import { noop } from 'lodash-es'
import type { ReactElement } from 'react'
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'

type CellCall = (isSelect: boolean, id: string) => void
type RowCall = (isSelect: boolean) => void
type AllCall = (isSelect: boolean | 'half') => void
type WidthCall = (map: Map<string, number>) => void

interface ResizeTableContextProps {
  onRowSelect: (row: string) => void
  onRowSelectAll: (isSelect: boolean) => void
  onCellSelect: (cell: string) => void
  onWidthChange: (cell: string, width?: number) => void
  observeRow: (row: string, call: RowCall) => () => void
  observeAllRow: (call: AllCall) => () => void
  observeCell: (cell: string, call: CellCall) => () => void
  observeWidth: (call: WidthCall) => () => void
}

export const ResizeTableContext = createContext<ResizeTableContextProps>({
  onRowSelect: noop,
  onRowSelectAll: noop,
  onCellSelect: noop,
  onWidthChange: noop,
  observeRow: () => () => {},
  observeAllRow: () => () => {},
  observeCell: () => () => {},
  observeWidth: () => () => {},
})

interface ResizeTableProviderProps {
  select?: string[]
  onSelect?: (select: string[]) => void
  children: ReactElement
}

export const ResizeTableProvider = memo(
  ({ select, onSelect, children }: ResizeTableProviderProps) => {
    const selectCell = useRef<string>('')
    const selectRows = useRef<Set<string>>(new Set())
    const widthMap = useRef<Map<string, number>>(new Map())
    const rowCall = useRef<Map<string, RowCall>>(new Map())
    const allRowCall = useRef<AllCall>()
    const cellCall = useRef<Map<string, CellCall>>(new Map())
    const widthCall = useRef<WidthCall>()

    const checkAllRowSelect = useMemoizedFn(() => {
      const isAllSelected =
        rowCall.current.size !== 0 &&
        selectRows.current.size === rowCall.current.size
      const isAllNoSelected = selectRows.current.size === 0
      allRowCall.current?.(isAllSelected || (isAllNoSelected ? false : 'half'))
    })

    const onRowSelect = useMemoizedFn((row: string) => {
      if (selectRows.current.has(row)) {
        selectRows.current.delete(row)
      } else {
        selectRows.current.add(row)
      }
      const call = rowCall.current.get(row)
      call && call(selectRows.current.has(row))
      checkAllRowSelect()
      onSelect?.([...selectRows.current])
    })

    const onRowSelectAll = useMemoizedFn((selected: boolean) => {
      for (const [key, call] of rowCall.current) {
        if (selected) {
          selectRows.current.add(key)
        } else {
          selectRows.current.delete(key)
        }
        call(selected)
      }
      allRowCall.current?.(selected)
      onSelect?.([...selectRows.current])
    })

    const onCellSelect = useMemoizedFn((cell: string) => {
      // if (selectCell.current === cell) {
      //   selectCell.current = ''
      // } else {
      //   selectCell.current = cell
      // }
      selectCell.current = cell
      for (const [key, call] of cellCall.current) {
        call(selectCell.current === key, selectCell.current)
      }
    })

    const onWidthChange = useMemoizedFn((cell: string, width?: number) => {
      if (!width) {
        widthMap.current.delete(cell)
      } else {
        widthMap.current.set(cell, width)
      }

      widthCall.current?.(widthMap.current)
    })

    const observeRow = useMemoizedFn((row: string, call: RowCall) => {
      rowCall.current.set(row, call)
      call(selectRows.current.has(row))
      checkAllRowSelect()
      return () => {
        selectRows.current.delete(row)
        rowCall.current.delete(row)
        onSelect?.([...selectRows.current])
        checkAllRowSelect()
      }
    })

    const observeAllRow = useMemoizedFn((call: AllCall) => {
      allRowCall.current = call
      checkAllRowSelect()
      return () => {
        allRowCall.current = undefined
      }
    })

    const observeCell = useMemoizedFn((cell: string, call: CellCall) => {
      cellCall.current.set(cell, call)
      call(cell === selectCell.current, selectCell.current)
      return () => {
        if (selectCell.current === cell) {
          selectCell.current = ''
          onCellSelect(selectCell.current)
        }
        cellCall.current.delete(cell)
      }
    })

    const observeWidth = useMemoizedFn((call: WidthCall) => {
      widthCall.current = call
      call(widthMap.current)
      return () => {
        widthCall.current = undefined
      }
    })

    const value = useMemo(() => {
      return {
        onRowSelect,
        onRowSelectAll,
        onCellSelect,
        onWidthChange,
        observeRow,
        observeAllRow,
        observeCell,
        observeWidth,
      }
    }, [])

    useEffect(() => {
      if (!select) return
      select.forEach(row => {
        const call = rowCall.current.get(row)
        call && call(true)
      })
    }, [select])

    return (
      <ResizeTableContext.Provider value={value}>
        {children}
      </ResizeTableContext.Provider>
    )
  },
)

export function useResizeTable() {
  const value = useContext(ResizeTableContext)
  return value
}
