import type { ReactNode } from 'react'
import React from 'react'
import { IconFont, Progress } from '@/components'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { isPrivateVersion } from '@/constants/common.ts'

export function ComboProcessCard({
  isInfinite = false,
  currentNum = 0,
  totalNum = 0,
  name,
  unit,
  processLeftColor,
  processRightColor,
  icon,
  iconColor,
  int,
  background,
  tabComp,
  buttonComp,
  ...otherProps
}: {
  isInfinite?: boolean
  currentNum?: number
  totalNum?: number
  processLeftColor: string
  processRightColor: string
  icon: string
  iconColor: string
  name: ReactNode
  unit: string
  int?: React.ReactNode
  background?: string
  tabComp?: React.ReactNode
  buttonComp?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...otherProps}
      className={`${
        otherProps.className || ''
      } p-20px border-rd-8px flex flex-col justify-between flex-1 bg-gradient-to-t from-#626999/0 to-#626999/4`}
      style={{ background, ...otherProps.style }}
    >
      <div className='flex flex-items-center justify-between'>
        <div className='flex gap-8px flex-items-center'>
          <IconFont style={{ fontSize: 18, color: iconColor }} name={icon} />
          <div className='c-font text-16px font-600'>{name}</div>
        </div>
        {tabComp}
      </div>
      {int}
      <div>
        <div className='flex justify-between items-end'>
          {isInfinite || isPrivateVersion ? (
            <div className='text-14px font-400 pt-10px pb-6px'>
              <span className='font-600 text-20px'>{currentNum}</span>
              <span style={{ color: 'rgba(23, 23, 29, 0.8)' }}>{unit}</span>
            </div>
          ) : (
            <div className='text-14px font-400 pt-10px pb-6px'>
              <span className='font-600 text-20px'>{currentNum}</span>
              <span
                style={{ color: 'rgba(23, 23, 29, 0.8)' }}
              >{`/${totalNum}${unit}`}</span>
            </div>
          )}
          {buttonComp}
        </div>
        <PrivateDeployGuard>
          <Progress
            style={{ height: 16 }}
            showInfo={false}
            type='line'
            percent={(currentNum / totalNum) * 100}
            strokeColor={{ '0%': processLeftColor, '100%': processRightColor }}
          />
        </PrivateDeployGuard>
      </div>
    </div>
  )
}
