import { Popover } from 'antd'
import { memo } from 'react'
import { CodeBlock } from '@/components/CodeBlock'

const example = `[
  {
    "contents": [
      {
        "content": "这是一个 FAQ 示例。",
        "keywords": ["示例", "新内容"]
      },
      {
        "content": "这是相似FAQ的内容示例， 和前一个属于同一个答案组",
        "keywords": ["示例", "另一个"]
      }
    ],
    "answers": {
      "zh": "这是两个问题共同的答案。",
      "en": "This is the answer for the FAQ group."
    }
  },
  {
    "contents": [
      {
        "content": "这是第二个 FAQ 示例。",
        "keywords": ["示例", "新内容"]
      }
    ],
    "answers": {
      "zh": "这是第二个问题共同的答案。",
      "en": "This is the answer for the FAQ group."
    }
  }
]`

function KnowledgeContentExampleContent() {
  return (
    <Popover
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      trigger={['click']}
      overlayInnerStyle={{
        minWidth: 320,
        padding: '12px 16px',
      }}
      arrow={false}
      placement='bottom'
      align={{ offset: [0, 12] }}
      content={
        <>
          <div className='mb-12px line-height-16px text-12px font-500 text-#17171d'>
            数据内容的JSON格式请参照示例
          </div>
          <CodeBlock
            codeFont='12px'
            str={example}
            title='示例'
            lang='json'
            className='bg-white b-1 b-#e1e1e5 b-op-60'
            titleFont='12px'
          />
        </>
      }
    >
      <div className='absolute bottom-10 right-12 z-2 cursor-pointer c-#7B61FF'>
        示例
      </div>
    </Popover>
  )
}

export const KnowledgeContentExample = memo(KnowledgeContentExampleContent)
