export const segmentedOptions = [
  { label: 'Javascript', value: 'javascript' },
  { label: 'Python', value: 'python' },
]

export const JAVASCRIPT_DEFAULT_CODE = `const Betteryeah = require('betteryeah').default
const { LLMModel } = require('betteryeah')
const betteryeah = new Betteryeah()

/**
* 必须保证节点中存在一个main函数
* 你的代码应该在main函数中编写
* main函数的返回值为当前节点的返回值
*/
async function main() {
  const res = await betteryeah.llm.chat({
    systemPrompt: '你好',
    model: LLMModel.gpt_35_turbo,
  })

  if (!res.success) {
    throw new Error(res.message)
  }
  return res.data
}`

export const PYTHON_DEFAULT_CODE = `from betteryeah import BetterYeah, Model
better_yeah = BetterYeah()

# 必须保证节点中存在一个main函数
# 你的代码应该在main函数中编写
# main函数的返回值为当前节点的返回值
async def main():
    res = await better_yeah.llm.chat(system_prompt="你好", model=Model.gpt_3_5_turbo)
    if not res.success:
        raise Exception(message="执行失败")
    return res.data
`

export function addJavaScriptCodeWrapper(code: string) {
  return `/**
* 必须保证节点中存在一个main函数
* 你的代码应该在main函数中编写
* main函数的返回值为当前节点的返回值
*/
async function main() {\n${code
    .split('\n')
    .map(line => `  ${line}`)
    .join('\n')}\n}`
}

export function addPythonCodeWrapper(code: string) {
  return `# 必须保证节点中存在一个main函数
# 你的代码应该在main函数中编写
# main函数的返回值为当前节点的返回值
async def main():\n${code
    .split('\n')
    .map(line => `    ${line}`)
    .join('\n')}`
}

export function convertOldCodeInput(
  code: string,
  language: 'javascript' | 'python',
) {
  if (language === 'javascript') {
    return addJavaScriptCodeWrapper(code)
  } else {
    return addPythonCodeWrapper(code)
  }
}
