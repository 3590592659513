import { useMemo } from 'react'
import { useRequest } from 'ahooks'
import { getAgentUploadFileSupportFileTypes } from '@/apis/agent'
import { transformFileSupportTypesRawData } from '../util'

export function useAgentUploadFileConfig(id: string) {
  const {
    data: fileSupportTypesRawData,
    refresh: refreshAgentUploadLoadSupportFileTypes,
  } = useRequest(() => getAgentUploadFileSupportFileTypes(id!), {
    refreshDeps: [id],
    ready: !!id,
  })

  const uploadFileConfig = useMemo(() => {
    return transformFileSupportTypesRawData(fileSupportTypesRawData || [])
  }, [fileSupportTypesRawData])

  return {
    uploadFileConfig,
    refreshAgentUploadLoadSupportFileTypes,
  }
}
