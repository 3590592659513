import type { CheckboxProps, RadioChangeEvent } from 'antd'
import { message, Row } from 'antd'
import { memo, useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'
import styled from '@emotion/styled'
import { Button, IconFont } from '@/components'
import { CodeBlock } from '@/components/CodeBlock'
import {
  CodeNodeCheckBox,
  CodeNodeCheckBoxGroup,
  CodeNodeRadio,
  CodeNodeRadioGroup,
} from '../StyledComponent'
import { useTrack } from '@/features/track/Track.tsx'
import {
  getFlowConfigCopyValue,
  getFlowFormItemType,
} from '@/features/nodes/code/CodeNodePanel/Components/CodeExample/utils.ts'
import type { TreeTitleRenderProps } from './TreeTitleRender'

interface PopoverContentProps extends TreeTitleRenderProps {}

interface SelectedState {
  tags: string[]
  fileList: string | undefined
  tableList: string | undefined
  params: string | undefined
}

type radioFieldType = 'fileList' | 'tableList'

const SelectPanel = styled.div`
  border: 1px solid rgba(225, 225, 229, 0.6);
  border-radius: 6px;

  .header {
    background: rgba(98, 105, 153, 0.06);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #17171d;
    padding: 8px 12px;
    min-width: 288px;
  }

  .content {
    padding: 4px 4px 0 4px;
  }
`

// TODO 这里职责太乱了，需要根据不同的资源类型分而治之
function generateCopyValue(
  dataStoreCheckedValue: SelectedState,
  language: 'python' | 'javascript',
) {
  const tagsValue = dataStoreCheckedValue.tags
    .map(item => `'${item}'`)
    .join(',')
  const fileListValue = dataStoreCheckedValue.fileList
  const tableListValue = dataStoreCheckedValue.tableList
  const paramsValue = dataStoreCheckedValue.params
  let str = paramsValue ?? ''
  if (language === 'python') {
    if (tagsValue) {
      str += `tags = [${tagsValue}]`
    }
    if (fileListValue) {
      str += `${tagsValue && ', '}file_ids = [${fileListValue}]`
    }
  } else {
    if (tagsValue) {
      str += `tags: [${tagsValue}]`
    }
    if (fileListValue) {
      str += `${tagsValue && ', '}fileIds: [${fileListValue}]`
    }
  }
  if (tableListValue) {
    str += `${tableListValue}`
  }
  copy(str)
  if (str) {
    message.success('复制成功')
  }
}

function PopoverContentWioutMemo(props: PopoverContentProps) {
  const {
    code,
    type,
    tags,
    fileList,
    params,
    language,
    tableList,
    trackMetaData,
  } = props

  const { doTrack } = useTrack()

  const [dataStoreCheckedValue, setDataStoreCheckedValue] =
    useState<SelectedState>({
      tags: [],
      params: undefined,
      fileList: undefined,
      tableList: undefined,
    })

  useEffect(() => {
    if (params) {
      setDataStoreCheckedValue({
        ...dataStoreCheckedValue,
        params: getFlowConfigCopyValue(params),
      })
    }
  }, [params])

  const handleTagCkeckboxChange = (checkedValue: string[]) => {
    setDataStoreCheckedValue({
      ...dataStoreCheckedValue,
      tags: checkedValue as string[],
    })
  }

  const handleTagCheckBoxCheckAllChange: CheckboxProps['onChange'] = e => {
    const checked = e.target.checked
    const tagsValue = tags.map(tag => tag)
    setDataStoreCheckedValue({
      ...dataStoreCheckedValue,
      tags: checked ? tagsValue : [],
    })
  }

  const handleRadioGroupChange: (
    e: RadioChangeEvent,
    type: radioFieldType,
  ) => void = (e, type) => {
    const value = e.target.value
    setDataStoreCheckedValue({
      ...dataStoreCheckedValue,
      ...{
        [type]: value,
      },
    })
  }

  const radioCancleChoose = (type: radioFieldType) => {
    setDataStoreCheckedValue({
      ...dataStoreCheckedValue,
      ...{
        [type]: undefined,
      },
    })
  }

  const indeterminate =
    dataStoreCheckedValue.tags.length > 0 &&
    dataStoreCheckedValue.tags.length < tags.length

  const checkAll = dataStoreCheckedValue.tags.length === tags?.length

  return (
    <div className='mt-12px max-w-800px'>
      {code && (
        <CodeBlock
          height='calc(100vh * 0.7 - 156px)'
          lang={language}
          str={code}
          codeFont={'12px'}
          onCopy={() => {
            copy(code)
            doTrack('flow_code_example_copy', {
              ...trackMetaData,
              example: props.title,
            })
            message.success('示例已复制到剪切板')
          }}
        />
      )}
      {type === 'DATASTORE' && (
        <>
          {tags && tags.length > 0 && (
            <SelectPanel>
              <div className='header'>数据标签</div>
              <div className='content max-h-[calc(100vh*0.3-126px)] overflow-y-auto'>
                <CodeNodeCheckBoxGroup
                  value={dataStoreCheckedValue.tags}
                  onChange={handleTagCkeckboxChange}
                >
                  {tags.map(tag => (
                    <Row key={tag}>
                      <CodeNodeCheckBox value={tag} key={tag}>
                        {tag}
                      </CodeNodeCheckBox>
                    </Row>
                  ))}
                </CodeNodeCheckBoxGroup>
              </div>
              <div
                className='content'
                style={{
                  borderTop: '1px solid rgba(225, 225, 229, 0.6)',
                }}
              >
                <CodeNodeCheckBox
                  indeterminate={indeterminate}
                  onChange={handleTagCheckBoxCheckAllChange}
                  checked={checkAll}
                >
                  全选
                </CodeNodeCheckBox>
              </div>
            </SelectPanel>
          )}
          {fileList && fileList.length > 0 && (
            <>
              <SelectPanel className='mt-12px'>
                <div className='header'>数据文件</div>
                <div className='content max-h-[calc(100vh*0.3-84px)] overflow-y-auto'>
                  <CodeNodeRadioGroup
                    onChange={e => handleRadioGroupChange(e, 'fileList')}
                    value={dataStoreCheckedValue.fileList}
                  >
                    {fileList.map(file => {
                      return (
                        <Row key={file.value}>
                          <CodeNodeRadio
                            onClick={() => radioCancleChoose('fileList')}
                            value={file.value}
                            key={file.value}
                          >
                            {file.name}
                          </CodeNodeRadio>
                        </Row>
                      )
                    })}
                  </CodeNodeRadioGroup>
                </div>
              </SelectPanel>
            </>
          )}
          {tableList && tableList?.length > 0 && (
            <>
              <SelectPanel className='mt-12px'>
                <div className='header'>数据表</div>
                <div className='content max-h-[calc(100vh*0.6-156px)] overflow-y-auto'>
                  <CodeNodeRadioGroup
                    onChange={e => handleRadioGroupChange(e, 'tableList')}
                    value={dataStoreCheckedValue.tableList}
                  >
                    {tableList.map(table => {
                      return (
                        <Row key={table.name}>
                          <CodeNodeRadio
                            onClick={() => radioCancleChoose('tableList')}
                            value={table.value}
                            key={table.value}
                          >
                            {table.name}
                          </CodeNodeRadio>
                        </Row>
                      )
                    })}
                  </CodeNodeRadioGroup>
                </div>
              </SelectPanel>
            </>
          )}
          {params && params.length > 0 && (
            <SelectPanel className='mt-12 min-w-320px'>
              <div className='header flex items-center'>
                <div className='w-50%'>参数名</div>
                <div className='w-30%'>变量名</div>
                <div className='w-20%'>类型</div>
              </div>
              <div className='content max-h-[calc(100vh*0.6-156px)] overflow-y-auto'>
                {params.map(param => {
                  return (
                    <Row
                      className='py-8 px-12 text-12px'
                      key={param.variableName}
                    >
                      <div className='w-50%'>{param.label}</div>
                      <div className='w-30%'>{param.variableName}</div>
                      <div className='w-20%'>
                        {getFlowFormItemType(param.type)}
                      </div>
                    </Row>
                  )
                })}
              </div>
            </SelectPanel>
          )}
          <div className='mt-12px text-right'>
            <Button
              className='!py-8px !px-12px !h-32px inline-flex items-center'
              onClick={() => {
                generateCopyValue(dataStoreCheckedValue, language)
              }}
              disabled={
                dataStoreCheckedValue.tags.length === 0 &&
                !dataStoreCheckedValue.fileList &&
                !dataStoreCheckedValue.tableList &&
                !dataStoreCheckedValue.params
              }
            >
              <IconFont
                className='text-16px text-#626999 text-op-60 mr-4px'
                name='copy'
              />
              <span className='text-12px'>复制</span>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export const PopoverContent = memo(PopoverContentWioutMemo)
