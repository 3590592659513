import styled from '@emotion/styled'
import { Badge as AntdBadge } from 'antd'

export const Badge = styled(AntdBadge)`
  .ant-badge-count {
    .current {
      position: relative;
      top: -0.5px;
      left: -0.5px;
      font-family: sans-serif;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    line-height: 16px;
    height: 16px;
    padding: 0 4px;
    box-shadow: none !important;
  }
`
