import { memo, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useMemoizedFn, useUpdateEffect } from 'ahooks'
import { TextEditor } from '@/features/editor/TextEditor'

export const Prompt = memo((props: any) => {
  const {
    className,
    readOnly = false,
    disabled = false,
    variables,
    value,
    onChange,
    fullscreen,
    setFullscreen,
  } = props

  const ref = useRef<HTMLDivElement>(null)
  const [editorValue, setValue] = useState<string>(value || '')

  useEffect(() => {
    setValue(value || '')
  }, [value])

  useUpdateEffect(() => {
    onChange(editorValue)
  }, [editorValue])

  const handleChange = useMemoizedFn((e: string) => {
    if (!readOnly) {
      setValue(e)
    }
  })

  return (
    <div ref={ref}>
      <TextEditor
        className={classNames(
          className,
          'text-14px bg-#fff! border-#E1E1E5! ',
          {
            'hover:border-#7b61ff': !readOnly && !disabled,
            'cursor-not-allowed': disabled,
          },
        )}
        anchor='right'
        height={fullscreen ? 'calc(100vh - 282px)' : '400px'}
        value={editorValue}
        readonly={readOnly}
        disabled={disabled}
        placeholder='输入内容，如：“你是一个广告文案撰写专家”'
        onChange={handleChange}
        variables={variables}
        showFullscreen={true}
        fullscreen={fullscreen}
        onFullscreenChange={setFullscreen}
      />
    </div>
  )
})
