import type { Edge, Node } from 'reactflow'
import { NodeType } from '@/features/nodes/base'
import { SPACE_BETWEEN_NODES } from '..'
import {
  isConditionNode,
  isIntentNode,
  isLoopNode,
  isNestableNode,
} from '../../utils'
import { getNodeBoundary } from '../boundary'
import { nestNodeAutoLayout } from './nest'

export function getNextNode(
  nodes: Node[],
  edges: Edge[],
  node: Node,
  endType?: NodeType,
) {
  if (node.type === NodeType.END) {
    return null
  }
  let nextNode = null
  if (isConditionNode(node)) {
    const resultNode = nodes.find(
      n => n.id === node.data.relation.conditionResultId,
    )!
    const nextId = edges.find(e => e.source === resultNode.id)?.target
    nextNode = nodes.find(n => n.id === nextId)
  } else if (isIntentNode(node)) {
    const resultNode = nodes.find(
      n => n.id === node.data.relation.conditionResultId,
    )!
    const nextId = edges.find(e => e.source === resultNode.id)?.target
    nextNode = nodes.find(n => n.id === nextId)
  } else if (isLoopNode(node)) {
    const resultNode = nodes.find(
      n => n.id === node.data.relation.loopResultId,
    )!
    const nextId = edges.find(e => e.source === resultNode.id)?.target
    nextNode = nodes.find(n => n.id === nextId)
  } else {
    const currentEdge = edges.find(e => e.source === node.id)
    nextNode = nodes.find(n => n.id === currentEdge?.target)
  }
  if (endType && nextNode?.type === endType) {
    return null
  }
  return nextNode || null
}

export function flowAutoLayout(nodes: Node[], edges: Edge[]) {
  const config = { nodes: [...nodes], edges: [...edges] }
  const startNode = config.nodes.find(n => n.type === NodeType.START)!
  let current = null
  let nextNode: Node | null = startNode
  while (nextNode) {
    if (isNestableNode(nextNode)) {
      nestNodeAutoLayout(nextNode, nodes, edges)
    }
    if (current && nextNode) {
      const currentNodeBoundary = getNodeBoundary(current, nodes, edges)
      const nextNodeBoundary = getNodeBoundary(nextNode, nodes, edges)
      // 下个节点与前一个节点统一y轴位置
      nextNode.position.y = current.position.y
      const gap = nextNodeBoundary.left - currentNodeBoundary.right
      const isGapProper = gap >= SPACE_BETWEEN_NODES - 2 && gap <= 100
      if (!isGapProper) {
        nextNode.position.x = currentNodeBoundary.right + SPACE_BETWEEN_NODES
        if (isNestableNode(nextNode)) {
          nestNodeAutoLayout(nextNode, nodes, edges)
        }
      }
    }
    current = nextNode
    nextNode = getNextNode(nodes, edges, current)
  }
  return config
}
