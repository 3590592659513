import classNames from 'classnames'
import React, { useMemo } from 'react'
import { IconFont } from '@/components'

interface BatchProgressProps {
  currentStep?: number
  totalSteps?: number
  onStop?: () => void
  onClose?: () => void
  status: 'running' | 'done'
  successCount?: number
  errorCount?: number
  className?: string
}

export const BatchProgress: React.FC<BatchProgressProps> = ({
  currentStep = 0,
  totalSteps = 0,
  successCount,
  errorCount,
  status,
  onStop,
  onClose,
  className,
}) => {
  // const percentage = Math.round((currentStep / totalSteps) * 100)

  const Icon = useMemo(() => {
    return status === 'running' ? (
      <img
        width={16}
        className='animate-spin'
        src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/flow/loading.png'
      />
    ) : (
      <IconFont name='chenggongbaocun' />
    )
  }, [status])

  return (
    <div className={classNames('flex items-center px-12 py-8 ', className)}>
      <div className='mr-4'>{Icon}</div>
      <div className='flex-grow text-#00B078 text-14px '>
        {status === 'running' && (
          <span>
            批量运行中：{currentStep}/{totalSteps}
          </span>
        )}
        {status === 'done' && (
          <span>
            批量运行完毕：
            <span className='font-normal'>
              成功{successCount}
              {!!errorCount && (
                <span className='text-font_red '> 失败 {errorCount}</span>
              )}
            </span>
          </span>
        )}
      </div>
      {status === 'running' && (
        <div
          onClick={onStop}
          className='cursor-pointer ml-22px w-54px h-30px flex items-center justify-center b-line b-1 b-op-80 text-font_1  rounded-6px text-12px hover:bg-bg_3 hover:bg-op-8'
        >
          <IconFont name='tingzhiyunxing' className='text-font_1 mr-4px' />
          停止
        </div>
      )}
      {status === 'done' && (
        // <div
        //   onClick={onClose}
        //   className='cursor-pointer ml-22px w-72px h-30px flex items-center justify-center b-line b-1 b-op-80 text-font_1  rounded-6px text-12px hover:bg-bg_3 hover:bg-op-8'
        // >
        //   我知道了
        // </div>
        <IconFont
          name='guanbi'
          className='text-font_1 cursor-pointer mt-1px ml-10px'
          onClick={onClose}
        />
      )}
    </div>
  )
}
