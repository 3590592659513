import classNames from 'classnames'
import styled from '@emotion/styled'

interface ColorSelectProps {
  value?: string
  onChange?: (color: string) => void
  colors: string[]
}

const ColorItem = styled.div<{ color: string }>`
  background: ${props => props.color};
`
export function ColorSelect(props: ColorSelectProps) {
  const { value, onChange, colors } = props

  return (
    <div className='flex gap-18'>
      {colors.map(color => {
        return (
          <div
            key={color}
            className={classNames(
              'w-40px h-40px rounded-10px flex-center shrink-0 b-1 b-transparent hover:bg-bg_3 hover:bg-op-6 cursor-pointer',
              {
                '!b-primary bg-bg_3 bg-op-6': value === color,
              },
            )}
            onClick={() => onChange?.(color)}
          >
            <ColorItem
              color={color}
              className='w-20px h-20px rounded-full bg-primary'
            />
          </div>
        )
      })}
    </div>
  )
}
