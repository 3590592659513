import type { FC } from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { IconFont, Input } from '@/components'

interface SimilarityConfigProps {
  defaultValue: number
  onChange: (value: number) => void
}

// const defaultSimilarityValues = [75, 85, 90]
const defaultSearchResultValues = [3, 6, 10]

export const SimilarityConfig: FC<SimilarityConfigProps> = props => {
  const { onChange, defaultValue } = props
  const [value, setValue] = useState(defaultValue)
  const [inputValue, setInputValue] = useState(defaultValue)
  const isDefaultCustomValue = !defaultSearchResultValues.includes(value)
  const [isCustom, setIsCustom] = useState(isDefaultCustomValue)

  const onValueChange = (_value: number) => {
    if (_value <= 0) {
      _value = 1
    } else if (value > 30) {
      _value = 30
    }
    // const result_value = _value / 100
    setValue(_value)
    onChange(_value)
  }
  return (
    <div className='flex items-center'>
      <div className='flex-center  font-400 text-14px'>
        <span>查询结果数</span>
        <Tooltip title='从知识库中查询X条结果（X与选择或设置的数字一致）'>
          <IconFont
            name='jieshishuimeng'
            style={{ color: 'rgba(141, 141, 153, 0.4)' }}
            className='ml-4px mr-8px cursor-pointer'
          />
        </Tooltip>
      </div>
      <div className='flex gap-8'>
        {defaultSearchResultValues.map(item => {
          const isSelected = item === value && !isCustom
          return (
            <div
              key={item}
              onClick={() => {
                setIsCustom(false)
                onValueChange(item)
              }}
              className={classNames(
                'rounded-4px cursor-pointer px-8 py-8 text-12px',
                {
                  'bg-primary c-white': isSelected,
                  'bg-bg_3 bg-op-6 hover:bg-op-8': !isSelected,
                },
              )}
            >
              {item}
            </div>
          )
        })}
        {!isCustom && (
          <div
            onClick={() => setIsCustom(true)}
            className='rounded-4px cursor-pointer px-8 py-8 bg-bg_3 bg-op-6 hover:bg-op-8 text-12px'
          >
            自定义
          </div>
        )}
        {isCustom && (
          <Input
            size='small'
            className={classNames('w-52px !h-28px', { '!b-primary': isCustom })}
            // suffix='%'
            value={Math.round(inputValue)}
            onChange={e => {
              let value = Number(e.target.value)
              if (Number.isNaN(value) || value < 0) {
                value = 1
              } else if (value > 30) {
                value = 30
              }
              setInputValue(value)
            }}
            onBlur={() => onValueChange(inputValue)}
            onPressEnter={() => onValueChange(inputValue)}
          />
        )}
      </div>
    </div>
  )
}
