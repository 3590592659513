import { useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import type { MouseEvent } from 'react'

interface TagProps {
  label: string
  type?: string
}
export interface VariableTagsProps {
  tags: TagProps[]
  onTagClick?: (tag: TagProps) => void
  anchor: 'left' | 'right'
}

export function VariableTags(props: VariableTagsProps) {
  const { tags = [], onTagClick, anchor } = props

  const handleTagClick = useMemoizedFn((event: MouseEvent, tag: TagProps) => {
    event.stopPropagation()
    event.preventDefault()
    onTagClick?.(tag)
  })

  return (
    <div
      className={classNames('flex flex-col', {
        'items-end': anchor === 'left',
        'items-start': anchor === 'right',
      })}
    >
      {tags.map((tag, i) => {
        return tag.type === 'node' ? (
          <div
            key={i}
            onMouseDown={e => handleTagClick?.(e, tag)}
            className='text-12px b-rd-4px b-1 b-#38a6ff b-opacity-24 text-#38A6FF  mb-4 bg-#F1FAFE px-6 py-4 shadow-[0_2x_4px_0px_rgba(0,0,0,0.08)] cursor-pointer hover:bg-#D0EEFC transition-all-200'
          >
            {tag.label}
          </div>
        ) : (
          <div
            key={i}
            onMouseDown={e => handleTagClick?.(e, tag)}
            className='text-12px b-rd-4px b-1 b-#7b61ff b-opacity-24 text-#7B61FF  mb-4  bg-#F7F6FF px-6 py-4 shadow-[0_2x_4px_0px_rgba(0,0,0,0.08)] cursor-pointer hover:bg-#E5DFFF transition-all-200'
          >
            {tag.label}
          </div>
        )
      })}
    </div>
  )
}
