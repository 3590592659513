import { injectGlobal } from '@emotion/css'
import { INPUT_VARIABLE_COLOR, NODE_VARIABLE_COLOR } from './const'

injectGlobal`

  .wavy-underline, .ace_wavy-underline {
    position: relative;
  }

  .wavy-underline::before, .ace_wavy-underline::before {
    content: '';
    position: absolute;
    bottom: -0.13em;
    width: 100%;
    height: 0.25em;
    background: linear-gradient(135deg, transparent, transparent 45%, red, transparent 55%, transparent 100%),
    linear-gradient(45deg, transparent, transparent 45%, red, transparent 55%, transparent 100%);
    background-size: 0.5em 0.5em;
    background-repeat: repeat-x, repeat-x;
    left: 0px;
  }

  .ace_gutter {
    width: 40px !important; /* Set the desired width here */
  }

  .ace_placeholder {
    white-space: pre !important;
  }

  .ace_content, .ace_placeholder, .ace_line {
    line-height: 1.3;
  }

  .ace_editor.ace_autocomplete {
    min-width: 160px;
    max-width: 320px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    backdrop-filter: blur(16px);
    border-color: transparent;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 24px;
    /* .ace_line {
      height: 24px!important;
      line-height: 24px!important;
    } */

    .ace_marker-layer .ace_active-line {
      border-radius: 2px;
      opacity: 1;
      background: rgba(141, 141, 153, 0.12);
      color: inherit;
    }

    .ace_line-hover {
      border: none;
      background: rgba(98, 105, 153, 0.08);
    }

    .ace_complete_input {
      color: ${INPUT_VARIABLE_COLOR};
    }

    .completion-highlight {
      text-decoration: underline;
    }

    .ace_complete_node {
      color: ${NODE_VARIABLE_COLOR};
    }
  }
`
