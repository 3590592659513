import { memo } from 'react'
import { FormItem } from '@/components'
import { WithQuickFillTextArea } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom/CustomInput.tsx'

export const BackgroundForm = memo<{
  placeholderMap: Record<string, string>
}>(({ placeholderMap }) => {
  return (
    <>
      <FormItem
        className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12'
        label='业务说明'
        name='businessDescription'
      >
        <WithQuickFillTextArea
          rows={3}
          className='resize-none!'
          placeholder={placeholderMap.businessDescription}
        />
      </FormItem>
      {/* <FormItem */}
      {/*  className='[&_.keyu-form-item-label]:font-500! [&_.ant-form-item-label]:pb-12' */}
      {/*  label='客户特点' */}
      {/*  name='customerProfile' */}
      {/* > */}
      {/*  <WithQuickFillTextArea */}
      {/*    rows={3} */}
      {/*    className='resize-none!' */}
      {/*    placeholder={placeholderMap.customerProfile} */}
      {/*  /> */}
      {/* </FormItem> */}
    </>
  )
})

BackgroundForm.displayName = 'BackgroundForm'
