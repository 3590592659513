import { getOutgoers, type Edge, type Node } from 'reactflow'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash-es'
import { NodeType } from '@/features/nodes/base'
import type {
  ConditionItem,
  ConditionNodeData,
} from '@/features/nodes/condition'

import type { IntentItem, IntentNodeData } from '@/features/nodes/intent'
import { BranchTypes } from '@/features/nodes/condition'
import { ConditionResultNode } from '@/features/nodes/condition/ConditionResultNode'
import { IntentResultNode } from '@/features/nodes/intent/IntentResultNode'

import type { ReplaceNodeData } from '@/store'
import {
  EMPTY_NODE_HEIGHT,
  NODE_WIDTH,
  SPACE_BETWEEN_NODES,
} from '../layout/constant'
import {
  generateEdgeFromNodes,
  generateNodeRelation,
  getAllBranchNodesFromHasConditionNode,
  isConditionNode,
  isIntentNode,
  isLoopNode,
  removeOuterRelation,
  // getBranchIdFromNode,
} from '../utils'
import { getNextNode } from '../layout'
import { TO_BE_COPIED_ID } from '../constants'
import type { FlowConfigData, InsertConfigData } from './BaseNodeOperation'
import { BaseNodeOperation } from './BaseNodeOperation'
import { findAllChildrenInNestedNode, getInheritedNodeEnableById } from './util'
import { SingleNodeOperation } from './SingleNodeOperation'
import { getOperationByNodeType } from '.'

export interface AddConditionData {
  id: string
  conditionIndex: number
  condition: Omit<ConditionItem | IntentItem, 'id'>
}
export interface RemoveConditionData {
  id: string
  conditionId: string
}

function getTargetMultiBranchNodeResultMetaType(type: NodeType) {
  if (type === NodeType.CONDITION) {
    return ConditionResultNode.meta
  } else if (type === NodeType.INTENT) {
    return IntentResultNode.meta
  }
  throw new Error('Unsupported NodeType')
}

export class MultiBranchNodeOperation extends BaseNodeOperation {
  insert(options: {
    type: NodeType
    data?: any
    source: string
    target: string
    sourceHandleId?: string | null | undefined
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }): InsertConfigData {
    const {
      type,
      data,
      source,
      target,
      sourceHandleId,
      nodes,
      edges,
      nodesMap,
    } = options
    const config: InsertConfigData = {
      nodes,
      edges,
      newNode: null,
    }
    const sourceNode = nodesMap[source]
    const targetNode = nodesMap[target]

    // 合法性校验
    if (!sourceNode || !targetNode) {
      throw new Error('添加节点失败')
    }

    // 节点关系
    const relation = generateNodeRelation(
      nodes,
      edges,
      sourceNode,
      sourceHandleId,
    )

    // 生成主节点
    const mainNode = {
      type,
      id: uuidv4(),
      position: {
        x: sourceNode.position.x,
        y: sourceNode.position.y,
      },
      width: NODE_WIDTH,
      height: EMPTY_NODE_HEIGHT,
      selected: true,
      data: {
        ...data,
        relation: relation || {},
      },
    }

    // 生成对应的result节点
    const targetResultMeta = getTargetMultiBranchNodeResultMetaType(type)!
    const resultNode = {
      type: targetResultMeta.type,
      id: uuidv4(),
      position: {
        x: mainNode.position.x,
        y: mainNode.position.y,
      },
      data: {
        ...targetResultMeta.initialData,
        actionType: targetResultMeta.actionType,
        relation: {
          conditionId: mainNode.id,
        },
      },
    }

    mainNode.data.relation.conditionResultId = resultNode.id

    // 插入时所有分支不加默认节点
    const conditionEdges: Edge[] = []
    const { conditions } = data as ConditionNodeData | IntentNodeData
    conditions.forEach(item => {
      item.id = uuidv4()
      conditionEdges.push(
        generateEdgeFromNodes({
          source: mainNode.id,
          target: resultNode,
          sourceHandle: item.id,
        }),
      )
    })

    // 替换到下一个节点的edge
    const replaceEdges = [
      generateEdgeFromNodes({
        source: sourceNode.id,
        target: mainNode.id,
        sourceHandle: sourceHandleId,
      }),
      generateEdgeFromNodes({
        source: resultNode,
        target: targetNode.id,
      }),
    ]

    // 处理画布数据
    config.edges = config.edges
      .filter(
        e =>
          e.id !==
          `${
            isConditionNode(sourceNode) || isIntentNode(sourceNode)
              ? mainNode.data.relation?.branchId
              : isLoopNode(sourceNode)
                ? `loopStart_${source}`
                : source
          }-${target}`,
      )
      .concat(conditionEdges)
      .concat(replaceEdges)
    config.nodes = config.nodes.concat([resultNode, mainNode])
    config.newNode = mainNode

    return config
  }

  replaceEmpty(data: {
    id: string
    node: ReplaceNodeData<any>
    sourceHandleId?: string
    nodes: Node[]
    edges: Edge[]
    nodesMap: Record<string, Node>
  }) {
    const { id, node, sourceHandleId, nodes, edges, nodesMap } = data
    const currentNode = nodesMap[id] as Node
    const type = currentNode.type as NodeType
    const outgoers = getOutgoers(currentNode, nodes, edges) // 找到空节点的下一个节点
    if (!outgoers.length) {
      throw new Error('节点数据异常')
    }

    // 生成对应的result节点
    const targetResultMeta = getTargetMultiBranchNodeResultMetaType(type)!
    const resultNode = {
      type: targetResultMeta.type,
      id: uuidv4(),
      position: {
        x: currentNode.position.x,
        y: currentNode.position.y,
      },
      data: {
        ...targetResultMeta.initialData,
        actionType: targetResultMeta.actionType,
        relation: {
          conditionId: id,
        },
      },
    }

    // 最后一条分支外，其余分支默认生成一个空节点
    const conditionEdges: Edge[] = []
    const emptyNodes: Node[] = []
    const { conditions } = node.data as ConditionNodeData | IntentNodeData
    conditions.forEach((item, i) => {
      item.id = uuidv4()
      if (i < conditions.length - 1) {
        const emptyNode = {
          id: uuidv4(),
          type: NodeType.EMPTY,
          zIndex: 20,
          position: {
            x: currentNode.position.x + NODE_WIDTH + SPACE_BETWEEN_NODES,
            y: currentNode.position.y,
          },
          data: {
            ...(sourceHandleId ? { sourceHandleId } : {}),
            relation: {
              branchId: item.id,
              conditionNodeId: id,
            },
          },
        }
        conditionEdges.push(
          generateEdgeFromNodes({
            source: id,
            target: emptyNode.id,
            sourceHandle: item.id,
          }),
          generateEdgeFromNodes({
            source: emptyNode.id,
            target: resultNode,
          }),
        )
        emptyNodes.push(emptyNode)
      } else {
        conditionEdges.push(
          generateEdgeFromNodes({
            source: id,
            target: resultNode,
            sourceHandle: item.id,
          }),
        )
      }
    })

    // 替换到下一个节点的edge
    const replaceEdge = generateEdgeFromNodes({
      source: resultNode,
      target: outgoers[0],
    })

    const nodeEnable = getInheritedNodeEnableById(id, nodes)
    const newNodes = nodes.concat([...emptyNodes, resultNode]).map(item =>
      item.id === id
        ? {
            ...item,
            ...node,
            data: {
              ...node.data,
              isEnable: nodeEnable,
              relation: {
                ...node.data?.relation,
                conditionResultId: resultNode.id,
              },
            },
          }
        : item,
    )
    const newEdges = edges
      .filter(edge => !(edge.source === id && edge.target === outgoers[0].id))
      .concat(conditionEdges)
      .concat(replaceEdge)

    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }

  remove(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
  ) {
    const removingNode = nodesMap[nodeId] as Node<ConditionNodeData>
    const conditionIds = removingNode.data.conditions.map(item => item.id)
    const resultId = removingNode.data.relation.conditionResultId
    const sourceIds = edges
      .filter(edge => edge.target === removingNode.id)
      .map(e => e.source)
    const targetIds = edges
      .filter(edge => edge.source === resultId)
      .map(e => e.target)
    if (!sourceIds.length || !targetIds.length) {
      throw new Error('节点数据异常')
    }
    const source = nodesMap[sourceIds[0]]
    const sourceEdge = edges.find(
      e =>
        e.source === source.id &&
        e.target === removingNode.id &&
        e.type === 'insert',
    )
    const removingNodes = findAllChildrenInNestedNode(removingNode, nodes)
    const newEdges = edges
      .filter(e => {
        if (e.sourceHandle && conditionIds.includes(e.sourceHandle)) {
          return false
        }
        if (removingNodes.find(n => n.id === e.source || n.id === e.target)) {
          return false
        }
        return true
      })
      .concat(
        generateEdgeFromNodes({
          source: sourceIds[0],
          target: targetIds[0],
          sourceHandle: sourceEdge?.sourceHandle,
          // ...(source?.type === NodeType.CONDITION && branchId
          //   ? { sourceHandle: branchId }
          //   : {}),
        }),
      )
    const newNodes = nodes.filter(
      n => !removingNodes.some(item => item.id === n.id),
    )
    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }

  copy(
    originNode: Node<ConditionNodeData>,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
    isStart = true,
  ) {
    const result: FlowConfigData = {
      nodes: [],
      edges: [],
    }
    const { conditions, relation } = originNode.data
    const resultNode = nodesMap[relation.conditionResultId]
    if (!resultNode) {
      throw new Error('条件节点复制出错')
    }
    const targetNode = cloneDeep({
      ...originNode,
      data: {
        ...originNode.data,
        relation: removeOuterRelation(originNode.data?.relation),
      },
      id: isStart ? `${TO_BE_COPIED_ID}_${originNode.id}` : uuidv4(),
    })
    const cloneResultNode = cloneDeep({
      ...resultNode,
      id: uuidv4(),
      data: {
        ...resultNode.data,
        relation: {
          ...resultNode.data.relation,
          conditionId: targetNode.id,
        },
      },
    })
    targetNode.data.relation.conditionResultId = cloneResultNode.id
    result.nodes.push(targetNode, cloneResultNode)

    if (conditions.length) {
      conditions.forEach(c => {
        const startEdge = edges.find(e => e.sourceHandle === c.id)
        const startNode = startEdge ? nodesMap[startEdge.target] : undefined
        if (!startEdge || !startNode) {
          return
        }
        const newBranchId = uuidv4()
        let current: Node | null = originNode
        let next: Node | null = startNode
        let currentId = targetNode.id
        while (current && next && next.id !== resultNode.id) {
          const operation = getOperationByNodeType(next.type as NodeType)
          if (operation instanceof SingleNodeOperation) {
            const cloneNext = cloneDeep({
              ...next,
              id: uuidv4(),
              data: {
                ...next.data,
                relation: {
                  ...next.data.relation,
                  branchId: newBranchId,
                  conditionNodeId: targetNode.id,
                },
              },
            })
            result.nodes.push(cloneNext)
            result.edges.push(
              generateEdgeFromNodes({
                source: currentId,
                target: cloneNext.id,
                ...(currentId === targetNode.id
                  ? { sourceHandle: newBranchId }
                  : {}),
              }),
            )
            currentId = cloneNext.id
          } else if (operation) {
            const { nodes: cNodes, edges: cEdges } = operation.copy(
              next,
              nodes,
              edges,
              nodesMap,
              false,
            )
            cNodes[0].data.relation.branchId = newBranchId
            cNodes[0].data.relation.conditionNodeId = targetNode.id
            result.nodes = result.nodes.concat(cNodes)
            result.edges.push(
              generateEdgeFromNodes({
                source: currentId,
                target: cNodes[0].id,
                ...(currentId === targetNode.id
                  ? { sourceHandle: newBranchId }
                  : {}),
              }),
            )
            result.edges = result.edges.concat(cEdges)
            currentId = cNodes[1].id
          }
          current = next
          next = getNextNode(nodes, edges, current)
        }
        result.edges.push(
          generateEdgeFromNodes({
            source: currentId,
            target: cloneResultNode.id,
            ...(currentId === targetNode.id
              ? { sourceHandle: newBranchId }
              : {}),
          }),
        )
        const condition = targetNode.data.conditions.find(
          item => item.id === c.id,
        )
        if (condition) {
          condition.id = newBranchId
        }
      })
    }

    return result
  }

  addBranch(
    data: AddConditionData,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
  ) {
    const { id, condition, conditionIndex } = data
    const currentNode = nodesMap[id]
    const currentType = currentNode.type

    let conditions, relation
    if (currentType === NodeType.CONDITION) {
      conditions = currentNode.data
        .conditions as ConditionNodeData['conditions']
      relation = currentNode.data.relation as ConditionNodeData['relation']
    } else if (currentType === NodeType.INTENT) {
      conditions = currentNode.data.conditions as IntentNodeData['conditions']
      relation = currentNode.data.relation as IntentNodeData['relation']
    } else {
      throw new Error('添加节点异常')
    }

    const resultNode = nodesMap[relation.conditionResultId]
    if (!resultNode) {
      throw new Error('节点数据异常')
    }
    const newCondition = {
      id: uuidv4(),
      ...condition,
    }
    // const emptyNode: Node = {
    //   id: uuidv4(),
    //   type: NodeType.EMPTY,
    //   position: {
    //     x: currentNode.position.x + NODE_WIDTH + SPACE_BETWEEN_NODES,
    //     y: currentNode.position.y,
    //   },
    //   data: {
    //     sourceHandle: newCondition.id,
    //     relation: {
    //       branchId: newCondition.id,
    //       conditionNodeId: id,
    //     },
    //   },
    // }
    const newEdges = edges.concat([
      // generateEdgeFromNodes({
      //   source: currentNode,
      //   target: emptyNode.id,
      //   sourceHandle: newCondition.id,
      // }),
      // generateEdgeFromNodes({
      //   source: emptyNode.id,
      //   target: resultNode,
      // }),
      generateEdgeFromNodes({
        source: currentNode,
        target: resultNode,
        sourceHandle: newCondition.id,
      }),
    ])
    const newNodes = nodes.map(n =>
      n.id === id
        ? {
            ...n,
            data: {
              ...n.data,
              conditions: [
                ...conditions.slice(0, conditionIndex),
                newCondition,
                ...conditions.slice(conditionIndex),
              ],
            },
          }
        : n,
    )

    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }

  removeBranch(
    data: RemoveConditionData,
    nodes: Node[],
    edges: Edge[],
    nodesMap: Record<string, Node>,
  ) {
    const { id, conditionId } = data
    const currentNode = nodesMap[id] as Node<ConditionNodeData | IntentNodeData>
    const { conditions } = currentNode.data
    const targetCondition = conditions.find(c => c.id === conditionId)
    if (!targetCondition) {
      throw new Error('该分支不存在')
    }
    const targetIndex = conditions.indexOf(targetCondition)
    if (targetCondition.type === BranchTypes.ELSE) {
      currentNode.data.conditions = conditions
        .filter(c => c.id !== conditionId)
        .map((item, i) =>
          i === targetIndex - 1
            ? { ...item, type: BranchTypes.ELSE, statement: '' }
            : item,
        )
    } else {
      currentNode.data.conditions = conditions.filter(c => c.id !== conditionId)
    }
    const branchNodes = nodes
      .filter(n => n.data.relation?.branchId === conditionId)
      .reduce<Node[]>((pre, cur) => {
        if (cur.type === NodeType.CONDITION || cur.type === NodeType.INTENT) {
          return pre.concat(getAllBranchNodesFromHasConditionNode(cur, nodes))
        }
        return pre.concat(cur)
      }, [])

    const newEdges = edges.filter(e => {
      if (e.sourceHandle === conditionId) {
        return false
      }
      if (branchNodes.find(n => n.id === e.source || n.id === e.target)) {
        return false
      }
      return true
    })
    const newNodes = nodes.filter(
      n => !branchNodes.some(item => item.id === n.id),
    )

    return {
      nodes: newNodes,
      edges: newEdges,
    }
  }
}
