import cn from 'classnames'
import { IconFont } from '../icon/IconFont'

export interface SuperLinkProps {
  className?: string
  onClick?: VoidFunction
}
export function SuperLink({ className, onClick }: SuperLinkProps) {
  return (
    <button
      className={cn(
        'text-16px cursor-pointer w-24px h-24px hover:bg-bg_3 hover:bg-op-12 text-#626999 text-op-60 rounded-4px flex-center',
        className,
      )}
      onClick={event => {
        event.stopPropagation()
        onClick?.()
      }}
    >
      <IconFont name='super-link' />
    </button>
  )
}
