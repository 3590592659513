import classNames from 'classnames'
import { usePageModal } from '@/components'
import { useWorkspaceKeys } from '@/features/agent/provider/WorkspaceKeysProvieder.tsx'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { CopyBox } from './CopyBox'

interface Props {
  className?: string
}

export function IntegratedKeys(props: Props) {
  const { className } = props
  const pageModal = usePageModal()
  const { applicationInfo } = useAgentEdit()
  const { apiKey } = useWorkspaceKeys()

  return (
    <div className={classNames('mt-24', className)}>
      <div>
        <div className='font-500'>API Key</div>
        {apiKey ? (
          <CopyBox className='mt-12' value={apiKey} />
        ) : (
          <div className='flex-center-between w-full flex-1 p-7 pl-12 mt-12 bg-bg_3 bg-op-5.88 rounded-8px'>
            <span className='font-400 truncate c-font_1 c-op-60 flex-1 pr-12'>
              未生成
            </span>
            <span
              onClick={() => {
                if (applicationInfo?.workspaceId) {
                  pageModal.show({
                    url: `/workspace/${applicationInfo.workspaceId}/settings/overview`,
                  })
                }
              }}
              className='c-primary line-height-22px cursor-pointer'
            >
              去生成
            </span>
          </div>
        )}
      </div>
      <div className='flex w-full items-center gap-24 mt-24'>
        <div className='flex-1 overflow-hidden'>
          <div className='font-500'>工作空间 Id</div>
          <CopyBox
            className='mt-12'
            value={applicationInfo?.workspaceId ?? ''}
          />
        </div>
        <div className='flex-1 overflow-hidden'>
          <div className='font-500'>Agent Id</div>
          <CopyBox className='mt-12' value={applicationInfo?.flowId ?? ''} />
        </div>
      </div>
    </div>
  )
}
