import { SearchOutlined } from '@ant-design/icons'
import type { ChangeEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce, useRequest } from 'ahooks'
import { Form, message, Popconfirm, Popover, Switch, Tooltip } from 'antd'
import type { TableProps, TableColumnsType, TableColumnType } from 'antd'
import dayjs from 'dayjs'
import { get } from 'lodash-es'
import { Input, Button, Modal, Select, IconFont } from '@/components'
import {
  createAlarmRule,
  fetchAlarmChannelByRuleId,
  fetchAlarmRules,
  removeAlarmRule,
  toggleAlarmRuleEnable,
  updateAlarmRule,
} from '@/apis/alarm'
import type {
  AlarmRuleItem,
  AlarmRuleConfigType,
  AlarmTypes,
  AlarmRuleConfig,
} from '@/apis/alarm'
import { getWorkspaceUsers, RoleCode } from '@/apis/authority'
import { useUserStore, useWorkspaceStore } from '@/store'
import { useTableBodyScroll } from '@/hooks/useTableBodyScroll'
import {
  AlarmChannelIcons,
  AlarmChannelNames,
  AlarmLevelIcons,
  AlarmLevelSortMap,
  AlarmTypeNames,
  AlarmTypeTargets,
  configTypeMap,
  DisabledAlarmTypes,
  SortOrderMap,
} from './constants'
import {
  getAlarmConditionText,
  getDefaultRuleValuesByRuleType,
  transformRuleItemToFormValues,
} from './util'
import { AlarmRuleForm } from './RuleForm'
import { StyledTable } from './styledComponents'
import type { AlarmRuleFormValues } from './type'
import { useDefaultPointsConfig } from './hooks/useDefaultPointsConfig'

const configTypes = Object.entries(configTypeMap).map(([k, v]) => {
  return {
    label: v,
    value: k,
  }
})

export function AlarmRules() {
  const [ruleType, setRuleType] = useState<AlarmRuleConfigType>()
  const [alarmType, setAlarmType] = useState<AlarmTypes>()
  const [ruleName, setRuleName] = useState<string>()
  const [page, setPage] = useState(1)
  const [sortField, setSortField] = useState<string>()
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>()

  const [modalOpen, setModalOpen] = useState(false)
  const [form] = Form.useForm<AlarmRuleFormValues>()
  const [currentRule, setCurrentRule] = useState<AlarmRuleItem | null>(null)

  const { tableBodyHeight, tableRef } = useTableBodyScroll()

  const { user } = useUserStore()

  const { defaultLeftPoints } = useDefaultPointsConfig()

  const modalTitle = useMemo(() => {
    return currentRule ? '编辑规则' : '创建告警规则'
  }, [currentRule])

  const defaultRuleValues = useMemo(
    () =>
      getDefaultRuleValuesByRuleType(ruleType || 'Workspace', {
        userId: user?.userId,
        defaultLeftPoints,
      }),
    [ruleType, user],
  )

  const alarmTypeOptions = useMemo(() => {
    if (!ruleType) {
      return Object.entries(AlarmTypeNames).map(([k, v]) => ({
        label: v,
        value: k as AlarmTypes,
        disabled: DisabledAlarmTypes.includes(k as AlarmTypes),
      }))
    }
    return Object.entries(AlarmTypeNames)
      .filter(([k]) => {
        const types = AlarmTypeTargets[k as AlarmTypes]
        return types.includes(ruleType)
      })
      .map(([k, v]) => ({
        label: DisabledAlarmTypes.includes(k as AlarmTypes)
          ? `${v}(即将上线)`
          : v,
        value: k as AlarmTypes,
        disabled: DisabledAlarmTypes.includes(k as AlarmTypes),
      }))
  }, [ruleType])

  const debouncedRuleName = useDebounce(ruleName, { wait: 300 })

  const { currentWorkspaceId } = useWorkspaceStore()

  const {
    data: rulesData,
    loading,
    refreshAsync: refreshRules,
  } = useRequest(
    params =>
      fetchAlarmRules({
        ...params,
        alarm_rule_type: ruleType,
        alarm_rule_name: debouncedRuleName ?? undefined,
        alarm_type: alarmType,
        page_no: page,
        page_size: 10,
        sort_field: sortField,
        sort_order: sortOrder,
      }),
    {
      refreshDeps: [
        ruleType,
        debouncedRuleName,
        alarmType,
        page,
        sortField,
        sortOrder,
        currentWorkspaceId,
      ],
    },
  )
  const { runAsync: toggleRuleEnable, loading: enableLoading } = useRequest(
    toggleAlarmRuleEnable,
    { manual: true },
  )

  const { runAsync: createRule } = useRequest(createAlarmRule, { manual: true })
  const { runAsync: updateRule } = useRequest(updateAlarmRule, { manual: true })
  const { runAsync: removeRule } = useRequest(removeAlarmRule, { manual: true })
  const { data: channelsData, runAsync: fetchChannels } = useRequest(
    fetchAlarmChannelByRuleId,
    { manual: true },
  )

  const { data: usersData } = useRequest(() =>
    getWorkspaceUsers({ workspaceId: currentWorkspaceId }),
  )

  const userOptions = useMemo(() => {
    if (!usersData) return []
    return usersData
      .filter(u => u.roleCode !== RoleCode.VIEWER)
      .map(u => ({
        label: u.username,
        value: u.userId,
      }))
  }, [usersData])

  const onNameSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value.trim()
    setRuleName(name)
  }

  const onEdit = (record: AlarmRuleItem) => {
    setCurrentRule(record)
    setModalOpen(true)
  }

  const onAddClick = () => {
    form.resetFields()
    form.setFieldsValue({
      ...defaultRuleValues,
      // alarm_rule_name: `${configTypeMap[ruleType || 'Workspace']} ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
      alarm_rule_name: `告警规则 ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
    })
    setCurrentRule(null)
    setModalOpen(true)
  }

  const onSave = async () => {
    try {
      const values = await form.validateFields()
      const api = currentRule ? updateRule : createRule
      const smsIds = get(
        values,
        ['alarm_channel', 'sms_notify_channel', 'notify_user'],
        [],
      )
      const smsUsers = smsIds
        .map(id => {
          const user = usersData?.find(u => u.userId === id)
          return {
            user_id: id,
            phone: user?.phone,
          }
        })
        .filter(u => u.phone) as { user_id: string | number; phone: string }[]
      const vmsIds = get(
        values,
        ['alarm_channel', 'vms_notify_channel', 'notify_user'],
        [],
      )
      const vmsUsers = vmsIds
        .map(id => {
          const user = usersData?.find(u => u.userId === id)
          return {
            user_id: id,
            phone: user?.phone,
          }
        })
        .filter(u => u.phone) as { user_id: number; phone: string }[]
      const dingUsers = get(
        values,
        ['alarm_channel', 'ding_notify_channel', 'notify_user'],
        '',
      ).split('\n')
      const params: AlarmRuleConfig & { alarm_rule_id?: string } = {
        ...values,
        enabled: currentRule ? values.enabled : true,
        threshold_config: values.threshold_config.sort(
          (a, b) =>
            AlarmLevelSortMap[b.alarm_level] - AlarmLevelSortMap[a.alarm_level],
        ),
        alarm_channel: {
          ...values.alarm_channel,
          sms_notify_channel: values.alarm_channel.sms_notify_channel
            ? {
                ...values.alarm_channel?.sms_notify_channel,
                notify_user: smsUsers,
              }
            : null,
          ding_notify_channel: values.alarm_channel.ding_notify_channel
            ? {
                ...values.alarm_channel?.ding_notify_channel,
                notify_user: dingUsers,
              }
            : null,
          vms_notify_channel: values.alarm_channel.vms_notify_channel
            ? {
                ...values.alarm_channel?.vms_notify_channel,
                notify_user: vmsUsers,
              }
            : null,
        },
      }
      if (currentRule) {
        params.alarm_rule_id = currentRule.alarm_rule_id
      }
      const res = await api(params as Parameters<typeof api>[0])
      if (res) {
        message.success(currentRule ? '修改成功' : '创建完成')
        setModalOpen(false)
        refreshRules()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onRemove = async (id: string) => {
    await removeRule(id)
    await refreshRules()
  }

  const onTableChange: TableProps<AlarmRuleItem>['onChange'] = (
    pagination,
    _,
    sorter,
  ) => {
    if (sorter && !Array.isArray(sorter)) {
      const { field, order } = sorter
      if (
        (field && field !== sortField) ||
        (order && SortOrderMap[order] !== sortOrder)
      ) {
        field && setSortField(field as string)
        order && setSortOrder(SortOrderMap[order])
        setPage(1)
        return
      }
    }
    if (pagination) {
      const { current } = pagination
      if (current && current !== page) {
        setPage(current)
      }
    }
  }

  const columns: TableColumnsType<AlarmRuleItem> = [
    {
      title: '告警场景',
      width: 120,
      key: 'alarm_rule_type',
      dataIndex: 'alarm_rule_type',
      render: (_, record) => {
        return configTypeMap[record.alarm_rule_type]
      },
    },
    {
      title: '告警类型',
      width: 240,
      key: 'alarm_type',
      dataIndex: 'alarm_type',
      render: (_, record) => {
        return AlarmTypeNames[record.alarm_type]
      },
    },
    {
      title: '触发条件',
      width: 240,
      key: 'threshold_config',
      dataIndex: 'threshold_config',
      render: (_, record) => {
        return (
          <>
            {record.threshold_config.map((item, i) => (
              <div key={i} className='mb-8px last:mb-0px flex items-center'>
                <img
                  className='w-44px h-20px'
                  src={AlarmLevelIcons[item.alarm_level]}
                  alt=''
                />
                <span className='ml-4px'>
                  {getAlarmConditionText({
                    alarmType: record.alarm_type,
                    time: item.alarm_time || 0,
                    count: item.threshold,
                    unit: !item.trigger_type
                      ? '%积分'
                      : item.trigger_type === 'NUMBER'
                        ? '积分'
                        : '%积分',
                  })}
                </span>
              </div>
            ))}
          </>
        )
      },
    },
    ...(ruleType === 'Workspace'
      ? []
      : [
          {
            title: '生效对象',
            width: 240,
            key: 'alarm_effect_objects',
            dataIndex: 'alarm_effect_objects',
            render: (_, record) => {
              if (record.alarm_rule_type === 'Workspace') {
                return '工作空间'
              }
              const names =
                record.alarm_effect_objects?.map(item => item.app_name) ?? []
              return !names.length ? (
                '-'
              ) : names.length <= 3 ? (
                names.join('、')
              ) : (
                <Tooltip title={names.join('、')}>
                  {`${names.slice(0, 3).join('、')}...+${names.length - 3}`}
                </Tooltip>
              )
            },
          } as TableColumnType<AlarmRuleItem>,
        ]),
    {
      title: '通知渠道',
      width: 100,
      key: 'alarm_channel',
      dataIndex: 'alarm_channel',
      render: (_, record) => {
        const { alarm_channel } = record
        const channels = Object.entries(alarm_channel)
          .filter(([_, v]) => v)
          .map(([k]) => ({
            key: k,
            label: AlarmChannelNames[k],
            icon: AlarmChannelIcons[k],
          }))
        return (
          <Popover
            placement='bottomLeft'
            arrow={false}
            content={
              <div className='flex flex-col gap-8px w-250px'>
                {record.alarm_channel?.sms_notify_channel?.enabled && (
                  <>
                    <div className='line-height-18px text-12px text-#17171d'>
                      短信通知
                    </div>
                    <div className='line-height-18px text-12px text-#8d8d99'>
                      {channelsData?.sms_notify_channel
                        .map(item => item.user_name)
                        .join('；')}
                    </div>
                  </>
                )}
                {record.alarm_channel?.ding_notify_channel?.enabled && (
                  <>
                    <div className='line-height-18px text-12px text-#17171d'>
                      钉钉群机器人通知
                    </div>
                    <div className='line-height-18px text-12px text-#8d8d99'>
                      {channelsData?.ding_notify_channel.notify_user?.length
                        ? channelsData.ding_notify_channel.notify_user.join(
                            '；',
                          )
                        : '未指定@群成员'}
                    </div>
                  </>
                )}
                {record.alarm_channel?.vms_notify_channel?.enabled && (
                  <>
                    <div className='line-height-18px text-12px text-#17171d'>
                      电话通知
                    </div>
                    <div className='line-height-18px text-12px text-#8d8d99'>
                      {channelsData?.vms_notify_channel
                        ?.map(item => item.user_name)
                        .join('；')}
                    </div>
                  </>
                )}
              </div>
            }
          >
            <div className='flex gap-8px cursor-pointer'>
              {channels.map(item => (
                <IconFont
                  key={item.key}
                  name={item.icon}
                  className='text-18px text-#8C7CEB'
                  onMouseEnter={async () => {
                    await fetchChannels(record.alarm_rule_id)
                  }}
                />
              ))}
            </div>
          </Popover>
        )
      },
    },
    {
      title: '规则名称',
      width: 120,
      className: 'truncate',
      key: 'alarm_rule_name',
      dataIndex: 'alarm_rule_name',
      render: (_, record) => {
        return (
          <Tooltip title={record.alarm_rule_name}>
            {record.alarm_rule_name}
          </Tooltip>
        )
      },
    },
    {
      title: '创建人',
      width: 150,
      key: 'created_name',
      dataIndex: 'created_name',
    },
    {
      title: '创建时间',
      width: 180,
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: true,
      render: (_, record) => {
        return dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '最后修改人',
      width: 150,
      key: 'updated_name',
      dataIndex: 'updated_name',
      render: (_, record) => record.updated_name || '-',
    },
    {
      title: '最后修改时间',
      width: 180,
      key: 'updated_at',
      dataIndex: 'updated_at',
      sorter: true,
      render: (_, record) => {
        return dayjs(record.updated_at).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '状态',
      width: 60,
      fixed: 'right',
      className: 'bg-#fff',
      key: 'enabled',
      dataIndex: 'enabled',
      render: (_, record) => {
        return (
          <Switch
            size='small'
            loading={enableLoading}
            value={record.enabled}
            onChange={async val => {
              await toggleRuleEnable({
                enabled: val,
                alarm_rule_id: record.alarm_rule_id,
              })
              await refreshRules()
            }}
          />
        )
      },
    },
    {
      title: '操作',
      width: 100,
      fixed: 'right',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='flex items-center gap-8px'>
            <Button
              className='p-0!'
              type='link'
              onClick={() => {
                onEdit(record)
              }}
            >
              编辑
            </Button>
            <Popconfirm
              rootClassName='aac'
              title={null}
              icon={null}
              description={
                <div className='w-220px mb-24px'>
                  确认删除规则？删除后不可撤销
                </div>
              }
              okButtonProps={{
                size: 'middle',
                danger: true,
              }}
              cancelButtonProps={{
                size: 'middle',
              }}
              onConfirm={() => {
                onRemove(record.alarm_rule_id)
              }}
            >
              <Button className='p-0!' type='link' danger>
                删除
              </Button>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (currentRule) {
      const values = transformRuleItemToFormValues(currentRule)
      form.setFieldsValue(values)
    }
  }, [currentRule])

  return (
    <div className='flex flex-col h-full p-24px'>
      <div className='mb-24px flex justify-between'>
        <div className='flex items-center gap-12px'>
          <Select
            className='w-200px'
            placeholder='告警场景'
            allowClear
            options={configTypes}
            value={ruleType}
            onChange={v => {
              setAlarmType(undefined)
              setRuleName(undefined)
              setRuleType(v as AlarmRuleConfigType)
            }}
          />
          <Select
            className='w-200px'
            popupMatchSelectWidth={220}
            placeholder='告警类型'
            allowClear
            virtual={false}
            options={alarmTypeOptions.filter(item => !item.disabled)}
            value={alarmType}
            onChange={v => {
              setAlarmType(v as AlarmTypes)
            }}
          />
          <Input
            className='w-200px'
            prefix={<SearchOutlined />}
            placeholder='搜索规则名称'
            value={ruleName}
            onChange={onNameSearch}
          />
        </div>
        <Button type='primary' onClick={onAddClick}>
          添加规则
        </Button>
      </div>
      <div ref={tableRef} className='flex-1'>
        <StyledTable
          className='h-full'
          rowKey='alarm_rule_id'
          loading={loading}
          columns={columns}
          dataSource={rulesData?.data || []}
          scroll={{
            // x: ruleType === 'Workspace' ? 1290 : 1530,
            x: ruleType === 'Workspace' ? 1700 : 1900,
            y: tableBodyHeight,
          }}
          pagination={{
            total: rulesData?.total || 0,
            showSizeChanger: false,
          }}
          onChange={onTableChange}
        />
      </div>
      <Modal
        title={modalTitle}
        width={1120}
        styles={{
          body: {
            height: 600,
            overflow: 'auto',
          },
        }}
        destroyOnClose
        open={modalOpen}
        okText='保存'
        onClose={closeModal}
        onCancel={closeModal}
        onOk={onSave}
      >
        <AlarmRuleForm
          isEdit={!!currentRule?.alarm_rule_id}
          form={form}
          userOptions={userOptions}
        />
      </Modal>
    </div>
  )
}
