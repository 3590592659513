import type { FormItemProps, InputProps } from 'antd'
import { Form, Switch } from 'antd'
import type { CardRadioGroupProps } from '@/components'
import {
  CardRadioGroup,
  GroupSelect,
  Input,
  RequiredMark,
  SliderSingle as Slider,
} from '@/components'

interface PluginConfFormItemCardRadioGroupProps extends CardRadioGroupProps {
  name: FormItemProps['name']
  displayName: string
  required?: boolean
}

function PluginConfFormItemCardRadioGroup({
  name,
  displayName,
  required,
  options,
}: PluginConfFormItemCardRadioGroupProps) {
  return (
    <Form.Item
      className='!mb-0'
      name={name}
      label={
        <span className='inline-flex text-14px/16px text-font'>
          {displayName}
          {required && <RequiredMark />}
        </span>
      }
      rules={
        required
          ? [{ required: true, message: `请配置${displayName}` }]
          : undefined
      }
    >
      <CardRadioGroup options={options} compact />
    </Form.Item>
  )
}

export { PluginConfFormItemCardRadioGroup as CardRadioGroup }

interface PluginConfFormItemSwitchProps {
  name: FormItemProps['name']
  displayName: string
  required?: boolean
  extra?: string
}

function PluginConfFormItemSwitch({
  name,
  displayName,
  required,
  extra,
}: PluginConfFormItemSwitchProps) {
  return (
    <div>
      <div className='flex items-center'>
        <span className='text-14px/16px'>{displayName}</span>
        {!required && <RequiredMark />}
        <Form.Item name={name} noStyle>
          <Switch className='ml-12' size='small' />
        </Form.Item>
      </div>
      {extra ? <p className='text-12px text-font_1 mt-12'>{extra}</p> : null}
    </div>
  )
}

export { PluginConfFormItemSwitch as Switch }

interface PluginConfFormItemLLMProps
  extends React.ComponentProps<typeof GroupSelect> {
  name: FormItemProps['name']
  displayName: string
  required?: boolean
}

function PluginConfFormItemLLM({
  name,
  displayName,
  required,
  options,
}: PluginConfFormItemLLMProps) {
  return (
    <Form.Item
      className='!mb-0'
      name={name}
      label={
        <span className='inline-flex text-14px/16px text-font'>
          {displayName}
          {required && <RequiredMark />}
        </span>
      }
      rules={
        required
          ? [{ required: true, message: `请配置${displayName}` }]
          : undefined
      }
    >
      <GroupSelect
        className='!h-36px [&>.ant-select-selector]:!h-36px'
        options={options}
      />
    </Form.Item>
  )
}

export { PluginConfFormItemLLM as LLM }

interface PluginConfFormItemSliderProps {
  name: FormItemProps['name']
  displayName: string
  required?: boolean
  min?: number
  max?: number
  disabled?: boolean
}

function PluginConfFormItemSlider({
  name,
  displayName,
  required,
  min,
  max,
  disabled,
}: PluginConfFormItemSliderProps) {
  return (
    <Form.Item
      className='!mb-0 [&_.ant-form-item-control]:bg-bg_3 [&_.ant-form-item-control]:bg-op-6 [&_.ant-form-item-control]:rounded-8px [&_.ant-form-item-control]:py-1 [&_.ant-form-item-control]:pl-12 [&_.ant-form-item-control]:pr-4'
      name={name}
      label={
        <span className='inline-flex text-14px/16px text-font'>
          {displayName}
          {required && <RequiredMark />}
        </span>
      }
      rules={
        required
          ? [{ required: true, message: `请配置${displayName}` }]
          : undefined
      }
    >
      <Slider min={min} max={max} disabled={disabled} noStyle />
    </Form.Item>
  )
}

export { PluginConfFormItemSlider as Slider }

interface PluginConfFormItemInputProps extends InputProps {
  name: FormItemProps['name']
  displayName: string
  required?: boolean
}

function PluginConfFormItemInput({
  name,
  displayName,
  required,
  placeholder,
}: PluginConfFormItemInputProps) {
  return (
    <Form.Item
      className='!mb-0'
      name={name}
      label={
        <span className='inline-flex text-14px/16px text-font'>
          {displayName}
          {required && <RequiredMark />}
        </span>
      }
      rules={
        required
          ? [{ required: true, message: `请配置${displayName}` }]
          : undefined
      }
    >
      <Input className='!rounded-8px' placeholder={placeholder} />
    </Form.Item>
  )
}

export { PluginConfFormItemInput as Input }
