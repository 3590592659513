import { useFocusWithin, useUpdateEffect } from 'ahooks'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  FLOW_DRAFT_LOCK_STATUS,
  useFlowDraftStore,
  useFlowInteractionStore,
} from '@/store'

export function useNodeElement(
  nodeId: string,
  selected: boolean,
  data: Record<string, unknown>,
) {
  const viewPortNodeId = useFlowDraftStore(s => s.viewPortNodeId)
  const setViewPortNodeId = useFlowDraftStore(s => s.setViewPortNodeId)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)
  const dangerStateNodeIds = useFlowInteractionStore(s => s.dangerStateNodeIds)
  const [nodeElement, setNodeElement] = useState<HTMLDivElement | null>(null)
  const [toBeRemoved, setNodeToBeRemoved] = useState(false)

  const nodeRef = useRef<HTMLDivElement | null>(null)

  const isFocusWithin = useFocusWithin(nodeRef)

  useLayoutEffect(() => {
    // 获取flow node节点的dom元素
    setNodeElement(
      document.querySelector(
        `.react-flow__node[data-id="${nodeId}"]`,
      ) as HTMLDivElement,
    )
  }, [])

  useEffect(() => {
    setNodeToBeRemoved(
      dangerStateNodeIds.includes(nodeId) || !!data.toBeRemoved,
    )
  }, [dangerStateNodeIds, nodeId, data.toBeRemoved])

  useUpdateEffect(() => {
    setViewPortNodeId('')
  }, [isFocusWithin])

  const focused =
    lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK
      ? false
      : (isFocusWithin && (data.isEnable as boolean)) ||
        selected ||
        toBeRemoved ||
        viewPortNodeId === nodeId

  return {
    nodeElement,
    toBeRemoved,
    setNodeToBeRemoved,
    nodeRef,
    isFocusWithin,
    focused,
  }
}
