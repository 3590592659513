import type { CollapseProps } from 'antd'
import { Collapse } from 'antd'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { IconFont } from '@/components'

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 10px;
    color: ${props => props.theme.colors.font_1};
  }
  &.ant-collapse-small
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0 0 2px 12px;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 4px 0 4px 38px;
  }

  .ant-collapse-header:hover {
    background: ${props => rgba(props.theme.colors.bg_3, 0.08)};
    border-radius: 8px !important;
  }
`

export function LoggerCollapse(props: CollapseProps) {
  return (
    <StyledCollapse
      ghost
      expandIcon={({ isActive }) => (
        <IconFont
          name='arrow'
          className='transition-transform-200 text-font_1 text-10px'
          style={{ transform: `rotate(${isActive ? 0 : -90}deg)` }}
        />
      )}
      {...props}
    />
  )
}
