import { useMemo } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import type { Token } from 'markdown-it'
import type { RenderRule } from 'markdown-it/lib/renderer'
import MarkdownIt from 'markdown-it'
import { VIDEO_EXTENSIONS } from '@bty/chat-ui/src/components/message/type/Markdown/MarkdownAnchor'
import type { Paragraph } from '@/pages/datastores/types/paragraph.ts'

export type ParagraphItem = Pick<
  Paragraph,
  | 'content'
  | 'isEdited'
  | 'enable'
  | 'key'
  | 'keywords'
  | 'extra_info'
  | 'chunk_id'
  | 'file_type'
  | 'file_name'
>

const Wrapper = styled.div`
  p {
    font-size: 14px;
    line-height: 20px;
  }
`

function markdownItVideo(md: MarkdownIt): void {
  const defaultRender: RenderRule =
    md.renderer.rules.image ||
    ((
      tokens: Token[],
      idx: number,
      options: MarkdownIt.Options,
      _env: any,
      self: any,
    ) => {
      return self.renderToken(tokens, idx, options)
    })

  md.renderer.rules.image = (
    tokens: Token[],
    idx: number,
    options: MarkdownIt.Options,
    env: any,
    self: any,
  ): string => {
    const token = tokens[idx]
    const srcIndex = token.attrIndex('src')
    const url = token?.attrs?.[srcIndex]?.[1] || ''

    if (VIDEO_EXTENSIONS.some(ext => url?.endsWith(ext))) {
      return `<video controls src="${url}">Your browser does not support the video tag.</video>`
    }

    return defaultRender(tokens, idx, options, env, self)
  }
}
export default function ParagraphPreview(props: {
  value: string
  enable?: boolean
  className?: string
}) {
  const { value = '', enable, className } = props
  const content = useMemo(() => {
    return value?.replace(
      /<script[^>]*>[\s\S]*?<\/script>|<iframe[^>]*>[\s\S]*?<\/iframe>/gi,
      '',
    )
  }, [value])

  const previewContent = useMemo(() => {
    return new MarkdownIt({
      html: true,
      linkify: false,
      breaks: true,
    })
      .use(markdownItVideo)
      .render(content)
  }, [content])

  return (
    <Wrapper
      className={classNames(
        'editor-wrap relative betteryeah-markdown',
        className,
        {
          'op-40': enable,
        },
      )}
      dangerouslySetInnerHTML={{ __html: previewContent }}
    />
  )
}
