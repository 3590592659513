export const mobileReg = /^1[3456789]\d{9}$/
export const mobileRules = [
  { required: true, message: '请输入手机号' },
  {
    pattern: mobileReg,
    message: '请输入正确的手机号',
    validateTrigger: 'onSubmit',
  },
]
export const codeRules = [
  { required: true, message: '请输入6位数字验证码', length: 6 },
]
