import { memo, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import { Modal } from '@/components'
import { TextEditorPure } from '@/features/editor/TextEditor/editor'

interface PromptToRawModalProps {
  open: boolean
  getPrompt: () => string
  onCancel: () => void
  onConfirm: (text: string) => void
}

export const PromptToRawModal = memo((props: PromptToRawModalProps) => {
  const { open, getPrompt, onCancel, onConfirm } = props

  const rawValue = useMemo(() => {
    if (!open) return ''
    return getPrompt()
  }, [open])

  const handleConfirm = useMemoizedFn(() => {
    onConfirm(getPrompt() ?? '')
  })

  return (
    <Modal
      maskClosable={false}
      open={open}
      onClose={onCancel}
      onCancel={onCancel}
      onOk={handleConfirm}
      width={960}
      // title='转为文本'
      title='使用结构化模式的prompt'
      styles={{ body: { padding: 24, height: 580 } }}
      footerClassName='border-none pt-0px'
      okText='使用'
    >
      <div className='h-full flex flex-col overflow-hidden'>
        <div className='flex-1 bg-bg_3/8 rounded-8px p-12px overflow-hidden'>
          <TextEditorPure className='z-10' value={rawValue} readonly />
        </div>
      </div>
    </Modal>
  )
})
