import axios from 'axios'
import type { TrackMetaData } from './type'

export interface SendTrackConfig {
  // env: TrackEnv
  data: TrackMetaData
  trigger: 'click' | 'show'
}

// 地址： https://salyut2.bantouyan.com/#/nc/nc_1000_tzsgbm6n9rd-iwr1sva2d2w6wpzzxze8g4og?name=track-prod&dbalias=&type=table

const NOCODB_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzMjI0IiwiY2xpZW50Ijoibm9jb19hcGkiLCJleHAiOjUzMDk4NjA2MzR9.WJYUyilgBVwwdnVFf1UgSMLkE5VFZu0HTcC0CmwZ9bc'

export function sendTrack(config: SendTrackConfig) {
  axios.post(
    `https://salyut2.bantouyan.com/api/v1/db/data/v1/tzsgbm6n9rd-iwr1sva2d2w6wpzzxze8g4og/${
      import.meta.env.VITE_TRACK_TABLE
    }`,
    {
      ...config.data,
      ua: window.navigator.userAgent,
      trigger: config.trigger,
      custom_attributes: JSON.stringify(config.data.custom_attributes || {}),
      url: window.location.pathname,
    },
    {
      headers: {
        'xc-auth': `${NOCODB_TOKEN}`,
      },
    },
  )
  // const { env, data } = config
}
