import classNames from 'classnames'
import type { InputRef } from 'antd'
import { Tooltip, message } from 'antd'
import styled from '@emotion/styled'
import { memo, useEffect, useRef, useState } from 'react'
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { IconFont, Input } from '@/components'
import TableOperation from '@/features/database/components/TableTabs/TableOperation.tsx'
import { useTable } from '@/features/database/provider/TableProvider.tsx'
import { TableNameValidateRule } from '@/features/database/constant/table'

export interface TabItem {
  id: string
  base_id: string
  source_id: string
  title: string
  next_id?: string
  draggableProvider?: DraggableProvided
  draggableSnapshot?: DraggableStateSnapshot
}

const WrapBox = styled.div<{ active: boolean }>`
  &:hover {
    .content {
      background: ${props => (props.active ? 'none' : '#f2f2f7')};
    }
    .operator {
      display: block;
    }
  }
`

function validateTableName(name: string): boolean {
  let flag = true
  if (!name) {
    flag = false
    message.warning('请输入数据表名称')
  } else if (!TableNameValidateRule.pattern.test(name)) {
    flag = false
    message.warning(TableNameValidateRule.message)
  }
  return flag
}

export default memo(function (props: TabItem) {
  const {
    title,
    base_id,
    source_id,
    id,
    next_id,
    draggableProvider,
    draggableSnapshot,
  } = props

  const [name, setName] = useState(title)

  const renameInput = useRef<InputRef | null>(null)

  const {
    renamingTableId,
    setRenamingTableId,
    onRename,
    currentTableId,
    setCurrentTableId,
    reloadCurrentTable,
    refreshTables,
  } = useTable()

  const active = currentTableId === id
  const nextActive = currentTableId === next_id

  const isRenaming = id === renamingTableId

  useEffect(() => {
    if (isRenaming) {
      renameInput.current?.select()
    }
  }, [isRenaming])

  const reNameTable = async () => {
    // 首先校验前后表名的修改是否相同
    if (title === name) {
      setName(name)
      setRenamingTableId(null)
      return
    }
    const isTableNameValid = validateTableName(name)
    if (isTableNameValid) {
      try {
        await onRename(id, base_id, name)
        refreshTables()
        reloadCurrentTable()
      } catch (_) {}
      setRenamingTableId(null)
    } else {
      // 保持输入框焦点状态
      renameInput.current?.select()
      setName(name)
    }
  }

  return (
    <WrapBox
      ref={draggableProvider?.innerRef}
      {...draggableProvider?.draggableProps}
      active={!!active}
      className={classNames(
        'h-40px bg-white cursor-pointer mr-4 last-of-type:mr-0 shrink-0 relative w-160px flex gap-4 items-center font-500 line-height-16px rd-t-8px b-1 b-b-0 b-transparent',
        {
          'bg-bg! b-line! b-op-60! h-40px z-1':
            active && !draggableSnapshot?.isDragging,
        },
      )}
      onClick={() => setCurrentTableId(id)}
    >
      <div
        {...draggableProvider?.dragHandleProps}
        className='content flex-1 overflow-hidden flex items-center py-8 px-10 rd-8px justify-between'
        style={{
          boxShadow: draggableSnapshot?.isDragging
            ? '0px 0px 10px 0px rgba(0, 0, 0, 0.15)'
            : '',
        }}
      >
        <div className='flex items-center flex-1 overflow-hidden'>
          <IconFont name='table' className='mr-5 ml-2 c-primary shrink-0' />
          {isRenaming ? (
            <Input
              ref={renameInput}
              size='small'
              autoFocus
              value={name}
              onChange={e => setName(e.target.value)}
              onBlur={reNameTable}
              onPressEnter={reNameTable}
              className='!h-24px text-14px/16px w-120px !p-4px'
            />
          ) : (
            <Tooltip placement='bottom' title={title}>
              <span className='truncate'>{title}</span>
            </Tooltip>
          )}
        </div>
        {!isRenaming && (
          <TableOperation
            id={id}
            name={title}
            database={{
              id: base_id,
              source_id,
            }}
            className={classNames(
              'shrink-0 operator',
              active ? 'block' : 'hidden',
            )}
          />
        )}
      </div>
      <span
        className={classNames(
          'inline-block w-1px h-16px bg-line bg-op-80 ml-4px',
          {
            hidden: active || nextActive,
          },
        )}
      ></span>
    </WrapBox>
  )
})
