import { useAuth } from '@bty/react-auth'
import { useBoolean, useClickAway, useRequest } from 'ahooks'
import { Popover, message } from 'antd'
import { debounce, filter, map, tail } from 'lodash-es'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import copy from 'copy-to-clipboard'
import { useWorkspaceStore } from '@/store'
import { Button, IconFont, Select } from '@/components'
import { RoleCode } from '@/apis/authority'
import { linkInvitUser } from '@/apis/application'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { AppCheckboxList } from './AppCheckboxList'
import { SelectUserContent } from './SelectUserContent'

interface OptionsItemProps {
  value: string
  label: string
  applicationType: string
}
interface InviteContentProps {
  role: RoleCode
  setRole: (role: RoleCode) => void
  inviteStr: string | ReactNode
  isInviteUser: boolean
  inviteWithoutAdmin?: boolean
  applicationId?: string
  applicationOptions: OptionsItemProps[]
  appIds: string[]
  lastInviteNum: number
  setAppIds: (value: string[]) => void
}
export interface OptionProps {
  label: string
  descrption: string
  value: RoleCode
}
const basicOptions = [
  { label: '使用者', descrption: '仅可对话', value: RoleCode.VIEWER },
  {
    label: '开发者',
    descrption: '可对话、可编辑',
    value: RoleCode.DEVELOPER,
  },
]
const advancedOptions = [
  {
    label: '管理者',
    descrption: '可以升级空间、编辑和使用应用、邀请其他用户加入工作空间等',
    value: RoleCode.ADMINISTRATOR,
  },
  {
    label: '开发者',
    descrption: '可以编辑和使用应用、邀请其他用户加入工作空间等',
    value: RoleCode.DEVELOPER,
  },
  {
    label: '使用者',
    descrption: '可以使用授权范围内的应用',
    value: RoleCode.VIEWER,
  },
]

export function InviteContent(props: InviteContentProps) {
  const {
    role,
    setRole,
    inviteStr,
    isInviteUser,
    applicationId,
    applicationOptions,
    appIds,
    setAppIds,
    lastInviteNum,
    inviteWithoutAdmin,
  } = props
  const [openPopover, { set: setOpenPopover }] = useBoolean(false)
  const ref = useRef(null)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { data: inviteUserData, runAsync: fetchLinkInviteUser } = useRequest(
    linkInvitUser,
    {
      manual: true,
    },
  )
  const {
    state: { role: workspaceRole },
  } = useAuth()
  const handleOptions = (applicationOptions: OptionsItemProps[]) => {
    let appList: OptionsItemProps[] = applicationOptions
    if (appIds.length !== 0) {
      appList = filter(applicationOptions, obj => appIds.includes(obj.value))
    }
    const FlowCheckList = map(
      appList?.filter(
        (item: OptionsItemProps) => item.applicationType === 'AI',
      ),
      'value',
    )
    const agentCheckList = map(
      appList?.filter(
        (item: OptionsItemProps) => item.applicationType === 'AGENT',
      ),
      'value',
    )

    return {
      agentCheckList,
      FlowCheckList,
    }
  }
  const onCopyKey = debounce(() => {
    fetchLinkInviteUser({
      workspace_id: currentWorkspaceId,
      role_code: role,
      is_auth_all_app: appIds?.length === applicationOptions?.length,
      application_ids: appIds,
    }).then(res => {
      const url = `${location.origin}/login?type=invite&link_id=${res?.id}`
      copy(url)
      message.success('已复制到剪贴板')
    })
  }, 200)
  const truncateString = (str: string, num: number) => {
    if (str.length > num) {
      return `${str.slice(0, num)}...`
    } else {
      return str
    }
  }
  useClickAway(() => {
    setOpenPopover(false)
  }, [ref])
  const handleSelectValue = () => {
    if (appIds?.length === applicationOptions?.length) {
      return '全部应用'
    }
    return appIds && appIds.length > 0
      ? `${applicationOptions?.find(obj => obj?.value === appIds[0])?.label}${
          appIds.length > 1 ? `等${appIds.length}个应用` : ''
        }`
      : '暂无授权应用'
  }
  useEffect(() => {
    if (appIds.length === 0) {
      setAppIds(map(applicationOptions, 'value'))
    }
  }, [role])
  return (
    <>
      {isInviteUser ? (
        <>
          <div className='mb-12px flex items-center'>
            <span className='text-14px/14px font-500'>用户角色&nbsp;</span>
            <span className='c-#8D8D99'>{inviteStr}</span>
          </div>
          <SelectUserContent
            role={role}
            setRole={setRole}
            options={
              workspaceRole === RoleCode.ADMINISTRATOR
                ? inviteWithoutAdmin
                  ? tail(advancedOptions)
                  : advancedOptions
                : tail(advancedOptions)
            }
            isInviteUser={isInviteUser}
            inviteWithoutAdmin={inviteWithoutAdmin}
          />
          {role === RoleCode.VIEWER && (
            <div>
              <div className='text-14px/14px font-500 mb-12px'>
                授权应用范围
              </div>
              {!applicationId && (
                <div ref={ref}>
                  <Popover
                    arrow={false}
                    placement='bottom'
                    trigger='click'
                    getPopupContainer={() => ref.current!}
                    open={openPopover}
                    autoAdjustOverflow={false}
                    overlayClassName='invitePopoverRange'
                    overlayInnerStyle={{ padding: 0, width: 536 }}
                    content={
                      <AppCheckboxList
                        options={applicationOptions}
                        autoSave={false}
                        defaultCheckedList={handleOptions(applicationOptions)}
                        onClose={() => setOpenPopover(false)}
                        onListChange={value => {
                          setAppIds(value)
                          setOpenPopover(false)
                        }}
                      />
                    }
                  >
                    <Select
                      value={handleSelectValue()}
                      className='w-full inviteSelect'
                      placeholder={`请选择${FLOW_DISPLAYNAME}和Agent`}
                      getPopupContainer={() => ref.current!}
                      open={false}
                      onClick={() => setOpenPopover(true)}
                    />
                  </Popover>
                </div>
              )}
            </div>
          )}
          <div className='mt-24px mb-12px flex items-center'>
            <span className='text-14px/14px font-500'>通过链接邀请&nbsp;</span>
            <span className='c-#8D8D99 text-14px/16px'>链接24小时内有效</span>
          </div>
          <div className='flex-center-between'>
            <div
              className='w-440px rounded-8px bg-bg_3 bg-op-5.88 flex-center-between px-12px py-10px cursor-pointer'
              onClick={onCopyKey}
            >
              <span className='text-14px/16px'>
                {truncateString(
                  `${location.origin}/login?type=invite&link_id=${inviteUserData?.id}`,
                  55,
                )}
              </span>
              <span>
                {lastInviteNum > 0 && (
                  <IconFont name='copy' className='c-#626999 opacity-60' />
                )}
              </span>
            </div>
            <Button
              type='primary'
              onClick={onCopyKey}
              disabled={lastInviteNum <= 0}
            >
              复制链接
            </Button>
          </div>
          <div className='mb-12px mt-24px'>
            <span className='text-14px/14px font-500'>通过账号添加用户</span>
          </div>
        </>
      ) : (
        <>
          <SelectUserContent
            role={role}
            setRole={setRole}
            options={basicOptions}
            isInviteUser={isInviteUser}
          />
          <div className='mb-12px flex items-center'>
            <span className='text-14px/14px font-500'>通过账号添加&nbsp;</span>
            <span className='c-#8D8D99'>{inviteStr}</span>
          </div>
        </>
      )}
    </>
  )
}
