import styled from '@emotion/styled'
import { useBoolean } from 'ahooks'
import { useCallback, useState, useMemo, useEffect } from 'react'
import type {
  NodeComponent,
  PatchVariableChangeOptions,
} from '@/features/nodes/base'
import { Segmented } from '@/components'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import type { FieldItem, StartNodeData } from './constant'
import { Meta, NodeTypeOptions, StartNodeTypes } from './constant'
import { FieldsConfig } from './components/FieldsConfig'
import { Webhook } from './components/Webhook'
import { FieldEdit } from './components/FieldEdit'

const StyledSegmented = styled(Segmented)`
  padding: 2px;

  .ant-segmented-item {
    padding: 8px 16px;
    width: 192px;
    font-size: 12px;
    font-weight: 500;
  }
`

export const StartNode: NodeComponent<StartNodeData> = props => {
  const isTemplateFlow = useFlowDraftStore(s => s.isTemplateFlow)

  const [type, setType] = useState<StartNodeTypes>(
    props.data.type || StartNodeTypes.Form,
  )
  const [fieldEditConfigVisible, { toggle: toggleFiledEditVisible }] =
    useBoolean(false)
  const [currentEditIndex, setCurrentEditIndex] = useState<number>(-1)

  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const onNodeConfigChange = useCallback(
    (
      newValue: StartNodeData,
      isMergeUpdate = true,
      options?: PatchVariableChangeOptions,
    ) => {
      props.onSaveChange(newValue, isMergeUpdate, options)
    },
    [],
  )

  const onFiledAdd = useCallback(() => {
    if (lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK) {
      return
    }
    toggleFiledEditVisible()
    setCurrentEditIndex(-1)
  }, [toggleFiledEditVisible])

  const onFieldEdit = useCallback(
    (index: number) => {
      setCurrentEditIndex(index)
      toggleFiledEditVisible()
    },
    [toggleFiledEditVisible],
  )

  const onFieldDelete = useCallback(
    (index: number) => {
      const newFields = [...(props.data.formConfig ?? [])]
      newFields.splice(index, 1)
      onNodeConfigChange({ ...props.data, formConfig: newFields }, false)
    },
    [onNodeConfigChange, props.data.formConfig],
  )

  const onFieldSort = useCallback(
    (newValue: FieldItem[]) => {
      onNodeConfigChange({ ...props.data, formConfig: newValue })
    },
    [onNodeConfigChange],
  )

  const [hasValidateError, setHasValidateError] = useState<boolean>(false)
  useEffect(() => {
    props.registerValidateFunction(() => {
      setHasValidateError(fieldEditConfigVisible)
      return !fieldEditConfigVisible
    })
  }, [fieldEditConfigVisible])

  const handleFiledSave = (
    item: FieldItem,
    options?: PatchVariableChangeOptions,
  ) => {
    const newFields = [...(props.data?.formConfig ?? [])]
    if (currentEditIndex > -1) {
      newFields[currentEditIndex] = item
    } else {
      newFields.push(item)
    }
    onNodeConfigChange({ ...props.data, formConfig: newFields }, false, options)
    toggleFiledEditVisible()
  }

  const variables = useMemo(() => {
    const editVariableName =
      currentEditIndex > -1
        ? props.data.formConfig[currentEditIndex]?.variableName
        : undefined
    return props.data.formConfig
      .map(item => item.variableName)
      .filter(item => item !== editVariableName)
  }, [props.data, currentEditIndex])

  return (
    <div className='w-420px relative'>
      {fieldEditConfigVisible ? (
        <FieldEdit
          showError={hasValidateError}
          variables={variables}
          isEdit={currentEditIndex > -1}
          editItem={
            currentEditIndex > -1
              ? props.data.formConfig[currentEditIndex]
              : undefined
          }
          onCancel={toggleFiledEditVisible}
          onConfirm={handleFiledSave}
        />
      ) : (
        <>
          {!isTemplateFlow && (
            <div className='p-16 mb-0 border-b border-solid border-line border-opacity-60'>
              <p className='mb-8 text-12px font-500'>类型</p>
              <StyledSegmented
                type={type}
                size='small'
                options={NodeTypeOptions}
                disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                value={type}
                onChange={value => {
                  setType(value as StartNodeTypes)
                  onNodeConfigChange({
                    ...props.data,
                    type: value as StartNodeTypes,
                  })
                }}
              />
            </div>
          )}
          <div>
            {type === StartNodeTypes.Webhook ? (
              <Webhook />
            ) : (
              <FieldsConfig
                value={props.data.formConfig ?? []}
                onFieldAdd={onFiledAdd}
                disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                onFieldEdit={onFieldEdit}
                onFieldDelete={onFieldDelete}
                onSort={onFieldSort}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

StartNode.meta = Meta
