import type { Edge } from 'reactflow'
import { useReactFlow } from 'reactflow'
import { useMemoizedFn } from 'ahooks'
import {
  isConditionNode,
  isIntentNode,
  isLoopNode,
  isNestableNode,
} from '@/features/flow/utils'
import { generateUniqueHandleStateId } from '@/features/flow/utils/handle'
import { useFlowInteractionStore } from '@/store'
import { NodeType } from '..'
import { findAllChildrenInNestedNode } from '@/features/flow/operation/util'

export function useNodeRemoveConfirm(nodeId: string) {
  const { getNodes, getEdges } = useReactFlow()
  const setDangerStates = useFlowInteractionStore(s => s.setDangerStates)
  const clearDangerStates = useFlowInteractionStore(s => s.clearDangerStates)

  const beforeRemove = useMemoizedFn(() => {
    const nodes = getNodes()
    const currentNode = nodes.find(n => n.id === nodeId)
    if (currentNode && isNestableNode(currentNode)) {
      const handleIds: string[] = []
      const edgeIds: string[] = []
      const nodeIds: string[] = [nodeId]
      const edges = getEdges()

      // 获取嵌套结构内将被移除的节点
      const removingNodeIds = findAllChildrenInNestedNode(
        currentNode,
        nodes,
        false,
      ).map(n => n.id)
      nodeIds.push(...removingNodeIds)

      let targetEdges: Edge[] = []
      if (isConditionNode(currentNode)) {
        const { conditions, relation } = currentNode.data
        const resultNode = nodes.find(n => n.id === relation.conditionResultId)
        if (!resultNode) {
          console.error('节点数据异常')
          return
        }
        nodeIds.push(resultNode.id)
        const conditionIds = conditions.map(c => c.id)
        targetEdges = edges.filter(
          e =>
            (e.sourceHandle && conditionIds.includes(e.sourceHandle)) ||
            removingNodeIds.includes(e.source) ||
            removingNodeIds.includes(e.target),
        )
      } else if (isIntentNode(currentNode)) {
        const { conditions, relation } = currentNode.data
        const resultNode = nodes.find(n => n.id === relation.conditionResultId)
        if (!resultNode) {
          console.error('节点数据异常')
          return
        }
        nodeIds.push(resultNode.id)
        const conditionIds = conditions.map(c => c.id)
        targetEdges = edges.filter(
          e =>
            (e.sourceHandle && conditionIds.includes(e.sourceHandle)) ||
            removingNodeIds.includes(e.source) ||
            removingNodeIds.includes(e.target),
        )
      } else if (isLoopNode(currentNode)) {
        const { relation } = currentNode.data
        const resultNode = nodes.find(n => n.id === relation.loopResultId)
        if (!resultNode) {
          console.error('节点数据异常')
          return
        }
        nodeIds.push(resultNode.id)
        targetEdges = edges.filter(
          e =>
            (e.sourceHandle && e.target === resultNode.id) ||
            removingNodeIds.includes(e.source) ||
            removingNodeIds.includes(e.target),
        )
      }

      targetEdges.forEach(({ id, source, target, sourceHandle }) => {
        edgeIds.push(id)
        handleIds.push(
          generateUniqueHandleStateId({
            nodeId: source,
            sourceHandle,
            type: 'source',
          }),
        )
        const targetNode = nodes.find(n => n.id === target)
        if (
          targetNode &&
          targetNode.type !== NodeType.CONDITION_RESULT &&
          targetNode.type !== NodeType.INTENT_RESULT
        ) {
          handleIds.push(
            generateUniqueHandleStateId({ nodeId: target, type: 'target' }),
          )
        }
      })
      setDangerStates({
        handles: handleIds,
        edgeIds,
        nodeIds,
      })
    }
  })

  const cancel = useMemoizedFn(() => {
    clearDangerStates()
  })

  return {
    beforeRemove,
    cancel,
  }
}
