import { request } from './lib'

export enum TaskType {
  FLOW = 'flow',
  PLUGIN = 'plugin',
  COMMAND = 'command',
  AGENT = 'agent',
}

export enum ExecuteType {
  IMMEDIATELY = 'IMMEDIATELY',
  SCHEDULE = 'SCHEDULE',
}

export enum LoopType {
  NONE = 'NO_REPEAT',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  WORKDAY = 'WEEKDAY',
  CUSTOM = 'CUSTOM',
}

export enum CustomLoopType {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  WEEK = 'WEEK',
}

export enum TaskStatusType {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
}

export interface TaskDetail {
  flow_id: string
  is_preset: boolean
  update_time: number
  conversation_id: string
  preset_enable?: boolean
  task_id?: string
  task_name: string
  execution_method: TaskType
  rel_flow_plugin_id: string
  execution_type: ExecuteType
  task_start_time?: string
  task_type?: LoopType
  custom_task_type?: CustomLoopType
  custom_task_config?: string
  run_param: Record<string, any>
}

export interface TaskResult extends TaskDetail {
  task_id: string
}

export interface TaskResultDetail {
  flow_id: string
  conversation_id: string
  task_id: string
  task_name: string
  config: TaskResult
}

export function getTask(
  task_id: string,
  conversation_id: string,
  flow_id: string,
) {
  return request.get<TaskResultDetail>('/v1/task_list/detail', {
    params: { task_id, conversation_id, flow_id },
  })
}

export function addTask(task: TaskDetail) {
  return request.post<string>('/v1/task_list/add', task)
}

export function updateTask(task: TaskResult) {
  return request.put('/v1/task_list/update', task)
}

export function deleteTask(task_id: string, conversation_id: string) {
  return request.delete('/v1/task_list/delete', {
    data: { task_id, conversation_id },
  })
}

export interface TaskListResult {
  id: number
  flow_id: string
  task_id: string
  task_name: string
  remark: string
  is_enable: boolean
  is_preset: boolean
  last_task_status: TaskStatusType
  last_task_time: number
}

export function enableTask(
  task: TaskListResult,
  conversation_id: string,
  enable: boolean,
) {
  return request.put('/v1/task_list/enable', {
    task_id: task.task_id,
    conversation_id,
    is_preset: task.is_preset,
    enable,
  })
}

export function executeTask(task_list_id: number) {
  return request.post('/v1/task_list/execute/task', {
    task_list_id,
  })
}

export function getTaskList(flow_id: string, conversation_id: string) {
  return request.get<TaskListResult[]>('/v1/task_list/list', {
    params: { flow_id, conversation_id },
  })
}

export function getTaskListDetail(
  task_id: string,
  conversation_id: string,
  flow_id: string,
) {
  return request.get<TaskListResult>('/v1/task_list/task/show/detail', {
    params: { task_id, conversation_id, flow_id },
  })
}

export interface TaskLogFunctionCallLog {
  content: string
  status: string
  type: string
  name: string
  metadata: {
    color: string
    icon: string
  }
  message: {
    flow_id: string
    task_id: string
    message: {
      step_err_info: string
    }
  }
}

export interface TaskLogItem {
  record_id: string
  execute_time: string
  output: {
    status: string
    duration_time: number
    tasks: TaskLogFunctionCallLog[]
    message: {
      flow_id: string
      task_id: string
      message: {
        step_err_info: string
      }
    }
  }
  run_param: Record<string, any>
  task_status: string
}

export interface TaskLogList {
  page_number: number
  page_size: number
  total_count: number
  total_pages: number
  data_list: TaskLogItem[]
}

export function getTaskLog(
  task_id: string,
  conversation_id: string,
  page_no: number,
  page_size = 10,
) {
  return request.post<TaskLogList>('/v1/task_list/log/page', {
    task_id,
    conversation_id,
    page_no,
    page_size,
  })
}

export function getNextTaskRunTime(task_id: string, conversation_id: string) {
  return request.get<{ next_run_time: number }>('/v1/task_list/next_run_time', {
    params: { task_id, conversation_id },
  })
}

export function getConversationTaskInfo(
  flow_id: string,
  conversation_id: string,
) {
  return request.get<{ count: number; is_new_task: boolean }>(
    '/v1/task_list/conversation/task_count',
    {
      params: { flow_id, conversation_id },
    },
  )
}

export function refreshConversationTaskInfo(conversation_id: string) {
  return request.put('/v1/chat/conversation/change_task_time', {
    conversation_id,
  })
}
